export interface IFile extends File {
  originalname: string;
  file: string;
}


export const serializeFiles = (files: IFile[]) =>
  files.map((file) => {
    return {
      ...file,
      file: { name: file.originalname, link: file.file },
    };
  });
