import React from "react"
import { useDispatch } from "react-redux"
import { userSelector } from "redux/modules/common/auth"
import { createTasksList } from "redux/modules/purchaser/Tasks/thunks"
import { useTypedSelector } from "redux/typedUseSelector"

export const useCreateTasksList = (successCallback?: () => void) => {
    const dispatch = useDispatch()
    const user = useTypedSelector(userSelector)
    const [name, setName] = React.useState("")

    const setNameHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }, [])

    const createTasksListHandler = React.useCallback(() => {
        dispatch(createTasksList({ list_name: name, user: user.id }, successCallback))
    }, [name])

    return {
        createTasksListHandler,
        setNameHandler,
        name
    }
}