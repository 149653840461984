import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { compose } from "redux";

import {
  resetExpendituresAction,
  resetSectionAction,
  resetSectionsAction,
} from "../../../../redux/modules/common/building/sections/sections";

import { ESTIMATE_STATES_IDS, ESTIMATE_STATES_NAMES } from "../constants";

const getLastEstimateStateIdOnBaseUrl = (baseUrl) => {
  const lastEstimateStateSplitCode = localStorage.getItem("lastEstimateStateCode")?.split("_");
  if (!lastEstimateStateSplitCode) return null;

  const [lastBaseUrl, lastEstimateStateId] = lastEstimateStateSplitCode;
  return lastBaseUrl === baseUrl ? lastEstimateStateId : null;
};

const useEstimateState = (baseUrl, permissions, isBuildingShared, isHideProductionInfo) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeEstimateStateId, setActiveEstimateStateId] = useState();
  const [availableEstimateStates, setAvailableEstimateStates] = useState();

  const onSetActiveEstimateStateId = useCallback(
    (estimateStateId) => {
      if (activeEstimateStateId === estimateStateId) return;
      localStorage.setItem("lastEstimateStateCode", `${baseUrl}_${estimateStateId}`);
      compose(dispatch, resetSectionsAction)();
      compose(dispatch, resetSectionAction)();
      compose(dispatch, resetExpendituresAction)();

      setActiveEstimateStateId(estimateStateId);
      history.push(baseUrl);
    },
    [activeEstimateStateId, baseUrl, dispatch, history]
  );

  const estimateStatesByPermissions = useMemo(() => {
    const result = [];

    if (!isBuildingShared && permissions.viewDraftHandlerState) {
      result.push({ id: ESTIMATE_STATES_IDS.DRAFT, name: ESTIMATE_STATES_NAMES.DRAFT });
    }
    if (permissions.viewLocaleHandlerState) {
      result.push({ id: ESTIMATE_STATES_IDS.LOCALE, name: ESTIMATE_STATES_NAMES.LOCALE });
    }
    if (permissions.viewObjectHandlerState) {
      result.push({ id: ESTIMATE_STATES_IDS.OBJECT, name: ESTIMATE_STATES_NAMES.OBJECT });
    }
    if (permissions.viewConsolidateHandlerState) {
      result.push({ id: ESTIMATE_STATES_IDS.CONSOLIDATE, name: ESTIMATE_STATES_NAMES.CONSOLIDATE });
    }
    if (permissions.viewProductionHandlerState && !isHideProductionInfo) {
      result.push({ id: ESTIMATE_STATES_IDS.PRODUCTION, name: ESTIMATE_STATES_NAMES.PRODUCTION });
    }

    return result;
  }, [
    isBuildingShared,
    isHideProductionInfo,
    permissions.viewConsolidateHandlerState,
    permissions.viewDraftHandlerState,
    permissions.viewLocaleHandlerState,
    permissions.viewObjectHandlerState,
    permissions.viewProductionHandlerState,
  ]);

  const estimateStatesMenu = useMemo(() => {
    if (activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT) {
      return estimateStatesByPermissions.filter(
        (state) => state.id !== ESTIMATE_STATES_IDS.DRAFT && state.id !== ESTIMATE_STATES_IDS.PRODUCTION
      );
    } else if (activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE) {
      return estimateStatesByPermissions.filter(
        (state) => state.id !== ESTIMATE_STATES_IDS.DRAFT && state.id !== ESTIMATE_STATES_IDS.LOCALE
      );
    } else if (activeEstimateStateId === ESTIMATE_STATES_IDS.OBJECT) {
      return estimateStatesByPermissions.filter(
        (state) =>
          state.id !== ESTIMATE_STATES_IDS.DRAFT &&
          state.id !== ESTIMATE_STATES_IDS.LOCALE &&
          state.id !== ESTIMATE_STATES_IDS.OBJECT
      );
    } else if (activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE) {
      return estimateStatesByPermissions.filter(
        (state) =>
          state.id !== ESTIMATE_STATES_IDS.DRAFT &&
          state.id !== ESTIMATE_STATES_IDS.LOCALE &&
          state.id !== ESTIMATE_STATES_IDS.OBJECT &&
          state.id !== ESTIMATE_STATES_IDS.CONSOLIDATE
      );
    }
  }, [activeEstimateStateId, estimateStatesByPermissions]);

  useEffect(() => {
    if (estimateStatesByPermissions.length === 0) return;

    const lastEstimateStateIdOnBaseUrl = getLastEstimateStateIdOnBaseUrl(baseUrl);
    const lastEstimateStateIsNotAvailable =
      estimateStatesByPermissions.findIndex((state) => state.id === lastEstimateStateIdOnBaseUrl) === -1;

    if (!lastEstimateStateIdOnBaseUrl || lastEstimateStateIsNotAvailable) {
      history.push(baseUrl);
      setActiveEstimateStateId(estimateStatesByPermissions[0].id);
    } else {
      setActiveEstimateStateId(lastEstimateStateIdOnBaseUrl);
    }

    setAvailableEstimateStates(estimateStatesByPermissions);
  }, [isBuildingShared, baseUrl, estimateStatesByPermissions]);

  return {
    activeEstimateStateId,
    setActiveEstimateStateId: onSetActiveEstimateStateId,
    availableEstimateStates,
    estimateStatesMenu,
  };
};

export default useEstimateState;
