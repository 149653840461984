import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";

import { getDetail, setDetailNull } from "../../../../redux/modules/common/building/object/nowObject";
import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import {
  chartViewModeSelector,
  manufacturingHashSelector,
} from "redux/modules/common/building/manufacturing/selectors";
import {
  dropLoadedChartMonths,
  mergeData,
  mergeWeekData,
  setConstructingExpandedBranches,
  setIsLoadingChartData,
} from "redux/modules/common/building/manufacturing/thunks";

import { IUseSpittingTreeProps } from "./useSpittingTree";

import {
  ExpandedBranchesType,
  IMonthArrayElement,
  IProcessedBranch,
  IProject,
  ManufacturingTabsType,
  ProjectEstimateType,
} from "../types";

export interface IUseProjectTreeProps {
  actualProjects: IProject[];
  tab: ManufacturingTabsType;
  expandedBranches: ExpandedBranchesType;
  projectEstimate: ProjectEstimateType;
  projectEstimateSet: (id: number) => Action;
  generateSpittingTree: (args?: Partial<IUseSpittingTreeProps["spittingTreeFnArgs"]>) => void;
  month: IMonthArrayElement | undefined;
  year: number | string;
  startWeek: number;
  endWeek: number;
  projectId: number | string;
  setIsEmpty: (newIsEmpty: boolean) => void;
  expenditureTypesToLoad: Record<ManufacturingTabsType, boolean>;
}

export const useProjectTree = ({
  actualProjects,
  tab,
  expandedBranches,
  projectEstimate,
  projectEstimateSet,
  generateSpittingTree,
  month,
  year,
  startWeek,
  endWeek,
  projectId,
  setIsEmpty,
}: IUseProjectTreeProps) => {
  const dispatch = useDispatch();
  const chartViewMode = useSelector(chartViewModeSelector);
  const manufacturingHash = useSelector(manufacturingHashSelector);

  useEffect(() => {
    dispatch(setIsLoadingChartData(true));
    dispatch(setDetailNull());

    if (!projectId) {
      dispatch(setIsLoadingChartData(false));
      return;
    }

    dispatch(getDetail(projectId, () => dispatch(setIsLoadingChartData(false))));

    return () => {
      dispatch(setDetailNull());
    };
  }, [projectId]);

  const checkIsExpandedBranchId = useCallback(
    (id: number) => {
      return expandedBranches.has(id);
    },
    [expandedBranches]
  );

  const merge = async (projectBranchId: number | string) => {
    if (chartViewMode === MONTH || chartViewMode === HALF_MONTH)
      await dispatch(mergeData({ projectId: projectBranchId, year, month: month.id + 1 }));

    if (chartViewMode === WEEK || chartViewMode === YEAR)
      await dispatch(mergeWeekData({ projectId: projectBranchId, start_week: startWeek, end_week: endWeek, year }));
  };

  const reloadProjectsTrees = () => {
    const projectsToMerge = actualProjects?.filter((x) => expandedBranches.has(x.id));
    if (!projectsToMerge?.length) return;

    Promise.allSettled(
      projectsToMerge.map(async (project) => {
        await merge(project.id);
        if (!projectEstimate.has(`${project.id}_${tab}`)) await dispatch(projectEstimateSet(project.id));
      })
    ).then(generateSpittingTree);
  };

  const toggleBranch = async (branch: IProcessedBranch | { id: number; lvl: number }) => {
    if (branch.lvl === 1) {
      await merge(branch.id);
      const isProjectTreeLoaded = projectEstimate.has(`${branch.id}_${tab}`);
      if (!isProjectTreeLoaded) await dispatch(projectEstimateSet(branch.id));
    }
    expandedBranches.has(branch.id) ? expandedBranches.delete(branch.id) : expandedBranches.add(branch.id);
    dispatch(setConstructingExpandedBranches(expandedBranches));

    generateSpittingTree();
  };

  useEffect(() => {
    const cleanUp = () => {
      setIsEmpty(false);
      generateSpittingTree();
    };
    if (!projectId || !actualProjects?.length) return;
    if (!expandedBranches.has(+projectId)) toggleBranch({ id: +projectId, lvl: 1 });
    return () => cleanUp();
  }, [projectId, actualProjects, manufacturingHash]);

  useEffect(reloadProjectsTrees, [
    year,
    chartViewMode,
    tab,
    projectId,
    month,
    projectEstimate,
    expandedBranches,
    actualProjects,
  ]);

  useEffect(() => {
    return () => {
      dispatch(dropLoadedChartMonths());
    };
  }, [manufacturingHash]);

  return {
    checkIsExpandedBranchId,
    toggleBranch,
  };
};
