import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { journalFulfillmentMaterialsInvalidateKeySelector } from "redux/modules/common/building/journal/journalExecution/selectors";
import { updateStockUsingMaterialsPrice } from "redux/modules/common/building/journal/thunks";
import { IChangeJournalPriceTypeRequest } from "redux/modules/common/building/journal/types";

import ButtonBase from "components/UI/atoms/ButtonBase";
import InputBase from "components/UI/atoms/InputBase";
import Radio from "components/UI/atoms/Radio/Radio";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./PopoverContent.module.scss";

export enum COUNTS_ENUM {
  FACT = "fact",
  PROJECT = "estimate",
}

export interface IProps {
  setIsOpenPopover: React.Dispatch<React.SetStateAction<boolean>>;
  buildingId: number;
  stockId: number;
  isGroup: boolean;
  amountType: COUNTS_ENUM;
  factAmount: string;
  estimateAmount: string;
}

const PopoverContent: React.FC<IProps> = ({
  setIsOpenPopover,
  buildingId,
  stockId,
  isGroup,
  amountType = COUNTS_ENUM.FACT,
  factAmount,
  estimateAmount,
}) => {
  const dispatch = useDispatch();

  const materialsInvalidateKey = useSelector(journalFulfillmentMaterialsInvalidateKeySelector);
  const prevInvalidateKey = React.useRef<number>(materialsInvalidateKey);

  const [activeCountName, setActiveCountName] = useState(amountType);

  useEffect(() => {
    if (prevInvalidateKey.current !== materialsInvalidateKey) {
      closePopover();
      prevInvalidateKey.current = materialsInvalidateKey;
    }
  }, [materialsInvalidateKey]);

  const closePopover = () => {
    setIsOpenPopover(false);
  };

  const handleSubmit = () => {
    const data: IChangeJournalPriceTypeRequest = {
      type: isGroup ? "group" : "expenditure",
      confirmed_amount_type: activeCountName,
    } as const;
    if (isGroup) {
      data.group_material = stockId;
    } else {
      data.expenditure_material = stockId;
    }
    dispatch(updateStockUsingMaterialsPrice(buildingId, data));
  };

  return (
    <div className={cn(styles.container)}>
      <>
        <header className={styles.header}>
          <h1>Выберите стоимость - фактическую или проектную</h1>
        </header>
        <div className={styles.divider} />
        <section className={styles.section}>
          <div
            onClick={() => setActiveCountName(COUNTS_ENUM.FACT)}
            className={cn(styles.item, styles.flexRow, {
              [styles.active]: activeCountName === COUNTS_ENUM.FACT,
            })}
          >
            <Radio isActive={activeCountName === COUNTS_ENUM.FACT} />
            <span className={styles.radioName}>Фактическая стоимость, {LOCALIZATION_CONFIG.currency}</span>
            <InputBase
              classNameInput={styles.input}
              variant="brandColorWhite"
              disabled
              input={undefined}
              className={undefined}
              label={undefined}
              onChange={undefined}
              onBlur={undefined}
              value={splitThousands(factAmount, false, false)}
            />
          </div>
          <div
            onClick={() => setActiveCountName(COUNTS_ENUM.PROJECT)}
            className={cn(styles.item, styles.flexRow, {
              [styles.active]: activeCountName === COUNTS_ENUM.PROJECT,
            })}
          >
            <Radio isActive={activeCountName === COUNTS_ENUM.PROJECT} />
            <div className={styles.projectContent}>
              <div className={styles.flexRow}>
                <span className={styles.radioName}>Проектная стоимость, {LOCALIZATION_CONFIG.currency}</span>
                <InputBase
                  classNameInput={styles.input}
                  variant="brandColorWhite"
                  disabled
                  onChange={undefined}
                  input={undefined}
                  className={undefined}
                  label={undefined}
                  onBlur={undefined}
                  value={splitThousands(estimateAmount, false, false)}
                />
              </div>
            </div>
          </div>
        </section>
        <footer className={styles.buttons}>
          <ButtonBase secondary onClick={() => setIsOpenPopover(false)}>
            Отменить
          </ButtonBase>
          <ButtonBase type="submit" primary onClick={handleSubmit}>
            Сохранить
          </ButtonBase>
        </footer>
      </>
    </div>
  );
};

export default React.memo(PopoverContent);
