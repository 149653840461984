import cn from "classnames";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { timesheetApprovalSelector } from "redux/modules/common/building/object/nowObject";
import { workersCountWorkSelector, workersSelector } from "redux/modules/common/building/workers";

import WorkDayCell from "./components/WorkDayCell/WorkDayCell";
import WorkDayHeaderCell from "./components/WorkDayHeaderCell/WorkDayHeaderCell";
import WorkerCard from "./components/WorkerCard/WorkerCard";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import SearchFilterTable from "components/UI/atoms/SearchFilterTable";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import { VIEW_MANUFACTURING_WORKERS_TURNOUT_EDIT } from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import { buildWeekDaysFromEnd } from "./utils/buildWeekDaysFromEnd";
import getShortFullName from "utils/formatters/getShortFullName";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import timesheetIcon from "images/icons/navigation/timesheetIcon.svg";

import styles from "./Week.module.scss";

const checkDateEqualsByWeekAndDay = (expectedDate, actual) => {
  return expectedDate.date() === actual.date && expectedDate.week() === actual.week;
};

const Week = ({ buildingId, startOfWeekDate, changeFilters, loadMore }) => {
  const workers = useSelector(workersSelector);
  const workersCountWork = useSelector(workersCountWorkSelector);
  const timesheetApproval = useSelector(timesheetApprovalSelector);

  const [changedDay, setChangedDay] = useState({ week: moment().week(), date: moment().date() });
  const activeWeekDays = useMemo(() => buildWeekDaysFromEnd(startOfWeekDate), [startOfWeekDate]);

  const [isWorkerCardOpen, setIsWorkerCardOpen] = useState(false);
  const [activeWorkerCardWorker, setActiveWorkerCardWorker] = useState(null);

  const openWorkerCard = useCallback((worker) => {
    setIsWorkerCardOpen(true);
    setActiveWorkerCardWorker(worker);
  }, []);

  const closeWorkerCard = useCallback(() => {
    setIsWorkerCardOpen(false);
    setActiveWorkerCardWorker(null);
  }, []);

  const getWorkerWorkByDate = useCallback(
    (workerId, date) =>
      workersCountWork?.results.find(
        (work) => work.worker === workerId && work.work_date === moment(date).format("YYYY-MM-DD")
      ),
    [workersCountWork?.results]
  );

  const haveEditTuroutPermission = usePermission(VIEW_MANUFACTURING_WORKERS_TURNOUT_EDIT);

  return (
    <>
      <div>
        <header className={styles.header}>
          <div className={styles.roundColumn} />
          <div className={styles.numberColumn}>№</div>
          <div className={styles.nameColumn}>
            <span>ФИО, должность (разряд)</span>
            <SearchFilterTable title="ФИО" setFilter={(value) => changeFilters(value, "name")} />
          </div>
          {activeWeekDays.map((weekDay) => (
            <div className={styles.dayColumn} key={weekDay.toString()}>
              <WorkDayHeaderCell
                workDay={weekDay}
                isChangedDay={checkDateEqualsByWeekAndDay(weekDay, changedDay)}
                setChangedDay={setChangedDay}
                isChangeDisabled={timesheetApproval.is_blocked || weekDay.isAfter(moment(), "date")}
                isFullDisabled={!haveEditTuroutPermission}
              />
            </div>
          ))}
        </header>
        {workers?.results?.length !== 0 ? (
          workers?.results?.map((worker, i) => (
            <div className={styles.row} key={worker.id} onClick={() => openWorkerCard(worker)}>
              <div className={styles.roundColumn}>
                <span className={cn(styles.round, { [styles.blueLabel]: worker.is_staff })} />
              </div>
              <div className={styles.numberColumn}>{i + 1}</div>
              <div className={styles.nameColumn}>
                <div data-name={getShortFullName(worker)}>{getShortFullName(worker)}</div>
                <div className={styles.postInfo}>
                  {worker.post_rank_info?.post_title}{" "}
                  {!!worker.post_rank_info?.rank_display && `(${worker.post_rank_info?.rank_display})`}
                </div>
              </div>
              {activeWeekDays.map((weekDay) => (
                <div className={styles.dayColumn} onClick={stopEventPropagation} key={weekDay.toString()}>
                  <WorkDayCell
                    workDate={weekDay}
                    buildingId={buildingId}
                    work={getWorkerWorkByDate(worker.id, weekDay)}
                    workerId={worker.id}
                    isWorkerInStaff={worker.is_staff}
                    isDisabled={
                      !haveEditTuroutPermission ||
                      !checkDateEqualsByWeekAndDay(weekDay, changedDay) ||
                      timesheetApproval.is_blocked
                    }
                  />
                </div>
              ))}
            </div>
          ))
        ) : (
          <EmptyPlaceholder img={timesheetIcon} className={styles.emptyPlaceholder} />
        )}
      </div>
      {workers?.count > workers?.results?.length && (
        <div className={styles.moreButton}>
          <ShowMoreButton handleAdd={loadMore} allCount={workers.count} showedCount={workers.results.length} />
        </div>
      )}
      {activeWorkerCardWorker && (
        <WorkerCard
          worker={activeWorkerCardWorker}
          isOpen={isWorkerCardOpen}
          onClose={closeWorkerCard}
          buildingId={buildingId}
        />
      )}
    </>
  );
};

export default React.memo(Week);
