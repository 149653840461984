import React from "react";

import BlueLabel from "components/UI/atoms/BlueLabel";
import PairOfInfoItems from "components/UI/organism/WorkOrMaterialsModals/components/PairOfInfoItems/PairOfInfoItems";
import ModalDivider from "components/modals/atoms/ModalDivider/ModalDivider";

import { IReplacementInExpenditure } from "../ReplacedExpenditure";

import { splitThousands } from "utils/formatters/splitThousands";
import downloadFile from "utils/helpers/download";

import styles from "./ReplacementsModal.module.scss";

interface IProps {
  replacement: IReplacementInExpenditure;
  isLast: boolean;
}

const ReplacementItem: React.FC<IProps> = ({ replacement, isLast }) => {
  return (
    <>
      <ModalDivider text={isLast ? "Исходная" : "Замененная"} icon={isLast ? "arrow" : "check"} color="#47D6D1" />
      <div className={styles.name}>{replacement.data.name}</div>
      <BlueLabel className={styles.justification} blueFont>
        {replacement.data.justification}
      </BlueLabel>
      <PairOfInfoItems
        label1="Кол-во:"
        label2="Стоимость:"
        value1={replacement.data.count + " " + replacement.data.measure}
        value2={splitThousands(replacement.data.estimate_amount) as string}
      />
    </>
  );
};

export default ReplacementItem;
