import { memoize, partial } from "lodash";
import React, { useMemo } from "react";

import { TableOslaLocal } from "../../../../UI/TableOslaCustom/TableOslaLocal";
import { TableHeader } from "../../../../UI/TotoRowTable/TableHeader/TableHeader";
import { ProductCommentsFormatter } from "../../../../UI/molecules/ProductCommentsFormatter/ProductCommentsFormatter";
import ProductFilesModal from "../../../Requisition/components/ProductFilesModal/ProductFilesModal";
import { ACT_TYPES_ENUM } from "components/pages/ServiceAct/types/enums";

import amountWithTaxColumnCell from "./formatters/AmountWithTaxColumnCell";
import deleteColumnCell from "./formatters/DeleteColumnCell";
import factCountColumnCell from "./formatters/FactCountColumnCell";
import priceColumnCell from "./formatters/PriceColumnCell";
import { LOCALIZATION_CONFIG } from "constants/localization";
import { IFile } from "types/interfaces/Files";
import { IPackingListItems } from "types/interfaces/PackingList";
import { IProductsComments } from "types/interfaces/Product";
import { IServiceActItem } from "types/interfaces/ServiceAct";

import { sliceTextByConstraint } from "../../../../../utils/formatters/sliceTextByConstraint";
import { stringifyArgs } from "../../../../../utils/helpers/stringifyArgs";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import styles from "./Products.module.scss";

export interface IProps {
  products: IServiceActItem[] | IPackingListItems[];
  loadPackingItemFiles?: (_: number) => void;
  productsComments?: IProductsComments[];
  files?: IFile[];
  isPurchaser?: boolean;
  productsCommentsAreLoading?: boolean;
  canEdit?: boolean;
  createProductComment?: (productId: number, comment: string) => void;
  getProductComments?: (productId: number) => void;
  addFiles?: (productId: number, addedFiles: IFile[]) => void;
  deleteFile?: (productId: number, file: IFile) => void;
  onChangeProductItem?: (changedProductId: number, changedItemName: string) => void;
  onDeleteProduct?: (deletedProductId: number) => void;
  permissions?: Record<string, boolean>;
  type?: ACT_TYPES_ENUM.SERVICE_ACTS;
  loadPackingList?: (isWithoutLoader?: boolean) => void;
}

const Products: React.FC<IProps> = ({
  products,
  loadPackingItemFiles,
  productsComments,
  productsCommentsAreLoading,
  createProductComment,
  getProductComments,
  files,
  addFiles,
  deleteFile,
  onChangeProductItem,
  canEdit,
  onDeleteProduct,
  isPurchaser,
  permissions,
  type = ACT_TYPES_ENUM.PACKING_LISTS,
  loadPackingList,
}) => {
  const [isPassports, setIsPassports] = React.useState<Record<number, boolean>>({});
  const partialAddFiles = useMemo(
    () => memoize((productId) => partial(addFiles!, productId), stringifyArgs),
    [addFiles]
  );

  const partialDeleteFile = useMemo(
    () => memoize((productId) => partial(deleteFile!, productId), stringifyArgs),
    [deleteFile]
  );

  const partialCreateComment = useMemo(
    () => memoize((productId) => partial(createProductComment!, productId), stringifyArgs),
    [createProductComment]
  );

  const onOpenProductFilesModal = React.useCallback(
    (productId: number) => () => {
      loadPackingItemFiles?.(productId);
      setIsPassports((prevState) => ({ ...prevState, [productId]: true }));
    },
    [loadPackingItemFiles]
  );

  const onCloseProductFilesModal = React.useCallback(
    (productId: number) => () => {
      setIsPassports((prevState) => ({ ...prevState, [productId]: false }));
      loadPackingList?.(true);
    },
    [loadPackingItemFiles, loadPackingList]
  );

  return (
    <TableOslaLocal
      data={products}
      pagination={false}
      rowClass={styles.tableRowClass}
      tableClass={styles.tables}
      headerClass={styles.tableHeaderClass}
    >
      <TableHeader cellStyle={{ width: "3%", textAlign: "center" }} numberGeneration>
        №
      </TableHeader>
      <TableHeader
        data={"name"}
        cellStyle={{ width: canEdit ? "33%" : "37%", textAlign: "left" }}
        formatter={(cell: string | undefined) => <div title={cell}>{sliceTextByConstraint(cell, 80)}</div>}
      >
        {type === ACT_TYPES_ENUM.PACKING_LISTS ? "Наименование товара" : "Наименование работ, услуг"}
      </TableHeader>
      {type === ACT_TYPES_ENUM.PACKING_LISTS && (
        <TableHeader
          cellStyle={{ width: "5%", textAlign: "center" }}
          formatter={(_: any, product: IServiceActItem | IPackingListItems) => (
            <ProductFilesModal
              variant="secondary" // @ts-ignore
              files={files![product.id as number]}
              filesCount={product?.count_files! + product?.count_documents! || undefined}
              uploadFilesCallback={partialAddFiles(product.id)}
              deleteFilesCallback={partialDeleteFile(product.id)}
              permissions={permissions}
              itemId={product.id}
              isPassports={isPassports[product.id]}
              productName={product.name}
              onOpen={onOpenProductFilesModal(product.id)}
              onClose={onCloseProductFilesModal(product.id)}
              isV2
              expenditureType={product.expenditure_type}
              expenditureId={product.expenditure_id}
            />
          )}
        />
      )}
      {type === ACT_TYPES_ENUM.PACKING_LISTS && (
        <TableHeader
          cellStyle={{ width: "5%", textAlign: "center" }}
          formatter={(_: any, product: IServiceActItem | IPackingListItems) => (
            <ProductCommentsFormatter
              product={product}
              productComments={productsComments?.[product.id as number]}
              productsCommentsAreLoading={productsCommentsAreLoading}
              createProductComment={partialCreateComment(product.id)}
              getProductComments={getProductComments}
            />
          )}
        />
      )}
      <TableHeader data={"measure"} cellStyle={{ width: "10%", textAlign: "center" }}>
        Ед. изм.
      </TableHeader>
      <TableHeader
        data={"count"}
        cellStyle={{ width: "10%", textAlign: "center" }}
        formatter={(cell: string | number) => dropNonSignificantZeros(cell)}
      >
        Кол-во
      </TableHeader>
      {type === ACT_TYPES_ENUM.PACKING_LISTS && (
        <TableHeader
          cellStyle={{ width: "10%", textAlign: "center" }}
          formatter={factCountColumnCell({
            canEdit,
            isPurchaser,
            onChange: onChangeProductItem,
          })}
        >
          Факт. Кол-во
        </TableHeader>
      )}
      <TableHeader formatter={priceColumnCell} cellStyle={{ width: "10%", textAlign: "center" }}>
        Цена&nbsp; <span className={styles.fontOfCurrency}>{LOCALIZATION_CONFIG.currency}</span>{" "}
      </TableHeader>
      <TableHeader
        cellStyle={{ width: "10%", textAlign: "center" }}
        formatter={amountWithTaxColumnCell({
          canEdit: false,
          isPurchaser,
          onChange: onChangeProductItem,
        })}
      >
        Сумма с&nbsp;НДС&nbsp;<span className={styles.fontOfCurrency}>{LOCALIZATION_CONFIG.currency}</span>
      </TableHeader>
      {canEdit && (
        <TableHeader formatter={deleteColumnCell({ onDelete: onDeleteProduct! })} cellStyle={{ width: "4%" }} />
      )}
    </TableOslaLocal>
  );
};

export default React.memo(Products);
