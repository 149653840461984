import { applyMiddleware, compose, createStore } from "redux";

import { initialState as manufacturingInitialState } from "./modules/common/building/manufacturing/manufacturing";
import { RootState, rootReducer } from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
// @ts-ignore
import persistState from "redux-localstorage";
import thunk from "redux-thunk";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export type InferPropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never;

export type IActions<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<InferPropertiesTypes<T>>;

const middlewares = [thunk];
const composeEnhancers =
  process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  persistState(["auth", "breadcrumbs", "manufacturing"], {
    serialize: (subset: Partial<RootState>) => {
      try {
        return JSON.stringify({
          ...subset,
          manufacturing: {
            diagramFilters: subset.manufacturing.diagramFilters,
            manufacturingExpandedBranches: Array.from(subset.manufacturing.manufacturingExpandedBranches),
            constructingExpandedBranches: Array.from(subset.manufacturing.constructingExpandedBranches),
          },
        });
      } catch (e) {
        return "";
      }
    },
    deserialize: (subsetString: string) => {
      try {
        const persistedState = JSON.parse(subsetString);
        return {
          ...persistedState,
          manufacturing: {
            ...persistedState.manufacturing,
            manufacturingExpandedBranches: new Set(persistedState.manufacturing.manufacturingExpandedBranches),
            constructingExpandedBranches: new Set(persistedState.manufacturing.constructingExpandedBranches),
          },
        };
      } catch (e) {
        return JSON.parse(subsetString) || {};
      }
    },
    merge: (initialState: RootState, persistedState: RootState) => {
      try {
        return {
          ...initialState,
          manufacturing: {
            ...manufacturingInitialState,
            ...persistedState.manufacturing,
          },
        };
      } catch (e) {
        return initialState;
      }
    },
  })
);
// @ts-ignore
const store = createStore(rootReducer, {}, enhancer);

export default store;
