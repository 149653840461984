import cn from "classnames";
import moment from "moment";
import React, { Fragment } from "react";

import { generateWeeksNumbersInMonth, getWeeksInYear } from "components/pages/Manufacturing/utils";

import { ICalendarDateLineProps } from "../../CalendarDateLine";

import { MONTH_IDS } from "../../../../constants";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import styles from "./MonthsLine.module.scss";

const MonthsLine = React.forwardRef<Record<number | string, HTMLDivElement>, ICalendarDateLineProps>(
  ({ year, unitOffset }, ref) => {
    const unitMultiplier = useUnitMultiplier();
    const weekWidth = unitMultiplier;
    return (
      <div
        className={styles.monthsLine}
        ref={(r) => {
          if (!ref.current) return;
          ref.current[year] = r;
        }}
        style={{ left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
      >
        {MONTH_IDS.map((monthId, index) => {
          const monthMoment = moment()
            .year(+year)
            .month(monthId);

          const today = moment().isSame(monthMoment, "month") ? moment().date() : undefined;

          const todayLabel = today !== undefined && `${moment().week()}`;

          const weeks = generateWeeksNumbersInMonth(+year, monthId);

          return (
            <Fragment key={index}>
              <div
                className={cn(styles.monthCaptionBlock, "startMonth")}
                style={{
                  left: `${weekWidth * (weeks[0] - 1)}rem`,
                  width: `${weekWidth * weeks.length}rem`,
                }}
                data-year={year}
                data-month={monthId}
              >
                <span className={styles.monthName}>{monthMoment.format("MMM")}</span>
                <span className={styles.monthInterval}>
                  {monthMoment.startOf("month").format("DD.MM")}-{monthMoment.endOf("month").format("DD.MM")}
                </span>
              </div>
              {today !== undefined && (
                <div
                  className={styles.currentDay}
                  style={{
                    left: `${(moment().week() - 0.5) * unitMultiplier}rem`,
                  }}
                >
                  <span>{todayLabel}</span>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  }
);
export default React.memo(MonthsLine);
