import {combineReducers} from 'redux'

import counterparties from './counterparties'
import contractors from './contractors'
import suppliers from './suppliers'
import customers from './customers'
import contacts from './contacts'
import invites from './invites'
import common from './common'

export const moduleName = 'counterparties'

export default combineReducers({
  index: counterparties,
  contractors,
  suppliers,
  customers,
  contacts,
  invites,
  common
})
