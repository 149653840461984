import axios from "axios";

import { ILearningItem } from "./types";
import { IList } from "types/ListResponse";

export const learningApi = {
  get: () => {
    return axios.get<IList<ILearningItem>>("/education/user_tasks/custom/", { params: { limit: 500 } });
  },
};
