import { message } from "antd";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { loadDetailedProjectForecast } from "../../redux/modules/common/constructing/finance/forecast/thunks";

import { useObjectId } from "../../components/pages/Documents/hooks/useObjectId";

import FinanceSettingsModalContainer from "./ui/FinanceSettingsModal/FinanceSettingsModalContainer";
import {
  deleteFinanceAdvancePayment,
  deleteFinanceWarranties,
  getFinanceAdvancePayments,
  getFinanceProjectAdvancePayments,
  getFinanceWarranties,
  patchFinanceAdvancePayment,
  patchFinanceWarranties,
  postFinanceAdvancePayment,
  postFinanceProjectAdvancePayments,
  postFinanceWarranties,
} from "widgets/FinanceSettingsModal/api/FinanceSettingsApi";

import { IAdvancePaymentItem, IGuaranteeObligationItem } from "./types";

import { prepareAdvancePaymentItem, prepareGuaranteeObligations } from "./utils";
import { beautifyNumberValue } from "utils/formatters/beautifyNumberValue";
import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { errorCatcher } from "utils/helpers/errorCatcher";

export interface IFinanceSettingsModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  buildingId: number;
}

const FinanceSettingsModal = ({ isModalOpen, onClose, buildingId }: IFinanceSettingsModalProps) => {
  const dispatch = useDispatch();

  const { objectId } = useParams();
  const [buildingAmount, setBuildingAmount] = useState(0);

  const [guaranteeObligationsItems, setGuaranteeObligationsItems] = useState<IGuaranteeObligationItem[]>([]);
  const [advancePaymentItem, setAdvancePaymentItem] = useState<IAdvancePaymentItem[]>([]);

  const [deletedItemsIds, setDeletedItemsIds] = useState<number[]>([]);
  const [deletedAdvancePaymentItem, setDeletedAdvancePaymentItem] = useState<number[]>([]);

  const [advancePaymentPercent, setAdvancePaymentPercent] = useState("0");
  const [advancePaymentAmount, setAdvancePaymentAmount] = useState("0");

  const [isAdvancePaymentToched, setIsAdvancePaymentToched] = useState(false);
  const [canCaluculateSumAdvance, setCanCaluculateSumAdvance] = useState(false);

  const closeModalAndClearStates = () => {
    setBuildingAmount(0);
    setGuaranteeObligationsItems([]);
    setDeletedItemsIds([]);
    setAdvancePaymentItem([]);
    setAdvancePaymentAmount("0");
    setAdvancePaymentPercent("0");
    setDeletedAdvancePaymentItem([]);
    setIsAdvancePaymentToched(false);
    setCanCaluculateSumAdvance(false);
    onClose();
  };

  const sumGuarantee = useMemo(
    () => guaranteeObligationsItems.reduce((acc, curr) => acc + +curr.amount, 0),
    [guaranteeObligationsItems]
  );

  const sumAdvance = useMemo(() => {
    const result = advancePaymentItem.reduce((acc, curr) => acc + +curr.amount, 0);
    if (result > +advancePaymentAmount && canCaluculateSumAdvance) {
      message.warn("Сумма выплат превышает авансирование проекта");
    }
    return result;
  }, [canCaluculateSumAdvance, advancePaymentAmount, advancePaymentItem]);

  const isShowButtons = useMemo(() => {
    const newOrTouched = [...guaranteeObligationsItems, ...advancePaymentItem].filter((el) => {
      return el.id < 1 || el.touched;
    });
    return (
      !!newOrTouched.length || isAdvancePaymentToched || !!deletedAdvancePaymentItem.length || !!deletedItemsIds.length
    );
  }, [guaranteeObligationsItems, advancePaymentItem, isAdvancePaymentToched]);

  const sumGuaranteePercent = useMemo(
    () => guaranteeObligationsItems.reduce((acc, curr) => acc + +curr.percent, 0),
    [guaranteeObligationsItems]
  );

  useEffect(() => {
    if (isModalOpen) {
      getFinanceWarranties(buildingId)
        .then((data) => setGuaranteeObligationsItems(prepareGuaranteeObligations(data)))
        .catch(errorCatcher);
      getFinanceProjectAdvancePayments(buildingId)
        .then((res) => {
          const amount = dropNonSignificantZeros(res.amount);
          const percent = dropNonSignificantZeros(res.percent);
          setBuildingAmount(+res.building_amount);
          setAdvancePaymentAmount(String(amount));
          setAdvancePaymentPercent(String(percent));
          getFinanceAdvancePayments(buildingId)
            .then((result) => {
              setAdvancePaymentItem(prepareAdvancePaymentItem(result.results, +res.building_amount));
            })
            .catch(errorCatcher);
        })
        .catch(errorCatcher);
    }
  }, [isModalOpen, buildingId]);

  const handleAddItem = useCallback(() => {
    setGuaranteeObligationsItems((prev) => [
      ...prev,
      { id: Math.random(), amount: "0", percent: "0", type: "", delay: 0 },
    ]);
  }, [guaranteeObligationsItems]);

  const handleDeleteItem = useCallback(
    (id: number) => {
      if (id >= 1) {
        setDeletedItemsIds((prev) => [...prev, id]);
      }
      setGuaranteeObligationsItems((prev) => prev.filter((el) => el.id !== id));
    },
    [guaranteeObligationsItems, deletedItemsIds]
  );

  const handleDeleteAdvancePaymentItem = useCallback(
    (id: number) => {
      if (id >= 1) {
        setDeletedAdvancePaymentItem((prev) => [...prev, id]);
      }
      setAdvancePaymentItem((prev) => prev.filter((el) => el.id !== id));
    },
    [advancePaymentItem]
  );

  const handleChangeAdvancePaymentPercent = useCallback(
    (value: string) => {
      if (+value > 100) {
        return message.warn("Авансирование проекта не может превышать 100% бюджета");
      }
      if (+sumAdvance > (+value * buildingAmount) / 100) message.warn("Сумма выплат превышает авансирование проекта");

      const procValue = [',', '.'].includes(value?.at(-1)) ? value : +value;

      setAdvancePaymentPercent(beautifyNumberValue(procValue, 8));
      setAdvancePaymentAmount(beautifyNumberValue(Math.round(+procValue * buildingAmount) / 100));
      if (+value > 0 && !advancePaymentItem.length) {
        handleAddAdvancePaymentItem();
      }
      if (+value <= 0 && advancePaymentItem.length) {
        advancePaymentItem.forEach((el) => handleDeleteAdvancePaymentItem(el.id));
        setAdvancePaymentItem([]);
      }
      setIsAdvancePaymentToched(true);
    },
    [advancePaymentItem, buildingAmount]
  );

  const handleChangeAdvancePaymentAmount = useCallback(
    (value: string) => {
      if (+value / buildingAmount > 1) {
        return message.warn("Авансирование проекта не может превышать 100% бюджета");
      }
      setAdvancePaymentAmount(beautifyNumberValue(value));
      setAdvancePaymentPercent(beautifyNumberValue((+value / buildingAmount) * 100, 8));
      if (+value > 0 && !advancePaymentItem.length) {
        handleAddAdvancePaymentItem();
      }
      if (+value <= 0 && advancePaymentItem.length) {
        advancePaymentItem.forEach((el) => handleDeleteAdvancePaymentItem(el.id));
        setAdvancePaymentItem([]);
      }
      if (+value > buildingAmount) {
        message.warn("Авансирование проекта не может превышать 100% бюджета");
      }
      setIsAdvancePaymentToched(true);
    },
    [advancePaymentItem, buildingAmount]
  );

  const handleChangeAmountValue = useCallback(
    (value: string, id: number) => {
      if (+value / buildingAmount >= 1) {
        return message.warn("Гарантийные обязательства не могут быть равны 100% бюджета");
      }
      const prettyValue = beautifyNumberValue(value);
      setGuaranteeObligationsItems((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                touched: true,
                amount: prettyValue,
                percent: beautifyNumberValue((+value / buildingAmount) * 100, 8),
              }
            : el
        )
      );
    },
    [guaranteeObligationsItems]
  );

  const handleChangePercentValue = useCallback(
    (value: string, id: number) => {
      if (+value >= 100) return message.warn("Гарантийные обязательства не могут быть равны 100% бюджета");

      const procValue = [',', '.'].includes(value?.at(-1)) ? value : +value;

      setGuaranteeObligationsItems((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                touched: true,
                percent: beautifyNumberValue(procValue, 8),
                amount: beautifyNumberValue(Math.round(+procValue * buildingAmount) / 100),
              }
            : el
        )
      );
    },
    [guaranteeObligationsItems]
  );

  const handleSelectSubmit = useCallback(
    (optionId: string | number, id: number) => {
      setGuaranteeObligationsItems((prev) =>
        prev.map((el) => (el.id === id ? { ...el, touched: true, type: String(optionId), delay: 0 } : el))
      );
    },
    [guaranteeObligationsItems]
  );

  const handleChangeDelay = useCallback(
    (value: string, id: number) => {
      setGuaranteeObligationsItems((prev) =>
        prev.map((el) => (el.id === id ? { ...el, touched: true, delay: +value } : el))
      );
    },
    [guaranteeObligationsItems]
  );

  const isDisabledSaveButton = useMemo(() => {
    const canSaveGuarantee = guaranteeObligationsItems.every(
      (item) => Number(item.percent) > 0 && item.type && (item.type === "delay" ? item.delay : true)
    );
    const canSaveAdvance = advancePaymentItem.every((el) => el.date && +el.amount > 0);
    const canSaveAdvansed = advancePaymentItem.reduce((acc, curr) => acc - +curr.percent, +advancePaymentPercent) === 0;
    return (
      !canSaveGuarantee ||
      !canSaveAdvance ||
      +advancePaymentAmount > buildingAmount ||
      +advancePaymentPercent > 100 ||
      +sumGuaranteePercent >= 100 ||
      +sumAdvance > +advancePaymentAmount ||
      !canSaveAdvansed
    );
  }, [
    advancePaymentAmount,
    buildingAmount,
    advancePaymentPercent,
    sumGuaranteePercent,
    sumAdvance,
    guaranteeObligationsItems,
    advancePaymentItem,
  ]);

  const handleSubmit = useCallback(() => {
    let isNeedToReloadForecast = false;
    const canSaveGuarantee = guaranteeObligationsItems.every(
      (item) => Number(item.percent) > 0 && item.type && (item.type === "delay" ? item.delay : true)
    );
    const canSaveAdvance = advancePaymentItem.every((el) => el.date && +el.amount > 0);
    const canSaveAdvansed = advancePaymentItem.reduce((acc, curr) => acc - +curr.percent, +advancePaymentPercent) === 0;
    if (!canSaveAdvansed) return message.warn("Сумма выплат не равна авансированию");
    if (!canSaveGuarantee) return message.warn("Гарантийные обязательства не заполнены");
    if (!canSaveAdvance) return message.warn("Выплаты не заполнены");
    if (+advancePaymentAmount > buildingAmount || +advancePaymentPercent > 100) {
      return message.warn("Авансирование проекта не может превышать 100% бюджета");
    }
    if (+sumGuaranteePercent >= 100) {
      return message.warn("Гарантийные обязательства не могут быть равны 100% бюджета");
    }

    const promises = [];

    if (+sumAdvance > +advancePaymentAmount) return message.warn("Выплаты превышают авансирование");
    guaranteeObligationsItems.forEach((el) => {
      isNeedToReloadForecast = true;
      if (el.id < 1) {
        promises.push(postFinanceWarranties(buildingId, { percent: el.percent, delay: el.delay }).catch(errorCatcher));
      }
      if (el.id >= 1 && el.touched) {
        promises.push(
          patchFinanceWarranties(buildingId, el.id, { percent: el.percent, delay: el.delay }).catch(errorCatcher)
        );
      }
    });
    deletedItemsIds.forEach((el) => {
      isNeedToReloadForecast = true;
      promises.push(deleteFinanceWarranties(buildingId, el).catch(errorCatcher));
    });

    // Заполняем разницу в копейках между суммой авансового платежа и
    // суммой элементов графика платежей
    // Если проценты аванса и суммы процентов элемента графика платежей
    // сходятся и разница не более 5 копеек, добавляем эти 5 копеек в последнюю дату графика платежей
    const sumAdvancePaymentItemsPercents = dropNonSignificantZeros(Math.round(sumAdvance / buildingAmount * 100), 8);
    const advancePaymentSumDiff = +advancePaymentAmount - +sumAdvance;

    if (
      advancePaymentItem.length
      && +advancePaymentPercent === +sumAdvancePaymentItemsPercents
      && Math.abs(advancePaymentSumDiff) < 0.05
    ) {
      advancePaymentItem[advancePaymentItem.length - 1].amount = (+advancePaymentItem[advancePaymentItem.length - 1].amount + +advancePaymentSumDiff).toFixed(2);
    }

    advancePaymentItem.forEach((el) => {
      isNeedToReloadForecast = true;
      if (el.id < 1) {
        promises.push(
          postFinanceAdvancePayment(buildingId, {
            amount: el.amount,
            date: moment(el.date, "DD.MM.YYYY").format("YYYY-MM-DD"),
          }).catch(errorCatcher)
        );
      }
      if (el.id >= 1 && el.touched) {
        promises.push(
          patchFinanceAdvancePayment(buildingId, el.id, {
            amount: el.amount,
            date: moment(el.date, "DD.MM.YYYY").format("YYYY-MM-DD"),
          }).catch(errorCatcher)
        );
      }
    });
    deletedAdvancePaymentItem.forEach((el) => {
      isNeedToReloadForecast = true;
      promises.push(deleteFinanceAdvancePayment(buildingId, el).catch(errorCatcher));
    });

    if (isAdvancePaymentToched) {
      isNeedToReloadForecast = true;
      promises.push(
        postFinanceProjectAdvancePayments(buildingId, { percent: advancePaymentPercent }).catch(errorCatcher)
      );
    }

    Promise.allSettled(promises).then(() => {
      if (isNeedToReloadForecast) {
        dispatch(loadDetailedProjectForecast(objectId));
      }

      onClose();
    });
  }, [
    guaranteeObligationsItems,
    buildingId,
    deletedItemsIds,
    deletedAdvancePaymentItem,
    advancePaymentItem,
    advancePaymentPercent,
    advancePaymentAmount,
    sumAdvance,
    buildingAmount
  ]);

  const handleChangeAdvancePaymentAmountItem = useCallback(
    (value: string, id: number) => {
      const prettyValue = beautifyNumberValue(value);
      setAdvancePaymentItem((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                touched: true,
                amount: prettyValue,
                percent: beautifyNumberValue(String(+prettyValue / (+buildingAmount / 100))),
              }
            : el
        )
      );
      setCanCaluculateSumAdvance(true);
    },
    [buildingAmount]
  );

  const handleChangeAdvancePaymentPercentItem = useCallback(
    (value: string, id: number) => {
      if (+value > +advancePaymentPercent) return message.warn("Выплата не может превышать авансирование");

      const procValue = [',', '.', '0'].includes(value?.at(-1)) ? value : +value;

      setAdvancePaymentItem((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                touched: true,
                amount: beautifyNumberValue(String(+buildingAmount * (+procValue / 100))),
                percent: beautifyNumberValue(procValue),
              }
            : el
        )
      );
      setCanCaluculateSumAdvance(true);
    },
    [buildingAmount, advancePaymentPercent]
  );

  const handleChangeAdvancePaymentDateItem = useCallback((value: Moment, id: number) => {
    setAdvancePaymentItem((prev) =>
      prev.map((el) => (el.id === id ? { ...el, touched: true, date: value.format("DD.MM.YYYY") } : el))
    );
  }, []);

  const handleAddAdvancePaymentItem = useCallback(() => {
    setAdvancePaymentItem((prev) => [
      ...prev,
      { id: Math.random(), date: moment().format("DD.MM.YYYY"), amount: "0", percent: "0" },
    ]);
  }, []);

  return (
    <FinanceSettingsModalContainer
      isModalOpen={isModalOpen}
      closeModalAndClearStates={closeModalAndClearStates}
      guaranteeObligation={guaranteeObligationsItems}
      handleDeleteItem={handleDeleteItem}
      handleChangeAmountValue={handleChangeAmountValue}
      handleChangePercentValue={handleChangePercentValue}
      handleChangeDelay={handleChangeDelay}
      handleSelectSubmit={handleSelectSubmit}
      handleAddItem={handleAddItem}
      sumGuarantee={sumGuarantee}
      advancePaymentPercent={advancePaymentPercent}
      advancePaymentAmount={advancePaymentAmount}
      handleChangeAdvancePaymentAmount={handleChangeAdvancePaymentAmount}
      handleChangeAdvancePaymentPercent={handleChangeAdvancePaymentPercent}
      advancePaymentItem={advancePaymentItem}
      sumAdvance={sumAdvance}
      handleChangeAdvancePaymentAmountItem={handleChangeAdvancePaymentAmountItem}
      handleAddAdvancePaymentItem={handleAddAdvancePaymentItem}
      handleDeleteAdvancePaymentItem={handleDeleteAdvancePaymentItem}
      handleChangeAdvancePaymentDateItem={handleChangeAdvancePaymentDateItem}
      handleSubmit={handleSubmit}
      isShowButtons={isShowButtons}
      isDisabledSaveButton={isDisabledSaveButton}
      handleChangeAdvancePaymentPercentItem={handleChangeAdvancePaymentPercentItem}
    />
  );
};

export default React.memo(FinanceSettingsModal);
