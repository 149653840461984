import cn from "classnames";
import moment from "moment";
import React, { useMemo } from "react";

import { ICalendarBackgroundProps } from "../../CalendarBackground";

import { WEEKDAY_BUBBLE_WIDTH_REM } from "../../../../constants";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import { generateWeeksForLine } from "../../../../utils";

import styles from "../../../../Manufacturing.module.scss";

const WeeksBackground: React.FC<ICalendarBackgroundProps> = ({
  year,
  unitHeight,
  constructionDateStart,
  constructionDateEnd,
  unitOffset,
  criticalDates = []
}) => {
  const unitMultiplier = useUnitMultiplier();
  const weeks = generateWeeksForLine(+year, constructionDateStart, constructionDateEnd, criticalDates);

  return (
    <div
      className={styles.weeksLine}
      style={{ height: `${unitHeight * 3}rem`, left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
    >
      {weeks.map((item, index) => {
        const currentDayLineOffset = moment().isBetween(moment(item.week.day(0)), moment(item.week.day(6)))
          ? moment().weekday()
          : undefined;
        return (
          <React.Fragment key={index}>
            <div
              className={cn(styles.weekBackground, {
                [styles.weekendBg]: index % 2 === 0 && !item.isOutOfDates,
                [styles.commonWeekBg]: index % 2 !== 0 && !item.isOutOfDates,
                [styles.outdatedBg]: item.isOutOfDates,
              })}
              style={{
                left: `${index * 7 * unitMultiplier}rem`,
                width: `${7 * unitMultiplier}rem`,
              }}
            />
            {currentDayLineOffset !== undefined && (
              <div
                className={styles.currentLine}
                style={{
                  left: `${(index * 7 + currentDayLineOffset) * unitMultiplier + WEEKDAY_BUBBLE_WIDTH_REM / 2}rem`,
                }}
              />
            )}
            {(item.criticalDayNumber !== null) && (
              <div
                className={cn(styles.criticalLine)}
                style={{
                  left: `${((index * 7) + item.criticalDayNumber) * unitMultiplier}rem`,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(WeeksBackground);
