import axios from "axios";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  detailDataSelector,
  setTimesheetApprovalFile,
  timesheetApprovalSelector,
} from "redux/modules/common/building/object/nowObject";
import { workersCountWorkSelector, workersSelector } from "redux/modules/common/building/workers";

import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import Approval from "./components/Approval/Approval";
import Info from "./components/Info/Info";
import WorkersTable from "./components/WorkersTable/WorkersTable";
import FileBlock from "components/UI/molecules/FileBlock/FileBlock";

import { serializeFile } from "./utils/serializeFile";
import { getLocalizedMonth } from "utils/formatters/getLocalizedMonth";

import timesheetIcon from "../../../../../images/icons/navigation/timesheetIcon.svg";

import styles from "./Timesheet.module.scss";

const Timesheet = ({ buildingId, activeDate, loadMore, workersPaginationLimit }) => {
  const dispatch = useDispatch();
  const building = useSelector(detailDataSelector);
  const workers = useSelector(workersSelector);
  const timesheetApproval = useSelector(timesheetApprovalSelector);
  const workersCountWork = useSelector(workersCountWorkSelector);

  const year = activeDate.year();
  const monthIndex = activeDate.month();
  const monthNumber = monthIndex + 1;

  const chosenMonthAndYearIsFuture = useMemo(
    () => moment().year(year).month(monthIndex).isAfter(moment(), "date"),
    [monthIndex, year]
  );

  const handleSetTimesheetApprovalFile = useCallback(
    ([file]) => {
      compose(dispatch, setTimesheetApprovalFile)({ buildingId, year, month: monthNumber }, file);
    },
    [buildingId, year, monthNumber]
  );

  const deleteTimesheetApprovalFile = useCallback(() => {
    compose(dispatch, setTimesheetApprovalFile)({ buildingId, year, month: monthNumber }, null);
  }, [buildingId, year, monthNumber]);

  const filesForFileBlock = useMemo(() => {
    if (chosenMonthAndYearIsFuture) return [];
    const file = { url: timesheetApproval.file };
    if (!timesheetApproval.file) {
      file.url = `${axios.defaults.baseURL}/building/${buildingId}/t13-download/${year}/${monthNumber}/`;
      file.extension = "xlsx";
    }
    return [serializeFile(file, getLocalizedMonth(monthNumber - 1), year)];
  }, [timesheetApproval.file, monthNumber, year, chosenMonthAndYearIsFuture]);

  const filteredWorkers = useMemo(() => workers.results.filter((worker) => worker.is_in_timesheet), [workers.results]);

  if (!filteredWorkers?.length) return <EmptyPlaceholder img={timesheetIcon} />;

  return (
    <div className={styles.container}>
      <div className={styles.topBlock}>
        <Info
          docNumber={timesheetApproval.doc_number}
          status={timesheetApproval.status}
          brandName={building.entity_name}
          departmentTitle={timesheetApproval.department_title}
        />
        <div>
          <FileBlock
            files={filesForFileBlock}
            addFiles={handleSetTimesheetApprovalFile}
            deleteFile={deleteTimesheetApprovalFile}
            isDisabled
            mustConfirmDownload
          />
        </div>
      </div>
      <WorkersTable
        workers={workers}
        filteredWorkers={filteredWorkers}
        loadMore={loadMore}
        workersLimit={workersPaginationLimit}
        monthIndex={monthIndex}
        year={year}
        workersCountWork={workersCountWork}
      />
      <Approval year={year} monthNumber={monthNumber} buildingId={buildingId} employees={building.employees} />
    </div>
  );
};

export default React.memo(Timesheet);
