import { Dispatch } from "redux";

import { RootState } from "../../../../../rootReducer";
import { prepareProjectV2FinanceTotals, setSubtreeIsOpen } from "../financeCommonUtils";
import { summaryActions } from "./actions";
import {
  allProjectsSummarySelector,
  detailedProjectSummarySelector,
  objectIdSelector,
  summaryTreeSelector,
} from "./selectors";
import { summaryApi } from "./summaryApi";

import { ISummaryInitialState, ISummaryTree } from "./types";
import { IIdAndName } from "types/idAndName";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";
import { produceMapSummaryParentProjectFn, produceMapSummaryProjectFn } from "./utils";

export const syncSummaryTree =
  (objectId?: number, projectId?: number | string) => (dispatch: Dispatch, getState: () => RootState) => {
    const allProjectsSummaryList = allProjectsSummarySelector(
      getState()
    ) as ISummaryInitialState["allProjectsSummaryList"];
    const detailedProjectSummary = detailedProjectSummarySelector(
      getState()
    ) as ISummaryInitialState["detailedProjectSummary"];

    const pageObjectId = objectIdSelector(getState());
    const prevTree = summaryTreeSelector(getState()) as ISummaryTree[];
    const mapProject = produceMapSummaryParentProjectFn(
      detailedProjectSummary,
      +(objectId || pageObjectId || -1),
      projectId
    );

    if (!prevTree?.length) {
      const tree = allProjectsSummaryList?.map(mapProject);
      dispatch(summaryActions.setSummaryTree(tree));
    } else {
      if (projectId && objectId) {
        const tree = prevTree.map((projectTree) => {
          if (projectTree.id !== +projectId) return projectTree;
          const updateCandidate = allProjectsSummaryList.find((p) => p.id === +projectId);
          if (!updateCandidate) return projectTree;
          return mapProject(updateCandidate);
        });
        dispatch(summaryActions.setSummaryTree(tree));
        return;
      }
      if (!objectId) return;
      const tree = prevTree.map((projectTree) => {
        if (projectTree.id !== +objectId) return projectTree;
        const updateCandidate = allProjectsSummaryList.find((p) => p.id === +objectId);
        if (!updateCandidate) return projectTree;
        return mapProject(updateCandidate);
      });
      dispatch(summaryActions.setSummaryTree(tree));
    }
  };

export const loadAllProjectsSummary = () => (dispatch: Dispatch) => {
  dispatch(summaryActions.setLoading(true));
  summaryApi
    .getProjectsViewSummary()
    .then(({ data }) => {
      dispatch(summaryActions.setAllProjectsSummaryList(data));
      if (data.length === 1) {
        dispatch(loadDetailedProjectSummary(data[0].id));
      } else {
        dispatch(syncSummaryTree());
      }
    })
    .catch(errorCatcher)
    .finally(() => dispatch(summaryActions.setLoading(false)));
};

export const loadDetailedProjectSummary =
  (objectId: number, projectId?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    if (getState().constructing.finance.summary.isLoadingByObjects[objectId] === undefined) {
      dispatch(summaryActions.setIsLoadingByObject(true, objectId));
    }
    return summaryApi
      .getDetailedProjectSummary(objectId)
      .then(({ data }) => {
        dispatch(summaryActions.setDetailedProjectSummary(objectId, data));
        dispatch(syncSummaryTree(objectId, projectId));
      })
      .catch(errorCatcher)
      .finally(() => dispatch(summaryActions.setIsLoadingByObject(false, objectId)));
  };

export const toggleOpenSummaryTree =
  (subTree: ISummaryTree) => async (dispatch: Dispatch, getState: () => RootState) => {
    const detailedProjectSummary = detailedProjectSummarySelector(
      getState()
    ) as ISummaryInitialState["detailedProjectSummary"];
    const allProjectsSummaryList = allProjectsSummarySelector(
      getState()
    ) as ISummaryInitialState["allProjectsSummaryList"];
    if (subTree.isRoot && !detailedProjectSummary[subTree.id]) {
      const projectId = allProjectsSummaryList?.find((pr) => pr.buildings.some((b) => b.id === subTree.id)).id;
      await dispatch(loadDetailedProjectSummary(subTree.id, projectId));
      return;
    }
    const prevTree = summaryTreeSelector(getState()) as ISummaryTree[];
    const prevTreeCopy = Object.assign([], prevTree);
    setSubtreeIsOpen(prevTreeCopy, subTree, !subTree.isOpen);
    dispatch(summaryActions.setSummaryTree(prevTreeCopy));
  };

export const downloadSummaryReport = (buildigId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const buildingName = getState().objects.objectsBreadcrumbs?.results?.find(
    (el: IIdAndName) => el.id == buildigId
  )?.name;
  const reportName = buildingName ? `Сводная по ${buildingName}.xlsx` : "Сводная.xlsx";
  summaryApi.downloadReport(buildigId, reportName);
};

//////////////////////////////////////////////////////////

export const getSummaryDetailedProjectV2 =
  (projectId: string | number, objectId: string | number) => (dispatch: Dispatch, getState: () => RootState) => {
    /// TODO loading by projects
    if (getState().constructing.finance.summary.isLoadingByObjects[projectId] === undefined) {
      dispatch(summaryActions.setIsLoadingByObject(true, +projectId));
    }
    return summaryApi
      .getDetailedProjectSummaryV2(+projectId)
      .then(({ data }) => {
        dispatch(summaryActions.setDetailedByProjectV2(String(projectId), data[0]));
        if (data[0].m_data && data[0].q_data) {
          dispatch(summaryActions.setProjectsV2Totals(prepareProjectV2FinanceTotals(data[0]), projectId as string));
        }
        if (data[0]?.buildings.length === 1) {
          dispatch(loadDetailedProjectSummary(data[0].buildings[0].id) as any);
        }
        if (!objectId || +objectId === 0) {
          dispatch(syncSummaryTree(-1, projectId) as any);
        }
      })
      .catch(errorCatcher)
      .finally(() => {
        // dispatch(auditActions.setIsLoadingByObject(false, +projectId));
      });
  };
