import React from "react";

import EmployeesElem from "../../../../../UI/molecules/EmployeesElem";
import { AddButton } from "../../../../../UI/AddButton/AddButton";

import styles from "./EditProjectPersonCell.module.scss";

function EditProjectPersonCell({ employee, label, onAdd, onDelete, canDelete }) {
  const isExist = !!employee;

  return (
    <div className={styles.container}>
      <div className={styles.headline}>
        <div className={styles.label}>{label}</div>
        {isExist && <div className={styles.change} onClick={onAdd}>изменить</div>}
      </div>
      {isExist ? (
        <EmployeesElem
          employee={employee}
          deleteEmployee={onDelete}
          editPermission={canDelete}
        />
      ) : (
        <AddButton data-name={label} onClick={onAdd}>Добавить</AddButton>
      )}
    </div>
  );
}

export default React.memo(EditProjectPersonCell);