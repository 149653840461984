import cn from "classnames";
import React from "react";

import PopoverOverlay from "../../../../../../../../UI/molecules/PopoverOverlay/PopoverOverlay";

import styles from "./BraceTooltip.module.scss";

export interface IBraceTooltipProps {
  isOpen: boolean;
  setIsOpen: (_: boolean) => void;
  children: React.ReactNode;
  label: string;
}

const BraceTooltip = React.forwardRef<HTMLElement, IBraceTooltipProps>(
  ({ children, isOpen, setIsOpen, label }, ref) => {
    const handleMouseLeave = React.useCallback(() => setIsOpen(false), [setIsOpen]);
    return (
      <PopoverOverlay
        ref={ref}
        isOpen={isOpen}
        placement="top"
        popoverBorderColor={"primary"}
        className={styles.popoverContainer}
        portalClassName={styles.portal}
        content={
          <div className={styles.tooltip} onMouseLeave={handleMouseLeave}>
            {label}
          </div>
        }
      >
        {children}
      </PopoverOverlay>
    );
  }
);

export default React.memo(BraceTooltip);
