import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { learningApi } from "./api";
import { learningActions } from "./learningSlice";

export const getLearning = () => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().learning.data?.results?.length) {
    dispatch(learningActions.setIsLoading(true));
  }
  learningApi
    .get()
    .then(({ data }) => {
      dispatch(
        learningActions.setData({
          ...data,
          results: data.results.map((el, i, arr) => ({
            ...el,
            menuTitle: `Задание ${i + 1}`,
            isDisabled: !!arr[i - 1] && !el.is_confirmed && (arr[i - 1].isDisabled || !arr[i - 1].is_confirmed),
          })),
        })
      );
    })
    .finally(() => {
      dispatch(learningActions.setIsLoading(false));
    });
};
