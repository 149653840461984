import React, { useEffect, useState } from "react";
import { Field, FieldInputProps } from "react-final-form";
import ReactTextareaAutosize from "react-textarea-autosize";

import { AookPermitsEnum } from "redux/modules/common/building/journal/aook/types";

import Checkbox from "components/UI/Checkbox";
import InputBase, { VALUE_TYPES } from "components/UI/atoms/InputBase";

import { required } from "utils/formHelpers/validations";

import styles from "./AookPermits.module.scss";

interface IProps {
  isDisabled?: boolean;
  initialPartiallyPermit?: string;
}

const AookPermits: React.FC<IProps> = ({ isDisabled, initialPartiallyPermit }) => {
  const [isOnCondition, setIsOnCondition] = useState(false);
  const [isPartially, setIsPartially] = useState(!!initialPartiallyPermit);
  const [partiallyPermit, setPartiallyPermit] = useState<string>('');

  useEffect(() => {
    if (initialPartiallyPermit) {
      setPartiallyPermit(`${AookPermitsEnum.partially}/${initialPartiallyPermit}`);
    }
  }, [initialPartiallyPermit]);

  const getPartiallyPermitPercent = (str: string) => {
    if (!isPartially) {
      return undefined;
    } else {
      const splitted = str.split('/');
      const percent = splitted?.[1];
      return percent ?? '';
    }
  }

  return (
    <div>
      <Field
        name="permit"
        render={({ input, meta }) => (
          <div className={styles.row}>
            <div className={styles.label}>Разрешается использование с нагрузкой в</div>
            <div className={styles.percents}>
              <InputBase
                disabled={isDisabled}
                valueType={VALUE_TYPES.NUMBER}
                defaultValue={initialPartiallyPermit}
                className={styles.inputPercents}
                value={getPartiallyPermitPercent(partiallyPermit)}
                onChange={(e: any) => {
                  const newPermitValue = `${AookPermitsEnum.partially}/${e.target.value}`;
                  input.onChange(newPermitValue);
                  setPartiallyPermit(newPermitValue);
                  setIsOnCondition(false);
                  setIsPartially(true);
                }}
              />
              %
            </div>
          </div>
        )}
      />

      <Field
        name="permit"
        render={({ input }) => (
          <div className={styles.row}>
            <div className={styles.label}>Разрешается использование конструкций по назначению</div>
            <Checkbox
              disabled={isDisabled}
              checked={input.value === AookPermitsEnum.use || !input.value}
              onChange={(e) => {
                if (e.target.checked) {
                  input.onChange(AookPermitsEnum.use);
                  setIsOnCondition(false);
                  setIsPartially(false);
                }
              }}
            />
          </div>
        )}
      />

      <Field
        name="permit"
        render={({ input }) => (
          <div className={styles.row}>
            <div className={styles.label}>Разрешается использование при условиях</div>
            <Checkbox
              disabled={isDisabled}
              checked={input.value === AookPermitsEnum.on_condition}
              onChange={(e) => {
                setIsOnCondition(e.target.checked);
                if (e.target.checked) {
                  input.onChange(AookPermitsEnum.on_condition);
                  setIsPartially(false);
                }
              }}
            />
          </div>
        )}
      />

      <Field
        name="permit_condition"
        validate={isOnCondition ? required() : undefined}
        render={({ input, meta }) => (
          <>
            <ReactTextareaAutosize
              disabled={isDisabled}
              value={input.value}
              key={String(meta.submitting)}
              onChange={input.onChange}
              minRows={3}
              placeholder="Укажите условия"
              className={isOnCondition && !input.value ? styles.err : ""}
            />
            {isOnCondition && !input.value && <span className={styles.errorText}>Введите условия</span>}
          </>
        )}
      />
    </div>
  );
};

export default AookPermits;
