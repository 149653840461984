import { IJournalAcceptanceAction } from "./actions";

import { IJournalAcceptanceState } from "./types";

const initialState: IJournalAcceptanceState = {
  sections: {},
  sectionsIsLoading: {},
  tickets: {},
  ticketsIsLoading: {},
  approvals: {},
  history: {},
  historyLoading: {},
  sharedContractors: {},
  detailedTickets: {},
};

export default (state = initialState, action: IJournalAcceptanceAction): IJournalAcceptanceState => {
  const { payload, type } = action;

  switch (type) {
    case "journalAcceptance/SET_SECTIONS":
      return {
        ...state,
        sections: {
          ...state.sections,
          [payload.key]: payload.sections,
        },
      };
    case "journalAcceptance/SET_SECTIONS_IS_LOADING":
      return {
        ...state,
        sectionsIsLoading: {
          ...state.sectionsIsLoading,
          [payload.key]: payload.status,
        },
      };
    case "journalAcceptance/SET_TICKETS":
      return {
        ...state,
        tickets: {
          ...state.tickets,
          [payload.key]: payload.tickets,
        },
      };
    case "journalAcceptance/SET_TICKETS_IS_LOADING":
      return {
        ...state,
        ticketsIsLoading: {
          ...state.ticketsIsLoading,
          [payload.key]: payload.status,
        },
      };
    case "journalAcceptance/SET_APPROVALS":
      return {
        ...state,
        approvals: {
          ...state.approvals,
          [payload.ticketId]: payload.approvals,
        },
      };
    case "journalAcceptance/SET_HISTORY":
      return {
        ...state,
        history: {
          ...state.history,
          [payload.ticketId]: payload.history,
        },
      };
    case "journalAcceptance/SET_HISTORY_LOADING":
      return {
        ...state,
        historyLoading: {
          ...state.historyLoading,
          [payload.ticketId]: payload.status,
        },
      };
    case "journalAcceptance/SET_SHARED_CONTRACTORS":
      return {
        ...state,
        sharedContractors: {
          ...state.sharedContractors,
          [payload.buildingId]: payload.contractors,
        },
      };
    case "journalAcceptance/SET_DETAILED_TICKET":
      return {
        ...state,
        detailedTickets: {
          ...state.detailedTickets,
          [payload.ticketId]: payload.ticket,
        },
      };
    case "journalAcceptance/UPDATE_TICKET_SIMPLE_RESOURCES":
      const oldSimpleResources = state.detailedTickets[payload.ticketId].simple_resources;

      const newSimpleResources = oldSimpleResources
        .filter((r) => !payload.deleted.includes(r.id))
        .map((r) => {
          const updatedIdx = payload.updated.findIndex((ur) => ur.id === r.id);

          if (updatedIdx !== -1) {
            return payload.updated[updatedIdx];
          }

          return r;
        })

      payload.created.forEach(r => {
        newSimpleResources.unshift(r);
      })

      return {
        ...state,
        detailedTickets: {
          ...state.detailedTickets,
          [payload.ticketId]: {
            ...state.detailedTickets[payload.ticketId],
            simple_resources: newSimpleResources
          }
        }
      }
    default:
      return state;
  }
};
