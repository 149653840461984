import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ILearningItem } from "./types";
import { IList } from "types/ListResponse";

export interface LearningState {
  isLoading: boolean;
  data: IList<ILearningItem> | null;
}

const initialState: LearningState = {
  isLoading: false,
  data: null,
};

const learningSlice = createSlice({
  name: "learning",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const learningActions = learningSlice.actions;

export default learningSlice.reducer;
