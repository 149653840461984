import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeTaskModal, openTaskModal, setTaskModalType } from "../../../../redux/modules/purchaser/Tasks/actions";
import { taskModalSelector } from "../../../../redux/modules/purchaser/Tasks/selectors";
import { deleteFilesFromTaskEditBuffer, getOneTask } from "../../../../redux/modules/purchaser/Tasks/thunks";
import { TaskPlacementType } from "../../../../redux/modules/purchaser/Tasks/types";

import { TASK_LISTS_TYPES, TASK_MODAL_TYPES } from "../constants";

export interface IOpenModal {
  placementType: TaskPlacementType;
  listType?: TASK_LISTS_TYPES;
  list_id?: number;
}

export interface IOpenModalId extends IOpenModal {
  id: number;
}

export const useTasksModal = () => {
  const dispatch = useDispatch();
  const { isOpen, type, taskId, placementType, listType, list_id, submission } = useSelector(taskModalSelector);

  const onClose = useCallback(() => {
    dispatch(closeTaskModal());
  }, [isOpen]);

  const deleteFilesOnCancel = useCallback(() => {
    dispatch(deleteFilesFromTaskEditBuffer());
  }, []);

  const openModal = useCallback(
    ({
      modalType,
      placementType,
      listType,
      id,
      list_id,
    }: {
      modalType: TASK_MODAL_TYPES;
      placementType: TaskPlacementType;
      listType?: TASK_LISTS_TYPES;
      id?: number;
      list_id?: number;
    }) => {
      dispatch(openTaskModal({ modalType, placementType, listType, id, list_id }));
    },
    [placementType, listType]
  );

  const changeModalType = useCallback((newModalType: TASK_MODAL_TYPES) => {
    dispatch(setTaskModalType(newModalType));
  }, []);

  return {
    deleteFilesOnCancel,
    onClose,
    type,
    isOpen,
    openModal,
    taskId,
    openAddModal: ({ placementType, listType, list_id }: IOpenModal) =>
      openModal({
        modalType: TASK_MODAL_TYPES.ADD,
        placementType,
        listType,
        list_id,
      }),
    openViewModal: ({ placementType, listType, id, list_id }: IOpenModalId) =>
      openModal({
        modalType: TASK_MODAL_TYPES.VIEW,
        placementType,
        listType,
        list_id,
        id,
      }),
    openEditModal: ({ placementType, listType, id, list_id }: IOpenModalId) =>
      openModal({
        modalType: TASK_MODAL_TYPES.EDIT,
        placementType,
        listType,
        id,
        list_id,
      }),
    placementType,
    listType,
    changeModalType,
    list_id,
    submission,
  };
};
