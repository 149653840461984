import { message } from "antd";
import axios from "axios";
import { createSelector } from "reselect";

import { filterNotConfirmedTerms } from "../../../../../../../components/pages/Constructing/Counterparties/utils/filterNotConfirmedTerms";

import { contactsStateSelector } from "../contacts";
import { loadContacts } from "../list/list";

import { errorCatcher } from "../../../../../../../utils/helpers/errorCatcher";

const initialState = {
  isLoading: true,
  contact: null,
  form: {
    data: null,
  },
  deleteModalOpen: false,
};

const moduleName = "item";
const actionsPrefix = "constructing/counterparties/contacts/item";

const SET_LOADING = `${actionsPrefix}_SET_LOADING`;
const SET_CONTACT = `${actionsPrefix}_SET_CONTACT`;
const SET_FORM_INIT_DATA = `${actionsPrefix}_SET_FORM_INIT_DATA`;
const SET_FORM_DATA = `${actionsPrefix}_SET_FORM_DATA`;
const SET_DELETE_MODAL_STATE = `${actionsPrefix}_SET_DELETE_MODAL_STATE`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;

export const stateSelector = createSelector(contactsStateSelector, (state) => state[moduleName]);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const contactSelector = createSelector(stateSelector, (state) => state.contact);
export const formDataSelector = createSelector(stateSelector, (state) => state.form.data);
export const deleteModalOpenSelector = createSelector(stateSelector, (state) => state.deleteModalOpen);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_CONTACT:
      return {
        ...state,
        contact: payload,
      };
    case SET_FORM_INIT_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          data: payload,
        },
      };
    case SET_FORM_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            [payload.field]: payload.value,
          },
        },
      };
    case SET_DELETE_MODAL_STATE:
      return {
        ...state,
        deleteModalOpen: payload,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const setContact = (data) => ({
  type: SET_CONTACT,
  payload: data,
});

export const setFormInitData = (data) => ({
  type: SET_FORM_INIT_DATA,
  payload: data,
});

export const setFormData = (data) => ({
  type: SET_FORM_DATA,
  payload: data,
});

export const loadContact = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.get(`/partnership/virtual-providers/${id}/`);
      dispatch(setContact(response.data));
      dispatch(setFormInitData(response.data));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setDeleteModalState = (data) => ({
  type: SET_DELETE_MODAL_STATE,
  payload: data,
});

export const updateContact = (updatedContact) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.put(`/partnership/virtual-providers/${updatedContact.id}/`, {
        ...updatedContact,
        delivery_terms: filterNotConfirmedTerms(updatedContact.delivery_terms),
      });
      dispatch(setContact(response.data));
      dispatch(setFormInitData(response.data));
      message.success("Успешно сохранено");
      dispatch(loadContacts());
    } catch (e) {
      errorCatcher(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteContact = () => (dispatch, getState) => {
  const contact = contactSelector(getState());

  try {
    axios.delete(`/partnership/virtual-providers/${contact.id}/`).then(() => dispatch(loadContacts()));
  } catch (e) {
    errorCatcher(e);
  }
};

export const clearData = () => ({
  type: CLEAR_DATA,
});
