import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  actingExpendituresSelector,
  actingInvalidateKeySelector,
} from "redux/modules/common/building/journal/acting/selectors";
import { getActingExpenditures } from "redux/modules/common/building/journal/acting/thunks";

import { Spinner } from "components/UI/Spinner/Spinner";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import JournalActingExpenditure from "../common/JournalActingExpenditure/JournalActingExpenditure";

import { LOCALIZATION_CONFIG } from "constants/localization";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalActingSectionsLevel.module.scss";

interface IProps {
  sectionId: number;
  estimateId: number;
}

const JournalActingSectionContent: React.FC<IProps> = ({ sectionId, estimateId }) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const expenditures = useSelector(actingExpendituresSelector)[String(sectionId)];
  const cacheKey = useSelector(actingInvalidateKeySelector);

  React.useEffect(() => {
    dispatch(getActingExpenditures(objectId, sectionId, estimateId));
  }, [sectionId, objectId, estimateId, cacheKey]);

  return (
    <>
      <TableReusableRow
        isHoverable={false}
        className={styles.expendituresHeader}
        containerClassName={styles.expHeaderContainer}
      >
        <TableReusableCell className={styles.number} isRighted>
          №
        </TableReusableCell>
        <TableReusableCell isCentered>Наименование</TableReusableCell>
        <TableReusableCell isCentered>Ед.изм.</TableReusableCell>
        <TableReusableCell isCentered>Сдано</TableReusableCell>
        <TableReusableCell isCentered>Остаток</TableReusableCell>
        <TableReusableCell isCentered>К сдаче</TableReusableCell>
        <TableReusableCell isRighted>Стоимость, {LOCALIZATION_CONFIG.currency} </TableReusableCell>
        <TableReusableCell isCentered>КС-2</TableReusableCell>
        <TableReusableCell>КС-3</TableReusableCell>
      </TableReusableRow>
      {expenditures?.isLoading && <Spinner />}
      {!expenditures?.isLoading && !expenditures?.results?.length && <EmptyPlaceholder img={jurnalIcon} />}
      {!expenditures?.isLoading &&
        expenditures?.results?.map((el) => (
          <JournalActingExpenditure
            type="estimate"
            expenditure={el}
            key={el.id}
            estimateId={estimateId}
            sectionId={sectionId}
          />
        ))}
    </>
  );
};

export default React.memo(JournalActingSectionContent);
