import {combineReducers} from 'redux'

import constructing from './constructing'
import finance from './finance'
import counterparties from './counterparties'

export const moduleName = 'constructing'

export default combineReducers({
  index: constructing,
  finance,
  counterparties
})
