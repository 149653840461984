import cn from "classnames";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

import { ISectionPlan } from "redux/modules/common/building/processTypes";

import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";
import SectionPlanModal from "components/modals/ManufacturingPlanSectionModal/ManufacturingPlanSectionModal";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { stopEventPropagation } from "../../../../../../utils/helpers/stopEventPropagation";

import { ReactComponent as CalendarBlack } from "images/icons/navigation/calendar-black.svg";

import styles from "./PlanSectionMark.module.scss";

type IProps = ISectionPlan & {
  onRefetchSectionPlan?: (parentId: number) => void;
  isNeedToOpen?: boolean;
  className?: string;
};

const PlanSectionMark: React.FC<IProps> = (props) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const { start_at, end_at, section_id, section_name, className, parent_id } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const diff = React.useMemo(() => {
    return moment(end_at).diff(start_at, "days");
  }, [start_at, end_at]);

  const clickHandler = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      stopEventPropagation(e);
      props.onRefetchSectionPlan?.(props.parent_id!);
      setIsModalOpen(true);
    },
    [props.parent_id, props.onRefetchSectionPlan]
  );

  React.useEffect(() => {
    props.isNeedToOpen && setIsModalOpen(true);
  }, [props.isNeedToOpen]);

  return (
    <>
      <PopoverOverlay
        openType="hover"
        content={
          <div className={styles.content} onClick={clickHandler}>
            <div className={styles.title}>План производства работ</div>
            <div>
              {moment(start_at).format("DD/MM/YYYY")} - {moment(end_at).format("DD/MM/YYYY")}
            </div>
            <div>Продолжительность - {diff + 1} дней </div>
          </div>
        }
        placement="bottom"
        popoverBorderColor="primary"
      >
        <div className={cn(styles.mark, className)} onClick={clickHandler}>
          <CalendarBlack />
        </div>
      </PopoverOverlay>
      <SectionPlanModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          props.onRefetchSectionPlan?.(props.parent_id!);
        }}
        externalData={props}
        objectId={objectId}
        intervaldata={{ id: -1 }}
        sectionId={section_id}
        modalType={!!parent_id ? "section" : "lsr"}
      />
    </>
  );
};

export default PlanSectionMark;
