import { IFinanceTableScrollPosition } from "../financeCommonTypes";
import { formatFinanceTreeViaObjectId } from "../financeCommonUtils";

import { EXPENDITURE_TYPES, EXPENDITURE_TYPES_NAMES } from "../../../../../../constants/constant";
import { IAuditTree } from "../audit/types";
import {
  IFinancePivotSection,
  IFinancePivotSubsection,
  IProjectV2FinanceMData,
  IProjectViewSummary,
  ISummaryTree,
  ITreeFinancePivotExpenditure,
  ITreeFinancePivotGroup,
  ITreeFinancePivotItem,
} from "./types";
import { ISummaryInitialState } from "./types";

const formatSummaryTableSection = (section: ISummaryTree, year: number) => {
  let tableSection = {};
  for (let i = 0; i < 12; i++) {
    const dataAtMoment = section.data?.find((x) => +x.year === +year && x.month === i + 1) || {
      plan: 0,
      fact: 0,
      diff: 0,
    };

    const fact = Number(dataAtMoment.fact);
    const plan = Number(dataAtMoment.plan);
    const diff = Number(dataAtMoment.diff);

    const planFact = {
      [`plan_${i}`]: plan,
      [`fact_${i}`]: fact,
      [`diff_${i}`]: diff,
    };

    tableSection = {
      key: `${section.id}_${section.parentId}_row`,
      ...tableSection,
      ...planFact,
    };
  }
  const totalKeys = Object.keys(section?.q_data || {});
  totalKeys.forEach((el) => {
    const planFact = {
      [`plan_${el}`]: section?.q_data?.[el]?.plan,
      [`fact_${el}`]: section?.q_data?.[el]?.fact,
      [`diff_${el}`]: section?.q_data?.[el]?.diff,
    };
    tableSection = {
      ...tableSection,
      ...planFact,
    };
  });
  return tableSection;
};

const prepareSummarySections = (sections: ISummaryTree[] | undefined, year: number) => {
  const preparedSections = [];
  sections?.forEach((section) => {
    preparedSections.push(formatSummaryTableSection(section, year));
    if (section.isOpen) {
      const children = prepareSummarySections(section.children, year);
      preparedSections.push(...children);
    }
  });

  return preparedSections;
};

export const prepareSummaryTableData = (
  sections: ISummaryTree[],
  year: number,
  objectId: number | string | undefined
) => {
  let sectionsToPrepare = formatFinanceTreeViaObjectId(sections, objectId);
  return prepareSummarySections(sectionsToPrepare, year);
};

export const produceMapSummaryParentProjectFn =
  (detailedProjectAudits: any, objectId: number, projectId: string = "-1") =>
  (project: any, _: number, arr: any) => {
    const isProjectAutoExpanded = +projectId === project.id;
    return {
      id: project.id,
      isParentProject: true,
      name: project.name,
      isOpen: isProjectAutoExpanded,
      data: project.data,
      q_data: project?.q_data,
      children: project.buildings.map(produceMapSummaryProjectFn(detailedProjectAudits, objectId)),
    };
  };

export const produceMapSummaryProjectFn =
  (detailedProjectSummary: ISummaryInitialState["detailedProjectSummary"], objectId: number) =>
  (project: IProjectViewSummary, _: number, arr: IProjectViewSummary[]) => {
    const isProjectAutoExpanded = objectId === project.id;
    const allSubSectionsNumber = detailedProjectSummary[project.id]?.sections
      ?.flatMap((s) => s.subsections.length)
      .reduce((acc, v) => acc + v, 0);
    const allExpendituresNumber = detailedProjectSummary[project.id]?.sections
      ?.flatMap((s) => s.subsections)
      .reduce((acc, ss) => acc + Number(!!ss.works) + Number(!!ss.materials) + Number(!!ss.services), 0);
    return {
      id: project.id,
      name: project.name,
      data: project.data,
      q_data: project?.q_data,
      isOpen: isProjectAutoExpanded,
      isRoot: true,
      parentId: null,
      children:
        detailedProjectSummary?.[project.id]?.sections?.map((section: IFinancePivotSection) => {
          const isSectionAutoExpanded = isProjectAutoExpanded && allSubSectionsNumber < 10;
          return {
            id: section.id,
            name: section.name,
            data: section.data,
            q_data: section?.q_data,
            isOpen: isSectionAutoExpanded,
            parentId: project.id,
            children: section.subsections?.map((subsection: IFinancePivotSubsection) => {
              const isSubsectionAutoExpanded = isSectionAutoExpanded && allExpendituresNumber < 10;
              return {
                id: subsection.id,
                name: subsection.name,
                data: subsection.data,
                q_data: subsection?.q_data,
                isOpen: isSubsectionAutoExpanded,
                parentId: section.id,
                children: [subsection.works, subsection.materials, subsection.services]
                  .map((typeExpenditures: ITreeFinancePivotItem, index: number) => {
                    const expenditureTypeName =
                      index === 0
                        ? EXPENDITURE_TYPES_NAMES[EXPENDITURE_TYPES.WORK]
                        : index === 1
                        ? EXPENDITURE_TYPES_NAMES[EXPENDITURE_TYPES.MATERIAL]
                        : index === 2
                        ? "Машины и механизмы"
                        : "Расценки";
                    const groupedExpenditures = typeExpenditures.groups?.flatMap(
                      (group: ITreeFinancePivotGroup) => group.expenditures
                    );
                    const typeExpendituresChildren = (
                      typeExpenditures.expenditures
                        ?.filter(
                          (expenditure) => groupedExpenditures.findIndex((x) => x?.id === expenditure?.id) === -1
                        )
                        ?.map((expenditure: ITreeFinancePivotExpenditure) => ({
                          ...expenditure,
                          id: expenditure.id,
                          name: expenditure.name,
                          parentId: `${subsection.id}_${index}`,
                          data: expenditure.data,
                        })) || []
                    ).concat(
                      typeExpenditures.groups?.map((group: ITreeFinancePivotGroup) => ({
                        id: group.id,
                        name: group.name,
                        data: group.data,
                        isOpen: false,
                        parentId: `${subsection.id}_${index}`,
                        children: group.expenditures?.map((expenditure: ITreeFinancePivotExpenditure) => ({
                          ...expenditure,
                          id: expenditure.id,
                          name: expenditure.name,
                          parentId: subsection.id,
                          data: expenditure.data,
                        })),
                      })) || []
                    );
                    if (!typeExpendituresChildren.length) return undefined;
                    return {
                      id: `${section.id}_${subsection.id}_${index}`,
                      name: expenditureTypeName,
                      isOpen: false,
                      parentId: subsection.id,
                      data: typeExpenditures.data,
                      children: typeExpendituresChildren,
                    };
                  })
                  .filter((x) => x),
              };
            }),
          };
        }) || [],
    };
  };

export const getScrollPositionPercent = (data: number | Partial<IFinanceTableScrollPosition>) => {
  if (typeof data === "number") return data;
  return (data.scrollPosition / data.containerWidth) * 100;
};

export const prepareSummaryTableDataByProjects = (
  sections: IAuditTree[],
  projectId?: string,
  objectId?: string,
  year?: number,
  projectSummaries?: Record<string, Record<string, string>>
) => {
  const projectSummary = projectSummaries?.[projectId!] ?? {};
  let sectionsToPrepare = formatFinanceTreeViaObjectId(sections, +(objectId ?? "-1"), projectId);

  const res = prepareSummarySections(sectionsToPrepare, year!);

  return res;
};
