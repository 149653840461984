import { DiagramFilters, SharedBraceStatusType } from "../../constants";

export const shouldIntervalBeHiddeByFilters = (
  sharedStatus: Omit<SharedBraceStatusType, "processed">,
  diagramFilters: Record<DiagramFilters, boolean>
) => {
  return (
    (sharedStatus === "canceled" && !diagramFilters.canceled_highlight) ||
    (sharedStatus === "confirmed" && !diagramFilters.confirmed_highlight) ||
    (sharedStatus === "moderation" && !diagramFilters.moderation_highlight)
  );
};
