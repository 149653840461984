import React from "react";
import { useSelector } from "react-redux";

import {
  sectionsWithConfirmedChildStatusSelector,
  sectionsWithNewChildStatusSelector,
} from "../../../../../../../../../redux/modules/common/building/sections/sections";

import Section from "../Section/Section";

import { SECTIONS_STATUS } from "../../../../../../../../../constants/constant";

import styles from "./SeparatedByChildStatusSections.module.scss";

const SeparatedByChildStatusSections = ({
  checkerItems,
  handleCheckOnce,
  activeEstimateStateId,
  onClickBySection,
  getSectionActions,
}) => {
  const sectionsWithNewChildStatus = useSelector(sectionsWithNewChildStatusSelector);
  const sectionsWithConfirmedChildStatus = useSelector(sectionsWithConfirmedChildStatusSelector);

  if (!sectionsWithNewChildStatus || !sectionsWithConfirmedChildStatus) return null;
  if (sectionsWithNewChildStatus.length === 0 && sectionsWithConfirmedChildStatus.length === 0) return null;

  return (
    <div className={styles.confirmSections}>
      <div className={styles.sections}>
        <h2 className={styles.title}>
          Требуют утверждения: <span className={styles.count}>{sectionsWithNewChildStatus?.length}</span>
        </h2>
        {sectionsWithNewChildStatus?.map((section) => (
          <div onClick={() => onClickBySection(section.id, SECTIONS_STATUS.NEW)}>
            <Section
              section={section}
              isChecked={checkerItems[section.id]}
              check={handleCheckOnce(section.id)}
              activeEstimateStateId={activeEstimateStateId}
              actions={getSectionActions(section, true)}
              key={section.id}
              isDisplayAmount={false}
            />
          </div>
        ))}
      </div>
      <div className={styles.divider} />
      <div className={styles.sections}>
        <h2 className={styles.title}>
          Утвержденные: <span className={styles.count}>{sectionsWithConfirmedChildStatus?.length}</span>
        </h2>
        {sectionsWithConfirmedChildStatus?.map((section) => (
          <div onClick={() => onClickBySection(section.id, SECTIONS_STATUS.CONFIRMED)}>
            <Section
              section={section}
              isChecked={checkerItems[section.id]}
              check={handleCheckOnce(section.id)}
              activeEstimateStateId={activeEstimateStateId}
              actions={getSectionActions(section, false)}
              canChangeEstimateState
              key={section.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(SeparatedByChildStatusSections);
