import { IupdateTask, TaskPlacementType } from "redux/modules/purchaser/Tasks/types";
import { useDispatch } from "react-redux";
import { updateTask } from "redux/modules/purchaser/Tasks/thunks";
import { compose } from "redux";
import { TASK_LISTS_TYPES, TASK_STATUSES } from "../constants";
import { useCallback, useState } from "react";
import { useTypedSelector } from "redux/typedUseSelector";
import { userSelector } from "redux/modules/common/auth";
import moment from "moment";
import { IIdAndName } from "types/idAndName";
import { useGetTask } from "./useGetTask";
import { closeTaskModal } from "redux/modules/purchaser/Tasks/actions";

export interface IuseUpdateTaskProps {
  taskId: number;
  placementType: TaskPlacementType;
  listType?: TASK_LISTS_TYPES;
  list_id?: number;
}

export const useUpdateTask = ({ taskId, placementType, listType, list_id }: IuseUpdateTaskProps) => {
  const dispatch = useDispatch();
  const { id: currentUserId } = useTypedSelector(userSelector);

  const { certainTask } = useGetTask(taskId);

  const isTaskStatusEditable =
    certainTask &&
    certainTask.status !== TASK_STATUSES.closed.toString() &&
    (certainTask.viewers.includes(currentUserId) ||
      certainTask.executor === currentUserId ||
      certainTask.creator === currentUserId);

  const [building, setBuilding] = useState<IIdAndName>(certainTask?.building_obj);
  const [section_id, setSectionId] = useState<number | undefined>(certainTask?.section);
  const [executor, setExecutor] = useState<string>(`,${certainTask?.executor},`);
  const [viewers, setViewers] = useState<string>(`,${certainTask?.viewers?.join(",")},`);

  const updateTaskStatusDirectly = useCallback(
    (newStatus: keyof typeof TASK_STATUSES) => {
      compose(
        dispatch,
        updateTask
      )({
        id: taskId,
        data: {
          ...certainTask,
          status: newStatus,
        },
        variant: placementType,
        building_id: certainTask.building,
        type: listType,
        list_id: certainTask.lists,
      });
    },
    [certainTask, taskId, listType, placementType]
  );

  const onSubmit = (values: IupdateTask) => {
    const {
      name,
      description,
      priority,
      deadline,
      executor: newExecutor,
      viewers: newViewers,
      remember_task,
      repeat_task,
      status,
    } = values;
    const data = {
      id: taskId,
      name,
      description,
      priority,
      status,
      deadline: moment(deadline).toISOString(),
      building: certainTask.building,
      section: section_id,
      executor: typeof newExecutor === "string" ? newExecutor.split(",").filter((x) => x.length)[0] : newExecutor,
      viewers: typeof newViewers === "string" ? newViewers.split(",").filter((x) => x.length) : newViewers || [],
      lists: list_id,
      creator: certainTask.creator,
    };
    if (remember_task) data["remember_task"] = [remember_task.toString()];
    if (repeat_task) data["repeat_task"] = repeat_task.toString();
    compose(
      dispatch,
      updateTask
    )({
      id: taskId,
      data,
      variant: placementType,
      building_id: certainTask.building,
      type: listType,
      list_id: data.lists,
    });
    dispatch(closeTaskModal());
  };
  const onBuildingChange = useCallback((building: IIdAndName) => {
    setBuilding(building);
    setExecutor("");
    setViewers("");
  }, []);
  return {
    onSubmit,
    building,
    setBuilding: onBuildingChange,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
    certainTask,
    updateTaskStatusDirectly,
    isTaskStatusEditable,
  };
};
