import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  allProjectsSummarySelector,
  detailedProjectSummarySelector,
  isLoadingSelector,
  summaryTableDataSelector,
  summaryTreeSelector,
  summaryYearSelector,
} from "redux/modules/common/constructing/finance/summary/selectors";
import { prepareSummaryTableDataByProjects } from "redux/modules/common/constructing/finance/summary/utils";
import { useTypedSelector } from "redux/typedUseSelector";

import { Spinner } from "components/UI/Spinner/Spinner";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useFinanceMeasure } from "../../../../../../features/financeMeasure";
import FinanceFooter from "../../_internal/FinanceFooter/FinanceFooter";
import Table from "./Table/Table";

import { ITotalsInFinance } from "../../types";

import { isFinanceFooter } from "../../_internal/utils";

import styles from "./TableSide.module.scss";

const TableSide: React.FC = () => {
  const objectId = useObjectId(3);
  const { projectId } = useParams<Record<string, string>>();
  const isLoading = useSelector(isLoadingSelector);
  const tableData = useSelector(summaryTableDataSelector);
  const rawData = useSelector(summaryTreeSelector);
  const totalsData = (useSelector(detailedProjectSummarySelector as any) as any)[objectId] as ITotalsInFinance | null;
  const { measure } = useFinanceMeasure();
  const year = useSelector(summaryYearSelector) as number;
  const projectSummary = useTypedSelector((state) => state.constructing.finance.summary.totalsByProjectsV2);
  const projectDetail = useTypedSelector((state) => state.constructing.finance.summary.detailedProjectSummaryV2)[
    projectId as any
  ];

  const displayingData = useMemo(() => {
    if (!projectId || projectId === "0") {
      return tableData;
    }

    return prepareSummaryTableDataByProjects(rawData as any, projectId, objectId, year);
  }, [projectId, tableData, objectId, rawData, year]);

  const footerData = useMemo(() => {
    const objId = +(objectId ?? "0");
    if (objId > 0) {
      return totalsData;
    } else {
      return projectDetail;
    }
  }, [projectId, objectId, totalsData, projectDetail]);

  if (isLoading && !tableData?.length) return <Spinner isStatic />;

  return (
    <>
      <div className={styles.tableSide}>
        <Table data={displayingData} totalsData={totalsData} />
      </div>
      {isFinanceFooter(objectId, projectId) && <FinanceFooter totalsData={footerData} />}
    </>
  );
};

export default TableSide;
