export const ROLES_ALIASES = {
  SUPER_ADMIN: "super-admin",
  ADMIN: "admin",
  CHIEF_PROJECT: "chief-project",
  ACCOUNTANT: "accountant",
  CEO: "ceo",
  CHIEF_ENGINEER: "сhief-еngineer",
  MANUFACTURING_DIRECTOR: "manufacturing-director",
  SUPPLY_DIRECTOR: "supply-director",
  PTO_ENGINEER: "pto-egineer",
  HR_OFFICER: "hr-officer",
  STOREKEEPER: "storekeeper",
  HEAD_OF_THE_SITE: "head-of-the-site",
  FOREMAN: "foreman",
  ESTIMATOR: "estimator",
  SUPPLY_SPECIALIST: "supply-specialist",
  SUPERVISION: "supervision",
  CHIEF_OF_FINANCIAL: "chief-of-financical",
  ECONOMIST: "economist",
  ECONOMIST_ON_THE_OBJECT: "economist-on-object",
};
