import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";

import DynamicInput from "../DynamicInput";

import styles from "./DynamicTitleInput.module.scss";

export const SIZES = { SMALL: "small", NORMAL: "normal", BIG: "big" };

export interface IProps {
  title: string;
  onChange: (number: string) => void;
  size: typeof SIZES.SMALL | typeof SIZES.NORMAL | typeof SIZES.BIG;
  placeholder: string;
  isDisabled?: boolean;
}

const DynamicTitleInput: React.FC<IProps> = ({
  title,
  onChange,
  size = SIZES.NORMAL,
  placeholder = "Введите название",
  isDisabled,
}) => {
  const [changedName, setChangedName] = useState("");

  const handleSetChangedName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setChangedName(event.target.value),
    []
  );
  const onBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value), [onChange]);

  useEffect(() => {
    setChangedName(title);
  }, [title]);

  return (
    <DynamicInput
      placeholder={placeholder}
      className={cn("autosize-input", styles.dynamicInput, styles[size])}
      maxLength={100}
      value={changedName}
      onChange={handleSetChangedName}
      onBlur={onBlur}
      disabled={isDisabled}
    />
  );
};

export default React.memo(DynamicTitleInput);
