import { BASE_ROW_MARGIN } from "./constants";

export const getRowMargin = (depth, isControlsVisible) => {
  let margin = BASE_ROW_MARGIN * depth + 22;
  return `${margin}px`;
};

export const getIsControlsVisible = (section) => {
  if (section.isExtra && (section.costs || section.isRoot || section.subsections)) {
    return true;
  }

  return Boolean(
    section.isRoot ||
      (section.hasOwnProperty("isOpen") &&
        (section.children?.length || section.subsection_count || section.expenditure_count || section.costs?.length))
  );
};
