import cn from "classnames";
import { memoize } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { setOrderPayment, updateOrder } from "../../../../../redux/modules/common/building/requisition/reducer";
import { resetToInitialAction } from "../../../../../redux/modules/common/orderCard/approval/actions";
import { APPROVAL_TYPES } from "../../../../../redux/modules/common/orderCard/approval/constants";
import {
  orderApprovalSelector,
  paymentApprovalSelector,
} from "../../../../../redux/modules/common/orderCard/approval/selectors";
import { userSelector } from "redux/modules/common/auth";
import { addEmployees } from "redux/modules/common/orderCard/approval/thunks/addEmployees";
import { deleteEmployee } from "redux/modules/common/orderCard/approval/thunks/deleteEmployee";
import { getEmployees } from "redux/modules/common/orderCard/approval/thunks/getEmployees";

import OrderRequests from "../../../../UI/OrderRequests";
import ApproversAndViewers from "../../../Order/components/ApproversAndViewers";
import { TYPES } from "components/UI/organism/Approvers";
import ManageOrder from "components/UI/organism/ManageOrder";
import useManageOrderOptions from "components/UI/organism/ManageOrder/hooks/useManageOrderOptions";
import { optionsIds } from "components/UI/organism/ManageOrder/options";

import ApprovalAndView from "../../../../../domain/ApprovalAndView";
import OrderHeader from "../OrderHeader";

import { PAYMENT_DECISION, PCR_NEED_APPROVE } from "../../../../../constants/constant";

import useApprovalAndView from "../../../../../hooks/useApprovalAndView";

import { getUserType } from "../../../../../utils/helpers/getUserType";
import { checkOrderStatusForApprove } from "../../utils/checkOrderStatusForApprove";
import { stringifyArgs } from "utils/helpers/stringifyArgs";

import styles from "./index.module.scss";

const Order = ({ order, isOpen, handleOpening, permissions, isSimplified, isChecked, onCheck }) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const orderApproval = useSelector(orderApprovalSelector);
  const paymentApproval = useSelector(paymentApprovalSelector);

  const [orderRequests, setOrderRequests] = useState(order.body.requests);
  const [orderKits, setOrderKits] = useState(order.body.kits);

  const { approvalAndView: orderApprovalAndView } = useApprovalAndView(orderApproval.list);
  const { approvalAndView: paymentApprovalAndView } = useApprovalAndView(paymentApproval.list);

  const canAddOrderApprovers = useMemo(() => orderApprovalAndView.checkCanAddApprovers(), [orderApprovalAndView]);

  const canAddPaymentApprovers = useMemo(() => paymentApprovalAndView.checkCanAddApprovers(), [paymentApprovalAndView]);

  const checkManageOrderOptionsIds = useCallback(
    () => ({
      [optionsIds.approveOrder]:
        order.was_saved &&
        order.status !== PAYMENT_DECISION &&
        orderApprovalAndView.checkCanUserApprove(orderApprovalAndView.approvers, user.id),
      [optionsIds.unApproveOrder]:
        order.status === PCR_NEED_APPROVE &&
        orderApprovalAndView.checkCanUserUnApprove(orderApprovalAndView.approvers, user.id),
      [optionsIds.approvePayment]:
        !canAddOrderApprovers &&
        order.status !== PCR_NEED_APPROVE &&
        paymentApprovalAndView.checkCanUserApprove(paymentApprovalAndView.approvers, user.id),
      [optionsIds.unApprovePayment]: paymentApprovalAndView.checkCanUserUnApprove(
        paymentApprovalAndView.approvers,
        user.id
      ),
    }),
    [
      paymentApprovalAndView,
      orderApprovalAndView,
      user.id,
      order.status,
      order.auto_transition_to_payment,
      order.was_saved,
      canAddOrderApprovers,
    ]
  );

  const [manageOrderOptionsIds, setManageOrderOptionsIds] = compose(useState, checkManageOrderOptionsIds)();
  const manageOrderActiveOptions = useManageOrderOptions(manageOrderOptionsIds, dispatch, order.id);

  const authEntityType = useSelector((state) => state.auth.entity.type);

  const handleAddEmployees = useCallback(
    (type) => (addedEmployees) => {
      if (!order) return;
      const employeesToRequest = ApprovalAndView.getApprovalElementsFromEmployees(addedEmployees);

      compose(dispatch, addEmployees)(employeesToRequest, {
        orderId: order.id,
        approvalType: APPROVAL_TYPES[type],
        userType: getUserType(authEntityType),
      });
    },
    [order.id, authEntityType]
  );

  const handleAddApprovers = useCallback(
    (type) => (addedEmployees) => handleAddEmployees(type)(ApprovalAndView.makeEmployeesApprovers(addedEmployees)),
    [handleAddEmployees]
  );

  const handleAddViewers = useCallback(
    (type) => (addedEmployees) => handleAddEmployees(type)(ApprovalAndView.makeEmployeesViewers(addedEmployees)),
    [handleAddEmployees]
  );

  const handleDeleteEmployee = useCallback(
    (type) => (deletedEmployee) => () =>
      compose(dispatch, deleteEmployee)(deletedEmployee, {
        orderId: order.id,
        approvalType: APPROVAL_TYPES[type],
        userType: getUserType(authEntityType),
      }),
    [order.id, authEntityType]
  );

  const memoizedHandleDeleteEmployee = useMemo(
    () => memoize(handleDeleteEmployee, stringifyArgs),
    [handleDeleteEmployee]
  );

  const canSendToPayment = useMemo(() => {
    const orderCanSendToPayment =
      paymentApprovalAndView.checkAllApproversApprove() && order.status === PAYMENT_DECISION;
    const userCanSendToPayment =
      !order.building.payment_decision_employee || user.id === order.building.payment_decision_employee.id;
    return orderCanSendToPayment && userCanSendToPayment;
  }, [paymentApprovalAndView, order.status, order.building?.payment_decision_employee?.id]);

  const sendToPayment = useCallback(
    (message) => {
      compose(dispatch, setOrderPayment)(order.id, { comment: message });
    },
    [order.id]
  );

  const handleUpdateOrder = useCallback(
    () => compose(dispatch, updateOrder)(order.requisition.id, order.id),
    [order.requisition.id, order.id]
  );

  const isShownManageOrder = manageOrderActiveOptions.length !== 0 || canSendToPayment;

  const orderRequestsPermissions = useMemo(
    () => ({
      viewInvoiceDifference: permissions.viewOrderInvoiceDifference,
      addFiles: permissions.viewAddOrderRequestsFiles,
      deleteFiles: permissions.viewDeleteOrderRequestsFiles,
    }),
    [permissions]
  );

  useEffect(() => {
    compose(setManageOrderOptionsIds, checkManageOrderOptionsIds)();
  }, [checkManageOrderOptionsIds]);

  useEffect(() => {
    if (canAddOrderApprovers || order.status !== PCR_NEED_APPROVE) return;
    handleUpdateOrder();
  }, [canAddOrderApprovers, handleUpdateOrder]);

  useEffect(() => {
    setOrderRequests(order.body.requests);
  }, [order.body.requests]);

  useEffect(() => {
    setOrderKits(order.body.kits);
  }, [order.body.kits]);

  useEffect(() => {
    if (!isOpen) return;

    compose(dispatch, getEmployees)(order.id, {
      userType: getUserType(authEntityType),
      approvalType: APPROVAL_TYPES.order,
    });

    compose(dispatch, getEmployees)(order.id, {
      userType: getUserType(authEntityType),
      approvalType: APPROVAL_TYPES.payment,
    });
  }, [isOpen, order.id]);

  useEffect(() => {
    if (isOpen) return;
    compose(dispatch, resetToInitialAction)();
  }, [isOpen]);

  return (
    <div className={cn(styles.order, { [styles.active]: isOpen })}>
      <OrderHeader
        isOrderOpen={isOpen}
        handleOrderOpening={handleOpening}
        order={order}
        isShownInvoiceDifference={permissions.viewOrderInvoiceDifference}
        isSimplified={isSimplified}
        isChecked={isChecked}
        onCheck={onCheck}
      />
      {isOpen && [
        (order.body.requests.length !== 0 || order.body.kits.length !== 0) && (
          <OrderRequests
            className={styles.orderRequests}
            requests={orderRequests}
            kits={orderKits}
            setKits={setOrderKits}
            setRequests={setOrderRequests}
            buildingId={order.building.id}
            updateOrder={handleUpdateOrder}
            permissions={orderRequestsPermissions}
          />
        ),
        checkOrderStatusForApprove(order.status) && order.was_saved && (
          <>
            <ApproversAndViewers
              className={styles.approversViewers}
              buildingId={order.building.id}
              orderApprovers={orderApprovalAndView.approvers}
              paymentApprovers={paymentApprovalAndView.approvers}
              handleDeleteOrderApprover={memoizedHandleDeleteEmployee(TYPES.order)}
              handleAddOrderApprovers={handleAddApprovers(TYPES.order)}
              canAddOrderApprovers={canAddOrderApprovers}
              canAddPaymentApprovers={canAddPaymentApprovers}
              orderViewers={orderApprovalAndView.viewers}
              handleDeleteOrderViewer={memoizedHandleDeleteEmployee(TYPES.order)}
              handleAddOrderViewers={handleAddViewers(TYPES.order)}
              paymentViewers={paymentApprovalAndView.viewers}
              handleDeletePaymentApprover={memoizedHandleDeleteEmployee(TYPES.payment)}
              handleAddPaymentApprovers={handleAddApprovers(TYPES.payment)}
              handleDeletePaymentViewer={memoizedHandleDeleteEmployee(TYPES.payment)}
              handleAddPaymentViewers={handleAddViewers(TYPES.payment)}
              areShownPaymentApproversAndViewers={!canAddOrderApprovers && order.status === PAYMENT_DECISION}
              approversAreLoading={orderApproval.isLoading || paymentApproval.isLoading}
            />
            {isShownManageOrder && (
              <ManageOrder
                options={manageOrderActiveOptions}
                canSendToPayment={canSendToPayment}
                sendToPayment={sendToPayment}
                orderPaymentTerms={order.payment_terms}
              />
            )}
          </>
        ),
      ]}
    </div>
  );
};

export default React.memo(Order);
