export default async function downloadFileByUrl(url: RequestInfo | URL, filename: string) {
  const file = await fetch(url);
  const fileBlob = await file.blob();
  const fileURL = URL.createObjectURL(fileBlob);

  const anchor = document.createElement("a");
  anchor.href = fileURL;
  anchor.download = filename;

  document.body.appendChild(anchor);
  anchor.click();
  URL.revokeObjectURL(fileBlob);
  document.body.removeChild(anchor);
}
