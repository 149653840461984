import { useMemo } from "react";
import { useSelector } from "react-redux";

import { userSelector } from "redux/modules/common/auth";

import { permissionsUserSelector } from "../model/selectors";

export const useOneOfPermissions = (aliases: string[]) => {
  const user = useSelector(userSelector);
  const allPermissions = useSelector(permissionsUserSelector)[user?.id];

  const isPermissed = useMemo(() => {
    let res = false;
    aliases.forEach((el) => {
      if (allPermissions?.find((perm) => perm.alias === el)?.state === "on") {
        res = true;
      }
    });

    return res;
  }, [allPermissions]);

  return isPermissed;
};
