import {combineReducers} from 'redux'

import contacts from './contacts'
import list from './list'
import item from './item'

export const moduleName = 'contacts'

export default combineReducers({
  index: contacts,
  list,
  item
})
