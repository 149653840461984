import React from 'react';
import { debounce } from 'lodash';
import cn from 'classnames';

import Icon from "components/UI/Icon/Icon";

import styles from './index.module.scss'
import searchIcon from "images/icons/searchIcon.svg";


export class InputSearchRound extends React.PureComponent {
  state = {
    val: this.props.defaultValue,
  };

  onChangeHandler = e => {
    const now_e = { ...e };
    this.setState({ val: e.target.value });
    this.props.onChange(now_e);
  };

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value && (this.props.value === '' || this.props.value == undefined)) {
      this.setState({
        val: ''
      })
    }
    if (oldProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        val: this.props.defaultValue
      })
    }
  }

  render() {

    return (
      <div className={cn(styles.container, this.props.className)}>
        <input
          {...this.props}
          value={this.state.val}
          onChange={this.onChangeHandler}
          type="text"
          className={styles.searchInput}
          placeholder={this.props.placeholder || 'Поиск'}
        />
        <Icon className={styles.searchIcon} icon={searchIcon} />
      </div>
    );
  }
}
