import {combineReducers} from 'redux'

import list from './list'
import addModal from './addModal'

export const moduleName = 'list'

export default combineReducers({
  index: list,
  addModal
})
