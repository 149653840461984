import cn from "classnames";
import moment from "moment";
import React from "react";

import PopoverOverlay from "../../../../../../molecules/PopoverOverlay/PopoverOverlay";

import Icon from "../../../../../../Icon/Icon";

import InfoIcon from "images/icons/InfoIcon";
import approvedCheckIcon from "images/icons/check-bold-white-icon.svg";

import styles from "./ApprovalBar.module.scss";

export interface IApprovalBarProps {
  isApproved: boolean;
  workerName: string;
  approvedAt?: string;
  sendToApproveAt?: string;
}

const ApprovalBar: React.FC<IApprovalBarProps> = ({ isApproved, workerName, approvedAt, sendToApproveAt }) => {
  return (
    <div className={cn(styles.bar)}>
      <div
        className={cn(styles.indicator, {
          [styles.indicatorApproved]: isApproved,
          [styles.indicatorWaiting]: !isApproved,
        })}
      >
        {isApproved && <Icon icon={approvedCheckIcon} />}
      </div>
      <span className={styles.name}>{workerName}</span>
      {!!approvedAt && <span className={styles.approvedAt}>{moment(approvedAt).format("DD.MM.YY HH:mm")}</span>}
      {!!sendToApproveAt && (
        <PopoverOverlay
          content={
            <div className={styles.sendToApprovePopover}>
              <span>Передано на согласование:</span>
              <span>{moment(sendToApproveAt).format("DD.MM.YY HH:mm")}</span>
            </div>
          }
          openType={"hover"}
          portalClassName={styles.portal}
          popoverBorderColor={"default"}
          placement={"bottom"}
        >
          <InfoIcon />
        </PopoverOverlay>
      )}
    </div>
  );
};

export default React.memo(ApprovalBar);
