import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useParams, useRouteMatch } from "react-router-dom";
import { compose } from "redux";

import {
  createRequisition,
  getLastRequisitionDraftId,
  lastRequisitionDraftIdSelector,
  resetLastRequisitionDraftIdAction,
} from "redux/modules/common/building/object/nowObject";
import { loadObjects, objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import { requisitionsListsSelector } from "redux/modules/common/building/requisitionsList/selectors";
import { getMoreRequisitionsInList, getRequisitionsList } from "redux/modules/common/building/requisitionsList/thunks";
import { parseStatusParam } from "redux/modules/common/building/requisitionsList/utils";

import CreateRequisitionModal from "./components/CreateRequisitionModal/CreateRequisitionModal";
import RequisitionsRow from "./components/RequisitionsRow/RequisitionsRow";
import RequisitionsTableHead from "./components/RequisitionsTableHead/RequisitionsTableHead";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import TabBar from "components/UI/atoms/TabBar/TabBar";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";

import { TABS_LINKS } from "./constants";
import { IIdAndName } from "types/idAndName";
import { IRequisitionInList } from "types/interfaces/Requisition";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useCheckIsProductionModule } from "../Documents/hooks/useCheckIsProductionModule";
import useRequisitionsStatusesByActiveTabId from "./hooks/useRequisitionsStatusesByActiveTabId";
import useTabs from "./hooks/useTabs";

import { hasOnlyPaginationFilters } from "../../../utils/hasOnlyPaginationFilters";

import applications from "images/icons/navigation/applications.svg";

const RequisitionsList: React.FC<
  RouteComponentProps<IRouterParamsWithObjectId> & { permissions: Record<string, string> }
> = ({ match, history, permissions }) => {
  const { objectId: buildingId } = useParams<IRouterParamsWithObjectId>();
  const activeTabMatch = useRouteMatch(`${match.path}/:activeTab`); //@ts-ignore
  const activeTab = activeTabMatch && activeTabMatch.params.activeTab;
  const isInProduction = useCheckIsProductionModule();

  const dispatch = useDispatch();
  const requisitionListsByAllKeys = useSelector(requisitionsListsSelector);

  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);
  const lastRequisitionDraftId = useSelector(lastRequisitionDraftIdSelector);

  const displayingRequisitionsStatuses = useRequisitionsStatusesByActiveTabId(activeTab);

  const modifiedProjectsArgs = useMemo(
    () =>
      breadcrumbsProjects.results.map((project: IIdAndName) => ({
        id: project.id,
        name: project.name,
        label: project.name,
      })),
    [breadcrumbsProjects.results]
  );

  const [filters, setFilters] = useState({ building_id: buildingId });

  const isWithFilters = useMemo(
    () => !hasOnlyPaginationFilters(filters, isInProduction ? ["building_id"] : []),
    [filters, isInProduction]
  );

  const [isCreateRequisitionModalOpen, setIsCreateRequisitionModalOpen] = useState(false);
  const { tabs } = useTabs(match.url, filters.building_id, activeTab);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const parsedStatuses = parseStatusParam(displayingRequisitionsStatuses);

  const requstionsList = requisitionListsByAllKeys[parsedStatuses + "_" + filters.building_id] || {};

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, building_id: buildingId }));
  }, [buildingId]);

  const loadMore = () => {
    dispatch(
      getMoreRequisitionsInList(displayingRequisitionsStatuses, {
        ...filters,
        offset: requstionsList?.requisitions?.length,
      })
    );
  };

  const handleResetLastRequisitionDraftId = useCallback(
    () => compose(dispatch, resetLastRequisitionDraftIdAction)(),
    []
  );

  const onCloseCreateRequisitionModal = useCallback(() => {
    handleResetLastRequisitionDraftId();
    setIsCreateRequisitionModalOpen(false);
  }, [handleResetLastRequisitionDraftId]);

  const hasNextPage = useMemo(() => requstionsList?.count > requstionsList?.requisitions?.length, [requstionsList]);

  const handleCreateRequisition = useCallback(
    (
      //@ts-ignore
      buildingId //@ts-ignore
    ) =>
      compose(dispatch, createRequisition)(
        buildingId, //@ts-ignore
        (
          //@ts-ignore
          response
        ) => history.push(`${match.url}/${TABS_LINKS.NEW_REQUISITIONS}/${response.data.id}`)
      ),
    [history, match.url]
  );

  const onClickByCreateRequisitionButton = useCallback(() => {
    if (!buildingId) {
      setIsCreateRequisitionModalOpen(true);
      return;
    }

    compose(
      dispatch,
      getLastRequisitionDraftId //@ts-ignore
    )(buildingId).then((responseData) => {
      if (responseData) {
        setIsCreateRequisitionModalOpen(true);
      } else {
        handleCreateRequisition(buildingId);
      }
    });
  }, [buildingId]);

  const onUseExistedRequisitionDraft = useCallback(() => {
    if (!lastRequisitionDraftId) return;
    onCloseCreateRequisitionModal();
    history.push(`${match.url}/${TABS_LINKS.NEW_REQUISITIONS}/${lastRequisitionDraftId}`);
  }, [history, match.url, lastRequisitionDraftId, onCloseCreateRequisitionModal]);

  useEffect(() => {
    if (displayingRequisitionsStatuses.length === 0) return;

    const requestParams = {
      ...filters,
      building_id: buildingId || filters?.building_id,
    };
    dispatch(getRequisitionsList(displayingRequisitionsStatuses, requestParams));
  }, [filters, displayingRequisitionsStatuses, buildingId]);

  useEffect(() => {
    handleResetLastRequisitionDraftId();
  }, [buildingId]);

  useEffect(
    () => () => {
      handleResetLastRequisitionDraftId();
    },
    []
  );

  const isEmpty = !requstionsList.isLoading && !requstionsList?.requisitions?.length;

  return (
    <TemplateBase dataTestId="page_requisitions">
      {tabs ? (
        <div>
          <ListPageHeaderTemplate>
            <div>
              {/* @ts-ignore */}
              <TabBar tabs={tabs} />
            </div>
            {permissions.viewCreateRequisition && (
              <ButtonBase secondary onClick={onClickByCreateRequisitionButton}>
                Создать заявку
              </ButtonBase>
            )}
          </ListPageHeaderTemplate>
          <div>
            {((isEmpty && isWithFilters) || !isEmpty) && (
              <RequisitionsTableHead
                objectId={buildingId}
                onChangeFilter={changeFilters}
                projects={modifiedProjectsArgs}
              />
            )}
            {!requstionsList.isLoading &&
              requstionsList?.requisitions?.map((el: IRequisitionInList) => (
                <RequisitionsRow key={el.id} requisition={el} objectId={buildingId} />
              ))}
            {requstionsList.isLoading && <Spinner />}
            {isEmpty && (
              <EmptyPlaceholder text="Нет заявок" img={applications}>
                {permissions.viewCreateRequisition && (
                  <ButtonBase primary onClick={onClickByCreateRequisitionButton}>
                    Создать заявку
                  </ButtonBase>
                )}
              </EmptyPlaceholder>
            )}
            <ShowMoreButton
              isExists={hasNextPage && !requstionsList.isLoading}
              handleAdd={loadMore}
              allCount={requstionsList?.count}
              showedCount={requstionsList?.requisitions?.length}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {permissions.viewCreateRequisition && (
        <CreateRequisitionModal
          isOpen={isCreateRequisitionModalOpen}
          onClose={onCloseCreateRequisitionModal}
          buildings={breadcrumbsProjects}
          buildingId={buildingId}
          createRequisition={handleCreateRequisition}
          onUseExistedDraft={onUseExistedRequisitionDraft}
        />
      )}
    </TemplateBase>
  );
};

export default RequisitionsList;
