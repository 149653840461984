import { AnalyticsBudgetResourceType } from "../../../../../redux/modules/common/constructing/analytics/types/budgetResource";

import { IOption } from "../../../../UI/atoms/Select";

export const BUDGET_RESOURCE_TYPE_OPTIONS: (Omit<IOption, "id"> & { id: AnalyticsBudgetResourceType })[] = [
  { id: "material", name: "Материалы" },
  { id: "equipment", name: "Оборудование" },
  { id: "services", name: "МиМ" },
  // { id: "workers", name: "Рабочие" },
];

export enum CHART_COLORS {
  PLAN = "#DFE0E1",
  FACT = "#7EE5B5",
  CURSOR = "#4FB1EB",
}

export const matchChartLabels = (rawLabel: "plan" | "fact") => {
  switch (rawLabel) {
    case "plan":
      return "План";
    case "fact":
      return "Факт";
    default:
      return "";
  }
};
