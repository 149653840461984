import React, { FC } from "react";

import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";
import BlueLabel from "components/UI/atoms/BlueLabel";
import StatusFormatter from "components/UI/atoms/StatusFormatter";
import { termsNames as deliveryTermsNames } from "components/UI/molecules/DeliveryTerms";

import { IOrder } from "../../OrdersComparison";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { transformPrice } from "../../utils";

import styles from "./OrderCard.module.scss";

export interface IOrderCardProps {
  order: IOrder;
  baseModuleRoute: string;
}

const OrderCard: FC<IOrderCardProps> = ({ order, baseModuleRoute }) => {
  return (
    <div className={styles.card}>
      <header className={styles.header}>
        <NavLinkIcon to={`${baseModuleRoute}/orders/${order.id}`} title={`Заказ №${order.number}`} />
        <div>{StatusFormatter(order.status_name, { flow: "in" })}</div>
        <div className={styles.buildingName} title={order.provider.name}>
          {order.provider.name}
        </div>
      </header>
      {order.payment_terms.map((term) => (
        <div key={term.id} className={styles.block}>
          <div className={styles.blockName}>{term?.payment_type_name}:</div>
          <div className={styles.bold}>{`${term.percent}% ${
            term.delay_days > 0 ? `(${term.delay_days} дней)` : ""
          }`}</div>
        </div>
      ))}
      <div className={styles.block}>
        <div className={styles.blockName}>Доставка:</div>
        <div className={styles.bold}>
          {order.delivery_terms.map((term) => {
            if (!term.confirmed) return null;
            if (term.term_type === "paid")
              return `${deliveryTermsNames[term.term_type]}(${term.cost} ${LOCALIZATION_CONFIG.currency})`;
            return deliveryTermsNames[term.term_type];
          })}
        </div>
      </div>
      <div className={styles.labelContainer}>
        <div>Стоимость товаров:</div>
        <BlueLabel className={styles.label} blueFont={undefined}>
          {transformPrice(order.calculation.amount)}
        </BlueLabel>
      </div>
    </div>
  );
};

export default React.memo(OrderCard);
