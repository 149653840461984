import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import CheckIcon from "components/UI/organism/WorkOrMaterialsModals/components/CheckIcon/CheckIcon";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";

import WorkSharingInfoItem from "./WorkSharingInfoItem/WorkSharingInfoItem";
import { useShareCompletedWork } from "./useShareCompletedWork";

import { LOCALIZATION_CONFIG } from "../../../../../../../constants/localization";
import { IFactInterval, IPlanInterval } from "types/interfaces/FactInterval";
import { IFile } from "types/interfaces/Files";

import sendEmailImage from "images/sendEmail.png";

import styles from "./WorkSharingModalContent.module.scss";

interface IProps {
  onClose: () => void;
  interval:
    | Pick<IFactInterval, "id" | "accepted" | "measure" | "from_organisation">
    | Pick<IPlanInterval, "id" | "count" | "measure" | "from_organisation">;
  onIntervalReload: (id: number) => void;
  objectId: number | string;
  files: IFile[];
  onAddFile: (file: File) => void;
  onDeleteFile: (fileId: number) => void;
  isPlan?: boolean;
}

const WorkSharingModalContent: React.FC<IProps> = ({
  onClose,
  interval,
  onIntervalReload,
  objectId,
  files,
  onAddFile,
  onDeleteFile,
  isPlan,
}) => {
  const { isLoading, isSended, comment, commentHanlder, submitHandler } = useShareCompletedWork({
    intervalId: interval.id,
    onIntervalReload,
    objectId,
    isPlan,
  });

  if (!interval) return null;

  const countToSend = isPlan ? (interval as IPlanInterval)?.count : (interval as IFactInterval)?.accepted?.[0]?.count;
  const sharingSuccessText = isPlan ? "План успешно передан" : "Работа успешно передана";
  const navbarText = isPlan ? "Сведения о плане работы" : "Сведения о работе";
  const commentLabel = isPlan ? "Комментарий к передаваемому плану" : "Комментарий к передаваемой работе";

  if (isSended) {
    return (
      <>
        <img src={sendEmailImage} className={styles.img} />
        <div className={styles.result}>
          <div className={styles.resultImg}>{isLoading ? <Spinner className={styles.spinner} /> : <CheckIcon />}</div>
          <div className={styles.resultText}>{isLoading ? "Передача на проверку…" : sharingSuccessText}</div>
        </div>
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={onClose}>
            Закрыть
          </ButtonBase>
        </BottomControls>
      </>
    );
  }

  return (
    <>
      <NavBar text={navbarText} onClick={onClose} isExist />
      <h2 className={styles.title}>Кому отчитаться:</h2>
      <div className={styles.pair}>
        <WorkSharingInfoItem name="Заказчик" value={interval?.from_organisation?.name || "Не указан"} />
        <WorkSharingInfoItem
          name="ФИО контактного лица"
          value={interval?.from_organisation?.contact_full_name || "Не указано"}
        />
      </div>
      <div className={styles.pair}>
        <WorkSharingInfoItem
          name={LOCALIZATION_CONFIG.taxesNumberLabel}
          value={interval?.from_organisation?.itn || "Не указано"}
        />
        <WorkSharingInfoItem name="Email" value={interval?.from_organisation?.email || "Не указано"} />
      </div>
      <h2 className={styles.title}>Что передать:</h2>
      <WorkSharingInfoItem name={`Кол-во (${interval.measure}):`} value={countToSend} className={styles.pairLine} />
      <h2 className={styles.title}>Обоснование передачи:</h2>
      <div className={styles.label}>{commentLabel}</div>
      <TextareaAutosize
        onChange={commentHanlder}
        placeholder={"Введите текст сообщения"}
        minRows={3}
        maxRows={10}
        value={comment}
      />
      {/* <div className={styles.checkbox}>
                <Checkbox checked onCheck={() => null}>уведомить по email</Checkbox>
            </div> */}
      <ExpandableFileInput
        title="Дополнительные вложения (документы):"
        files={files}
        canExtractFiles={false}
        onRemoveFileDirectly={onDeleteFile}
        onUploadFileDirectly={onAddFile}
      />
      <BottomControls isExists isDoubleBtns>
        <ButtonBase tertiary onClick={onClose}>
          Отменить
        </ButtonBase>
        <ButtonBase primary onClick={submitHandler}>
          Передать
        </ButtonBase>
      </BottomControls>
    </>
  );
};

export default React.memo(WorkSharingModalContent);
