import React from "react";

import DoubleInput, { Measure } from "components/UI/atoms/DoubleInput/DoubleInput";
import InputBase, { VALUE_TYPES } from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select";

import { selectOptions } from "widgets/FinanceSettingsModal/constants";

import { ReactComponent as DeleteIcon } from "shared/assets/icons/IconClose-big.svg";

import styles from "./GuaranteeObligationItem.module.scss";
import { transformDigitToFinancial } from "../../../../utils/formatters/transformDigitToFinancial";
import cn from "classnames";

interface IGuaranteeObligationItemProps {
  deleteItem: () => void;
  id: number;
  amount: string;
  percent: string;
  type: string;
  delay: number;
  handleChangeAmountValue: (value: string, id: number) => void;
  handleChangePercentValue: (value: string, id: number) => void;
  handleSelectSubmit: (optionId: string | number, id: number) => void;
  handleChangeDelay: (value: string, id: number) => void;
}

const GuaranteeObligationItem: React.FC<IGuaranteeObligationItemProps> = ({
  id,
  amount,
  percent,
  type,
  delay,
  handleChangeAmountValue,
  handleChangePercentValue,
  handleChangeDelay,
  handleSelectSubmit,
  deleteItem,
}) => {
  const transformedAmount = transformDigitToFinancial(amount, {
    withFloat: true,
    dropZeros: true,
  });

  return (
    <div className={styles.flex}>
      <div className={styles.leftSide}>
        <DoubleInput
          firstValueMeasure={Measure.currency}
          firstInputSize={2}
          secondValueMeasure={Measure.percent}
          firstValue={transformedAmount}
          secondValue={percent}
          secondInputSize={1}
          onChangeFirstValue={(value) => handleChangeAmountValue(value.replaceAll(' ', ''), id)}
          onChangeSecondValue={(value) => handleChangePercentValue(value, id)}
          variant={"outline"}
          className={styles.doubleInput}
        />
        <Select
          className={styles.input}
          selectClassName={styles.selectInput}
          options={selectOptions}
          value={type}
          onChange={(value) => handleSelectSubmit(value, id)}
        />
        {type === "delay" && (
          <InputBase
            value={String(delay)}
            valueType={VALUE_TYPES.NUMBER}
            className={styles.delayInput}
            classNameInput={styles.delayInputInner}
            onChange={(e) => handleChangeDelay(e.target.value, id)}
            icon={<span>дней</span>}
          />
        )}
      </div>
        <DeleteIcon onClick={deleteItem} className={cn(styles.righted, styles.crossIcon)} />
    </div>
  );
};

export default React.memo(GuaranteeObligationItem);
