import { Table as AntTable } from "antd";
import React, { PropsWithChildren } from "react";

import { Spinner } from "components/UI/Spinner/Spinner";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import AuditHeadline from "./AuditHeadline/AuditHeadline";
import ListSide from "./ListSide/ListSide";
import TableSide from "./TableSide/TableSide";
import { useAuditData } from "./useAuditData";

import { TABLE_GROUPS } from "./TableSide/Table/constants";
import { LOCALIZATION_CONFIG } from "constants/localization";

import financeIcon from "images/icons/navigation/finance.svg";

import styles from "./Audit.module.scss";

const { Column, ColumnGroup } = AntTable;

const Audit: React.FC<PropsWithChildren> = ({ children }) => {
  const { measure, measureInTitle } = useFinanceMeasure();
  const { displayedTree, isLoading, isFirstLoad, isLoadingByObject } = useAuditData();

  if (isLoading || isLoadingByObject) return <Spinner />;

  if ((displayedTree === undefined || !displayedTree?.length) && !isLoading) {
    return <EmptyPlaceholder img={financeIcon} />;
  }

  return (
    <div className={styles.audit} data-testid="page_finance_audit">
      <div className={styles.audit__listSide}>
        <ListSide>{children}</ListSide>
      </div>
      <div className={styles.audit__tableSide}>
        <div className={styles.sticky}>
          <AuditHeadline />
          <AntTable dataSource={[]} size="small" scroll={{ x: 1100 }} pagination={false} className={styles.thead}>
            {TABLE_GROUPS.map((group, idx) => (
              <ColumnGroup className={styles.title} title={group.title} key={idx}>
                <Column
                  key={`${group.fieldName}_plan`}
                  title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`${group.fieldName}_plan`}
                  ellipsis
                />
                <Column
                  key={`${group.fieldName}_fact`}
                  title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`${group.fieldName}_fact`}
                  ellipsis
                />
                <Column
                  key={`${group.fieldName}_delta`}
                  title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`${group.fieldName}_delta`}
                  ellipsis
                />
              </ColumnGroup>
            ))}
          </AntTable>
        </div>
        <TableSide />
      </div>
    </div>
  );
};

export default React.memo(Audit);
