import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  relationAvailableIntervalsSelector,
  relationAvailableSectionsSelector,
  relationsFromCurrentIntervalSelector,
  relationsToCurrentIntervalSelector,
} from "../../../../../../../../../redux/modules/common/building/manufacturing/selectors";
import {
  bulkCreateRelations,
  clearRelationAvailableIntervals,
  loadRelationAvailableIntervals,
} from "../../../../../../../../../redux/modules/common/building/manufacturing/thunks";

import Icon from "../../../../../../../../UI/Icon/Icon";
import Checkbox from "../../../../../../../../UI/atoms/Checkbox/Checkbox";
import Select from "../../../../../../../../UI/atoms/Select";
import ConfirmBlock from "../../../../../../../../UI/organism/AddingListInModal/components/ConfirmBlock/ConfirmBlock";

import { IAddRelationBlock, IAddingRelationInModal } from "../../IntervalRelationsContent";
import DisplayedRelation from "../DisplayedRelation/DisplayedRelation";

import { RELATION_TYPES } from "../../../../../../constants";
import { IDisplayedRelation, IIntervalToConnect, RelationsDirection } from "../../types";

import useArrayItemsChecker from "../../../../../../../../../hooks/useArrayItemsChecker";

import DoubleCheck from "images/icons/CheckDouble";

import styles from "./RelationsHeader.module.scss";

export interface IRelationsHeaderProps {
  canAddRelation?: boolean;
  projectId: number;
  intervalStartAt: string;
  intervalEndAt: string;
  addingRelationParams: IAddingRelationInModal | null;
  setAddingRelationParams: (value: IAddingRelationInModal | null) => void;
  intervalId: number;
  isEditMode?: boolean;
  block: IAddRelationBlock;
  isIntervalGroup?: boolean;
}

const RelationsHeader: React.FC<IRelationsHeaderProps> = ({
  canAddRelation,
  projectId,
  intervalStartAt,
  intervalEndAt,
  addingRelationParams,
  setAddingRelationParams,
  intervalId,
  block,
  isEditMode,
  isIntervalGroup = false
}) => {
  const dispatch = useDispatch();
  const { icon, label, type, direction, relations } = block;
  const relationAvailableSections: IIntervalToConnect[] = useSelector(relationAvailableSectionsSelector) || [];
  const relationAvailableIntervals: IIntervalToConnect[] = useSelector(relationAvailableIntervalsSelector) || [];

  const relationsFromCurrentInterval: IDisplayedRelation[] = useSelector(relationsFromCurrentIntervalSelector) || [];

  const relationsToCurrentInterval: IDisplayedRelation[] = useSelector(relationsToCurrentIntervalSelector) || [];

  const checkIsIntervalInRelations = useCallback(
    (id: number) => {
      return (
        relationsFromCurrentInterval.some((x) => (x.to_interval === id) || (x.to_group === id)) ||
        relationsToCurrentInterval.some((x) => (x.from_interval === id) || (x.from_group === id))
      );
    },
    [relationsFromCurrentInterval, relationsToCurrentInterval]
  );

  const [selectedSectionId, setSelectedSectionId] = useState<number | undefined>(undefined);

  const {
    items,
    checkedCount,
    checkOnce,
    reset: resetSelected,
  } = useArrayItemsChecker(relationAvailableIntervals, "id");

  const stopAddingRelation = useCallback(() => {
    setAddingRelationParams(null);
    if (!!selectedSectionId) setSelectedSectionId(undefined);
    dispatch(clearRelationAvailableIntervals());
    resetSelected();
  }, [resetSelected]);

  const handleSectionChange = useCallback(
    (sectionId: number) => {
      if (!sectionId || !projectId) return;
      let start_at__gte = undefined;
      let start_at__lte = undefined;
      let end_at__gte = undefined;
      let end_at__lte = undefined;

      if (type === RELATION_TYPES.oh) {
        start_at__gte = direction === RelationsDirection.from ? intervalEndAt : undefined;
        end_at__lte = direction === RelationsDirection.to ? intervalStartAt : undefined;
      } else if (type === RELATION_TYPES.hh) {
        start_at__gte = direction === RelationsDirection.from ? intervalStartAt : undefined;
        start_at__lte = direction === RelationsDirection.to ? intervalStartAt : undefined;
      } else if (type === RELATION_TYPES.ho) {
        if (direction === RelationsDirection.from) {
          end_at__gte = intervalStartAt;
          end_at__lte = intervalEndAt;
        } else {
          start_at__gte = intervalStartAt;
          start_at__lte = intervalEndAt;
        }
      } else if (type === RELATION_TYPES.oo) {
        end_at__gte = direction === RelationsDirection.from ? intervalEndAt : undefined;
        end_at__lte = direction === RelationsDirection.to ? intervalEndAt : undefined;
      }
      dispatch(
        loadRelationAvailableIntervals({
          projectId,
          intervalId,
          sectionId: sectionId,
          start_at__gte,
          start_at__lte,
          end_at__gte,
          end_at__lte,
        })
      );
      setSelectedSectionId(sectionId);
    },
    [projectId, intervalId, intervalEndAt, intervalStartAt, direction, type]
  );

  const handleAddBulkRelations = useCallback(() => {
    const relationCandidatesIds = Object.entries(items)
      .filter(([id, isChecked]) => isChecked)
      .map(([id, isChecked]) => id);

    const relationCandidates = relationCandidatesIds.map((id) => {
      return relationAvailableIntervals.find((r) => r.id === +id);
    })

    dispatch(bulkCreateRelations({ intervalId, isIntervalGroup, relationCandidates, type, projectId, direction }));
    !!selectedSectionId && handleSectionChange(selectedSectionId);
    stopAddingRelation();
  }, [items, intervalId, type, projectId, direction, selectedSectionId, stopAddingRelation, relationAvailableIntervals, isIntervalGroup]);

  const isChosen = useMemo(
    () => addingRelationParams?.type === type && addingRelationParams?.direction === direction,
    [addingRelationParams, type, direction]
  );


  return (
    <>
      {isChosen && <span className={styles.chosenIndicator}>Выбран тип связи:</span>}
      <div className={styles.relationLocationLabel}>
        {icon && <Icon icon={icon} className={styles.relationLocationIcon} />}
        <span>{`${label}:`}</span>
      </div>
      {relations.map((relation) => (
        <DisplayedRelation
          relation={relation}
          isEditMode={isEditMode}
          projectId={projectId}
          direction={direction}
          type={type}
          key={relation.id}
        />
      ))}
      {canAddRelation && isChosen && (
        <div className={styles.newRelationContainer}>
          {isChosen && (
            <>
              <ConfirmBlock onDecline={stopAddingRelation} count={checkedCount} onAccept={handleAddBulkRelations} />
              <div>
                <Select
                  label={<span className={styles.selectLabel}>Укажите наименование подраздела</span>}
                  options={relationAvailableSections}
                  onChange={handleSectionChange}
                  value={selectedSectionId}
                  isScrolledToDefaultOption
                />
              </div>
              {selectedSectionId && relationAvailableIntervals?.length > 0 && (
                <span className={styles.selectLabel}>
                  Укажите ниже работу или работы, которые будут связаны с текущей работой
                </span>
              )}
              <div className={styles.intervals}>
                {!!selectedSectionId &&
                  relationAvailableIntervals.map((interval, index) => {
                    const isDoubleChecked = checkIsIntervalInRelations(interval.id);
                    return (
                      <div
                        className={cn(styles.intervalToCheck, {
                          [styles.blueBg]: index % 2 === 1,
                        })}
                        title={interval.name}
                        key={index}
                      >
                        {isDoubleChecked ? (
                          <DoubleCheck className={styles.doubleCheckIcon} />
                        ) : (
                          <Checkbox
                            onCheck={(e) => checkOnce(interval.id, e.target.checked)}
                            checked={items[interval.id]}
                            className={styles.checkbox}
                          />
                        )}
                        <span>{index + 1}</span>
                        <span className={styles.expenditureName}>{interval.name}</span>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(RelationsHeader);
