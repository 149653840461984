import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  HALF_MONTH,
  MONTH,
  WEEK,
  YEAR,
} from "../../../../../redux/modules/common/building/manufacturing/manufacturing";
import {
  chartViewModeSelector,
  criticalDatesSelector
} from "../../../../../redux/modules/common/building/manufacturing/selectors";
import { detailDataSelector } from "../../../../../redux/modules/common/building/object/nowObject";

import DaysBackground from "./components/DaysBackground/DaysBackground";
import WeeksBackground from "./components/WeeksBackground/WeeksBackground";
import YearBackground from "./components/YearBackground/YearBackground";

import { getDaysInYear, getUnitOffset, getWeeksInYear } from "../../utils";

export interface ICalendarBackgroundProps {
  year: number | string;
  unitHeight: number;
  constructionDateStart?: string | null;
  constructionDateEnd?: string | null;
  touchedYears?: string[];
  unitOffset?: number;
  criticalDates?: string[]
}

const CalendarBackground: React.FC<ICalendarBackgroundProps> = (props) => {
  const {
    year,
    unitHeight,
    touchedYears,
  } = props;
  const criticalDates = useSelector(criticalDatesSelector);
  const chartViewMode = useSelector(chartViewModeSelector);
  const { objectId } = useParams<{ objectId?: string }>();

  // Загрузка текущего проекта в nowObject через хук useProjectTree отдельно для Manufacturing и ManufacturingProduct
  const projectDetail = useSelector(detailDataSelector);

  const { constructionDateStart, constructionDateEnd } = useMemo(
    () => ({
      constructionDateStart: (!!objectId && projectDetail?.construction_date_start) || null,
      constructionDateEnd: (!!objectId && projectDetail?.construction_date_end) || null,
    }),
    [projectDetail, objectId]
  );
  if (chartViewMode === WEEK)
    return (
      <>
        {touchedYears ? (
          touchedYears.map((y, index) => (
            <WeeksBackground
              key={y}
              year={y}
              unitHeight={unitHeight}
              constructionDateStart={constructionDateStart}
              constructionDateEnd={constructionDateEnd}
              unitOffset={getUnitOffset(touchedYears, index, chartViewMode)}
              criticalDates={criticalDates}
            />
          ))
        ) : (
          <WeeksBackground
            year={year}
            unitHeight={unitHeight}
            constructionDateStart={constructionDateStart}
            constructionDateEnd={constructionDateEnd}
            criticalDates={criticalDates}
          />
        )}
      </>
    );
  if (chartViewMode === MONTH || chartViewMode === HALF_MONTH)
    return (
      <>
        {touchedYears ? (
          touchedYears.map((y, index) => (
            <DaysBackground
              key={y}
              year={y}
              unitHeight={unitHeight}
              constructionDateStart={constructionDateStart}
              constructionDateEnd={constructionDateEnd}
              unitOffset={getUnitOffset(touchedYears, index, chartViewMode)}
              criticalDates={criticalDates}
            />
          ))
        ) : (
          <DaysBackground
            year={year}
            unitHeight={unitHeight}
            constructionDateStart={constructionDateStart}
            constructionDateEnd={constructionDateEnd}
            criticalDates={criticalDates}
          />
        )}
      </>
    );
  if (chartViewMode === YEAR)
    return (
      <>
        {touchedYears ? (
          touchedYears.map((y, index) => (
            <YearBackground
              key={y}
              year={y}
              unitHeight={unitHeight}
              constructionDateStart={constructionDateStart}
              constructionDateEnd={constructionDateEnd}
              unitOffset={getUnitOffset(touchedYears, index, chartViewMode)}
              criticalDates={criticalDates}
            />
          ))
        ) : (
          <YearBackground
            year={year}
            unitHeight={unitHeight}
            constructionDateStart={constructionDateStart}
            constructionDateEnd={constructionDateEnd}
            criticalDates={criticalDates}
          />
        )}
      </>
    );
  return null;
};

export default React.memo(CalendarBackground);
