import React from "react";

import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import { beautifyNumberValue } from "utils/formatters/beautifyNumberValue";
import declOfNum from "utils/formatters/declOfNum";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./JournalContractors.module.scss";

interface IProps {
  onSectionClick: () => void;
  name: string;
  count: number;
}

const JournalContractorRow: React.FC<IProps> = ({ onSectionClick, name, count }) => {
  return (
    <TableReusableRow className={styles.row} onClick={onSectionClick}>
      <TableReusableCell className={styles.name}>{name}</TableReusableCell>
      {!!count && (
        <TableReusableCell className={styles.right}>
          <span className={styles.count}>{transformDigitToFinancial(count, { withFloat: false })}</span>
          <span className={styles.toAcceptance}>
            {declOfNum(count, ["работа", "работы", "работ"], { hideValue: true })} к принятию
          </span>
        </TableReusableCell>
      )}
    </TableReusableRow>
  );
};

export default JournalContractorRow;
