import cn from "classnames";
import React from "react";

import { IJournalHistoryEditing } from "redux/modules/common/building/journal/acceptance/types";

import { EditingHistoryFieldNames } from "widgets/EditingHistory/constants";

import { getHistoryItemDiffValue } from "widgets/EditingHistory/utils";

import styles from "./EditingHistoryItem.module.scss";

interface IProps {
  date: string;
  userName: string;
  diff: IJournalHistoryEditing["diff"];
  type?: "manual" | "auto";
  reason?: string;
}

const EditingHistoryItem: React.FC<IProps> = ({ date, userName, diff, type = "manual", reason }) => {
  return (
    <div className={styles.container}>
      <div className={styles.date}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.06401 1.5C4.06401 1.22386 4.28787 1 4.56401 1H5.13894C5.41508 1 5.63894 1.22386 5.63894 1.5V2.18493H10.3637V1.5C10.3637 1.22386 10.5876 1 10.8637 1H11.4386C11.7148 1 11.9386 1.22386 11.9386 1.5V2.18493H12.2026C13.749 2.18493 15.0026 3.43854 15.0026 4.98493V12.1997C15.0026 13.7461 13.749 14.9997 12.2026 14.9997H3.8C2.25361 14.9997 1 13.7461 1 12.1997V4.98494C1 3.43854 2.25361 2.18493 3.8 2.18493H4.06401V1.5ZM2.6 12.1997V5.86212H13.4026V12.1997C13.4026 12.8625 12.8653 13.3997 12.2026 13.3997H3.8C3.13726 13.3997 2.6 12.8625 2.6 12.1997ZM9.19035 9.33667C8.91421 9.33667 8.69035 9.56053 8.69035 9.83667V11.6146C8.69035 11.8907 8.91421 12.1146 9.19035 12.1146H11.6355C11.9117 12.1146 12.1355 11.8907 12.1355 11.6146V9.83667C12.1355 9.56053 11.9117 9.33667 11.6355 9.33667H9.19035Z"
            fill="white"
          />
        </svg>
        {date}
      </div>
      <div className={styles.pair}>
        <div className={styles.key}>{type === "manual" ? "Изменил:" : "Подтвердил:"}</div>
        <div className={styles.value}>{userName}</div>
      </div>
      {diff.map((el, i) => {
        if (el.field === "end_at") return null;
        return (
          <div key={el.field + i}>
            <div className={styles.pair}>
              <div className={styles.key}>{EditingHistoryFieldNames[el.field]} (подано):</div>
              <div className={styles.value}>{getHistoryItemDiffValue(el, diff, "old")}</div>
            </div>
            <div className={styles.pair}>
              <div className={styles.key}>{EditingHistoryFieldNames[el.field]} (изменено):</div>
              <div className={styles.value}>{getHistoryItemDiffValue(el, diff, "new")}</div>
            </div>
            {!!reason && (
              <div className={cn(styles.key, styles.reason)}>
                <span className={styles.reasonKey}>Причина:</span>
                <span>{reason}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default EditingHistoryItem;
