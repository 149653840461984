import { message } from "antd";
import axios from "axios";
import { Dispatch } from "redux";

import { IAosrInKs2ExpenditureListFiles } from "redux/modules/common/passportsAndCertificates/types";

import { ICreateAosrFormVals } from "components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/Aosr/useCreateAosr";

import { RootState } from "../../../../../rootReducer";
import { journalExecutionActions } from "../journalExecution/actions";
import { aosrActions } from "./actions";
import { aosrApi } from "./api";

import { IExpenditure } from "../../../../../../types/interfaces/Expenditure";
import { IAosrDocument, IEditDocsValues } from "./types";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const getAosr = (buildingId: string, expenditureId: number) => (dispatch: Dispatch) => {
  aosrApi
    .getAosr(buildingId)
    .then(({ data }) => {
      dispatch(aosrActions.setAosr(data.results[0], expenditureId));
    })
    .catch(errorCatcher);
};

export const publishAosr =
  (buildingId: string, expenditureId: number, values: ICreateAosrFormVals, aosrId: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const filteredValues: Partial<ICreateAosrFormVals> = {
      extra_info: values.extra_info ?? "",
    };

    for (let key in values) {
      /* @ts-ignore */
      if (values[key] !== null && !["docs", "group_id", "expenditure_id"].includes(key)) {
        /* @ts-ignore */
        filteredValues[key] = values[key];
      }
    }

    filteredValues.next_expenditure = values.next_expenditure;
    filteredValues.next_expenditure_id = values.next_expenditure_id;
    filteredValues.next_group = values.next_group;
    filteredValues.next_group_id = values.next_group_id;

    aosrApi
      .editAosr(buildingId, aosrId, filteredValues)
      .then(({ data }) => {
        dispatch(aosrActions.setAosr(data, expenditureId));
        dispatch(aosrActions.invalidateKey());
        if (values.docs.length) {
          const docRequests = values.docs?.map(async (el) => {
            if ((el as any).files?.length) {
              /* @ts-ignore */
              const mappedInB64Files = await getArrayFilesInBase64((el.files || []).map((file) => ({ file })));
              return aosrApi.createDoc(
                buildingId,
                {
                  ...el,
                  hiddenwork: aosrId,
                  /* @ts-ignore */
                  files: mappedInB64Files || [],
                } as any,
                data.id
              );
            } else {
              return aosrApi.createDoc(
                buildingId,
                {
                  ...el,
                  hiddenwork: aosrId,
                } as any,
                data.id
              );
            }
          });
          Promise.all(docRequests).then(() => {
            message.success("Успешно сформировано");
            dispatch(aosrActions.invalidateKey());
          });
        } else {
          message.success("Успешно сформировано");
          dispatch(aosrActions.invalidateKey());
        }
      })
      .catch(errorCatcher);
  };

export const createAosr =
  (buildingId: string, expenditureId: number, values: ICreateAosrFormVals, isPublishing?: boolean) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(aosrActions.setCreatingPending(true, 1));
    aosrApi
      .createAosr(buildingId, values)
      .then(({ data }) => {
        dispatch(aosrActions.setAosrDetail(data));
        dispatch(aosrActions.setAosr(data, expenditureId));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(aosrActions.setCreatingPending(false, 1));
      });
  };

export const getWorksBySectionInAosr = (buildingId: string, sectionId: number) => async (dispatch: Dispatch) => {
  await axios
    .get(`/tree/buildings/${buildingId}/lite/`, { params: { limit: 500, expenditure_type: "work" } })
    .then((response) => {
      const works: IExpenditure[] = [];
      response.data.sections.forEach(
        (section: {
          id: number;
          subsections: { id: number; expenditures: IExpenditure[]; groups: IExpenditure[] }[];
        }) =>
          section.subsections.forEach((subsection) => {
            if (subsection.id === sectionId)
              works.push(
                ...subsection.expenditures?.filter((exp) => exp.expenditure_type === "work"),
                ...subsection.groups
              );
          })
      );
      dispatch(aosrActions.setWorksBySection(works, sectionId));
    });
};

export const editAosr =
  (
    buildingId: string,
    expenditureId: number,
    aosrId: number,
    values: Partial<ICreateAosrFormVals>,
    isPublishing: boolean = true
  ) =>
  (dispatch: Dispatch) => {
    const filteredValues: Partial<ICreateAosrFormVals> = {
      extra_info: values.extra_info ?? "",
    };

    for (let key in values) {
      /* @ts-ignore */
      if (values[key] !== null && !["docs", "group_id", "expenditure_id"].includes(key)) {
        /* @ts-ignore */
        filteredValues[key] = values[key];
      }
    }

    filteredValues.next_expenditure = values.next_expenditure;
    filteredValues.next_expenditure_id = values.next_expenditure_id;
    filteredValues.next_group = values.next_group;
    filteredValues.next_group_id = values.next_group_id;

    aosrApi
      .editAosr(buildingId, aosrId, filteredValues)
      .then(({ data }) => {
        if (!isPublishing) return;
        dispatch(aosrActions.setAosr(data, expenditureId));
        dispatch(aosrActions.invalidateKey());
        message.success("Сохранено");
      })
      .catch(errorCatcher);
  };

export const editDocs =
  (buildingId: string, expenditureId: number, aosrId: number, values: IEditDocsValues, docId: number) => async () => {
    const files = await getArrayFilesInBase64(values.listfiles);
    return aosrApi.editDocs(buildingId, aosrId, { ...values, files }, docId).catch(errorCatcher);
  };

export const createDocs =
  (buildingId: string, expenditureId: number, values: IAosrDocument, aosrId: number) => async () => {
    const files = await getArrayFilesInBase64(values.listfiles);
    return aosrApi.createDoc(buildingId, { ...values, listfiles: files, files }, aosrId).catch(errorCatcher);
  };

export const deleteDocs = (buildingId: string, expenditureId: number, aosrId: number, docId: number) => () => {
  return aosrApi.deleteDocs(buildingId, aosrId, docId).catch(errorCatcher);
};

export const addDocFiles =
  (buildingId: string, expenditureId: number, itemId: number, file: IAosrInKs2ExpenditureListFiles) => () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("item_id", itemId.toString());

    return aosrApi.addDocFile(buildingId, expenditureId, formData).catch(errorCatcher);
  };

export const deleteDocFiles = (buildingId: string, expenditureId: number, aosrId: number, docId: number) => () => {
  return aosrApi.deleteDocFile(buildingId, expenditureId, aosrId, docId).catch(errorCatcher);
};

export const addAosrFile =
  (buildingId: string, expenditureId: number, aosrId: number, file: File) => async (dispatch: Dispatch) => {
    const b64files = await getArrayFilesInBase64([{ file }]);
    aosrApi
      .editAosr(buildingId, expenditureId, aosrId, { confirmed_file: b64files[0] || "" })
      .then(() => {
        dispatch(aosrActions.invalidateKey());
        message.success("Сохранено");
      })
      .catch(errorCatcher);
  };

export const setAosrDetail = (buildingId: string, expenditureId: number, aosrId: number) => (dispatch: Dispatch) => {
  aosrApi
    .getAosrDetail(buildingId, expenditureId, aosrId)
    .then(({ data }) => dispatch(aosrActions.setAosrDetail(data)));
};

export const dropCurrentAosrDetail = () => (dispatch: Dispatch) => {
  dispatch(aosrActions.setAosrDetail(null));
};

export const closeAosr = (buildingId: string, aosrId: number, file: File) => async (dispatch: Dispatch) => {
  const serializedFiles = await getArrayFilesInBase64([file]);
  dispatch(aosrActions.setClosingPending(true, aosrId));
  aosrApi
    .closeAosr(buildingId, aosrId, serializedFiles[0].file as string)
    .then(() => {
      dispatch(aosrActions.invalidateKey());
      message.success("Успешно");
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(aosrActions.setClosingPending(false, aosrId));
    });
};
