import { message } from "antd";
import cn from "classnames";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { useDispatch } from "react-redux";

import { deleteRequisitesProfile } from "redux/modules/common/settings/ourCompanyTabs/profile";

import AlertPopup from "../../../../../UI/molecules/AlertPopup/AlertPopup";
import Icon from "components/UI/Icon/Icon";
import InputBase from "components/UI/atoms/InputBase";

import { alertIcon } from "../../Users/alertIcon";

import { LOCALIZATION_CONFIG, REGIONS_LABELS } from "constants/localization";

import useFormFocus from "../../../../../../utils/hooks/useFormFocus";

import { maxLengthValidator } from "../../../../../../utils/formHelpers/maxLengthValidator";
import { mustBeNumber } from "utils/formHelpers/validations";
import { composeFieldValidators, required } from "utils/formHelpers/validations";

import deleteIcon from "images/icons/delete.svg";

import styles from "./BankDetails.module.scss";

const BankDetails = ({ item, index, deleteEmptyHandler, specialDeleteHandler, isDisabled, isLast, addNew }) => {
  const dispatch = useDispatch();
  const inputRootRef = useFormFocus(addNew);

  const [deletingAlert, setDeletingAlert] = useState(false);

  const removeBankDetails = () => {
    setDeletingAlert(false);
    if (specialDeleteHandler) {
      item.id ? specialDeleteHandler(item.id) : deleteEmptyHandler();
      return;
    }
    if (item.pk) {
      dispatch(deleteRequisitesProfile(item.pk));
    } else {
      deleteEmptyHandler();
    }
  };

  const openModalHandler = () => {
    if (isLast) {
      message.error("Нельзя удалить последние реквизиты");
    } else {
      setDeletingAlert(true);
    }
  };

  return (
    <>
      <div ref={!isDisabled ? inputRootRef : null} className={cn(styles.row, { [styles.disabled]: isDisabled })}>
        <div className={styles.field1}>
          <Field
            name={`general_entity.requisite_set[${index}].bank_name`}
            label="Наименование банка"
            component={InputBase}
            selfControlled={true}
            placeholder="Введите Наименование банка"
            validate={composeFieldValidators(required())}
            format={maxLengthValidator(255)}
            formatOnBlur
          />
        </div>
        <div className={styles.field2}>
          <Field
            name={`general_entity.requisite_set[${index}].bic`}
            label="БИК"
            component={InputBase}
            selfControlled={true}
            placeholder="Введите БИК"
            validate={composeFieldValidators(
              required(),
              LOCALIZATION_CONFIG.langLabel !== REGIONS_LABELS.kz ? mustBeNumber : null
            )}
            format={maxLengthValidator(LOCALIZATION_CONFIG.bicLength)}
            formatOnBlur
          />
        </div>
        <div className={styles.field3}>
          <Field
            name={`general_entity.requisite_set[${index}].corresponding_account`}
            label="Корр.счет"
            component={InputBase}
            selfControlled={true}
            placeholder="Введите Корр.счет"
            validate={composeFieldValidators(
              required(),
              LOCALIZATION_CONFIG.langLabel !== REGIONS_LABELS.kz ? mustBeNumber : null
            )}
            format={maxLengthValidator(20)}
            formatOnBlur
          />
        </div>
        <div className={styles.field4}>
          <Field
            name={`general_entity.requisite_set[${index}].checking_account`}
            label="Расчетный счет"
            component={InputBase}
            selfControlled={true}
            placeholder="Введите Расчетный счет"
            validate={composeFieldValidators(
              required(),
              LOCALIZATION_CONFIG.langLabel !== REGIONS_LABELS.kz ? mustBeNumber : null
            )}
            format={maxLengthValidator(20)}
            formatOnBlur
          />
        </div>
        <div className={styles.removeIcon} onClick={openModalHandler}>
          <Icon icon={deleteIcon} />
        </div>
      </div>
      {deletingAlert && (
        <AlertPopup onClose={() => setDeletingAlert(false)} title="Подтвердите действие">
          <div className={styles.alert}>
            {alertIcon}
            <p>Вы действительно хотите удалить реквизиты?</p>
          </div>
          <div className={styles.btns}>
            <button onClick={removeBankDetails}>Подтвердить</button>
            <button onClick={() => setDeletingAlert(false)}>Отменить</button>
          </div>
        </AlertPopup>
      )}
    </>
  );
};

export default BankDetails;
