import { message } from "antd";
import moment, { Moment } from "moment";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { auditDatesSelector } from "../../../../../../redux/modules/common/constructing/finance/audit/selectors";
import { setAuditDates } from "../../../../../../redux/modules/common/constructing/finance/audit/thunks";

import Calendar from "components/UI/molecules/Calendar/Calendar";

import { LABELS } from "./constants";

import styles from "./DatePicker.module.scss";

const DatePicker = () => {
  const dispatch = useDispatch();
  const dates = useSelector(auditDatesSelector) || {};

  const startMoment = useMemo(() => moment(dates.start), [dates.start]);
  const endMoment = useMemo(() => moment(dates.end), [dates.end]);

  const handleSetDateStart = (dateStart: Moment) => {
    const startMomentCandidate = moment(dateStart);
    if (startMomentCandidate.isAfter(endMoment)) {
      message.error("Начало периода должно предшествовать его окончанию");
      return;
    }
    dispatch(setAuditDates({ start: startMomentCandidate.format("YYYY-MM-DD"), end: dates.end }));
  };

  const handleSetDateEnd = (dateEnd: Moment) => {
    const endMomentCandidate = moment(dateEnd);
    if (endMomentCandidate.isBefore(startMoment)) {
      message.error("Окончание периода должно следовать за его началом");
      return;
    }
    dispatch(setAuditDates({ start: dates.start, end: endMomentCandidate.format("YYYY-MM-DD") }));
  };

  return (
    <div className={styles.datePicker}>
      <div className={styles.datePicker__text}>{LABELS.SELECTED_PERIOD}</div>
      <span className={styles.gap}>с</span>
      <Calendar value={startMoment} setValue={handleSetDateStart} />
      <span className={styles.gap}>по</span>
      <Calendar value={endMoment} setValue={handleSetDateEnd} />
    </div>
  );
};

export default React.memo(DatePicker);
