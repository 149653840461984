import cn from "classnames";
import React from "react";

import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";

import styles from "./PeriodOfExecution.module.scss";

type propsType = {
  dateStart: string;
  dateEnd: string;
  isDisabled?: boolean;
  isVertical?: boolean;
  className?: string;
};

function PeriodOfExecution({ dateEnd, dateStart, isDisabled = true, isVertical = false, className }: propsType) {
  return (
    <div className={cn(styles.pair, styles.centered, { [styles.vertical]: isVertical }, className)}>
      <b>Период выполнения:</b>
      {/* @ts-ignore */}
      <CalendarRange
        defaultDateStart={dateStart}
        defaultDateEnd={dateEnd}
        disabled={isDisabled}
        classNameSelect={styles.calendar}
        isTooRight
      />
    </div>
  );
}

export default PeriodOfExecution;
