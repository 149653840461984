import React, { useCallback, useState } from "react";

import MatchingIcon from "../../../../../../UI/atoms/MatchingIcon/MatchingIcon";
import ProductInfoModal from "../../../../../../UI/molecules/ProductInfoModal/ProductInfoModal";

import { getWriteOffMatchingStatus } from "./getWriteOffMatchingStatus";

import { getProductMatchingStatus } from "../../../../utils/getProductMatchingStatus";

const ProductMatching = ({ product, onEditProductMatching, matchedMap }) => {
  const [isMatchingInfoModalOpen, setIsMatchingInfoModalOpen] = useState(false);

  const openMatchingInfoModal = useCallback(() => setIsMatchingInfoModalOpen(true), []);
  const closeMatchingInfoModal = useCallback(() => setIsMatchingInfoModalOpen(false), []);

  const handleOnEditProductMatching = useCallback(
    () => onEditProductMatching(product),
    [onEditProductMatching, product]
  );

  const onClickByMatchingIcon = useCallback(() => {
    if (product?.product_building?.estimate_expenditure) {
      openMatchingInfoModal();
    } else {
      handleOnEditProductMatching();
    }
  }, []);

  if (product?.product_building?.type === "additional") {
    return null;
  }

  return (
    <>
      {product?.product_building?.estimate_expenditure && (
        <ProductInfoModal
          isOpen={isMatchingInfoModalOpen}
          onClose={closeMatchingInfoModal}
          onOpenEditing={handleOnEditProductMatching}
          section={product?.product_building?.estimate_expenditure?.section.name}
          number={product?.product_building?.estimate_expenditure?.number}
          justification={product?.product_building?.estimate_expenditure?.justification}
          expenditureName={product?.product_building?.estimate_expenditure?.name}
          price={product?.product_building?.estimate_expenditure?.price}
          count={product?.product_building?.estimate_expenditure?.count}
          canEdit
        />
      )}
      <MatchingIcon
        onClick={onClickByMatchingIcon}
        status={getWriteOffMatchingStatus(
          {
            ...product,
            product_building: {
              ...(product?.product_building || {}),
              created_from_estimate: product?.created_from_estimate,
            },
          },
          matchedMap?.[product.id]
        )}
      />
    </>
  );
};

export default React.memo(ProductMatching);
