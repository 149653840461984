import styles from "../ShiftsModal.module.scss";
import { dropNonSignificantZeros } from "../../../../../../../utils/formatters/dropNonSignificantZeros";
import cn from "classnames";
import moment from "moment/moment";
import InputBase, { VALUE_TYPES } from "../../../../../../UI/atoms/InputBase";
import BottomControls from "../../../../../../UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ButtonBase from "../../../../../../UI/atoms/ButtonBase";
import React, { ChangeEvent, FC, memo, useCallback, useMemo, useState } from "react";
import { ReactComponent as ArrowUp } from "../../../../../../../shared/assets/icons/ArrowUp.svg";
import { ReactComponent as IconWarning } from "../../../../../../../shared/assets/icons/IconWarning.svg";
import { ReactComponent as IconCheck } from "../../../../../../../shared/assets/icons/IconCheck.svg";
import { IShiftDetail } from "../types";
import { getDayLabel, serializeShiftPlanData } from "../utils";
import { Spinner } from "../../../../../../UI/Spinner/Spinner";
import { useSelector } from "react-redux";
import {
  plannedSectionsDataSelector, planPlannedSectionsDataSelector, planSectionsDataSelector,
  sectionsDataSelector
} from "../../../../../../../redux/modules/common/building/manufacturing/selectors";
import { useLocation } from "react-router-dom";


interface ISpecificPlanContentProps {
  data: IShiftDetail;
  isApproveActionOpened: boolean;
  setIsApproveActionOpened: (v: boolean) => void;
  onCancel: () => void;
  onMove: (days: number, finallyCallback: () => void) => void;
}

const SelectedPlanContent: FC<ISpecificPlanContentProps> = (props) => {
  const {
    data,
    isApproveActionOpened,
    setIsApproveActionOpened,
    onCancel,
    onMove
  } = props;

  const location = useLocation();
  const isConstructing = location.pathname.includes('constructing');

  const sections = useSelector(isConstructing ? sectionsDataSelector : planSectionsDataSelector);
  const plans = useSelector(isConstructing ? plannedSectionsDataSelector : planPlannedSectionsDataSelector);

  const serializedShift = serializeShiftPlanData(data);

  const [shiftDays, setShiftDays] = useState("1");
  const [isRelatedPlansOpened, setIsRelatedPlansOpened] = useState(false);
  const [isShiftMoving, setIsShiftMoving] = useState(false);

  const materials = serializedShift.not_enough;
  const relatedPlans = serializedShift.related;

  let isCritical = useMemo(() => {
    const plansDatesMoments = [moment(serializedShift.end_at, 'DD.MM.YYYY'), ...serializedShift.related.map(r => moment(r.end_at, 'YYYY-MM-DD'))];
    let maxPlanDateMoment = plansDatesMoments[0];

    plansDatesMoments.forEach((m) => {
      plansDatesMoments.forEach((m2) => {
        if (m.isSameOrAfter(m2)) {
          maxPlanDateMoment = m;
        }
      })
    });

    const lsrId = sections.find(s => s.name === serializedShift.parent_section)?.id;
    const sectionId = sections.find(s => s.name === serializedShift.section)?.id;

    const sectionPlan = plans.filter(p => p.section_id === sectionId)?.[0];
    const lsrPlan = plans.filter(p => p.section_id === lsrId)?.[0];

    const lsrPlanEndAt = lsrPlan ? moment(lsrPlan.end_at, "YYYY-MM-DD") : null;
    const sectionPlanEndAt = sectionPlan ? moment(sectionPlan.end_at, "YYYY-MM-DD") : null;

    let momentToCheck;

    if (lsrPlanEndAt) {
      momentToCheck = moment(lsrPlanEndAt, "YYYY-MM-DD");
    }

    if (sectionPlanEndAt) {
      const sectionPlanEndMoment = moment(sectionPlanEndAt, "YYYY-MM-DD");

      if (momentToCheck) {
        momentToCheck = momentToCheck.isAfter(sectionPlanEndMoment) ? sectionPlanEndMoment : momentToCheck;
      } else {
        momentToCheck = sectionPlanEndMoment;
      }
    }

    const maxPlanDateMomentAfterShift = maxPlanDateMoment ? maxPlanDateMoment.clone().add(+shiftDays, 'days') : null

    return maxPlanDateMomentAfterShift && momentToCheck
      ? maxPlanDateMomentAfterShift.isAfter(momentToCheck)
      : false;
  }, [sections, plans, data, shiftDays]);

  const handleOnMove = useCallback(() => {
    setIsShiftMoving(true);
    onMove(+shiftDays, () => {
      setIsShiftMoving(false);
      setIsApproveActionOpened(false);
    });
  }, [shiftDays, onMove]);

  const handleActionClick = useCallback(() => {
    setIsApproveActionOpened(true);
  }, []);

  const onShiftDaysChange = (e: ChangeEvent<HTMLInputElement>) => {
    // для запрета ввода дробного числа
    if (!e.target.value.includes('.')) {
      setShiftDays(e.target.value);
    }
  }


  if (isApproveActionOpened) {
    return (
      <>
        <div className={styles.centerContent}>
          {isShiftMoving ? (
            <Spinner className={cn(styles.spinner, styles.smallMargin)} />
          ) : (
            <>
              <IconWarning
                className={cn(styles.warningIcon, {[styles.critical]: isCritical})}
              />
              {isCritical ? (
                <>
                  <span className={styles.attention}>Внимание!</span>
                  <span>
                    Вы хотите сдвинуть график работ на {shiftDays} {getDayLabel(+shiftDays)},<br/>
                    это повлияет на критический путь проекта.
                  </span>
                </>
              ) : (
                <span>
                Вы действительно хотите сдвинуть<br/>график работы на {shiftDays} {getDayLabel(+shiftDays)}?
              </span>
              )}
            </>
          )}
        </div>

        <footer>
          <BottomControls
            className={cn(styles.controls, styles.double)}
            wrapperClassName={styles.controlsWrapper}
            isExists={true}
          >
            <ButtonBase
              secondary
              className={styles.bigButton}
              onClick={() => setIsApproveActionOpened(false)}
            >
              Отмена
            </ButtonBase>
            <ButtonBase
              className={styles.bigButton}
              onClick={handleOnMove}
            >
              Подтвердить
            </ButtonBase>
          </BottomControls>
        </footer>
      </>
    )
  }

  return (
    <>
      {!!materials.length ? (
        <>
          <div className={styles.materials}>
            <div className={styles.materialsHeader}>
              Недостающие материалы для начала работы:
            </div>

            <div className={styles.materialsItemsWrapper}>
              {materials.map((m, idx) => (
                <div className={styles.materialsItem} key={`${idx}_${m.name}`}>
                  <span className={styles.materialItemName}>{m.name}</span>
                  <div className={styles.materialInfo}>
                    <div className={styles.materialInfoItem}>
                      <span className={styles.materialInfoItemName}>В наличии</span>
                      <span className={styles.materialInfoItemCount}>{dropNonSignificantZeros(m.stock_count)} {m.measure}</span>
                    </div>
                    <div className={styles.materialInfoItem}>
                      <span className={styles.materialInfoItemName}>Необходимо</span>
                      <span className={styles.materialInfoItemCount}>{dropNonSignificantZeros(m.count)} {m.measure}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {relatedPlans.length ? (
            <div className={styles.relatedPlans}>
              <div
                className={styles.relatedPlansHeader}
                onClick={() => {
                  setIsRelatedPlansOpened(prev => !prev);
                }}
              >
                <span>Также будут затронуты связанные планы работ: {relatedPlans.length}</span>
                <ArrowUp className={cn({[styles.arrow]: true, [styles.opened]: isRelatedPlansOpened})} />
              </div>
              <div className={cn({[styles.relatedPlansItems]: true, [styles.opened]: isRelatedPlansOpened})}>
                {relatedPlans.map((plan, idx) => {
                  const dateStart =  moment(plan.start_at).format("DD.MM.YYYY");
                  const dateEnd =  moment(plan.end_at).format("DD.MM.YYYY");

                  return (
                    <div
                      className={styles.relatedPlansItem}
                      key={plan.id}
                    >
                      <span className={styles.relatedPlansItemName}>
                        {plan.name}
                      </span>
                      <div className={styles.relatedPlansItemInfo}>
                        <span>Выполнение с&nbsp;</span>
                        <span className={styles.date}>{dateStart} по {dateEnd}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}

          <div className={styles.shiftDays}>
            Сдвинуть график выполнения работ на&nbsp;
            <InputBase
              className={styles.input}
              value={shiftDays}
              onChange={onShiftDaysChange}
              valueType={VALUE_TYPES.NUMBER}
            />&nbsp;
            {getDayLabel(+shiftDays)}
          </div>
        </>
        )
        : (
        <div className={styles.centerContent}>
          <IconCheck />
          <span>Недостающих материалов для начала работы нет.<br/>Сдвиг графика не требуется.</span>
        </div>
      )}

      <footer>
        <BottomControls
          className={styles.controls}
          wrapperClassName={styles.controlsWrapper}
          isExists={true}
        >
          {materials.length ? (
            <>
              <ButtonBase
                primary={true}
                disabled={!+shiftDays}
                onClick={handleActionClick}
                className={styles.bigButton}
              >
                Сдвинуть график
              </ButtonBase>
            </>
          ) : (
            <ButtonBase
              primary={true}
              onClick={onCancel}
              className={styles.bigButton}
            >
              Хорошо!
            </ButtonBase>
          )}
        </BottomControls>
      </footer>
    </>
  )
}

export default memo(SelectedPlanContent);