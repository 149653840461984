import React from "react";

import Expandable from "../../atoms/Expandable/Expandable";
import InputFiles from "../../atoms/InputFiles/InputFiles";

import downloadFileByUrl from "../../../../utils/helpers/downloadFileByUrl";

import styles from "./ExpandableFileInput.module.scss";

type propsType = {
  files: any[];
  setFiles?: (file: any) => void;
  onUploadFileDirectly?: (file: any) => void;
  onRemoveFileDirectly?: (fileId: number) => void;
  canExtractFiles?: boolean;
  canUploadFiles?: boolean;
  canDeleteFiles?: boolean;
  title?: string;
  isLoading?: boolean;
};

function ExpandableFileInput({
  files,
  setFiles,
  onUploadFileDirectly,
  onRemoveFileDirectly,
  canExtractFiles = true,
  canUploadFiles = true,
  canDeleteFiles = true,
  title = "Список вложений:",
  isLoading,
}: propsType) {
  const getFilesHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    files?.forEach((el) => {
      downloadFileByUrl(el.file, el.name || el.originalname);
    });
  };

  return (
    //@ts-ignore
    <Expandable
      className={styles.filesContainer}
      additionalHeadline={
        <div className={styles.filesHead}>
          <span>
            {title} {files?.length}
          </span>
          {canExtractFiles && !!files.length && <div onClick={getFilesHandler}>Выгрузить</div>}
        </div>
      }
    >
      {/* @ts-ignore */}
      <InputFiles
        value={files}
        setValue={setFiles}
        uploadFilesDirectly={onUploadFileDirectly}
        removeFileDirectly={onRemoveFileDirectly}
        permissions={{
          addFiles: canUploadFiles,
          deleteFiles: canDeleteFiles,
        }}
        isLoading={isLoading}
      />
    </Expandable>
  );
}

export default React.memo(ExpandableFileInput);
