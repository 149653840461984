import cn from "classnames";
import moment from "moment";
import React from "react";
import { Field, Form } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import ButtonBase from "components/UI/atoms/ButtonBase";
import InputBase from "components/UI/atoms/InputBase";
import InputFiles, { MAX_DISPLAYING_FILES_COUNT } from "components/UI/atoms/InputFiles/InputFiles";
import Calendar from "components/UI/molecules/Calendar/Calendar";
import AddOneFromTable from "components/UI/organism/AddOneFromTable/AddOneFromTable";

import { ICreateAndEditRemarkFieldValues } from "../../types/types";
import { IFile } from "types/interfaces/Files";
import { IEmployee } from "types/personsTypes";

import { useRemarkForm } from "../../hooks/useRemarkForm";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import { ReactComponent as AddBtnIcon } from "images/icons/add-btn-circle-blue.svg";
import CalendarBlue from "images/icons/calendarBlue.svg";
import { ReactComponent as CloseBtnIcon } from "images/icons/close-btn-circle-pink.svg";
import { ReactComponent as RemoveIcon } from "images/icons/cross-icon-white-small.svg";

import styles from "./CreateOrEditRemarkForm.module.scss";

export interface IProps {
  onSubmit: (values: ICreateAndEditRemarkFieldValues) => void;
  initialValues: Record<string, string>;
  handleOpenTable: () => void;
  handleCloseTable: () => void;
  selectedWorker: Partial<IEmployee> | null;
  isTableOpen: boolean;
  handleRemoveSelected: () => void;
  handleSelectWorker: (worker: IEmployee) => void;
  files: IFile[];
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
  isResetFiles: boolean;
  isLoading: boolean;
  isEditRemarkForm?: boolean;
  handleCloseForm?: () => void;
  handleOpenDeleteModal?: () => void;
  isShared?: boolean;
  intervalId: number;
}

const CreateOrEditRemarkForm = React.forwardRef<HTMLFormElement, IProps>(
  (
    {
      onSubmit,
      initialValues,
      handleOpenTable,
      handleCloseTable,
      selectedWorker,
      isTableOpen,
      handleRemoveSelected,
      handleSelectWorker,
      files,
      setFiles,
      isResetFiles,
      isLoading,
      isEditRemarkForm,
      handleCloseForm,
      handleOpenDeleteModal,
      isShared,
      intervalId,
    },
    ref
  ) => {
    const { isShowSelectTableIcon, isShowWorkersTable, isShowSelectedWorkers, filteredWorkers } = useRemarkForm({
      selectedWorker,
      isTableOpen,
      isShared,
      intervalId,
    });

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ form, handleSubmit, hasValidationErrors }) => (
          <form ref={ref} className={styles.form} onReset={() => form.restart()} onSubmit={handleSubmit}>
            <div className={styles.paddings}>
              <div className={styles.field}>
                <Field // @ts-ignore
                  component={InputBase}
                  name={"name"}
                  label={"Наименование"}
                  placeholder={"Введите наименование замечания"}
                  validate={maxLength(255, "Не более 255 символов")}
                />
              </div>
              <div className={styles.field}>
                <Field
                  name={"description"}
                  validate={composeFieldValidators(required(), maxLength(255, "Не более 255 символов"))}
                  render={({ input, meta }) => (
                    <div>
                      <label>Описание</label>
                      <TextareaAutosize {...input} placeholder={"Подробное описание замечания"} minRows={3} />
                      {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
                    </div>
                  )}
                />
              </div>
              <div className={styles.field}>
                <Field // @ts-ignore
                  component={InputBase}
                  name={"regulations"}
                  label={"Нормативные документы"}
                  placeholder={"Укажите ссылки на документы"}
                  validate={maxLength(255, "Не более 255 символов")}
                />
              </div>
              <div className={cn(styles.flexBetween, styles.field)}>
                <Field
                  name={"resolve_date"}
                  render={({ input, meta }) => (
                    <div className={styles.calendar}>
                      <label className={styles.label}>Срок устранения</label>
                      <Calendar
                        classNameSelect={styles.calendarSelect}
                        classNameOptions={styles.calendarOptions}
                        label={input.label}
                        icon={CalendarBlue}
                        placeholder={moment().format("L")}
                        value={input.value || meta.initial}
                        setValue={input.onChange}
                        format={"L"}
                      />
                    </div>
                  )}
                />
                <div>
                  <label className={styles.label}>Добавить ответственного</label>
                  <div className={styles.responsible}>
                    {isShowSelectTableIcon && <AddBtnIcon onClick={handleOpenTable} />}
                    {isShowWorkersTable && (
                      <div className={styles.cancel} onClick={handleCloseTable}>
                        <CloseBtnIcon />
                        <span>Отменить</span>
                      </div>
                    )}
                    {isShowSelectedWorkers && (
                      <div className={styles.selected}>
                        {sliceTextByConstraint(selectedWorker?.short_full_name, 18)}
                        <RemoveIcon onClick={handleRemoveSelected} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {isShowWorkersTable && (
              <div className={styles.table}>
                <AddOneFromTable // @ts-ignore
                  rowList={filteredWorkers}
                  headerCellName="ФИО"
                  onRowClick={handleSelectWorker}
                />
              </div>
            )}
            <div className={cn(styles.files, styles.paddings)}>
              <label className={styles.label}>Список вложений: {files.length || "-"}</label>
              <InputFiles
                value={files}
                setValue={setFiles}
                canExpand={files?.length > MAX_DISPLAYING_FILES_COUNT}
                isReset={isResetFiles}
              />
            </div>
            {isEditRemarkForm && (
              <div className={styles.deleteRemark}>
                <ButtonBase onClick={handleOpenDeleteModal} secondary medium>
                  Удалить замечание
                </ButtonBase>
              </div>
            )}
            <footer className={styles.footer}>
              {isEditRemarkForm ? (
                <>
                  <ButtonBase onClick={handleCloseForm} secondary medium className={styles.buttonCancel}>
                    Отменить
                  </ButtonBase>
                  <ButtonBase
                    type="submit"
                    primary
                    medium
                    disabled={hasValidationErrors || !selectedWorker}
                    isLoading={isLoading}
                  >
                    Сохранить
                  </ButtonBase>
                </>
              ) : (
                <ButtonBase
                  type="submit"
                  primary
                  medium
                  disabled={hasValidationErrors || !selectedWorker || isLoading}
                  isLoading={isLoading}
                >
                  Создать
                </ButtonBase>
              )}
            </footer>
          </form>
        )}
      />
    );
  }
);

export default React.memo(CreateOrEditRemarkForm);
