import cn from "classnames";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { auditTypeSelector } from "../../../../../../redux/modules/common/constructing/finance/audit/selectors";
import { toggleAuditType } from "../../../../../../redux/modules/common/constructing/finance/audit/thunks";
import { useTypedSelector } from "../../../../../../redux/typedUseSelector";

import LabeledSwitch from "../../../../../UI/atoms/LabeledSwitch";

import styles from "./AuditTypeSwitcher.module.scss";

export interface IAuditTypeSwitcherProps {
  className?: string;
}

const AuditTypeSwitcher: React.FC<IAuditTypeSwitcherProps> = ({ className }) => {
  const dispatch = useDispatch();
  const auditType = useTypedSelector(auditTypeSelector);

  const isSwitchActive = auditType === "forecast";

  const toggleSwitch = useCallback(() => {
    dispatch(toggleAuditType());
  }, []);

  return (
    <div className={cn(styles.container, className)}>
      <span>Аудит: </span>
      <LabeledSwitch label={"сводной"} labelRight={"прогноза"} value={isSwitchActive} onChange={toggleSwitch} />
    </div>
  );
};

export default React.memo(AuditTypeSwitcher);
