import { message } from "antd";
import React, { useEffect, useMemo, useState } from "react";

import { serializePlanMimsInEditPlan } from "components/pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/utils";

import { IMimInPlanInterval, IMimsInAddingInPlan, ISerializedMimsInPlan } from "types/interfaces/Mims";

const mimCountValidation = (mim: IMimsInAddingInPlan) =>
  mim?.local_count &&
  ((mim.sum_balance_count && +mim.local_count > +mim.sum_balance_count) ||
    (mim.count && !mim.available_count && +mim.local_count > +mim.count) ||
    (mim.available_count && +mim.local_count > +mim.available_count));

export const useSelectingMims = (
  defaultMims?: IMimInPlanInterval[],
  isExistingPlan?: boolean,
  isExpendituresGroup?: boolean
) => {
  const [addedMims, setAddedMims] = useState<ISerializedMimsInPlan[]>([]);
  const [selectedMims, setSelectedMims] = useState<ISerializedMimsInPlan[]>([]);
  const [deletedMims, setDeletedMims] = useState<ISerializedMimsInPlan[]>([]);

  const clearMims = () => {
    setAddedMims([]);
    setSelectedMims([]);
    setDeletedMims([]);
  };

  useEffect(() => {
    // фильтрация доступных к добавлению в факт МиМ (при создании факта)
    const filteredMims = defaultMims?.filter((m) => +m?.available_count !== 0);

    if (!filteredMims?.length) return;
    if (!!filteredMims?.[0]?.service) {
      setAddedMims(
        filteredMims.map((el) => ({
          id: el.service.id,
          count: el.count,
          name: el.service.name,
          measure: el.service.measure,
          available_count: el.available_count,
          local_count: el.count,
        }))
      );
    } else if (isExpendituresGroup) {
      setAddedMims(
        filteredMims.map((el) => ({
          ...el,
          local_count: el.local_count ?? el.count,
        }))
      );
    } else {
      setDeletedMims(filteredMims.filter((x) => deletedMims.findIndex((y) => y.id === x.id) !== -1));
      setAddedMims(filteredMims.filter((x) => deletedMims.findIndex((y) => y.id === x.id) === -1));
    }
    return () => {
      clearMims();
    };
  }, [defaultMims]);

  const addedMimsIds = useMemo(() => {
    return addedMims.map((el) => el.id);
  }, [addedMims]);

  const selectedMimsIds = useMemo(() => {
    return selectedMims.map((el) => el.id);
  }, [selectedMims]);

  const confirmMimsHandler: () => boolean = () => {
    if (selectedMims?.some((item) => !item.local_count || (item.local_count && +item.local_count <= 0))) {
      message.error("Необходимо указать количество для машин и механизмов");
      return false;
    }

    if (selectedMims?.some(mimCountValidation)) {
      message.error("Кол-во МиМ не должно превышать значение по проекту");
      return false;
    }
    setAddedMims((prev) => [...prev, ...selectedMims]);
    setDeletedMims((prevState) => prevState.filter((x) => selectedMims.findIndex((y) => y.id === x.id) === -1));
    setSelectedMims([]);
    return true;
  };

  const cancelMimsHandler: () => boolean = () => {
    setSelectedMims([]);
    return true;
  };

  const changeCountMimsHandler = (id: number, count: string | number, type: "selected" | "added") => {
    const mapper = (arr: any[]) =>
      arr.map((el) => {
        if (el.id === id) {
          return { ...el, local_count: count };
        } else {
          return el;
        }
      });
    if (type === "selected") {
      setSelectedMims((prev) => mapper(prev));
    } else {
      setAddedMims((prev) => mapper(prev));
    }
  };

  const validateSubmittingMims = () => {
    if (addedMims.some((el) => !el.local_count)) {
      message.error("Укажите кол-во каждому выбранному МиМу");
      return false;
    }
    if (addedMims.some(mimCountValidation)) {
      message.error("Кол-во МиМ не должно превышать значение по проекту");
      return false;
    }
    if (!!selectedMims.length) {
      message.warn("Подтвердите добавленные МиМ");
      return false;
    }
    return true;
  };

  const deleteAddedHandler = (id: number) => {
    const candidate = addedMims.find((x) => x.id === id);
    if (!candidate) return;
    setDeletedMims((prevState) => prevState.concat([candidate]));
    setAddedMims((prevState) => prevState.filter((el) => el.id !== id));
  };

  const selectMimsHandler = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    if (e.target.checked) {
      setSelectedMims((prev) => [...prev, item]);
    } else {
      setSelectedMims((prev) => prev.filter((el) => el.id !== item.id));
    }
  };

  const setPresettedMims = (presetedMims: Array<IMimsInAddingInPlan | ISerializedMimsInPlan>) => {
    if (!presetedMims?.length) return;
    setAddedMims(
      isExistingPlan
        ? serializePlanMimsInEditPlan(presetedMims as IMimsInAddingInPlan[])
        : (presetedMims as ISerializedMimsInPlan[])
    );
  };

  return {
    confirmMimsHandler,
    cancelMimsHandler,
    changeCountMimsHandler,
    validateSubmittingMims,
    deleteAddedHandler,
    selectMimsHandler,
    addedMims,
    selectedMims,
    selectedMimsIds,
    addedMimsIds,
    deletedMims,
    clearMims,
    setPresettedMims,
  };
};
