import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.extraForecast;

export const extraForecastDataSelector = createSelector(stateSelector, (state) => state.extraExpenses);
export const extraForecastPendingSelector = createSelector(stateSelector, (state) => state.isPending);
export const extraForecastInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const extraForecastSectionsSelector = createSelector(stateSelector, (state) => state.extraSectionsLite);
export const extraForecastSubsectionsSelector = createSelector(stateSelector, (state) => state.extraSubSectionsLite);
export const extraForecastDetailSelector = createSelector(stateSelector, (state) => state.detailedExpenses);
