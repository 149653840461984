import moment from "moment";
import React from "react";

import { IAnalyticsEventData } from "../../../../../../../redux/modules/common/constructing/analytics/types/events";

import Icon from "../../../../../../UI/Icon/Icon";
import Avatar from "../../../../../../UI/atoms/Avatar/Avatar";
import EventEntry from "../../../../../../UI/atoms/EventItem/EventEntry";
import EventItemBody, { IEventItemBodyProps } from "../../../../../../UI/atoms/EventItem/EventItemBody";

import { EVENT_TYPES } from "../../../../../../../types/interfaces/Event";
import { eventsNames } from "../../../../../Calendar/constants";

import { useUrlModuleWithObjectId } from "../../../../../../../utils/hooks/useUrlModuleWithObjectId";

import { transformDigitToFinancial } from "../../../../../../../utils/formatters/transformDigitToFinancial";

import calendarIcon from "../../../../../../../images/icons/calendarIcon.svg";

import styles from "./AnalyticsEventsBody.module.scss";

export interface IAnalyticsEventProps {
  event: IAnalyticsEventData;
  statusSwitcher: IEventItemBodyProps["eventStatusSwitcher"];
  openTask: (taskId: number) => void;
  addToRequisition?: (payload: {
    buildingId: number;
    productBuildingId: number;
    count: string;
    lastRequisitionDraftId: number | null;
  }) => void;
}

const AnalyticsEvent: React.FC<IAnalyticsEventProps> = ({ event, statusSwitcher, openTask, addToRequisition }) => {
  const urlAdapter = useUrlModuleWithObjectId();

  if (event.event_type === EVENT_TYPES.payment) {
    const paymentData = event.payment_data;
    if (!paymentData) return null;
    return (
      <EventItemBody
        status={null}
        name={eventsNames.payment}
        eventType={EVENT_TYPES.payment}
        isCompleted={paymentData.payment_is_paid}
        eventStatusSwitcher={statusSwitcher}
      >
        <EventEntry
          label={`Тип: `}
          value={paymentData.payment_type_display}
          bubbleLabel={`Счет №${paymentData.invoice_number}`}
          bubbleLink={`/${urlAdapter}/documents/bills/${paymentData.invoice_id}`}
          bubbleClassName={styles.eventBubble}
        />
        <EventEntry
          label={`Сумма: `}
          value={transformDigitToFinancial(paymentData.amount, {
            withFloat: true,
            dropZeros: true,
            withCurrencySign: true,
          })}
          bubbleLabel={`Заказ №${paymentData.order_number}`}
          bubbleLink={`/${urlAdapter}/orders/${paymentData.order_id}`}
          bubbleClassName={styles.eventBubble}
        />
      </EventItemBody>
    );
  }

  if (event.event_type === EVENT_TYPES.supply) {
    const supplyData = event.supply_data;
    if (!supplyData) return null;
    return (
      <EventItemBody
        status={supplyData.packinglist_status || supplyData.serviceact_status}
        name={eventsNames.supply}
        eventType={EVENT_TYPES.supply}
        isCompleted={false}
        eventStatusSwitcher={statusSwitcher}
      >
        <EventEntry label={`Объект: `} value={supplyData.building_name} />
        {!!supplyData.packinglist_id && !!supplyData.serviceact_id ? (
          <>
            <EventEntry
              label={`Сумма: `}
              value={transformDigitToFinancial(supplyData.sum_amount, {
                withFloat: true,
                dropZeros: true,
                withCurrencySign: true,
              })}
              bubbleLabel={`УПД`}
              bubbleLink={`/${urlAdapter}/documents/packing-lists/${supplyData.packinglist_id}`}
            />
            <EventEntry
              label={""}
              bubbleLabel={"Акт"}
              bubbleLink={`/${urlAdapter}/documents/service-acts/${supplyData.serviceact_id}`}
            />
            bubbleClassName={styles.eventBubble}
          </>
        ) : (
          <EventEntry
            label={`Сумма: `}
            value={transformDigitToFinancial(supplyData.sum_amount, {
              withFloat: true,
              dropZeros: true,
              withCurrencySign: true,
            })}
            bubbleLabel={!!supplyData.serviceact_id ? `Акт` : `УПД`}
            bubbleLink={
              !!supplyData.serviceact_id
                ? `/${urlAdapter}/documents/service-acts/${supplyData.serviceact_id}`
                : `/${urlAdapter}/documents/packing-lists/${supplyData.packinglist_id}`
            }
            bubbleClassName={styles.eventBubble}
          />
        )}
      </EventItemBody>
    );
  }

  if (event.event_type === EVENT_TYPES.task) {
    const taskData = event.task_data;
    if (!taskData) return null;
    return (
      <>
        <EventItemBody
          status={taskData.status}
          name={eventsNames.task}
          eventType={EVENT_TYPES.task}
          isCompleted={false}
          eventStatusSwitcher={statusSwitcher}
          onClick={() => openTask(taskData.id)}
          className={styles.taskEvent}
        >
          <EventEntry label={taskData.name} />
          <EventEntry
            label={`Назначил: `}
            value={
              <div className={styles.taskAvatars}>
                <Avatar person={taskData.creator.full_name} title={taskData.creator.full_name} />
                <svg xmlns="http://www.w3.org/2000/svg" width="9.82" height="18.225" viewBox="0 0 9.82 18.225">
                  <path
                    id="Path_8737"
                    data-name="Path 8737"
                    d="M0,0,8.759,8.759,17.518,0"
                    transform="translate(0.354 17.871) rotate(-90)"
                    fill="none"
                    stroke="#000"
                    strokeWidth="1"
                  />
                </svg>
                <Avatar person={taskData.executor.full_name} title={taskData.executor.full_name} />
              </div>
            }
            bubbleLabel={
              <div className={styles.taskDeadline}>
                <span>{`Срок до ${moment(taskData.deadline).format("DD/MM/YY")}`}</span>
                <Icon icon={calendarIcon} />
              </div>
            }
            withoutArrow
            bubbleClassName={styles.eventBubble}
          />
        </EventItemBody>
      </>
    );
  }

  if (event.event_type === EVENT_TYPES.order) {
    const orderData = event.order_data;
    if (!orderData) return null;
    return (
      <EventItemBody
        status={orderData.status}
        statusDisplay={orderData.status_display}
        name={eventsNames.order}
        eventType={EVENT_TYPES.order}
        isCompleted={false}
        eventStatusSwitcher={statusSwitcher}
      >
        <EventEntry label={orderData.name} />
        <EventEntry
          label={`Кол-во: `}
          value={`${orderData.count} шт.`}
          withBubblePlaceholder
          bubbleClassName={styles.eventBubble}
        />
        <EventEntry
          label={`Сумма: `}
          value={transformDigitToFinancial(orderData.amount, {
            withFloat: true,
            dropZeros: true,
            withCurrencySign: true,
          })}
          withBubblePlaceholder={!addToRequisition}
          bubbleLabel={!!addToRequisition ? `Добавить в заявку` : undefined}
          bubbleOnClick={() =>
            addToRequisition?.({
              buildingId: orderData.building_id,
              productBuildingId: orderData.product_building_id,
              count: orderData.count,
              lastRequisitionDraftId: orderData.last_requisition_draft_id,
            })
          }
          bubbleClassName={styles.eventBubble}
        />
      </EventItemBody>
    );
  }

  return null;
};

export default React.memo(AnalyticsEvent);
