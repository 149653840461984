import React from "react";
import { useParams } from "react-router-dom";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";

import { NavLinkIcon } from "../../../../UI/NavLinkIcon/NavLinkIcon";

function OrdersCell({ className, orders }) {
  const { objectId } = useParams();
  const urlAdapter = useUrlModule();

  const setOfOrders = React.useMemo(() => {
    const ids = [];
    const result = [];
    orders?.forEach((el) => {
      if (!ids.includes(el.order.id)) {
        result.push(el);
        ids.push(el.order.id);
      }
    });
    return result;
  }, [orders]);

  const urlPrefix = () => {
    if (urlAdapter !== "objects") return urlAdapter;
    return `${urlAdapter}/${objectId}`;
  };

  return (
    <div className={className}>
      {setOfOrders.map((el) => (
        <NavLinkIcon key={el.order?.id} to={`/${urlPrefix()}/orders/${el.order?.id}`} title={`№${el.order?.number}`} />
      ))}
    </div>
  );
}

export default React.memo(OrdersCell);
