import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import { getActingExpenditures } from "redux/modules/common/building/journal/acting/thunks";
import { IExpenditureInActing } from "redux/modules/common/building/journal/acting/types";

import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import JournalKs2ModalSubRow from "./JournalKs2ModalSubRow";

import { LOCALIZATION_CONFIG } from "constants/localization";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import styles from "./JournalKsModalRow.module.scss";

interface IProps {
  expenditures?: IExpenditureInActing[];
  isNeedLoad?: boolean;
  sectionId: number;
  estimateId: number;
  onDirectlyRemoveExpenditure?: (expenditureId: number) => void;
}

const JournalKs2ModalRowContent: React.FC<IProps> = ({
  isNeedLoad,
  expenditures,
  sectionId,
  estimateId,
  onDirectlyRemoveExpenditure,
}) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  React.useEffect(() => {
    if (!isNeedLoad) return;
    dispatch(getActingExpenditures(objectId, sectionId, estimateId));
  }, [isNeedLoad, objectId, sectionId, estimateId]);

  const deselectHandler = React.useCallback(
    (expenditureId: number) => {
      if (onDirectlyRemoveExpenditure) {
        onDirectlyRemoveExpenditure(expenditureId);
      } else {
        dispatch(actingActions.selectExpenditure(expenditureId, false, sectionId, estimateId));
      }
    },
    [estimateId, sectionId, onDirectlyRemoveExpenditure]
  );

  return (
    <>
      <TableReusableRow
        isHoverable={false}
        className={styles.sectionExpHeader}
        containerClassName={styles.sectionExpHeaderWrapper}
      >
        <TableReusableCell>№</TableReusableCell>
        <TableReusableCell>Наименование расценки</TableReusableCell>
        <TableReusableCell>Кол-во</TableReusableCell>
        <TableReusableCell>Стоимость, {LOCALIZATION_CONFIG.currency} </TableReusableCell>
      </TableReusableRow>
      {expenditures?.map((el) => (
        <JournalKs2ModalSubRow key={el.id} expenditure={el} onDeselect={deselectHandler} />
      ))}
    </>
  );
};

export default React.memo(JournalKs2ModalRowContent);
