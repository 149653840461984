import { Table as AntTable } from "antd";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { loadDetailedProjectForecast } from "redux/modules/common/constructing/finance/forecast/thunks";

import { Spinner } from "../../../../UI/Spinner/Spinner";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ListSide from "./components/ListSide/ListSide";
import TableSide from "./components/TableSide/TableSide";
import AddButton from "components/UI/atoms/AddButton/AddButton";
import ConfirmModal from "components/UI/molecules/ConfirmationModal/ConfirmModal";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import SummaryHeadline from "../Summary/SummaryHeadline/SummaryHeadline";
import { useSummaryScroll } from "../Summary/useSummaryScroll";
import AddExtraExpenseModal from "../widgets/AddExtraExpenseModal/AddExtraExpenseModal";
import EditExtraExpenseModal from "../widgets/AddExtraExpenseModal/EditExtraExpenseModal";
import { extraForecastInvalidateKeySelector } from "../widgets/AddExtraExpenseModal/model/selectors";
import { deleteExtraForecast, refetchExtraForecast } from "../widgets/AddExtraExpenseModal/model/thunks";
import { useForecastData } from "./useForecastData";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";

import { MonthAndQuarterArray, MonthArray } from "../../../../../constants/constant";
import { LOCALIZATION_CONFIG } from "../../../../../constants/localization";
import { VIEW_FINANCE_ADD_EXTRA_FORECAST } from "constants/permissions/constructingPermissions";

import usePermission from "hooks/usePermission";

import { isFinanceFooter, isQuartalColumns } from "../_internal/utils";

import financeIcon from "../../../../../images/icons/navigation/finance.svg";

import styles from "../Summary/Summary.module.scss";

export interface IForecastProps extends PropsWithChildren {}

const Forecast: React.FC<IForecastProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { projectId } = useParams<Record<string, string>>();
  const { measureInTitle } = useFinanceMeasure();
  const { displayedTree, isLoading, isFirstLoad, isLoadingByObject } = useForecastData();
  const { tableRef, year } = useSummaryScroll();
  const objectId = useObjectId(3);
  const invalidateKey = useSelector(extraForecastInvalidateKeySelector);
  const canAddExtraExpense = usePermission(VIEW_FINANCE_ADD_EXTRA_FORECAST);

  const showAdditionalExpenses = !!objectId && objectId !== "0";
  const showAddExtraExpenses = showAdditionalExpenses && canAddExtraExpense;

  const [isExtraModal, setIsExtraModal] = useState(false);
  const [openedExtraCost, setOpenedExtraCost] = useState(0);

  useEffect(() => {
    if (!showAdditionalExpenses) return;
    dispatch(refetchExtraForecast(objectId));
  }, [objectId, invalidateKey]);

  const [deletingExtra, setDeletingExtra] = useState(0);

  const confirmDelete = () => {
    dispatch(deleteExtraForecast(objectId, deletingExtra));
    setDeletingExtra(0);
  };

  if (isLoading || isLoadingByObject) return <Spinner />;

  if (!displayedTree?.length) return <EmptyPlaceholder img={financeIcon} />;

  return (
    <div className={styles.summary}>
      <div className={styles.summary__listSide}>
        <ListSide
          onDeleteExtraItem={setDeletingExtra}
          onClickExtraItem={setOpenedExtraCost}
          additionalHeader={
            showAddExtraExpenses ? (
              <AddButton onClick={() => setIsExtraModal(true)} text="Статья расхода" textPosition="left" />
            ) : (
              <div style={{ height: "calc(2rem + 1px)" }}></div>
            )
          }
        >
          {children}
        </ListSide>
      </div>
      <div className={styles.summary__tableSide}>
        <div className={styles.sticky} ref={tableRef}>
          <SummaryHeadline location="forecast" />
          <AntTable dataSource={[]} size="small" scroll={{ x: 1300 }} pagination={false}>
            {isQuartalColumns(objectId, projectId)
              ? MonthAndQuarterArray.map((month) => (
                  <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                    <Column
                      title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`plan_${month.id}`}
                      key={`plan_${month.id}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`fact_${month.id}`}
                      key={`fact_${month.id}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`diff_${month.id}`}
                      key={`diff_${month.id}`}
                      width={116}
                      ellipsis
                    />
                  </ColumnGroup>
                ))
              : MonthArray.map((month, idx) => (
                  <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                    <Column
                      title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`plan_${idx}`}
                      key={`plan_${idx}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`fact_${idx}`}
                      key={`fact_${idx}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`diff_${idx}`}
                      key={`diff_${idx}`}
                      width={116}
                      ellipsis
                    />
                  </ColumnGroup>
                ))}
          </AntTable>
        </div>
        <TableSide />
      </div>
      <AddExtraExpenseModal isOpen={isExtraModal} onClose={() => setIsExtraModal(false)} buildingId={objectId} />
      <EditExtraExpenseModal
        expenseId={openedExtraCost}
        isOpen={!!openedExtraCost}
        onClose={() => setOpenedExtraCost(0)}
        buildingId={objectId}
      />
      <ConfirmModal
        title="Вы уверены что хотите удалить?"
        variant="secondary"
        isOpen={!!deletingExtra}
        onClose={() => setDeletingExtra(0)}
        action={confirmDelete}
        acceptButtonText="Удалить"
      />
    </div>
  );
};

export default React.memo(Forecast);
