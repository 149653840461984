import moment, { Moment } from "moment";
import React, { useCallback, useMemo } from "react";

import { WEEK, YEAR } from "../../../../../redux/modules/common/building/manufacturing/manufacturing";

import ButtonArrow from "../../../../UI/atoms/ButtonArrow/ButtonArrow";
import TabBarNotLinks, { ITab } from "../../../../UI/atoms/TabBar/TabBarNotLinks";
import MonthsYearsSlider from "../../../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider";

import SendMultiplePlansForApprove from "widgets/SendMultiplePlansForApprove/SendMultiplePlansForApprove";

import { WEEK_SCROLL_LENGTH } from "../../constants";
import { IMonthArrayElement, ManufacturingTabsType } from "../../types";

import { useChartViewModeTabs } from "../../hooks/useChartViewModeTabs";

import styles from "../../Manufacturing.module.scss";

export interface IManufacturingControlsProps {
  year: number | string;
  month: IMonthArrayElement;
  handleDateChange: (newDate: Moment) => void;
  scrollCalendar: (units: number) => void;
  manufacturingTabs: ITab[];
  tab: ManufacturingTabsType;
  setTab: (_: string) => void;
  isEmpty: boolean;
}

const SELECTED_MONTHS_COUNT = 6;

const ManufacturingControls: React.FC<IManufacturingControlsProps> = ({
  year,
  month,
  handleDateChange,
  scrollCalendar,
  manufacturingTabs,
  tab,
  setTab,
  isEmpty,
}) => {
  const { chartViewMode, setViewTab, chartViewModeTabs } = useChartViewModeTabs(year, month?.id, handleDateChange);

  const increaseWeek = useCallback(() => scrollCalendar(WEEK_SCROLL_LENGTH), [scrollCalendar]);
  const decreaseWeek = useCallback(() => scrollCalendar(-WEEK_SCROLL_LENGTH), [scrollCalendar]);

  const selectedDate = useMemo(
    () =>
      moment()
        .year(+year)
        .month(
          chartViewMode === YEAR && month.id > SELECTED_MONTHS_COUNT ? month.id - SELECTED_MONTHS_COUNT + 1 : month.id
        )
        .date(1),
    [year, month, chartViewMode]
  );

  const yearViewLeftArrowHandler = useCallback(() => {
    if (selectedDate.month() === 0) {
      handleDateChange(
        moment()
          .year(+year - 1)
          .month(11)
          .date(1)
      );
      return;
    }
    if (selectedDate.month() < 3) {
      scrollCalendar(-SELECTED_MONTHS_COUNT + 1);
      return;
    }
    const cur = moment()
      .year(selectedDate.year())
      .month(selectedDate.month() - 1);
    const prev = moment()
      .year(selectedDate.year())
      .month(selectedDate.month() - 2);
    const weeksDiff = cur.week() - prev.week();
    scrollCalendar(-weeksDiff);
  }, [selectedDate, year]);

  const yearViewRightArrowHandler = useCallback(() => {
    if (selectedDate.month() === 12 - SELECTED_MONTHS_COUNT) {
      handleDateChange(
        moment()
          .year(+year + 1)
          .month(0)
          .date(1)
      );
      return;
    }
    if (selectedDate.month() >= SELECTED_MONTHS_COUNT - 1) {
      scrollCalendar(SELECTED_MONTHS_COUNT - 1);
      return;
    }
    if (selectedDate.month() === 0) {
      scrollCalendar(SELECTED_MONTHS_COUNT - 2);
      return;
    }
    const cur = moment().year(selectedDate.year()).month(selectedDate.month());
    const next = moment()
      .year(selectedDate.year())
      .month(selectedDate.month() + 1);
    const weeksDiff = next.week() - cur.week();
    scrollCalendar(weeksDiff);
  }, [selectedDate]);

  return (
    <div className={styles.controls}>
      <TabBarNotLinks tabs={manufacturingTabs} activeId={tab} onClick={setTab} />
      <div>
        {chartViewMode === YEAR ? (
          <MonthsYearsSlider
            selectingMonthsCount={SELECTED_MONTHS_COUNT}
            date={selectedDate}
            onChange={handleDateChange}
            className={styles.datePicker}
            onDirectlyClickLeftArrow={yearViewLeftArrowHandler}
            onDirectlyClickRightArrow={yearViewRightArrowHandler}
          />
        ) : (
          <MonthsYearsSlider
            date={selectedDate}
            onChange={handleDateChange}
            className={styles.datePicker}
            onDirectlyClickLeftArrow={undefined}
            onDirectlyClickRightArrow={undefined}
          />
        )}
      </div>
      {/*<SendMultiplePlansForApprove />*/}
      <TabBarNotLinks
        tabs={chartViewModeTabs}
        activeId={chartViewMode}
        onClick={setViewTab}
        className={styles.viewTabs}
        isOnlyTabsView
      />
      {chartViewMode === WEEK && !isEmpty && (
        <div className={styles.weeksLineControls}>
          <ButtonArrow direction={"left"} className={styles.weeksLineButton} onClick={decreaseWeek} />
          <ButtonArrow direction={"right"} className={styles.weeksLineButton} onClick={increaseWeek} />
        </div>
      )}
    </div>
  );
};

export default ManufacturingControls;
