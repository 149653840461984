import axios from "axios";
import { Scatter } from "recharts";
import { createSelector } from "reselect";

import {
  DEFAULT_ACCEPTED_FILTERS,
  DEFAULT_TRANSFERRED_FILTERS,
} from "../../../../components/pages/Constructing/Objects/constants";

import { loadObjectsBreadcrumbs } from "./objects";

import { hasOnlyPaginationFilters } from "../../../../utils/hasOnlyPaginationFilters";
import { errorCatcher } from "utils/helpers/errorCatcher";

const moduleName = "projects";
const LOAD_OBJECTS = `${moduleName}/LOAD_OBJECTS`;
const SET_OBJECTS = `${moduleName}/SET_OBJECTS`;
const ADD_OBJECTS = `${moduleName}/ADD_OBJECTS`;
const SET_IS_PROJECTS_LOADING = `${moduleName}/SET_IS_PROJECTS_LOADING`;
const SET_LOADING_MORE_OBJECTS = `${moduleName}/SET_LOADING_MORE_OBJECTS`;
const SET_SETTINGS_PROJECTS = `${moduleName}/SET_SETTINGS_PROJECTS`;
const SET_SETTINGS_PROJECTS_LOADING = `${moduleName}/SET_SETTINGS_PROJECTS_LOADING`;
const SET_MORE_SETTINGS_PROJECTS = `${moduleName}/SET_MORE_SETTINGS_PROJECTS`;

const initialState = {
  objects: { results: [] },
  accepted: { results: [] },
  transferred: { results: [] },
  cachedObjects: { results: [] },
  isLoading: false,
  loadingSave: false,
  events: [],
  isLoadingMoreObjects: false,
  isProjectsLoading: false,
  settingsProjects: {},
  isSettingsProjectsLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_PROJECTS_LOADING:
      return {
        ...state,
        isProjectsLoading: payload,
      };
    case SET_LOADING_MORE_OBJECTS:
      return {
        ...state,
        isLoadingMoreObjects: payload,
      };
    case LOAD_OBJECTS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_OBJECTS:
      return {
        ...state,
        [action.area || "objects"]: payload,
      };
    case ADD_OBJECTS:
      return {
        ...state,
        [action.area || "objects"]: {
          ...payload,
          results: [...state[action.area || "objects"].results, ...payload.results],
        },
        isLoading: false,
        isLoadingMoreObjects: false,
      };
    case SET_SETTINGS_PROJECTS:
      return { ...state, settingsProjects: { ...state.settingsProjects, [payload.projectId]: payload.data } };
    case SET_SETTINGS_PROJECTS_LOADING:
      return { ...state, isSettingsProjectsLoading: payload };
    case "objects/CLEAR":
      return {
        ...state,
        objects: { results: [] },
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const objectsSelector = createSelector(stateSelector, (state) => state.objects);
export const cachedObjectsSelector = createSelector(stateSelector, (state) => state.cachedObjects);
export const transferredSelector = createSelector(stateSelector, (state) => state.transferred);
export const acceptedSelector = createSelector(stateSelector, (state) => state.accepted);
export const objectsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const isProjectsLoadingSelector = createSelector(stateSelector, (state) => state.isProjectsLoading);
export const objectsLoadingMoreSelector = createSelector(stateSelector, (state) => state.isLoadingMoreObjects);
export const settingsProjectsSelector = createSelector(stateSelector, (state) => state.settingsProjects);
export const settingsProjectsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.isSettingsProjectsLoading
);

export const updateObjects = (payload, area) => ({
  type: SET_OBJECTS,
  payload: payload,
  area: area,
});

export const setCachedObjects = (payload) => ({
  type: SET_OBJECTS,
  payload: payload,
  area: "cachedObjects",
});

export const setIsProjectsLoading = (data) => ({
  type: SET_IS_PROJECTS_LOADING,
  payload: data,
});

export const setIsLoadingMoreObjects = (data) => ({
  type: SET_LOADING_MORE_OBJECTS,
  payload: data,
});

const setSettingsProjects = (data, projectId) => ({
  type: SET_SETTINGS_PROJECTS,
  payload: { data, projectId },
});

const setMoreSettingsProjects = (payload) => ({
  type: SET_MORE_SETTINGS_PROJECTS,
  payload,
});

const setSettingsProjectsLoading = (payload) => ({
  type: SET_SETTINGS_PROJECTS_LOADING,
  payload,
});

export const clearObjectsLegacy = () => ({
  type: "objects/CLEAR",
});

export const addLoadObjects = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      dispatch(setIsLoadingMoreObjects(true));
      axios
        .get(`/constructing/projects/`, config)
        .then((response) =>
          dispatch({
            type: ADD_OBJECTS,
            payload: response.data,
            area: config.params.shared_status,
          })
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const loadObjects = (paginationParams, filterParams, isReturnCachedObjects = false) => {
  const config = { params: { ...paginationParams, ...filterParams } };
  return (dispatch, getState) => {
    const cachedObjects = getState()?.objects?.cachedObjects;
    if (isReturnCachedObjects && !!cachedObjects?.results?.length) return;
    if (!getState()?.projects?.objects?.results?.length) {
      dispatch({
        type: LOAD_OBJECTS,
      });
    }
    !config.params.shared_status && dispatch(setIsProjectsLoading(true));
    axios
      .get(`/constructing/projects/`, config)
      .then((response) => {
        dispatch(updateObjects(response.data, config.params.shared_status));
        if (hasOnlyPaginationFilters(config.params) && config.params.limit >= 100)
          dispatch(setCachedObjects(response.data));
        !config.params.shared_status && dispatch(setIsProjectsLoading(false));
      })
      .catch(errorCatcher);
  };
};

export const loadMultipleObjects = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (objectsParams) => {
    if (ld_cancel) ld_cancel();
    return (dispatch, getState) => {
      Promise.all(
        objectsParams.map((params) => {
          if (!getState()?.projects?.objects?.results?.length) {
            dispatch({
              type: LOAD_OBJECTS,
            });
          }
          dispatch({
            type: LOAD_OBJECTS,
          });
          axios
            .get(`/constructing/projects/`, {
              params: {
                ...params["paginationParams"],
                ...params["filterParams"],
                ordering: params["ordering"],
              },
              cancelToken: new CancelToken((c) => {
                ld_cancel = c;
              }),
            })
            .then((response) => {
              dispatch(updateObjects(response.data, params?.filterParams?.shared_status));
            })
            .catch((e) => console.error(e));
        })
      );
    };
  };
})();

export const changeObjectStatus = (id, status, params) => {
  return (dispatch) => {
    return axios
      .post(`/building/${id}/estimate/items/entities/change_status/`, {
        status,
      })
      .then(() => {
        dispatch(loadMultipleObjects(params));
        dispatch(loadObjects(null, DEFAULT_ACCEPTED_FILTERS));
        dispatch(loadObjects(null, DEFAULT_TRANSFERRED_FILTERS));
        dispatch(loadObjectsBreadcrumbs());
      })
      .catch(errorCatcher);
  };
};

export const loadSettingsProjects =
  (requestParams, options = {}) =>
  (dispatch) => {
    if (!options.isLoadMore) {
      dispatch(setSettingsProjectsLoading(true));
    }
    return axios
      .get(`/constructing/projects/`, { params: { limit: 10, ...requestParams } })
      .then((response) => {
        if (!options.isLoadMore) {
          dispatch(setSettingsProjects(response.data, requestParams.project));
          dispatch(setSettingsProjectsLoading(false));
        } else {
          dispatch(setMoreSettingsProjects(response.data));
        }
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(setSettingsProjectsLoading(false));
      });
  };
