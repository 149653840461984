import cn from "classnames";
import React from "react";

import SearchFilterTable from "../../../../atoms/SearchFilterTable";

import styles from "./TableHeader.module.scss";

interface IProps {
  onFiltersChange?: (value: string, type: string) => void;
  className?: string;
}

const PlanMimsTableHeader: React.FC<IProps> = ({ onFiltersChange, className }) => {
  return (
    <div className={cn(styles.header, styles.planHeader, className)}>
      <div />
      <div className={styles.name}>
        МиМ
        {onFiltersChange && (
          <SearchFilterTable title="Материал" setFilter={(value) => onFiltersChange(value, "estimate_item_name")} />
        )}
      </div>
      <div>Кол-во</div>
      <div className={styles.centered}>По проекту</div>
    </div>
  );
}

export default React.memo(PlanMimsTableHeader);
