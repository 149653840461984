import { message } from "antd";
import axios from "axios";
import { createSelector } from "reselect";

import { contractorsStateSelector } from "../contractors";
import { moduleName as parentModuleName } from "./index";

import { messageErrorHandler } from "utils/helpers/errorHandler";

const initialState = {
  isLoading: true,
  contractor: null,
  deleteModalOpen: false,
};

const moduleName = "index";
const actionsPrefix = "constructing/counterparties/contractors/item";

const SET_LOADING = `${actionsPrefix}_SET_LOADING`;
const SET_CONTRACTOR = `${actionsPrefix}_SET_CONTRACTOR`;
const SET_DELETE_MODAL_STATE = `${actionsPrefix}_SET_DELETE_MODAL_STATE`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;

export const itemStateSelector = createSelector(contractorsStateSelector, (state) => state[parentModuleName]);
export const stateSelector = createSelector(itemStateSelector, (state) => state[moduleName]);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const contractorSelector = createSelector(stateSelector, (state) => state.contractor);
export const deleteModalOpenSelector = createSelector(stateSelector, (state) => state.deleteModalOpen);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_CONTRACTOR:
      return {
        ...state,
        contractor: payload,
      };
    case SET_DELETE_MODAL_STATE:
      return {
        ...state,
        deleteModalOpen: payload,
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const setContractor = (data) => ({
  type: SET_CONTRACTOR,
  payload: data,
});

export const loadContractor = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.get(`/contractors/${id}/`);
      dispatch(setContractor(response.data));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setDeleteModalState = (data) => ({
  type: SET_DELETE_MODAL_STATE,
  payload: data,
});

export const changeManager = (manager) => {
  return async (dispatch, getState) => {
    const contractor = contractorSelector(getState());
    try {
      await axios.post(`/contractors/${contractor.id}/change_manager/`, {
        manager_id: manager ? manager.id : null,
      });
      message.success("Успешно сохранено");
      dispatch(setContractor({ ...contractor, contact_manager: manager }));
    } catch (e) {
      messageErrorHandler(e.response.data.errors, true);
    }
  };
};

export const deleteContractor = () => {
  return async (_, getState) => {
    const contractor = contractorSelector(getState());
    try {
      await axios.delete(`/contractors/${contractor.id}/`);
    } catch (e) {
      console.error(e);
    }
  };
};

export const clearData = () => ({
  type: CLEAR_DATA,
});
