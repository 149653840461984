import React from "react";

import { IDetailedKs2InDocs } from "redux/modules/common/building/documents/ksList/types";
import { IDetailedKs2InActing } from "redux/modules/common/building/journal/acting/types";

import { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";

import KS2PageSubRow from "./KS2PageSubRow";

import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./KS2PageRowContent.module.scss";

interface IProps {
  expenditures: IDetailedKs2InDocs["items"][0]["expenditures"];
  sectionId: number;
}

const KS2PageRowContent: React.FC<IProps> = ({ expenditures, sectionId }) => {
  return (
    <>
      <div className={styles.thead}>
        <TableReusableHeaderCell>№</TableReusableHeaderCell>
        <TableReusableHeaderCell>Наименование расценки</TableReusableHeaderCell>
        <TableReusableHeaderCell isCentered>Кол-во</TableReusableHeaderCell>
        <TableReusableHeaderCell className={styles.right}>
          Стоимость, {LOCALIZATION_CONFIG.currency}{" "}
        </TableReusableHeaderCell>
      </div>
      {expenditures.map((el) => (
        <KS2PageSubRow key={el.expenditure_id} expenditure={el} sectionId={sectionId} />
      ))}
    </>
  );
};

export default React.memo(KS2PageRowContent);
