import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import InputFiles, { MAX_DISPLAYING_FILES_COUNT } from "components/UI/atoms/InputFiles/InputFiles";

import { IFile } from "types/interfaces/Files";

import styles from "./ReportFields.module.scss";

export interface IProps {
  description: string;
  setDescription: atch<React.SetStateAction<string>>;
  files: IFile[];
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
}

const ReportFields: React.FC<IProps> = ({ description, setDescription, files, setFiles }) => {
  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <label>Описание</label>
        <TextareaAutosize
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          placeholder={"Подробное описание выполненной работы"}
          minRows={3}
        />
      </div>
      <div className={styles.field}>
        <label>Список вложений: {files.length || "-"}</label>
        <InputFiles
          value={files} //@ts-ignore
          setValue={setFiles}
          canExpand={files?.length > MAX_DISPLAYING_FILES_COUNT}
        />
      </div>
    </div>
  );
};

export default React.memo(ReportFields);
