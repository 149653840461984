import { message } from "antd";
import axios from "axios";

export function apiGetAggregations(objectId) {
  return axios
    .get(`building/${objectId}/estimate/aggregations/`)
    .then(({ data }) => data)
    .catch((e) => console.error(e));
}
export function apiChangeStatusSections(objectId, data) {
  return axios
    .post(`/building/${objectId}/estimate/change_status/`, data)
    .then((res) => {
      message.success(`Выбранные разделы ${data?.status === "confirmed" ? "утверждены" : "отклонены"}`);
    })
    .catch((e) => console.error(e));
}
