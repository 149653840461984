import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { beautifyNumberValue } from "../../../../utils/formatters/beautifyNumberValue";
import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "../../../../utils/formatters/transformDigitToFinancial";

const useEstimatedPriceDoubleInput = ({ cost, count, changeCost }) => {
  const debouncedChangeCost = useMemo(() => debounce((cost) => changeCost(cost?.toFixed(2)), 500), [changeCost]);

  const [editableCost, setEditableCost] = useState(() =>
    transformDigitToFinancial(cost, { withFloat: true, dropZeros: true })
  );
  const [costByUnit, setCostByUnit] = useState(() =>
    transformDigitToFinancial(+cost / +count, { withFloat: true, dropZeros: true })
  );

  const onChangeCost = useCallback(
    (value) => {
      const beautifiedValue = transformDigitToFinancial(parseTransformedString(value), {
        withFloat: true,
        dropZeros: true,
      });
      const parsedNumberValue = parseTransformedNumber(beautifiedValue);
      if (isNaN(+parsedNumberValue) || +parsedNumberValue < 0) return;

      setEditableCost(beautifiedValue);
      setCostByUnit(transformDigitToFinancial(+parsedNumberValue / count, { withFloat: true, dropZeros: true }));
      debouncedChangeCost(parsedNumberValue);
    },
    [debouncedChangeCost, count]
  );

  const onChangeCostByUnit = useCallback(
    (costByUnit) => {
      let beautified = transformDigitToFinancial(parseTransformedString(costByUnit), {
        withFloat: true,
        dropZeros: true,
      });
      const parsedNumberValue = parseTransformedNumber(beautified);
      if (isNaN(+parsedNumberValue) || +parsedNumberValue < 0) return;

      setCostByUnit(beautified);
      setEditableCost(transformDigitToFinancial(parsedNumberValue * count, { withFloat: true, dropZeros: true }));
      debouncedChangeCost(parsedNumberValue * count);
    },
    [count, debouncedChangeCost]
  );

  return { editableCost, costByUnit, onChangeCost, onChangeCostByUnit };
};

export default useEstimatedPriceDoubleInput;
