import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";
import { betonIncomeStatusOptions } from "components/pages/Beton/constants";

import styles from "./../../BetonIncome.module.scss";

interface IProps {
  onChangeFilter: (name: string, value: string) => void;
  haveViewSendToProduction: boolean;
}

const BetonIncomeHeader: React.FC<IProps> = ({ onChangeFilter, haveViewSendToProduction }) => {
  return (
    <TableReusableHead className={cn(styles.row)} isSticky>
      <TableReusableHeaderCell
        filterField="name"
        filterTitle="Наименование"
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        Наименование
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="provider"
        filterTitle="Поставщик"
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        Поставщик
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Поставка</TableReusableHeaderCell>
      <TableReusableHeaderCell>
        <div className={styles.volumeHeader}>
          <div className={styles.volumeSubheader}>Объём</div>
          <div>
            <div className={styles.divider}></div>
          </div>
          <div className={styles.volumeSubheader}>Остаток</div>
          <div></div>
        </div>
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="status"
        filterTitle="Статус"
        filterType="select"
        filterOptions={betonIncomeStatusOptions}
        onChangeFilter={onChangeFilter}
      >
        Статус
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Файлы</TableReusableHeaderCell>
      {haveViewSendToProduction && (<div />)}
    </TableReusableHead>
  );
};

export default BetonIncomeHeader;
