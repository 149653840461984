import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { MONTH, YEAR } from "../../../redux/modules/common/building/manufacturing/manufacturing";
import {
  constructingExpandedBranchesSelector,
  diagramFiltersSelector,
  isLoadingChartDataSelector,
  isLoadingProjectsSelector,
  projectsSelector,
} from "../../../redux/modules/common/building/manufacturing/selectors";
import { IBuildingRoot } from "../../../redux/modules/common/building/manufacturing/types";
import { projectEstimateSelector } from "redux/modules/common/building/manufacturing/selectors";
import {
  loadManufacturingProjects,
  projectEstimateSet,
  setChartViewMode,
} from "redux/modules/common/building/manufacturing/thunks";

import { Spinner } from "../../UI/Spinner/Spinner";
import EmptyPlaceholder from "../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ForbiddenPage from "../../routes/components/ForbiddenPage/ForbiddenPage";
import CalendarBackground from "./components/CalendarBackground/CalendarBackground";
import CalendarDateLine from "./components/CalendarDateLine/CalendarDateLine";
import DiagramActions from "./components/DiagramActions/DiagramActions";
import Legend from "./components/Legend/Legend";
import ManufacturingControls from "./components/ManufacturingControls/ManufacturingControls";
import Month from "./components/Month/Month";
import ProjectsTree from "./components/ProjectsTree/ProjectsTree";
import ShiftsModal from "./components/modals/ShiftsModal/ShiftsModal";
import TemplateSimple from "components/UI/templates/TemplateSimple/TemplateSimple";

import { Xwrapper } from "react-xarrows";

import { IRouterParamsWithObjectId } from "../../../types/routerTypes";
import { DiagramFilters, WORKS_TAB_ID } from "./constants";
import { IMonthArrayElement, ISpittingTreeElement } from "./types";

import { useQueryParams } from "../../../utils/hooks/useQueryParams";
import { useCalendarScroll } from "./hooks/useCalendarScroll";
import { useChartViewModeTabs } from "./hooks/useChartViewModeTabs";
import { useIntervals } from "./hooks/useIntervals";
import { useProjectTree } from "./hooks/useProjectTree";
import { useSpittingTree } from "./hooks/useSpittingTree";
import { useTabsWithPermissions } from "./hooks/useTabsWithPermissions";

import {
  checkIsMonthBranchShownAsExpenditure,
  checkIsMonthBranchShownAsSection,
  checkIsShownSectionPlan,
  checkIsShownSuperSectionPlan,
  getMonthInfo,
  getWeeksInYear,
  spittingTreeGenerationFn,
} from "./utils";

import scheduleIcon from "../../../images/icons/navigation/scheduleIcon.svg";

import styles from "./Manufacturing.module.scss";

const Manufacturing = () => {
  const dispatch = useDispatch();
  const { objectId: projectId } = useParams<IRouterParamsWithObjectId>();

  const { chartViewMode } = useChartViewModeTabs();
  const calendarRef = useRef(null);
  const containerRef = useRef(null);

  const currentDay = moment().date();
  const currentMonth = moment().month();

  const projectEstimate = useSelector(projectEstimateSelector);
  const projects = useSelector(projectsSelector);
  const isProjectsLoading = useSelector(isLoadingProjectsSelector);
  const isLoadingChartData = useSelector(isLoadingChartDataSelector);

  const diagramFilters = useSelector(diagramFiltersSelector);
  const isPlanEditing = diagramFilters[DiagramFilters.plans_editing_enabled];

  const [isEmpty, setIsEmpty] = useState(false);
  const expandedBranches = useSelector(constructingExpandedBranchesSelector);
  const [month, setMonth] = useState<IMonthArrayElement | undefined>(() => getMonthInfo(currentMonth));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [filterValue, setFilterValue] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState({
    dateEnd: null,
    dateStart: null,
  });

  const weeksInYear = getWeeksInYear(year);

  const startWeek = 1;
  const endWeek = weeksInYear;

  const { tab, setTab, expenditureTypesToLoad, manufacturingTabs } = useTabsWithPermissions();

  const actualProjects = useMemo(() => {
    if (isProjectsLoading) return [];
    if (projectId) {
      return projects?.results?.filter((p: IBuildingRoot) => +p.id === +projectId);
    }
    return projects?.results;
  }, [projectId, projects, isProjectsLoading]);

  useEffect(() => {
    dispatch(loadManufacturingProjects());
  }, []);

  const { handleDateChange, scrollCalendar, touchedYears } = useCalendarScroll({
    calendarRef,
    month,
    year,
    projectId,
    setMonth,
    setYear,
  });

  useIntervals({ touchedYears, expenditureTypesToLoad });

  const { generateSpittingTree, isGeneratingTree, spittingTree, skipGeneratingTreeHandler } = useSpittingTree({
    spittingTreeGenerationFn,
    spittingTreeFnArgs: {
      actualProjects,
      expandedBranches,
      projectEstimate,
      tab,
      isHideExpenditures: chartViewMode === YEAR && !projectId,
    },
    projectId,
    filterParams: filterValue,
    selectedPeriod,
  });

  useEffect(() => {
    if (!actualProjects?.length) {
      skipGeneratingTreeHandler();
      return;
    }
    generateSpittingTree();
  }, [actualProjects, projectEstimate, tab, projectId, projectEstimate.size, touchedYears?.length]);

  useEffect(() => {
    if (!spittingTree.length || !projectId || !actualProjects) return;
    if (actualProjects[0]?.works_count === 0 && actualProjects[0]?.products_count === 0) setIsEmpty(true);
    return () => setIsEmpty(false);
  }, [spittingTree, projectId]);

  const { checkIsExpandedBranchId, toggleBranch } = useProjectTree({
    actualProjects,
    tab,
    projectEstimate,
    projectEstimateSet,
    generateSpittingTree,
    month,
    year,
    startWeek,
    endWeek,
    projectId,
    setIsEmpty,
    expandedBranches,
  });

  const checkIsCheckboxTrue = useCallback(
    (branch: ISpittingTreeElement) => {
      const expandedBranchesHasBranch = checkIsExpandedBranchId(branch.id);
      return branch.lvl === 1 || (branch.lvl === 2 && actualProjects?.length > 1) || branch.lvl === 3
        ? !expandedBranchesHasBranch
        : expandedBranchesHasBranch;
    },
    [checkIsExpandedBranchId, actualProjects?.length]
  );

  const checkIsCheckboxVisible = useCallback(
    (branch: ISpittingTreeElement) => {
      if (chartViewMode === YEAR && !projectId) {
        return branch.lvl < 3;
      }
      return branch.lvl < 4;
    },
    [chartViewMode, projectId]
  );

  React.useEffect(() => {
    return () => {
      dispatch(setChartViewMode(MONTH));
    };
  }, []);

  const isShownSpinner = !projectId || isGeneratingTree || isProjectsLoading || isLoadingChartData;
  const isFilterActive = useMemo(() => {
    return !!filterValue || (!!selectedPeriod.dateEnd && !!selectedPeriod.dateStart);
  }, [filterValue, selectedPeriod]);
  const isShownPlaceholder = isEmpty || (!spittingTree?.length && !isFilterActive && !isShownSpinner);

  const isShiftsModalOpenedQuery = useQueryParams("openShiftModal");
  const [isShiftsModalOpened, setIsShiftsModalOpened] = useState<boolean>(!!isShiftsModalOpenedQuery);
  const onShiftsModalClose = useCallback(() => {
    setIsShiftsModalOpened(false);
  }, []);

  useEffect(() => {
    setSelectedPeriod({
      dateEnd: null,
      dateStart: null,
    });
    setFilterValue("");
  }, [projectId, tab]);

  if (!manufacturingTabs?.length && !isShownSpinner) {
    return (
      <TemplateSimple>
        <ForbiddenPage />
      </TemplateSimple>
    );
  }

  return (
    <TemplateSimple contentClassName={styles.template} dataTestId="page_chart">
      {isShownSpinner ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          <ManufacturingControls
            year={year}
            month={month}
            handleDateChange={handleDateChange}
            scrollCalendar={scrollCalendar}
            manufacturingTabs={manufacturingTabs}
            tab={tab}
            setTab={setTab}
            isEmpty={isShownPlaceholder}
          />
          {isShownPlaceholder ? (
            <EmptyPlaceholder img={scheduleIcon} />
          ) : (
            <div className={styles.content} ref={containerRef}>
              <div
                className={cn(styles.calendar, "diagram_calendar")}
                ref={calendarRef}
                style={{ height: `${spittingTree.length * 3}rem` }}
              >
                <CalendarDateLine year={year} touchedYears={touchedYears} />
                <ProjectsTree
                  isHideFilters={tab === "work"}
                  spittingTree={spittingTree}
                  checkIsExpandedBranchId={checkIsExpandedBranchId}
                  toggleBranch={toggleBranch}
                  type={tab}
                  checkIsCheckboxTrue={checkIsCheckboxTrue}
                  checkIsCheckboxVisible={checkIsCheckboxVisible}
                  isPlanEditing={isPlanEditing}
                  setFilterValue={setFilterValue}
                  setSelectedPeriod={setSelectedPeriod}
                  isFilterVisible={tab === WORKS_TAB_ID}
                  isPeriodVisible={tab === WORKS_TAB_ID}
                />
                <CalendarBackground year={year} unitHeight={spittingTree.length} touchedYears={touchedYears} />
                <Xwrapper>
                  <Month
                    projects={actualProjects}
                    tree={spittingTree}
                    year={year}
                    touchedYears={touchedYears}
                    today={currentMonth === month?.id ? currentDay : -1}
                    type={tab}
                    startWeek={startWeek}
                    endWeek={endWeek}
                    checkIsMonthBranchShownAsSection={checkIsMonthBranchShownAsSection}
                    checkIsShownSectionPlan={checkIsShownSectionPlan}
                    checkIsShownSuperSectionPlan={checkIsShownSuperSectionPlan}
                    checkIsMonthBranchShownAsExpenditure={checkIsMonthBranchShownAsExpenditure}
                    isInConstructing={true}
                  />
                </Xwrapper>
                <div className={styles.legendaWrapper}>
                  <div className={styles.legenda}>
                    <Legend type={tab} />
                  </div>
                </div>
                {tab === WORKS_TAB_ID && (
                  <div
                    className={cn(styles.diagramActionsWrapper, {
                      [styles.diagramActionsWrapperMoreOffsetTop]: chartViewMode !== MONTH,
                    })}
                  >
                    <DiagramActions year={year} />
                  </div>
                )}
              </div>
              {isShiftsModalOpened && <ShiftsModal isOpened={true} onClose={onShiftsModalClose} objectId={projectId} />}
            </div>
          )}
        </div>
      )}
    </TemplateSimple>
  );
};

export default Manufacturing;
