import axios from "axios";

import { IDetailedProjectSummary, IProjectViewSummary } from "./types";

import downloadFile from "utils/helpers/download";

export const summaryApi = {
  getProjectsViewSummary: () => {
    return axios.get<IProjectViewSummary[]>(`/tree/projects/finance_pivot/`);
  },
  getDetailedProjectSummary: (objectId: number) => {
    return axios.get<IDetailedProjectSummary>(`/tree/finance_pivot/${objectId}/`);
  },
  downloadReport: (buildingId: number, reportName: string) => {
    downloadFile(`${axios.defaults.baseURL}/buildings/${buildingId}/finances/pivot/report/`, reportName, "POST");
  },
  //
  getDetailedProjectSummaryV2: (projectId: number) => {
    return axios.get(`/tree/projects/finance_pivot/`, { params: { project_id: projectId } });
  },
};
