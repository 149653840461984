import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";
import { betonExpenseStatusOptions } from "components/pages/Beton/constants";

import styles from "./../../BetonExpense.module.scss";

interface IProps {
  onChangeFilter: (name: string, value: string) => void;
}

const BetonExpenseHeader: React.FC<IProps> = ({ onChangeFilter }) => {
  return (
    <TableReusableHead isSticky className={styles.row}>
      <TableReusableHeaderCell
        filterField="name"
        filterTitle="Наименование"
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        Наименование
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Когда выдан</TableReusableHeaderCell>
      <TableReusableHeaderCell isCentered>Объём</TableReusableHeaderCell>
      {/*<TableReusableHeaderCell>*/}
      {/*  <div className={styles.axis}>*/}
      {/*    <div>Ось</div>*/}
      {/*    <div>*/}
      {/*      <div className={styles.divider} />*/}
      {/*    </div>*/}
      {/*    <div>Отметка</div>*/}
      {/*  </div>*/}
      {/*</TableReusableHeaderCell>*/}
      <TableReusableHeaderCell
        filterField="user"
        filterTitle="Ответственный"
        filterType="text"
        onChangeFilter={onChangeFilter}
      >
        Ответственный
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Файлы</TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="status"
        filterTitle="Статус"
        filterType="select"
        filterOptions={betonExpenseStatusOptions}
        onChangeFilter={onChangeFilter}
        isLast
      >
        Статус
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default BetonExpenseHeader;
