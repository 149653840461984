import React from "react";
import { useSelector } from "react-redux";

import ButtonBack from "../../../../UI/atoms/ButtonBack/ButtonBack";
import ButtonBase from "../../../../UI/atoms/ButtonBase";

import ProgressBar from "../ProgressBar/ProgressBar";

import { IbuildingSubsection } from "../../types";

import { formatNumberWithMeasure } from "../../../../../utils/formatters/formatNumberWithMeasure";
import { percentsFormatter } from "../../../../../utils/percentsFormatter";

import styles from "./ProjectHeader.module.scss";
import { useFinanceMeasure } from "../../../../../features/financeMeasure";

export interface IProps {
  subSection?: IbuildingSubsection | null;
  projectName?: string;
  isExists?: boolean;
  isShared: boolean;
  onBackClick: () => void;
  onApproveSharing: () => void;
  onDeclineSharing: () => void;
}

const ProjectSubsectionsHeader: React.FC<IProps> = ({
  subSection,
  isExists,
  onBackClick,
  isShared,
  onApproveSharing,
  onDeclineSharing,
  projectName,
}) => {
  const sum_child_subsections = subSection?.sum_child_subsections;
  const completedPercent = sum_child_subsections?.child_completed_percent
    ? Math.round(sum_child_subsections?.child_completed_percent)
    : 0;
  const { measure } = useFinanceMeasure();

  if (!isExists) return null;

  return (
    <div className={styles.header}>
      {onBackClick && <ButtonBack link={false} onClick={onBackClick} />}
      <h1 title={subSection?.name}>{subSection?.name}</h1>
      <span className={styles.sum}>
        {formatNumberWithMeasure(sum_child_subsections?.child_sum_estimate_amount, measure)}
      </span>
      <div className={styles.divider}></div>
      {projectName && isShared && <span className={styles.projectName}>{projectName}:</span>}
      {isShared && (
        <div className={styles.btns}>
          <ButtonBase onClick={onApproveSharing} secondary>
            Принять проект
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.8781 1.50005C10.6309 1.50025 8.43432 2.16678 6.56598 3.41538C4.69764 4.66397 3.2415 6.43854 2.38169 8.51469C1.52188 10.5908 1.29702 12.8753 1.73553 15.0793C2.17405 17.2832 3.25626 19.3076 4.84531 20.8965C6.43435 22.4855 8.45886 23.5675 10.6628 24.0058C12.8668 24.4441 15.1513 24.2191 17.2274 23.3591C19.3035 22.4991 21.0779 21.0428 22.3263 19.1743C23.5748 17.3059 24.2411 15.1092 24.2411 12.862C24.2453 11.3687 23.9543 9.88925 23.3847 8.50877C22.8152 7.1283 21.9783 5.87404 20.9223 4.81813C19.8663 3.76221 18.612 2.92548 17.2314 2.35604C15.8509 1.7866 14.3714 1.49569 12.8781 1.50005ZM11.3291 17.717L7.3521 13.74L8.0751 13.017L11.3291 16.271L19.2291 8.37105L19.9521 9.09405L11.3291 17.717Z"
                fill="#00DEC5"
                stroke="white"
                stroke-width="1.4"
              />
            </svg>
          </ButtonBase>
          <ButtonBase onClick={onDeclineSharing} secondary>
            Отклонить проект
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.8791 24.2244C10.2592 24.2171 7.72293 23.3019 5.70183 21.6349C3.68072 19.9678 2.29977 17.6519 1.79411 15.0812C1.28846 12.5106 1.68926 9.84412 2.92839 7.53577C4.16752 5.22742 6.16858 3.41981 8.5905 2.42069C11.0124 1.42157 13.7058 1.29266 16.2121 2.05591C18.7183 2.81917 20.8827 4.42742 22.3366 6.60688C23.7905 8.78634 24.444 11.4023 24.1862 14.0095C23.9284 16.6167 22.7747 19.054 20.922 20.9064C19.8673 21.9638 18.6136 22.8014 17.2331 23.3709C15.8525 23.9405 14.3725 24.2306 12.8791 24.2244ZM12.5011 17.2424V18.2424H13.5011V17.2424H12.5011ZM12.5011 7.24244V14.2424H13.5011V7.24244H12.5011Z"
                fill="#EB70B7"
                stroke="white"
                stroke-width="1.4"
              />
            </svg>
          </ButtonBase>
        </div>
      )}
      {!isShared && (
        <div className={styles.progress}>
          <ProgressBar completed={Math.round(+completedPercent)} displayValue={percentsFormatter(completedPercent)} />
        </div>
      )}
    </div>
  );
};

export default ProjectSubsectionsHeader;
