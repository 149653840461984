import moment from "moment";
import React from "react";

import { Popover } from "@blueprintjs/core";

import fork from "../../../images/icons/code.svg";

import styles from "./ForkProducts.module.scss";

export default class ForkProducts extends React.PureComponent {
  state = {
    isOpen: false,
    count: "",
    date: moment(this.props.disabledDate || Date.now()),
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.disabledDate !== this.props.disabledDate) {
      this.setState({
        count: "",
        date: moment(this.props.disabledDate || Date.now()),
      });
    }
  }

  changeDate = (val) =>
    this.setState({ date: val }, () => {
      const { count } = this.state;
      this.props.fork(count, val);
      this.setState(
        {
          count: "",
          date: moment(this.props.disabledDate || Date.now()),
        },
        this.close
      );
    });

  changeCount = (value) => {
    const { maxCount = 999999 } = this.props;
    if ((value.match(/^[0-9]+(\.\d{0,2})?$/) && parseFloat(value) <= maxCount - 0.01) || value === "") {
      this.setState({
        count: value,
      });
    }
  };

  fork = () => {
    const { count } = this.state;
    const { fork, disabledDate } = this.props;
    fork(count, moment(disabledDate));
    this.setState(
      {
        count: "",
        date: moment(disabledDate || Date.now()),
      },
      this.close
    );
  };

  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });

  render() {
    const { count, isOpen } = this.state;

    return (
      <>
        <Popover
          //isOpen={ isOpen }
          usePortal={false}
          interactionKind={"click"}
          //interactionKind={'click_target_only'}
          disabled={false}
          content={
            <div className={styles.popover}>
              <div className={styles.title}>Кол-во:</div>
              <input
                value={count}
                onChange={(e) => this.changeCount(e.target.value)}
                onBlur={(e) => {
                  !isNaN(parseFloat(e.target.value)) && this.changeCount(parseFloat(e.target.value || 0).toFixed(2));
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.fork();
                  }
                }}
              />
              {/*
              <div className={styles.title}>Дата:</div>
              <div style={{
                opacity: activeDateInput ? '1' : '0.5',
                cursor: activeDateInput ? 'pointer' : 'default'
              }}>
                <SingleDatePicker
                  //getCalendarContainer={trigger => trigger.parentNode}
                  className="ant-calendar-custom-flat ant-calendar-picker"
                  dropdownClassName="ant-calendar-dropdown-simple"
                  placeholder="Дата поставки"
                  onChange={this.changeDate}
                  format="DD.MM.YYYY"
                  value={date || null}
                  disabled={!activeDateInput}
                  disabledDate={endValue =>
                  {
                    return (
                      endValue.valueOf() < moment(Date.now()).subtract(1, 'days')
                      ||
                      endValue.format('YYYY-MM-DD') === moment(disabledDate).format('YYYY-MM-DD')
                    );
                  }}
                />
              </div>*/}
            </div>
          }
        >
          <img onClick={isOpen ? this.close : this.open} className={styles.forkIcon} alt={"разделить"} src={fork} />
        </Popover>
      </>
    );
  }
}
