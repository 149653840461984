import React, { PropsWithChildren, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { toggleOpenSummaryTree } from "../../../../../../redux/modules/common/constructing/finance/summary/thunks";
import { ISummaryTree } from "../../../../../../redux/modules/common/constructing/finance/summary/types";
import { objectIdSelector } from "redux/modules/common/constructing/finance/finance";

import List from "../../_internal/List";
import ListRow from "../../_internal/List/_internal/ListRow";
import { useSummaryData } from "../useSummaryData";

import { LABELS } from "./constants";

import { isFinanceFooter } from "../../_internal/utils";

import styles from "./ListSide.module.scss";

export interface IListSideProps extends PropsWithChildren {
  additionalHeader?: ReactNode;
}

const ListSide: React.FC<IListSideProps> = ({ children, additionalHeader }) => {
  const { projectId } = useParams<any>();
  const dispatch = useDispatch();

  const handleListItemClick = (subTree: ISummaryTree) => {
    dispatch(toggleOpenSummaryTree(subTree));
  };

  const { displayedTree } = useSummaryData();

  const objectId = useSelector(objectIdSelector);

  const isObject = +(objectId ?? 0) > 0;

  const total = {
    id: "total",
    name: `Итого по ${isObject ? "объекту" : "проекту"}`,
    isOpen: false,
  };

  return (
    <div className={styles.listSide}>
      <div className={styles.sticky}>
        {children}
        <div className={styles.listSide__header}>
          <span className={styles.listSide__title}>{LABELS.TITLE}</span>
          {additionalHeader ?? <div style={{ height: "calc(2rem + 1px)" }}></div>}
        </div>
      </div>
      <div className={styles.listSide__list}>
        <List data={displayedTree} handleListItemClick={handleListItemClick} />
        {isFinanceFooter(objectId, projectId) && (
          <ListRow data={total} depth={2} handleListItemClick={handleListItemClick} className={styles.total} />
        )}
      </div>
    </div>
  );
};

export default ListSide;
