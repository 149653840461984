import moment from "moment";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import TabBar from "components/UI/atoms/TabBar/TabBar";
import MonthsYearsSlider from "components/UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";

import BetonExpense from "./BetonExpense/BetonExpense";
import BetonIncome from "./BetonIncome/BetonIncome";

import { useObjectId } from "../Documents/hooks/useObjectId";
import { useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import styles from "./Beton.module.scss";
import usePermission from "../../../hooks/usePermission";
import {
    VIEW_MANUFACTURING_BETON_INCOMES,
    VIEW_MANUFACTURING_BETON_OUTCOMES
} from "../../../constants/permissions/manufacturingPermissions";

const Beton = () => {
  const [date, setDate] = useState(moment());
  const year = date.year();
  const month = date.month();
  const baseRoute = useUrlModuleWithObjectId();

  const objectId = useObjectId();
  const building = useBuildingDetailDataById(objectId);
  const isMismatchingStockId = +objectId !== building?.id;

  const tabs = [];

  const haveViewIncome = usePermission(VIEW_MANUFACTURING_BETON_INCOMES);
  const haveViewOutcome = usePermission(VIEW_MANUFACTURING_BETON_OUTCOMES);

  let redirect = null;

  if (haveViewIncome) {
      tabs.push({ link: `/${baseRoute}/beton/income/`, text: "Приход" });
      redirect = (<Redirect to={`/${baseRoute}/beton/income/`} />)
  }

  if (haveViewOutcome) {
      tabs.push({ link: `/${baseRoute}/beton/expense/`, text: "Расход" });
      if (!haveViewIncome) {
          redirect = (<Redirect to={`/${baseRoute}/beton/expense/`} />)
      }
  }

  return (
    <TemplateBase>
      <ListPageHeaderTemplate>
        <TabBar
          tabs={tabs}
        />
        <MonthsYearsSlider date={date} onChange={setDate} className={styles.slider} />
      </ListPageHeaderTemplate>
      <Switch>
          {haveViewIncome && (
              <Route path={`/${baseRoute}/beton/income/`}>
                  <BetonIncome
                      stockId={building?.stock_id}
                      isStockMismatching={isMismatchingStockId}
                      year={year}
                      month={month}
                  />
              </Route>
          )}
          {haveViewOutcome && (
              <Route path={`/${baseRoute}/beton/expense/`}>
                  <BetonExpense
                      stockId={building?.stock_id}
                      isStockMismatching={isMismatchingStockId}
                      year={year}
                      month={month}
                  />
              </Route>
          )}
          {redirect}
      </Switch>
    </TemplateBase>
  );
};

export default Beton;
