import moment, { Moment } from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { Spinner } from "components/UI/Spinner/Spinner";
import BackNavigationBar from "components/UI/atoms/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import Select from "components/UI/atoms/Select";
import WeeksSlider from "components/UI/molecules/WeeksSlider/WeeksSlider";
import { getDisplayWeeksCountForWindowWidth } from "components/pages/Workers/components/Turnout/utils/getDisplayWeeksCountForWindowWidth";
import {
  planFactVolumeDaysCountSelector,
  planFactVolumeSectionsLoadingSelector,
  planFactVolumeSectionsSelector,
} from "pages/PlanFact/PlanFactVolume/model/selectors";
import { getPlanFactVolumeSections } from "pages/PlanFact/PlanFactVolume/model/thunks";

import PlanFactVolumeSection from "./PlanFactVolumeSection/PlanFactVolumeSection";
import { planFactVolumeActions } from "./model/actions";

import { daysInWeekCountOptions } from "./constants";
import { IRouterSectionId } from "./types";
import { VIEW_PLAN_FACT_VOLUME } from "constants/permissions/manufacturingPermissions";
import { ROUTES } from "constants/routes";

import usePermission from "hooks/usePermission";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import planFact from "images/icons/navigation/plan-fact.svg";

import styles from "./PlanFactVolume.module.scss";

interface IProps {
  date: Moment;
  setDate: React.Dispatch<React.SetStateAction<Moment>>;
}

const PlanFactVolumeSectionsLevel: React.FC<IProps> = ({ date, setDate }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const startAt = date.startOf("week").format("YYYY-MM-DD");
  const endAt = date.endOf("week").format("YYYY-MM-DD");

  const { sectionId, objectId } = useParams<IRouterSectionId>();
  const key = generateStorageKey({ buildingId: objectId, startAt, endAt });
  const lsrs = useSelector(planFactVolumeSectionsSelector)[key];
  const isLsrsLoading = useSelector(planFactVolumeSectionsLoadingSelector)[key];

  const section = React.useMemo(() => {
    if (!lsrs) return null;
    return lsrs.find((el) => +el.id === +sectionId) || null;
  }, [lsrs, sectionId]);

  const rowClickHandler = React.useCallback(
    (subsectionId: number) => {
      history.push(`/objects/${objectId}/plan-fact/volume/${sectionId}/${subsectionId}/`);
    },
    [objectId, sectionId]
  );

  const setWeek = React.useCallback(
    (week: number) => {
      const newDate = moment(date);
      newDate.week(week);
      setDate(newDate);
    },
    [setDate, date]
  );

  React.useEffect(() => {
    if (!!lsrs?.length) return;
    dispatch(getPlanFactVolumeSections(objectId, startAt, endAt, 5));
  }, [objectId, startAt, endAt, lsrs]);

  const viewVolumePermission = usePermission(VIEW_PLAN_FACT_VOLUME);

  const setDaysCount = (count: 5 | 7) => {
    dispatch(planFactVolumeActions.setDaysCount(count));
  };

  const daysCount = useSelector(planFactVolumeDaysCountSelector);

  if (!viewVolumePermission) {
    return <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE} />;
  }

  return (
    <>
      <div className={styles.head}>
        <div className={styles.flex}>
          <WeeksSlider
            className={styles.slider}
            year={date.year()}
            activeWeekNumber={date.week()}
            setActiveWeekNumber={setWeek}
            displayWeeksCount={getDisplayWeeksCountForWindowWidth(window.innerWidth)}
          />
          <Select options={daysInWeekCountOptions} onChange={setDaysCount as any} value={daysCount} />
        </div>
        <BackNavigationBar title={section?.name!} className={styles.navbar} />
      </div>
      {isLsrsLoading && <Spinner />}
      {!isLsrsLoading && !lsrs?.length && <EmptyPlaceholder img={planFact} />}
      {section?.subsections?.map((el) => (
        <PlanFactVolumeSection
          key={el.id}
          name={el.name}
          percents={+el.percent}
          onClick={rowClickHandler}
          sectionId={el.id}
        />
      ))}
    </>
  );
};

export default React.memo(PlanFactVolumeSectionsLevel);
