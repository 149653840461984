import React, { useCallback, useState } from "react";

import ProjectPersonCell from "../../../../../UI/molecules/Person/Person";
import ContractorInfoModal from "./components/ContractorInfoModal/ContractorInfoModal";
import DeleteContractorCell from "./components/DeleteContractorCell/DeleteContractorCell";
import { Spinner } from "components/UI/Spinner/Spinner";
import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import SearchFilterTable from "components/UI/atoms/SearchFilterTable";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import ConfirmationModal from "components/UI/molecules/ConfirmationModal/ConfirmModal";
import { OpenAddModal } from "components/pages/Settings/EditProject/EditProjectContractors";
import { IChangeFilter, IDeleteOurContractor } from "components/pages/Settings/EditProject/types";

import { emptyIcon } from "./emptyIcon";

import { LOCALIZATION_CONFIG } from "../../../../../../constants/localization";
import IContactManager from "types/interfaces/ContactManager";
import IContractor from "types/interfaces/Contractor";

import styles from "./ContractorsTable.module.scss";

interface IContractorsTable {
  ourContractors: IContractor[];
  ourContractorsCount: number;
  ourContractorsLoading: boolean;
  openAddModal: OpenAddModal;
  filterHandler: IChangeFilter;
  loadMoreHandler: () => void;
  deleteOurContractor: IDeleteOurContractor;
}

const ContractorsTable = ({
  ourContractors,
  ourContractorsCount,
  ourContractorsLoading,
  openAddModal,
  filterHandler,
  loadMoreHandler,
  deleteOurContractor,
}: IContractorsTable) => {
  const hasNextPage = ourContractorsCount > ourContractors.length;

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [deletedContractorId, setDeletedContractorId] = useState<IContractor["id"] | null>(null);
  const [activeDetailContractor, setActiveDetailContractor] = useState<IContractor | null>(null);

  const openConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const closeConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const onDeleteContractor = useCallback(
    (contractorId: IContractor["id"]) => {
      setDeletedContractorId(contractorId);
      openConfirmDeleteModal();
    },
    [openConfirmDeleteModal]
  );

  const confirmDeleteContractor = useCallback(() => {
    if (deletedContractorId) deleteOurContractor(deletedContractorId);
    setDeletedContractorId(null);
    closeConfirmDeleteModal();
  }, [deleteOurContractor, deletedContractorId, closeConfirmDeleteModal]);

  const resetActiveDetailContractor = useCallback(() => setActiveDetailContractor(null), []);

  return (
    <>
      <div className={styles.addLine}>
        <h2>Подрядчики</h2>
        <button onClick={openAddModal} type="button">
          Добавить
          <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17 0C20.3623 0 23.6491 0.997033 26.4447 2.86502C29.2403 4.733 31.4193 7.38804 32.706 10.4944C33.9926 13.6007 34.3293 17.0189 33.6734 20.3165C33.0174 23.6142 31.3983 26.6433 29.0208 29.0208C26.6433 31.3983 23.6142 33.0174 20.3165 33.6734C17.0189 34.3293 13.6007 33.9926 10.4944 32.706C7.38804 31.4193 4.733 29.2403 2.86502 26.4447C0.997033 23.6491 0 20.3623 0 17C0 12.4913 1.79107 8.1673 4.97918 4.97918C8.1673 1.79107 12.4913 0 17 0V0Z"
              fill="#4FB2ED"
            />
            <path
              d="M15.5176 25.52V18.4829H8.47852C8.08616 18.4824 7.71103 18.3263 7.43359 18.0488C7.15615 17.7714 7.00053 17.3953 7 17.0029C7.00053 16.6106 7.15615 16.2345 7.43359 15.957C7.71103 15.6796 8.08616 15.5235 8.47852 15.5229H15.5176V8.48682C15.5176 8.09402 15.6737 7.71748 15.9512 7.43945C16.2286 7.16142 16.6053 7.0047 16.998 7.00391C17.391 7.0047 17.7674 7.16121 18.0449 7.43945C18.3224 7.7177 18.4788 8.09482 18.4785 8.48779V15.5239H25.5195C25.9119 15.5245 26.287 15.6806 26.5645 15.958C26.8419 16.2354 26.9995 16.6115 27 17.0039C26.9995 17.3963 26.8419 17.7724 26.5645 18.0498C26.287 18.3272 25.9119 18.4834 25.5195 18.4839H18.4785V25.521C18.4786 25.7154 18.4396 25.9078 18.3652 26.0874C18.2909 26.267 18.1824 26.4304 18.0449 26.5679C17.9075 26.7053 17.7441 26.8143 17.5645 26.8887C17.3848 26.963 17.1924 27.0011 16.998 27.001C16.8036 27.0011 16.6113 26.9626 16.4316 26.8882C16.252 26.8138 16.0886 26.7049 15.9512 26.5674C15.8137 26.4298 15.7051 26.2667 15.6309 26.0869C15.5566 25.9072 15.5173 25.7145 15.5176 25.52Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {ourContractorsLoading && <Spinner isStatic />}
      {!ourContractorsLoading && (
        <TableOslaLocal
          data={ourContractors}
          pagination={false}
          tableClass={styles.table}
          headerClass={styles.table__header}
          rowClass={styles.table__row}
          onRowClick={setActiveDetailContractor}
          isLoading={ourContractorsLoading}
          isStaticLoader
          emptyPlaceholder={
            <EmptyPlaceholder svgNative={emptyIcon}>
              <ButtonBase onClick={openAddModal} primary>
                Добавить
              </ButtonBase>
            </EmptyPlaceholder>
          }
          paginationHide
        >
          <TableHeader
            data="name"
            cellStyle={{
              width: "20%",
              textAlign: "left",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Наименование организации подрядчика</div>
              <SearchFilterTable
                title="Наименование компании"
                setFilter={(value) => {
                  filterHandler(value, "name");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="itn"
            cellStyle={{
              width: "10%",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>{LOCALIZATION_CONFIG.taxesNumberLabel} подрядчика</div>
              <SearchFilterTable
                title={LOCALIZATION_CONFIG.taxesNumberLabel}
                setFilter={(value) => {
                  filterHandler(value, "itn");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="contact_manager"
            cellStyle={{
              width: "15%",
            }}
            formatter={(cell: IContactManager) => <ProjectPersonCell cell={cell} />}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Представитель</div>
            </div>
          </TableHeader>
          <TableHeader
            cellStyle={{
              width: "10%",
            }}
            formatter={(_: undefined, row: IContractor) => (
              <DeleteContractorCell onClick={() => onDeleteContractor(row.id)} />
            )}
          ></TableHeader>
        </TableOslaLocal>
      )}
      <ShowMoreButton
        isExists={hasNextPage}
        handleAdd={loadMoreHandler}
        showedCount={ourContractors?.length}
        allCount={ourContractorsCount}
      />
      {isConfirmDeleteModalOpen && (
        <ConfirmationModal
          onClose={closeConfirmDeleteModal}
          action={confirmDeleteContractor}
          variant="secondary"
          title="Подтвердите действие"
          isOpen
        >
          Вы действительно хотите удалить подрядчика?
        </ConfirmationModal>
      )}
      {activeDetailContractor && (
        <ContractorInfoModal
          contractorId={activeDetailContractor.entity_id}
          contractorName={activeDetailContractor.name}
          onClose={resetActiveDetailContractor}
          isOpen
        />
      )}
    </>
  );
};

export default React.memo(ContractorsTable);
