import axios from "axios";

import {
  ICertainTaskResponse,
  IcreateTask,
  ItaskAggregationsParams,
  ItaskIndicatorsByBuilding,
  ItaskResponse,
  IupdateTask,
  listOfTaskListsType,
  tasksFieldsFilterType,
  tasksFieldsType,
  tasksListFilterType,
} from "./types";
import { IList } from "types/ListResponse";
import { IFile } from "types/interfaces/Files";

export const tasksApi = {
  getTasks: (params: tasksListFilterType) => {
    return axios.get<IList<ItaskResponse>>(`/tasks/`, { params });
  },
  getTasksLists: (params?: tasksListFilterType) => {
    return axios.get<IList<listOfTaskListsType>>(`/tasks/lists/`, { params });
  },
  patchTasksList: (params: listOfTaskListsType) => {
    return axios.put(`/tasks/list/${params.id}/`, { list_name: params.list_name, user: params.user });
  },
  deleteTasksList: (id: number) => {
    return axios.delete(`/tasks/list/${id}/`);
  },
  createTasksList: (data: Omit<listOfTaskListsType, "id" | "tasks_count">) => {
    return axios.post<{ id: number }>(`/tasks/lists/`, data);
  },
  deleteTask: (id: number) => {
    return axios.delete(`/task/${id}/`);
  },
  getTask: (id: number) => {
    return axios.get<ICertainTaskResponse>(`/task/${id}/`);
  },
  createTask: (data: IcreateTask) => {
    return axios.post<ICertainTaskResponse>(`/tasks/`, data);
  },
  createTaskComment: (text: string, task: number, creator: number, date: string) => {
    return axios.post(`/tasks/comments/`, { text, task, creator, date });
  },
  postTaskFiles: (files: IFile[], taskId: number) => {
    const formData = new FormData();
    files.forEach((el) => {
      formData.append("files", el);
    });
    formData.append("task", taskId.toString());
    return axios.post(`/tasks/files/`, formData);
  },
  deleteTaskFile: (fileId: number) => {
    return axios.delete(`/tasks/file/${fileId}/`);
  },
  getTasksFields: (params?: tasksFieldsFilterType) => {
    return axios.get<tasksFieldsType>(`/tasks/fields/`, { params });
  },
  updateTask: (id: number, data: IupdateTask) => {
    return axios.put<ICertainTaskResponse>(`/task/${id}/`, data);
  },
  getByBuildingIndicators: (buildingId: number, params: tasksListFilterType) => {
    return axios.get<ItaskIndicatorsByBuilding>(`/tasks/${buildingId}/indicators/`, { params });
  },
  getTasksAggregation: (params: ItaskAggregationsParams) => {
    return axios.get(`/tasks/aggregations-indicators/`, { params });
  },
};
