import axios from "axios";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { ordersListActions } from "./actions";

import { IList } from "types/ListResponse";
import { IOrderInList } from "types/interfaces/Order";

import { checkIsNeedSetLoading } from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const getOrdersList = (statuses: string[], filters: any) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = statuses.join(",");

  const params = { ...filters };
  if (statuses && !params.status) params.status = key;

  if (checkIsNeedSetLoading(getState(), filters, key)) {
    dispatch(ordersListActions.setIsLoading(true, key));
  }
  axios
    .get<IList<IOrderInList>>(`/purchaser/orders/`, {
      params,
    })
    .then(({ data }) => {
      dispatch(ordersListActions.setOrders(data.results, key, filters, data.count));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(ordersListActions.setIsLoading(false, key));
    });
};

export const getOrdersListMore =
  (statuses: string[], filters: any, offset: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = statuses.join(",");
    const params = { ...filters, offset };
    if (statuses && !params.status) params.status = key;

    axios
      .get<IList<IOrderInList>>(`/purchaser/orders/`, { params })
      .then(({ data }) => dispatch(ordersListActions.setMoreOrders(data.results, key, filters, data.count)))
      .catch(errorCatcher);
  };
