import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { certainTaskSelector, isCertainTaskLoadingSelector } from "redux/modules/purchaser/Tasks/selectors";
import { getOneTask } from "redux/modules/purchaser/Tasks/thunks";
import { useTypedSelector } from "redux/typedUseSelector";


export const useGetTask = (id: number, isOpen = false) => {
  const dispatch = useDispatch();
  const tasks = useTypedSelector(certainTaskSelector);
  const tasksLoading = useTypedSelector(isCertainTaskLoadingSelector);

  useEffect(() => {
    if (!isOpen) return;

    id > 0 && dispatch(getOneTask(id));
  }, [dispatch, id, isOpen]);

  return {
    certainTask: tasks[id],
    isCertainTaskLoading: tasksLoading[id],
  };
};
