import React, { FC } from "react";
import { Helmet } from "react-helmet";

import App from "components/App";
import ErrorBoundary from "components/ErrorBoundary";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { URL_MODULE_NAMES_MAP } from "./constants/breadcrumbs";

import { useUrlModule } from "./utils/hooks/useUrlModule";

const Root: FC = () => {
  const urlModule = useUrlModule();

  return (
    <DndProvider backend={HTML5Backend}>
      <ErrorBoundary>
        <Helmet>
          <title>OSLA {URL_MODULE_NAMES_MAP[urlModule] || ""}</title>
        </Helmet>
        <App />
      </ErrorBoundary>
    </DndProvider>
  );
};

export default Root;
