import { combineReducers } from "redux";

import audit from "./audit/reducer";
import finance from "./finance";
import forecast from "./forecast/reducer";
import planFact from "./planFact/reducer";
import summary from "./summary/reducer";

export const moduleName = "finance";

export default combineReducers({
  index: finance,
  audit,
  summary,
  planFact,
  forecast,
});
