import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";

import { LOCALIZATION_CONFIG } from "constants/localization";
import { IdNameLabelType } from "types/IdNameLabelType";

import { useCheckIsProductionModule } from "../../hooks/useCheckIsProductionModule";

import styles from "./BillsTableHead.module.scss";

interface IBillsTableHeadProps {
  onChangeFilter: (name: string, value: string) => void;
  statusBillsArray: IdNameLabelType[];
  formattedObjects: IdNameLabelType[];
}

const BillsTableHead: React.FC<IBillsTableHeadProps> = ({ onChangeFilter, statusBillsArray, formattedObjects }) => {
  const isInProduction = useCheckIsProductionModule();

  return (
    <TableReusableHead className={cn(styles.row, { [styles.inProduction]: isInProduction })} isSticky>
      <TableReusableHeaderCell filterType="text" filterField="number" filterTitle="№" onChangeFilter={onChangeFilter}>
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterType="text"
        filterField="order_number"
        filterTitle="Заказ №"
        onChangeFilter={onChangeFilter}
      >
        Заказ №
      </TableReusableHeaderCell>
      {!isInProduction && (
        <TableReusableHeaderCell
          filterField="building_id"
          filterOptions={formattedObjects}
          filterTitle="Проект"
          filterType="select"
          onChangeFilter={onChangeFilter}
        >
          Проект
        </TableReusableHeaderCell>
      )}
      <TableReusableHeaderCell
        filterField="provider_name"
        filterType="text"
        filterTitle="Поставщик"
        onChangeFilter={onChangeFilter}
      >
        Поставщик
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle="Создан"
        filterType="datesRange"
        onChangeFilter={onChangeFilter}
        rangeEndField="created_at_before"
        rangeStartField="created_at_after"
        isCentered
      >
        Создан
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle={`Сумма, ${LOCALIZATION_CONFIG.currency}`}
        filterType="sum"
        onChangeFilter={onChangeFilter}
        rangeEndField="amount_total_max"
        rangeStartField="amount_total_min"
        isRighted
      >
        Сумма, {LOCALIZATION_CONFIG.currency}
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="status"
        filterOptions={statusBillsArray}
        filterTitle="Статус"
        filterType="select"
        onChangeFilter={onChangeFilter}
        isLast
      >
        Статус
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default React.memo(BillsTableHead);
