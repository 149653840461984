import { message } from "antd";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { ksListActions } from "./actions";
import { ksListApi } from "./api";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const getKsLists =
  (buildingId: string, params?: Record<string, string | number>) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().ksList.ksLists?.[buildingId]?.results?.length) {
      dispatch(ksListActions.setKsListLoading(buildingId, true));
    }
    ksListApi
      .getKsList(buildingId, params)
      .then(({ data }) => {
        dispatch(ksListActions.setKsList(buildingId, data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(ksListActions.setKsListLoading(buildingId, false));
      });
  };

export const getKsSublist = (ks3Id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().ksList.ksSublists?.[ks3Id]?.id) {
    dispatch(ksListActions.setKsSublistLoading(ks3Id, true));
  }
  ksListApi
    .getKsSubList(ks3Id)
    .then(({ data }) => {
      dispatch(ksListActions.setKsSublist(ks3Id, data));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(ksListActions.setKsSublistLoading(ks3Id, false));
    });
};

export const sendFileToKsPay = (ks3Id: number, file: File) => async (dispatch: Dispatch) => {
  //@ts-ignore
  const filesInBase64 = await getArrayFilesInBase64([{ file }]);
  const fileName = file.name;
  if (filesInBase64[0]?.file) {
    ksListApi
      .attachFileToKs3(ks3Id, filesInBase64[0].file as string, fileName)
      .then(() => {
        dispatch(ksListActions.invalidateKey());
        message.success("Сохранено");
      })
      .catch(errorCatcher);
  }
};

export const signKs3 = (ks3Id: number) => (dispatch: Dispatch) => {
  ksListApi
    .signKs3(ks3Id)
    .then(() => {
      dispatch(ksListActions.invalidateKey());
      message.success("Отмечено");
    })
    .catch(errorCatcher);
};

export const getMoreKs3inList =
  (buildingId: string, params?: Record<string, string | number>) => (dispatch: Dispatch, getState: () => RootState) => {
    ksListApi
      .getKsList(buildingId, params)
      .then(({ data }) => {
        dispatch(ksListActions.addMoreKsList(buildingId, data));
      })
      .catch(errorCatcher);
  };

export const getDetailedKs2InDocs = (ks2Id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().ksList?.detailedKs2?.[ks2Id]?.id) {
    dispatch(ksListActions.setDetailedKs2Loading(ks2Id, true));
  }
  ksListApi
    .getDetailedKs2(ks2Id)
    .then(({ data }) => {
      dispatch(ksListActions.setDetailedKs2(ks2Id, data));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(ksListActions.setDetailedKs2Loading(ks2Id, false));
    });
};
