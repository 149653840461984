import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  diagramFiltersSelector,
  planSelector,
  projectDataSelector,
} from "redux/modules/common/building/manufacturing/selectors";

import { ISpittingTreeElement } from "../types";

import { filterSpittingTree } from "../utils";

export interface IUseSpittingTreeProps {
  spittingTreeGenerationFn: (args: any) => ISpittingTreeElement[];
  spittingTreeFnArgs: any;
  projectId: number | string;
  filterParams?: string;
  isProduction?: boolean;
  selectedPeriod?: { dateEnd: null | undefined | string; dateStart: null | undefined | string };
}

export const useSpittingTree = ({
  spittingTreeGenerationFn,
  spittingTreeFnArgs,
  projectId,
  filterParams = "",
  isProduction = false,
  selectedPeriod,
}: IUseSpittingTreeProps) => {
  const data = useSelector(isProduction ? planSelector : projectDataSelector);
  const diagramFilters = useSelector(diagramFiltersSelector);
  const isFirstTouch = useRef<boolean>(true);
  const [spittingTree, setSpittingTree] = useState<ISpittingTreeElement[]>([]);
  const [isGeneratingTree, setIsGeneratingTree] = useState(true);

  const isActiveEditing = useMemo(() => {
    return diagramFilters.plans_editing_enabled || diagramFilters.linking_editing_enabled;
  }, [diagramFilters, diagramFilters]);

  useEffect(() => {
    isFirstTouch.current = true;
  }, [projectId]);

  const handleGenerateSpittingTree = useCallback(
    (updatedArgs?: Partial<IUseSpittingTreeProps["spittingTreeFnArgs"]>) => {
      try {
        if ("scheduler" in global) {
          global.scheduler.postTask(() => {
            const newSpittingTree = spittingTreeGenerationFn({
              ...spittingTreeFnArgs,
              ...updatedArgs,
              isFirstTouch: isFirstTouch.current,
            });
            if (newSpittingTree.length > 0) isFirstTouch.current = false;
            setSpittingTree(
              filterSpittingTree(newSpittingTree, filterParams, isProduction, data, selectedPeriod, isActiveEditing)
            );
            setIsGeneratingTree(false);
          });
        } else {
          const newSpittingTree = spittingTreeGenerationFn({
            ...spittingTreeFnArgs,
            ...updatedArgs,
            isFirstTouch: isFirstTouch.current,
          });
          if (newSpittingTree.length > 0) isFirstTouch.current = false;
          setSpittingTree(
            filterSpittingTree(newSpittingTree, filterParams, isProduction, data, selectedPeriod, isActiveEditing)
          );
          setIsGeneratingTree(false);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      spittingTreeGenerationFn,
      spittingTreeFnArgs,
      isFirstTouch.current,
      filterParams,
      isProduction,
      selectedPeriod,
      isActiveEditing,
    ]
  );

  const skipGeneratingTreeHandler = useCallback(() => {
    setIsGeneratingTree(false);
  }, [setIsGeneratingTree]);

  useEffect(() => {
    handleGenerateSpittingTree();
  }, [filterParams, isProduction, selectedPeriod, isActiveEditing]);

  return {
    generateSpittingTree: handleGenerateSpittingTree,
    isGeneratingTree,
    spittingTree,
    skipGeneratingTreeHandler,
  };
};
