import React from 'react'
import styles from "./TableHeader.module.scss"
import SearchFilterTable from "./../../../../atoms/SearchFilterTable"
import cn from "classnames"

function WorkersTableHeader({ onFiltersChange, className }) {
    return (
        <div className={cn(styles.header, className)}>
            <div />
            <div>№</div>
            <div />
            <div className={styles.name}>
                ФИО
                {onFiltersChange && <SearchFilterTable
                    title="ФИО"
                    setFilter={value => onFiltersChange(value, "name")}
                />}
            </div>
            <div>
                Кол-во, ч.
            </div>
            <div />
        </div>
    )
}

export default WorkersTableHeader