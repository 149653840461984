import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.journalAcceptance;

export const journalAcceptanceSectionsSelector = createSelector(stateSelector, (state) => state.sections);

export const journalAcceptanceSectionsIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.sectionsIsLoading
);

export const journalAcceptanceTicketsSelector = createSelector(stateSelector, (state) => state.tickets);

export const journalAcceptanceTicketsIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.ticketsIsLoading
);

export const journalAcceptanceApprovalsSelector = createSelector(stateSelector, (state) => state.approvals);

export const journalAcceptanceHistorySelector = createSelector(stateSelector, (state) => state.history);
export const journalAcceptanceHistoryLoadingSelector = createSelector(stateSelector, (state) => state.historyLoading);

export const journalAcceptanceSharedContractorsSelector = createSelector(
  stateSelector,
  (state) => state.sharedContractors
);
export const journalAcceptanceDetailedTicketsSelector = createSelector(stateSelector, (state) => state.detailedTickets);
