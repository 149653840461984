import { MatchingStatusesEnum } from "components/UI/atoms/MatchingIcon/MatchingIcon";
import { getProductMatchingStatus } from "components/pages/Stock/utils/getProductMatchingStatus";

export const getWriteOffMatchingStatus = (
  product: {
    product_building: { type: string; estimate_expenditure: string; created_from_estimate: boolean };
    created_from_estimate?: boolean;
  },
  isManual?: boolean
) => {
  if (isManual) return MatchingStatusesEnum.manual;
  return getProductMatchingStatus(product);
};
