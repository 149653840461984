import axios from "axios";
import { createSelector } from "reselect";

import { commonStateSelector } from "../common";
import { TABS_IDS } from "./constants";
import { getActiveTab, prepareTabs } from "./utils";

const initialState = {
  tabs: null,
  activeTab: TABS_IDS.CONTACTS,
  counts: null
};

const moduleName = "tabs";
const actionsPrefix = "constructing/counterparties/common/tabs";

const SET_ACTIVE_TAB = `${actionsPrefix}_SET_ACTIVE_TAB`;
const SET_COUNTS = `${actionsPrefix}_SET_COUNTS`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;
const SET_TABS = `${actionsPrefix}_SET_TABS`;

export const stateSelector = createSelector(commonStateSelector, (state) => state[moduleName]);
export const countsSelector = createSelector(stateSelector, (state) => state.counts);
export const tabsSelector = createSelector(stateSelector, (state) => state.tabs);
export const preparedTabsSelector = createSelector(tabsSelector, countsSelector, prepareTabs);
export const activeTabSelector = createSelector(stateSelector, (state) => state.activeTab);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COUNTS:
      return {
        ...state,
        counts: payload
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload
      };
    case CLEAR_DATA: {
      return {
        ...initialState
      };
    }
    case SET_TABS: {
      return { ...state, tabs: payload };
    }
    default:
      return state;
  }
}

export const setCounts = (data) => ({
  type: SET_COUNTS,
  payload: data
});

export const setActiveTab = (data) => ({
  type: SET_ACTIVE_TAB,
  payload: data
});

export const onChangePathname = (pathname) => {
  return (dispatch) => {
    const activeTab = getActiveTab(pathname);
    dispatch(setActiveTab(activeTab?.id));
  };
};

export const loadPartnersCounter = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("entities/counter/");
      dispatch(setCounts(response.data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const clearData = () => ({
  type: CLEAR_DATA
});

export const setTabs = (data) => ({
  type: SET_TABS,
  payload: data
});