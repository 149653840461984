import React from "react";
import { Route, Switch } from "react-router-dom";

import { LandingPages } from "../pages/LandingPages";
import Authorization from "./pages/Authorization/Authorization";
import RegisterConfirm from "./pages/Authorization/RegisterConfirm/RegisterConfirm";
import ThanksPage from "./pages/HomePage/components/ThanksPage/ThanksPage";
import SimplifiedRoutes from "./routes/components/SimplifiedRoutes/SimplifiedRoutes";

import Main from "./Main";
import ProtectedRoute from "./ProtectedRoute";

const App = () => {
  const hasLandingPage = !window.location?.hostname?.includes("app.");
  const RootSubstituteComponent = hasLandingPage ? LandingPages : undefined;
  return (
    <Switch>
      <Route path="/auth" component={Authorization} />
      {hasLandingPage && <Route path={"/thanks"} component={ThanksPage} />}
      <Route path="/register/confirm/:token" component={RegisterConfirm} />
      <Route path="/simplified/:page/:userId" component={SimplifiedRoutes} />
      <ProtectedRoute path="/" component={Main} SubstituteComponent={RootSubstituteComponent} />
    </Switch>
  );
};

export default App;
