import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { filesSelector } from "../../../../../redux/modules/common/orderCard/files/selectors";
import { addFile } from "../../../../../redux/modules/common/orderCard/files/thunks/addFile";
import { deleteFile } from "../../../../../redux/modules/common/orderCard/files/thunks/deleteFile";
import { chatRoomTypes } from "redux/modules/common/chatV2/types";

import FileBlock from "../../../../UI/molecules/FileBlock/FileBlock";
import { Switch } from "components/UI/atoms/Switch/Switch";
import Chat from "components/UI/organism/Chat/ChatV2";

import Info from "../Info";

import { useLocationArray } from "utils/hooks/useLocationArray";

import styles from "./Header.module.scss";

const Header = ({
  order,
  permissions,
  isSimplified,
  orderPermissions,
  isSpecificationCalculation,
  setIsSpecificationCalculation,
  setIsWithLoading,
}) => {
  const dispatch = useDispatch();
  const files = useSelector(filesSelector);
  const locationArr = useLocationArray();

  const handleAddFiles = useCallback(
    (files) => files.forEach((file) => compose(dispatch, addFile)(order.id, file)),
    [order.id]
  );

  const handleDeleteFile = useCallback((fileId) => compose(dispatch, deleteFile)(order.id, fileId), [order.id]);

  const isShowSpecificationSwitcher = !orderPermissions.editRequest && orderPermissions.viewInvoiceDifference;

  const handleSpecificationChange = useCallback(() => {
    setIsSpecificationCalculation((prev) => !prev);
    setIsWithLoading(false);
  }, [setIsSpecificationCalculation, setIsWithLoading]);

  return (
    <header className={styles.header}>
      <Info order={order} isSimplified={isSimplified} />
      <div>
        <div className={styles.chatContainer}>
          <Chat
            room={chatRoomTypes.ORDER}
            building_id={order.building?.id}
            item_id={locationArr.at(-1)}
            title={`Заказ ${order.number} (чат компании)`}
            subTitle={order.status_name}
            isDragDisable
            isStatic
          />
        </div>
        <FileBlock
          className={styles.fileBlock}
          files={files.list}
          addFiles={handleAddFiles}
          deleteFile={handleDeleteFile}
          permissions={permissions}
        />
        {isShowSpecificationSwitcher && (
          <div className={styles.switcher}>
            <span>Подсчет по спецификации</span>
            <Switch checked={isSpecificationCalculation} onChange={handleSpecificationChange} />
          </div>
        )}
      </div>
    </header>
  );
};

export default React.memo(Header);
