import React from 'react';
import AutosizeInput from "react-input-autosize";

export default class DynamicInput extends React.Component {

  state = {
      value: this.props.value
  };

    componentDidUpdate (oldProps) {
      if ( oldProps.value !== this.props.value ) {
        this.setState({
          value: this.props.value
        });
      }
    }

    validate = () => {
      this.props.validate && this.setState({
        value: this.props.validate(this.state.value)
      });
    };

    render() {
      const { value, onChange, className, id, onBlur } = this.props;
      return(
        <AutosizeInput
          {...this.props}
          id={id}
          value={this.props.value ? this.props.value : this.state.value}
          defaultValue={this.props.defaultValue}
          className={className}
          onChange={
            e => {
              this.setState({
                  value: e.target.value
              });
              if (onChange) onChange(e);
            }
          }
          onBlur={
            e => {
              if (e.target.value === '') {
                this.setState({
                  value: value
                });
              }
              if (onBlur) onBlur(e);
              this.validate();
            }
          }
        />
    );
  }
}