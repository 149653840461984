import { useMemo } from "react";
import { useSelector } from "react-redux";

import { userSelector } from "redux/modules/common/auth";

import { permissionsUserSelector } from "../model/selectors";

export const usePermissionsObject = <T extends Record<string, string | boolean | string[]>, K extends keyof T>(
  permissions: T
): Record<K, boolean> => {
  const user = useSelector(userSelector);
  const allPermissions = useSelector(permissionsUserSelector)[user?.id];

  const permissionsObject = useMemo(() => {
    const keys = Object.keys(permissions) as K[];
    const res = {} as Record<K, boolean>;

    if (!allPermissions?.length) return res;

    keys.forEach((key) => {
      if (typeof permissions[key] === "boolean") {
        res[key] = (permissions as Record<K, boolean>)[key];
      } else if (typeof permissions[key] === "string") {
        const alias = permissions[key];
        res[key] = allPermissions.find((el) => el.alias === alias)?.state === "on" ? true : false;
      } else if (Array.isArray(permissions[key])) {
        let tempRes = false;
        (permissions[key] as string[]).forEach((alias) => {
          if (allPermissions.find((el) => el.alias === alias)?.state === "on") {
            tempRes = true;
          }
        });
        res[key] = tempRes;
      }
    });

    return res;
  }, [permissions, allPermissions]);

  return permissionsObject;
};
