import { IFile, IFileNames } from "types/interfaces/Files";

export const serializeFilesByName = (file: IFile & IFileNames, filesArray?: (IFile & IFileNames)[]) => {
  if (Array.isArray(filesArray)) {
    filesArray.map((file) => {
      return {
        ...file,
        name: file.originalname || file.name || file.file_name || file.file.name,
      };
    });
  } else {
    return {
      ...file,
      name: file.originalname || file.name || file.file_name || file.file.name,
    };
  }
};
