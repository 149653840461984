import { memoize, partial } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

import { Spinner } from "components/UI/Spinner/Spinner";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import EmptyPlaceholder from "../../atoms/EmptyPlaceholder/EmptyPlaceholder";
import ContractsModal from "./ContractsModal/ContractsModal";
import ContractsRow from "./ContractsRow/ContractsRow";
import ContractsTableHeader from "./ContractsTableHeader/ContractsTableHeader";

import { IContract } from "types/interfaces/Contract";

import { stringifyArgs } from "../../../../utils/helpers/stringifyArgs";

import ContractIcon from "../../../../images/icons/documentsTab/ContractIcon";

interface Iprops {
  contracts: IContract[];
  files: Record<number, unknown[]>;
  isLoading: boolean;
  changeFilter: (name: string, value: string) => void;
  deleteContract: () => void;
  loadMore: () => void;
  allCount: number;
  addFiles: () => void;
  deleteFile: () => void;
  permissions: Record<string, boolean>;
  containerClassName?: string;
  headerClassName?: string;
  dataTestId?: string;
  isWithFilters: boolean;
  isContacts?: boolean;
}

const ContractsTable: React.FC<Iprops> = ({
  contracts,
  files,
  isLoading,
  changeFilter,
  deleteContract,
  loadMore,
  allCount,
  addFiles,
  deleteFile,
  permissions,
  containerClassName,
  headerClassName,
  dataTestId,
  isWithFilters,
  isContacts,
}) => {
  const hasNextPage = allCount > contracts.length;

  const [contractInModal, setContractInModal] = useState<IContract | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const openModal = useCallback(
    (contract: IContract) => () => {
      setContractInModal(contract);
      setIsOpenModal(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, [isOpenModal]);

  const partialAddFiles = useMemo(
    () => memoize((contractId) => partial(addFiles, contractId), stringifyArgs),
    [addFiles]
  );

  const partialDeleteFile = useMemo(
    () => memoize((contractId) => partial(deleteFile, contractId), stringifyArgs),
    [deleteFile]
  );

  const partialDeleteContract = useMemo(
    () => memoize((contractId) => partial(deleteContract, contractId), stringifyArgs),
    [deleteContract]
  );

  const filesPermissions = useMemo(
    () => ({
      addFiles: permissions.viewAddContractFiles,
      deleteFiles: permissions.viewDeleteContractFiles,
    }),
    [permissions.viewAddContractFiles, permissions.viewDeleteContractFiles]
  );

  const isEmpty = !isLoading && !contracts?.length;

  return (
    <div className={containerClassName} data-testid={dataTestId}>
      {((isEmpty && isWithFilters) || !isEmpty) && (
        <ContractsTableHeader onChangeFilter={changeFilter} className={headerClassName} isContacts={isContacts} />
      )}
      {contracts?.map((contract: IContract) => (
        <ContractsRow
          key={contract.id}
          contract={contract}
          files={files[contract.id]}
          canDeleteContract={permissions.viewDeleteContract}
          onDeleteContract={partialDeleteContract(contract.id)}
          onClick={openModal(contract)}
          isContacts={isContacts}
        />
      ))}
      {isEmpty && <EmptyPlaceholder text="Нет контрактов" svgJsx={<ContractIcon />} />}
      {isLoading && <Spinner />}
      <ShowMoreButton isExists={hasNextPage} handleAdd={loadMore} allCount={allCount} showedCount={contracts.length} />
      <ContractsModal
        isOpen={isOpenModal}
        contract={contractInModal || {}}
        onClose={closeModal}
        onDeleteContract={!!contractInModal?.id ? partialDeleteContract(contractInModal.id) : () => {}}
        canDeleteContract={permissions.viewDeleteContract}
        files={!!contractInModal?.id ? files[contractInModal.id] : []}
        filesPermissions={filesPermissions}
        onAddFile={!!contractInModal?.id ? partialAddFiles(contractInModal.id) : () => {}}
        onDeleteFile={!!contractInModal?.id ? partialDeleteFile(contractInModal.id) : () => {}}
      />
    </div>
  );
};

export default React.memo(ContractsTable);
