import { message } from "antd";
import moment from "moment";
import { Dispatch } from "redux";

import { chatApi } from "./chatApi";
import {
  ADD_CHAT_ITEM,
  ADD_PREV_CHAT_ITEMS,
  SET_CHAT_IS_LOADING,
  SET_CHAT_ITEMS,
  SET_FALLBACK_MESSAGES
} from "./reducers";

import { chatRoomTypes, IgetChatMessagesParams } from "./types";

export const getMessages = (params: IgetChatMessagesParams) => (dispatch: Dispatch, getState: () => any) => {
  if (!getState().chatV2?.allMessages?.length) {
    dispatch({
      type: SET_CHAT_IS_LOADING,
      payload: true,
    });
  }

  const queryParams = {};

  if (params.room === chatRoomTypes.REQUISITION) {
    queryParams['requisition_id'] = params.item_id;
  }

  if (params.room === chatRoomTypes.ORDER) {
    queryParams['order_id'] = params.item_id;
  }

  chatApi
    .getMessages(params, queryParams)
    .then(({ data }) => {
      dispatch({
        type: SET_CHAT_ITEMS,
        payload: data,
      });
    })
    .finally(() =>
      dispatch({
        type: SET_CHAT_IS_LOADING,
        payload: false,
      })
    );
};

export const getMoreMessages =
  (params: IgetChatMessagesParams, offset: number) => (dispatch: Dispatch, getState: () => any) => {
    dispatch({
      type: SET_CHAT_IS_LOADING,
      payload: true,
    });
    chatApi
      .getMessages(params, { offset })
      .then(({ data }) => {
        dispatch({
          type: ADD_PREV_CHAT_ITEMS,
          payload: data,
        });
      })
      .finally(() =>
        dispatch({
          type: SET_CHAT_IS_LOADING,
          payload: false,
        })
      );
  };

export const sendMessage =
  (params: IgetChatMessagesParams, text: string, mentions: number[]) => (dispatch: Dispatch, getState: () => any) => {
    const fallbackState = getState().chatReducerV2?.allMessages ? [...getState().chatReducerV2?.allMessages] : [];
    const generatedTempId = Math.random();
    const author = getState().auth.user;
    dispatch({
      type: ADD_CHAT_ITEM,
      payload: {
        text,
        id: generatedTempId,
        author: {
          id: author.id,
          full_name: `${author.first_name} ${author.last_name}`,
          roles: author.roles,
          position: author.position,
        },
        created_at: moment().toISOString(),
      },
    });
    chatApi.postMessage(params, text, mentions).catch((e: any) => {
      message.error("Произошла ошибка");
      dispatch({
        type: SET_FALLBACK_MESSAGES,
        payload: fallbackState,
      });
    });
  };
