import React, { useRef } from "react";

import CreateOrEditRemarkForm from "../components/CreateOrEditRemark/CreateOrEditRemarkForm";
import CurrentRemark from "../components/CurrentRemark/CurrentRemark";
import Header from "../components/Header/Header";
import ReportFields from "../components/ReportFields/ReportFields";
import { Spinner } from "components/UI/Spinner/Spinner";
import BackNavigationBar from "components/UI/atoms/BackNavigationBar/BackNavigationBar";
import ButtonBase from "components/UI/atoms/ButtonBase";
import ConfirmationModal from "components/UI/molecules/ConfirmationModal/ConfirmModal";

import { useRemarkDetailInfo } from "../hooks/useRemarkDetailInfo";

import { serializeFiles } from "utils/serializeFiles";

import styles from "./RemarkDetailInfo.module.scss";

export interface IProps {
  isOpen: boolean;
  currentRemarkId: number;
  workName: string;
  handleBack: () => void;
  remarksCount: number;
  closeModal: () => void;
  isDelivery?: boolean;
}

const RemarkDetailInfo: React.FC<IProps> = ({
  isOpen,
  currentRemarkId,
  workName,
  handleBack,
  remarksCount,
  closeModal,
  isDelivery,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const {
    detailRemarkInfo,
    isRemarkDetailInfoLoading,
    serializedFiles,
    isRemarkAuthor,
    isRemarkResponsible,
    canEditRemark,
    isShowEditRemark,
    handleOpenEditRemark,
    onSubmit,
    editRemarkInitialValues,
    handleOpenTable,
    handleCloseTable,
    selectedWorker,
    handleRemoveSelected,
    handleSelectWorker,
    files,
    setFiles,
    isResetFiles,
    isEditLoading,
    handleCloseEditForm,
    handleOpenDeleteModal,
    isOpenDeleteModal,
    handleDeleteRemark,
    handleCloseDeleteModal,
    isTableOpen,
    isShowReportFields,
    handleOpenReportFields,
    handleCloseReportFields,
    handleSubmitAfterFillReportForm,
    reportDescription,
    setReportDescription,
    reportFiles,
    setReportFiles,
    resolveRemark,
    isResolveOrReturnButtonDisabled,
    returnRemark,
    acceptRemark,
    isAcceptLoading,
  } = useRemarkDetailInfo({
    isOpen,
    currentRemarkId,
    formRef,
    handleBack,
    closeModal,
    remarksCount,
    isDelivery,
  });

  return (
    <>
      <Header
        title={detailRemarkInfo?.name || `Замечание ${detailRemarkInfo?.id ? `№${detailRemarkInfo?.id}` : ""}`}
        workName={workName}
        remarkStatus={detailRemarkInfo?.status}
      />
      {remarksCount > 1 && (
        <BackNavigationBar title={"Все замечания"} className={styles.backButton} onBack={handleBack} />
      )}
      {!isShowEditRemark &&
        (!isRemarkDetailInfoLoading ? (
          <>
            <div>
              {!!detailRemarkInfo && (
                <CurrentRemark
                  dividerType={"created"}
                  createdAt={detailRemarkInfo.created_at}
                  isShowResolveInfo
                  resolveDate={detailRemarkInfo.resolve_date}
                  resolveLabel={"Ответственный:"}
                  resolveName={detailRemarkInfo.responsible_name}
                  creatorLabel={"Создал:"}
                  creatorName={detailRemarkInfo.author_name}
                  description={detailRemarkInfo.description}
                  regulations={detailRemarkInfo.regulations}
                  /* @ts-ignore */
                  files={serializedFiles}
                />
              )}
              {detailRemarkInfo?.corrections.length !== 0 &&
                detailRemarkInfo?.corrections.map((item, index) => (
                  <CurrentRemark
                    key={item.remark_correction_id}
                    dividerType={item?.check_comment ? "resolve" : "accepted"}
                    createdAt={item?.check_comment ? item.check_date : item.created_at}
                    resolveDate={detailRemarkInfo.accepted_at}
                    isWithResolveHours
                    creatorLabel={item?.check_comment ? "Создал:" : "Выполнил:"}
                    creatorName={item?.check_comment ? detailRemarkInfo.author_name : detailRemarkInfo.responsible_name}
                    isShowResolveInfo={
                      detailRemarkInfo?.status === "accepted" &&
                      !item.check_comment &&
                      index === detailRemarkInfo?.corrections?.length - 1
                    }
                    resolveLabel={"Принял:"}
                    resolveName={detailRemarkInfo.author_name}
                    description={item?.check_comment ? item.check_comment : item.resolve_comment}
                    /* @ts-ignore */
                    files={serializeFiles(item?.check_comment ? item?.check_files : item?.resolve_files)}
                  />
                ))}
            </div>
            {isShowReportFields && (
              <ReportFields
                description={reportDescription}
                setDescription={setReportDescription}
                files={reportFiles}
                setFiles={setReportFiles}
              />
            )}
          </>
        ) : (
          <Spinner />
        ))}
      {!isRemarkDetailInfoLoading && (
        <div className={styles.buttons}>
          {canEditRemark && isRemarkAuthor && detailRemarkInfo?.corrections.length === 0 && (
            <ButtonBase type="button" primary medium onClick={handleOpenEditRemark}>
              Редактировать
            </ButtonBase>
          )}
          {isRemarkResponsible && !isShowReportFields && detailRemarkInfo?.status === "created" && (
            <ButtonBase type="button" primary medium onClick={handleOpenReportFields}>
              Исправлено
            </ButtonBase>
          )}
          {isRemarkResponsible && isShowReportFields && (
            <ButtonBase
              type="button"
              primary
              medium
              disabled={isResolveOrReturnButtonDisabled}
              onClick={() => handleSubmitAfterFillReportForm(resolveRemark)}
            >
              На проверку
            </ButtonBase>
          )}
          {isRemarkAuthor && detailRemarkInfo?.status === "review" && !isShowReportFields && (
            <>
              <ButtonBase
                type="button"
                secondary
                medium
                disabled={isAcceptLoading}
                className={styles.mr}
                onClick={handleOpenReportFields}
              >
                На доработку
              </ButtonBase>
              <ButtonBase type="button" primary medium disabled={isAcceptLoading} onClick={acceptRemark}>
                Устранено
              </ButtonBase>
            </>
          )}
          {isRemarkAuthor && detailRemarkInfo?.status === "review" && isShowReportFields && (
            <>
              <ButtonBase type="button" secondary medium className={styles.mr} onClick={handleCloseReportFields}>
                Отменить
              </ButtonBase>
              <ButtonBase
                type="button"
                primary
                medium
                disabled={isResolveOrReturnButtonDisabled}
                onClick={() => handleSubmitAfterFillReportForm(returnRemark)}
              >
                Подтвердить
              </ButtonBase>
            </>
          )}
        </div>
      )}
      {isShowEditRemark && (
        <CreateOrEditRemarkForm
          ref={formRef}
          onSubmit={onSubmit}
          initialValues={editRemarkInitialValues}
          handleOpenTable={handleOpenTable}
          handleCloseTable={handleCloseTable}
          selectedWorker={selectedWorker}
          handleRemoveSelected={handleRemoveSelected}
          handleSelectWorker={handleSelectWorker}
          files={files}
          setFiles={setFiles}
          isResetFiles={isResetFiles}
          isTableOpen={isTableOpen}
          isLoading={isEditLoading}
          isEditRemarkForm
          handleCloseForm={handleCloseEditForm}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      )}
      <ConfirmationModal
        variant="secondary"
        isOpen={isOpenDeleteModal}
        action={handleDeleteRemark}
        onClose={handleCloseDeleteModal}
      >
        Вы действительно хотите удалить замечание?
      </ConfirmationModal>
    </>
  );
};

export default React.memo(RemarkDetailInfo);
