import React, { FC, Fragment, useCallback } from "react";

import { EXPENDITURE_TYPES, EXPENDITURE_TYPES_NAMES } from "../../../../../../constants/constant";
import TableReusableHead, { TableReusableHeaderCell } from "../../../../atoms/TableReusable/TableReusableHead";
import PopoverOverlay from "../../../../molecules/PopoverOverlay/PopoverOverlay";

import { sliceTextByConstraint } from "../../../../../../utils/formatters/sliceTextByConstraint";
import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";
import { LOCALIZATION_CONFIG } from "../../../../../../constants/localization";

import cn from "classnames";
import styles from "./ExpendituresTable.module.scss";
import InputBase, { VALUE_TYPES } from "../../../../atoms/InputBase";
import { transformOptions } from "../../lib/transformOptions";


interface IProps {
  isEdit: boolean;
  isMarketPrice: boolean;
  expenditures: any[];
  onExpenditureEstimatedCostChange: (id: number, value: string) => void;
  isShowButtons: boolean;
}

export const ExpendituresTable: FC<IProps> = ({
  isEdit,
  isMarketPrice,
  expenditures,
  onExpenditureEstimatedCostChange,
  isShowButtons
}) => {
  return (
    <Fragment>
      <TableReusableHead className={cn(styles.tableHead, { [styles.expanded]: isMarketPrice })}>
        <TableReusableHeaderCell className={styles.number}>№</TableReusableHeaderCell>
        <TableReusableHeaderCell className={styles.name}>Наименование</TableReusableHeaderCell>
        <TableReusableHeaderCell className={styles.quantity} isCentered>Кол-во</TableReusableHeaderCell>
        <TableReusableHeaderCell
          className={styles.amount}>Стоимость {LOCALIZATION_CONFIG.currency}</TableReusableHeaderCell>
        {isMarketPrice && (
          <TableReusableHeaderCell
            className={styles.marketPrice}>(Рыночная, {LOCALIZATION_CONFIG.currency})</TableReusableHeaderCell>
        )}
      </TableReusableHead>
      <div className={cn(styles.tableContent, {[styles.withoutButtons]: !isShowButtons})}>
        <div className={cn(styles.expendituresContainer, { [styles.expanded]: isMarketPrice })}>
          {expenditures.map((item) => (
            <div
              key={item.id}
              className={cn(styles.expenditureItem,
                {
                  [styles.work]: item?.expenditure_type === EXPENDITURE_TYPES.WORK,
                  [styles.expanded]: isMarketPrice
                })}
            >
              <div>{item.number}</div>
              <div className={styles.flexColumn}>
                <div className={styles.itemName}>{item.name}</div>
                <div className={styles.bubblesWrapper}>
                  <div className={styles.bubble}>
                    <span>{EXPENDITURE_TYPES_NAMES[item.expenditure_type]}</span>
                  </div>
                  <div className={cn(styles.bubble, styles.justification)}>
                    <span>{item.justification || "-"}</span>
                  </div>
                  {item?.ret_own_mat && (
                    <PopoverOverlay
                      openType="hover"
                      placement="top"
                      popoverBorderColor="primary"
                      content={<div className={styles.popover}>От заказчика</div>}
                      popoverClassName={styles.popoverTooltip}
                      portalClassName={styles.popoverTooltip}
                      className={styles.popoverWrapper}
                    >
                      <div className={styles.retOwnMat}>Д</div>
                    </PopoverOverlay>
                  )}
                </div>
              </div>
              <div
                className={cn(styles.itemCenter, styles.quantity)}>{item.count} {sliceTextByConstraint(item.measure, 10)}</div>
              <div className={styles.itemRight}>
                {transformDigitToFinancial(+item.price * +item.count, { withFloat: true, dropZeros: true })}
              </div>
              {isMarketPrice && (
                <div className={styles.itemRight}>
                  {!isEdit
                    ? transformDigitToFinancial(item.estimated_cost, transformOptions)
                    : (
                      <InputBase
                        classNameInput={styles.input}
                        value={item.estimated_cost}
                        valueType={VALUE_TYPES.NUMBER}
                        numberTransformOptions={transformOptions}
                        onChange={(e) => {
                          onExpenditureEstimatedCostChange(item.id, e.target.value || '0');
                        }}
                      />
                    )
                  }
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};