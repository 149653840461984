import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isSessionValid, pageLoadingSelector, setCurrentUser, userSelector } from "redux/modules/common/auth";
import { loadObjectsBreadcrumbs, objectsBreadcrumbsLoadingSelector } from "redux/modules/common/building/objects";
import { socketConnectV2, socketKill as socketKillV2 } from "redux/modules/common/chatV2/socketEventsV2";
import { getUserPermissions } from "redux/modules/common/permissions/thunks/getUserPermissions";

import { OslaLoader } from "./pages/OslaLoader/OslaLoader";

import { permissionsLoadingSelector } from "features/permissions/model/selectors";
import { getCurrentUserPermissions } from "features/permissions/model/thunks";

import Routes from "./routes/Routes";

import "../scss/main.scss";

function Main() {
  const [sessionValid, setSessionValid] = React.useState(false);
  const pageIsLoading = useSelector(pageLoadingSelector);

  const dispatch = useDispatch();
  const userPermissionsAreLoading = useSelector(permissionsLoadingSelector);
  const breadcrumbsProjectsAreLoading = useSelector(objectsBreadcrumbsLoadingSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    dispatch(setCurrentUser());

    //dispatch(getCurrentUserPermissions());
    dispatch(isSessionValid(() => setSessionValid(true)));
    dispatch(loadObjectsBreadcrumbs({ limit: 1000 }));
  }, []);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(getCurrentUserPermissions(user?.id));
  }, [user?.id]);

  useEffect(() => {
    const key = localStorage.getItem("authToken");
    dispatch(socketConnectV2(key!));

    return () => {
      socketKillV2();
    };
  }, []);

  const showRoutes =
    user?.id && sessionValid && !userPermissionsAreLoading && !breadcrumbsProjectsAreLoading && !pageIsLoading;

  if (showRoutes) {
    return <Routes />;
  }

  return <OslaLoader />;
}

export default Main;
