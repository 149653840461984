import { message } from "antd";
import moment from "moment";
import { Dispatch } from "redux";

import { ManufacturingModalType } from "../../../../../components/pages/Manufacturing/components/modals/ManufacturingModal/useManufacturingModal";
import {
  DiagramFilters,
  EQUIPMENT_TAB_ID,
  MATERIALS_TAB_ID,
  MIMES_TAB_ID,
  RESOURCES_TAB_ID,
  WORKS_TAB_ID,
} from "../../../../../components/pages/Manufacturing/constants";
import { ManufacturingTabsType } from "../../../../../components/pages/Manufacturing/types";
import { getFetchYearsMonths } from "../../../../../components/pages/Manufacturing/utils";

import { RootState } from "../../../../rootReducer";
import { editGroupPlanInterval, editPlanInterval, editSectionPlan } from "../processApi";
import {
  addIntervalLinkAction,
  deleteArrowAction,
  disableIntervalLinkingAction,
  dropHighlightRelatedIntervalsAction,
  dropLoadedChartMonthsAction,
  enableIntervalLinkingAction,
  getEstimateEquipmentTreeAction,
  getEstimateMaterialsTreeAction,
  getEstimateMimesTreeAction,
  getEstimateResourcesTreeAction,
  getEstimateTreeAction,
  getIntervalAction,
  getMaterialIntervalAction,
  getMaterialsAction,
  getMimesAction,
  getModalIntervalAction,
  getModalIntervalFilesAction,
  getModalIntervalListAction,
  getModalIntervalMaterialsAction,
  getPlanAction,
  getProjectsAction,
  getWeekMaterialsAction,
  getWeekMimesAction,
  getWeekPlanAction,
  highlightRelatedIntervalsAction,
  loadAvailableIntervalsAction,
  loadAvailableSectionsAction,
  markConstructingYearMonthAction,
  markManufacturingYearMonthAction,
  projectEstimateSetAction,
  pushFromRelationsAction,
  pushToRelationsAction,
  removeFromRelationAction,
  removeToRelationAction,
  setActiveBranchAction,
  setArrowHashAction,
  setArrowsAction,
  setChartViewModeAction,
  setConstructingExpandedBranchesAction,
  setIsLoadingChartDataAction,
  setIsLoadingProjectsAction,
  setIsLockedIntervalEditing,
  setManufacturingExpandedBranchesAction,
  setManufacturingHashAction,
  setManufacturingMonthMarkersAction,
  setManufacturingTabAction,
  setMaterialDataAction,
  setMaterialWeekDataAction,
  setMimesDataAction,
  setMimesWeekDataAction,
  setModalIntervalDataAction,
  setModalMaterialsLoading,
  setProjectDataAction,
  setProjectWeekDataAction,
  setRelationsFromCurrentIntervalAction,
  setRelationsToCurrentIntervalAction,
  startDragIntervalAction,
  startLoadingChartTreeAction,
  startLoadingModalAction,
  stopDragIntervalAction,
  stopLoadingChartTreeAction,
  stopLoadingModalAction,
  updateArrowAction,
  updateDiagramFiltersAction,
  updateFromRelationAction,
  updateToRelationAction,
} from "./actions";
import {
  FACT_INTERVALS,
  HALF_MONTH,
  INTERVALS,
  MONTH,
  WEEK,
  YEAR,
  getInitialMaterialData,
  getInitialMimesData,
  getInitialModalData,
  getInitialWorksData,
} from "./manufacturing";
import {
  apiBulkCreateRelations,
  apiCreatePlanRelation,
  apiDeletePlanRelation,
  apiGetExpenditure,
  apiGetExpenditurePlan,
  apiGetInterval,
  apiGetIntervalFiles,
  apiGetIntervalList,
  apiGetMaterialInterval,
  apiGetMaterials,
  apiGetMimes,
  apiGetPlan,
  apiGetPlanRelations,
  apiGetWeekMaterials,
  apiGetWeekMimes,
  apiGetWeekPlan,
  apiGetWorkMaterials,
  apiPatchRelation,
  apiRemoveFile,
  apiUploadFile,
  loadIntervalsForRelations,
  loadSectionsForRelations,
} from "./manufacturingApi";
import { manufacturingApi } from "./manufacturingApi.ts";
import { chartViewModeSelector, diagramFiltersSelector } from "./selectors";

import { IBuildingRootResponse, IProjectTreeResponse, TRelationAvailableInterval } from "./types";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";
import {
  expenditureTypesByTab,
  fillDetailedCountExpenditures,
  getRelationsForBulkCreate,
  pushMaterialsToAcc,
  pushMimesToAcc,
  pushWorksToAcc,
} from "./utils";
import { simpleResourcesAPI } from "../../../../../features/simpleResources/lib/api";

export const loadTree = (
  projectId: number | string,
  expenditureTypesToLoad: Record<ManufacturingTabsType, boolean>
) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingChartTreeAction());
    let tree;
    let materialsTree;
    let mimesTree;
    let resourcesTree;
    let equipmentTree;

    await Promise.allSettled([
      expenditureTypesToLoad.work &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.work)
          .then(({ data }: { data: IProjectTreeResponse }) => (tree = data))
          .catch(errorCatcher),
      expenditureTypesToLoad.material &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.material)
          .then(({ data }: { data: IProjectTreeResponse }) => (materialsTree = data))
          .catch(errorCatcher),
      expenditureTypesToLoad.mims &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.mims)
          .then(({ data }: { data: IProjectTreeResponse }) => (mimesTree = data))
          .catch(errorCatcher),
      expenditureTypesToLoad.resources &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.resources)
          .then(({ data }: { data: IProjectTreeResponse }) => (resourcesTree = data))
          .catch(errorCatcher),
      expenditureTypesToLoad.equipment &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.equipment)
          .then(({ data }: { data: IProjectTreeResponse }) => (equipmentTree = data))
          .catch(errorCatcher),
    ]);

    if (tree) dispatch(getEstimateTreeAction(fillDetailedCountExpenditures(tree)));
    if (materialsTree) dispatch(getEstimateMaterialsTreeAction(materialsTree));
    if (mimesTree) dispatch(getEstimateMimesTreeAction(mimesTree));
    if (resourcesTree) dispatch(getEstimateResourcesTreeAction(resourcesTree));
    if (equipmentTree) dispatch(getEstimateEquipmentTreeAction(equipmentTree));
    dispatch(stopLoadingChartTreeAction());
  };
};

export const loadPlan = (projectId: number | string, year: number | string, month: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { loadedManufacturingChartData } = getState().manufacturing;

    const fetchedDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedManufacturingChartData,
      tabId: WORKS_TAB_ID,
    });

    let worksPlansData = await Promise.allSettled(
      fetchedDates.map((yearMonth) => apiGetPlan(projectId, ...yearMonth.split("-").map((x) => Number(x))))
    );

    const [startYear, startMonth] = fetchedDates[0]?.split("-")?.map((x) => Number(x)) || [];
    const [endYear, endMonth] =
      fetchedDates
        .at(-1)
        ?.split("-")
        ?.map((x) => Number(x)) || [];

    let newData = getInitialWorksData();

    if ([startYear, startMonth, endYear, endMonth].every((x) => x !== undefined)) {
      const startAtGroupDate = moment()
        .year(startYear)
        .month(startMonth - 1)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endAtGroupDate = moment()
        .year(endYear)
        .month(endMonth - 1)
        .endOf("month")
        .format("YYYY-MM-DD");

      await Promise.allSettled([
        manufacturingApi
          .getGroupsFacts(projectId, startAtGroupDate, endAtGroupDate)
          .then(({ data }) => (newData.groupWorks = data?.results || [])),
        manufacturingApi
          .getGroupsPlans(projectId, startAtGroupDate, endAtGroupDate)
          .then(({ data }) => (newData.groupPlans = data?.results || [])),
      ]);
    }

    if (worksPlansData) {
      worksPlansData.filter((x) => x.status === "fulfilled").forEach(({ value }) => pushWorksToAcc(newData, value));
    }

    dispatch(getPlanAction(newData));

    dispatch(
      markManufacturingYearMonthAction({
        projectId,
        fetchedDates,
        dataType: WORKS_TAB_ID,
      })
    );
  };
};

export const loadMaterials = (projectId, year, month) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { loadedManufacturingChartData } = getState().manufacturing;

    const fetchedDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedManufacturingChartData,
      tabId: MATERIALS_TAB_ID,
    });

    let data = await Promise.allSettled(
      fetchedDates.map((yearMonth) => apiGetMaterials(projectId, ...yearMonth.split("-").map((x) => Number(x))))
    );

    let newMaterialsData = getInitialMaterialData();

    data.filter((x) => x.status === "fulfilled").forEach(({ value }) => pushMaterialsToAcc(newMaterialsData, value));

    dispatch(getMaterialsAction(newMaterialsData));

    dispatch(
      markManufacturingYearMonthAction({
        projectId,
        dataType: MATERIALS_TAB_ID,
        fetchedDates,
      })
    );
  };
};

export const loadMimes = (projectId, year, month) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { loadedManufacturingChartData } = getState().manufacturing;

    const fetchedDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedManufacturingChartData,
      tabId: MIMES_TAB_ID,
    });

    let data = await Promise.allSettled(
      fetchedDates.map((yearMonth) => apiGetMimes(projectId, ...yearMonth.split("-").map((x) => Number(x))))
    );

    let newMimesData = getInitialMimesData();

    data.filter((x) => x.status === "fulfilled").forEach(({ value }) => pushMimesToAcc(newMimesData, value));

    dispatch(getMimesAction(newMimesData));

    dispatch(
      markManufacturingYearMonthAction({
        projectId,
        dataType: MIMES_TAB_ID,
        fetchedDates,
      })
    );
  };
};

export const loadWeekPlan = (
  objectid: number | string,
  start_week__gte: number,
  end_week__gte: number,
  year: string
) => {
  return async (dispatch: Dispatch) => {
    const weekData = getInitialWorksData();

    let weekPlan = await apiGetWeekPlan(objectid, start_week__gte, end_week__gte, year);

    pushWorksToAcc(weekData, weekPlan);

    if ([start_week__gte, end_week__gte, year].every((x) => x !== undefined)) {
      const startAtGroupDate = moment()
        .year(+year)
        .week(start_week__gte)
        .startOf("week")
        .format("YYYY-MM-DD");
      const endAtGroupDate = moment()
        .year(+year)
        .week(end_week__gte)
        .endOf("week")
        .format("YYYY-MM-DD");
      await Promise.allSettled([
        manufacturingApi
          .getGroupsFacts(objectid, startAtGroupDate, endAtGroupDate)
          .then(({ data }) => (weekData.groupWorks = data?.results || [])),
        manufacturingApi
          .getGroupsPlans(objectid, startAtGroupDate, endAtGroupDate)
          .then(({ data }) => (weekData.groupPlans = data?.results || [])),
      ]);
    }

    dispatch(getWeekPlanAction(weekData, year));
  };
};

export const loadWeekMaterials = (
  objectid: number | string,
  start_week__gte: number,
  end_week__gte: number,
  year: string
) => {
  return async (dispatch: Dispatch) => {
    const weekMaterials = await apiGetWeekMaterials(objectid, start_week__gte, end_week__gte, year);
    dispatch(getWeekMaterialsAction(weekMaterials, year));
  };
};

export const loadWeekMimes = (
  objectid: number | string,
  start_week__gte: number,
  end_week__gte: number,
  year: string
) => {
  return async (dispatch: Dispatch) => {
    const weekMimes = await apiGetWeekMimes(objectid, start_week__gte, end_week__gte, year);
    dispatch(getWeekMimesAction(weekMimes, year));
  };
};

export const loadInterval = (start_moment, end_moment) => {
  return async (dispatch: Dispatch) => {
    const interval = await apiGetInterval(start_moment.format("YYYY-MM-DD"), end_moment.format("YYYY-MM-DD"));
    dispatch(getIntervalAction(interval));
  };
};

export const loadMaterialInterval = (start_moment, end_moment) => {
  return async (dispatch: Dispatch) => {
    const materialInterval = await apiGetMaterialInterval(
      start_moment.format("YYYY-MM-DD"),
      end_moment.format("YYYY-MM-DD")
    );
    dispatch(getMaterialIntervalAction(materialInterval));
  };
};

export const setChartViewMode =
  (chartViewMode: typeof MONTH | typeof HALF_MONTH | typeof WEEK | typeof YEAR) => (dispatch: Dispatch) => {
    dispatch(setChartViewModeAction(chartViewMode));
  };

export const setManufacturingTab = (manufacturingTab) => (dispatch: Dispatch) => {
  dispatch(setManufacturingTabAction(manufacturingTab));
};

export const getModalIntervalList = ({
  projectId,
  expenditureId,
  activeModule,
  date_start,
  date_end,
  modalType,
  hasToRedirectOnSingleInterval,
  isGroupInterval,
}: {
  projectId: number | string;
  expenditureId: number;
  activeModule: "intervals" | "fact-intervals";
  date_start: string;
  date_end: string;
  modalType: ManufacturingModalType;
  hasToRedirectOnSingleInterval: boolean;
  isGroupInterval?: boolean;
}) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoadingModalAction(modalType));

    let intervalList = [];
    let groupIntervalList = [];

    await Promise.allSettled([
      !isGroupInterval &&
        apiGetIntervalList(projectId, expenditureId, activeModule, date_start, date_end, modalType).then(
          (data) => (intervalList = data)
        ),
      modalType === "section" &&
        manufacturingApi
          .getGroupsPlans(projectId, date_start, date_end, {
            limit: 500,
            section_id: expenditureId,
          })
          .then(({ data }) => (groupIntervalList = data.results)),
      modalType !== "section" &&
        isGroupInterval &&
        manufacturingApi
          .getGroupsPlans(projectId, date_start, date_end, {
            limit: 500,
            group_id: expenditureId,
          })
          .then(({ data }) => (groupIntervalList = data.results)),
    ]);

    const list = intervalList?.concat(groupIntervalList || []) || [];

    dispatch(getModalIntervalListAction(modalType, list));

    if (list?.length === 1 && hasToRedirectOnSingleInterval) {
      dispatch(
        getModalInterval(projectId, activeModule, list[0]?.expenditure_id, list[0]?.id, modalType, isGroupInterval)
      );
      !isGroupInterval && dispatch(getModalFiles(projectId, list[0].expenditure_id, activeModule, list[0].id));
    } else {
      dispatch(stopLoadingModalAction(modalType));
    }
  };
};

export const getModalInterval = (
  projectId: number | string,
  activeModule: "intervals" | "fact-intervals",
  expenditureId: number,
  intervalId: number,
  modalType: ManufacturingModalType,
  isGroup: boolean
) => {
  return async (dispatch: Dispatch) => {
    let modalInterval;
    dispatch(startLoadingModalAction(modalType));

    if (activeModule === FACT_INTERVALS) {
      if (isGroup) {
        modalInterval = await manufacturingApi
          .getGroupFact(projectId, intervalId)
          .then(({ data }) => data)
          .catch(errorCatcher);
        if (!!modalInterval) {
          await Promise.allSettled([
            manufacturingApi
              .getGroupRemarks(projectId, expenditureId)
              .then(({ data }) => {
                modalInterval.remarks = data?.results;
              })
              .catch(errorCatcher),
            manufacturingApi
              .getGroupTicketRemarks(projectId, intervalId)
              .then(({ data }) => {
                modalInterval.ticketRemarks = data?.results;
              })
              .catch(errorCatcher),
          ]);
        }
      } else {
        modalInterval = await apiGetExpenditure(projectId, expenditureId, intervalId, modalType);
        if (!!modalInterval) {
          await Promise.allSettled([
            simpleResourcesAPI
              .getAll({buildingId: projectId, fact_work_id: intervalId})
              .then((data) => {
                modalInterval.simpleResources = data?.results ?? []
              }),
            manufacturingApi
              .getWorkRemarks(projectId, expenditureId)
              .then(({ data }) => {
                modalInterval.remarks = data?.results;
              })
              .catch(errorCatcher),
            manufacturingApi
              .getWorkTicketRemarks(projectId, intervalId, { with_files: 1 })
              .then(({ data }) => {
                modalInterval.ticketRemarks = data?.results;
              })
              .catch(errorCatcher),
          ]);
        }
      }
    }

    if (activeModule === INTERVALS) {
      if (isGroup)
        modalInterval = await manufacturingApi
          .getGroupPlan(projectId, intervalId)
          .then(({ data }) => data)
          .catch(errorCatcher);
      else modalInterval = await apiGetExpenditurePlan(projectId, expenditureId, intervalId, modalType);
    }

    if (modalInterval) dispatch(getModalIntervalAction(modalType, modalInterval));

    dispatch(stopLoadingModalAction(modalType));
  };
};

export const getModalFiles = (projectId, expenditureId, activeModule, intervalId) => {
  return async (dispatch: Dispatch) => {
    const modalIntervalFiles = await apiGetIntervalFiles(projectId, expenditureId, activeModule, intervalId);
    dispatch(getModalIntervalFilesAction(modalIntervalFiles));
  };
};

export const downloadChartFile = async (projectId: string | number, year: string) => {
  message.info("Загрузка файла...");
  try {
    const response = await manufacturingApi.downloadChart(projectId, year);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `График - ${year}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  } catch (e) {
    message.error("Ошибка при загрузке файла");
  }
};

export const getModalWorkMaterials = (projectId, expenditureId, intervalId) => {
  return async (dispatch: Dispatch) => {
    dispatch(setModalMaterialsLoading(true));
    const modalIntervalMaterials = await apiGetWorkMaterials(projectId, expenditureId, intervalId);
    dispatch(getModalIntervalMaterialsAction(modalIntervalMaterials));
    dispatch(setModalMaterialsLoading(false));
  };
};

export const uploadModalFile = (projectId, expenditureId, activeModule, intervalId, formData) => {
  return async (dispatch: Dispatch) => {
    const modalIntervalFiles = await apiUploadFile(projectId, expenditureId, activeModule, intervalId, formData);
    dispatch(getModalIntervalFilesAction(modalIntervalFiles));
  };
};

export const removeModalFile = (projectId, expenditureId, activeModule, intervalId, fileId) => {
  return async (dispatch: Dispatch) => {
    const modalIntervalFiles = await apiRemoveFile(projectId, expenditureId, activeModule, intervalId, fileId);
    dispatch(getModalIntervalFilesAction(modalIntervalFiles));
  };
};

export const clearModalData = () => (dispatch: Dispatch) => {
  dispatch(setModalIntervalDataAction(getInitialModalData()));
};

export const clearModalInterval = (modalType: ManufacturingModalType) => (dispatch: Dispatch) => {
  dispatch(getModalIntervalAction(modalType, {}));
};

export const setIsLoadingChartData = (isLoading) => (dispatch: Dispatch) => {
  dispatch(setIsLoadingChartDataAction(isLoading));
};

export const mergeData = ({ projectId, year, month }) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { loadedConstructingChartData, tab } = getState().manufacturing;

    const fetchedWorkDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedConstructingChartData,
      tabId: WORKS_TAB_ID,
    });

    const fetchedMaterialDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedConstructingChartData,
      tabId: MATERIALS_TAB_ID,
    });

    const fetchedMimesDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedConstructingChartData,
      tabId: MIMES_TAB_ID,
    });

    let worksData =
      tab === WORKS_TAB_ID &&
      (await Promise.allSettled(
        fetchedWorkDates.map((yearMonth) => apiGetPlan(projectId, ...yearMonth.split("-").map((x) => Number(x))))
      ));

    let materialData =
      [MATERIALS_TAB_ID, RESOURCES_TAB_ID].includes(tab) &&
      (await Promise.allSettled(
        fetchedMaterialDates.map((yearMonth) =>
          apiGetMaterials(projectId, ...yearMonth.split("-").map((x) => Number(x)))
        )
      ));

    let mimesData =
      [MIMES_TAB_ID, EQUIPMENT_TAB_ID, RESOURCES_TAB_ID].includes(tab) &&
      (await Promise.allSettled(
        fetchedMimesDates.map((yearMonth) => apiGetMimes(projectId, ...yearMonth.split("-").map((x) => Number(x))))
      ));

    if (!worksData && !materialData && !mimesData) return;

    if (worksData) {
      let newData = getInitialWorksData();

      if (newData)
        worksData.filter((x) => x.status === "fulfilled").forEach(({ value }) => pushWorksToAcc(newData, value));

      const [startYear, startMonth] = fetchedWorkDates[0]?.split("-")?.map((x) => Number(x)) || [];
      const [endYear, endMonth] =
        fetchedWorkDates
          .at(-1)
          ?.split("-")
          ?.map((x) => Number(x)) || [];

      if ([startYear, startMonth, endYear, endMonth].every((x) => x !== undefined)) {
        const startAtGroupDate = moment()
          .year(startYear)
          .month(startMonth - 1)
          .startOf("month")
          .format("YYYY-MM-DD");
        const endAtGroupDate = moment()
          .year(endYear)
          .month(endMonth - 1)
          .endOf("month")
          .format("YYYY-MM-DD");

        await Promise.allSettled([
          manufacturingApi
            .getGroupsFacts(projectId, startAtGroupDate, endAtGroupDate)
            .then(({ data }) => (newData.groupWorks = data?.results || [])),
          manufacturingApi
            .getGroupsPlans(projectId, startAtGroupDate, endAtGroupDate)
            .then(({ data }) => (newData.groupPlans = data?.results || [])),
        ]);
      }
      dispatch(setProjectDataAction(newData));
      dispatch(
        markConstructingYearMonthAction({
          projectId,
          dataType: WORKS_TAB_ID,
          fetchedDates: fetchedWorkDates,
        })
      );
    }

    if (materialData) {
      let newMaterialData = getInitialMaterialData();
      materialData
        .filter((x) => x.status === "fulfilled")
        .forEach(({ value }) => pushMaterialsToAcc(newMaterialData, value));

      dispatch(setMaterialDataAction(newMaterialData));
      dispatch(
        markConstructingYearMonthAction({
          projectId,
          dataType: MATERIALS_TAB_ID,
          fetchedDates: fetchedMaterialDates,
        })
      );
    }

    if (mimesData) {
      let newMimesData = getInitialMimesData();
      mimesData.filter((x) => x.status === "fulfilled").forEach(({ value }) => pushMimesToAcc(newMimesData, value));

      dispatch(setMimesDataAction(newMimesData));
      dispatch(
        markConstructingYearMonthAction({
          projectId,
          dataType: MIMES_TAB_ID,
          fetchedDates: fetchedMimesDates,
        })
      );
    }
  };
};

export const mergeWeekData = ({ projectId, start_week, end_week, year }) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { tab } = getState().manufacturing;

    let weekData = tab === WORKS_TAB_ID && getInitialWorksData();
    let materialWeekData;
    let mimesWeekData;

    await Promise.allSettled([
      tab === WORKS_TAB_ID && apiGetWeekPlan(projectId, start_week, end_week, year).then((res) => (weekData = res)),
      [MATERIALS_TAB_ID, RESOURCES_TAB_ID].includes(tab) &&
        apiGetWeekMaterials(projectId, start_week, end_week, year).then((res) => (materialWeekData = res)),
      [MATERIALS_TAB_ID, EQUIPMENT_TAB_ID, RESOURCES_TAB_ID].includes(tab) &&
        apiGetWeekMimes(projectId, start_week, end_week, year).then((res) => (mimesWeekData = res)),
    ]);

    if (!weekData && !materialWeekData && !mimesWeekData) return;

    if (weekData) {
      if ([start_week, end_week, year].every((x) => x !== undefined)) {
        const startAtGroupDate = moment().year(year).week(start_week).startOf("week").format("YYYY-MM-DD");
        const endAtGroupDate = moment().year(year).week(end_week).endOf("week").format("YYYY-MM-DD");
        await Promise.allSettled([
          manufacturingApi
            .getGroupsFacts(projectId, startAtGroupDate, endAtGroupDate)
            .then(({ data }) => (weekData.groupWorks = data?.results || [])),
          manufacturingApi
            .getGroupsPlans(projectId, startAtGroupDate, endAtGroupDate)
            .then(({ data }) => (weekData.groupPlans = data?.results || [])),
        ]);
      }
      dispatch(setProjectWeekDataAction(weekData, year));
    }
    if (materialWeekData) dispatch(setMaterialWeekDataAction(materialWeekData, year));
    if (mimesWeekData) dispatch(setMimesWeekDataAction(mimesWeekData, year));
  };
};

export const projectEstimateSet = (projectId: number | string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { tab } = getState().manufacturing;

    let projectTree;
    let projectMaterialsTree;
    let projectMimesTree;
    let projectResourcesTree;
    let projectEquipmentTree;
    let projectPlanRelations;

    await Promise.allSettled([
      tab === WORKS_TAB_ID &&
        apiGetPlanRelations({ building_id: projectId }).then((res) => (projectPlanRelations = res.results)),
      tab === WORKS_TAB_ID &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.work)
          .then(({ data }: { data: IProjectTreeResponse }) => (projectTree = data)),
      tab === MATERIALS_TAB_ID &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.material)
          .then(({ data }: { data: IProjectTreeResponse }) => (projectMaterialsTree = data)),
      tab === MIMES_TAB_ID &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.mims)
          .then(({ data }: { data: IProjectTreeResponse }) => (projectMimesTree = data)),
      tab === RESOURCES_TAB_ID &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.resources)
          .then(({ data }: { data: IProjectTreeResponse }) => (projectResourcesTree = data)),
      tab === EQUIPMENT_TAB_ID &&
        manufacturingApi
          .getEstimateTree(projectId, expenditureTypesByTab.equipment)
          .then(({ data }: { data: IProjectTreeResponse }) => (projectEquipmentTree = data)),
    ]);

    if (projectTree) {
      const toSet = fillDetailedCountExpenditures(projectTree);

      dispatch(
        projectEstimateSetAction({
          key: `${projectId}_${WORKS_TAB_ID}`,
          value: toSet,
        })
      );
    }

    if (projectMaterialsTree)
      dispatch(
        projectEstimateSetAction({
          key: `${projectId}_${MATERIALS_TAB_ID}`,
          value: projectMaterialsTree,
        })
      );

    if (projectMimesTree)
      dispatch(
        projectEstimateSetAction({
          key: `${projectId}_${MIMES_TAB_ID}`,
          value: projectMimesTree,
        })
      );

    if (projectResourcesTree)
      dispatch(
        projectEstimateSetAction({
          key: `${projectId}_${RESOURCES_TAB_ID}`,
          value: projectResourcesTree,
        })
      );

    if (projectEquipmentTree)
      dispatch(
        projectEstimateSetAction({
          key: `${projectId}_${EQUIPMENT_TAB_ID}`,
          value: projectEquipmentTree,
        })
      );

    if (projectPlanRelations) dispatch(addIntervalLinkAction({ projectId, arrows: projectPlanRelations }));
  };
};

export const dropLoadedChartMonths = () => (dispatch: Dispatch) => {
  dispatch(dropLoadedChartMonthsAction());
};

export const setManufacturingMonthMarkers = (markers: number[], year: number | string) => (dispatch: Dispatch) => {
  dispatch(setManufacturingMonthMarkersAction(markers, year));
};

export const setActiveBranch =
  ({ index, eventFrom }) =>
  (dispatch: Dispatch) => {
    dispatch(setActiveBranchAction({ index, eventFrom }));
  };

export const updateDiagramFilters =
  ({ name, value }: { name: DiagramFilters; value: boolean }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    // XOR режимов редактирования графика
    if (name === DiagramFilters.linking_editing_enabled || name === DiagramFilters.plans_editing_enabled) {
      const diagramFilters = diagramFiltersSelector(getState());
      if (
        name === DiagramFilters.linking_editing_enabled &&
        value &&
        diagramFilters[DiagramFilters.plans_editing_enabled]
      ) {
        dispatch(updateDiagramFiltersAction({ name: DiagramFilters.plans_editing_enabled, value: false }));
      } else if (
        name === DiagramFilters.plans_editing_enabled &&
        value &&
        diagramFilters[DiagramFilters.linking_editing_enabled]
      ) {
        dispatch(updateDiagramFiltersAction({ name: DiagramFilters.linking_editing_enabled, value: false }));
      }
    }

    // Редактирование планов доступно только в режиме "Дни"
    if (name === DiagramFilters.plans_editing_enabled && value) {
      const chartViewMode = chartViewModeSelector(getState());
      if (chartViewMode !== MONTH && chartViewMode !== HALF_MONTH) {
        dispatch(setChartViewMode(MONTH));
      }
    }
    dispatch(updateDiagramFiltersAction({ name, value }));
  };

export const startDragInterval =
  ({ projectId, intervalId, startDate, endDate, origin, isGroup }) =>
  (dispatch: Dispatch) => {
    dispatch(startDragIntervalAction({ projectId, intervalId, startDate, endDate, origin, isGroup }));
  };

export const stopDragInterval =
  ({ projectId, intervalId }) =>
  (dispatch: Dispatch) => {
    dispatch(stopDragIntervalAction({ projectId, intervalId }));
  };

export const enableIntervalLinking = () => (dispatch: Dispatch) => {
  dispatch(enableIntervalLinkingAction());
};

export const disableIntervalLinking = () => (dispatch: Dispatch) => {
  dispatch(disableIntervalLinkingAction());
};

export const addIntervalLink =
  (projectId, { from_interval, from_group, to_interval, to_group, related_type }, successCallback) =>
  async (dispatch: Dispatch) => {
    const tempId = Math.random();
    dispatch(
      addIntervalLinkAction({
        projectId,
        arrows: { from_interval, to_interval, related_type, id: tempId },
      })
    );
    const newRelationResponse = await apiCreatePlanRelation(projectId, {
      from_interval,
      to_interval,
      related_type,
      from_group: from_group ?? null,
      to_group,
    });
    if (!newRelationResponse) {
      dispatch(deleteArrowAction({ projectId, arrowId: tempId }));
    } else {
      dispatch(
        updateArrowAction({
          projectId,
          arrowId: tempId,
          data: newRelationResponse,
        })
      );
      successCallback?.();
    }
  };

export const loadArrows = (projectId) => async (dispatch: Dispatch) => {
  const arrows = await apiGetPlanRelations({ building_id: projectId });
  if (!arrows || !arrows?.results) return;
  dispatch(setArrowsAction({ projectId, arrows: arrows.results }));
};

export const deleteArrow =
  ({ projectId, arrowId, direction }) =>
  async (dispatch: Dispatch) => {
    const deleteRelationOk = await apiDeletePlanRelation(projectId, arrowId);
    if (deleteRelationOk) {
      dispatch(deleteArrowAction({ projectId, arrowId }));
      if (direction === "to") {
        dispatch(removeToRelationAction(arrowId));
      }
      if (direction === "from") {
        dispatch(removeFromRelationAction(arrowId));
      }
      message.success("Связь удалена");
    }
    return deleteRelationOk;
  };

export const loadIntervalRelations =
  ({ projectId, intervalId, isGroup }) =>
  async (dispatch: Dispatch) => {
    let relationsFromCurrentInterval;
    let relationsToCurrentInterval;

    const promises = [];

    if (isGroup) {
      promises.push(
        apiGetPlanRelations({
          from_group: intervalId,
          building_id: projectId,
        }).then((res) => (relationsFromCurrentInterval = res)),
        apiGetPlanRelations({
          to_group: intervalId,
          building_id: projectId,
        }).then((res) => (relationsToCurrentInterval = res))
      );
    } else {
      promises.push(
        apiGetPlanRelations({
          from_interval: intervalId,
          building_id: projectId,
        }).then((res) => (relationsFromCurrentInterval = res)),
        apiGetPlanRelations({
          to_interval: intervalId,
          building_id: projectId,
        }).then((res) => (relationsToCurrentInterval = res))
      );
    }

    await Promise.allSettled(promises);

    if (relationsFromCurrentInterval?.results)
      dispatch(setRelationsFromCurrentIntervalAction(relationsFromCurrentInterval.results));
    if (relationsToCurrentInterval?.results)
      dispatch(setRelationsToCurrentIntervalAction(relationsToCurrentInterval.results));
  };

export const clearLoadedIntervalRelations = () => (dispatch: Dispatch) => {
  dispatch(setRelationsFromCurrentIntervalAction([]));
  dispatch(setRelationsToCurrentIntervalAction([]));
  dispatch(loadAvailableSectionsAction([]));
  dispatch(loadAvailableIntervalsAction([]));
};

export const loadRelationAvailableSections = (projectId) => async (dispatch: Dispatch) => {
  const sections = await loadSectionsForRelations(projectId);
  if (!sections) return [];
  dispatch(loadAvailableSectionsAction(sections));
};

export const loadRelationAvailableIntervals =
  ({ projectId, sectionId, start_at__gte, start_at__lte, end_at__gte, end_at__lte, intervalId }) =>
  async (dispatch: Dispatch) => {
    const intervals: TRelationAvailableInterval[] = await loadIntervalsForRelations({
      projectId,
      sectionId,
      start_at__gte,
      start_at__lte,
      end_at__gte,
      end_at__lte,
    });
    if (!intervals) return [];

    const formattedIntervals = [];
    intervals.forEach((x) => {
      const type = x.type;
      if (x.type === "group" && +x.group.id !== +intervalId) {
        formattedIntervals.push({ ...x.group, isGroup: true });
        return;
      }
      if (x.type === "expenditure" && +x.expenditure.id !== +intervalId) {
        formattedIntervals.push(x.expenditure);
      }
    });

    dispatch(loadAvailableIntervalsAction(formattedIntervals));
  };

export const clearRelationAvailableIntervals = () => (dispatch: Dispatch) => dispatch(loadAvailableIntervalsAction([]));

export const bulkCreateRelations =
  ({ intervalId, isIntervalGroup, relationCandidates, type, projectId, direction }) =>
  async (dispatch: Dispatch) => {
    const response = await apiBulkCreateRelations(
      projectId,
      getRelationsForBulkCreate({ intervalId, isIntervalGroup, relationCandidates, type, direction })
    );
    if (!response) return;
    if (direction === "to") {
      dispatch(pushToRelationsAction(response));
    }
    if (direction === "from") {
      dispatch(pushFromRelationsAction(response));
    }
    dispatch(addIntervalLinkAction({ projectId, arrows: response }));
    message.success("Связи успешно добавлены");
  };

export const updateRelationDayDelay =
  ({ relationId, delayDay, projectId, direction }) =>
  async (dispatch: Dispatch) => {
    const response = await apiPatchRelation({
      buildingId: projectId,
      relationId,
      data: { delay_day: delayDay },
    });
    if (!response) return;
    if (direction === "to") {
      dispatch(updateToRelationAction({ relationId, data: response }));
    }
    if (direction === "from") {
      dispatch(updateFromRelationAction({ relationId, data: response }));
    }
    dispatch(updateArrowAction({ projectId, arrowId: relationId, data: response }));
    message.success("Перерыв успешно обновлён");
  };

export const highlightRelatedIntervals =
  ({ intervalId, projectId }) =>
  (dispatch: Dispatch) => {
    dispatch(highlightRelatedIntervalsAction({ intervalId, projectId }));
  };

export const dropHighlightRelatedIntervals = () => (dispatch: Dispatch) => {
  dispatch(dropHighlightRelatedIntervalsAction());
};

export const setArrowHash = (hash) => (dispatch: Dispatch) => {
  dispatch(setArrowHashAction(hash));
};

export const setManufacturingHash = (hash) => (dispatch: Dispatch) => {
  dispatch(setManufacturingHashAction(hash));
};

export const setManufacturingExpandedBranches = (branchesSet) => (dispatch: Dispatch) => {
  dispatch(setManufacturingExpandedBranchesAction(branchesSet));
};

export const setConstructingExpandedBranches = (branchesSet) => (dispatch: Dispatch) => {
  dispatch(setConstructingExpandedBranchesAction(branchesSet));
};

export const loadManufacturingProjects = () => (dispatch: Dispatch) => {
  dispatch(setIsLoadingProjectsAction(true));
  return manufacturingApi
    .getManufacturingProjects()
    .then(({ data }: { data: IBuildingRootResponse }) => !!data && dispatch(getProjectsAction(data)))
    .catch(errorCatcher)
    .finally(() => dispatch(setIsLoadingProjectsAction(false)));
};

export const editDiagramIntervalDates =
  ({
    projectId,
    intervalId,
    expenditureId,
    isGroup,
    isSectionPlan,
    dateStart,
    dateEnd,
    successCallback,
    failCallback,
  }: {
    projectId: number | string;
    intervalId: number;
    expenditureId: number;
    isGroup?: boolean;
    isSectionPlan?: boolean;
    dateStart: string;
    dateEnd: string;
    successCallback?: () => void;
    failCallback?: () => void;
  }) =>
  (dispatch: Dispatch) => {
    let intervalEditPromise;
    const reloadDiagram = () => dispatch(setManufacturingHash(Math.random()));
    dispatch(setIsLockedIntervalEditing(true));
    if (isSectionPlan) {
      intervalEditPromise = editSectionPlan(projectId.toString(), intervalId, {
        start_at: dateStart,
        end_at: dateEnd,
      }).catch(errorCatcher);
    } else if (isGroup) {
      intervalEditPromise = editGroupPlanInterval({
        buildingId: Number(projectId),
        intervalId,
        data: { start_at: dateStart, end_at: dateEnd },
      }).catch(errorCatcher);
    } else {
      intervalEditPromise = editPlanInterval({
        buildingId: Number(projectId),
        intervalId,
        expenditureId,
        data: { start_at: dateStart, end_at: dateEnd },
      }).catch(errorCatcher);
    }

    intervalEditPromise
      .then(() => {
        reloadDiagram();
        successCallback?.();
      }, failCallback)
      .catch(errorCatcher)
      .finally(() => {
        dispatch(setIsLockedIntervalEditing(false));
      });
  };

export const shiftDiagramIntervalDates =
  ({
    intervalId,
    isGroup,
    days,
    successCallback,
    failCallback,
  }: {
    intervalId: number;
    isGroup?: boolean;
    days: number;
    successCallback?: () => void;
    failCallback?: () => void;
  }) =>
  (dispatch: Dispatch) => {
    const reloadDiagram = () => dispatch(setManufacturingHash(Math.random()));
    dispatch(setIsLockedIntervalEditing(true));

    manufacturingApi
      .shiftInterval(intervalId, days, isGroup ? "plan_group" : "plan_work")
      .then(() => {
        reloadDiagram();
        successCallback?.();
      }, failCallback)
      .catch(errorCatcher)
      .finally(() => {
        dispatch(setIsLockedIntervalEditing(false));
      });
  };
