import _ from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  loadTasksObjects,
  objectsTasksLoadingSelector,
  objectsTasksSelector,
} from "redux/modules/common/building/objects";
import { tasksFieldsSelector } from "redux/modules/purchaser/Tasks/selectors";
import { loadTasksFields } from "redux/modules/purchaser/Tasks/thunks";

import { useTasksModal } from "./useTasksModal";

import { IIdAndName } from "types/idAndName";

export type TaskObjectsFiltersType = { name?: string };

export interface IUseTaskObjectsSectionsUsers {
  building_id?: number | undefined;
  buildingFilterParams?: TaskObjectsFiltersType;
}

export interface ITaskObject extends IIdAndName {
  entity_name: string;
}

export const useTaskObjectsSectionsUsers = ({ building_id, buildingFilterParams }: IUseTaskObjectsSectionsUsers) => {
  const dispatch = useDispatch();
  const objects = useSelector(objectsTasksSelector);
  const isLoadingObjects = useSelector(objectsTasksLoadingSelector);
  const { section, users } = useSelector(tasksFieldsSelector);
  const { isOpen } = useTasksModal();

  const lastAppliedFilters = useRef<TaskObjectsFiltersType>();

  useEffect(() => {
    if (
      !isOpen ||
      (!!objects.results.length &&
        (_.isEqual(lastAppliedFilters.current, buildingFilterParams) ||
          (buildingFilterParams && !Object.values(buildingFilterParams).some((x) => x) && !lastAppliedFilters.current)))
    )
      return;
    dispatch(loadTasksObjects({ limit: 100 }, buildingFilterParams));
    lastAppliedFilters.current = buildingFilterParams;
  }, [buildingFilterParams, isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    building_id && dispatch(loadTasksFields({ limit: 100, building_id }));
  }, [building_id, isOpen]);

  return {
    objects: objects
      ? objects.results.map((x: ITaskObject) => ({
          id: x.id,
          name: x.name,
          label: x.name,
          entity_name: x.entity_name,
        }))
      : [],
    section: section.map((x) => ({ ...x, label: x.name })),
    users: users.map((x) => ({
      id: x.id,
      name: `${x.first_name} ${x.last_name}`,
      label: `${x.first_name} ${x.last_name}`,
    })),
    isLoadingObjects,
  };
};
