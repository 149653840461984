import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "redux/typedUseSelector";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBack from "components/UI/atoms/ButtonBack/ButtonBack";
import ProgressBar from "components/UI/atoms/ProgressBar/ProgressBar";

import HelpModal from "../ui/HelpModal/HelpModal";
import { confirmedIcon } from "./confirmedIcon";
import { getLearning } from "./model/thunks";
import { processIcon } from "./processIcon";
import ReactMarkdown from "react-markdown";

import { ILearningItem } from "./model/types";

import styles from "./Learning.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const Learning: React.FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const data = useTypedSelector((state) => state.learning.data);
  const isLoading = useTypedSelector((state) => state.learning.isLoading);

  const confirmedPercents = useMemo(() => {
    if (!data?.count) return 0;
    const percent = data.count / 100;
    const completedCount = data?.results?.filter((el) => el.confirmed_at)?.length ?? 0;
    const res = Math.round(completedCount / percent);
    if (res > 100) return 100;
    return res;
  }, [data]);

  const [activeLesson, setActiveLesson] = useState<null | ILearningItem>(null);

  useEffect(() => {
    if (!data) return;
    setActiveLesson((prev) => {
      if (!!prev) {
        return prev;
      } else {
        return data.results?.filter((el) => !el.confirmed_at)?.[0] ?? data.results?.[0];
      }
    });
  }, [data]);

  const [prevLesson, nextLesson] = useMemo(() => {
    if (!data?.results?.length || !activeLesson) {
      return [null, null] as const;
    }
    const index = data.results.findIndex((el) => el.key === activeLesson.key);
    if (index === -1) {
      return [null, null] as const;
    }
    return [data.results[index - 1] ?? null, data.results[index + 1] ?? null];
  }, [data, activeLesson]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getLearning());
    }
  }, [isOpen]);

  const onExpand = () => {
    dispatch(getLearning());
  };

  return (
    <HelpModal
      onExpand={onExpand}
      isOpen={isOpen}
      onClose={onClose}
      navHeader={
        <>
          <div>Обучение Osla</div>
          <ProgressBar completed={confirmedPercents} direction="row-reverse" containerClassName={styles.progress} />
        </>
      }
      navContent={
        <>
          {data?.results.map((el, i) => (
            <div
              key={el.key}
              className={cn(styles.item, {
                [styles.active]: el.key === activeLesson?.key,
                [styles.confirmed]: el.is_confirmed,
                [styles.disabled]: el.isDisabled,
              })}
              onClick={() => {
                setActiveLesson(data.results.find((item) => item.key === el.key) ?? null);
              }}
            >
              {el.menuTitle}
              {el.is_confirmed && confirmedIcon}
              {!el.is_confirmed && !el.isDisabled && processIcon}
            </div>
          ))}
        </>
      }
      contentHeader={
        <>
          <span className={styles.title}>
            {activeLesson?.menuTitle}&nbsp;<span>/</span>&nbsp;
            {activeLesson?.edu_data.title}
          </span>
          <div className={styles.subtitle}></div>
        </>
      }
      content={
        <div className={styles.content}>
          {isLoading && <Spinner />}
          {activeLesson?.edu_data.videos?.map((el, i) => (
            <div key={i} className={styles.video}>
              <video src={el.url} controls muted={false} controlsList="nodownload" />
            </div>
          ))}
          <ReactMarkdown>{activeLesson?.edu_data.description as string}</ReactMarkdown>
          <div className={styles.bottom}>
            <div className={cn(styles.prev, { [styles.btnDisabled]: prevLesson?.isDisabled })}>
              {prevLesson && <ButtonBack onClick={() => setActiveLesson(prevLesson)} />}
              {prevLesson?.menuTitle}
            </div>
            <div className={cn(styles.next, { [styles.btnDisabled]: nextLesson?.isDisabled })}>
              {nextLesson && <ButtonBack onClick={() => setActiveLesson(nextLesson)} />}
              {nextLesson?.menuTitle}
            </div>
          </div>
        </div>
      }
    ></HelpModal>
  );
};

export default Learning;
