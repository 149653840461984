import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IDetailedKs2InDocs } from "redux/modules/common/building/documents/ksList/types";
import { IDetailedKs2InActing } from "redux/modules/common/building/journal/acting/types";
import {
  ks2SectionsAttachmentsSelector,
  passportsInvalidateKeySelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import { getKs2SectionAttachments } from "redux/modules/common/passportsAndCertificates/thunks";
import { DocumentsTypesEnum } from "redux/modules/common/passportsAndCertificates/types";

import ExpandPillTrigger from "components/UI/atoms/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "components/UI/atoms/FilesClipButton/FilesClipButton";
import StatusElement from "components/UI/atoms/StatusElement/StatusElement";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";
import FilesModalKs2Section from "components/modals/ExpenditureFilesModal/FilesModalKs2Section";
import JournalRowIndicator from "components/pages/Journal/components/JournalActing/common/JournalRowIndicator/JournalRowIndicator";

import KS2PageRowContent from "./KS2PageRowContent/KS2PageRowContent";

import { getAttachmentName } from "../utils";
import { splitThousands } from "utils/formatters/splitThousands";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./KS2PageRow.module.scss";

interface IProps {
  section: IDetailedKs2InDocs["items"][0];
  isAutoExpand?: boolean;
  ks2Id: number;
}

const KS2PageRow: React.FC<IProps> = ({ section, isAutoExpand, ks2Id }) => {
  const dispatch = useDispatch();
  const [isFilesRow, setIsFilesRow] = useState(false);
  const [isFilesModal, setIsFilesModal] = useState(false);
  const invalidateDocsKey = useSelector(passportsInvalidateKeySelector);
  const attachments = useSelector(ks2SectionsAttachmentsSelector)[`${ks2Id}_${section?.section_id}`];

  const toggleFilesRow = useCallback(() => {
    setIsFilesRow((prev) => !prev);
  }, []);

  const toggleFilesModal = useCallback((event?: React.MouseEvent) => {
    event && event.stopPropagation();

    setIsFilesModal((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!isFilesRow) return;
    dispatch(getKs2SectionAttachments(ks2Id, section.section_id));
  }, [invalidateDocsKey, ks2Id, isFilesRow, section.section_id]);

  const filteredAttachments = useMemo(() => {
    return attachments?.results?.filter((el) => el.type !== DocumentsTypesEnum.file);
  }, [attachments]);

  return (
    <>
      <TableReusableRow
        isExpandable
        isParentOfExpenditures
        innerContent={<KS2PageRowContent expenditures={section.expenditures} sectionId={section.section_id} />}
        className={styles.row}
        containerClassName={styles.rowContainer}
        openClassName={styles.rowOpen}
        isDefaultExpanded={isAutoExpand}
      >
        <div className={styles.headRow}>
          <TableReusableCell>{section.section_name}</TableReusableCell>
          <TableReusableCell>
            <JournalRowIndicator title="Стоимость" value={splitThousands(section.amount)} />
          </TableReusableCell>
          <div className={styles.divider}></div>
          <TableReusableCell className={styles.lastIndicator}>
            <JournalRowIndicator title="Расценки" value={section.expenditures.length} />
          </TableReusableCell>
          <TableReusableCell isCentered onClick={stopEventPropagation} className={styles.fileCell}>
            <FilesClipButton onClick={toggleFilesModal} />
          </TableReusableCell>
          <TableReusableCell isCentered onClick={stopEventPropagation} className={styles.expandCell}>
            {!!section.attachments_count && (
              <ExpandPillTrigger
                isExpanded={isFilesRow}
                toggleExpand={toggleFilesRow}
                variant="success"
                label={`${section.attachments_count}`}
              />
            )}
          </TableReusableCell>
        </div>
        {isFilesRow && (
          <div className={styles.filesRow}>
            {filteredAttachments?.map((el) => (
              <StatusElement key={el.id} status="success" text={getAttachmentName(el)} onClick={toggleFilesModal} />
            ))}
          </div>
        )}
      </TableReusableRow>
      <FilesModalKs2Section
        isOpen={isFilesModal}
        onClose={toggleFilesModal}
        sectionId={section.section_id}
        ks2Id={ks2Id}
      />
    </>
  );
};

export default React.memo(KS2PageRow);
