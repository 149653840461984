import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useParams } from "react-router-dom";

import { addExpenditure } from "../../../redux/modules/common/building/materials/specification";

import ExpenditureForm from "../../UI/ExpenditureForm/ExpenditureForm";
import TabBarNotLinks from "../../UI/atoms/TabBar/TabBarNotLinks";
import BottomSliderMaterials from "./components/BottomSliderMaterials";
import Chapter from "./components/Chapter";
import Estimate from "./components/Estimate/Estimate";
import Import from "./components/Import";
import Mims from "./components/Mims/Mims";
import Specification from "./components/Specification/Specification";
import ButtonBase from "components/UI/atoms/ButtonBase";
import LabeledSwitch from "components/UI/atoms/LabeledSwitch";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";

import { IRouterParamsWithObjectId } from "../../../types/routerTypes";
import { ADDITIONAL, ESTIMATE, MIMS, SPECIFICATION, TABS_IDS } from "./constants";
import {
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL,
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import CrossRoundedIcon from "../../../images/icons/CrossRoundedIcon";
import TwoHorizontalSmallLinesIcon from "images/icons/TwoHorizontalSmallLinesIcon";

import styles from "./Materials.module.scss";

const Materials = ({ location, match, history }: RouteComponentProps) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const dispatch = useDispatch();

  const [isOpenModalAddSection, setIsOpenModalAddSection] = useState(false);
  const [isOpenModalAddExpenditures, setIsOpenModalAddExpenditures] = useState(false);
  const [isOpenModalImport, setIsOpenModalImport] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [titleBtn, setTitleBtn] = useState("Добавить раздел");
  const [idSubsection, setIdSubsection] = useState(null);
  const [activeSectionId, setActiveSectionId] = useState(null);
  const [addPosition, setAddPosition] = useState(false);

  const activeTabId = useMemo(() => {
    const partOfSectionPath = location.pathname.replace(match.url, "");

    return TABS_IDS.find((id) => `/${id}` === partOfSectionPath) || ESTIMATE;
  }, [location.pathname, match.url]);

  const handleChangeActiveTab = useCallback(
    (tabId: string) => {
      history.push(`${match.url}/${tabId}`);
    },
    [history, match.url]
  );

  useEffect(() => {
    if (!activeTabId) handleChangeActiveTab(ESTIMATE);
  }, []);

  const getCurrentTab = () => {
    switch (activeTabId) {
      case ESTIMATE:
        return <Estimate objectId={objectId} isSwitch={isSwitch} />;
      case SPECIFICATION:
        return (
          <Specification
            titleBtn={titleBtn}
            setIdSubsection={setIdSubsection}
            setActiveSectionId={setActiveSectionId}
            setTitleBtn={setTitleBtn}
            objectId={objectId}
            setAddPosition={setAddPosition}
            canEdit={haveEditSpecificationPermission}
          />
        );
      case MIMS:
        return (
          <Mims
            setIdSubsection={setIdSubsection}
            setTitleBtn={setTitleBtn}
            objectId={objectId}
            typeUrl={activeTabId}
            setAddPosition={setAddPosition}
            canEdit={haveEditAdditionalPermission}
          />
        );
    }
  };

  const closeModalAddSection = () => {
    setIsOpenModalAddSection(false);
  };

  const closeModalAddExpenditures = () => {
    setIsOpenModalAddExpenditures(false);
  };

  const closeModalImport = () => {
    setIsOpenModalImport(false);
  };

  useEffect(() => {
    if ([SPECIFICATION].includes(activeTabId)) {
      setAddPosition(false);
    }
  }, [activeTabId]);

  const submitAddPositionForm = useCallback(
    (values) => {
      closeModalAddExpenditures();
      dispatch(
        addExpenditure(objectId, values.from_estimate ? ADDITIONAL : activeTabId, {
          ...values,
          section_id: activeSectionId,
          ret_own_mat: false
        })
      );
    },
    [objectId, activeTabId, closeModalAddExpenditures, activeSectionId]
  );

  const haveEstimatePermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ESTIMATE);
  const haveSpecificationPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_SPECIFICATION);
  const haveAdditionalPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ADDITIONAL);

  const haveEditEstimatePermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING);
  const haveEditSpecificationPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING);
  const haveEditAdditionalPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING);

  useEffect(() => {
    if (!haveEstimatePermission && haveSpecificationPermission) {
      handleChangeActiveTab(SPECIFICATION);
    }
  }, [haveEstimatePermission, haveSpecificationPermission]);

  if (!haveEstimatePermission && !haveSpecificationPermission && !haveAdditionalPermission) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <>
      <BottomSliderMaterials isOpen={isOpenModalAddSection} closeFunc={closeModalAddSection}>
        <Chapter
          titleBtn={titleBtn}
          id={idSubsection}
          label={idSubsection ? "подраздел" : "раздел"}
          name={idSubsection ? "Наименование подраздела" : "Наименование раздела"}
          placeholder={idSubsection ? "Введите наименование подраздела" : "Введите наименование раздела"}
          objId={objectId}
          typeUrl={activeTabId}
          close={closeModalAddSection}
        />
      </BottomSliderMaterials>
      <BottomSliderMaterials isOpen={isOpenModalAddExpenditures} closeFunc={closeModalAddExpenditures}>
        <div className={styles.headerContainer}>
          <div className={styles.block1Title}>
            <span className={styles.title}>Добавить позицию</span>
            <div className={styles.closeIcon} onClick={closeModalAddExpenditures}>
              <CrossRoundedIcon />
            </div>
          </div>
        </div>
        <ExpenditureForm submit={submitAddPositionForm} isServices={activeTabId === MIMS} />
      </BottomSliderMaterials>
      <BottomSliderMaterials isOpen={isOpenModalImport} closeFunc={closeModalImport}>
        <Import objId={objectId} close={closeModalImport} />
      </BottomSliderMaterials>
      <TemplateBase>
        <div className={styles.container}>
          <TabBarNotLinks
            tabs={[
              {
                text: "По смете",
                id: ESTIMATE,
                isHidden: !haveEstimatePermission,
              },
              {
                text: "Спецификация",
                id: SPECIFICATION,
                isHidden: !haveSpecificationPermission,
              },
              {
                text: "МиМ",
                id: MIMS,
              },
            ]}
            activeId={activeTabId}
            onClick={handleChangeActiveTab}
            className={styles.tabs}
          />
          {haveEditEstimatePermission && activeTabId === ESTIMATE && (
            <div className={styles.boxEstimate}>
              <div className={styles.switchBox}>
                <LabeledSwitch
                  label={"Просмотр"}
                  labelRight={"Редактирование"}
                  onChange={() => setIsSwitch(!isSwitch)}
                  value={isSwitch}
                />
              </div>
            </div>
          )}
          {(activeTabId === SPECIFICATION || activeTabId === MIMS) && haveEditSpecificationPermission && (
            <div className={styles.actionBlock}>
              {titleBtn !== "Добавить позицию" && (
                <ButtonBase buttonPrimaryEmpty onClick={() => setIsOpenModalAddSection(true)}>
                  {titleBtn}
                </ButtonBase>
              )}
              {addPosition && activeTabId !== MIMS && (
                <ButtonBase buttonPrimaryEmpty onClick={() => setIsOpenModalAddExpenditures(true)}>
                  Добавить позицию
                  <span className={styles.plusIcon}>
                    <TwoHorizontalSmallLinesIcon />
                  </span>
                </ButtonBase>
              )}
              <ButtonBase
                className={styles.importButton}
                onClick={() => setIsOpenModalImport(true)}
                medium
                buttonPrimaryEmpty
              >
                Импорт
              </ButtonBase>
            </div>
          )}
        </div>
        {getCurrentTab()}
      </TemplateBase>
    </>
  );
};

export default Materials;
