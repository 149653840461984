import React from "react";

import { LOCALIZATION_CONFIG } from "constants/localization";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./Amounts.module.scss";

interface IProps {
  productsAmount: number;
  taxAmount: number;
  totalAmount: number;
  deliveryAmount: number;
  taxIsInclude: boolean;
  taxIncludeText?: string
}

const Amounts: React.FC<IProps> = ({
  productsAmount,
  taxAmount,
  totalAmount,
  deliveryAmount,
  taxIsInclude,
  taxIncludeText = "В том числе НДС"
}) => {
  return (
    <div className={styles.amounts}>
      <div className={styles.amountContainer}>
        <div className={styles.amountName}>
          <span className={styles.amountRow}>Товары:</span>
          {taxIsInclude && <span className={styles.amountRow}>{taxIncludeText}:</span>}
          <span className={styles.amountRow}>Доставка:</span>
          <span className={styles.amountRow}>Итого:</span>
        </div>

        <div className={styles.amount}>
          <span className={styles.amountRow}>{splitThousands(productsAmount, false, false)}</span>
          {taxIsInclude && <span className={styles.amountRow}>{splitThousands(taxAmount, false, false)}</span>}
          <span className={styles.amountRow}>{splitThousands(deliveryAmount, false, false)}</span>
          <span className={styles.amountRow}>{splitThousands(totalAmount, false, false)}</span>
        </div>
        <div className={styles.amountCurrency}>
          <span className={styles.amountRow}>{LOCALIZATION_CONFIG.currency}</span>
          {taxIsInclude && <span className={styles.amountRow}>{LOCALIZATION_CONFIG.currency}</span>}
          <span className={styles.amountRow}>{LOCALIZATION_CONFIG.currency}</span>
          <span className={styles.amountRow}>{LOCALIZATION_CONFIG.currency}</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Amounts);
