import _ from "lodash";
import { Dispatch } from "redux";

import { RootState } from "../../../../../rootReducer";
import { setSubtreeIsOpen } from "../financeCommonUtils";
import { auditActions } from "./actions";
import { auditApi } from "./auditApi";
import {
  allProjectsAuditsListSelector,
  auditDatesSelector,
  auditDetailedProjectsV2Selector,
  auditTreeSelector,
  auditTypeSelector,
  detailedProjectAuditsSelector,
  objectIdSelector,
} from "./selectors";

import { IAuditInitialState, IAuditTree } from "./types";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";
import { auditTypeToggler, produceMapAuditParentProjectFn, produceMapAuditProjectFn } from "./utils";

export const syncAuditsTree =
  (objectId?: number, projectId?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const allProjectsAuditList = allProjectsAuditsListSelector(
      getState()
    ) as IAuditInitialState["allProjectsAuditList"];
    const detailedObjectAudits = detailedProjectAuditsSelector(
      getState()
    ) as IAuditInitialState["detailedProjectAudits"];
    const detailedProjectsAudits = auditDetailedProjectsV2Selector(
      getState()
    ) as IAuditInitialState["detailedProjectsV2"];

    const pageObjectId = objectIdSelector(getState()) as number;
    const prevTree = auditTreeSelector(getState()) as IAuditTree[];

    const mapProject = produceMapAuditParentProjectFn(
      detailedObjectAudits,
      +(objectId || pageObjectId || -1),
      projectId,
      detailedProjectsAudits
    );

    const tree = allProjectsAuditList?.map(mapProject) || [];
    dispatch(auditActions.setAuditTree(tree as any));
  };

export const loadAllProjectsAudits = (isWithoutLoader?: boolean) => (dispatch: Dispatch, getState: () => RootState) => {
  const dates = auditDatesSelector(getState()) as IAuditInitialState["dates"];
  const auditType = auditTypeSelector(getState()) as IAuditInitialState["auditType"];

  !isWithoutLoader && dispatch(auditActions.setLoading(true));

  auditApi
    .getProjectsViewAudit(dates, auditType)
    .then(({ data }) => {
      dispatch(auditActions.setAllProjectsAuditList(data));
      if (data.length === 1) {
        dispatch(loadDetailedProjectAudit(data[0].id));
      } else {
        dispatch(syncAuditsTree());
      }
    })
    .catch(errorCatcher)
    .finally(() => !isWithoutLoader && dispatch(auditActions.setLoading(false)));
};

export const loadDetailedProjectAudit =
  (objectId: number, projectId?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const dates = auditDatesSelector(getState()) as IAuditInitialState["dates"];
    const auditType = auditTypeSelector(getState()) as IAuditInitialState["auditType"];
    const loadingByObject = getState().constructing.finance.audit.isLoadingByObjects;

    if (loadingByObject[objectId] === undefined) {
      dispatch(auditActions.setIsLoadingByObject(true, objectId));
    }
    return auditApi
      .getDetailedProjectAudit(objectId, dates, auditType)
      .then(({ data }) => {
        dispatch(auditActions.setDetailedProjectAudit(objectId, data));
        dispatch(syncAuditsTree(objectId, projectId));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(auditActions.setIsLoadingByObject(false, objectId));
      });
  };

export const setAuditDates =
  (dates: IAuditInitialState["dates"]) => (dispatch: Dispatch, getState: () => RootState) => {
    const detailedProjectAudits = detailedProjectAuditsSelector(
      getState()
    ) as IAuditInitialState["detailedProjectAudits"];
    const currentDates = auditDatesSelector(getState()) as IAuditInitialState["dates"];
    if (_.isEqual(currentDates, dates)) return;
    dispatch(auditActions.setAuditDates(dates));
    dispatch(loadAllProjectsAudits());
    Object.keys(detailedProjectAudits).forEach((objectId) => dispatch(loadDetailedProjectAudit(+objectId)));
  };

export const toggleOpenAuditTree = (subTree: IAuditTree) => async (dispatch: Dispatch, getState: () => RootState) => {
  const detailedProjectAudits = detailedProjectAuditsSelector(
    getState()
  ) as IAuditInitialState["detailedProjectAudits"];
  const detailedProjectsAudits = auditDetailedProjectsV2Selector(
    getState()
  ) as IAuditInitialState["detailedProjectsV2"];
  const projectId = getState().constructing.finance.audit.allProjectsAuditList.find((el) =>
    el.buildings.some((b) => b.id === subTree.id)
  )?.id!;
  const isNotExistObjectInStore = !detailedProjectAudits[subTree.id];
  if (subTree.isRoot && isNotExistObjectInStore) {
    await dispatch(loadDetailedProjectAudit(subTree.id, String(projectId)) as any);
    return;
  }
  const prevTree = auditTreeSelector(getState()) as IAuditTree[];
  const prevTreeCopy = Object.assign([], prevTree);
  setSubtreeIsOpen(prevTreeCopy, subTree, !subTree.isOpen);
  dispatch(auditActions.setAuditTree(prevTreeCopy));
};

export const toggleAuditType = () => (dispatch: Dispatch, getState: () => RootState) => {
  const currentAuditType: IAuditInitialState["auditType"] = auditTypeSelector(getState());
  dispatch(auditActions.setAuditType(auditTypeToggler(currentAuditType)));
};

//////////////////////////

export const getAuditProjectsV2 = (isWithoutLoader?: boolean) => (dispatch: Dispatch, getState: () => RootState) => {
  const dates = auditDatesSelector(getState()) as IAuditInitialState["dates"];
  const auditType = auditTypeSelector(getState()) as IAuditInitialState["auditType"];

  !isWithoutLoader && dispatch(auditActions.setLoading(true));

  auditApi
    .getProjectsAuditV2(dates, auditType)
    .then(({ data }) => {
      dispatch(auditActions.setAllProjectsAuditList(data));
      if (data.length === 1) {
        //dispatch(loadDetailedProjectAudit(data[0].id));
      } else {
        dispatch(syncAuditsTree() as any);
      }
    })
    .catch(errorCatcher)
    .finally(() => !isWithoutLoader && dispatch(auditActions.setLoading(false)));
};

export const getAuditDetailedProjectV2 =
  (projectId: string | number) => (dispatch: Dispatch, getState: () => RootState) => {
    const dates = auditDatesSelector(getState()) as IAuditInitialState["dates"];
    const auditType = auditTypeSelector(getState()) as IAuditInitialState["auditType"];
    ///loading by projects
    const loadingByObject = getState().constructing.finance.audit.isLoadingByObjects;

    if (loadingByObject[projectId] === undefined) {
      dispatch(auditActions.setIsLoadingByObject(true, +projectId));
    }
    return auditApi
      .getProjectsAuditV2(dates, auditType, projectId)
      .then(({ data }) => {
        dispatch(auditActions.setDetailedProjectAuditV2(+projectId, data[0]));
        if (data[0]?.buildings.length === 1) {
          dispatch(loadDetailedProjectAudit(data[0].buildings[0].id) as any);
        }
        setTimeout(() => {
          dispatch(syncAuditsTree(-1, projectId) as any);
        }, 1);
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(auditActions.setIsLoadingByObject(false, +projectId));
      });
  };
