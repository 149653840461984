import cn from "classnames";
import React from "react";

import NavigationBar from "../../molecules/NavigationBar";
import Header from "components/UI/molecules/Header/Header";

import styles from "./TemplateBase.module.scss";

export interface ITemplateBaseProps {
  children: React.ReactNode;
  contentClassName?: string;
  dataTestId?: string | number;
}

const TemplateBase: React.FC<ITemplateBaseProps> = ({ children, contentClassName, dataTestId = "templateBase" }) => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <NavigationBar />
        <div className={cn(styles.content, contentClassName)} data-testid={dataTestId}>
          {children}
        </div>
      </div>
    </>
  );
};

export default React.memo(TemplateBase);
