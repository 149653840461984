import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  expenditureAttachmentsSelector,
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  attachDocumentToExpenditure,
  attachFileToExpenditure,
  getExpendituresAttachments,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { ICreatePassport } from "redux/modules/common/passportsAndCertificates/types";

import CommonFilesModal from "./CommonFilesModal";

import { IExpenditure } from "types/interfaces/Expenditure";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditureName?: string;
  expenditureId: number;
  expenditureType?: IExpenditure["expenditure_type"];
}

const ExpenditureFilesModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  expenditureName,
  expenditureId,
  expenditureType,
}) => {
  const dispatch = useDispatch();
  const attachments = useSelector(expenditureAttachmentsSelector)[expenditureId];
  const invalidateKey = useSelector(passportsInvalidateKeySelector);
  const isPending = useSelector(passportsPendingSelector);

  React.useEffect(() => {
    if (!isOpen) return;
    dispatch(getExpendituresAttachments(expenditureId));
  }, [expenditureId, isOpen, invalidateKey]);

  const uploadFile = React.useCallback(
    (file: File) => {
      dispatch(attachFileToExpenditure(expenditureId, file));
    },
    [expenditureId]
  );

  const onSubmit = React.useCallback(
    (values: ICreatePassport) => {
      dispatch(attachDocumentToExpenditure(values, expenditureId));
    },
    [expenditureId]
  );

  return (
    <CommonFilesModal
      isOpen={isOpen}
      onClose={onClose}
      name={expenditureName}
      expenditureType={expenditureType}
      attachments={attachments?.results}
      isPending={isPending}
      expenditureId={expenditureId}
      invalidateKey={invalidateKey}
      onUpload={uploadFile}
      onSubmit={onSubmit}
    />
  );
};

export default ExpenditureFilesModal;
