import {createSelector} from 'reselect'

import {counterpartiesStateSelector} from '../counterparties'
import {moduleName as parentModuleName} from './index'

const initialState = {}

export const moduleName = 'index'

export const contactsStateSelector = createSelector(
  counterpartiesStateSelector,
  (state) => state[parentModuleName]
)
export const stateSelector = createSelector(contactsStateSelector, (state) => state[moduleName])

export default (state = initialState, action) => {
  const {type} = action
  switch (type) {
    default:
      return state
  }
}
