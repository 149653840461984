import moment from "moment";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { IDetailedKs3InDocs } from "redux/modules/common/building/documents/ksList/types";

import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";
import DateCell from "components/UI/atoms/DateCell/DateCell";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import { splitThousands } from "utils/formatters/splitThousands";

import StatusCanceledIcon from "images/icons/StatusCanceledIcon";

import styles from "./KsListRow.module.scss";

interface IProps {
  act: IDetailedKs3InDocs["ks2_acts"][0];
}

const KsListSubRow: React.FC<IProps> = ({ act }) => {
  const history = useHistory();
  const location = useLocation();

  const clickHandler = React.useCallback(() => {
    history.push(`${location.pathname}?ks2Id=${act.id}`);
  }, []);

  return (
    <TableReusableRow
      className={styles.subRow}
      containerClassName={styles.subRowContainer}
      isHoverable={false}
      onClick={clickHandler}
    >
      <TableReusableCell className={styles.ks}>
        <NavLinkIcon to="#" title={`КС-2 № ${act.title}`} className={styles.link} />
      </TableReusableCell>
      <TableReusableCell isNoBreak>{act.parent_section.name}</TableReusableCell>
      <TableReusableCell>
        <DateCell date={`${moment(act.start_at).format("DD/MM/YY")} - ${moment(act.end_at).format("DD/MM/YY")}`} />
      </TableReusableCell>
      <TableReusableCell>Всего расценок: {act.count_expenditures} шт.</TableReusableCell>
      <TableReusableCell>
        {/* <StatusCanceledIcon />
        <div className={styles.remark}>7</div> */}
      </TableReusableCell>
      <TableReusableCell>
        <div className={styles.right}>{splitThousands(act.amount, false, false)}</div>
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(KsListSubRow);
