import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  isServiceActsListLoadingSelector,
  serviceActsListSelector,
} from "redux/modules/common/building/documents/serviceActs/selectors";
import { getServiceActsList } from "redux/modules/common/building/documents/serviceActs/thunks";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import { DEFAULT_LIMIT_DOCUMENT } from "redux/modules/common/documents/constants";

import { Spinner } from "components/UI/Spinner/Spinner";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import ActListsRow from "./ActListsRow/ActListsRow";
import ActListsTableHeader from "./ActListsTableHeader/ActListsTableHeader";

import { statusActsArray } from "constants/constant";
import { IIdAndName } from "types/idAndName";
import { IServiceAct } from "types/interfaces/ServiceAct";

import { hasOnlyPaginationFilters } from "../../../../utils/hasOnlyPaginationFilters";

import ActsIcon from "images/icons/documentsTab/ActsIcon";

const ActLists = () => {
  const dispatch = useDispatch();
  const actsList = useSelector(serviceActsListSelector);

  const isLoading = useSelector(isServiceActsListLoadingSelector);
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  const [filters, setFilters] = useState({ limit: DEFAULT_LIMIT_DOCUMENT, offset: 0 });

  const isWithFilters = useMemo(() => !hasOnlyPaginationFilters(filters), [filters]);

  useEffect(() => {
    dispatch(getServiceActsList(filters));
  }, [dispatch, filters]);

  const formattedObjects = useMemo(() => {
    return breadcrumbsProjects.results.map((el: IIdAndName) => ({ id: el.id, name: el.name, label: el.name }));
  }, [breadcrumbsProjects]);

  const hasNextPage = useMemo(() => {
    if (actsList?.results?.length) {
      return actsList?.count > actsList?.results?.length;
    }

    return false;
  }, [actsList]);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const loadMore = () => {
    dispatch(
      getServiceActsList(
        {
          ...filters,
          offset: actsList?.results?.length,
        },
        true
      )
    );
  };

  const isEmpty = !isLoading && !actsList?.results.length;

  if (!isWithFilters && isLoading) return <Spinner />;

  return (
    <div data-testid="page_docs_acts">
      {((isEmpty && isWithFilters) || !isEmpty) && (
        <ActListsTableHeader
          onChangeFilter={changeFilters}
          statusBillsArray={statusActsArray}
          formattedObjects={formattedObjects}
        />
      )}
      {Array.isArray(actsList?.results) &&
        !isLoading &&
        actsList?.results.map((item: IServiceAct) => <ActListsRow key={item.id} listItem={item} />)}
      {isEmpty && <EmptyPlaceholder text="Нет Актов" svgJsx={<ActsIcon />} />}
      {isLoading && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage && !isLoading}
        showedCount={actsList?.results?.length}
        allCount={actsList?.count}
        handleAdd={loadMore}
      />
    </div>
  );
};

export default React.memo(ActLists);
