import _ from "lodash";

import { IProjectV2FinanceMData } from "./financeCommonTypes";

export const formatFinanceTreeViaObjectId = (
  tree: { id: number; children?: any[]; buildings?: any[] }[],
  objectId: number | string | undefined,
  projectId: number | string | undefined,
  totals?: Record<string, string>
) => {
  objectId = +(objectId || 0);
  projectId = +(projectId || 0);
  if (!!objectId) {
    return (
      tree
        .filter((p) => p.children?.some((o) => o.id === objectId))?.[0]
        ?.children?.filter((el) => el.id === objectId) || []
    );
  }
  if (!projectId) {
    return !!objectId ? tree?.filter((p) => p.id === objectId)?.[0]?.children || [] : tree;
  } else {
    const filteredTree = tree?.filter((p) => p.id === projectId);
    return filteredTree?.[0]?.children || filteredTree?.[0]?.buildings || [];
  }
};

export const setSubtreeIsOpen = (
  tree: { isOpen: boolean; children?: any[] }[],
  targetSubTree: { isOpen: boolean; children?: any[] },
  isOpen: boolean
) => {
  const targetSection = tree.find((s) => _.isEqual(s, targetSubTree));
  if (targetSection) {
    targetSection.isOpen = isOpen;
  } else {
    tree.forEach((section) => {
      if (section.children) {
        setSubtreeIsOpen(section.children, targetSubTree, isOpen);
      }
    });
  }
};

export const prepareProjectV2FinanceTotals = (data: IProjectV2FinanceMData) => {
  let res: Record<string, string> = {};

  const totalKeys = Object.keys(data?.m_data || {});
  totalKeys.forEach((el) => {
    const key =
      +el
        .split("")
        .filter((e) => !isNaN(+e))
        .join("") - 1;
    const planFact = {
      [`plan_${key}`]: data?.m_data?.[el]?.plan,
      [`fact_${key}`]: data?.m_data?.[el]?.fact,
      [`diff_${key}`]: data?.m_data?.[el]?.diff,
      key: "total",
    };
    res = {
      ...res,
      ...planFact,
    };
  });

  const quartalKeys = Object.keys(data?.q_data || {});
  quartalKeys.forEach((key) => {
    const planFact = {
      [`plan_${key}`]: data?.q_data?.[key]?.plan,
      [`fact_${key}`]: data?.q_data?.[key]?.fact,
      [`diff_${key}`]: data?.q_data?.[key]?.diff,
      key: "total",
    };
    res = {
      ...res,
      ...planFact,
    };
  });

  return res;
};
