import React from 'react';
import {MONTH_COLOR_MAP} from '../components/pages/Manufacturing/constants'

export default function TicketTail({borderColor = MONTH_COLOR_MAP['default'], fillColor = 'none', isRight= false, linesColor= null, wideLinesColor = null}){
  return (
    <svg width="7" height="35" viewBox="0 0 5 35" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: `${isRight ? 'scaleX(-1) scaleY(-1) translateX(.6px)' : 'translateX(.6px)'}`}}>
      <path d="M1.06067 6.42567C3.77149 5.96832 5.90585 3.79178 6.29935 1.06068V0H7V35H6.29986V33.9393C5.90751 31.2076 3.77256 29.0301 1.06067 28.5732V26.7462C1.99704 26.3361 2.65297 25.4016 2.65297 24.3179C2.65297 23.2328 1.99702 22.2975 1.06067 21.8874V19.93C1.99704 19.5193 2.65297 18.5834 2.65297 17.4981C2.65297 16.4135 1.99702 15.4785 1.06067 15.0685V13.1111C1.99704 12.7005 2.65297 11.7646 2.65297 10.6793C2.65297 9.59464 1.99702 8.6597 1.06067 8.2497V6.42567Z" fill={'white'}/>
      <path d="M1.06068 6.42567C3.7715 5.96832 5.90586 3.79178 6.29937 1.06068V0H5.30173C5.3031 0.0495089 5.30382 0.0990802 5.30382 0.148932C5.30382 3.07342 2.92543 5.45263 0 5.45369V9.0889C0.877996 9.08943 1.5923 9.80286 1.5923 10.6793C1.5923 11.5572 0.878932 12.2714 0 12.2725V15.9077C0.877996 15.9082 1.5923 16.6216 1.5923 17.4981C1.5923 18.376 0.8789 19.0903 0 19.0914V22.7265C0.877996 22.727 1.5923 23.4409 1.5923 24.3179C1.5923 25.1937 0.8789 25.9062 0 25.9073V29.5453C2.92455 29.5459 5.30382 31.9243 5.30382 34.8473C5.30382 34.8984 5.30304 34.9492 5.30288 35H6.29987V33.9393C5.90753 31.2076 3.77258 29.0301 1.06068 28.5732V26.7462C1.99706 26.3361 2.65299 25.4016 2.65299 24.3179C2.65299 23.2328 1.99703 22.2975 1.06068 21.8874V19.93C1.99706 19.5193 2.65299 18.5834 2.65299 17.4981C2.65299 16.4135 1.99703 15.4785 1.06068 15.0685V13.1111C1.99706 12.7005 2.65299 11.7646 2.65299 10.6793C2.65299 9.59464 1.99703 8.6597 1.06068 8.2497V6.42567Z" fill={borderColor}/>
      <path d="M4.33402 5.49935C4.33402 5.22435 4.63263 5 5 5C5.36737 5 5.66598 5.22435 5.66598 5.49935C5.66598 5.77436 5.36737 5.99871 5 5.99871C4.63263 5.99871 4.33402 5.77539 4.33402 5.49935Z" fill="#EBEBEB"/>
      <path d="M5.66803 6.50013C5.66803 6.22409 5.96665 5.99974 6.33402 5.99974C6.70139 5.99974 7 6.22409 7 6.50013C7 6.77617 6.6942 6.99742 6.32991 6.99742C5.96562 6.99742 5.66803 6.77514 5.66803 6.50013Z" fill="#EBEBEB"/>
      <path d="M7 4.49961C7 4.22357 6.70139 3.99922 6.33402 3.99922C5.96665 3.99922 5.66803 4.22357 5.66803 4.49961C5.66803 4.77565 5.96665 4.99897 6.33402 4.99897C6.70139 4.99897 7 4.77565 7 4.49961Z" fill="#EBEBEB"/>
      <path d="M7 2.50013C7 2.77617 6.6942 2.99742 6.32991 2.99742C6.10818 2.99742 5.91116 2.91507 5.79092 2.7886C5.90188 2.54616 5.99804 2.2956 6.07826 2.03807C6.15703 2.01338 6.24343 1.99974 6.33402 1.99974C6.70139 1.99974 7 2.22409 7 2.50013Z" fill="#EBEBEB"/>
      <path d="M6.37136 0C6.72141 0.0145895 7 0.232988 7 0.499612C7 0.775652 6.70139 0.998966 6.33402 0.998966C6.32239 0.998966 6.31083 0.998743 6.29935 0.998301V0H6.37136Z" fill="#EBEBEB"/>
      <path d="M2.6354 5.94214C2.66772 5.92737 2.6999 5.91234 2.73193 5.89704C2.70159 5.91402 2.66931 5.92912 2.6354 5.94214Z" fill="#EBEBEB"/>
      <path d="M1.06067 6.99717V6.42567C1.248 6.39407 1.43258 6.35425 1.61402 6.30661C1.64748 6.36611 1.66598 6.43142 1.66598 6.49987C1.66598 6.75957 1.39969 6.97409 1.06067 6.99717Z" fill="#EBEBEB"/>
      <path d="M1.65073 8.60579C1.47143 8.46296 1.27328 8.34279 1.06067 8.2497V8.00206C1.39969 8.02513 1.66598 8.23966 1.66598 8.49935C1.66598 8.53586 1.66072 8.57148 1.65073 8.60579Z" fill="#EBEBEB"/>
      <path d="M2.55591 9.96796C2.46007 9.62481 2.29667 9.30946 2.081 9.03722C2.15904 9.01307 2.24449 8.99974 2.33402 8.99974C2.70139 8.99974 3 9.22409 3 9.50013C3 9.71637 2.81234 9.89899 2.55591 9.96796Z" fill="#EBEBEB"/>
      <path d="M2.30096 11.9984C2.46609 11.7115 2.57913 11.3912 2.62709 11.0503C2.84782 11.1318 3 11.3026 3 11.4996C3 11.7757 2.70139 11.999 2.33402 11.999C2.32293 11.999 2.31191 11.9988 2.30096 11.9984Z" fill="#EBEBEB"/>
      <path d="M1.06067 14.9972V14.0026C1.39969 14.0257 1.66598 14.2402 1.66598 14.4999C1.66598 14.7596 1.39969 14.9741 1.06067 14.9972Z" fill="#EBEBEB"/>
      <path d="M2.17698 15.985C2.03477 15.7814 1.86473 15.5986 1.67241 15.4421C1.71048 15.1931 1.99255 14.9992 2.33402 14.9992C2.70139 14.9992 3 15.2236 3 15.4996C3 15.7757 2.70139 15.999 2.33402 15.999C2.27994 15.999 2.22734 15.9941 2.17698 15.985Z" fill="#EBEBEB"/>
      <path d="M2.61445 17.9498C2.63977 17.803 2.65297 17.652 2.65297 17.4981C2.65297 17.3441 2.63974 17.1931 2.61437 17.0462C2.84196 17.1258 3 17.2993 3 17.5001C3 17.6996 2.8404 17.8704 2.61445 17.9498Z" fill="#EBEBEB"/>
      <path d="M1.67224 19.556C1.86432 19.3996 2.03419 19.2168 2.17629 19.0134C2.22687 19.0041 2.27969 18.9992 2.33402 18.9992C2.70139 18.9992 3 19.2236 3 19.4996C3 19.7757 2.70139 19.999 2.33402 19.999C1.99204 19.999 1.70965 19.8055 1.67224 19.556Z" fill="#EBEBEB"/>
      <path d="M1.06067 20.9967V20.0021C1.39969 20.0251 1.66598 20.2397 1.66598 20.4994C1.66598 20.7591 1.39969 20.9736 1.06067 20.9967Z" fill="#EBEBEB"/>
      <path d="M2.62724 23.9481C2.5794 23.6071 2.46639 23.2867 2.30126 22.9998C2.31211 22.9994 2.32303 22.9992 2.33402 22.9992C2.70139 22.9992 3 23.2236 3 23.4996C3 23.6966 2.84789 23.8668 2.62724 23.9481Z" fill="#EBEBEB"/>
      <path d="M2.07969 25.9605C2.29608 25.6879 2.45999 25.3721 2.55601 25.0283C2.81448 25.0971 3 25.2826 3 25.5001C3 25.7762 2.6942 25.9974 2.32991 25.9974C2.24147 25.9974 2.15696 25.9843 2.07969 25.9605Z" fill="#EBEBEB"/>
      <path d="M1.06067 26.9972V26.7462C1.27297 26.6532 1.47086 26.5333 1.64995 26.3908C1.66045 26.4259 1.66598 26.4624 1.66598 26.4999C1.66598 26.7596 1.39969 26.9741 1.06067 26.9972Z" fill="#EBEBEB"/>
      <path d="M1.61427 28.6922C1.43275 28.6445 1.24808 28.6047 1.06067 28.5732V28.0021C1.39969 28.0251 1.66598 28.2397 1.66598 28.4994C1.66598 28.5676 1.64757 28.6328 1.61427 28.6922Z" fill="#EBEBEB"/>
      <path d="M2.75415 29.1121C2.70612 29.0889 2.65776 29.0664 2.60908 29.0444C2.66139 29.0623 2.7101 29.0851 2.75415 29.1121Z" fill="#EBEBEB"/>
      <path d="M5.56526 31.7631C5.4173 31.4973 5.25085 31.2433 5.06767 31.0026C5.40334 31.0281 5.66598 31.2414 5.66598 31.4994C5.66598 31.596 5.62908 31.6864 5.56526 31.7631Z" fill="#EBEBEB"/>
      <path d="M6.07865 32.9602C5.99851 32.7028 5.90241 32.4524 5.79154 32.2101C5.91238 32.0828 6.11047 31.9997 6.33402 31.9997C6.70139 31.9997 7 32.2241 7 32.5001C7 32.7762 6.6942 32.9974 6.32991 32.9974C6.24108 32.9974 6.15621 32.9842 6.07865 32.9602Z" fill="#EBEBEB"/>
      <path d="M6.29986 34.9983V33.9999C6.31117 33.9994 6.32256 33.9992 6.33402 33.9992C6.70139 33.9992 7 34.2236 7 34.4996C7 34.7757 6.70139 34.999 6.33402 34.999C6.32256 34.999 6.31117 34.9987 6.29986 34.9983Z" fill="#EBEBEB"/>
      <path d="M7 10.5001C7 10.7762 6.6942 10.9974 6.32991 10.9974C5.96562 10.9974 5.66803 10.7751 5.66803 10.5001C5.66803 10.2241 5.96665 9.99974 6.33402 9.99974C6.70139 9.99974 7 10.2241 7 10.5001Z" fill="#EBEBEB"/>
      <path d="M7 12.4996C7 12.2236 6.70139 11.9992 6.33402 11.9992C5.96665 11.9992 5.66803 12.2236 5.66803 12.4996C5.66803 12.7757 5.96665 12.999 6.33402 12.999C6.70139 12.999 7 12.7757 7 12.4996Z" fill="#EBEBEB"/>
      <path d="M7 14.5001C7 14.7762 6.6942 14.9974 6.32991 14.9974C5.96562 14.9974 5.66803 14.7751 5.66803 14.5001C5.66803 14.2241 5.96665 13.9997 6.33402 13.9997C6.70139 13.9997 7 14.2241 7 14.5001Z" fill="#EBEBEB"/>
      <path d="M7 16.4996C7 16.2236 6.70139 15.9992 6.33402 15.9992C5.96665 15.9992 5.66803 16.2236 5.66803 16.4996C5.66803 16.7757 5.96665 16.999 6.33402 16.999C6.70139 16.999 7 16.7757 7 16.4996Z" fill="#EBEBEB"/>
      <path d="M7 18.5001C7 18.7762 6.6942 18.9974 6.32991 18.9974C5.96562 18.9974 5.66803 18.7751 5.66803 18.5001C5.66803 18.2241 5.96665 17.9997 6.33402 17.9997C6.70139 17.9997 7 18.2241 7 18.5001Z" fill="#EBEBEB"/>
      <path d="M7 20.4996C7 20.2236 6.70139 19.9992 6.33402 19.9992C5.96665 19.9992 5.66803 20.2236 5.66803 20.4996C5.66803 20.7757 5.96665 20.999 6.33402 20.999C6.70139 20.999 7 20.7757 7 20.4996Z" fill="#EBEBEB"/>
      <path d="M7 22.5001C7 22.7762 6.6942 22.9974 6.32991 22.9974C5.96562 22.9974 5.66803 22.7751 5.66803 22.5001C5.66803 22.2241 5.96665 21.9997 6.33402 21.9997C6.70139 21.9997 7 22.2241 7 22.5001Z" fill="#EBEBEB"/>
      <path d="M7 24.4996C7 24.2236 6.70139 23.9992 6.33402 23.9992C5.96665 23.9992 5.66803 24.2236 5.66803 24.4996C5.66803 24.7757 5.96665 24.999 6.33402 24.999C6.70139 24.999 7 24.7757 7 24.4996Z" fill="#EBEBEB"/>
      <path d="M7 26.5001C7 26.7762 6.6942 26.9974 6.32991 26.9974C5.96562 26.9974 5.66803 26.7751 5.66803 26.5001C5.66803 26.2241 5.96665 25.9997 6.33402 25.9997C6.70139 25.9997 7 26.2241 7 26.5001Z" fill="#EBEBEB"/>
      <path d="M7 28.4996C7 28.2236 6.70139 27.9992 6.33402 27.9992C5.96665 27.9992 5.66803 28.2236 5.66803 28.4996C5.66818 28.7756 5.96674 28.999 6.33402 28.999C6.70139 28.999 7 28.7757 7 28.4996Z" fill="#EBEBEB"/>
      <path d="M7 30.5001C7 30.2241 6.70138 29.9997 6.33402 29.9997C5.96665 29.9997 5.66803 30.2241 5.66803 30.5001C5.66803 30.7762 5.96665 30.999 6.33402 30.999C6.70139 30.999 7 30.7762 7 30.5001Z" fill="#EBEBEB"/>
      <path d="M5.06646 3.99676C5.40271 3.97164 5.66598 3.75809 5.66598 3.49987C5.66598 3.4029 5.62885 3.31222 5.56467 3.23546C5.41653 3.50147 5.24988 3.75582 5.06646 3.99676Z" fill="#EBEBEB"/>
      <path d="M7 8.49961C7 8.22357 6.70139 7.99922 6.33402 7.99922C5.96665 7.99922 5.66803 8.22357 5.66803 8.49961C5.66803 8.77565 5.96665 8.99897 6.33402 8.99897C6.70139 8.99897 7 8.77565 7 8.49961Z" fill="#EBEBEB"/>
      <path d="M5 7.00052C4.63263 7.00052 4.33402 7.22486 4.33402 7.49987C4.33402 7.77591 4.63263 7.99922 5 7.99922C5.36737 7.99922 5.66598 7.77488 5.66598 7.49987C5.66598 7.22486 5.36737 7.00052 5 7.00052Z" fill="#EBEBEB"/>
      <path d="M3.66598 8.00026C3.29884 8.00026 3.00037 8.22484 3 8.49961C3.00037 8.77645 3.29782 9 3.66598 9C4.03438 9 4.33196 8.77462 4.33196 8.49961C4.33196 8.22461 4.03335 8.00026 3.66598 8.00026Z" fill="#EBEBEB"/>
      <path d="M3.66598 5.99974C3.29861 5.99974 3 6.22409 3 6.50013C3 6.77514 3.29861 6.99742 3.66701 6.99742C4.0354 6.99742 4.33196 6.77617 4.33196 6.50013C4.33196 6.22409 4.03335 5.99974 3.66598 5.99974Z" fill="#EBEBEB"/>
      <path d="M3.66598 12.0003C3.29884 12.0003 3.00037 12.2248 3 12.4996C3.00037 12.7764 3.29782 13 3.66598 13C4.03438 13 4.33196 12.7746 4.33196 12.4996C4.33196 12.2246 4.03335 12.0003 3.66598 12.0003Z" fill="#EBEBEB"/>
      <path d="M5 11.0005C4.63263 11.0005 4.33402 11.2249 4.33402 11.4999C4.33402 11.7759 4.63263 11.9992 5 11.9992C5.36737 11.9992 5.66598 11.7749 5.66598 11.4999C5.66598 11.2249 5.36737 11.0005 5 11.0005Z" fill="#EBEBEB"/>
      <path d="M3.66598 9.99974C3.29861 9.99974 3 10.2241 3 10.5001C3 10.7751 3.29861 10.9974 3.66701 10.9974C4.0354 10.9974 4.33196 10.7762 4.33196 10.5001C4.33196 10.2241 4.03335 9.99974 3.66598 9.99974Z" fill="#EBEBEB"/>
      <path d="M4.33402 9.49935C4.33402 9.22435 4.63263 9 5 9C5.36737 9 5.66598 9.22435 5.66598 9.49935C5.66598 9.77436 5.36737 9.99871 5 9.99871C4.63263 9.99871 4.33402 9.77539 4.33402 9.49935Z" fill="#EBEBEB"/>
      <path d="M3.66598 16.0003C3.29884 16.0003 3.00037 16.2248 3 16.4996C3.00037 16.7764 3.29782 17 3.66598 17C4.03438 17 4.33196 16.7746 4.33196 16.4996C4.33196 16.2246 4.03335 16.0003 3.66598 16.0003Z" fill="#EBEBEB"/>
      <path d="M5 15.0005C4.63263 15.0005 4.33402 15.2249 4.33402 15.4999C4.33402 15.7759 4.63263 15.9992 5 15.9992C5.36737 15.9992 5.66598 15.7749 5.66598 15.4999C5.66598 15.2249 5.36737 15.0005 5 15.0005Z" fill="#EBEBEB"/>
      <path d="M3.66598 13.9997C3.29861 13.9997 3 14.2241 3 14.5001C3 14.7751 3.29861 14.9974 3.66701 14.9974C4.0354 14.9974 4.33196 14.7762 4.33196 14.5001C4.33196 14.2241 4.03335 13.9997 3.66598 13.9997Z" fill="#EBEBEB"/>
      <path d="M4.33402 13.4994C4.33402 13.2243 4.63263 13 5 13C5.36737 13 5.66598 13.2243 5.66598 13.4994C5.66598 13.7744 5.36737 13.9987 5 13.9987C4.63263 13.9987 4.33402 13.7754 4.33402 13.4994Z" fill="#EBEBEB"/>
      <path d="M3.66598 20.0003C3.29884 20.0003 3.00037 20.2248 3 20.4996C3.00037 20.7764 3.29782 21 3.66598 21C4.03438 21 4.33196 20.7746 4.33196 20.4996C4.33196 20.2246 4.03335 20.0003 3.66598 20.0003Z" fill="#EBEBEB"/>
      <path d="M5 19.0005C4.63263 19.0005 4.33402 19.2249 4.33402 19.4999C4.33402 19.7759 4.63263 19.9992 5 19.9992C5.36737 19.9992 5.66598 19.7749 5.66598 19.4999C5.66598 19.2249 5.36737 19.0005 5 19.0005Z" fill="#EBEBEB"/>
      <path d="M3.66598 17.9997C3.29861 17.9997 3 18.2241 3 18.5001C3 18.7751 3.29861 18.9974 3.66701 18.9974C4.0354 18.9974 4.33196 18.7762 4.33196 18.5001C4.33196 18.2241 4.03335 17.9997 3.66598 17.9997Z" fill="#EBEBEB"/>
      <path d="M4.33402 17.4994C4.33402 17.2243 4.63263 17 5 17C5.36737 17 5.66598 17.2243 5.66598 17.4994C5.66598 17.7744 5.36737 17.9987 5 17.9987C4.63263 17.9987 4.33402 17.7754 4.33402 17.4994Z" fill="#EBEBEB"/>
      <path d="M3.66598 24.0003C3.29884 24.0003 3.00037 24.2248 3 24.4996C3.00037 24.7764 3.29782 25 3.66598 25C4.03438 25 4.33196 24.7746 4.33196 24.4996C4.33196 24.2246 4.03335 24.0003 3.66598 24.0003Z" fill="#EBEBEB"/>
      <path d="M5 23.0005C4.63263 23.0005 4.33402 23.2249 4.33402 23.4999C4.33402 23.7759 4.63263 23.9992 5 23.9992C5.36737 23.9992 5.66598 23.7749 5.66598 23.4999C5.66598 23.2249 5.36737 23.0005 5 23.0005Z" fill="#EBEBEB"/>
      <path d="M3.66598 21.9997C3.29861 21.9997 3 22.2241 3 22.5001C3 22.7751 3.29861 22.9974 3.66701 22.9974C4.0354 22.9974 4.33196 22.7762 4.33196 22.5001C4.33196 22.2241 4.03335 21.9997 3.66598 21.9997Z" fill="#EBEBEB"/>
      <path d="M4.33402 21.4994C4.33402 21.2243 4.63263 21 5 21C5.36737 21 5.66598 21.2243 5.66598 21.4994C5.66598 21.7744 5.36737 21.9987 5 21.9987C4.63263 21.9987 4.33402 21.7754 4.33402 21.4994Z" fill="#EBEBEB"/>
      <path d="M4.33196 28.4996C4.33196 28.6021 4.29106 28.6971 4.22077 28.7761C4.10153 28.9108 3.89777 29 3.66598 29C3.32358 29 3.04234 28.8066 3.00436 28.5569C3.00148 28.5381 3 28.519 3 28.4996C3 28.2236 3.29861 28.0003 3.66598 28.0003C4.03335 28.0003 4.33196 28.2236 4.33196 28.4996Z" fill="#EBEBEB"/>
      <path d="M5 27.0005C4.63263 27.0005 4.33402 27.2249 4.33402 27.4999C4.33402 27.7759 4.63263 27.9992 5 27.9992C5.36737 27.9992 5.66598 27.7749 5.66598 27.4999C5.66598 27.2249 5.36737 27.0005 5 27.0005Z" fill="#EBEBEB"/>
      <path d="M3.66598 25.9997C3.29861 25.9997 3 26.2241 3 26.5001C3 26.7751 3.29861 26.9974 3.66701 26.9974C4.0354 26.9974 4.33196 26.7762 4.33196 26.5001C4.33196 26.2241 4.03335 25.9997 3.66598 25.9997Z" fill="#EBEBEB"/>
      <path d="M4.33402 25.4994C4.33402 25.2243 4.63263 25 5 25C5.36737 25 5.66598 25.2243 5.66598 25.4994C5.66598 25.7744 5.36737 25.9987 5 25.9987C4.63263 25.9987 4.33402 25.7754 4.33402 25.4994Z" fill="#EBEBEB"/>
      <path d="M4.33402 29.4994C4.33402 29.2243 4.63263 29 5 29C5.36737 29 5.66598 29.2243 5.66598 29.4994C5.66598 29.7744 5.36737 29.9987 5 29.9987C4.63263 29.9987 4.33402 29.7754 4.33402 29.4994Z" fill="#EBEBEB"/>
      <path d="M2.33402 6.99922C1.96665 6.99922 1.66803 7.22357 1.66803 7.49961C1.66803 7.77565 1.96665 7.99897 2.33402 7.99897C2.70139 7.99897 3 7.77565 3 7.49961C3 7.22357 2.70139 6.99922 2.33402 6.99922Z" fill="#EBEBEB"/>
      <path d="M1.66803 13.5001C1.66803 13.2241 1.96665 12.9997 2.33402 12.9997C2.70139 12.9997 3 13.2241 3 13.5001C3 13.7762 2.6942 13.9974 2.32991 13.9974C1.96562 13.9974 1.66803 13.7751 1.66803 13.5001Z" fill="#EBEBEB"/>
      <path d="M1.66803 21.5001C1.66803 21.2241 1.96665 20.9997 2.33402 20.9997C2.70139 20.9997 3 21.2241 3 21.5001C3 21.7762 2.6942 21.9974 2.32991 21.9974C1.96562 21.9974 1.66803 21.7751 1.66803 21.5001Z" fill="#EBEBEB"/>
      <path d="M2.33402 26.9992C1.96665 26.9992 1.66803 27.2236 1.66803 27.4996C1.66803 27.7757 1.96665 27.999 2.33402 27.999C2.70139 27.999 3 27.7757 3 27.4996C3 27.2236 2.70139 26.9992 2.33402 26.9992Z" fill="#EBEBEB"/>
      <path d="M1.06067 6.42567C3.77149 5.96832 5.90585 3.79178 6.29935 1.06068V0H7V35H6.29986V33.9393C5.90751 31.2076 3.77256 29.0301 1.06067 28.5732V26.7462C1.99704 26.3361 2.65297 25.4016 2.65297 24.3179C2.65297 23.2328 1.99702 22.2975 1.06067 21.8874V19.93C1.99704 19.5193 2.65297 18.5834 2.65297 17.4981C2.65297 16.4135 1.99702 15.4785 1.06067 15.0685V13.1111C1.99704 12.7005 2.65297 11.7646 2.65297 10.6793C2.65297 9.59464 1.99702 8.6597 1.06067 8.2497V6.42567Z" fill={fillColor}/>
        {
            linesColor && linesColor !== 'none' && <path fillRule="evenodd" clipRule="evenodd" d="M7 2.57645V0H6.6066L6.29935 0.307249V1.06068C5.90585 3.79178 3.77149 5.96832 1.06067 6.42567V8.2497C1.12168 8.27641 1.1815 8.30536 1.24003 8.33642L7 2.57645ZM1.06067 13.6073V13.1111C1.99704 12.7005 2.65297 11.7646 2.65297 10.6793C2.65297 10.2093 2.5298 9.76737 2.31401 9.38412L7 4.69813V7.66798L1.06067 13.6073ZM2.62771 17.1318C2.52221 16.373 2.09383 15.7158 1.48616 15.3035L7 9.78965V12.7595L2.62771 17.1318ZM2.1223 22.7287C1.84849 22.3644 1.48312 22.0724 1.06067 21.8874V20.8205L7 14.8812V17.851L2.1223 22.7287ZM1.32009 28.6225C1.23424 28.6043 1.14775 28.5878 1.06067 28.5732V26.7462C1.99651 26.3364 2.65222 25.4027 2.65297 24.3197L7 19.9727V22.9426L1.32009 28.6225ZM4.58984 30.4442C4.09889 29.933 3.52356 29.5033 2.88648 29.1778L7 25.0642V28.0341L4.58984 30.4442ZM6.2849 33.8407C6.15932 33.0546 5.88916 32.316 5.50261 31.6531L7 30.1558V33.1256L6.2849 33.8407Z" fill={linesColor}/>
        }
        {
            wideLinesColor && wideLinesColor !== 'none' && <path fillRule="evenodd" clipRule="evenodd" d="M1.69067 15.4571C1.50101 15.2999 1.28919 15.1686 1.06067 15.0685V13.9657L7 8.02641V10.1477L1.69067 15.4571ZM2.44907 23.2987C2.25027 22.8234 1.91685 22.4178 1.49674 22.1297L7 16.6264V18.7477L2.44907 23.2987ZM4.24064 30.1071C3.86256 29.7687 3.4439 29.4747 2.99296 29.2334L7 25.2264V27.3477L4.24064 30.1071ZM7 33.8264V35H6.29986V34.5265L7 33.8264ZM6.42641 0H7V1.54773L5.8171 2.73063C6.05033 2.20718 6.21492 1.6467 6.29935 1.06068V0.127054L6.42641 0ZM2.58108 5.96665L1.06067 7.48706V6.42567C1.5926 6.33593 2.10234 6.17999 2.58108 5.96665Z" fill={wideLinesColor}/>
        }
    </svg>
  )
}
