import axios, { AxiosRequestConfig } from "axios";
import React, { FC, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch, useParams } from "react-router-dom";
import { compose } from "redux";

import { socketConnectV2, socketKill as socketKillV2 } from "redux/modules/common/chatV2/socketEventsV2";

import Bill from "components/pages/Documents/Bills/Bill/Bill";
import Order from "components/pages/Order";
import { OslaLoader } from "components/pages/OslaLoader/OslaLoader";
import PackingList from "components/pages/PackingList/PackingList";
import Requisition from "components/pages/Requisition/Requisition";
import SimplifiedTask from "components/pages/SimplifiedTask/SimplifiedTask";
import StockWriteOffApprove from "components/pages/StockWriteOffApprove/StockWriteOffApprove";

import { permissionsLoadingSelector } from "features/permissions/model/selectors";
import { getCurrentUserPermissions } from "features/permissions/model/thunks";

import {
  VIEW_ADD_BILL_FILES as VIEW_CONSTRUCTING_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE as VIEW_CONSTRUCTING_ADD_BILL_PAYMENTS_FILES,
  VIEW_ADD_PACKING_LIST_FILES as VIEW_CONSTRUCTING_ADD_PACKING_LIST_FILES,
  VIEW_BILL_IN_EDIT as VIEW_CONSTRUCTING_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID as VIEW_CONSTRUCTING_BILL_PAYMENTS_MAKE_PAID,
  VIEW_DELETE_BILL_FILES as VIEW_CONSTRUCTING_DELETE_BILL_FILES,
  VIEW_PACKING_LIST_ACCEPT as VIEW_CONSTRUCTING_PACKING_LIST_ACCEPT,
} from "constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_BILL_ATTACH_FILES,
  VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC,
  VIEW_MANUFACTURING_BILL_DELETE_FILES,
  VIEW_MANUFACTURING_BILL_EDITING,
  VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED,
  VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  VIEW_MANUFACTURING_ORDER_FILES_ATTACH,
  VIEW_MANUFACTURING_ORDER_FILES_DELETE,
  VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE,
  VIEW_MANUFACTURING_PACKING_LIST_ACCEPT,
  VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES,
  VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES,
  VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER,
  VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING,
  VIEW_MANUFACTURING_REQUISITION_COMPLETE,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE,
  VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES,
  VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE,
  VIEW_MANUFACTURING_REQUISITION_LOGS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS,
  VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK,
} from "constants/permissions/manufacturingPermissions";
import {
  ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  ORDER_FILES_ATTACH,
  ORDER_FILES_DELETE,
  ORDER_PRODUCT_FILE_ATTACH,
  ORDER_PRODUCT_FILE_DELETE,
  VIEW_ADD_ADDITIONAL_PRODUCTS,
  VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_ADD_REQUISITION_APPROVERS,
  VIEW_ADD_REQUISITION_VIEWERS,
  VIEW_ASSIGN_REQUISITION_EXECUTOR,
  VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DELETE_REQUISITION_APPROVERS,
  VIEW_DELETE_REQUISITION_PRODUCTS_FILES,
  VIEW_DELETE_REQUISITION_VIEWERS,
  VIEW_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_ORDER_INVOICE_DIFFERENCE,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PROCESS_ORDERS,
  VIEW_PROCESS_PAYMENTS,
  VIEW_PROCESS_REQUISITION,
  VIEW_PRODUCTS_REQUISITION_COMPLETE,
  VIEW_ADD_BILL_FILES as VIEW_PURCHASES_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE as VIEW_PURCHASES_ADD_BILL_PAYMENTS_FILE,
  VIEW_BILL_IN_EDIT as VIEW_PURCHASES_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID as VIEW_PURCHASES_BILL_PAYMENTS_MAKE_PAID,
  VIEW_DELETE_BILL_FILES as VIEW_PURCHASES_DELETE_BILL_FILES,
  VIEW_REQUISITION_IN_PROCESSING_MODE,
  VIEW_REQUISITION_LOGS,
  VIEW_REQUISITION_REASSIGN,
  VIEW_REQUISITION_TAKE_TO_WORK,
  VIEW_REQUISITION_UN_APPROVE,
} from "constants/permissions/purchasesPermissions";

import { useOneOfPermissions } from "features/permissions/hooks/useOneOfPermissions";
import { usePermissionsObject } from "features/permissions/hooks/usePermissionsObject";
import usePermission from "hooks/usePermission";

import LogoImage from "images/authorization/auth_logo.svg";

import styles from "./SimplifiedRoutes.module.scss";

type MatchParams = { userId: string };

const SimplifiedRoutes: FC<RouteComponentProps<MatchParams>> = ({ location }) => {
  const dispatch = useDispatch();
  const currentUserPermissionsAreLoading = useSelector(permissionsLoadingSelector);
  const { userId } = useParams<MatchParams>();

  const token = useMemo(() => {
    const locationUrl = new URL(window?.location?.href);
    const tokenCandidate = locationUrl?.searchParams?.get("token");
    if (tokenCandidate) localStorage.setItem("authToken", tokenCandidate);
    return tokenCandidate;
  }, []);

  useEffect(() => {
    if (!token) return;

    dispatch(socketConnectV2(token!));

    return () => {
      socketKillV2();
    };
  }, [token]);

  const haveViewOrderInvoiceDifferencePermission = useOneOfPermissions([
    VIEW_ORDER_INVOICE_DIFFERENCE,
    VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE,
  ]);
  const haveViewAddOrderRequestsFilesPermission = useOneOfPermissions([
    ORDER_PRODUCT_FILE_ATTACH,
    VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH,
  ]);
  const haveViewDeleteOrderRequestsFilesPermission = useOneOfPermissions([
    ORDER_PRODUCT_FILE_DELETE,
    VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE,
  ]);

  const autoTransitionToPaymentPerm = useOneOfPermissions([
    ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
    VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  ]);

  const addFilesOrderPerm = useOneOfPermissions([ORDER_FILES_ATTACH, VIEW_MANUFACTURING_ORDER_FILES_ATTACH]);
  const deleteFilesOrderPerm = useOneOfPermissions([ORDER_FILES_DELETE, VIEW_MANUFACTURING_ORDER_FILES_DELETE]);

  const orderPermissions = useMemo(
    () => ({
      viewOrderInvoiceDifference: haveViewOrderInvoiceDifferencePermission,
      autoTransitionToPayment: autoTransitionToPaymentPerm,
      addFiles: addFilesOrderPerm,
      deleteFiles: deleteFilesOrderPerm,
      viewAddOrderRequestsFiles: haveViewAddOrderRequestsFilesPermission,
      viewDeleteOrderRequestsFiles: haveViewDeleteOrderRequestsFilesPermission,
    }),
    [
      currentUserPermissionsAreLoading,
      haveViewOrderInvoiceDifferencePermission,
      haveViewAddOrderRequestsFilesPermission,
      haveViewDeleteOrderRequestsFilesPermission,
      autoTransitionToPaymentPerm,
      addFilesOrderPerm,
      deleteFilesOrderPerm,
    ]
  );

  const requisitionPermissions = usePermissionsObject({
    viewProcessRequisition: [VIEW_PROCESS_REQUISITION, VIEW_MANUFACTURING_REQUISITION_PROCESS],
    viewProcessOrders: [VIEW_PROCESS_ORDERS, VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS],
    viewProcessPayments: [VIEW_PROCESS_PAYMENTS, VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS],
    viewAddOutOfEstimateProducts: [
      VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS,
      VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE,
    ],
    viewAddAdditionalProducts: [
      VIEW_ADD_ADDITIONAL_PRODUCTS,
      VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL,
    ],
    viewEditRequisitionBeforeApprove: [
      VIEW_EDIT_REQUISITION_BEFORE_APPROVE,
      VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE,
    ],
    viewAssignRequisitionExecutor: [VIEW_ASSIGN_REQUISITION_EXECUTOR, VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR],
    viewRequisitionTakeToWork: [VIEW_REQUISITION_TAKE_TO_WORK, VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK],
    viewRequisitionReassign: [VIEW_REQUISITION_REASSIGN, VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR],
    viewAddRequisitionApprovers: [VIEW_ADD_REQUISITION_APPROVERS, VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS],
    viewAddRequisitionViewers: [VIEW_ADD_REQUISITION_VIEWERS, VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS],
    viewDeleteRequisitionApprovers: [
      VIEW_DELETE_REQUISITION_APPROVERS,
      VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS,
    ],
    viewDeleteRequisitionViewers: [VIEW_DELETE_REQUISITION_VIEWERS, VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS],
    viewRequisitionUnApprove: [VIEW_REQUISITION_UN_APPROVE, VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING],
    viewRequisitionLogs: [VIEW_REQUISITION_LOGS, VIEW_MANUFACTURING_REQUISITION_LOGS],
    viewRequisitionInProcessingMode: [
      VIEW_REQUISITION_IN_PROCESSING_MODE,
      VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE,
    ],
    viewDeleteRequisitionProductsFiles: [
      VIEW_DELETE_REQUISITION_PRODUCTS_FILES,
      VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES,
    ],
    iewAddRequisitionProductsFiles: true,
    viewRequisitionProductsComments: true,
    viewAddRequisitionProductsComments: true,
    viewCreateRequisitionProductsProvider: [
      VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER,
      VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER,
    ],
    viewOrderInvoiceDifference: haveViewOrderInvoiceDifferencePermission,
    viewAddOrderRequestsFiles: haveViewAddOrderRequestsFilesPermission,
    viewDeleteOrderRequestsFiles: haveViewDeleteOrderRequestsFilesPermission,
    viewCompleteRequisition: [VIEW_MANUFACTURING_REQUISITION_COMPLETE, VIEW_PRODUCTS_REQUISITION_COMPLETE],
  });

  const billPermissions = usePermissionsObject({
    viewBillInEdit: [VIEW_MANUFACTURING_BILL_EDITING, VIEW_PURCHASES_BILL_IN_EDIT, VIEW_CONSTRUCTING_BILL_IN_EDIT],
    addFiles: [VIEW_MANUFACTURING_BILL_ATTACH_FILES, VIEW_CONSTRUCTING_ADD_BILL_FILES, VIEW_PURCHASES_ADD_BILL_FILES],
    deleteFiles: [
      VIEW_MANUFACTURING_BILL_DELETE_FILES,
      VIEW_PURCHASES_DELETE_BILL_FILES,
      VIEW_CONSTRUCTING_DELETE_BILL_FILES,
    ],
    viewAddBillPaymentsFile: [
      VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC,
      VIEW_PURCHASES_ADD_BILL_PAYMENTS_FILE,
      VIEW_CONSTRUCTING_ADD_BILL_PAYMENTS_FILES,
    ],
    viewBillPaymentsMakePaid: [
      VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED,
      VIEW_PURCHASES_BILL_PAYMENTS_MAKE_PAID,
      VIEW_CONSTRUCTING_BILL_PAYMENTS_MAKE_PAID,
    ],
  });

  const packingListPermissions = usePermissionsObject({
    viewPackingListAccept: [
      VIEW_PACKING_LIST_ACCEPT,
      VIEW_MANUFACTURING_PACKING_LIST_ACCEPT,
      VIEW_CONSTRUCTING_PACKING_LIST_ACCEPT,
    ],
    addFiles: [
      VIEW_ADD_PACKING_LIST_FILES,
      VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES,
      VIEW_CONSTRUCTING_ADD_PACKING_LIST_FILES,
    ],
    deleteFiles: [
      VIEW_DELETE_PACKING_LIST_FILES,
      VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES,
      VIEW_CONSTRUCTING_DELETE_BILL_FILES,
    ],
  });

  useEffect(() => {
    if (!token || !userId) return;
    dispatch(getCurrentUserPermissions(+userId));
  }, [userId, token]);

  if (currentUserPermissionsAreLoading) return <OslaLoader />;

  return (
    <div className={styles.simplified}>
      <img src={LogoImage} alt="OSLA" className={styles.logo} />
      <Switch>
        <Route
          path={`/simplified/order/:userId/:orderId`}
          render={(props) => (
            <>
              <Helmet>
                <meta name="viewport" content="width=1290px,initial-scale=0,maximum-scale=1.0" />
              </Helmet>
              <Order {...props} permissions={orderPermissions} isSimplified />
            </>
          )}
        />
        <Route
          path={`/simplified/request/:userId/:requisitionId`}
          render={(props) => (
            <>
              <Helmet>
                <meta name="viewport" content="width=1370px,initial-scale=0,maximum-scale=1.0" />
              </Helmet>
              <Requisition {...props} permissions={requisitionPermissions} isSimplified />
            </>
          )}
        />
        <Route
          path={`/simplified/bill/:userId/:idBill`}
          render={(props) => (
            <>
              <Helmet>
                <meta name="viewport" content="width=1350px,initial-scale=0,maximum-scale=1.0" />
              </Helmet>
              <Bill {...props} permissions={billPermissions} isSimplified />
            </>
          )}
        />
        <Route
          path={`/simplified/packing-list/:userId/:packingListId`}
          render={(props) => (
            <>
              <Helmet>
                <meta name="viewport" content="width=1350px,initial-scale=0,maximum-scale=1.0" />
              </Helmet>
              <PackingList {...props} permissions={packingListPermissions} isSimplified />
            </>
          )}
        />
        <Route
          path={`/simplified/stocks/:userId/:stockId/using/:usingId`}
          render={(props) => (
            <>
              <Helmet>
                <meta name="viewport" content="width=990px,initial-scale=0,maximum-scale=1.0" />
              </Helmet>
              <StockWriteOffApprove {...props} />
            </>
          )}
        />
        <Route
          path={`/simplified/task/:userId/:taskId`}
          render={({ match }) => (
            <>
              <Helmet>
                <meta name="viewport" content="width=700px,initial-scale=0,maximum-scale=1.0" />
              </Helmet>
              <SimplifiedTask taskId={match.params.taskId} />
            </>
          )}
        />
      </Switch>
    </div>
  );
};

export default React.memo(SimplifiedRoutes);
