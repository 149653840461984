import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { getEntitiesList } from "redux/modules/common/settings/organization";
import { getTaxes } from "redux/modules/common/settings/organization";
import { getProfile, infoStateSelector, patchProfile } from "redux/modules/common/settings/ourCompanyTabs/profile";

import Icon from "components/UI/Icon/Icon";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import InputBase from "components/UI/atoms/InputBase";

import SettingsHeadline from "../Settings/SettingsHeadline/SettingsHeadline";
import SettingOrgRow from "../SettingsTable/SettingOrgRow";
import SettingsTable from "../SettingsTable/SettingsTable";
import BankDetails from "./BankDetails";

import { LOCALIZATION_CONFIG, REGIONS_LABELS } from "../../../../../constants/localization";

import useFormFocus from "../../../../../utils/hooks/useFormFocus";
import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";

import { maxLengthValidator } from "../../../../../utils/formHelpers/maxLengthValidator";
import { minLength } from "../../../../../utils/formHelpers/validations";
import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";

import plusSmalCercle from "images/icons/plusSmalCercle.svg";

import styles from "./Profile.module.scss";

const Profiles = () => {
  const { objectId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const urlModule = useUrlModule();
  const [isLoading, setIsLoading] = useState(!!profile?.id);

  const profile = useSelector(infoStateSelector);
  const organizations = useSelector((state) => state.organization.tableData);

  const [bankDetails, setBankDetails] = useState([]);

  const fullUrlModule = () => {
    if (urlModule !== "objects") return urlModule;
    return `objects/${objectId}`;
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getEntitiesList());
  }, []);

  const addbankDetails = useCallback(() => {
    setBankDetails([...bankDetails, {}]);
  }, [bankDetails]);

  const deleteEmptyHandler = useCallback(() => {
    const tempArr = [...bankDetails];
    tempArr.pop();
    setBankDetails(tempArr);
  }, [bankDetails]);

  const initialValue = (profile) => {
    return { ...profile, ...profile.general_entity };
  };

  useEffect(() => {
    dispatch(getTaxes());
    dispatch(getProfile(() => setIsLoading(false)));
  }, []);

  useEffect(() => {
    setBankDetails(profile?.general_entity?.requisite_set);
  }, [profile]);

  const [headlineData, setHeadlineData] = React.useState({});
  const [isDirty, setIsDirty] = React.useState(false);
  const headlineSubmit = (form) => {
    setHeadlineData(form);
  };
  useEffect(() => {
    setHeadlineData({
      brand_name: profile.brand_name,
      full_name: profile.full_name,
      email: profile.email,
      phone_number: profile.phone_number,
      account_type: profile.account_type,
      tariff: profile.tariff,
      address: profile.address,
    });
  }, [profile]);

  const submitRequisit = (value) => {
    const newVal = value?.general_entity?.requisite_set?.map((item) => {
      return {
        ...item,
        itn: item?.itn ? item.itn : value?.general_entity?.itn,
        iec: item?.iec ? item.iec : value?.general_entity?.iec,
        address: item?.address ? item.address : "Не указано",
        entity_name: item?.entity_name ? item.entity_name : value?.general_entity?.name,
      };
    });
    value.general_entity.requisite_set = newVal;
    dispatch(patchProfile({ ...value, ...headlineData }));
  };

  const inputRootRef = useFormFocus();

  const showSpinner = isLoading && !profile?.id;

  return (
    <div className={styles.container}>
      <ButtonBase
        buttonPrimaryEmpty
        className={styles.addButtonClass}
        onClick={() => history.push(`/${fullUrlModule()}/settings/organization/new`)}
      >
        Добавить организацию
      </ButtonBase>
      {showSpinner && <Spinner isFixed />}
      {!showSpinner && (
        <>
          <SettingsHeadline
            data={{
              brand_name: profile.brand_name,
              full_name: profile.full_name,
              email: profile.email,
              phone_number: profile.phone_number,
              account_type: profile.account_type,
              tariff: profile.tariff,
              address: profile.address,
            }}
            onFormChange={headlineSubmit}
            onSetDirty={setIsDirty}
          />
          <Form
            onSubmit={(values) => submitRequisit(values)}
            initialValues={initialValue(profile)}
            render={({ handleSubmit, dirty }) => (
              <>
                <div
                  ref={inputRootRef}
                  className={cn(styles.form, {
                    [styles.kz]: LOCALIZATION_CONFIG.langLabel === REGIONS_LABELS.kz,
                  })}
                >
                  <Field
                    name="general_entity.name"
                    label="Наименование юридического лица"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                  />
                  <Field
                    name="general_entity.itn"
                    label={LOCALIZATION_CONFIG.taxesNumberLabel}
                    component={InputBase}
                    validate={composeFieldValidators(
                      required(),
                      minLength(10),
                      maxLength(LOCALIZATION_CONFIG.taxesNumberLength)
                    )}
                    classNameInput={styles.colorInput}
                    format={maxLengthValidator(LOCALIZATION_CONFIG.taxesNumberLength)}
                    formatOnBlur
                  />
                  {LOCALIZATION_CONFIG.langLabel === REGIONS_LABELS.ru && (
                    <>
                      <Field
                        name="general_entity.iec"
                        label="КПП"
                        component={InputBase}
                        validate={composeFieldValidators(required(), minLength(9), maxLength(9))}
                        classNameInput={styles.colorInput}
                        format={maxLengthValidator(9)}
                        formatOnBlur
                      />
                      <Field
                        name="general_entity.psrn"
                        label="ОГРН/ОГРНИП"
                        component={InputBase}
                        validate={composeFieldValidators(required(), minLength(13), maxLength(13))}
                        classNameInput={styles.colorInput}
                        format={maxLengthValidator(13)}
                        formatOnBlur
                      />
                    </>
                  )}

                  <Field
                    name="general_entity.address"
                    label="Юридический адрес"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                  />
                </div>
                <div className={styles.add}>
                  Банковские реквизиты
                  <button className={styles.btnAdd} onClick={addbankDetails}>
                    <Icon icon={plusSmalCercle} />
                  </button>
                </div>
                {bankDetails?.map((item, index) => (
                  <BankDetails
                    key={index}
                    item={item}
                    index={index}
                    deleteEmptyHandler={deleteEmptyHandler}
                    isLast={bankDetails?.length <= 1}
                    addNew={addbankDetails}
                  />
                ))}
                {(dirty || isDirty) && (
                  <ButtonBase primary medium onClick={handleSubmit} className={styles.submit}>
                    Сохранить
                  </ButtonBase>
                )}
              </>
            )}
          />
          <SettingsTable>
            {Array.isArray(organizations?.list) &&
              organizations?.list.map((el) => (
                <React.Fragment key={el.id}>
                  <SettingOrgRow
                    key={el.id}
                    objectId={objectId}
                    id={el.id}
                    name={el.name}
                    inn={el.itn}
                    kpp={el.iec}
                    isLocked={el.is_blocked}
                  />
                </React.Fragment>
              ))}
          </SettingsTable>
        </>
      )}
    </div>
  );
};

export default Profiles;
