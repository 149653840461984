import { message } from "antd";
import { memoize } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { compose } from "redux";

import { setRequisitionIsAgreedAction } from "../../../redux/modules/common/building/requisition/actions";
import {
  addRequisitionEmployees,
  approveRequisition,
  createOrder,
  createRequisition,
  deleteRequisitionEmployee,
  employeesErrorSelector,
  employeesLoadingSelector,
  employeesSelector,
  getInfo,
  getProducts,
  getRequisitionEmployees,
  infoDataSelector,
  infoErrorSelector,
  infoLoadingSelector,
  isInfoNotFoundSelector,
  patchRequisition,
  productsDataSelector,
  productsLoadingSelector,
  resetToInitialAction,
  unApproveRequisition,
} from "../../../redux/modules/common/building/requisition/reducer";
import { completeRequisition } from "../../../redux/modules/common/building/requisition/thunks/completeRequisition";
import { commentsLoadingSelector, commentsSelector } from "../../../redux/modules/common/comments/reducer";
import { getRequisitionComments } from "../../../redux/modules/common/comments/thunks/getRequisitionComments";
import { userSelector } from "redux/modules/common/auth";
import { apiGetExport } from "redux/modules/common/building/nowRequsitionApi/nowRequsitionApi";
import { resetDetail } from "redux/modules/common/building/object/nowObject";

import BackNavigationBar from "../../UI/atoms/BackNavigationBar/BackNavigationBar";
import Paper from "../../UI/templates/Paper/Paper";
import Orders from "./components/Orders";
import Products from "./components/Products";
import ProgressTabs from "./components/ProgressTabs";
import RequisitionApproversAndViewers from "./components/RequisitionApproversAndViewers";
import RequisitionInfo from "./components/RequisitionInfo";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import ProgressTabsHelper, { ORDER_TAB, PAYMENT_TAB, REQUISITION_TAB } from "./domain/ProgressTabs";
import RequisitionValidator from "./domain/RequisitionValidator";
import ApprovalAndView from "domain/ApprovalAndView";

import { COMPLETED } from "../../../constants/constant";
import { REQUISITION_STATUS } from "constants/constant";
import { NOT_FOUND_PLACEHOLDER } from "constants/placeholders";

import useApprovalAndView from "hooks/useApprovalAndView";

import { getSerializedProductsComments } from "./utils/getSerializedProductsComments";
import { stringifyArgs } from "utils/helpers/stringifyArgs";

import notFoundIcon from "images/icons/not-found-icon-black.svg";

import styles from "./Requisition.module.scss";

const Requisition = ({ permissions, isSimplified }) => {
  const { requisitionId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const requisition = useSelector(infoDataSelector);
  const requisitionIsLoading = useSelector(infoLoadingSelector);
  const requisitionIsError = useSelector(infoErrorSelector);
  const isRequisitionNotFound = useSelector(isInfoNotFoundSelector);

  const products = useSelector(productsDataSelector);
  const productsAreLoading = useSelector(productsLoadingSelector);

  const productsComments = useSelector(commentsSelector);
  const productsCommentsAreLoading = useSelector(commentsLoadingSelector);

  const requisitionEmployees = useSelector(employeesSelector);
  const requisitionEmployeesAreLoading = useSelector(employeesLoadingSelector);
  const requisitionEmployeesAreError = useSelector(employeesErrorSelector);

  const { approvalAndView } = useApprovalAndView(requisitionEmployees);

  const isDraft = requisition?.status === REQUISITION_STATUS.DRAFT;

  const getTabs = () => {
    if (requisitionIsLoading || !requisition) return [];

    return ProgressTabsHelper.getTabs(
      {
        processOrdersStep: permissions.viewProcessOrders,
        processRequisitionStep: permissions.viewProcessRequisition,
        processPaymentsStep: permissions.viewProcessPayments,
      },
      requisition.have_unpaid_orders,
      requisition.have_payment_orders
    );
  };

  const [tabs, setTabs] = useState(getTabs());
  const activeTab = useMemo(() => ProgressTabsHelper.getActiveTab(tabs), [tabs]);

  const setActiveTab = useCallback(
    (setTabId) => setTabs((prevState) => ProgressTabsHelper.setActiveTab(prevState, setTabId)),
    []
  );

  const changeExecutionDate = useCallback(
    (updatedDate) => {
      if (!requisition) return;
      compose(dispatch, patchRequisition)(requisitionId, { date: moment(updatedDate).format("YYYY-MM-DD") });
    },
    [requisitionId, requisition]
  );

  const saveExcel = useCallback(async () => {
    if (!requisition) return;

    const response = await apiGetExport(requisitionId);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");

    link.setAttribute("download", `Заявка ${requisition?.number ? `№${requisition?.number}` : ""}.xlsx`);

    link.href = url;
    document.body.appendChild(link);
    link.click();
  }, [requisitionId, requisition?.number]);

  const handleCreateRequisition = useCallback(
    () => compose(dispatch, createRequisition)(requisitionId),
    [requisitionId]
  );

  const onValidatorError = (errors) => errors.forEach((error) => message.error(error));

  const handleCreateOrder = useCallback(
    (addedProductsIds) => {
      const requisitionValidator = new RequisitionValidator(onValidatorError);
      const addedProducts = products.filter((product) => addedProductsIds.indexOf(product.id) !== -1);

      if (!requisitionValidator.validate(requisition, addedProducts, approvalAndView)) return;

      compose(dispatch, createOrder)(requisitionId, { items_ids: addedProductsIds, is_internal: true });
    },
    [requisitionId, products, requisition, approvalAndView]
  );

  const handleAddEmployees = useCallback(
    (addedEmployees) => {
      const employeesToRequest = ApprovalAndView.getApprovalElementsFromEmployees(addedEmployees);

      compose(dispatch, addRequisitionEmployees)(requisitionId, employeesToRequest);
    },
    [requisitionId]
  );

  const getHandleDeleteEmployeeCallback = useCallback(
    (deletedEmployeeId) => () => compose(dispatch, deleteRequisitionEmployee)(requisitionId, deletedEmployeeId),
    [requisitionId]
  );

  const memoizedGetHandleDeleteEmployeeCallback = useCallback(memoize(getHandleDeleteEmployeeCallback, stringifyArgs), [
    getHandleDeleteEmployeeCallback,
  ]);

  const handleAddApprovers = useCallback(
    (addedEmployees) => compose(handleAddEmployees, ApprovalAndView.makeEmployeesApprovers)(addedEmployees),
    [handleAddEmployees]
  );

  const handleAddViewers = useCallback(
    (addedEmployees) => {
      const reqsIds = requisitionEmployees.map((el) => el.employee?.id);
      const filteredEmployees = addedEmployees.filter((el) => !reqsIds.includes(el.id));

      if (filteredEmployees.length === addedEmployees.length) {
        compose(handleAddEmployees, ApprovalAndView.makeEmployeesViewers)(filteredEmployees);
      } else {
        message.error("Согласующий уже добавлен");
      }
    },
    [handleAddEmployees, requisitionEmployees]
  );

  const canUserApprove = useMemo(
    () => approvalAndView.checkCanUserApprove(approvalAndView.approvers, user.id),
    [user.id, approvalAndView]
  );

  const canUserView = useMemo(
    () => approvalAndView.checkCanUserApprove(approvalAndView.viewers, user.id),
    [user.id, approvalAndView]
  );

  const canUserUnApprove = useMemo(
    () => approvalAndView.checkCanUserUnApprove(approvalAndView.approvers, user.id),
    [user.id, approvalAndView]
  );

  const canUserUnView = useMemo(
    () => approvalAndView.checkCanUserUnApprove(approvalAndView.viewers, user.id),
    [user.id, approvalAndView]
  );

  const handleApprove = useCallback(
    () => compose(dispatch, approveRequisition)(requisitionId, user.id),
    [requisitionId, user.id]
  );

  const handleUnApprove = useCallback(
    () => compose(dispatch, unApproveRequisition)(requisitionId, user.id),
    [requisitionId, user.id]
  );

  const handleChangeRequisitionName = useCallback(
    (name) => compose(dispatch, patchRequisition)(requisitionId, { name }),
    [requisitionId]
  );

  const serializedProductsComments = useMemo(() => {
    const result = {};
    Object.entries(productsComments).forEach(
      ([productId, comments]) => (result[productId] = getSerializedProductsComments(comments))
    );
    return result;
  }, [productsComments]);

  const canCompleteRequisition = !isDraft && requisition?.status !== COMPLETED && permissions.viewCompleteRequisition;

  const handleCompleteRequisition = useCallback(() => {
    if (!canCompleteRequisition) return;
    compose(dispatch, completeRequisition)();
  }, [canCompleteRequisition]);

  useEffect(() => {
    compose(dispatch, getInfo)(requisitionId);
  }, [requisitionId]);

  useEffect(() => {
    if (!permissions.viewProcessRequisition) return;
    compose(dispatch, getRequisitionEmployees)(requisitionId);
    compose(dispatch, getProducts)(requisitionId);
    compose(dispatch, getRequisitionComments)(requisitionId);
  }, [requisitionId]);

  useEffect(() => {
    if (requisitionIsLoading || productsAreLoading || !requisition) return;
    setTabs(ProgressTabsHelper.calculateTabsSteps(getTabs(), products, requisition));
  }, [requisition, products, requisitionIsLoading, productsAreLoading]);

  useEffect(() => {
    if (requisition && approvalAndView.checkAllApproversApprove()) {
      compose(dispatch, setRequisitionIsAgreedAction)(true);
    }
  }, [approvalAndView]);

  useEffect(
    () => () => {
      compose(dispatch, resetToInitialAction)();
      compose(dispatch, resetDetail)();
    },
    []
  );

  const isShownComprasion = activeTab && [ORDER_TAB, PAYMENT_TAB].indexOf(activeTab.id) !== -1;
  const isShownOrders = activeTab && [ORDER_TAB, PAYMENT_TAB].indexOf(activeTab.id) !== -1;

  if (
    (requisitionIsLoading || !requisition || productsAreLoading || productsCommentsAreLoading) &&
    !isRequisitionNotFound
  )
    return <Spinner />;

  if (isRequisitionNotFound) return <EmptyPlaceholder img={notFoundIcon} text={NOT_FOUND_PLACEHOLDER} />;

  if (requisitionIsError || requisitionEmployeesAreError || !products) return null;

  return (
    <div className={styles.requisition}>
      {!isSimplified && <BackNavigationBar title="Заявки" backLink={`/objects/${requisition?.building?.id}/requisitions`} rightSideText={requisition.building.name} />}
      <Paper>
        <RequisitionInfo
          requisition={requisition}
          changeExecutionDate={changeExecutionDate}
          changeRequisitionName={handleChangeRequisitionName}
          saveExcel={saveExcel}
          permissions={permissions}
        />
        {activeTab && (
          <>
            {!isDraft && <ProgressTabs tabs={tabs} activeTabId={activeTab.id} setActiveTab={setActiveTab} />}
            {activeTab.id === REQUISITION_TAB && (
              <>
                <Products
                  requisition={requisition}
                  products={products}
                  productsComments={serializedProductsComments}
                  isDraft={isDraft}
                  createOrder={handleCreateOrder}
                  permissions={permissions}
                />
                <footer className={styles.footer}>
                  {!isDraft && (
                    <RequisitionApproversAndViewers
                      buildingId={requisition.building.id}
                      approvers={approvalAndView.approvers}
                      handleDeleteApprover={memoizedGetHandleDeleteEmployeeCallback}
                      handleAddApprovers={handleAddApprovers}
                      viewers={approvalAndView.viewers}
                      handleDeleteViewer={memoizedGetHandleDeleteEmployeeCallback}
                      handleAddViewers={handleAddViewers}
                      canApprove={canUserApprove}
                      canView={canUserView}
                      canUnApprove={canUserUnApprove}
                      canUnView={canUserUnView}
                      requisitionIsAgreed={requisition.is_agreed}
                      approve={handleApprove}
                      unApprove={handleUnApprove}
                      approversAreLoading={requisitionEmployeesAreLoading}
                      userId={user.id}
                      permissions={permissions}
                      requisitionCreationDate={requisition.created_at}
                    />
                  )}
                  {isDraft && products.length !== 0 && (
                    <ButtonBase className={styles.alignRightButton} primary medium onClick={handleCreateRequisition}>
                      Создать заявку
                    </ButtonBase>
                  )}
                  {canCompleteRequisition && (
                    <ButtonBase primary onClick={handleCompleteRequisition} className={styles.completeButton}>
                      Завершить заявку
                    </ButtonBase>
                  )}
                </footer>
              </>
            )}
            {isShownOrders && (
              <Orders
                requisitionId={requisitionId}
                isPayment={activeTab.id === PAYMENT_TAB}
                permissions={permissions}
                isSimplified={isSimplified}
                isShownComparison={isShownComprasion}
              />
            )}
          </>
        )}
      </Paper>
    </div>
  );
};

export default Requisition;
