import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.journalExecution;

export const sectionsInJournalExecSelector = createSelector(stateSelector, (state) => state.sections);
export const sectionsWithRemarksSelector = createSelector(stateSelector, (state) => state.sectionsWithRemarks);

export const ticketsInJournalExecSelector = createSelector(stateSelector, (state) => state.ticketsBySections);
export const remarksBySectionsSelector = createSelector(stateSelector, (state) => state.remarksBySections);
export const invalidateKeyInJournalExecSelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const reportInvalidateKeyInJournalExecSelector = createSelector(
  stateSelector,
  (state) => state.reportInvalidateKey
);

export const createRemarkIsSuccessSelector = createSelector(stateSelector, (state) => state.createRemark.isSuccess);
export const createRemarkIsLoadingSelector = createSelector(stateSelector, (state) => state.createRemark.isLoading);

export const editRemarkIsSuccessSelector = createSelector(stateSelector, (state) => state.editRemark.isSuccess);
export const editRemarkIsLoadingSelector = createSelector(stateSelector, (state) => state.editRemark.isLoading);

export const remarksListResultsSelector = createSelector(stateSelector, (state) => state.remarksList.results);
export const remarksListIsLoadingSelector = createSelector(stateSelector, (state) => state.remarksList.isLoading);

export const detailRemarkInfoResultsSelector = createSelector(stateSelector, (state) => state.detailRemarkInfo.result);
export const detailRemarkInfoIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.detailRemarkInfo.isLoading
);

export const deleteRemarkIsSuccessSelector = createSelector(stateSelector, (state) => state.deleteRemark.isSuccess);
export const deleteRemarkIsLoadingSelector = createSelector(stateSelector, (state) => state.deleteRemark.isLoading);

export const ticketActingInfoResultsSelector = createSelector(stateSelector, (state) => state.ticketActingInfo.results);
export const groupTicketActingInfoResultsSelector = createSelector(
  stateSelector,
  (state) => state.groupTicketActingInfo.results
);

export const ticketActingInfoIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.ticketActingInfo.isLoading
);

export const groupTicketActingInfoIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.groupTicketActingInfo.isLoading
);
export const ticketActingIsApprovingSelector = createSelector(
  stateSelector,
  (state) => state.ticketActingInfo.isApproving
);

export const resolveOrReturnRemarkIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.resolveOrReturnRemark.isLoading
);
export const resolveOrReturnRemarkIsSuccessSelector = createSelector(
  stateSelector,
  (state) => state.resolveOrReturnRemark.isSuccess
);

export const acceptRemarkIsLoadingSelector = createSelector(stateSelector, (state) => state.acceptRemark.isLoading);

export const journalFulfillmentSectionsSelector = createSelector(stateSelector, (state) => state.sectionsV2);
export const journalFulfillmentExpendituresSelector = createSelector(stateSelector, (state) => state.expendituresV2);
export const journalFulfillmentGroupsSelector = createSelector(stateSelector, (state) => state.groups);
export const journalFulfillmentContentOfExpendituresSelector = createSelector(
  stateSelector,
  (state) => state.contentOfExpenditures
);
export const journalFulfillmentMaterialsInvalidateKeySelector = createSelector(
  stateSelector,
  (state) => state.materialsInvalidateKey
);
export const journalFulfillmentSectionsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.sectionsV2Loading
);
export const journalFulfillmentExpendituresLoadingSelector = createSelector(
  stateSelector,
  (state) => state.expendituresV2Loading
);
