import { IActingAction } from "./actions";

import { IActingState, IExpenditureInActing, ISectionInActing } from "./types";

export const setActingSections = (state: IActingState, action: IActingAction): IActingState => {
  const { type, payload } = action;
  if (type === "acting/SET_SECTIONS") {
    const oldSectionsListLength = state.sections[payload.key]?.results?.length;
    if (oldSectionsListLength) {
      const oldSectionsObj = state.sections[payload.key]?.results?.reduce<Record<number, ISectionInActing>>(
        (prev, cur) => {
          return {
            ...prev,
            [cur.id]: cur,
          };
        },
        {}
      );
      const newSections = [...payload.sections].map((el) => {
        if (oldSectionsObj[el.id]) {
          return {
            ...el,
            isSelected: oldSectionsObj[el.id].isSelected,
          };
        } else {
          return el;
        }
      });
      return {
        ...state,
        sections: {
          ...state.sections,
          [payload.key]: {
            results: newSections,
            isLoading: false,
          },
        },
      };
    } else {
      return {
        ...state,
        sections: {
          ...state.sections,
          [payload.key]: {
            results: payload.sections,
            isLoading: false,
          },
        },
      };
    }
  }
  return state;
};

export const setActingExpenditures = (state: IActingState, action: IActingAction): IActingState => {
  const { type, payload } = action;
  if (type === "acting/SET_EXPENDITURES") {
    const oldExpListLength = state.expenditures[payload.key]?.results?.length;
    const parentStatus = state.sections[payload.estimateId]?.results?.find((el) => el.id === +payload.key)?.isSelected;
    if (oldExpListLength) {
      const oldExpsObj = state.expenditures[payload.key]?.results?.reduce<Record<number, IExpenditureInActing>>(
        (prev, cur) => {
          return {
            ...prev,
            [cur.id]: cur,
          };
        },
        {}
      );

      const newExps = [...payload.expenditures].map((el) => {
        if (oldExpsObj[el.id]) {
          return {
            ...el,
            isSelected: parentStatus || oldExpsObj[el.id].isSelected,
            parentId: +payload.key,
          };
        } else {
          return { ...el, parentId: +payload.key };
        }
      });

      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          [payload.key]: {
            results: newExps,
            isLoading: false,
          },
        },
      };
    } else {
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          [payload.key]: {
            results: payload.expenditures.map((el) => ({
              ...el,
              isSelected: parentStatus || el.isSelected,
              parentId: +payload.key,
            })),
            isLoading: false,
          },
        },
      };
    }
  }
  return state;
};

export const selectActingSection = (state: IActingState, action: IActingAction): IActingState => {
  const { type, payload } = action;
  if (type === "acting/SELECT_SECTION") {
    const sectionsByEstimate = state.sections[payload.estimateId] || {};
    const sectionResults = sectionsByEstimate.results?.map((el) => {
      if (el.id === payload.sectionId) {
        return {
          ...el,
          isSelected: payload.status,
        };
      } else {
        return el;
      }
    });
    const expendituresBySection = state.expenditures[payload.sectionId] || {};
    const expendituresResults = expendituresBySection.results?.map((el) => ({
      ...el,
      isSelected: payload.status,
    }));
    return {
      ...state,
      sections: {
        ...state.sections,
        [payload.estimateId]: {
          ...sectionsByEstimate,
          results: sectionResults,
        },
      },
      expenditures: {
        ...state.expenditures,
        [payload.sectionId]: {
          ...expendituresBySection,
          results: expendituresResults,
        },
      },
    };
  }
  return state;
};

export const selectActingExpenditure = (state: IActingState, action: IActingAction): IActingState => {
  const { type, payload } = action;
  if (type === "acting/SELECT_EXPENDITURE") {
    let sectionId = payload.parentId as number;
    if (!sectionId) {
      for (let key in state.expenditures) {
        if (state.expenditures[key].results?.some((el) => el.id === payload.expId)) {
          sectionId = +key;
        }
      }
    }
    const siblingExpenditures = state.expenditures[sectionId]?.results?.map((el) => {
      if (el.id === payload.expId) {
        return { ...el, isSelected: payload.status };
      } else {
        return el;
      }
    });
    const parentSections = state.sections[payload.estimateId]?.results?.map((el) => {
      if (el.id === payload.parentId) {
        return { ...el, isSelected: siblingExpenditures.every((el) => el.isSelected) };
      } else {
        return el;
      }
    });
    return {
      ...state,
      sections: {
        ...state.sections,
        [payload.estimateId]: {
          ...(state.sections[payload.estimateId] || {}),
          results: parentSections,
        },
      },
      expenditures: {
        ...state.expenditures,
        [sectionId]: {
          ...(state.expenditures[sectionId] || {}),
          results: siblingExpenditures,
        },
      },
      intersectingExpenditures:
        payload.status === true
          ? state.intersectingExpenditures
          : state.intersectingExpenditures.filter((el) => el.expenditure_id !== payload.expId),
    };
  }
  return state;
};

export const deselectActingSectionsAndExpenditures = (state: IActingState, action: IActingAction): IActingState => {
  const { type, payload } = action;
  if (type === "acting/DESELECT_ALL") {
    const clearedExpenditures = { ...state.expenditures };
    for (let sectionId in clearedExpenditures) {
      clearedExpenditures[sectionId] = {
        ...clearedExpenditures[sectionId],
        results: clearedExpenditures[sectionId].results?.map((el) => ({ ...el, isSelected: false })),
      };
    }
    const clearedSections = { ...state.sections };
    for (let estimateId in clearedSections) {
      clearedSections[estimateId] = {
        ...clearedSections[estimateId],
        results: clearedSections[estimateId].results?.map((el) => ({ ...el, isSelected: false })),
      };
    }
    return {
      ...state,
      sections: clearedSections,
      expenditures: clearedExpenditures,
    };
  }
  return state;
};
