import cn from "classnames";
import React from "react";

import PopoverOverlay from "../../../../../../UI/molecules/PopoverOverlay/PopoverOverlay";
import MaterialStatusBlock from "./components/MaterialStatusBlock/MaterialStatusBlock";
import StatusBlock from "./components/StatusBlock/StatusBlock";

import {
  INTERVAL_NAME_MAP,
  INTERVAL_TYPES,
  REMARKS_COLOR,
  WORK_STATUSES,
  getHighestMaterialStatus,
  getWorkStatus,
} from "../../../../constants";

import { AlertIcon } from "../../../../../../../images/icons/AlertIcon";

import styles from "./InfoPopup.module.scss";

const InfoPopup = React.forwardRef(({
  data,
  type,
  intervalDates,
  setOpenPopup,
  setOpenModal,
  children,
  isOpen,
  remarksCount,
  openShiftModal,
  hasShift = false
}, ref) => {
    const workStatus =
      type === INTERVAL_TYPES.work && data.percentage ? getWorkStatus(data.percentage) : WORK_STATUSES.default;

    const isMaterialOrMim = [
      INTERVAL_TYPES.material,
      INTERVAL_TYPES.equipment,
      INTERVAL_TYPES.machine,
      INTERVAL_TYPES.transport,
    ].includes(type);

    const materialStatus = isMaterialOrMim ? getHighestMaterialStatus(data?.map((m) => m.status)) : "";

    const workMeasure = type === INTERVAL_TYPES.work ? data.measure : "";

    const isWorkIndicatorsShown =
      type === INTERVAL_TYPES.work && !!data.count_indicators && workStatus !== WORK_STATUSES.default;

    const isMaterialStatusesShown = isMaterialOrMim && data?.length;

    return (
      <PopoverOverlay
        ref={ref}
        openType="hover"
        isOpen={isOpen}
        className={styles.popoverContainer}
        placement="bottom"
        popoverBorderColor={isMaterialOrMim ? `${materialStatus}Material` : workStatus}
        content={
          <div onMouseLeave={() => setOpenPopup(false)} className={styles.container}>
            <div>
              <div className={styles.header}>
                <span>{INTERVAL_NAME_MAP[type]}</span>
                <span className={styles.intervalDates}>{intervalDates}</span>
              </div>

              {isWorkIndicatorsShown && (
                <div className={cn(styles.item, styles.statuses)}>
                  {+data?.count_indicators?.doned > 0 && (
                    <StatusBlock measure={workMeasure} count={+data?.count_indicators?.doned} status={"doned"} />
                  )}
                  {+data?.count_indicators?.received > 0 && (
                    <StatusBlock measure={workMeasure} count={+data?.count_indicators?.received} status={"received"} />
                  )}
                  {(+data?.count_indicators?.confirmed > 0 ||
                    +data?.count_indicators?.accepted_count_for_contractor > 0) && (
                    <StatusBlock
                      measure={workMeasure}
                      count={
                        (+data?.count_indicators?.confirmed || 0) +
                        (+data?.count_indicators?.accepted_count_for_contractor || 0)
                      }
                      status={"confirmed"}
                    />
                  )}
                  {+data?.count_indicators?.todo > 0 && (
                    <StatusBlock measure={workMeasure} count={+data?.count_indicators?.todo} status={"todo"} />
                  )}
                  {+data?.count_indicators?.topay > 0 && (
                    <StatusBlock measure={workMeasure} count={+data?.count_indicators?.topay} status={"topay"} />
                  )}
                </div>
              )}

              {isMaterialStatusesShown && (
                <div className={cn(styles.item, styles.statuses)}>
                  {data.map(({ status, count, measure }, index) => (
                    <MaterialStatusBlock status={status} count={count} measure={measure} key={index} />
                  ))}
                </div>
              )}

              {!!remarksCount && (
                <div className={cn(styles.item, styles.remarks)}>
                  <span>
                    <AlertIcon fill={REMARKS_COLOR} width={16} /> Замечания:
                  </span>
                  <span className={styles.textPrimary}>{remarksCount}</span>
                </div>
              )}

              {hasShift && (
                <div
                  className={styles.shift}
                  onClick={openShiftModal}
                >
                  Требуется<br/>корректировка плана
                </div>
              )}

              <div className={styles.buttons}>
                <div onClick={() => setOpenModal(true)} className={styles.cardButton}>
                  Посмотреть
                </div>
              </div>
            </div>
          </div>
        }
      >
        {children}
      </PopoverOverlay>
    );
  }
);

export default React.memo(InfoPopup);
