import cn from "classnames";
import React, { useState } from "react";

import ContentWithCount from "../../../../UI/atoms/ContentWithCount/ContentWithCount";
import { FilesPermissionsType } from "../../../../UI/atoms/InputFiles/InputFiles";
import InputFiles from "../../../../UI/atoms/InputFiles/index";
import Button from "components/UI/atoms/ButtonBase";
import SliderModal from "components/UI/atoms/SliderModal";
import ExpenditureFilesModal from "components/modals/ExpenditureFilesModal/ExpenditureFilesModal";
import PassportsAndSertificates from "components/modals/PassportsAndSertificates/PassportsAndSertificates";

import { LABELS } from "../../constants";
import { IExpenditure } from "types/interfaces/Expenditure";

import { useProductFilesModal } from "../../../../../hooks/useProductFilesModal";

import PaperClipIcon from "images/icons/PaperClipIcon";

import styles from "./ProfuctFilesModal.module.scss";

export interface IProps {
  variant: string;
  files: any;
  uploadFilesCallback?: (addedFiles: any[]) => void;
  deleteFilesCallback?: (file: any) => void;
  fetchFilesCallback?: () => void;
  permissions?: FilesPermissionsType;
  buildingId?: number | string;
  itemId?: number | string;
  filesCount?: number;
  readOnly?: boolean;
  productName?: string;
  isPassports?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  expenditureId: number;
  isV2?: boolean;
  expenditureType?: IExpenditure["expenditure_type"];
  isDontOpenModal?: boolean;
}

const ProductFilesModal: React.FC<IProps> = ({
  variant,
  files: productFiles,
  uploadFilesCallback,
  deleteFilesCallback,
  fetchFilesCallback,
  buildingId,
  itemId,
  filesCount,
  readOnly,
  productName,
  permissions = { addFiles: true, deleteFiles: true },
  isPassports,
  onOpen,
  onClose,
  expenditureId,
  isV2,
  expenditureType,
  isDontOpenModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    if (isDontOpenModal) return;
    onOpen?.();
    setIsOpen(true);
  };

  const closeModal = () => {
    onClose?.();
    setIsOpen(false);
  };

  const { files, preparedFiles, downloadFiles, handleFilesChange, handleSubmit } = useProductFilesModal({
    productFiles,
    isOpen,
    fetchFilesCallback,
    uploadFilesCallback,
    deleteFilesCallback,
    buildingId,
    itemId,
  });

  return (
    <div>
      {!isV2 && (
        <SliderModal isOpen={isOpen} closeHandler={closeModal} className={styles.addFilesModal}>
          <div className={styles.addFilesModal__inner}>
            <div className={styles.addFilesModal__form}>
              <h3 className={styles.addFilesModal__form_title}>{LABELS.TITLE}</h3>
              {productName && <p className={styles.addFilesModal__form_subtitle}>к позиции:</p>}
              {productName && <p>{productName}</p>}
              {isPassports && <PassportsAndSertificates productId={+itemId!} />}
              <div className={styles.addFilesModal__files}>
                <div className={styles.addFilesModal__files_header}>
                  <span className={styles.title}>
                    {LABELS.FILES_INPUT.TITLE} {files?.length}
                  </span>
                  {!!files?.length && (
                    <button className={styles.actionButton} onClick={downloadFiles}>
                      {LABELS.FILES_INPUT.DOWNLOAD}
                    </button>
                  )}
                </div>
                <InputFiles value={preparedFiles as any} setValue={handleFilesChange} permissions={permissions} />
              </div>
            </div>
            <div className={styles.addFilesModal__buttons}>
              <Button secondary medium onClick={closeModal}>
                {LABELS.BUTTONS.CANCEL}
              </Button>
              <Button primary medium onClick={handleSubmit}>
                {LABELS.BUTTONS.SUBMIT}
              </Button>
            </div>
          </div>
        </SliderModal>
      )}
      {isV2 && (
        <ExpenditureFilesModal
          isOpen={isOpen}
          onClose={closeModal}
          expenditureName={productName}
          expenditureId={expenditureId}
          expenditureType={expenditureType}
        />
      )}
      <div
        className={cn(styles.paperclipIcon, [styles[variant]])}
        onClick={files?.length > 0 || !readOnly ? openModal : undefined}
      >
        <ContentWithCount
          isCountDisplayed={(filesCount && filesCount > 0) || preparedFiles?.length > 0}
          count={filesCount || preparedFiles?.length}
          containerClassName={styles.button}
        >
          <div title={"Прикрепленные файлы"}>
            <button
              type="button"
              style={{
                width: "1.3rem",
                cursor: files?.length > 0 || !readOnly ? "pointer" : "default",
              }}
              className="filesModalTrigger"
            >
              <PaperClipIcon fill="#6C6C6C" width="0.57rem" />
            </button>
          </div>
        </ContentWithCount>
      </div>
    </div>
  );
};

export default React.memo(ProductFilesModal);
