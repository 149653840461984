import { message } from "antd";
import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { postsActions } from "../../../../../../../redux/modules/common/building/posts/actions";
import {
  postInModalIsLoadingSelector,
  postInModalSelector,
} from "../../../../../../../redux/modules/common/building/posts/selectors";
import { loadPostInModal } from "../../../../../../../redux/modules/common/building/posts/thunks";
import { useTypedSelector } from "../../../../../../../redux/typedUseSelector";

import { Spinner } from "../../../../../../UI/Spinner/Spinner";
import AddButton from "../../../../../../UI/atoms/AddButton/AddButton";
import ButtonBase from "../../../../../../UI/atoms/ButtonBase";
import InputBase from "../../../../../../UI/atoms/InputBase";
import BottomControls from "../../../../../../UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "../../../../../../UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import PositionModalHeader from "./components/PositionModalRow/PositionModalHeader";
import PositionModalRow from "./components/PositionModalRow/PositionModalRow";

import useEditPositions, { IPositionInModal } from "./useEditPositions";

import { VIEW_EDIT_SETTINGS_POSTS } from "../../../../../../../constants/permissions/settingsPermissions";
import { ICreatePostRank, IPostRank } from "../../../../../../../types/interfaces/Posts";
import { POSITION_MODAL_TITLES } from "./constants";

import usePermission from "../../../../../../../hooks/usePermission";

import { checkUnsavedPositions } from "./utils";

import styles from "./PositionModal.module.scss";

export interface IOnSavePostProps {
  title: string;
  addedRanks: ICreatePostRank[];
  removedRanksIds?: number[];
  editedRanks?: IPostRank[];
}

export interface IPositionModalProps {
  isOpen: boolean;
  onClose: () => void;
  isNew?: boolean;
  onSave: ({ title, addedRanks }: IOnSavePostProps) => void;
  onEdit: ({ title, addedRanks, removedRanksIds, editedRanks }: IOnSavePostProps) => void;
  postId: number | null;
}

export interface IPositionModalContext {
  unsavedChanges: Record<number, boolean>;
  setUnsavedChanges: (key: number, _: boolean) => void;
}

export const PositionModalContext = React.createContext<IPositionModalContext>({
  unsavedChanges: {},
  setUnsavedChanges: (k, _) => {},
});

const PositionModal: React.FC<IPositionModalProps> = ({ isOpen, onClose, isNew, onSave, postId, onEdit }) => {
  const dispatch = useDispatch();
  const positionInModal = useTypedSelector(postInModalSelector);
  const isLoading = useTypedSelector(postInModalIsLoadingSelector);
  const [unsavedChanges, setUnsavedChanges] = useState<IPositionModalContext["unsavedChanges"]>({});

  const haveViewEditSettingsPostsPermission = usePermission(VIEW_EDIT_SETTINGS_POSTS);

  const handleUpdateUnsavedChanges = useCallback((key: number, isUnsaved: boolean) => {
    setUnsavedChanges((prevState) => ({ ...prevState, [key]: isUnsaved }));
  }, []);

  const [isEdit, setIsEdit] = useState<boolean>(!!isNew);

  useEffect(() => {
    if (!isOpen || !postId || positionInModal?.id === postId) return;
    dispatch(loadPostInModal(postId));
  }, [isOpen, postId, positionInModal?.id]);

  const [positionName, setPositionName] = useState<string>(positionInModal?.title || "");

  useEffect(() => {
    setPositionName(positionInModal?.title || "");
  }, [positionInModal]);

  const handlePositionNameInput = useCallback(
    (e: InputEvent) => setPositionName((e.target as HTMLInputElement)?.value || ""),
    []
  );

  const {
    positions,
    addNewPosition,
    removePosition,
    editPosition,
    dropPositions,
    onCancelEdit,
    availableRanks,
    availableRankTypes,
    removedRanksIds,
  } = useEditPositions(positionInModal?.ranks);

  const handleClose = useCallback(() => {
    onClose();
    dropPositions();
    dispatch(postsActions.clearPostInModal());
    setIsEdit(false);
  }, [dropPositions, onClose]);

  const modalTitle = useMemo(() => {
    if (isNew) return POSITION_MODAL_TITLES.new;
    if (isEdit) return POSITION_MODAL_TITLES.edit;
    return positionInModal?.title || "";
  }, [isNew, isEdit, positionInModal?.title]);

  const handleSaveClick = useCallback(() => {
    if (!positionName) {
      message.warn("Введите название должности");
      return;
    }
    if (checkUnsavedPositions(unsavedChanges, positions) || positions.some((p) => !p.rank || !p.stake || !p.type)) {
      message.warn("Подтвердите все разряды должности");
      return;
    }
    if (isNew) {
      onSave({
        title: positionName,
        addedRanks: positions.map((p) => ({ rank: p.rank, type: p.type, stake: p.stake })) as ICreatePostRank[],
      });
      onClose();
    } else
      onEdit({
        title: positionName,
        addedRanks: positions
          .filter((x) => x.id! % 1 !== 0)
          .map((p) => ({ rank: p.rank, type: p.type, stake: p.stake })) as ICreatePostRank[],
        editedRanks: positions.filter((x) => x.id! % 1 === 0) as IPostRank[],
        removedRanksIds: Array.from(new Set(removedRanksIds)),
      });
    setIsEdit(false);
  }, [positions, positionName, removedRanksIds, unsavedChanges]);

  const handleCancelClick = useCallback(() => {
    if (isEdit) {
      onCancelEdit();
      setIsEdit(false);
    } else handleClose();
  }, [isEdit, handleClose, onCancelEdit]);

  const canSave = isNew || isEdit;
  const canEdit = haveViewEditSettingsPostsPermission;

  return (
    <PositionModalContext.Provider value={{ unsavedChanges, setUnsavedChanges: handleUpdateUnsavedChanges }}>
      <ModalContainer
        isOpen={isOpen}
        onClose={handleClose}
        name={modalTitle}
        contentClassName={styles.modalContent}
        customHeadline={
          (isEdit || isNew) && (
            <div className={styles.headline}>
              <InputBase
                label={"Должность"}
                value={positionName}
                onInput={handlePositionNameInput as any}
                placeholder={"Введите название должности"}
              />
              <AddButton text={"Разряд"} textPosition={"left"} className={styles.addBtn} onClick={addNewPosition} />
            </div>
          )
        }
        headlineClassName={cn({ [styles.withoutBottomBorder]: !!positions.length })}
      >
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            {!!positions?.length && <PositionModalHeader isEdit={isNew! || isEdit} />}
            <div className={styles.positions}>
              {positions.map((p, index, arr) => (
                <PositionModalRow
                  key={p.id}
                  position={p}
                  isEdit={p.isNew || isEdit}
                  canDelete={p.isNew || isEdit || !!isNew}
                  onApprove={(payload) => editPosition(p.id!, payload)}
                  onDelete={() => removePosition(p.id!)}
                  index={index}
                  hasTopBorder={index === 0 || arr[index - 1]?.rank !== p.rank}
                  availableRanks={availableRanks}
                  availableRankTypes={availableRankTypes}
                  isDisplay={!isNew && !isEdit}
                />
              ))}
            </div>
            <BottomControls isExists={canSave || canEdit} isDoubleBtns={canSave && (canEdit || isNew)}>
              {canSave && (
                <ButtonBase secondary onClick={handleCancelClick}>
                  Отменить
                </ButtonBase>
              )}
              {canSave ? (
                <ButtonBase primary onClick={handleSaveClick}>
                  Сохранить
                </ButtonBase>
              ) : canEdit ? (
                <ButtonBase primary onClick={() => setIsEdit(true)}>
                  Редактировать
                </ButtonBase>
              ) : null}
            </BottomControls>
          </>
        )}
      </ModalContainer>
    </PositionModalContext.Provider>
  );
};

export default React.memo(PositionModal);
