import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { message } from "antd";

import styles from "./ModalAddTask.module.scss";
import { EXTRA_MODAL_TAB, MAIN_MODAL_TAB } from "../../../../constants";
import ModalAddTaskMainFields from "./ModalAddTaskMainFields";
import ModalAddTaskExtraFields from "./ModalAddTaskExtraFields";
import { useCreateTask } from "../../../../hooks/useCreateTask";
import { ADD_TASK_INITIALS } from "../../../../constants";
import { useTasksModal } from "../../../../hooks/useTasksModal";
import cn from 'classnames'

export interface IModalAddTaskProps {
  formRef: React.RefObject<HTMLFormElement>;
  activeTab: string;
  presetBuildingId: number;
  handleCloseModal: () => void;
  isHidden?: boolean
}

const ModalAddTask: React.FC<IModalAddTaskProps> = ({ formRef, activeTab, presetBuildingId, handleCloseModal, isHidden }) => {
  const { placementType, listType, list_id, submission } = useTasksModal();

  const { onSubmit, building, setBuilding, section_id, setSectionId, executor, setExecutor, viewers, setViewers } =
    useCreateTask({ placementType, listType, list_id });

  const [requestClose, setRequestClose] = useState<boolean>(false);

  useEffect(() => {
    if (!submission?.isSubmitted && submission?.taskId !== -1 && requestClose) handleCloseModal();
  }, [submission, requestClose]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={ADD_TASK_INITIALS}
      render={({ form, handleSubmit }) => (
        <form
          className={cn(styles.form, {[styles.isHidden]: isHidden})}
          ref={formRef}
          onReset={() => form.restart()}
          onSubmit={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (form.getState().valid) {
              await handleSubmit();
              form.restart();
              setRequestClose(true);
            } else {
              message.warn("Заполните обязательные поля формы");
            }
          }}
        >
          <ModalAddTaskMainFields
            presetBuildingId={presetBuildingId}
            building={building}
            setBuilding={setBuilding}
            section_id={section_id}
            setSectionId={setSectionId}
            isVisible={activeTab === MAIN_MODAL_TAB}
            executor={executor}
            setExecutor={setExecutor}
            viewers={viewers}
            setViewers={setViewers}
          />
          <ModalAddTaskExtraFields isVisible={activeTab === EXTRA_MODAL_TAB} />
        </form>
      )}
    />
  );
};
export default ModalAddTask;
