import {combineReducers} from 'redux'

import customers from './customers'
import list from './list'
import item from './item'

export const moduleName = 'customers'

export default combineReducers({
  index: customers,
  list,
  item
})
