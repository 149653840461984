import React from "react";

import {
  IExpenditureInActing,
  ISectionInActing,
  ISelectedSectionInActing,
} from "redux/modules/common/building/journal/acting/types";

import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import JournalRowIndicator from "../../../common/JournalRowIndicator/JournalRowIndicator";
import JournalKs2ModalRowContent from "./JournalKs2ModalRowContent";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./JournalKsModalRow.module.scss";

interface IProps {
  section: ISelectedSectionInActing;
  estimateId: number;
}

const JournalKs2ModalRow: React.FC<IProps> = ({ section, estimateId }) => {
  return (
    <TableReusableRow
      isOpenByDefault
      isHoverable={false}
      isExpandable
      isParentOfExpenditures
      className={styles.sectionRow}
      innerContent={
        <JournalKs2ModalRowContent
          sectionId={section.id}
          isNeedLoad={section.isNeedLoad}
          expenditures={section.expenditures}
          estimateId={estimateId}
        />
      }
    >
      <TableReusableCell isNoBreak>{section.name}</TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator value={transformDigitToFinancial(section.todo_amount)} title="К сдаче" />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(JournalKs2ModalRow);
