import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import ConfirmBlock from "./components/ConfirmBlock/ConfirmBlock";
import MoreButton from "./components/MoreButton/MoreButton";
import MimsTableHeader from "./components/TableHeader/MimsTableHeader";
import MimsTableRow from "./components/TableRow/MimsTableRow";

import { Spinner } from "../../Spinner/Spinner";
import AddButton from "../../atoms/AddButton/AddButton";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder/EmptyPlaceholder";
import Expandable from "../../atoms/Expandable/Expandable";

import { IMimsInAdding } from "types/interfaces/Mims";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useMimsList } from "../../../../hooks/useMimsList";

import materials from "images/icons/navigation/materials.svg";

import styles from "./AddingListInModal.module.scss";
import { TResource } from "./hooks/useSimpleResources";
import { isNanChecker } from "../../../../utils/formatters/isNanChecker";
import {
  SimpleResourceEditBlock
} from "../../../../features/simpleResources/ui/SimpleResourceEditBlock/SimpleResourceEditBlock";
import {
  SimpleResourceBlock
} from "../../../../features/simpleResources/ui/SimpleResourceBlock/SimpleResourceBlock";
import { ResourceTypeEnum } from "../../../../features/simpleResources/types/simpleResources";

interface IProps {
  addedMims: any[] | IMimsInAdding[];
  selectedMims: IMimsInAdding[];
  onAccept: () => boolean;
  onDecline: () => boolean;
  onChangeCount: (id: number, count: number | string, type: "selected" | "added") => void;
  onDelete: (id: number) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: IMimsInAdding) => void;
  selectedIds: number[];
  addedIds: number[];
  isDisabled: boolean;
  activeSection: number | null;
  activeWork: number | null;
  defaultMimsList?: IMimsInAdding[];
  isWorkGroup?: boolean;
  groupId?: number;
  externalMimsData?: {
    allMims: any[];
    isLoading: boolean;
    loadMoreHandler: () => void;
    count: number;
    filterHandler: () => void;
  };
  isValidatingCount?: boolean;
  simpleResources?: TResource[],
  createNewSimpleResource?: () => void;
  deleteSimpleResource?: (r: TResource) => void;
  isSimpleResourceAdding?: boolean;
  toggleIsSimpleResourceEditing?: (r: TResource) => void;
  updateSimpleResource?: (r: TResource) => void;
}

const AddMimsInModal: React.FC<IProps> = ({
  addedMims,
  selectedMims,
  onAccept,
  onDecline,
  onChangeCount,
  onDelete,
  onSelect,
  selectedIds,
  addedIds,
  isDisabled,
  activeSection,
  activeWork,
  defaultMimsList,
  isWorkGroup,
  groupId,
  externalMimsData,
  isValidatingCount,
  simpleResources,
  createNewSimpleResource,
  deleteSimpleResource,
  isSimpleResourceAdding,
  toggleIsSimpleResourceEditing,
  updateSimpleResource,
}) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const { allMims, isLoading, loadMoreHandler, count, filterHandler, fetchMims } = useMimsList({
    objectId,
    newSectionId: activeSection,
    expWorkId: activeWork,
    defaultMimsList,
    isWorkGroup,
    groupId,
    externalMimsData,
  });

  const [isAdding, setIsAdding] = useState(false);

  const withStopAdding = React.useCallback((cb: () => boolean) => {
    return () => cb?.() && setIsAdding(false);
  }, []);

  const isAddSimpleMaterialDisabled = isSimpleResourceAdding;
  const onAddSimpleMaterialClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (!isAddSimpleMaterialDisabled) {
      createNewSimpleResource?.({type: ResourceTypeEnum.transport});
    }
  };

  const simpleResourcesWithoutDeleted = useMemo(() => {
    return simpleResources?.filter(r => !r.delete) ?? [];
  }, [simpleResources])

  const onSaveSimpleResource = useCallback((r: TResource) => (resource: TResource) => {
    updateSimpleResource?.({...r, ...resource});
  }, [updateSimpleResource]);

  const onCancelEditSimpleResource = useCallback((r: TResource) => () => {
    if (r.isAdding) {
      deleteSimpleResource?.(r);
    } else {
      toggleIsSimpleResourceEditing?.(r);
    }
  }, [deleteSimpleResource, toggleIsSimpleResourceEditing])

  const toggleEditSimpleResource = useCallback((r: TResource) => () => {
    toggleIsSimpleResourceEditing?.(r);
  }, [toggleIsSimpleResourceEditing])

  const onDeleteSimpleResource = useCallback((r: TResource) => () => {
    deleteSimpleResource?.(r);
  }, [deleteSimpleResource]);

  const resourcesCount = useMemo(() => {
    return isNanChecker(addedMims?.length) + isNanChecker(simpleResourcesWithoutDeleted?.length)
  }, [addedMims, simpleResourcesWithoutDeleted])

  return (
    <Expandable
      className={styles.wrapper}
      additionalHeadline={
        <div className={styles.expandableHeadline}>
          <span className={styles.title}>Машины и механизмы: {resourcesCount || "-"}</span>
          <button
            className={cn(styles.subTitleButton, isAddSimpleMaterialDisabled && styles.disabled)}
            disabled={isAddSimpleMaterialDisabled}
            onClick={onAddSimpleMaterialClick}
          >
            + добавить новый
          </button>
        </div>
      }
    >
      {simpleResourcesWithoutDeleted.map((r) => {
        if (r.isAdding || r.isEditing) {
          return (
            <SimpleResourceEditBlock
              key={r.tmpId ?? r.id}
              name={r.name}
              count={r.count}
              measure={r.measure}
              type={r.type}
              onSave={onSaveSimpleResource(r)}
              onCancel={onCancelEditSimpleResource(r)}
              isMim
            />
          )
        } else {
          return (
            <SimpleResourceBlock
              key={r.tmpId ?? r.id}
              canInteract
              name={r.name}
              count={r.count}
              measure={r.measure!}
              onEdit={toggleEditSimpleResource(r)}
              onDelete={onDeleteSimpleResource(r)}
            />
          )
        }
      })}
      {!!addedMims?.length && (
        <div className={styles.table}>
          <MimsTableHeader />
          {externalMimsData?.isLoading
            ? (<Spinner isSmall isStatic className={styles.spinner} />)
            : (addedMims?.map((item) => (
              <MimsTableRow
                key={item.id}
                material={item}
                isCanDelete
                onChangeCount={(event) => onChangeCount(item.id, event.target.value, "added")}
                onDelete={() => onDelete(item.id)}
                isCanBeChecked={false}
                isValidatingCount
              />
            )))
          }
        </div>
      )}
      {!isLoading && count !== addedMims?.length && (
        <div className={styles.subtitle}>
          <span
            className={styles.pointer}
            onClick={() => {
              if (!isAdding && !isDisabled) {
                setIsAdding(true);
              }
            }}
          >
            МиМ по смете
          </span>
          {!isAdding && (
            <AddButton
              className={styles.addButton}
              isDisabled={isDisabled}
              onClick={() => setIsAdding(true)}
            />
          )}
        </div>
      )}
      {isAdding && (
        <ConfirmBlock
          count={selectedMims?.length}
          onAccept={withStopAdding(onAccept)}
          onDecline={withStopAdding(onDecline)}
        />
      )}
      {isAdding && (
        <div
          className={cn(styles.table, styles.bordered, {
            [styles.minHeight]: isLoading,
          })}
        >
          <MimsTableHeader onFiltersChange={isWorkGroup ? undefined : filterHandler} className={styles.header} />
          {isLoading && <Spinner />}
          {!!(!isLoading && allMims?.length) &&
            allMims
              ?.filter((item: any) => !addedIds.includes(item.id))
              .map((item: any) => (
                <MimsTableRow
                  key={item.id}
                  material={item}
                  onChangeCount={(e) => onChangeCount(item.id, Number(e.target.value), "selected")}
                  onCheck={onSelect}
                  isSelected={selectedIds.includes(item.id)}
                />
              ))}
          {!isLoading && !allMims?.length && <EmptyPlaceholder img={materials} />}
          <MoreButton
            isLoading={isLoading}
            allCount={count}
            existingCount={allMims?.length}
            onLoadMore={loadMoreHandler}
          />
        </div>
      )}
    </Expandable>
  );
};

export default React.memo(AddMimsInModal);
