import React from "react";
import { useSelector } from "react-redux";

import {
  sectionSelector,
  sectionsWithConfirmedChildStatusSelector,
  sectionsWithNewChildStatusSelector,
} from "../../../../../redux/modules/common/building/sections/sections";
import { detailDataSelector } from "../../../../../redux/modules/common/building/object/nowObject";

import Amount from "../../../../UI/atoms/Amount/Amount";
import ProgressBar from "../../../../UI/atoms/ProgressBar/ProgressBar";

import { getSectionAmount } from "../../utils/getSectionAmount";
import { getSectionCompletedPercent } from "../../utils/getSectionCompletedPercent";

import styles from "./Amounts.module.scss";
import { useAggregations } from "../../hooks/useAggregations";

const Amounts = ({ isBuilding, activeEstimateStateId }) => {
  const activeSection = useSelector(sectionSelector);
  const building = useSelector(detailDataSelector);

  const sectionsWithNewChildStatus = useSelector(sectionsWithNewChildStatusSelector);
  const sectionsWithConfirmedChildStatus = useSelector(sectionsWithConfirmedChildStatusSelector);

  const { actualAggregation } = useAggregations({ sectionId: activeEstimateStateId });

  const activeSectionCompletePercent = activeSection
    ? getSectionCompletedPercent(activeSection, activeEstimateStateId)
    : 0;

  let activeSectionAmount = 0;

  if (sectionsWithNewChildStatus) {
    activeSectionAmount = sectionsWithNewChildStatus.reduce((acc, section) => {
      return acc + +getSectionAmount(section, activeEstimateStateId);
    }, 0);
  } else if (sectionsWithConfirmedChildStatus) {
    activeSectionAmount = sectionsWithConfirmedChildStatus.reduce((acc, section) => {
      return acc + +getSectionAmount(section, activeEstimateStateId);
    }, 0);
  } else if (activeSection) {
    activeSectionAmount = getSectionAmount(activeSection, activeEstimateStateId);
  }

  const buildingAmountBySection = actualAggregation || building.estimate_amount;
  const buildingPercentBySection = building.complete_status_percent;

  return (
    <div className={styles.amounts}>
      <Amount value={isBuilding ? buildingAmountBySection : activeSectionAmount} isBold />
      <div className={styles.divider} />
      <ProgressBar completed={isBuilding ? buildingPercentBySection : activeSectionCompletePercent} />
    </div>
  );
};

export default React.memo(Amounts);
