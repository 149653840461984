import cn from "classnames";
import React from "react";

import Calendar from "../../../../molecules/Calendar/Calendar";

import styles from "./ClosureDateDisplay.module.scss";

type propsType = {
  closureDate: string;
  isDisabled?: boolean;
  isVertical?: boolean;
};

function ClosureDateDisplay({ closureDate, isDisabled = true, isVertical = false }: propsType) {
  return (
    <div className={cn(styles.pair, styles.centered, { [styles.vertical]: isVertical })}>
      <b>Плановая дата закрытия:</b>
      {/* @ts-ignore */}
      <Calendar value={closureDate} setValue={() => {}} disabled={isDisabled} classNameSelect={styles.calendar} />
    </div>
  );
}

export default ClosureDateDisplay;
