import moment from "moment";
import React from "react";

import { IKs2InActingList } from "redux/modules/common/building/journal/acting/types";

import Checkbox from "components/UI/atoms/Checkbox/Checkbox";
import DateCell from "components/UI/atoms/DateCell/DateCell";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import JournalKs3EditModal from "../JournalActingModal/JournalKs3EditModal";
import JournalRowIndicator from "../common/JournalRowIndicator/JournalRowIndicator";
import ksIcon from "./../common/JournalActingExpenditure/ksIcon";
import JournalKs2Content from "./JournalKs2Content";

import { VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS3 } from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalKs2Level.module.scss";

interface IProps {
  isFirstInParent?: boolean;
  rowOpenClassName?: string;
  ks2: IKs2InActingList;
  isChecked?: boolean;
  onCheck: (ksId: number, status: boolean) => void;
}

const JournalKs2Row: React.FC<IProps> = ({
  isFirstInParent,
  rowOpenClassName = styles.openKs2Row,
  ks2,
  isChecked,
  onCheck,
}) => {
  const haveFormationKs3Permission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS3);
  const checkHandler = React.useCallback(() => {
    onCheck(ks2.id, !isChecked);
  }, [isChecked, ks2]);

  const [isEdit, setIsEdit] = React.useState(false);

  return (
    <>
      {isFirstInParent && (
        <TableReusableRow containerClassName={styles.parent}>{ks2.parent_section_name}</TableReusableRow>
      )}
      <TableReusableRow
        className={styles.ks2row}
        isExpandable
        isParentOfExpenditures
        innerContent={<JournalKs2Content ks2Id={ks2.id} />}
        isWhiteSvgOnHover
        openClassName={rowOpenClassName}
      >
        {haveFormationKs3Permission && (
          <TableReusableCell className={styles.checkbox}>
            <Checkbox checked={!!isChecked} onCheck={checkHandler} />
          </TableReusableCell>
        )}

        <TableReusableCell isNoBreak className={styles.ksName}>
          КС-2 №{ks2.title}
        </TableReusableCell>
        <TableReusableCell>
          <DateCell
            isIcon
            date={`${moment(ks2.start_at).format("DD.MM.YYYY")} - ${moment(ks2.end_at).format("DD.MM.YYYY")}`}
            className={undefined}
          />
        </TableReusableCell>
        <TableReusableCell isRighted isNoBreak className={styles.ksName}>
          {ks2.act_ks3_title ? `КС-3 №${ks2.act_ks3_title}` : ""}
        </TableReusableCell>
        <TableReusableCell
          onClick={(e) => {
            e.stopPropagation();
            ks2.act_ks3 && setIsEdit(true);
          }}
        >
          {!!ks2.act_ks3 && ksIcon}
        </TableReusableCell>
        <TableReusableCell>
          <JournalRowIndicator title="К сдаче" value={splitThousands(ks2.todo_amount)} />
        </TableReusableCell>
      </TableReusableRow>
      {/* @ts-ignore */}
      {ks2.act_ks3 && <JournalKs3EditModal isOpen={isEdit} onClose={() => setIsEdit(false)} ks3Id={ks2.act_ks3} />}
    </>
  );
};

export default React.memo(JournalKs2Row);
