import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { formatFinanceTreeViaObjectId } from "../../../../../redux/modules/common/constructing/finance/financeCommonUtils";
import {
  allProjectsSummarySelector,
  detailedProjectSummarySelector,
  isLoadingSelector,
  objectIdSelector,
  summaryIsLoadingByObjectsSelector,
  summaryTreeSelector,
} from "../../../../../redux/modules/common/constructing/finance/summary/selectors";
import {
  getSummaryDetailedProjectV2,
  loadAllProjectsSummary,
  loadDetailedProjectSummary,
  toggleOpenSummaryTree,
} from "../../../../../redux/modules/common/constructing/finance/summary/thunks";

export const useSummaryData = () => {
  const dispatch = useDispatch();

  const { projectId } = useParams<Record<string, string>>();
  const objectId = useSelector(objectIdSelector);
  const isLoading = useSelector(isLoadingSelector);
  const tree = useSelector(summaryTreeSelector);
  const allProjectsSummary = useSelector(allProjectsSummarySelector);
  const detailedProjectsSummary = useSelector(detailedProjectSummarySelector);

  const isFirstLoad = useRef<boolean>(!allProjectsSummary?.length);

  const isLoadingByObject = (useSelector(summaryIsLoadingByObjectsSelector) as Record<string, boolean>)[
    objectId as string
  ];

  const displayedTree = useMemo(() => {
    return formatFinanceTreeViaObjectId(tree, +objectId, projectId) || [];
  }, [tree, objectId, projectId]);

  useEffect(() => {
    if (isLoading || !isFirstLoad.current) return;
    dispatch(loadAllProjectsSummary());
    isFirstLoad.current = false;
  }, [allProjectsSummary]);

  useEffect(() => {
    if (!+objectId || !!detailedProjectsSummary?.[objectId]) return;
    dispatch(loadDetailedProjectSummary(objectId));
  }, [objectId, detailedProjectsSummary]);

  useEffect(() => {
    if (projectId && +projectId !== 0) {
      dispatch(getSummaryDetailedProjectV2(projectId, objectId));
    }
  }, [projectId, objectId]);

  return {
    displayedTree,
    isLoading,
    isFirstLoad: isFirstLoad.current,
    isLoadingByObject,
  };
};
