import React, { useEffect, useState } from "react";

import { apiAddFile, apiDeleteFile, apiGetFile } from "redux/modules/common/building/processFileApi";

import Button from "components/UI/atoms/ButtonBase";
import SliderModal from "components/UI/atoms/SliderModal";

import InputFiles from "../../atoms/InputFiles/InputFiles";

import { IProjectExpenditure } from "types/interfaces/Expenditure";
import { IFile } from "types/interfaces/Files";

import downloadFileByUrl from "utils/helpers/downloadFileByUrl";

import styles from "./SaveFileModal.module.scss";

export interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  product: IProjectExpenditure;
  objectId: string;
  loadSectionExpenditures: () => void;
  canUpload?: boolean;
  canDelete?: boolean;
}

const SaveFileModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  product,
  objectId,
  loadSectionExpenditures,
  canUpload = true,
  canDelete = true,
}) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [newFile, setNewFile] = useState<IFile[]>([]);

  const handleCloseModal = () => {
    handleClose();
    setFiles([]);
  };

  const handleSubmit = () => {
    const deletedFiles = files.filter((initialFile) => !newFile.find((file) => file.id === initialFile.id));

    if (newFile.length > 0) {
      newFile.forEach(
        (file) => !file.file_name && apiAddFile(objectId, product.id, file.file, loadSectionExpenditures)
      );
    }

    if (deletedFiles.length > 0) {
      deletedFiles.forEach((file) => apiDeleteFile(objectId, product.id, file.id, loadSectionExpenditures));
    }

    handleCloseModal();
  };

  const loadFile = async () => {
    const data = await apiGetFile(objectId, product.id);
    setNewFile(data.results);
    setFiles(data.results);
  };

  useEffect(() => {
    isOpen && loadFile();
  }, [isOpen, product]);

  const downloadAllHandler = () => {
    const preparedFiles = newFile?.filter((el) => el.file);
    preparedFiles.forEach((el) => {
      downloadFileByUrl(el.file, el.file_name || el.name);
    });
  };

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Вложения</div>
          </div>
          <div className={styles.content}>
            <div className={styles.hiddenWrapper}>
              <div className={styles.fieldTitle}>Список вложений: {!!newFile.length && newFile.length}</div>
              <button type="button" onClick={downloadAllHandler} className={styles.fieldTitleActive}>
                Выгрузить
              </button>
            </div>
            <InputFiles
              value={newFile?.map((el) => ({ ...el, name: el.file_name }))}
              setValue={setNewFile}
              permissions={{ addFiles: canUpload, deleteFiles: canDelete }}
            />
          </div>
          <div className={styles.actionBlock}>
            <Button type="button" medium secondary onClick={handleCloseModal}>
              Отменить
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button medium primary onClick={handleSubmit}>
              Сохранить
            </Button>
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default React.memo(SaveFileModal);
