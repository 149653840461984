import axios from 'axios'
import { addFilesAction, deleteFileAction, setFilesAction } from './stockFilesReducer'

export const fetchAddFile = (receivedFile, stockId, stockProductId) => {
    const formData = new FormData()
    formData.append('file', receivedFile) // { file: receivedFile }
    return dispatch => {
        axios.post(`/stocks/${stockId}/products/${stockProductId}/files/`, formData)
            .then(response => dispatch(addFilesAction({file: response.data, stockProductId})))
    }
}

export const fetchDeleteFile = (stockId, stockProductId, fileId) => {
    return dispatch => {
        axios.delete(`/stocks/${stockId}/products/${stockProductId}/files/${fileId}/`)
            .then(response => dispatch(deleteFileAction({stockProductId, fileId})))
    }
}

export const fetchGetFiles = (stockId, stockProductId) => {
    return dispatch => {
        axios.get(`/stocks/${stockId}/products/${stockProductId}/files/`, {params: {limit: 100}})
            .then(response => dispatch(setFilesAction({files: response.data.results, stockProductId})))
    }
}