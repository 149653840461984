import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { countLoadingSelector, countSelector, loadCountProcess } from "redux/modules/common/building/process";

import CircleComponents from "components/UI/molecules/CircleComponents";

import { formatNumberWithMeasure } from "utils/formatters/formatNumberWithMeasure";
import { isNanChecker } from "utils/formatters/isNanChecker";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./index.module.scss";

interface Iprops {
  id: number | string;
  measure: number;
}

const Indicators = ({ id, measure }: Iprops) => {
  const sections = useSelector(countSelector);
  const dispatch = useDispatch();
  const isLoading = useSelector(countLoadingSelector);

  const sum = useMemo(() => sections?.sum_estimate_amount || 0, [sections?.sum_estimate_amount]);

  const invested = useMemo(() => {
    if (measure) {
      return formatNumberWithMeasure(sections?.invested || 0, measure);
    }

    return splitThousands(sections?.invested || 0);
  }, [measure, sections?.invested]);

  const accepted = useMemo(() => {
    if (measure) {
      return formatNumberWithMeasure(sections?.fact_estimate_work_accepted || 0, measure);
    }

    return splitThousands(sections?.fact_estimate_work_accepted || 0);
  }, [measure, sections?.fact_estimate_work_accepted]);

  const completed = useMemo(() => {
    if (measure) {
      return formatNumberWithMeasure(sections?.fact_estimate_work_completed || 0, measure);
    }

    return splitThousands(sections?.fact_estimate_work_completed || 0);
  }, [measure, sections?.fact_estimate_work_completed]);

  useEffect(() => {
    dispatch(loadCountProcess(id));
  }, [dispatch, id]);

  const completedPercentsDisplayingValue = React.useMemo(() => {
    if (isNanChecker(Math.round((sections?.complete_status_percent || 0) * 100)) > 0) {
      return isNanChecker(Math.round((sections?.complete_status_percent || 0) * 100));
    }
    if (
      isNanChecker(Math.round((sections?.complete_status_percent || 0) * 100)) == 0 &&
      sections?.fact_estimate_work_completed > 0
    ) {
      return "<1";
    }
    return 0;
  }, [sections]);

  return (
    <div className={styles.container}>
      <div className={styles.block_circle}>
        <div className={styles.item}>
          {!isLoading && (
            <CircleComponents
              progress={isNanChecker(Math.round((sections?.complete_status_percent || 0) * 100))}
              percent="%"
              color="#00C3F2"
              description="Процент выполнения"
              titleClassName={styles.circleTitle}
              descriptionClassName={styles.circleDescription}
              displayingValue={completedPercentsDisplayingValue + "%"}
              isProgressStringHidden
            />
          )}
        </div>
        <div className={styles.divider}></div>
        <div className={styles.item}>
          {!isLoading && (
            <CircleComponents
              progress={isNanChecker(Math.round((sections?.invested || 0) / (sum / 100)))}
              color="#C55EEB"
              description="Израсходовано"
              displayingValue={invested as string}
              isProgressStringHidden
              titleClassName={styles.circleTitle}
              descriptionClassName={styles.circleDescription}
            />
          )}
        </div>
        <div className={styles.item}>
          {!isLoading && (
            <CircleComponents
              progress={isNanChecker(Math.round((sections?.fact_estimate_work_completed || 0) / (sum / 100)))}
              color="#72E2E6"
              displayingValue={completed as string}
              description="Выполнено"
              isProgressStringHidden
              titleClassName={styles.circleTitle}
              descriptionClassName={styles.circleDescription}
            />
          )}
        </div>
        <div className={styles.item}>
          {!isLoading && (
            <CircleComponents
              progress={isNanChecker(Math.round((sections?.fact_estimate_work_accepted || 0) / (sum / 100)))}
              color="#8FE52B"
              description="Принято"
              displayingValue={accepted as string}
              isProgressStringHidden
              titleClassName={styles.circleTitle}
              descriptionClassName={styles.circleDescription}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Indicators);
