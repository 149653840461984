import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import {
  editDefaultExpendituresList,
  handlerEditWorkPendingSelector,
} from "redux/modules/common/building/sections/sections";

import { IExpenditureInHandlerProduction } from "../Body/components/Expenditures/types";
import { prepareExpenditureForEditInHandler } from "../Body/components/Expenditures/utils";
import ExpenditureFormRow from "components/UI/ExpenditureForm/ExpenditureFormRow";
import { Spinner } from "components/UI/Spinner/Spinner";

import { OUT_OF_ESTIMATE_REQUIRED_FIELDS } from "../../constants";

import styles from "./AddExpenditure.module.scss";

type IMaterial = IExpenditureInHandlerProduction["editingForm"];

interface IProps {
  expenditure: IExpenditureInHandlerProduction;
  buildingId: number;
  sectionId: number;
  onCancelEdit: () => void;
}

const EditExpendituresInHandler: React.FC<IProps> = ({ expenditure, sectionId, buildingId, onCancelEdit }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const pendingsMap = useSelector(handlerEditWorkPendingSelector);
  const isPending = (pendingsMap as Record<string, boolean>)[String(expenditure.id)];

  const submit = (values: IExpenditureInHandlerProduction["editingForm"]) => {
    dispatch(
      editDefaultExpendituresList({
        values,
        buildingId,
        newMaterialIds: newMaterials,
        deletedMaterialsIds: deletedMaterials,
        sectionId,
        succesCallback: () => {
          onCancelEdit();
        },
      })
    );
  };

  const cancelHandler = () => {
    onCancelEdit();
  };

  const [newMaterials, setNewMaterials] = useState<number[]>([]);

  const [deletedMaterials, setDeletedMaterials] = useState<number[]>([]);

  const existingMaterials = useMemo<IMaterial[]>(() => {
    if (!expenditure.editingForm) return [];
    return Object.entries(expenditure.editingForm!)
      .map(([id, material]) => {
        if (
          !!(material as any)?.expenditure_type &&
          (material as any)?.expenditure_type !== "work" &&
          !deletedMaterials.includes(+id)
        ) {
          return material as IMaterial;
        }
        return null;
      })
      .filter((el) => !!el) as IMaterial[];
  }, [expenditure, deletedMaterials]);

  const getFieldName = (name: string, id: number) => {
    return `id:${id}.${name}`;
  };

  return (
    <div className={styles.container}>
      <Form
        onSubmit={submit}
        initialValues={prepareExpenditureForEditInHandler(expenditure.editingForm)}
        render={({ values, handleSubmit, form }) => {
          return (
            <form ref={formRef} onSubmit={handleSubmit} onReset={() => form.restart()}>
              <div className={styles.formWrapper} style={{ overflow: isPending ? "hidden" : undefined }}>
                {isPending && (
                  <div className={styles.overlaySpinner}>
                    <Spinner isStatic />
                  </div>
                )}
                <ExpenditureFormRow
                  submit={submit}
                  requiredFields={OUT_OF_ESTIMATE_REQUIRED_FIELDS}
                  onCancel={cancelHandler}
                  onAddNew={() => setNewMaterials((prev) => [...prev, Date.now()])}
                  handleSubmitForm={handleSubmit}
                  isClickCancelDirectly
                  isWork={expenditure.expenditure_type === "work"}
                  form={form as any}
                  expType="work"
                />
                {existingMaterials.map((el) => (
                  <div key={el?.id}>
                    <ExpenditureFormRow
                      submit={() => {}}
                      requiredFields={OUT_OF_ESTIMATE_REQUIRED_FIELDS}
                      onCancel={() => setDeletedMaterials((prev) => [...prev, el?.id!])}
                      isWork={false}
                      id={el?.id}
                      showLabels={false}
                      form={form as any}
                      //@ts-ignore
                      expType={form.getState().values[getFieldName("expenditure_type", el?.id!)] as any}
                    />
                  </div>
                ))}
                {newMaterials.map((el) => (
                  <div key={el}>
                    <ExpenditureFormRow
                      submit={() => {}}
                      requiredFields={OUT_OF_ESTIMATE_REQUIRED_FIELDS}
                      onCancel={() => setNewMaterials((prev) => prev.filter((m) => m !== el))}
                      isWork={false}
                      id={el}
                      showLabels={false}
                      form={form as any}
                      //@ts-ignore
                      expType={form.getState().values[getFieldName("expenditure_type", el?.id!)] as any}
                    />
                  </div>
                ))}
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default EditExpendituresInHandler;
