import SearchFilterTable from 'components/UI/atoms/SearchFilterTable'
import SearchSelectFilter from 'components/UI/atoms/SearchSelectFilter'
import TableReusableHead, { TableReusableHeaderCell } from 'components/UI/atoms/TableReusable/TableReusableHead'
import React from 'react'
import { tasksListFilterType } from 'redux/modules/purchaser/Tasks/types'
import { TASKS_PRIORITY_OPTIONS, TASKS_STATUSES_OPTIONS, TASK_PRIORITY, TASK_STATUSES } from '../../constants'
import styles from "./TaskTableHead.module.scss"
import { memoize } from "lodash";
import cn from "classnames"

interface Iprops {
    onChangeFilters: (name: keyof tasksListFilterType, value: tasksListFilterType[typeof name]) => void;
    className?: string;
}

function TaskTableHead({
    onChangeFilters,
    className
}: Iprops) {
    const setFilter = React.useCallback((key: keyof tasksListFilterType, value: tasksListFilterType[typeof key]) => onChangeFilters(key, value), [onChangeFilters]);
    const partialSetFilter = React.useMemo(() => memoize((key: keyof tasksListFilterType) => (value: tasksListFilterType[typeof key]) => setFilter(key, value)), [setFilter]);
    
    return (
        <TableReusableHead className={cn(styles.head, className)}>
            <TableReusableHeaderCell>
                №
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                Наименование задачи
                <SearchFilterTable title="Наименование задачи" setFilter={partialSetFilter("search")} />
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                Исполнитель
                <SearchFilterTable title="Исполнитель" setFilter={partialSetFilter("executor_name")} />
            </TableReusableHeaderCell>
            <TableReusableHeaderCell isCentered>
                Срок
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                Статус{/* @ts-ignore */}
                <SearchSelectFilter title="Статус" filterName={TASKS_STATUSES_OPTIONS} setFilter={partialSetFilter("status")} />
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                Приоритет{/* @ts-ignore */}
                <SearchSelectFilter title="Приоритет" filterName={TASKS_PRIORITY_OPTIONS} setFilter={partialSetFilter("priority")}/>
            </TableReusableHeaderCell>
            <div />
            <div />
        </TableReusableHead>
    )
}

export default React.memo(TaskTableHead)