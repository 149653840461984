import { Moment } from "moment";
import React from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";
import DoubleInput from "components/UI/atoms/DoubleInput/DoubleInput";
import { Measure } from "components/UI/atoms/DoubleInput/DoubleInput";
import Modal from "components/UI/atoms/Modal";

import AdvancePaymentItem from "../AdvancePaymentItem/AdvancePaymentItem";
import GuaranteeObligationItem from "../GuaranteeObligationItem/GuaranteeObligationItem";

import { IAdvancePaymentItem, IGuaranteeObligationItem } from "widgets/FinanceSettingsModal/types";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import { ReactComponent as PlusFillCircleIcon } from "shared/assets/icons/Icon-Plus.svg";

import styles from "./FinanceSettingsModalContainer.module.scss";

interface IFinanceSettingsModalContainerProps {
  isModalOpen: boolean;
  closeModalAndClearStates: () => void;
  guaranteeObligation: IGuaranteeObligationItem[];
  handleDeleteItem: (id: number) => void;
  handleChangeAmountValue: (value: string, id: number) => void;
  handleChangePercentValue: (value: string, id: number) => void;
  handleChangeDelay: (value: string, id: number) => void;
  handleSelectSubmit: (optionId: string | number, id: number) => void;
  handleAddItem: () => void;
  sumGuarantee: number;
  advancePaymentPercent: string;
  advancePaymentAmount: string;
  handleChangeAdvancePaymentAmount: (value: string) => void;
  handleChangeAdvancePaymentPercent: (value: string) => void;
  advancePaymentItem: IAdvancePaymentItem[];
  sumAdvance: number;
  handleChangeAdvancePaymentAmountItem: (value: string, id: number) => void;
  handleAddAdvancePaymentItem: () => void;
  handleDeleteAdvancePaymentItem: (id: number) => void;
  handleChangeAdvancePaymentDateItem: (value: Moment, id: number) => void;
  handleSubmit: () => void;
  isShowButtons: boolean;
  isDisabledSaveButton: boolean;
  handleChangeAdvancePaymentPercentItem: (value: string, id: number) => void;
}

const FinanceSettingsModalContainer = ({
  isModalOpen,
  closeModalAndClearStates,
  guaranteeObligation,
  handleDeleteItem,
  handleChangeAmountValue,
  handleChangePercentValue,
  handleChangeDelay,
  handleSelectSubmit,
  handleAddItem,
  sumGuarantee,
  advancePaymentPercent,
  advancePaymentAmount,
  handleChangeAdvancePaymentAmount,
  handleChangeAdvancePaymentPercent,
  advancePaymentItem,
  sumAdvance,
  handleChangeAdvancePaymentAmountItem,
  handleAddAdvancePaymentItem,
  handleDeleteAdvancePaymentItem,
  handleChangeAdvancePaymentDateItem,
  handleSubmit,
  isShowButtons,
  isDisabledSaveButton,
  handleChangeAdvancePaymentPercentItem,
}: IFinanceSettingsModalContainerProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      title="Настройки финансов"
      onClose={closeModalAndClearStates}
      className={styles.modal}
      headerClassName={styles.title}
      closeOnClickOutside={false}
      darkShadow
      children={
        <>
          <div className={styles.guaranteeObligation}>
            <h3>Гарантийные обязательства</h3>
            <span className={styles.subTitle}>По условиям договора</span>
            {guaranteeObligation.length
              ? guaranteeObligation.map((el) => (
                  <GuaranteeObligationItem
                    key={el.id}
                    id={el.id}
                    amount={el.amount}
                    percent={el.percent}
                    type={el.type}
                    delay={el.delay}
                    deleteItem={() => handleDeleteItem(el.id)}
                    handleChangeAmountValue={handleChangeAmountValue}
                    handleChangePercentValue={handleChangePercentValue}
                    handleChangeDelay={handleChangeDelay}
                    handleSelectSubmit={handleSelectSubmit}
                  />
                ))
              : null}
            <div className={styles.addBtn} onClick={handleAddItem}>
              Добавить&nbsp;&nbsp;&nbsp;
              <PlusFillCircleIcon />
            </div>
            {guaranteeObligation.length ? (
              <div className={styles.flex}>
                <span className={styles.subTitle}>Итого гарантийных обязательств:</span>
                <div>
                  <span className={styles.amount}>{transformDigitToFinancial(sumGuarantee)}</span>
                  <span className={styles.measure}>{` ${Measure.currency}`}</span>
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.sector}>
            <div>
              <h3>Авансирование на сумму:</h3>
              <span className={styles.subTitle}>По условиям договора</span>
            </div>
            <DoubleInput
              firstValueMeasure={Measure.percent}
              firstInputSize={1}
              secondValueMeasure={Measure.currency}
              secondValue={transformDigitToFinancial(advancePaymentAmount, {
                withFloat: true,
                dropZeros: true,
              })}
              firstValue={advancePaymentPercent}
              secondInputSize={2}
              onChangeFirstValue={handleChangeAdvancePaymentPercent}
              onChangeSecondValue={(v) => handleChangeAdvancePaymentAmount(v.replaceAll(" ", ""))}
              variant={"outline"}
              className={styles.doubleInput}
            />
          </div>
          {advancePaymentItem.length !== 0 && (
            <div className={styles.advancePaymentChart}>
              <div className={styles.flex}>
                <h4>График авансовых платежей:</h4>
                {sumAdvance > +advancePaymentAmount && (
                  <span className={styles.warning}>Выплаты превышают авансирование</span>
                )}
              </div>
              {advancePaymentItem.map((el, index) => (
                <AdvancePaymentItem
                  key={el.id}
                  index={index}
                  id={el.id}
                  amount={el.amount}
                  percent={el.percent}
                  date={el.date}
                  handleChangeAdvancePaymentAmountItem={handleChangeAdvancePaymentAmountItem}
                  handleAddAdvancePaymentItem={handleAddAdvancePaymentItem}
                  deleteItem={handleDeleteAdvancePaymentItem}
                  handleChangeAdvancePaymentDateItem={handleChangeAdvancePaymentDateItem}
                  handleChangeAdvancePaymentPercentItem={handleChangeAdvancePaymentPercentItem}
                />
              ))}
            </div>
          )}
          {isShowButtons ? (
            <div className={styles.buttons}>
              <ButtonBase secondary onClick={closeModalAndClearStates} className={styles.btn}>
                Отмена
              </ButtonBase>
              <ButtonBase primary onClick={handleSubmit} className={styles.btn} disabled={isDisabledSaveButton}>
                Сохранить
              </ButtonBase>
            </div>
          ) : null}
        </>
      }
    />
  );
};

export default React.memo(FinanceSettingsModalContainer);
