import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { summaryTableHighlightingSelector } from "redux/modules/common/constructing/finance/summary/selectors";

import valueInThousandsOrMillions from "../../../../../../../../utils/formatters/valueInThousandsOrMillions";

import styles from "./styles.module.scss";

const MoneyCell = React.memo((props) => {
  const { data, measure = 1000, isDiff } = props;
  const tableHighlighting = useSelector(summaryTableHighlightingSelector) || {};

  return (
    <div>
      <span
        className={cn(styles.moneyCell__text, props.className, {
          [styles.negative]: isDiff && tableHighlighting.negative && data < 0,
          [styles.positive]: isDiff && tableHighlighting.positive && data > 0,
        })}
        title={valueInThousandsOrMillions(data, measure)}
      >
        {valueInThousandsOrMillions(data, measure)}
      </span>
    </div>
  );
});

const renderMoneyCell = (data, measure, isDiff, className) => {
  return <MoneyCell data={data} measure={measure} isDiff={isDiff} className={className} />;
};

export default renderMoneyCell;
