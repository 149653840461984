import { Dispatch } from "redux";

import { extraForecastActions } from "components/pages/Constructing/Finance/widgets/AddExtraExpenseModal/model/extraForecastSlice";

import { RootState } from "../../../../../rootReducer";
import { prepareProjectV2FinanceTotals, setSubtreeIsOpen } from "../financeCommonUtils";
import { objectIdSelector } from "../summary/selectors";
import { forecastActions } from "./actions";
import { forecastApi } from "./forecastApi";
import { allProjectsForecastsListSelector, detailedProjectForecastsSelector, forecastTreeSelector } from "./selectors";

import { ISummaryInitialState } from "../summary/types";
import { IForecastInitialState, IForecastTree } from "./types";
import { IIdAndName } from "types/idAndName";

import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";
import { produceMapForecastParentProjectFn } from "./utils";

export const syncForecastsTree =
  (objectId?: number, projectId?: string | number) => (dispatch: Dispatch, getState: () => RootState) => {
    const allProjectsForecastsList = allProjectsForecastsListSelector(
      getState()
    ) as IForecastInitialState["allProjectsForecastsList"];
    const detailedProjectForecasts = detailedProjectForecastsSelector(
      getState()
    ) as IForecastInitialState["detailedProjectForecasts"];

    const pageObjectId = objectIdSelector(getState());
    const prevTree = forecastTreeSelector(getState()) as IForecastTree[];

    const mapProject = produceMapForecastParentProjectFn(
      detailedProjectForecasts,
      +(objectId || (pageObjectId as number) || -1),
      projectId as any
    );

    if (!prevTree?.length) {
      const tree = allProjectsForecastsList?.map(mapProject);
      dispatch(forecastActions.setForecastsTree(tree));
    } else {
      if (projectId && objectId) {
        const tree = prevTree.map((projectTree) => {
          if (projectTree.id !== +projectId) return projectTree;
          const updateCandidate = allProjectsForecastsList.find((p) => p.id === +projectId);
          if (!updateCandidate) return projectTree;
          return mapProject(updateCandidate);
        });
        dispatch(forecastActions.setForecastsTree(tree));
        return;
      }
      if (!objectId) return;
      const tree = prevTree.map((projectTree) => {
        if (projectTree.id !== +objectId) return projectTree;
        const updateCandidate = allProjectsForecastsList.find((p) => p.id === +objectId);
        if (!updateCandidate) return projectTree;
        return mapProject(updateCandidate);
      });
      dispatch(forecastActions.setForecastsTree(tree));
    }
  };

export const loadAllProjectsForecasts = () => (dispatch: Dispatch) => {
  dispatch(forecastActions.setLoading(true));
  forecastApi
    .getProjectsViewForecast()
    .then(({ data }) => {
      dispatch(forecastActions.setAllProjectsForecastsList(data));
      if (data.length === 1) {
        dispatch(loadDetailedProjectForecast(data[0].id));
      } else {
        dispatch(syncForecastsTree());
      }
    })
    .catch(errorCatcher)
    .finally(() => dispatch(forecastActions.setLoading(false)));
};

export const loadDetailedProjectForecast =
  (objectId: number, projectId?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    //dispatch(forecastActions.setLoading(true));
    if (getState().constructing.finance.forecast.isLoadingByObjects[objectId] === undefined) {
      dispatch(forecastActions.setIsLoadingByObject(true, objectId));
    }
    return forecastApi
      .getDetailedProjectForecast(objectId)
      .then(({ data }) => {
        dispatch(forecastActions.setDetailedProjectForecast(objectId, data));
        dispatch(syncForecastsTree(objectId, projectId));
        dispatch(extraForecastActions.setExtraExpense({ key: String(objectId), data: data.extra_costs }));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(forecastActions.setIsLoadingByObject(false, objectId));
        //dispatch(forecastActions.setLoading(false));
      });
  };

export const toggleOpenForecastTree =
  (subTree: IForecastTree) => async (dispatch: Dispatch, getState: () => RootState) => {
    const detailedProjectForecasts = detailedProjectForecastsSelector(
      getState()
    ) as IForecastInitialState["detailedProjectForecasts"];
    const allProjectsSummaryList = allProjectsForecastsListSelector(
      getState()
    ) as IForecastInitialState["allProjectsForecastsList"];
    if (subTree.isRoot && !detailedProjectForecasts[subTree.id]) {
      const projectId = allProjectsSummaryList?.find((pr) => pr.buildings.some((b) => b.id === subTree.id))?.id;
      await dispatch(loadDetailedProjectForecast(subTree.id, projectId));
      return;
    }
    const prevTree = forecastTreeSelector(getState()) as IForecastTree[];
    const prevTreeCopy = Object.assign([], prevTree);
    setSubtreeIsOpen(prevTreeCopy, subTree, !subTree.isOpen);
    dispatch(forecastActions.setForecastsTree(prevTreeCopy));
  };

export const downloadForecastReport = (buildigId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const buildingName = getState().objects.objectsBreadcrumbs?.results?.find(
    (el: IIdAndName) => el.id == buildigId
  )?.name;
  const reportName = buildingName ? `Прогноз по ${buildingName}.xlsx` : "Прогноз.xlsx";
  forecastApi.downloadReport(buildigId, reportName);
};

export const downloadForecastBdr =
  (buildingId: number, dateStart: string, dateEnd: string) => (dispatch: Dispatch, getState: () => RootState) => {
    let reportName = `БДР ${dateStart}-${dateEnd}.xlsx`;
    if (buildingId) {
      const buildingName = getState().objects.objectsBreadcrumbs?.results?.find(
        (el: IIdAndName) => el.id == buildingId
      )?.name;
      if (buildingName) {
        reportName = `БДР по ${buildingName} ${dateStart}-${dateEnd}.xlsx`;
      }
    }

    let buildingIds: number[];
    if (buildingId) {
      buildingIds = [buildingId];
    } else {
      buildingIds = getState().objects.objectsBreadcrumbs?.results?.map((el: IIdAndName) => el.id);
    }

    return forecastApi.downloadBDR(buildingIds, dateStart, dateEnd, reportName);
  };

export const getForecastDetailedProjectV2 =
  (projectId: string | number, objectId: string | number) => (dispatch: Dispatch, getState: () => RootState) => {
    /// TODO loading by projects
    if (getState().constructing.finance.forecast.isLoadingByObjects[projectId] === undefined) {
      dispatch(forecastActions.setIsLoadingByObject(true, +projectId));
    }

    return forecastApi
      .getDetailedProjectSummaryV2(+projectId)
      .then(({ data }) => {
        dispatch(forecastActions.setDetailedByProjectV2(String(projectId), data[0]));
        if (data[0].m_data && data[0].q_data) {
          dispatch(forecastActions.setProjectsV2Totals(prepareProjectV2FinanceTotals(data[0]), projectId as string));
        }
        if (data[0]?.buildings.length === 1) {
          dispatch(loadDetailedProjectForecast(data[0].buildings[0].id) as any);
        }
        if (!objectId || +objectId === 0) {
          dispatch(syncForecastsTree(-1, projectId) as any);
        }
      })
      .catch(errorCatcher)
      .finally(() => {
        // dispatch(auditActions.setIsLoadingByObject(false, +projectId));
      });
  };
