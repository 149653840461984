import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { deleteExpenditure, updateExpenditure } from "redux/modules/common/building/materials/specification";

import MatchingModal from "../../../../../../UI/atoms/MatchingModal/MatchingModal";
import ConfirmationModal from "../../../../../../UI/molecules/ConfirmationModal/ConfirmModal";
import ProductFilesModal from "../../../../../Requisition/components/ProductFilesModal/ProductFilesModal";
import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import { getMatchingType } from "components/UI/atoms/MatchingModal/utils";
import AddRelationToProduct from "components/UI/organism/AddRelationToProduct";
import ProductInfo from "components/UI/organism/ProductInfo";

import AddFile from "../../../AddFile";

import { LOCALIZATION_CONFIG } from "constants/localization";

import CheckFile from "images/icons/CheckFile";
import Delete from "images/icons/Delete";

import styles from "./Expenditure.module.scss";
import {
  parseTransformedString,
  transformDigitToFinancial
} from "../../../../../../../utils/formatters/transformDigitToFinancial";

const Expenditure = ({ data, objectId, typeUrl, canEdit }) => {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [countValue, setCountValue] = useState("count");
  const [priceValue, setPriceValue] = useState("price");
  const [expenditure, setExpenditure] = useState(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [idAddRelation, setIdAddRelation] = useState(false);
  const [sliderAddFile, setSliderAddFile] = useState(false);

  const countUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, typeUrl, id, { count: data }));
  };
  const priceUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, typeUrl, id, { price: data }));
  };
  const handleRemoveExpenditure = (id) => {
    dispatch(deleteExpenditure(objectId, typeUrl, id));
    setIsDeleteModalOpen(false);
  };

  const closeConfirmationModal = () => {
    setIsDeleteModalOpen(false);
  };

  const nameBlock = (cell, row) => {
    return typeUrl === "specification" ? (
      <div>
        <span style={styles.quontityCell}>{cell}</span>
        <div className={styles.labelBox}>
          <div className={styles.blueLabel}>Тип: {row?.brand}</div>
          {/* <ProductFilesModal
            files={row.files}
            permissions={{
              addFiles: canEdit,
              deleteFiles: canEdit,
            }}
            buildingId={objectId}
            itemId={row.id}
            productName={cell}
            expenditureId={row?.estimate_expenditure_id}
            isV2
            expenditureType={row?.estimate_expenditure?.expenditure_type}
          /> */}
        </div>
      </div>
    ) : (
      <div className={styles.contentBox}>
        <div className={styles.box1}>
          <span>{cell}</span>
          <div className={styles.blueLabel}>Тип: {row?.brand}</div>
        </div>

        {/* <ProductFilesModal
          files={row.files}
          permissions={{
            addFiles: canEdit,
            deleteFiles: canEdit,
          }}
          buildingId={objectId}
          itemId={row.id}
          productName={cell}
          expenditureId={row?.estimate_expenditure_id}
          isV2
          expenditureType={row?.estimate_expenditure?.expenditure_type}
        /> */}
      </div>
    );
  };
  const countBlock = (cell, row) => {
    return (
      <input
        disabled={!canEdit}
        className={styles.input}
        value={transformDigitToFinancial(cell, {withFloat: true, dropZeros: true})}
        onBlur={(e) => {
          countUpdate(row.id, transformDigitToFinancial(parseTransformedString(e.target.value), {
            withFloat: true,
            dropZeros: true
          }));
        }}
        onChange={(e) => {
          const value = transformDigitToFinancial(parseTransformedString(e.target.value), {
            withFloat: true,
            dropZeros: true
          });
          e.target.value = value
          setCountValue(value);
        }}
      />
    );
  };
  const priceBlock = (cell, row) => {
    return (
      <div className={styles.inputBox}>
        <input
          disabled={!canEdit}
          className={styles.input}
          value={transformDigitToFinancial(parseTransformedString(cell), {
            withFloat: true,
            dropZeros: true
          })}
          onBlur={(e) => {
            priceUpdate(row.id, transformDigitToFinancial(parseTransformedString(e.target.value), {
              withFloat: true,
              dropZeros: true
            }))
          }}
          onChange={(e) => {
            const value = transformDigitToFinancial(parseTransformedString(e.target.value), {
              withFloat: true,
              dropZeros: true
            });
            e.target.value = value;
            setPriceValue(value);
          }}
        />
        {typeUrl === "specification" && (
          <MatchingModal
            info={row}
            type={getMatchingType(row.type)}
            updateProducts={setExpenditure}
            objectId={setIdAddRelation}
          />
        )}
        {canEdit && (
          <div
            className={styles.checkFile}
            onClick={() => {
              setExpenditure(row);
              setIsDeleteModalOpen(true);
            }}
          >
            <Delete />
          </div>
        )}
      </div>
    );
  };

  const mimsNumberFormatter = (_, row) => row?.estimate_expenditure?.number;

  return (
    <div className={styles.tableWrapper}>
      <TableOslaLocal
        data={data}
        pagination={false}
        rowClass={styles.tableRowClass}
        headerClass={styles.tableHeaderClass}
      >
        <TableHeader
          style={{ width: "2%", textAlign: "center", paddingLeft: "1.25rem" }}
          cellStyle={{ textAlign: "center", width: "2%", paddingLeft: "1.25rem" }}
          data={"number"}
          formatter={mimsNumberFormatter}
        >
          <div className={styles.tableTextBold}>№</div>
        </TableHeader>
        <TableHeader
          style={{ width: "50%", textAlign: "left", padding: "0 3.88rem" }}
          cellStyle={{
            textAlign: "left",
            width: "50%",
            padding: "1rem 3.88rem",
          }}
          data={"name"}
          formatter={nameBlock}
        >
          <div className={styles.tableTextBold}>Наименование</div>
        </TableHeader>
        <TableHeader
          data={"measure"}
          style={{ width: "15%", textAlign: "center" }}
          cellStyle={{ textAlign: "center", width: "15%" }}
        >
          <div className={styles.tableTextBold}>Ед. изм.</div>
        </TableHeader>
        <TableHeader
          data={countValue}
          formatter={countBlock}
          style={{ width: "10%", textAlign: "left" }}
          cellStyle={{ textAlign: "left", width: "10%" }}
        >
          <div className={styles.tableTextBold}>Количество</div>
        </TableHeader>
        <TableHeader
          data={priceValue}
          formatter={priceBlock}
          style={{ width: "15%", textAlign: "left" }}
          cellStyle={{ textAlign: "left", width: "15%" }}
        >
          <div className={styles.tableTextBold}>Цена&nbsp;{LOCALIZATION_CONFIG.currency}</div>
        </TableHeader>
      </TableOslaLocal>
      {isDeleteModalOpen && (
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={closeConfirmationModal}
          action={() => handleRemoveExpenditure(expenditure.id)}
        >
          Удалить {expenditure.name}?
        </ConfirmationModal>
      )}
      <AddRelationToProduct
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(false)}
        objectId={objectId}
        editable={idAddRelation.editable}
      />
      <ProductInfo
        isOpen={isInfoModalOpen}
        closeModal={() => setIsInfoModalOpen(false)}
        objectId={objectId}
        chosenMatchingProduct={expenditure}
        openRelationToProduct={() => {
          setIdAddRelation({ id: isInfoModalOpen?.id });
        }}
      />
      <AddFile
        objectId={objectId}
        isOpen={sliderAddFile}
        closeFunModal={() => setSliderAddFile(false)}
        obj={expenditure}
      />
    </div>
  );
};

export default Expenditure;

const FileComponent = ({ cell, setIdAddRelation, setIsInfoModalOpen, setExpenditure }) => {
  return (
    <div>
      {cell.estimate_expenditure && cell?.created_from_estimate && (
        <span
          onClick={() => {
            setExpenditure(cell);
            setIsInfoModalOpen({ id: cell.id, editable: true });
          }}
          className={styles.checkFile}
        >
          <CheckFile className={styles.svgMargin} height={20} width={18} />
        </span>
      )}
      {cell?.estimate_expenditure && !cell?.created_from_estimate && (
        <span
          onClick={() => {
            setExpenditure(cell);
            setIsInfoModalOpen({ id: cell.id });
          }}
          className={styles.checkFile}
        >
          <CheckFile className={styles.svgMargin} height={20} width={18} color="#00F241" />
        </span>
      )}
      {!cell?.estimate_expenditure && (
        <div
          className={styles.checkFile}
          onClick={() => {
            setExpenditure(cell);
            setIdAddRelation({ id: cell.id });
          }}
        >
          <CheckFile className={styles.svgMargin} height={20} width={18} color="#000" />
        </div>
      )}
    </div>
  );
};
