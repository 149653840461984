import axios from "axios";

import { IAuditInitialState, IDetailedProjectAudit, IProjectViewAudit, IProjectViewAuditV2 } from "./types";

export const auditApi = {
  getProjectsViewAudit: (dates: IAuditInitialState["dates"], auditType: IAuditInitialState["auditType"]) => {
    const params = {
      start_at: dates.start,
      end_at: dates.end,
    };
    if (auditType === "forecast") {
      return axios.get<IProjectViewAudit[]>(`/tree/finance_forecasts_audit/`, {
        params,
      });
    }
    return axios.get<IProjectViewAudit[]>(`/tree/finance_audit/`, {
      params,
    });
  },
  getDetailedProjectAudit: (
    objectId: number,
    dates: IAuditInitialState["dates"],
    auditType: IAuditInitialState["auditType"]
  ) => {
    const params = {
      start_at: dates.start,
      end_at: dates.end,
    };
    if (auditType === "forecast") {
      return axios.get<IProjectViewAudit[]>(`/tree/finance_forecasts_audit/${objectId}/`, {
        params,
      });
    }
    return axios.get<IDetailedProjectAudit>(`/tree/finance_audit/${objectId}/`, {
      params,
    });
  },

  getProjectsAuditV2: (
    dates: IAuditInitialState["dates"],
    auditType: IAuditInitialState["auditType"],
    projectId?: string | number
  ) => {
    const params: Record<string, any> = {
      start_at: dates.start,
      end_at: dates.end,
    };
    if (projectId && +projectId !== 0) {
      params.project_id = projectId;
    }
    if (auditType === "forecast") {
      return axios.get<IProjectViewAuditV2[]>(`/tree/projects/finance_forecasts_audit/`, {
        params,
      });
    }
    return axios.get<IProjectViewAuditV2[]>(`/tree/projects/finance_audit/`, {
      params,
    });
  },
};
