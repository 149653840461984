import cn from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import InputBase from "../../../../../../UI/atoms/InputBase";
import InputSelect from "../../../../../../UI/atoms/InputSelect";
import Calendar from "../../../../../../UI/molecules/Calendar/Calendar";
import Select from "components/UI/atoms/Select";

import PriorityRange from "../../../PriorityRange/PriorityRange";
import TasksPersonsSelect from "../../../TaskPersonsSelect/TasksPersonsSelect";

import { IdNameLabelType } from "../../../../../../../types/IdNameLabelType";
import { TASKS_STATUS_OPTIONS } from "../../../../constants";

import { useTaskObjectsSectionsUsers } from "../../../../hooks/useTaskObjectsSectionsUsers";

import {
  composeFieldValidators,
  maxLength,
  minLength,
  required,
} from "../../../../../../../utils/formHelpers/validations";

import selectStyles from "../../../TaskPersonsSelect/TasksPersonsSelect.module.scss";
import styles from "../ModalAddTask/ModalAddTask.module.scss";

const ModalEditTaskMainFields: React.FC<any> = ({
  building,
  section_id,
  setSectionId,
  executor,
  setExecutor,
  viewers,
  setViewers,
  isVisible = true,
  isTaskStatusEditable = true,
}) => {
  const { section, users } = useTaskObjectsSectionsUsers({ building_id: building.id });

  useEffect(() => {
    if (!section.find((x) => x.id === section_id)) {
      setSectionId(undefined);
    }
  }, [section]);

  return (
    <div
      className={cn(styles.fields, {
        [styles.isVisible]: isVisible,
      })}
    >
      <Field
        name={"name"}
        label={"*Название"}
        placeholder={"Название новой задачи"}
        /* @ts-ignore */
        component={InputBase}
        validate={composeFieldValidators(
          required(),
          maxLength(255, "Не более 255 символов"),
          minLength(1, "Введите название задачи")
        )}
        className={styles.outView}
      />
      <Field
        name={"description"}
        validate={maxLength(255, "Не более 255 символов")}
        render={({ input, meta }) => (
          <div className={styles.outView}>
            <label className={styles.label}>Описание задачи</label>
            <TextareaAutosize {...input} placeholder={"Описание задачи"} minRows={3} />
            {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
          </div>
        )}
      />
      <div className={styles.pair}>
        <Field
          name={"deadline"}
          validate={required()}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <label className={styles.label}>*Контрольная дата</label>
              <Calendar
                value={input.value || meta.initial}
                setValue={input.onChange}
                classNameSelect={""}
                classNameOptions={styles.calendarOptions}
                label={input.label}
                placeholder={moment().format("DD/MM/YY")}
                format={"DD/MM/YY"}
              />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </div>
          )}
        />
        <Field
          name={"priority"}
          render={({ input, meta }) => (
            <>
              <PriorityRange
                onChange={input.onChange}
                value={input.value}
                defaultValue={meta.initial}
                className={styles.range}
                label={"*Приоритет:"}
              />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </>
          )}
        />
      </div>
      <Field
        name={"section"}
        value={section_id}
        render={({ input, meta }) => (
          <div className={styles.outView}>
            <InputSelect
              className={styles.fieldInput}
              optionsBlockClassName={styles.options}
              name={"section"}
              label={"Раздел"}
              placeholder={"Введите или укажите наименование раздела"}
              meta={meta}
              defaultValue={section.find((x) => x.id === section_id)?.name}
              selectedAction={undefined}
              input={{
                ...input,
                onChange: (value: IdNameLabelType) => {
                  input.onChange(value);
                  setSectionId(value);
                },
                value: section.find((x) => x.id === section_id)?.id,
              }}
              onClick={setSectionId}
              options={section}
            />
          </div>
        )}
      />
      <Field
        name={"executor"}
        validate={required()}
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"executor"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
                setExecutor(value);
              },
              value: executor,
            }}
            options={users}
            label={"*Исполнители"}
            placeholder={"Введите или укажите ФИО исполнителя"}
            meta={meta}
          />
        )}
      />
      <Field
        name={"viewers"}
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"viewers"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
                setViewers(value);
              },
              value: viewers,
            }}
            options={users}
            label={"Контролеры"}
            placeholder={"Введите или укажите ФИО контролера"}
            meta={meta}
          />
        )}
      />
      <Field
        name={"status"}
        render={({ input, meta }) => (
          <div className={styles.outView}>
            <Select
              options={TASKS_STATUS_OPTIONS}
              value={input.value}
              color={undefined}
              size={undefined}
              labelColor={undefined}
              onChange={input.onChange}
              disabled={!isTaskStatusEditable}
              className={undefined}
              selectClassName={selectStyles.select}
              label={"Статус задачи"}
              meta={meta}
              onOpen={undefined}
              onClose={undefined}
            />
          </div>
        )}
      />
    </div>
  );
};

export default React.memo(ModalEditTaskMainFields);
