import { Upload, message } from "antd";
import axios from "axios";
import cn from "classnames";
import React, { useState } from "react";

import PopoverOverlay from "../molecules/PopoverOverlay/PopoverOverlay";
import Radio from "components/UI/atoms/Radio/Radio";

import ButtonBase from "../atoms/ButtonBase";

import styles from "./ExcelProductsUpload.module.scss";

export const CREATE_NEW_POSITION = "create_new";
export const REPLACE_EXISTING = "merge";

const ExcelProductsUpload = ({
  buttonText = "Выбрать файл для импорта",
  disabled,
  showUploadList,
  className,
  api,
  loadImportList = null,
  postCallback = null,
  id = null,
  noMessage = true,
  multiple = false,
  apiMethod = "post",
  excelValidation,
  isUploading = false,
  beginUploading,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [activeDownloadType, setActiveDownloadType] = useState(CREATE_NEW_POSITION);

  const handleRequest = ({ onSuccess, onError, file }) => {
    if (fileCheck(file)) {
      const formData = new FormData();
      if (multiple) {
        formData.set("files", file);
      } else {
        formData.set("file", file);
      }
      formData.append("merging_strategy", activeDownloadType);

      if (beginUploading) beginUploading();

      axios[apiMethod](api, formData, { timeout: 1000 * 120 })
        .then((res) => {
          setIsOpenPopover(false);
          onSuccess();
          !noMessage && message.success(`${file.name} успешно загружен`);
          loadImportList?.(id);
          postCallback?.(res);
        })
        .catch((err) => {
          onError();
          message.error(`Ошибка загрузки`);
        });
    }
  };

  const fileCheck = (file) => {
    if (!excelValidation) return true;
    const ext = file.name.split(".").pop();
    if (ext === "xls" || ext === "xlsx" || ext === "xlsb" || ext === "gge" || ext === "xml" || ext === "kenml") {
      return true;
    } else {
      message.error("Неверное расширение файла. Допустимые расширения: xls, xlsx, xlsb, gge, xml, kenml.");
    }
  };

  return (
    <Upload
      name="file"
      customRequest={handleRequest}
      fileList={fileList}
      disabled={disabled || isUploading}
      className="ant-upload-no-style"
      showUploadList={showUploadList !== undefined ? showUploadList : true}
      multiple={multiple}
    >
      <ButtonBase className={className} isLoading={isUploading} primary>
        Выберите файл
      </ButtonBase>
    </Upload>
  );

  return (
    <PopoverOverlay
      isOpen={isOpenPopover && !isUploading}
      onInteraction={(event) => setIsOpenPopover(event)}
      content={
        <div className={styles.container}>
          <header className={styles.header}>
            <h1>Выберите тип обновления</h1>
          </header>
          <div className={styles.divider} />
          <section className={styles.section}>
            <div
              onClick={() => setActiveDownloadType(CREATE_NEW_POSITION)}
              className={cn(styles.item, styles.flexRow, {
                [styles.active]: activeDownloadType === CREATE_NEW_POSITION,
              })}
            >
              <Radio isActive={activeDownloadType === CREATE_NEW_POSITION} />
              <span className={styles.radioName}>Создавать новые позиции</span>
            </div>
            <div
              onClick={() => setActiveDownloadType(REPLACE_EXISTING)}
              className={cn(styles.item, styles.flexRow, {
                [styles.active]: activeDownloadType === REPLACE_EXISTING,
              })}
            >
              <Radio isActive={activeDownloadType === REPLACE_EXISTING} />
              <span className={styles.radioName}>Заменять существующие</span>
            </div>
          </section>
          <footer className={styles.buttons}>
            <Upload
              name="file"
              customRequest={handleRequest}
              fileList={fileList}
              disabled={disabled || isUploading}
              className="ant-upload-no-style"
              showUploadList={showUploadList !== undefined ? showUploadList : true}
              multiple={multiple}
            >
              <ButtonBase className={className} isLoading={isUploading} primary>
                Выберите файл
              </ButtonBase>
            </Upload>
          </footer>
        </div>
      }
      placement={"bottom"}
      popoverBorderColor={"primary"}
    >
      <ButtonBase className={className} primary isLoading={isUploading}>
        {buttonText}
      </ButtonBase>
    </PopoverOverlay>
  );
};

export default React.memo(ExcelProductsUpload);
