import { Table as AntTable } from "antd";
import React, { PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "components/UI/Spinner/Spinner";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import ListSide from "./ListSide/ListSide";
import SummaryHeadline from "./SummaryHeadline/SummaryHeadline";
import TableSide from "./TableSide/TableSide";
import { useSummaryData } from "./useSummaryData";
import { useSummaryScroll } from "./useSummaryScroll";

import { MonthAndQuarterArray, MonthArray } from "constants/constant";
import { LOCALIZATION_CONFIG } from "constants/localization";

import { isFinanceFooter, isQuartalColumns } from "../_internal/utils";

import financeIcon from "images/icons/navigation/finance.svg";

import styles from "./Summary.module.scss";

const { Column, ColumnGroup } = AntTable;

const Summary: React.FC<PropsWithChildren> = ({ children }) => {
  const objectId = useObjectId(3);
  const { projectId } = useParams<Record<string, string>>();
  const { measure, measureChangeHandler, measureInTitle } = useFinanceMeasure();
  const { displayedTree, isLoading, isFirstLoad, isLoadingByObject } = useSummaryData();
  const { tableRef, year } = useSummaryScroll();

  if (isLoading || isLoadingByObject) return <Spinner />;

  if ((displayedTree === undefined || !displayedTree?.length) && !isLoading)
    return <EmptyPlaceholder img={financeIcon} />;

  return (
    <div className={styles.summary}>
      <div className={styles.summary__listSide}>
        <ListSide>{children}</ListSide>
      </div>
      <div className={styles.summary__tableSide}>
        <div className={styles.sticky} ref={tableRef}>
          <SummaryHeadline measure={measure} onMeasureChange={measureChangeHandler} location="summary" />
          <AntTable dataSource={[]} size="small" scroll={{ x: 1300 }} pagination={false}>
            {isQuartalColumns(objectId, projectId)
              ? MonthAndQuarterArray.map((month, idx) => (
                  <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                    <Column
                      title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`plan_${month.id}`}
                      key={`plan_${month.id}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`fact_${month.id}`}
                      key={`fact_${month.id}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`diff_${month.id}`}
                      key={`diff_${month.id}`}
                      width={116}
                      ellipsis
                    />
                  </ColumnGroup>
                ))
              : MonthArray.map((month, idx) => (
                  <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                    <Column
                      title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`plan_${idx}`}
                      key={`plan_${idx}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`fact_${idx}`}
                      key={`fact_${idx}`}
                      width={116}
                      ellipsis
                    />
                    <Column
                      title={`Итого ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                      dataIndex={`diff_${idx}`}
                      key={`diff_${idx}`}
                      width={116}
                      ellipsis
                    />
                  </ColumnGroup>
                ))}
          </AntTable>
        </div>
        <TableSide />
      </div>
    </div>
  );
};

export default Summary;
