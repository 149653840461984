import React from 'react'
import styles from "./ExpenseTableRow.module.scss"
import TableRow from "./../../common/TableRow/TableRow"
import { Spinner } from '../../../../../UI/Spinner/Spinner'
import SearchFilterTable from '../../../../../UI/atoms/SearchFilterTable'
import SearchRangeDateFilter from '../../../../../UI/atoms/SearchRangeDateFilter'
import cn from "classnames"
import ExpenseProduct from './ExpenseProduct/ExpenseProduct'
import { getExpenseSubProducts } from '../../../../../../redux/modules/common/building/stocksApi'

function ExpenseTableRow({ row, measure }) {
    const [products, setProducts] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const openProductsHandler = () => {
        !products?.length && setIsLoading(true)
        getExpenseSubProducts(row?.id)
            .then(resp => setProducts(resp.data.results))
            .finally(() => setIsLoading(false))
    }

    const [filters, setFilters] = React.useState(null)

    const changeFilters = (value, name) => {
        setFilters((prevState) => ({ ...prevState, [name]: value }))
    }

    React.useEffect(() => {
        if (!filters) return
        getExpenseSubProducts(row?.id, filters)
            .then(resp => setProducts(resp.data.results))
    }, [filters])

    const sectionString = React.useMemo(() => {
        return row?.section?.parent?.name + ", " + row?.section?.name
    }, [row])

    return (
        <TableRow
            countChildren={row?.count_usings}
            rowNumber={row?.row_number}
            name={row?.name}
            sectionString={sectionString}
            measureProduct={row?.measure}
            measureMoney={measure}
            count1={row?.count_using}
            amount1={row?.product_using_amount}
            count2={row?.product_production_count}
            amount2={row?.product_production_amount}
            count3={row?.confirm_sum_using_count}
            amount3={row?.confirm_sum_using_amount}
            onOpen={openProductsHandler}
        >

            <div className={styles.content}>
                <div className={styles.tableHeader}>
                    <div className={cn(styles.rowItem, styles.fieldNumber)}>
                        <div className={styles.dot}></div>
                    </div>
                    <div className={cn(styles.rowItem, styles.fieldTitle)}>
                        <div className={styles.title}>Номенклатура</div>
                        <SearchFilterTable
                            title="Номенклатура"
                            setFilter={(value) => {
                                changeFilters(value, 'estimate_item_name')
                            }}
                        />
                    </div>
                    <div className={cn(styles.rowItem, styles.fieldSection, styles.center)}>
                        Выдал
                        <SearchFilterTable
                            title="Выдал"
                            setFilter={(value) => {
                                changeFilters(value, 'responsible_full_name')
                            }}
                        />
                    </div>
                    <div className={cn(styles.rowItem, styles.fieldCount, styles.center)}>
                        Когда
                        <SearchRangeDateFilter
                            title="Когда "
                            setFilterStart={(value) => {
                                changeFilters(
                                    value,
                                    'created_at_gte'
                                )
                            }}
                            setFilterEnd={(value) => {
                                changeFilters(
                                    value,
                                    'created_at_lte'
                                )
                            }}
                        />
                    </div>
                    <div className={cn(styles.rowItem, styles.center)}>
                        Кому
                        <SearchFilterTable
                            title="Кому"
                            setFilter={(value) => {
                                changeFilters(value, 'user_full_name')
                            }}
                        />
                    </div>
                    <div className={cn(styles.rowItem, styles.center)}>
                        Ед. изм
                    </div>
                    <div className={cn(styles.rowItem, styles.center)}>
                        Количество
                    </div>
                </div>
                {isLoading && <Spinner />}
                {products?.map((item) => (
                    <ExpenseProduct data={item} measure={measure} />
                ))}
            </div>
        </TableRow>
    )
}

export default ExpenseTableRow
