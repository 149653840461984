import moment from "moment";

import {
  IBuildingRoot,
  ICountExpenditures,
  IProjectTreeResponse,
} from "../../../redux/modules/common/building/manufacturing/types";
import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import { getNearestYearsMonths } from "redux/modules/common/building/manufacturing/utils";

import { numberSortFunction } from "../../../shared/lib/numberSortFunction/numberSortFunction";
import { extendMoment } from "moment-range";

import {
  ACCEPTED_MATERIAL,
  EQUIPMENT_TAB_ID,
  MATERIALS_TAB_ID,
  MIMES_TAB_ID,
  ON_STOCK_MATERIAL,
  PAYED_MATERIAL,
  PLANS_MATERIAL,
  PURCHASES_MATERIAL,
  RESOURCES_TAB_ID,
  STOCKLESS_MATERIAL,
  TO_PAID_MATERIAL,
  WORKS_TAB_ID,
  enumerateDaysBetweenDates,
} from "./constants";
import {
  ExpandedBranchesType,
  ICurrencyIndicators,
  IDayElement,
  IDiagramMaterial,
  IDiagramMaterialBase,
  IDiagramPlannedSection,
  IDiagramPlannedWork,
  IDiagramPlans,
  IDiagramPlansMaterial,
  IDiagramPlansMim,
  IDiagramSectionsPlan,
  IDiagramWorks,
  IInfoIndicators,
  IInterval,
  IIntervalBase,
  ILoadedChartData,
  IMaterialInterval,
  IMonthArrayElement,
  IProcessedBranch,
  IProcessedBranchElement,
  IProject,
  IProjectIntervalBase,
  IProjectMaterialsDataInterval,
  IProjectPlanInterval,
  IProjectWorkInterval,
  ISpittingTreeElement,
  IWeekIntervalBase,
  ManufacturingTabsType,
  ManufacturingViewType,
  MaterialType,
  MaterialsMapType,
  ProjectEstimateType,
  WorkPlanMapType,
} from "./types";
import { MonthArray } from "constants/constant";

import { dropNonSignificantZeros } from "../../../utils/formatters/dropNonSignificantZeros";
import { sortExpendituresAndGroups } from "../../../utils/sortExpendituresAndGroups";
import isFullHD from "utils/checkers/isFullHD";
import { remToPx } from "utils/remToPx";

export interface IComputeLineYpxProps {
  clientY: number;
  calendarElement: HTMLElement;
  containerElement: HTMLElement;
}

export const computeLineYpx = ({ clientY, calendarElement, containerElement }: IComputeLineYpxProps) => {
  const REM = remToPx(1);
  const pxToDiscreteYPosition = (yValue: number) => Math.max(Math.floor(yValue / (3 * REM)) * 3 * REM, 0);

  const Y = pxToDiscreteYPosition(calendarElement.scrollTop + clientY - containerElement.getBoundingClientRect().top);
  const availableY = pxToDiscreteYPosition(calendarElement.scrollHeight - 6 * REM);

  return Math.min(Y, availableY);
};

export const runningLineMover =
  (runningLineElement: HTMLElement | null, containerElement: HTMLElement | null, calendarElement: HTMLElement | null) =>
  (clientY: number) => {
    if (!containerElement || !calendarElement || !runningLineElement) return;
    runningLineElement.style.top = `${computeLineYpx({
      clientY,
      calendarElement,
      containerElement,
    })}px`;
  };

export const getUnitMultiplier = (chartViewMode: ManufacturingViewType) => {
  if (chartViewMode === MONTH || chartViewMode === WEEK) return isFullHD ? 2.5 : 2.135;
  if (chartViewMode === HALF_MONTH) return isFullHD ? 5 : 4.27;
  if (chartViewMode === YEAR) return isFullHD ? (12.48 * 12) / 52 : (10.636 * 12) / 52;
  return 2.5;
};

export const getDiagramMoment = (year: number | string, month: number) => moment().year(+year).month(month).date(1);

export const getDaysInYear = (year: number | string) => (moment().year(+year).isLeapYear() ? 366 : 365);

export const getWeeksInYear = (year: number | string) => moment().year(+year).weeksInYear();

export const monthMarkers = (startMonthElements: Node[]) => {
  const REM = remToPx(1);
  const startMonth: number[] = [];
  Array.from(startMonthElements).map((item) => {
    startMonth.push((item as HTMLElement).offsetLeft / REM);
  });
  return startMonth;
};

export const getMonthInfo = (monthId: number): IMonthArrayElement => {
  const foundMonth = MonthArray.find((item: IMonthArrayElement) => item.id === monthId);
  if (foundMonth) return foundMonth;
  return MonthArray.find((item: IMonthArrayElement) => item.id === 0)!;
};

export const generateDaysForLine = (
  year: number | string,
  constructionDateStart?: string | null,
  constructionDateEnd?: string | null,
  criticalDates: string[] = []
) => {
  const elements: IDayElement[] = [];
  const data = [];
  const constructionStartMoment = constructionDateStart ? moment(constructionDateStart, "YYYY-MM-DD") : null;
  const constructionEndMoment = constructionDateEnd ? moment(constructionDateEnd, "YYYY-MM-DD") : null;
  const criticalMoments = criticalDates.map((d) => moment(d, "YYYY-MM-DD"));
  for (let i = 0; i < 12; i++) {
    data.push({
      maxDay: moment().year(+year).month(i).daysInMonth(),
      year: year,
      monthNumber: i + 1,
    });
  }

  data.forEach((x) => {
    for (let i = 1; i <= x.maxDay; i++) {
      const localMoment = moment(`${x.year}-${x.monthNumber}-${i}`, "YYYY-MM-DD");
      const dayOfWeek = localMoment.day();

      let isCritical = false;
      if (criticalMoments.some((m) => m.isSame(localMoment))) {
        isCritical = true;
      }

      const isOutOfDates =
        constructionStartMoment && constructionEndMoment
          ? localMoment.isAfter(constructionEndMoment) || localMoment.isBefore(constructionStartMoment)
          : false;

      elements.push({
        day: i,
        weekend: dayOfWeek === 0 || dayOfWeek === 6,
        today: moment().isSame(localMoment, "days"),
        month: x.monthNumber,
        isOutOfDates,
        isCritical,
      });
    }
  });

  return elements;
};

export const generateWeeksForLine = (
  year: number,
  constructionDateStart?: string | null,
  constructionDateEnd?: string | null,
  criticalDates: string[] = []
) => {
  const elements = [];
  const constructionStartMoment = constructionDateStart ? moment(constructionDateStart, "YYYY-MM-DD") : null;
  const constructionEndMoment = constructionDateEnd ? moment(constructionDateEnd, "YYYY-MM-DD") : null;
  const criticalMoments = criticalDates.map((d) => moment(d, "YYYY-MM-DD"));

  for (let i = 1; i <= moment().year(year).weeksInYear(); i++) {
    let localWeek = moment().year(year).week(i);
    let isNewMonth = false;
    if (i === 1) isNewMonth = true;
    if (moment().year(year).week(i).day(0).date() === 1) isNewMonth = true;
    if (moment().year(year).week(i).day(0).date() > moment().year(year).week(i).day(6).date()) isNewMonth = true;

    let criticalDayNumber = null;
    criticalMoments.forEach((m) => {
      if (year === m.year() && i === m.week()) {
        criticalDayNumber = m.weekday() + 1;
      }
    });

    const isOutOfDates =
      constructionStartMoment && constructionEndMoment
        ? localWeek.day(0).isAfter(constructionEndMoment) || localWeek.day(6).isBefore(constructionStartMoment)
        : false;

    elements.push({
      week: localWeek,
      isNewMonth,
      isOutOfDates,
      criticalDayNumber,
    });
  }

  return elements;
};

export const spittingTreeGenerationFn = ({
  actualProjects,
  expandedBranches,
  projectEstimate,
  tab,
  isHideExpenditures,
  isFirstTouch,
}: // showOnlyEstimate
{
  actualProjects: IBuildingRoot[];
  expandedBranches: ExpandedBranchesType;
  projectEstimate: ProjectEstimateType;
  tab: ManufacturingTabsType;
  isHideExpenditures?: boolean;
  isFirstTouch?: boolean;
  // showOnlyEstimate?: boolean;
}) => {
  if (!actualProjects?.length) return [];

  const newSpittingTree: ISpittingTreeElement[] = [];

  actualProjects?.map((project) => {
    if (!shouldShowSectionInTree(project.count_expenditures, tab)) return;
    if (isFirstTouch && actualProjects.length < 10) expandedBranches.add(project.id);
    let projectCountWork = 0;

    const afterProjectAddingLength = newSpittingTree.push({
      id: project.id,
      name: project.name,
      lvl: 1,
      childCount: 0,
      data: project,
      collapsed: !expandedBranches.has(project.id),
      count_work: projectCountWork,
      count_material: project.count_expenditures.material,
      count_mims:
        project.count_expenditures.machine +
        project.count_expenditures.equipment +
        project.count_expenditures.transport,
      count_resources:
        project.count_expenditures.equipment +
        project.count_expenditures.machine +
        project.count_expenditures.transport +
        project.count_expenditures.material,
      count_equipment: project.count_expenditures.equipment,
    });

    if (projectEstimate.has(`${project.id}_${tab}`)) {
      const projectTree: IProjectTreeResponse = projectEstimate.get(`${project.id}_${tab}`);
      if (expandedBranches.has(project.id)) {
        projectTree.sections.map((section) => {
          const hasToBeShown =
            shouldShowSectionInTree(section.count_expenditures, tab) && !!section.subsections?.length;
          // && (showOnlyEstimate ? section.is_default === false : section.is_default === true);

          if (hasToBeShown) {
            // const sectionCountWork = showOnlyEstimate
            //   ? section.count_expenditures.estimate?.work
            //   : section.count_expenditures.not_estimate?.work;

            // projectCountWork += sectionCountWork;

            newSpittingTree.push({
              id: section.id,
              name: section.name,
              lvl: 2,
              childCount: section.subsections?.length || 0,
              data: section,
              collapsed:
                actualProjects.length > 1 ? !expandedBranches.has(section.id) : expandedBranches.has(section.id),
              // count_work: sectionCountWork,
              count_work: section.count_expenditures.work,
              count_material: section.count_expenditures.material,
              count_mims:
                section.count_expenditures.machine +
                section.count_expenditures.equipment +
                section.count_expenditures.transport,
              count_resources:
                section.count_expenditures.equipment +
                section.count_expenditures.machine +
                section.count_expenditures.transport +
                section.count_expenditures.material,
              count_equipment: section.count_expenditures.equipment,
            });
          }

          if (actualProjects.length > 1 ? expandedBranches.has(section.id) : !expandedBranches.has(section.id)) {
            if (section.subsections?.length) {
              section.subsections.forEach((subsection) => {
                const hasToBeShown = shouldShowSectionInTree(subsection.count_expenditures, tab);
                // && (showOnlyEstimate ? subsection.is_default === false : section.is_default === true);

                if (!hasToBeShown) return;

                // const expendituresCountInSection =
                //   subsection.expenditures?.length + (tab === WORKS_TAB_ID ? subsection.groups?.length : 0) || 0;

                newSpittingTree.push({
                  id: subsection.id,
                  name: subsection.name,
                  lvl: 3,
                  // childCount: expendituresCountInSection,
                  childCount: subsection.expenditures.length + (tab === WORKS_TAB_ID ? subsection.groups?.length : 0),
                  data: subsection,
                  collapsed: !expandedBranches.has(subsection.id),
                  count_work: subsection.count_expenditures.work,
                  count_material: subsection.count_expenditures.material,
                  count_mims:
                    subsection.count_expenditures.machine +
                    subsection.count_expenditures.equipment +
                    subsection.count_expenditures.transport,
                  count_resources:
                    subsection.count_expenditures.equipment +
                    subsection.count_expenditures.machine +
                    subsection.count_expenditures.transport +
                    subsection.count_expenditures.material,
                  count_equipment: subsection.count_expenditures.equipment,
                  parentId: section.id,
                });

                if (expandedBranches.has(subsection.id) && !isHideExpenditures) {
                  sortManufacturingExpendituresAndGroups(
                    subsection?.expenditures,
                    tab === WORKS_TAB_ID ? subsection?.groups : []
                  )?.map((expenditureOrGroup) => {
                    newSpittingTree.push({
                      id: expenditureOrGroup.id,
                      name: expenditureOrGroup.name,
                      lvl: 4,
                      childCount: 0,
                      data: expenditureOrGroup,
                      collapsed: false,
                      parentId: subsection.id,
                    });
                  });
                }
              });
            }
          }
        });
      }
    }
    newSpittingTree[afterProjectAddingLength - 1].count_work = projectCountWork;
  });
  return newSpittingTree;
};

const accumulateCountIndicators = (
  targetWork: IDiagramWorks | IProjectWorkInterval | null | undefined,
  sourceWork: IDiagramWorks | IProjectWorkInterval | null | undefined
): IDiagramWorks | IProjectWorkInterval | null | undefined => {
  if (!targetWork || !targetWork.count_indicators) return sourceWork;
  if (!sourceWork || !sourceWork.count_indicators) return targetWork;

  return {
    ...targetWork,
    count_indicators: Object.entries(sourceWork.count_indicators).reduce(
      (totalIndicators, [indicatorName, indicatorValue]) => {
        return {
          ...totalIndicators,
          [indicatorName]: dropNonSignificantZeros(
            ((+totalIndicators[indicatorName] || 0) + +indicatorValue).toFixed(4)
          ),
        };
      },
      { ...targetWork.count_indicators }
    ),
  };
};

const accumulateRemarksCount = (
  targetWork: IDiagramWorks | IProjectWorkInterval | null | undefined,
  sourceWork: IDiagramWorks | IProjectWorkInterval | null | undefined
): IDiagramWorks | IProjectWorkInterval | null | undefined => {
  if (!targetWork) return sourceWork;
  if (!sourceWork) return targetWork;
  return {
    ...targetWork,
    totalRemarksCount:
      (targetWork.ticket_remarks_in_work?.length || 0) +
      (targetWork.remarks_in_work?.length || 0) +
      (sourceWork.ticket_remarks_in_work?.length || 0) +
      (sourceWork.remarks_in_work?.length || 0),
  };
};

export const accumulateIntervalCounts = (
  targetWork: IDiagramWorks | IProjectWorkInterval | null | undefined,
  sourceWork: IDiagramWorks | IProjectWorkInterval | null | undefined
): IDiagramWorks | IProjectWorkInterval | null | undefined => {
  let target = accumulateRemarksCount(targetWork, sourceWork);
  return accumulateCountIndicators(target, sourceWork);
};
// Вызывать до обработки планов (чтобы установить plan.type = 'brace' | 'full')
export const processDiagramWork = ({
  work,
  workPlanMap,
  entityId,
}: {
  work: IDiagramWorks | IProjectWorkInterval;
  workPlanMap: WorkPlanMapType;
  entityId: number;
}) => {
  if (!work.start_at || !work.end_at || !entityId) return;
  const dateKey = moment(work.start_at).format("YYYY-MM-DD");
  const ongoingDates = enumerateDaysBetweenDates(moment(work.start_at), moment(work.end_at));
  const intervalUnitLength = ongoingDates.length || 1;
  const currentItems = workPlanMap.get(dateKey);

  ongoingDates.forEach((date) => {
    const existingData = workPlanMap.get(date);
    workPlanMap.set(date, {
      ...existingData,
      [entityId]: { ...(existingData?.[entityId] || { works: [], plans: [] }), hasOngoingWorks: true },
    });
  });

  if (!!currentItems?.[entityId]) {
    workPlanMap.set(dateKey, {
      ...currentItems,
      [entityId]: {
        ...currentItems[entityId],
        works: currentItems[entityId].works.concat([
          {
            start: work.start_at,
            end: work.end_at,
            days: intervalUnitLength,
            data: work,
          },
        ]),
      },
    });
  } else {
    workPlanMap.set(dateKey, {
      ...currentItems,
      [entityId]: {
        plans: [],
        works: [
          {
            start: work.start_at,
            end: work.end_at,
            days: intervalUnitLength,
            data: work,
          },
        ],
      },
    });
  }
};
// Вызывать после обработки всех работ!
export const processDiagramPlan = ({
  plan,
  workPlanMap,
  entityId,
  isSectionPlan,
}: {
  plan: IDiagramPlans | IDiagramSectionsPlan | IProjectPlanInterval | IDiagramPlannedSection;
  workPlanMap: WorkPlanMapType;
  entityId: number;
  isSectionPlan?: boolean;
}) => {
  if (!plan.start_at || !plan.end_at || !entityId) return;
  const dateKey = moment(plan.start_at).format("YYYY-MM-DD");
  const intervalUnitLength = moment(plan.end_at).diff(moment(plan.start_at), "days") + 1;

  const currentItems = workPlanMap.get(dateKey);
  if (!!currentItems?.[entityId]) {
    workPlanMap.set(dateKey, {
      ...currentItems,
      [entityId]: {
        ...currentItems[entityId],
        plans: currentItems[entityId].plans.concat([
          {
            type: currentItems[entityId].works.some((work) => checkPlanOverlapWork(plan, work)) ? "brace" : "full",
            start: plan.start_at,
            end: plan.end_at,
            days: intervalUnitLength,
            data: plan,
            isSectionPlan,
          },
        ]),
      },
    });
  } else {
    workPlanMap.set(dateKey, {
      ...currentItems,
      [entityId]: {
        works: [],
        plans: [
          {
            type: "full",
            start: plan.start_at,
            end: plan.end_at,
            days: intervalUnitLength,
            data: plan,
            isSectionPlan,
          },
        ],
      },
    });
  }
};

export const generateMaterialItemKeys = (element: IDiagramMaterialBase, startDateKey: string) => {
  const itemKey = element.estimate_expenditure_id && `${element.estimate_expenditure_id}_${startDateKey}`;
  const itemSectionKey = element.expenditure_section_id && `${element.expenditure_section_id}_${startDateKey}`;
  const itemParentKey = element.expenditure_parent_id && `${element.expenditure_parent_id}_${startDateKey}`;
  return { itemKey, itemSectionKey, itemParentKey };
};

export const processDiagramWeekPlan = ({
  year,
  plan,
  entityId,
  workPlanMap,
}: {
  year: number | string;
  plan: IWeekIntervalBase;
  entityId: number;
  workPlanMap: WorkPlanMapType;
}) => {
  if ([plan.start_week, plan.start_day_week, plan.end_week, plan.end_day_week].some((x) => x === undefined)) return;

  const plan_start_moment = moment().year(+year).week(plan.start_week).day(plan.start_day_week);

  const plan_end_moment = moment()
    .year(+year)
    .week((plan as IWeekIntervalBase).end_week)
    .day((plan as IWeekIntervalBase).end_day_week);

  processDiagramPlan({
    plan: { ...plan, start_at: plan_start_moment.format("YYYY-MM-DD"), end_at: plan_end_moment.format("YYYY-MM-DD") },
    workPlanMap,
    entityId,
  });
};

export const processDiagramWeekWork = ({
  year,
  work,
  entityId,
  workPlanMap,
}: {
  year: number | string;
  work: IWeekIntervalBase;
  entityId: number;
  workPlanMap: WorkPlanMapType;
}) => {
  if ([work.start_week, work.start_day_week, work.end_week, work.end_day_week].some((x) => x === undefined)) return;

  const work_start_moment = moment().year(+year).week(work.start_week).day(work.start_day_week);

  const work_end_moment = moment().year(+year).week(work.end_week).day(work.end_day_week);

  processDiagramWork({
    work: { ...work, start_at: work_start_moment.format("YYYY-MM-DD"), end_at: work_end_moment.format("YYYY-MM-DD") },
    workPlanMap,
    entityId,
  });
};

export const processDiagramSectionPlan = ({
  plan,
  entityId,
  workPlanMap,
}: {
  plan: IDiagramPlannedSection | IProjectPlanInterval;
  entityId: number | null;
  workPlanMap: WorkPlanMapType;
}) => {
  if (!plan.start_at || !plan.end_at || !entityId) return;
  processDiagramPlan({ plan, workPlanMap, entityId, isSectionPlan: true });
};

export const getMonthEnumerateDataForChart = ({ year, monthMarkers }: { year: number; monthMarkers: number[] }) => {
  return monthMarkers.map((marker, index) => ({
    maxDay: moment().year(year).month(index).daysInMonth(),
    year: +year,
    monthNumber: index + 1,
    offsetLeft: marker,
  }));
};

export const applyProcessProjectIntervalFnIfExists = ({
  projects,
  projectInterval,
  processProjectInterval,
  acceptedMap,
  payedMap,
  onStockMap,
  plansMap,
  purchasesMap,
  stocklessMap,
  toPaidMap,
}: {
  projects?: IProject[];
  projectInterval?: IProjectMaterialsDataInterval;
  processProjectInterval: (item: IProjectIntervalBase, type: MaterialType, map: MaterialsMapType) => void;
  onStockMap: MaterialsMapType;
  plansMap: MaterialsMapType;
  purchasesMap: MaterialsMapType;
  stocklessMap: MaterialsMapType;
  acceptedMap: MaterialsMapType;
  payedMap: MaterialsMapType;
  toPaidMap: MaterialsMapType;
}) => {
  if (projects?.length && projectInterval) {
    projects.map((project) => {
      projectInterval?.accepted?.hasOwnProperty(project.id) &&
        projectInterval.accepted[project.id].map((accept) =>
          processProjectInterval(accept, ACCEPTED_MATERIAL, acceptedMap)
        );
      projectInterval?.payed?.hasOwnProperty(project.id) &&
        projectInterval.payed[project.id].map((pay) => processProjectInterval(pay, PAYED_MATERIAL, payedMap));
      projectInterval?.on_stock?.hasOwnProperty(project.id) &&
        projectInterval.on_stock[project.id].map((onStockItem) =>
          processProjectInterval(onStockItem, ON_STOCK_MATERIAL, onStockMap)
        );
      projectInterval?.plans?.hasOwnProperty(project.id) &&
        projectInterval.plans[project.id].map((plan) => processProjectInterval(plan, PLANS_MATERIAL, plansMap));
      projectInterval?.purchases?.hasOwnProperty(project.id) &&
        projectInterval.purchases[project.id].map((purchase) =>
          processProjectInterval(purchase, PURCHASES_MATERIAL, purchasesMap)
        );
      projectInterval?.stockless?.hasOwnProperty(project.id) &&
        projectInterval.stockless[project.id].map((stocklessItem) =>
          processProjectInterval(stocklessItem, STOCKLESS_MATERIAL, stocklessMap)
        );
      projectInterval?.to_paid?.hasOwnProperty(project.id) &&
        projectInterval.to_paid[project.id].map((to_pay) =>
          processProjectInterval(to_pay, TO_PAID_MATERIAL, toPaidMap)
        );
    });
  }
};

export const checkIsMonthBranchShownAsSection = (branch: IProcessedBranch) =>
  (branch.lvl === 2 && branch.collapsed) || (branch.lvl === 3 && branch.collapsed);

export const checkIsShownSectionPlan = (branch: IProcessedBranch) =>
  (branch.lvl === 1 && branch.collapsed) || (branch.lvl === 2 && branch.collapsed) || branch.lvl === 3;
export const checkIsShownSuperSectionPlan = (branch: IProcessedBranch) =>
  (branch.lvl === 1 && branch.collapsed) || branch.lvl === 2;

export const checkIsMonthBranchShownAsExpenditure = (branch: IProcessedBranch) =>
  branch.lvl === 4 || (branch.lvl === 1 && branch.collapsed);

export const assignWorkStatusesCountToBunch = ({
  checkWork,
  statuses,
}: {
  checkWork: IInterval;
  statuses: IProcessedBranchElement["worksBunch"]["statuses"];
}) => {
  if (!checkWork.data.percentage) return;
  Object.entries(checkWork.data.percentage).map(([status, value]) => {
    if (!statuses.hasOwnProperty(status)) {
      Object.defineProperty(statuses, status, {
        value: 0,
        writable: true,
        enumerable: true,
      });
      statuses[status] = 0;
    }
    if (value > 0) statuses[status] = statuses[status] + 1;
  });
};

export const getFetchYearsMonths = ({
  projectId,
  year,
  month,
  loadedChartData,
  tabId,
}: {
  projectId: number | string;
  year: number | string;
  month: number | string;
  tabId: string;
  loadedChartData: ILoadedChartData;
}) => {
  const { nextYear, nextMonth, prevYear, prevMonth } = getNearestYearsMonths(year, month);
  const { prevYear: prev2Year, prevMonth: prev2Month } = getNearestYearsMonths(prevYear, prevMonth);
  const { nextYear: next2Year, nextMonth: next2Month } = getNearestYearsMonths(nextYear, nextMonth);

  let fetchedDates = [
    `${prev2Year}-${prev2Month}`,
    `${prevYear}-${prevMonth}`,
    `${year}-${month}`,
    `${nextYear}-${nextMonth}`,
    `${next2Year}-${next2Month}`,
  ];

  if (loadedChartData[projectId] && loadedChartData[projectId][tabId]) {
    fetchedDates = fetchedDates.filter((x) => loadedChartData[projectId][tabId].indexOf(x) === -1);
  }

  return fetchedDates;
};

export const getIntervalDatesLabel = (start_at: string, end_at: string) =>
  moment(start_at).isSame(moment(end_at), "days")
    ? moment(start_at).format("DD.MM.YY")
    : `${moment(start_at).format("DD.MM.YY")} - ${moment(end_at).format("DD.MM.YY")}`;

export const pushMaterialItem = (
  keysArr: (string | undefined | 0 | null)[],
  map: MaterialsMapType,
  data: IMaterialInterval
) => {
  keysArr.forEach((key) => {
    if (!key) return;
    const currentItem = map.get(key);
    if (!currentItem || currentItem.days < data.days) {
      map.set(key, data);
    }
  });
};

export const processProjectMaterialInterval = (
  item: IProjectIntervalBase,
  type: MaterialType,
  map: MaterialsMapType
) => {
  const startDateKey = moment(item.start_at).format("YYYY-MM-DD");
  const itemKey = `${item.building_id}_${startDateKey}`;
  const days = moment(item.end_at).diff(moment(item.start_at), "days") + 1 || 1;
  pushMaterialItem([itemKey], map, {
    days,
    data: item,
    actualItem: item,
    type: type,
  });
};

export const parseDateTimeToYYYYMMDD = (string: string) => string?.match(/\d{4}-\d{2}-\d{2}/)?.[0];

export const processMaterialWeekDataElement =
  (year: number | string) => (item: any, parent: any, type: MaterialType, map: MaterialsMapType) => {
    const hasWeekInfo = !!item.start_week && !!item.start_day_week && !!item.end_week && !!item.end_day_week;
    if (!hasWeekInfo && !item.start_at && !item.end_at && !item.using_data?.[0]?.confirm_date && !item?.confirm_date)
      return;

    const startMoment = !!item.start_at
      ? moment(parseDateTimeToYYYYMMDD(item.start_at))
      : hasWeekInfo
      ? moment().year(+year).week(item.start_week).day(item.start_day_week)
      : !!item?.confirm_date
      ? moment(parseDateTimeToYYYYMMDD(item?.confirm_date))
      : moment(parseDateTimeToYYYYMMDD(item.using_data?.[0]?.confirm_date));

    const endMoment = !!item.end_at
      ? moment(parseDateTimeToYYYYMMDD(item.end_at))
      : hasWeekInfo
      ? moment().year(+year).week(item.end_week).day(item.end_day_week)
      : !!item?.confirm_date
      ? moment(parseDateTimeToYYYYMMDD(item?.confirm_date))
      : moment(parseDateTimeToYYYYMMDD(item.using_data?.[0]?.confirm_date));

    const startDateKey = startMoment.format("YYYY-MM-DD");

    const itemKey = parent.estimate_expenditure_id && `${parent.estimate_expenditure_id}_${startDateKey}`;
    const itemSectionKey = parent.expenditure_section_id && `${parent.expenditure_section_id}_${startDateKey}`;
    const itemParentKey = parent.expenditure_parent_id && `${parent.expenditure_parent_id}_${startDateKey}`;

    const days = endMoment.diff(startMoment, "days") + 1 || 1;
    pushMaterialItem(
      [itemKey, itemSectionKey, itemParentKey].filter((x) => x),
      map,
      {
        days,
        data: parent,
        actualItem: {
          ...item,
          start_at: moment(startMoment).format("YYYY-MM-DD"),
          end_at: moment(endMoment).format("YYYY-MM-DD"),
        },
        type,
      }
    );
  };

export const processMaterialDataElement = (
  item: IIntervalBase & { confirm_date?: string },
  parent: IDiagramMaterial,
  type: MaterialType,
  map: MaterialsMapType
) => {
  if (!(item.start_at || item.confirm_date)) return;
  const startDateKey = moment(
    parseDateTimeToYYYYMMDD(item.start_at) || parseDateTimeToYYYYMMDD(item.confirm_date || "")
  ).format("YYYY-MM-DD");
  const { itemKey, itemSectionKey, itemParentKey } = generateMaterialItemKeys(parent, startDateKey);
  const days = moment(item.end_at).diff(moment(item.start_at), "days") + 1 || 1;
  pushMaterialItem([itemKey, itemSectionKey, itemParentKey], map, {
    days,
    data: parent,
    actualItem: item,
    type,
  });
};

export const processMaterialPlanDataElement = (
  plan: IDiagramPlansMaterial | IDiagramPlansMim,
  plan_work: IDiagramPlannedWork,
  type: MaterialType,
  map: MaterialsMapType
) => {
  if (plan_work.workinterval?.start_at === undefined || plan_work.workinterval?.end_at === undefined) return;
  const startDateKey = moment(plan_work.workinterval.start_at).format("YYYY-MM-DD");
  const { itemKey, itemSectionKey, itemParentKey } = generateMaterialItemKeys(plan, startDateKey);
  const days = moment(plan_work.workinterval.end_at).diff(moment(plan_work.workinterval.start_at), "days") + 1 || 1;
  pushMaterialItem([itemKey, itemSectionKey, itemParentKey], map, {
    days,
    data: plan,
    actualItem: plan_work,
    type,
  });
};

export const generateWeeksNumbersInMonth = (year: number, monthId: number) => {
  //@ts-ignore
  const extendedMoment = extendMoment(moment);
  const monthMoment = moment().year(+year).month(monthId);

  const weeks: any[] = [];
  const startDay = moment(monthMoment).startOf("month");
  const endDay = moment(monthMoment).endOf("month");
  const monthRange = extendedMoment.range(startDay, endDay);

  const days = Array.from(monthRange.by("day"));
  const weekNumbersByDays = days.map((day) => day.week());
  weekNumbersByDays.forEach((it) => {
    const weekEntryInMonth = weekNumbersByDays.filter((el) => el === it);
    if (!weeks.includes(it) && weekEntryInMonth.length > 3) {
      weeks.push(it);
    }
  });

  return weeks;
};

export const checkPlanOverlapWork = (
  plan: IDiagramPlans | IDiagramSectionsPlan | IProjectPlanInterval | IDiagramPlannedSection,
  work: IInterval
) =>
  moment(plan.start_at).isBetween(work.start, work.end, undefined, "[]") ||
  moment(plan.end_at).isBetween(work.start, work.end, undefined, "[]") ||
  moment(work.start).isBetween(plan.start_at, plan.end_at, undefined, "[]") ||
  moment(work.end).isBetween(plan.start_at, plan.end_at, undefined, "[]");

export const checkWorkInsideOfWork = (longWork: IInterval, shortWork: IInterval) =>
  moment(shortWork.start).isBetween(longWork.start, longWork.end, undefined, "[]") &&
  moment(shortWork.end).isBetween(longWork.start, longWork.end, undefined, "[]");

export const shouldShowSectionInTree = (
  elementExpendituresCount: ICountExpenditures,
  tab: ManufacturingTabsType,
  showOnlyEstimate: boolean = false
): boolean => {
  if (tab === WORKS_TAB_ID) {
    if (showOnlyEstimate) {
      return elementExpendituresCount.estimate?.work > 0;
    } else {
      return elementExpendituresCount.work + elementExpendituresCount.groups > 0;
    }
  }
  if (tab === RESOURCES_TAB_ID)
    return (
      elementExpendituresCount.equipment +
        elementExpendituresCount.machine +
        elementExpendituresCount.transport +
        elementExpendituresCount.material >
      0
    );
  if (tab === MATERIALS_TAB_ID) return elementExpendituresCount.material > 0;
  if (tab === MIMES_TAB_ID)
    return (
      elementExpendituresCount.equipment + elementExpendituresCount.machine + elementExpendituresCount.transport > 0
    );
  if (tab === EQUIPMENT_TAB_ID) return elementExpendituresCount.equipment > 0;
  return true;
};

const mergeIndicators = (...args: Record<any, string | number>[]) => {
  const keys = Array.from(new Set(args.map((x) => Object.keys(x)).flat()));
  let newIndicators: Record<any, number> = {};
  for (let k = 0; k <= keys.length; k++) {
    const currentKey = keys[k];
    if (!currentKey) continue;
    newIndicators[currentKey] = args.reduce((acc, value) => {
      return acc + Number(value[currentKey] || 0);
    }, 0);
  }
  return newIndicators;
};

export const parseBranchIndicators = (branchData: ISpittingTreeElement["data"], tab: ManufacturingTabsType) => {
  let currencyIndicators: ICurrencyIndicators = {};
  let infoIndicators: IInfoIndicators = {};
  const mergeMaterialsIndicators = () => {
    if (branchData.material_amount_indicators)
      currencyIndicators = Object.assign({}, branchData.material_amount_indicators);
    if (branchData.material_indicators) {
      currencyIndicators = Object.assign({}, branchData.material_indicators);
      infoIndicators = Object.assign({}, branchData.material_indicators);
    }
  };
  const mergeEquipmentIndicators = () => {
    if (branchData.equipment_indicators) {
      currencyIndicators = mergeIndicators(currencyIndicators, branchData.equipment_indicators);
      infoIndicators = mergeIndicators(infoIndicators, branchData.equipment_indicators);
    }
  };
  const mergeMimesIndicators = () => {
    if (branchData.machine_amount_indicators)
      currencyIndicators = mergeIndicators(currencyIndicators, branchData.machine_amount_indicators);
    if (branchData.equipment_amount_indicators)
      currencyIndicators = mergeIndicators(currencyIndicators, branchData.equipment_amount_indicators);
    if (branchData.transport_amount_indicators)
      currencyIndicators = mergeIndicators(currencyIndicators, branchData.transport_amount_indicators);
    if (branchData.machine_indicators) {
      currencyIndicators = mergeIndicators(currencyIndicators, branchData.machine_indicators);
      infoIndicators = mergeIndicators(infoIndicators, branchData.machine_indicators);
    }
    if (branchData.equipment_indicators) {
      currencyIndicators = mergeIndicators(currencyIndicators, branchData.equipment_indicators);
      infoIndicators = mergeIndicators(infoIndicators, branchData.equipment_indicators);
    }
    if (branchData.transport_indicators) {
      currencyIndicators = mergeIndicators(currencyIndicators, branchData.transport_indicators);
      infoIndicators = mergeIndicators(infoIndicators, branchData.transport_indicators);
    }
  };

  const mergeWorkIndicators = () => {
    if (branchData.work_amount_indicators) currencyIndicators = Object.assign({}, branchData.work_amount_indicators);
    if (branchData.work_indicators) {
      currencyIndicators = Object.assign({}, branchData.work_indicators);
      infoIndicators = Object.assign({}, branchData.work_indicators);
    }
  };

  if (tab === WORKS_TAB_ID) {
    mergeWorkIndicators();
  } else if (tab === MATERIALS_TAB_ID) {
    mergeMaterialsIndicators();
  } else if (tab === MIMES_TAB_ID) {
    mergeMimesIndicators();
  } else if (tab === RESOURCES_TAB_ID) {
    mergeMaterialsIndicators();
    mergeMimesIndicators();
  } else if (tab === EQUIPMENT_TAB_ID) {
    mergeEquipmentIndicators();
  }
  if (branchData.estimate_amount) currencyIndicators.estimate_amount = branchData.estimate_amount;
  if (branchData.count) infoIndicators.count = branchData.count;
  if (branchData.measure) infoIndicators.measure = branchData.measure;

  return {
    currencyIndicators,
    infoIndicators,
  };
};

export const getUnitOffset = (touchedYears: string[], index: number, viewMode: ManufacturingViewType) => {
  if (index < 0) return 0;
  if (viewMode === "MONTH" || viewMode === "HALF_MONTH") {
    return touchedYears.slice(0, index).reduce((acc: number, year: string) => acc + getDaysInYear(year), 0);
  } else if (viewMode === "WEEK") {
    return touchedYears.slice(0, index).reduce((acc: number, year: string) => acc + getWeeksInYear(year), 0) * 7;
  } else if (viewMode === "YEAR") {
    return touchedYears.slice(0, index).reduce((acc: number, year: string) => acc + getWeeksInYear(year), 0);
  }
  return 0;
};

export interface ISortManufacturingGroup {
  expenditure_numbers: string[];
}

export interface ISortManufacturingExpenditure {
  number: number | string;
}

export const numberWithPointsSortFn = (a: string, b: string): number => {
  if (!a.length || !b.length) {
    return 0;
  }

  const aParts = a.split(".");
  const bParts = b.split(".");
  const biggestLength = Math.max(aParts.length, bParts.length);

  for (let i = 0; i < biggestLength; i++) {
    if (typeof a?.[i] === "number" && typeof b?.[i] === "undefined") {
      return -1;
    }

    if (typeof a?.[i] === "undefined" && typeof b?.[i] === "number") {
      return 1;
    }

    if (typeof a?.[i] === "undefined" && typeof b?.[i] === "undefined") {
      return 0;
    }

    const diff = +b[i] - +a[i];
    if (diff !== 0) {
      return diff;
    }
  }
};

const getManufacturingGroupAnchorIndex = (group: ISortManufacturingGroup) => {
  const sorted = (group?.expenditure_numbers ?? []).sort(numberSortFunction);

  if (sorted.length) {
    return +sorted[0];
  } else {
    // TODO: понять, используется ли этот кейс как-нибудь и отрефакторить
    // до решения бага OSLA-4815 функция возвращала Math.min(...(group.expenditure_numbers?.map(Number) || []));
    return Infinity;
  }
};

export const sortManufacturingExpendituresAndGroups = (
  expenditures: ISortManufacturingExpenditure[],
  groups: ISortManufacturingGroup[]
) => {
  const groupedExpendituresNumbers = groups.flatMap((group) => group.expenditure_numbers);
  return sortExpendituresAndGroups(
    expenditures,
    groups,
    getManufacturingGroupAnchorIndex,
    (expenditures, groupAnchorNumber) => expenditures.findIndex((exp) => Number(exp.number) > groupAnchorNumber)
  ).filter((x) => (!!x.number ? !groupedExpendituresNumbers.includes(x.number) : true));
};

export const getCriticalDatesFromChartTrees = (calcTrees: any, tree?: any, level = 2) => {
  const result = new Set();
  let visibleSectionsIds = null;

  if (tree) {
    visibleSectionsIds = tree.filter((s) => s.lvl === level).map((s) => s.id);
  }

  calcTrees.forEach((tree) => {
    tree.forEach((el) => {
      el?.days?.forEach((day) => {
        if (day?.plan?.isSectionPlan) {
          if (Array.isArray(visibleSectionsIds)) {
            if (visibleSectionsIds.includes(day.plan.data.section_id)) {
              result.add(day.plan.end);
            }
          } else {
            result.add(day.plan.end);
          }
        }
      });
    });
  });

  return Array.from(result) as string[];
};

export const filterSpittingTree = (
  tree: ISpittingTreeElement[],
  filterParams: string,
  isProduction = false,
  data: any,
  periodParams: { dateStart: string | null; dateEnd: string | null },
  isActiveEditing = false
) => {
  const isPeriod = !!periodParams.dateStart && !!periodParams.dateEnd;
  if (!filterParams && !isPeriod) return tree;

  return tree.filter((el) => {
    if (isProduction ? el.lvl === 1 : el.lvl === 2) {
      return !!el.data?.subsections?.filter((sub) => {
        if (sub?.groups?.length) {
          return !!sub?.groups?.filter((exp) => {
            const filteredByName = !!exp.name.toLocaleLowerCase().includes(filterParams.toLocaleLowerCase());
            if (isPeriod) {
              const filteredPlans = data?.groupPlans?.filter((plan) => {
                const isMatchIds = plan.group.id === exp?.id;
                const isDateEndBeforePeriod = moment(plan.end_at).isBefore(periodParams.dateStart);
                const isDateStartAfterPeriod = moment(plan.start_at).isAfter(periodParams.dateEnd);
                return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
              });
              const filteredFacts = data?.groupWorks?.filter((fact) => {
                const isMatchIds = fact.group.id === exp?.id;
                const isDateEndBeforePeriod = moment(fact.end_at).isBefore(periodParams.dateStart);
                const isDateStartAfterPeriod = moment(fact.start_at).isAfter(periodParams.dateEnd);
                return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
              });
              const filteredByPeriod = !isActiveEditing
                ? !!filteredPlans.length || !!filteredFacts.length
                : !!filteredPlans.length;
              return filteredByName && filteredByPeriod;
            }
            return filteredByName;
          }).length;
        } else if (sub?.expenditures?.length) {
          return !!sub?.expenditures?.filter((exp) => {
            const filteredByName = !!exp.name.toLocaleLowerCase().includes(filterParams.toLocaleLowerCase());
            if (isPeriod) {
              const filteredPlans = data?.plans?.filter((plan) => {
                const isMatchIds = plan.expenditure_id === exp?.id;
                const isDateEndBeforePeriod = moment(plan.end_at).isBefore(periodParams.dateStart);
                const isDateStartAfterPeriod = moment(plan.start_at).isAfter(periodParams.dateEnd);
                return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
              });
              const filteredFacts = data?.works?.filter((fact) => {
                const isMatchIds = fact.expenditure_id === exp?.id;
                const isDateEndBeforePeriod = moment(fact.end_at).isBefore(periodParams.dateStart);
                const isDateStartAfterPeriod = moment(fact.start_at).isAfter(periodParams.dateEnd);
                return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
              });
              const filteredByPeriod = !isActiveEditing
                ? !!filteredPlans.length || !!filteredFacts.length
                : !!filteredPlans.length;
              return filteredByName && filteredByPeriod;
            }
            return filteredByName;
          }).length;
        } else {
          return false;
        }
      }).length;
    } else if (isProduction ? el.lvl === 2 : el.lvl === 3) {
      if (!!el.data?.groups?.length) {
        return !!el.data?.groups?.filter((exp) => {
          const filteredByName = !!exp.name.toLocaleLowerCase().includes(filterParams.toLocaleLowerCase());
          if (isPeriod) {
            const filteredPlans = data?.groupPlans?.filter((plan) => {
              const isMatchIds = plan.group.id === exp?.id;
              const isDateEndBeforePeriod = moment(plan.end_at).isBefore(periodParams.dateStart);
              const isDateStartAfterPeriod = moment(plan.start_at).isAfter(periodParams.dateEnd);
              return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
            });
            const filteredFacts = data?.groupWorks?.filter((fact) => {
              const isMatchIds = fact.group.id === exp?.id;
              const isDateEndBeforePeriod = moment(fact.end_at).isBefore(periodParams.dateStart);
              const isDateStartAfterPeriod = moment(fact.start_at).isAfter(periodParams.dateEnd);
              return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
            });
            const filteredByPeriod = !isActiveEditing
              ? !!filteredPlans.length || !!filteredFacts.length
              : !!filteredPlans.length;
            return filteredByName && filteredByPeriod;
          }
          return filteredByName;
        }).length;
      } else if (!!el.data?.expenditures?.length) {
        return !!el.data?.expenditures?.filter((exp) => {
          const filteredByName = !!exp.name.toLocaleLowerCase().includes(filterParams.toLocaleLowerCase());
          if (isPeriod) {
            const filteredPlans = data?.plans?.filter((plan) => {
              const isMatchIds = plan.expenditure_id === exp?.id;
              const isDateEndBeforePeriod = moment(plan.end_at).isBefore(periodParams.dateStart);
              const isDateStartAfterPeriod = moment(plan.start_at).isAfter(periodParams.dateEnd);
              return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
            });
            const filteredFacts = data?.works?.filter((fact) => {
              const isMatchIds = fact.expenditure_id === exp?.id;
              const isDateEndBeforePeriod = moment(fact.end_at).isBefore(periodParams.dateStart);
              const isDateStartAfterPeriod = moment(fact.start_at).isAfter(periodParams.dateEnd);
              return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
            });
            const filteredByPeriod = !isActiveEditing
              ? !!filteredPlans.length || !!filteredFacts.length
              : !!filteredPlans.length;
            return filteredByName && filteredByPeriod;
          }
          return filteredByName;
        }).length;
      } else {
        return false;
      }
    } else if (isProduction ? el.lvl === 3 : el.lvl === 4) {
      const filteredByName = el.data.name.toLocaleLowerCase().includes(filterParams.toLocaleLowerCase());
      if (isPeriod && el.data?.expenditure_type) {
        const filteredPlans = data?.plans?.filter((plan) => {
          const isMatchIds = plan.expenditure_id === el.data.id;
          const isDateEndBeforePeriod = moment(plan.end_at).isBefore(periodParams.dateStart);
          const isDateStartAfterPeriod = moment(plan.start_at).isAfter(periodParams.dateEnd);
          return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
        });
        const filteredFacts = data?.works?.filter((fact) => {
          const isMatchIds = fact.expenditure_id === el.data?.id;
          const isDateEndBeforePeriod = moment(fact.end_at).isBefore(periodParams.dateStart);
          const isDateStartAfterPeriod = moment(fact.start_at).isAfter(periodParams.dateEnd);
          return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
        });
        const filteredByPeriod = !isActiveEditing
          ? !!filteredPlans.length || !!filteredFacts.length
          : !!filteredPlans.length;
        return filteredByName && filteredByPeriod;
      }
      if (isPeriod && el.data?.expenditure_numbers.length) {
        const filteredPlans = data?.groupPlans?.filter((plan) => {
          const isMatchIds = plan.group.id === el.data.id;
          const isDateEndBeforePeriod = moment(plan.end_at).isBefore(periodParams.dateStart);
          const isDateStartAfterPeriod = moment(plan.start_at).isAfter(periodParams.dateEnd);
          return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
        });
        const filteredFacts = data?.groupWorks?.filter((fact) => {
          const isMatchIds = fact.group.id === el.data?.id;
          const isDateEndBeforePeriod = moment(fact.end_at).isBefore(periodParams.dateStart);
          const isDateStartAfterPeriod = moment(fact.start_at).isAfter(periodParams.dateEnd);
          return isMatchIds && !isDateEndBeforePeriod && !isDateStartAfterPeriod;
        });
        const filteredByPeriod = !isActiveEditing
          ? !!filteredPlans.length || !!filteredFacts.length
          : !!filteredPlans.length;
        return filteredByName && filteredByPeriod;
      }
      return filteredByName;
    } else if (!isProduction && el.lvl === 1) {
      return true;
    }
  });
};
