import React from "react";

import { IJournalAcceptanceSection } from "redux/modules/common/building/journal/acceptance/types";

import { useUIIndicator } from "../../../../../../../../hooks/uiIndicators/useUIIndicator";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import { MODULES_ENUM } from "types/enums/ModulesEnum";
import { IIdAndName } from "types/idAndName";

import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import commonJournalStyles from "../../../../../Journal.module.scss";
import styles from "./JournalSection.module.scss";

interface IProps {
  section: IJournalAcceptanceSection["subsections"][number];
  onSectionClick: (sectionid: number) => void;
  year: number;
  month: number;
  parentId: number;
}

const JournalSubSection: React.FC<IProps> = ({ section, onSectionClick, month, year, parentId }) => {
  const { isIndicatorDisplayed } = useUIIndicator({
    data: { child_section_id: section?.id, parent_section_id: parentId, year, month },
    module: MODULES_ENUM.OBJECTS,
    type: ["journal_accept_work", "journal_accept_group"] as any,
  });

  const sectionClickHandler = React.useCallback(() => {
    onSectionClick(section?.id);
  }, [section?.id, onSectionClick]);

  return (
    <TableReusableRow onClick={sectionClickHandler} className={styles.row} isWithIndicator={isIndicatorDisplayed}>
      <TableReusableCell isNoBreak>{section?.name}</TableReusableCell>
      {!!section.facts_to_confirm && (
        <TableReusableCell className={styles.right}>
          <span className={styles.count}>
            {transformDigitToFinancial(section.facts_to_confirm, { withFloat: false })}
          </span>
          <span className={styles.toAcceptance}>работ к принятию</span>
        </TableReusableCell>
      )}
    </TableReusableRow>
  );
};

export default JournalSubSection;
