import cn from "classnames";
import React, { MouseEventHandler, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  dropHighlightRelatedIntervals,
  highlightRelatedIntervals,
  setArrowHash,
} from "../../../../../../../../../redux/modules/common/building/manufacturing/thunks";

import ConnectPointsWrapper from "../../components/ConnectPointsWrapper/ConnectPointsWrapper";

import { IDiagramIntervalProps } from "../../DiagramInterval";
import { IDiagramIntervalLink, useIntervalLinks } from "./useIntervalLinks";
import Xarrow from "react-xarrows";

import {
  DIAGRAM_ARROW_CONFIG,
  HIGHLIGHT_COLOR,
  INTERVAL_MAX_Z_INDEX,
  INTERVAL_TYPES,
  LINKS_DISPLAY_COLOR,
  TAILS_WIDTH_COMPENSATION_REM,
} from "../../../../../../constants";

import styles from "./withLinking.module.scss";

export interface IWithLinkingProps {
  isLinkingEnabled: boolean;
  isLinkingVisible: boolean;
  handleIntervalClickLinking: MouseEventHandler;
}

export const withLinking =
  (Component: React.ComponentType<IDiagramIntervalProps>) => (props: IDiagramIntervalProps) => {
    const {
      daysLength,
      unitMultiplier,
      hover,
      intervalWrapperRef,
      type,
      intervalId,
      projectId,
      startDate,
      endDate,
      onAddArrowCallback,
      isSectionPlan,
    } = props;

    const {
      isLinkingEnabled,
      isLinkingVisible,
      handleIntervalClick,
      position,
      handleCreateIntervalLink,
      handleDragStart,
      handleDragging,
      arrowsStartsWithCurrentIntervalId,
      beingDraggedArrows,
      projectArrows,
      isSomeArrowBeingDragged,
      isIntervalHighlighted,
      highlightedArrows,
      arrowHash,
    } = useIntervalLinks({
      intervalWrapperRef,
      toIntervalId: intervalId,
      projectId,
      startDate,
      endDate,
      onAddArrowCallback,
      isToIntervalGroup: props.isGroup,
      isToIntervalSection: props.isSectionPlan,
    });

    const dispatch = useDispatch();

    const isAddingArrowAllowed = !isSectionPlan && type === INTERVAL_TYPES.plan && isLinkingEnabled;

    const intervalWidthRem = Math.max(daysLength, 1) * unitMultiplier;

    const arrowAcceptElementStyles = {
      width: `${intervalWidthRem + TAILS_WIDTH_COMPENSATION_REM - 0.4}rem`,
    };

    const highlightChain = useCallback(() => {
      if (!isLinkingEnabled) return;
      dispatch(highlightRelatedIntervals({ intervalId, projectId }));
    }, [intervalId, projectId, isLinkingEnabled]);

    const removeHighlightChain = useCallback(() => dispatch(dropHighlightRelatedIntervals()), []);

    const isChainHighlighted = isLinkingEnabled && (hover || isIntervalHighlighted);

    useEffect(() => {
      if (!isLinkingVisible) return;
      dispatch(setArrowHash(Math.random()));
    }, [isLinkingVisible]);

    const linkingLineColor = (isArrowHighlighted: boolean) => {
      if (isChainHighlighted || isArrowHighlighted) {
        return HIGHLIGHT_COLOR;
      }
      if (isLinkingVisible && !isLinkingEnabled) {
        return LINKS_DISPLAY_COLOR;
      }
      return DIAGRAM_ARROW_CONFIG.lineColor;
    };

    const linkingHeadColor = (isArrowHighlighted: boolean) => {
      if (isChainHighlighted || isArrowHighlighted) {
        return HIGHLIGHT_COLOR;
      }
      if (isLinkingVisible && !isLinkingEnabled) {
        return LINKS_DISPLAY_COLOR;
      }
      return DIAGRAM_ARROW_CONFIG.headColor;
    };

    const innerBeforeContent = (
      <>
        {props.innerBeforeContent}
        {isLinkingVisible && (
          <div className={styles.arrowAcceptElement} id={intervalId} style={arrowAcceptElementStyles} />
        )}
      </>
    );

    const innerAfterContent = (
      <>
        {props.innerAfterContent}
        {isLinkingEnabled && (
          <ConnectPointsWrapper
            intervalId={intervalId}
            isAddingArrowAllowed={isAddingArrowAllowed}
            hover={hover}
            position={position}
            handleDragEnd={handleCreateIntervalLink}
            handleDragStart={handleDragStart}
            handleDragging={handleDragging}
            intervalWrapperRef={intervalWrapperRef}
            projectId={projectId}
            beingDraggedArrows={beingDraggedArrows}
            projectArrows={projectArrows}
            isLinkingEnabled={isLinkingEnabled}
            isSomeArrowBeingDragged={isSomeArrowBeingDragged}
          />
        )}
      </>
    );

    const afterContent = (
      <>
        {props.afterContent}
        {isLinkingVisible &&
          arrowsStartsWithCurrentIntervalId
            .filter(
              (x) =>
                document.getElementById(x.from_interval?.toString() ?? x.from_group?.toString()) &&
                document.getElementById(x.to_interval?.toString() ?? x.to_group?.toString())
            )
            .map((arrow: IDiagramIntervalLink) => {
              const isArrowHighlighted = highlightedArrows.indexOf(arrow.id) !== -1;
              return (
                <Xarrow
                  {...DIAGRAM_ARROW_CONFIG}
                  lineColor={linkingLineColor(isArrowHighlighted)}
                  headColor={linkingHeadColor(isArrowHighlighted)}
                  zIndex={isChainHighlighted || isArrowHighlighted || !!arrow.delay_day ? INTERVAL_MAX_Z_INDEX + 1 : 0}
                  start={arrow.from_interval?.toString() ?? arrow.from_group?.toString()}
                  end={arrow.to_interval?.toString() ?? arrow.to_group?.toString()}
                  relationType={arrow.related_type}
                  key={`${arrow.from_interval ?? arrow.from_group}-${arrow.to_interval ?? arrow.to_group}-${arrowHash}`}
                  labels={{
                    start: !!arrow.delay_day ? (
                      <div
                        className={cn(styles.breakBubble, {
                          [styles.breakBubbleHighlighted]: isChainHighlighted || isArrowHighlighted,
                        })}
                        onMouseOver={highlightChain}
                        onMouseLeave={removeHighlightChain}
                      >
                        {arrow.delay_day}
                      </div>
                    ) : undefined,
                  }}
                  passProps={{
                    onMouseOver: highlightChain,
                    onMouseLeave: removeHighlightChain,
                  }}
                />
              );
            })}
      </>
    );
    return (
      <>
        <Component
          {...props}
          innerAfterContent={innerAfterContent}
          innerBeforeContent={innerBeforeContent}
          isLinkingEnabled={isLinkingEnabled}
          isLinkingVisible={isLinkingVisible}
          handleIntervalClickLinking={handleIntervalClick}
          afterContent={afterContent}
          children={props.children}
        />
      </>
    );
  };
