import React from "react"
import { useDispatch } from "react-redux"
import { isTasksLoadingSelector, tasksListsSelector } from "redux/modules/purchaser/Tasks/selectors"
import { loadTasksLists, loadMoreTasksLists } from "redux/modules/purchaser/Tasks/thunks"
import { useTypedSelector } from "redux/typedUseSelector"

export const useTasksLists = (objectId: string) => {
    const dispatch = useDispatch()
    const tasksLists = useTypedSelector(tasksListsSelector)
    const isTasksLoading = useTypedSelector(isTasksLoadingSelector)

    React.useEffect(() => {
        dispatch(loadTasksLists(objectId))
    }, [objectId])

    const loadMoreTasksListsHandler = React.useCallback(() => {
        dispatch(loadMoreTasksLists(tasksLists.results.length))
    }, [tasksLists.results.length])

    return {
        tasksLists,
        isTasksLoading,
        loadMoreTasksListsHandler
    }
}