import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { loadObjects, objectsLoadingSelector, objectsSelector } from "redux/modules/common/building/projects";

import { useLoadUIIndicators } from "../../../hooks/uiIndicators/useLoadUIIndicators";
import { Spinner } from "../../UI/Spinner/Spinner";
import EmptyPlaceholder from "../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import TemplateSimple from "../../UI/templates/TemplateSimple/TemplateSimple";

import settingGear from "images/icons/navigation/settingGear.svg";

import styles from "./ObjectsList.module.scss";

const ObjectsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const objects = useSelector(objectsSelector);
  const isLoadingObjects = useSelector(objectsLoadingSelector);

  useLoadUIIndicators();

  useEffect(() => {
    if (objects?.results?.length) return;
    dispatch(loadObjects(null, { limit: 1000, offset: 0 }));
  }, []);

  useEffect(() => {
    if (!objects?.results?.length) return;
    if (localStorage.getItem("lastObjectId")) {
      history.push(`/objects/${localStorage.getItem("lastObjectId")}`);
    } else {
      history.push(`/objects/${objects?.results[0]?.id}`);
      localStorage.setItem("lastObjectId", objects?.results[0]?.id);
    }
  }, [objects]);

  if (objects.results && objects.results.length === 0 && !isLoadingObjects) {
    return (
      <TemplateSimple>
        <EmptyPlaceholder text="Нет проектов" img={settingGear} className={styles.placeholder} />
      </TemplateSimple>
    );
  }

  if (isLoadingObjects) {
    return (
      <TemplateSimple>
        <Spinner />
      </TemplateSimple>
    );
  }

  return null;
};

export default ObjectsList;
