import { message } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ksListDetailedKs2Selector } from "redux/modules/common/building/documents/ksList/selectors";
import { getDetailedKs2InDocs } from "redux/modules/common/building/documents/ksList/thunks";
import { actingApi } from "redux/modules/common/building/journal/acting/api";
import { actingInvalidateKeySelector } from "redux/modules/common/building/journal/acting/selectors";
import {
  ks2AttachmentsSelector,
  passportsInvalidateKeySelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import { getKs2Attachments } from "redux/modules/common/passportsAndCertificates/thunks";
import { DocumentsTypesEnum } from "redux/modules/common/passportsAndCertificates/types";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBack from "components/UI/atoms/ButtonBack/ButtonBack";
import DateCell from "components/UI/atoms/DateCell/DateCell";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ExpandPillTrigger from "components/UI/atoms/ExpandPillTrigger/ExpandPillTrigger";
import FilesClipButton from "components/UI/atoms/FilesClipButton/FilesClipButton";
import StatusElement from "components/UI/atoms/StatusElement/StatusElement";
import Paper from "components/UI/templates/Paper/Paper";
import TemplateSimple from "components/UI/templates/TemplateSimple/TemplateSimple";
import FilesModalKs2 from "components/modals/ExpenditureFilesModal/FilesModalKs2";

import KsStatus from "../Documents/KsList/KsListRow/KsStatus/KsStatus";
import KS2PageRow from "./KS2PageRow/KS2PageRow";
import KSPageDownloadDropDown, { downloadOptionsValuesEnum } from "./KSPageDownloadDropDown/KSPageDownloadDropDown";

import { useObjectId } from "../Documents/hooks/useObjectId";
import { useQueryParams } from "utils/hooks/useQueryParams";

import { getAttachmentName } from "./utils";

import twoListsIcon from "images/icons/navigation/twoListsIcon.svg";

import styles from "./KSPage.module.scss";

const KS2Page = () => {
  const dispatch = useDispatch();
  const [isFilesRow, setIsFilesRow] = useState(false);
  const [isFilesModal, setIsFilesModal] = useState(false);

  const toggleFilesRow = useCallback(() => {
    setIsFilesRow((prev) => !prev);
  }, []);

  const toggleFilesModal = useCallback(() => {
    setIsFilesModal((prev) => !prev);
  }, []);

  const ks2Id = Number(useQueryParams("ks2Id"));
  const objectId = useObjectId();

  const ks2Data = useSelector(ksListDetailedKs2Selector)[ks2Id];
  const invalidateKey = useSelector(actingInvalidateKeySelector);
  const invalidateDocsKey = useSelector(passportsInvalidateKeySelector);
  const ks2Attachments = useSelector(ks2AttachmentsSelector)[ks2Id];

  useLayoutEffect(() => {
    dispatch(getDetailedKs2InDocs(ks2Id));
  }, [ks2Id, invalidateKey, objectId, invalidateDocsKey]);

  const dateString = `${moment(ks2Data?.start_at).format("DD/MM/YYYY")} - ${moment(ks2Data?.end_at).format(
    "DD/MM/YYYY"
  )}`;

  const downloadSelectHandler = useCallback(
    (val: string) => {
      const value = val as downloadOptionsValuesEnum;
      if (value === downloadOptionsValuesEnum.onlyKs) {
        actingApi.getKs2Report(objectId, ks2Data.id, `Акт КС-2 №${ks2Data.title} ${dateString}.xlsx`);
      }
      if (value === downloadOptionsValuesEnum.all) {
        actingApi.createKsExport(ks2Data.id, "2", objectId).then(() => {
          message.success("Выгрузка формируется, подождите");
        });
      }
    },
    [ks2Data, objectId]
  );

  useEffect(() => {
    if (!isFilesRow) return;
    dispatch(getKs2Attachments(ks2Id));
  }, [invalidateDocsKey, ks2Id, isFilesRow]);

  const filteredAttachments = useMemo(() => {
    return ks2Attachments?.results?.filter((el) => el.type !== DocumentsTypesEnum.file);
  }, [ks2Attachments]);

  return (
    <>
      <div className={styles.wrapper}>
        <TemplateSimple contentClassName={styles.template}>
          <Paper>
            {ks2Data?.isLoading && (
              <div className={styles.spinner}>
                <Spinner isStatic />
              </div>
            )}
            {ks2Data?.id ? (
              !ks2Data?.isLoading && (
                <div className={styles.paper}>
                  <div className={styles.headline}>
                    <div className={styles.head}>
                      <ButtonBack />
                      <h1>Акт КС-2 № {ks2Data?.title}</h1>
                      <KsStatus status={ks2Data?.act_ks3?.status} />
                      <KSPageDownloadDropDown onSelect={downloadSelectHandler} />
                    </div>

                    <div className={styles.subtitle}>
                      <div className={styles.periodTitle}>Отчетный период:</div>
                      <DateCell date={dateString} className={styles.date} />
                      <FilesClipButton className={styles.filesBtn} onClick={toggleFilesModal} />
                      {!!ks2Data?.attachments_count && (
                        <ExpandPillTrigger
                          isExpanded={isFilesRow}
                          toggleExpand={toggleFilesRow}
                          variant="success"
                          label={`${ks2Data?.attachments_count}`}
                        />
                      )}
                    </div>
                    {isFilesRow && (
                      <div className={styles.filesRow}>
                        {filteredAttachments?.map((el) => (
                          <StatusElement
                            key={el.id}
                            status="success"
                            text={getAttachmentName(el)}
                            onClick={toggleFilesModal}
                          />
                        ))}
                      </div>
                    )}
                  </div>

                  <div className={styles.list}>
                    {ks2Data?.items?.map((el) => (
                      <KS2PageRow
                        key={el.section_id}
                        section={el}
                        isAutoExpand={ks2Data?.items?.length === 1}
                        ks2Id={ks2Id}
                      />
                    ))}
                  </div>
                </div>
              )
            ) : (
              <div className={styles.paper}>
                <div className={styles.head}>
                  <ButtonBack />
                  <h1>Акт КС-2</h1>
                </div>

                <EmptyPlaceholder text="Нет данных" img={twoListsIcon} />
              </div>
            )}
          </Paper>
        </TemplateSimple>
      </div>
      <FilesModalKs2 onClose={toggleFilesModal} isOpen={isFilesModal} ks2Id={+ks2Id!} />
    </>
  );
};

export default React.memo(KS2Page);
