import cn from "classnames";
import React, { MouseEventHandler } from "react";

import styles from "./ContentWithCount.module.scss";

export interface IContentWithCountProps {
  children: React.ReactNode;
  count: number;
  leftCount?: number;
  isCountDisplayed?: boolean;
  countClassName?: string;
  containerClassName?: string;
  onClick?: MouseEventHandler;
  absoluteContainerClassName?: string;
}

const ContentWithCount: React.FC<IContentWithCountProps> = ({
  count,
  leftCount,
  containerClassName,
  countClassName,
  isCountDisplayed = true,
  onClick,
  children,
  absoluteContainerClassName,
}) => {
  return (
    <div className={cn(styles.container, containerClassName)} onClick={onClick}>
      <div className={cn(styles.absoluteContainer, absoluteContainerClassName)}>
        {!!leftCount && <span className={cn(styles.count, styles.leftCount)}>{leftCount}</span>}
        {isCountDisplayed && <span className={cn(styles.count, countClassName)}>{count}</span>}
      </div>
      {children}
    </div>
  );
};

export default React.memo(ContentWithCount);
