import { message } from "antd";
import axios from "axios";
import { createSelector } from "reselect";

import { messageErrorHandler } from "../../../../utils/helpers/errorHandler";

const moduleName = "inviteOrg";

export const INVITE_FORM = "INVITE_FORM";
export const ALREADY_EXIST = "ALREADY_EXIST";
export const INVITED = "INVITE_SENT";
export const ADD_PARTNER = "ADD_PARTNER";

export const INVITE_LOADING = `${moduleName}/INVITE_LOADING`;
export const INVITE_SENT = `${moduleName}/INVITE`;
export const EXIST_INVITE_SENT = `${moduleName}/EXIST_INVITE_SENT`;
export const SET_INFO = `${moduleName}/SET_INFO`;
export const RESET = `${moduleName}/RESET`;

const initialState = {
  orgInfo: null,
  window: INVITE_FORM,
  loading: false,
};

export const stateSelector = (state) => state[moduleName];
export const windowSelector = createSelector(stateSelector, (state) => state.window);
export const loadingSelector = createSelector(stateSelector, (state) => state.loading);
export const orgInfoSelector = createSelector(stateSelector, (state) => state.orgInfo);

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INVITE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_INFO:
      return {
        ...state,
        orgInfo: payload,
        window: ALREADY_EXIST,
        loading: false,
      };

    case INVITED:
      return {
        ...state,
        orgInfo: payload,
        window: INVITE_SENT,
        loading: false,
      };

    case EXIST_INVITE_SENT:
      return {
        ...state,
        orgInfo: payload,
        window: ADD_PARTNER,
        loading: false,
      };

    case RESET:
      return {
        ...state,
        //orgInfo: null,
        window: INVITE_FORM,
        loading: false,
      };

    default:
      return state;
  }
};

export const inviteLoading = () => ({
  type: INVITE_LOADING,
});

export const setOrgInfo = (info) => ({
  type: SET_INFO,
  payload: info,
});

export const invited = (info) => ({
  type: INVITED,
  payload: info,
});

export const existInvited = (info) => ({
  type: EXIST_INVITE_SENT,
  payload: info,
});

export const resetWindow = () => ({
  type: RESET,
});

export const inviteExists = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, recipient) => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch(inviteLoading());
      axios
        .post(
          `entities/${entityId}/invite/exists/`,
          {
            recipient: recipient,
          },
          config
        )
        .then((res) => {
          dispatch(existInvited(res.data));
        })
        .catch((err) => {
          message.error("Ошибка сервера");
          dispatch(resetWindow());
        });
    };
  };
})();

export const invite = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (data) => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch(inviteLoading());
      axios
        .post(
          `/partnership/invitations/`,
          {
            orgName: data.orgName,
            itn: data.itn,
            email: data.eMail,
          },
          config
        )
        .then((res) => {
          if (res.data.exists) {
            dispatch(setOrgInfo(res.data));
          } else {
            dispatch(invited(res.data));
          }
        })
        .catch((error) => {
          messageErrorHandler(error.response.data.errors);
          dispatch(resetWindow());
        });
    };
  };
})();
