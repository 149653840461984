import _ from "lodash";
import { Dispatch } from "redux";

import { RootState } from "../../../rootReducer";
import { userSelector } from "../auth";
import { uiIndicatorsActions } from "./actions";
import { uiIndicatorsApi } from "./uiIndicatorsApi";

import { MODULES_ENUM } from "../../../../types/enums/ModulesEnum";
import { ESTIMATE_RESPONSIBLE_INDICATORS, PLANCONFIRM_INDICATORS } from "./constants";
import {
  IIndicatorsInState,
  IUIIndicatorResponse,
  IUiIndicatorsInitialState,
  UIIndicatorsType,
  UI_INDICATORS_PERMISSIONS,
} from "./types";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";
import { ApprovalType } from "../auth-utils.";
import { indicatorMatchesType } from "./utils";

export const deleteIndicator =
  (indicatorId: number, indicatorModules: MODULES_ENUM[], buildingId?: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    return uiIndicatorsApi
      .deleteIndicator(indicatorId)
      .then(() => {
        dispatch(uiIndicatorsActions.deleteIndicator(indicatorId, indicatorModules, buildingId));
        dispatch(uiIndicatorsActions.invalidateKey());
      })
      .catch(errorCatcher);
  };

export const loadUIIndicators =
  (params: Parameters<typeof uiIndicatorsApi.getIndicators>[0]) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(uiIndicatorsActions.setIndicatorsLoading(true));
    return uiIndicatorsApi
      .getIndicators(params)
      .then(({ data }) => {
        dispatch(uiIndicatorsActions.setIndicators(data.results, params.module, data.count, params.buildingId));
      })
      .catch(errorCatcher)
      .finally(() => dispatch(uiIndicatorsActions.setIndicatorsLoading(false)));
  };

export const checkUIIndicator =
  ({
    data,
    type,
    module,
    buildingId,
    customFilter,
  }: {
    data: Partial<IUIIndicatorResponse["data"]>;
    type: UIIndicatorsType | UIIndicatorsType[];
    module: MODULES_ENUM;
    buildingId?: number;
    customFilter?: (_: IUIIndicatorResponse) => boolean;
  }) =>
  (dispatch: Dispatch, getState: () => RootState): IUIIndicatorResponse | undefined => {
    const currentIndicatorsState = getState().uiIndicators;
    const currentUser = getState().auth?.user || {};
    const currentUserPermissions = getState().permissionsV2.userPermissionsList[currentUser?.id];

    let indicatorsByModule = currentIndicatorsState?.[module];
    if (module === MODULES_ENUM.OBJECTS && !!buildingId) {
      indicatorsByModule = (indicatorsByModule as IUiIndicatorsInitialState[MODULES_ENUM.OBJECTS])[buildingId];
    }

    if (!indicatorsByModule) return undefined;

    const dataKeys = Object.keys(data);

    return (indicatorsByModule as IIndicatorsInState).indicators?.find(
      (indicator) =>
        indicatorMatchesType(indicator, type) &&
        dataKeys.every((key) => _.isEqual(data[key], indicator.data[key])) &&
        (!!indicator.for_user_id ? +indicator.for_user_id === +currentUser.id : true) &&
        (!!buildingId ? +indicator.building_id === +buildingId : true) &&
        (!!customFilter ? customFilter(indicator) : true) &&
        currentUserPermissions?.find((el) => el.alias === UI_INDICATORS_PERMISSIONS[indicator.type])?.state === "on" &&
        dispatch(checkApprovalIndicators(indicator))
    );
  };

const checkApprovalIndicators =
  (indicator: IUIIndicatorResponse) =>
  (dispatch: Dispatch, getState: () => RootState): boolean => {
    const user = userSelector(getState());
    const userApprovalsOnBuilding: Record<ApprovalType, boolean> = user.approval[indicator.building_id];
    if (
      ESTIMATE_RESPONSIBLE_INDICATORS.indexOf(indicator.type) !== -1 &&
      !userApprovalsOnBuilding?.is_estimate_responsible
    ) {
      return false;
    }
    if (PLANCONFIRM_INDICATORS.indexOf(indicator.type) !== -1 && !userApprovalsOnBuilding?.is_planconfirm_approval) {
      return false;
    }
    return true;
  };
