import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import { projectsV2IsLoadingSelector, projectsV2ListSelector } from "pages/Projects/model/selectors";
import { getProjectsList } from "pages/Projects/model/thunks";
import ProjectsTableHeader from "pages/Projects/ui/ProjectsTable/ProjectsTableHeader";
import ProjectsTableRow from "pages/Projects/ui/ProjectsTable/ProjectsTableRow";

import styles from "./ProjectsV2Settings.module.css";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import projectsIcon from "images/icons/navigation/estimateIcon.svg";

interface IProps {
  path: string;
}

const ProjectsV2Settings: React.FC<IProps> = ({ path }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<Record<string, string>>({});
  const key = generateStorageKey({ projectId: 0, ...filter } as any);

  const isLoading = useSelector(projectsV2IsLoadingSelector)[key];
  const projects = useSelector(projectsV2ListSelector)[key];

  const rootPath = useUrlModuleWithObjectId();

  const onCreate = () => {
    history.push(`/${rootPath}/settings/project/new/`);
  };

  const onClickProject = (id: number) => {
    history.push(`/${rootPath}/settings/objects/${id}`);
  };

  const onClickSettings = (id: number) => {
    history.push(`/${rootPath}/settings/project/${id}`);
  };

  useEffect(() => {
    dispatch(getProjectsList(filter));
  }, [filter]);

  return (
    <>
      <ProjectsTableHeader onChangeFilter={(name, value) => setFilter((prev) => ({ ...prev, [name]: value }))} />
      {isLoading && (
        <div className={styles.spinner}>
          <Spinner isStatic />
        </div>
      )}
      {!isLoading && !projects?.length && (
        <EmptyPlaceholder text="Нет проектов" img={projectsIcon}>
          <ButtonBase primary onClick={onCreate}>
            Создать проект
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {projects?.map((el) => (
        <ProjectsTableRow project={el} key={el.id} onClick={onClickProject} onSettingsClick={onClickSettings} />
      ))}
    </>
  );
};

export default ProjectsV2Settings;
