import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch, useRouteMatch } from "react-router-dom";

import { aggregationSelector } from "redux/modules/common/building/aggregations";

import ChaptersList from "./components/ChaptersList/ChaptersList";
import Expenditures from "./components/Expenditures/Expenditures";
import Sections from "./components/Sections/Sections";
import { Spinner } from "components/UI/Spinner/Spinner";
import { ChaptersEnum } from "components/pages/Handler/enums";

import { CHAPTERS, ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../constants";
import { EstimateStateId } from "../../types";

import { PricingModes } from "../../hooks/usePricingMode";

import styles from "./Body.module.scss";

export interface IProps {
  match: RouteComponentProps["match"];
  activeEstimateStateId: EstimateStateId;
  areIndicatorsOpen: boolean;
  estimateItemsChecker: any;
  permissions: any;
  sectionsAreLoading: boolean;
  consolidateStateChapter: ChaptersEnum | null;
  onSelectConsolidateStateChapter: (chapterId: ChaptersEnum) => void;
  activePricingMode: PricingModes;
  visibilityChecked?: (id: number) => boolean;
  handleVisibilityCheck?: (item: any) => void;
}

const Body: React.FC<IProps> = ({
  match,
  activeEstimateStateId,
  areIndicatorsOpen,
  estimateItemsChecker,
  permissions,
  sectionsAreLoading,
  consolidateStateChapter,
  onSelectConsolidateStateChapter,
  activePricingMode,
  visibilityChecked,
  handleVisibilityCheck,
}) => {
  const sectionIdMatch = useRouteMatch<{ sectionId: string }>(`${match.path}/:sectionId`);
  const sectionId = sectionIdMatch && sectionIdMatch.params.sectionId;

  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");

  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const aggregations = useSelector(aggregationSelector);

  const calcExpendituresStickyHeaderTop = useCallback(() => {
    if (!isProduction && statusFromQueryParams !== ESTIMATE_ITEM_STATUSES.NEW) {
      return areIndicatorsOpen ? "21.7rem" : "11.7rem";
    }
    return areIndicatorsOpen ? "18rem" : "7.3rem";
  }, [areIndicatorsOpen, isProduction, statusFromQueryParams]);

  const filteredEmptyChapters = useMemo(
    () => CHAPTERS.filter(({ id }) => aggregations[`count_chapter_${id}`] !== 0),
    [aggregations]
  );

  return (
    <div className={styles.bodyContent}>
      <Switch>
        <Route
          exact
          path={`${match.path}/:sectionId/:subsectionId`}
          render={({ match }) => (
            <Expenditures
              {...match.params}
              stickyHeaderTop={calcExpendituresStickyHeaderTop()}
              activeEstimateStateId={activeEstimateStateId}
              checkOnce={estimateItemsChecker.checkOnce}
              permissions={permissions}
              checkerItems={estimateItemsChecker.items}
              activePricingMode={activePricingMode}
              handleVisibilityCheck={handleVisibilityCheck}
              visibilityChecked={visibilityChecked}
            />
          )}
        />
        <Route path={match.path}>
          {activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE && !consolidateStateChapter ? (
            <ChaptersList chapters={filteredEmptyChapters} onClickByChapter={onSelectConsolidateStateChapter} />
          ) : sectionsAreLoading ? (
            <Spinner isStatic />
          ) : (
            <Sections
              activePricingMode={activePricingMode}
              activeEstimateStateId={activeEstimateStateId}
              sectionId={sectionId}
              checkOnce={estimateItemsChecker.checkOnce}
              checkerItems={estimateItemsChecker.items}
              handleVisibilityCheck={handleVisibilityCheck}
              visibilityChecked={visibilityChecked}
            />
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default React.memo(Body);
