import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { passportsInvalidateKeySelector } from "redux/modules/common/passportsAndCertificates/selectors";
import {
  deletePassportDocument,
  deletePassportOrCertificate,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { IAttachmentInExpenditure } from "redux/modules/common/passportsAndCertificates/types";

import StatusElement from "components/UI/atoms/StatusElement/StatusElement";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";
import ConfirmModal from "components/UI/molecules/ConfirmationModal/ConfirmModal";

import { attachmentModalType } from "../../ExpenditureFilesModal/CommonFilesModal";

import { checkExpenditureAttachmentIsWarn } from "../utils";
import downloadFile from "utils/helpers/download";

import { ReactComponent as DownloadIcon } from "images/download.svg";
import CrossInCircle from "images/icons/CrossInCircle";

import styles from "./PassportsTable.module.scss";

interface IProps {
  document: IAttachmentInExpenditure;
  expenditureId?: number;
  onDirectlyDelete?: (docId: number) => void;
  canDelete?: boolean;
}

const PassportsRow: React.FC<IProps> = ({ document, expenditureId, onDirectlyDelete, canDelete = true }) => {
  const invalidateKey = useSelector(passportsInvalidateKeySelector);
  const [isDelete, setIsDelete] = useState(false);
  const dispatch = useDispatch();

  const switchDeletingModal = useCallback(() => {
    setIsDelete((prev) => !prev);
  }, []);

  const deleteDocument = useCallback(() => {
    if (onDirectlyDelete) {
      onDirectlyDelete(document.id);
      return;
    }
    if (expenditureId) {
      dispatch(deletePassportDocument(expenditureId, document.id));
    } else {
      dispatch(deletePassportOrCertificate(document));
    }
  }, [document, expenditureId]);

  useEffect(() => {
    setIsDelete(false);
  }, [invalidateKey]);

  const isWarnStatus = useMemo(() => {
    return checkExpenditureAttachmentIsWarn(document);
  }, [document]);

  const downloadHandler = useCallback(() => {
    const filePath = document.file ?? document.file_path;
    downloadFile(filePath, document.originalname);
  }, [document]);

  const date = document.date || document.start_at || document.end_at;

  return (
    <>
      <TableReusableRow className={styles.row} containerClassName={styles.rowContainer} isHoverable={false}>
        <TableReusableCell isNoBreak>{document.number}</TableReusableCell>
        <TableReusableCell isNoBreak>{document.type_display} </TableReusableCell>
        <TableReusableCell isCentered>
          <StatusElement
            portalClassName={styles.statusPortal}
            className={styles.status}
            dateEnd={document?.end_at}
            status={isWarnStatus ? "fail" : "success"}
            isTransparent
          />
        </TableReusableCell>
        <TableReusableCell isCentered>{date ? moment(date).format("DD.MM.YYYY") : ""}</TableReusableCell>
        <TableReusableCell isCentered>
          <div className={styles.files} onClick={downloadHandler}>
            <DownloadIcon />
          </div>
        </TableReusableCell>
        <TableReusableCell>
          {canDelete && (
            <div onClick={switchDeletingModal} className={styles.delete}>
              <CrossInCircle />
            </div>
          )}
        </TableReusableCell>
      </TableReusableRow>
      {document.title && <div className={styles.title}>{document.title}</div>}
      <ConfirmModal
        isOpen={isDelete}
        onClose={switchDeletingModal}
        action={deleteDocument}
        variant="secondary"
        title="Подтвердите удаление"
      />
    </>
  );
};

export default React.memo(PassportsRow);
