import { createSelector } from "reselect";

import { RootState } from "../../../../rootReducer";

export const stateSelector = (state: RootState) => state.posts;

export const allPostsSelector = createSelector(stateSelector, (state) => state.allPostsList);
export const allPostsDetailedSelector = createSelector(stateSelector, (state) => state.allPostsDetailedList);
export const allPostsDetailedIsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.loadings.allPostsDetailedList
);
export const allPostsIsLoadingSelector = createSelector(stateSelector, (state) => state.loadings.allPostsList);
export const postInModalSelector = createSelector(stateSelector, (state) => state.postInModal);
export const postInModalIsLoadingSelector = createSelector(stateSelector, (state) => state.loadings.postInModal);
