import { debounce } from "lodash";
import moment from "moment";
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";

import { ReactComponent as FilterIcon } from "shared/assets/icons/Filter.svg";
import { ReactComponent as CrossIcon } from "shared/assets/icons/IconClose-small.svg";
import { ReactComponent as SearchIcon } from "shared/assets/icons/IconZoom.svg";

import styles from "./TreeFilterHeader.module.scss";
import cn from "classnames";

interface ITreeFilterHeaderProps {
  setFilterValue: React.Dispatch<SetStateAction<string>>;
  setSelectedPeriod: React.Dispatch<SetStateAction<{ dateEnd: null | string; dateStart: null | string }>>;
  isHideFilters: boolean;
  variant: string;
}

const TreeFilterHeader: React.FC<ITreeFilterHeaderProps> = ({
  setFilterValue,
  setSelectedPeriod,
  isHideFilters,
  variant
}) => {
  const [ inputValue, setInputValue ] = useState("");
  const [ isInputOnFocus, setIsInputOnFocus ] = useState(false);
  const [ dateStart, setDateStart ] = useState<undefined | string>();
  const [ dateEnd, setDateEnd ] = useState<undefined | string>();

  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      setFilterValue(value);
    }, 1500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [ debouncedChangeHandler ]);

  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    debouncedChangeHandler(e.target.value);
  };

  const handleChangeDateStart = useCallback(
    (date: string) => {
      setDateStart(moment(date).format("DD.MM.YYYY"));
      if (!!dateEnd) {
        setSelectedPeriod({
          dateStart: moment(date).format("YYYY-MM-DD"),
          dateEnd: moment(dateEnd, "DD.MM.YYYY").format("YYYY-MM-DD")
        });
      }
    },
    [ dateEnd ]
  );

  const handleChangeDateEnd = useCallback(
    (date: string) => {
      setDateEnd(moment(date).format("DD.MM.YYYY"));
      if (!!dateStart) {
        setSelectedPeriod({
          dateEnd: moment(date).format("YYYY-MM-DD"),
          dateStart: moment(dateStart, "DD.MM.YYYY").format("YYYY-MM-DD")
        });
      }
    },
    [ dateStart ]
  );

  const inputWidth = useMemo(() => {
    const calculatedWidth = inputValue?.length ? (inputValue.length + 3) / 1.65 : 15;
    const width = calculatedWidth > 18 ? 18 : calculatedWidth;

    return { width: `calc(${width}rem)` };
  }, [ inputValue ]);

  const [ clearInvalidateKey, setClearInvalidateKey ] = useState(Math.random());

  return (
    <div className={styles.treeFilterHeader}>
      {isHideFilters && (
        <>
          <div className={styles.flex}>
            <SearchIcon className={isInputOnFocus ? styles.iconFocus : styles.icon} />
            <input
              className={styles.filterInput}
              placeholder="Поиск расценки"
              value={inputValue}
              onChange={handleUserInputChange}
              onFocus={() => setIsInputOnFocus(true)}
              onBlur={() => setIsInputOnFocus(false)}
              style={inputWidth}
            />
            {inputValue && (
              <div
                className={styles.circle}
                onClick={() => {
                  setInputValue("");
                  setFilterValue("");
                }}
              >
                <CrossIcon />
              </div>
            )}
          </div>
          <div className={cn(styles.flex, styles.calendarWrapper)}>
            <CalendarRange
              isHiddenInput
              classNameSelect={styles.calendar}
              setDefaultDateStart={handleChangeDateStart}
              setDefaultDateEnd={handleChangeDateEnd}
              clearInvalidateKey={clearInvalidateKey}
              skipValidation
              children={
                dateStart && dateEnd ? (
                  <>
                    <span className={styles.date}>
                      {dateStart} - {dateEnd}
                    </span>
                    <div
                      className={styles.circle}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDateStart(undefined);
                        setDateEnd(undefined);
                        setSelectedPeriod({ dateEnd: null, dateStart: null });
                        setClearInvalidateKey(Math.random());
                      }}
                    >
                      <CrossIcon />
                    </div>
                  </>
                ) : (
                  <>
                    <span className={styles.periodFilterText}>По периоду</span>
                    <FilterIcon className={styles.periodFilterIcon} />
                  </>
                )
              }
            />
          </div>
          <div className={styles.flexDaysPeriods}>
            {variant === "days" || variant === "period" ? (
              <div className={styles.days}>
                <span>Дней</span>
              </div>
            ) : null}
            {variant === "period" ? (
              <div className={styles.period}>
                <span>Начало&nbsp;-&nbsp;окончание</span>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(TreeFilterHeader);
