import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import ButtonBase from "../../UI/atoms/ButtonBase";
import TabBarNotLinks from "../../UI/atoms/TabBar/TabBarNotLinks";
import MonthScrollBar from "../../UI/molecules/MonthScrollBar/MonthScrollBar";
import MonthsYearsSlider from "../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import JournalActing from "./components/JournalActing/JournalActing";
import JournalDelivery from "./components/JournalDelivery";
import JournalDone from "./components/JournalDone/JournalExecution";
import Ks6a from "./components/Ks6a/Ks6a";
import Remarks from "./components/Remarks/Remarks";
import LabeledSwitch from "components/UI/atoms/LabeledSwitch";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";

import { useKs6aMonthScrollBar } from "./useKs6aMonthScrollBar";

import {
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_ADD_TICKET,
  VIEW_MANUFACTURING_JOURNAL_ACTS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import styles from "./Journal.module.scss";
import { useOpenFactApprove } from "./components/JournalDelivery/JournalTicketModal/hooks/useOpenFactApprove";

const LAPTOP_WIDTH = 1440;

const KS6A_TAB_ID = "ks6a";
const DONE_TAB_ID = "done";
const DELIVERY_TAB_ID = "delivery";
const ACTING_TAB_ID = "acting";
const REMARKS_TAB_ID = "remarks";

const ALLOWED_TABS_IDS = [KS6A_TAB_ID, DONE_TAB_ID, DELIVERY_TAB_ID, ACTING_TAB_ID, REMARKS_TAB_ID];

const Journal = ({ location, match, history }) => {
  const params = useParams();
  const { objectId } = params;
  const haveViewAcceptancePermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE);
  const haveViewProgressPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS);
  const haveViewActsPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACTS);
  const haveAddTicketPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_ADD_TICKET);
  const haveViewKs6Permission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A);
  const haveViewRemarksPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS);

  useEffect(() => {
    if (!haveViewAcceptancePermission && haveViewProgressPermission) {
      setTab(DONE_TAB_ID);
    }
  }, [haveViewAcceptancePermission, haveViewProgressPermission]);

  const activeTabId = useMemo(() => {
    const partOfSectionPath = location.pathname.replace(match.url, "");
    return ALLOWED_TABS_IDS.find((id) => `/${id}` === partOfSectionPath) || DELIVERY_TAB_ID;
  }, [location.pathname, match.url]);

  const tabs = useMemo(() => {
    const res = [];
    if (haveViewProgressPermission && activeTabId === KS6A_TAB_ID)
      res.push({ text: "Выберите", id: KS6A_TAB_ID, isDesktopHidden: true });
    if (haveViewProgressPermission && activeTabId === REMARKS_TAB_ID)
      res.push({ text: "Выберите", id: REMARKS_TAB_ID, isDesktopHidden: true });
    if (haveViewAcceptancePermission) res.push({ text: "Приемка", id: DELIVERY_TAB_ID });
    if (haveViewProgressPermission) res.push({ text: "Выполнение", id: DONE_TAB_ID });
    if (haveViewActsPermission) res.push({ text: "Актирование", id: ACTING_TAB_ID });
    return res;
  }, [haveViewAcceptancePermission, haveViewProgressPermission, haveViewActsPermission, activeTabId]);

  const setTab = useCallback(
    (tabId) => {
      history.push(`${match.url}/${tabId}`);
    },
    [history, match.url]
  );

  const [date, setDate] = useState(moment());
  const year = date.year();
  const month = date.month();
  const {
    handleYearChange: handleKs6aYearChange,
    setTableScrollPosition,
    onArrowClick,
    year: ks6aYear,
    tableViewWidthPercent,
    tableScrollPosition,
  } = useKs6aMonthScrollBar(year);

  const [isShowAddPlan, setIsShowAddPlan] = useState(false);

  const {
    needOpenFact,
    openYear,
    openMonth
  } = useOpenFactApprove();

  useEffect(() => {
    if (needOpenFact) {
      setDate(moment(`${openYear}-${+openMonth}`));
    }
  }, [needOpenFact, openYear, openMonth]);

  useEffect(() => {
    if (!activeTabId) setTab(DELIVERY_TAB_ID);
  }, []);

  const [isGroupedByKs2, setIsGroupedByKs2] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!haveViewAcceptancePermission && haveViewProgressPermission) {
      setTab(DONE_TAB_ID);
    }
    if (!haveViewAcceptancePermission && !haveViewProgressPermission && haveViewActsPermission) {
      setTab(ACTING_TAB_ID);
    }
  }, [haveViewAcceptancePermission, haveViewProgressPermission, haveViewActsPermission, objectId]);

  if (!haveViewAcceptancePermission && !haveViewProgressPermission && !haveViewActsPermission) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <TemplateBase
      contentClassName={cn({
        [styles.templateNarrow]: activeTabId === KS6A_TAB_ID,
      })}
    >
      <ListPageHeaderTemplate
        className={cn(styles.headerTemplate, { [styles.headerTemplateKs6A]: activeTabId === KS6A_TAB_ID })}
      >
        <header
          className={cn(styles.header, {
            [styles.headerNarrow]: activeTabId === KS6A_TAB_ID,
          })}
        >
          <TabBarNotLinks activeId={activeTabId} onClick={setTab} tabs={tabs} className={styles.tabs} />
          {activeTabId === KS6A_TAB_ID && (
            <MonthScrollBar
              className={styles.dateSlider}
              year={ks6aYear}
              handleYearChange={handleKs6aYearChange}
              tableViewWidthPercent={tableViewWidthPercent}
              tableScrollPosition={tableScrollPosition}
              setTableScrollPosition={setTableScrollPosition}
              onArrowClick={onArrowClick}
              showArrows
            />
          )}
          {[DONE_TAB_ID, DELIVERY_TAB_ID].includes(activeTabId) && (
            <MonthsYearsSlider className={styles.dateSlider} date={date} onChange={setDate} />
          )}
          {activeTabId === ACTING_TAB_ID && (
            <LabeledSwitch
              label="Группировать по КС-2"
              value={isGroupedByKs2}
              onChange={setIsGroupedByKs2}
              className={styles.switchGroupBy}
            />
          )}
          {/* {activeTabId === KS6A_TAB_ID && (
            <MeasureBtn
              measure={tableMeasure}
              onMeasureChange={measureSwitchHandler}
              className={styles.measureButton}
            />
          )} */}
          {[DONE_TAB_ID, REMARKS_TAB_ID].includes(activeTabId) && haveViewRemarksPermission && (
            <ButtonBase
              className={cn(styles.button, styles.remarksButton)}
              small={window.innerWidth <= LAPTOP_WIDTH}
              primary={activeTabId === REMARKS_TAB_ID}
              secondary={activeTabId !== REMARKS_TAB_ID}
              onClick={() => setTab(REMARKS_TAB_ID)}
            >
              Замечания
            </ButtonBase>
          )}
          {[KS6A_TAB_ID, ACTING_TAB_ID].includes(activeTabId) && haveViewKs6Permission && (
            <ButtonBase
              className={cn(styles.button, styles.ks6aButton)}
              small={window.innerWidth <= LAPTOP_WIDTH}
              primary={activeTabId === KS6A_TAB_ID}
              secondary={activeTabId !== KS6A_TAB_ID}
              onClick={() => setTab(KS6A_TAB_ID)}
            >
              КС-6а
            </ButtonBase>
          )}
          {activeTabId === DELIVERY_TAB_ID && haveAddTicketPermission && (
            <ButtonBase
              className={cn(styles.button, styles.newTicket)}
              onClick={() => setIsShowAddPlan(true)}
              small={window.innerWidth <= LAPTOP_WIDTH}
              secondary
            >
              <div>
                Добавить <span>запись</span>
              </div>
            </ButtonBase>
          )}
        </header>
      </ListPageHeaderTemplate>
      <JournalDelivery
        isOpen={activeTabId === DELIVERY_TAB_ID}
        objectId={objectId}
        month={month + 1}
        year={year}
        isShowAddPlan={isShowAddPlan}
        setIsShowAddPlan={setIsShowAddPlan}
        onClickAddPlan={() => setIsShowAddPlan(true)}
      />
      {activeTabId === DONE_TAB_ID && haveViewAcceptancePermission && (
        <JournalDone objectId={objectId} month={month + 1} year={year} />
      )}
      {activeTabId === REMARKS_TAB_ID && haveViewRemarksPermission && <Remarks objectId={objectId} />}
      {activeTabId === KS6A_TAB_ID && haveViewKs6Permission && <Ks6a buildingId={objectId} year={+year} />}
      {activeTabId === ACTING_TAB_ID && <JournalActing isGroupedByKs2={isGroupedByKs2} />}
    </TemplateBase>
  );
};

export default Journal;
