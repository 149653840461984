import React from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import ReplacementsModal from "components/pages/Handler/components/Body/components/Expenditures/ReplacedExpenditure/ReplacementsModal/ReplacementsModal";

import { useJournalReplacements } from "./useJournalReplacements";

import { ITicketInJournalDone } from "types/interfaces/Tickets";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditure: IExpendituresInJournalFulfillment["items"][0];
}

const JournalReplacementsModal: React.FC<IProps> = ({ isOpen, onClose, expenditure }) => {
  const objectId = useObjectId();
  const { replacements, isLoading } = useJournalReplacements(
    objectId,
    expenditure.expenditure?.expenditure_id!,
    isOpen
  );

  const ticketItem = { ...(expenditure?.expenditure || {}), estimate_amount: expenditure?.expenditure?.amount };

  return (
    <ReplacementsModal
      isOpen={isOpen}
      onClose={onClose}
      title={ticketItem?.name!}
      justification={ticketItem?.justification}
      //@ts-ignore
      initialExpenditure={ticketItem}
      replacements={replacements}
      isLoading={isLoading}
    />
  );
};

export default JournalReplacementsModal;
