import { chatRoomTypes } from "redux/modules/common/chatV2/types";
import { OBJECTS_DEFAULT_PAGES_ENUM } from "types/enums/ModulesDefaultPageEnum";


export const findChatTypePage = (chatType: chatRoomTypes) => {
  switch (chatType) {
    case chatRoomTypes.SCHEDULE:
      return OBJECTS_DEFAULT_PAGES_ENUM.SCHEDULE;
    case chatRoomTypes.PROJECT:
      return OBJECTS_DEFAULT_PAGES_ENUM.PROJECT;
    case chatRoomTypes.FACT_WORK:
      return OBJECTS_DEFAULT_PAGES_ENUM.FACT_WORK;
    case chatRoomTypes.EMPLOYEES:
      return OBJECTS_DEFAULT_PAGES_ENUM.EMPLOYEES;
    case chatRoomTypes.REQUISITIONS:
      return OBJECTS_DEFAULT_PAGES_ENUM.REQUISITIONS;
    case chatRoomTypes.STOCK:
      return OBJECTS_DEFAULT_PAGES_ENUM.STOCK;
    default:
      break;
  }
};