import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { prepareFiles } from "../components/pages/Requisition/components/ProductFilesModal/utils";

import { IFile } from "../types/interfaces/Files";

import downloadFileByUrl from "../utils/helpers/downloadFileByUrl";
import { errorCatcher } from "../utils/helpers/errorCatcher";

export interface IUseProductFilesModalProps {
  productFiles: any;
  isOpen: boolean;
  uploadFilesCallback?: (addedFiles: any[]) => void;
  deleteFilesCallback?: (file: any) => void;
  fetchFilesCallback?: () => void;
  buildingId?: number | string;
  itemId?: number | string;
}

export const useProductFilesModal = ({
  productFiles,
  isOpen,
  fetchFilesCallback,
  uploadFilesCallback,
  deleteFilesCallback,
  buildingId,
  itemId,
}: IUseProductFilesModalProps) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [filesWasUploaded, setFilesWasUploaded] = useState(false);

  useEffect(() => setFiles(productFiles), [productFiles]);

  useEffect(() => {
    if (!filesWasUploaded && fetchFilesCallback && isOpen) {
      setFilesWasUploaded(true);
      fetchFilesCallback();
    }
  }, [isOpen, filesWasUploaded]);

  const handleFilesChange = (files: React.SetStateAction<IFile[]>) => {
    setFiles(files);
  };

  const uploadFiles = async (files: any[]) => {
    const formData = new FormData();
    files.map((file) => formData.append("files", file.file));
    if (uploadFilesCallback) {
      uploadFilesCallback(files);
    } else {
      await axios
        .post(`/building/${buildingId}/materials/${itemId}/files/`, formData)
        .then((resp) => {
          const tempFiles = [...files].map((el, i) => ({
            ...el,
            file: {
              ...el.file,
              link: resp.data[i].file,
              name: el.file.name || resp.data[i].path,
            },
          }));
          setFiles(tempFiles);
        })
        .catch(errorCatcher);
    }
  };

  const deleteFile = async (file: IFile) => {
    if (deleteFilesCallback) {
      deleteFilesCallback(file);
    } else {
      await axios.delete(`/building/${buildingId}/materials/${itemId}/files/${file.id}/`);
    }
  };

  const downloadFiles = () => {
    const isNewFiles = files.some((el) => !el.file.link);
    if (isNewFiles) {
      message.info("Сохраните прежде, чем выгружать");
      return;
    }
    files.forEach((file) => {
      if (file.file?.link) {
        downloadFileByUrl(file.file.link || file.file, file.file.name || file.name);
      }
    });
  };

  const deleteFiles = async (files: IFile[]) => {
    const deleteFilesFunc = files.map((file) => deleteFile(file));
    await Promise.all(deleteFilesFunc);
  };

  const handleSubmit = async () => {
    const newFiles = files.filter((file) => !file.file.link);
    const deletedFiles = productFiles.filter(
      (propsFile: { id: number }) => !files.find((file) => file.id === propsFile.id)
    );

    try {
      if (newFiles?.length) await uploadFiles(newFiles);
      if (deletedFiles?.length) await deleteFiles(deletedFiles);
      message.success("Файлы успешно сохранены");
    } catch (e) {
      errorCatcher(e);
    }
  };
  const preparedFiles = prepareFiles(files);

  return {
    files,
    preparedFiles,
    downloadFiles,
    handleFilesChange,
    handleSubmit,
  };
};
