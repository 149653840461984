import { createSelector } from "reselect";

import { parentStateSelector } from "../constructing";
import { moduleName as parentModuleName } from "./index";

const initialState = {
  objectId: undefined,
};

export const moduleName = "index";
const actionsPrefix = "constructing/finance";

const SET_OBJECT = `${actionsPrefix}_SET_OBJECT`;
const SET_MEASURE = `${actionsPrefix}_SET_MEASURE`;

export const financeStateSelector = createSelector(parentStateSelector, (state) => state[parentModuleName]);
export const stateSelector = createSelector(financeStateSelector, (state) => state[moduleName]);
export const objectIdSelector = createSelector(stateSelector, (state) => state.objectId);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OBJECT:
      return {
        ...state,
        objectId: payload,
      };
    case SET_MEASURE:
      return {
        ...state,
        measure: payload,
      };
    default:
      return state;
  }
};

export const setObjectId = (id) => ({
  type: SET_OBJECT,
  payload: id,
});

export const setFinanceMeasure = (newMeasure = 1) => ({
  type: SET_MEASURE,
  payload: newMeasure,
});
