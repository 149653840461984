import { IActions } from "redux/store";

import { IOrderInList } from "types/interfaces/Order";

export const ordersListActions = {
  setIsLoading: (status: boolean, key: string) =>
    ({
      type: "ordersList/SET_IS_LOADING",
      payload: { status, key },
    } as const),
  setOrders: (orders: IOrderInList[], key: string, filters: Record<string, string | number>, count: number) =>
    ({
      type: "ordersList/SET_ORDERS",
      payload: { orders, key, filters, count },
    } as const),
  setMoreOrders: (orders: IOrderInList[], key: string, filters: Record<string, string | number>, count: number) =>
    ({
      type: "ordersList/SET_MORE_ORDERS",
      payload: { orders, key, filters, count },
    } as const),
};

export type IOrdersListActions = IActions<typeof ordersListActions>;
