import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  expenditureAttachmentsSelector,
  ks2ExpendituresAttachmentsSelector,
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  attachDocumentToExpenditure,
  attachFileToExpenditure,
  attachKs2DocumentToExpenditure,
  getExpendituresAttachments,
  getKs2ExpenditureAttachments,
} from "redux/modules/common/passportsAndCertificates/thunks";
import { DocumentsTypesEnum, ICreatePassport } from "redux/modules/common/passportsAndCertificates/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import CommonFilesModal from "./CommonFilesModal";
import ExpenditureFilesModal from "./ExpenditureFilesModal";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";
import { IExpenditure } from "types/interfaces/Expenditure";
import { passportsApi } from "../../../redux/modules/common/passportsAndCertificates/api";
import { passportsActions } from "../../../redux/modules/common/passportsAndCertificates/actions";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditureName?: string;
  expenditureId: number;
  expenditureType?: IExpenditure["expenditure_type"];
  ks2Id: number;
}

const ExpenditureKs2FilesModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  expenditureName,
  expenditureId,
  expenditureType,
  ks2Id,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const attachments = useSelector(ks2ExpendituresAttachmentsSelector)[`${ks2Id}_${expenditureId}`];
  const invalidateKey = useSelector(passportsInvalidateKeySelector);
  const isPending = useSelector(passportsPendingSelector);
  const isWork = expenditureType === ExpenditureTypeEnum.work;

  const formattedAttachments = useMemo(() => {
    if (!attachments) return [];
    const res = [];
    for (let key in attachments) {
      res.push(attachments[key]);
    }
    return res;
  }, [attachments]);

  useEffect(() => {
    if (!isOpen) return;
    if (!isWork) return;
    dispatch(getKs2ExpenditureAttachments(objectId, ks2Id, expenditureId));
  }, [expenditureId, isOpen, invalidateKey, ks2Id, objectId]);

  const uploadFile = useCallback(
    (file: File) => {
      if (isWork) {
        dispatch(
          attachKs2DocumentToExpenditure(
            { file: file, type: DocumentsTypesEnum.file } as any,
            expenditureId,
            ks2Id,
            objectId
          )
        );
      } else {
        dispatch(attachDocumentToExpenditure({ file: file, type: DocumentsTypesEnum.file } as any, expenditureId));
      }
    },
    [expenditureId, ks2Id]
  );

  const onSubmit = useCallback(
    (values: ICreatePassport) => {
      if (isWork) {
        dispatch(attachKs2DocumentToExpenditure(values, expenditureId, ks2Id, objectId));
      } else {
        dispatch(attachDocumentToExpenditure(values, expenditureId));
      }
    },
    [expenditureId, ks2Id, expenditureType, objectId]
  );

  const onDirectlyDeleteWorkDocument = useCallback((id: number) => {
    // Передается в компонент CommonFilesModal и удаляет только
    // документы для расценки работы

    // Расценки для ресурсов (материал, оборудование и т. д. удаляются правильно
    // внутри ExpenditureFilesModal)

    passportsApi.deleteWorkAttachment(+objectId, id).then(() => {
      dispatch(passportsActions.invalidateKey());
    })
  }, [objectId, dispatch]);

  if (!isWork) {
    return (
      <ExpenditureFilesModal
        isOpen={isOpen}
        onClose={onClose}
        expenditureId={expenditureId}
        expenditureType={expenditureType}
        expenditureName={expenditureName}
      />
    );
  }

  return (
    <CommonFilesModal
      isOpen={isOpen}
      onClose={onClose}
      name={expenditureName}
      expenditureType={expenditureType}
      attachments={formattedAttachments}
      isPending={isPending}
      expenditureId={expenditureId}
      invalidateKey={invalidateKey}
      onUpload={uploadFile}
      onSubmit={onSubmit}
      onDirectlyDelete={onDirectlyDeleteWorkDocument}
    />
  );
};

export default ExpenditureKs2FilesModal;
