import React from "react";

import { Spinner } from "components/UI/Spinner/Spinner";
import BlueLabel from "components/UI/atoms/BlueLabel";
import FileRow from "components/UI/atoms/FileRow/FileRow";
import DayWorkerAndEvent from "components/UI/organism/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent";
import ManufacturingModalDivider from "components/UI/organism/WorkOrMaterialsModals/components/ManufacturingModalDivider/ManufacturingModalDivider";
import ModalContainer from "components/UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import PairOfInfoItems from "components/UI/organism/WorkOrMaterialsModals/components/PairOfInfoItems/PairOfInfoItems";
import ModalDivider from "components/modals/atoms/ModalDivider/ModalDivider";
import DateTimeCalendar from "components/pages/Tasks/components/DateTimeCalendar/DateTimeCalendar";

import { IReplacementInExpenditure } from "../ReplacedExpenditure";
import ReplacementItem from "./ReplacementItem";

import { IIdAndName } from "types/idAndName";
import { IExpenditure } from "types/interfaces/Expenditure";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./ReplacementsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  replacements: IReplacementInExpenditure[];
  justification?: string;
  initialExpenditure: Pick<IExpenditure, "name" | "justification" | "measure" | "count"> & {
    author: IIdAndName;
    updated_at: string;
    indicators?: {
      estimate_amount: number;
    };
  };
  isLoading?: boolean;
}

const ReplacementsModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  title,
  replacements,
  justification,
  initialExpenditure,
  isLoading,
}) => {
  const restReplacements = React.useMemo(() => {
    if (!replacements) return [];
    return replacements.slice(1);
  }, [replacements]);

  if (isLoading) {
    return (
      <ModalContainer isOpen={isOpen} onClose={onClose} name={title} justification={justification} isJustificationLeft>
        <Spinner />
      </ModalContainer>
    );
  }
  if (!replacements?.length) return null;

  const lastReplacement = replacements[0];

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} name={title} justification={justification} isJustificationLeft>
      <DayWorkerAndEvent date={initialExpenditure.updated_at} label="Изменил:" author={initialExpenditure.author} />
      <DayWorkerAndEvent date={replacements[0].created_at} label="Согласовал:" author={replacements[0].author} />
      <ReplacementItem replacement={lastReplacement} isLast />
      {restReplacements?.map((el, i, arr) => (
        <ReplacementItem key={el.id} replacement={el} isLast={false} />
      ))}
      <ModalDivider text="Актуальная" icon="check" />
      <div className={styles.name}>{initialExpenditure.name}</div>
      <BlueLabel className={styles.justification} blueFont>
        {initialExpenditure.justification}
      </BlueLabel>
      <PairOfInfoItems
        label1="Кол-во:"
        label2="Стоимость:"
        value1={initialExpenditure.count + " " + initialExpenditure.measure}
        value2={splitThousands(replacements?.[0]?.estimate_amount) as string}
      />
      {!!lastReplacement.covering_letter_file_name && (
        <FileRow
          /* @ts-ignore */
          file={{ name: lastReplacement.covering_letter_file_name, file: lastReplacement.covering_letter_file }}
          canDownload
          download={(link) => {
            /* @ts-ignore */
            downloadFile(link.file, lastReplacement.covering_letter_file_name);
          }}
        />
      )}
      {!!lastReplacement.drawing_file && (
        <FileRow
          /* @ts-ignore */
          file={{ name: lastReplacement.drawing_file_name, file: lastReplacement.drawing_file }}
          canDownload
          download={(link) => {
            /* @ts-ignore */
            downloadFile(link.file, lastReplacement.drawing_file_name);
          }}
        />
      )}
    </ModalContainer>
  );
};

export default ReplacementsModal;
