import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  auditTableDataSelector,
  auditTreeSelector,
  isAuditsLoadingSelector,
} from "../../../../../../redux/modules/common/constructing/finance/audit/selectors";
import { prepareAuditTableDataByProjects } from "redux/modules/common/constructing/finance/audit/utils";

import { Spinner } from "components/UI/Spinner/Spinner";

import Table from "./Table/Table";

import styles from "./TableSide.module.scss";

const TableSide = () => {
  const isLoading = useSelector(isAuditsLoadingSelector);
  const tableData = useSelector(auditTableDataSelector);
  const rawTableData = useSelector(auditTreeSelector);
  const { objectId, projectId } = useParams();

  const data = useMemo(() => {
    return prepareAuditTableDataByProjects(rawTableData, projectId, objectId);
  }, [rawTableData, objectId, projectId]);

  if (isLoading && !tableData?.length) return <Spinner isStatic />;

  return (
    <div className={styles.tableSide}>
      <Table data={data} />
    </div>
  );
};

export default React.memo(TableSide);
