import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";

function TableHeader({
  onFiltersChange,
  lastColName,
  preLastColName,
  prePreLastColName,
  filter_name = "product_building_name",
  filter_section = "section",
}) {
  return (
    <TableReusableHead isSticky isWithEar>
      <TableReusableHeaderCell
        onChangeFilter={onFiltersChange}
        filterField="row_number"
        filterTitle="№"
        filterType="text"
      >
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        onChangeFilter={onFiltersChange}
        filterField={filter_name}
        filterTitle="Наименование"
        filterType="text"
      >
        Наименование
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        onChangeFilter={onFiltersChange}
        filterField={filter_section}
        filterTitle="Категория"
        filterType="text"
      >
        Категория
      </TableReusableHeaderCell>
      <TableReusableHeaderCell isCentered>Ед.изм.</TableReusableHeaderCell>
      <TableReusableHeaderCell isCentered>{prePreLastColName}</TableReusableHeaderCell>
      <TableReusableHeaderCell isCentered>{preLastColName}</TableReusableHeaderCell>
      <TableReusableHeaderCell isCentered>{lastColName}</TableReusableHeaderCell>
    </TableReusableHead>
  );
}

export default TableHeader;
