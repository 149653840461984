import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  journalAcceptanceDetailedTicketsSelector,
  journalAcceptanceTicketsIsLoadingSelector,
} from "redux/modules/common/building/journal/acceptance/selectors";
import { getDetailedTicketInJournalAcceptance } from "redux/modules/common/building/journal/acceptance/thunks";
import { IJournalAcceptanceTicketItem } from "redux/modules/common/building/journal/acceptance/types";
import { journalTicketsInvalidateKeySelector } from "redux/modules/common/building/journal/selectors";

import ModalContainer from "../../../../../UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import JournalTicketModalDisplay from "./JournalTicketModalDisplay";
import JournalTicketModalEdit from "./JournalTicketModalEdit";

import {
  VIEW_MANUFACTIRING_JOURNAL_ACCEPTANCE_EDIT_TICKET,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_TICKET_DELETE_FILE,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import { generateStorageKey } from "utils/helpers/generateStorageKey";
import { useCanApproveFact } from "./hooks/useCanApproveFact";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
  ticketData: IJournalAcceptanceTicketItem;
  type: "expenditure" | "group";
  parentName?: string;
  sectionId: number;
}

const JournalTicketModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  ticketData,
  type,
  parentName,
  sectionId,
  onApprove
}) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const ticketItem = ticketData?.expenditure ?? ticketData?.group;
  const haveDeleteFilesPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_TICKET_DELETE_FILE);
  const haveEditTicketPermission = usePermission(VIEW_MANUFACTIRING_JOURNAL_ACCEPTANCE_EDIT_TICKET);
  const [isEdit, setIsEdit] = useState(false);

  const canEditTicket = haveEditTicketPermission && !ticketItem.is_confirmed && !ticketItem.confirmed_approvals;

  const key = generateStorageKey({ ticketId: ticketItem.id, type });
  const detailedData = useSelector(journalAcceptanceDetailedTicketsSelector)[key];
  const isLoading = useSelector(journalAcceptanceTicketsIsLoadingSelector)[key];

  const isGroup = !!ticketData?.group;

  const canApproveFact = useCanApproveFact(detailedData?.approvals);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getDetailedTicketInJournalAcceptance(objectId, ticketItem.id, type));
  }, [ticketData, isOpen, type, objectId]);

  const [files, setFiles] = useState<any>([]);
  const [isFilesLoading, setIsFilesLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    setIsFilesLoading(true);
    Axios.get(`/building/${objectId}/fact-intervals/${ticketItem?.id}/files/`)
      .then(({ data }) => {
        setFiles(data.results);
      })
      .finally(() => {
        setIsFilesLoading(false);
      });
  }, [isOpen]);

  const uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    setIsFilesLoading(true);
    Axios.post(`/building/${objectId}/fact-intervals/${ticketItem?.id}/files/`, formData).then((resp) => {
      Axios.get(`/building/${objectId}/fact-intervals/${ticketItem?.id}/files/`)
        .then((resp) => {
          setFiles(resp.data.results);
        })
        .finally(() => {
          setIsFilesLoading(false);
        });
    });
  };

  const removeFile = (fileId: number) => {
    setIsFilesLoading(true);
    Axios.delete(`/building/${objectId}/fact-intervals/${ticketItem?.id}/files/${fileId}/`).then((resp) => {
      Axios.get(`/building/${objectId}/fact-intervals/${ticketItem?.id}/files/`)
        .then((resp) => {
          setFiles(resp.data.results);
        })
        .finally(() => {
          setIsFilesLoading(false);
        });
    });
  };

  const closeHandler = () => {
    onClose && onClose();
  };

  return (
    <ModalContainer onClose={closeHandler} isOpen={!!(isOpen && ticketData)} name={ticketItem?.name}>
      {isLoading && <Spinner />}
      {!isEdit && !isLoading && (
        <JournalTicketModalDisplay
          isOpen={isOpen}
          ticketData={ticketData}
          detailedData={detailedData}
          parentName={parentName}
          fileInput={
            !isGroup &&
            !isLoading && (
              <div style={{ marginBottom: "1rem" }}>
                <ExpandableFileInput
                  files={files || []}
                  onRemoveFileDirectly={removeFile}
                  onUploadFileDirectly={uploadFile}
                  canDeleteFiles={haveDeleteFilesPermission}
                  isLoading={isFilesLoading}
                />
              </div>
            )
          }
        />
      )}
      {isEdit && (
        <JournalTicketModalEdit
          ticketData={ticketData}
          detailedData={detailedData}
          parentName={parentName}
          sectionId={sectionId}
          onClose={() => {
            setIsEdit(false)
            closeHandler();
          }}
          onCancel={() => setIsEdit(false)}
          fileInput={
            !isGroup &&
            !isLoading && (
              <ExpandableFileInput
                files={files || []}
                onRemoveFileDirectly={removeFile}
                onUploadFileDirectly={uploadFile}
                canDeleteFiles={haveDeleteFilesPermission}
                isLoading={isFilesLoading}
              />
            )
          }
        />
      )}
      {!isEdit && (canEditTicket || canApproveFact) && (
        <BottomControls isExists isDoubleBtns>
          {canEditTicket && (
            <ButtonBase
              primary
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setIsEdit(true);
              }}
            >
              Редактировать
            </ButtonBase>
          )}
          {canApproveFact && (
            <ButtonBase
              primary
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onApprove();
              }}
            >
              Согласовать
            </ButtonBase>
          )}
        </BottomControls>
      )}
    </ModalContainer>
  );
};

export default JournalTicketModal;
