import cn from "classnames";
import React from "react";

import { WorkStatusType, MONTH_COLOR_MAP as colorMap } from "../../../../../../constants";
import { IWorkStatusPercentage } from "../../../../../../types";

import styles from "../../InfoPopup.module.scss";

const STATUSES: Record<WorkStatusType, { class: string; name: string }> = {
  topay: {
    class: styles.paid,
    name: "К оплате",
  },
  todo: {
    class: styles.todo,
    name: "К сдаче",
  },
  confirmed: {
    class: styles.confirmed,
    name: "Подтверждено",
  },
  received: {
    class: styles.received,
    name: "Принято",
  },
  doned: {
    class: styles.doned,
    name: "Выполнено",
  },
  default: {
    class: styles.default,
    name: "Запланировано",
  },
};

export interface IStatusBlockProps {
  status: WorkStatusType;
  percentage?: IWorkStatusPercentage;
  count?: string;
  measure?: string;
}

const StatusBlock: React.FC<IStatusBlockProps> = ({ status, percentage, count, measure }) => {
  if (STATUSES[status]) {
    return (
      <div className={styles.statusBlock}>
        <div
          className={cn(styles.status, STATUSES[status].class)}
          style={{ backgroundColor: colorMap[status], borderColor: colorMap[status + "Border"] }}
        />
        <div className={styles.indicators}>
          <span className={styles.statusName}>{STATUSES[status].name}</span>
          {percentage && <span className={styles.statusPercentage}>{Math.round(percentage[status] * 100)}%</span>}
          {count !== undefined && (
            <span className={cn(styles.statusCount, styles.textPrimary)}>{`${count} ${
              measure ? `(${measure})` : ""
            }`}</span>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default React.memo(StatusBlock);
