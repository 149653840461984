import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { aookCretingPendingSelector, currentAookSelector } from "redux/modules/common/building/journal/aook/selectors";
import { createAook, publishAook } from "redux/modules/common/building/journal/aook/thunks";
import { AookTypes, ICreateAook } from "redux/modules/common/building/journal/aook/types";
import { AosrTypes } from "redux/modules/common/building/journal/aosr/types";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useCreateAookMaterials } from "./useAookMaterials";
import { FormState } from "final-form";

import { initialAosrDoc, initialAosrForm } from "../Aosr/constants";

import { getNormalizedAookPermit } from "./utils";

interface IProps {
  type: AookTypes;
  ticket: IExpendituresInJournalFulfillment["items"][0];
  groupId?: number;
  onClose: () => void;
}

export const useAookCreate = ({ type, ticket, groupId, onClose }: IProps) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const currentAook = useSelector(currentAookSelector);
  const expenditureId = ticket?.expenditure?.expenditure_id ?? ticket?.group?.group_id;
  const isCreatingPending = useSelector(aookCretingPendingSelector)[objectId];

  const { allMaterials, addMaterial, deleteMaterial } = useCreateAookMaterials({
    currentMaterials: currentAook?.all_materials!,
  });

  const [aook, setAook] = React.useState<Partial<ICreateAook> & { id?: number }>({
    ...initialAosrForm,
  } as Partial<ICreateAook>);

  const changeAookDateField = (field: string) => (date: moment.Moment) => {
    setAook((prev) => ({ ...prev, [field]: moment(date).format("YYYY-MM-DD") }));
  };

  //@ts-ignore
  const [aosrDocs, setAosrDocs] = React.useState<IAosrCreatingDocument[]>([initialAosrDoc]);

  const addAosrDoc = React.useCallback(() => {
    //@ts-ignore
    setAosrDocs((prev) => [...prev, { doc_date: "", doc_type: "", title: "", files: [] }]);
  }, []);

  const removeAosrDoc = React.useCallback((i: number) => {
    setAosrDocs((prev) =>
      prev.map((el, idx) => {
        if (idx == i) {
          return { ...el, isHidden: true };
        } else {
          return el;
        }
      })
    );
  }, []);

  const _createAookHandler = (values: Partial<ICreateAook>, isPublishing: boolean) => {
    if (isCreatingPending) return;
    const normalizedPermit = getNormalizedAookPermit(values);

    let group = undefined;

    if (type === AookTypes.FOR_GROUP) {
      group = expenditureId;
    } else if (type === AookTypes.FOR_GROUP_EXP) {
      group = groupId
    }

    const aookData = {
      ...values,
      ...normalizedPermit,
      type,
      act_date: moment(values.act_date).format("YYYY-MM-DD"),
      start_at: aook.start_date as string,
      end_at: aook.end_date as string,
      expenditure: type !== AookTypes.FOR_GROUP ? expenditureId : undefined,
      group,
      materials: (allMaterials || []).filter((el) => !el.was_deleted).map((el) => el.id),
      hiddenworks: values.hiddenworks || [],
      docs: (values.docs || [])
        .filter((el, i) => !aosrDocs[i].isHidden)
        .map((doc) => ({ ...doc, doc_date: moment(doc.doc_date).format("YYYY-MM-DD") })),
    };

    aookData.next_expenditure = values.next_expenditure;
    aookData.next_expenditure_id = values.next_expenditure_id;
    aookData.next_group = values.next_group;
    aookData.next_group_id = values.next_group_id;

    if (isPublishing) {
      dispatch(publishAook(objectId, aookData as ICreateAook));
    } else {
      dispatch(createAook(objectId, aookData as ICreateAook));
    }
  };

  const changeFormWatcher = (formState: FormState<ICreateAook, Partial<ICreateAook>>) => {
    const { number, structure_name, project_doc, accordance } = formState?.values || {};
    const isSomeRequiredFieldEmpty = [number, structure_name, project_doc, accordance].some((el) => !el);
    if (!isSomeRequiredFieldEmpty && !currentAook) {
      _createAookHandler(formState.values, false);
    }
  };

  const submitFormHandler = (values: Partial<ICreateAook>) => {
    _createAookHandler(values, true);
  };

  return {
    changeAookDateField,
    aook,
    changeFormWatcher,
    submitFormHandler,
    allMaterials,
    addMaterial,
    deleteMaterial,
    aosrDocs,
    addAosrDoc,
    removeAosrDoc,
    currentAook,
  };
};
