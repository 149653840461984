import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { eventCountSelector } from "redux/modules/common/notifications";

import ContentWithCount from "../../../../atoms/ContentWithCount/ContentWithCount";
import MenuItem from "../../../NavigationBar/MenuItem";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import CalendarEvents from "images/icons/navigation/CalendarEvents";

import styles from "./Calendar.module.scss";
import { useTranslation } from "react-i18next";

const Calendar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const fullUrlModule = useUrlModuleWithObjectId();

  const eventCount = useSelector(eventCountSelector);

  return (
    <MenuItem
      isDirectlyActive={locationArray.includes("events")}
      isExists
      title={t("События")}
      link={`/${fullUrlModule}/events/calendar`}
      isBottom
      jsxIcon={
        <ContentWithCount
          count={eventCount}
          isCountDisplayed={eventCount > 0}
          countClassName={styles.notyCounter}
          containerClassName={styles.notyCounterBlock}
        >
          <CalendarEvents />
        </ContentWithCount>
      }
      icon={undefined}
      onClick={undefined}
    />
  );
};

export default React.memo(Calendar);
