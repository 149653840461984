import cn from "classnames";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { makeExport } from "../../../../redux/modules/common/building/documents/exports";
import { objectsBreadcrumbsSelector } from "../../../../redux/modules/common/building/objects";

import ButtonBase from "../../../UI/atoms/ButtonBase";
import Modal from "../../../UI/atoms/Modal";
import Select from "../../../UI/atoms/Select";
import CalendarRange from "../../../UI/molecules/CalendarRange/CalendarRange";

import { uploadSvg } from "./exportSvg";

import { useLocationArray } from "../../../../utils/hooks/useLocationArray";

import { composeFieldValidators, required } from "../../../../utils/formHelpers/validations";

import arrowSelectSmallBlue from "../../../../images/icons/arrowSelectSmallBlue.svg";

import styles from "./MakeExport.module.scss";

const EXPORTED_DOCS = [
  {
    id: "invoice",
    name: "Счет",
  },
  {
    id: "packinglist",
    name: "УПД",
  },
];

function MakeExport({ onTabClick, isOpenExportModal, setIsOpenExportModal }) {
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());

  const dispatch = useDispatch();
  const projects = useSelector(objectsBreadcrumbsSelector);

  const locationArray = useLocationArray();

  const buildingsOptions = useMemo(() => {
    if (!projects) return;
    return projects.results.map((building) => ({
      id: building.id,
      name: building.name,
    }));
  }, [projects]);

  const submitHandler = useCallback(
    async (values) => {
      const exported = {
        ...values,
        start_at: moment(dateStart).format("YYYY-MM-DD"),
        end_at: moment(dateEnd).format("YYYY-MM-DD"),
      };

      await compose(
        dispatch,
        makeExport
      )(exported).then(() => {
        if (onTabClick) onTabClick();
      });
      setIsOpenExportModal(false);
    },
    [dateEnd, dateStart, onTabClick, setIsOpenExportModal]
  );

  return (
    <>
      <div className={cn(styles.btn, { [styles.activeBtn]: locationArray.includes("export") })}>
        <button className={styles.reestr} onClick={onTabClick} data-testid="tab_export">
          Экспорт
        </button>
        <button className={styles.upload} onClick={() => setIsOpenExportModal(true)}>
          {uploadSvg}
        </button>
      </div>
      <Modal
        isOpen={isOpenExportModal}
        onClose={() => setIsOpenExportModal(false)}
        title="Выгрузка"
        className={styles.modal}
      >
        <Form
          onSubmit={submitHandler}
          render={({ handleSubmit, hasValidationErrors }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.selects}>
                <div>
                  <div className={styles.label}>Период</div>
                  <Field
                    name="date"
                    component={CalendarRange}
                    defaultDateStart={dateStart}
                    setDefaultDateStart={setDateStart}
                    defaultDateEnd={dateEnd}
                    setDefaultDateEnd={setDateEnd}
                  />
                </div>
                <div>
                  <Field
                    name="building_id"
                    label="Объект"
                    component={Select}
                    icon={arrowSelectSmallBlue}
                    options={buildingsOptions}
                    validate={composeFieldValidators(required())}
                  />
                </div>
                <div>
                  <Field
                    name="doc_type"
                    label="Тип документа"
                    component={Select}
                    icon={arrowSelectSmallBlue}
                    options={EXPORTED_DOCS}
                    validate={composeFieldValidators(required())}
                  />
                </div>
              </div>

              <div className={styles.btns}>
                <ButtonBase primary big type="submit" disabled={hasValidationErrors}>
                  Выгрузить
                </ButtonBase>
              </div>
            </form>
          )}
        />
      </Modal>
    </>
  );
}

export default React.memo(MakeExport);
