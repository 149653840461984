import cn from "classnames";
import React from "react";

import Icon from "../../../../../UI/Icon/Icon";
import ButtonBase from "../../../../../UI/atoms/ButtonBase";

import zoomOutIcon from "images/icons/zoom-out.svg";

import styles from "./ZoomOutBtn.module.scss";

export interface IZoomOutBtnProps {
  zoomOut: () => void;
  className?: string;
}

const ZoomOutBtn: React.FC<IZoomOutBtnProps> = ({ zoomOut, className }) => {
  return (
    <ButtonBase onClick={zoomOut} quaternary className={cn(styles.zoomOutBtn, className)}>
      <Icon icon={zoomOutIcon} />
    </ButtonBase>
  );
};

export default React.memo(ZoomOutBtn);
