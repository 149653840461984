import {
  IAnalyticsFulfillmentBuildingData,
  IAnalyticsFulfillmentSectionData,
  IAnalyticsFulfillmentWorkData,
} from "../../../../../redux/modules/common/constructing/analytics/types/fulfillment";

import { FULFILLMENT_COLORS } from "./constants";

export const makeLinearGradient = (colorFrom: string, colorTo: string, percent: number) =>
  `linear-gradient(to right, ${colorFrom} 0%, ${colorTo} ${percent}%, transparent ${percent + 0.001}%)`;

export const defineFulfillmentStatus = (
  fulfillment: IAnalyticsFulfillmentWorkData | IAnalyticsFulfillmentSectionData | IAnalyticsFulfillmentBuildingData
) => {
  let chip: { color: string; text: string } | null = null;
  let factPercentColor: FULFILLMENT_COLORS;
  let progressFill: string;
  const percentDiff = fulfillment.current_plan_percent - fulfillment.current_fact_percent;
  const hasPlanAndFact = !!fulfillment.current_plan_percent && !!fulfillment.current_fact_percent;
  if (percentDiff <= 0 && hasPlanAndFact) {
    if (percentDiff < 0) {
      chip = {
        color: FULFILLMENT_COLORS.GREEN,
        text: "С опережением",
      };
    }
    factPercentColor = FULFILLMENT_COLORS.GREEN;
    progressFill = [
      makeLinearGradient(FULFILLMENT_COLORS.LIGHTGREEN, FULFILLMENT_COLORS.GREEN, fulfillment.current_fact_percent),
      makeLinearGradient(FULFILLMENT_COLORS.PLAN, FULFILLMENT_COLORS.PLAN, fulfillment.current_plan_percent),
    ].join(",");
  } else if (percentDiff < 30 && hasPlanAndFact) {
    factPercentColor = FULFILLMENT_COLORS.YELLOW;
    progressFill = [
      makeLinearGradient(FULFILLMENT_COLORS.RED, FULFILLMENT_COLORS.YELLOW, fulfillment.current_fact_percent),
      makeLinearGradient(FULFILLMENT_COLORS.PLAN, FULFILLMENT_COLORS.PLAN, fulfillment.current_plan_percent),
    ].join(",");
  } else {
    if (hasPlanAndFact) {
      chip = {
        color: FULFILLMENT_COLORS.RED,
        text: "Срыв сроков!",
      };
      factPercentColor = FULFILLMENT_COLORS.RED;
    } else {
      factPercentColor = FULFILLMENT_COLORS.GRAY;
    }
    progressFill = [
      makeLinearGradient(FULFILLMENT_COLORS.RED, FULFILLMENT_COLORS.RED, fulfillment.current_fact_percent),
      makeLinearGradient(FULFILLMENT_COLORS.PLAN, FULFILLMENT_COLORS.PLAN, fulfillment.current_plan_percent),
    ].join(",");
  }
  return {
    chip,
    progressFill,
    factPercentColor,
  };
};
