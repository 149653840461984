import { IProjectTreeResponse } from "../../../redux/modules/common/building/manufacturing/types";

import { WORKS_TAB_ID } from "../Manufacturing/constants";
import {
  ExpandedBranchesType,
  IProcessedBranch,
  ISpittingTreeElement,
  ManufacturingTabsType,
} from "../Manufacturing/types";

import { shouldShowSectionInTree, sortManufacturingExpendituresAndGroups } from "../Manufacturing/utils";

export interface ISpittingTreeGenerationFnProps {
  projectTree: IProjectTreeResponse;
  tab: ManufacturingTabsType;
  expandedBranches: ExpandedBranchesType;
  isFirstTouch?: boolean;
  showOnlyEstimate?: boolean;
}

const calculateTotalTreeEntriesCounts = (
  projectTree: IProjectTreeResponse,
  tab: ManufacturingTabsType
): { lsr: number; sections: number; expendituresAndGroups: number } => {
  let lsr = 0;
  let sections = 0;
  let expendituresAndGroups = 0;
  projectTree?.sections?.forEach((lsrElem) => {
    const hasToBeShown = shouldShowSectionInTree(lsrElem.count_expenditures, tab) && !!lsrElem.subsections?.length;
    if (!hasToBeShown) return;
    lsr++;
    lsrElem?.subsections?.forEach((sectionElem) => {
      const hasToBeShown = shouldShowSectionInTree(sectionElem.count_expenditures, tab);
      if (!hasToBeShown) return;
      sections++;
      const expendituresInGroups = sectionElem.groups?.flatMap((x) => x?.expenditure_numbers || []) || [];
      expendituresAndGroups +=
        sectionElem.expenditures?.filter((x) => !expendituresInGroups.includes(Number(x.number)))?.length +
          (tab === WORKS_TAB_ID ? sectionElem.groups?.length : 0) || 0;
    });
  });
  return { lsr, sections, expendituresAndGroups };
};

export const spittingTreeGenerationFn = ({
  projectTree,
  tab,
  expandedBranches,
  isFirstTouch,
  // showOnlyEstimate
}: ISpittingTreeGenerationFnProps) => {
  const newSpittingTree: ISpittingTreeElement[] = [];
  const counts = calculateTotalTreeEntriesCounts(projectTree, tab);
  projectTree?.sections?.map((section) => {
    let hasToBeShown = shouldShowSectionInTree(section.count_expenditures, tab);
    hasToBeShown = hasToBeShown && !!section.subsections?.length;
    // if (tab === WORKS_TAB_ID) {
    //   hasToBeShown = hasToBeShown && (showOnlyEstimate ? section.is_default === false : section.is_default === true);
    // }
    if (!hasToBeShown) return;
    const shouldAutoExpandLsr = (counts.lsr < 10 && counts.sections < 10) || counts.lsr === 1;
    if (isFirstTouch && shouldAutoExpandLsr) expandedBranches.add(section.id);
    newSpittingTree.push({
      id: section.id,
      name: section.name,
      lvl: 1,
      childCount: section.subsections?.length || 0,
      // count_work: showOnlyEstimate
      //   ? section.count_expenditures.estimate?.work
      //   : section.count_expenditures.work,
      count_work: section.count_expenditures.work,
      count_material: section.count_expenditures.material,
      count_mims:
        section.count_expenditures.equipment +
        section.count_expenditures.machine +
        section.count_expenditures.transport,
      count_resources:
        section.count_expenditures.equipment +
        section.count_expenditures.machine +
        section.count_expenditures.transport +
        section.count_expenditures.material,
      count_equipment: section.count_expenditures.equipment,
      data: section,
      collapsed: !expandedBranches.has(section.id),
    });
    if (expandedBranches.has(section.id)) {
      section.subsections?.length &&
        section.subsections.forEach((subsection) => {
          let hasSubsectionToBeShown = shouldShowSectionInTree(subsection.count_expenditures, tab);
          // if (tab === WORKS_TAB_ID) {
          //   hasSubsectionToBeShown = hasSubsectionToBeShown && (showOnlyEstimate ? subsection.is_default === false : subsection.is_default === true)
          // }
          if (!hasSubsectionToBeShown) return;

          const expendituresCountInSection =
            subsection.expenditures?.length + (tab === WORKS_TAB_ID ? subsection.groups?.length : 0) || 0;
          if (isFirstTouch && shouldAutoExpandLsr && counts.expendituresAndGroups < 10)
            expandedBranches.add(subsection.id);
          newSpittingTree.push({
            id: subsection.id,
            name: subsection.name,
            lvl: 2,
            childCount: expendituresCountInSection,
            count_work: subsection.count_expenditures.work,
            count_material: subsection.count_expenditures.material,
            count_mims:
              subsection.count_expenditures.equipment +
              subsection.count_expenditures.machine +
              subsection.count_expenditures.transport,
            count_resources:
              subsection.count_expenditures.equipment +
              subsection.count_expenditures.machine +
              subsection.count_expenditures.transport +
              subsection.count_expenditures.material,
            count_equipment: subsection.count_expenditures.equipment,
            data: subsection,
            collapsed: !expandedBranches.has(subsection.id),
            parentId: section.id,
          });
          if (expandedBranches.has(subsection.id)) {
            sortManufacturingExpendituresAndGroups(
              subsection?.expenditures,
              tab === WORKS_TAB_ID ? subsection?.groups : []
            ).map((expenditureOrGroup) => {
              newSpittingTree.push({
                id: expenditureOrGroup.id,
                name: expenditureOrGroup.name,
                lvl: 3,
                childCount: 0,
                data: expenditureOrGroup,
                collapsed: false,
                parentId: subsection.id,
              });
            });
          }
        });
    }
  });
  return newSpittingTree;
};

export const checkIsMonthBranchShownAsSection = (branch: IProcessedBranch) => branch.lvl < 3 && branch.collapsed;

export const checkIsMonthBranchShownAsExpenditure = (branch: IProcessedBranch) => branch.lvl === 3;

export const checkIsShownSectionPlan = (branch: IProcessedBranch) =>
  (branch.lvl === 1 && branch.collapsed) || branch.lvl === 2;

export const checkIsShownSuperSectionPlan = (branch: IProcessedBranch) => branch.lvl === 1;
