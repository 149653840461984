import React, { useEffect, useMemo } from "react";

import EstimateSection from "../../../../../../../../UI/molecules/EstimateSection/EstimateSection";
import { AMOUNTS_VARIANTS } from "../../../../../../../../UI/molecules/EstimateSection/components/Amounts/Amounts";

import { useDrag } from "react-dnd";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../../../constants";

import { getSectionAmount } from "../../../../../../utils/getSectionAmount";

const Section = ({
  section,
  isChecked,
  check,
  activeEstimateStateId,
  canChangeEstimateState,
  actions,
  activePricingMode,
  changeDiscountCost,
  changeCost,
  isDisplayAmount,
  isSubsection,
  isProduction = false,
  visibilityChecked,
  handleVisibilityCheck,
}) => {
  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;

  const [, drag] = useDrag(
    () => ({
      type: "box",
      item: [section],
      canDrag: !!canChangeEstimateState,
    }),
    [section, canChangeEstimateState]
  );

  const isDisplayAmounts = React.useMemo(() => {
    if (isDisplayAmount !== undefined) return isDisplayAmount;
    return (
      !isDraft &&
      (section.status === ESTIMATE_ITEM_STATUSES.CONFIRMED || activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION)
    );
  }, [isDisplayAmount, isDraft, activeEstimateStateId, section.status]);

  const amounts = useMemo(
    () => ({
      all: getSectionAmount(section, activeEstimateStateId),
      completed: section.indicators ? section.indicators.invested : 0,
      completed_percent: section.indicators ? section.indicators.completed_percent : 0,
      accepted: section.indicators ? section.indicators.amount_accepted : 0,
      invested: section.indicators ? section.indicators.invested : 0,
      variant: AMOUNTS_VARIANTS.HIDDEN,
      allSource: section.indicators ? section.indicators.estimate_amount_source : 0,
      discount: section.indicators ? section.indicators.discount_cost : 0,
      estimatedCost: section.indicators ? section.indicators.estimated_cost : 0,
      sumEstimateAmount: section.aggregation_data?.sum_estimate_amount ?? 0,
    }),
    [activeEstimateStateId, section]
  );

  return (
    <div ref={drag}>
      <EstimateSection
        handleVisibilityCheck={handleVisibilityCheck}
        visibilityChecked={visibilityChecked}
        isProduction={isProduction}
        section={section}
        isChecked={isChecked}
        onCheck={check}
        canCheck={canChangeEstimateState}
        actions={actions}
        amounts={amounts}
        isDisplayAmounts={isDisplayAmounts}
        isDisplayEstimateStateMark={
          (isDraft && section.confirmed) || activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION
        }
        activePricingMode={activePricingMode}
        changeDiscountCost={changeDiscountCost}
        changeCost={changeCost}
        isWithIndexes={
          activeEstimateStateId !== ESTIMATE_STATES_IDS.PRODUCTION &&
          !isSubsection &&
          activeEstimateStateId !== ESTIMATE_STATES_IDS.DRAFT
        }
      />
    </div>
  );
};

export default React.memo(Section);
