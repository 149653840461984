import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  betonExpenseDetailSelector,
  betonIncomeDetailSelector,
  betonIncomeLoadingSelector,
  betonInvalidateKeySelector,
  betonIsPendingSelector,
} from "redux/modules/common/building/beton/selectors";
import {
  addBetonIncomeComment,
  editBetonIncomeTime,
  getBetonExpenseDetail,
  getBetonIncomeDetail,
} from "redux/modules/common/building/beton/thunks";
import { BetonIncomeStatusEnum, IBetonIncomeItem } from "redux/modules/common/building/beton/types";
import {
  passportsInvalidateKeySelector,
  passportsPendingSelector,
} from "redux/modules/common/passportsAndCertificates/selectors";
import {
  attachDocumentToExpenditure,
  deletePassportDocument,
} from "redux/modules/common/passportsAndCertificates/thunks";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import Expandable from "components/UI/atoms/Expandable/Expandable";
import TimeEdit from "components/UI/atoms/TimeEdit/TimeEdit";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import InfoItem from "components/UI/organism/WorkOrMaterialsModals/components/InfoItem/InfoItem";
import ModalContainer from "components/UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import PassportForm from "components/modals/PassportsAndSertificates/PassportForm/PassportForm";
import PassportsTable from "components/modals/PassportsAndSertificates/PassportsTable/PassportsTable";
import BetonExpenseComments from "components/pages/Beton/BetonExpense/components/BetonExpenseComments/BetonExpenseComments";

import BetonHistoryItem from "../BetonHistoryItem/BetonHistoryItem";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./BetonIncomeModal.module.scss";

interface IProps {
  item: IBetonIncomeItem;
  isOpen: boolean;
  onClose: () => void;
  stockId: number;
  onSendToProduction: () => void;
}

const BetonIncomeModal: React.FC<IProps> = ({ item, isOpen, onClose, stockId, onSendToProduction }) => {
  const dispatch = useDispatch();
  const isPending = useSelector(betonIsPendingSelector)[item.id];
  const isDocumentsPending = useSelector(passportsPendingSelector);
  const invalidateKey = useSelector(betonInvalidateKeySelector);
  const documentsInvalidateKey = useSelector(passportsInvalidateKeySelector);
  const detailItem = useSelector(betonIncomeDetailSelector)[item.id];
  const isLoading = useSelector(betonIncomeLoadingSelector)[generateStorageKey({ stockId, betonId: item.id })];

  useEffect(() => {
    if (!isOpen || !stockId) return;
    dispatch(getBetonIncomeDetail(stockId, item.id));
  }, [stockId, isOpen, item.id, invalidateKey, documentsInvalidateKey]);

  const addComment = (comment: string) => {
    dispatch(addBetonIncomeComment(stockId, item.id, comment));
  };

  const editTime = (hours: string, minutes: string) => {
    dispatch(editBetonIncomeTime(stockId, item.id, hours, minutes));
  };

  const attachDoc = (vals: any) => {
    dispatch(attachDocumentToExpenditure({ ...vals, stock_replenishment: item.id }, detailItem?.expenditure_id!));
  };

  const deleteDoc = (docId: number) => {
    dispatch(deletePassportDocument(detailItem?.expenditure_id!, docId));
  };

  return (
    <ModalContainer
      name={item.name}
      customHeadline={
        <div className={styles.headline}>
          <div className={styles.time}>Принят: {moment(detailItem?.received_at).format("DD.MM.YYYY")}</div>
          <TimeEdit onSubmit={editTime} defaultValue={detailItem?.received_at} isPending={isPending} />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <div className={styles.grid}>
            <InfoItem label="Объем поставки:" value={`${item.count} ${item.measure}`} />
            <InfoItem
              label="В производстве:"
              value={`${dropNonSignificantZeros((+item.count - +item.left_count).toFixed(2))} ${item.measure}`}
            />
            <InfoItem label="Остаток:" value={`${item.left_count} ${item.measure}`} />
            <InfoItem label="Поставщик:" value={item.provider} className={styles.exp} />
          </div>
          <Expandable title={`Документы: ${detailItem?.attachments?.length}`}>
            {!detailItem?.attachments?.length && (
              <PassportForm
                onSubmit={attachDoc}
                isPending={isDocumentsPending}
                setIsOpen={() => void 0}
                isOpen={true}
                expenditureType="betonIncome"
              />
            )}
            <PassportsTable docs={detailItem?.attachments} onDirectlyDelete={deleteDoc} />
          </Expandable>
          <BetonExpenseComments comments={detailItem?.comments} onAddComment={addComment} isPending={isPending} />
          <Expandable title={`История перемещений: ${detailItem?.using?.length}`}>
            <div className={styles.history}>
              {detailItem?.using?.map((el) => (
                <BetonHistoryItem key={el.id} item={el} />
              ))}
            </div>
          </Expandable>
          <BottomControls isDoubleBtns isExists={item.status !== BetonIncomeStatusEnum.full}>
            <ButtonBase primary onClick={onSendToProduction}>
              В производство
            </ButtonBase>
          </BottomControls>
        </>
      )}
    </ModalContainer>
  );
};

export default BetonIncomeModal;
