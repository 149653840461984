import { IActions } from "redux/store";

import {
  IDetailedKs2InActing,
  IDetailedKs3InActing,
  IEstimateInActing,
  IExpenditureInActing,
  IIntersectingKs2Expenditure,
  IKs2InActingList,
  ISectionInActing,
} from "./types";

export const actingActions = {
  setEstimates: (estimates: IEstimateInActing[], key: string) =>
    ({
      type: "acting/SET_ESTIMATES",
      payload: { estimates, key },
    } as const),
  setEstimatesIsLoading: (status: boolean, key: string) =>
    ({
      type: "acting/SET_ESTIMATES_LOADING",
      payload: { status, key },
    } as const),
  ///////////////////////////////////////////////////////////////////////////////////////////////
  setSections: (sections: ISectionInActing[], key: string) =>
    ({
      type: "acting/SET_SECTIONS",
      payload: { sections, key },
    } as const),
  setSectionsIsLoading: (status: boolean, key: string) =>
    ({
      type: "acting/SET_SECTIONS_LOADING",
      payload: { status, key },
    } as const),
  ///////////////////////////////////////////////////////////////////////////////////////////////
  setExpenditures: (expenditures: IExpenditureInActing[], key: string, estimateId: number) =>
    ({
      type: "acting/SET_EXPENDITURES",
      payload: { expenditures, key, estimateId },
    } as const),
  setExpendituresIsLoading: (status: boolean, key: string) =>
    ({
      type: "acting/SET_EXPENDITURES_LOADING",
      payload: { status, key },
    } as const),
  ///////////////////////////////////////////////////////////////////////////////////////////////
  invalidateCache: () =>
    ({
      type: "acting/INVALIDATE_CACHE",
      payload: true,
    } as const),
  ///////////////////////////////////////////////////////////////////////////////////////////////
  selectSection: (sectionId: number, estimateId: number, status: boolean) =>
    ({
      type: "acting/SELECT_SECTION",
      payload: { sectionId, status, estimateId },
    } as const),
  selectExpenditure: (expId: number, status: boolean, parentId: number | null, estimateId: number) =>
    ({
      type: "acting/SELECT_EXPENDITURE",
      payload: { expId, status, parentId, estimateId },
    } as const),
  ///////////////////////////////////////////////////////////////////////////////////////////////
  setKsList: (ks2list: IKs2InActingList[], key: string) =>
    ({
      type: "acting/SET_KS_LIST",
      payload: { ks2list, key },
    } as const),
  setKsListIsLoading: (status: boolean, key: string) =>
    ({
      type: "acting/SET_KS_LIST_LOADING",
      payload: { status, key },
    } as const),
  ///////////////////////////////////////////////////////////////////////////////////////////////
  setIntersectingExpenditures: (expenditures: IIntersectingKs2Expenditure[]) =>
    ({
      type: "acting/SET_INTERSECTING_EXPENDITURES",
      payload: expenditures,
    } as const),
  deselectAllForKs2: (buildingId: string) =>
    ({
      type: "acting/DESELECT_ALL",
      payload: buildingId,
    } as const),
  setDetailedKs2: (data: IDetailedKs2InActing) =>
    ({
      type: "acting/SET_DETAILDES_KS2",
      payload: data,
    } as const),
  selectKs2Id: (ksId: number, buildingId: string, status: boolean) =>
    ({
      type: "acting/SELECT_KS2_ID",
      payload: { ksId, buildingId, status },
    } as const),
  deSelectAllKs2Ids: (buildingId: string) =>
    ({
      type: "acting/DE_SELECT_ALL_KS2_IDS",
      payload: buildingId,
    } as const),
  setDetailedKs3: (data: IDetailedKs3InActing) =>
    ({
      type: "acting/SET_DETAILDES_KS3",
      payload: data,
    } as const),
  setKs2ListByEstimate: (list: IKs2InActingList[], estimateId: number) =>
    ({
      type: "acting/SET_KS2_LIST_BY_ESTIMATE",
      payload: { list, estimateId },
    } as const),
  setKs2ListByEstimateLoading: (status: boolean, estimateId: number) =>
    ({
      type: "acting/SET_KS2_LIST_BY_ESTIMATE_LOADING",
      payload: { status, estimateId },
    } as const),
  setDetailedKs2IsLoading: (ks2Id: number, status: boolean) =>
    ({
      type: "acting/SET_DETAILED_KS2_IS_LOADING",
      payload: { ks2Id, status },
    } as const),
};

export type IActingAction = IActions<typeof actingActions>;
