import React from "react";

import AuditTypeSwitcher from "../AuditTypeSwitcher/AuditTypeSwitcher";
import DatePicker from "../DatePicker/DatePicker";

import styles from "./AuditHeadline.module.scss";
import MeasureBtn from "../../../../../UI/atoms/MeasureBtn/MeasureBtn";

function AuditHeadline() {
  return (
    <div className={styles.headline}>
      <DatePicker />
      <AuditTypeSwitcher className={styles.switcher} />
      <MeasureBtn
        className={styles.measureBtn}
      />
    </div>
  );
}

export default React.memo(AuditHeadline);
