import {
  VIEW_ACCEPTED_BUILDINGS_TAB,
  VIEW_ADD_CONTRACTOR,
  VIEW_ADD_CONTRACTORS_CONTRACTS,
  VIEW_ADD_CONTRACTORS_CONTRACTS_FILES,
  VIEW_ADD_CUSTOMERS_CONTRACTS,
  VIEW_ADD_CUSTOMERS_CONTRACTS_FILES,
  VIEW_ALL_BUILDINGS_TAB,
  VIEW_ANALYTICS,
  VIEW_BUILDING,
  VIEW_BUILDINGS_SECTION,
  VIEW_BUILDING_ADD_EXPENDITURE_FILES,
  VIEW_BUILDING_ADD_FACT_INTERVALS,
  VIEW_BUILDING_ADD_GROUPS,
  VIEW_BUILDING_ADD_PLAN_INTERVALS,
  VIEW_BUILDING_DELETE_EXPENDITURE_FILES,
  VIEW_BUILDING_EDIT_GROUPS,
  VIEW_BUILDING_PLAN_TAB,
  VIEW_BUILDING_PROGRESS_TAB,
  VIEW_BUILDING_TRANSFER_FACT_WORKS,
  VIEW_BUILDING_TRANSFER_PLAN_WORKS,
  VIEW_BUILDING_UNGROUP_EXPENDITURES,
  VIEW_BUILDING_UNGROUP_GROUPS,
  VIEW_CONSOLIDATE_HANDLER_STATE,
  VIEW_ADD_BILL_FILES as VIEW_CONSTRUCTING_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE as VIEW_CONSTRUCTING_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTACT as VIEW_CONSTRUCTING_ADD_CONTACT,
  VIEW_ADD_CONTRACT_FILES as VIEW_CONSTRUCTING_ADD_CONTRACT_FILES,
  VIEW_ADD_PACKING_LIST_FILES as VIEW_CONSTRUCTING_ADD_PACKING_LIST_FILES,
  VIEW_ADD_SUPPLIERS_CONTRACTS as VIEW_CONSTRUCTING_ADD_SUPPLIERS_CONTRACTS,
  VIEW_ADD_SUPPLIERS_CONTRACTS_FILES as VIEW_CONSTRUCTING_ADD_SUPPLIERS_CONTRACTS_FILES,
  VIEW_BILLS_LIST as VIEW_CONSTRUCTING_BILLS_LIST,
  VIEW_BILL_IN_EDIT as VIEW_CONSTRUCTING_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID as VIEW_CONSTRUCTING_BILL_PAYMENTS_MAKE_PAID,
  VIEW_CONSTRUCTING_CHART,
  VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS,
  VIEW_CONSTRUCTING_CHART_PLANS_DELETE,
  VIEW_CONSTRUCTING_CHART_WORKS,
  VIEW_CONTACTS_LIST as VIEW_CONSTRUCTING_CONTACTS_LIST,
  VIEW_CONTRACTS_LIST as VIEW_CONSTRUCTING_CONTRACTS_LIST,
  VIEW_COUNTERPARTIES_SECTION as VIEW_CONSTRUCTING_COUNTERPARTIES_SECTION,
  VIEW_CONSTRUCTING_CREATE_DIRECTIVE_PLAN,
  VIEW_CONSTRUCTING_CREATE_SECTION_PLAN,
  VIEW_DELETE_BILL_FILES as VIEW_CONSTRUCTING_DELETE_BILL_FILES,
  VIEW_DELETE_CONTACTS as VIEW_CONSTRUCTING_DELETE_CONTACTS,
  VIEW_DELETE_CONTRACT as VIEW_CONSTRUCTING_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACT_FILES as VIEW_CONSTRUCTING_DELETE_CONTRACT_FILES,
  VIEW_DELETE_EXPORTS as VIEW_CONSTRUCTING_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES as VIEW_CONSTRUCTING_DELETE_PACKING_LIST_FILES,
  VIEW_DELETE_SUPPLIERS as VIEW_CONSTRUCTING_DELETE_SUPPLIERS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS as VIEW_CONSTRUCTING_DELETE_SUPPLIERS_CONTRACTS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES as VIEW_CONSTRUCTING_DELETE_SUPPLIERS_CONTRACTS_FILES,
  VIEW_DOCUMENTS_SECTION as VIEW_CONSTRUCTING_DOCUMENTS_SECTION,
  VIEW_EDIT_CONTACT as VIEW_CONSTRUCTING_EDIT_CONTACT,
  VIEW_CONSTRUCTING_EDIT_DIRECTIVE_PLAN,
  VIEW_CONSTRUCTING_EDIT_SECTION_PLAN,
  VIEW_EDIT_SUPPLIER_MANAGER as VIEW_CONSTRUCTING_EDIT_SUPPLIER_MANAGER,
  VIEW_EXPORT_LIST as VIEW_CONSTRUCTING_EXPORT_LIST,
  VIEW_INVITES_LIST as VIEW_CONSTRUCTING_INVITES_LIST,
  VIEW_INVITES_LIST_ACTIONS as VIEW_CONSTRUCTING_INVITES_LIST_ACTIONS,
  VIEW_INVITE_SUPPLIER as VIEW_CONSTRUCTING_INVITE_SUPPLIER,
  VIEW_PACKING_LISTS as VIEW_CONSTRUCTING_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION as VIEW_CONSTRUCTING_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PACKING_LIST_ACCEPT as VIEW_CONSTRUCTING_PACKING_LIST_ACCEPT,
  VIEW_CONSTRUCTING_PLANS_DELETE,
  VIEW_CONSTRUCTING_SECTION,
  VIEW_SUPPLIERS_LIST as VIEW_CONSTRUCTING_SUPPLIERS_LIST,
  VIEW_CONTRACTORS_LIST,
  VIEW_CREATE_BUILDING,
  VIEW_CUSTOMERS_LIST,
  VIEW_DELETE_CONTRACTORS,
  VIEW_DELETE_CONTRACTORS_CONTRACTS,
  VIEW_DELETE_CONTRACTORS_CONTRACTS_FILES,
  VIEW_DELETE_CUSTOMERS,
  VIEW_DELETE_CUSTOMERS_CONTRACTS,
  VIEW_DELETE_CUSTOMERS_CONTRACTS_FILES,
  VIEW_DRAFT_HANDLER_STATE,
  VIEW_EDIT_BUILDINGS,
  VIEW_EDIT_CONTRACTOR_MANAGER,
  VIEW_EDIT_CUSTOMER_MANAGER,
  VIEW_FINANCE_AUDIT,
  VIEW_FINANCE_FORECAST,
  VIEW_FINANCE_SECTION,
  VIEW_FINANCE_SECTION_DOWNLOAD_BDR,
  VIEW_FINANCE_SECTION_DOWNLOAD_FORECAST,
  VIEW_FINANCE_SUMMARY,
  VIEW_FINANCE_WARRANTIES_AND_ADVANCE_PAYMENTS,
  VIEW_HANDLER,
  VIEW_INVITE_CUSTOMER,
  VIEW_LOCALE_HANDLER_STATE,
  VIEW_OBJECT_HANDLER_STATE,
  VIEW_PRODUCTION_HANDLER_STATE,
  VIEW_SENT_BUILDINGS_TAB, VIEW_CONSTRUCTING_EDIT_PLAN, VIEW_CONSTRUCTING_CHART_EDIT_PLAN
} from "./constructingPermissions";
import { VIEW_FILE_STORAGE, VIEW_SETTINGS_SECTION } from "./generalPermissions";
import {
  VIEW_MANUFACTIRING_JOURNAL_ACCEPTANCE_EDIT_TICKET,
  VIEW_MANUFACTURING_BETON,
  VIEW_MANUFACTURING_BETON_INCOMES,
  VIEW_MANUFACTURING_BETON_OUTCOMES,
  VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DETAILS,
  VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DOCS,
  VIEW_MANUFACTURING_BETON_OUTCOMES_EDIT_DETAILS,
  VIEW_MANUFACTURING_BETON_TO_PRODUCTION,
  VIEW_MANUFACTURING_BILL_ATTACH_FILES,
  VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC,
  VIEW_MANUFACTURING_BILL_DELETE_FILES,
  VIEW_MANUFACTURING_BILL_EDITING,
  VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED,
  VIEW_MANUFACTURING_BILL_SECTION,
  VIEW_MANUFACTURING_CHART,
  VIEW_MANUFACTURING_CHART_EDIT_RELATIONS,
  VIEW_MANUFACTURING_CHART_PLANS_DELETE,
  VIEW_MANUFACTURING_CHART_RESOURCES,
  VIEW_MANUFACTURING_CHART_WORKS,
  VIEW_MANUFACTURING_CREATE_DIRECTIVE_PLAN,
  VIEW_MANUFACTURING_CREATE_SECTION_PLAN,
  VIEW_MANUFACTURING_EDIT_DIRECTIVE_PLAN,
  VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_MANUFACTURING_EDIT_SECTION_PLAN,
  VIEW_MANUFACTURING_JOURNAL,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_ADD_TICKET,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_APPROVE_TICKET,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_TICKET_DELETE_FILE,
  VIEW_MANUFACTURING_JOURNAL_ACTS,
  VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2,
  VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS3,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_APPROVE,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_ATTACH_KS23,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_REMARK,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_EDIT_AOSR,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_FORMATION_AOSR,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_IMPORT_AOSR,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_TRANSFER_TO_ACTS,
  VIEW_MANUFACTURING_KS_LISTS,
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL,
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING,
  VIEW_MANUFACTURING_MATERIALS_SECTION,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING,
  VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  VIEW_MANUFACTURING_ORDER_FILES_ATTACH,
  VIEW_MANUFACTURING_ORDER_FILES_DELETE,
  VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE,
  VIEW_MANUFACTURING_ORDER_SECTION,
  VIEW_MANUFACTURING_PACKING_LIST_ACCEPT,
  VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES,
  VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES,
  VIEW_MANUFACTURING_PACKING_LIST_SECTION,
  VIEW_MANUFACTURING_PLANS_DELETE,
  VIEW_MANUFACTURING_PLAN_FACT_SECTION,
  VIEW_MANUFACTURING_PROJECT,
  VIEW_MANUFACTURING_PROJECT_ADD_FILES,
  VIEW_MANUFACTURING_PROJECT_ADD_GROUPS,
  VIEW_MANUFACTURING_PROJECT_DELETE_FILES,
  VIEW_MANUFACTURING_PROJECT_EDIT_GROUPS,
  VIEW_MANUFACTURING_PROJECT_PLAN,
  VIEW_MANUFACTURING_PROJECT_PLAN_ADD_WORK,
  VIEW_MANUFACTURING_PROJECT_PROGRESS,
  VIEW_MANUFACTURING_PROJECT_PROGRESS_ADD_WORK,
  VIEW_MANUFACTURING_PROJECT_UNGROUP_EXPENDITURES,
  VIEW_MANUFACTURING_PROJECT_UNGROUP_GROUPS,
  VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER,
  VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING,
  VIEW_MANUFACTURING_REQUISITION_COMPLETE,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE,
  VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION,
  VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES,
  VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE,
  VIEW_MANUFACTURING_REQUISITION_LOGS,
  VIEW_MANUFACTURING_REQUISITION_PAGE,
  VIEW_MANUFACTURING_REQUISITION_PROCESS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS,
  VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_SECTION,
  VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK,
  VIEW_MANUFACTURING_SECTION,
  VIEW_MANUFACTURING_SHARE_PLAN_WORK,
  VIEW_MANUFACTURING_SHARE_PROGRESS_WORK,
  VIEW_MANUFACTURING_STOCKS_DELETE_PRODUCT_FILES,
  VIEW_MANUFACTURING_STOCKS_INCOMES,
  VIEW_MANUFACTURING_STOCKS_MOVINGS,
  VIEW_MANUFACTURING_STOCKS_OUTCOMES,
  VIEW_MANUFACTURING_STOCKS_SECTION,
  VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION,
  VIEW_MANUFACTURING_STOCKS_UTILIZATION,
  VIEW_MANUFACTURING_WORKERS,
  VIEW_MANUFACTURING_WORKERS_CARD_DELETE_FILES,
  VIEW_MANUFACTURING_WORKERS_CARD_EXPORT_FILES,
  VIEW_MANUFACTURING_WORKERS_EXPORT_WORKERS,
  VIEW_MANUFACTURING_WORKERS_IMPORT_WORKERS,
  VIEW_MANUFACTURING_WORKERS_TIMESHEET,
  VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_APPROVERS,
  VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_WORKERS,
  VIEW_MANUFACTURING_WORKERS_TURNOUT,
  VIEW_MANUFACTURING_WORKERS_TURNOUT_EDIT,
  VIEW_PLAN_FACT_FINANCE,
  VIEW_PLAN_FACT_VOLUME, VIEW_MANUFACTURING_CHART_EDIT_PLAN, VIEW_MANUFACTURING_PROJECT_PLAN_EDIT_PLAN
} from "./manufacturingPermissions";
import {
  ORDERS_SECTION_VIEW,
  ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  ORDER_FILES_ATTACH,
  ORDER_FILES_DELETE,
  ORDER_PRODUCT_FILE_ATTACH,
  ORDER_PRODUCT_FILE_DELETE,
  VIEW_ADD_ADDITIONAL_PRODUCTS,
  VIEW_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTACT,
  VIEW_ADD_CONTRACT_FILES,
  VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_ADD_REQUISITION_APPROVERS,
  VIEW_ADD_REQUISITION_PRODUCTS_COMMENTS,
  VIEW_ADD_REQUISITION_PRODUCTS_FILES,
  VIEW_ADD_REQUISITION_VIEWERS,
  VIEW_ADD_SUPPLIERS_CONTRACTS,
  VIEW_ADD_SUPPLIERS_CONTRACTS_FILES,
  VIEW_ASSIGN_REQUISITION_EXECUTOR,
  VIEW_BILLS_LIST,
  VIEW_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID,
  VIEW_CONTACTS_LIST,
  VIEW_CONTRACTS_LIST,
  VIEW_COUNTERPARTIES_SECTION,
  VIEW_CREATE_REQUISITION,
  VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER,
  VIEW_DELETE_BILL_FILES,
  VIEW_DELETE_CONTACTS,
  VIEW_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACT_FILES,
  VIEW_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DELETE_REQUISITION_APPROVERS,
  VIEW_DELETE_REQUISITION_PRODUCTS_FILES,
  VIEW_DELETE_REQUISITION_VIEWERS,
  VIEW_DELETE_SUPPLIERS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES,
  VIEW_DOCUMENTS_SECTION,
  VIEW_EDIT_CONTACT,
  VIEW_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_EDIT_SUPPLIER_MANAGER,
  VIEW_EXPORT_LIST,
  VIEW_INVITES_LIST,
  VIEW_INVITES_LIST_ACTIONS,
  VIEW_INVITE_SUPPLIER,
  VIEW_ORDER_INVOICE_DIFFERENCE,
  VIEW_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PROCESS_ORDERS,
  VIEW_PROCESS_PAYMENTS,
  VIEW_PROCESS_REQUISITION,
  VIEW_PRODUCTS_REQUISITION_COMPLETE,
  VIEW_PURCHASES_CHART,
  VIEW_PURCHASES_CHART_EQUIPMENT,
  VIEW_PURCHASES_CHART_MATERIALS,
  VIEW_PURCHASES_CHART_MIMES,
  VIEW_PURCHASES_SECTION,
  VIEW_REQUISITION,
  VIEW_REQUISITIONS_LIST,
  VIEW_REQUISITION_IN_PROCESSING_MODE,
  VIEW_REQUISITION_LOGS,
  VIEW_REQUISITION_PRODUCTS_COMMENTS,
  VIEW_REQUISITION_REASSIGN,
  VIEW_REQUISITION_TAKE_TO_WORK,
  VIEW_REQUISITION_UN_APPROVE,
  VIEW_SUPPLIERS_LIST,
} from "./purchasesPermissions";
import { VIEW_EDIT_SETTINGS_POSTS } from "./settingsPermissions";

export const MATCHED_CHILD_PERMISSIONS = [
  {
    name: VIEW_CONSTRUCTING_SECTION,
    children: [
      {
        name: VIEW_BUILDINGS_SECTION,
        children: [
          { name: VIEW_ALL_BUILDINGS_TAB },
          { name: VIEW_SENT_BUILDINGS_TAB },
          { name: VIEW_ACCEPTED_BUILDINGS_TAB },
          { name: VIEW_CREATE_BUILDING },
          { name: VIEW_EDIT_BUILDINGS },
          {
            name: VIEW_HANDLER,
            children: [
              { name: VIEW_DRAFT_HANDLER_STATE },
              { name: VIEW_LOCALE_HANDLER_STATE },
              { name: VIEW_OBJECT_HANDLER_STATE },
              { name: VIEW_CONSOLIDATE_HANDLER_STATE },
              { name: VIEW_PRODUCTION_HANDLER_STATE },
            ],
          },
          {
            name: VIEW_BUILDING,
            children: [
              {
                name: VIEW_BUILDING_PLAN_TAB,
                children: [
                  { name: VIEW_CONSTRUCTING_CREATE_DIRECTIVE_PLAN },
                  { name: VIEW_CONSTRUCTING_EDIT_DIRECTIVE_PLAN },
                  { name: VIEW_CONSTRUCTING_CREATE_SECTION_PLAN },
                  { name: VIEW_CONSTRUCTING_EDIT_SECTION_PLAN },
                  { name: VIEW_BUILDING_ADD_PLAN_INTERVALS },
                  { name: VIEW_CONSTRUCTING_EDIT_PLAN },
                  { name: VIEW_CONSTRUCTING_PLANS_DELETE },
                  { name: VIEW_BUILDING_TRANSFER_PLAN_WORKS },
                  { name: VIEW_BUILDING_ADD_GROUPS },
                  { name: VIEW_BUILDING_UNGROUP_GROUPS },
                  { name: VIEW_BUILDING_EDIT_GROUPS },
                  { name: VIEW_BUILDING_UNGROUP_EXPENDITURES },
                ],
              },
              {
                name: VIEW_BUILDING_PROGRESS_TAB,
                children: [{ name: VIEW_BUILDING_ADD_FACT_INTERVALS }, { name: VIEW_BUILDING_TRANSFER_FACT_WORKS }],
              },
              { name: VIEW_BUILDING_ADD_EXPENDITURE_FILES },
              { name: VIEW_BUILDING_DELETE_EXPENDITURE_FILES },
            ],
          },
        ],
      },

      {
        name: VIEW_CONSTRUCTING_CHART,
        children: [
          {
            name: VIEW_CONSTRUCTING_CHART_WORKS,
            children: [
              { name: VIEW_CONSTRUCTING_CHART_EDIT_PLAN },
              { name: VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS },
            ],
          },
          { name: VIEW_CONSTRUCTING_CHART_PLANS_DELETE },
        ],
      },
      {
        name: VIEW_FINANCE_SECTION,
        children: [
          { name: VIEW_FINANCE_AUDIT },
          { name: VIEW_FINANCE_SUMMARY },
          { name: VIEW_FINANCE_FORECAST },
          { name: VIEW_FINANCE_SECTION_DOWNLOAD_FORECAST },
          { name: VIEW_FINANCE_SECTION_DOWNLOAD_BDR },
          { name: VIEW_FINANCE_WARRANTIES_AND_ADVANCE_PAYMENTS },
        ],
      },
      {
        name: VIEW_CONSTRUCTING_COUNTERPARTIES_SECTION,
        children: [
          {
            name: VIEW_CONSTRUCTING_CONTACTS_LIST,
            children: [
              { name: VIEW_CONSTRUCTING_ADD_CONTACT },
              { name: VIEW_CONSTRUCTING_DELETE_CONTACTS },
              { name: VIEW_CONSTRUCTING_EDIT_CONTACT },
            ],
          },
          {
            name: VIEW_CONTRACTORS_LIST,
            children: [
              { name: VIEW_ADD_CONTRACTOR },
              { name: VIEW_DELETE_CONTRACTORS },
              { name: VIEW_ADD_CONTRACTORS_CONTRACTS },
              { name: VIEW_DELETE_CONTRACTORS_CONTRACTS },
              { name: VIEW_EDIT_CONTRACTOR_MANAGER },
              { name: VIEW_ADD_CONTRACTORS_CONTRACTS_FILES },
              { name: VIEW_DELETE_CONTRACTORS_CONTRACTS_FILES },
            ],
          },
          {
            name: VIEW_CONSTRUCTING_SUPPLIERS_LIST,
            children: [
              { name: VIEW_CONSTRUCTING_INVITE_SUPPLIER },
              { name: VIEW_CONSTRUCTING_DELETE_SUPPLIERS },
              { name: VIEW_CONSTRUCTING_ADD_SUPPLIERS_CONTRACTS },
              { name: VIEW_CONSTRUCTING_DELETE_SUPPLIERS_CONTRACTS },
              { name: VIEW_CONSTRUCTING_EDIT_SUPPLIER_MANAGER },
              { name: VIEW_CONSTRUCTING_ADD_SUPPLIERS_CONTRACTS_FILES },
              { name: VIEW_CONSTRUCTING_DELETE_SUPPLIERS_CONTRACTS_FILES },
            ],
          },
          {
            name: VIEW_CUSTOMERS_LIST,
            children: [
              { name: VIEW_INVITE_CUSTOMER },
              { name: VIEW_DELETE_CUSTOMERS },
              { name: VIEW_ADD_CUSTOMERS_CONTRACTS },
              { name: VIEW_DELETE_CUSTOMERS_CONTRACTS },
              { name: VIEW_EDIT_CUSTOMER_MANAGER },
              { name: VIEW_ADD_CUSTOMERS_CONTRACTS_FILES },
              { name: VIEW_DELETE_CUSTOMERS_CONTRACTS_FILES },
            ],
          },
          {
            name: VIEW_CONSTRUCTING_INVITES_LIST,
            children: [{ name: VIEW_CONSTRUCTING_INVITES_LIST_ACTIONS }],
          },
        ],
      },
      {
        name: VIEW_CONSTRUCTING_DOCUMENTS_SECTION,
        children: [
          {
            name: VIEW_CONSTRUCTING_BILLS_LIST,
            children: [
              { name: VIEW_CONSTRUCTING_BILL_IN_EDIT },
              { name: VIEW_CONSTRUCTING_ADD_BILL_FILES },
              { name: VIEW_CONSTRUCTING_DELETE_BILL_FILES },
              { name: VIEW_CONSTRUCTING_ADD_BILL_PAYMENTS_FILE },
              { name: VIEW_CONSTRUCTING_BILL_PAYMENTS_MAKE_PAID },
            ],
          },
          {
            name: VIEW_CONSTRUCTING_PACKING_LISTS,
            children: [
              { name: VIEW_CONSTRUCTING_PACKING_LISTS_VAT_CALCULATION },
              { name: VIEW_CONSTRUCTING_PACKING_LIST_ACCEPT },
              { name: VIEW_CONSTRUCTING_ADD_PACKING_LIST_FILES },
              { name: VIEW_CONSTRUCTING_DELETE_PACKING_LIST_FILES },
            ],
          },
          {
            name: VIEW_CONSTRUCTING_CONTRACTS_LIST,
            children: [
              { name: VIEW_CONSTRUCTING_DELETE_CONTRACT },
              { name: VIEW_CONSTRUCTING_ADD_CONTRACT_FILES },
              { name: VIEW_CONSTRUCTING_DELETE_CONTRACT_FILES },
            ],
          },
          {
            name: VIEW_CONSTRUCTING_EXPORT_LIST,
            children: [{ name: VIEW_CONSTRUCTING_DELETE_EXPORTS }],
          },
        ],
      },
      { name: VIEW_ANALYTICS },
    ],
  },
  {
    name: VIEW_MANUFACTURING_SECTION,
    children: [
      {
        name: VIEW_MANUFACTURING_PROJECT,
        children: [
          {
            name: VIEW_MANUFACTURING_PROJECT_PLAN,
            children: [
              { name: VIEW_MANUFACTURING_CREATE_DIRECTIVE_PLAN },
              { name: VIEW_MANUFACTURING_EDIT_DIRECTIVE_PLAN },
              { name: VIEW_MANUFACTURING_CREATE_SECTION_PLAN },
              { name: VIEW_MANUFACTURING_EDIT_SECTION_PLAN },
              { name: VIEW_MANUFACTURING_PROJECT_PLAN_ADD_WORK },
              { name: VIEW_MANUFACTURING_PROJECT_PLAN_EDIT_PLAN },
              { name: VIEW_MANUFACTURING_PLANS_DELETE },
              { name: VIEW_MANUFACTURING_SHARE_PLAN_WORK },
              { name: VIEW_MANUFACTURING_PROJECT_ADD_GROUPS },
              { name: VIEW_MANUFACTURING_PROJECT_UNGROUP_EXPENDITURES },
              { name: VIEW_MANUFACTURING_PROJECT_UNGROUP_GROUPS },
              { name: VIEW_MANUFACTURING_PROJECT_EDIT_GROUPS },
            ],
          },
          {
            name: VIEW_MANUFACTURING_PROJECT_PROGRESS,
            children: [
              { name: VIEW_MANUFACTURING_PROJECT_PROGRESS_ADD_WORK },
              { name: VIEW_MANUFACTURING_SHARE_PROGRESS_WORK },
            ],
          },
          { name: VIEW_MANUFACTURING_PROJECT_ADD_FILES },
          { name: VIEW_MANUFACTURING_PROJECT_DELETE_FILES },
        ],
      },
      {
        name: VIEW_MANUFACTURING_CHART,
        children: [
          {
            name: VIEW_MANUFACTURING_CHART_WORKS,
            children: [
              { name: VIEW_MANUFACTURING_CHART_EDIT_PLAN },
              { name: VIEW_MANUFACTURING_CHART_EDIT_RELATIONS },
            ],
          },
          { name: VIEW_MANUFACTURING_CHART_PLANS_DELETE },
          { name: VIEW_MANUFACTURING_CHART_RESOURCES },
        ],
      },
      {
        name: VIEW_MANUFACTURING_JOURNAL,
        children: [
          {
            name: VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
            children: [
              { name: VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_ADD_TICKET },
              { name: VIEW_MANUFACTIRING_JOURNAL_ACCEPTANCE_EDIT_TICKET },
              { name: VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_APPROVE_TICKET },
              {
                name: VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_TICKET_DELETE_FILE,
              },
            ],
          },
          {
            name: VIEW_MANUFACTURING_JOURNAL_PROGRESS,
            children: [
              { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_APPROVE },
              { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_ATTACH_KS23 },
              { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_FORMATION_AOSR },
              { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_EDIT_AOSR },
              { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_IMPORT_AOSR },
              { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_TRANSFER_TO_ACTS },
              { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_REMARK },
            ],
          },
          {
            name: VIEW_MANUFACTURING_JOURNAL_ACTS,
            children: [
              { name: VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS2 },
              { name: VIEW_MANUFACTURING_JOURNAL_ACTS_FORMATION_KS3 },
            ],
          },
          { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A },
          { name: VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS },
        ],
      },
      {
        name: VIEW_MANUFACTURING_WORKERS,
        children: [
          {
            name: VIEW_MANUFACTURING_WORKERS_TURNOUT,
            children: [
              { name: VIEW_MANUFACTURING_WORKERS_CARD_EXPORT_FILES },
              { name: VIEW_MANUFACTURING_WORKERS_CARD_DELETE_FILES },
              { name: VIEW_MANUFACTURING_WORKERS_TURNOUT_EDIT },
            ],
          },
          {
            name: VIEW_MANUFACTURING_WORKERS_TIMESHEET,
            children: [
              { name: VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_APPROVERS },
              { name: VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_WORKERS },
              { name: VIEW_MANUFACTURING_WORKERS_EXPORT_WORKERS },
              { name: VIEW_MANUFACTURING_WORKERS_IMPORT_WORKERS },
            ],
          },
        ],
      },
      {
        name: VIEW_MANUFACTURING_REQUISITION_SECTION,
        children: [
          { name: VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION },
          {
            name: VIEW_MANUFACTURING_REQUISITION_PAGE,
            children: [
              {
                name: VIEW_MANUFACTURING_REQUISITION_PROCESS,
                children: [
                  { name: VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE },
                  {
                    name: VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE,
                  },
                  {
                    name: VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL,
                  },
                  { name: VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS },
                  { name: VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS },
                  { name: VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS },
                  { name: VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS },
                  { name: VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING },
                  { name: VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE },
                  { name: VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES },
                  { name: VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER },
                ],
              },
              { name: VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS },
              { name: VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS },
              { name: VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR },
              { name: VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK },
              { name: VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR },
              { name: VIEW_MANUFACTURING_REQUISITION_LOGS },
              { name: VIEW_MANUFACTURING_REQUISITION_COMPLETE },
            ],
          },
        ],
      },
      { name: VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE },
      {
        name: VIEW_MANUFACTURING_STOCKS_SECTION,
        children: [
          { name: VIEW_MANUFACTURING_STOCKS_INCOMES },
          { name: VIEW_MANUFACTURING_STOCKS_OUTCOMES },
          { name: VIEW_MANUFACTURING_STOCKS_MOVINGS },
          {
            name: VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION,
            children: [
              { name: VIEW_MANUFACTURING_STOCKS_UTILIZATION },
              { name: VIEW_MANUFACTURING_STOCKS_DELETE_PRODUCT_FILES },
            ],
          },
        ],
      },
      {
        name: VIEW_MANUFACTURING_BETON,
        children: [
          {
            name: VIEW_MANUFACTURING_BETON_INCOMES,
            children: [{ name: VIEW_MANUFACTURING_BETON_TO_PRODUCTION }],
          },
          {
            name: VIEW_MANUFACTURING_BETON_OUTCOMES,
            children: [
              { name: VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DETAILS },
              { name: VIEW_MANUFACTURING_BETON_OUTCOMES_EDIT_DETAILS },
              { name: VIEW_MANUFACTURING_BETON_OUTCOMES_ADD_DOCS },
            ],
          },
        ],
      },
      {
        name: VIEW_MANUFACTURING_MATERIALS_SECTION,
        children: [
          {
            name: VIEW_MANUFACTURING_MATERIALS_ESTIMATE,
            children: [{ name: VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING }],
          },
          {
            name: VIEW_MANUFACTURING_MATERIALS_SPECIFICATION,
            children: [{ name: VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING }],
          },
          {
            name: VIEW_MANUFACTURING_MATERIALS_ADDITIONAL,
            children: [{ name: VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING }],
          },
        ],
      },
      {
        name: VIEW_MANUFACTURING_PLAN_FACT_SECTION,
        children: [
          // OSLA-5302
          { name: VIEW_PLAN_FACT_FINANCE },
          { name: VIEW_PLAN_FACT_VOLUME },
        ],
      },
      {
        name: VIEW_MANUFACTURING_ORDER_SECTION,
        children: [
          { name: VIEW_MANUFACTURING_ORDER_FILES_ATTACH },
          { name: VIEW_MANUFACTURING_ORDER_FILES_DELETE },
          { name: VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH },
          { name: VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE },
          { name: VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION },
        ],
      },
      {
        name: VIEW_MANUFACTURING_BILL_SECTION,
        children: [
          { name: VIEW_MANUFACTURING_BILL_EDITING },
          { name: VIEW_MANUFACTURING_BILL_ATTACH_FILES },
          { name: VIEW_MANUFACTURING_BILL_DELETE_FILES },
          { name: VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC },
          { name: VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED },
        ],
      },
      {
        name: VIEW_MANUFACTURING_PACKING_LIST_SECTION,
        children: [
          { name: VIEW_MANUFACTURING_PACKING_LIST_ACCEPT },
          { name: VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES },
          { name: VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES },
        ],
      },
      {
        name: VIEW_MANUFACTURING_KS_LISTS,
      },
    ],
  },
  {
    name: VIEW_PURCHASES_SECTION,
    children: [
      {
        name: VIEW_PURCHASES_CHART,
        children: [
          { name: VIEW_PURCHASES_CHART_MATERIALS },
          { name: VIEW_PURCHASES_CHART_EQUIPMENT },
          { name: VIEW_PURCHASES_CHART_MIMES },
        ],
      },
      {
        name: VIEW_REQUISITIONS_LIST,
        children: [
          { name: VIEW_CREATE_REQUISITION },
          {
            name: VIEW_REQUISITION,
            children: [
              {
                name: VIEW_PROCESS_REQUISITION,
                children: [
                  { name: VIEW_EDIT_REQUISITION_BEFORE_APPROVE },
                  { name: VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS },
                  { name: VIEW_ADD_ADDITIONAL_PRODUCTS },
                  { name: VIEW_ADD_REQUISITION_APPROVERS },
                  { name: VIEW_ADD_REQUISITION_VIEWERS },
                  { name: VIEW_DELETE_REQUISITION_APPROVERS },
                  { name: VIEW_DELETE_REQUISITION_VIEWERS },
                  { name: VIEW_REQUISITION_UN_APPROVE },
                  { name: VIEW_REQUISITION_IN_PROCESSING_MODE },
                  { name: VIEW_ADD_REQUISITION_PRODUCTS_FILES },
                  { name: VIEW_DELETE_REQUISITION_PRODUCTS_FILES },
                  { name: VIEW_REQUISITION_PRODUCTS_COMMENTS },
                  { name: VIEW_ADD_REQUISITION_PRODUCTS_COMMENTS },
                  { name: VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER },
                ],
              },
              { name: VIEW_PROCESS_ORDERS },
              { name: VIEW_PROCESS_PAYMENTS },
              { name: VIEW_ASSIGN_REQUISITION_EXECUTOR },
              { name: VIEW_REQUISITION_TAKE_TO_WORK },
              { name: VIEW_REQUISITION_REASSIGN },
              { name: VIEW_REQUISITION_LOGS },
              { name: VIEW_PRODUCTS_REQUISITION_COMPLETE },
            ],
          },
        ],
      },
      { name: VIEW_ORDER_INVOICE_DIFFERENCE },
      {
        name: ORDERS_SECTION_VIEW,
        children: [
          { name: ORDER_FILES_ATTACH },
          { name: ORDER_FILES_DELETE },
          { name: ORDER_PRODUCT_FILE_ATTACH },
          { name: ORDER_PRODUCT_FILE_DELETE },
          { name: ORDER_AUTOMATIC_AGREEMENT_TRANSITION },
        ],
      },
      {
        name: VIEW_DOCUMENTS_SECTION,
        children: [
          {
            name: VIEW_BILLS_LIST,
            children: [
              { name: VIEW_BILL_IN_EDIT },
              { name: VIEW_ADD_BILL_FILES },
              { name: VIEW_DELETE_BILL_FILES },
              { name: VIEW_ADD_BILL_PAYMENTS_FILE },
              { name: VIEW_BILL_PAYMENTS_MAKE_PAID },
            ],
          },
          {
            name: VIEW_PACKING_LISTS,
            children: [
              { name: VIEW_PACKING_LISTS_VAT_CALCULATION },
              { name: VIEW_PACKING_LIST_ACCEPT },
              { name: VIEW_ADD_PACKING_LIST_FILES },
              { name: VIEW_DELETE_PACKING_LIST_FILES },
            ],
          },
          {
            name: VIEW_CONTRACTS_LIST,
            children: [
              { name: VIEW_DELETE_CONTRACT },
              { name: VIEW_ADD_CONTRACT_FILES },
              { name: VIEW_DELETE_CONTRACT_FILES },
            ],
          },
          {
            name: VIEW_EXPORT_LIST,
            children: [{ name: VIEW_DELETE_EXPORTS }],
          },
        ],
      },
      {
        name: VIEW_COUNTERPARTIES_SECTION,
        children: [
          {
            name: VIEW_CONTACTS_LIST,
            children: [{ name: VIEW_ADD_CONTACT }, { name: VIEW_DELETE_CONTACTS }, { name: VIEW_EDIT_CONTACT }],
          },
          {
            name: VIEW_SUPPLIERS_LIST,
            children: [
              { name: VIEW_INVITE_SUPPLIER },
              { name: VIEW_DELETE_SUPPLIERS },
              { name: VIEW_ADD_SUPPLIERS_CONTRACTS },
              { name: VIEW_DELETE_SUPPLIERS_CONTRACTS },
              { name: VIEW_EDIT_SUPPLIER_MANAGER },
              { name: VIEW_ADD_SUPPLIERS_CONTRACTS_FILES },
              { name: VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES },
            ],
          },
          {
            name: VIEW_INVITES_LIST,
            children: [{ name: VIEW_INVITES_LIST_ACTIONS }],
          },
        ],
      },
    ],
  },
  { name: VIEW_SETTINGS_SECTION, children: [{ name: VIEW_EDIT_SETTINGS_POSTS }] },
  { name: VIEW_FILE_STORAGE },
];
