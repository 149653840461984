import { MatchingStatusesEnum } from "components/UI/atoms/MatchingIcon/MatchingIcon";

export const getProductMatchingStatus = (product: {
  product_building: { type: string; estimate_expenditure: string; created_from_estimate: boolean };
  created_from_estimate?: boolean;
}) => {
  if (product?.product_building?.type === "out_of_estimate" && !product?.product_building?.estimate_expenditure) {
    return MatchingStatusesEnum.outOfEstimate;
  }
  if (product?.created_from_estimate || product?.product_building?.created_from_estimate)
    return MatchingStatusesEnum.auto;
  if (product?.product_building?.estimate_expenditure) return MatchingStatusesEnum.manual;
};
