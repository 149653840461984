import moment, { Moment } from "moment";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { planFactSelectedDatesSelector } from "../../../../redux/modules/common/constructing/finance/planFact/selectors";
import {
  setPlanFactDatesRange,
  setPlanFactMonthsCount,
  setPlanFactStartDate,
} from "../../../../redux/modules/common/constructing/finance/planFact/thunks";
import { useTypedSelector } from "../../../../redux/typedUseSelector";

import Select from "../../../UI/atoms/Select";
import Calendar from "../../../UI/molecules/Calendar/Calendar";
import MonthsYearsSlider from "../../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import PopoverOverlay from "../../../UI/molecules/PopoverOverlay/PopoverOverlay";

import { IRouterParamsWithObjectId } from "../../../../types/routerTypes";
import { PLANFACT_PERIOD_OPTIONS } from "../constants";

import { useBuildingDetailDataById } from "../../../../hooks/useBuildingDetailDataById";

import SettingGear from "../../../../images/SettingGear";

import styles from "./PlanFactFinanceMonthsSlider.module.scss";

const PlanFactFinanceMonthsSlider: React.FC = () => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const dispatch = useDispatch();
  const selectedDates = useTypedSelector(planFactSelectedDatesSelector);

  const buildingDetail = useBuildingDetailDataById(objectId);

  const monthSliderDate = useMemo(() => moment(selectedDates?.start_at) || moment(), [selectedDates]);

  const onMonthSliderChange = useCallback((date: Moment) => {
    dispatch(setPlanFactStartDate(date.format("YYYY-MM-DD")));
  }, []);

  const allMonthsCount = useMemo(() => {
    if (!buildingDetail?.construction_date_start || !buildingDetail?.construction_date_end) return 1;

    const startMoment = moment(buildingDetail?.construction_date_start);
    const endMoment = moment(buildingDetail?.construction_date_end);

    return (endMoment.year() - startMoment.year()) * 12 + (endMoment.month() - startMoment.month() + 1);
  }, [buildingDetail?.construction_date_start, buildingDetail?.construction_date_end]);

  const onMonthCountChange = useCallback(
    (monthsCount: number | "all") => {
      if (
        monthsCount === "all" &&
        !!buildingDetail?.construction_date_start &&
        !!buildingDetail?.construction_date_end
      ) {
        dispatch(
          setPlanFactMonthsCount(
            monthsCount,
            buildingDetail?.construction_date_start,
            buildingDetail?.construction_date_end
          )
        );
      } else {
        dispatch(setPlanFactMonthsCount(monthsCount));
      }
    },
    [buildingDetail?.construction_date_start, buildingDetail?.construction_date_end]
  );

  const setStartDate = useCallback(
    (startMoment: Moment | string) => {
      const startAt = moment(startMoment).format("YYYY-MM-DD");
      let endAt = selectedDates?.end_at;
      if (moment(endAt).isBefore(startAt, "day")) endAt = startAt;
      dispatch(setPlanFactDatesRange(startAt, endAt));
    },
    [selectedDates?.end_at]
  );

  const setEndDate = useCallback(
    (endMoment: Moment | string) => {
      const endAt = moment(endMoment).format("YYYY-MM-DD");
      let startAt = selectedDates?.start_at;
      if (moment(startAt).isAfter(endAt, "day")) startAt = endAt;
      dispatch(setPlanFactDatesRange(startAt, endAt));
    },
    [selectedDates?.start_at]
  );

  return (
    <div className={styles.container}>
      <MonthsYearsSlider
        date={monthSliderDate}
        onChange={onMonthSliderChange}
        className={styles.slider}
        selectingMonthsCount={selectedDates?.monthsCount === "all" ? allMonthsCount : selectedDates?.monthsCount}
      />
      <PopoverOverlay
        openType={"click"}
        content={
          <div className={styles.periodOptions}>
            <div className={styles.periodPickers}>
              <span>Укажите период времени</span>
              <div className={styles.row}>
                <Calendar label={"Дата начала"} value={selectedDates?.start_at} setValue={setStartDate} />
                <Calendar label={"Дата завершения"} value={selectedDates?.end_at} setValue={setEndDate} />
              </div>
            </div>
            <div className={styles.periodSelect}>
              <span>Период:</span>
              <Select
                onChange={onMonthCountChange}
                value={selectedDates?.monthsCount}
                options={PLANFACT_PERIOD_OPTIONS}
              />
            </div>
          </div>
        }
        popoverBorderColor={"default"}
        placement={"bottom"}
      >
        <SettingGear color={"#4fb2ed"} className={styles.gear} />
      </PopoverOverlay>
    </div>
  );
};

export default React.memo(PlanFactFinanceMonthsSlider);
