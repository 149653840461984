import { message } from "antd";
import axios from "axios";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import {
  journalActions,
  setGroupDetailInfo,
  setStockUsingMaterialsPriceList,
  setStockUsingMaterialsPriceListIsLoading,
} from "./actions";
import { journalApi } from "./journalApi";
import { journalExecutionActions } from "./journalExecution/actions";

import { IChangeJournalPriceTypeRequest, IConfirmGroupData } from "./types";
import { ITicketJournalDelivery } from "types/interfaces/Tickets";

import { generateJornalTicketSectionKey } from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const updateStockUsingMaterialsPrice =
  (buildingId: number, data: IChangeJournalPriceTypeRequest) => (dispatch: Dispatch) => {
    journalApi
      .changeMaterialPrice(buildingId, data)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        dispatch(journalExecutionActions.changeInvalidateKey());
        dispatch(journalExecutionActions.changeMaterialsInvalidateKey());
        message.success("Стоимость успешно выбрана");
      })
      .catch(errorCatcher);
  };

export const getSectionsForTickets =
  (objectId: string, year: number, month: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateJornalTicketSectionKey(objectId, year, month);
    if (!getState()?.journal.sectionsForTickets[key]?.results?.length) {
      dispatch(journalActions.setSectionsForTicketsLoading(true, key));
    }
    journalApi
      .getSectionsForTickets(objectId, year, month)
      .then(({ data }) => {
        dispatch(journalActions.setSectionsForTickets(data.results, key));
      })
      .finally(() => {
        dispatch(journalActions.setSectionsForTicketsLoading(false, key));
      });
  };

export const getTicketsBySection =
  (objectId: string, year: number, month: number, sectionId: number, filters: Record<string, string> = {}) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const params = { year, month, section_id: sectionId, ...filters };
    const key = generateJornalTicketSectionKey(objectId, year, month, sectionId);
    if (!getState()?.journal.ticketsBySections[key]?.results?.length) {
      dispatch(journalActions.setTicketsBySectionLoading(true, key));
    }
    journalApi
      .getTickets(objectId, params)
      .then(({ data }) => {
        dispatch(journalActions.setTicketsBySection(data, key));
      })
      .finally(() => {
        dispatch(journalActions.setTicketsBySectionLoading(false, key));
      });
  };

export const patchTicket = (
  objectId: string,
  ticketId: number,
  ticketData: Record<string, string | number>,
  successCallback?: () => void,
  successMsg?: string
) => {
  return (dispatch: Dispatch) => {
    journalApi
      .patchTicket(objectId, ticketId, ticketData)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        successCallback && successCallback();
        successMsg && message.success(successMsg);
      })
      .catch(errorCatcher);
  };
};

interface ICreateTicketAdditionalConfig {
  finallyCallback?: () => void;
  successCallback?: (data: any) => void;
}

export const createTicket = (
  objectId: string,
  ticketData: Partial<ITicketJournalDelivery>,
  files: any[] = [],
  locationArray: string[] = [],
  { finallyCallback, successCallback }: ICreateTicketAdditionalConfig = {}
) => {
  return (dispatch: Dispatch) => {
    journalApi
      .createTicket(objectId, ticketData)
      .then((response) => {
        if (locationArray.includes("journal")) {
          message.success("Запись успешно добавлена");
        } else {
          message.success("Запись успешно добавлена в Журнал Учета");
        }
        dispatch(journalActions.changeInvalidateKey());

        successCallback?.(response.data);

        if (files.length) {
          const formData = new FormData();
          files.forEach((file) => formData.append("file", file.file));
          axios.post(`/building/${objectId}/fact-intervals/${response.data.id}/files/`, formData).catch(errorCatcher);
        }
      })
      .catch(errorCatcher)
      .finally(() => {
        finallyCallback?.();
      });
  };
};

export const getGroupsBySection =
  (objectId: string, year: number, month: number, sectionId: number, filters: Record<string, string> = {}) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const params = { year, month, section_id: sectionId, ...filters };
    const key = generateJornalTicketSectionKey(objectId, year, month, sectionId);

    if (!getState()?.journal.groupsBySection[key]?.results?.length) {
      dispatch(journalActions.setGroupsBySectionLoading(true, key));
    }

    journalApi
      .getGroups(objectId, params)
      .then(({ data }) => {
        dispatch(journalActions.setGroupsBySection(data.results, key));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(journalActions.setGroupsBySectionLoading(false, key));
      });
  };

export const getGroupDetailInfo = (objectId: string, groupId: number) => (dispatch: Dispatch) => {
  journalApi
    .getGroupDetailInfo(objectId, groupId)
    .then(({ data }) => {
      dispatch(setGroupDetailInfo(data));
    })
    .catch(errorCatcher);
};

export const confirmGroup = (
  objectId: string,
  groupId: number,
  groupData: IConfirmGroupData,
  successCallback?: () => void
) => {
  return (dispatch: Dispatch) => {
    journalApi
      .postGroup(objectId, groupId, groupData)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        successCallback?.();
      })
      .catch(errorCatcher);
  };
};

export const approveTicket = (buildingId: string, expenditureId: number, isGroup: boolean) => {
  return (dispatch: Dispatch) => {
    const api = isGroup ? journalApi.approveGroupTicket : journalApi.approveTicket;
    return api(buildingId, expenditureId)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
      })
      .catch(errorCatcher);
  };
};
