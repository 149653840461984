import React, { useEffect, useState } from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";
import { apiGetEstimateExpenditure } from "redux/modules/common/building/shedulesApi";

import ProductInfoModal from "components/UI/molecules/ProductInfoModal/ProductInfoModal";

interface IProps {
  isOpen?: any;
  closeModal: () => void;
  objectId: string;
  chosenMatchingProduct: IExpendituresInJournalFulfillment["items"][0];
  openRelationToProduct?: () => void;
  directlyCantEdit: boolean;
}

const ProductInfo: React.FC<IProps> = ({
  isOpen,
  closeModal,
  objectId,
  chosenMatchingProduct,
  openRelationToProduct,
  directlyCantEdit,
}) => {
  const [expenditure, setExpenditure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ticketItem = chosenMatchingProduct?.expenditure;

  const loadEx = async (objectId: string, id: number) => {
    setIsLoading(true);

    const ex = await apiGetEstimateExpenditure(objectId, id);
    await setExpenditure(ex);
    await setIsLoading(false);
  };

  useEffect(() => {
    if (!isOpen) return;
    (ticketItem as any)?.expenditure_id && loadEx(objectId, (ticketItem as any)?.expenditure_id);
  }, [chosenMatchingProduct, isOpen]);

  return (
    <ProductInfoModal
      isOpen={isOpen && expenditure}
      isLoading={isLoading}
      onClose={closeModal}
      onOpenEditing={openRelationToProduct}
      canEdit={!directlyCantEdit}
      section={expenditure?.section?.name}
      number={expenditure?.number}
      justification={expenditure?.justification}
      expenditureName={expenditure?.name}
      price={expenditure?.price}
      count={expenditure?.count}
    />
  );
};

export default ProductInfo;
