import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonBase from "components/UI/atoms/ButtonBase";
import ConfirmModal from "components/UI/molecules/ConfirmationModal/ConfirmModal";

import { deletePlanIsLoadingSelector } from "./model/selectors";
import { deletePlan } from "./model/thunks";

import { useCanDeletePlan } from "./hooks/useCanDeletePlan";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./DeletePlan.module.scss";

interface IProps {
  buildingId: string;
  isGroup: boolean;
  expenditureId: number;
  planId: number;
  onClose: () => void;
}

const DeletePlan: React.FC<IProps> = ({ buildingId, isGroup, expenditureId, planId, onClose }) => {
  const dispatch = useDispatch();
  const key = generateStorageKey({ buildingId, expenditureId: planId });
  const isLoading = useSelector(deletePlanIsLoadingSelector)[key];
  const [isModal, setIsModal] = useState(false);

  const onDelete = () => {
    dispatch(deletePlan({ buildingId, expenditureId, planId, isGroup, successCallback: onClose }));
  };

  const canDelete = useCanDeletePlan();

  if (!canDelete) {
    return null;
  }

  return (
    <div>
      <div className={styles.title}>Действия</div>
      <ButtonBase onClick={() => setIsModal(true)} danger isLoading={isLoading}>
        Удалить план
      </ButtonBase>
      <ConfirmModal
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        action={onDelete}
        title="Подтвердите действие"
        variant="secondary"
        acceptButtonText="Удалить"
      >
        Вы действительно хотите удалить план?
      </ConfirmModal>
    </div>
  );
};

export default DeletePlan;
