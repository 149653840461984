import cn from "classnames";
import React, { useCallback, useRef, useState } from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";

import FilesList from "../FilesList/FilesList";

import { IFile } from "types/interfaces/Files";

import PaperClipIcon from "images/icons/PaperClipIcon";

import styles from "./FileBlock.module.scss";

export interface IProps {
  files: IFile[];
  addFiles?: (files: IFile[]) => void;
  deleteFile?: (removedFileId: number) => void;
  permissions?: Record<string, boolean>;
  className?: string;
  isDisabled?: boolean;
  mustConfirmDownload?: boolean;
  canExpand?: boolean;
}

const FileBlock: React.FC<IProps> = ({
  className,
  files = [],
  addFiles,
  deleteFile: removeFile,
  isDisabled,
  mustConfirmDownload,
  permissions = { addFiles: true, deleteFiles: true },
  canExpand = true,
}) => {
  const [isBodyShown, setIsBodyShown] = useState(true);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileDialog = useCallback(() => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  }, []);

  const onFileInputChange = (event: any) => addFiles?.(Array.from(event.target.files));
  const handleBodyShown = () => {
    if (!canExpand) return;
    setIsBodyShown((prevState) => !prevState);
  };

  const canAddFiles = !isDisabled && permissions.addFiles;

  return (
    <div className={cn(styles.fileBlock, { [styles.isClose]: !isBodyShown }, className)}>
      <header className={styles.header}>
        <h2 className={styles.headerText} onClick={handleBodyShown}>
          Файлы: {files.length || "нет"}
        </h2>
        {canAddFiles && (
          <ButtonBase className={styles.button} primary medium onClick={openFileDialog}>
            <PaperClipIcon className={styles.buttonIcon} fill="#fff" width="0.6rem" />{" "}
            {files.length ? "Прикрепить ещё" : "Прикрепить"}
          </ButtonBase>
        )}
      </header>
      {isBodyShown && (
        <div>
          {files.length ? (
            <FilesList
              files={files}
              removeFile={removeFile}
              isDisabled={isDisabled || !permissions.deleteFiles}
              mustConfirmDownload={mustConfirmDownload}
            />
          ) : (
            canAddFiles && <p className={styles.empty}>Прикрепите файлы</p>
          )}
        </div>
      )}
      <input type="file" ref={fileInputRef} onChange={onFileInputChange} className={styles.hiddenInput} />
    </div>
  );
};

export default React.memo(FileBlock);
