import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { addFileIdToTaskModalBuffer, setTaskSubmitted } from "../../../../redux/modules/purchaser/Tasks/actions";
import { taskModalSelector } from "../../../../redux/modules/purchaser/Tasks/selectors";
import { ItaskFile } from "../../../../redux/modules/purchaser/Tasks/types";
import { tasksApi } from "redux/modules/purchaser/Tasks/api";
import { getOneTask } from "redux/modules/purchaser/Tasks/thunks";

import { TASK_MODAL_TYPES } from "../constants";
import { IFile } from "types/interfaces/Files";

const computeLocalId = (file: IFile): number => file.lastModified + file.size;

export interface IuseTaskFiles {
  taskId?: number;
  variant: TASK_MODAL_TYPES;
}

export const useTaskFiles = ({ taskId, variant }: IuseTaskFiles) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<Partial<IFile>[]>([]);
  const { submission } = useSelector(taskModalSelector);

  const attachFilesToNewTask = useCallback(
    (newTaskId: number) => {
      tasksApi
        .postTaskFiles(
          files.map((fileWithId) => fileWithId.file),
          newTaskId
        )
        .then(() => {
          dispatch(getOneTask(newTaskId));
        });
    },
    [files]
  );

  useEffect(() => {
    if (!submission?.isSubmitted) return;
    if (files.length && variant === TASK_MODAL_TYPES.ADD) attachFilesToNewTask(submission.taskId);
    compose(dispatch, setTaskSubmitted)(false, submission.taskId);
    setFiles([]);
  }, [files, submission, attachFilesToNewTask, variant]);

  const uploadFilesHandler = useCallback(
    (files: IFile[]) => {
      if (variant === TASK_MODAL_TYPES.ADD) {
        setFiles((prevState) => prevState.concat(files.map((file) => ({ id: Math.random(), file }))));
      } else {
        if (!taskId) return;
        tasksApi.postTaskFiles(files, taskId).then(({ data }) => {
          dispatch(getOneTask(taskId));
          data.data.forEach((file: ItaskFile) => compose(dispatch, addFileIdToTaskModalBuffer)(file.id));
        });
      }
    },
    [variant, taskId]
  );

  const deleteFileHandler = useCallback(
    (fileId: number) => {
      if (variant === TASK_MODAL_TYPES.ADD) {
        setFiles((prevState) => prevState.filter((fileWithId) => fileWithId.id !== fileId));
      } else {
        if (!taskId) return;
        tasksApi.deleteTaskFile(fileId).then(() => {
          dispatch(getOneTask(taskId));
        });
      }
    },
    [variant, taskId]
  );

  return {
    uploadFilesHandler,
    deleteFileHandler,
    computeLocalId,
    files,
    handleSetFiles: setFiles,
  };
};
