import { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { YEAR } from "../../../../redux/modules/common/building/manufacturing/manufacturing";
import {
  chartViewModeSelector,
  manufacturingMonthMarkersSelector,
} from "redux/modules/common/building/manufacturing/selectors";

import { useRem } from "./useRem";
import { useUnitMultiplier } from "./useUnitMultiplier";

import { IMonthArrayElement } from "../types";

import { getMonthInfo } from "../utils";

export interface IUseCalendarScrollProps {
  calendarRef: React.RefObject<HTMLElement>;
  month: IMonthArrayElement | undefined;
  year: string;
  projectId?: number | string;
  setMonth: (newMonth: IMonthArrayElement) => void;
  setYear: (newYear: string) => void;
}

export const useCalendarScroll = ({
  calendarRef,
  month,
  year,
  projectId,
  setMonth,
  setYear,
}: IUseCalendarScrollProps) => {
  const unitMultiplier = useUnitMultiplier();

  const monthMarkersByYears = useSelector(manufacturingMonthMarkersSelector);
  const chartViewMode = useSelector(chartViewModeSelector);

  const [touchedYears, setTouchedYears] = useState<string[]>(() => [year.toString()]);

  useEffect(() => {
    if (!touchedYears.includes(year.toString())) {
      setTouchedYears([year?.toString()]);
    }
  }, [year, touchedYears]);

  const { REM } = useRem();

  const scrollCalendar = (units: number) => {
    if (!calendarRef.current) return;
    calendarRef.current.scrollLeft += units * unitMultiplier * REM;
  };

  const scrollToStartMonth = useCallback(
    (year: number, month: number, scrollOptions: ScrollIntoViewOptions = {}) => {
      const elementCandidate = calendarRef.current?.querySelector(
        `.startMonth[data-year='${year}'][data-month='${month}']`
      );
      elementCandidate?.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "center",
        ...scrollOptions,
      });
    },
    [calendarRef.current]
  );

  useEffect(() => {
    if (!month || !year || !calendarRef.current || !monthMarkersByYears?.[year]) return;
    scrollToStartMonth(+year, month.id, { behavior: "auto" });
  }, [scrollToStartMonth, projectId, monthMarkersByYears]);

  useEffect(() => {
    if (!calendarRef.current) return;
    const observerCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      try {
        const entriesInView = entries.filter((x) => x.isIntersecting);
        if (!entriesInView.length) return;

        const datesInViewPool: { year: string; month: string }[] = [];

        const counts: Record<string, number> = {};

        entriesInView.forEach((entry) => {
          const entryYear = entry.target.attributes.getNamedItem("data-year")?.value;
          const entryMonth = entry.target.attributes.getNamedItem("data-month")?.value;
          if (!entryYear || !entryMonth) return;
          datesInViewPool.push({ year: entryYear, month: entryMonth });
        });

        if (!datesInViewPool?.length) return;

        datesInViewPool.forEach((x) => (counts[JSON.stringify(x)] = (counts[JSON.stringify(x)] || 0) + 1));

        const mostRecentDateEntry = Object.entries(counts).sort((a, b) => b[1] - a[1])[0];

        if (!mostRecentDateEntry) return;

        const dateCandidate = JSON.parse(mostRecentDateEntry[0]);

        if (!dateCandidate) return;

        const prevYear = (+dateCandidate.year - 1).toString();
        const nextYear = (+dateCandidate.year + 1).toString();

        if (chartViewMode === YEAR) {
          if (datesInViewPool.findIndex((x) => +x.month === 11) !== -1 && !touchedYears.includes(nextYear)) {
            observer.disconnect();
            setTouchedYears((prevState) => [...prevState, nextYear]);
          }
          if (datesInViewPool.findIndex((x) => +x.month === 0) !== -1 && !touchedYears.includes(prevYear)) {
            observer.disconnect();
            setTouchedYears((prevState) => [prevYear, ...prevState]);
            setYear(dateCandidate.year);
            setMonth(getMonthInfo(+dateCandidate.month + 1));
            return;
          }
        } else {
          if (!touchedYears.includes(nextYear) && +dateCandidate.month === 11) {
            observer.disconnect();
            setTouchedYears((prevState) => [...prevState, nextYear]);
          }
          if (!touchedYears.includes(prevYear) && +dateCandidate.month === 0) {
            observer.disconnect();
            setTouchedYears((prevState) => [prevYear, ...prevState]);
          }
        }
        setYear(dateCandidate.year);
        setMonth(getMonthInfo(+dateCandidate.month));
      } catch (e) {
        console.warn(e);
      }
    };

    const scrollObserver = new IntersectionObserver(observerCallback, {
      rootMargin: `100% ${chartViewMode === YEAR ? "0px" : "-30%"} 100% ${chartViewMode === YEAR ? "-35%" : "-65%"}`,
      threshold: chartViewMode === YEAR ? 0.5 : 0.01,
    });

    try {
      Array.from(document.querySelectorAll(`[data-year][data-month]`))?.forEach((el) => scrollObserver.observe(el));
    } catch (e) {
      console.error(e);
    }
    return () => {
      scrollObserver.disconnect();
    };
  }, [calendarRef.current, touchedYears, touchedYears?.length, chartViewMode]);

  const handleDateChange = (date: Moment) => {
    scrollToStartMonth(date.year(), date.month(), { behavior: "auto" });
    date.month() !== month?.id && setMonth(getMonthInfo(date.month()));
    date.year() !== +year && setYear(date.year().toString());
  };

  return {
    handleDateChange,
    scrollCalendar,
    touchedYears,
  };
};
