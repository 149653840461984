import { ChaptersEnum } from "./enums";

export const ESTIMATE_STATES_IDS = {
  DRAFT: "draft",
  LOCALE: "locale",
  OBJECT: "object",
  CONSOLIDATE: "consolidate",
  PRODUCTION: "production",
};

export const ESTIMATE_STATES_NAMES = {
  DRAFT: "Черновик",
  LOCALE: "Локальный сметный расчет",
  OBJECT: "Объектный сметный расчет",
  CONSOLIDATE: "Сводный сметный расчет",
  PRODUCTION: "Производство",
};

export const ESTIMATE_STATES_NAMES_FOR_FROM = {
  DRAFT: "Черновика",
  LOCALE: "Локального сметного расчета",
  OBJECT: "Объектного сметного расчета",
  CONSOLIDATE: "Сводного сметного расчета",
  PRODUCTION: "Производства",
};

export const ESTIMATE_ITEM_STATUSES = {
  NEW: "new",
  CONFIRMED: "confirmed",
  CANCELED: "canceled",
};

export const ESTIMATE_ITEMS_TYPES = {
  SECTION: "section",
  EXPENDITURE: "expenditure",
};

export const CHAPTERS = [
  { id: ChaptersEnum.FIRST, name: "Подготовка территории строительства" },
  { id: ChaptersEnum.SECOND, name: "Основные объекты строительства" },
  { id: ChaptersEnum.THIRD, name: "Объекты подсобного и обслуживающего назначения" },
  { id: ChaptersEnum.FOURTH, name: "Объекты энергетического хозяйства" },
  { id: ChaptersEnum.FIFTH, name: "Объекты транспортного хозяйства и связи" },
  {
    id: ChaptersEnum.SIXTH,
    name: "Наружные сети и сооружения водоснабжения, канализации, теплоснабжения и газоснабжения",
  },
  { id: ChaptersEnum.SEVENTH, name: "Благоустройство и озеленение территории" },
  { id: ChaptersEnum.EIGHTH, name: "Временные здания и сооружения" },
  { id: ChaptersEnum.NINTH, name: "Прочие работы и затраты" },
  { id: ChaptersEnum.TENTH, name: "Содержание дирекции ( технический надзор) строящегося предприятия (учреждения)" },
  { id: ChaptersEnum.ELEVENTH, name: "Подготовка эксплуатационных кадров" },
  { id: ChaptersEnum.TWELFTH, name: "Проектные и изыскательские работы, авторский надзор" },
];

export const AGGERATIONS_TRANSFORM_OPTIONS = { withFloat: false, withCurrencySign: true };

export const OUT_OF_ESTIMATE_REQUIRED_FIELDS = ["name", "measure", "count", "expenditure_type"];
