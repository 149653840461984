export const groupIcon = (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.83887 2.48592C10.0045 2.40963 10.1935 2.53063 10.1935 2.71299V5.63711C10.6207 5.42401 11.1517 5.15856 11.6935 4.88756V2.71299C11.6935 1.43644 10.3708 0.589445 9.21133 1.1235L3.70615 3.65922C2.02214 4.43489 0.943359 6.1195 0.943359 7.97356V17.2799C0.943359 18.5427 2.24012 19.3898 3.39653 18.8824L6.98716 17.268C6.6147 16.9249 6.2844 16.4933 6.03031 16.0536L2.79381 17.5088C2.62861 17.5813 2.44336 17.4603 2.44336 17.2799V7.97356C2.44336 6.70499 3.18147 5.55236 4.33369 5.02164L9.83887 2.48592ZM15.0573 6.64512C15.0573 5.49546 13.8497 4.74591 12.8194 5.25612L9.75666 6.77293C8.47918 7.40558 7.6709 8.70785 7.6709 10.1334V14.9992C7.6709 16.1489 8.87854 16.8984 9.90878 16.3882L12.9716 14.8714C14.249 14.2388 15.0573 12.9365 15.0573 11.5109V6.64512Z"
      fill="#A26DD0"
    />
  </svg>
);
