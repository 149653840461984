import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userSelector } from "redux/modules/common/auth";

import HideBlock from "components/UI/atoms/HideBlock";
import InputBase from "components/UI/atoms/InputBase";
import SliderModal from "components/UI/atoms/SliderModal";
import MenuItem from "components/UI/molecules/NavigationBar/MenuItem";

import HelpModal from "../ui/HelpModal/HelpModal";
import HelpModalNavigation from "../ui/HelpModalNavigation/HelpModalNavigation";
import instructionsIcon from "./instructionsIcon.svg";
import { permissionsApi } from "features/permissions/model/permissionsApi";
import {
  permissionsDescrSelector,
  permissionsInvalidateKeySelector,
  permissionsTreeSelector,
} from "features/permissions/model/selectors";
import { getPermissionsTree } from "features/permissions/model/thunks";
import ReactMarkdown from "react-markdown";

import { useInstructionByUrl } from "./hooks/useInstructionsByUrl";

import { permissionsUtils } from "features/permissions/model/utils";

import styles from "./Instructions.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const Instructions: React.FC<IProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const tree = useSelector(permissionsTreeSelector);
  const descriptions = useSelector(permissionsDescrSelector);
  const user = useSelector(userSelector);
  const invalidatePermissionsKey = useSelector(permissionsInvalidateKeySelector);

  const [activePerm, setActivePerm] = useState("");

  const presetPermission = useInstructionByUrl(activePerm);

  useEffect(() => {
    if (!presetPermission) return;
    setActivePerm(presetPermission);
  }, [presetPermission]);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!user?.id) return;
    dispatch(getPermissionsTree(user.id, true));
  }, [user?.id, invalidatePermissionsKey]);

  const fullActivePermission = descriptions?.[activePerm];

  const searchedTree = useMemo(() => {
    if (!tree) return null;
    return permissionsUtils.searchInTree(tree, search);
  }, [tree, search]);

  const parentName = useMemo(() => {
    if (!fullActivePermission?.parentName) return "";
    if (fullActivePermission.parentName === fullActivePermission.title) {
      if (fullActivePermission.parentChain.at(-2)) {
        return fullActivePermission.parentChain.at(-2);
      }
    }
    return fullActivePermission?.parentName;
  }, [fullActivePermission]);

  return (
    <HelpModal
      isOpen={isOpen}
      onClose={onClose}
      navHeader="Инструкции Osla"
      navContent={
        <>
          <div className={styles.searchWrapper}>
            <InputBase
              classNameInput={styles.searchInput}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className={styles.search}
              placeholder="Поиск инструкций"
            />
          </div>

          {searchedTree?.groups.map((el) => (
            <HelpModalNavigation
              activePerm={activePerm}
              key={el.name}
              group={el}
              onClick={setActivePerm}
              activePermPath={fullActivePermission?.parentChain}
              search={search}
              onOpen={(perm) => setActivePerm(perm)}
            />
          ))}
        </>
      }
      contentHeader={
        <>
          {parentName}&nbsp;<span>/</span>&nbsp;
          <span>{fullActivePermission?.title as string}</span>
        </>
      }
      content={
        <div className={styles.content}>
          {fullActivePermission?.videos?.map((el, i) => (
            <div key={i} className={styles.video}>
              <video src={el.url} controls muted={false} controlsList="nodownload" />
            </div>
          ))}
          <ReactMarkdown>{fullActivePermission?.description as string}</ReactMarkdown>
        </div>
      }
    ></HelpModal>
  );
};

export default Instructions;
