import cn from "classnames";
import React from "react";

import { listHoverHandler } from "../../../utils";
import { getIsControlsVisible, getRowMargin } from "../utils";

import MinusBoxIcon from "images/icons/MinusBoxIcon";
import PlusBoxIcon from "images/icons/PlusBoxIcon";

import styles from "./ListRow.module.scss";

const ListRow = (props) => {
  const { data, depth, handleListItemClick, className, isExtra, renderExtraControl, isRoot } = props;

  const isControlsVisible = getIsControlsVisible(data);
  const { isNegative, isPositive } = diffChecker(data);

  const dataId = !isExtra ? data.id : data.localId;
  const isCompositedId = (data.parentId !== undefined && !isExtra) || data.isParentProject;

  return (
    <div
      className={cn(styles.listRow, className, "financeListRow", {
        [styles.listRow__border]: data.isOpen && data.id?.toString()?.includes("expenditure"),
        opened: data.isOpen,
        [styles.extra]: isExtra,
        [styles.borderTop]: isExtra && isRoot,
      })}
      style={{ paddingLeft: getRowMargin(depth) }}
      onMouseEnter={() => listHoverHandler(isCompositedId ? `${data.id}_${data.parentId}_row` : dataId, true)}
      onMouseLeave={() => listHoverHandler(isCompositedId ? `${data.id}_${data.parentId}_row` : dataId, false)}
      data-row-id={dataId}
      onClick={() => {
        isControlsVisible && handleListItemClick(data);
      }}
    >
      {isControlsVisible && (
        <div className={styles.listRow__icon}>{data.isOpen ? <MinusBoxIcon /> : <PlusBoxIcon />}</div>
      )}

      {!isControlsVisible && !!data.number && (
        <span className={styles.expenditureNumber} title={data.number}>
          {data.number}
        </span>
      )}
      {data.number === " " && <div className={styles.numberHolder} />}
      <div
        className={cn(styles.listRow__titleWrapper, {
          [styles.positive]: isPositive,
          [styles.negative]: isNegative,
        })}
      >
        <span
          className={cn(styles.listRow__title, {
            [styles.active]: data.isOpen && data.id?.toString()?.includes("expenditure"),
            [styles.extraChildrenTitle]: isExtra && !isControlsVisible,
          })}
          title={data.name}
        >
          {data.name}
        </span>
      </div>
      {data.id?.toString()?.includes("expenditure") && (
        <span className={cn(styles.listRow__count, { [styles.active]: data.isOpen })}>{data.children?.length}</span>
      )}
      {isExtra && !data.subsections && !data.costs && renderExtraControl?.(data.id)}
    </div>
  );
};

export default ListRow;

const diffChecker = (data) => {
  let isPositive;
  let isNegative;

  if (data.audit_data) {
    if (
      numberPositiveChecker(data.audit_data.selected_period?.diff) ||
      numberPositiveChecker(data.audit_data.project?.diff) ||
      numberPositiveChecker(data.audit_data.from_start?.diff)
    ) {
      isPositive = true;
    }
    if (
      numberNegativeChecker(data.audit_data.selected_period?.diff) ||
      numberNegativeChecker(data.audit_data.project?.diff) ||
      numberNegativeChecker(data.audit_data.from_start?.diff)
    ) {
      isNegative = true;
    }
  }
  if (data.data) {
    const value = data.data.at(-1) || {};
    if (numberPositiveChecker(value.plan - value.fact)) {
      isPositive = true;
    }
    if (numberNegativeChecker(value.plan - value.fact)) {
      isNegative = true;
    }
  }
  if (data.forecast_data) {
    const value = data.forecast_data.at(-1) || {};
    if (numberPositiveChecker(value.plan - value.fact)) {
      isPositive = true;
    }
    if (numberNegativeChecker(value.plan - value.fact)) {
      isNegative = true;
    }
  }

  return {
    isPositive,
    isNegative,
  };
};

const numberPositiveChecker = (num) => {
  if (isNaN(Number(num))) return false;
  if (Number(num) > 0) return true;
  return false;
};

const numberNegativeChecker = (num) => {
  if (isNaN(Number(num))) return false;
  if (Number(num) < 0) return true;
  return false;
};
