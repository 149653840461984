import cn from "classnames";
import React from "react";

import { Props } from "recharts/types/component/DefaultLegendContent";

import styles from "./AnalyticsChartLegend.module.scss";

export type IAnalyticsChartLegendProps = Props & { className?: string; centered?: boolean; columns?: number };

const AnalyticsChartLegend: React.FC<IAnalyticsChartLegendProps> = ({ payload, className, centered, columns = 2 }) => {
  return (
    <ul className={cn(styles.chartLegend, className)} style={{ gridTemplateColumns: `repeat(${columns}, auto)` }}>
      {payload?.map((legendItem, index) => (
        <li key={legendItem.value + "_" + index} className={cn({ [styles.centered]: centered })}>
          <div
            className={styles.chartLegendMark}
            style={{
              background: legendItem.color || "transparent",
              outline: `2px solid ${legendItem.outline || "transparent"}`,
            }}
          />
          {legendItem.value}
        </li>
      ))}
    </ul>
  );
};

export default React.memo(AnalyticsChartLegend);
