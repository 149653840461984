import React from 'react'
import {Field} from 'react-final-form'
import {TASK_MODAL_TYPES, TASKS_REMIND_OPTIONS, TASKS_REPEAT_OPTIONS} from '../../../../constants'
import RadioSquares from '../../../RadioSquares/RadioSquares'
import cn from 'classnames'
import styles from './ModalAddTask.module.scss'
import TaskFiles from '../../../TaskFiles/TaskFiles'

const ModalAddTaskExtraFields: React.FC<{
  isVisible?: boolean;
}> = ({ isVisible = true}) => {
  return (
    <div
      className={cn(styles.fields, {
        [styles.isVisible]: isVisible,
      })}
    >
      <Field
        name={"remember_task"}
        allowNull
        render={({ input, meta }) => (
          <RadioSquares
            options={TASKS_REMIND_OPTIONS}
            label={"Напомнить"}
            defaultValue={meta.initial}
            onClick={input.onChange}
            value={input.value}
          />
        )}
      />
      <Field
        name={"repeat_task"}
        allowNull
        render={({ input, meta }) => (
          <RadioSquares
            options={TASKS_REPEAT_OPTIONS}
            label={"Повтор"}
            defaultValue={meta.initial}
            onClick={input.onChange}
            value={input.value}
          />
        )}
      />
      <TaskFiles files={[]} variant={TASK_MODAL_TYPES.ADD} />
    </div>
  );
};

export default ModalAddTaskExtraFields;
