import moment from "moment";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import MatchingModal from "../../../../UI/atoms/MatchingModal/MatchingModal";
import { getMatchingType } from "components/UI/atoms/MatchingModal/utils";
import AddRelationToProduct from "components/UI/organism/AddRelationToProduct";

import { useUrlModuleWithObjectId } from "../../../../../utils/hooks/useUrlModuleWithObjectId";

import OpenLink from "images/icons/OpenLink";

import styles from "./RelatedProduct.module.scss";

const RelatedProduct = ({ product, objectId, updateProducts }) => {
  const [idAddRelation, setIdAddRelation] = useState(false);
  const dateColumnFormatter = (val) => (val ? moment(val).format("DD.MM.YY") : "");

  const urlModule = useUrlModuleWithObjectId();

  const filteredOrders = React.useMemo(() => {
    const ordersIds = [];
    const res = [];
    product?.orders?.forEach((el) => {
      if (!ordersIds.includes(el.order.number)) {
        res.push(el);
        ordersIds.push(el.order.number);
      }
    });
    return res;
  }, [product]);

  return (
    <div className={styles.container}>
      <div className={styles.name}>{product.product_building?.name}</div>
      <div className={styles.box}>
        <div className={styles.rowImg}>
          <MatchingModal
            info={product.product_building}
            objectId={objectId}
            updateProducts={updateProducts}
            type={getMatchingType(product.product_building?.type)}
          />
        </div>
        <div className={styles.rowItem}>
          {product.required_count} {product.product_building?.measure}
        </div>
        <div className={styles.rowItem}>{product.requests_sum_count}</div>
        <div className={styles.rowItem}>{dateColumnFormatter(product.delivery_date)}</div>
        <div className={styles.rowItem}>
          {filteredOrders?.map((el) => (
            <NavLink to={`/${urlModule}/orders/${el.order.id}`} key={el.order.id} className={styles.order}>
              <div className={styles.rowLink}>
                <div className={styles.bottomLine}>{el.order.number}</div>
                <OpenLink width={"1rem"} color={"#868686"} />
              </div>
            </NavLink>
          ))}
        </div>
      </div>
      <AddRelationToProduct
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(false)}
        objectId={objectId}
        editable={idAddRelation.editable}
      />
    </div>
  );
};

export default React.memo(RelatedProduct);
