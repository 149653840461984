import React from "react";

import { userSelector } from "../../../../../../../redux/modules/common/auth";
import { useTypedSelector } from "../../../../../../../redux/typedUseSelector";

import { IApprovalInModal } from "../../../../../../UI/organism/WorkOrMaterialsModals/components/Approval/types";

import {
  Responsibilities,
  useCheckIsCurrentUserResponsible,
} from "../../../../../../../hooks/useCheckIsCurrentUserResponsible";

export interface IUseCanApprovePlanProps {
  objectId: number | string;
  approvals?: IApprovalInModal[];
  isSection: boolean;
  isActive: boolean;
}

const useCanApprovePlan = ({ objectId, approvals, isSection, isActive }: IUseCanApprovePlanProps) => {
  const userInfo = useTypedSelector(userSelector);

  const isWorkPlanConfirmResponsible = useCheckIsCurrentUserResponsible({
    responsibleFor: Responsibilities.planWorkConfirm,
    projectId: objectId,
    isActive,
  });

  const isSectionPlanConfirmResponsible = useCheckIsCurrentUserResponsible({
    responsibleFor: Responsibilities.planSectionConfirm,
    projectId: objectId,
    isActive,
  });

  const isResponsible = (isSection && isSectionPlanConfirmResponsible) || (!isSection && isWorkPlanConfirmResponsible);

  const userInApproval = approvals?.find((approval) => approval.user.id === userInfo.id);

  if (!isResponsible || !userInApproval) return false;

  if (!userInApproval || userInApproval.is_confirmed) return false;

  if (!!userInApproval.position && userInApproval.position > 1) {
    return (
      isResponsible &&
      approvals?.find((approval) => approval.position === (userInApproval.position as number) - 1)?.is_confirmed
    );
  }

  return isResponsible;
};

export default useCanApprovePlan;
