import { createMedia } from "@artsy/fresnel";

const ExampleAppMedia = createMedia({
    breakpoints: {
    xs: 0,
    sm: 500,
    mobile: 650,
    md: 768,
    lg: 1024,
    lgx: 1070
  }
});

// Generate CSS to be injected into the head
export const mediaStyle = ExampleAppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = ExampleAppMedia;
