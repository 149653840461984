import React from "react";

import UIExpenditure from "components/UI/molecules/Expenditure/Expenditure";

import ReplacementsModal from "./ReplacementsModal/ReplacementsModal";
import replecementIcon from "./replecementIcon";

import { IIdAndName } from "types/idAndName";
import { IExpenditure } from "types/interfaces/Expenditure";

import styles from "./ReplacedExpenditure.module.scss";

export interface IReplacementInExpenditure {
  id: number;
  data: {
    number: number;
    name: string;
    justification: string;
    estimate_count: string;
    count: string;
    price: string;
    estimate_price: string;
    estimate_amount: string;
    measure: string;
    measure_coefficient: string;
  };
  author: IIdAndName;
  estimate_amount: string;
  created_at: string;
  covering_letter_file_name: string | null;
  covering_letter_file: string | null;
  drawing_file_name: string | null;
  drawing_file: string | null;
}

interface IProps {
  buildingId: string;
  expenditure: IReplacementInExpenditure;
  intervals: any;
  allReplacements: IReplacementInExpenditure[];
  initialExpenditure: IExpenditure;
}

const ReplacedExpenditure: React.FC<IProps> = ({
  buildingId,
  expenditure,
  intervals,
  allReplacements,
  initialExpenditure,
}) => {
  const [isModal, setIsModal] = React.useState(false);
  const serializedExpediture = React.useMemo(() => {
    return {
      ...expenditure,
      ...expenditure.data,
    };
  }, [expenditure]);

  return (
    <div className={styles.container}>
      <div className={styles.btn} onClick={() => setIsModal(true)}>
        {replecementIcon}
      </div>
      {/* @ts-ignore */}
      <UIExpenditure
        buildingId={buildingId}
        expenditure={serializedExpediture}
        actions={null}
        intervals={intervals}
        className={styles.row}
        directlyAction={[]}
        isKSHidden
        isPopupsHidden
        isFileHidden
        isNumberHidden
      />
      <ReplacementsModal
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        replacements={allReplacements}
        title={expenditure.data.name}
        justification={expenditure.data.justification}
        initialExpenditure={initialExpenditure}
      />
    </div>
  );
};

export default ReplacedExpenditure;
