import cn from "classnames";
import React from "react";

import { IPlanFactExpenditureInner } from "../../../../../../../../../../redux/modules/common/constructing/finance/planFact/types";

import { dropNonSignificantZeros } from "../../../../../../../../../../utils/formatters/dropNonSignificantZeros";
import { transformDigitToFinancial } from "../../../../../../../../../../utils/formatters/transformDigitToFinancial";

import styles from "../FactAcceptedMaterial/FactAcceptedMaterial.module.scss";

export interface IFactWorkProps {
  work: IPlanFactExpenditureInner;
  isExpandedDifference?: boolean;
}

const FactWork: React.FC<IFactWorkProps> = ({ work, isExpandedDifference }) => {
  return (
    <div className={cn(styles.container, { [styles.withExpandedDifference]: isExpandedDifference })}>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <div className={styles.field1}></div>
          <div className={styles.field2} title={work.name}>
            <span>{work.name}</span>
          </div>
          {!isExpandedDifference && <div className={styles.field3}>{work.measure}</div>}
        </div>
        <div className={styles.rightSide}>
          {!isExpandedDifference && <div className={styles.fieldLeft1}>-</div>}
          <div className={styles.fieldLeft2}>-</div>
          <div className={styles.fieldLeft3}>-</div>
          {!isExpandedDifference && (
            <div className={styles.fieldLeft4}>{dropNonSignificantZeros(work.fact_count) || "-"}</div>
          )}
          <div className={styles.fieldLeft5}>
            {transformDigitToFinancial(work.fact_avg_price, { withFloat: true, dropZeros: true }) || "-"}
          </div>
          <div className={styles.fieldLeft6}>
            {transformDigitToFinancial(work.fact_amount, { withFloat: true, dropZeros: true }) || "-"}
          </div>
          <div className={styles.fieldLeft7}>{"-"}</div>
          {isExpandedDifference && <div className={styles.fieldLeft8}>{"-"}</div>}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FactWork);
