import cn from "classnames";
import React from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useParams } from "react-router-dom";

import Icon from "components/UI/Icon/Icon";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBack from "components/UI/atoms/ButtonBack/ButtonBack";
import ButtonBase from "components/UI/atoms/ButtonBase";
import InputBase from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select/index";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";

import BankDetails from "../Profile/BankDetails";
import { useOrganizationData } from "./useOrganizationData";
import formatStringByPattern from "format-string-by-pattern";

import { LOCALIZATION_CONFIG, REGIONS_LABELS } from "../../../../../constants/localization";
import { vatTypeArray } from "constants/constant";

import useFormFocus from "utils/hooks/useFormFocus";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { maxLengthValidator } from "utils/formHelpers/maxLengthValidator";
import { composeFieldValidators, maxLength, minLength, required } from "utils/formHelpers/validations";

import plusSmalCercle from "images/icons/plusSmalCercle.svg";

import styles from "./EditOrganization.module.scss";

function EditOrganization() {
  const urlId = useParams().id;
  const history = useHistory();
  const inputRootRef = useFormFocus();
  const fullUrlModule = useUrlModuleWithObjectId();

  const backLink = `/${fullUrlModule}/settings/profiles`;

  const isNew = urlId === "new";
  const isEdit = new URLSearchParams(window.location.search).get("edit") === "true" || isNew;
  const {
    taxes,
    initialForm,
    submitHandler,
    bankDetails,
    addbankDetails,
    deleteEmptyHandler,
    specialDeleteBankDetailHandler,
    isLoading,
  } = useOrganizationData(urlId, isNew);

  const getIncludingVat = () => {
    return (
      taxes?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      }) || []
    );
  };

  if (isLoading) {
    return (
      <TemplateBase>
        <Spinner />
      </TemplateBase>
    );
  }

  return (
    <TemplateBase>
      <Form
        initialValues={initialForm}
        onSubmit={submitHandler}
        render={({ handleSubmit, dirty }) => (
          <div ref={inputRootRef}>
            <div className={styles.headline}>
              <ButtonBack link={backLink} />
              <h1>{isNew ? "Назад" : initialForm?.name}</h1>
              {isEdit && dirty && (
                <ButtonBase className={styles.save} type="submit" onClick={handleSubmit}>
                  Сохранить
                </ButtonBase>
              )}
              {isEdit && dirty && (
                <ButtonBase buttonPrimaryEmpty onClick={() => history.push(`/${fullUrlModule}/settings`)}>
                  Отменить
                </ButtonBase>
              )}
            </div>
            <div className={styles.nameInput}>
              <Field
                name="name"
                label="Наименование организации"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                classNameInput={styles.colorInput}
                disabled={!isEdit}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.cellInRow1}>
                <Field
                  name="contact_full_name"
                  label="Контактное лицо"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  disabled={!isEdit}
                />
                <Field
                  name="contact_email"
                  label="Почта"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  disabled={!isEdit}
                  type="email"
                />
              </div>
              <Field
                name="contact_phone"
                label="Телефон"
                component={InputBase}
                validate={composeFieldValidators(maxLength(255))}
                disabled={!isEdit}
                parse={formatStringByPattern("+7 (999) 999 99 99")}
              />
              <div className={styles.select}>
                <Field
                  name="including_vat"
                  label="НДС"
                  component={Select}
                  options={vatTypeArray() || []}
                  selfControlled={true}
                  validate={composeFieldValidators(required(), maxLength(255))}
                  disabled={!isEdit}
                />
              </div>
              <div className={styles.select}>
                <Field
                  name="tax_system"
                  label="Налогообложение"
                  component={Select}
                  options={getIncludingVat() || []}
                  selfControlled={true}
                  validate={composeFieldValidators(required(), maxLength(255))}
                  disabled={!isEdit}
                />
              </div>
              <Field
                name="factaddress"
                label="Фактический адрес"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                disabled={!isEdit}
              />
            </div>
            <div
              className={cn(styles.row, {
                [styles.kz]: LOCALIZATION_CONFIG.langLabel === REGIONS_LABELS.kz,
              })}
            >
              <Field
                name="yur_name"
                label="Наименование юридического лица"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                disabled={!isEdit}
              />
              <Field
                name="itn"
                label={LOCALIZATION_CONFIG.taxesNumberLabel}
                component={InputBase}
                validate={composeFieldValidators(
                  required(),
                  minLength(10),
                  maxLength(LOCALIZATION_CONFIG.taxesNumberLength)
                )}
                disabled={!isEdit}
                formatOnBlur
                format={maxLengthValidator(10)}
              />
              {LOCALIZATION_CONFIG.langLabel === REGIONS_LABELS.ru && (
                <>
                  <Field
                    name="iec"
                    label="КПП"
                    component={InputBase}
                    validate={composeFieldValidators(required(), minLength(9), maxLength(9))}
                    disabled={!isEdit}
                    formatOnBlur
                    format={maxLengthValidator(9)}
                  />
                  <Field
                    name="psrn"
                    label="ОГРН/ОГРНИП"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(13), minLength(13))}
                    disabled={!isEdit}
                    formatOnBlur
                    format={maxLengthValidator(13)}
                  />
                </>
              )}
              <Field
                name="address"
                label="Юридический адрес"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                disabled={!isEdit}
                formatOnBlur
              />
            </div>
            {isEdit ? (
              <div className={styles.add}>
                Банковские реквизиты
                {isEdit && (
                  <button className={styles.btnAdd} onClick={addbankDetails}>
                    <Icon icon={plusSmalCercle} />
                  </button>
                )}
              </div>
            ) : null}
            {bankDetails?.map((item, index) => (
              <BankDetails
                key={index}
                item={item}
                index={index}
                deleteEmptyHandler={deleteEmptyHandler}
                specialDeleteHandler={specialDeleteBankDetailHandler}
                isDisabled={!isEdit}
                isLast={bankDetails?.length <= 1}
                addNew={addbankDetails}
              />
            ))}
          </div>
        )}
      />
    </TemplateBase>
  );
}

export default EditOrganization;
