import React, { useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import type { NavLinkProps } from "react-router-dom";
import cn from "classnames";

import { NavLinkVariantsEnum } from "./constants";

import OpenLink from "../../../images/icons/OpenLink";

import styles from "./NavLinkIcon.module.scss";


interface INavLinkIcon extends Omit<NavLinkProps, "title" | "target"> {
  to: string;
  className?: string;
  titleClassName?: string;
  title?: string | number | null;
  variant?: NavLinkVariantsEnum;
  isPropagate?: boolean;
  isWithoutIcon?: boolean;
  isReplaceRoute?: boolean;
  target?: NavLinkProps["target"]
}

export const NavLinkIcon = React.memo(({
  isPropagate,
  className,
  titleClassName,
  title,
  variant = NavLinkVariantsEnum.PRIMARY,
  to,
  isWithoutIcon,
  isReplaceRoute,
  target = "_blank",
  ...anotherProps
}: INavLinkIcon) => {
  const location = useLocation();

  const onClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    if (isPropagate) return;
    event.stopPropagation();
  }, [isPropagate]);

  const link = to.includes('?') ? `${to}&from=${location.pathname}` : `${to}?from=${location.pathname}`;

  return (
    <NavLink
      {...anotherProps}
      to={link}
      target={target}
      className={cn(styles.link, styles[variant], className)}
      title={String(title)}
      onClick={onClick}
    >
      <span className={cn(styles.title, titleClassName)}>{title}</span>
      {!isWithoutIcon && (
        <span className={styles.icon}>
          <OpenLink width={"1rem"} color={"#868686"} />
        </span>
      )}
    </NavLink>
  );
});
