import React from "react";
import cn from "classnames";
import styles from "../../components/UI/atoms/Expandable/Expandable.module.scss";

const ExpandableIcon: React.FC<{ className?: string }> = ({ className = "" }) => (
  <svg className={className} width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.550903 0.539062L9.07239 9.06055L17.5939 0.539062" stroke="#707070" />
  </svg>
);

export default ExpandableIcon