import React, { MouseEventHandler, useCallback, useMemo, useRef, useState } from "react";

import Icon from "../../../../../../../../UI/Icon/Icon";

import Xarrow from "react-xarrows";

import { DIAGRAM_ARROW_CONFIG, INTERVAL_MAX_Z_INDEX, RELATION_TYPES, RelationType } from "../../../../../../constants";

import { IDiagramIntervalLink, IDraggedInterval, createIntervalOriginType } from "../../utils/withLinking/";

import diagramAddLinkCircle from "../../../../../../../../../images/diagramAddLinkCircle.svg";

import styles from "./ConnectPointsWrapper.module.scss";

export interface IConnectPointsWrapperProps {
  intervalId: number;
  isAddingArrowAllowed?: boolean;
  hover: boolean;
  position: {
    x?: number;
    y?: number;
  };
  handleDragStart: (e: MouseEvent, origin: createIntervalOriginType) => void;
  handleDragEnd: MouseEventHandler;
  handleDragging: MouseEventHandler;
  projectId: number;
  beingDraggedArrows: {
    [key: number]: IDraggedInterval;
  };
  projectArrows: IDiagramIntervalLink[];
  isLinkingEnabled: boolean;
  isSomeArrowBeingDragged: boolean;
}

const ConnectPointsWrapper: React.FC<IConnectPointsWrapperProps> = ({
  intervalId,
  isAddingArrowAllowed,
  hover,
  position,
  handleDragStart,
  handleDragEnd,
  handleDragging,
  projectId,
  beingDraggedArrows,
  projectArrows,
  isLinkingEnabled,
  isSomeArrowBeingDragged,
}) => {
  const refStartPoint = useRef<HTMLDivElement>(null);
  const refEndPoint = useRef<HTMLDivElement>(null);
  const [tmpRelationType, setTmpRelationType] = useState<RelationType | undefined>(undefined);

  const withTmpRelationType = useCallback(
    (fn: MouseEventHandler, type: RelationType | undefined) => (e: Event) => {
      setTmpRelationType(type);
      fn?.(e);
    },
    []
  );

  const draggedIInterval = beingDraggedArrows?.[intervalId];

  const { isBeingDragged, origin: draggedOrigin } = draggedIInterval || {};

  const checkIsAddPointShown = (origin: "start" | "end") => {
    const isCurrentOriginDragged = isBeingDragged && origin === draggedOrigin;
    if (!isLinkingEnabled || !isAddingArrowAllowed) return false;
    return isCurrentOriginDragged || (hover && !isSomeArrowBeingDragged);
  };

  const isStartPointShown = checkIsAddPointShown("start");
  const isEndPointShown = checkIsAddPointShown("end");

  return (
    <React.Fragment>
      {isEndPointShown && (
        <div
          style={{
            left: position.x,
            top: position.y,
            transform: isBeingDragged ? "none" : "translate(30%, -50%)",
            pointerEvents: isBeingDragged ? "none" : "auto",
          }}
          draggable
          onDragStart={withTmpRelationType((e) => handleDragStart(e, "end"), RELATION_TYPES.oh)}
          onDrag={withTmpRelationType(handleDragging, RELATION_TYPES.oh)}
          onDragEnd={withTmpRelationType(handleDragEnd, undefined)}
          onClick={withTmpRelationType((e) => handleDragStart(e, "end"), RELATION_TYPES.oh)}
          ref={refEndPoint}
          className={styles.connectPoint}
        >
          <Icon icon={diagramAddLinkCircle} />
        </div>
      )}
      {isBeingDragged && (
        <Xarrow
          {...DIAGRAM_ARROW_CONFIG}
          start={intervalId?.toString()}
          end={isEndPointShown ? refEndPoint : refStartPoint}
          zIndex={INTERVAL_MAX_Z_INDEX + 2}
          relationType={tmpRelationType}
        />
      )}
      {isStartPointShown && (
        <div
          style={{
            left: position.x || 0,
            top: position.y,
            transform: isBeingDragged ? "none" : "translate(-30%, -50%)",
            pointerEvents: isBeingDragged ? "none" : "auto",
          }}
          draggable
          onDragStart={withTmpRelationType((e) => handleDragStart(e, "start"), RELATION_TYPES.hh)}
          onDrag={withTmpRelationType(handleDragging, RELATION_TYPES.hh)}
          onDragEnd={withTmpRelationType(handleDragEnd, undefined)}
          onClick={withTmpRelationType((e) => handleDragStart(e, "start"), RELATION_TYPES.hh)}
          ref={refStartPoint}
          className={styles.connectPoint}
        >
          <Icon icon={diagramAddLinkCircle} />
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(ConnectPointsWrapper);
