import { Table as AntTable } from "antd";
import React from "react";

import { useFinanceMeasure } from "../../../../../../../features/financeMeasure";

import renderMoneyCell from "./_internal/MoneyCell";
import { useTableHover } from "./_internal/useTableHover";

import { TABLE_GROUPS } from "./constants";
import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./Table.module.scss";

const { Column, ColumnGroup } = AntTable;

const Table = (props) => {
  const { data } = props;

  const {
    measure,
    measureInTitle
  } = useFinanceMeasure();

  useTableHover();

  return (
    <div className={styles.hideTitle}>
      <AntTable dataSource={data} size="small" scroll={{ x: 1100 }} pagination={false} className={styles.table}>
        {TABLE_GROUPS.map((group, idx) => (
          <ColumnGroup className={styles.title} title={group.title} key={idx}>
            <Column
              title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
              dataIndex={`${group.fieldName}_plan`}
              key={`${group.fieldName}_plan`}
              render={(data) => renderMoneyCell(data, measure, false)}
              ellipsis
            />
            <Column
              title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
              dataIndex={`${group.fieldName}_fact`}
              key={`${group.fieldName}_fact`}
              render={(data) => renderMoneyCell(data, measure, false, true)}
              ellipsis
            />
            <Column
              title={`Разница ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
              dataIndex={`${group.fieldName}_diff`}
              key={`${group.fieldName}_diff`}
              render={(data) => renderMoneyCell(data, measure, true)}
              ellipsis
            />
          </ColumnGroup>
        ))}
      </AntTable>
    </div>
  );
};

export default React.memo(Table);
