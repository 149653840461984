import { message } from "antd";
import React, { useCallback, useState } from "react";

import ButtonPill from "../../../../../../atoms/ButtonPill/ButtonPill";
import InputBase, { VALUE_TYPES } from "../../../../../../atoms/InputBase";
import MeasureSelect from "../../../../../../atoms/MeasureSelect/MeasureSelect";
import Select from "../../../../../../atoms/Select";
import TableReusableRow, { TableReusableCell } from "../../../../../../atoms/TableReusable/TableReusableRow";

import { EXPENDITURE_TYPES_NAMES } from "../../../../../../../../constants/constant";
import { EXPENDITURE_TYPES_SELECT } from "../../constants";
import { ISubExpenditure } from "../../types";

import { checkIsValidStructureItem } from "../../utils";

import styles from "./UngroupExpenditureStructureItem.module.scss";

export interface IUngroupExpenditureStructureItemProps {
  subExpenditure: ISubExpenditure;
  isEdit: boolean;
  onEdit: () => void;
  updateSubExpenditure: (exp: Partial<ISubExpenditure>) => void;
  onEditSuccess: (exp: Partial<ISubExpenditure>) => void;
  onEditCancel: () => void;
  onRemove: () => void;
}

const UngroupExpenditureStructureItem: React.FC<IUngroupExpenditureStructureItemProps> = ({
  subExpenditure,
  isEdit,
  onEdit,
  updateSubExpenditure,
  onEditSuccess,
  onEditCancel,
  onRemove,
}) => {
  const [editedInfo, setEditedInfo] = useState<ISubExpenditure>(subExpenditure);

  const onChangeInfo = useCallback(
    (field: string) => (value: any) => {
      setEditedInfo((prevState) => ({ ...prevState, [field]: value }));
      updateSubExpenditure({ [field]: value });
    },
    [updateSubExpenditure]
  );

  const validateAndSave = () => {
    if (checkIsValidStructureItem(editedInfo)) onEditSuccess(editedInfo);
    else message.warn("Заполните все поля");
  };

  if (isEdit) {
    return (
      <TableReusableRow className={styles.row} isHoverable={false}>
        <TableReusableCell isNoBreak>{subExpenditure.number}</TableReusableCell>
        <TableReusableCell isNoBreak>
          <InputBase
            value={editedInfo.name}
            onChange={(e) => onChangeInfo("name")(e.target.value)}
            className={styles.input}
          />
        </TableReusableCell>
        <TableReusableCell isNoBreak className={styles.selectCell}>
          <MeasureSelect value={editedInfo.measure} onChange={onChangeInfo("measure")} />
        </TableReusableCell>
        <TableReusableCell isNoBreak>
          <InputBase
            value={editedInfo.count}
            valueType={VALUE_TYPES.NUMBER}
            onChange={(e) => onChangeInfo("count")(e.target.value)}
            className={styles.input}
          />
        </TableReusableCell>
        <TableReusableCell isNoBreak className={styles.selectCell}>
          <Select
            value={editedInfo.expenditure_type}
            options={EXPENDITURE_TYPES_SELECT}
            onChange={onChangeInfo("expenditure_type")}
          />
        </TableReusableCell>
        <TableReusableCell isNoBreak>
          <ButtonPill
            onClick={onEditCancel}
            icon={"cross"}
            variant={"default"}
            onClickSecond={validateAndSave}
            secondVariant={"primary"}
            secondIcon={"check"}
          />
        </TableReusableCell>
      </TableReusableRow>
    );
  }
  return (
    <TableReusableRow className={styles.row} isHoverable={false}>
      <TableReusableCell isNoBreak>{subExpenditure.number}</TableReusableCell>
      <TableReusableCell isNoBreak>{subExpenditure.name}</TableReusableCell>
      <TableReusableCell isNoBreak>{subExpenditure.measure}</TableReusableCell>
      <TableReusableCell isNoBreak>{subExpenditure.count}</TableReusableCell>
      <TableReusableCell isNoBreak>{EXPENDITURE_TYPES_NAMES[subExpenditure.expenditure_type] || "-"}</TableReusableCell>
      <TableReusableCell isNoBreak>
        <ButtonPill
          onClick={onRemove}
          icon={"cross"}
          variant={"default"}
          onClickSecond={onEdit}
          secondVariant={"primary"}
          secondIcon={"edit"}
        />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(UngroupExpenditureStructureItem);
