import { IActions } from "redux/store";

import { IFileInStorage, ISetFoldersAndFilesActionProps } from "./types";
import { IBuildingInList } from "types/interfaces/Building";

export const fileStorageActions = {
  setRecent: (objectId: string | null, results: IFileInStorage[]) =>
    ({
      type: "storage/SET_RECENT",
      payload: {
        results,
        objectId,
      },
    } as const),
  setFoldersAndFiles: ({
    filesCount,
    foldersCount,
    objectId,
    folderId,
    files,
    folders,
    parentName,
  }: ISetFoldersAndFilesActionProps) =>
    ({
      type: "storage/SET_FOLDERS_AND_FILES",
      payload: {
        filesCount,
        foldersCount,
        objectId,
        folderId,
        files,
        folders,
        parentName,
      },
    } as const),
  deleteFileInStorage: (fileId: number) =>
    ({
      type: "storage/DELETE_FILE",
      payload: fileId,
    } as const),
  deleteFolder: (folderId: number) =>
    ({
      type: "storage/DELETE_FOLDER",
      payload: folderId,
    } as const),
  addFolder: (name: string, objectId: string, sectionId?: number) =>
    ({
      type: "storage/ADD_FOLDER",
      payload: { name, objectId, sectionId },
    } as const),
  setIsLoaded: (status: boolean, objectId: string, sectionId?: number) =>
    ({
      type: "storage/SET_IS_LOADING",
      payload: { status, objectId, sectionId },
    } as const),
  renameFile: (name: string, id: number) =>
    ({
      type: "storage/RENAME_FILE",
      payload: {
        name,
        id,
      },
    } as const),
  renameFolder: (name: string, id: number) =>
    ({
      type: "storage/RENAME_FOLDER",
      payload: {
        name,
        id,
      },
    } as const),
  setCounts: (objectId: string, counts: any) =>
    ({
      type: "storage/SET_COUNTS",
      payload: {
        objectId,
        counts,
      },
    } as const),
  setObjects: (objects: IBuildingInList[]) =>
    ({
      type: "storage/SET_OBJECTS",
      payload: objects,
    } as const),
  setObjectsIsLoading: (status: boolean) =>
    ({
      type: "storage/SET_OBJECTS_IS_LOADING",
      payload: status,
    } as const),
};

export type IFileStorageActions = IActions<typeof fileStorageActions>;
