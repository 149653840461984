import Sites7Svg from "./img/sites7.png";
import Sites8Svg from "./img/sites8.png";
import Sites9Svg from "./img/sites9.png";
import SitesMob7Svg from "./img/sites-mob7.png";
import SitesMob8Svg from "./img/sites-mob8.png";
import SitesMob9Svg from "./img/sites-mob9.png";
import { useEffect } from "react";
import $ from "jquery";

export const ExecutionWorksSection = () => {
  useEffect(() => {
    addActiveClassTree('reviews-min-text-tree', 'active-href');
    changeCaseBlockTree(this, 'reviews-min-text-tree', 'reviews-min-tree', 'active-href', 'click-reviews-tree');
    $('.click-reviews-tree').on('click', function () {
      changeActiveClassWithClickTree(this, 'reviews-min-text-tree', 'active-href')
      changeCaseBlockTree(this, 'reviews-min-text-tree', 'reviews-min-tree', 'active-href', 'click-reviews-tree');
    })
    function addActiveClassTree(parent_menu, active_class) {
      let prt = $('.' + parent_menu);
      let prt_childrens = $(prt).children();
      let prt_child_li = $(prt_childrens).children();
      let first_child = $(prt_child_li)[0]
      if (!$(first_child).hasClass(active_class)) {
        !$(first_child).addClass(active_class)
      }
    }
    function changeActiveClassWithClickTree($this, parent_block, active_class) {
      let prt = $($this).parents('.' + parent_block);
      let prt_child = $(prt).find('li');
      $(prt_child).each(function () {
        $(this).removeClass(active_class);
      })
      $($this).addClass(active_class);
    }
    function changeCaseBlockTree($this, case_menu, case_block, active_class, menu_link) {
      let case_menu_block = $('.' + case_menu);
      let case_block_sub = $('.' + case_block);
      let case_block_child = $(case_block_sub).children();
      $(case_block_child).each(function () {
        $(this).css({display: 'none', height: 0});
      })
      if ($($this).hasClass(menu_link)) {
        let this_attr = $($this).attr('data-catalog');
        $(case_block_child).each(function () {
          if ($(this).attr('data-catalog') == this_attr) {
            $(this).css({display: 'block', height: '100%'});
          }
        })
      } else {
        let active_find = $(case_menu_block).find('.' + active_class);
        let active_find_attr = $(active_find).attr('data-catalog');
        $(case_block_child).each(function () {
          if ($(this).attr('data-catalog') == active_find_attr) {
            $(this).css({display: 'block', height: '100%'});
          }
        })
      }
    }
  }, [])

  return (
    <section className="execution-works sites-block-plan" id="works-execution">
      <div className="container">
        <div className="sites-block__title">
          <h2>Выполнение работ</h2>
          <div className="reviews-min-text-tree menu-sites-blocks">
            <ul>
              <li className="click-reviews-tree active-href" data-catalog="gathering">Сбор факта</li>
              <li className="click-reviews-tree" data-catalog="acceptance">Приемка</li>
              <li className="click-reviews-tree" data-catalog="remarks">Замечания</li>
            </ul>
          </div>
        </div>
        <div className="reviews-min-tree">
          <div className="reviews-min-block-js" data-catalog="gathering">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы можете выбрать сметную или фактическую стоимость для закрытия работы</li>
                    <li>Вы видите данные о работах, выполненных подрядчиками, в режиме реального времени
                    </li>
                    <li>Подрядчики фиксируют выполнение работ на платформе, а руководители контролируют ход
                      производства работ
                    </li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Отслеживание прогресса и ключевых событий на графике</li>
                    <li>Контроль выполнения недельного плана</li>
                    <li>Соотнесение видов работ по статьям затрат и расценкам</li>
                    <li>Оценка объемов работ, планируемых к сдаче</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites7Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob7Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="acceptance">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>
                      Вы можете настроить многоуровневую систему согласования на приемку
                    </li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Внутренний контроль качества выполненных работ</li>
                    <li>Возможность организации нескольких уровней контроля качества работ</li>
                    <li>Возможность настройки цепочки согласований на приемку</li>
                    <li>Передача выполненных работ в ПТО одним кликом</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites8Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob8Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="remarks">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>
                      Вы видите все замечания на графике
                    </li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Формирование замечаний и предписаний</li>
                    <li>Контроль устранения замечаний и соблюдения сроков</li>
                    <li>Автоматическое информирование о добавлении замечания и изменении статуса</li>
                    <li>Автоматическое отображение статуса замечания на графике производства работ</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites9Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob9Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
        </div>
        <span className="title-bot">Контролируйте производство работ в любое время с любого устройства</span>
      </div>
    </section>
  )
}