export const VIEW_CONSTRUCTING_SECTION = "viewConstructingSection";
export const VIEW_BUILDINGS_SECTION = "viewBuildingsSection";
export const VIEW_ALL_BUILDINGS_TAB = "viewAllBuildingsTab";
export const VIEW_SENT_BUILDINGS_TAB = "viewSentBuildingsTab";
export const VIEW_ACCEPTED_BUILDINGS_TAB = "viewAcceptedBuildingsTab";
export const VIEW_CREATE_BUILDING = "viewCreateBuilding";
export const VIEW_EDIT_BUILDINGS = "viewEditBuildings";
export const VIEW_BUILDING_PLAN_TAB = "viewBuildingPlanTab";
export const VIEW_BUILDING_ADD_PLAN_INTERVALS = "viewBuildingAddPlanIntervals";
export const VIEW_BUILDING_TRANSFER_PLAN_WORKS = "viewBuildingTransferPlanWorks";
export const VIEW_BUILDING_ADD_GROUPS = "viewBuildingAddGroups";
export const VIEW_BUILDING_UNGROUP_GROUPS = "viewBuildingUngroupGroups";
export const VIEW_BUILDING_EDIT_GROUPS = "viewBuildingEditGroups";
export const VIEW_BUILDING_UNGROUP_EXPENDITURES = "viewBuildingUngroupExpenditures";
export const VIEW_BUILDING_PROGRESS_TAB = "viewBuildingProgressTab";
export const VIEW_BUILDING_ADD_FACT_INTERVALS = "viewBuildingAddFactIntervals";
export const VIEW_BUILDING_TRANSFER_FACT_WORKS = "viewBuildingTransferFactWorks";
export const VIEW_BUILDING_ADD_EXPENDITURE_FILES = "viewBuildingAddExpenditureFiles";
export const VIEW_BUILDING_DELETE_EXPENDITURE_FILES = "viewBuildingDeleteExpenditureFiles";
export const VIEW_BUILDING = "viewConstructingProject";

export const VIEW_ANALYTICS = "viewConstructingAnalytics";

export const VIEW_HANDLER = "viewHandler";
export const VIEW_DRAFT_HANDLER_STATE = "viewDraftHandlerState";
export const VIEW_LOCALE_HANDLER_STATE = "viewLocaleHandlerState";
export const VIEW_OBJECT_HANDLER_STATE = "viewObjectHandlerState";
export const VIEW_CONSOLIDATE_HANDLER_STATE = "viewConsolidateHandlerState";
export const VIEW_PRODUCTION_HANDLER_STATE = "viewProductionHandlerState";

export const VIEW_FINANCE_SECTION = "viewFinanceSection";
export const VIEW_FINANCE_FORECAST = "viewFinanceForecast";
export const VIEW_FINANCE_AUDIT = "viewFinanceAudit";
export const VIEW_FINANCE_SUMMARY = "viewFinanceSummary";
export const VIEW_FINANCE_SECTION_DOWNLOAD_BDR = "viewFinanceSectionDownloadBdr";
export const VIEW_FINANCE_SECTION_DOWNLOAD_FORECAST = "viewFinanceSectionDownloadForecast";
export const VIEW_FINANCE_WARRANTIES_AND_ADVANCE_PAYMENTS = "viewFinanceWarrantiesAndAdvancePayments";
export const VIEW_FINANCE_ADD_EXTRA_FORECAST = "viewConstructinfFinanceConsumptionAdd";
export const VIEW_FINANCE_EDIT_EXTRA_FORECAST = "viewConstructinfFinanceConsumptionEdit";
export const VIEW_FINANCE_DELETE_EXTRA_FORECAST = "viewConstructinfFinanceConsumptionDelete";

export const VIEW_COUNTERPARTIES_SECTION = "viewConstructingCounterpartiesSection";
export const VIEW_CONTACTS_LIST = "viewConstructingContactsList";
export const VIEW_ADD_CONTACT = "viewConstructingAddContact";
export const VIEW_DELETE_CONTACTS = "viewConstructingDeleteContacts";
export const VIEW_EDIT_CONTACT = "viewConstructingEditContact";
export const VIEW_CONTRACTORS_LIST = "viewContractorsList";
export const VIEW_ADD_CONTRACTOR = "viewAddContractor";
export const VIEW_DELETE_CONTRACTORS = "viewDeleteContractors";
export const VIEW_EDIT_CONTRACTOR_MANAGER = "viewEditContractorManager";
export const VIEW_ADD_CONTRACTORS_CONTRACTS = "viewAddContractorsContracts";
export const VIEW_DELETE_CONTRACTORS_CONTRACTS = "viewDeleteContractorsContracts";
export const VIEW_ADD_CONTRACTORS_CONTRACTS_FILES = "viewAddContractorsContractsFiles";
export const VIEW_DELETE_CONTRACTORS_CONTRACTS_FILES = "viewDeleteContractorsContractsFiles";
export const VIEW_SUPPLIERS_LIST = "viewConstructingSuppliersList";
export const VIEW_INVITE_SUPPLIER = "viewConstructingInviteSupplier";
export const VIEW_DELETE_SUPPLIERS = "viewConstructingDeleteSuppliers";
export const VIEW_EDIT_SUPPLIER_MANAGER = "viewConstructingEditSupplierManager";
export const VIEW_ADD_SUPPLIERS_CONTRACTS = "viewConstructingAddSuppliersContracts";
export const VIEW_DELETE_SUPPLIERS_CONTRACTS = "viewConstructingDeleteSuppliersContracts";
export const VIEW_ADD_SUPPLIERS_CONTRACTS_FILES = "viewConstructingAddSuppliersContractsFiles";
export const VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES = "viewConstructingDeleteSuppliersContractsFiles";
export const VIEW_CUSTOMERS_LIST = "viewCustomersList";
export const VIEW_INVITE_CUSTOMER = "viewInviteCustomer";
export const VIEW_DELETE_CUSTOMERS = "viewDeleteCustomers";
export const VIEW_EDIT_CUSTOMER_MANAGER = "viewEditCustomerManager";
export const VIEW_ADD_CUSTOMERS_CONTRACTS = "viewAddCustomersContracts";
export const VIEW_DELETE_CUSTOMERS_CONTRACTS = "viewDeleteCustomersContracts";
export const VIEW_ADD_CUSTOMERS_CONTRACTS_FILES = "viewAddCustomersContractsFiles";
export const VIEW_DELETE_CUSTOMERS_CONTRACTS_FILES = "viewDeleteCustomersContractsFiles";
export const VIEW_INVITES_LIST = "viewConstructingInvitesList";
export const VIEW_INVITES_LIST_ACTIONS = "viewConstructingInvitesListActions";

export const VIEW_DOCUMENTS_SECTION = "viewConstructingDocumentsSection";
export const VIEW_BILLS_LIST = "viewConstructingBillsList";
export const VIEW_BILL = "viewConstructingBill";
export const VIEW_BILL_IN_EDIT = "viewConstructingBillInEdit";
export const VIEW_ADD_BILL_FILES = "viewConstructingAddBillFiles";
export const VIEW_DELETE_BILL_FILES = "viewConstructingDeleteBillFiles";
export const VIEW_ADD_BILL_PAYMENTS_FILE = "viewConstructingAddBillPaymentsFile";
export const VIEW_BILL_PAYMENTS_MAKE_PAID = "viewConstructingBillPaymentsMakePaid";
export const VIEW_PACKING_LISTS = "viewConstructingPackingLists";
export const VIEW_PACKING_LIST_PAGE = "viewConstructingPackingListPage";
export const VIEW_PACKING_LIST_ACCEPT = "viewConstructingPackingListAccept";
export const VIEW_ADD_PACKING_LIST_FILES = "viewConstructingAddPackingListFiles";
export const VIEW_DELETE_PACKING_LIST_FILES = "viewConstructingDeletePackingListFiles";
export const VIEW_PACKING_LISTS_VAT_CALCULATION = "viewConstructingPackingListsVatCalculation";
export const VIEW_CONTRACTS_LIST = "viewConstructingContractsList";
export const VIEW_DELETE_CONTRACT = "viewConstructingDeleteContract";
export const VIEW_ADD_CONTRACT_FILES = "viewConstructingAddContractFiles";
export const VIEW_DELETE_CONTRACT_FILES = "viewConstructingDeleteContractFiles";
export const VIEW_EXPORT_LIST = "viewConstructingDocumentsExportList";
export const VIEW_DELETE_EXPORTS = "viewConstructingDeleteDocumentsExports";

export const VIEW_CONSTRUCTING_CHART = "viewConstructingChart";
export const VIEW_CONSTRUCTING_CHART_WORKS = "viewConstructingChartWorks";
export const VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS = "viewConstructingChartEditRelations";

export const VIEW_CONSTRUCTING_EDIT_DIRECTIVE_PLAN = "viewConstructingEditDirectivePlan";
export const VIEW_CONSTRUCTING_CREATE_DIRECTIVE_PLAN = "viewConstructingCreateDirectivePlan";
export const VIEW_CONSTRUCTING_CREATE_SECTION_PLAN = "viewConstructingCreateSectionPlan";
export const VIEW_CONSTRUCTING_EDIT_SECTION_PLAN = "viewConstructingEditSectionPlan";

export const VIEW_CONSTRUCTING_PLANS_DELETE = "viewBuildingPlansDelete";
export const VIEW_CONSTRUCTING_CHART_PLANS_DELETE = "viewBuildingChartPlansDelete";

export const VIEW_CONSTRUCTING_CHART_EDIT_PLAN = "viewConstructingChartEditPlan";
export const VIEW_CONSTRUCTING_EDIT_PLAN = "viewConstructingEditPlan";
