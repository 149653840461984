import axios from "axios";
import { createSelector } from "reselect";

import { invitesStateSelector } from "../invites";

import { LOAD_LIMIT, TABS, TABS_IDS } from "./constants";

import { prepareTableDataForSender } from "../pending/utils";

const initialState = {
  tabs: TABS,
  activeTab: TABS_IDS.ACCEPTED,
  isLoading: true,
  invites: {
    count: 0,
    list: [],
  },
  tableFilters: {},
};

const moduleName = "list";
const actionsPrefix = "constructing/counterparties/invites/list";

const SET_ACTIVE_TAB = `${actionsPrefix}_SET_ACTIVE_TAB`;
const SET_LOADING = `${actionsPrefix}_SET_LOADING`;
const SET_INVITES = `${actionsPrefix}_SET_INVITES`;
const SET_MORE_INVITES = `${actionsPrefix}_SET_MORE_INVITES`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;
const SET_TABLE_FILTER = `${actionsPrefix}_SET_TABLE_FILTER`;

export const stateSelector = createSelector(invitesStateSelector, (state) => state[moduleName]);
export const tabsSelector = createSelector(stateSelector, (state) => state.tabs);
export const activeTabSelector = createSelector(stateSelector, (state) => state.activeTab);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const invitesSelector = createSelector(stateSelector, (state) => state.invites);
export const tableFiltersSelector = createSelector(stateSelector, (state) => state.tableFilters);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_INVITES:
      return {
        ...state,
        invites: payload,
      };
    case SET_MORE_INVITES:
      return {
        ...state,
        invites: {
          ...state.invites,
          list: [...state.invites.list, ...payload.list],
        },
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case SET_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export const setActiveTab = (data) => ({
  type: SET_ACTIVE_TAB,
  payload: data,
});

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const setInvites = (data) => ({
  type: SET_INVITES,
  payload: data,
});

export const setMoreInvites = (data) => ({
  type: SET_MORE_INVITES,
  payload: data,
});

export const loadInvites = () => {
  return async (dispatch, getState) => {
    const activeTab = activeTabSelector(getState());
    const tableFilters = tableFiltersSelector(getState());

    try {
      dispatch(setLoading(true));
      const response = await axios.get("/partnership/invitations/", {
        params: {
          offset: 0,
          limit: LOAD_LIMIT,
          status: activeTab,
          ...tableFilters,
        },
      });
      const preparedData = {
        count: response.data.count,
        list: prepareTableDataForSender(response.data.results),
      };
      dispatch(setInvites(preparedData));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const loadMoreInvites = () => {
  return async (dispatch, getState) => {
    const activeTab = activeTabSelector(getState());
    const invites = invitesSelector(getState());
    try {
      const response = await axios.get("/providers/", {
        params: {
          offset: invites.list.length,
          limit: LOAD_LIMIT,
          status: activeTab,
        },
      });
      const preparedData = {
        count: response.data.count,
        list: prepareTableDataForSender(response.data.results),
      };
      dispatch(setMoreInvites(preparedData));
    } catch (e) {
      console.error(e);
    }
  };
};

export const clearData = () => ({
  type: CLEAR_DATA,
});

export const setTableFilter = (data) => ({
  type: SET_TABLE_FILTER,
  payload: data,
});
