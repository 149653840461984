import styles from "./RelationsInfoModal.module.scss";
import ButtonBase from "../../../../../atoms/ButtonBase";
import Modal from "../../../../../atoms/Modal";
import React, { FC, memo, useEffect, useState } from "react";
import axios from "axios";
import { errorCatcher } from "../../../../../../../utils/helpers/errorCatcher";
import { Spinner } from "../../../../../Spinner/Spinner";
import ExpenditureInfo from "./ExpenditureInfo";


interface IProductInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  matchingClickHandler: () => void;
  expendituresIds: number[];
  objectId: string;
}

const RelationsInfoModal: FC<IProductInfoModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    matchingClickHandler,
    objectId,
    expendituresIds
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [expenditures, setExpenditures] = useState<any[]>([]);

  useEffect(() => {
    if (isOpen) {
      const loadInfo = async () => {
        setIsLoading(true);

        const promises = expendituresIds.map(id => {
          return axios.get(`/building/${objectId}/estimate/expenditure/${id}/`)
            .then(({data}) => data)
            .catch(errorCatcher)
        });

        const settledPromises = await Promise.allSettled(promises);
        setExpenditures(settledPromises.map(promise => promise.value));
        setIsLoading(false);
      };

      loadInfo();
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="По смете"
      closeOnClickOutside={true}
    >
      <div className={styles.body}>
        {isLoading
          ? (
            <Spinner />
          )
          : (
            <>
              <h3>Количество связей: {expendituresIds.length}</h3>
              <div className={styles.infoWrapper}>
                {expenditures.map(exp => (
                  <ExpenditureInfo
                    section={exp?.section?.name}
                    number={exp?.number}
                    justification={exp?.justification}
                    expenditureName={exp?.name}
                    price={exp?.price}
                    count={exp?.count}
                  />
                ))}
              </div>

            </>
          )}
      </div>
      <footer className={styles.footer}>
        <div className={styles.btns}>
          <ButtonBase primary onClick={matchingClickHandler} medium>
            Изменить
          </ButtonBase>
        </div>
      </footer>
    </Modal>
  )
};

export default memo(RelationsInfoModal);
