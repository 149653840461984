import cn from "classnames";
import moment from "moment";
import React from "react";

import { IRemarksList } from "../../../../../../redux/modules/common/building/journal/journalExecution/types";

import FileRow from "components/UI/atoms/FileRow/FileRow";

import Icon from "../../../../Icon/Icon";
import iconCalendar from "./calendarEvent.svg";

import downloadFile from "utils/helpers/download";

import styles from "./RemarksInModal.module.scss";

export interface IRemarksInModalProps {
  remarks: IRemarksList[];
}

const RemarksInModal: React.FC<IRemarksInModalProps> = ({ remarks }) => {
  if (!remarks.length) return null;
  return (
    <div className={styles.remarks}>
      {remarks.map((remark) => {
        const resolveDate = moment(remark.resolve_date);
        const isCreated = remark.status === "created";
        const isOnReview = remark.status === "review";
        const createdDate = moment(remark.created_at);
        const withFiles = !!remark.with_files?.length;

        return (
          <div className={styles.remark} key={remark.id + remark.created_at}>
            <div className={styles.responsible}>
              <span className={styles.bold}>Создал:</span>
              <span>{remark.author_name}</span>
            </div>
            <div className={styles.resolve}>
              <span className={cn({ [styles.resolveStatus]: true, [styles.isOnReview]: isOnReview })}>
                {isCreated && (
                  <>
                    <div className={styles.point} /> Не решено
                  </>
                )}
                {isOnReview && (
                  <>
                    <div className={cn(styles.point, styles.isOnReview)} /> На проверке
                  </>
                )}
              </span>
              <div className={styles.resolveDate}>
                <Icon icon={iconCalendar} className={styles.iconCalendar} />
                <span>{createdDate.format("DD.MM.YYYY")}</span>
              </div>
            </div>
            <div className={styles.responsible}>
              <span className={styles.bold}>Ответственный:</span>
              <span>{remark.responsible_name}</span>
            </div>
            <div className={styles.resolve}>
              <span className={styles.bold}>Устранить до:</span>
              <div className={styles.resolveDate}>
                <Icon icon={iconCalendar} className={styles.iconCalendar} />
                <span>{resolveDate.format("DD.MM.YYYY")}</span>
              </div>
            </div>
            {!!remark.description && (
              <div className={styles.descr}>
                <span className={styles.bold}>Описание:</span>
                <span>{remark.description}</span>
              </div>
            )}
            {!!remark.regulations && (
              <div className={styles.resolve}>
                <span className={styles.bold}>Обоснование:</span>
                <span>{remark.regulations}</span>
              </div>
            )}
            {withFiles && (
              <div className={styles.resolve}>
                <span className={styles.bold}>Вложения:</span>
                <span>
                  {remark.with_files?.map((el) => (
                    <FileRow
                      key={el.id}
                      file={el as any}
                      canDownload
                      className={styles.file}
                      download={() => downloadFile(el.file, el.originalname)}
                    />
                  ))}
                </span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(RemarksInModal);
