import cn from "classnames";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { deleteReplacementFile, loadReplacementFile } from "redux/modules/common/building/sections/sections";

import FileRow from "components/UI/atoms/FileRow/FileRow";
import FilesClipButton from "components/UI/atoms/FilesClipButton/FilesClipButton";
import Select, { IOption } from "components/UI/atoms/Select";
import ConfirmModal from "components/UI/molecules/ConfirmationModal/ConfirmModal";

import { IFile } from "types/interfaces/Files";

import styles from "./ReplacedExpenditureFilesModal.module.scss";
import { ReplacementFileTypesEnum } from "../../types/ReplacementFileTypesEnum";

interface IReplacementFileTypeOption extends IOption {
  id: ReplacementFileTypesEnum
}

const typesOptions: IReplacementFileTypeOption[] = [
  {
    id: ReplacementFileTypesEnum.DRAWING,
    name: "Чертеж",
  },
  {
    id: ReplacementFileTypesEnum.COVER_LETTER,
    name: "Сопроводительное письмо"
  },
];

interface IProps {
  replacementId: number;
  buildingId: number;
  fileName?: string;
  filePath?: string;
  fileType?: IForm["type"];
  attachedFilesTypes?: ReplacementFileTypesEnum[];
}

interface IForm {
  type: ReplacementFileTypesEnum;
  file: File | IFile;
}

const ReplacementsFileForm: FC<IProps> = (props) => {
  const {
    replacementId,
    buildingId,
    fileName,
    filePath,
    fileType,
    attachedFilesTypes = []
  } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [form, setForm] = useState<IForm>({} as IForm);

  const isFinished = !!form.file && !!form.type;

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const targetFiles = Array.from(e.target.files || []);
    setForm((prev) => ({ ...prev, file: targetFiles[0] }));
    if (!!form.type) {
      dispatch(loadReplacementFile(buildingId, replacementId, targetFiles[0], form.type));
    }
  };

  useEffect(() => {
    if (!fileName && !filePath && !fileType) return;
    const formData: IForm = {} as IForm;
    if (/* !!fileName &&  */ !!filePath) {
      const file: IFile = {
        link: filePath,
        name: fileName ?? "file",
      } as IFile;
      formData.file = file;
    }
    if (!!fileType) {
      formData.type = fileType;
    }
    setForm((prev) => ({ ...prev, ...formData }));
  }, [fileName, filePath, fileType]);

  const [deletingModal, setDeletingModal] = useState(false);

  const filteredOptions = typesOptions.filter((o) => !attachedFilesTypes.includes(o.id))

  const deleteFile = () => {
    dispatch(deleteReplacementFile(buildingId, replacementId, form.type));
    setDeletingModal(false);
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <Select
          options={filteredOptions}
          label="Тип документа"
          onChange={(v) => {
            setForm((prev) => ({ ...prev, type: v as IForm["type"] }));
          }}
          value={fileType}
          containerClassName={styles.select}
          disabled={isFinished || !!fileType}
        />
        <div className={styles.inputWrapper}>
          <FilesClipButton onClick={() => fileInputRef.current?.click()} className={styles.filesBtn} />
          <input
            type="file"
            ref={fileInputRef}
            onChange={uploadFile}
            className={styles.filesInput}
            disabled={isFinished || !!filePath}
          />
        </div>
      </div>
      {!!form.file && (
        <div>
          <FileRow
            file={form.file as IFile}
            className={styles.fileRow}
            canRemove={!!form.type}
            onDirectlyRemove={() => setDeletingModal(true)}
          />
        </div>
      )}
      <ConfirmModal
        isOpen={deletingModal}
        onClose={() => setDeletingModal(false)}
        action={deleteFile}
        acceptButtonText="Удалить "
        cancelButtonText="Отменить"
        title="Подтвердите удаление"
        variant="secondary"
      />
    </>
  );
};

export default ReplacementsFileForm;
