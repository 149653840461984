import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { expendituresSelector } from "../../../../../redux/modules/common/building/sections/sections";

import Select from "../../../../UI/atoms/Select";

import AllChecker from "../AllChecker/AllChecker";
import TransferApprove from "../TransferApprove/TransferApprove";

import { ESTIMATE_ITEMS_TYPES, ESTIMATE_STATES_IDS } from "../../constants";

import styles from "./ChangeEstimateItemsState.module.scss";

const ChangeEstimateItemsState = ({
  estimateItemsChecker,
  subsectionId,
  estimateStatesMenu,
  allSections,
  changeEstimateItemsState,
  isShared,
}) => {
  const expenditures = useSelector(expendituresSelector);
  const [isApproving, setIsApproving] = useState(false);

  const displayingEstimateItemsType = useMemo(() => {
    if (subsectionId) return ESTIMATE_ITEMS_TYPES.EXPENDITURE;
    return ESTIMATE_ITEMS_TYPES.SECTION;
  }, [subsectionId]);

  const selectTargetEstimateState = useCallback(
    (targetEstimateStateId, chapter) => {
      const changedEstimateItemsIds = Object.entries(estimateItemsChecker.items)
        .filter(([, isChecked]) => isChecked)
        .map(([estimateItemId]) => estimateItemId);

      if (changedEstimateItemsIds.length === 0) return;
      estimateItemsChecker.reset();
      changeEstimateItemsState(targetEstimateStateId, changedEstimateItemsIds, chapter);
    },
    [estimateItemsChecker.reset, estimateItemsChecker.items, changeEstimateItemsState]
  );

  const onApprove = useCallback(
    (isApprove, targetEstimateStateId, chapter) => {
      setIsApproving(false);
      if (!isApprove) return;

      selectTargetEstimateState(targetEstimateStateId, chapter);
    },
    [selectTargetEstimateState]
  );

  const onChooseEstimateState = useCallback(
    (targetEstimateStateId) => {
      if (targetEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE) {
        setIsApproving(true);
        return;
      }
      selectTargetEstimateState(targetEstimateStateId);
    },
    [selectTargetEstimateState]
  );

  return (
    <div className={styles.container}>
      <AllChecker
        checkerItems={estimateItemsChecker.items}
        checkOnceEstimateItem={estimateItemsChecker.checkOnce}
        estimateItemsType={displayingEstimateItemsType}
        subsectionId={subsectionId}
        sections={allSections}
        expenditures={expenditures}
        isDisabled={isShared}
      />
      {isApproving && (
        <div className={styles.transferApprove}>
          <TransferApprove
            targetEstimateStateId={ESTIMATE_STATES_IDS.CONSOLIDATE}
            onApprove={onApprove}
            itemsCount={estimateItemsChecker.checkedCount}
          />
        </div>
      )}
      <div className={styles.selectContainer}>
        <span>Переместить выбранные в:</span>
        <Select
          className={styles.select}
          options={estimateStatesMenu}
          onChange={onChooseEstimateState}
          disabled={estimateItemsChecker.checkedCount === 0 || isShared}
        />
      </div>
    </div>
  );
};

export default React.memo(ChangeEstimateItemsState);
