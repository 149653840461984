import React from "react";

import {
  approveExpenditurePlan,
  approveGroupPlan,
  approveSectionPlan,
  editPlanInterval,
} from "redux/modules/common/building/processApi";

import { useUIIndicator } from "../../../../../../../hooks/uiIndicators/useUIIndicator";

import { MODULES_ENUM } from "../../../../../../../types/enums/ModulesEnum";

import { useUrlModule } from "../../../../../../../utils/hooks/useUrlModule";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const useApprovePlan = (
  buildingId: number,
  expenditureId: number,
  intervalId: number,
  dates: { year: number | undefined; month: number | undefined } = { year: undefined, month: undefined },
  variant: "section" | "group" | "expenditure",
  callback?: (id: number) => void
) => {
  const module = useUrlModule();

  const { deleteIndicator, indicator } = useUIIndicator({
    data: { exp_id: expenditureId, ...dates },
    module: module as MODULES_ENUM,
    type: "confirm_expenditure_plan",
    buildingId,
  });

  const approveHandler = () => {
    let promiseFn = () => new Promise((resolve) => {});
    switch (variant) {
      case "expenditure":
        promiseFn = () => approveExpenditurePlan(buildingId, intervalId);
        break;
      case "group":
        promiseFn = () => approveGroupPlan({ buildingId, planGroupId: intervalId });
        break;
      case "section":
        promiseFn = () => approveSectionPlan(buildingId, intervalId);
        break;
    }
    promiseFn()
      .then(() => {
        callback && callback(intervalId);
        deleteIndicator();
      })
      .catch(errorCatcher);
  };

  return approveHandler;
};
