import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { journalAcceptanceApprovalsSelector } from "redux/modules/common/building/journal/acceptance/selectors";

import ButtonBase from "../../../../../../UI/atoms/ButtonBase";
import Modal from "../../../../../../UI/atoms/Modal";
import Calendar from "../../../../../../UI/molecules/Calendar/Calendar";

import { ITicketApproval } from "../../../../../../../types/interfaces/Tickets";

import blueApproveIcon from "images/icons/blueCheckIcon";
import fillBlueApproveIcon from "images/icons/filledBlueCheckIcon";

import styles from "./JournalTicketAccept.module.scss";

const JournalTicketAccept = ({
  ticketId,
  isOpen,
  onClose,
  onAccept,
}: {
  ticketId: number;
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}) => {
  const approvals = useSelector(journalAcceptanceApprovalsSelector)[ticketId] ?? [];
  const confirmedApprovalsCount = useMemo(() => {
    return approvals?.filter((approver) => approver.is_confirmed)?.length;
  }, [approvals]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Подтвердите перевод в выполнение">
      <div className={styles.modal}>
        {approvals.length !== 0 && (
          <header className={styles.header}>
            <span className={styles.userNameCol}>Согласующий</span>
            <span>Дата и время</span>
          </header>
        )}
        {confirmedApprovalsCount !== 0 && (
          <>
            <div className={styles.sectionTitle}>
              Согласовано: <span className={styles.sectionCount}>{confirmedApprovalsCount}</span>
            </div>
            {approvals
              .filter((approver) => approver.is_confirmed)
              .map((approver) => (
                <div className={styles.row}>
                  <span className={styles.userNameCol}>{approver.user.name}</span>
                  <Calendar
                    classNameSelect={styles.calendar}
                    format="DD/MM/YY - HH:MM"
                    value={approver.confirmed_at}
                    disabled
                  />
                  <span className={styles.iconCol}>{fillBlueApproveIcon}</span>
                </div>
              ))}
          </>
        )}
        {confirmedApprovalsCount !== approvals.length && (
          <>
            <div className={styles.sectionTitle}>
              Ожидание согласования:{" "}
              <span className={styles.sectionCount}>{approvals.length - confirmedApprovalsCount}</span>
            </div>
            {approvals
              .filter((approver) => !approver.is_confirmed)
              .map((approver) => (
                <div className={styles.row}>
                  <span className={styles.userNameCol}>{approver.user.name}</span>
                  <span>-</span>
                  <span className={styles.iconCol}>{blueApproveIcon}</span>
                </div>
              ))}
          </>
        )}
      </div>
      <footer className={styles.footer}>
        <ButtonBase secondary onClick={onClose}>
          Отменить
        </ButtonBase>
        <ButtonBase primary onClick={onAccept}>
          Подтвердить
        </ButtonBase>
      </footer>
    </Modal>
  );
};

export default React.memo(JournalTicketAccept);
