import axios from "axios";

import { ICreateIndex, IHandlerIndex } from "./types";
import { IList } from "types/ListResponse";

export const indexesApi = {
  getIndexes: (buildingId: string, section_id: number) => {
    return axios.get<IList<IHandlerIndex>>(`/building/${buildingId}/estimate/indexes/`, {
      params: { section_id, limit: 500 },
    });
  },
  createIndex: (buildingId: string, vals: ICreateIndex) => {
    return axios.post(`/building/${buildingId}/estimate/indexes/`, vals);
  },
  editIndex: (buildingId: string, indexId: number, vals: Partial<ICreateIndex>) => {
    return axios.patch(`/building/${buildingId}/estimate/indexes/${indexId}/`, vals);
  },
  deleteIndex: (buildingId: string, indexId: number) => {
    return axios.delete(`/building/${buildingId}/estimate/indexes/${indexId}/`);
  },
};
