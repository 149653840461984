import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "../../../../utils/formatters/transformDigitToFinancial";

const formatPercentValue = (value) =>
  transformDigitToFinancial(
    parseTransformedString(value),
    { withFloat: true, dropZeros: true, log: true }
  );

const useBudgetDoubleInput = ({ amountSource, discountCost, discountPercent, changeDiscountCost }) => {

  const [changedDiscountCost, setChangedDiscountCost] = useState(
    transformDigitToFinancial(discountCost, { withFloat: true, dropZeros: true }) || "0"
  );
  const [changedDiscountPercent, setChangedDiscountPercent] = useState(() => formatPercentValue(discountPercent));

  const debouncedChangeExpenditureDiscountCost = useMemo(
    () => debounce((cost) => changeDiscountCost(cost), 500),
    [changeDiscountCost]
  );

  const onChangeDiscountPercent = useCallback(
    (value) => {
      let valueWithoutPercent = parseTransformedNumber(value);
      if (isNaN(+valueWithoutPercent) || +valueWithoutPercent >= 100 || +valueWithoutPercent < 0) return;

      setChangedDiscountPercent(parseTransformedString(value));

      const beatifiedNewDiscountCost = transformDigitToFinancial((valueWithoutPercent * +amountSource) / 100, {
        withFloat: true,
        dropZeros: true,
      });
      setChangedDiscountCost(beatifiedNewDiscountCost);
      debouncedChangeExpenditureDiscountCost(parseTransformedNumber(beatifiedNewDiscountCost));
    },
    [debouncedChangeExpenditureDiscountCost, amountSource]
  );

  const onChangeDiscountCost = useCallback(
    (value) => {
      let beautifiedValue = transformDigitToFinancial(parseTransformedString(value), {
        withFloat: true,
        dropZeros: true,
      });
      const parsedNumber = parseTransformedNumber(beautifiedValue);

      if (isNaN(+parsedNumber) || +parsedNumber >= amountSource || +parsedNumber < 0) return;

      debouncedChangeExpenditureDiscountCost(parsedNumber);
      setChangedDiscountPercent(formatPercentValue(((+parsedNumber * 100) / amountSource).toString()));
      setChangedDiscountCost(beautifiedValue);
    },
    [debouncedChangeExpenditureDiscountCost, amountSource]
  );

  return { changedDiscountCost, changedDiscountPercent, onChangeDiscountPercent, onChangeDiscountCost };
};

export default useBudgetDoubleInput;
