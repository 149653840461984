import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadObjects, objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";

import { orderStatusesType } from "constants/constant";
import { IIdAndName } from "types/idAndName";

import styles from "./OrdersTableHead.module.scss";

interface Iprops {
  onChangeFilter: (name: string, value: string) => void;
  tab: "inWorkOrders" | "acceptedOrders" | "completedOrders";
}

const OrdersTableHead: React.FC<Iprops> = ({ onChangeFilter, tab }) => {
  const dispatch = useDispatch();
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  React.useEffect(() => {
    if (breadcrumbsProjects.results.length !== 0) return;
    dispatch(loadObjects({ limit: 1000 }));
  }, [breadcrumbsProjects]);

  const modifiedProjectsArgs = breadcrumbsProjects.results.map((project: IIdAndName) => {
    return { id: project.id, name: project.name, label: project.name };
  });

  return (
    <TableReusableHead className={styles.row} isSticky>
      <TableReusableHeaderCell filterType="text" filterField="number" filterTitle="№" onChangeFilter={onChangeFilter}>
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>Заявка №</TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="building_id"
        filterOptions={modifiedProjectsArgs}
        filterTitle="Проект"
        filterType="select"
        onChangeFilter={onChangeFilter}
      >
        Проект
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="provider"
        filterType="text"
        filterTitle="Поставщик"
        onChangeFilter={onChangeFilter}
      >
        Поставщик
      </TableReusableHeaderCell>
      <TableReusableHeaderCell>УПД</TableReusableHeaderCell>
      <TableReusableHeaderCell>Счет</TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="status"
        filterOptions={orderStatusesType[tab]}
        filterTitle="Статус"
        filterType="select"
        onChangeFilter={onChangeFilter}
        isLast
      >
        Статус
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default React.memo(OrdersTableHead);
