import cn from "classnames";
import React, { MouseEventHandler, useCallback, useMemo, useState } from "react";

import { analyticsDatesSelector } from "../../../../../../../redux/modules/common/constructing/analytics/selectors";
import {
  IAnalyticsFulfillmentBuildingData,
  IAnalyticsFulfillmentSectionData,
  IAnalyticsFulfillmentWorkData,
} from "../../../../../../../redux/modules/common/constructing/analytics/types/fulfillment";
import { useTypedSelector } from "../../../../../../../redux/typedUseSelector";

import AnalyticsFulfillmentItemEntry from "../AnalyticsFulfillmentItemEntry/AnalyticsFulfillmentItemEntry";

import { percentsFormatter } from "../../../../../../../utils/percentsFormatter";
import { defineFulfillmentStatus } from "../../utils";

import styles from "./AnalyticsFulfillmentItem.module.scss";

export interface IAnalyticsFulfillmentItemProps {
  variant: "work" | "section" | "building";
  fulfillment: IAnalyticsFulfillmentWorkData | IAnalyticsFulfillmentSectionData | IAnalyticsFulfillmentBuildingData;
  onClick?: () => void;
}

const AnalyticsFulfillmentItem: React.FC<IAnalyticsFulfillmentItemProps> = ({ variant, fulfillment, onClick }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const disabled = !fulfillment.current_fact_percent;
  const selectedDates = useTypedSelector(analyticsDatesSelector);

  const { chip, factPercentColor, progressFill } = useMemo(
    () => defineFulfillmentStatus(fulfillment) || {},
    [fulfillment]
  );

  const onItemClick = () => onClick?.();

  const onMouseEnter = () => !disabled && setIsOpen(true);
  const onMouseLeave = () => setIsOpen(false);

  const periodSubtitle = selectedDates.monthsCount > 1 ? "на период" : "на месяц";

  return (
    <div
      className={cn(styles.container, { [styles.containerOpen]: isOpen, [styles.clickable]: !!onClick })}
      onClick={onItemClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.header}>
        <div className={styles.headline}>
          <span>{fulfillment.name}</span>
          {chip && (
            <div className={styles.chip} style={{ backgroundColor: chip.color }}>
              {chip.text}
            </div>
          )}
          <div className={styles.percents}>
            <span style={{ color: factPercentColor || "black" }}>
              {percentsFormatter(fulfillment.current_fact_percent || (!!fulfillment.current_plan_percent ? "nan" : 0))}
            </span>
            <span>из</span>
            <span>{percentsFormatter(fulfillment.current_plan_percent)}</span>
          </div>
        </div>
        <div className={styles.progress} style={{ background: progressFill }} />
      </div>
      {isOpen && (
        <div className={styles.body}>
          {variant === "work" && (fulfillment = fulfillment as IAnalyticsFulfillmentWorkData) && (
            <>
              <AnalyticsFulfillmentItemEntry
                title={"Запланировано"}
                entries={[
                  { subtitle: "на сегодня", value: fulfillment.current_plan_count, measure: fulfillment.measure },
                  { subtitle: periodSubtitle, value: fulfillment.period_plan_count, measure: fulfillment.measure },
                ]}
              />
              <hr />
              <AnalyticsFulfillmentItemEntry
                title={"Выполнено"}
                entries={[
                  { subtitle: "факт на сегодня", value: fulfillment.current_fact_count, measure: fulfillment.measure },
                ]}
              />
              <hr />
              <AnalyticsFulfillmentItemEntry
                title={fulfillment.delay > 0 ? "Опережение" : "Отставание"}
                entries={[{ value: Math.abs(fulfillment.delay), measure: "дн." }]}
              />
            </>
          )}
          {(variant === "section" || variant === "building") &&
            (fulfillment = fulfillment as IAnalyticsFulfillmentSectionData) && (
              <>
                <AnalyticsFulfillmentItemEntry
                  title={"Запланировано работ"}
                  entries={[
                    { subtitle: "на сегодня", value: fulfillment.current_works_count, measure: "шт." },
                    { subtitle: periodSubtitle, value: fulfillment.period_works_count, measure: "шт." },
                  ]}
                />
                <hr />
                <AnalyticsFulfillmentItemEntry
                  title={"Выполнение работ"}
                  entries={[
                    { subtitle: "выполняется", value: fulfillment.works_in_progress_count, measure: "шт." },
                    { subtitle: "завершено", value: fulfillment.works_completed_count, measure: "шт." },
                  ]}
                />
                <hr />
                <AnalyticsFulfillmentItemEntry
                  title={fulfillment.delay > 0 ? "Опережение" : "Отставание"}
                  entries={[{ value: Math.abs(fulfillment.delay), measure: "дн." }]}
                />
              </>
            )}
        </div>
      )}
    </div>
  );
};

export default React.memo(AnalyticsFulfillmentItem);
