import React, { useCallback, useState } from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";

import { ESTIMATE_STATES_IDS } from "components/pages/Handler/constants";
import ChooseChapter from "components/pages/Handler/components/ChooseChapter/ChooseChapter";
import { EstimateStateId } from "components/pages/Handler/types";

import styles from "./TransfetApprove.module.scss";
import { ChaptersEnum } from "components/pages/Handler/enums";

interface ITransferApproveProps {
  targetEstimateStateId: EstimateStateId;
  onApprove: (isApprove: boolean, targetEstimateStateId?: EstimateStateId, chapter?: ChaptersEnum) => void;
  itemsCount: number | string;
}

const TransferApprove = ({ targetEstimateStateId, onApprove, itemsCount }: ITransferApproveProps) => {
  const targetEstimateStateIsConsolidate = targetEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE;

  const [chosenChapter, setChosenChapter] = useState<ChaptersEnum | null>(null);

  const onClickApprove = useCallback(() => {
    if (targetEstimateStateIsConsolidate && chosenChapter) {
      onApprove(true, targetEstimateStateId, chosenChapter);
      return;
    }
    onApprove(true, targetEstimateStateId);
  }, [chosenChapter, onApprove, targetEstimateStateId, targetEstimateStateIsConsolidate]);

  const onClickCancel = useCallback(() => onApprove(false), [onApprove]);

  return (
    <div className={styles.approveBlock}>
      {targetEstimateStateIsConsolidate && (
        <div className={styles.chooseChapter}>
          <ChooseChapter onChoseChapter={setChosenChapter} chosenChapter={chosenChapter} />
        </div>
      )}
      <div className={styles.approve}>
        <span>Вы действительно хотите переместить {itemsCount} объект(ов)?</span>
        <div className={styles.actionBlock}>
          <ButtonBase
            className={styles.button1}
            secondary
            medium
            onClick={onClickCancel}
          >
            Отменить
          </ButtonBase>
          <ButtonBase
            primary
            medium
            onClick={onClickApprove}
            disabled={targetEstimateStateIsConsolidate && !chosenChapter}
          >
            Подтвердить
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TransferApprove);
export type { ITransferApproveProps };
