import { notification } from "antd";
import axios from "axios";
import { createSelector } from "reselect";

import { INotificationConfig } from "../../../components/UI/molecules/Header/components/Profile/types/types";

import { errorCatcher } from "utils/helpers/errorCatcher";

const moduleName = "notifications";
const SET_ITEMS = `${moduleName}/SET_ITEMS`;
const ADD_ITEM = `${moduleName}/ADD_ITEM`;
const READ_ITEM = `${moduleName}/READ_ITEM`;
const SET_IS_LOADING = `${moduleName}/SET_IS_LOADING`;
const ADD_ITEMS = `${moduleName}/ADD_ITEMS`;

const SET_TO_MODIFY = `${moduleName}/SET_TO_MODIFY`;

export const initialState = {
  unreadValue: 0,
  eventCount: 0,
  allNotifications: [],
  isLoading: true,
  maxCount: 0,
  toModify: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_ITEMS:
      return {
        ...state,
        allNotifications: payload.results,
        isLoading: false,
        maxCount: payload.count,
        unreadValue: payload.unread_count,
        eventCount: payload.event_count,
      };

    case ADD_ITEMS:
      return {
        ...state,
        allNotifications: [...state.allNotifications, ...payload.results],
        isLoading: false,
        maxCount: payload.count,
        unreadValue: payload.unread_count,
      };

    case ADD_ITEM:
      return {
        ...state,
        unreadValue: state.unreadValue + 1,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case READ_ITEM:
      const oldItem = state.allNotifications.find((notification) => notification.id === payload);
      const newItem = {
        ...oldItem,
        is_read: true,
      };
      const newArr = [...state.allNotifications];
      const oldIndex = newArr.indexOf(oldItem);
      newArr.splice(oldIndex, 1);
      newArr.splice(oldIndex, 0, newItem);
      return {
        ...state,
        allNotifications: newArr,
        unreadValue: state.unreadValue - 1,
      };

    case SET_TO_MODIFY:
      return {
        ...state,
        toModify: {
          email: payload.email,
          web: payload.web.filter((item) => item?.class_name !== "NotificationChatMentionV2"),
        },
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state.notifications;
export const allNotificationsSelector = createSelector(stateSelector, (state) => state.allNotifications);
export const unreadValSelector = createSelector(stateSelector, (state) => state.unreadValue);
export const eventCountSelector = createSelector(stateSelector, (state) => state.eventCount);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const maxCountSelector = createSelector(stateSelector, (state) => state.maxCount);
export const notificationsToModifySelector = createSelector(stateSelector, (state) => state.toModify);

export const setItems = (items) => ({
  type: SET_ITEMS,
  payload: items,
});

export const addItems = (items) => ({
  type: ADD_ITEMS,
  payload: items,
});

export const addNotification = (item) => ({
  type: ADD_ITEM,
  payload: item,
});

export const setNotificationRead = (id) => ({
  type: READ_ITEM,
  payload: id,
});

export const setIsLoading = () => ({
  type: SET_IS_LOADING,
});

export const setToModify = (notifications) => ({
  type: SET_TO_MODIFY,
  payload: notifications,
});

export const getNotifications = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (offset, more = false) => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
      params: {
        limit: 20,
        offset,
      },
    };

    return (dispatch) => {
      dispatch(setIsLoading());
      axios
        .get(`/users/current/notifications/`, config)
        .then((res) => {
          more ? dispatch(addItems(res.data)) : dispatch(setItems(res.data));
        })
        .catch(errorCatcher);
    };
  };
})();

export const readNotification = (id) => {
  const data = {
    is_read: true,
  };

  return (dispatch) =>
    axios
      .patch(`/users/current/notifications/${id}/`, data)
      .then(() => {
        dispatch(setNotificationRead(id));
      })
      .catch(errorCatcher);
};

export const hideNotification = (id, val) => {
  const data = {
    is_hidden: val,
  };

  return () => axios.patch(`/users/current/notifications/${id}/`, data);
};

export const getNotificationsToModify = () => (dispatch) => {
  return axios
    .get(`/notifications/types/`)
    .then(({ data }) => dispatch(setToModify(data)))
    .catch(errorCatcher);
};

export const unsubscribeNotification = (currentUnsubscribtions, option, type, isSubscribed) => async (dispatch) => {
  let unsubscribeData = {
    web_unsubscribe: currentUnsubscribtions.web?.filter((x) => x.is_unsubscribed)?.map((x) => x.class_name),
    email_unsubscribe: currentUnsubscribtions.email?.filter((x) => x.is_unsubscribed)?.map((x) => x.class_name),
  };
  const notificationType = type === "web" ? "web_unsubscribe" : "email_unsubscribe";
  if (isSubscribed) {
    unsubscribeData[notificationType] = currentUnsubscribtions[type]
      ?.filter((x) => {
        if (x.class_name === option.class_name) return false;
        return x.is_unsubscribed;
      })
      ?.map((x) => x.class_name);
  } else {
    unsubscribeData[notificationType] = currentUnsubscribtions[type]
      ?.filter((x) => x.is_unsubscribed)
      ?.map((x) => x.class_name)
      .concat(option.class_name);
  }
  return axios
    .post(`/notifications/email/unsubscribe/`, unsubscribeData)
    .then(() => dispatch(getNotificationsToModify()))
    .catch(errorCatcher);
};
