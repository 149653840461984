import React, { useCallback, useState } from "react";

import SendToPaymentModal from "../../../pages/Order/components/SendToPaymentModal";

import ButtonBase from "../../atoms/ButtonBase";
import CancelOrderModal from "../CancelOrderModal/CancelOrderModal";

import styles from "./index.module.scss";

const ManageOrder = (props) => {
  const {
    options,
    canCancelOrder,
    cancelOrder,
    canSendToPayment,
    sendToPayment,
    orderPaymentTerms,
    canSaveOrder,
    saveOrder,
    canSendToPaymentDecision,
    sendToPaymentDecision,
    isLoading,
  } = props;

  const [isSendToPaymentModalOpen, setIsSendToPaymentModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const openSendToPaymentModal = useCallback(() => setIsSendToPaymentModalOpen(true), []);
  const closeSendToPaymentModal = useCallback(() => setIsSendToPaymentModalOpen(false), []);

  const openCancelModal = useCallback(() => setIsCancelModalOpen(true), []);
  const closeCancelModal = useCallback(() => setIsCancelModalOpen(false), []);

  const handleSendToPayment = useCallback(
    (message) => {
      closeSendToPaymentModal();
      sendToPayment(message);
    },
    [closeSendToPaymentModal, sendToPayment]
  );

  const handleCancelOrder = useCallback(() => {
    closeCancelModal();
    cancelOrder();
  }, [closeCancelModal, cancelOrder]);

  if (isLoading) return null;

  return (
    <div className={styles.manageOrder}>
      {canCancelOrder && (
        <>
          <ButtonBase className={styles.button} onClick={openCancelModal} medium secondary>
            Отменить заказ
          </ButtonBase>
          <CancelOrderModal isOpen={isCancelModalOpen} cancelOrder={handleCancelOrder} closeModal={closeCancelModal} />
        </>
      )}
      {options.map((option, i) => (
        <ButtonBase
          className={styles.button}
          onClick={option.callback}
          secondary={option.variant === "secondary"}
          key={i}
          medium
        >
          {option.name}
        </ButtonBase>
      ))}
      {canSendToPaymentDecision && (
        <ButtonBase className={styles.button} onClick={sendToPaymentDecision} medium>
          Отправить в решение по оплате
        </ButtonBase>
      )}
      {canSendToPayment && (
        <>
          <ButtonBase className={styles.button} onClick={openSendToPaymentModal} medium>
            Отправить в оплату
          </ButtonBase>
          <SendToPaymentModal
            isOpen={isSendToPaymentModalOpen}
            sendToPayment={handleSendToPayment}
            onClose={closeSendToPaymentModal}
            paymentTerms={orderPaymentTerms}
          />
        </>
      )}
      {canSaveOrder && (
        <ButtonBase className={styles.button} onClick={saveOrder} medium>
          Сохранить заказ
        </ButtonBase>
      )}
    </div>
  );
};

export default React.memo(ManageOrder);
