import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { compose } from "redux";

import {
  detailLoadingSelector,
  getDetail,
  getTimesheetApproval,
  getTimesheetApprovers,
  timesheetApprovalSelector,
} from "redux/modules/common/building/object/nowObject";
import {
  addToWorkers,
  loadWorkers,
  loadWorkersCountWork,
  setWorkersList,
  workersCountWorkSelector,
  workersLoadingSelector,
  workersSelector,
} from "redux/modules/common/building/workers";

import SelectStatus from "../../UI/atoms/SelectStatus/SelectStatus";
import TabBarNotLinks from "../../UI/atoms/TabBar/TabBarNotLinks";
import AddWorkerModal from "./components/AddWorkerModal/AddWorkerModal";
import Timesheet from "./components/Timesheet/Timesheet";
import { STATUSES } from "./components/Timesheet/constants";
import Turnout from "./components/Turnout/Turnout";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import MonthsYearsSlider from "components/UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import WeeksInYear from "components/UI/molecules/WeeksSlider/domain/WeeksInYear";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";

import { LAPTOP_WIDTH } from "constants/dimensions";
import {
  VIEW_MANUFACTURING_WORKERS_EXPORT_WORKERS,
  VIEW_MANUFACTURING_WORKERS_IMPORT_WORKERS,
  VIEW_MANUFACTURING_WORKERS_TIMESHEET,
  VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_WORKERS,
  VIEW_MANUFACTURING_WORKERS_TURNOUT,
} from "constants/permissions/manufacturingPermissions";

import { useExportImportWorkers } from "./hooks/useExportImportWorkers";
import usePermission from "hooks/usePermission";
import { useLocationArray } from "utils/hooks/useLocationArray";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./Workers.module.scss";
import { useQueryParams } from "../../../utils/hooks/useQueryParams";

const INITIAL_WORKERS_PAGINATION = {
  limit: 10,
  offset: 0,
};

export const SELECT_OPTIONS_IDS = { ADD: "add", EXPORT: "export", IMPORT: "import" };

const Workers = ({ match, location, history }) => {
  const dispatch = useDispatch();
  const { objectId: buildingId } = useParams();
  const getTimesheetApprovalInterval = useRef();
  const fileInputRef = useRef(null);
  const tabPath = useLocationArray()?.at(-1);

  const buildingIsLoading = useSelector(detailLoadingSelector);
  const workers = useSelector(workersSelector);
  const workersAreLoading = useSelector(workersLoadingSelector);
  const workersCountWork = useSelector(workersCountWorkSelector);
  const timesheetApproval = useSelector(timesheetApprovalSelector);

  const v = useQueryParams('month');
  const [activeDate, setActiveDate] = useState(v ? moment().set('month', +v) : moment());
  const [fileValue, setFileValue] = useState("");

  const year = activeDate.year();
  const monthIndex = activeDate.month();
  const monthNumber = monthIndex + 1;
  const activeWeekNumber = activeDate.week();

  const startOfMonthFormattedDate = useMemo(
    () => moment(activeDate).startOf("month").format("DD.MM.YYYY"),
    [activeDate]
  );
  const endOfMonthFormattedDate = useMemo(() => moment(activeDate).endOf("month").format("DD.MM.YYYY"), [activeDate]);

  const [workersPagination, setWorkersPagination] = useState(INITIAL_WORKERS_PAGINATION);
  const [workersQueryParams, setWorkersQueryParams] = useState();

  const formT13FileIsLoading = timesheetApproval?.status === STATUSES.COMPLETED && !timesheetApproval.file;

  const haveViewTurnoutPermission = usePermission(VIEW_MANUFACTURING_WORKERS_TURNOUT);
  const haveViewTimeSheetPermission = usePermission(VIEW_MANUFACTURING_WORKERS_TIMESHEET);
  const haveAddWorkerPermission = usePermission(VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_WORKERS);
  const haveExportWorkersPermission = usePermission(VIEW_MANUFACTURING_WORKERS_EXPORT_WORKERS);
  const haveImportWorkersPermission = usePermission(VIEW_MANUFACTURING_WORKERS_IMPORT_WORKERS);

  const TABS = useMemo(() => {
    const res = [];
    if (haveViewTurnoutPermission) {
      res.push({ id: "turnout", text: "Явка" });
    }
    if (haveViewTimeSheetPermission) {
      res.push({ id: "timesheet", text: "Табель" });
    }
    return res;
  }, [haveViewTurnoutPermission, haveViewTimeSheetPermission]);

  useEffect(() => {
    return () => {
      setWorkersList(null);
    };
  }, []);

  useEffect(() => {
    if (!haveViewTurnoutPermission && haveViewTimeSheetPermission) {
      history.push(`${match.url}/timesheet`);
    }
  }, [haveViewTurnoutPermission, haveViewTimeSheetPermission]);

  const activeTabId = useMemo(() => {
    const partOfSectionPath = location.pathname.replace(match.url, "");
    return TABS.find((tab) => `/${tab.id}` === partOfSectionPath)?.id;
  }, [TABS, location.pathname, match.url]);

  const redirectToSectionByTabId = useCallback(
    (tabId) => {
      history.push(`${match.url}/${tabId}`);
    },
    [history, match.url]
  );

  const loadMore = useCallback(
    () =>
      setWorkersPagination((prevState) => ({
        ...prevState,
        offset: (prevState?.offset || 0) + prevState?.limit,
        tabelmonth: monthNumber,
        tabelyear: year,
      })),
    []
  );

  const debouncedLoadWorkersCountWork = useMemo(
    () => debounce((config) => dispatch(loadWorkersCountWork(buildingId, config)), 500),
    [buildingId]
  );

  const changeFilters = useCallback(
    (value, name) =>
      setWorkersQueryParams((prevState) => ({
        ...prevState,
        [name]: value,
      })),
    []
  );

  const [startOfWeekDate] = useMemo(
    () => WeeksInYear.getRangeByWeekNumber(year, activeWeekNumber),
    [activeWeekNumber, year]
  );

  const [isAddWorkerModalOpen, setIsAddWorkerModalOpen] = useState(false);
  const closeAddWorkerModal = useCallback(() => setIsAddWorkerModalOpen(false), []);
  const openAddWorkerModal = useCallback(() => setIsAddWorkerModalOpen(true), []);

  const getWorkers = useCallback(() => {
    dispatch(
      loadWorkers(buildingId, {
        ...workersQueryParams,
        ...workersPagination,
        tabelmonth: monthNumber,
        tabelyear: year,
      })
    );
  }, [buildingId, dispatch, monthNumber, workersPagination, workersQueryParams, year]);

  const { handleExportWorkers, handleImportWorkers } = useExportImportWorkers(buildingId, getWorkers);

  useEffect(() => {
    dispatch(getTimesheetApproval({ buildingId, year, month: monthNumber }));
  }, [year, monthNumber, buildingId]);

  useEffect(() => {
    if (workersPagination.offset === INITIAL_WORKERS_PAGINATION.offset) return;
    dispatch(addToWorkers(buildingId, workersPagination));
  }, [buildingId, workersPagination]);

  useEffect(() => {
    if (tabPath !== 'workers') {
      setWorkersPagination(INITIAL_WORKERS_PAGINATION);
      getWorkers();
    } else {
      history.push(location.pathname + '/turnout');
    }
  }, [buildingId, workersQueryParams, tabPath, monthNumber, year]);

  useEffect(() => {
    if (!workers || !workers.results) return;

    debouncedLoadWorkersCountWork({
      worker_ids: workers.results.map((item) => item.id).join(","),
      work_date_after: startOfMonthFormattedDate,
      work_date_before: endOfMonthFormattedDate,
      limit: 1000,
    });
  }, [startOfMonthFormattedDate, endOfMonthFormattedDate, debouncedLoadWorkersCountWork, workers, tabPath]);

  useEffect(() => {
    compose(dispatch, getDetail)(buildingId);
  }, [buildingId]);

  useEffect(() => {
    compose(dispatch, getTimesheetApprovers)({ buildingId: buildingId, year, month: monthNumber });
  }, [buildingId, year, monthNumber]);

  useEffect(() => {
    if (getTimesheetApprovalInterval.current) clearInterval(getTimesheetApprovalInterval.current);
    if (!formT13FileIsLoading) return;

    getTimesheetApprovalInterval.current = setInterval(
      () => dispatch(getTimesheetApproval({ buildingId: buildingId, year, month: monthNumber })),
      10000
    );
  }, [formT13FileIsLoading, monthNumber, year, buildingId]);

  const isShowOnlyAddWorkerButton =
    haveAddWorkerPermission && !haveExportWorkersPermission && !haveImportWorkersPermission;

  const onFileInputChange = useCallback(
    (event) => {
      handleImportWorkers(Array.from(event.target.files));
    },
    [handleImportWorkers]
  );

  const openFileDialog = () => {
    if (!fileInputRef.current) return;

    fileInputRef.current.click();
  };

  const handleStatusChange = (status) => {
    switch (status) {
      case SELECT_OPTIONS_IDS.ADD:
        openAddWorkerModal();
        break;
      case SELECT_OPTIONS_IDS.IMPORT:
        openFileDialog();
        break;
      case SELECT_OPTIONS_IDS.EXPORT:
        handleExportWorkers();
        break;
      default:
        break;
    }
  };

  const selectOptions = useMemo(() => {
    const res = [];
    if (haveAddWorkerPermission)
      res.push({
        id: SELECT_OPTIONS_IDS.ADD,
        name: (
          <div className={styles.selectItem}>
            <span>Добавить сотрудника</span>
          </div>
        ),
      });
    if (haveExportWorkersPermission)
      res.push({
        id: SELECT_OPTIONS_IDS.IMPORT,
        name: (
          <div className={styles.selectItem}>
            <span>Загрузить</span>
          </div>
        ),
      });
    if (haveImportWorkersPermission)
      res.push({
        id: SELECT_OPTIONS_IDS.EXPORT,
        name: (
          <div className={styles.selectItem}>
            <span>Выгрузить</span>
          </div>
        ),
      });
    return res;
  }, [haveAddWorkerPermission, haveExportWorkersPermission, haveImportWorkersPermission]);

  if (!haveViewTurnoutPermission && !haveViewTimeSheetPermission) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <TemplateBase>
      <div className={styles.container}>
        <header className={styles.header} onClick={stopEventPropagation}>
          <TabBarNotLinks activeId={activeTabId} tabs={TABS} onClick={redirectToSectionByTabId} />
          <MonthsYearsSlider className={styles.slider} date={activeDate} onChange={setActiveDate} />
          {isShowOnlyAddWorkerButton ? (
            <ButtonBase
              className={styles.addButton}
              onClick={openAddWorkerModal}
              small={window.innerWidth <= LAPTOP_WIDTH}
              secondary
            >
              Добавить сотрудника
            </ButtonBase>
          ) : (
            <>
              <SelectStatus
                className={styles.selectContainer}
                contentClassName={styles.selectContent}
                optionsClassName={styles.selectOptions}
                optionClassName={styles.selectOption}
                options={selectOptions}
                onChange={handleStatusChange}
                disabled={buildingIsLoading}
                value={SELECT_OPTIONS_IDS.ADD}
              />
              <input
                ref={fileInputRef}
                type="file"
                onClick={() => setFileValue("")}
                onChange={onFileInputChange}
                value={fileValue}
                className={styles.hiddenInput}
                accept=".xlsx"
              />
            </>
          )}
        </header>
        {!workersAreLoading && !buildingIsLoading && workersCountWork && timesheetApproval ? (
          <Switch>
            <Route exact path={match.path}>
              <Redirect to={match.url + "/turnout"} />
            </Route>
            <Route exact path={match.path + "/turnout"}>
              <Turnout
                buildingId={buildingId}
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                changeFilters={changeFilters}
                loadMore={loadMore}
                workersPaginationLimit={workersPagination.limit}
                startOfWeekDate={startOfWeekDate}
              />
            </Route>
            <Route exact path={match.path + "/timesheet"}>
              <Timesheet
                buildingId={buildingId}
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                loadMore={loadMore}
                workersPaginationLimit={workersPagination.limit}
                getWorkers={getWorkers}
              />
            </Route>
          </Switch>
        ) : (
          <Spinner />
        )}
      </div>
      <AddWorkerModal buildingId={buildingId} isOpen={isAddWorkerModalOpen} onClose={closeAddWorkerModal} />
    </TemplateBase>
  );
};

export default Workers;
