import moment, { Moment } from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { Spinner } from "components/UI/Spinner/Spinner";
import BackNavigationBar from "components/UI/atoms/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import Select from "components/UI/atoms/Select";
import TitleRegistryRow from "components/UI/atoms/TitleRegistryRow/TitleRegistryRow";
import WeeksSlider from "components/UI/molecules/WeeksSlider/WeeksSlider";
import { getDisplayWeeksCountForWindowWidth } from "components/pages/Workers/components/Turnout/utils/getDisplayWeeksCountForWindowWidth";
import { planFactVolumeApi } from "pages/PlanFact/PlanFactVolume/model/planFactVolumeApi";
import {
  planFactVolumeDaysCountSelector,
  planFactVolumeExpendituresLoadingSelector,
  planFactVolumeExpendituresSelector,
  planFactVolumeSectionsLoadingSelector,
  planFactVolumeSectionsSelector,
} from "pages/PlanFact/PlanFactVolume/model/selectors";
import { getPlanFactVolumeSections, getPlanFactVolumesExpenditures } from "pages/PlanFact/PlanFactVolume/model/thunks";

import PlanFactVolumeSection from "./PlanFactVolumeSection/PlanFactVolumeSection";
import PlanfactVolumeExpenditure from "./PlanfactVolumeExpenditure/PlanfactVolumeExpenditure";
import { planFactVolumeActions } from "./model/actions";
import PlanFactVolumeTableHead from "./ui/PlanFactVolumeTableHead/PlanFactVolumeTableHead";

import { daysInWeekCountOptions } from "./constants";
import { IRouterSectionId, IRouterSectionIdSubsectionId } from "./types";
import { VIEW_PLAN_FACT_VOLUME } from "constants/permissions/manufacturingPermissions";
import { ROUTES } from "constants/routes";

import usePermission from "hooks/usePermission";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import planFact from "images/icons/navigation/plan-fact.svg";

import styles from "./PlanFactVolume.module.scss";

interface IProps {
  date: Moment;
  setDate: React.Dispatch<React.SetStateAction<Moment>>;
}

const PlanFactVolumeExpenditures: React.FC<IProps> = ({ date, setDate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { subsectionId, objectId, sectionId } = useParams<IRouterSectionIdSubsectionId>();
  const setDaysCount = (count: 5 | 7) => {
    dispatch(planFactVolumeActions.setDaysCount(count));
  };

  const daysCount = useSelector(planFactVolumeDaysCountSelector);

  const startAt = date.startOf("week").format("YYYY-MM-DD");
  const endAt = date.endOf("week").format("YYYY-MM-DD");

  const key = generateStorageKey({ sectionId: subsectionId, startAt, endAt, daysCount });
  const sectionKey = generateStorageKey({ buildingId: objectId, startAt, endAt });
  const lsrs = useSelector(planFactVolumeSectionsSelector)[sectionKey];
  const expenditures = useSelector(planFactVolumeExpendituresSelector)[key];
  const isExpendituresLoading = useSelector(planFactVolumeExpendituresLoadingSelector)[key];

  const isEmpty = !expenditures?.expenditures?.length && !expenditures?.groups?.length;

  const section = React.useMemo(() => {
    if (!lsrs) return null;
    return lsrs.find((el) => +el.id === +sectionId) || null;
  }, [lsrs, sectionId]);

  const setWeek = React.useCallback(
    (week: number) => {
      const newDate = moment(date);
      newDate.week(week);
      setDate(newDate);
    },
    [setDate, date]
  );

  React.useLayoutEffect(() => {
    dispatch(getPlanFactVolumesExpenditures(objectId, startAt, endAt, subsectionId, daysCount));
  }, [objectId, subsectionId, startAt, endAt, daysCount]);

  React.useEffect(() => {
    if (!!lsrs?.length) return;
    dispatch(getPlanFactVolumeSections(objectId, startAt, endAt, daysCount));
  }, [objectId, startAt, endAt, lsrs, daysCount]);

  const viewVolumePermission = usePermission(VIEW_PLAN_FACT_VOLUME);

  const itemCount = expenditures?.groups?.length + expenditures?.expenditures?.length;

  if (!viewVolumePermission) {
    return <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE} />;
  }
  return (
    <>
      <div className={styles.head}>
        <div className={styles.flex}>
          <WeeksSlider
            className={styles.slider}
            year={date.year()}
            activeWeekNumber={date.week()}
            setActiveWeekNumber={setWeek}
            displayWeeksCount={getDisplayWeeksCountForWindowWidth(window.innerWidth)}
          />
          <Select options={daysInWeekCountOptions} onChange={setDaysCount as any} value={daysCount} />
        </div>
        <BackNavigationBar className={styles.navbar} title={section?.name!} />
        <PlanFactVolumeTableHead daysCount={daysCount} startAt={startAt} />
      </div>
      {isExpendituresLoading && !itemCount && <Spinner />}
      {!isExpendituresLoading && isEmpty && <EmptyPlaceholder img={planFact} />}
      {expenditures?.groups?.map((el) => (
        <PlanfactVolumeExpenditure
          key={el.id}
          expenditure={el}
          startAt={startAt}
          nestedExpenditures={el.expenditures}
          daysCount={daysCount}
        />
      ))}
      {expenditures?.expenditures?.map((el) => (
        <PlanfactVolumeExpenditure key={el.id} expenditure={el} startAt={startAt} daysCount={daysCount} />
      ))}
    </>
  );
};

export default React.memo(PlanFactVolumeExpenditures);
