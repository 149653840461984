import { IExpenditureInHandlerProduction } from "components/pages/Handler/components/Body/components/Expenditures/types";

import { extractKey } from "utils/helpers/extractKey";

export const handlerOutOfEstimateUtils = {
  extractNewResources: (
    values: IExpenditureInHandlerProduction["editingForm"],
    newMaterialIds: number[],
    relatedWorkId: number,
    sectionId: number
  ) => {
    const res: IExpenditureInHandlerProduction["editingForm"][] = [];
    newMaterialIds.forEach((id) => {
      res.push({
        ...values?.[`id:${id}`],
        related_work_id: relatedWorkId,
        section_id: sectionId,
      } as IExpenditureInHandlerProduction["editingForm"] & {
        related_work_id: number;
        section_id: number;
      });
    });

    return res;
  },
  prepareValuesForEdit: (
    values: IExpenditureInHandlerProduction["editingForm"],
    newMaterialIds: number[],
    deletedMaterialsIds: number[]
  ) => {
    if (!values) return;
    const work: IExpenditureInHandlerProduction["editingForm"] = {
      count: values.count,
      price: values.price,
      id: values.id,
      name: values.name,
      number: values.number,
      measure: values.measure,
      expenditure_type: values.expenditure_type,
    } as IExpenditureInHandlerProduction["editingForm"];
    const res: IExpenditureInHandlerProduction["editingForm"][] = [];

    for (let key in values) {
      if (!newMaterialIds.includes(+extractKey(key)) && !deletedMaterialsIds.includes(+extractKey(key))) {
        !!values[key]?.id &&
          typeof values[key] !== "string" &&
          res.push(values[key] as IExpenditureInHandlerProduction["editingForm"]);
      }
    }

    return [work, ...res];
  },
};
