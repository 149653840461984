import { RootState } from "redux/rootReducer";
import { createSelector } from "reselect";
import { ITasksInitialState } from "./types";

export const stateSelector = (state: RootState): ITasksInitialState => state.tasks;
export const tasksbyBuildingSelector = createSelector(stateSelector, state => state.tasks);
export const isTasksLoadingSelector = createSelector(stateSelector, state => state.isLoading);
export const tasksbyAllBuildingsSelector = createSelector(stateSelector, state => state.tasksByAllBuildings);
export const tasksListsSelector = createSelector(stateSelector, state => state.tasksLists)
export const tasksByListSelector = createSelector(stateSelector, state => state.tasksInLists)
export const tasksByListLoadingSelector = createSelector(stateSelector, state => state.isTasksInListLoading)
export const certainTaskSelector =createSelector(stateSelector, state => state.certainTasks)
export const isCertainTaskLoadingSelector = createSelector(stateSelector, state => state.isCertainTaskLoading)
export const taskModalSelector = createSelector(stateSelector, state => state.modal)
export const tasksFieldsSelector = createSelector(stateSelector, state => state.tasksFields)
export const tasksSectionFilterSelector = createSelector(stateSelector, state => state.sectionFilters)
export const tasksIndicatorsByBuildingSelector = createSelector(stateSelector, state => state.indicatorsByBuilding)
export const tasksIsSectionsLoadingSelector = createSelector(stateSelector, state => state.isSectionsByBuildingLoading)
export const tasksSectionsForBuildingsSelector = createSelector(stateSelector, state => state.sectionsByBuildings)
export const tasksAggregationsSelector = createSelector(stateSelector, state => state.aggregations)
export const tasksFiltersSelector = createSelector(stateSelector, state => state.tasksFilters)