import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";

import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./JournalExecSectionsHeader.module.scss";

interface IProps {
  isWithoutCreateRemark: boolean;
  isWithoutLastColumn: boolean;
}

const JournalExecTicketsHeader: React.FC<IProps> = ({ isWithoutCreateRemark, isWithoutLastColumn }) => {
  return (
    <TableReusableHead
      isSticky
      isWithEar
      className={cn(styles.ticketsHeader, {
        [styles.withoutCreateRemark]: isWithoutCreateRemark,
        [styles.withoutLastColumn]: isWithoutLastColumn,
      })}
    >
      <TableReusableHeaderCell>Наименование работы</TableReusableHeaderCell>
      <TableReusableHeaderCell>Выполнено</TableReusableHeaderCell>
      <TableReusableHeaderCell>Материалы, {LOCALIZATION_CONFIG.currency}</TableReusableHeaderCell>
      <TableReusableHeaderCell>Стоимость, {LOCALIZATION_CONFIG.currency}</TableReusableHeaderCell>
      <TableReusableHeaderCell>Замечания</TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default React.memo(JournalExecTicketsHeader);
