import React from "react";
import styles from "./index.module.scss";
import cn from "classnames"

const StatusFormatter = (status, row, className) => (
  <div className={cn(styles.myOrdersStatus, className)} title={status}>
    <span
      className={cn(styles.status, {
        [styles.in]: row?.flow === "in",
        [styles.out]: row?.flow === "out",
        [styles.end]: row?.flow === "end",
      })}
    />
    <span>{status}</span>
  </div>
);

export default StatusFormatter;