import React, { ReactNode, useCallback } from "react";
import cn from "classnames";

import styles from "./ManufacturingProductEmptyPlaceholder.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ButtonBase from "../../../UI/atoms/ButtonBase";
import { diagramFiltersSelector } from "../../../../redux/modules/common/building/manufacturing/selectors";
import { DiagramFilters } from "../../Manufacturing/constants";
import { updateDiagramFilters } from "../../../../redux/modules/common/building/manufacturing/thunks";

interface IEmptyPlaceholderProps {
  className?: string;
  children?: ReactNode;
  img?: string;
  svgJsx?: ReactNode;
  svgNative?: ReactNode;
  text?: string;
  fullHeight?: boolean;
  isSwitcherVisible?: boolean;
}

const ManufacturingProductEmptyPlaceholder = ({
  className,
  children,
  img,
  svgJsx,
  svgNative,
  text = "Нет сведений",
  fullHeight = true,
  isSwitcherVisible = false
}: IEmptyPlaceholderProps) => {
  const dispatch = useDispatch();
  const diagramFilters = useSelector(diagramFiltersSelector);
  const isOutOfEstimateEnabled = diagramFilters[DiagramFilters.out_of_estimate_enabled];

  const divRef = React.useRef<HTMLDivElement>(null);
  const [offset, setOffset] = React.useState<number | null>(null);

  const onButtonClick = useCallback(() => {
      dispatch(updateDiagramFilters({ name: DiagramFilters.out_of_estimate_enabled, value: !isOutOfEstimateEnabled }));
  }, [dispatch, diagramFilters])

  React.useEffect(() => {
    if (!divRef.current || !fullHeight) return;
    setOffset(divRef.current.getBoundingClientRect().top);
  }, []);


  return (
    <div
      className={cn(styles.container, className)}
      ref={divRef}
      style={fullHeight ? { height: `calc(100vh - ${offset}px - 2.2rem)` } : {}}
    >
      <span className={styles.text}>{text}</span>
      <div className={styles.imgWrapper}>
        {img && <img src={img} className={styles.img} alt="иконка пустого контента" />}
        {svgJsx || null}
        {svgNative || null}
      </div>
      {/*{isSwitcherVisible && (*/}
      {/*  <ButtonBase*/}
      {/*    className={styles.button}*/}
      {/*    primary={true}*/}
      {/*    onClick={onButtonClick}*/}
      {/*  >*/}
      {/*    {isOutOfEstimateEnabled ? 'Показать смету' : 'Показать вне сметы'}*/}
      {/*  </ButtonBase>*/}
      {/*)}*/}
    </div>
  );
};

export default React.memo(ManufacturingProductEmptyPlaceholder);
export type { ManufacturingProductEmptyPlaceholder };
