import ContractorsIcon from "images/icons/constructing/counterparties/ContractorsIcon";
import SuppliersIcon from "images/icons/constructing/counterparties/SuppliersIcon";
import CustomersIcon from "images/icons/constructing/counterparties/CustomersIcon";
import ContactsIcon from "images/icons/constructing/counterparties/ContactsIcon";

import { ROUTES } from "../../../../../../../constants/routes";

export const TABS_IDS = {
  CONTRACTORS: "contractors",
  SUPPLIERS: "suppliers",
  CUSTOMERS: "customers",
  CONTACTS: "contacts",
};

export const TABS = {
  CONTACTS: {
    id: TABS_IDS.CONTACTS,
    title: "Контакты",
    route: ROUTES.CONSTRUCTING_COUNTERPARTIES_CONTACTS_LIST,
    icon: ContactsIcon,
    count: null,
  },
  CONTRACTORS: {
    id: TABS_IDS.CONTRACTORS,
    title: "Подрядчики",
    route: ROUTES.CONSTRUCTING_COUNTERPARTIES_CONTRACTORS_LIST,
    icon: ContractorsIcon,
    count: null,
  },
  SUPPLIERS: {
    id: TABS_IDS.SUPPLIERS,
    title: "Поставщики",
    route: ROUTES.CONSTRUCTING_COUNTERPARTIES_SUPPLIERS_LIST,
    icon: SuppliersIcon,
    count: null,
  },
  CUSTOMERS: {
    id: TABS_IDS.CUSTOMERS,
    title: "Заказчики",
    route: ROUTES.CONSTRUCTING_COUNTERPARTIES_CUSTOMERS_LIST,
    icon: CustomersIcon,
    count: null,
  },
};
