import cn from "classnames";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { diagramFiltersSelector } from "../../../../../../../redux/modules/common/building/manufacturing/selectors";

import IntervalGroupModal from "../../../../../../UI/molecules/Expenditure/components/IntervalGroupModal/IntervalGroupModal";

import ManufacturingModal from "../../../modals/ManufacturingModal/ManufacturingModal";
import DiagramInterval from "../DiagramInterval/DiagramInterval";
import InfoPopup from "../InfoPopup/InfoPopup";

import {
  INTERVAL_MAX_Z_INDEX,
  INTERVAL_TYPES,
  MATERIALS_COLOR_MAP,
  PLANS_MATERIAL,
  MONTH_COLOR_MAP as colorMap,
  getWorkStatus,
} from "../../../../constants";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import { getIntervalDatesLabel } from "../../../../utils";
import { shouldIntervalBeHiddeByFilters } from "../../utils";

import TicketTail from "../../../../../../../images/TicketTail";
import { AlertIcon } from "../../../../../../../images/icons/AlertIcon";

import styles from "../../Month.module.scss";

const Work = ({ branch, isSection = false, offsetLeft, objectId = null }) => {
  const intervalWrapperRef = useRef(null);
  const popupRef = useRef();
  const unitMultiplier = useUnitMultiplier();
  const diagramFilters = useSelector(diagramFiltersSelector);
  const isLinkingEnabled = diagramFilters.linking_editing_enabled;
  const isPlansEditingEnabled = diagramFilters.plans_editing_enabled;

  const isRemarkChipVisible = diagramFilters.remarks_visible;

  const [hover, setHover] = useState(false);

  const isGroup = !!branch.work?.data?.group;

  const { confirmed, doned, paid, topay, received, todo } = branch.work.data.percentage || {};

  const lastColorKey = getWorkStatus(branch.work.data.percentage);
  const borderColor = isGroup ? MATERIALS_COLOR_MAP[PLANS_MATERIAL] : colorMap[lastColorKey + "Border"];
  const leftTailProps = {
    fillColor: isGroup ? MATERIALS_COLOR_MAP[PLANS_MATERIAL] : "none",
    borderColor,
  };
  const rightTailProps = {
    fillColor: isGroup ? MATERIALS_COLOR_MAP[PLANS_MATERIAL] : "none",
    borderColor,
    isRight: true,
  };
  if (doned > 0) {
    leftTailProps.linesColor = colorMap["received"];
  }
  if (doned >= 1) {
    rightTailProps.linesColor = colorMap["received"];
  }
  if (received > 0) {
    leftTailProps.fillColor = colorMap["received"];
  }
  if (received >= 1) {
    rightTailProps.fillColor = colorMap["received"];
  }
  if (confirmed > 0) {
    leftTailProps.wideLinesColor = colorMap["confirmed"];
  }
  if (confirmed >= 1) {
    rightTailProps.wideLinesColor = colorMap["confirmed"];
  }
  if (todo > 0) {
    leftTailProps.linesColor = "none";
    leftTailProps.wideLinesColor = "none";
    leftTailProps.fillColor = colorMap["todo"];
  }
  if (todo >= 1) {
    rightTailProps.linesColor = "none";
    rightTailProps.wideLinesColor = "none";
    rightTailProps.fillColor = colorMap["todo"];
  }
  if (topay > 0) {
    leftTailProps.linesColor = "none";
    leftTailProps.wideLinesColor = "none";
    leftTailProps.fillColor = colorMap["topay"];
  }
  if (topay >= 1) {
    rightTailProps.linesColor = "none";
    rightTailProps.wideLinesColor = "none";
    rightTailProps.fillColor = colorMap["topay"];
  }

  const [isOpenWorkCard, setIsOpenWorkCard] = useState(false);

  const handleCloseModal = () => {
    setIsOpenWorkCard(false);
    setHover(false);
  };

  const workWrapperStyles = {
    zIndex: hover || isOpenWorkCard ? INTERVAL_MAX_Z_INDEX : Math.min(branch.work.days + 1, INTERVAL_MAX_Z_INDEX),
  };

  const modalType =
    +branch.parentId === +objectId || !branch.parentId ? "building" : isSection ? "section" : "expenditure";

  const dateStart = moment(branch.work.interval_start_date || branch.work.start)?.format("YYYY-MM-DD");
  const dateEnd = moment(branch.work.interval_end_date || branch.work.end)?.format("YYYY-MM-DD");

  const intervalDates = getIntervalDatesLabel(branch.work.start, branch.work.end);

  const updateWorkStatus = (newStatus, intervalId) => {
    if (intervalId !== branch.work.data.id) return;
    branch.work.data.status = newStatus;
  };

  const remarksCount =
    Number(branch.work.data.totalRemarksCount) ||
    (branch.work.data.ticket_remarks_in_work?.length || 0) + (branch.work.data.remarks_in_work?.length || 0);

  if (isLinkingEnabled || isPlansEditingEnabled) return null;

  const sharedStatus = branch.work?.data?.status;

  if (shouldIntervalBeHiddeByFilters(sharedStatus, diagramFilters)) return null;

  return (
    <DiagramInterval
      day={branch.day}
      daysLength={branch.work.days}
      unitMultiplier={unitMultiplier}
      wrapperStyles={workWrapperStyles}
      offsetLeft={offsetLeft}
      hover={hover}
      setHover={setHover}
      intervalWrapperRef={intervalWrapperRef}
      intervalSharedStatus={sharedStatus}
      isCardOpen={isOpenWorkCard}
      type={INTERVAL_TYPES.work}
      popupRef={popupRef}
    >
      <TicketTail {...leftTailProps} />
      {isRemarkChipVisible && !!remarksCount && (
        <div className={styles.remarksChip}>
          <AlertIcon fill={"white"} width={16} /> {remarksCount}
        </div>
      )}
      <div
        className={styles.work}
        style={{
          borderTop: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${borderColor}`,
        }}
      >
        <div className={cn(styles.planBg, { [styles.groupWork]: isGroup })} style={{ width: `100%` }} />
        {+branch.work.data.percentage?.doned > 0 && (
          <div
            className={styles.doneBg}
            style={{ width: `${Math.min(branch.work.data.percentage?.doned * 100, 100)}%` }}
          />
        )}
        {+branch.work.data.percentage?.received > 0 && (
          <div
            className={styles.receivedBg}
            style={{
              width: `${Math.min(branch.work.data.percentage?.received * 100, 100)}%`,
            }}
          />
        )}
        {+branch.work.data.percentage?.todo > 0 && (
          <div
            className={styles.todoBg}
            style={{ width: `${Math.min(branch.work.data.percentage?.todo * 100, 100)}%` }}
          />
        )}
        {+branch.work.data.percentage?.confirmed > 0 && (
          <div
            className={styles.confirmedBg}
            style={{
              width: `${Math.min(branch.work.data.percentage?.confirmed * 100, 100)}%`,
            }}
          />
        )}
        {+branch.work.data.percentage?.topay > 0 && (
          <div
            className={styles.topayBg}
            style={{ width: `${Math.min(branch.work.data.percentage?.topay * 100, 100)}%` }}
          />
        )}
        {+branch.work.data.percentage?.paid > 0 && (
          <div
            className={styles.paidBg}
            style={{ width: `${Math.min(branch.work.data.percentage?.paid * 100, 100)}%` }}
          />
        )}
      </div>
      <TicketTail {...rightTailProps} />
      {(hover || isOpenWorkCard) && (
        <InfoPopup
          ref={popupRef}
          setOpenPopup={setHover}
          intervalDates={intervalDates}
          data={branch.work.data}
          type={INTERVAL_TYPES.work}
          setOpenModal={setIsOpenWorkCard}
          remarksCount={remarksCount}
          isOpen
        >
          <div className={styles.popupAnchor} />
        </InfoPopup>
      )}
      {!isGroup && isOpenWorkCard && (
        <ManufacturingModal
          isOpen
          onClose={handleCloseModal}
          type={INTERVAL_TYPES.work}
          date_start={dateStart}
          date_end={dateEnd}
          objectId={objectId}
          intervaldata={branch.work.data}
          expenditureId={branch.work.data.expenditure_id || branch.parentId}
          modalType={modalType}
          updateWorkStatus={updateWorkStatus}
        />
      )}
      {isGroup && isOpenWorkCard && (
        <IntervalGroupModal
          isOpen
          onClose={handleCloseModal}
          activeModule={"facts"}
          date_end={dateEnd}
          date_start={dateStart}
          expenditureId={branch.work.data.id}
          expenditure={{}}
          sectionName={branch.work.data.group.section_name}
          objectId={objectId}
          sectionId={branch.parentId}
        />
      )}
    </DiagramInterval>
  );
};

export default React.memo(Work);
