import React from "react";
import { Spinner } from "../../../../../UI/Spinner/Spinner";
import SubTableHeader from "./SubTableHeader/SubTableHeader";
import SubTableRow from "./SubTableRow/SubTableRow";
import { useSubTablePacking } from "./useSubTablePacking";

function SubTablePacking({ packinglist, measure = 1000 }) {
  const { items, isLoading } = useSubTablePacking(packinglist);

  if (!packinglist) return null;

  return (
    <>
      <SubTableHeader />
      {isLoading && <Spinner />}
      {items?.map((el) => (
        <SubTableRow
          key={el.id}
          name={el.name}
          measureMoney={measure}
          measureProduct={el.measure}
          count={el.count_get}
          amount={el.amount}
        />
      ))}
    </>
  );
}

export default React.memo(SubTablePacking);
