import cn from "classnames";
import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

import styles from "./EventItem.module.scss";

export interface IEventEntryProps {
  label: string;
  value?: string | React.ReactNode;
  bubbleLabel?: string | React.ReactNode;
  bubbleLink?: string;
  withoutArrow?: boolean;
  withBubblePlaceholder?: boolean;
  className?: string;
  valueClassName?: string;
  bubbleClassName?: string;
  bubbleOnClick?: MouseEventHandler;
}

const EventEntry: React.FC<IEventEntryProps> = ({
  label,
  value,
  bubbleLabel,
  bubbleLink,
  withoutArrow,
  withBubblePlaceholder,
  className,
  valueClassName,
  bubbleClassName,
  bubbleOnClick,
}) => {
  const isOnlyLabel = !value && !bubbleLabel && !withBubblePlaceholder;
  const isWithoutBubble = !bubbleLabel && !withBubblePlaceholder;
  return (
    <div className={cn(styles.eventEntry, className)}>
      <span className={cn(styles.eventEntryLabel, { [styles.onlyLabel]: isOnlyLabel })}>{label}</span>
      {(!!value || !!bubbleLabel) && (
        <div className={cn(styles.eventEntryValue, { [styles.withoutBubble]: isWithoutBubble }, valueClassName)}>
          <span title={typeof value === "string" ? value : undefined}>{value}</span>
          {!!bubbleLabel && (
            <>
              <div className={styles.eventEntryDivider}>&nbsp;</div>
              {!!bubbleLink ? (
                <Link
                  to={bubbleLink}
                  className={cn(styles.eventEntryBubble, { [styles.withoutArrow]: withoutArrow }, bubbleClassName)}
                  title={typeof bubbleLabel === "string" ? bubbleLabel : undefined}
                >
                  {bubbleLabel}
                </Link>
              ) : (
                <span
                  className={cn(
                    styles.eventEntryBubble,
                    { [styles.withoutArrow]: withoutArrow, [styles.isButton]: !!bubbleOnClick },
                    bubbleClassName
                  )}
                  title={typeof bubbleLabel === "string" ? bubbleLabel : undefined}
                  onClick={bubbleOnClick}
                >
                  {bubbleLabel}
                </span>
              )}
            </>
          )}{" "}
          {!bubbleLabel && !!withBubblePlaceholder && (
            <>
              <div className={styles.eventEntryDivider}>&nbsp;</div>
              <div className={bubbleClassName} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(EventEntry);
