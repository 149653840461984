import cn from "classnames";
import React from "react";

import { ReactComponent as CheckIcon } from "images/icons/check-bold-white-icon.svg";
import { ReactComponent as RemoveIcon } from "images/icons/remove-gray-icon.svg";

import styles from "./ActionsButtons.module.scss";

interface IProps {
  onConfirm: () => void;
  onRemove: () => void;
  className?: string;
  checkClassName?: string;
  removeClassName?: string;
  canCheck?: boolean;
  canRemove?: boolean;
}

const ActionsButtons: React.FC<IProps> = ({
  onConfirm,
  onRemove,
  className,
  checkClassName,
  removeClassName,
  canRemove = true,
  canCheck = true,
}) => {
  return (
    <div className={cn(styles.actions, className)}>
      {canCheck && (
        <button className={cn(styles.checkButton, checkClassName, { [styles.once]: !canRemove })} onClick={onConfirm}>
          <CheckIcon />
        </button>
      )}
      {canRemove && (
        <button className={cn(styles.removeButton, removeClassName, { [styles.once]: !canCheck })} onClick={onRemove}>
          <RemoveIcon />
        </button>
      )}
    </div>
  );
};

export default React.memo(ActionsButtons);