import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  journalAcceptanceHistoryLoadingSelector,
  journalAcceptanceHistorySelector,
} from "redux/modules/common/building/journal/acceptance/selectors";
import { getAcceptanceEditingHistory } from "redux/modules/common/building/journal/acceptance/thunks";

import { Spinner } from "components/UI/Spinner/Spinner";
import Expandable from "components/UI/atoms/Expandable/Expandable";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import EditingHistoryItem from "./ui/EditingHistoryItem/EditingHistoryItem";

import { getHistoryFactEditingDate, getHistoryPlanEditingDate } from "./utils";
import getShortFullName from "utils/formatters/getShortFullName";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./EditingHistory.module.scss";

interface IProps {
  isOpen: boolean;
  ticketId: number;
  isGroup: boolean;
  objectId: string;
}

const PlanEditingHistory: React.FC<IProps> = ({ objectId, ticketId, isGroup, isOpen }) => {
  const dispatch = useDispatch();
  const historyItems = useSelector(journalAcceptanceHistorySelector)[
    generateStorageKey({ expenditureId: ticketId, type: "plan" })
  ];
  const isLoading = useSelector(journalAcceptanceHistoryLoadingSelector)[
    generateStorageKey({ expenditureId: ticketId, type: "plan" })
  ];

  useEffect(() => {
    if (!isOpen || !ticketId) return;
    dispatch(getAcceptanceEditingHistory(objectId, ticketId, isGroup, "plan"));
  }, [objectId, ticketId, isGroup, isOpen]);

  if (!isLoading && !historyItems?.length) {
    return null;
  }

  return (
    <Expandable title={`История изменений: ${historyItems?.length ?? 0}`}>
      {historyItems?.map((el) => (
        <EditingHistoryItem
          key={el.date}
          date={getHistoryPlanEditingDate(el)}
          userName={getShortFullName(el.user?.full_name)}
          diff={el.diff}
          type={el.change_type}
          reason={el.reason!}
        />
      ))}
      {isLoading && <Spinner isSmall isStatic />}
    </Expandable>
  );
};

export default PlanEditingHistory;
