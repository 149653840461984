import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { editGroupPlanInterval, editPlanInterval } from "redux/modules/common/building/processApi";
import { projectActions } from "redux/modules/common/building/project/actions";

import useClosureDates from "../../../../../../UI/molecules/ClosureDates/useClosureDates";

import { sendMultiplePlansStateActions } from "widgets/SendMultiplePlansForApprove/model/sendMultiplePlansSlice";

import { IMimsInAddingInPlan } from "../../../../../../../types/interfaces/Mims";
import { IMaterialInPlanInterval, ISerializedMaterialInPlan } from "types/interfaces/Materials";

import {
  mapEditingGroupPlanMaterials,
  mapEditingGroupPlanMims,
  mapEditingPlanMaterials,
  mapEditingPlanMims,
  serializeGroupPlanWorkersInEditPlan,
} from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

interface IidAndCount {
  id: number;
  count: number;
}

interface Iprops {
  planDateStart: string;
  planDateEnd: string;
  planCount: number;
  addedUsers: IidAndCount[];
  addedMaterials: ISerializedMaterialInPlan[];
  addedMims: any[];
  expenditureId: number;
  buildingId: number;
  intervalId: number;
  onClose: () => void;
  previousMaterials: IMaterialInPlanInterval[];
  previousMims: any[];
  onEditCallback?: () => void;
  isExpendituresGroup?: boolean;
  getUpdatedList?: (intervalId: string, needToFetchRemarks: false) => void;
  sectionId: number;
  paymentDate: string;
  closeWholeModal?: () => void;
}

export const usePlanEdit = ({
  planDateStart,
  planDateEnd,
  planCount,
  addedUsers,
  addedMaterials,
  addedMims,
  expenditureId,
  intervalId,
  buildingId,
  onClose,
  previousMaterials,
  previousMims,
  onEditCallback,
  isExpendituresGroup,
  getUpdatedList,
  sectionId,
  paymentDate,
  closeWholeModal,
}: Iprops) => {
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = React.useState(planDateStart);
  const [dateEnd, setDateEnd] = React.useState(planDateEnd);
  const [count, setCount] = React.useState(planCount);

  const [planPaymentDate, setPlanPaymentDate] = useState(paymentDate);
  const [selectedClosureDateId, setSelectedClosureDateId] = useState<number | null>(null);
  const { closureDatesOptions } = useClosureDates({ isOpen: true, objectId: buildingId, sectionId });

  useEffect(() => {
    setSelectedClosureDateId(closureDatesOptions?.find((date) => date.payment_date === paymentDate)?.id || null);
  }, [paymentDate, closureDatesOptions]);

  const submitHandler = () => {
    if (isExpendituresGroup) {
      const data = {
        start_at: moment(dateStart).format("YYYY-MM-DD"),
        end_at: moment(dateEnd).format("YYYY-MM-DD"),
        count: count || 0,
        workers: serializeGroupPlanWorkersInEditPlan(addedUsers),
        materials: mapEditingGroupPlanMaterials(addedMaterials, previousMaterials),
        services: mapEditingGroupPlanMims(addedMims),
        payment_date:
          closureDatesOptions.find((date) => date.id === selectedClosureDateId)?.payment_date ||
          moment(planPaymentDate).format("YYYY-MM-DD"),
        close_date_id: selectedClosureDateId || undefined,
      };

      editGroupPlanInterval({
        buildingId,
        intervalId,
        data,
      })
        .then(() => {
          getUpdatedList?.(intervalId, false);
          onClose?.();
          closeWholeModal?.();
          onEditCallback?.();
          message.success("Сохранено");
          dispatch(projectActions.invalidateKey());
        })
        .catch(errorCatcher);
    } else {
      const data = {
        start_at: moment(dateStart).format("YYYY-MM-DD"),
        end_at: moment(dateEnd).format("YYYY-MM-DD"),
        count: count || 0,
        workers: addedUsers,
        planned_materials: mapEditingPlanMaterials(addedMaterials, previousMaterials),
        count_planned_services: mapEditingPlanMims(addedMims, previousMims),
        count_workers: addedUsers.map((item) => ({ worker_id: item.id, count: item.count })),
        payment_date:
          closureDatesOptions.find((date) => date.id === selectedClosureDateId)?.payment_date ||
          moment(planPaymentDate).format("YYYY-MM-DD"),
        close_date_id: selectedClosureDateId || undefined,
      };

      editPlanInterval({
        buildingId,
        intervalId,
        expenditureId,
        data,
      })
        .then(() => {
          onEditCallback?.();
          onClose?.();
          dispatch(sendMultiplePlansStateActions.invalidateKey());
        })
        .catch(errorCatcher);
    }
  };

  return {
    submitHandler,
    count,
    setCount,
    dateEnd,
    setDateEnd,
    dateStart,
    setDateStart,
    closureDatesOptions,
    selectedClosureDateId,
    setSelectedClosureDateId,
    planPaymentDate,
    setPlanPaymentDate,
  };
};
