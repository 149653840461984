import React from "react";
import { useDispatch } from "react-redux";

import { userSelector } from "redux/modules/common/auth";
import { detailDataSelector, getDetail } from "redux/modules/common/building/object/nowObject";
import { useTypedSelector } from "redux/typedUseSelector";

export enum Responsibilities {
  planWorkConfirm = "planWorkConfirm",
  planSectionConfirm = "planSectionConfirm",
  responsibleEmployee = "responsibleEmployee",
}

export type ResponsibilitiesType = keyof typeof Responsibilities;

export interface IUseCheckIsCurrentUserResponsibleProps {
  projectId: number | string;
  responsibleFor: ResponsibilitiesType;
  isActive: boolean;
}

export const useCheckIsCurrentUserResponsible = ({
  projectId,
  responsibleFor,
  isActive,
}: IUseCheckIsCurrentUserResponsibleProps) => {
  const dispatch = useDispatch();
  const userInfo = useTypedSelector(userSelector);
  const buildingInfo = useTypedSelector(detailDataSelector);

  React.useEffect(() => {
    if (!isActive || buildingInfo?.id === Number(projectId) || !projectId) return;
    dispatch(getDetail(projectId));
  }, [isActive, projectId, buildingInfo]);

  if (!buildingInfo || !userInfo) return false;

  if (responsibleFor === Responsibilities.planWorkConfirm)
    return buildingInfo.planworkchainapprovalemployees_set?.findIndex((x) => x.user === userInfo.id) !== -1;

  if (responsibleFor === Responsibilities.planSectionConfirm)
    return buildingInfo.plansectionchainapprovalemployees_set?.findIndex((x) => x.user === userInfo.id) !== -1;

  if (responsibleFor === Responsibilities.responsibleEmployee)
    return buildingInfo.responsible_employee?.id === userInfo.id;

  return false;
};
