import React, { useEffect } from "react";

import { IIntersectingKs2Expenditure } from "redux/modules/common/building/journal/acting/types";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Modal from "components/UI/atoms/Modal";

import JournalExcludeItem from "./JournalExcludeItem/JournalExcludeItem";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./JournalExcludeKsModal.module.scss";

interface IProps {
  expenditures: IIntersectingKs2Expenditure[];
  isOpen: boolean;
  onClose: () => void;
  onExcludeExpenditure: (expId: number, parentId: number) => void;
  onSubmit: () => void;
}

const JournalExcludeKsModal: React.FC<IProps> = ({ expenditures, isOpen, onClose, onExcludeExpenditure, onSubmit }) => {
  const [includedExpenditures, setIncludedExpenditures] = React.useState<number[]>([]);

  useEffect(() => {
    if (isOpen) return;
    setIncludedExpenditures([]);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Подтвердить состав нового акта" className={styles.modal}>
      <div className={styles.subtitle}>
        Перечисленные расценки уже были добавлены в состав КС-2. Вы уверены, что хотите удалить их из существующего
        акта?
      </div>
      <div className={styles.list}>
        {expenditures
          ?.sort((a, b) => a.expenditure_id - b.expenditure_id)
          ?.map((el) => (
            <JournalExcludeItem
              isApproved={includedExpenditures.includes(el.expenditure_id)}
              key={el.expenditure_id}
              name={el.name}
              subName={sliceTextByConstraint(el.act_ks2_title, 20)}
              onDelete={() => onExcludeExpenditure(el.expenditure_id, el.parent_id)}
              onApprove={() => setIncludedExpenditures((prev) => [...prev, el.expenditure_id])}
            />
          ))}
      </div>
      <div className={styles.bottom}>
        <ButtonBase primary disabled={expenditures?.length > includedExpenditures.length} onClick={onSubmit}>
          Подтвердить
        </ButtonBase>
      </div>
    </Modal>
  );
};

export default React.memo(JournalExcludeKsModal);
