import React from "react";

import Select from "../Select";

export const MEASURES = [
  { name: "шт", id: "шт" },
  { name: "кг", id: "кг" },
  { name: "м", id: "м" },
  { name: "м2", id: "м2" },
  { name: "м3", id: "м3" },
  { name: "л", id: "л" },
  { name: "мп", id: "мп" },
  { name: "км", id: "км" },
  { name: "уп", id: "уп" },
  { name: "т", id: "т" },
  { name: "компл", id: "компл" },
];

export const MEASURES_SERVICES = [
  { name: "чел.час", id: "чел.час" },
  { name: "маш.час", id: "маш.час" },
];

export const ALL_MEASURES = [
  ...MEASURES,
  ...MEASURES_SERVICES
]

const MeasureSelect = (props) => (
  <Select
    placeholder={"-"}
    options={props.useAllMeasures ? ALL_MEASURES : props.isServices ? MEASURES_SERVICES : MEASURES}
    {...props}
  />
);

export default React.memo(MeasureSelect);
