import cn from "classnames";
import React, { useRef, useState } from "react";

import MenuItemModule from "./components/MenuItemModule/MenuItemModule";
import Icon from "components/UI/Icon/Icon";

import { VIEW_CONSTRUCTING_SECTION } from "../../../../../../constants/permissions/constructingPermissions";
import { VIEW_MANUFACTURING_SECTION } from "../../../../../../constants/permissions/manufacturingPermissions";
import { VIEW_PURCHASES_SECTION } from "../../../../../../constants/permissions/purchasesPermissions";

import useOnClickOutside from "hooks/useOnClickOutside";
import usePermission from "hooks/usePermission";

import taskIcon from "images/icons/moduleMenuIcon/checkIcon.svg";
import checkIcon from "images/icons/moduleMenuIcon/checkIcons.svg";
import fiveSquareIcon from "images/icons/moduleMenuIcon/fiveSquareIcon.svg";
import purchases from "images/icons/navigation/purchases.svg";
import oslaLogo from "images/icons/oslaLogo.svg";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const [isOpenModuleMenu, setIsOpenModuleMenu] = useState(false);
  const { t } = useTranslation();
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setIsOpenModuleMenu(false);
  });

  const userHaveViewPurchasesPermission = usePermission(VIEW_PURCHASES_SECTION);
  const userHaveViewConstructingPermission = usePermission(VIEW_CONSTRUCTING_SECTION);
  const userHaveViewManufacturingPermission = usePermission(VIEW_MANUFACTURING_SECTION);

  return (
    <div ref={ref} className={styles.container}>
      <div
        className={cn(styles.menuIcon, { [styles.active]: isOpenModuleMenu })}
        onClick={() => setIsOpenModuleMenu(!isOpenModuleMenu)}
        data-testid="modules_menu"
      >
        <a
          href={window.location?.href}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Icon icon={oslaLogo} />
        </a>
      </div>
      <div className={cn(styles.moduleMenuPopup, { [styles.active]: isOpenModuleMenu })}>
        {userHaveViewConstructingPermission && (
          <MenuItemModule
            title={t("Управление")}
            name="constructing"
            link="/constructing"
            icon={checkIcon}
          />
        )}
        {userHaveViewManufacturingPermission && (
          <MenuItemModule
            title={t("Производство")}
            name="production"
            link="/objects"
            icon={fiveSquareIcon}
          />
        )}
        {userHaveViewPurchasesPermission && (
          <MenuItemModule
            title={t("Закупки")}
            name="purchase"
            link="/purchases"
            icon={purchases}
          />
        )}
        <MenuItemModule
          title={t("Задачи")}
          name="tasks"
          link="/tasks"
          icon={taskIcon}
        />
      </div>
    </div>
  );
};

export default React.memo(Menu);
