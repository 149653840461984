import cn from "classnames";
import moment from "moment";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";

import { createCertificate } from "redux/modules/common/passportsAndCertificates/thunks";
import { ICreateCertificate } from "redux/modules/common/passportsAndCertificates/types";

import FileRow from "components/UI/atoms/FileRow/FileRow";
import FileUploader from "components/UI/atoms/FileUploader/FileUploader";
import InputBase from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select";
import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";
import ConfirmBlock from "components/UI/organism/AddingListInModal/components/ConfirmBlock/ConfirmBlock";

import { passportsTypesOptions } from "../constants";

import { composeFieldValidators, required } from "utils/formHelpers/validations";

import styles from "./PassportForm.module.scss";

interface IProps {
  onChangeType: (type: number | string) => void;
  type: string;
  productId: number;
  isPending: boolean;
}

const CertificateForm: React.FC<IProps> = ({ onChangeType, type, productId, isPending }) => {
  const closeForm = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onChangeType("");
    },
    [onChangeType]
  );
  const [dateStart, setDateStart] = React.useState(moment().format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = React.useState(moment().format("YYYY-MM-DD"));
  const dispatch = useDispatch();

  const submitForm = React.useCallback(
    (values: ICreateCertificate) => {
      dispatch(createCertificate({ ...values, start_at: dateStart, end_at: dateEnd }, productId));
    },
    [productId, dateStart, dateEnd]
  );

  return (
    <>
      <Form
        onSubmit={submitForm}
        render={({ values, handleSubmit, form }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <ConfirmBlock onAccept={handleSubmit} onDecline={closeForm} isWithoutCount disabled={isPending} />
            <div className={cn(styles.row, styles.row6_4)}>
              <div>
                <Select options={passportsTypesOptions} onChange={onChangeType} label="Тип документа" value={type} />
              </div>
              {/* @ts-ignore */}
              <Field name="number" component={InputBase} label="Номер" validate={composeFieldValidators(required())} />
            </div>
            <div className={cn(styles.row, styles.row5_5)}>
              <CalendarRange
                label="Срок действия"
                defaultDateEnd={dateEnd}
                defaultDateStart={dateStart}
                setDefaultDateEnd={setDateEnd}
                setDefaultDateStart={setDateStart}
                isTooLeft
              />
              <Field
                name="file"
                validate={composeFieldValidators(required())}
                render={({ input, meta }) => (
                  <div
                    className={styles.inputWrapper}
                    onClick={() => {
                      input.onFocus();
                      input.onBlur();
                    }}
                  >
                    <FileUploader
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        input.onBlur();
                        const targetFiles = Array.from(e.target.files || []);
                        input.onChange(targetFiles[0]);
                      }}
                      children={undefined}
                      className={styles.files}
                      text="Прикрепить "
                    />
                    {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                  </div>
                )}
              />
            </div>
            <Field
              name="file"
              render={({ input }) =>
                input.value ? (
                  <FileRow
                    file={input.value}
                    canRemove
                    onDirectlyRemove={() => {
                      input.onChange(undefined);
                    }}
                    className={styles.fileRow}
                  />
                ) : null
              }
            />
          </form>
        )}
      />
    </>
  );
};

export default React.memo(CertificateForm);
