import React from "react";

import useBudgetDoubleInput from "../../../../../pages/Handler/hooks/useBudgetDoubleInput";

import DoubleInput, { Measure } from "../../../../atoms/DoubleInput/DoubleInput";

import { beautifyNumberValue } from "../../../../../../utils/formatters/beautifyNumberValue";
import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";

import blackCheckIcon from "../../../../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../../../../images/icons/blueCheckIcon";

import styles from "../../Expenditure.module.scss";

const BudgetColumns = ({ discountCost, discountPercent, changeDiscountCost, amountSource, quantity }) => {
  const {
    changedDiscountCost,
    changedDiscountPercent,
    onChangeDiscountPercent,
    onChangeDiscountCost
  } = useBudgetDoubleInput({
    amountSource,
    discountPercent,
    discountCost,
    changeDiscountCost
  });

  return (
    <>
      <div className={styles.priceCol}>
        {transformDigitToFinancial(amountSource)}
        <br/>
        ({transformDigitToFinancial(amountSource/quantity)} за ед.)
      </div>
      <div className={styles.discount}>
        <DoubleInput
          firstValue={changedDiscountPercent}
          secondValue={changedDiscountCost}
          firstInputSize={1}
          secondInputSize={2}
          onChangeFirstValue={onChangeDiscountPercent}
          onChangeSecondValue={onChangeDiscountCost}
          firstValueMeasure={Measure.percent}
          secondValueMeasure={Measure.currency}
        />
        {+beautifyNumberValue(changedDiscountCost) !== 0 ? (
          {
            ...blueCheckIcon,
            props: { ...blueCheckIcon.props, width: 18, height: 18 },
          }
        ) : (
          <span className={styles.withoutDiscountIcon} title="Скидка отсутствует">
            {{
              ...blackCheckIcon,
              props: { ...blackCheckIcon.props, width: 18, height: 18 },
            }}
          </span>
        )}
      </div>
      <div className={styles.withDiscountAmount}>
        {transformDigitToFinancial(amountSource - beautifyNumberValue(changedDiscountCost))}
        <br/>
        ({transformDigitToFinancial((amountSource - beautifyNumberValue(changedDiscountCost))/quantity)} за ед.)
      </div>
    </>
  );
};

export default React.memo(BudgetColumns);
