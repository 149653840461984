import cn from "classnames";
import React from "react";

import { transformDigitToFinancial } from "../../../../../../../../../utils/formatters/transformDigitToFinancial";
import { getExpenditureAmount } from "../../../../../../utils/getExpenditureAmount";
import { getSectionAmount } from "../../../../../../utils/getSectionAmount";

import DragHandlerIcon from "../../../../../../../../../images/icons/DragHandlerIcon";

import styles from "./EstimateItem.module.scss";

const EstimateItem = ({ estimateItem, activeEstimateStateId }) => {
  const isExpenditure = !!estimateItem.expenditure_type;
  const amount = transformDigitToFinancial(
    isExpenditure ? getExpenditureAmount(estimateItem) : getSectionAmount(estimateItem, activeEstimateStateId),
    { withFloat: false, withCurrencySign: true }
  );

  return (
    <div className={cn(styles.itemBlock, { [styles.expenditure]: isExpenditure })}>
      <div className={styles.itemName}>
        {isExpenditure && <DragHandlerIcon className={styles.icon} />}
        {estimateItem.name}
      </div>
      <div className={styles.amount}>{amount}</div>
    </div>
  );
};

export default React.memo(EstimateItem);
