import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import Draft from "components/pages/Handler/components/Body/components/Sections/components/Draft/Draft";
import styles from "components/pages/Handler/components/CreateSection/CreateSection.module.scss";
import CreateSectionInput from "components/pages/Handler/components/CreateSection/components/CreateSectionInput/CreateSectionInput";

import { ReactComponent as PlusFillCircleIcon } from "../../../../../shared/assets/icons/Icon-Plus.svg";

interface ICreateSectionProps {
  isSubsections: boolean;
  sectionsAreEmpty: boolean;
  onSubmitCreateSection: (name: string) => void;
  onDirectlyClick?: () => void;
  buttonLabel?: string;
  isProduction?: boolean;
  isDraft?: boolean;
}

const CreateSection = ({
  isSubsections,
  sectionsAreEmpty,
  onSubmitCreateSection,
  onDirectlyClick,
  isProduction,
  isDraft = true,
}: ICreateSectionProps) => {
  const [isSectionInputOpen, setIsSectionInputOpen] = useState(false);
  const openSectionInput = useCallback(() => setIsSectionInputOpen(true), []);
  const closeSectionInput = useCallback(() => setIsSectionInputOpen(false), []);

  const isShowDraftPlug = sectionsAreEmpty && !isSubsections && !isProduction;

  const addButtonLabel = isProduction
    ? `Добавить ${isSubsections ? "подраздел" : "раздел"}`
    : `Добавить ${isSubsections ? "раздел" : "ЛСР"}`;
  const addInputPlaceholder = isProduction
    ? `Введите название ${isSubsections ? "подраздела" : "раздела"}`
    : `Введите название ${isSubsections ? "раздела" : "локального сметного расчета"}`;

  const handlePlusButtonClick = useCallback(() => {
    if (onDirectlyClick) {
      onDirectlyClick();
      return;
    }
    openSectionInput();
  }, [onDirectlyClick, openSectionInput]);

  const buttonPositionStyle = useMemo(() => {
    return { left: isDraft ? "22rem" : "32rem" };
  }, [isDraft]);

  return (
    <>
      <button className={styles.addSectionButton} style={buttonPositionStyle} onClick={handlePlusButtonClick}>
        <span className={styles.text}>{addButtonLabel}</span>
        <PlusFillCircleIcon />
      </button>
      {isSectionInputOpen && (
        <div className={cn({ [styles.withBorderBottom]: !sectionsAreEmpty })}>
          <CreateSectionInput
            onSubmit={onSubmitCreateSection}
            close={closeSectionInput}
            placeholder={addInputPlaceholder}
          />
        </div>
      )}
      {isShowDraftPlug && <Draft onCreateSection={openSectionInput} />}
    </>
  );
};

export default React.memo(CreateSection);
