import { IDetailedProjectForecast } from "redux/modules/common/constructing/finance/forecast/types";

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IExtraForecastData, IExtraForecastTreeItem } from "../../../Forecast/types";
import { IExtraForecastDetail } from "./types";
import { IIdAndName } from "types/idAndName";

import { IPreparedExtraForecastData, extraForecastUtils } from "./utils";

export type IExtraExpensesData = IExtraForecastTreeItem[];

interface ExtraForecastState {
  invalidateKey: number;
  isPending: Record<string, boolean>;
  extraExpenses: Record<string, IPreparedExtraForecastData>;
  extraSectionsLite: Record<string, IIdAndName[]>;
  extraSubSectionsLite: Record<string, IIdAndName[]>;
  detailedExpenses: Record<string, IExtraForecastDetail>;
}

const initialState: ExtraForecastState = {
  invalidateKey: 0,
  isPending: {},
  extraExpenses: {},
  extraSectionsLite: {},
  extraSubSectionsLite: {},
  detailedExpenses: {},
};

const extraForecastSlice = createSlice({
  name: "extraForecast",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setIsPending: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { key, status } = action.payload;
      state.isPending[key] = status;
    },
    setExtraExpense: (state, action: PayloadAction<{ data: IExtraForecastData; key: string }>) => {
      const { key, data } = action.payload;
      state.extraExpenses[key] = extraForecastUtils.initData(data);
      //@ts-ignore
      state.extraSectionsLite[key] = extraForecastUtils.initExtraSections(data);
      state.extraSubSectionsLite[key] = extraForecastUtils.initExtraSubSections(data);
    },
    toggleOpen: (state, action: PayloadAction<{ key: string; id: string }>) => {
      const { key, id } = action.payload;
      const root = state.extraExpenses[key]?.sections?.[0];
      if (!root) return;
      if (id === root.localId) {
        root.isOpen = !root.isOpen;
        return;
      }
      const parent = root?.subsections?.find((el) => el.localId === id);
      if (parent) {
        parent.isOpen = !parent.isOpen;
      } else {
        const findingParent = root.subsections?.find((el, i) => el.subsections.map((s) => s.localId).includes(id));
        if (findingParent) {
          const child = findingParent.subsections.find((el) => el.localId === id);
          if (child) {
            child.isOpen = !child.isOpen;
          }
        }
      }
    },
    setDetailed: (state, action: PayloadAction<{ key: string; data: IExtraForecastDetail }>) => {
      const { key, data } = action.payload;
      state.detailedExpenses[key] = data;
    },
  },
});

export const extraForecastActions = extraForecastSlice.actions;

export default extraForecastSlice.reducer;
