import { IExtraForecastData } from "../../../Forecast/types";
import { IIdAndName } from "types/idAndName";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export type IPreparedExtraForecastData = ReturnType<typeof extraForecastUtils.initData>;

export const extraForecastUtils = {
  initData: (data: IExtraForecastData) => {
    if (!data.sections?.length) {
      return data;
    }
    return {
      ...data,
      sections: [
        {
          name: "Прочие расходы",
          isOpen: true,
          isExtra: true,
          isRoot: true,
          localId: generateStorageKey({ type: "parent", sectionId: 0 }),
          subsections: data.sections?.map((el, i) => ({
            ...el,
            isOpen: true,
            isExtra: true,
            localId: generateStorageKey({ type: "section", sectionId: 0, buildingId: String(i) }),
            subsections: el.subsections?.map((e, subIndex) => ({
              ...e,
              isOpen: true,
              isExtra: true,
              localId: generateStorageKey({
                type: "subSection",
                sectionId: subIndex,
                buildingId: String(i),
                stockId: 0,
              }),
              costs: e.costs.map((cost, costIndex) => ({
                ...cost,
                number: " ",
                localId: cost.id,
              })),
            })),
          })),
        },
      ],
    };
  },
  initExtraSections: (data: IExtraForecastData) => {
    return data.sections.map((el, i) => ({
      name: el.name,
      id: el.name,
    }));
  },
  initExtraSubSections: (data: IExtraForecastData) => {
    const res: IIdAndName[] = [];
    data.sections.forEach((el, i) => {
      el.subsections.forEach((sub, subIndex) => {
        //@ts-ignore
        res.push({ name: sub.name, id: sub.name });
      });
    });
    return res;
  },
};

type IForecastData = Record<string, string | number>;

export const prepareDataForTableInExtraForecast = (raw: IPreparedExtraForecastData) => {
  const { sections, data, q_data } = raw;
  const acc: IForecastData[] = [];
  const res: IForecastData = {};
  const usedKeys: Record<string, boolean> = {};

  if (!data?.length) {
    return acc;
  }

  if (!sections?.length) {
    return acc;
  }

  data.forEach((el, i) => {
    Object.entries(el).forEach(([key, value]) => {
      res[`${key}_${el.month - 1}`] = value;
      res.key = generateStorageKey({ type: "parent", sectionId: 0 });
    });
  });
  Object.entries(q_data).forEach(([q, val]) => {
    Object.entries(val).forEach(([key, value]) => {
      res[`${key}_${q}`] = value;
    });
  });

  acc.push(res);

  sections.forEach((section, sectionIndex) => {
    if (section.isOpen) {
      section.subsections.forEach((sub, i) => {
        const temp: IForecastData = {};
        const storageKey = generateStorageKey({
          type: "section",
          sectionId: sectionIndex,
          buildingId: String(i),
        });
        sub.data.forEach((sectionData, sectionDataIndex) => {
          Object.entries(sectionData).forEach(([key, value]) => {
            temp[`${key}_${sectionData.month - 1}`] = +value;
            temp.key = storageKey;
          });
          Object.entries(sub.q_data).forEach(([q, val]) => {
            Object.entries(val).forEach(([key, value]) => {
              temp[`${key}_${q}`] = value;
            });
          });
          if (!usedKeys[storageKey]) {
            acc.push(temp);
          }
          usedKeys[storageKey] = true;
        });
        if (sub.isOpen) {
          sub.subsections.forEach((nestedSub, nestedIndex) => {
            const temp: IForecastData = {};
            const storageKey = generateStorageKey({
              type: "subSection",
              sectionId: nestedIndex,
              buildingId: String(i),
              stockId: 0,
            });
            nestedSub.data.forEach((nestedData, nestedDataIndex) => {
              Object.entries(nestedData).forEach(([key, value]) => {
                temp[`${key}_${nestedData.month - 1}`] = +value;
                temp.key = storageKey;
              });
              Object.entries(nestedSub.q_data).forEach(([q, val]) => {
                Object.entries(val).forEach(([key, value]) => {
                  temp[`${key}_${q}`] = value;
                });
              });
              if (!usedKeys[storageKey]) {
                acc.push(temp);
              }
              usedKeys[storageKey] = true;
            });

            if (nestedSub.isOpen) {
              nestedSub.costs.forEach((cost, costIndex) => {
                const temp: IForecastData = {};
                const storageKey = cost.id;
                cost.data.forEach((costData, costDataIndex) => {
                  Object.entries(costData).forEach(([key, value]) => {
                    temp[`${key}_${costData.month - 1}`] = +value;
                    temp.key = storageKey;
                  });
                  Object.entries(cost.q_data).forEach(([q, val]) => {
                    Object.entries(val).forEach(([key, value]) => {
                      temp[`${key}_${q}`] = value;
                    });
                  });
                  if (!usedKeys[storageKey]) {
                    acc.push(temp);
                  }
                  usedKeys[storageKey] = true;
                });
              });
            }
          });
        }
      });
    }
  });

  return acc ?? [];
};
