import React from "react";
import cn from "classnames";
import styles from "../../Manufacturing.module.scss";
import { useSelector } from "react-redux";
import { activeBranchSelector } from "../../../../../redux/modules/common/building/manufacturing/selectors";
import {useRem} from '../../hooks/useRem'

interface IRunningLineProps {
  widthREM: number;
}

const RunningLine = React.forwardRef<any, IRunningLineProps>(({ widthREM }, ref) => {
  const activeBranch = useSelector(activeBranchSelector);
  const { REM } = useRem();
  return (
    <div
      className={cn(styles.runningLine, "runningLine")}
      style={{
        width: `${widthREM}rem`,
        top: activeBranch.index * 3 * REM,
      }}
      ref={ref}
    />
  );
});

export default React.memo(RunningLine);
