export default (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.7398 1.22077C2.44691 0.927875 1.97204 0.927875 1.67914 1.22077C1.38625 1.51366 1.38625 1.98854 1.67914 2.28143L5.39772 6L1.67915 9.71857C1.38626 10.0115 1.38626 10.4863 1.67915 10.7792C1.97204 11.0721 2.44692 11.0721 2.73981 10.7792L6.45838 7.06066L10.1769 10.7792C10.4698 11.0721 10.9447 11.0721 11.2376 10.7792C11.5305 10.4863 11.5305 10.0115 11.2376 9.71857L7.51904 6L11.2376 2.28143C11.5305 1.98854 11.5305 1.51366 11.2376 1.22077C10.9447 0.927875 10.4698 0.927875 10.177 1.22077L6.45838 4.93934L2.7398 1.22077Z"
      fill="#A4A4A4"
    />
  </svg>
);
