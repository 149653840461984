import moment from "moment";

import { IAnalyticsAction } from "./actions";

import { IAnalyticsState } from "./types";

export const analyticsInitialState: IAnalyticsState = {
  invalidateKey: 1,
  activeTab: "project",
  selectedDates: {
    start_at: moment().startOf("month").format("YYYY-MM-DD"),
    end_at: moment().endOf("month").format("YYYY-MM-DD"),
    monthsCount: 1,
    allProject: 0,
  },
  selectedScope: {
    project: null,
    lsr: null,
    section: null,
  },
  budgetResource: {
    resourceType: "material",
    data: {},
  },
  events: {
    activeTab: "all",
    filters: {
      start_at: moment().startOf("month").format("YYYY-MM-DD"),
      end_at: moment().endOf("month").format("YYYY-MM-DD"),
      event_types: undefined,
      name: undefined,
    },
    allEventsData: [],
    overdueEventsData: [],
  },
  progress: {},
  fulfillment: {},
  budget: [],
  cachedTrees: {},
  projectDates: {},
  loadings: {
    budgetResource: true,
    events: true,
    progress: true,
    fulfillment: true,
    budget: true,
  },
};

export default (state = analyticsInitialState, action: IAnalyticsAction): IAnalyticsState => {
  const { type, payload } = action;
  switch (type) {
    case "analytics/CLEAR_DATA":
      return {
        ...analyticsInitialState,
      };
    case "analytics/SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: payload,
      };
    case "analytics/SET_SELECTED_DATES":
      return {
        ...state,
        selectedDates: payload,
        events: {
          ...state.events,
          filters: {
            ...state.events.filters,
            start_at: payload.start_at || state.events.filters.start_at,
            end_at: payload.end_at || state.events.filters.end_at,
          },
        },
      };
    case "analytics/CHANGE_SELECTED_SCOPE":
      return {
        ...state,
        selectedScope: { ...state.selectedScope, ...payload },
      };
    case "analytics/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "analytics/BUDGET_RESOURCE_SET_TYPE":
      return {
        ...state,
        budgetResource: {
          ...state.budgetResource,
          resourceType: payload,
        },
      };
    case "analytics/BUDGET_RESOURCE_SET_DATA":
      return {
        ...state,
        budgetResource: {
          ...state.budgetResource,
          data: { ...state.budgetResource.data, [payload.type]: payload.data },
        },
      };
    case "analytics/ADD_TREE":
      return {
        ...state,
        cachedTrees: { ...state.cachedTrees, [+payload.objectId]: payload.data },
      };
    case "analytics/SET_EVENTS_ACTIVE_TAB":
      return {
        ...state,
        events: {
          ...state.events,
          activeTab: payload,
        },
      };
    case "analytics/SET_ALL_EVENTS":
      return {
        ...state,
        events: {
          ...state.events,
          allEventsData: payload,
        },
      };
    case "analytics/SET_OVERDUE_EVENTS":
      return {
        ...state,
        events: {
          ...state.events,
          overdueEventsData: payload,
        },
      };
    case "analytics/CHANGE_EVENTS_FILTERS":
      return {
        ...state,
        events: {
          ...state.events,
          filters: { ...state.events.filters, ...payload },
        },
      };
    case "analytics/SET_PROGRESS_DATA":
      return {
        ...state,
        progress: payload,
      };
    case "analytics/SET_FULFILLMENT_DATA":
      return {
        ...state,
        fulfillment: payload,
      };
    case "analytics/SET_BUDGET_DATA":
      return {
        ...state,
        budget: payload,
      };
    case "analytics/ADD_PROJECT_DATES":
      return {
        ...state,
        projectDates: {
          ...state.projectDates,
          [payload.key]: payload.dates,
        },
      };
    case "analytics/SET_LOADING":
      return {
        ...state,
        loadings: {
          ...state.loadings,
          [payload.area]: payload.isLoading,
        },
      };
    default:
      return state;
  }
};
