import React from "react";

import styles from "components/pages/Tasks/components/TasksModal/components/ModalViewTask/ModalViewTask.module.scss";

export interface ILabelTemplateProps {
  label: string;
  children: React.ReactNode;
  testId?: string;
}

const LabelTemplate: React.FC<ILabelTemplateProps> = ({ children, label, testId }) => {
  return (
    <div className={styles.partial}>
      <span className={styles.partialLabel}>{label}</span>
      <span className={styles.partialText} data-testid={testId}>
        {children}
      </span>
    </div>
  );
};

export default React.memo(LabelTemplate);
