import React, { useMemo } from "react";

import { IPlanFactBudget } from "redux/modules/common/constructing/finance/planFact/types";

import { useFinanceMeasure } from "../../../../../../features/financeMeasure";

import HideLine from "../../../../../UI/atoms/HideLine/HideLine";
import CircleComponents from "components/UI/molecules/CircleComponents";

import { formatNumberWithMeasure } from "../../../../../../utils/formatters/formatNumberWithMeasure";
import { percentsFormatter } from "../../../../../../utils/percentsFormatter";

import styles from "./PlanFactIndicators.module.scss";

interface IPlanFactIndicators {
  planFactBudgets: Pick<IPlanFactBudget, "budget" | "fact" | "plan">;
}

const PlanFactIndicators: React.FC<IPlanFactIndicators> = ({ planFactBudgets }) => {
  const { measure } = useFinanceMeasure();

  const { plan, fact, budget, percent } = useMemo(() => {
    const plan = parseFloat(planFactBudgets?.plan) || 0;
    const fact = parseFloat(planFactBudgets?.fact) || 0;
    const budget = parseFloat(planFactBudgets?.budget) || 0;
    const percent = !!plan && !!fact ? (fact * 100) / plan : 0;
    return {
      plan,
      fact,
      budget,
      percent,
    };
  }, [planFactBudgets]);

  return (
    <HideLine
      contentClassName={styles.content}
      containerClassName={styles.container}
      containerOpenClassName={styles.open}
    >
      {planFactBudgets && (
        <div className={styles.circleBlock}>
          <div className={styles.item}>
            <CircleComponents
              progress={percentsFormatter(percent, 0.01)}
              color="#8FE52B"
              description="Процент выполнения"
              titleClassName={styles.circleTitle}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.item}>
            <CircleComponents
              progress={100}
              color="#00C3F2"
              description="План"
              displayingValue={!!plan ? (formatNumberWithMeasure(plan, measure) as string) : 0}
              isProgressStringHidden
              titleClassName={styles.circleTitle}
            />
          </div>
          <div className={styles.item}>
            <CircleComponents
              progress={!!plan ? fact / (plan / 100) : 0}
              color="#C55EEB"
              description="Факт"
              displayingValue={formatNumberWithMeasure(fact, measure) as string}
              isProgressStringHidden
              titleClassName={styles.circleTitle}
            />
          </div>
          <div className={styles.item}>
            <CircleComponents
              progress={!!plan ? budget / (plan / 100) : 0}
              color="#72E2E6"
              description="Бюджет"
              displayingValue={formatNumberWithMeasure(budget, measure) as string}
              isProgressStringHidden
              titleClassName={styles.circleTitle}
            />
          </div>
        </div>
      )}
    </HideLine>
  );
};

export default React.memo(PlanFactIndicators);
