import { IActions } from "../../../../../store";

import { IDetailedProjectForecast, IForecastTree, IProjectViewForecast } from "./types";

export const forecastActions = {
  clearData: () =>
    ({
      type: "constructing/finance/forecast_CLEAR_DATA",
      payload: {},
    } as const),
  setLoading: (isLoading: boolean) =>
    ({
      type: "constructing/finance/forecast_SET_LOADING",
      payload: isLoading,
    } as const),
  setAllProjectsForecastsList: (forecastsList: IProjectViewForecast[]) =>
    ({
      type: "constructing/finance/forecast_SET_ALL_PROJECTS_FORECASTS",
      payload: forecastsList,
    } as const),
  setDetailedProjectForecast: (objectId: number, forecast: IDetailedProjectForecast) =>
    ({
      type: "constructing/finance/forecast_SET_DETAILED_PROJECT_FORECAST",
      payload: { objectId, forecast },
    } as const),
  setForecastsTree: (tree: IForecastTree[]) =>
    ({
      type: "constructing/finance/forecast_SET_FORECAST_TREE",
      payload: tree,
    } as const),
  setIsLoadingByObject: (status: boolean, objectId: number) =>
    ({
      type: "constructing/finance/forecast_SET_IS_LOADING_BY_OBJECT",
      payload: {
        status,
        objectId,
      },
    } as const),
  toggleLsr: (lsrId: number) =>
    ({
      type: "constructing/finance/forecast_TOGGLE_LSR",
      payload: lsrId,
    } as const),
  setDetailedByProjectV2: (projectId: string, data: any) =>
    ({
      type: "constructing/finance/forecast_SET_DEATILED_BY_PROJECT_V2",
      payload: {
        data,
        projectId,
      },
    } as const),
  setProjectsV2Totals: (data: Record<string, string>, projectId: string) =>
    ({
      type: "constructing/finance/forecast_setProjectsV2Totals",
      payload: {
        data,
        projectId,
      },
    } as const),
};

export type IForecastAction = IActions<typeof forecastActions>;
