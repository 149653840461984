import $ from "jquery";
import { useEffect } from "react";

import Sites11Svg from "./img/sites11.png";
import Sites12Svg from "./img/sites12.png";
import Sites13Svg from "./img/sites13.png";
import Sites14Svg from "./img/sites14.png";
import Sites15Svg from "./img/sites15.png";
import SitesMob11Svg from "./img/sites-mob11.png";
import SitesMob12Svg from "./img/sites-mob12.png";
import SitesMob13Svg from "./img/sites-mob13.png";
import SitesMob14Svg from "./img/sites-mob14.png";
import SitesMob15Svg from "./img/sites-mob15.png";

export const AdditionalFeaturesSection = () => {
  useEffect(() => {
    addActiveClassFour("reviews-min-text-four", "active-href");
    changeCaseBlockFour(this, "reviews-min-text-four", "reviews-min-four", "active-href", "click-reviews-four");
    $(".click-reviews-four").on("click", function () {
      changeActiveClassWithClickFour(this, "reviews-min-text-four", "active-href");
      changeCaseBlockFour(this, "reviews-min-text-four", "reviews-min-four", "active-href", "click-reviews-four");
    });
    function addActiveClassFour(parent_menu, active_class) {
      let prt = $("." + parent_menu);
      let prt_childrens = $(prt).children();
      let prt_child_li = $(prt_childrens).children();
      let first_child = $(prt_child_li)[0];
      if (!$(first_child).hasClass(active_class)) {
        !$(first_child).addClass(active_class);
      }
    }
    function changeActiveClassWithClickFour($this, parent_block, active_class) {
      let prt = $($this).parents("." + parent_block);
      let prt_child = $(prt).find("li");
      $(prt_child).each(function () {
        $(this).removeClass(active_class);
      });
      $($this).addClass(active_class);
    }
    function changeCaseBlockFour($this, case_menu, case_block, active_class, menu_link) {
      let case_menu_block = $("." + case_menu);
      let case_block_sub = $("." + case_block);
      let case_block_child = $(case_block_sub).children();
      $(case_block_child).each(function () {
        $(this).css({ display: "none", height: 0 });
      });
      if ($($this).hasClass(menu_link)) {
        let this_attr = $($this).attr("data-catalog");
        $(case_block_child).each(function () {
          if ($(this).attr("data-catalog") == this_attr) {
            $(this).css({ display: "block", height: "100%" });
          }
        });
      } else {
        let active_find = $(case_menu_block).find("." + active_class);
        let active_find_attr = $(active_find).attr("data-catalog");
        $(case_block_child).each(function () {
          if ($(this).attr("data-catalog") == active_find_attr) {
            $(this).css({ display: "block", height: "100%" });
          }
        });
      }
    }
  }, []);

  return (
    <section className="additional-features sites-block-plan" id="additional-features">
      <div className="container">
        <div className="sites-block__title">
          <h2>Дополнительные возможности</h2>
          <div className="reviews-min-text-four menu-sites-blocks">
            <ul>
              <li className="click-reviews-four active-href" data-catalog="tasks">
                Задачи
              </li>
              <li className="click-reviews-four" data-catalog="rmployee">
                Учет сотрудников
              </li>
              <li className="click-reviews-four" data-catalog="calendar">
                Календарь
              </li>
              <li className="click-reviews-four" data-catalog="chats">
                Чаты
              </li>
              <li className="click-reviews-four" data-catalog="storage">
                Хранилище
              </li>
            </ul>
          </div>
        </div>
        <div className="reviews-min-four">
          <div className="reviews-min-block-js" data-catalog="tasks">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы можете формировать списки задач для проведения совещаний</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Постановка задачи по проекту и разделу</li>
                    <li>Постановка повторяющихся задач</li>
                    <li>Назначение исполнителей и контролеров</li>
                    <li>Установка приоритетов</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites11Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob11Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="rmployee">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы видите данные о явке как собственных сотрудников, так и сотрудников субподрядчиков</li>
                    <li>Вы получаете табель по форме Т-13 в автоматическом режиме</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Контроль явки и отработанного времени по каждому сотруднику</li>
                    <li>Автоматическое формирование табеля учета рабочего времени и его передача в учетную систему</li>
                    <li>Простой интерфейс, позволяющий заполнять данные с любого устройства</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites12Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob12Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="calendar">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы легко контролируете ключевые события</li>
                    <li>Вы можете получить УПД на поставку из календаря</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Отражение ключевых событий</li>
                    <li>Переход в документы, связанные с событиями</li>
                    <li>Отражение необходимых данных по каждому событию</li>
                    <li>Настройка уведомлений о событиях</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites13Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob13Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="chats">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы можете задать вопрос любому сотруднику в чате на платформе</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Коммуникация в чате при планировании, выполнении, приемке работ и формировании документации</li>
                    <li>Коммуникация в чате при обработке заявок и заказов</li>
                    <li>Коммуникация в чате при учете сотрудников</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites14Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob14Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
          <div className="reviews-min-block-js" data-catalog="storage">
            <div className="sites-block__flex">
              <div className="sites-block__info">
                <div className="sites-block__text">
                  <ul>
                    <li>Вы можете хранить все документы по проекту на платформе</li>
                  </ul>
                </div>
                <div className="sites-block__hert">
                  <ul>
                    <li>Доступ к документам 24/7</li>
                    <li>Любой участник проекта имеет доступ к документам в соответствии с правами</li>
                  </ul>
                </div>
              </div>
              <div className="sites-block__photo">
                <img src={Sites15Svg} className="sites-pic-dec" alt="image" />
                <img src={SitesMob15Svg} className="sites-pic-mob" alt="image" />
              </div>
            </div>
          </div>
        </div>
        <span className="title-bot">Работайте в общей среде данных</span>
      </div>
    </section>
  );
};
