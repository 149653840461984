import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../../../UI/atoms/Modal";
import styles from "./ShiftsModal.module.scss";
import { errorCatcher } from "../../../../../../utils/helpers/errorCatcher";
import { Spinner } from "../../../../../UI/Spinner/Spinner";
import { getShiftsList } from "./getShiftsList";
import { IShift, IShiftDetail } from "./types";
import ShiftsList from "./ShiftsList/ShiftsList";
import SelectedPlanContent from "./SelectedPlanContent/SelectedPlanContent";
import ListHeader from "./ListHeader/ListHeader";
import SelectedPlanHeader from "./SelectedPlanHeader/SelectedPlanHeader";
import { getShiftDetail } from "./getShiftDetail";
import { getShiftIdByPlanId, serializeShiftPlanData } from "./utils";
import ApproveActionHeader from "./ApproveActionHeader/ApproveActionHeader";
import cn from "classnames";
import { cancelShift, moveShift } from "./api";
import { message } from "antd";
import axios from "axios";
import moment from "moment";
import { useQueryParams } from "../../../../../../utils/hooks/useQueryParams";
import { useDispatch } from "react-redux";
import { setManufacturingHash } from "../../../../../../redux/modules/common/building/manufacturing/thunks";


interface IShiftModalProps {
  isOpened: boolean;
  onClose: () => void;
  objectId: string;
  planId?: number;
  isGroup?: boolean;
  openedFromPlanClick?: boolean;
}

const ShiftsModal: FC<IShiftModalProps> = (props) => {
  const {
    isOpened,
    onClose,
    objectId,
    planId,
    isGroup = false,
    openedFromPlanClick = false
  } = props;

  const dispatch = useDispatch();

  const [isNeedReload, setIsNeedReload] = useState(false);

  const [isShiftsLoading, setIsShiftsLoading] = useState(false);
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [nextShiftsLink, setNextShiftsLink] = useState<string | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [isSelectedPlanLoading, setIsSelectedPlanLoading] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(planId ?? null);
  const [selectedShiftData, setSelectedShiftData] = useState<IShiftDetail | null>(null);

  const [isApproveActionOpened, setIsApproveActionOpened] = useState(false);

  const startDate = useQueryParams('openShiftModalDate');

  useEffect(() => {
    // Загрузка общего списка
    const query: Record<string, any> = {
      building: objectId,
    };
    if (startDate && !openedFromPlanClick) {
      query.created_at_after = startDate;
      query.created_at_before = moment(startDate).add('days', 1).format('YYYY-MM-DD');
    }

    if (selectedPlanId && openedFromPlanClick) {
      if (isGroup) {
        query.plan_group = selectedPlanId;
      } else {
        query.plan_work = selectedPlanId;
      }
    }

    setIsShiftsLoading(true);
    getShiftsList(query).then((response) => {
      setShifts(response.data.results);
      if (response.data.next) {
        setNextShiftsLink(response.data.next.replace('http:', 'https:'));
      }

      if (selectedPlanId) {
        // В случае, если открыт кликом на плане на графике, подгружаем данные о конкретном сдвиге
        setIsSelectedPlanLoading(true);
        const selectedPlanShiftId = (response.data?.results ?? []).find((shift) => {
          if (shift.type === "work") {
            return shift.plan_work.id === selectedPlanId;
          } else {
            return shift.plan_group.id === selectedPlanId;
          }
        })?.id;

        if (selectedPlanShiftId) {
          getShiftDetail(selectedPlanShiftId)
            .then((response) => {
              setSelectedShiftData(response.data);
            })
            .catch(errorCatcher)
            .finally(() => {
              setIsSelectedPlanLoading(false);
            })
        }
      }
    })
    .catch(errorCatcher)
    .finally(() => {
      setIsShiftsLoading(false);
    })
  }, []);

  useEffect(() => {
    if (shifts && selectedPlanId && !selectedShiftData) {
      setIsSelectedPlanLoading(true);
      const selectedShiftId = getShiftIdByPlanId(shifts, selectedPlanId);

      if (selectedShiftId) {
        getShiftDetail(selectedShiftId)
          .then((response) => {
            setSelectedShiftData(response.data);
          })
          .catch(errorCatcher)
          .finally(() => {
            setIsSelectedPlanLoading(false);
          })
      }
    }
  }, [shifts, selectedPlanId, selectedShiftData]);

  const onCancel = () => {
    if (selectedPlanId) {
      const shiftId = getShiftIdByPlanId(shifts, selectedPlanId);
      if (shiftId) {
        cancelShift(shiftId).then(r => {
          if (openedFromPlanClick) {
            onClose();
            dispatch(setManufacturingHash(Math.random()));
          } else {
            setSelectedShiftData(null);
            setSelectedPlanId(null);
            setShifts(prev => prev.map(p => {
              if (p.id === shiftId) {
                return { ...p, deactivated: true }
              } else {
                return p
              }
            }));
            setIsNeedReload(true);
          }
        }).catch(errorCatcher);
      }
    }
  }

  const onMove = (days: number, finallyCallback: () => void) => {
    if (selectedPlanId) {
      const shiftId = getShiftIdByPlanId(shifts, selectedPlanId);
      if (shiftId) {
        moveShift(shiftId, days).then(r => {
          message.success("График успешно сдвинут");
          if (openedFromPlanClick) {
            onClose();
            dispatch(setManufacturingHash(Math.random()));
          } else {
            setSelectedPlanId(null);
            setSelectedShiftData(null);
            setShifts(prev => prev.map(p => {

              // После успешного сдвига обновляем даты в общем списке
              if (p.id === shiftId) {
                const isGroup = p.type === "group";

                let start_at;
                let end_at;

                start_at = isGroup
                  ? moment(p.plan_group.start_at).add(days, 'days').format('YYYY-MM-DD')
                  : moment(p.plan_work.start_at).add(days, 'days').format('YYYY-MM-DD');
                end_at = isGroup
                  ? moment(p.plan_group.end_at).add(days, 'days').format('YYYY-MM-DD')
                  : moment(p.plan_work.end_at).add(days, 'days').format('YYYY-MM-DD');

                if (isGroup) {
                  return {
                    ...p,
                    plan_group: {
                      ...p.plan_group,
                      start_at,
                      end_at
                    },
                    deactivated: true,
                  }
                } else {
                  return {
                    ...p,
                    plan_work: {
                      ...p.plan_work,
                      start_at,
                      end_at
                    },
                    deactivated: true,
                  }
                }
              } else {
                return p
              }
            }));
            setIsNeedReload(true);
          }
        })
          .catch(errorCatcher)
          .finally(() => {
            finallyCallback()
          });
      }
    }
  }

  const handleModalClose = useCallback(() => {
    let closed = false;

    if (selectedShiftData) {
      if ((selectedShiftData.type === "work" && !selectedShiftData.plan_work.not_enough.length)
        || (selectedShiftData.type === "group" && !selectedShiftData.plan_group.not_enough.length)) {
        onCancel();
        if (isNeedReload) {
          dispatch(setManufacturingHash(Math.random()));
        }
        closed = true;
      }
    }

    if (!closed) {
      onClose();
      if (isNeedReload) {
        dispatch(setManufacturingHash(Math.random()));
      }
    }
  }, [selectedShiftData, onClose, isNeedReload]);

  const handleShiftClick = (planId: number) => {
    setSelectedPlanId(planId);
  }

  const handleSelectedPlanHeaderBackButtonClick = useCallback(() => {
    if (selectedShiftData) {
      if ((selectedShiftData.type === "work" && !selectedShiftData.plan_work.not_enough.length)
        || (selectedShiftData.type === "group" && !selectedShiftData.plan_group.not_enough.length)) {
        onCancel();
      }
    }

    setSelectedShiftData(null);
    setSelectedPlanId(null);
  }, [selectedShiftData]);

  const serializedPlanData = selectedShiftData ? serializeShiftPlanData(selectedShiftData) : null;

  let ModalHeader = null;

  if (!selectedPlanId) {
    ModalHeader = <ListHeader />;
  } else {
    if (serializedPlanData) {
      ModalHeader = (
        <SelectedPlanHeader
          title={serializedPlanData.section}
          dateStart={serializedPlanData.start_at}
          dateEnd={serializedPlanData.end_at}
          showBackButton={!openedFromPlanClick}
          onBackButtonClick={handleSelectedPlanHeaderBackButtonClick}
        />
      )
    }

    if (isApproveActionOpened) {
      ModalHeader = <ApproveActionHeader />
    }
  }

  const onLoadMoreShifts = useCallback(() => {
    if (nextShiftsLink) {
      setIsLoadingMore(true);
      axios.get(nextShiftsLink).then(r => {
        setShifts(prev => [...prev, ...r.data.results]);
        setNextShiftsLink(r.data.next ? r.data.next.replace('http:', 'https:') : null);
      })
        .catch(errorCatcher)
        .finally(() => setIsLoadingMore(false));
    }
  }, [nextShiftsLink, shifts])

  return (
    <Modal
      isOpen={isOpened}
      onClose={handleModalClose}
      className={cn(styles.modalContainer, {[styles.approveActionView]: isApproveActionOpened})}
      title={ModalHeader}
      headerClassName={styles.modalHeader}
    >
      {isShiftsLoading || isSelectedPlanLoading
        ? (<Spinner className={styles.spinner} />)
        : (
          <>
            {selectedShiftData
              ? (
                <SelectedPlanContent
                  data={selectedShiftData}
                  isApproveActionOpened={isApproveActionOpened}
                  setIsApproveActionOpened={setIsApproveActionOpened}
                  onCancel={onCancel}
                  onMove={onMove}
                />
              )
              : (
                <ShiftsList
                  shifts={shifts}
                  onShiftClick={handleShiftClick}
                  showLoadMore={!!nextShiftsLink}
                  onLoadMore={onLoadMoreShifts}
                  isLoadingMore={isLoadingMore}
                />
              )
            }
          </>
          )
      }
    </Modal>
  );
}

export default memo(ShiftsModal);
