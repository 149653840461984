import Axios from "axios";
import cn from "classnames";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { loadContracts } from "redux/modules/common/building/aggregations";

import ButtonBase from "components/UI/atoms/ButtonBase";
import styles from "components/pages/Handler/components/ImportExtraordinaryDocumentation/components/ImportModal/ImportModal.module.scss";

import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { errorCatcher } from "utils/helpers/errorCatcher";

import { ReactComponent as WarningIcon } from "../../../../../../../shared/assets/icons/Icon Warning.svg";
import { ReactComponent as ExcelIcon } from "../../../../../../../shared/assets/icons/Icon-ChooseImportFile.svg";
import { ReactComponent as SuccessIcon } from "../../../../../../../shared/assets/icons/Icon-success.svg";

interface IRouterParamsWithBuildingId {
  buildingId: string;
}

interface IProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setCountOfPositions: React.Dispatch<React.SetStateAction<number>>;
  setErrList: React.Dispatch<React.SetStateAction<object>>;
  setIsErr: React.Dispatch<React.SetStateAction<boolean>>;
  refreshSections: any;
  isErr: boolean;
  errList: any;
  countOfPositions: number;
  isSuccess: boolean;
}

const formatFileName = (name: string) => {
  return name.split("filename=")[1];
};

const ImportModal: React.FC<IProps> = ({
  setIsLoading,
  setIsModalOpen,
  setIsSuccess,
  setCountOfPositions,
  setErrList,
  setIsErr,
  isErr,
  errList,
  isSuccess,
  countOfPositions,
  refreshSections,
}) => {
  const params = useParams<IRouterParamsWithBuildingId>();
  const buildingId: string = params.buildingId;

  const dispatch = useDispatch();

  const getAggregations = useCallback(() => {
    dispatch(loadContracts(buildingId));
  }, [buildingId, dispatch]);

  const handleDownloadSample = () => {
    Axios.post(`/building/${buildingId}/estimate/import_work_estimate/template/`, {}, { responseType: "blob" })
      .then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers.contentType }));
        link.download = formatFileName(response.headers["content-disposition"]);
        link.click();
      })
      .catch(errorCatcher);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    setIsErr(false);
    const file: any = event.target.files?.item(0);
    getArrayFilesInBase64([file]).then((arrayFilesInBase64) => {
      Axios.post(`/building/${buildingId}/estimate/import_work_estimate/`, {
        name: arrayFilesInBase64[0].name,
        file: arrayFilesInBase64[0].file,
      })
        .then((res) => {
          setCountOfPositions(res.data.created);
          getAggregations();
          refreshSections();
          setIsLoading(false);
          setIsSuccess(true);
        })
        .catch((err) => {
          setIsLoading(false);
          if (!err.response.data.detail) {
            setErrList(err.response.data?.errors);
            setIsErr(true);
          }
          errorCatcher(err);
        });
    });
  };

  const handleSuccess = () => {
    setIsModalOpen(false);
    setIsSuccess(false);
    setCountOfPositions(0);
    setIsErr(false);
    setErrList({});
  };

  return !isSuccess ? (
    <>
      {!isErr ? (
        <>
          <ExcelIcon className={styles.icon} />
          <p className={styles.modaltext}>
            Импорт происходит согласно шаблону.
            <br />
            Заполните шаблон и импортируйте файл xlsx
          </p>
        </>
      ) : (
        <>
          <WarningIcon className={styles.icon} />
          <p className={styles.modaltext}>Позиции не сохранены. Требуются исправления:</p>
          <div className={styles.titles}>
            <h4>Строка</h4>
            <h4>Ошибка</h4>
          </div>
          <div className={styles.listbox}>
            {Object.keys(errList).map((el) => {
              return (
                <div className={styles.table} key={el}>
                  <div className={styles.string}>
                    <h4>{el}</h4>
                  </div>
                  <div className={styles.mistake}>{errList[el]}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className={styles.buttons}>
        <ButtonBase secondary onClick={handleDownloadSample}>
          Скачать шаблон
        </ButtonBase>
        <label>
          <div className={styles.btn}>Выбрать файл</div>
          <input type="file" accept=".xlsx" className={styles.input} onChange={handleChangeInput} />
        </label>
      </div>
    </>
  ) : (
    <>
      <div>
        <SuccessIcon className={cn(styles.icon, styles.success)} />
        <p className={styles.modaltext}>В систему успешно добавлены позиции: {countOfPositions}</p>
      </div>
      <div className={styles.buttons} style={{ justifyContent: "center" }}>
        <ButtonBase primary onClick={handleSuccess}>
          Хорошо!
        </ButtonBase>
      </div>
    </>
  );
};

export default React.memo(ImportModal);
