import cn from "classnames";
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { diagramFiltersSelector } from "redux/modules/common/building/manufacturing/selectors";
import { downloadChartFile, updateDiagramFilters } from "redux/modules/common/building/manufacturing/thunks";

import Icon from "../../../../UI/Icon/Icon";
import { Switch } from "../../../../UI/atoms/Switch/Switch";
import ButtonBase from "components/UI/atoms/ButtonBase";

import { DiagramFilters, REMARKS_COLOR } from "../../constants";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import useOnClickOutside from "hooks/useOnClickOutside";

import diagramLinkingIcon from "images/diagramLinkingIcon.svg";
import arrowDownSelect from "images/icons/arrowDownSelect.svg";
import closeNotyItem from "images/icons/closeNotyItem.svg";
import { ReactComponent as DownloadIcon } from "images/icons/download_16.svg";
import diagramEditIcon from "images/icons/edit_outline_24.svg";

import styles from "./DiagramActions.module.scss";

interface IProps {
  year: string;
}

const DiagramActions: React.FC<IProps> = ({ year }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const diagramFilters = useSelector(diagramFiltersSelector);
  const { objectId: projectId } = useParams<IRouterParamsWithObjectId>();

  const containerRef = useRef<HTMLDivElement>(null);
  const onClose = useCallback(() => setIsOpen(false), []);

  useOnClickOutside(containerRef, onClose);

  const onActionChange = (name: DiagramFilters, value: boolean) => {
    dispatch(updateDiagramFilters({ name, value }));
  };

  const toggleOpen: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  }, []);

  const isLinkingEditingEnabled = diagramFilters[DiagramFilters.linking_editing_enabled];
  const isPlansEditingEnabled = diagramFilters[DiagramFilters.plans_editing_enabled];

  const isOutOfEstimateEnabled = diagramFilters[DiagramFilters.out_of_estimate_enabled];

  const disableLinkingEditing = useCallback(() => onActionChange(DiagramFilters.linking_editing_enabled, false), []);
  const disablePlansEditing = useCallback(() => onActionChange(DiagramFilters.plans_editing_enabled, false), []);

  const downloadButtonClickHandler = () => {
    downloadChartFile(projectId, year);
  };

  return (
    <div className={styles.diagramActions} ref={containerRef}>
      {isLinkingEditingEnabled && (
        <div className={styles.modeBubble}>
          <Icon icon={diagramLinkingIcon} className={styles.modeBubbleIcon} />
          <span>Работа со связями</span>
          <Icon icon={closeNotyItem} className={styles.modeBubbleCloseIcon} onClick={disableLinkingEditing} />
        </div>
      )}
      {isPlansEditingEnabled && (
        <div className={styles.modeBubble}>
          <Icon icon={diagramEditIcon} className={styles.modeBubbleIcon} />
          <span>Работа с планом</span>
          <Icon icon={closeNotyItem} className={styles.modeBubbleCloseIcon} onClick={disablePlansEditing} />
        </div>
      )}
      <div className={styles.actionsContainer}>
        <div className={cn(styles.head, { [styles.isOpen]: isOpen })} onClick={toggleOpen}>
          <span>Действия c графиком</span>
          <Icon icon={arrowDownSelect} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen })} />
        </div>
        {isOpen && (
          <div className={styles.content}>
            <div className={styles.actionsGroup}>
              <Switch
                className={styles.action}
                onChange={(e) => onActionChange(DiagramFilters.linking_editing_enabled, e.target.checked)}
                checked={isLinkingEditingEnabled}
                label={<span className={styles.actionLabel}>Работа со связями</span>}
              />
              <Switch
                className={styles.action}
                onChange={(e) => onActionChange(DiagramFilters.plans_editing_enabled, e.target.checked)}
                checked={isPlansEditingEnabled}
                label={<span className={styles.actionLabel}>Работа с планом</span>}
              />
            </div>
            {/*<div className={styles.actionsGroup}>*/}
            {/*  <Switch*/}
            {/*    className={styles.action}*/}
            {/*    onChange={(e) => onActionChange(DiagramFilters.out_of_estimate_enabled, e.target.checked)}*/}
            {/*    checked={isOutOfEstimateEnabled}*/}
            {/*    label={<span className={styles.actionLabel}>Отображать по РД</span>}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className={cn(styles.actionsGroup, styles.center)}>
              <ButtonBase primary medium className={styles.downloadButton} onClick={downloadButtonClickHandler}>
                Скачать график
                <DownloadIcon className={styles.downloadIcon} />
              </ButtonBase>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(DiagramActions);
