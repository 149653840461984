import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  analyticsEventsActiveTabSelector,
  analyticsEventsFiltersSelector,
} from "../../../../../../../redux/modules/common/constructing/analytics/selectors";
import {
  changeAnalyticsEventsFilter,
  changeAnalyticsEventsTab,
} from "../../../../../../../redux/modules/common/constructing/analytics/thunks";
import { IAnalyticsEvents } from "../../../../../../../redux/modules/common/constructing/analytics/types/events";

import TabBarNotLinks from "../../../../../../UI/atoms/TabBar/TabBarNotLinks";
import TableReusableHead, { TableReusableHeaderCell } from "../../../../../../UI/atoms/TableReusable/TableReusableHead";

import { ANALYTICS_EVENTS_TABS, ANALYTICS_EVENTS_TYPES_OPTIONS } from "../../constants";

import styles from "./AnalyticsEventsHeader.module.scss";

const AnalyticsEventsHeader: React.FC = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(analyticsEventsActiveTabSelector);
  const filters = useSelector(analyticsEventsFiltersSelector);

  const handleTabChange = useCallback((newTab: string) => {
    dispatch(changeAnalyticsEventsTab(newTab as IAnalyticsEvents["activeTab"]));
  }, []);

  const handleFilterChange = useCallback((fieldName: string, value: any) => {
    dispatch(changeAnalyticsEventsFilter({ [fieldName]: value }));
  }, []);

  return (
    <div className={styles.header}>
      <TabBarNotLinks
        tabs={ANALYTICS_EVENTS_TABS}
        activeId={activeTab}
        onClick={handleTabChange}
        variant={"light"}
        className={styles.tabs}
      />
      <TableReusableHead className={styles.tableHead}>
        <TableReusableHeaderCell
          filterType={"select"}
          filterField={"event_types"}
          onChangeFilter={handleFilterChange}
          filterOptions={ANALYTICS_EVENTS_TYPES_OPTIONS}
          filterTitle={"Тип события"}
          filterSelectDefaultValue={filters.event_types}
        >
          Тип события
        </TableReusableHeaderCell>
        {/*<TableReusableHeaderCell*/}
        {/*  filterType={"text"}*/}
        {/*  isLast*/}
        {/*  onChangeFilter={handleFilterChange}*/}
        {/*  filterField={"name"}*/}
        {/*  filterTitle={"Наименование события"}*/}
        {/*  isCentered*/}
        {/*>*/}
        {/*  Наименование события*/}
        {/*</TableReusableHeaderCell>*/}
        <div />
        <TableReusableHeaderCell
          filterType={"datesRange"}
          isLast
          onChangeFilter={handleFilterChange}
          rangeStartField={"start_at"}
          rangeEndField={"end_at"}
          filterTitle={"Дата"}
          isRighted
        >
          Дата
        </TableReusableHeaderCell>
      </TableReusableHead>
    </div>
  );
};

export default React.memo(AnalyticsEventsHeader);
