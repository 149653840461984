import cn from "classnames";
import React from "react";

import { ICalendarBackgroundProps } from "../../CalendarBackground";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import { generateDaysForLine } from "../../../../utils";

import styles from "../../../../Manufacturing.module.scss";

const DaysBackground: React.FC<ICalendarBackgroundProps> = ({
  year,
  unitHeight,
  constructionDateStart,
  constructionDateEnd,
  unitOffset,
  criticalDates = []
}) => {
  const unitMultiplier = useUnitMultiplier();
  const days = generateDaysForLine(+year, constructionDateStart, constructionDateEnd, criticalDates);

  return (
    <div
      className={styles.daysLine}
      style={{ height: `${unitHeight * 3}rem`, left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
    >
      {days.map((day, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={cn({
                [styles.dayWeekend]: day.weekend,
                [styles.dayWork]: !day.weekend,
                [styles.outdatedBg]: day.isOutOfDates,
              })}
              style={{
                left: `${index * unitMultiplier}rem`,
                width: `${unitMultiplier}rem`,
              }}
            />
            {day.today && (
              <div
                className={styles.currentDay}
                style={{
                  left: `${(index + 0.5) * unitMultiplier}rem`,
                }}
              />
            )}
            {day.isCritical && (
              <div
                className={cn(styles.criticalLine)}
                style={{
                  left: `${(index + 1) * unitMultiplier}rem`,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(DaysBackground);
