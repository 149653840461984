import cn from "classnames";
import React, { useCallback, useState } from "react";

import BackNavigationBar from "../../../../../UI/atoms/BackNavigationBar/BackNavigationBar";
import ShowMoreButton from "../../../../../UI/atoms/ShowMoreButton/index";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import SearchFilterTable from "components/UI/atoms/SearchFilterTable";
import SliderModal from "components/UI/atoms/SliderModal";
import { CloseAddModal } from "components/pages/Settings/EditProject/EditProjectContractors";
import { IAddOurContractors, IChangeFilter } from "components/pages/Settings/EditProject/types";

import ContractorInfo from "../ContractorInfo/ContractorInfo";
import AddContractorRow from "./AddContractorRow";

import { LOCALIZATION_CONFIG } from "../../../../../../constants/localization";
import IContractor from "types/interfaces/Contractor";

import useArrayItemsChecker from "../../../../../../hooks/useArrayItemsChecker";

import styles from "./AddModal.module.scss";

interface IAddContractorModal {
  contractors: IContractor[];
  contractorsCount: number;
  onClose: CloseAddModal;
  isOpen: boolean;
  handleChangeFilter: IChangeFilter;
  handleLoadMore: () => void;
  addContractors: IAddOurContractors;
  isLoading?: boolean;
}

const AddContractorModal = ({
  contractors,
  contractorsCount,
  onClose,
  isOpen,
  handleChangeFilter,
  handleLoadMore,
  addContractors,
  isLoading,
}: IAddContractorModal) => {
  const hasNextPage = contractorsCount > contractors.length;

  const checker = useArrayItemsChecker(contractors, "id");
  const [activeDetailContractor, setActiveDetailContractor] = useState<IContractor | null>(null);

  const onSubmit = useCallback(() => {
    const checkedContractors = Object.entries(checker.items)
      .filter(([_, isChecked]) => isChecked)
      .map(([id]) => +id);
    addContractors(checkedContractors);
    onClose();
    checker.reset();
  }, [addContractors, onClose, checker.items, checker.reset]);

  const resetActiveDetailContractor = useCallback(() => setActiveDetailContractor(null), []);
  return (
    <SliderModal className={styles.modal} isOpen={isOpen} closeHandler={onClose}>
      <div className={styles.inner}>
        <h2 className={styles.title}>Выберите подрядчика</h2>
        {isLoading && <Spinner />}
        {!activeDetailContractor ? (
          <>
            <p className={styles.count}>Выбрано организаций: {checker.checkedCount}</p>
            <div className={`${styles.thead} ${styles.row}`}>
              <div className={cn(styles.th, styles.number)}>№</div>
              <div className={styles.th}>
                Наименование
                <SearchFilterTable
                  title="Наименование"
                  setFilter={(value) => {
                    handleChangeFilter(value, "name");
                  }}
                />
              </div>
              <div className={styles.th}>
                {LOCALIZATION_CONFIG.taxesNumberLabel}
                <SearchFilterTable
                  title={LOCALIZATION_CONFIG.taxesNumberLabel}
                  setFilter={(value) => {
                    handleChangeFilter(value, "itn");
                  }}
                  last
                />
              </div>
            </div>
            <div className={styles.scroll}>
              {contractors.map((contractor, i) => (
                <AddContractorRow
                  contractor={contractor}
                  onActiveDetail={setActiveDetailContractor}
                  isSelected={checker.items[contractor.id]}
                  onSelect={checker.checkOnce}
                  number={i + 1}
                  key={contractor.id}
                />
              ))}
            </div>
            <div className={styles.btns}>
              <ButtonBase onClick={onSubmit} disabled={checker.checkedCount === 0} primary>
                Добавить: {checker.checkedCount}
              </ButtonBase>
            </div>
          </>
        ) : (
          <>
            <nav className={styles.backNav}>
              <BackNavigationBar
                title={activeDetailContractor.name}
                backLink={false}
                onBack={resetActiveDetailContractor}
                rightSideText={undefined}
              />
            </nav>
            <div className={styles.contractorInfo}>
              <ContractorInfo contractorId={activeDetailContractor.id} />
            </div>
          </>
        )}
      </div>
      <ShowMoreButton
        isExists={hasNextPage}
        handleAdd={handleLoadMore}
        showedCount={contractors.length}
        allCount={contractorsCount}
      />
    </SliderModal>
  );
};

export default React.memo(AddContractorModal);
