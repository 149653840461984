import React from "react";

import { IApprovalInModal } from "../../../../../../UI/organism/WorkOrMaterialsModals/components/Approval/types";

import {
  Responsibilities,
  useCheckIsCurrentUserResponsible,
} from "../../../../../../../hooks/useCheckIsCurrentUserResponsible";

export interface IUseCanEditPlanProps {
  isActive: boolean;
  objectId: string | number;
  isSection: boolean;
  approvals?: IApprovalInModal[];
}

export const useCanEditPlan = ({ isActive, objectId, isSection, approvals }: IUseCanEditPlanProps) => {
  const isWorkPlanConfirmResponsible = useCheckIsCurrentUserResponsible({
    responsibleFor: Responsibilities.planWorkConfirm,
    projectId: objectId,
    isActive,
  });

  const isSectionPlanConfirmResponsible = useCheckIsCurrentUserResponsible({
    responsibleFor: Responsibilities.planSectionConfirm,
    projectId: objectId,
    isActive,
  });

  const isResponsibleEmployee = useCheckIsCurrentUserResponsible({
    responsibleFor: Responsibilities.responsibleEmployee,
    projectId: objectId,
    isActive,
  });

  const isResponsible =
    isResponsibleEmployee ||
    (isSection && isSectionPlanConfirmResponsible) ||
    (!isSection && isWorkPlanConfirmResponsible);

  if (!isResponsible) return false;

  const hasApprovals = approvals?.some((approval) => approval.is_confirmed);

  if (hasApprovals && isSection) return false;

  return isResponsible;
};
