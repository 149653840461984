import cn from "classnames";
import React, { ChangeEvent, useMemo, useRef } from "react";

import Icon from "components/UI/Icon/Icon";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import { ReactComponent as CheckBlackThin } from "images/icons/check-black-thin.svg";

import styles from "./Checkbox.module.scss";

export const enum LabelPlacement {
  LEFT = "left",
  RIGHT = "right",
}

interface IProps {
  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  children?: React.ReactNode;
  className?: string;
  labelPlacement?: LabelPlacement;
  disabled?: boolean;
}

const Checkbox: React.FC<IProps> = (props) => {
  const { onCheck, checked, children, className, labelPlacement = LabelPlacement.LEFT, disabled = false } = props;
  const ref = useRef(null);

  const generatedId = useMemo(() => {
    return "checkbox" + Math.random();
  }, []);

  const handleOnCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onCheck(e);
    }
  };

  return (
    <label ref={ref} htmlFor={generatedId} className={cn(styles.container, className)} onClick={stopEventPropagation}>
      {labelPlacement === LabelPlacement.LEFT && children}
      <input
        id={generatedId}
        type="checkbox"
        onChange={handleOnCheck}
        className={styles.checkbox}
        checked={checked}
        disabled={disabled}
      />
      <label
        className={cn(styles.borders, "checkbox", { [styles.labelRight]: labelPlacement === LabelPlacement.RIGHT })}
        htmlFor={generatedId}
      >
        {checked && <CheckBlackThin />}
      </label>
      {labelPlacement === LabelPlacement.RIGHT && children}
    </label>
  );
};

export default React.memo(Checkbox);
