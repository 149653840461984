import moment from "moment";
import React from "react";

import { IBetonIncomeDetail } from "redux/modules/common/building/beton/types";

import ModalDivider from "components/modals/atoms/ModalDivider/ModalDivider";
import StatusBlock from "components/pages/Manufacturing/components/Month/components/InfoPopup/components/StatusBlock/StatusBlock";

import styles from "./BetonHistoryItem.module.scss";

interface IProps {
  item: IBetonIncomeDetail["using"][number];
}

const BetonHistoryItem: React.FC<IProps> = ({ item }) => {
  return (
    <div className={styles.container}>
      <div className={styles.divider}>
        <ModalDivider text={`${item.count} м³`} icon="check" />
      </div>
      <div className={styles.grid}>
        <div className={styles.firstCol}>
          <div className={styles.label}>От:</div>
          <div className={styles.value}>{item.from_user}</div>
        </div>
        <div className={styles.secCol}>
          <div className={styles.label}>Дата:</div>
          <div className={styles.value}>{moment(item.created_at).format("DD.MM.YY")}</div>
        </div>
        {/*<div className={styles.thirdCol}>*/}
        {/*  <div className={styles.label}>Ось:</div>*/}
        {/*  <div className={styles.value}>КА</div>*/}
        {/*</div>*/}
        <div className={styles.firstCol}>
          <div className={styles.label}>Кому:</div>
          <div className={styles.value}>{item.to_user}</div>
        </div>
        <div className={styles.secCol}>
          <div className={styles.label}>Время:</div>
          <div className={styles.value}>{moment(item.created_at).format("HH:mm")}</div>
        </div>
        {/*<div className={styles.thirdCol}>*/}
        {/*  <div className={styles.label}>Отметка:</div>*/}
        {/*  <div className={styles.value}>01</div>*/}
        {/*</div>*/}
      </div>
      {item.work_name && <div className={styles.name}>{item.work_name}</div>}
    </div>
  );
};

export default BetonHistoryItem;
