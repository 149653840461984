import {createSelector} from 'reselect'

import {parentStateSelector} from '../constructing'
import {moduleName as parentModuleName} from './index'

const initialState = {}

export const moduleName = 'index'

export const counterpartiesStateSelector = createSelector(
  parentStateSelector,
  (state) => state[parentModuleName]
)
export const stateSelector = createSelector(
  counterpartiesStateSelector,
  (state) => state[moduleName]
)

export default (state = initialState, action) => {
  const {type} = action
  switch (type) {
    default:
      return state
  }
}
