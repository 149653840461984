import { isEqual } from "lodash";

import { RootState } from "redux/rootReducer";

export const checkIsNeedSetLoading = (
  state: RootState,
  filters: Record<string, string | number>,
  key: string
): boolean => {
  if (!state.ordersList.orders?.[key]?.orders?.length) {
    return true;
  }
  if (!isEqual(state.ordersList.orders?.[key]?.filters, filters)) {
    return true;
  }
  return false;
};
