import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { auditTableHighlightingSelector } from "redux/modules/common/constructing/finance/audit/selectors";

import valueInThousandsOrMillions from "../../../../../../../../utils/formatters/valueInThousandsOrMillions";

import styles from "./styles.module.scss";

const MoneyCell = React.memo((props) => {
  const { data, measure, isDiff, isAbs } = props;
  const tableHighlighting = useSelector(auditTableHighlightingSelector) || {};

  return (
    <div
      className={cn(styles.moneyCell__text, {
        [styles.negative]: isDiff && tableHighlighting.negative && data < 0,
        [styles.positive]: isDiff && tableHighlighting.positive && data > 0,
      })}
      title={valueInThousandsOrMillions(data, measure, isAbs)}
    >
      {valueInThousandsOrMillions(data, measure, isAbs)}
    </div>
  );
});

const renderMoneyCell = (data, measure, isDiff, isAbs) => {
  return <MoneyCell data={data} measure={measure} isDiff={isDiff} isAbs={isAbs} />;
};

export default renderMoneyCell;
