import axios from "axios";

import { IDetailedProjectForecast, IProjectViewForecast } from "./types";

import downloadFile from "utils/helpers/download";

export const forecastApi = {
  getProjectsViewForecast: () => {
    return axios.get<IProjectViewForecast[]>(`/tree/projects/finance_forecasts/`);
  },
  getDetailedProjectForecast: (objectId: number) => {
    return axios.get<IDetailedProjectForecast>(`/tree/finance_forecasts/${objectId}/`);
  },
  downloadReport: (buildingId: number, reportName: string) => {
    downloadFile(`${axios.defaults.baseURL}/buildings/${buildingId}/finances/forecast/report/`, reportName, "POST");
  },
  downloadBDR: (buildings: number[], dateStart: string, dateEnd: string, reportName: string) => {
    return downloadFile(`${axios.defaults.baseURL}/bdr/export/`, reportName, "POST", {
      start_at: dateStart,
      end_at: dateEnd,
      buildings,
    });
  },
  getDetailedProjectSummaryV2: (projectId: number) => {
    return axios.get(`/tree/projects/finance_forecasts/`, { params: { project_id: projectId } });
  },
};
