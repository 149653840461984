import cn from "classnames";
import React from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import ContentWithCount from "components/UI/atoms/ContentWithCount/ContentWithCount";
import { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";
import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";

import addSvg from "./assets/add";
import aosrSvg from "./assets/aosr";
import passSvg from "./assets/passed";
import pendingSvg from "./assets/pending";

import { ITicketInJournalDone } from "types/interfaces/Tickets";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import checkSvg from "../../../../../../../../../images/icons/blueCheckIcon";

import styles from "./JournalExecActions.module.scss";

interface IProps {
  ticket: IExpendituresInJournalFulfillment["items"][0];
  handleOpenAddRemarkModal: () => void;
  handleOpenRemarkModal: (onlyAccepted: boolean, count: number) => void;
  onAcceptTicket: () => void;
  onCreateAosr: () => void;
  isAosrDisabled: boolean;
  isShowFormationAosr: boolean;
  isShowCreateRemark: boolean;
  isShowTransferToAct: boolean;
  isHideRemarks?: boolean;
}

const JournalExecActions: React.FC<IProps> = ({
  handleOpenAddRemarkModal,
  handleOpenRemarkModal,
  ticket,
  onAcceptTicket,
  onCreateAosr,
  isAosrDisabled,
  isShowFormationAosr,
  isShowCreateRemark,
  isShowTransferToAct,
  isHideRemarks,
}) => {
  const ticketItem = ticket.expenditure ?? ticket.group ?? ticket.linked;
  return (
    <>
      {!isHideRemarks && (
        <TableReusableCell onClick={stopEventPropagation} className={cn(styles.remarks)}>
          {isShowCreateRemark && (
            <div className={styles.add} onClick={handleOpenAddRemarkModal}>
              <PopoverOverlay
                content={<div className={styles.popup}>Создать замечание</div>}
                openType="hover"
                popoverBorderColor="default"
                placement="bottom"
              >
                {addSvg}
              </PopoverOverlay>
            </div>
          )}
          <div
            className={styles.pendingRemarks}
            onClick={() => handleOpenRemarkModal(false, ticketItem?.count_remarks_in_work!)}
          >
            <PopoverOverlay
              content={
                <div className={styles.popup}>
                  {ticketItem?.count_remarks_in_work! > 0
                    ? `Замечаний к устранению: ${ticketItem?.count_remarks_in_work}`
                    : "Нет замечаний"}
                </div>
              }
              openType="hover"
              popoverBorderColor="default"
              placement="bottom"
            >
              <ContentWithCount
                count={ticketItem?.count_remarks_in_work!}
                countClassName={styles.pendingRemarksCount}
                isCountDisplayed={ticketItem?.count_remarks_in_work! > 0}
              >
                {pendingSvg}
              </ContentWithCount>
            </PopoverOverlay>
          </div>
          <div
            className={styles.pendingRemarks}
            onClick={() => handleOpenRemarkModal(true, ticketItem?.count_remarks_accepted!)}
          >
            <PopoverOverlay
              content={
                <div className={styles.popup}>
                  {ticketItem?.count_remarks_accepted! > 0
                    ? `Замечаний устранено: ${ticketItem?.count_remarks_accepted}`
                    : "Нет замечаний"}
                </div>
              }
              openType="hover"
              popoverBorderColor="default"
              placement="bottom"
            >
              <ContentWithCount
                count={ticketItem?.count_remarks_accepted!}
                isCountDisplayed={ticketItem?.count_remarks_accepted! > 0}
              >
                {passSvg}
              </ContentWithCount>
            </PopoverOverlay>
          </div>
          {(isShowFormationAosr || isShowTransferToAct) && <div className={styles.divider}></div>}
        </TableReusableCell>
      )}
      <TableReusableCell className={cn(styles.approve, { [styles.alone]: isHideRemarks })}>
        {isShowFormationAosr && (
          <div onClick={!isAosrDisabled ? onCreateAosr : undefined}>
            <PopoverOverlay
              content={<div className={styles.popup}>Сформировать Акт</div>}
              openType="hover"
              popoverBorderColor="default"
              placement="bottom"
              isDisabled={isAosrDisabled}
            >
              {aosrSvg}
            </PopoverOverlay>
          </div>
        )}
        {isShowTransferToAct && (
          <div onClick={onAcceptTicket}>
            <PopoverOverlay
              content={<div className={styles.popup}>Подтвердить</div>}
              openType="hover"
              popoverBorderColor="default"
              placement="bottom"
            >
              {checkSvg}
            </PopoverOverlay>
          </div>
        )}
      </TableReusableCell>
    </>
  );
};

export default React.memo(JournalExecActions);
