import React from 'react'
import cn from "classnames";

import MonthSlider, { IMonthSliderProps } from "./components/MonthSlider/MonthSlider";

import styles from "./MonthScrollBar.module.scss";

import {COLORS, SIZES} from 'components/UI/atoms/Select'
import YearSelect from '../../atoms/YearSelect/YearSelect'

export interface IMonthScrollBarProps extends IMonthSliderProps {
  className?: string;
  year: number;
  handleYearChange: (optionId: string | number) => void;
  selectColor?: COLORS;
  selectLabelColor?: string;
  selectDisabled?: boolean;
  selectClassName?: string;
  selectLabel?: string;
  selectMeta?: any;
  onOpenSelect?: () => void;
  onCloseSelect?: () => void;
}

const MonthScrollBar: React.FC<IMonthScrollBarProps> = ({
  className,
  year,
  handleYearChange,
  tableScrollPosition,
  tableViewWidthPercent,
  setTableScrollPosition,
  selectColor,
  selectLabelColor,
  selectDisabled,
  selectClassName,
  selectLabel,
  selectMeta,
  onOpenSelect,
  onCloseSelect,
  onArrowClick,
  ...props
}) => {
  year = +year;
  return (
    <div className={cn(styles.container, className)}>
      <YearSelect
        size={SIZES.SMALL}
        value={year}
        onChange={handleYearChange}
        color={selectColor}
        labelColor={selectLabelColor}
        disabled={selectDisabled}
        selectClassName={selectClassName}
        label={selectLabel}
        meta={selectMeta}
        onOpen={onOpenSelect}
        onClose={onCloseSelect}
      />
      <MonthSlider
        tableScrollPosition={tableScrollPosition}
        tableViewWidthPercent={tableViewWidthPercent}
        setTableScrollPosition={setTableScrollPosition}
        handleYearChange={handleYearChange}
        onArrowClick={onArrowClick}
        year={year}
        {...props}
      />
    </div>
  );
};

export default React.memo(MonthScrollBar);
