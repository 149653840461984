import moment from "moment";

import { ITab } from "../../UI/atoms/TabBar/TabBarNotLinks";

export const ANALYTICS_TABS: ITab[] = [
  {
    id: "project",
    text: "Проект",
  },
  {
    id: "resources",
    text: "Ресурсы",
    isHidden: true,
  },
];

export const ANALYTICS_SELECTING_MONTHS_OPTIONS = [
  {
    id: 1,
    name: "1 месяц",
  },
  {
    id: 3,
    name: "3 месяца",
  },
  {
    id: 6,
    name: "6 месяцев",
  },
  {
    id: 12,
    name: "12 месяцев",
  },
];
