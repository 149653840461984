import cn from "classnames";
import React, { MouseEventHandler, useCallback, useState } from "react";

import ContentWithCount from "../../../../../../../../UI/atoms/ContentWithCount/ContentWithCount";
import DropdownArrow from "../../../../../../../../UI/atoms/DropdownArrow/DropdownArrow";
import PopoverOverlay from "../../../../../../../../UI/molecules/PopoverOverlay/PopoverOverlay";
import addSvg from "../../../../../JournalDone/components/JournalExecTicket/components/Actions/assets/add";
import passSvg from "../../../../../JournalDone/components/JournalExecTicket/components/Actions/assets/passed";
import pendingSvg from "../../../../../JournalDone/components/JournalExecTicket/components/Actions/assets/pending";

import CreateRemarkModal from "../../../../../../../../modals/Journal/Remarks/CreateRemarkModal/CreateRemarkModal";
import RemarkModal from "../../../../../../../../modals/Journal/Remarks/RemarkModal/RemarkModal";
import { IJournalDeliveryRowProps } from "../../JournalDeliveryRow";

import TicketRemarks from "../../../../../../../../../images/TicketRemarks";
import ArrowDownSelect from "../../../../../../../../../images/icons/ArrowDownSelect";

import styles from "./RemarksCell.module.scss";

interface IProps {
  ticket: IJournalDeliveryRowProps["ticket"];
  isGroup: boolean;
  isShared: boolean;
}

const RemarksCell: React.FC<IProps> = ({ ticket, isGroup, isShared }) => {
  const ticketItem = ticket?.expenditure ?? ticket?.group;
  //@ts-ignore
  const expenditureId = ticket.expenditure?.id ?? ticket.group?.id;
  const [isOpenAddRemarkModal, setIsOpenAddRemarkModal] = useState(false);
  const [isOpenRemarkModal, setIsOpenRemarkModal] = useState(false);

  const [remarksPopoverIsOpen, setRemarksPopoverIsOpen] = useState(false);
  const [inModalOnlyAcceptedRemarks, setInModalOnlyAcceptedRemarks] = useState(false);

  const openAddRemarkModal: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
    setIsOpenAddRemarkModal(true);
  }, []);

  const openRemarksModal: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
    setInModalOnlyAcceptedRemarks(false);
    setIsOpenRemarkModal(true);
  }, []);

  const openAcceptedRemarksModal: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.stopPropagation();
    setInModalOnlyAcceptedRemarks(true);
    setIsOpenRemarkModal(true);
  }, []);

  const handleRemarksPopover = useCallback(
    (nextOpenState: boolean) => {
      if (ticketItem?.count_remarks_in_work === 0 && ticketItem?.count_remarks_accepted === 0) return;
      setRemarksPopoverIsOpen(nextOpenState);
    },
    [ticketItem?.count_remarks_in_work, ticketItem?.count_remarks_accepted]
  );

  const remarksPopover = (
    <div className={styles.popup}>
      <h6 className={styles.popupTitle}>Управление замечаниями</h6>
      {ticketItem?.count_remarks_in_work !== 0 && (
        <div className={styles.popupRow} onClick={openRemarksModal}>
          <span className={styles.popupRowIcon}>{pendingSvg}</span>К исполнению
          <div className={styles.popupRowEnd}>
            <span className={cn(styles.count, styles.inWorkCount)}>{ticketItem?.count_remarks_in_work}</span>
            <ArrowDownSelect rotate="-90" />
          </div>
        </div>
      )}
      {ticketItem?.count_remarks_accepted !== 0 && (
        <div className={styles.popupRow} onClick={openAcceptedRemarksModal}>
          <span className={styles.popupRowIcon}>{passSvg}</span>
          Выполнено
          <div className={styles.popupRowEnd}>
            <span className={styles.count}>{ticketItem?.count_remarks_accepted}</span>
            <ArrowDownSelect rotate="-90" />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.remarksCell} onClick={(e) => e.stopPropagation()}>
      <div className={styles.addRemark} onClick={openAddRemarkModal}>
        <PopoverOverlay
          content={<div className={styles.createPopup}>Создать замечание</div>}
          openType="hover"
          popoverBorderColor="default"
          placement="bottom"
        >
          {addSvg}
        </PopoverOverlay>
      </div>
      <div className={styles.remarksIcon}>
        <TicketRemarks />
      </div>
      <PopoverOverlay
        isOpen={remarksPopoverIsOpen}
        content={remarksPopover}
        openType="click"
        popoverBorderColor="default"
        placement="bottom"
        onInteraction={handleRemarksPopover}
      >
        <ContentWithCount
          containerClassName={styles.ticketRemarks}
          absoluteContainerClassName={styles.remarksCountContainer}
          leftCount={ticketItem?.count_remarks_in_work}
          count={ticketItem?.count_remarks_accepted}
          isCountDisplayed={ticketItem?.count_remarks_accepted > 0}
        >
          {(ticketItem?.count_remarks_in_work !== 0 || ticketItem?.count_remarks_accepted !== 0) && (
            <DropdownArrow isOpen={remarksPopoverIsOpen} />
          )}
        </ContentWithCount>
      </PopoverOverlay>
      <CreateRemarkModal
        isOpen={isOpenAddRemarkModal}
        setIsOpen={setIsOpenAddRemarkModal}
        workName={ticketItem?.name}
        expenditureId={expenditureId}
        isGroup={isGroup}
        isDelivery
        isShared={isShared}
        intervalId={ticketItem?.id}
      />
      <RemarkModal
        isOpen={isOpenRemarkModal}
        setIsOpen={setIsOpenRemarkModal}
        workName={ticketItem?.name}
        remarksCount={ticketItem?.count_remarks_accepted + ticketItem?.count_remarks_in_work}
        expenditureId={expenditureId}
        isGroup={isGroup}
        isShowOnlyAccepted={inModalOnlyAcceptedRemarks}
        isDelivery
      />
    </div>
  );
};

export default React.memo(RemarksCell);
