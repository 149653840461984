import { message } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  createSectionClosureDates as apiCreateSectionClosureDates,
  deleteClosureDate,
  getSectionClosureDates, patchSectionClosureDate
} from "../../../../redux/modules/common/building/processApi";
import { detailDataSelector } from "redux/modules/common/building/object/nowObject";

import moment from "moment/moment";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

export interface IClosureDate {
  payment_date: string;
  id: number;
  percent?: string;
  amount?: string;
  count_plans?: number;
  user_percent?: string;
  strategy: 1 | 2;
  touched?: boolean;
}

export interface IUseClosureDatesProps {
  isOpen: boolean;
  sectionId?: number;
  objectId: number | string;
  defaultClosureDates?: IClosureDate[];
  isEditing: boolean;
}

const useClosureDates = ({
  isOpen,
  objectId,
  sectionId,
  defaultClosureDates = [],
  isEditing = false,
}: IUseClosureDatesProps) => {
  const [closureDates, setClosureDates] = React.useState<IClosureDate[]>(defaultClosureDates || []);
  const isNew_close_dates = useSelector(detailDataSelector)?.new_close_dates;

  useEffect(() => {
    if (!isOpen || !sectionId || !objectId || !!defaultClosureDates?.length) return;
    getSectionClosureDates({ objectId, sectionId })
      .then(({ data }) => {
        setClosureDates(data.results);
      })
      .catch(errorCatcher);
  }, [isOpen, sectionId, objectId]);

  const addNewClosureDate = useCallback(async () => {
    let isNew = isEditing ? true : false;
    if (isNew_close_dates && !isEditing) {
      const count1 = await axios.get(`/building/${objectId}/groups/plans/`, {
        params: { section_id: sectionId, limit: 0 },
      });

      const count2 = await axios.get(`/building/${objectId}/estimate/items/sections/${sectionId}/intervals/`, {
        params: { limit: 0 },
      });
      isNew = count1.data.count + count2.data.count === 0 ? true : false;
    }

    const newStrategyLength = closureDates.filter((el) => el.strategy === 2).length;

    setClosureDates((prevState) => [
      ...prevState,
      {
        payment_date: "",
        id: Math.random(),
        user_percent: "0",
        amount: "0",
        strategy:
          isNew_close_dates && isNew && (isEditing ? (closureDates.length ? newStrategyLength : true) : true) ? 2 : 1,
      },
    ]);
  }, [isNew_close_dates]);

  const editClosureDate = useCallback(
    (id: number, payload: Partial<Omit<IClosureDate, "id">>) => {

      if (closureDates.findIndex((date) => date.payment_date === payload.payment_date) !== -1) {
        message.warn("Эта дата уже добавлена");
        return false;
      }
      setClosureDates((prevState) =>
        prevState.map((date) => {
          if (date.id === id) return { ...date, ...payload, touched: true };
          return date;
        })
      );
      return true;
    },
    [closureDates]
  );

  const removeClosureDate = useCallback((id: number) => {
    setClosureDates((prevState) => prevState.filter((date) => date.id !== id));
  }, []);

  const createSectionClosureDates = async ({ planId, withMissedPercentFilling = false }: { planId: number, withMissedPercentFilling?: boolean }) => {
    let sumPercents = closureDates.reduce((acc, d) => {
      return acc + +(d?.user_percent ?? 0)
    }, 0);

    const isCompletePercents = sumPercents === 100;

    const addCandidates = closureDates?.filter((x) => {
      if (!isNew_close_dates) {
        return defaultClosureDates?.findIndex((date) => {
          return date.payment_date === x.payment_date
        }) === -1
      } else {
       return x.id < 1;
      }
    }) || [];

    if (!isCompletePercents && withMissedPercentFilling && addCandidates.length) {
      const idx = addCandidates.length - 1;
      if (sumPercents > 100) {
        addCandidates[idx].user_percent = (+addCandidates[idx]?.user_percent - (100 - sumPercents)).toFixed(8);
      } else {
          addCandidates[idx].user_percent = (+addCandidates[idx].user_percent + (100 - sumPercents)).toFixed(8);
      }
    }

    await Promise.allSettled(
      addCandidates.map((date, idx) => {
        return apiCreateSectionClosureDates({
          objectId,
          planId,
          payment_date: date.payment_date,
          amount: date.amount,
          percent: date.user_percent,
        }).catch(errorCatcher);
      })
    );
  };

  const deleteSectionClosureDates = async () => {
    const removeCandidates =
      defaultClosureDates?.filter(
        (x) => closureDates.findIndex((date) => (date.id === x.id)) === -1
      ) || [];
    await Promise.allSettled(
      removeCandidates.map((date) => deleteClosureDate({ objectId, dateId: date.id }).catch(errorCatcher))
    );
  };

  const updateSectionClosureDates = async ({ planId }: { planId: number }) => {
    const updateCandidates = closureDates.filter(d => d.touched && d.id >= 1);
    await Promise.allSettled(updateCandidates.map((date) => patchSectionClosureDate({
      objectId,
      dateId: date.id,
      planId,
      paymentDate: date.payment_date,
      userPercent: Number(date.user_percent)?.toFixed(8)
    })));
  }

  const validateClosureDates = () => closureDates.every((date) => moment(date.payment_date)?.isValid());

  const validateClosureDatesPercents = () =>
    closureDates.filter((el) => Number(el.strategy) === 2).filter((el) => Number(el.user_percent) === 0).length === 0;

  const closureDatesOptions = useMemo(
    () =>
      closureDates.map((date) => {
        const displayDate = moment(date.payment_date).format("DD.MM.YYYY");
        return {
          id: date.id,
          name: displayDate,
          label: displayDate,
          payment_date: date.payment_date,
        };
      }),
    [closureDates]
  );

  const fillMissedPercents = (budget: string | number, missedPercent: number) => {
    const candidates = closureDates
      .filter(d => d.strategy === 2)
      .filter(d => d.touched || d.id < 1);

    if (candidates.length && missedPercent < 1) {
      const winner = candidates.at(-1);
      editClosureDate(winner.id, { user_percent: +winner.user_percent + missedPercent })
    }
  }

  return {
    closureDates,
    addNewClosureDate,
    editClosureDate,
    removeClosureDate,
    createSectionClosureDates,
    deleteSectionClosureDates,
    updateSectionClosureDates,
    validateClosureDates,
    validateClosureDatesPercents,
    closureDatesOptions,
    fillMissedPercents
  };
};

export default useClosureDates;
