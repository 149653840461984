import cn from "classnames";
import React from "react";

import FilesClipButton from "components/UI/atoms/FilesClipButton/FilesClipButton";
import StatusElement from "components/UI/atoms/StatusElement/StatusElement";
import ExpenditureFilesModal from "components/modals/ExpenditureFilesModal/ExpenditureFilesModal";
import JournalFilesModal from "components/modals/ExpenditureFilesModal/JournalFilesModal";

import { EXPENDITURE_TYPES } from "constants/constant";
import { IExpenditure } from "types/interfaces/Expenditure";

import CheckIcon from "./icons/Check";
import CrossIcon from "./icons/Cross";
import EditIcon from "./icons/Edit";

import styles from "./JournalExecFiles.module.scss";

interface IProps {
  name: string;
  expenditureId: number;
  expenditureType: IExpenditure["expenditure_type"];
  isExpired: boolean;
  count: number;
  year?: number;
  month?: number;
}

const JournalExecFiles: React.FC<IProps> = ({
  name,
  expenditureId,
  expenditureType,
  isExpired,
  count,
  year,
  month,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isWork = expenditureType === EXPENDITURE_TYPES.WORK;

  const openModalHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <div className={styles.container} onClick={openModalHandler}>
        <FilesClipButton className={styles.clip} />
        {!!count && (
          <StatusElement
            status={isExpired ? "fail" : "success"}
            text={String(count)}
            className={cn(styles.count, { [styles.expired]: isExpired })}
          />
        )}
      </div>
      {isWork ? (
        <JournalFilesModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          expenditureName={name}
          expenditureId={expenditureId}
          expenditureType={expenditureType}
          year={year!}
          month={month!}
        />
      ) : (
        <ExpenditureFilesModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          expenditureName={name}
          expenditureId={expenditureId}
          expenditureType={expenditureType}
        />
      )}
    </>
  );
};

export default JournalExecFiles;
