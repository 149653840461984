import React, { useCallback } from "react";

import { Switch } from "components/UI/atoms/Switch/Switch";

import { ChangeNotificationConfigFnType, INotificationConfig, NotificationOptionType } from "../../../types/types";

import styles from "../ManageNotificationsModal.module.scss";

export interface ISwitchedNotificationProps {
  option: INotificationConfig;
  onChangeChecked: ChangeNotificationConfigFnType;
  type: NotificationOptionType;
  canEdit: boolean;
}

const SwitchedNotification: React.FC<ISwitchedNotificationProps> = ({ option, onChangeChecked, canEdit, type }) => {
  const onChangeEmail = useCallback(
    (e) => onChangeChecked(option, type, e.target.checked),
    [onChangeChecked, option, type]
  );
  return (
    <div className={styles.switches}>
      <span>{option.verbose_name}</span>
      <Switch
        checked={!option.is_unsubscribed}
        onChange={onChangeEmail}
        disabled={!canEdit}
        className={styles.switch}
      />
    </div>
  );
};

export default React.memo(SwitchedNotification);
