import LogoSvg from "./img/logo-footer.svg";
import KubSvg from "./img/kub.svg";
import KubMobSvg from "./img/kub-mob1.svg";
import Kub2Svg from "./img/kub2.svg";
import KubMob2Svg from "./img/kub-mob2.svg";
import React, { FC, Fragment } from "react";
import InputMask from "react-input-mask";
import { LandingPagesRoutesEnum } from "../../lib/landingPagesRoutesEnum";
import { Link } from "react-router-dom";
import $ from "jquery";
import { sendLead, showSendLeadSuccess } from "../RequestModal/RequestModal";

interface IProps {
  showDemoSection?: boolean
}

export const Footer: FC<IProps> = ({showDemoSection = true}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      company: $("#form-company").val(),
      name: $("#form-name").val(),
      phone: $("#form-phone").val(),
    }

    if (data.company && data.name && data.phone) {
      sendLead(data).then(() => {
        $('#modal').addClass('out')
        setTimeout(function () {
          $('#modal').fadeOut();
          showSendLeadSuccess();
          $('#form-demo').trigger("reset");
        }, 100);
      });
    }
  }

  return (
    <Fragment>
      {showDemoSection && (
        <section className="request__main" id="demo">
          <div className="request-img__left">
            <img src={KubSvg} className="request-img__dec" alt="image" />
            <img src={KubMobSvg} className="request-img__plansh" alt="image" />
          </div>
          <div className="request__cnt">
            <h2>Запросите демонстрацию платформы</h2>
            <p>И мы свяжемся с Вами в ближайшее время!</p>
            <form onSubmit={handleSubmit} id="form-demo">
              <div className="request__form">
                <div className="request__wrap">
                  <div className="request__inp">
                    <label>
                      <input type="text" placeholder="Компания" id="form-company" />
                    </label>
                  </div>
                  <div className="request__inp">
                    <label>
                      <input type="text" placeholder="Ваше имя" id="form-name" />
                    </label>
                  </div>
                  <div className="request__inp">
                    <label>
                      <InputMask
                        className="phone"
                        mask="+7 (999) 999-99-99"
                        maskChar={null}
                        placeholder="+7 (. . .) . . . - . . - . ."
                        id="form-phone"
                      />
                    </label>
                  </div>
                </div>
                <button className="btn">Свяжитесь со мной</button>
                <p>Направляя форму, Вы даете согласие на обработку персональных данных в соответствии с <Link to={LandingPagesRoutesEnum.privacyPolicy} target="_blank">Политикой обработки персональных данных</Link></p>
              </div>
            </form>
          </div>
          <div className="request-img__right">
            <img src={Kub2Svg} className="request-img__dec" alt="image" />
            <img src={KubMob2Svg} className="request-img__plansh" alt="image" />
          </div>
        </section>
      )}

      <footer>
        <div className="container">
          <div className="footer__main">
            <div className="footer__logo">
              <Link to={LandingPagesRoutesEnum.home}>
                <img src={LogoSvg} alt="logo" />
              </Link>
              <span>Искусство оптимизации</span>
            </div>
            <div className="footer__flex">
              <div className="footer__info">
                <p>ООО «Осла»</p>
                <p>© Все права защищены 2023</p>
              </div>
              <div className="footer__contact">
                <a href="tel: + 7 (812) 701-00-05">+ 7 (812) 701-00-05</a>
                <a href="mailto: info@osla.ru">info@osla.ru</a>
              </div>
              <div className="footer__data">
                <p>г. Санкт-Петербург, Каменноостровский пр., д. 25</p>
              </div>
              <div className="footer__politic">
                <Link to={LandingPagesRoutesEnum.privacyPolicy} target="_blank">Политика обработки персональных данных</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

