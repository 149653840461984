import React from "react";
import { useDispatch } from "react-redux";

import { detailDataSelector, getDetail, setDetailNull } from "redux/modules/common/building/object/nowObject";
import { useTypedSelector } from "redux/typedUseSelector";

export const useBuildingDetailDataById = (buildingId: string, isImmediatelyClearing?: boolean) => {
  const dispatch = useDispatch();
  const buildingData = useTypedSelector(detailDataSelector);

  React.useEffect(() => {
    if (isNaN(+buildingId) || !buildingId) return;
    if (buildingData?.id === +buildingId) return;
    dispatch(getDetail(buildingId));
    return () => {
      if (isImmediatelyClearing) {
        dispatch(setDetailNull());
      }
    };
  }, [buildingId, buildingData?.id]);

  return buildingData;
};
