import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { setTaskFilters } from "../../../../redux/modules/purchaser/Tasks/actions";
import { tasksFiltersSelector } from "../../../../redux/modules/purchaser/Tasks/selectors";
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types";

import { taskFilterStatuses } from "../constants";

export const useTasksFilter = () => {
  const dispatch = useDispatch();

  const filters = useSelector(tasksFiltersSelector);

  const setFilters = useCallback((newTaskFilters: tasksListFilterType) => {
    compose(dispatch, setTaskFilters)(newTaskFilters);
  }, []);

  useEffect(() => {
    return () => setFilters({});
  }, []);

  const filtersHandler = React.useCallback(
    (name: keyof tasksListFilterType, value: tasksListFilterType[typeof name]) => {
      const newFilters = Object.assign({}, filters);
      if (!value || (typeof value === "string" && !value.length)) {
        delete newFilters[name];
        setFilters(newFilters);
      } else {
        setFilters({ ...newFilters, [name]: value });
      }
    },
    [filters, setFilters]
  );

  const dateRangeHandler = React.useCallback(
    (dateString: moment.Moment) => {
      setFilters({
        ...filters,
        deadline_start: moment(dateString).format("YYYY-MM-DD"),
        deadline_end: moment(dateString).add(1, "months").format("YYYY-MM-DD"),
      });
    },
    [filters, setFilters]
  );

  const changeSectionHandler = React.useCallback(
    (id: number) => {
      setFilters({
        ...filters,
        section_id: String(id),
      });
    },
    [filters, setFilters]
  );

  return {
    filters,
    filtersHandler,
    dateRangeHandler,
    changeSectionHandler,
  };
};
