import React, { useCallback, useState } from "react";
import { compose } from "redux";
import cn from "classnames";

import ThreeDotsMenuIcon from "../../../../images/icons/ThreeDotsMenuIcon";
import styles from "./ThreeDotsDropdown.module.scss";

const ThreeDotsDropdown = ({ items, hoverTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleIsOpen = useCallback(() => setIsOpen((prevState) => !prevState), []);
  const close = useCallback(() => setIsOpen(false), []);

  return (
    <div className={cn(styles.container, { [styles.open]: isOpen })}>
      <div className={styles.handler} onClick={handleIsOpen}>
        <ThreeDotsMenuIcon title={hoverTitle} />
      </div>
      {isOpen && (
        <>
          <div className={styles.hider} />
          <div className={styles.items}>
            {items.map((item) => (
              <div className={styles.item} onClick={compose(item.action, close)} key={item.name}>
                <div className={styles.icon}>
                  <img src={item.icon} alt={item.name} />
                </div>
                <div className={styles.name}>{item.name}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ThreeDotsDropdown);
