import { message } from "antd";
import React from "react";

import { serializePlanMaterialsInEditPlan } from "components/pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/utils";

import { IProductInWorkGroup } from "../../../../../types/interfaces/Works";
import { IMaterialInPlanInterval, ISerializedMaterialInPlan } from "types/interfaces/Materials";

const materialCountValidation = (material: ISerializedMaterialInPlan) => {
  if (!!(material as any).estimate_expenditure?.estimate_count) {
    return material?.local_count && +material.local_count > +(material as any).estimate_expenditure?.estimate_count;
  }
  return (
    material?.local_count &&
    ((material.sum_using_count_to_work && +material.local_count > +material.sum_using_count_to_work) ||
      (material.count && !material.available_count && +material.local_count > +material.count) ||
      (material.available_count && +material.local_count > +material.available_count))
  );
};

export const useSelectingMaterials = (
  defaultMaterials?: IMaterialInPlanInterval[] | IProductInWorkGroup[],
  isExistingPlan?: boolean,
  isExpendituresGroup?: boolean
) => {
  const [addedMaterials, setAddedMaterials] = React.useState<ISerializedMaterialInPlan[]>([]);
  const [selectedMaterials, setSelectedMaterials] = React.useState<ISerializedMaterialInPlan[]>([]);
  const [deletedMaterials, setDeletedMaterials] = React.useState<ISerializedMaterialInPlan[]>([]);

  const clearMaterials = () => {
    setAddedMaterials([]);
    setSelectedMaterials([]);
    setDeletedMaterials([]);
  };

  React.useEffect(() => {
    // фильтрация доступных к добавлению в факт материалов (при создании факта)
    const filteredMaterials = defaultMaterials?.filter((m) => +m.available_count !== 0);

    if (!filteredMaterials?.length) return;
    if (!!filteredMaterials?.[0]?.material) {
      setAddedMaterials(
        filteredMaterials.map((el) => ({
          id: el.material.id,
          count: el.count,
          name: el.material.name,
          measure: el.material.measure,
          available_count: el.material.estimate_expenditure?.estimate_count ?? el.available_count,
          local_count: el.count,
        }))
      );
    } else if (isExpendituresGroup) {
      setAddedMaterials(
        filteredMaterials.map((el) => ({
          ...el,
          local_count: el.local_count ?? el.count,
        }))
      );
    } else {
      setAddedMaterials(filteredMaterials.filter((x) => deletedMaterials.findIndex((y) => y.id === x.id) === -1));
      setDeletedMaterials(filteredMaterials.filter((x) => deletedMaterials.findIndex((y) => y.id === x.id) !== -1));
    }
    return () => {
      clearMaterials();
    };
  }, [defaultMaterials]);

  const addedMaterialsIds = React.useMemo(() => {
    return addedMaterials.map((el) => el.id);
  }, [addedMaterials]);

  const selectedMaterialsIds = React.useMemo(() => {
    return selectedMaterials.map((el) => el.id);
  }, [selectedMaterials]);

  const confirmMaterialsHandler: () => boolean = () => {
    if (selectedMaterials?.some((item) => !item.local_count || (item.local_count && +item.local_count <= 0))) {
      message.error("Необходимо указать количество материала");
      return false;
    }

    if (selectedMaterials?.some(materialCountValidation)) {
      message.error("Кол-во материала не должно превышать значение по проекту");
      return false;
    }
    setAddedMaterials((prev) => [...prev, ...selectedMaterials]);
    setDeletedMaterials((prevState) =>
      prevState.filter((x) => selectedMaterials.findIndex((y) => y.id === x.id) === -1)
    );
    setSelectedMaterials([]);
    return true;
  };

  const cancelMaterialsHandler: () => boolean = () => {
    setSelectedMaterials([]);
    return true;
  };

  const changeCountMaterialsHandler = (id: number, count: string | number, type: "selected" | "added") => {
    const mapper = (arr: any[]) =>
      arr.map((el) => {
        if (el.id === id) {
          return { ...el, local_count: count };
        } else {
          return el;
        }
      });
    if (type === "selected") {
      setSelectedMaterials((prev) => mapper(prev));
    } else {
      setAddedMaterials((prev) => mapper(prev));
    }
  };

  const validateSubmittingMaterials = () => {
    if (addedMaterials.some((el) => !el.local_count && !el.count)) {
      message.error("Укажите кол-во каждому выбранному материалу");
      return false;
    }
    if (addedMaterials.some(materialCountValidation)) {
      message.error("Кол-во материала не должно превышать значение по проекту");
      return false;
    }
    if (!!selectedMaterials.length) {
      message.warn("Подтвердите добавленные материалы");
      return false;
    }
    return true;
  };

  const deleteAddedHandler = (id: number) => {
    const candidate = addedMaterials.find((x) => x.id === id);
    if (!candidate) return;
    setDeletedMaterials((prevState) => prevState.concat([candidate]));
    setAddedMaterials((prevState) => prevState.filter((el) => el.id !== id));
  };

  const selectMaterialsHandler = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    if (e.target.checked) {
      setSelectedMaterials((prev) => [...prev, item]);
    } else {
      setSelectedMaterials((prev) => prev.filter((el) => el.id !== item.id));
    }
  };

  const setPresettedMaterials = (presetedMaterials: Array<IMaterialInPlanInterval | ISerializedMaterialInPlan>) => {
    if (!presetedMaterials?.length) return;
    setAddedMaterials(
      isExistingPlan
        ? serializePlanMaterialsInEditPlan(presetedMaterials as IMaterialInPlanInterval[])
        : (presetedMaterials as ISerializedMaterialInPlan[])
    );
  };

  return {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    deletedMaterials,
    clearMaterials,
    setPresettedMaterials,
  };
};
