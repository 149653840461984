import axios from "axios";

import {
  IAcceptGroupTicketToActingData,
  IAcceptTicketToActingData,
  ICreateDeliveryRemarkData,
  ICreateRemarkData,
  IDetailRemarkInfo,
  IEditRemarkData,
  IGroupTicketActingInfo,
  IRemarkBySection,
  IRemarksList,
  IResolveRemarkData,
  IReturnRemarkData,
  ISectionInJournalExec, //@ts-ignore
  ISectionInJournalFulfillment,
  ISectionWithRemarks,
  ISectionsFilterJournalExec,
  ITicketActingInfo,
} from "./types";
import { IList } from "types/ListResponse";
import { IGroupInJournalDone, ITicketInJournalDone } from "types/interfaces/Tickets";

import { queryParamsFormatter } from "utils/formatters/queryParamsFormatter";

export const journalExecutionApi = {
  getSectionsWithRemarks: (buildingId: string, filters: ISectionsFilterJournalExec) => {
    return axios.get<IList<ISectionWithRemarks>>(`/building/${buildingId}/remarks/sections/`, {
      params: { ...filters, limit: 500 },
    });
  },
  getGroups: (
    buildingId: string,
    year: number,
    month: number,
    section_id: number,
    filters: ISectionsFilterJournalExec
  ) => {
    const params = {
      ...filters,
      year,
      month,
      section_id,
      limit: 500,
    };
    return axios.get<IList<IGroupInJournalDone>>(`/building/${buildingId}/groups/journal/done/`, { params });
  },
  getRemarks: (buildingId: string, section: number, filters: Record<string, string>) => {
    const params = {
      ...filters,
      section,
      limit: 500,
    };
    return axios.get<IList<IRemarkBySection>>(`/building/${buildingId}/remarks/`, { params });
  },
  createRemark: (buildingId: string, data: ICreateRemarkData) => {
    return axios.post(`/building/${buildingId}/remarks/`, data);
  },
  getDeliveryRemarksList: (
    buildingId: string,
    expenditureId: number,
    isShowOnlyAccepted: boolean,
    isGroup?: boolean
  ) => {
    const param1 = !isGroup
      ? new URLSearchParams({
          work_interval_id: String(expenditureId),
          limit: "1000",
          status: isShowOnlyAccepted ? "accepted" : "created",
        })
      : new URLSearchParams({
          group_interval_id: String(expenditureId),
          limit: "1000",
          status: isShowOnlyAccepted ? "accepted" : "created",
        });
    const param2 = isShowOnlyAccepted ? {} : { status: "review" };
    const combinedParams = queryParamsFormatter(param1, param2);
    return axios.get<IList<IRemarksList>>(`/building/${buildingId}/ticket_remarks/`, {
      params: combinedParams,
    });
  },
  getRemarksList: (buildingId: string, expenditureId: number, isShowOnlyAccepted: boolean, isGroup?: boolean) => {
    const param1 = !isGroup
      ? new URLSearchParams({
          estimate_expenditure_id: String(expenditureId),
          limit: "1000",
          status: isShowOnlyAccepted ? "accepted" : "created",
        })
      : new URLSearchParams({
          estimate_group_id: String(expenditureId),
          limit: "1000",
          status: isShowOnlyAccepted ? "accepted" : "created",
        });
    const param2 = isShowOnlyAccepted ? {} : { status: "review" };
    const combinedParams = queryParamsFormatter(param1, param2);
    return axios.get<IList<IRemarksList>>(`/building/${buildingId}/remarks`, {
      params: combinedParams,
    });
  },
  getDetailRemarkInfo: (buildingId: string, remarkId: number) => {
    return axios.get<IDetailRemarkInfo>(`/building/${buildingId}/remarks/${remarkId}/`);
  },
  getDetailDeliveryRemarkInfo: (buildingId: string, remarkId: number) => {
    return axios.get<IDetailRemarkInfo>(`/building/${buildingId}/ticket_remarks/${remarkId}/`);
  },
  editRemark: (buildingId: string, remarkId: number, data: IEditRemarkData) => {
    return axios.put<IDetailRemarkInfo>(`/building/${buildingId}/remarks/${remarkId}/`, data);
  },
  editDeliveryRemark: (buildingId: string, remarkId: number, data: IEditRemarkData) => {
    return axios.put<IDetailRemarkInfo>(`/building/${buildingId}/ticket_remarks/${remarkId}/`, data);
  },
  deleteRemark: (buildingId: string, remarkId: number) => {
    return axios.delete(`/building/${buildingId}/remarks/${remarkId}/`);
  },
  deleteDeliveryRemark: (buildingId: string, remarkId: number) => {
    return axios.delete(`/building/${buildingId}/ticket_remarks/${remarkId}/`);
  },
  resolveRemark: (buildingId: string, remarkId: number, data: IResolveRemarkData) => {
    return axios.post(`/building/${buildingId}/remarks/${remarkId}/resolve/`, data);
  },
  resolveDeliveryRemark: (buildingId: string, remarkId: number, data: IResolveRemarkData) => {
    return axios.post(`/building/${buildingId}/ticket_remarks/${remarkId}/resolve/`, data);
  },
  returnRemark: (buildingId: string, remarkId: number, data: IReturnRemarkData) => {
    return axios.post(`/building/${buildingId}/remarks/${remarkId}/return/`, data);
  },
  returnDeliveryRemark: (buildingId: string, remarkId: number, data: IReturnRemarkData) => {
    return axios.post(`/building/${buildingId}/ticket_remarks/${remarkId}/return/`, data);
  },
  acceptRemark: (buildingId: string, remarkId: number) => {
    return axios.post(`/building/${buildingId}/remarks/${remarkId}/accept/`);
  },
  getTicketActingInfo: (buildingId: string, expenditureId: number, year: number, month: number) => {
    const config = {
      params: {
        year,
        month,
      },
    };

    return axios.get<ITicketActingInfo>(
      `/building/${buildingId}/estimate/acts/${expenditureId}/collect_volume/`,
      config
    );
  },
  getGroupTicketActingInfo: (buildingId: string, groupId: number, year: number, month: number) => {
    return axios.get<IGroupTicketActingInfo>(`/building/${buildingId}/estimate/groups/${groupId}/collect_volume/`, {
      params: { year, month },
    });
  },
  acceptToActing: (
    buildingId: string,
    expenditureId: number,
    year: number,
    month: number,
    data: IAcceptTicketToActingData
  ) => {
    return axios.post(`/building/${buildingId}/estimate/acts/${expenditureId}/collect_volume/`, {
      year,
      month,
      ...data,
    });
  },
  acceptGroupTicketToActing: (buildingId: string, groupId: number, data: IAcceptGroupTicketToActingData) => {
    return axios.post(`/building/${buildingId}/estimate/groups/${groupId}/collect_volume/`, data);
  },
  createDeliveryRemark: (buildingId: string, data: ICreateDeliveryRemarkData) => {
    return axios.post(`/building/${buildingId}/ticket_remarks/`, data);
  },
  acceptDeliveryRemark: (buildingId: string, remarkId: number) => {
    return axios.post(`/building/${buildingId}/ticket_remarks/${remarkId}/accept/`);
  },
  //////////////////////////////////////////////////////////////////////////////////// V2
  getSectionsV2: (buildungId: string, year: number, month: number) => {
    return axios.get<ISectionInJournalFulfillment[]>(`/journal/${buildungId}/fulfillment/sections/`, {
      params: { year, month, limit: 500 },
    });
  },
  getExpendituresV2: (buildingId: string, sectionId: number, year: number, month: number) => {
    return axios.get(`/journal/${buildingId}/fulfillment/sections/${sectionId}/`, {
      params: { year, month, limit: 500 },
    });
  },
  acceptMaterialV2: (buildingId: string) => {
    return axios.post(`/journal/${buildingId}/fulfillment/materials/confirm/`);
  },
  changeAmountV2: (buildingId: string) => {
    return axios.post(`/journal/${buildingId}/fulfillment/materials/change_amount/`);
  },
  getGroup: (buildingId: string, groupId: number, year: number, month: number) => {
    return axios.get(`/journal/${buildingId}/fulfillment/group/${groupId}/`, { params: { year, month } });
  },
  getContentOfExpenditure: (buildingId: string, expenditureId: number, year: number, month: number) => {
    return axios.get(`/journal/${buildingId}/fulfillment/expenditure/${expenditureId}/`, { params: { year, month } });
  },
};
