import axios from "axios";

import {
  ICheckingKs2IntersectingResponse,
  ICreateKs2,
  ICreateKs3,
  IEstimateInActing,
  IExpenditureInActing,
  IKs2InActingList,
  ISectionInActing,
} from "./types";
import { IList } from "types/ListResponse";

import downloadFile from "utils/helpers/download";

export const actingApi = {
  getLsrs: (buildingId: string) => {
    return axios.get<IList<IEstimateInActing>>(`/building/${buildingId}/estimate/acts/ks2/sections/`, {
      params: { limit: 500 },
    });
  },
  getSections: (buildingId: string, lsrId: number) => {
    return axios.get<IList<ISectionInActing>>(
      `/building/${buildingId}/estimate/acts/ks2/sections/${lsrId}/subsections/`,
      {
        params: { limit: 500 },
      }
    );
  },
  getExpenditures: (buildingId: string, sectionId: number) => {
    return axios.get<IList<IExpenditureInActing>>(
      `/building/${buildingId}/estimate/acts/ks2/sections/${sectionId}/items/`,
      {
        params: { limit: 500 },
      }
    );
  },
  getKs2List: (buildingId: string, estimateId?: number) => {
    return axios.get<IList<IKs2InActingList>>(`/building/${buildingId}/estimate/acts/ks2/`, {
      params: { limit: 500, parent_section: estimateId },
    });
  },
  createKs2: (buildingId: string, data: ICreateKs2) => {
    return axios.post(`/building/${buildingId}/estimate/acts/ks2/`, data, { params: { save: 1 } });
  },
  checkKs2Intersecting: (buildingId: string, data: ICreateKs2) => {
    return axios.post<ICheckingKs2IntersectingResponse>(`/building/${buildingId}/estimate/acts/ks2/preview/`, data);
  },
  getdetailedKs2: (buildingId: string, ks2Id: number) => {
    return axios.get(`/building/${buildingId}/estimate/acts/ks2/${ks2Id}/`);
  },
  createKs3: (buildingId: string, data: ICreateKs3) => {
    return axios.post(`/building/${buildingId}/estimate/acts/ks3/`, data);
  },
  editKs2: (buildingId: string, ks2Id: number, data: Omit<ICreateKs2, "section_ids" | "expenditure_ids">) => {
    return axios.patch(`/building/${buildingId}/estimate/acts/ks2/${ks2Id}/`, data);
  },
  editKs3: (buildingId: string, ks3Id: number, data: Omit<ICreateKs2, "section_ids" | "expenditure_ids">) => {
    return axios.patch(`/building/${buildingId}/estimate/acts/ks3/${ks3Id}/`, data);
  },
  deleteExpenditureFromKs2: (buildingId: string, ks2Id: number, expenditureId: number) => {
    return axios.delete(`/building/${buildingId}/estimate/acts/ks2/${ks2Id}/remove_expenditure/${expenditureId}/`);
  },
  deleteExpenditureFromKs3: (buildingId: string, ks3Id: number, expenditureId: number) => {
    return axios.delete(`/building/${buildingId}/estimate/acts/ks3/${ks3Id}/remove_ks2/${expenditureId}/`);
  },
  getDetailedKs3: (buildingId: string, ks3Id: number) => {
    return axios.get(`/building/${buildingId}/estimate/acts/ks3/${ks3Id}/`);
  },
  getKs2Report: (buildingId: string, ks2Id: number, ks2Name?: string) => {
    downloadFile(
      `${axios.defaults.baseURL}/building/${buildingId}/estimate/acts/ks2/${ks2Id}/report/`,
      ks2Name || "ks2.xlsx",
      "POST"
    );
  },
  getKs3Report: (buildingId: string, ks3Id: number, ks3Name?: string) => {
    downloadFile(
      `${axios.defaults.baseURL}/building/${buildingId}/estimate/acts/ks3/${ks3Id}/report/`,
      ks3Name || "ks3.xlsx",
      "POST"
    );
  },
  createKsExport: (ksId: number, ksParamNumber: "2" | "3", buildingId: string) => {
    return axios.post(`/export/`, {
      [`act_ks${ksParamNumber}`]: ksId,
      doc_type: `ks${ksParamNumber}_set`,
      building_id: buildingId,
    });
  },
};
