import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "redux";

import { useLoadUIIndicators } from "../../../../hooks/uiIndicators/useLoadUIIndicators";
import Calendar from "../../../pages/Calendar";
import ConstructingCounterparties from "../../../pages/Constructing/Counterparties";
import TemplatedOrder from "../../../pages/Order/TemplatedOrder";
import OrdersList from "../../../pages/OrdersList/OrdersList";
import PaymentsList from "../../../pages/PaymentsList";
import TemplatedRequisition from "../../../pages/Requisition/TemplatedRequisition";
import RequisitionsList from "../../../pages/RequisitionsList/RequisitionsList";
import SuppliesList from "../../../pages/SuppliesList";
import { ProfilePage } from "components/UI/molecules/Header/components/Profile/components/ProfilePage/ProfilePage";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";
import Manufacturing from "components/pages/Manufacturing/Manufacturing";
import ServiceAct from "components/pages/ServiceAct/ServiceAct";

import DocumentsRoutes from "../DocumentsRoutes/DocumentsRoutes";
import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";

import { VIEW_FILE_STORAGE, VIEW_SETTINGS_SECTION } from "../../../../constants/permissions/generalPermissions";
import {
  ORDERS_SECTION_VIEW,
  ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  ORDER_FILES_ATTACH,
  ORDER_FILES_DELETE,
  ORDER_PRODUCT_FILE_ATTACH,
  ORDER_PRODUCT_FILE_DELETE,
  VIEW_ADD_ADDITIONAL_PRODUCTS,
  VIEW_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTACT,
  VIEW_ADD_CONTRACT_FILES,
  VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_ADD_REQUISITION_APPROVERS,
  VIEW_ADD_REQUISITION_PRODUCTS_COMMENTS,
  VIEW_ADD_REQUISITION_PRODUCTS_FILES,
  VIEW_ADD_REQUISITION_VIEWERS,
  VIEW_ADD_SUPPLIERS_CONTRACTS,
  VIEW_ADD_SUPPLIERS_CONTRACTS_FILES,
  VIEW_ASSIGN_REQUISITION_EXECUTOR,
  VIEW_BILL,
  VIEW_BILLS_LIST,
  VIEW_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID,
  VIEW_CONTACTS_LIST,
  VIEW_CONTRACTS_LIST,
  VIEW_COUNTERPARTIES_SECTION,
  VIEW_CREATE_REQUISITION,
  VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER,
  VIEW_DELETE_BILL_FILES,
  VIEW_DELETE_CONTACTS,
  VIEW_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACT_FILES,
  VIEW_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DELETE_REQUISITION_APPROVERS,
  VIEW_DELETE_REQUISITION_PRODUCTS_FILES,
  VIEW_DELETE_REQUISITION_VIEWERS,
  VIEW_DELETE_SUPPLIERS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES,
  VIEW_DOCUMENTS_SECTION,
  VIEW_EDIT_CONTACT,
  VIEW_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_EDIT_SUPPLIER_MANAGER,
  VIEW_EXPORT_LIST,
  VIEW_INVITES_LIST,
  VIEW_INVITES_LIST_ACTIONS,
  VIEW_INVITE_SUPPLIER,
  VIEW_ORDER_INVOICE_DIFFERENCE,
  VIEW_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PACKING_LIST_PAGE,
  VIEW_PROCESS_ORDERS,
  VIEW_PROCESS_PAYMENTS,
  VIEW_PROCESS_REQUISITION,
  VIEW_PRODUCTS_REQUISITION_COMPLETE,
  VIEW_PURCHASES_CHART,
  VIEW_REQUISITION,
  VIEW_REQUISITIONS_LIST,
  VIEW_REQUISITION_IN_PROCESSING_MODE,
  VIEW_REQUISITION_LOGS,
  VIEW_REQUISITION_PRODUCTS_COMMENTS,
  VIEW_REQUISITION_REASSIGN,
  VIEW_REQUISITION_TAKE_TO_WORK,
  VIEW_REQUISITION_UN_APPROVE,
  VIEW_SUPPLIERS_LIST,
} from "../../../../constants/permissions/purchasesPermissions";

import usePermission from "../../../../hooks/usePermission";
import { useLastObjectId } from "../../../../utils/hooks/uselastObjectId";
import { usePermissionsObject } from "features/permissions/hooks/usePermissionsObject";

const PurchasesRoutes = ({ match }) => {
  const dispatch = useDispatch();

  useLastObjectId();
  useLoadUIIndicators();

  const haveViewRequisitionsListPermission = usePermission(VIEW_REQUISITIONS_LIST);
  const haveViewRequisitionPermission = usePermission(VIEW_REQUISITION);
  const haveViewProcessRequisitionPermission = usePermission(VIEW_PROCESS_REQUISITION);
  const haveViewProcessOrdersPermission = usePermission(VIEW_PROCESS_ORDERS);
  const haveViewProcessPaymentsPermission = usePermission(VIEW_PROCESS_PAYMENTS);
  const haveViewOrderListPermission = usePermission(ORDERS_SECTION_VIEW);
  const haveViewOrderInvoiceDifferencePermission = usePermission(VIEW_ORDER_INVOICE_DIFFERENCE);
  const haveViewAddOrderRequestsFilesPermission = usePermission(ORDER_PRODUCT_FILE_ATTACH);
  const haveViewDeleteOrderRequestsFilesPermission = usePermission(ORDER_PRODUCT_FILE_DELETE);
  const haveViewDocumentsSectionPermission = usePermission(VIEW_DOCUMENTS_SECTION);
  const haveViewCounterpartiesSectionPermission = usePermission(VIEW_COUNTERPARTIES_SECTION);
  const haveViewFileStoragePermission = usePermission(VIEW_FILE_STORAGE);
  const haveViewSettingsSectionPermission = usePermission(VIEW_SETTINGS_SECTION);
  const haveViewPurchasesChartPermission = usePermission(VIEW_PURCHASES_CHART);

  const getAblePurchasesUrl = () => {
    if (haveViewPurchasesChartPermission) return `${match.url}/manufacturing/`;
    if (haveViewRequisitionsListPermission) return `${match.url}/requisitions`;
    if (haveViewOrderListPermission) return `${match.url}/orders`;
    if (haveViewDocumentsSectionPermission) return `${match.url}/documents`;
    if (haveViewCounterpartiesSectionPermission) return `${match.url}/counterparties`;
  };

  const requisitionsListPermissions = usePermissionsObject({
    viewCreateRequisition: VIEW_CREATE_REQUISITION,
  });

  const canViewRequisition = useMemo(() => {
    const haveOnceOfProcessPermissions =
      haveViewProcessRequisitionPermission || haveViewProcessOrdersPermission || haveViewProcessPaymentsPermission;

    return haveViewRequisitionPermission && haveOnceOfProcessPermissions;
  }, []);

  const requisitionPermissions = usePermissionsObject({
    viewProcessRequisition: haveViewProcessRequisitionPermission,
    viewProcessOrders: haveViewProcessOrdersPermission,
    viewProcessPayments: haveViewProcessPaymentsPermission,
    viewAddOutOfEstimateProducts: VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS,
    viewAddAdditionalProducts: VIEW_ADD_ADDITIONAL_PRODUCTS,
    viewEditRequisitionBeforeApprove: VIEW_EDIT_REQUISITION_BEFORE_APPROVE,
    viewAssignRequisitionExecutor: VIEW_ASSIGN_REQUISITION_EXECUTOR,
    viewRequisitionTakeToWork: VIEW_REQUISITION_TAKE_TO_WORK,
    viewRequisitionReassign: VIEW_REQUISITION_REASSIGN,
    viewAddRequisitionApprovers: VIEW_ADD_REQUISITION_APPROVERS,
    viewAddRequisitionViewers: VIEW_ADD_REQUISITION_VIEWERS,
    viewDeleteRequisitionApprovers: VIEW_DELETE_REQUISITION_APPROVERS,
    viewDeleteRequisitionViewers: VIEW_DELETE_REQUISITION_VIEWERS,
    viewRequisitionUnApprove: VIEW_REQUISITION_UN_APPROVE,
    viewRequisitionLogs: VIEW_REQUISITION_LOGS,
    viewOrderInvoiceDifference: haveViewOrderInvoiceDifferencePermission,
    viewAddOrderRequestsFiles: haveViewAddOrderRequestsFilesPermission,
    viewDeleteOrderRequestsFiles: haveViewDeleteOrderRequestsFilesPermission,
    viewRequisitionInProcessingMode: VIEW_REQUISITION_IN_PROCESSING_MODE,
    viewDeleteRequisitionProductsFiles: VIEW_DELETE_REQUISITION_PRODUCTS_FILES,
    viewAddRequisitionProductsFiles: VIEW_ADD_REQUISITION_PRODUCTS_FILES,
    viewRequisitionProductsComments: VIEW_REQUISITION_PRODUCTS_COMMENTS,
    viewAddRequisitionProductsComments: VIEW_ADD_REQUISITION_PRODUCTS_COMMENTS,
    viewCreateRequisitionProductsProvider: VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER,
    viewCompleteRequisition: VIEW_PRODUCTS_REQUISITION_COMPLETE,
  });

  const orderPermissions = usePermissionsObject({
    viewOrderInvoiceDifference: haveViewOrderInvoiceDifferencePermission,
    autoTransitionToPayment: ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
    addFiles: ORDER_FILES_ATTACH,
    deleteFiles: ORDER_FILES_DELETE,
    viewAddOrderRequestsFiles: haveViewAddOrderRequestsFilesPermission,
    viewDeleteOrderRequestsFiles: haveViewDeleteOrderRequestsFilesPermission,
  });

  const counterpartiesPermissions = usePermissionsObject({
    viewContactsList: VIEW_CONTACTS_LIST,
    viewAddContact: VIEW_ADD_CONTACT,
    viewDeleteContacts: VIEW_DELETE_CONTACTS,
    viewEditContact: VIEW_EDIT_CONTACT,
    viewSuppliersList: VIEW_SUPPLIERS_LIST,
    viewInviteSupplier: VIEW_INVITE_SUPPLIER,
    viewDeleteSuppliers: VIEW_DELETE_SUPPLIERS,
    viewAddSuppliersContracts: VIEW_ADD_SUPPLIERS_CONTRACTS,
    viewDeleteSuppliersContract: VIEW_DELETE_SUPPLIERS_CONTRACTS,
    viewEditSupplierManager: VIEW_EDIT_SUPPLIER_MANAGER,
    viewAddSuppliersContractFiles: VIEW_ADD_SUPPLIERS_CONTRACTS_FILES,
    viewDeleteSuppliersContractFiles: VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES,
    viewInvitesList: VIEW_INVITES_LIST,
    viewInvitesListActions: VIEW_INVITES_LIST_ACTIONS,
  });

  const documentsPermissions = usePermissionsObject({
    viewBillsList: VIEW_BILLS_LIST,
    viewPackingLists: VIEW_PACKING_LISTS,
    viewBill: VIEW_BILL,
    viewPackingListPage: VIEW_PACKING_LIST_PAGE,
    viewPackingListsVatCalculation: VIEW_PACKING_LISTS_VAT_CALCULATION,
    viewContractsList: VIEW_CONTRACTS_LIST,
    viewDeleteContract: VIEW_DELETE_CONTRACT,
    viewAddContractFiles: VIEW_ADD_CONTRACT_FILES,
    viewDeleteContractFiles: VIEW_DELETE_CONTRACT_FILES,
    viewExportList: VIEW_EXPORT_LIST,
    viewDeleteExports: VIEW_DELETE_EXPORTS,
  });

  const billPermissions = usePermissionsObject({
    viewBillInEdit: VIEW_BILL_IN_EDIT,
    addFiles: VIEW_ADD_BILL_FILES,
    deleteFiles: VIEW_DELETE_BILL_FILES,
    viewAddBillPaymentsFile: VIEW_ADD_BILL_PAYMENTS_FILE,
    viewBillPaymentsMakePaid: VIEW_BILL_PAYMENTS_MAKE_PAID,
  });

  const packingListPermissions = usePermissionsObject({
    viewPackingListAccept: VIEW_PACKING_LIST_ACCEPT,
    addFiles: VIEW_ADD_PACKING_LIST_FILES,
    deleteFiles: VIEW_DELETE_PACKING_LIST_FILES,
  });

  return (
    <Switch>
      <Route exact path={match.path} render={() => <Redirect replace to={getAblePurchasesUrl()} />} />
      {haveViewPurchasesChartPermission && (
        <Route
          exact
          path={[`${match.path}/manufacturing/`, `${match.path}/manufacturing/:objectId`]}
          component={Manufacturing}
        />
      )}
      <Route
        exact
        path={`${match.path}/requisitions/:activeTab/:requisitionId`}
        render={(props) =>
          canViewRequisition ? (
            <TemplatedRequisition {...props} permissions={requisitionPermissions} />
          ) : (
            <ForbiddenPage />
          )
        }
      />
      {haveViewRequisitionsListPermission && (
        <Route
          path={`${match.path}/requisitions`}
          render={(props) => <RequisitionsList {...props} permissions={requisitionsListPermissions} />}
        />
      )}
      {haveViewOrderListPermission && <Route exact path={`${match.path}/orders`} component={OrdersList} />}
      {haveViewOrderListPermission && (
        <Route
          exact
          path={`${match.path}/orders/:orderId`}
          render={(props) => <TemplatedOrder {...props} permissions={orderPermissions} />}
        />
      )}
      {haveViewDocumentsSectionPermission && (
        <Route
          path={`${match.path}/documents`}
          render={(props) => (
            <DocumentsRoutes
              {...props}
              documentsPermissions={documentsPermissions}
              billPermissions={billPermissions}
              packingListPermissions={packingListPermissions}
            />
          )}
        />
      )}
      {haveViewCounterpartiesSectionPermission && (
        <Route
          path={`${match.path}/counterparties`}
          render={(props) => <ConstructingCounterparties {...props} permissions={counterpartiesPermissions} />}
        />
      )}
      <Route exact path={`${match.path}/events/payments`} component={PaymentsList} />
      <Route exact path={`${match.path}/documents/:actId`} component={ServiceAct} />
      <Route path={`${match.path}/supplies`} component={SuppliesList} />
      <Route exact path={`${match.path}/profile`} component={ProfilePage} />
      <Route exact path={`${match.path}/events/calendar`} component={Calendar} />
      <Route exact path={`${match.path}/events/supplies`} component={SuppliesList} />
      {haveViewFileStoragePermission && (
        <Route exact path={`${match.path}/files/:objectId/`} component={FilesStorage} />
      )}
      {haveViewSettingsSectionPermission && <Route path={`${match.path}/settings`} component={SettingsRoutes} />}
      <Route component={ForbiddenPage} />
    </Switch>
  );
};

export default React.memo(PurchasesRoutes);
