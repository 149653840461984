import moment from "moment";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { betonActions } from "./betonActions";
import { betonApi } from "./betonApi";

import { IBetonToProduction, IEditBetonExpenseRequest } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getBetonIncome =
  (stockId: number, objectId: string, filter: Record<string, string | number>) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().betonReducer.income[stockId]) {
      dispatch(betonActions.setIncomeLoading(objectId, true));
    }
    betonApi
      .getIncome(stockId, filter)
      .then(({ data }) => {
        dispatch(betonActions.setIncome(stockId, data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(betonActions.setIncomeLoading(objectId, false));
      });
  };

export const sendBetonToProduction =
  (stockId: number, betonId: number, values: IBetonToProduction) => (dispatch: Dispatch) => {
    betonApi
      .toProduction(stockId, betonId, values)
      .then(() => {
        dispatch(betonActions.invalidateKey());
      })
      .catch(errorCatcher);
  };

export const getBetonExpense =
  (stockId: number, objectId: string, filter: Record<string, string | number>) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().betonReducer.expense[stockId]) {
      dispatch(betonActions.setIncomeLoading(objectId, true));
    }
    betonApi
      .getExpense(stockId, filter)
      .then(({ data }) => {
        dispatch(betonActions.setExpense(stockId, data));
      })
      .finally(() => {
        dispatch(betonActions.setIncomeLoading(objectId, false));
      });
  };

export const getBetonExpenseDetail =
  (stockId: number, betonId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().betonReducer.expenseDetail[betonId]) {
      dispatch(betonActions.setIncomeLoading(generateStorageKey({ betonId, stockId }), true));
    }
    betonApi
      .getExpenseDetail(stockId, betonId)
      .then(({ data }) => {
        dispatch(betonActions.setExpenseDetail(betonId, data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(betonActions.setIncomeLoading(generateStorageKey({ betonId, stockId }), false));
      });
  };

export const addBetonExpenseComment = (stockId: number, betonId: number, message: string) => (dispatch: Dispatch) => {
  dispatch(betonActions.setIsPending(betonId, true));
  betonApi
    .addComment(stockId, betonId, message)
    .then(() => {
      dispatch(betonActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(betonActions.setIsPending(betonId, false));
    });
};

export const editBetonExpense =
  (stockId: number, betonId: number, data: IEditBetonExpenseRequest) => (dispatch: Dispatch) => {
    dispatch(betonActions.setIsPending(betonId, true));
    betonApi
      .editBetonExpense(stockId, betonId, data)
      .then(() => {
        dispatch(betonActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(betonActions.setIsPending(betonId, false));
      });
  };

export const getBetonIncomeDetail =
  (stockId: number, betonId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().betonReducer.incomeDetail[betonId]) {
      dispatch(betonActions.setIncomeLoading(generateStorageKey({ betonId, stockId }), true));
    }
    betonApi
      .getIncomeDetail(stockId, betonId)
      .then(({ data }) => {
        dispatch(betonActions.setIncomeDetail(betonId, data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(betonActions.setIncomeLoading(generateStorageKey({ betonId, stockId }), false));
      });
  };

export const addBetonIncomeComment = (stockId: number, betonId: number, message: string) => (dispatch: Dispatch) => {
  dispatch(betonActions.setIsPending(betonId, true));
  betonApi
    .addIncomeComment(stockId, betonId, message)
    .then(() => {
      dispatch(betonActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(betonActions.setIsPending(betonId, false));
    });
};

export const editBetonIncomeTime =
  (stockId: number, betonId: number, hours: string, minutes: string) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const date = getState().betonReducer.incomeDetail[betonId]?.received_at;
    if (!date) return;
    dispatch(betonActions.setIsPending(betonId, true));
    const newDate = moment(date)
      .set("hours", +hours)
      .set("minutes", +minutes)
      .toISOString();
    betonApi
      .editIncome(stockId, betonId, { received_at: newDate })
      .then(() => {
        dispatch(betonActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(betonActions.setIsPending(betonId, false));
      });
  };
