import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.betonReducer;

export const betonIncomeSelector = createSelector(stateSelector, (state) => state.income);
export const betonIncomeLoadingSelector = createSelector(stateSelector, (state) => state.incomeIsLoading);
export const betonInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const betonExpenseSelector = createSelector(stateSelector, (state) => state.expense);
export const betonExpenseDetailSelector = createSelector(stateSelector, (state) => state.expenseDetail);
export const betonIncomeDetailSelector = createSelector(stateSelector, (state) => state.incomeDetail);
export const betonIsPendingSelector = createSelector(stateSelector, (state) => state.isPending);
