import React from 'react'
import { IEmployee } from 'types/personsTypes'
import { filterMentions } from './utils'

export const useSendChatMessage = (
    mentions: IEmployee[],
    setMentions: React.Dispatch<React.SetStateAction<IEmployee[]>>,
    inputRef: React.RefObject<HTMLTextAreaElement>,
    submitHandler: (mentions: number[]) => void,
    isVisible: boolean
) => {
    const submitMessageHandler = React.useCallback(() => {
        if (!inputRef.current) return
        const mentionedIds = filterMentions(inputRef.current.value, mentions)
        submitHandler(mentionedIds)
        setMentions([])
    }, [mentions, submitHandler, setMentions])

    const chatInputKeyHandler = React.useCallback((e: KeyboardEvent) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            if (!inputRef.current) return
            const mentionedIds = filterMentions(inputRef.current.value, mentions)
            submitHandler(mentionedIds)
            setMentions([])
        }
    }, [mentions, submitHandler, setMentions])

    React.useEffect(() => {
        if (isVisible) {
            inputRef.current?.addEventListener("keydown", chatInputKeyHandler)
        }
        return () => {
            inputRef.current?.removeEventListener("keydown", chatInputKeyHandler)
        }
    }, [isVisible, mentions, submitHandler, setMentions])

    return {
        submitMessageHandler
    }
}
