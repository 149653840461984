import axios from "axios";
import cn from "classnames";
import React, { Component } from "react";
import { Field, Form } from "react-final-form";
import validator from "validator";

import Button from "../../../UI/atoms/ButtonBase";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

import styles from "./AuthTabs.module.scss";

class PasswordRecovery extends Component {
  state = {
    sent: false,
    error: false,
  };

  submitHandler = async (formData) => {
    await axios
      .post("/auth/password/reset/", formData)
      .then(() => this.setState({ sent: true }))
      .catch(errorCatcher);
  };

  validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Заполните поле";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Неправильный формат почты";
    }

    return errors;
  };

  render() {
    return (
      <div>
        {!this.state.sent ? (
          <Form
            onSubmit={this.submitHandler}
            validate={this.validate}
            render={({ handleSubmit, submitting }) => (
              <form className="authorization__form password-recovery" onSubmit={handleSubmit}>
                <div className="authorization__controls">
                  <Field name="email">
                    {({ input, meta }) => (
                      <div className="authorization__form-item">
                        <label className="authorization__label">
                          <span className="authorization__input-icon authorization__input-icon_mail" />
                          <input
                            placeholder="Введите Ваш E-mail"
                            {...input}
                            className={cn("authorization__input form__input", styles.input)}
                          />
                        </label>
                        {(meta.error || meta.submitError) && (meta.touched || meta.submitError) && (
                          <span className="help-text error is-visible">{meta.error || meta.submitError}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="authorization__buttons">
                  <Button primary type={"submit"} className={cn("btn-main", styles.submit)} disabled={submitting}>
                    Отправить
                  </Button>
                  <Button secondary onClick={this.props.forgotPasswordClick} type={"cancel"}>
                    Отменить
                  </Button>
                </div>
              </form>
            )}
          />
        ) : (
          <div className="authorization__confirm-panel">
            <div className="authorization__text">Мы отправили новый пароль на Вашу почту!</div>
            <div className="authorization__buttons">
              <button onClick={this.props.forgotPasswordClick} className="authorization__btn">
                Ок!
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PasswordRecovery;
