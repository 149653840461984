import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.planFactVolume;

export const planFactVolumeSectionsSelector = createSelector(stateSelector, (state) => state.sections);

export const planFactVolumeExpendituresSelector = createSelector(stateSelector, (state) => state.expenditures);

export const planFactVolumeSectionsLoadingSelector = createSelector(stateSelector, (state) => state.sectionsIsLoading);

export const planFactVolumeExpendituresLoadingSelector = createSelector(
  stateSelector,
  (state) => state.expendituresIsLoading
);

export const planFactVolumeIsAllExpandedSelector = createSelector(stateSelector, (state) => state.isAllExpanded);

export const planFactVolumeDaysCountSelector = createSelector(stateSelector, (state) => state.daysCount);
