import axios from "axios";

import { IActsListFilter } from "./types";
import { IFile } from "types/interfaces/Files";
import { IServiceAct, IServiceActsList } from "types/interfaces/ServiceAct";

export const serviceActsApi = {
  getActsList: (params: IActsListFilter) => {
    return axios.get<IServiceActsList>(`/service-acts/`, { params });
  },
  getAct: (actId: number) => {
    return axios.get<IServiceAct>(`/service-acts/${actId}/`);
  },
  changeServiceActNumber: (number: string, actId: string) => {
    return axios.post<IServiceAct>(`/service-acts/${actId}/set-number/`, { number });
  },
  changeAct: (actId: string, data: Partial<IServiceAct>) => {
    return axios.patch<IServiceAct>(`/service-acts/${actId}/`, data);
  },
  acceptAct: (actId: string, data: any) => {
    return axios.post<IServiceAct>(`/service-acts/${actId}/accept/`, data);
  },
  getActFiles: (actId: string) => {
    return axios.get(`/service-acts/${actId}/files/`, { params: { limit: 100 } });
  },
  postActFiles: (actId: string, files: IFile[]) => {
    const formData = new FormData();
    files.forEach((el) => formData.append("files", el));
    return axios.post(`/service-acts/${actId}/files/`, formData);
  },
  deleteActFile: (actId: string, fileId: number) => {
    return axios.delete(`/service-acts/${actId}/files/${fileId}/`);
  },
};
