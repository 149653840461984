import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  arrowHashSelector,
  diagramFiltersSelector,
  diagramIntervalArrowsSelector,
  highlightedArrowsSelector,
  highlightedIntervalsSelector,
  isBeingDraggedDiagramIntervalSelector,
} from "../../../../../../../../../redux/modules/common/building/manufacturing/selectors";
import {
  addIntervalLink,
  startDragInterval,
  stopDragInterval,
} from "../../../../../../../../../redux/modules/common/building/manufacturing/thunks";

import { relationType } from "react-xarrows";

import { DiagramFilters, RELATION_TYPES } from "../../../../../../constants";

export interface IDraggedInterval {
  isBeingDragged: boolean;
  projectId: number;
  startDate: string;
  endDate: string;
  origin: createIntervalOriginType;
  isGroup?: boolean;
}

export interface IDiagramIntervalLink {
  from_interval: number | null;
  from_group: number | null;
  to_interval: number;
  to_group: number | null;
  id: number;
  delay_day: number | null;
  related_type: relationType;
}

export interface IUseIntervalLinksProps {
  intervalWrapperRef?: React.RefObject<HTMLDivElement> | undefined;
  toIntervalId?: number | undefined;
  projectId?: number | null;
  startDate?: string;
  endDate?: string;
  onAddArrowCallback?: () => void;
  isToIntervalSection?: boolean;
  isToIntervalGroup?: boolean;
}

export interface IArrows {
  [key: number]: IDiagramIntervalLink[];
}

export type createIntervalOriginType = "start" | "end";

export const useIntervalLinks = ({
  intervalWrapperRef,
  toIntervalId,
  projectId,
  startDate,
  endDate,
  onAddArrowCallback,
  isToIntervalSection = false,
  isToIntervalGroup = false,
}: IUseIntervalLinksProps) => {
  const dispatch = useDispatch();
  const arrowHash = useSelector(arrowHashSelector);

  const [position, setPosition] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });
  const arrows: IArrows = useSelector(diagramIntervalArrowsSelector);
  const beingDraggedArrows: IDraggedInterval[] = useSelector(isBeingDraggedDiagramIntervalSelector);

  const projectArrows = useMemo(() => (projectId && arrows[projectId]) || [], [projectId, arrows]);

  const isSomeArrowBeingDragged = useMemo(
    () => Object.values(beingDraggedArrows).some((x) => x.isBeingDragged),
    [beingDraggedArrows]
  );

  const draggedIntervalEntry = useMemo(
    () => Object.entries(beingDraggedArrows).find(([_, { isBeingDragged }]) => isBeingDragged),
    [beingDraggedArrows]
  );

  const draggedIntervalId: number | undefined = draggedIntervalEntry && +draggedIntervalEntry[0];

  const diagramFilters = useSelector(diagramFiltersSelector);
  const isLinkingEnabled = diagramFilters[DiagramFilters.linking_editing_enabled];
  const isLinkingVisible = diagramFilters[DiagramFilters.linking_enabled];

  const addArrow = useCallback(
    (
      {
        from_interval,
        from_group,
        to_interval,
        to_group,
        related_type,
      }: Pick<IDiagramIntervalLink, "from_interval" | "from_group" | "to_interval" | "to_group" | "related_type">,
      successCallback?: () => void
    ) => {
      if ((!from_interval && !from_group) || (!to_interval && !to_group) || !projectId) return;

      const arrowCandidate = {
        from_interval,
        from_group,
        to_group,
        to_interval,
        related_type,
      };

      if (
        projectArrows.findIndex(
          (x) =>
            x.from_interval === arrowCandidate.from_interval &&
            x.to_interval === arrowCandidate.to_interval &&
            x.related_type === arrowCandidate.related_type
        ) === -1
      ) {
        dispatch(addIntervalLink(projectId, arrowCandidate, successCallback));
      }
    },
    [projectArrows, projectId]
  );

  const handleDragging = useCallback(
    (e: MouseEvent | DragEvent) => {
      const rect = intervalWrapperRef?.current?.getBoundingClientRect();
      setPosition({
        x: e.clientX - 10 - (rect?.left || 0),
        y: e.clientY - 10 - (rect?.top || 0),
      });
    },
    [intervalWrapperRef?.current]
  );

  const handleDragEnd = useCallback(() => {
    if (!draggedIntervalId || !isSomeArrowBeingDragged) return;
    dispatch(stopDragInterval({ projectId, intervalId: draggedIntervalId }));
    setPosition({ x: undefined, y: undefined });
  }, [draggedIntervalEntry, projectId, isSomeArrowBeingDragged]);

  useEffect(() => {
    if (!draggedIntervalId || !toIntervalId || draggedIntervalId !== toIntervalId) return;
    window.addEventListener("mousemove", handleDragging);
    window.addEventListener("mouseup", handleDragEnd);
    return () => {
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("mousemove", handleDragging);
      handleDragEnd();
    };
  }, [draggedIntervalId, handleDragging, handleDragEnd, toIntervalId]);

  const handleDragStart = useCallback(
    (e: MouseEvent, origin: createIntervalOriginType) => {
      if (!toIntervalId) return;
      handleDragging(e);
      dispatch(
        startDragInterval({
          projectId,
          intervalId: toIntervalId,
          startDate,
          endDate,
          origin,
          isGroup: isToIntervalGroup,
        })
      );
    },
    [toIntervalId, projectId, startDate, endDate, handleDragging, isToIntervalGroup]
  );

  const handleCreateIntervalLink = useCallback(
    (targetStartOrEnd: "start" | "end") => {
      if (
        !draggedIntervalEntry ||
        !draggedIntervalId ||
        !toIntervalId ||
        draggedIntervalId === toIntervalId ||
        draggedIntervalEntry[1]?.projectId !== projectId ||
        isToIntervalSection
      ) {
        return;
      }

      const isFromIntervalGroup = !!draggedIntervalEntry?.[1]?.isGroup;

      const arrow = {
        from_interval: isFromIntervalGroup ? null : draggedIntervalId,
        from_group: isFromIntervalGroup ? draggedIntervalId : null,
        to_interval: isToIntervalGroup ? null : toIntervalId,
        to_group: isToIntervalGroup ? toIntervalId : null,
      };

      const sourceStartMoment = moment(draggedIntervalEntry[1]?.startDate);
      const sourceEndMoment = moment(draggedIntervalEntry[1]?.endDate);
      const targetStartMoment = moment(startDate);
      const targetEndMoment = moment(endDate);
      const origin = draggedIntervalEntry[1]?.origin;

      if (targetStartMoment.isAfter(sourceEndMoment) && targetStartOrEnd === "start" && origin === "end") {
        //OH-связь "Начнётся после окончания текущей"
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.oh }, onAddArrowCallback);
      } else if (
        targetStartMoment.isSameOrAfter(sourceStartMoment) &&
        targetStartOrEnd === "start" &&
        origin === "start"
      ) {
        //HH-связь "Начнётся после начала текущей"
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.hh }, onAddArrowCallback);
      } else if (targetEndMoment.isSameOrAfter(sourceEndMoment) && targetStartOrEnd === "end" && origin === "end") {
        //OO-связь "Окончится до окончания текущей"
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.oo }, onAddArrowCallback);
      } else if (targetEndMoment.isAfter(sourceStartMoment) && targetStartOrEnd === "end" && origin === "start") {
        //HO-связь "Окончится после начала текущей"
        addArrow?.({ ...arrow, related_type: RELATION_TYPES.ho }, onAddArrowCallback);
      }
      handleDragEnd();
    },
    [
      addArrow,
      draggedIntervalEntry,
      draggedIntervalId,
      handleDragEnd,
      toIntervalId,
      projectId,
      onAddArrowCallback,
      isToIntervalSection,
      isToIntervalGroup,
    ]
  );

  const handleIntervalClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!isSomeArrowBeingDragged) return;
      if (toIntervalId === draggedIntervalId) {
        handleDragEnd();
        return;
      }
      const targetBound = (e.currentTarget as Element)?.getBoundingClientRect();

      const targetStartOrEnd =
        Math.abs(e.clientX - targetBound?.left) <= Math.abs(e.clientX - targetBound?.right) ? "start" : "end";
      handleCreateIntervalLink(targetStartOrEnd);
    },
    [handleCreateIntervalLink, isSomeArrowBeingDragged, toIntervalId, draggedIntervalId]
  );

  const arrowsStartsWithCurrentIntervalId = useMemo(
    () =>
      projectArrows.filter((x) => {
        return isToIntervalGroup ? x.from_group === toIntervalId : x.from_interval === toIntervalId;
      }),
    [projectArrows, toIntervalId, isToIntervalGroup]
  );

  const highlightedIntervals: number[] = useSelector(highlightedIntervalsSelector);
  const highlightedArrows: number[] = useSelector(highlightedArrowsSelector);
  const isIntervalHighlighted =
    isLinkingEnabled &&
    highlightedIntervals?.length > 0 &&
    !!toIntervalId &&
    highlightedIntervals.indexOf(toIntervalId) !== -1;

  return {
    arrows,
    projectArrows,
    isLinkingEnabled,
    isLinkingVisible,
    beingDraggedArrows,
    isSomeArrowBeingDragged,
    handleIntervalClick,
    position,
    handleDragging,
    handleDragStart,
    handleCreateIntervalLink,
    arrowsStartsWithCurrentIntervalId,
    draggedIntervalEntry,
    isIntervalHighlighted,
    highlightedArrows,
    arrowHash,
  };
};
