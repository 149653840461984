import React, { useMemo, useState } from "react";

import NotificationsInModal from "./components/NotificationsInModal";
import SliderModal from "components/UI/atoms/SliderModal";

import TabBarNotLinks, { ITab } from "../../../../../../atoms/TabBar/TabBarNotLinks";

import { ChangeNotificationConfigFnType, INotificationsToModify, NotificationOptionType } from "../../types/types";

import styles from "./ManageNotificationsModal.module.scss";

export interface IManageNotificationsModalProps {
  isOpen: boolean;
  onClose: () => void;
  canEdit: boolean;
  notificationsToModify: INotificationsToModify;
  onChangeChecked: ChangeNotificationConfigFnType;
}

const ManageNotificationsTabs: ITab[] = [
  {
    id: "email",
    text: "Email",
  },
  {
    id: "web",
    text: "Web",
  },
];

const ManageNotificationsModal: React.FC<IManageNotificationsModalProps> = ({
  isOpen,
  onClose,
  notificationsToModify,
  canEdit,
  onChangeChecked,
}) => {
  const [activeTab, setActiveTab] = useState<NotificationOptionType>("email");

  const options = useMemo(() => notificationsToModify[activeTab] || [], [activeTab, notificationsToModify]);

  if (!isOpen) return null;

  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} childrenContainerClassName={styles.modalChildren}>
      <header className={styles.header}>
        <h2 className={styles.title}>Управление уведомлениями</h2>
        <p className={styles.subtitle}>Выберите тип уведомления в списке ниже</p>
      </header>
      <TabBarNotLinks
        tabs={ManageNotificationsTabs}
        activeId={activeTab}
        onClick={setActiveTab}
        className={styles.tabs}
        isOnlyTabsView
      />
      <NotificationsInModal options={options} onChangeChecked={onChangeChecked} canEdit={canEdit} type={activeTab} />
      <div className={styles.footer} />
    </SliderModal>
  );
};

export default React.memo(ManageNotificationsModal);
