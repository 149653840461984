import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { socketAddHandler, socketRemoveHandler } from "../../redux/modules/common/chatV2/socketEventsV2";
import { uiIndicatorsActions } from "../../redux/modules/common/uiIndicators/actions";
import { loadUIIndicators } from "../../redux/modules/common/uiIndicators/thunks";
import { IUIIndicatorResponse } from "../../redux/modules/common/uiIndicators/types";

import { MODULES_ENUM } from "../../types/enums/ModulesEnum";
import { IRouterParamsWithObjectId } from "../../types/routerTypes";

import { useUrlModule } from "../../utils/hooks/useUrlModule";

export const useLoadUIIndicators = () => {
  const dispatch = useDispatch();
  const urlModule = useUrlModule();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const newIndicatorHandler = useCallback((newIndicator: IUIIndicatorResponse) => {
    dispatch(uiIndicatorsActions.pushIndicator(newIndicator));
    dispatch(uiIndicatorsActions.invalidateKey());
  }, []);

  useEffect(() => {
    socketAddHandler(`indicators_${urlModule}`, newIndicatorHandler);
    dispatch(loadUIIndicators({ module: urlModule as MODULES_ENUM, buildingId: +objectId || undefined }));

    return () => {
      socketRemoveHandler(`indicators_${urlModule}`);
    };
  }, [urlModule, objectId]);
};
