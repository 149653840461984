//@ts-nocheck
import React, { useRef, useState } from "react";
import cn from "classnames";

import Line from "images/icons/Line";
import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import useEscapeHandler from "utils/hooks/useEscapeHandler";
import useOnClickOutside from "hooks/useOnClickOutside";

import styles from "./index.module.scss";

interface Iprops {
  children?: React.ReactNode,
  selectedOption?: unknown,
  titleClassName?: string,
  placeholder?: string,
  disabled?: boolean,
  selectClassName?: string,
  selectClassNameOpen?: string,
  optionsBlockClassName?: string,
  rotate?: unknown,
  lastEl?: boolean
}

const SelectMenu: React.FC<Iprops> = ({
  children,
  selectedOption,
  titleClassName,
  placeholder = "Выберите",
  disabled = false,
  selectClassName,
  selectClassNameOpen,
  optionsBlockClassName,
  rotate,
  lastEl,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const setToggle = () => setIsOpen(!isOpen);

  const getLabel = (selectedOption, placeholder) => {
    if (selectedOption && (typeof selectedOption === "string" || typeof selectedOption === "number")) {
      return selectedOption;
    } else {
      return selectedOption ? selectedOption.label : placeholder;
    }
  };

  useEscapeHandler(() => setIsOpen(false));
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div
      className={cn(styles.select, selectClassName, {
        [styles.isOpen]: isOpen && !disabled,
        [styles.disabled]: disabled,
        [selectClassNameOpen]: isOpen,
      })}
      onClick={setToggle}
      ref={ref}
    >
      <span className={cn(styles.title, titleClassName)}>{getLabel(selectedOption, placeholder)}</span>
      {children ? (
        <div style={{ marginRight: "1.25rem" }}>
          {rotate ? <ArrowSmallUpGrey rotate={isOpen ? 0 : 180} /> : <ArrowSmallUpGrey rotate={isOpen ? 180 : 90} />}
        </div>
      ) : (
        ""
      )}
      {lastEl ? "" : <Line width={"0.125rem"} height={"2.313rem"} />}
      {!disabled && children ? (
        <div
          className={cn(styles.optionsBlock, {
            [styles.isOpen]: isOpen,
            [optionsBlockClassName]: isOpen,
          })}
        >
          {children}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(SelectMenu);
