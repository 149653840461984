import { IForecastAction } from "./actions";

import { IForecastInitialState } from "./types";

const initialState: IForecastInitialState = {
  isLoading: false,
  allProjectsForecastsList: [],
  detailedProjectForecasts: {},
  tree: [],
  isLoadingByObjects: {},
  detailedProjectSummaryV2: {},
  totalsByProjectsV2: {},
};

export default (state = initialState, action: IForecastAction) => {
  const { type, payload } = action;
  switch (type) {
    case "constructing/finance/forecast_SET_LOADING":
      return {
        ...state,
        isLoading: payload,
      };
    case "constructing/finance/forecast_CLEAR_DATA":
      return {
        ...initialState,
      };
    case "constructing/finance/forecast_SET_ALL_PROJECTS_FORECASTS":
      return {
        ...state,
        allProjectsForecastsList: payload,
      };
    case "constructing/finance/forecast_SET_DETAILED_PROJECT_FORECAST":
      return {
        ...state,
        detailedProjectForecasts: { ...state.detailedProjectForecasts, [payload.objectId]: payload.forecast },
      };
    case "constructing/finance/forecast_SET_FORECAST_TREE":
      return {
        ...state,
        tree: payload,
      };
    case "constructing/finance/forecast_SET_IS_LOADING_BY_OBJECT":
      return {
        ...state,
        isLoadingByObjects: {
          ...state.isLoadingByObjects,
          [payload.objectId]: payload.status,
        },
      };
    case "constructing/finance/forecast_SET_DEATILED_BY_PROJECT_V2":
      return {
        ...state,
        detailedProjectSummaryV2: {
          ...state.detailedProjectSummaryV2,
          [payload.projectId]: payload.data,
        },
      };
    case "constructing/finance/forecast_setProjectsV2Totals":
      return {
        ...state,
        totalsByProjectsV2: {
          ...state.totalsByProjectsV2,
          [payload.projectId]: payload.data,
        },
      };
    default:
      return state;
  }
};
