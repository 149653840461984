import cn from "classnames";
import React, { Fragment } from "react";

import Material from "../Material/Material";
import Plan from "../Plan/Plan";
import Work from "../Work/Work";

import styles from "../../Month.module.scss";

const Branch = ({
  branch,
  offsetLeft = 0,
  objectId,
  isShownAsSection,
  isShownAsExpenditure,
  isShownSectionPlan,
  isShownSuperSectionPlan,
}) => {
  return (
    <div className={cn(styles.branch, "calendar_branch")} style={{ top: `${branch?.indexInTree * 3}rem` }}>
      {branch.days.map((item, branchIndex) => {
        return (
          <Fragment key={branchIndex}>
            {!isShownAsSection &&
              !isShownAsExpenditure &&
              item.plan?.isSectionPlan &&
              ((isShownSectionPlan && !!item.plan?.data?.parent_id) ||
                (isShownSuperSectionPlan && !item.plan?.data?.parent_id)) && (
                <Plan
                  isSection
                  branch={{ ...item, plan: { ...item.plan, type: "full" } }}
                  offsetLeft={offsetLeft}
                  objectId={objectId}
                  isSectionPlan
                />
              )}
            {isShownAsSection && (
              <Fragment>
                {item.plan && (
                  <Plan
                    isSection
                    branch={item}
                    offsetLeft={offsetLeft}
                    objectId={objectId}
                    isSectionPlan={item.plan.isSectionPlan}
                  />
                )}
                {item.work && <Work isSection branch={item} offsetLeft={offsetLeft} objectId={objectId} />}
                {item.material && <Material isSection branch={item} offsetLeft={offsetLeft} objectId={objectId} />}
              </Fragment>
            )}
            {isShownAsExpenditure && (
              <Fragment>
                {item.plan && <Plan branch={item} offsetLeft={offsetLeft} objectId={objectId} />}
                {item.work && <Work branch={item} offsetLeft={offsetLeft} objectId={objectId} />}
                {item.material && <Material branch={item} offsetLeft={offsetLeft} objectId={objectId} />}
              </Fragment>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(Branch);
