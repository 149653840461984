import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import { ITaskComment } from "redux/modules/purchaser/Tasks/types";

import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import AddButton from "components/UI/atoms/AddButton/AddButton";
import MessageIcon from "images/icons/MessageIcon";

import { useTaskComments } from "../../hooks/useTaskComments";

import TaskOneComment from "./TaskOneComment";
import styles from "./TaskComments.module.scss";
import cn from "classnames";

export interface ITaskComments extends ReturnType<typeof useTaskComments> {
  comments: ITaskComment[];
  testId?: string;
  className?: string;
  isDisplayEmptyPlaceholder?: boolean;
}

function TaskComments({
  comments,
  createNewTaskCommentHandler,
  writeTaskCommentHandler,
  commentText,
  textInputRef,
  testId,
  className,
  isDisplayEmptyPlaceholder = true,
}: ITaskComments) {
  if (!comments) return null;

  return (
    <div className={cn(styles.comments, className)} data-testid={testId}>
      <span className={styles.title}>Комментарии: {comments.length}</span>
      <TextareaAutosize
        value={commentText}
        onChange={writeTaskCommentHandler}
        placeholder="Введите текст комментария"
        ref={textInputRef}
        minRows={3}
      />
      <AddButton text="Добавить комментарий" onClick={createNewTaskCommentHandler} className={styles.addButton} />
      <div className={styles.list}>
        {comments.map((el) => (
          <TaskOneComment key={el.id} date={el.date} text={el.text} creator_name={el.creator_name} />
        ))}
      </div>
      {comments.length === 0 && isDisplayEmptyPlaceholder && (
        <EmptyPlaceholder text="Нет комментариев" svgJsx={<MessageIcon />} className={styles.emptyPlaceholder} />
      )}
    </div>
  );
}

export default React.memo(TaskComments);
