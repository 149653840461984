import cn from "classnames";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  analyticsEventsActiveTabSelector,
  analyticsEventsFiltersSelector,
  analyticsInvalidateKeySelector,
  analyticsScopeSelector,
} from "../../../../../redux/modules/common/constructing/analytics/selectors";
import { loadAnalyticsEvents } from "../../../../../redux/modules/common/constructing/analytics/thunks";
import { useTypedSelector } from "../../../../../redux/typedUseSelector";

import TasksModal from "../../../Tasks/components/TasksModal/TasksModal";
import AnalyticsEventsBody from "./components/AnalyticsEventsBody/AnalyticsEventsBody";
import AnalyticsEventsHeader from "./components/AnalyticsEventsHeader/AnalyticsEventsHeader";

import Island, { IIslandElement } from "../Island/Island";
import { useAnalyticsEventsActions } from "./useAnalyticsEventsActions";

import commonStyles from "../../Analytics.module.scss";
import styles from "./AnalyticsEvents.module.scss";

const AnalyticsEvents: React.FC<IIslandElement> = ({ islandParentRef }) => {
  const dispatch = useDispatch();
  const invalidateKey = useTypedSelector(analyticsInvalidateKeySelector);
  const tab = useTypedSelector(analyticsEventsActiveTabSelector);
  const filters = useTypedSelector(analyticsEventsFiltersSelector);
  const selectedScope = useTypedSelector(analyticsScopeSelector);

  const { openTask, addToRequisition } = useAnalyticsEventsActions();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(loadAnalyticsEvents(abortController.signal));
    return () => {
      abortController.abort();
    };
  }, [invalidateKey, tab, filters]);

  return (
    <Island
      islandParentRef={islandParentRef}
      heading={<h3 className={commonStyles.islandHeading}>События</h3>}
      className={styles.eventsIsland}
      headingClassName={styles.eventsIslandHeading}
    >
      <AnalyticsEventsHeader />
      <AnalyticsEventsBody openTask={openTask} addToRequisition={addToRequisition} />
      <TasksModal presetBuildingId={!!selectedScope.project ? +selectedScope.project : 0} />
    </Island>
  );
};

export default React.memo(AnalyticsEvents);
