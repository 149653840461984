import { message } from "antd";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isAbleToCreateProjectSelector } from "../../../../../../redux/modules/common/auth";

import ButtonBack from "components/UI/atoms/ButtonBack/ButtonBack";
import ButtonBase from "components/UI/atoms/ButtonBase";

import { useLocationArray } from "utils/hooks/useLocationArray";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import styles from "./EditProjectHeadline.module.scss";

function EditProjectHeadline({
  name = "Создание проекта",
  isDirty,
  submit,
  isNew,
  resetForm,
  isPending,
  type = "objects",
}) {
  const fullUrlModule = useUrlModuleWithObjectId();
  const isAbleToCreateProject = useSelector(isAbleToCreateProjectSelector);
  const { projectId } = useParams();

  const locationArray = useLocationArray();

  const linkForBack = useMemo(() => {
    if (type === "objects") {
      if (locationArray.includes("settings")) {
        return `/${fullUrlModule}/settings/objects/${projectId}/`;
      }

      return `/${fullUrlModule}/projects/objects-list/${projectId}`;
    }
    if (locationArray.includes("settings")) {
      return `/${fullUrlModule}/settings/projects`;
    }

    return `/${fullUrlModule}/projects`;
  }, [fullUrlModule, locationArray, type, projectId]);

  const handleSaveButtonClick = React.useCallback(() => {
    if (isNew && !isAbleToCreateProject) {
      message.error("Вы достигли максимального кол-ва проектов");
      return;
    }
    submit();
  }, [submit, isNew, isAbleToCreateProject]);

  return (
    <div className={styles.headline}>
      <ButtonBack link={linkForBack} />
      <h1>{name}</h1>
      <div className={styles.btns}>
        {isDirty && !isNew && (
          <ButtonBase buttonPrimaryEmpty className={styles.cancel} onClick={resetForm}>
            Отменить
          </ButtonBase>
        )}
        {isDirty && (
          <ButtonBase data-name="save_button" onClick={handleSaveButtonClick} isLoading={isPending}>
            Сохранить
          </ButtonBase>
        )}
      </div>
    </div>
  );
}

export default EditProjectHeadline;
