import React, { FC } from "react";

import styles from "../../EstimateSection.module.scss";

enum ESTIMATE_STATES_TRANSCRIPTS {
  locale = "Локальный сметный расчет",
  object = "Объектный сметный расчет",
  consolidate = "Сводный сметный расчет",
}

enum ESTIMATE_STATES_SHORTS {
  locale = "ЛСР",
  object = "ОСР",
  consolidate = "ССР",
}

interface IEstimateStateMarkProps {
  state: keyof { [key in keyof typeof ESTIMATE_STATES_SHORTS]: any };
}

const EstimateStateMark: FC<IEstimateStateMarkProps> = ({ state }) => {
  return (
    <div className={styles.mark} title={ESTIMATE_STATES_TRANSCRIPTS[state]}>
      {ESTIMATE_STATES_SHORTS[state]}
    </div>
  );
};

export default React.memo(EstimateStateMark);
