import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  chooseSectionSubSectionsSelector,
  getChooseSectionModalSubSections,
  isChooseSectionSubSectionsLoadingSelector,
  setChooseSectionSubSections,
} from "redux/modules/common/building/materials/specification";

import { Spinner } from "components/UI/Spinner/Spinner";
import BackNavigationBar from "components/UI/atoms/BackNavigationBar/BackNavigationBar";

import Modal from "../../atoms/Modal";
import Section from "../../atoms/Section";

import styles from "./SelectSectionsModal.module.scss";

const SelectSectionsModal = ({ isOpen, onClose, sections, selectSection, objectId }) => {
  const dispatch = useDispatch();

  const chooseSectionSubSections = useSelector(chooseSectionSubSectionsSelector);
  const isChooseSectionSubSectionsLoading = useSelector(isChooseSectionSubSectionsLoadingSelector);

  const [chosenSection, setChosenSection] = useState(null);

  const resetChosenSection = useCallback(() => {
    setChosenSection(null);
    dispatch(setChooseSectionSubSections({}));
  }, []);

  useEffect(() => {
    if (!chosenSection?.id || !objectId) return;

    dispatch(getChooseSectionModalSubSections(objectId, chosenSection.id));
  }, [chosenSection, objectId]);

  const handleSelectSection = useCallback(
    (selectedSection) => {
      selectSection(selectedSection);
      resetChosenSection();
      onClose();
    },
    [selectSection, resetChosenSection, onClose]
  );

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose} title="Выберите раздел">
      <div className={styles.container}>
        {chosenSection && (
          <header className={styles.header}>
            <BackNavigationBar onBack={resetChosenSection} />
            <h2 className={styles.title}>{chosenSection.name}</h2>
          </header>
        )}
        <div className={cn(styles.scroll, { [styles.loading]: isChooseSectionSubSectionsLoading })}>
          {!isChooseSectionSubSectionsLoading ? (
            !chosenSection || !chooseSectionSubSections.subsections ? (
              sections.map(
                (item) => item.products_count !== 0 && <Section data={item} onClick={setChosenSection} key={item.id} />
              )
            ) : (
              chooseSectionSubSections.subsections.map((item) => (
                <Section data={item} onClick={handleSelectSection} key={item.id} />
              ))
            )
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(SelectSectionsModal);
