import React, { useState } from "react";

import { resetPasswordId } from "redux/modules/common/userRedux";

import ButtonBack from "components/UI/atoms/ButtonBack/ButtonBack";
import ButtonBase from "components/UI/atoms/ButtonBase";
import { Switch } from "components/UI/atoms/Switch/Switch";

import { MiniModal } from "../MiniModal/MiniModal";

import { RiCloseFill } from "react-icons/ri";

import styles from "./Navigation.module.scss";

export const Navigation = ({ isEditing, block, setBlock, innerInput, setInnerInput, headerData }) => {
  const [modalPassword, setModalPassword] = useState(false);

  const checkHandler = (event) => {
    setInnerInput({ ...innerInput, is_blocked: event.target.checked });
  };

  const resetPasswordHandler = async () => {
    await resetPasswordId(innerInput.id, innerInput);
    setModalPassword(!modalPassword);
  };

  return (
    <header>
      <div className={styles.navContent}>
        <MiniModal visible={modalPassword} setVisible={setModalPassword}>
          <div className={styles.flexAction}>
            <div style={{ fontSize: "22px", fontWeight: "500", color: "#000" }}>Подтвердите действие</div>
            <RiCloseFill
              size="25"
              style={{ cursor: "pointer", color: "#707070" }}
              onClick={() => setModalPassword(!modalPassword)}
            />
          </div>
          <div>
            <div style={{ padding: "0 20px", marginBottom: "17px" }}>
              <p className={styles.text} style={{ color: "#000", fontSize: "18px" }}>
                Обновить пароль пользователя?
              </p>
            </div>
            <div
              style={{
                borderTop: "1px solid #d3d3d3",
                padding: "10px 19px 11px 0px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonBase
                style={{ marginRight: "12.5px" }}
                onClick={() => setModalPassword(!modalPassword)}
                medium
                secondary
              >
                Отменить
              </ButtonBase>
              <ButtonBase onClick={resetPasswordHandler} medium primary>
                Да
              </ButtonBase>
            </div>
          </div>
        </MiniModal>
        <div className={styles.flexText} style={{ alignItems: "baseline" }}>
          <div>
            <ButtonBack />
          </div>
          <p className={styles.text} style={{ marginLeft: "30px" }}>
            {headerData?.last_name} {headerData?.first_name} {headerData?.middle_name}
          </p>
        </div>
        <div className={styles.flexText} style={{ alignItems: "baseline" }}>
          {isEditing && (
            <div className={styles.flexText} style={{ marginRight: "45px" }}>
              <p className={styles.text} style={{ marginRight: "20px" }}>
                {!block ? "Заблокировать" : "Разблокировать"}
              </p>
              <Switch checked={block} onChange={checkHandler} onClick={() => setBlock(!block)} />
            </div>
          )}
          {isEditing && (
            <div>
              <ButtonBase onClick={() => setModalPassword(!modalPassword)} buttonPrimaryEmpty>
                Обновить пароль
              </ButtonBase>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
