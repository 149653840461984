import {combineReducers} from 'redux'

import suppliers from './suppliers'
import list from './list'
import item from './item'

export const moduleName = 'suppliers'

export default combineReducers({
  index: suppliers,
  list,
  item
})
