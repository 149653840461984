import styles from "./GroupAmounts.module.scss";
import React, { FC } from "react";
import InputBase, { VALUE_TYPES } from "../../../../atoms/InputBase";
import { LOCALIZATION_CONFIG } from "../../../../../../constants/localization";
import cn from "classnames";
import { transformOptions as generalTransformOptions } from "../../lib/transformOptions";
import { IGroupInfo, IGroupTotals } from "../../types";
import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";
import { ExpenditureTypeEnum } from "../../../../../../types/enums/ExpenditureTypeEnum";


interface IProps {
  isEdit: boolean;
  group: Partial<IGroupInfo>;
  onTotalChange: (totalName: keyof Omit<IGroupTotals, "all">, value: string) => void;
}

const transformOptions = {
  ...generalTransformOptions,
  withCurrencySign: true,
};

const inputTransformOptions = generalTransformOptions;

export const GroupAmounts: FC<IProps> = ({
  isEdit,
  group,
  onTotalChange
}) => {
  const isMaterialsVisible = !!group.expenditures?.some(e => (e.expenditure_type === ExpenditureTypeEnum.material) && !e.ret_own_mat);
  const isEquipmentVisible = !!group.expenditures?.some(e => (e.expenditure_type === ExpenditureTypeEnum.equipment) && !e.ret_own_mat);
  const isServicesVisible = !!group.expenditures?.some(e => [ExpenditureTypeEnum.machine, ExpenditureTypeEnum.transport].includes(e.expenditure_type));
  const isRetOwnMatVisible = !!group.expenditures?.some(e => e.ret_own_mat);


  return (
    <div className={styles.groupAmounts}>
      <span className={styles.title}>Итоговые стоимости группы:</span>
      <div className={styles.amountsContainer}>
        <div className={cn(styles.amountItem, { [styles.isEditing]: isEdit })}>
          <span className={styles.title}>Работы:</span>
          {!isEdit ? (
            <span className={styles.value}>{transformDigitToFinancial(group.totals.works, transformOptions)}</span>
          ) : (
            <InputBase
              classNameInput={styles.inputInner}
              value={group.totals.works}
              valueType={VALUE_TYPES.NUMBER}
              numberTransformOptions={inputTransformOptions}
              icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
              onChange={(e) => {
                onTotalChange("works", e.target.value || "0");
              }}
            />
          )}
        </div>


        {isMaterialsVisible && (
          <div className={cn(styles.amountItem, { [styles.isEditing]: isEdit })}>
            <span className={styles.title}>Материалы:</span>
            {!isEdit ? (
              <span
                className={styles.value}>{transformDigitToFinancial(group.totals?.materials, transformOptions)}</span>
            ) : (
              <InputBase
                classNameInput={styles.inputInner}
                value={group.totals?.materials}
                valueType={VALUE_TYPES.NUMBER}
                numberTransformOptions={inputTransformOptions}
                icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
                onChange={(e) => {
                  onTotalChange("materials", e.target.value || "0");
                }}
              />
            )}
          </div>
        )}

        {isEquipmentVisible && (
          <div className={cn(styles.amountItem, { [styles.isEditing]: isEdit })}>
            <span className={styles.title}>Оборудование:</span>
            {!isEdit ? (
              <span
                className={styles.value}>{transformDigitToFinancial(group.totals?.equipments, transformOptions)}</span>
            ) : (
              <InputBase
                classNameInput={styles.inputInner}
                value={group.totals?.equipments}
                valueType={VALUE_TYPES.NUMBER}
                numberTransformOptions={inputTransformOptions}
                icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
                onChange={(e) => {
                  onTotalChange("equipments", e.target.value || "0");
                }}
              />
            )}
          </div>
        )}

        {isServicesVisible && (
          <div className={cn(styles.amountItem, { [styles.isEditing]: isEdit })}>
            <span className={styles.title}>МиМ:</span>
            {!isEdit ? (
              <span className={styles.value}>{transformDigitToFinancial(group.totals?.services, transformOptions)}</span>
            ) : (
              <InputBase
                classNameInput={styles.inputInner}
                value={group.totals?.services}
                valueType={VALUE_TYPES.NUMBER}
                numberTransformOptions={inputTransformOptions}
                icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
                onChange={(e) => {
                  onTotalChange("services", e.target.value || "0");
                }}
              />
            )}
          </div>
        )}

        {isRetOwnMatVisible && (
          <>
            <div className={cn(styles.amountItem, { [styles.isEditing]: isEdit })}>
              <span className={styles.title}>Давальческие:</span>
              {!isEdit ? (
                <span
                  className={styles.value}>{transformDigitToFinancial(group.totals?.rom_materials, transformOptions)}</span>
              ) : (
                <InputBase
                  classNameInput={styles.inputInner}
                  value={group.totals?.rom_materials}
                  valueType={VALUE_TYPES.NUMBER}
                  numberTransformOptions={inputTransformOptions}
                  icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
                  onChange={(e) => {
                    onTotalChange("rom_materials", e.target.value || "0");
                  }}
                />
              )}
            </div>

            <div className={cn(styles.amountItem, { [styles.isEditing]: isEdit })}>
              <span className={styles.title}>Без давальч.:</span>
              {!isEdit ? (
                <span
                  className={styles.value}>{transformDigitToFinancial(group.totals?.all_without_rom, transformOptions)}</span>
              ) : (
                <InputBase
                  classNameInput={styles.inputInner}
                  value={group.totals?.all_without_rom}
                  valueType={VALUE_TYPES.NUMBER}
                  numberTransformOptions={inputTransformOptions}
                  icon={<span className={styles.currency}>{LOCALIZATION_CONFIG.currency}</span>}
                  onChange={(e) => {
                    onTotalChange("all_without_rom", e.target.value || "0");
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};