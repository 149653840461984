import moment from "moment";

import { getOptionsFromEnum } from "../../../utils/formHelpers/getOptionsFromEnum";

export enum TASK_LISTS_TYPES {
  ALL = "all",
  MY = "executor",
  ASSIGNED = "creator",
  WATCHING = "viewer",
  LISTS = "lists",
}

export enum taskFilterStatuses {
  NOT_COMPLETED = "in_work,completed,expired",
  COMPLETED = "closed",
}

export enum TASK_STATUSES {
  in_work = "В работе",
  completed = "Задача выполнена",
  closed = "Завершено",
  expired = "Просрочено",
}

export type TaskStatus = keyof typeof TASK_STATUSES;

export enum TASK_PRIORITY {
  gray = "Низкий",
  light_green = "Ниже среднего",
  yellow = "Средний",
  orange = "Выше среднего",
  red = "Высокий",
}

export type TaskPriority = keyof typeof TASK_PRIORITY;

export enum TASK_MODAL_TYPES {
  ADD = "ADD",
  VIEW = "VIEW",
  EDIT = "EDIT",
}

export const TASKS_STATUSES_OPTIONS = getOptionsFromEnum(TASK_STATUSES);

export const TASKS_PRIORITY_OPTIONS = (Object.keys(TASK_PRIORITY) as Array<keyof typeof TASK_PRIORITY>).map((key) => ({
  id: key,
  name: TASK_PRIORITY[key],
  label: TASK_PRIORITY[key],
}));

export const ADD_TASK_INITIALS = {
  name: "", //
  description: "",
  deadline: moment().toISOString(), //
  priority: "yellow",
  building: -1, //
  section: undefined,
  executor: "", //
  viewers: "", //
  creator: -1, //
  remember_task: null,
  repeat_task: null,
};

export const MAIN_MODAL_TAB = "MAIN_MODAL_TAB";
export const EXTRA_MODAL_TAB = "EXTRA_MODAL_TAB";

export const TASKS_MODAL_TABS = [
  {
    text: "Основные сведения",
    id: MAIN_MODAL_TAB,
  },
  {
    text: "Дополнительные",
    id: EXTRA_MODAL_TAB,
  },
];

export const TASKS_REMIND_OPTIONS = [
  {
    id: 1,
    name: "за час",
  },
  {
    id: 24,
    name: "за день",
  },
  {
    id: 168,
    name: "за неделю",
  },
  {
    id: null,
    name: "не напоминать",
  },
];

export const TASKS_REPEAT_OPTIONS = [
  {
    id: 1,
    name: "каждый день",
  },
  {
    id: 7,
    name: "каждую неделю",
  },
  {
    id: 30,
    name: "каждый месяц",
  },
  {
    id: null,
    name: "без повтора",
  },
];

export enum tasksAggregationSectionsEnum {
  all = "all",
  executor = "executor",
  creator = "creator",
  viewer = "viewer",
  tasks_lists = "tasks_lists",
}

export const TASKS_STATUS_OPTIONS = [
  {
    id: "in_work",
    name: TASK_STATUSES.in_work,
  },
  {
    id: "completed",
    name: TASK_STATUSES.completed,
  },
  {
    id: "closed",
    name: TASK_STATUSES.closed,
  },
  {
    id: "expired",
    name: TASK_STATUSES.expired,
    isHidden: true,
  },
];

export const TASK_REPEAT = {
  "1": "каждый день",
  "7": "каждую неделю",
  "30": "каждый месяц",
};

export const TASK_REMEMBER = {
  "1": "за час",
  "24": "за день",
  "168": "за неделю",
};
