import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  relationsFromCurrentIntervalSelector,
  relationsToCurrentIntervalSelector,
} from "../../../../../../../redux/modules/common/building/manufacturing/selectors";
import {
  clearLoadedIntervalRelations,
  loadIntervalRelations,
  loadRelationAvailableSections,
} from "../../../../../../../redux/modules/common/building/manufacturing/thunks";

import { IDisplayedRelation } from "./types";

export interface IUseLoadIntervalRelationsProps {
  projectId: number | undefined;
  intervalId: number | undefined;
  isGroup?: boolean;
}

export const useLoadIntervalRelations = ({ projectId, intervalId, isGroup = false }: IUseLoadIntervalRelationsProps) => {
  const dispatch = useDispatch();

  const relationsFromCurrentInterval: IDisplayedRelation[] = useSelector(relationsFromCurrentIntervalSelector) || [];

  const relationsToCurrentInterval: IDisplayedRelation[] = useSelector(relationsToCurrentIntervalSelector) || [];

  const hasRelationsOnMountMoment = useRef(relationsFromCurrentInterval?.length > 0 || relationsToCurrentInterval?.length > 0);

  const hasRelations = useMemo(() => !!relationsToCurrentInterval?.length || !!relationsFromCurrentInterval?.length, [relationsFromCurrentInterval, relationsToCurrentInterval])

  const isChart = useMemo(() => location.pathname.includes('manufacturing'), [location])

  useEffect(() => {
    if (!!relationsToCurrentInterval?.length || !!relationsFromCurrentInterval?.length && !hasRelationsOnMountMoment.current) hasRelationsOnMountMoment.current = true;
  }, [relationsFromCurrentInterval, relationsToCurrentInterval]);

  useEffect(() => {
    if (!intervalId || !projectId || !isChart) return;
    dispatch(loadIntervalRelations({ projectId, intervalId, isGroup }));
    dispatch(loadRelationAvailableSections(projectId));
    return () => {
      dispatch(clearLoadedIntervalRelations());
    };
  }, [intervalId, projectId]);

  return {
    hasRelations: hasRelations || hasRelationsOnMountMoment.current,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
  };
};
