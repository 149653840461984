import React from "react";
import { useDispatch } from "react-redux";

import {
  isTasksLoadingSelector,
  tasksSectionFilterSelector,
  tasksbyAllBuildingsSelector,
} from "redux/modules/purchaser/Tasks/selectors";
import { CLEAR_SECTION_TASK_FILTER } from "redux/modules/purchaser/Tasks/tasks";
import { loadMoreTasksByAllBuildings, loadTasksByAllBuildings } from "redux/modules/purchaser/Tasks/thunks";
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types";
import { useTypedSelector } from "redux/typedUseSelector";

import { TASK_LISTS_TYPES } from "../constants";

type propsType = {
  filters: tasksListFilterType;
  type?: TASK_LISTS_TYPES;
};

export const useTasksByAllBuildingsData = ({ filters, type }: propsType) => {
  const dispatch = useDispatch();
  const tasks = useTypedSelector(tasksbyAllBuildingsSelector);
  const isTasksLoading = useTypedSelector(isTasksLoadingSelector);
  const sectionTaskFilter = useTypedSelector(tasksSectionFilterSelector);

  React.useEffect(() => {
    if (!type) return;
    dispatch(loadTasksByAllBuildings(filters, type));
  }, [type, filters]);

  const tasksByType = React.useMemo(() => {
    if (!type) return null;
    return tasks?.[type] || null;
  }, [tasks, type]);

  const loadMoreTasksHandler = React.useCallback(() => {
    if (!tasksByType?.results?.length) return;
    const sectionsParam = Object.entries(sectionTaskFilter)
      .map((el) => `${el[0]}:${el[1]}`)
      .join(",");
    dispatch(
      loadMoreTasksByAllBuildings({ ...filters, building_section: sectionsParam }, type!, tasksByType?.results?.length)
    );
  }, [tasksByType?.results, filters, type, sectionTaskFilter]);

  React.useEffect(() => {
    return () => {
      dispatch({
        type: CLEAR_SECTION_TASK_FILTER,
      });
    };
  }, []);

  return {
    isTasksLoading,
    tasksByType,
    loadMoreTasksHandler,
  };
};
