import React, { useCallback } from "react";

import ButtonBase from "../../../../../../UI/atoms/ButtonBase";
import HandlerIndicatorItem from "../HandlerIndicatorItem/HandlerIndicatorItem";

import styles from "./LoadDraftIndicator.module.scss";
import { ESTIMATE_STATES_IDS } from "../../../../constants";

const LoadDraftIndicator = ({ drafterCount, onClick, isActive }) => {
  const buttonHandler = (e) => {
    e.stopPropagation();
  };

  const handleOnClick = useCallback(() => onClick(ESTIMATE_STATES_IDS.DRAFT), [onClick]);

  return (
    <HandlerIndicatorItem borderColor="#8472E6" isActive={isActive}>
      <div className={styles.container} onClick={handleOnClick}>
        <ButtonBase className={styles.btn} onClick={buttonHandler}>Создать позицию</ButtonBase>
        <span className={styles.number}>{drafterCount || 0} позиций </span>
        <div className={styles.subTitle}>В черновике</div>
        {isActive && <span className={styles.border} />}
      </div>
    </HandlerIndicatorItem>

  );
};

export default React.memo(LoadDraftIndicator);
