import React from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";

import styles from "./DownloadFinanceReportButton.module.scss";

interface IProps {
  onClick: () => void;
  text?: string;
}

const DownloadFinanceReportButton: React.FC<IProps> = ({ onClick, text = "XLSX" }) => {
  return (
    <button className={styles.btn} onClick={onClick}>
      {text}
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 15L15 11H13.5V13.5H2.5V11H1V15H15Z" fill="#4FB1EB" />
        <path
          d="M12 5.5L8.75 8.49999V1L7.25 1L7.25 8.5L4 5.5L3.05041 6.47386L7.99379 11.4226L12.9492 6.47386L12 5.5Z"
          fill="#4FB1EB"
        />
      </svg>
    </button>
  );
};

export default DownloadFinanceReportButton;
