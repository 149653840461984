import React from "react";
import { Link } from "react-router-dom";

import SelectMenu from "../../../../atoms/SelectMenu";
import { useBreadcrumbs } from "./useBreadcrumbs";
import { useTranslation } from "react-i18next";

import { IIdAndName } from "../../../../../../types/idAndName";

import Line from "../../../../../../images/icons/Line";

import styles from "./Breadcrumbs.module.scss";

export interface IBreadcrumbsProps {}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({}) => {
  const {
    activeModule,
    activeSubModule,
    activeObjectName,
    objectsOptions,
    constructSelectLink,
    isObjectSelectShown,
    //projects
    isProjectSelectShown,
    projectsOptions,
    activeProjectName,
    constructProjectSelectLink,
    projectId,
  } = useBreadcrumbs();

  const { t } = useTranslation();

  const hasSubmodule = !!activeSubModule?.title;

  return (
    <div className={styles.container}>
      {activeModule && (
        <div className={styles.selectClassNameNew}>
          <div className={styles.breadcrumbsTitle}>{t(activeModule.title)}</div>
          {(hasSubmodule || isObjectSelectShown) && <Line width={"0.125rem"} height={"2.313rem"} />}
        </div>
      )}
      {hasSubmodule && (
        <SelectMenu
          selectedOption={t(activeSubModule?.title)}
          titleClassName={styles.breadcrumbsTitleSmall}
          selectClassName={styles.selectClassName}
          optionsBlockClassName={styles.optionsBlockClassName}
        />
      )}
      {isProjectSelectShown && (
        <SelectMenu
          selectedOption={activeProjectName}
          titleClassName={styles.breadcrumbsTitleSmall}
          selectClassName={styles.selectClassName}
          optionsBlockClassName={styles.optionsBlockClassName}
          lastEl
        >
          {!!projectsOptions?.length &&
            projectsOptions.map((option: IIdAndName) => (
              <Link key={option.id} to={constructProjectSelectLink(option.id)}>
                <div className={styles.breadcrumbsItem} title={option.name}>
                  {option.name}
                </div>
              </Link>
            ))}
        </SelectMenu>
      )}
      {isObjectSelectShown && (
        <SelectMenu
          selectedOption={activeObjectName}
          titleClassName={styles.breadcrumbsTitleSmall}
          selectClassName={styles.selectClassName}
          optionsBlockClassName={styles.optionsBlockClassName}
          lastEl
          disabled={isProjectSelectShown && !projectId}
        >
          {!!objectsOptions?.length &&
            objectsOptions.map((option: IIdAndName) => (
              <Link key={option.id} to={constructSelectLink(option.id)}>
                <div className={styles.breadcrumbsItem} title={option.name}>
                  {option.name}
                </div>
              </Link>
            ))}
        </SelectMenu>
      )}
    </div>
  );
};

export default React.memo(Breadcrumbs);
