import moment from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";

import Button from "components/UI/atoms/ButtonBase";
import InputNumber from "components/UI/atoms/InputNumber";
import SliderModal from "components/UI/atoms/SliderModal";
import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";
import AddMimsInModal from "components/UI/organism/AddingListInModal/AddMimsInModal";

import AddExpendituresInModal from "../../../../organism/AddingListInModal/AddExpendituresInModal";
import AddMaterialsInModal from "../../../../organism/AddingListInModal/AddMaterialsInModal";
import AddWorkersInModal from "../../../../organism/AddingListInModal/AddWorkersInModal";
import ExpandableFilesInput from "../../../ExpandableFileInput/ExpandableFileInput";

import { useExpendituresInGroups } from "../../../../organism/AddingListInModal/hooks/useExpendituresInGroups";
import { useSelectingMaterials } from "../../../../organism/AddingListInModal/hooks/useSelectingMaterials";
import { useSelectingMims } from "../../../../organism/AddingListInModal/hooks/useSelectingMims";
import { useSelectingWorkers } from "../../../../organism/AddingListInModal/hooks/useSelectingWorkers";
import { useSimpleResources } from "../../../../organism/AddingListInModal/hooks/useSimpleResources";

import styles from "./CreateGroupFactModal.module.scss";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  product: any; // TODO: delete any
  objectId: number | string;
  groupId: number;
  loadSectionGroups: () => void;
}

const CreateGroupFactModal: FC<IProps> = ({
  isOpen,
  handleClose,
  product,
  objectId,
  groupId,
  loadSectionGroups
}) => {
  const [ dateStart, setDateStart ] = useState(moment());
  const [ dateEnd, setDateEnd ] = useState(moment());
  const [ count, setCount ] = useState("0");
  const [ files, setFiles ] = useState([]);
  const [ isPending, setIsPending ] = useState(false);

  const handleCloseModal = () => {
    loadSectionGroups();
    handleClose();
    clearMaterials();
    clearMims();
    clearWorkers();
    clearExpendituresInGroups();
  };

  useEffect(() => {
    if (!isOpen) setIsPending(false);
  }, [ isOpen ]);

  const {
    addedExpenditures,
    changeExpendituresCountHandler,
    validateSubmittingExpenditures,
    createGroupExpendituresFact,
    workGroupMaterials,
    workGroupMims,
    clearExpendituresInGroups
  } = useExpendituresInGroups({
    isOpen,
    selectedWorkGroupId: groupId,
    count,
    setCount
  });

  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers
  } = useSelectingWorkers();

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    selectMaterialsHandler,
    addedMaterials,
    deletedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    clearMaterials
  } = useSelectingMaterials(workGroupMaterials);

  const {
    confirmMimsHandler,
    cancelMimsHandler,
    changeCountMimsHandler,
    validateSubmittingMims,
    deleteAddedHandler: deleteAddedMims,
    selectMimsHandler,
    addedMims,
    selectedMims,
    selectedMimsIds,
    addedMimsIds,
    deletedMims,
    clearMims
  } = useSelectingMims(workGroupMims);

  const {
    resources: simpleResources,
    deleteResource: deleteSimpleResource,
    updateResource: updateSimpleResource,
    createNewResource: createNewSimpleResource,
    isAdding: isSimpleResourceAdding,
    toggleIsEditing: toggleIsSimpleResourceEditing,
    onSubmit: onSimpleResourcesSubmit,
    isValidForSubmit: validateSimpleResources
  } = useSimpleResources();

  const handleSubmit = async () => {
    const hasValidWorkers = validateSubmitting();
    const hasValidMaterials = validateSubmittingMaterials();
    const hasValidMims = validateSubmittingMims();
    const hasValidExpenditures = validateSubmittingExpenditures();
    const hasValidSimpleResources = validateSimpleResources();
    if (groupId && hasValidWorkers && hasValidMaterials && hasValidMims && hasValidExpenditures && hasValidSimpleResources) {
      await createGroupExpendituresFact({
        startDate: moment(dateStart).format("YYYY-MM-DD"),
        endDate: moment(dateEnd).format("YYYY-MM-DD"),
        addedMims,
        addedMaterials,
        addedWorkers: addedUsers,
        callback: (data) => {
          onSimpleResourcesSubmit({fact_group: data?.id});
          handleCloseModal();
        }
      });
    }
  };

  const isSaveButtonDisabled = useMemo(
    () => !count || (+count === 0 && addedMaterials?.length <= 0 && addedMims?.length <= 0),
    [ count, addedMaterials, addedMims ]
  );

  return (
    <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
      <div className={styles.container}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>Добавить в факт</div>
          <div className={styles.subTitle} title={product.name}>
            {product.name}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.hiddenWrapper}>
            <div className={styles.fieldTitle}>Количество</div>
            <div className={styles.fieldValue}>
              <InputNumber value={count} setValue={setCount} decimalPlace="4" />
            </div>
            <div className={styles.fieldTitle}>Период выполнения</div>
            <div className={styles.fieldRow}>
              <div className={styles.fieldCalendar}>
                <CalendarRange
                  defaultDateStart={dateStart}
                  setDefaultDateStart={setDateStart}
                  defaultDateEnd={dateEnd}
                  setDefaultDateEnd={setDateEnd}
                />
              </div>
            </div>
            {isOpen && (
              <AddWorkersInModal
                addedUsers={addedUsers}
                selectedUsers={selectedUsers}
                onAccept={confirmHandler}
                onDecline={cancelHandler}
                onChangeCount={changeCountHandler}
                onSelect={selectHandler}
                selectedIds={selectedUsersIds}
                onDelete={deleteAddedHandler}
                addedIds={addedUsersIds}
              />
            )}

            {isOpen && !!groupId && (
              <AddExpendituresInModal
                addedExpenditures={addedExpenditures}
                onChangeCount={changeExpendituresCountHandler}
              />
            )}

            {isOpen && (
              <AddMaterialsInModal
                addedMaterials={addedMaterials}
                selectedUsers={selectedMaterials}
                onAccept={confirmMaterialsHandler}
                onDecline={cancelMaterialsHandler}
                onChangeCount={changeCountMaterialsHandler}
                onSelect={selectMaterialsHandler}
                selectedIds={selectedMaterialsIds}
                onDelete={deleteAddedMaterial}
                addedIds={addedMaterialsIds}
                activeSection={product.section_id}
                activeWork={product.id}
                defaultMaterialsList={!!groupId ? [ ...addedMaterials, ...deletedMaterials ] : undefined}
                isWorkGroup={!!groupId}
                simpleResources={simpleResources}
                createNewSimpleResource={createNewSimpleResource}
                deleteSimpleResource={deleteSimpleResource}
                isSimpleResourceAdding={isSimpleResourceAdding}
                toggleIsSimpleResourceEditing={toggleIsSimpleResourceEditing}
                updateSimpleResource={updateSimpleResource}
              />
            )}
            {isOpen && (
              <AddMimsInModal
                addedMims={addedMims}
                selectedMims={selectedMims}
                onAccept={confirmMimsHandler}
                onDecline={cancelMimsHandler}
                onChangeCount={changeCountMimsHandler}
                onSelect={selectMimsHandler}
                selectedIds={selectedMimsIds}
                onDelete={deleteAddedMims}
                addedIds={addedMimsIds}
                activeSection={product.section_id}
                activeWork={product.id}
                defaultMimsList={!!groupId ? [ ...addedMims, ...deletedMims ] : undefined}
                isWorkGroup={!!groupId}
              />
            )}
            {!groupId && (
              <ExpandableFilesInput files={files} setFiles={setFiles} canExtractFiles={false} />
            )}
          </div>
        </div>
        <div className={styles.actionBlock}>
          <Button type="button" medium secondary disabled={false} onClick={handleCloseModal}>
            Отменить
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button medium primary onClick={handleSubmit} disabled={isSaveButtonDisabled} isLoading={isPending}>
            Сохранить
          </Button>
        </div>
      </div>
    </SliderModal>
  );
};

export default CreateGroupFactModal;
