import React, { useCallback, useEffect, useMemo, useState } from "react";
import { compose } from "redux";
import moment from "moment";

import WeekBar from "../../atoms/WeekBar/WeekBar";
import ButtonArrow from "../../atoms/ButtonArrow/ButtonArrow";

import WeeksInYear from "./domain/WeeksInYear";
import styles from "./WeeksSlider.module.scss";
import cn from "classnames";

const LAST_WEEK_NUMBER = 1;

let activeWeekNumberUpdateWasByArrows = false;

const WeeksSlider = ({ className, year, activeWeekNumber, setActiveWeekNumber, displayWeeksCount = 6 }) => {
  const weeksInYearCount = moment().year(year).weeksInYear();
  const weeksInYear = useMemo(() => new WeeksInYear(year), [year]);
  const [weeks, setWeeks] = compose(useState, weeksInYear.getWeeksGroupByMiddle)(activeWeekNumber, displayWeeksCount);

  const activeNextWeek = useCallback(() => {
    if (activeWeekNumber === weeksInYearCount) return;

    const updatedActiveWeekNumber = activeWeekNumber + 1;
    activeWeekNumberUpdateWasByArrows = true;
    setActiveWeekNumber(updatedActiveWeekNumber);

    if (updatedActiveWeekNumber > weeks[weeks.length - 1]?.number) compose(setWeeks, weeksInYear.getWeeksGroupByEnd)(
      updatedActiveWeekNumber,
      displayWeeksCount
    );
  }, [activeWeekNumber, weeks[weeks.length - 1]?.number, displayWeeksCount, weeksInYear]);

  const activePrevWeek = useCallback(() => {
    if (activeWeekNumber === LAST_WEEK_NUMBER) return;

    const updatedActiveWeekNumber = activeWeekNumber - 1;
    activeWeekNumberUpdateWasByArrows = true;
    setActiveWeekNumber(updatedActiveWeekNumber);

    if (updatedActiveWeekNumber < weeks[0]?.number) compose(setWeeks, weeksInYear.getWeeksGroupByStart)(
      updatedActiveWeekNumber - 1,
      displayWeeksCount
    );
  }, [activeWeekNumber, weeks[0]?.number, displayWeeksCount, weeksInYear]);

  useEffect(() => {
    if (activeWeekNumberUpdateWasByArrows) {
      activeWeekNumberUpdateWasByArrows = false;
      return;
    }
    compose(setWeeks, weeksInYear.getWeeksGroupByMiddle)(activeWeekNumber, displayWeeksCount);
  }, [activeWeekNumber, displayWeeksCount, weeksInYear]);

  return (
    <div className={cn(styles.weeksSlider, className)}>
      <ButtonArrow direction="left" onClick={activePrevWeek} />
      <div className={styles.weeks}>
        {weeks.map((week) => (
          <WeekBar
            className={styles.weekBar}
            number={week.number}
            range={week.range}
            isActive={activeWeekNumber === week.number}
            key={week.number}
          />
        ))}
      </div>
      <ButtonArrow direction="right" onClick={activeNextWeek} />
    </div>
  );
};

export default React.memo(WeeksSlider);