import React from "react";

import CompBox from "./components/CompBox";

import {
  MATERIALS_COLOR_MAP,
  MATERIALS_STATUSES,
  MATERIALS_TAB_ID,
  MIMES_TAB_ID,
  MONTH_COLOR_MAP,
  RESOURCES_TAB_ID,
  WORKS_TAB_ID
} from "../../constants";
import { ManufacturingTabsType } from "../../types";

import styles from "./Legend.module.scss";

export interface ILegendProps {
  type: ManufacturingTabsType;
}

const Legend: React.FC<ILegendProps> = ({ type = WORKS_TAB_ID }) => {
  return (
    <div className={styles.container}>
      <div className={styles.big}>
        {type === WORKS_TAB_ID && (
          <>
            <CompBox title={"План"} style={{ backgroundColor: MONTH_COLOR_MAP["default"] }} />
            <CompBox title={"Выполнено"} className={styles.doned} />
            <CompBox title={"Принято"} style={{ background: MONTH_COLOR_MAP["received"] }} />
            <CompBox title={"Подтверждено"} className={styles.confirmed} />
            <CompBox title={"К сдаче"} style={{ backgroundColor: MONTH_COLOR_MAP["todo"] }} />
            <CompBox title={"К оплате"} style={{ backgroundColor: MONTH_COLOR_MAP["topay"] }} />
            {/*<CompBox title={"Критический путь"} style={{ backgroundColor: MONTH_COLOR_MAP["critical"] }} />*/}
          </>
        )}
        {[MATERIALS_TAB_ID, MIMES_TAB_ID, RESOURCES_TAB_ID].includes(type) &&
          Object.entries(MATERIALS_STATUSES).map(([key, value]) => (
            <CompBox key={key} title={value.name} style={{ backgroundColor: MATERIALS_COLOR_MAP[key] as string }} />
          ))}
        <div className={styles.triangleRight} />
      </div>
    </div>
  );
};

export default React.memo(Legend);
