import { IActions } from "../../../../../store";
import { IFinanceTableHighlighting, IFinanceTableScrollPosition } from "../financeCommonTypes";

import { IDetailedProjectSummary, IProjectViewSummary, ISummaryTree } from "./types";

import { getScrollPositionPercent } from "./utils";

export const summaryActions = {
  clearData: () =>
    ({
      type: "constructing/finance/summary_CLEAR_DATA",
      payload: {},
    } as const),
  setLoading: (isLoading: boolean) =>
    ({
      type: "constructing/finance/summary_SET_LOADING",
      payload: isLoading,
    } as const),
  setAllProjectsSummaryList: (summaryList: IProjectViewSummary[]) =>
    ({
      type: "constructing/finance/summary_SET_ALL_PROJECTS_SUMMARY",
      payload: summaryList,
    } as const),
  setDetailedProjectSummary: (objectId: number, summary: IDetailedProjectSummary) =>
    ({
      type: "constructing/finance/summary_SET_DETAILED_PROJECT_SUMMARY",
      payload: { objectId, summary },
    } as const),
  setSummaryTree: (tree: ISummaryTree[]) =>
    ({
      type: "constructing/finance/summary_SET_SUMMARY_TREE",
      payload: tree,
    } as const),
  setTableViewWidthPercent: (widthPercent: number) =>
    ({
      type: "constructing/finance/summary_SET_TABLE_VIEW_WIDTH_PERCENT",
      payload: widthPercent,
    } as const),
  setTableHighlighting: (highlightingParams: Partial<IFinanceTableHighlighting>) =>
    ({
      type: "constructing/finance/summary_SET_TABLE_HIGHLIGHTING",
      payload: highlightingParams,
    } as const),
  setYear: (year: number) =>
    ({
      type: "constructing/finance/summary_SET_YEAR",
      payload: year,
    } as const),
  setTableScrollPosition: (positionParams: Partial<IFinanceTableScrollPosition>) =>
    ({
      type: "constructing/finance/summary_SET_TABLE_SCROLL_POSITION",
      payload: { ...positionParams, percent: getScrollPositionPercent(positionParams) },
    } as const),
  setIsLoadingByObject: (status: boolean, objectId: number) =>
    ({
      type: "constructing/finance/summary_SET_IS_LOADING_BY_OBJECT",
      payload: {
        status,
        objectId,
      },
    } as const),
  setDetailedByProjectV2: (projectId: string, data: any) =>
    ({
      type: "constructing/finance/summary_SET_DEATILED_BY_PROJECT_V2",
      payload: {
        data,
        projectId,
      },
    } as const),
  setProjectsV2Totals: (data: Record<string, string>, projectId: string) =>
    ({
      type: "constructing/finance/summary_setProjectsV2Totals",
      payload: {
        data,
        projectId,
      },
    } as const),
};

export type ISummaryAction = IActions<typeof summaryActions>;
