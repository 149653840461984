import {combineReducers} from 'redux'

import invites from './invites'
import pending from './pending'
import list from './list'

export const moduleName = 'invites'

export default combineReducers({
  index: invites,
  pending,
  list
})
