import React, { useCallback, useMemo, useState } from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Modal from "components/UI/atoms/Modal";

import { ReactComponent as HideIcon } from "./Icons/HideIconBlue.svg";
import { changeVisibility } from "./api/VisibilityModalApi";

import styles from "./VisibilityModal.module.scss";

interface IProps {
  checkedList: { id: number; status: number }[];
  buildingId: number | string;
  setCheckedList: React.Dispatch<React.SetStateAction<{ id: number; status: number }[]>>;
  refreshSections: () => void;
  handleLoadExpenditures: () => void;
}

const VisibilityModal = ({
  checkedList,
  buildingId,
  setCheckedList,
  refreshSections,
  handleLoadExpenditures,
}: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const isAllVisible = useMemo(() => {
    return checkedList.every((el) => el.status === 0);
  }, [checkedList]);

  const isAllHidden = useMemo(() => {
    return checkedList.every((el) => el.status === 1);
  }, [checkedList]);

  const handleChangeVisibility = useCallback(
    (action: "hide" | "show") => {
      changeVisibility(
        buildingId,
        action,
        checkedList.map((el) => el.id)
      ).then(() => {
        refreshSections();
        handleLoadExpenditures();
      });
      setIsOpenModal(false);
      setCheckedList([]);
    },
    [buildingId, checkedList]
  );

  return (
    <>
      <button
        className={styles.VisibilityModal}
        onClick={() => setIsOpenModal(true)}
        disabled={!checkedList.length}
        style={{ opacity: checkedList.length ? 1 : 0.5 }}
      >
        <span className={styles.text}>Видимость</span>
        <HideIcon />
      </button>
      {isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          title="Подтвердите действие"
          headerClassName={styles.title}
          className={styles.modal}
          darkShadow
        >
          <div className={styles.modalText}>
            <span>
              {isAllVisible
                ? `Из производства будут скрыты позиции: ${checkedList.length} шт`
                : isAllHidden
                ? `В производстве станут доступны позиции: ${checkedList.length} шт`
                : `Выбрано позиций: ${checkedList.length}. Вы можете сделать видимыми или скрыть выбранные позиции в производстве`}
            </span>
          </div>
          <div className={styles.buttons}>
            <ButtonBase
              secondary
              className={styles.btn}
              onClick={
                isAllHidden || isAllVisible
                  ? () => setIsOpenModal(false)
                  : () => {
                      handleChangeVisibility("hide");
                    }
              }
            >
              {isAllHidden || isAllVisible ? "Отмена" : "Скрыть выбранные"}
            </ButtonBase>
            <ButtonBase
              primary
              className={styles.btn}
              onClick={() => {
                handleChangeVisibility(isAllVisible ? "hide" : "show");
              }}
            >
              {isAllVisible ? "Скрыть выбранные" : "Раскрыть выбранные"}
            </ButtonBase>
          </div>
        </Modal>
      )}
    </>
  );
};

export default React.memo(VisibilityModal);
