import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actingActions } from "redux/modules/common/building/journal/acting/actions";
import {
  actingInvalidateKeySelector,
  actingKs2ListByEstimateSelector,
  actingSelectedKs2IdsSelector,
} from "redux/modules/common/building/journal/acting/selectors";
import { getKs2ListByEstimate } from "redux/modules/common/building/journal/acting/thunks";

import { Spinner } from "components/UI/Spinner/Spinner";
import BackNavigationBar from "components/UI/atoms/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import JournalKs3Modal from "../JournalActingModal/JournalKs3Modal";
import JournalActingSelectingBar from "../common/JournalActingSelectingBar/JournalActingSelectingBar";
import JournalKs2Row from "./JournalKs2Row";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalKs2Level.module.scss";

interface IProps {
  onBack: () => void;
  estimateId: number;
}

const JournalKs2NestedLevel: React.FC<IProps> = ({ onBack, estimateId }) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const dispatch = useDispatch();

  const ks2List = useSelector(actingKs2ListByEstimateSelector)[estimateId];
  const cacheKey = useSelector(actingInvalidateKeySelector);
  const selectedIds = useSelector(actingSelectedKs2IdsSelector)[objectId] || [];

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getKs2ListByEstimate(objectId, estimateId));
  }, [cacheKey, estimateId, objectId]);

  const checkHandler = useCallback(
    (ksId: number, status: boolean) => {
      dispatch(actingActions.selectKs2Id(ksId, objectId, status));
    },
    [objectId]
  );

  return (
    <div>
      <div className={styles.nav}>
        <BackNavigationBar onBack={onBack} isInNestedList title={ks2List?.results?.[0]?.parent_section_name} isBold />
        <JournalActingSelectingBar
          type="ks2"
          onSubmit={() => setIsModalOpen(true)}
          selectedCount={selectedIds.length}
        />
      </div>
      {ks2List?.isLoading && <Spinner />}
      {!ks2List?.isLoading && !ks2List?.results?.length && <EmptyPlaceholder img={jurnalIcon} />}
      {ks2List?.results?.map((el) => (
        <JournalKs2Row
          key={el.id}
          rowOpenClassName={styles.openKs2RowInNested}
          ks2={el}
          onCheck={checkHandler}
          isChecked={selectedIds.includes(el.id)}
        />
      ))}
      <JournalKs3Modal estimateId={estimateId} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default React.memo(JournalKs2NestedLevel);
