import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  allNotificationsSelector,
  getNotifications,
  isLoadingSelector,
  maxCountSelector,
  unreadValSelector,
} from "redux/modules/common/notifications";

import ManageNotificationsModal from "./components/ManageNotificationsModal/ManageNotificationsModal";
import Modal from "./components/Modal/Modal";
import NotificationItem from "./components/NotificationItem/NotificationItem";
import PopoverModal from "./components/PopoverModal/PopoverModal";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import ContentWithCount from "../../../../atoms/ContentWithCount/ContentWithCount";
import MenuItem from "../../../NavigationBar/MenuItem";

import { INotification } from "./types/types";

import { useManageNotifications } from "./hooks/useManageNotifications";

import SettingGear from "../../../../../../images/SettingGear";
import NotificationIcon from "images/icons/navigation/NotificationIcon";

import styles from "./Notification.module.scss";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    canEditNotifications,
    notificationsToModify,
    isManageNotificationsModalOpen,
    openManageNotificationsModal,
    closeManageNotificationsModal,
    onChangeCheckedNotifications,
  } = useManageNotifications();

  const maxCount = useSelector(maxCountSelector);
  const isLoading = useSelector(isLoadingSelector);
  const notifications = useSelector(allNotificationsSelector);
  const unreadVal = useSelector(unreadValSelector);

  const [offset, setOffset] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const [notificationDeletedStatus, setNotificationDeletedStatus] = useState<Record<number, boolean>>({});

  const isMaxCountNotify = notifications.length >= maxCount;

  useEffect(() => dispatch(getNotifications(offset)), []);

  const openAllNotifications = () => {
    setIsPopover(true);
    setIsModal(false);
    setOffset(20);
  };

  const openSmallModal = () => {
    setIsModal(true);
    dispatch(getNotifications(0));
  };

  const loadMore = useCallback(() => {
    if (!isMaxCountNotify) {
      setOffset((prev) => prev + 20);
    }

    if (isPopover) {
      dispatch(getNotifications(offset, true));
    }
  }, [dispatch, isMaxCountNotify, isPopover, offset]);

  const scrollHandler: React.UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const element = event.target as HTMLElement;

      if (element.scrollHeight - (element.scrollTop + window.innerHeight) < 150 && !isLoading && !isMaxCountNotify) {
        loadMore();
      }
    },
    [isLoading, isMaxCountNotify, loadMore]
  );

  const handleOpenNotificationManageModal = useCallback(() => {
    setIsModal(false);
    openManageNotificationsModal();
  }, [openManageNotificationsModal]);
  return (
    <>
      <MenuItem
        isExists
        title={t("Уведомления")}
        link={undefined}
        jsxIcon={
          <ContentWithCount
            count={unreadVal}
            isCountDisplayed={unreadVal > 0}
            countClassName={styles.notyCounter}
            containerClassName={styles.notyCounterBlock}
          >
            <NotificationIcon />
          </ContentWithCount>
        }
        onClick={openSmallModal}
        isBottom
        isWithoutUnderline
        icon={undefined}
        isDirectlyActive={undefined}
        isTooltipHidden={isModal || isPopover || isManageNotificationsModalOpen}
      />

      <Modal isVisible={isModal} setIsVisible={setIsModal}>
        <div className={styles.innerModal}>
          {!isLoading && !notifications.length ? (
            <EmptyPlaceholder
              text={"Нет уведомлений"}
              svgJsx={<NotificationIcon />}
              className={styles.emptyPlaceholder}
            />
          ) : isLoading && !notifications.length ? (
            <Spinner />
          ) : (
            <>
              <div className={styles.notyQuantity}>
                Уведомления: {notifications.slice(0, 5).length} из {maxCount}
              </div>
              <div className={styles.notyList}>
                {notifications.slice(0, 5).map((notification: INotification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    notificationDeletedStatus={notificationDeletedStatus}
                    setNotificationDeletedStatus={setNotificationDeletedStatus}
                  />
                ))}
              </div>
              <div className={styles.bottomActions}>
                <SettingGear
                  fill={"#606060"}
                  onClick={handleOpenNotificationManageModal}
                  className={styles.settingsIcon}
                />
                <ButtonBase secondary medium onClick={openAllNotifications}>
                  Показать все
                </ButtonBase>
              </div>
            </>
          )}
        </div>
      </Modal>

      <PopoverModal isVisible={isPopover} setIsVisible={setIsPopover}>
        <div onScroll={scrollHandler} className={styles.innerLargeModal}>
          {!isLoading && !notifications.length ? (
            <EmptyPlaceholder
              text={"Нет уведомлений"}
              svgJsx={<NotificationIcon />}
              className={styles.emptyPlaceholder}
            />
          ) : isLoading && !notifications.length ? (
            <Spinner />
          ) : (
            <>
              <div className={styles.marginNoty}>
                <div className={styles.notyTextTitle}>Уведомления</div>
              </div>
              <div className={styles.notyList}>
                {notifications.map((notification: INotification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    notificationDeletedStatus={notificationDeletedStatus}
                    setNotificationDeletedStatus={setNotificationDeletedStatus}
                  />
                ))}
                {!isLoading && !isMaxCountNotify && <div>Загрузка данных...</div>}
              </div>
            </>
          )}
        </div>
      </PopoverModal>
      <ManageNotificationsModal
        notificationsToModify={notificationsToModify}
        onChangeChecked={onChangeCheckedNotifications}
        isOpen={isManageNotificationsModalOpen}
        onClose={closeManageNotificationsModal}
        canEdit={canEditNotifications}
      />
    </>
  );
};

export default React.memo(Notification);
