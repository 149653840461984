import axios from "axios";
import { createSelector } from "reselect";

import { contractorsStateSelector } from "../contractors";
import { moduleName as parentModuleName } from "./index";

import { LOAD_LIMIT } from "./constants";

import { prepareTableData } from "./utils";

const initialState = {
  isLoading: true,
  contractors: {
    count: 0,
    list: [],
  },
  tableFilters: {},
};

const moduleName = "index";
const actionsPrefix = "constructing/counterparties/contractors/list";

const SET_LOADING = `${actionsPrefix}_SET_LOADING`;
const SET_CONTRACTORS = `${actionsPrefix}_SET_CONTRACTORS`;
const SET_MORE_CONTRACTORS = `${actionsPrefix}_SET_MORE_CONTRACTORS`;
const SET_TABLE_FILTER = `${actionsPrefix}_SET_TABLE_FILTER`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;

export const listStateSelector = createSelector(contractorsStateSelector, (state) => state[parentModuleName]);
export const stateSelector = createSelector(listStateSelector, (state) => state[moduleName]);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const contractorsSelector = createSelector(stateSelector, (state) => state.contractors);
export const tableFiltersSelector = createSelector(stateSelector, (state) => state.tableFilters);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_CONTRACTORS:
      return {
        ...state,
        contractors: {
          count: payload.count,
          list: payload.list,
        },
      };
    case SET_MORE_CONTRACTORS:
      return {
        ...state,
        contractors: {
          ...state.contractors,
          list: [...state.contractors.list, ...payload.list],
        },
      };
    case SET_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...payload,
        },
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const setContractors = (data) => ({
  type: SET_CONTRACTORS,
  payload: data,
});

export const setMoreContractors = (data) => ({
  type: SET_MORE_CONTRACTORS,
  payload: data,
});

export const loadContractors = () => {
  return async (dispatch, getState) => {
    const tableFilters = tableFiltersSelector(getState());
    try {
      dispatch(setLoading(true));
      const response = await axios.get("/contractors/", {
        params: {
          offset: 0,
          limit: LOAD_LIMIT,
          ...tableFilters,
        },
      });

      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results),
      };
      dispatch(setContractors(preparedData));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const loadMoreContractors = () => {
  return async (dispatch, getState) => {
    const contractors = contractorsSelector(getState());
    const tableFilters = tableFiltersSelector(getState());
    try {
      const response = await axios.get("/contractors/", {
        params: {
          offset: contractors.list.length,
          limit: LOAD_LIMIT,
          ...tableFilters,
        },
      });

      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results),
      };
      dispatch(setMoreContractors(preparedData));
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteContractor = (id) => {
  return async (dispatch, getState) => {
    const contractors = contractorsSelector(getState());
    try {
      await axios.delete(`/contractors/${id}/`);
      const newContractors = {
        count: contractors.count - 1,
        list: contractors.list.filter((contractor) => contractor.id !== id),
      };
      dispatch(setContractors(newContractors));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setTableFilter = (data) => ({
  type: SET_TABLE_FILTER,
  payload: data,
});

export const clearData = () => ({
  type: CLEAR_DATA,
});
