import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { fileStorageObjectsSelector } from "redux/modules/common/filesStorage/selectors";
import { getStorageObjects } from "redux/modules/common/filesStorage/thunks";

import FileItem from "./components/FileItem/FileItem";
import fileSvg from "./components/FileItem/assets/files.svg";
import { Spinner } from "components/UI/Spinner/Spinner";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import { useUrlModule } from "utils/hooks/useUrlModule";

import styles from "./FileStorage.module.scss";

const FileStorageRootFoldersList = () => {
  const moduleRoute = useUrlModule();
  const history = useHistory();
  const dispatch = useDispatch();
  const objects = useSelector(fileStorageObjectsSelector);

  React.useEffect(() => {
    dispatch(getStorageObjects());
  }, []);

  return (
    <>
      <div className={styles.grid}>
        {objects.results?.map((el) => (
          <FileItem
            key={el.id}
            name={el.name}
            id={el.id}
            isFolder
            onOpenFolder={() => history.push(`/${moduleRoute}/files/${el.id}`)}
            filesCount={el.count_files}
            foldersCount={el.count_folders}
            isEditable={false}
          />
        ))}
        {objects.isLoading && !objects.results?.length && <Spinner />}
      </div>
      {!objects.isLoading && !objects.results?.length && <EmptyPlaceholder text="Нет файлов" img={fileSvg} />}
    </>
  );
};

export default React.memo(FileStorageRootFoldersList);
