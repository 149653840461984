import { message } from "antd";
import cn from "classnames";
import React, { useCallback, useState } from "react";

import BottomControls from "../../../../organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import UngroupExpenditureCostItem from "./components/UngroupExpenditureCostItem/UngroupExpenditureCostItem";
import UngroupExpenditureStructureItem from "./components/UngroupExpenditureStructureItem/UngroupExpenditureStructureItem";

import AddButton from "../../../../atoms/AddButton/AddButton";
import ButtonBase from "../../../../atoms/ButtonBase";
import EmptyPlaceholder from "../../../../atoms/EmptyPlaceholder/EmptyPlaceholder";
import Modal from "../../../../atoms/Modal";
import TabBarNotLinks, { ITab } from "../../../../atoms/TabBar/TabBarNotLinks";
import TableReusableHead, { TableReusableHeaderCell } from "../../../../atoms/TableReusable/TableReusableHead";
import { useUngroupExpenditureModal } from "./useUngroupExpenditureModal";

import { UNGROUP_MODAL_TABS, UngroupArrowsIcon } from "./constants";
import { ISubExpenditure } from "./types";

import { transformDigitToFinancial } from "../../../../../../utils/formatters/transformDigitToFinancial";

import styles from "./UngroupExpenditureModal.module.scss";

export interface IUngroupExpenditureModalProps {
  isOpen: boolean;
  onClose: () => void;
  expenditureId: number;
  expenditureName: string;
  expenditureNumber: number;
  measure: string;
  count: number | string;
  estimatedCost: number | string;
  budget: number | string;
  className?: string;
  ungroupSuccessCallback?: () => void;
}

const UngroupExpenditureModal: React.FC<IUngroupExpenditureModalProps> = ({
  isOpen,
  expenditureId,
  expenditureName,
  expenditureNumber,
  onClose,
  measure,
  count,
  estimatedCost,
  budget,
  className,
  ungroupSuccessCallback,
}) => {
  const [activeTab, setActiveTab] = useState<"structure" | "cost">("structure");

  const {
    subExpenditures,
    totalPercentHint,
    onAddSubExpenditure,
    onRemoveSubExpenditure,
    setIsEditSubExpenditure,
    updateSubExpenditure,
    canContinue,
    canConfirm,
    hasConfirmValidationErrors,
    onContinue,
    onConfirm,
    isConfirming,
    recalculatedEstimatedCost,
  } = useUngroupExpenditureModal({
    expenditureId,
    expenditureNumber,
    estimatedCost,
    confirmSuccessCallback: () => {
      ungroupSuccessCallback?.();
      onClose();
    },
    setActiveTab: (t: "structure" | "cost") => setActiveTab(t),
  });

  const onTabChange = useCallback(
    (newTabId: "structure" | "cost") => {
      if (subExpenditures.some((exp: ISubExpenditure) => exp._isEdit)) {
        message.warn("Все расценки должны быть подтверждены");
        return;
      }
      setActiveTab(newTabId);
    },
    [subExpenditures]
  );

  const handleConfirmButtonHover = () => {
    if (!canConfirm && !!totalPercentHint) message.info(totalPercentHint);
    else if (!canConfirm && hasConfirmValidationErrors) message.info("Заполните поля для всех расценок");
  };

  return (
    <Modal
      isOpen={isOpen}
      title={expenditureName}
      onClose={onClose}
      className={cn(styles.modal, className)}
      headerClassName={styles.modalHeader}
    >
      <div className={styles.head}>
        <div className={styles.info}>
          <div>
            <span>Ед. изм:</span>
            <span>{measure}</span>
          </div>
          <div>
            <span>Кол-во по проекту:</span>
            <span>{count}</span>
          </div>
          <div>
            <span>Рыночная стоимость:</span>
            <span>
              {transformDigitToFinancial(recalculatedEstimatedCost, { withCurrencySign: true, withFloat: true })}
            </span>
          </div>
          <div>
            <span>Бюджет по проекту:</span>
            <span>{transformDigitToFinancial(budget, { withCurrencySign: true, withFloat: true })}</span>
          </div>
        </div>
        <div className={styles.controls}>
          <TabBarNotLinks tabs={UNGROUP_MODAL_TABS} onClick={onTabChange} activeId={activeTab} isOnlyTabsView />
          {activeTab === "structure" && (
            <AddButton
              text={"Добавить позицию"}
              textPosition={"left"}
              className={styles.addButton}
              onClick={onAddSubExpenditure}
            />
          )}
        </div>
        {activeTab === "structure" && !!subExpenditures.length && (
          <TableReusableHead className={styles.tableHead}>
            <TableReusableHeaderCell>№</TableReusableHeaderCell>
            <TableReusableHeaderCell>Наименование</TableReusableHeaderCell>
            <TableReusableHeaderCell>Ед. изм</TableReusableHeaderCell>
            <TableReusableHeaderCell>Кол-во</TableReusableHeaderCell>
            <TableReusableHeaderCell>Тип</TableReusableHeaderCell>
            <TableReusableHeaderCell isCentered isLast>
              Действия
            </TableReusableHeaderCell>
          </TableReusableHead>
        )}
        {activeTab === "cost" && !!subExpenditures.length && (
          <TableReusableHead className={cn(styles.tableHead, styles.costTableHead)}>
            <TableReusableHeaderCell>№</TableReusableHeaderCell>
            <TableReusableHeaderCell>Наименование</TableReusableHeaderCell>
            <TableReusableHeaderCell>Рыночная стоимость</TableReusableHeaderCell>
            <TableReusableHeaderCell>Бюджет по проекту</TableReusableHeaderCell>
            <TableReusableHeaderCell isLast> </TableReusableHeaderCell>
          </TableReusableHead>
        )}
      </div>
      {!subExpenditures.length && (
        <EmptyPlaceholder
          text={"Добавьте позицию для разгруппировки"}
          fullHeight={false}
          svgNative={<UngroupArrowsIcon />}
          className={styles.placeholder}
        />
      )}
      {!!subExpenditures.length && (
        <div className={styles.body}>
          {activeTab === "structure" &&
            subExpenditures.map((subExpenditure) => (
              <UngroupExpenditureStructureItem
                key={subExpenditure._key}
                subExpenditure={subExpenditure}
                onRemove={() => onRemoveSubExpenditure(subExpenditure.number)}
                isEdit={subExpenditure._isEdit!}
                onEdit={() => setIsEditSubExpenditure(subExpenditure.number, true)}
                onEditCancel={() => onRemoveSubExpenditure(subExpenditure.number)}
                updateSubExpenditure={(data: Partial<ISubExpenditure>) =>
                  updateSubExpenditure(subExpenditure.number, data)
                }
                onEditSuccess={(data: Partial<ISubExpenditure>) => {
                  updateSubExpenditure(subExpenditure.number, { ...data, _isEdit: false });
                }}
              />
            ))}
          {activeTab === "cost" &&
            subExpenditures.map((subExpenditure) => (
              <UngroupExpenditureCostItem
                key={subExpenditure._key}
                subExpenditure={subExpenditure}
                isEdit={subExpenditure._isEdit!}
                onEdit={() => setIsEditSubExpenditure(subExpenditure.number, true)}
                onEditSuccess={(data: Partial<ISubExpenditure>) => {
                  updateSubExpenditure(subExpenditure.number, { ...data, _isEdit: false });
                }}
                updateSubExpenditure={(data: Partial<ISubExpenditure>) => {
                  updateSubExpenditure(subExpenditure.number, data);
                }}
                budget={budget}
                estimatedCost={recalculatedEstimatedCost}
              />
            ))}
        </div>
      )}
      <BottomControls isExists className={styles.bottomControls}>
        {activeTab === "structure" && (
          <ButtonBase onClick={onContinue} disabled={!canContinue} primary className={styles.bottomControlsButton}>
            Продолжить
          </ButtonBase>
        )}
        {activeTab === "cost" && (
          <div onMouseEnter={handleConfirmButtonHover}>
            <ButtonBase
              onClick={onConfirm}
              disabled={!canConfirm}
              primary
              className={styles.bottomControlsButton}
              isLoading={isConfirming}
            >
              Подтвердить
            </ButtonBase>
          </div>
        )}
      </BottomControls>
    </Modal>
  );
};

export default React.memo(UngroupExpenditureModal);
