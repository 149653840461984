import React from "react";

import Select from "../../../../../../UI/atoms/Select";

import BillPayment from "./BillPayment";
import styles from "./BillPayment.module.scss";
import permissions from "../../../../../Settings/components/Users/UserPage/components/PermissionsSettings/components/ManagePermissionsModal/components/Permissions/Permissions";

const options = [
  {
    id: "all",
    name: "Показать все",
    label: "Показать все",
  },
  {
    id: "paid",
    name: "Оплачен",
    label: "Оплачен",
  },
  {
    id: "waiting",
    name: "Ожидает оплаты",
    label: "Ожидает оплаты",
  },
];

function BillPaymentList({ payments, parentId, permissions, isSimplified }) {
  const [activeType, setActiveType] = React.useState("all");
  const [isExpanded, setIsExpanded] = React.useState(false);

  const filteredPayments = React.useMemo(() => {
    if (!Array.isArray(payments)) return [];
    if (activeType === "all") {
      return payments;
    } else if (activeType === "paid") {
      return payments.filter((el) => el.paid);
    } else if (activeType === "waiting") {
      return payments.filter((el) => !el.paid);
    }
  }, [activeType, payments]);

  const slicedPayments = React.useMemo(() => {
    if (isExpanded || filteredPayments.length < 6) {
      return filteredPayments;
    } else {
      return filteredPayments.slice(0, 5);
    }
  }, [filteredPayments, isExpanded]);

  const paymentsAmounts = React.useMemo(() => {
    return {
      paid: filteredPayments.filter((el) => el.paid).length - slicedPayments.filter((el) => el.paid).length,
      waiting: filteredPayments.filter((el) => !el.paid).length - slicedPayments.filter((el) => !el.paid).length,
    };
  }, [filteredPayments, slicedPayments]);

  return (
    <>
      <div className={styles.paysHead}>
        <span>Платежи: {filteredPayments?.length}</span>
        <Select className={styles.selectWidth} options={options} value={activeType} onChange={setActiveType} />
        {isExpanded && (
          <div className={styles.hide} onClick={() => setIsExpanded(false)}>
            Скрыть
          </div>
        )}
      </div>
      <div className={styles.list}>
        {slicedPayments.map((payment) => (
          <BillPayment
            parentId={parentId}
            payment={payment}
            permissions={permissions}
            isSimplified={isSimplified}
            key={payment.id}
          />
        ))}
        {!isExpanded && filteredPayments?.length > 5 && (
          <div className={`${styles.container}`}>
            <div className={styles.moreTypes}>
              <div className={styles.isPaid}>
                <div style={{ marginRight: "0.1rem" }}>Оплачен:</div>
                <div>{paymentsAmounts.paid}</div>
              </div>
              <div className={styles.isWaiting}>
                <div style={{ marginRight: "0.1rem" }}>Ожидает оплаты:</div>
                <div>{paymentsAmounts.waiting}</div>
              </div>
            </div>
            <div style={{ margin: "1.76rem 0" }} className={styles.more}>
              Еще {paymentsAmounts.paid + paymentsAmounts.waiting}
            </div>
            <button onClick={() => setIsExpanded(true)} className={styles.btn}>
              Показать
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(BillPaymentList);
