import React, { useMemo } from "react";

import SwitchedNotification from "./SwitchedNotification";

import { NOTIFICATION_GROUP_NAMES } from "../../../constants";
import { ChangeNotificationConfigFnType, INotificationConfig, NotificationOptionType } from "../../../types/types";

import styles from "../ManageNotificationsModal.module.scss";

export interface INotificationsInModalProps {
  options: INotificationConfig[];
  onChangeChecked: ChangeNotificationConfigFnType;
  canEdit: boolean;
  type: NotificationOptionType;
}

const NotificationsInModal: React.FC<INotificationsInModalProps> = ({ options, onChangeChecked, canEdit, type }) => {
  const optionsToDisplay = useMemo(
    () =>
      options
        .map((option) => ({ ...option, group: NOTIFICATION_GROUP_NAMES[option.group] || option.group }))
        ?.sort((a, b) => {
          return a.group > b.group ? 1 : -1;
        }),
    [options]
  );

  return (
    <div className={styles.notifications}>
      {optionsToDisplay.map((option, index) => (
        <React.Fragment key={option.class_name}>
          {option.group && (index === 0 || option.group !== optionsToDisplay[index - 1]?.group) && (
            <span className={styles.groupLabel}>{option.group}</span>
          )}
          <SwitchedNotification option={option} onChangeChecked={onChangeChecked} canEdit={canEdit} type={type} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(NotificationsInModal);
