import { message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { downloadForecastBdr } from "redux/modules/common/constructing/finance/forecast/thunks";

import ButtonBase from "components/UI/atoms/ButtonBase";
import FormItem from "components/UI/atoms/FormItem/FormItem";
import Modal from "components/UI/atoms/Modal";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import MonthSelect from "shared/ui/inputs/MonthSelect/MonthSelect";

import styles from "./BdrModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: number;
}

const BdrModal: React.FC<IProps> = ({ isOpen, onClose, buildingId }) => {
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = useState<moment.Moment>();
  const [dateEnd, setDateEnd] = useState<moment.Moment>();

  const submitHandler = () => {
    if (!dateEnd || !dateStart) return;
    if (moment(dateStart).isAfter(moment(dateEnd))) {
      message.error("Дата начала должна быть раньше даты конца");
      return;
    }
    dispatch(downloadForecastBdr(buildingId, dateStart.format("YYYY-MM-DD"), dateEnd.format("YYYY-MM-DD")));
  };

  return (
    <Modal title="Укажите период выгрузки БДР" isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <div className={styles.content}>
        <FormItem title="С какого периода">
          <MonthSelect date={dateStart!} onChange={setDateStart} />
        </FormItem>
        <FormItem title="По какой период">
          <MonthSelect date={dateEnd!} onChange={setDateEnd} />
        </FormItem>
      </div>
      <div className={styles.bottom}>
        <ButtonBase onClick={onClose} secondary>
          Отмена
        </ButtonBase>
        <ButtonBase onClick={submitHandler} primary disabled={!dateEnd || !dateStart}>
          Скачать БДР
        </ButtonBase>
      </div>
    </Modal>
  );
};

export default BdrModal;
