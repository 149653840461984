import React from "react";

import TemplateSimple from "../../UI/templates/TemplateSimple/TemplateSimple";

import PackingList from "./PackingList";

const TemplatedPackingList = (props) => (
  <TemplateSimple>
    <PackingList {...props} />
  </TemplateSimple>
);

export default React.memo(TemplatedPackingList);
