import cn from "classnames";
import React from "react";

import Button from "components/UI/atoms/ButtonBase";

import styles from "./index.module.scss";

interface IProps {
  allCount?: number;
  showedCount?: number;
  handleAdd: () => void;
  limit?: number;
  isExists: boolean;
  isLoading?: boolean;
}

const ShowMoreButton: React.FC<IProps> = ({
  allCount = 0,
  showedCount = 0,
  handleAdd,
  limit = 10,
  isExists = true,
  isLoading,
}) => {
  if (!isExists) return null;

  return (
    <>
      {allCount > showedCount && (
        <div className={styles.boxButtonPaginator}>
          <Button
            primary
            onClick={handleAdd}
            className={cn(styles.buttonPaginator, { [styles.isLoading]: isLoading })}
            isLoading={isLoading}
          >
            Показать еще {allCount - showedCount > limit ? limit : allCount - showedCount} из {allCount - showedCount}
          </Button>
        </div>
      )}
    </>
  );
};

export default ShowMoreButton;
