import cn from "classnames";
import React from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";

import styles from "./FilesNavBar.module.scss";

interface IProps {
  canBack?: boolean;
  canCreateFolder?: boolean;
  onCreateNewFolder?: () => void;
  onGoBack: () => void;
  text?: string;
}

const FilesNavBar: React.FC<IProps> = ({ canBack, canCreateFolder, onCreateNewFolder, onGoBack, text }) => {
  if (!canCreateFolder && !canBack) return null;

  return (
    <div className={cn(styles.bar, { [styles.withoutBack]: !canBack })}>
      {canBack && <NavBar onClick={onGoBack} isExist text={text || "Все проекты"} />}
      {canCreateFolder && (
        <ButtonBase secondary onClick={onCreateNewFolder} className={cn({ [styles.rightBtn]: !canBack })}>
          Создать папку
        </ButtonBase>
      )}
    </div>
  );
};

export default React.memo(FilesNavBar);
