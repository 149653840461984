import { message } from "antd";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { extraForecastApi } from "./extraForecastApi";
import { extraForecastActions } from "./extraForecastSlice";

import { IAddExtraExpenseForm } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createExtraForecast = (buildingId: string, data: IAddExtraExpenseForm) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ buildingId, type: "create" });
  dispatch(extraForecastActions.setIsPending({ status: true, key }));
  extraForecastApi
    .create(buildingId, data)
    .then(() => {
      message.success("Сохранено");
      dispatch(extraForecastActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(extraForecastActions.setIsPending({ status: false, key }));
    });
};

export const getDetailedExtraForecast =
  (buildingId: string, id: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ buildingId, type: "detail", expenditureId: id });
    if (!getState().extraForecast.detailedExpenses[key]) {
      dispatch(extraForecastActions.setIsPending({ status: true, key }));
    }
    extraForecastApi
      .getDetail(buildingId, id)
      .then(({ data }) => {
        dispatch(extraForecastActions.setDetailed({ key, data }));
      })
      .finally(() => {
        dispatch(extraForecastActions.setIsPending({ status: false, key }));
      });
  };

export const editExtraForecast =
  (buildingId: string, id: number, data: IAddExtraExpenseForm) => (dispatch: Dispatch) => {
    const key = generateStorageKey({ buildingId, type: "edit" });
    dispatch(extraForecastActions.setIsPending({ status: true, key }));
    extraForecastApi
      .edit(buildingId, id, data)
      .then(() => {
        message.success("Сохранено");
        dispatch(extraForecastActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(extraForecastActions.setIsPending({ status: false, key }));
      });
  };

export const refetchExtraForecast = (buildingId: string) => (dispatch: Dispatch) => {
  extraForecastApi.refetchExtra(buildingId).then(({ data }) => {
    dispatch(extraForecastActions.setExtraExpense({ key: buildingId, data: data.extra_costs }));
  });
};

export const deleteExtraForecast = (buildingId: string, id: number) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ buildingId, type: "delete" });
  dispatch(extraForecastActions.setIsPending({ status: true, key }));
  extraForecastApi
    .delete(buildingId, id)
    .then(() => {
      message.success("Удалено");
      dispatch(extraForecastActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(extraForecastActions.setIsPending({ status: false, key }));
    });
};
