import { IActions } from "redux/store";

import { IHandlerIndex } from "./types";

export const handlerIndexesActions = {
  setIndexes: (sectionId: number, indexes: IHandlerIndex[]) =>
    ({
      type: "handlerIndexes/SET_INDEXES",
      payload: { sectionId, indexes },
    } as const),
  setLoading: (key: string, status: boolean) =>
    ({
      type: "handlerIndexes/SET_LOADING",
      payload: { key, status },
    } as const),
  invalidateKey: () =>
    ({
      type: "handlerIndexes/INVALIDATE",
      payload: true,
    } as const),
};

export type IHandlerIndexesActions = IActions<typeof handlerIndexesActions>;
