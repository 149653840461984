import React from 'react'
import { Spinner } from '../../../../../UI/Spinner/Spinner'
import SubTableHeader from './SubTableHeader/SubTableHeader'
import SubTableRow from './SubTableRow/SubTableRow'
import { useSubTableUsing } from './useSubTableUsing'

function SubTableUsing({ limit, stockusing, measure = 1000 }) {
  const { isLoading, items } = useSubTableUsing({ usingId: stockusing, limit })

  if (!stockusing) return null

  return (
    <>
      <SubTableHeader />
      {isLoading && <Spinner />}
      {items?.map((el) => (
        <SubTableRow
          key={el.id}
          name={el.stock_product?.product_building?.name}
          measureMoney={measure}
          measureProduct={el.stock_product?.product_building?.measure}
          count={el.count}
          amount={el.amount}
        />
      ))}
    </>
  )
}

export default React.memo(SubTableUsing)