import moment from "moment";

import { IAuditAction } from "./actions";

import { IAuditInitialState } from "./types";

const initialState: IAuditInitialState = {
  isLoading: false,
  allProjectsAuditList: [],
  detailedProjectAudits: {},
  tree: [],
  dates: {
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  tableHighlighting: {
    positive: false,
    negative: true,
  },
  auditType: "summary",
  isLoadingByObjects: {},
  detailedProjectsV2: {},
};

export default (state = initialState, action: IAuditAction): IAuditInitialState => {
  const { type, payload } = action;
  switch (type) {
    case "constructing/finance/audit_SET_LOADING":
      return {
        ...state,
        isLoading: payload,
      };
    case "constructing/finance/audit_CLEAR_DATA":
      return {
        ...initialState,
      };
    case "constructing/finance/audit_SET_ALL_PROJECTS_AUDITS":
      return {
        ...state,
        allProjectsAuditList: payload,
      };
    case "constructing/finance/audit_SET_DETAILED_PROJECT_AUDIT":
      return {
        ...state,
        detailedProjectAudits: { ...state.detailedProjectAudits, [payload.objectId]: payload.audit },
      };
    case "constructing/finance/audit_SET_AUDIT_TREE":
      return {
        ...state,
        tree: payload,
      };
    case "constructing/finance/audit_SET_DATES":
      return {
        ...state,
        dates: payload,
      };
    case "constructing/finance/audit_SET_AUTIT_TYPE":
      return {
        ...state,
        auditType: payload,
      };
    case "constructing/finance/audit_SET_IS_LOADING_BY_OBJECT":
      return {
        ...state,
        isLoadingByObjects: {
          ...state.isLoadingByObjects,
          [payload.objectId]: payload.status,
        },
      };
    ////////
    case "constructing/finance/audit_SET_DETAILED_BY_PROJECT_V2":
      return {
        ...state,
        detailedProjectsV2: {
          ...state.detailedProjectsV2,
          [payload.projectId]: payload.data,
        },
      };
    default:
      return state;
  }
};
