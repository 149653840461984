import { createSelector } from "reselect";

import { financeStateSelector, stateSelector as indexStateSelector } from "../finance";

import { prepareSummaryTableData } from "./utils";

export const stateSelector = createSelector(financeStateSelector, (state) => state.summary);
export const objectIdSelector = createSelector(indexStateSelector, (state) => state.objectId);

export const summaryYearSelector = createSelector(stateSelector, (state) => state.year);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const summaryTreeSelector = createSelector(stateSelector, (state) => state.tree);
export const allProjectsSummarySelector = createSelector(stateSelector, (state) => state.allProjectsSummaryList);
export const detailedProjectSummarySelector = createSelector(stateSelector, (state) => state.detailedProjectSummary);

export const tableScrollPositionSelector = createSelector(stateSelector, (state) => state.tableScrollPosition);
export const tableViewWidthPercentSelector = createSelector(stateSelector, (state) => state.tableViewWidthPercent);
export const summaryTableHighlightingSelector = createSelector(stateSelector, (state) => state.tableHighlighting);

export const summaryTableDataSelector = createSelector(
  summaryTreeSelector,
  summaryYearSelector,
  objectIdSelector,
  prepareSummaryTableData
);

export const summaryIsLoadingByObjectsSelector = createSelector(stateSelector, (state) => state.isLoadingByObjects);
export const summaryByProjectV2Selector = createSelector(stateSelector, (state) => state.detailedProjectSummaryV2);
