import cn from "classnames";
import moment from "moment";
import React, { Fragment } from "react";

import { ICalendarDateLineProps } from "../../CalendarDateLine";

import { DAYS_ABBRS } from "../../../../constants";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import { generateWeeksForLine, getDaysInYear, getWeeksInYear } from "../../../../utils";

import styles from "./WeeksLine.module.scss";

const WeeksLine = React.forwardRef<Record<number | string, HTMLDivElement>, ICalendarDateLineProps>(
  ({ year, unitOffset }, ref) => {
    const unitMultiplier = useUnitMultiplier();
    const weeks = generateWeeksForLine(+year);
    return (
      <div
        className={styles.weeksLine}
        ref={(r) => {
          if (!ref.current) return;
          ref.current[year] = r;
        }}
        style={{ left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
      >
        {weeks?.map((item, index) => {
          const today = moment().isBetween(moment(item.week.day(0)), moment(item.week.day(6)))
            ? moment().weekday()
            : undefined;
          return (
            <Fragment key={index}>
              <div
                className={cn(styles.weekCaptionBlock, {
                  startMonth: item.isNewMonth,
                })}
                style={{
                  left: `${index * 7 * unitMultiplier}rem`,
                  width: `${7 * unitMultiplier}rem`,
                }}
                data-year={year}
                data-month={item.week.month()}
              >
                <span>Неделя {index + 1}</span>
                <span className={styles.weekInterval}>
                  {item.week.weekday(0).format("DD.MM")}-{item.week.weekday(6).format("DD.MM")} {year}
                </span>
              </div>
              {today !== undefined && (
                <div
                  className={styles.currentWeekDay}
                  style={{
                    left: `${(index * 7 + today) * unitMultiplier}rem`,
                  }}
                >
                  <span>{DAYS_ABBRS[today]}</span>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  }
);
export default React.memo(WeeksLine);
