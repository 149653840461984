import React, { MouseEventHandler, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  dropHighlightRelatedIntervals,
  highlightRelatedIntervals,
  setArrowHash,
} from "../../../../../../../redux/modules/common/building/manufacturing/thunks";

import SharedBrace from "./components/SharedBrace/SharedBrace";

import {
  INTERVAL_TYPES,
  SharedBraceStatuses,
  TAILS_OFFSET_COMPENSATION_REM,
  TAILS_WIDTH_COMPENSATION_REM,
} from "../../../../constants";

import { IWithEditingProps, withEditing } from "./utils/withEditing";
import { IWithLinkingProps, withLinking } from "./utils/withLinking";

import styles from "../../Month.module.scss";

export interface IDiagramIntervalProps extends IWithLinkingProps, IWithEditingProps {
  day: number;
  daysLength: number;
  unitMultiplier: number;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  onMouseMove?: MouseEventHandler;
  children: React.ReactNode;
  wrapperStyles: Object;
  offsetLeft: number;
  hover: boolean;
  setHover: (isHover: boolean) => void;
  intervalWrapperRef: React.RefObject<HTMLDivElement>;
  intervalSharedStatus?: keyof typeof SharedBraceStatuses;
  isShared?: boolean;
  isFromProvider?: boolean;
  isCardOpen?: boolean;
  type: keyof typeof INTERVAL_TYPES;
  intervalId?: number;
  expenditureId?: number;
  projectId: number | null;
  startDate?: string;
  endDate?: string;
  onAddArrowCallback?: () => void;
  popupRef?: React.RefObject<HTMLElement>;
  innerBeforeContent?: React.ReactNode;
  innerAfterContent?: React.ReactNode;
  afterContent?: React.ReactNode;
  isGroup?: boolean;
  isSectionPlan?: boolean;
}

const DiagramInterval: React.FC<IDiagramIntervalProps> = (props) => {
  const {
    day,
    daysLength,
    unitMultiplier,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    children,
    wrapperStyles,
    offsetLeft,
    hover,
    setHover,
    intervalWrapperRef,
    intervalSharedStatus,
    isCardOpen,
    intervalId,
    projectId,
    popupRef,
    isLinkingEnabled,
    isLinkingVisible,
    handleIntervalClickLinking,
    innerBeforeContent,
    innerAfterContent,
    afterContent,
  } = props;

  const dispatch = useDispatch();

  const intervalLeftRem = props.intervalLeftRem !== undefined ? props.intervalLeftRem : (day - 1) * unitMultiplier;
  const intervalWidthRem =
    props.intervalWidthRem !== undefined ? props.intervalWidthRem : Math.max(daysLength, 1) * unitMultiplier;

  const mergedWrapperStyles = {
    transform: `translateX(calc(${intervalLeftRem - TAILS_OFFSET_COMPENSATION_REM}rem + ${offsetLeft}px))`,
    width: `${intervalWidthRem + TAILS_WIDTH_COMPENSATION_REM}rem`,
    ...wrapperStyles,
  };

  const highlightChain = useCallback(() => {
    if (!isLinkingEnabled) return;
    dispatch(highlightRelatedIntervals({ intervalId, projectId }));
  }, [intervalId, projectId, isLinkingEnabled]);

  const removeHighlightChain = useCallback(() => dispatch(dropHighlightRelatedIntervals()), []);

  const movePopover = (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
    requestAnimationFrame(() => {
      if (!popupRef?.current || daysLength < 2 || isCardOpen || !intervalWrapperRef.current) return;
      const bounds = intervalWrapperRef.current.getBoundingClientRect();
      const x = Math.max(bounds.left + 8, Math.min(e.clientX, bounds.right - 8));
      popupRef.current.style.left = `${x}px`;
      popupRef.current.style.transform = `translateX(-50%)`;
    });
  };

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
      !hover && setHover(true);
      highlightChain();
      if (onMouseEnter) onMouseEnter(e);
      movePopover(e);
    },
    [onMouseEnter, hover, highlightChain]
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
      setHover(false);
      removeHighlightChain();
      if (onMouseLeave) onMouseLeave(e);
    },
    [onMouseLeave, isCardOpen, removeHighlightChain]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
      if (!hover) setHover(true);
      if (onMouseMove) onMouseMove(e);
      movePopover(e);
    },
    [onMouseMove, hover]
  );

  const handlePreventDefault = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (!isLinkingVisible) return;
    dispatch(setArrowHash(Math.random()));
  }, [isLinkingVisible]);

  const onIntervalClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleIntervalClickLinking?.(e);
    },
    [handleIntervalClickLinking]
  );

  return (
    <>
      <div
        ref={intervalWrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className={styles.diagramIntervalWrapper}
        style={mergedWrapperStyles}
        onDragOver={handlePreventDefault}
        onClick={onIntervalClick}
        onMouseUp={onIntervalClick}
      >
        {innerBeforeContent}
        {children}
        {intervalSharedStatus && <SharedBrace status={intervalSharedStatus} />}
        {innerAfterContent}
      </div>
      {afterContent}
    </>
  );
};

export default React.memo(DiagramInterval);
