import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";

import { BetonExpenseStatusNamesEnum, IBetonExpenseItem } from "redux/modules/common/building/beton/types";

import FilesClipButton from "components/UI/atoms/FilesClipButton/FilesClipButton";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import BetonExpenseModal from "../BetonExpenseModal/BetonExpenseModal";

import styles from "./../../BetonExpense.module.scss";

interface IProps {
  item: IBetonExpenseItem;
  stockId: number;
}

const BetonExpenseRow: React.FC<IProps> = ({ item, stockId }) => {
  const [isModal, setIsModal] = useState(false);

  return (
    <>
      <TableReusableRow className={styles.row} onClick={() => setIsModal(true)}>
        <TableReusableCell isNoBreak>{item.name}</TableReusableCell>
        <TableReusableCell>{moment(item.created_at).format("DD.MM.YYYY HH:mm")}</TableReusableCell>
        <TableReusableCell isCentered>{item.count}</TableReusableCell>
        {/*<TableReusableCell>*/}
        {/*  <div className={styles.axis}>*/}
        {/*    <div>АПН</div>*/}
        {/*    <div>*/}
        {/*      <div className={styles.divider} />*/}
        {/*    </div>*/}
        {/*    <div>123-4</div>*/}
        {/*  </div>*/}
        {/*</TableReusableCell>*/}
        <TableReusableCell isNoBreak>{item.user ?? item.worker}</TableReusableCell>
        <TableReusableCell>
          <FilesClipButton count={item.files} className={cn({ [styles.disabledFiles]: !item.files })} />
        </TableReusableCell>
        <TableReusableCell>{BetonExpenseStatusNamesEnum[item.status]}</TableReusableCell>
      </TableReusableRow>
      {isModal && (
          <BetonExpenseModal isOpen={isModal} onClose={() => setIsModal(false)} item={item} stockId={stockId} />
      )}
    </>
  );
};

export default BetonExpenseRow;
