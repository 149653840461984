import React, { useCallback, useMemo, useState } from "react";

import PlusFillCircleIcon from "../../../../../../images/icons/PlusFillCircleIcon";

import ButtonBase from "../../../../../UI/atoms/ButtonBase";
import AddProductsModal from "../../../../../UI/molecules/AddProductsModal/AddProductsModal";

import VirtualProviders from "../../VirtualProviders";
import styles from "../index.module.scss";

const Actions = ({
  buildingId,
  requisitionExecutor,
  requisitionIsDraft,
  requisitionNotApprove,
  isViewModeMonitoring,
  products,
  productsChecker,
  onCreateOrder,
  onAddProducts,
  onAddVirtualProviders,
  permissions,
}) => {
  const [isAddProductsOpen, setIsAddProductsOpen] = useState(false);

  const [isVirtualProvidersOpen, setIsVirtualProvidersOpen] = useState(false);
  const openVirtualProviders = useCallback(() => setIsVirtualProvidersOpen(true), []);
  const closeVirtualProviders = useCallback(() => setIsVirtualProvidersOpen(false), []);

  const checkedProductsHaveProvider = useMemo(
    () =>
      products
        .filter((product) => productsChecker.items[product.id])
        .every((product) => product.requested_providers && product.requested_providers.length !== 0),
    [productsChecker.items, products]
  );

  return (
    <div className={styles.productsHeaderTableAction}>
      <div className={styles.leftSide}>
        {(!requisitionNotApprove || permissions.viewEditRequisitionBeforeApprove) && (
          <>
            <div className={styles.title}>Добавить товары:</div>
            <div onClick={() => setIsAddProductsOpen(true)} className={styles.icon}>
              <PlusFillCircleIcon width="23" />
            </div>
          </>
        )}
        {products.length > 0 && <span>Всего: {products.length}</span>}
        {!requisitionIsDraft && !isViewModeMonitoring && (
          <ButtonBase
            onClick={openVirtualProviders}
            disabled={productsChecker.checkedCount === 0 || !requisitionExecutor}
            className={styles.chooseProviderButton}
            primary
            medium
          >
            Выбрать поставщика
          </ButtonBase>
        )}
      </div>
      {!requisitionIsDraft && !isViewModeMonitoring && (
        <ButtonBase
          primary
          medium
          onClick={onCreateOrder}
          disabled={productsChecker.checkedCount === 0 || !requisitionExecutor || !checkedProductsHaveProvider}
          className={styles.chooseProviderButton}
        >
          Оформить заказ
        </ButtonBase>
      )}
      {isAddProductsOpen && (
        <AddProductsModal
          isOpen={isAddProductsOpen}
          closeAddProductsBlock={() => setIsAddProductsOpen(false)}
          addProducts={onAddProducts}
          objectId={buildingId}
          permissions={permissions}
        />
      )}
      {isVirtualProvidersOpen && (
        <VirtualProviders
          onClose={closeVirtualProviders}
          addVirtualProviders={onAddVirtualProviders}
          canCreateVirtualProviders={permissions.viewCreateRequisitionProductsProvider}
          isOpen
        />
      )}
    </div>
  );
};

export default React.memo(Actions);
