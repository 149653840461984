import { IPassportActions } from "./actions";

import { IPassportsState } from "./types";

export const passportsInitialState: IPassportsState = {
  invalidateKey: 1,
  documents: {},
  isPending: false,
  expenditureAttachments: {},
  ks2Attachments: {},
  sectionAttachments: {},
  ks2ExpenditureAttachments: {},
};

export default (state = passportsInitialState, action: IPassportActions): IPassportsState => {
  const { type, payload } = action;
  switch (type) {
    case "passports/SET_DOCUMENTS":
      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.productId]: { documents: payload.documents, isLoading: false },
        },
      };
    case "passports/SET_LOADING":
      return {
        ...state,
        documents: {
          ...state.documents,
          [payload.productId]: {
            ...(state.documents[payload.productId] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "passports/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "passports/SET_PENDING":
      return {
        ...state,
        isPending: payload,
      };
    case "passports/SET_ATTACHMENTS":
      return {
        ...state,
        expenditureAttachments: {
          ...state.expenditureAttachments,
          [payload.expenditureId]: { results: payload.attachments, isLoading: false },
        },
      };
    case "passports/SET_ATTACHMENTS_LOADING":
      return {
        ...state,
        expenditureAttachments: {
          ...state.expenditureAttachments,
          [payload.expenditureId]: {
            ...(state.expenditureAttachments[payload.expenditureId] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "passports/SET_KS2_ATTACHMENTS":
      return {
        ...state,
        ks2Attachments: {
          ...state.ks2Attachments,
          [payload.ks2Id]: { results: payload.attachments, isLoading: false },
        },
      };
    case "passports/SET_KS2_SECTION_ATTACHMENTS":
      return {
        ...state,
        sectionAttachments: {
          ...state.sectionAttachments,
          [`${payload.ks2Id}_${payload.sectionId}`]: { results: payload.attachments, isLoading: false },
        },
      };
    case "passports/SET_KS2_EXPENDITURE_ATTACHMENTS":
      return {
        ...state,
        ks2ExpenditureAttachments: {
          ...state.ks2ExpenditureAttachments,
          [`${payload.ks2Id}_${payload.expenditureId}`]: { ...payload.attachments },
        },
      };
    default:
      return state;
  }
};
