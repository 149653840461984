import React from "react";

import { IAttachmentInExpenditure } from "redux/modules/common/passportsAndCertificates/types";

import PassportsRow from "./PassportsRow";
import PassportsTableHead from "./PassportsTableHead";

interface IProps {
  docs?: IAttachmentInExpenditure[];
  expenditureId?: number;
  onDirectlyDelete?: (docId: number) => void;
  canDelete?: boolean;
}

const PassportsTable: React.FC<IProps> = ({ docs, onDirectlyDelete, expenditureId, canDelete = true }) => {
  if (!docs?.length) return null;

  return (
    <>
      <PassportsTableHead />
      {docs?.map((el) => (
        <PassportsRow
          key={el.id}
          expenditureId={expenditureId}
          onDirectlyDelete={onDirectlyDelete}
          document={el}
          canDelete={canDelete}
        />
      ))}
    </>
  );
};

export default PassportsTable;
