import React from "react";

import { Spinner } from "components/UI/Spinner/Spinner";

import ListRow from "./_internal/ListRow";

import styles from "./_internal/ListRow.module.scss";

const List = (props) => {
  const { data, depth = 0, isLoading, handleListItemClick, isExtra, renderExtraControl } = props;

  if (isLoading && !data?.length)
    return (
      <div className={styles.loader}>
        <Spinner isStatic />
      </div>
    );

  return (
    <>
      {data?.map((section) => {
        const isDepth2 =
          section.name === "Авансирование" ||
          section.name === "Гарантийные обязательства" ||
          section.name === "Итого по проекту";

        return (
          <React.Fragment key={section.id}>
            <ListRow
              data={section}
              depth={isDepth2 ? 2 : depth}
              handleListItemClick={handleListItemClick}
              isExtra={section.isExtra || isExtra}
              renderExtraControl={renderExtraControl}
              isRoot={section.isRoot}
            />
            {section.isOpen && (
              <List
                data={section.children ?? section.subsections ?? section.costs}
                depth={depth + 1}
                handleListItemClick={handleListItemClick}
                isExtra={section.isExtra || isExtra}
                renderExtraControl={renderExtraControl}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default List;
