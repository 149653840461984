import cn from "classnames";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InputMask from "react-input-mask";

import DatePicker from "./components/DatePicker/DatePicker";
import MonthPicker from "./components/MonthPicker/MonthPicker";

import Icon from "../../Icon/Icon";

import useOnClickOutside from "../../../../hooks/useOnClickOutside";

import { parseDateInputMask } from "../../../../utils/formatters/parseInputMask";

import activeCalendar from "../../../../images/icons/activeCalendar.svg";
import activeCalendarBlue from "../../../../images/icons/activeCalendarBlue.png";
import disCalendar from "../../../../images/icons/disCalendar.svg";

import styles from "./CalendarRange.module.scss";

const DATE_PATTERN = /(\d{2}\.){2}\d{4}/;
const DATE_FORMAT = "DD.MM.YYYY";

const validateFormattedDate = (date: string) => {
  const [day, month, year] = date.split(".");
  if ([day, month, year].some((x) => !x)) return false;
  return moment(date, DATE_FORMAT).isValid();
};

interface IProps {
  defaultDateStart?: string | Moment;
  setDefaultDateStart?: (date: string) => void;
  defaultDateEnd?: string | Moment;
  setDefaultDateEnd?: (date: string) => void;
  classNameSelect?: string;
  classNamePopup?: string;
  disabled?: boolean;
  isTooRight?: boolean;
  containerClassName?: string;
  label?: string;
  isTooLeft?: boolean;
  skipValidation?: boolean;
  isHiddenInput?: boolean;
  children?: React.ReactNode;
  clearInvalidateKey?: number;
}

const CalendarRange: React.FC<IProps> = ({
  defaultDateStart,
  setDefaultDateStart,
  defaultDateEnd,
  setDefaultDateEnd,
  classNameSelect,
  classNamePopup,
  disabled = false,
  isTooRight,
  containerClassName,
  label,
  isTooLeft,
  skipValidation,
  isHiddenInput = false,
  children,
  clearInvalidateKey,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const hasToShowInterval = useRef<boolean>(false);

  const [dateStart, setDateStart] = useState<Moment>(() => moment(defaultDateStart));
  const [dateEnd, setDateEnd] = useState<Moment>(() => moment(defaultDateEnd));

  const [navigationDate, setNavigationDate] = useState<Moment>(moment(defaultDateStart));

  const [isOpen, setIsOpen] = useState(false);
  const setToggle = useCallback(() => setIsOpen((prevState) => !prevState), []);
  useOnClickOutside(ref, () => setIsOpen(false));

  const [activeDatePoint, setActiveDatePoint] = useState<"start" | "end">("start");
  const [error, setError] = useState<string | null>(null);

  const formattedDateRange = useMemo(
    () => `${moment(dateStart).format(DATE_FORMAT)}-${moment(dateEnd).format(DATE_FORMAT)}`,
    [dateStart, dateEnd]
  );

  const [rawInputValue, setRawInputValue] = useState<string>(formattedDateRange);

  useEffect(() => {
    setDateStart(() => moment(defaultDateStart));
    setDateEnd(() => moment(defaultDateEnd));
    setNavigationDate(() => moment(defaultDateStart));
    setActiveDatePoint("start");
    setIsOpen(false);
    setRawInputValue(formattedDateRange);
    hasToShowInterval.current = false;
    setError(null);
  }, [clearInvalidateKey]);

  useEffect(() => {
    if (!defaultDateStart || !defaultDateEnd) return;
    const defaultStartMoment = moment(defaultDateStart);
    const defaultEndMoment = moment(defaultDateEnd);
    if (!skipValidation && (!defaultStartMoment.isValid() || !defaultEndMoment.isValid())) {
      setError("Период указан некорректно");
      return;
    }
    setNavigationDate((prev) => {
      if (moment(prev).isValid()) {
        return prev;
      }
      return defaultStartMoment;
    });
    setDateStart(defaultStartMoment);
    setDateEnd(defaultEndMoment);
    setRawInputValue(`${defaultStartMoment.format(DATE_FORMAT)}-${defaultEndMoment.format(DATE_FORMAT)}`);
  }, [defaultDateStart, defaultDateEnd, skipValidation]);

  const date = useMemo(
    () => (activeDatePoint === "start" ? moment(dateStart) : moment(dateEnd)),
    [dateStart, dateEnd, activeDatePoint]
  );

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const [intervalStart, intervalEnd] = e.target.value?.split("-") || [];
      const isValidStart = intervalStart?.match(DATE_PATTERN) && validateFormattedDate(intervalStart);
      const isValidEnd = intervalEnd?.match(DATE_PATTERN) && validateFormattedDate(intervalEnd);
      if (isValidEnd && isValidStart) {
        const intervalStartMoment = moment(intervalStart, DATE_FORMAT);
        const intervalEndMoment = moment(intervalEnd, DATE_FORMAT);
        if (intervalStartMoment.isAfter(intervalEndMoment)) {
          !skipValidation && setError("Дата начала должна предшествовать дате окончания");
        } else {
          if (!intervalStartMoment.isSame(dateStart, "day")) setActiveDatePoint("start");
          setDateStart(intervalStartMoment);
          setDefaultDateStart?.(intervalStartMoment.format("YYYY-MM-DD"));

          if (!intervalEndMoment.isSame(dateEnd, "day")) setActiveDatePoint("end");
          setDateEnd(intervalEndMoment);
          setDefaultDateEnd?.(intervalEndMoment.format("YYYY-MM-DD"));

          setError(null);
        }
      }
      setRawInputValue(parseDateInputMask(e.target.value));
    },
    [dateStart, dateEnd, skipValidation]
  );

  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const [intervalStart, intervalEnd] = e.target.value?.split("-") || [];
      const isValidStart = intervalStart?.match(DATE_PATTERN) && validateFormattedDate(intervalStart);
      const isValidEnd = intervalEnd?.match(DATE_PATTERN) && validateFormattedDate(intervalEnd);
      if (!parseDateInputMask(intervalStart)) {
        !skipValidation && setError("Укажите дату начала");
      } else if (!isValidStart) {
        !skipValidation && setError("Дата начала указана некорректно");
      } else if (!parseDateInputMask(intervalEnd)) {
        !skipValidation && setError("Укажите дату окончания");
      } else if (!isValidEnd) {
        !skipValidation && setError("Дата окончания указана некорректно");
      } else setError(null);
    },
    [skipValidation]
  );

  const setDate = useCallback(
    (val: Moment | string) => {
      const newMoment = moment(val);
      hasToShowInterval.current = true;
      if (activeDatePoint === "start") {
        if (moment(dateEnd).isBefore(newMoment)) {
          setDateEnd(newMoment);
          setDefaultDateEnd?.(newMoment.format("YYYY-MM-DD")!);
        }
        setDateStart(newMoment);
        setDefaultDateStart?.(newMoment.format("YYYY-MM-DD")!);
        setActiveDatePoint("end");
      } else if (activeDatePoint === "end") {
        if (moment(dateStart).isAfter(newMoment)) {
          setDateStart(newMoment);
          setDefaultDateStart?.(newMoment.format("YYYY-MM-DD")!);
        }
        setDateEnd(newMoment);
        setDefaultDateEnd?.(newMoment.format("YYYY-MM-DD")!);
      }
    },
    [dateEnd, dateStart, activeDatePoint]
  );

  const onEndLabelClick = useCallback(() => {
    setActiveDatePoint("end");
  }, []);

  const onStartLabelClick = useCallback(() => {
    setActiveDatePoint("start");
  }, []);

  return (
    <div className={containerClassName}>
      {label && <label className={styles.label}>{label}</label>}
      <div
        className={cn(styles.select, {
          [styles.isOpen]: isOpen && !disabled,
          [styles.disabled]: disabled,
          [classNameSelect!]: classNameSelect,
        })}
        onClick={setToggle}
        ref={ref}
      >
        {isHiddenInput && <>{children}</>}
        {!isHiddenInput && (
          <>
            <InputMask
              mask={"99.99.9999 - 99.99.9999"}
              placeholder={"__.__.____ - __.__.____"}
              value={rawInputValue}
              onChange={handleInput}
              onBlur={handleBlur}
              className={styles.input}
              disabled={disabled}
            />

            <div className={styles.calendatIconView}>
              <img src={activeCalendarBlue} alt="" />
            </div>
          </>
        )}
        {!!error && !isHiddenInput && <span className={styles.error}>{error}</span>}
        {!disabled && (
          <div
            className={cn(
              styles.optionsBlock,
              {
                [styles.isOpen]: isOpen,
                [styles.isTooRight]: isTooRight,
                [styles.isTooLeft]: isTooLeft,
              },
              classNamePopup
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={cn(styles.container)}>
              <div className={styles.triangle} />
              <div className={styles.titleBlock}>
                <div className={styles.dateBlock}>
                  <div
                    className={cn(styles.dateValue, {
                      [styles.activeDate]: activeDatePoint === "start",
                    })}
                    onClick={onStartLabelClick}
                  >
                    <div className={styles.textPeriod}>От</div>
                    <div className={styles.textDate}>{moment(dateStart).format(DATE_FORMAT)}</div>
                    {activeDatePoint === "start" ? <Icon icon={activeCalendar} /> : <Icon icon={disCalendar} />}
                  </div>
                  <div
                    className={cn(styles.dateValue, {
                      [styles.activeDate]: activeDatePoint === "end",
                    })}
                    onClick={onEndLabelClick}
                  >
                    <div className={styles.textPeriod}>До</div>
                    <div className={styles.textDate}>{moment(dateEnd).format(DATE_FORMAT)}</div>
                    {activeDatePoint !== "start" ? <Icon icon={activeCalendar} /> : <Icon icon={disCalendar} />}
                  </div>
                </div>
              </div>

              <div className={styles.wrapper}>
                <MonthPicker date={navigationDate} setDate={setNavigationDate} />
              </div>
              <DatePicker
                navigationDate={navigationDate}
                dateStart={dateStart}
                dateEnd={dateEnd}
                date={date}
                setDate={setDate}
                isDirty={hasToShowInterval.current}
                activeDatePoint={activeDatePoint}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CalendarRange);
