import moment from 'moment'
import React from 'react'
import { ITaskComment } from 'redux/modules/purchaser/Tasks/types'
import styles from "./TaskComments.module.scss"

function TaskOneComment({ date, creator_name, text }: Pick<ITaskComment, "creator_name" | "date" | "text">) {
    return (
        <div className={styles.comment}>
            <div className={styles.head}>
                <div className={styles.name}>{creator_name}</div>
                <div className={styles.date}>{moment(date).format("DD.MM.YYYY")} - {moment(date).format("HH:mm")}</div>
            </div>
            <div className={styles.content}>{text}</div>
        </div>
    )
}

export default TaskOneComment