import React, { useCallback, useState } from "react";
import cn from "classnames";

import AddEmployeeSlide from "../../../UI/molecules/AddEmployeeSlide/index";
import EmployeesElem from "../../../UI/molecules/EmployeesElem";
import { AddButton } from "../../../UI/AddButton/AddButton";

import EditProjectPersonRequiredCell from "./components/EditProjectPersonCell/EditProjectPersonCell";

import { EMPLOYEES_TYPES } from "./hooks/useProjectEmployees";

import styles from "./EditProject.module.scss";


function EditProjectUsers({
  payment_decision_employee,
  pto_employee,
  employees,
  responsible_employee,
  deleteEmployeeHandler,
  addEmployeeHandler,
  timesheetResponsibleEmployee,
  stockResponsibleEmployee,
  estimateResponsibleEmployee,
  isDisabled,
}) {
  const [employeeType, setEmployeeType] = useState(null);
  const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);

  const openAddEmployeeModal = useCallback((type) => {
    setEmployeeType(type);
    setIsAddEmployeeModalOpen(true);
  }, []);

  const closeAddEmployeeModal = useCallback(() => {
    setEmployeeType(null);
    setIsAddEmployeeModalOpen(false);
  }, []);

  const addEmployees = useCallback(
    (employees) => {
      addEmployeeHandler(employees, employeeType);
      closeAddEmployeeModal();
    },
    [addEmployeeHandler, employeeType, closeAddEmployeeModal]
  );

  return (
    <>
      <div className={cn(styles.threeCols, { [styles.disabled]: isDisabled })}>
        <EditProjectPersonRequiredCell
          label="Руководитель проекта"
          employee={responsible_employee}
          onAdd={() => openAddEmployeeModal(EMPLOYEES_TYPES.RESPONSIBLE)}
        />
        <EditProjectPersonRequiredCell
          label="ПТО"
          employee={pto_employee}
          onAdd={() => openAddEmployeeModal(EMPLOYEES_TYPES.PTO)}
          onDelete={() => deleteEmployeeHandler(responsible_employee.id, EMPLOYEES_TYPES.PTO)}
          canDelete
        />
        <EditProjectPersonRequiredCell
          label="Решение по оплате"
          employee={payment_decision_employee}
          onAdd={() => openAddEmployeeModal(EMPLOYEES_TYPES.PAYMENT_DECISION)}
          onDelete={() => deleteEmployeeHandler(responsible_employee.id, EMPLOYEES_TYPES.PAYMENT_DECISION)}
          canDelete
        />
        <EditProjectPersonRequiredCell
          label="Ответственный по табелю"
          employee={timesheetResponsibleEmployee}
          onAdd={() => openAddEmployeeModal(EMPLOYEES_TYPES.TIMESHEET_RESPONSIBLE)}
          onDelete={() => deleteEmployeeHandler(responsible_employee.id, EMPLOYEES_TYPES.TIMESHEET_RESPONSIBLE)}
          canDelete
        />
        <EditProjectPersonRequiredCell
          label="Ответственный по складу"
          employee={stockResponsibleEmployee}
          onAdd={() => openAddEmployeeModal(EMPLOYEES_TYPES.STOCK_RESPONSIBLE)}
          onDelete={() => deleteEmployeeHandler(stockResponsibleEmployee?.id, EMPLOYEES_TYPES.STOCK_RESPONSIBLE)}
          canDelete
        />
        <EditProjectPersonRequiredCell
          label="Ответственный за смету"
          employee={estimateResponsibleEmployee}
          onAdd={() => openAddEmployeeModal(EMPLOYEES_TYPES.ESTIMATE_RESPONSIBLE)}
          onDelete={() => deleteEmployeeHandler(estimateResponsibleEmployee?.id, EMPLOYEES_TYPES.ESTIMATE_RESPONSIBLE)}
          canDelete
        />
      </div>
      <div className={styles.teamRow}>
        <div className={styles.headline}>
          <div className={styles.label}>Команда проекта</div>
          <AddButton onClick={() => openAddEmployeeModal()} />
        </div>
        <div className={styles.teamEmployees}>
          {Array.isArray(employees) &&
            employees.map((el) => (
              <div className={styles.teamEmployee}>
                <EmployeesElem employee={el} deleteEmployee={() => deleteEmployeeHandler(el.id)} editPermission />
              </div>
            ))}
        </div>
      </div>
      <AddEmployeeSlide
        returnOneData={Object.values(EMPLOYEES_TYPES).indexOf(employeeType) !== -1}
        isOpen={isAddEmployeeModalOpen}
        closeAddPEmployeeBlock={closeAddEmployeeModal}
        addEmployees={addEmployees}
      />
    </>
  );
}

export default React.memo(EditProjectUsers);
