import { message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { isAbleToCreateProjectSelector } from "../../../../redux/modules/common/auth";
import {
  addLoadObjects,
  cachedObjectsSelector,
  changeObjectStatus,
  clearObjectsLegacy,
  isProjectsLoadingSelector,
  loadObjects,
  objectsLoadingMoreSelector,
  objectsSelector,
  transferredSelector,
} from "../../../../redux/modules/common/building/projects";

import TabBarNotLinks from "../../../UI/atoms/TabBar/TabBarNotLinks";
import AlertPopup from "../../../UI/molecules/AlertPopup/AlertPopup";
import ForbiddenPage from "../../../routes/components/ForbiddenPage/ForbiddenPage";
import ObjectsHeaderRow from "./components/ObjectsRow/ObjectsHeaderRow";
import ObjectsRow from "./components/ObjectsRow/ObjectsRow";
import { Spinner } from "components/UI/Spinner/Spinner";
import BackNavigationBar from "components/UI/atoms/BackNavigationBar/BackNavigationBar";
import Button from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ProgressBar from "components/UI/atoms/ProgressBar/ProgressBar";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";
import Projects from "pages/Projects/Projects";
import { projectsV2ListSelector } from "pages/Projects/model/selectors";
import { getProjectsList } from "pages/Projects/model/thunks";
import Project from "pages/Settings/Project/Project";

import { DEFAULT_ACCEPTED_FILTERS, DEFAULT_TRANSFERRED_FILTERS } from "./constants";

import { beautifyNumberValue } from "utils/formatters/beautifyNumberValue";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { hasOnlyPaginationFilters } from "utils/hasOnlyPaginationFilters";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import projectsIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./index.module.scss";

const Objects = ({ addLoadObjects, permissions, isProjectsLoading }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const accepted = useSelector(objectsSelector);
  const transferred = useSelector(transferredSelector);
  const isLoadingMoreObjects = useSelector(objectsLoadingMoreSelector);
  const isAbleToCreateProject = useSelector(isAbleToCreateProjectSelector);

  const [acceptedFilters, setAcceptedFilters] = useState(DEFAULT_ACCEPTED_FILTERS);
  const isWithAcceptedFilters = useMemo(() => !hasOnlyPaginationFilters(acceptedFilters), [acceptedFilters]);

  const actualAcceptedProjects = accepted;

  const sentTabAbleToView = transferred.results.length !== 0;

  const getDefaultActiveTab = () => {
    if (permissions.viewAllBuildingsTab) return "all";
    if (permissions.viewSentBuildingsTab && sentTabAbleToView) return "sent";
    if (permissions.viewAcceptedBuildingsTab) return "accepted";
  };

  const [tab, setTab] = useState(getDefaultActiveTab());

  const handleTabChange = useCallback((newTab) => {
    setAcceptedFilters(DEFAULT_ACCEPTED_FILTERS);
    setTab(newTab);
  }, []);

  const transferredFilters = useMemo(() => DEFAULT_TRANSFERRED_FILTERS, []);

  const changeAcceptedFilters = (name, value) => {
    setAcceptedFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAcceptedAdd = () => {
    addLoadObjects(
      null,
      {
        ...acceptedFilters,
        offset: actualAcceptedProjects?.results.length,
      },
      null
    );
  };

  const handleTransferredAdd = () => {
    addLoadObjects(null, {
      ...transferredFilters,
      offset: transferred?.results.length,
    });
  };

  const onCreateClick = useCallback(() => {
    if (!isAbleToCreateProject) {
      message.error("Вы достигли максимального кол-ва объектов");
      return;
    }
    history.push(`/constructing/projects/create-object/${projectId}/new/passport`);
  }, [history, isAbleToCreateProject]);

  const [confirmModal, setConfirmModal] = useState({ open: false, name: "", id: null, status: null });
  const clearConfirm = useCallback(() => {
    setConfirmModal({ open: false, name: "", id: null, status: null });
  }, []);

  const handleStatusChange = (row, value) => {
    setConfirmModal({
      open: true,
      id: row?.id,
      name: row?.name,
      status: value,
    });
  };

  const [pendingSharedObjectsIds, setPendingSharedObjectsIds] = React.useState([]);

  const changeStatus = useCallback(
    async (id, status) => {
      setPendingSharedObjectsIds((prev) => [...prev, id]);
      dispatch(
        changeObjectStatus(id, status, [
          {
            paginationParams: null,
            filterParams: acceptedFilters,
            ordering: null,
          },
          {
            paginationParams: null,
            filterParams: transferredFilters,
            ordering: null,
          },
        ])
      );
      clearConfirm();
    },
    [confirmModal.id, confirmModal.status]
  );

  const cancelChange = () => {
    clearConfirm();
  };

  const tabs = React.useMemo(() => {
    const result = [];
    if (permissions.viewAllBuildingsTab) result.push({ text: "Все объекты", id: "all" });
    if (permissions.viewSentBuildingsTab && sentTabAbleToView) result.push({ text: "Выполненные", id: "sent" });
    if (permissions.viewAcceptedBuildingsTab) result.push({ text: "Выполняются", id: "accepted" });
    return result;
  }, [
    permissions.viewAcceptedBuildingsTab,
    permissions.viewAllBuildingsTab,
    permissions.viewSentBuildingsTab,
    sentTabAbleToView,
  ]);

  useEffect(() => {
    dispatch(loadObjects(null, { ...acceptedFilters, project: projectId }, hasOnlyPaginationFilters(acceptedFilters)));
    return () => {
      dispatch(clearObjectsLegacy());
    };
  }, [acceptedFilters, projectId]);

  useEffect(() => {
    dispatch(loadObjects(null, transferredFilters));
  }, [transferredFilters]);

  const key = generateStorageKey({ projectId: 0 });
  useEffect(() => {
    dispatch(getProjectsList({}));
  }, []);
  const projects = useSelector(projectsV2ListSelector)[key];
  const parent = useMemo(() => {
    return projects?.find((el) => el.id === +projectId);
  }, [projects, projectId]);

  return (
    <TemplateBase dataTestId="page_objects">
      <ListPageHeaderTemplate>
        <TabBarNotLinks tabs={tabs} activeId={tab} onClick={handleTabChange} />
        {permissions.viewCreateBuilding && (
          <Button onClick={onCreateClick} secondary>
            Создать объект
          </Button>
        )}
      </ListPageHeaderTemplate>
      <BackNavigationBar
        className={styles.back}
        title="Все проекты"
        onBack={() => history.push("/constructing/projects")}
        rightSideText={
          parent ? (
            <div className={styles.total}>
              <div className={styles.budget}>
                {transformDigitToFinancial(parent?.calculation.budget, { withCurrencySign: true })}
              </div>
              <div className={styles.divider}></div>
              <ProgressBar
                containerClassName={styles.progress}
                completed={Math.round((parent?.calculation.complete_percent ?? 0) * 100)}
              />
            </div>
          ) : null
        }
      />
      {tab === "all" && transferred?.results?.length > 0 && (
        <div className={styles.sharedBlock}>
          <p>
            Предложения от заказчика: <strong>{transferred.results.length}</strong>
          </p>
          {transferred.results.map((el) => (
            <ObjectsRow
              key={el.id}
              object={el}
              onStatusChange={handleStatusChange}
              isTransferred
              viewBuildingPermission={permissions.viewBuilding}
              viewEditBuildingPermission={permissions.viewEditBuildings}
              viewHandlerPermission={permissions.viewHandler}
              isSharingPending={pendingSharedObjectsIds.includes(el.id)}
              projectId={projectId}
            />
          ))}
          <ShowMoreButton
            handleAdd={handleTransferredAdd}
            showedCount={transferred?.results.length}
            allCount={transferred?.count}
            isExists={transferred?.count > transferred?.results.length}
            isLoading={isLoadingMoreObjects}
          />
          {confirmModal.open && (
            <AlertPopup
              onClose={clearConfirm}
              title={`${confirmModal.status === "accepted" ? "Принять" : "Отклонить"} проект "${confirmModal.name}"`}
            >
              <div className={styles.alert}>
                <p>
                  Вы действительно хотите{" "}
                  <strong>{confirmModal.status === "accepted" ? "принять" : "отклонить"}</strong> проект{" "}
                  <strong>{confirmModal.name}</strong>?
                </p>
              </div>
              <div className={styles.btns}>
                <button onClick={() => cancelChange(confirmModal.id)}>Отменить</button>
                <button
                  onClick={() => changeStatus(confirmModal.id, confirmModal.status)}
                  className={confirmModal.status === "accepted" ? styles.accept : styles.decline}
                >
                  {confirmModal.status === "accepted" ? "Принять" : "Отклонить"}
                </button>
              </div>
            </AlertPopup>
          )}
        </div>
      )}
      {tab !== "sent" && (
        <>
          {(isWithAcceptedFilters || !!actualAcceptedProjects?.results.length) && (
            <ObjectsHeaderRow onChangeFilter={changeAcceptedFilters} />
          )}
          {!isProjectsLoading && !actualAcceptedProjects?.results.length && (
            <EmptyPlaceholder text="Нет объектов" img={projectsIcon} />
          )}
          {actualAcceptedProjects?.results?.map((el) => (
            <ObjectsRow
              key={el.id}
              object={el}
              viewBuildingPermission={permissions.viewBuilding}
              viewEditBuildingPermission={permissions.viewEditBuildings}
              viewHandlerPermission={permissions.viewHandler}
              projectId={projectId}
            />
          ))}
          {isProjectsLoading && !actualAcceptedProjects?.results.length && (
            <Spinner isFullHeight={!!transferred?.results?.length} isStatic />
          )}
        </>
      )}
      <ShowMoreButton
        allCount={actualAcceptedProjects?.count}
        showedCount={actualAcceptedProjects?.results.length}
        isExists={
          ["all", "accepted"].includes(tab) && actualAcceptedProjects?.count > actualAcceptedProjects?.results.length
        }
        handleAdd={handleAcceptedAdd}
        isLoading={isLoadingMoreObjects}
        limit={DEFAULT_ACCEPTED_FILTERS.limit}
      />
      {tab === "sent" && <EmptyPlaceholder text="Нет объектов" img={projectsIcon} />}
      {!tab && <ForbiddenPage />}
    </TemplateBase>
  );
};

export default connect(
  (state) => ({
    isProjectsLoading: isProjectsLoadingSelector(state),
    full_access: state.auth.user.full_access,
  }),
  {
    addLoadObjects,
  }
)(Objects);
