import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  IPermission,
  IPermissionExtended,
  IPermissionSimplified,
  IPermissionsTree,
  IPermissionsTreeResponse,
} from "./types";

import { permissionsUtils } from "./utils";

interface PermissionsState {
  tree: null | IPermissionsTree;
  descriptionsByAliases: null | Record<string, IPermissionExtended>;
  isLoading: boolean;
  userPermissionsList: Record<string, IPermissionSimplified[]>;
  invalidationKey: number;
  isPending: Record<string, boolean>;
}

const initialState: PermissionsState = {
  tree: null,
  descriptionsByAliases: null,
  isLoading: true,
  userPermissionsList: {},
  invalidationKey: 0,
  isPending: {},
};

const permissionsSlice = createSlice({
  name: "permissions_v2",
  initialState,
  reducers: {
    setPermissionsTree: (state, action: PayloadAction<IPermissionsTreeResponse>) => {
      const preparedTree = permissionsUtils.prepareTree(action.payload);
      state.tree = preparedTree;
      state.descriptionsByAliases = permissionsUtils.extractDescriptionsByAliases(preparedTree);
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUserPermissions: (state, action: PayloadAction<{ list: IPermissionSimplified[]; userId: string | number }>) => {
      const { list, userId } = action.payload;
      state.userPermissionsList[userId] = list;
    },
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsPending: (state, action: PayloadAction<{ status: boolean; userId: string | number }>) => {
      const { status, userId } = action.payload;
      state.isPending[userId] = status;
    },
  },
});

export const permissionsActions = permissionsSlice.actions;

export default permissionsSlice.reducer;
