import { IWorker } from "redux/modules/common/building/processTypes";

import { IExpenditure } from "types/interfaces/Expenditure";
import { IMaterialInPlanInterval, ISerializedMaterialInPlan } from "types/interfaces/Materials";
import { IMimsInAddingInPlan, ISerializedMimsInPlan } from "types/interfaces/Mims";

import { formatMaterialCount } from "utils/formatters/formatMaterialCount";

export const serializePlanMaterialsInEditPlan = (materials: IMaterialInPlanInterval[]): ISerializedMaterialInPlan[] => {
  return materials.map((el) => {
    return {
      count: formatMaterialCount(el.material.estimate_expenditure.count, el.material.estimate_expenditure.measure),
      name: el.material.name,
      measure: el.material.estimate_expenditure.measure,
      local_count: el.count,
      id: el.material.id,
    };
  });
};

export const serializePlanMimsInEditPlan = (mims: IMimsInAddingInPlan[]): ISerializedMaterialInPlan[] => {
  return mims.map((el) => {
    return {
      count: formatMaterialCount(el.count, el.measure),
      name: el.name,
      measure: el.measure,
      local_count: el.count,
      id: el.id,
    };
  });
};

export const filterRestPlannedMaterials = (materials: ISerializedMaterialInPlan[], addedIds: number[]) => {
  return materials.filter((el) => {
    return !addedIds.includes(el.id);
  });
};

export const filterRestPlannedMims = (mims: ISerializedMimsInPlan[], addedIds: number[]) => {
  return mims.filter((el) => {
    return !addedIds.includes(el.id);
  });
};

export const mapEditingPlanMaterials = (
  materials: ISerializedMaterialInPlan[],
  prevMaterials: IMaterialInPlanInterval[]
) => {
  const savingMaterialsIds = materials.map((el) => el.id);
  const deletingMaterials = prevMaterials
    .filter((el) => !savingMaterialsIds.includes(el.material.id))
    .map((el) => ({
      material_id: el.material.id,
      is_deleted: true,
      count: el.count,
    }));
  const savingMaterials = materials.map((el) => ({
    material_id: el.id,
    count: el.local_count || el.count,
  }));

  return [...savingMaterials, ...deletingMaterials];
};

export const mapEditingPlanMims = (mims, prevMims) => {
  const savingMimsIds = mims.map((el) => el.id);
  const deletingMims = prevMims
    .filter((el) => !savingMimsIds.includes(el.service_id || el.service?.id))
    .map((el) => ({
      service_id: el.service_id || el.service?.id,
      is_deleted: true,
      count: el.local_count ?? el.count,
    }));

  const savingMims = mims.map((el) => ({
    service_id: el.id,
    count: el.local_count ?? el.count,
  }));

  return [...savingMims, ...deletingMims];
};

export const mapEditingGroupPlanMims = (mims) => {
  return mims.map((mim) => ({ id: mim.id, count: mim.local_count ?? mim.count }));
};

export const mapEditingGroupPlanMaterials = (materials: IExpenditure[]) => {
  const savingMaterials = materials.map((el) => ({
    id: el.id,
    count: el.local_count || el.count,
  }));

  return savingMaterials;
};

export const serializeGroupPlanWorkersInEditPlan = (workers: IWorker[]) => {
  return workers.map((el) => {
    return {
      id: el.worker_id ?? el.id,
      stake_count: el.count,
    };
  });
};
