import React from "react";

import { Header, Tab } from "components/UI/atoms/Tabs";

const OrdersTabs = (props) => {
  const {
    awaitPaymentOrders,
    paymentDoneOrders,
    isPayment,
    tab,
    setTab,
    newOrders,
    awaitOrders,
    declineOrders
  } = props;

  return (
    <Header>
      {isPayment ? (
        <>
          <Tab
            name="awaitPayment"
            title={`Ожидают решения ${awaitPaymentOrders.length}`}
            setTab={setTab}
            activeTab={tab}
          />
          <Tab
            name="paymentDone"
            title={`Решение принято ${paymentDoneOrders.length}`}
            setTab={setTab}
            activeTab={tab}
          />
        </>
      ) : (
        <>
          <Tab name="active" title={`Активные ${newOrders.length}`} setTab={setTab} activeTab={tab} />
          <Tab name="await" title={`Ожидают решения ${awaitOrders.length}`} setTab={setTab} activeTab={tab} />
          <Tab name="decline" title={`Отклоненные ${declineOrders.length}`} setTab={setTab} activeTab={tab} />
        </>
      )}
    </Header>
  );
};

export default React.memo(OrdersTabs);