import React from 'react'
import { tasksListFilterType } from 'redux/modules/purchaser/Tasks/types';
import { IIdAndName } from 'types/idAndName';
import TaskRow, { ItaskRowProps } from '../TaskRow/TaskRow'
import styles from "./TaskRowByAllObjects.module.scss"
import TaskSubheaderByObject from './TaskSubheaderByObject/TaskSubheaderByObject';

interface Iprops extends ItaskRowProps {
  isFirstInObject: boolean;
  isExists: boolean;
  building: IIdAndName;
  onExpand: (id: number) => void;
  onChangeSection: (id: number) => void;
  filters: tasksListFilterType
}

function TaskRowByAllObjects(props: Iprops) {
  return (
    <>
      {props.isFirstInObject && <TaskSubheaderByObject
        onChangeSection={props.onChangeSection}
        isExpanded={props.isExists}
        building={props.building}
        onExpand={props.onExpand}
        filters={props.filters}
      />}
      {props.isExists && <TaskRow {...props} />}
    </>
  )
}

export default TaskRowByAllObjects