import { message } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { isAbleToCreateProjectSelector } from "../../../../redux/modules/common/auth";
import { objectsSelector } from "../../../../redux/modules/common/building/projects";

import BackNavigationBar from "components/UI/atoms/BackNavigationBar/BackNavigationBar";
import ButtonBase from "components/UI/atoms/ButtonBase";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";

import ProjectsTable from "./ProjectsTable/ProjectsTable";

import styles from "./ProjectsSettings.module.scss";

function ProjectSettings({ path }) {
  return <ProjectsTable path={path} />;
}

export default ProjectSettings;
