export const processIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6.875 9.99878C6.875 11.0343 6.03553 11.8738 5 11.8738C3.96447 11.8738 3.125 11.0343 3.125 9.99878C3.125 8.96325 3.96447 8.12378 5 8.12378C6.03553 8.12378 6.875 8.96325 6.875 9.99878ZM11.875 9.99878C11.875 11.0343 11.0355 11.8738 10 11.8738C8.96447 11.8738 8.125 11.0343 8.125 9.99878C8.125 8.96325 8.96447 8.12378 10 8.12378C11.0355 8.12378 11.875 8.96325 11.875 9.99878ZM15 11.8738C16.0355 11.8738 16.875 11.0343 16.875 9.99878C16.875 8.96325 16.0355 8.12378 15 8.12378C13.9645 8.12378 13.125 8.96325 13.125 9.99878C13.125 11.0343 13.9645 11.8738 15 11.8738Z"
      fill="#C3C3C3"
    />
  </svg>
);
