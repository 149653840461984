import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSpecificationsSections,
  getSpecificationsSubSections,
  setSpecificationSubSections,
  specificationSubSectionsSelector,
} from "redux/modules/common/building/materials/specification";

import SectionLevel from "./components/Levels/SectionLevel";
import SubSectionsLevel from "./components/Levels/SubSectionsLevel";
import ProductTable from "./components/ProductTable/ProductTable";
import SectionChild from "./components/SectionChild/SectionChild";
import { InputSearchRound } from "components/UI/InputSearchRound";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import ButtonBase from "../../../../atoms/ButtonBase";
import Section from "../../../../atoms/Section";

import useDebounce from "utils/hooks/useDebouncedValue";

import styles from "./Materials.module.scss";

interface IProps {
  sections: any[];
  type: string;
  products: any[];
  setProducts: any;
  handleAddProducts: any;
  objectId: string;
}

const Materials: React.FC<IProps> = ({ sections, type, products, setProducts, handleAddProducts, objectId }) => {
  const dispatch = useDispatch();

  const specificationSubSections = useSelector(specificationSubSectionsSelector);

  const [searchBarValue, setSearchBarValue] = useState<string | null>();
  const [activeSectionId, setActiveSectionId] = useState(null);

  const debouncedSearchBarValue = useDebounce(searchBarValue, 500);

  useEffect(() => {
    if (!debouncedSearchBarValue && typeof searchBarValue !== "string") return;

    !specificationSubSections.subsections
      ? dispatch(getSpecificationsSections(objectId, type, searchBarValue!))
      : dispatch(getSpecificationsSubSections(objectId, type, activeSectionId, searchBarValue!));
  }, [debouncedSearchBarValue, activeSectionId]);

  const handleSetSection = useCallback(
    (section: any) => {
      setActiveSectionId(section.id);
      setSearchBarValue(null);
      dispatch(getSpecificationsSubSections(objectId, type, section.id));
    },
    [objectId, type]
  );

  /* const allSectionsCount = useMemo(
    () =>
      specificationSubSections.subsections?.length > 0 ? sections?.length : specificationSubSections.products?.length,
    [specificationSubSections, sections]
  ); */

  const handleBack = useCallback(() => {
    setActiveSectionId(null);
    //setSearchBarValue(null);
    dispatch(setSpecificationSubSections({}));
    dispatch(getSpecificationsSections(objectId, type));
  }, [objectId, type]);

  const handleSearchBarChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBarValue(event.target.value);
  }, []);

  if (!activeSectionId) {
    return (
      <SectionLevel
        objectId={objectId}
        type={type}
        productsCount={products?.length}
        handleAddProducts={handleAddProducts}
        handleSetSection={handleSetSection}
      />
    );
  }

  if (activeSectionId) {
    return (
      <SubSectionsLevel
        onGoBack={handleBack}
        objectId={objectId}
        type={type}
        productsCount={products?.length}
        handleAddProducts={handleAddProducts}
        products={products}
        setProducts={setProducts}
        activeSectionId={activeSectionId}
      />
    );
  }

  return null;

  /* return (
    <div className={styles.container}>
      <div className={styles.searchBlock}>
        <InputSearchRound
          onChange={handleSearchBarChange}
          className={styles.inputSearchClassName}
          value={searchBarValue}
        />
        <ButtonBase onClick={handleAddProducts} disabled={products.length === 0} primary>
          Добавить
        </ButtonBase>
      </div>
      {!specificationSubSections.subsections ? (
        sections.map(
          (item) =>
            item.products_count !== 0 && <Section key={item.id} data={item} onClick={handleSetSection} type={type} />
        )
      ) : (
        <div>
          <div className={styles.titleTable}>
            <span className={styles.button}>
              <NavBar onClick={handleBack} isExist text={specificationSubSections.name} />
            </span>
            <span className={styles.countSection}>всего записей: {allSectionsCount}</span>
          </div>
          {specificationSubSections.subsections.map(
            (item: any) =>
              item.products_count !== 0 && (
                <SectionChild
                  key={item.id}
                  data={item}
                  objectId={objectId}
                  type={type}
                  products={products}
                  setProducts={setProducts}
                />
              )
          )}
          {specificationSubSections.subsections?.length === 0 && specificationSubSections.products?.length > 0 && (
            <ProductTable
              section={specificationSubSections}
              type={type}
              products={products}
              setProducts={setProducts}
            />
          )}
        </div>
      )}
    </div>
  ); */
};

export default React.memo(Materials);
