import React, { useMemo } from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import { IFactInterval } from "types/interfaces/FactInterval";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./ManufacturingModalBottom.module.scss";

interface IProps {
  interval?: IFactInterval & { approved_at?: string };
  isPlan: boolean;
  canEditPlan: boolean;
  canApprovePlan: boolean;
  planIsInFuture: boolean;
  onAddToJournal: () => void;
  onEdit: () => void;
  onApprove: () => void;
  onSetSharing: () => void;
  onSetReviewingShared: () => void;
}

const ManufacturingModalBottom: React.FC<IProps> = ({
  interval,
  isPlan,
  canEditPlan,
  canApprovePlan,
  planIsInFuture,
  onAddToJournal,
  onEdit,
  onApprove,
  onSetSharing,
  onSetReviewingShared,
}) => {
  const showPlanEditing =
    isPlan &&
    (!interval?.approved_at || (interval?.approved_at && interval?.status === "canceled")) &&
    canEditPlan &&
    !interval?.is_from_provider;
  const showAddingToJournal =
    isPlan &&
    !!interval?.work_status &&
    ["actived", "completed"].includes(interval?.work_status) &&
    !canApprovePlan &&
    !interval?.is_from_provider;
  const showTotalAmount = interval?.work_status === "to_payed";
  const showSetToSharing =
    interval?.is_shared &&
    interval?.status !== "moderation" &&
    interval?.status !== "confirmed" &&
    (isPlan ? interval?.approved_at : interval?.is_ended);

  const showSetToReviewingShared =
    interval?.is_from_provider &&
    interval?.status !== "canceled" &&
    interval?.status !== "confirmed" &&
    (isPlan ? !interval?.approved_at : interval?.is_ended);

  const setToSharingText = useMemo(() => (isPlan ? "Передача плана" : "Передача работы"), [isPlan]);

  return (
    <>
      <BottomControls isExists={!!showSetToSharing || showTotalAmount || !!showSetToReviewingShared}>
        <div className={styles.column}>
          {showSetToReviewingShared && (
            <ButtonBase primary onClick={onSetReviewingShared}>
              Принять решение
            </ButtonBase>
          )}
          {showSetToSharing && (
            <ButtonBase primary onClick={onSetSharing}>
              {setToSharingText}
            </ButtonBase>
          )}
          {showTotalAmount && (
            <div className={styles.sum}>
              Итого к оплате:<strong>{splitThousands(interval?.total_sum_amount)}</strong>
            </div>
          )}
        </div>
      </BottomControls>


      <BottomControls
        isExists={showPlanEditing || showAddingToJournal || canApprovePlan}
        isDoubleBtns
      >
        {showPlanEditing && (
          <ButtonBase secondary onClick={onEdit}>
            Редактировать
          </ButtonBase>
        )}
        {showAddingToJournal && (
          <ButtonBase primary onClick={onAddToJournal}>
            Добавить запись в ЖУ
          </ButtonBase>
        )}
        {canApprovePlan && (
          <ButtonBase primary onClick={onApprove}>
            Согласовать
          </ButtonBase>
        )}
      </BottomControls>
    </>
  );
};

export default ManufacturingModalBottom;
