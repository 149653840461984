import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { manufacturingTabSelector } from "redux/modules/common/building/manufacturing/selectors";
import { setManufacturingTab } from "redux/modules/common/building/manufacturing/thunks";

import { VIEW_CONSTRUCTING_CHART_WORKS } from "../../../../constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_CHART_RESOURCES,
  VIEW_MANUFACTURING_CHART_WORKS,
} from "../../../../constants/permissions/manufacturingPermissions";
import {
  VIEW_PURCHASES_CHART_EQUIPMENT,
  VIEW_PURCHASES_CHART_MATERIALS,
  VIEW_PURCHASES_CHART_MIMES,
} from "../../../../constants/permissions/purchasesPermissions";
import { EQUIPMENT_TAB_ID, MATERIALS_TAB_ID, MIMES_TAB_ID, RESOURCES_TAB_ID, WORKS_TAB_ID } from "../constants";
import { ManufacturingTabsType } from "../types";
import { MODULES_ENUM } from "types/enums/ModulesEnum";

import usePermission from "../../../../hooks/usePermission";
import { useUrlModule } from "utils/hooks/useUrlModule";

export const useTabsWithPermissions = () => {
  const dispatch = useDispatch();
  const tab = useSelector(manufacturingTabSelector);
  const module = useUrlModule();

  const haveViewWorksTabPermission = usePermission(
    module === MODULES_ENUM.CONSTRUCTING
      ? VIEW_CONSTRUCTING_CHART_WORKS
      : module === MODULES_ENUM.OBJECTS
      ? VIEW_MANUFACTURING_CHART_WORKS
      : ""
  );
  const haveViewMaterialsTabPermission = usePermission(
    module === MODULES_ENUM.PURCHASES ? VIEW_PURCHASES_CHART_MATERIALS : ""
  );
  const haveViewMimesTabPermission = usePermission(module === MODULES_ENUM.PURCHASES ? VIEW_PURCHASES_CHART_MIMES : "");
  const haveViewResourcesTabPermission = usePermission(
    module === MODULES_ENUM.OBJECTS ? VIEW_MANUFACTURING_CHART_RESOURCES : ""
  );
  const haveViewEquipmentTabPermission = usePermission(
    module === MODULES_ENUM.PURCHASES ? VIEW_PURCHASES_CHART_EQUIPMENT : ""
  );

  const setTab = useCallback((newTab: string) => {
    dispatch(setManufacturingTab(newTab));
  }, []);

  const isShowWorks = useMemo(
    () =>
      haveViewWorksTabPermission && [MODULES_ENUM.OBJECTS, MODULES_ENUM.CONSTRUCTING].includes(module as MODULES_ENUM),
    [module, haveViewWorksTabPermission]
  );
  const isShowResources = useMemo(
    () => haveViewResourcesTabPermission && [MODULES_ENUM.OBJECTS].includes(module as MODULES_ENUM),
    [module, haveViewResourcesTabPermission]
  );
  const isShowEquipment = useMemo(
    () => haveViewEquipmentTabPermission && module === MODULES_ENUM.PURCHASES,
    [module, haveViewEquipmentTabPermission]
  );
  const isShowMimes = useMemo(
    () => haveViewMimesTabPermission && module === MODULES_ENUM.PURCHASES,
    [module, haveViewMimesTabPermission]
  );
  const isShowMaterials = useMemo(
    () => haveViewMaterialsTabPermission && module === MODULES_ENUM.PURCHASES,
    [module, haveViewMaterialsTabPermission]
  );

  const manufacturingTabs = useMemo(() => {
    const res = [];
    if (isShowWorks) res.push({ id: WORKS_TAB_ID, text: "Работы" });

    if (isShowResources)
      res.push({
        id: RESOURCES_TAB_ID,
        text: "Ресурсы",
      });

    if (isShowMaterials) res.push({ id: MATERIALS_TAB_ID, text: "Материалы" });
    if (isShowEquipment) res.push({ id: EQUIPMENT_TAB_ID, text: "Оборудование" });
    if (isShowMimes) res.push({ id: MIMES_TAB_ID, text: "МиМ" });

    return res;
  }, [isShowWorks, isShowMaterials, isShowMimes, isShowResources, isShowEquipment]);

  useEffect(() => {
    const materialsAllowanceMap = {
      [WORKS_TAB_ID]: isShowWorks,
      [RESOURCES_TAB_ID]: isShowResources,
      [MATERIALS_TAB_ID]: isShowMaterials,
      [EQUIPMENT_TAB_ID]: isShowEquipment,
      [MIMES_TAB_ID]: isShowMimes,
    };

    if (!materialsAllowanceMap[tab]) {
      const [allowedTab] = Object.entries(materialsAllowanceMap).find(([chartTab, isAllowed]) => isAllowed) || [];
      if (!!allowedTab) setTab(allowedTab);
    }
  }, [isShowWorks, isShowResources, isShowMaterials, isShowMimes, isShowEquipment, setTab, tab]);

  const expenditureTypesToLoad: Record<ManufacturingTabsType, boolean> = useMemo(
    () =>
      ({
        [WORKS_TAB_ID]: manufacturingTabs.findIndex((x) => x.id === WORKS_TAB_ID) !== -1,
        [RESOURCES_TAB_ID]: manufacturingTabs.findIndex((x) => x.id === RESOURCES_TAB_ID) !== -1,
        [MATERIALS_TAB_ID]: manufacturingTabs.findIndex((x) => x.id === MATERIALS_TAB_ID) !== -1,
        [MIMES_TAB_ID]: manufacturingTabs.findIndex((x) => x.id === MIMES_TAB_ID) !== -1,
        [EQUIPMENT_TAB_ID]: manufacturingTabs.findIndex((x) => x.id === EQUIPMENT_TAB_ID) !== -1,
      } as Record<ManufacturingTabsType, boolean>),
    [manufacturingTabs]
  );

  return {
    manufacturingTabs,
    tab,
    setTab,
    expenditureTypesToLoad,
  };
};
