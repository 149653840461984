import { TABS } from "./constants";

export const getActiveTab = (pathname) => {
  return Object.values(TABS).find((tab) => tab.route === pathname);
};

export const prepareTabs = (tabs, counts) => {
  if (!counts) return tabs;

  return tabs.map((tab) => ({
    ...tab,
    count: counts[tab.id],
  }));
};
