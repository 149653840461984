import { FC } from "react";

import { sliceTextByConstraint } from "../../../../utils/formatters/sliceTextByConstraint";
import { dropNonSignificantZeros } from "../../../../utils/formatters/dropNonSignificantZeros";

import styles from "./SimpleResourceBlock.module.scss";

import { ReactComponent as EditIcon } from "../icons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../icons/DeleteIcon.svg";
import cn from "classnames";

interface IProps {
  name: string;
  count: string;
  measure: string;
  canInteract?: boolean;
  className?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  id?: string | number;
}

export const SimpleResourceBlock: FC<IProps> = ({
  name,
  count,
  measure,
  className,
  onEdit,
  onDelete,
  id,
  canInteract = false
}) => {
  return (
    <div className={cn(styles.wrapper, className)} key={id}>
      <span className={styles.title}>{name}</span>

      <div className={styles.infoWrapper}>
        <div className={styles.infoItem}>
          <span className={styles.infoTitle}>Количество:</span>
          <span className={styles.infoValue}>
            <>{dropNonSignificantZeros(count)} ({sliceTextByConstraint(measure, 7)})</>
          </span>
        </div>

        <div className={styles.actionsWrapper}>
          <span className={styles.manuallyText}>создан вручную</span>
          {canInteract && (
            <div className={styles.button}>
              <div className={styles.editPart} onClick={onEdit}>
                <EditIcon />
              </div>
              <div className={styles.deletePart} onClick={onDelete}>
                <DeleteIcon />
              </div>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};
