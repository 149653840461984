import cn from "classnames";
import React from "react";

import ConfirmationModal from "../../molecules/ConfirmationModal/ConfirmModal";

import { useConfirmationModal } from "../../../../hooks/useConfirmationModal";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./Actions.module.scss";

export interface IActionsProps {
  className?: string;
  canSave?: boolean;
  onSave?: () => void;
  canEdit?: boolean;
  onEdit?: () => void;
  canRemove?: boolean;
  onRemove?: () => void;
  onRemoveDirectly?: () => void;
  confirmDeleteText?: React.ReactNode;
  onOpenConfirmModal?: () => void;
  deleteLabel?: string;
  color?: "blue";
  disabled?: boolean;
}

const Actions = ({
  className,
  canSave,
  onSave,
  canEdit,
  onEdit,
  canRemove,
  onRemove,
  onRemoveDirectly,
  confirmDeleteText = "Подтвердить удаление?",
  onOpenConfirmModal,
  deleteLabel = "Удалить",
  color,
  disabled,
}: IActionsProps) => {
  const { openRemoveConfirmationModal, isOpenRemoveConfirmationModal, confirmRemove, closeRemoveConfirmationModal } =
    useConfirmationModal({ onRemove, onRemoveDirectly, onOpenConfirmModal });

  return (
    <div className={cn(className, { [styles.disabled]: disabled })} onClick={stopEventPropagation}>
      <div className={cn(styles.actions, styles[color!])}>
        {canSave && (
          <div className={styles.button} title="Сохранить" onClick={onSave}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.25198 9.86993L2.8888 6.50682C2.40301 6.02103 1.6154 6.02104 1.12961 6.50683C0.643815 6.99262 0.643818 7.78025 1.12961 8.26604L5.55335 12.6897C5.94388 13.0802 6.57704 13.0802 6.96756 12.6897L14.914 4.74333C15.4021 4.2552 15.4021 3.46378 14.914 2.97565C14.4258 2.48751 13.6344 2.48751 13.1463 2.97564L6.25198 9.86993Z"
                fill="#949494"
              />
            </svg>
          </div>
        )}
        {canEdit && (
          <div className={styles.button} title="Редактировать" onClick={onEdit}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.95288 15.4901C3.7745 15.489 2.64473 15.0203 1.81149 14.1871C0.978245 13.3539 0.509615 12.2241 0.508438 11.0457L0.508438 5.91503C0.50985 4.73673 0.978555 3.60709 1.81174 2.7739C2.64493 1.94071 3.77458 1.472 4.95288 1.47059H9.79199C10.0125 1.47042 10.2326 1.48676 10.4507 1.51948L8.62577 3.38615H5.0471C4.33986 3.38615 3.66158 3.6671 3.16149 4.16719C2.66139 4.66729 2.38044 5.34557 2.38044 6.05281V10.9159C2.38044 11.6232 2.66139 12.3014 3.16149 12.8015C3.66158 13.3016 4.33986 13.5826 5.0471 13.5826H9.69866C10.4059 13.5826 11.0842 13.3016 11.5843 12.8015C12.0844 12.3014 12.3653 11.6232 12.3653 10.9159V7.39592L14.2178 5.50437C14.2311 5.64659 14.2373 5.78437 14.2373 5.91592V11.0475C14.2361 12.2259 13.7675 13.3556 12.9343 14.1889C12.101 15.0221 10.9713 15.4907 9.79288 15.4919L4.95288 15.4901ZM7.47111 6.44481L12.8738 0.927479C13.0174 0.788757 13.1874 0.680215 13.3737 0.608257C13.5599 0.5363 13.7587 0.502386 13.9583 0.508517C14.1579 0.514649 14.3543 0.560701 14.5358 0.643955C14.7173 0.72721 14.8803 0.84598 15.0151 0.993257C15.3083 1.27808 15.4789 1.66593 15.4909 2.0745C15.5028 2.48307 15.3551 2.88023 15.0791 3.1817L9.67733 8.69814L6.70399 9.48481L7.47111 6.44481Z"
                fill="#949494"
              />
            </svg>
          </div>
        )}
        {canRemove && (
          <>
            <div
              className={cn(styles.button, "actionsDelete")}
              title={deleteLabel}
              onClick={openRemoveConfirmationModal}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.05025 1.63664C2.65972 1.24612 2.02656 1.24612 1.63603 1.63664C1.24551 2.02717 1.24551 2.66033 1.63603 3.05086L6.58579 8.00061L1.63605 12.9504C1.24552 13.3409 1.24552 13.974 1.63605 14.3646C2.02657 14.7551 2.65973 14.7551 3.05026 14.3646L8 9.41482L12.9497 14.3646C13.3403 14.7551 13.9734 14.7551 14.364 14.3646C14.7545 13.974 14.7545 13.3409 14.364 12.9504L9.41421 8.00061L14.364 3.05086C14.7545 2.66033 14.7545 2.02717 14.364 1.63664C13.9734 1.24612 13.3403 1.24612 12.9498 1.63664L8 6.5864L3.05025 1.63664Z"
                  fill="#949494"
                />
              </svg>
            </div>
            <ConfirmationModal
              variant={"secondary"}
              isOpen={isOpenRemoveConfirmationModal}
              action={confirmRemove}
              onClose={closeRemoveConfirmationModal}
            >
              {confirmDeleteText}
            </ConfirmationModal>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Actions);
