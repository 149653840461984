import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import InputBase from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select";
import TabBarNotLinks from "components/UI/atoms/TabBar/TabBarNotLinks";
import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";
import EditProjectHeadline from "components/pages/Settings/EditProject/components/EditProjectHeadline/EditProjectHeadline";
import { useEditProject } from "components/pages/Settings/EditProject/hooks/useEditProject";
import { projectsV2DetailedSelector, projectsV2IsLoadingSelector } from "pages/Projects/model/selectors";
import { createProject, editProject, getDetailedProject } from "pages/Projects/model/thunks";
import { ICreateProject } from "pages/Projects/model/types";

import { DEFAULT_PROJECT_FIELDS } from "./constnts";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./Project.module.scss";

const tabs = [
  {
    id: `default`,
    text: "О проекте",
  },
];

const isNew = true;

const Project = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const { projectId } = useParams<Record<string, string>>();
  const isNew = projectId === "new";
  const { entitiesList } = useEditProject("");
  const onSubmit = (vals: ICreateProject) => {
    if (isNew) {
      dispatch(
        createProject(
          {
            ...vals,
            date_end: moment(dateEnd).format("YYYY-MM-DD"),
            date_start: moment(dateStart).format("YYYY-MM-DD"),
          },
          () => history.push("/constructing/projects/")
        )
      );
    } else {
      dispatch(
        editProject(projectId, {
          ...DEFAULT_PROJECT_FIELDS,
          ...vals,
          date_end: moment(dateEnd).format("YYYY-MM-DD"),
          date_start: moment(dateStart).format("YYYY-MM-DD"),
        })
      );
    }
  };

  useEffect(() => {
    if (isNew) return;
    dispatch(getDetailedProject(projectId));
  }, [projectId, isNew]);
  const pendingKey = generateStorageKey({ type: "pending" });
  const project = useSelector(projectsV2DetailedSelector)[projectId];
  const isLoading = useSelector(projectsV2IsLoadingSelector)[projectId];
  const isPending = useSelector(projectsV2IsLoadingSelector)[pendingKey];

  return (
    <TemplateBase>
      <Form
        onSubmit={onSubmit}
        initialValues={project}
        render={({ handleSubmit, dirty, form, hasValidationErrors }) => (
          <>
            <EditProjectHeadline
              isPending={isPending}
              isDirty={dirty}
              name={isLoading ? "" : isNew ? "Новый проект" : project?.name}
              submit={handleSubmit}
              isNew={isNew}
              resetForm={() => {
                form.restart();
              }}
              type="project"
            />
            <div className={styles.wrapper}>
              <div className={styles.row}>
                <Field
                  name="name"
                  validate={composeFieldValidators(required(), maxLength(255))}
                  label="*Наименование проекта"
                  component={InputBase}
                />
                <Field
                  name="entity"
                  validate={composeFieldValidators(required())}
                  render={({ input, meta }) => (
                    <Select
                      label="*Организация"
                      input={input}
                      meta={meta}
                      options={(entitiesList as any)?.map((el: any) => ({ name: el.name, id: el.id })) || []}
                    />
                  )}
                />
              </div>
              <TabBarNotLinks
                activeId="default"
                onClick={() => {}}
                tabs={tabs}
                className={styles.mb32}
                isOnlyTabsView
              />
              <div className={styles.grid}>
                <Field
                  name="address"
                  label="*Адрес"
                  component={InputBase}
                  validate={composeFieldValidators(required(), maxLength(255))}
                  classNameInput={styles.colorInput}
                />
                <Field
                  name="permit_build"
                  label="Разрешение на строительство"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  classNameInput={styles.colorInput}
                />
                <Field
                  name="responsible"
                  label="*Ответственный"
                  component={InputBase}
                  validate={composeFieldValidators(required(), maxLength(255))}
                  classNameInput={styles.colorInput}
                />
                <CalendarRange
                  label="*Сроки строительства"
                  defaultDateEnd={dateEnd}
                  defaultDateStart={dateStart}
                  setDefaultDateStart={setDateStart as any}
                  setDefaultDateEnd={setDateEnd as any}
                />
                <Field
                  name="customer"
                  label="Заказчик"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  classNameInput={styles.colorInput}
                />
                <Field
                  name="developer"
                  label="Застройщик"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  classNameInput={styles.colorInput}
                />
                <Field
                  name="general_contractor"
                  label="Генеральный подрядчик"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  classNameInput={styles.colorInput}
                />
                <Field
                  name="technical_supervision"
                  label="Технический надзор"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  classNameInput={styles.colorInput}
                />
              </div>
            </div>
          </>
        )}
      />
    </TemplateBase>
  );
};

export default Project;
