import React from "react";

import useBudgetDoubleInput from "../../../../../pages/Handler/hooks/useBudgetDoubleInput";

import Amount from "../../../../atoms/Amount/Amount";
import DoubleInput, { Measure } from "../../../../atoms/DoubleInput/DoubleInput";

import {
  parseTransformedNumber,
  transformDigitToFinancial,
} from "../../../../../../utils/formatters/transformDigitToFinancial";
import { stopEventPropagation } from "../../../../../../utils/helpers/stopEventPropagation";

import blackCheckIcon from "../../../../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../../../../images/icons/blueCheckIcon";

import styles from "../../EstimateSection.module.scss";

const PricingModeBudgetIndicators = ({ amounts, changeDiscountCost }) => {
  const discountPercent = +amounts.allSource === 0 ? 0 : ((amounts.discount * 100) / amounts.allSource);

  const {
    changedDiscountCost,
    changedDiscountPercent,
    onChangeDiscountPercent,
    onChangeDiscountCost
  } = useBudgetDoubleInput({
      discountPercent: discountPercent.toFixed(2),
      discountCost: amounts.discount,
      amountSource: amounts.allSource,
      changeDiscountCost,
    });

  return (
    <>
      <Amount
        titleClassName={styles.budgetTitle}
        title="По смете"
        value={amounts.allSource}
        measure={amounts.measure}
        isBold
        isLeft
      />
      <div className={styles.discount} onClick={stopEventPropagation}>
        <DoubleInput
          firstValue={changedDiscountPercent}
          secondValue={changedDiscountCost}
          firstInputSize={1}
          secondInputSize={2}
          onChangeFirstValue={onChangeDiscountPercent}
          onChangeSecondValue={onChangeDiscountCost}
          firstValueMeasure={Measure.percent}
          secondValueMeasure={Measure.currency}
          fontWeight={500}
          generalLabel="Скидка от подрядчика"
          inputFontSize={+parseTransformedNumber(changedDiscountCost) > 1_000_000_000 ? 0.875 : 1}
        />
        {+parseTransformedNumber(changedDiscountCost) !== 0 ? (
          {
            ...blueCheckIcon,
            props: { ...blueCheckIcon.props, width: 18, height: 18 },
          }
        ) : (
          <span className={styles.withoutDiscountIcon} title="Скидка отсутствует">
            {{
              ...blackCheckIcon,
              props: { ...blackCheckIcon.props, width: 18, height: 18 },
            }}
          </span>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.budgetCol}>
        <Amount
          titleClassName={styles.budgetTitle}
          title="С учетом скидки"
          value={transformDigitToFinancial(amounts.allSource - +parseTransformedNumber(changedDiscountCost))}
          measure={amounts.measure}
          isBold
          isLeft
        />
      </div>
    </>
  );
};

export default React.memo(PricingModeBudgetIndicators);
