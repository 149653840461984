import { combineReducers } from "redux";

import common from "./common";
import manager from "./manager";
import tabs from "./tabs";

export const moduleName = "common";

export default combineReducers({
  index: common,
  manager,
  tabs
});
