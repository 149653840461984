import {moduleName as parentModuleName} from './index'

const initialState = {}

export const moduleName = 'index'

export const parentStateSelector = (state) => state[parentModuleName]

export default (state = initialState, action) => {
  const {type} = action
  switch (type) {
    default:
      return state
  }
}
