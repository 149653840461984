import { createSelector } from "reselect";

import { RootState } from "../../../../rootReducer";

export const stateSelector = (state: RootState) => state.analytics;
export const analyticsActiveTabSelector = createSelector(stateSelector, (state) => state.activeTab);
export const analyticsDatesSelector = createSelector(stateSelector, (state) => state.selectedDates);
export const analyticsScopeSelector = createSelector(stateSelector, (state) => state.selectedScope);
export const analyticsBudgetResourceSelector = createSelector(stateSelector, (state) => state.budgetResource);
export const analyticsInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const analyticsCachedTreesSelector = createSelector(stateSelector, (state) => state.cachedTrees);
export const analyticsEventsActiveTabSelector = createSelector(stateSelector, (state) => state.events.activeTab);
export const analyticsEventsFiltersSelector = createSelector(stateSelector, (state) => state.events.filters);
export const analyticsEventsAllSelector = createSelector(stateSelector, (state) => state.events.allEventsData);
export const analyticsEventsOverdueSelector = createSelector(stateSelector, (state) => state.events.overdueEventsData);
export const analyticsProgressDataSelector = createSelector(stateSelector, (state) => state.progress);
export const analyticsBudgetDataSelector = createSelector(stateSelector, (state) => state.budget);
export const analyticsFulfillmentDataSelector = createSelector(stateSelector, (state) => state.fulfillment);
export const analyticsProjectDatesSelector = createSelector(stateSelector, (state) => state.projectDates);
export const analyticsLoadingsSelector = createSelector(stateSelector, (state) => state.loadings);
