import React, { useMemo } from "react";

import ApprovalBar from "./components/ApprovalBar/ApprovalBar";
import ApprovalChain, { IApprovalChainProps } from "./components/ApprovalChain/ApprovalChain";

import { IIdAndName } from "../../../../../../types/idAndName";
import { IApprovalInModal } from "./types";

import styles from "./Approval.module.scss";

export interface IApprovalProps {
  approvals: IApprovalInModal[];
  planCreatingDate: string;
}

const Approval: React.FC<IApprovalProps> = ({ approvals, planCreatingDate }) => {
  const separateApprovals = useMemo(() => approvals.filter((x) => !x.position), [approvals]);
  const chainedApprovals = useMemo(() => approvals.filter((x) => !!x.position), [approvals]);
  return (
    <div className={styles.container}>
      <b>Согласование: </b>
      <div className={styles.approvals}>
        {separateApprovals.map((approval) => (
          <ApprovalBar
            isApproved={approval.is_confirmed}
            approvedAt={approval.confirmed_at}
            sendToApproveAt={planCreatingDate}
            workerName={approval.user.name}
            key={`${approval.id}_${approval.is_confirmed}`}
          />
        ))}
        {!!chainedApprovals.length && (
          <ApprovalChain
            chainedApprovals={chainedApprovals as IApprovalChainProps["chainedApprovals"]}
            planCreatingDate={planCreatingDate}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(Approval);
