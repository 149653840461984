import React from "react";

import EditExpendituresInHandler from "components/pages/Handler/components/AddExpenditure/EditExpendituresInHandler";

import Expenditure, { IHandlerExpenditureProps } from "./Expenditure";

import { IExpenditureInHandlerProduction } from "../../types";

interface IProps extends IHandlerExpenditureProps {
  expenditure: IExpenditureInHandlerProduction;
  isEditing?: boolean;
  sectionId: number;
  onCancelEdit: () => void;
  visibilityChecked?: (id: number) => boolean;
  handleVisibilityCheck?: (item: any) => void;
}

const ExpenditureInHandlerAbleToEdit: React.FC<IProps> = ({
  expenditure,
  isEditing,
  sectionId,
  onCancelEdit,
  visibilityChecked,
  handleVisibilityCheck,
  ...props
}) => {
  if (!isEditing) {
    return (
      <Expenditure
        expenditure={expenditure}
        {...props}
        handleVisibilityCheck={handleVisibilityCheck}
        visibilityChecked={visibilityChecked}
      />
    );
  }

  return (
    <EditExpendituresInHandler
      expenditure={expenditure}
      buildingId={props.buildingId}
      sectionId={sectionId}
      onCancelEdit={onCancelEdit}
    />
  );
};

export default ExpenditureInHandlerAbleToEdit;
