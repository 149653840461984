import cn from "classnames";
import React, { useCallback } from "react";

import { AddButton } from "../../../UI/AddButton/AddButton";
import { Switch } from "../../../UI/atoms/Switch/Switch";
import AddEmployeeSlide from "./../../../UI/molecules/AddEmployeeSlide/OldVersion";
import EmployeesElem from "components/UI/molecules/EmployeesElem";

import styles from "./EditProject.module.scss";

function EditProjectApprovals(props) {
  const {
    approvalsState,
    deleteAgreementsEmployee,
    handleAddChainEmployee,
    urlId,
    handleChangeAgreementsEmployees,
    handleChangeAllowShift,
    handleChangenew_close_dates,
  } = props;

  const [isModal, setIsModal] = React.useState(false);
  const [type, setType] = React.useState(null);

  const addEmployeeOpen = useCallback((type) => {
    setType(type);
    setIsModal(true);
  }, []);

  const addEmployees = useCallback(
    (employees) => {
      handleChangeAgreementsEmployees(employees, type);
      setIsModal(false);
    },
    [handleChangeAgreementsEmployees, type]
  );

  const onAllowShiftChange = useCallback(
    (e) => {
      handleChangeAllowShift(e.target.checked);
    },
    [handleChangeAllowShift]
  );

  const onnew_close_datesChange = useCallback(
    (e) => {
      handleChangenew_close_dates(e.target.checked);
    },
    [handleChangenew_close_dates]
  );

  return (
    <div className={cn({ [styles.disabled]: props.isDisabled })}>
      <div className={styles.apprLine}>
        Заявки
        <AddButton data-name="Заявки" onClick={() => addEmployeeOpen("requisitionApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvalsState?.requisitionApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "requisitionApprovalEmployees")}
            editPermission={true}
            addChainEmployee={(item) => handleAddChainEmployee(item, "requisitionApprovalEmployees", employee)}
            nextEmployee={approvalsState?.requisitionApprovalEmployees[index + 1]}
            isChain={approvalsState?.requisitionApprovalEmployees?.find((item) => item?.position)}
            isCanChain
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>
        Заказы
        <AddButton data-name="Заказы" onClick={() => addEmployeeOpen("orderApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvalsState?.orderApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "orderApprovalEmployees")}
            editPermission={true}
            addChainEmployee={(item) => handleAddChainEmployee(item, "orderApprovalEmployees", employee)}
            nextEmployee={approvalsState?.orderApprovalEmployees[index + 1]}
            isChain={approvalsState?.orderApprovalEmployees?.find((item) => item?.position)}
            isCanChain
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>
        Оплата
        <AddButton data-name="Оплата" onClick={() => addEmployeeOpen("paymentApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvalsState?.paymentApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "paymentApprovalEmployees")}
            editPermission={true}
            addChainEmployee={(item) => handleAddChainEmployee(item, "paymentApprovalEmployees", employee)}
            nextEmployee={approvalsState?.paymentApprovalEmployees[index + 1]}
            isChain={approvalsState?.paymentApprovalEmployees?.find((item) => item?.position)}
            isCanChain
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>
        Табель
        <AddButton data-name="Табель" onClick={() => addEmployeeOpen("timesheetApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvalsState?.timesheetApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "timesheetApprovalEmployees")}
            editPermission={true}
            addChainEmployee={(item) => handleAddChainEmployee(item, "timesheetApprovalEmployees", employee)}
            nextEmployee={approvalsState?.timesheetApprovalEmployees[index + 1]}
            isChain={approvalsState?.timesheetApprovalEmployees?.find((item) => item?.position)}
            isCanChain
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>
        Планы работ
        {!approvalsState?.planWorkConfirmEmployees || (
          <AddButton data-name="Планы работ" onClick={() => addEmployeeOpen("planWorkConfirmEmployees")} />
        )}
      </div>
      <div className={styles.approvals}>
        {approvalsState?.planWorkConfirmEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "planWorkConfirmEmployees")}
            editPermission={true}
            addChainEmployee={(item) => handleAddChainEmployee(item, "planWorkConfirmEmployees", employee)}
            nextEmployee={approvalsState?.planWorkConfirmEmployees[index + 1]}
            isChain={approvalsState?.planWorkConfirmEmployees?.find((item) => item?.position)}
            isCanChain
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>
        Планы разделов
        {!approvalsState?.planSectionConfirmEmployees || (
          <AddButton data-name="Планы разделов" onClick={() => addEmployeeOpen("planSectionConfirmEmployees")} />
        )}
      </div>
      <div className={styles.approvals}>
        {approvalsState?.planSectionConfirmEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "planSectionConfirmEmployees")}
            editPermission={true}
            addChainEmployee={(item) => handleAddChainEmployee(item, "planSectionConfirmEmployees", employee)}
            nextEmployee={approvalsState?.planSectionConfirmEmployees[index + 1]}
            isChain={approvalsState?.planSectionConfirmEmployees?.find((item) => item?.position)}
            isCanChain
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>
        Журнал учета - приемка
        {approvalsState?.ticketApprovalEmployees?.length === 0 && (
          <AddButton data-name="Журнал учета - приемка" onClick={() => addEmployeeOpen("ticketApprovalEmployees")} />
        )}
      </div>
      <div className={styles.approvals}>
        {approvalsState?.ticketApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "ticketApprovalEmployees")}
            addChainEmployee={(item) => handleAddChainEmployee(item, "ticketApprovalEmployees", employee)}
            nextEmployee={approvalsState?.ticketApprovalEmployees[index + 1]}
            isChain={approvalsState?.ticketApprovalEmployees?.find((item) => item?.position)}
            index={index}
            buildingId={urlId}
            isCanChain
            objectOnly
            isInApprovals
            editPermission
          />
        ))}
      </div>
      {/* 
      
      
      
      
      
      */}

      {/*<div className={styles.apprLine}>*/}
      {/*  Журнал учета - работы подрядчика*/}
      {/*  <AddButton*/}
      {/*    data-name="Журнал учета - работы подрядчика"*/}
      {/*    onClick={() => addEmployeeOpen("sharingfactchainapprovalemployees_set")}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={styles.approvals}>*/}
      {/*  {approvalsState?.sharingfactchainapprovalemployees_set?.map((employee, index) => (*/}
      {/*    <EmployeesElem*/}
      {/*      key={employee.id}*/}
      {/*      employee={employee}*/}
      {/*      styles={styles}*/}
      {/*      deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "sharingfactchainapprovalemployees_set")}*/}
      {/*      editPermission={true}*/}
      {/*      addChainEmployee={(item) => handleAddChainEmployee(item, "sharingfactchainapprovalemployees_set", employee)}*/}
      {/*      nextEmployee={approvalsState?.sharingfactchainapprovalemployees_set[index + 1]}*/}
      {/*      isChain={approvalsState?.sharingfactchainapprovalemployees_set?.find((item) => item?.position)}*/}
      {/*      isCanChain*/}
      {/*      index={index}*/}
      {/*      buildingId={urlId}*/}
      {/*      objectOnly*/}
      {/*      isInApprovals*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</div>*/}

      {/*<div className={styles.apprLine}>*/}
      {/*  Ответственные за замечания заказчика*/}
      {/*  <AddButton*/}
      {/*    data-name="Ответственные за замечания заказчика"*/}
      {/*    onClick={() => addEmployeeOpen("shared_remark_responsible_employees")}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={styles.approvals}>*/}
      {/*  {approvalsState?.shared_remark_responsible_employees?.map((employee, index) => (*/}
      {/*    <EmployeesElem*/}
      {/*      key={employee.id}*/}
      {/*      employee={employee}*/}
      {/*      styles={styles}*/}
      {/*      deleteEmployee={(employee) => deleteAgreementsEmployee(employee, "shared_remark_responsible_employees")}*/}
      {/*      editPermission={true}*/}
      {/*      addChainEmployee={(item) => handleAddChainEmployee(item, "shared_remark_responsible_employees", employee)}*/}
      {/*      nextEmployee={approvalsState?.shared_remark_responsible_employees[index + 1]}*/}
      {/*      isChain={approvalsState?.shared_remark_responsible_employees?.find((item) => item?.position)}*/}
      {/*      isCanChain={false}*/}
      {/*      index={index}*/}
      {/*      buildingId={urlId}*/}
      {/*      objectOnly*/}
      {/*      isInApprovals*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</div>*/}
      {/* 
      
      
      
      
      
      
      
      
      */}
      <AddEmployeeSlide
        returnOneData={true}
        isOpen={isModal}
        closeAddPEmployeeBlock={() => setIsModal(false)}
        addEmployees={addEmployees}
        objectOnly
        buildingId={urlId}
      />
      <Switch
        className={styles.allowShift}
        label="Анализ планов работ"
        checked={approvalsState?.allow_shift ?? false}
        onChange={onAllowShiftChange}
      />
      <Switch
        className={styles.allowShift}
        label="Разнесение по фактам"
        checked={approvalsState?.new_close_dates ?? false}
        onChange={onnew_close_datesChange}
      />
    </div>
  );
}

export default React.memo(EditProjectApprovals);
