import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch as RouterSwitch, useHistory, useRouteMatch } from "react-router-dom";

import ForbiddenPage from "../../routes/components/ForbiddenPage/ForbiddenPage";
import ButtonBase from "components/UI/atoms/ButtonBase";
import { Switch } from "components/UI/atoms/Switch/Switch";
import TabBar from "components/UI/atoms/TabBar/TabBar";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";

import ActLists from "./ActLists/ActLists";
import Bills from "./Bills/Bills";
import Contract from "./Contracts";
import Export from "./Export/Export";
import KsList from "./KsList/KsList";
import ExportBtn from "./MakeExport/MakeExport";
import ModalCalculation from "./ModalCalculation";
import PackingLists from "./PackingLists/PackingLists";

import { ACT_LISTS_PATH, BILLS_PATH, CONTRACTS_PATH, EXPORT_PATH, KSES_PATH, PACKING_LISTS_PATH } from "./constants";
import { MODULES_ENUM } from "types/enums/ModulesEnum";

import { useUrlModule } from "utils/hooks/useUrlModule";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import isLaptop from "utils/checkers/isLaptop";

import styles from "./Documents.module.scss";

const Documents = ({ permissions, match }) => {
  const tabMatch = useRouteMatch(match.path + "/:tabPath");
  const history = useHistory();
  const module = useUrlModuleWithObjectId();
  const moduleName = useUrlModule();

  const switchTab = (tabName) => {
    history.push(`/${module}/documents/${tabName}`);
  };

  const [isSwitch, setIsSwitch] = useState(true);
  const [isOpenModalCalculation, setIsOpenModalCalculation] = useState(false);
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);

  const closeModalCalculation = () => {
    setIsOpenModalCalculation(false);
  };

  const tabs = useMemo(() => {
    const result = [];
    console.log(permissions, "permissions");

    if (permissions.viewExportList && tabMatch?.params?.tabPath === EXPORT_PATH && isLaptop) {
      result.push({
        text: "Выберите",
        link: `/${module}/documents/${EXPORT_PATH}`,
        isDesktopHidden: true,
      });
    }
    if (permissions.viewBillsList) result.push({ text: "Счета", link: `/${module}/documents/${BILLS_PATH}` });
    if (permissions.viewPackingLists) {
      result.push({
        text: "УПД",
        link: `/${module}/documents/${PACKING_LISTS_PATH}`,
      });
    }
    if (permissions.viewPackingLists && moduleName !== MODULES_ENUM.OBJECTS) {
      result.push({
        text: "Акты",
        link: `/${module}/documents/${ACT_LISTS_PATH}`,
      });
    }
    if (permissions.viewContractsList && moduleName !== MODULES_ENUM.OBJECTS) {
      result.push({
        text: "Контракты",
        link: `/${module}/documents/${CONTRACTS_PATH}`,
      });
    }
    if (moduleName === MODULES_ENUM.OBJECTS && permissions.viewKsLists) {
      result.push({ text: "КС", link: `/${module}/documents/${KSES_PATH}` });
    }
    return result;
  }, [
    module,
    permissions.viewBillsList,
    permissions.viewContractsList,
    permissions.viewPackingLists,
    permissions.viewKsLists,
    moduleName,
    tabMatch,
  ]);

  useEffect(() => {
    if (tabMatch && tabMatch.params.tabPath) return;

    if (permissions.viewBillsList) {
      history.replace(`/${module}/documents/${BILLS_PATH}`);
    } else if (permissions.viewPackingLists) {
      history.replace(`/${module}/documents/${PACKING_LISTS_PATH}`);
    } else if (permissions.viewContractsList) {
      history.replace(`/${module}/documents/${CONTRACTS_PATH}`);
    } else if (permissions.viewExportList) {
      history.replace(`/${module}/documents/${EXPORT_PATH}`);
    } else {
      history.replace(`/${module}/kses/${KSES_PATH}`);
    }
  }, [history, module, tabMatch?.params.tabPath]);

  return (
    <TemplateBase>
      <ListPageHeaderTemplate>
        <ModalCalculation close={closeModalCalculation} isOpen={isOpenModalCalculation} />
        <TabBar tabs={tabs} />
        <RouterSwitch>
          <Route exact path={`/${module}/documents/${BILLS_PATH}`}>
            <div className={styles.ml_auto} style={{ alignSelf: "center" }}>
              <div style={{ marginRight: "10px" }}>Показать все</div>
              <Switch onChange={() => setIsSwitch((prev) => !prev)} checked={isSwitch} />
            </div>
          </Route>
          {permissions.viewPackingListsVatCalculation && (
            <Route exact path={`/${module}/documents/${PACKING_LISTS_PATH}`}>
              <div onClick={() => setIsOpenModalCalculation(true)} className={styles.ml_auto}>
                <ButtonBase buttonPrimaryEmpty>Подсчет НДС</ButtonBase>
              </div>
            </Route>
          )}
        </RouterSwitch>
        {permissions.viewExportList && (
          <ExportBtn
            onTabClick={() => switchTab(EXPORT_PATH)}
            isOpenExportModal={isOpenExportModal}
            setIsOpenExportModal={setIsOpenExportModal}
          />
        )}
      </ListPageHeaderTemplate>
      <div className={styles.content}>
        <RouterSwitch>
          <Route exact path={`/${module}/documents/${BILLS_PATH}`}>
            <Bills isSwitch={isSwitch} />
          </Route>
          <Route exact path={`/${module}/documents/${PACKING_LISTS_PATH}`} component={PackingLists} />
          <Route exact path={`/${module}/documents/${ACT_LISTS_PATH}`} component={ActLists} />
          <Route exact path={`/${module}/documents/${CONTRACTS_PATH}`}>
            <Contract permissions={permissions} />
          </Route>
          <Route exact path={`/${module}/documents/${KSES_PATH}`} component={KsList} />
          <Route
            exact
            path={`/${module}/documents/${EXPORT_PATH}`}
            render={() =>
              permissions.viewExportList ? (
                <Export
                  onClickExportBtn={() => setIsOpenExportModal(true)}
                  canDeleteExport={permissions.viewDeleteExports}
                />
              ) : (
                <ForbiddenPage />
              )
            }
          />
          <ForbiddenPage />
        </RouterSwitch>
      </div>
    </TemplateBase>
  );
};

export default React.memo(Documents);
