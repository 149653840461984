import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { formatFinanceTreeViaObjectId } from "../../../../../redux/modules/common/constructing/finance/financeCommonUtils";
import {
  allProjectsForecastsListSelector,
  detailedProjectForecastsSelector,
  forecastIsLoadingByObjectsSelector,
  forecastTreeSelector,
  isForecastsLoadingSelector,
} from "../../../../../redux/modules/common/constructing/finance/forecast/selectors";
import {
  getForecastDetailedProjectV2,
  loadAllProjectsForecasts,
  loadDetailedProjectForecast,
  toggleOpenForecastTree,
} from "../../../../../redux/modules/common/constructing/finance/forecast/thunks";
import { objectIdSelector } from "../../../../../redux/modules/common/constructing/finance/summary/selectors";

import { extraForecastInvalidateKeySelector } from "../widgets/AddExtraExpenseModal/model/selectors";

export const useForecastData = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<Record<string, string>>();
  const objectId = useSelector(objectIdSelector);
  const isLoading = useSelector(isForecastsLoadingSelector);
  const allProjectsForecasts = useSelector(allProjectsForecastsListSelector);
  const detailedProjectForecasts = useSelector(detailedProjectForecastsSelector);
  const invalidateKey = useSelector(extraForecastInvalidateKeySelector);

  const tree = useSelector(forecastTreeSelector);

  const displayedTreeWithoutAdvansed = useMemo(
    () => formatFinanceTreeViaObjectId(tree, +objectId, projectId) || [],
    [tree, objectId, projectId]
  );

  const warranties = {
    id: Math.random(),
    children: [],
    isOpen: false,
    name: "Гарантийные обязательства",
    forecast_data: detailedProjectForecasts[objectId]?.warranties,
    parentId: objectId,
  };
  const advancePayment = {
    id: Math.random(),
    name: "Авансирование",
    forecast_data: detailedProjectForecasts[objectId]?.advance_payments,
    parentId: objectId,
    children: [],
    isOpen: false,
  };

  let displayedTree: any[];

  if (advancePayment.forecast_data?.length && warranties.forecast_data?.length) {
    displayedTree = [...displayedTreeWithoutAdvansed, advancePayment, warranties];
  } else if (advancePayment.forecast_data?.length && !warranties.forecast_data?.length) {
    displayedTree = [...displayedTreeWithoutAdvansed, advancePayment];
  } else if (!advancePayment.forecast_data?.length && warranties.forecast_data?.length) {
    displayedTree = [...displayedTreeWithoutAdvansed, warranties];
  } else {
    displayedTree = [...displayedTreeWithoutAdvansed];
  }

  const isFirstLoad = useRef<boolean>(!allProjectsForecasts?.length);

  const isLoadingByObject = (useSelector(forecastIsLoadingByObjectsSelector) as Record<string, boolean>)[
    objectId as string
  ];

  useEffect(() => {
    if (isLoading || !isFirstLoad.current) return;
    dispatch(loadAllProjectsForecasts());
    isFirstLoad.current = false;
  }, [allProjectsForecasts, objectId]);

  const cachedKey = useRef<number | null>(0);
  useEffect(() => {
    if (!+objectId /* || !!detailedProjectForecasts?.[objectId] */) return;
    dispatch(loadDetailedProjectForecast(objectId));
    cachedKey.current = invalidateKey;
  }, [objectId, invalidateKey]);

  useEffect(() => {
    if (projectId && +projectId !== 0) {
      dispatch(getForecastDetailedProjectV2(projectId, objectId));
    }
  }, [projectId, objectId]);

  return {
    displayedTree,
    isLoading,
    isFirstLoad: isFirstLoad.current,
    isLoadingByObject,
  };
};
