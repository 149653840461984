import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import styles from "./TableReusable.module.scss";

type cellPropsType = {
  children: React.ReactNode;
  className?: string;
  isCentered?: boolean;
  isNoBreak?: boolean;
  isRighted?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  isFont13?: boolean;
  title?: string;
};

function Cell({
  children,
  className,
  isCentered,
  isNoBreak,
  isRighted,
  onClick,
  isFont13 = true,
  title,
}: cellPropsType) {
  const isWrapped = isCentered || isNoBreak || isRighted || false;
  const displayTitle = !!title ? title : typeof children !== "object" ? children : undefined;

  return (
    <div className={cn(styles.rowCell, className, isFont13 && styles.font13)} onClick={onClick}>
      {isWrapped && (
        <div
          className={cn({
            [styles.centered]: isCentered,
            [styles.noBreak]: isNoBreak,
            [styles.righted]: isRighted,
          })}
          title={isNoBreak ? (displayTitle as string) : undefined}
        >
          {children}
        </div>
      )}
      {!isWrapped && children}
    </div>
  );
}

export const TableReusableCell = React.memo(Cell);

type propsType = {
  className?: string;
  children: React.ReactNode;
  isEarCounter?: boolean;
  isExpandable?: boolean;
  isDefaultExpanded?: boolean;
  onExpand?: (e?: React.MouseEvent) => void;
  earCount?: number;
  isEarBlue?: boolean;
  isEarGreen?: boolean;
  innerContent?: React.ReactNode;
  onClick?: (e?: React.MouseEvent) => void;
  isHoverable?: boolean;
  isWhiteSvgOnHover?: boolean;
  containerClassName?: string;
  onCollapse?: () => void;
  isParentOfExpenditures?: boolean;
  openClassName?: string;
  isFont13?: boolean;
  dataTestId?: string;
  isOpenByDefault?: boolean;
  isWithIndicator?: boolean;
  isSmall?: boolean;
};

function TableReusableRow({
  children,
  className,
  isEarBlue,
  isEarCounter,
  isEarGreen,
  isExpandable,
  onExpand,
  earCount,
  innerContent,
  onClick,
  isHoverable = true,
  isWhiteSvgOnHover,
  containerClassName,
  onCollapse,
  isDefaultExpanded,
  isParentOfExpenditures,
  openClassName,
  isFont13 = true,
  dataTestId,
  isOpenByDefault = false,
  isWithIndicator = false,
  isSmall,
}: propsType) {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const openHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsOpen((prev) => {
        if (prev) {
          onCollapse?.();
        } else {
          onExpand?.();
        }
        return !prev;
      });
    },
    [setIsOpen, onExpand, isOpen]
  );

  useEffect(() => {
    if (!isDefaultExpanded || !isExpandable || isOpen) return;
    setIsOpen(true);
    onExpand?.();
  }, [isDefaultExpanded, isExpandable]);

  const openExpenditures = useCallback(
    (e: React.MouseEvent) => {
      isParentOfExpenditures && openHandler(e);
    },
    [openHandler, isParentOfExpenditures]
  );

  return (
    <div
      className={cn(styles.row, containerClassName, {
        [styles.active]: isOpen,
        [styles.hoverable]: isHoverable,
        [styles.hoverableWhiteSvg]: isWhiteSvgOnHover,
        [styles.parentOfExpenditures]: isParentOfExpenditures,
        [styles.font13]: isFont13,
        [openClassName || ""]: isOpen && openClassName,
        [styles.withIndicator]: isWithIndicator,
        [styles.small]: isSmall,
      })}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <div className={cn(styles.header, className)} onClick={openExpenditures}>
        {isExpandable && !isParentOfExpenditures && (
          <div
            className={cn(styles.buttonOpen, {
              [styles.blue]: isEarBlue,
              [styles.green]: isEarGreen,
            })}
            onClick={openHandler}
          >
            {isEarCounter && <div className={styles.earCount}>{earCount}</div>}
            <ArrowSmallUpGrey color={"#000"} rotate={String(isOpen ? 0 : 180)} />
          </div>
        )}
        {children}
      </div>
      {isOpen && (
        <div className={styles.content} onClick={stopEventPropagation}>
          {innerContent}
        </div>
      )}
    </div>
  );
}

export default React.memo(TableReusableRow);
