import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { setCriticalDates } from "../../../../../redux/modules/common/building/manufacturing/actions";
import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import { chartViewModeSelector, criticalDatesSelector } from "redux/modules/common/building/manufacturing/selectors";

import Branch from "./components/Branch/Branch";

import RunningLine from "../RunningLine/RunningLine";

import { WORKS_TAB_ID } from "../../constants";

import { useChartTrees } from "../../hooks/useChartTrees";
import { useRem } from "../../hooks/useRem";
import { useRunningLine } from "../../hooks/useRunningLine";
import { useUnitMultiplier } from "../../hooks/useUnitMultiplier";

import { getCriticalDatesFromChartTrees, getDaysInYear, getUnitOffset, getWeeksInYear } from "../../utils";

import styles from "./Month.module.scss";

const Month = ({
  tree,
  year,
  projects,
  type = WORKS_TAB_ID,
  startWeek,
  endWeek,
  checkIsMonthBranchShownAsSection,
  checkIsMonthBranchShownAsExpenditure,
  isGeneratingSpittingTree,
  checkIsShownSectionPlan,
  checkIsShownSuperSectionPlan,
  touchedYears,
  isInConstructing = true,
}) => {
  const dispatch = useDispatch();
  const unitMultiplier = useUnitMultiplier();
  const { REM } = useRem();
  const { objectId } = useParams();
  const chartViewMode = useSelector(chartViewModeSelector);

  const daysInYear = getDaysInYear(+year);
  const weeksInYear = getWeeksInYear(+year);

  const { calcTrees } = useChartTrees({
    tree,
    projects,
    startWeek,
    endWeek,
    type,
    isGeneratingSpittingTree,
    touchedYears,
  });

  // OSLA-5052 Вадим попросил скрыть красные линии критического пути
  // const prevCalcTrees = useRef([]);
  // const criticalDatesInStore = useSelector(criticalDatesSelector);
  //
  // useEffect(() => {
  //   if (_.differenceWith(calcTrees, prevCalcTrees.current, _.isEqual).length) {
  //     const criticalDates = getCriticalDatesFromChartTrees(calcTrees, tree, isInConstructing ? 2 : 1);
  //     if (!_.isEqual(criticalDatesInStore, criticalDates)) {
  //       dispatch(setCriticalDates(criticalDates));
  //     }
  //   }
  // }, [calcTrees, tree, criticalDatesInStore]);

  const monthContainerStyle = {
    minHeight: `${3 * tree.length}rem`,
    width:
      chartViewMode === WEEK
        ? `${weeksInYear * 7 * unitMultiplier * (touchedYears?.length || 1)}rem`
        : chartViewMode === MONTH || chartViewMode === HALF_MONTH
        ? `${daysInYear * unitMultiplier * (touchedYears?.length || 1)}rem`
        : chartViewMode === YEAR
        ? `${weeksInYear * unitMultiplier * (touchedYears?.length || 1)}rem`
        : "100%",
  };

  const monthContainerRef = useRef(null);
  const { runningLineRef } = useRunningLine({
    containerElement: monthContainerRef.current,
    calendarElement: monthContainerRef.current,
  });

  const runningLineWidthRem =
    (chartViewMode === WEEK
      ? weeksInYear * 7 * (touchedYears?.length || 1)
      : chartViewMode === MONTH || chartViewMode === HALF_MONTH
      ? daysInYear * (touchedYears?.length || 1)
      : chartViewMode === YEAR
      ? weeksInYear * (touchedYears?.length || 1)
      : 0) * unitMultiplier;

  return (
    <div className={styles.container} style={monthContainerStyle} ref={monthContainerRef}>
      <RunningLine widthREM={runningLineWidthRem} ref={runningLineRef} />
      {calcTrees.map((currentTree, idx) => {
        return (
          <div className={`${styles.tree} calendar_node`} key={idx}>
            {currentTree.map((branch, index) => {
              return (
                branch.days.length > 0 && (
                  <Branch
                    offsetLeft={
                      (branch.offsetLeft || 0) +
                      (getUnitOffset(touchedYears, touchedYears.indexOf(branch.year), chartViewMode) *
                        unitMultiplier *
                        REM || 0)
                    }
                    branch={branch}
                    key={`${idx}_${index}`}
                    objectId={objectId || branch.objectId}
                    isShownAsSection={checkIsMonthBranchShownAsSection(branch)}
                    isShownSectionPlan={checkIsShownSectionPlan && checkIsShownSectionPlan(branch)}
                    isShownSuperSectionPlan={checkIsShownSuperSectionPlan && checkIsShownSuperSectionPlan(branch)}
                    isShownAsExpenditure={checkIsMonthBranchShownAsExpenditure(branch)}
                  />
                )
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Month);
