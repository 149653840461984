import moment from "moment";
import React, { useEffect, useState } from "react";

import Icon from "components/UI/Icon/Icon";
import Calendar from "components/UI/molecules/Calendar/Calendar";

import Select from "../Select";

import { IIdAndName } from "types/idAndName";

import CalendarBlue from "images/icons/calendarBlue.svg";

import styles from "./DateSelectOrPicker.module.scss";

interface IProps {
  options?: IIdAndName[];
  onSelectChange: (id: string | number) => void;
  onPickerChange: (date: moment.Moment) => void;
  selectValue: number;
  pickerValue: string;
}

const DateSelectOrPicker: React.FC<IProps> = ({
  options,
  onPickerChange,
  onSelectChange,
  selectValue,
  pickerValue,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (!options?.length) return;
    setShowSelect(true);
  }, [options]);

  const pickerChange = (date: moment.Moment) => {
    onPickerChange(date);
    setValue(moment(date).format("DD.MM.YYYY"));
  };

  const selectChange = (id: number | string, name: string | number) => {
    onSelectChange(id);
    setValue(String(name));
  };

  if (!options?.length) {
    return (
      <Calendar icon={CalendarBlue} placeholder="Укажите дату" value={pickerValue} setValue={pickerChange} format="L" />
    );
  }

  if (showSelect) {
    return (
      <Select
        displayIngValue={value}
        value={selectValue}
        options={options}
        onChange={selectChange}
        classNameOptions={styles.closureDatesOptions}
        customDropdown={
          <div className={styles.freeTrigger} onClick={() => setShowSelect(false)}>
            Указать вручную
            <Icon icon={CalendarBlue} className={styles.calendarIcon} />
          </div>
        }
      />
    );
  }

  return (
    <Calendar
      icon={CalendarBlue}
      placeholder="Укажите дату"
      value={pickerValue}
      setValue={pickerChange}
      format="DD.MM.YYYY"
      isDefaultOpen
      onBlur={() => setShowSelect(true)}
    />
  );
};

export default DateSelectOrPicker;
