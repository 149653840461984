import React from "react";
import { Field } from "react-final-form";
import { TASK_MODAL_TYPES, TASKS_REMIND_OPTIONS, TASKS_REPEAT_OPTIONS } from "../../../../constants";
import RadioSquares from "../../../RadioSquares/RadioSquares";
import TaskFiles from "../../../TaskFiles/TaskFiles";
import cn from "classnames";
import styles from "../ModalAddTask/ModalAddTask.module.scss";
import { ICertainTaskResponse } from "redux/modules/purchaser/Tasks/types";
import TaskCommentsContainer from "components/pages/Tasks/components/TaskComments/TaskCommentsContainer";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import MessageIcon from "images/icons/MessageIcon";

const ModalEditTaskExtraFields: React.FC<{
  isVisible?: boolean;
  certainTask: ICertainTaskResponse;
}> = ({ isVisible = true, certainTask }) => {
  const { id, task_comments, task_files } = certainTask;
  return (
    <div
      className={cn(styles.fields, {
        [styles.isVisible]: isVisible,
      })}
    >
      <Field
        name={"remember_task"}
        allowNull
        render={({ input, meta }) => (
          <RadioSquares
            options={TASKS_REMIND_OPTIONS}
            label={"Напомнить"}
            defaultValue={meta.initial}
            onClick={input.onChange}
            value={input.value}
          />
        )}
      />
      <Field
        name={"repeat_task"}
        allowNull
        render={({ input, meta }) => (
          <RadioSquares
            options={TASKS_REPEAT_OPTIONS}
            label={"Повтор"}
            defaultValue={meta.initial}
            onClick={input.onChange}
            value={input.value}
          />
        )}
      />
      <TaskFiles files={task_files} taskId={id} variant={TASK_MODAL_TYPES.EDIT} />
      <TaskCommentsContainer comments={task_comments || []} taskId={id} />
    </div>
  );
};

export default ModalEditTaskExtraFields;
