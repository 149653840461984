import { IJournalExecutionAction } from "./actions";

import { IJournalExecutionInitialState } from "./types";

export const journalExecutionInitialState: IJournalExecutionInitialState = {
  sections: {},
  ticketsBySections: {},
  ticketActingInfo: {
    results: {
      expenditure: null,
      materials: [],
      services: [],
    },
    isLoading: false,
    isApproving: false,
  },
  groupTicketActingInfo: {
    results: {
      expenditure: null,
      materials: [],
      services: [],
      expenditures: [],
    },
    isLoading: false,
    isApproving: false,
  },
  sectionsWithRemarks: {},
  remarksBySections: {},
  createRemark: {
    isLoading: false,
    isSuccess: false,
  },
  editRemark: {
    isLoading: false,
    isSuccess: false,
  },
  remarksList: {
    results: [],
    isLoading: false,
  },
  detailRemarkInfo: {
    result: null,
    isLoading: false,
  },
  deleteRemark: {
    isSuccess: false,
    isLoading: false,
  },
  resolveOrReturnRemark: {
    isLoading: false,
    isSuccess: false,
  },
  acceptRemark: {
    isLoading: false,
  },
  invalidateKey: 0,
  reportInvalidateKey: 0,
  sectionsV2: {},
  expendituresV2: {},
  groups: {},
  contentOfExpenditures: {},
  materialsInvalidateKey: 0,
  sectionsV2Loading: {},
  expendituresV2Loading: {},
};

export default (
  state: IJournalExecutionInitialState = journalExecutionInitialState,
  action: IJournalExecutionAction
): IJournalExecutionInitialState => {
  const { type, payload } = action;
  switch (type) {
    case "journalExecution/SET_SECTIONS":
      return {
        ...state,
        sections: {
          ...state.sections,
          [payload.key]: {
            results: payload.sections,
            isLoading: false,
            filters: {},
          },
        },
      };
    case "journalExecution/SET_SECTIONS_WITH_REMARKS":
      return {
        ...state,
        sectionsWithRemarks: {
          ...state.sectionsWithRemarks,
          [payload.key]: {
            results: payload.sections,
            isLoading: false,
            filters: {},
          },
        },
      };
    case "journalExecution/SET_SECTIONS_LOADING":
      return {
        ...state,
        sections: {
          ...state.sections,
          [payload.key]: {
            ...(state.sections[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "journalExecution/SET_SECTIONS_WITH_REMARKS_LOADING":
      return {
        ...state,
        sectionsWithRemarks: {
          ...state.sectionsWithRemarks,
          [payload.key]: {
            ...(state.sectionsWithRemarks[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "journalExecution/SET_TICKETS":
      return {
        ...state,
        ticketsBySections: {
          ...state.ticketsBySections,
          [payload.key]: {
            results: payload.tickets,
          },
        },
      };
    case "journalExecution/SET_GROUP_TICKETS":
      return {
        ...state,
        ticketsBySections: {
          ...state.ticketsBySections,
          [payload.key]: {
            results: [...payload.tickets, ...state.ticketsBySections[payload.key]?.results],
          },
        },
      };
    case "journalExecution/SET_REMARKS_BY_SECTION":
      return {
        ...state,
        remarksBySections: {
          ...state.remarksBySections,
          [payload.key]: {
            results: payload.remarks,
            isLoading: false,
          },
        },
      };
    case "journalExecution/SET_TICKETS_LOADING":
      return {
        ...state,
        ticketsBySections: {
          ...state.ticketsBySections,
          [payload.key]: {
            ...(state.ticketsBySections[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "journalExecution/SET_REMARKS_BY_SECTION_LOADING":
      return {
        ...state,
        remarksBySections: {
          ...state.remarksBySections,
          [payload.key]: {
            ...(state.remarksBySections[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "journalExecution/SET_TICKET_IN_SECTION":
      return {
        ...state,
        ticketsBySections: {
          ...state.ticketsBySections,
          [payload.key]: {
            results:
              state.ticketsBySections[payload.key]?.results?.map((ticket) =>
                ticket?.id === payload.ticket?.id ? payload.ticket : ticket
              ) || [],
            isLoading: false,
          },
        },
      };
    case "journalExecution/SET_IS_CREATE_REMARK_SUCCESS":
      return {
        ...state,
        createRemark: {
          ...state.createRemark,
          isSuccess: payload,
        },
      };
    case "journalExecution/SET_IS_CREATE_REMARK_LOADING":
      return {
        ...state,
        createRemark: {
          ...state.createRemark,
          isLoading: payload,
        },
      };
    case "journalExecution/SET_IS_EDIT_REMARK_SUCCESS":
      return {
        ...state,
        editRemark: {
          ...state.editRemark,
          isSuccess: payload,
        },
      };
    case "journalExecution/SET_IS_EDIT_REMARK_LOADING":
      return {
        ...state,
        editRemark: {
          ...state.editRemark,
          isLoading: payload,
        },
      };
    case "journalExecution/SET_REMARKS_LIST_DATA":
      return {
        ...state,
        remarksList: {
          ...state.remarksList,
          results: payload,
        },
      };
    case "journalExecution/SET_IS_REMARKS_LIST_LOADING":
      return {
        ...state,
        remarksList: {
          ...state.remarksList,
          isLoading: payload,
        },
      };
    case "journalExecution/SET_DETAIL_REMARK_INFO_DATA":
      return {
        ...state,
        detailRemarkInfo: {
          ...state.detailRemarkInfo,
          result: payload,
        },
      };
    case "journalExecution/SET_IS_DETAIL_REMARK_INFO_LOADING":
      return {
        ...state,
        detailRemarkInfo: {
          ...state.detailRemarkInfo,
          isLoading: payload,
        },
      };
    case "journalExecution/SET_IS_DELETE_REMARK_LOADING":
      return {
        ...state,
        deleteRemark: {
          ...state.deleteRemark,
          isLoading: payload,
        },
      };
    case "journalExecution/SET_IS_DELETE_REMARK_SUCCESS":
      return {
        ...state,
        deleteRemark: {
          ...state.deleteRemark,
          isSuccess: payload,
        },
      };
    case "journalExecution/SET_IS_RESOLVE_OR_RETURN_REMARK_LOADING":
      return {
        ...state,
        resolveOrReturnRemark: {
          ...state.resolveOrReturnRemark,
          isLoading: payload,
        },
      };
    case "journalExecution/SET_IS_RESOLVE_OR_RETURN_REMARK_SUCCESS":
      return {
        ...state,
        resolveOrReturnRemark: {
          ...state.resolveOrReturnRemark,
          isSuccess: payload,
        },
      };
    case "journalExecution/SET_IS_ACCEPT_REMARK_LOADING":
      return {
        ...state,
        acceptRemark: {
          ...state.acceptRemark,
          isLoading: payload,
        },
      };
    case "journalExecution/SET_TICKET_ACTING_IS_APPROVING":
      return { ...state, ticketActingInfo: { ...state.ticketActingInfo, isApproving: payload } };
    case "journalExecution/SET_TICKET_ACTING_INFO_DATA":
      return {
        ...state,
        ticketActingInfo: {
          isApproving: state.ticketActingInfo.isApproving,
          isLoading: state.ticketActingInfo.isLoading,
          results: {
            expenditure: {
              ...payload?.expenditure,
              sum_accepted_amount_for_one:
                +payload?.expenditure?.sum_accepted_amount / +payload?.expenditure?.sum_accepted_count || 0,
            },
            materials: payload?.materials
              .filter((item) => +item.sum_accepted_amount > 0)
              ?.map((item) => ({
                ...item,
                sum_accepted_amount_for_one: +item.sum_accepted_amount / +item.sum_accepted_count,
              })),
            services: payload?.services
              .filter((item) => +item.sum_accepted_amount > 0)
              ?.map((item) => ({
                ...item,
                sum_accepted_amount_for_one: +item.sum_accepted_amount / +item.sum_accepted_count,
              })),
          },
        },
      };
    case "journalExecution/SET_GROUP_TICKET_ACTING_INFO_DATA":
      return {
        ...state,
        groupTicketActingInfo: {
          ...state.groupTicketActingInfo,
          results: {
            ...payload,
            materials: payload.materials
              .filter((item) => +item.sum_accepted_amount > 0)
              .map((item) => ({
                ...item,
                sum_accepted_amount_for_one: +item.sum_accepted_amount / +item.sum_accepted_count,
              })),
            services: payload.services
              .filter((item) => +item.sum_accepted_amount > 0)
              .map((item) => ({
                ...item,
                sum_accepted_amount_for_one: +item.sum_accepted_amount / +item.sum_accepted_count,
              })),
          },
        },
      };
    case "journalExecution/SET_GROUP_TICKET_ACTING_INFO_LOADING":
      return { ...state, groupTicketActingInfo: { ...state.groupTicketActingInfo, isLoading: payload } };
    case "journalExecution/REMOVE_ONE_TICKET_ACTING_INFO_DATA":
      return {
        ...state,
        ticketActingInfo: {
          ...state.ticketActingInfo,
          results: {
            expenditure:
              state.ticketActingInfo.results.expenditure?.id !== payload
                ? state.ticketActingInfo.results.expenditure
                : {},
            materials: [...state.ticketActingInfo.results.materials.filter((el) => el.id !== payload)],
            services: [...state.ticketActingInfo.results.services.filter((el) => el.id !== payload)],
          },
        },
      };
    case "journalExecution/SET_IS_TICKET_ACTING_INFO_LOADING":
      return {
        ...state,
        ticketActingInfo: {
          ...state.ticketActingInfo,
          isLoading: payload,
        },
      };
    case "journalExecution/CHANGE_REPORT_INVALIDATE_KEY":
      return {
        ...state,
        reportInvalidateKey: state.reportInvalidateKey + 1,
      };
    case "journalExecution/CHANGE_INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    ////////////////////// V2
    case "journalFulfillment/SET_SECTIONS":
      return {
        ...state,
        sectionsV2: {
          ...state.sectionsV2,
          [payload.buildingId]: payload.sections,
        },
      };
    case "journalFulfillment/SET_EXPENDITURES":
      return {
        ...state,
        expendituresV2: {
          ...state.expendituresV2,
          [payload.sectionId]: payload.expenditures,
        },
      };
    case "journalFulfillment/SET_GROUP":
      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.key]: payload.group,
        },
      };
    case "journalFulfillment/SET_CONTENT_OF_EXPENDITURE":
      return {
        ...state,
        contentOfExpenditures: {
          ...state.contentOfExpenditures,
          [payload.key]: payload.content,
        },
      };
    case "journalExecution/CHANGE_MATERIALS_INVALIDATE_KEY":
      return {
        ...state,
        materialsInvalidateKey: state.materialsInvalidateKey + 1,
      };
    case "journalExecution/SET_SECTIONS_V2_LOADING":
      return {
        ...state,
        sectionsV2Loading: {
          ...state.sectionsV2Loading,
          [payload.key]: payload.status,
        },
      };
    case "journalExecution/SET_EXPENDITURES_V2_LOADING":
      return {
        ...state,
        expendituresV2Loading: {
          ...state.expendituresV2Loading,
          [payload.key]: payload.status,
        },
      };
    default:
      return state;
  }
};
