import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import Checkbox from "../../../../UI/Checkbox";
import Amounts from "../../../../UI/molecules/Amounts/Amounts";
import DeliveryTerms from "../../../../UI/molecules/DeliveryTerms";
import deliveryTerms from "../../../../UI/molecules/DeliveryTerms";
import PaymentTerms from "../../../../UI/molecules/PaymentTerms";
import { Switch } from "components/UI/atoms/Switch/Switch";

import styles from "./Footer.module.scss";

const OrderFooter = ({ order, changeOrder, canEdit, ordersSum, ordersTax }) => {
  const authEntityType = useSelector((state) => state.auth.entity.type);
  const isPurchaser = authEntityType === "pcr";

  const onChangeTaxIncluding = useCallback(
    (e) => changeOrder({ ...order, including_vat: e.target.checked }),
    [order, changeOrder]
  );

  const changePaymentTerms = useCallback(
    (changedPaymentTerms) =>
      changeOrder({ ...order, payment_terms: changedPaymentTerms.map(({ id, ...term }) => term) }),
    [changeOrder, order]
  );

  const changeDeliveryTerms = useCallback(
    (changedTerms) => changeOrder({ ...order, delivery_terms: changedTerms.map(({ id, ...term }) => term) }),
    [order, changeOrder]
  );

  const disagreeDeliveryChange = useCallback(
    (val) => changeOrder({ ...order, disagree_delivery: val }),
    [order, changeOrder]
  );

  const deliveryAmount = useMemo(() => {
    const selectedTerm = order.delivery_terms.filter((t) => t.confirmed)?.[0] ?? null;

    if (selectedTerm) {
      return +selectedTerm.cost;
    }

    return 0;
  }, [order.delivery_terms]);

  return (
    <footer className={styles.footer}>
      <div className={styles.termsContainer}>
        <PaymentTerms
          status={order.status}
          selectedTerms={order.payment_terms}
          isPurchaser={isPurchaser && !order.is_internal}
          changeSelectedTerms={changePaymentTerms}
          disabled={!canEdit}
        />
        <DeliveryTerms
          status={order.status}
          terms={order.delivery_terms}
          termChangeHandler={changeDeliveryTerms}
          disagree_delivery={order.disagree_delivery}
          disagreeDeliveryChange={disagreeDeliveryChange}
          isPurchaser={isPurchaser}
          isInternal={order.is_internal}
          disabled={!canEdit}
        />
      </div>
      <div className={styles.amountsContainer}>
        <div className={styles.checkbox}>
          {(!isPurchaser || order.is_internal) && (
            <div className={styles.switchContainer}>
              <Switch className={styles.switcher} checked={order.including_vat} onChange={onChangeTaxIncluding} disabled={!canEdit} />
              <span>Включая НДС</span>
            </div>
          )}
        </div>
        <Amounts
          productsAmount={ordersSum}
          taxAmount={ordersTax}
          deliveryAmount={deliveryAmount}
          totalAmount={ordersSum + deliveryAmount}
          taxIsInclude={order.including_vat}
        />
      </div>
    </footer>
  );
};

export default React.memo(OrderFooter);
