import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  approveGroupPlan,
  getGroupIntervalsList,
  getGroupRemarks,
  getGroupTicketRemarks,
} from "redux/modules/common/building/processApi";
import { IGroupIntervalResponse, GroupIntervalModulesType } from "redux/modules/common/building/processTypes";
import { getExpendituresGroups } from "redux/modules/common/building/shedules";

import { useUIIndicator } from "../../../../../../hooks/uiIndicators/useUIIndicator";
import { useCanEditPlan } from "components/pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/useCanEditPlan";

import { MODULES_ENUM } from "../../../../../../types/enums/ModulesEnum";
import { IIntervalGroupExpenditure } from "types/interfaces/Expenditure";

import { useUrlModule } from "../../../../../../utils/hooks/useUrlModule";
import { Responsibilities, useCheckIsCurrentUserResponsible } from "hooks/useCheckIsCurrentUserResponsible";

import { errorCatcher } from "utils/helpers/errorCatcher";
import {
  MAIN_PLAN_TAB, PLAN_TABS,
  RELATIONS_PLAN_TAB
} from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/constants";
import { useCommonModulesPermissions } from "../../../../../../hooks/useCommonModulesPermissions";
import {
  VIEW_MANUFACTURING_CHART_EDIT_RELATIONS
} from "../../../../../../constants/permissions/manufacturingPermissions";
import {
  VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS
} from "../../../../../../constants/permissions/constructingPermissions";
import {
  useLoadIntervalRelations
} from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/IntervalRelationsContent/useLoadIntervalRelations";
import { useParams } from "react-router-dom";
import { simpleResourcesAPI } from "../../../../../../features/simpleResources/lib/api";

interface IProps {
  isOpen: boolean;
  buildingId: string;
  expenditure: Record<string, IIntervalGroupExpenditure[]>;
  expenditureId: number;
  dateStart: string;
  dateEnd: string;
  activeModule: GroupIntervalModulesType;
  onClose: () => void;
  defaultOpenedIntervalId?: string | null;
}

type TPlanTab = typeof MAIN_PLAN_TAB | typeof RELATIONS_PLAN_TAB;

export const useModalGroupIntervalModal = ({
  isOpen,
  buildingId,
  expenditure,
  expenditureId,
  dateEnd,
  dateStart,
  activeModule,
  onClose,
  defaultOpenedIntervalId = null
}: IProps) => {
  const dispatch = useDispatch();
  const module = useUrlModule();
  const [intervalId, setIntervalId] = useState<null | number>(() => defaultOpenedIntervalId);
  const { objectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IGroupIntervalResponse | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isEditingPlan, setIsEditingPlan] = useState(false);
  const [tab, setTab] = useState<TPlanTab>(MAIN_PLAN_TAB);

  const isOpenedOnChart = window.location.pathname.includes("manufacturing");

  const canEditPlan = useCanEditPlan({
    isActive: isOpen,
    isSection: false,
    approvals: data?.approvals,
    objectId: buildingId,
  });
  const planIsInFuture = !!data && moment(data?.start_at).isAfter(moment(), "day");

  const canApprovePlan = useCheckIsCurrentUserResponsible({
    responsibleFor: Responsibilities.planWorkConfirm,
    projectId: buildingId,
    isActive: isOpen,
  });

  const modalStatus = useMemo(() => {
    if (activeModule === "plans") {
      const formattedCurrentDate = moment().format("YYYY/MM/DD");
      const formattedDateStart = moment(data?.start_at, "YYYY/MM/DD");
      const formattedDateEnd = moment(data?.end_at, "YYYY/MM/DD");

      return moment(formattedCurrentDate).isBetween(formattedDateStart, formattedDateEnd, undefined, "[]")
        ? "actived"
        : moment(formattedCurrentDate).isAfter(formattedDateEnd)
          ? "completed"
          : "planed";
    }

    if (activeModule === "facts") {
      return data?.is_confirmed ? "accepted" : "completed";
    }
  }, [data, activeModule]);

  const expendituresOnCurrentWeek = useMemo(() => {
    if (data?.week && expenditure?.[data.week]) {
      return expenditure[data.week];
    } else {
      if (activeModule === "plans") {
        return [expenditure];
      }

      if (activeModule === "facts") {
        return [data];
      }
    }

    return [];
  }, [expenditure, data]);

  useEffect(() => {
    if (isOpen && intervalId !== expenditureId) {
      if (activeModule === "facts") {
        getGroupIntervalsListRequest(intervalId || expenditureId, isOpenedOnChart);
      } else {
        getGroupIntervalsListRequest(intervalId || expenditureId, false);
      }
    }
  }, [isOpen, expenditureId, intervalId]);

  useEffect(() => {
    if (expendituresOnCurrentWeek?.length === 1) {
      handleChangeIntervalId(expendituresOnCurrentWeek[0]?.id);
    }
  }, [expenditure, data]);

  const getGroupIntervalsListRequest = useCallback(
    (intervalId: number, isNeedToGetRemarks: boolean = false) => {
      setIsLoading(true);
      getGroupIntervalsList({
        buildingId,
        intervalId,
        activeModule,
      })
        .then(async (resp) => {
          const groupId = resp.data?.group?.id;

          if (groupId && isNeedToGetRemarks) {
            await Promise.allSettled([
              getGroupRemarks(buildingId, resp.data?.group?.id, { with_files: 1 })
                .then(({ data }) => {
                  resp.data.remarks = data?.results;
                })
                .catch(errorCatcher),
              getGroupTicketRemarks(buildingId, intervalId, { with_files: 1 })
                .then(({ data }) => {
                  resp.data.ticketRemarks = data?.results;
                })
                .catch(errorCatcher),
            ]);
          }

          let simpleResources;

          if (activeModule === "facts") {
            const simpleResourcesResponse = await simpleResourcesAPI.getAll({buildingId, fact_group_id: intervalId});
            simpleResources = simpleResourcesResponse?.results ?? []
          }

          setData({
            ...resp.data,
            week: moment(resp.data.start_at, "YYYY-MM-DD").week(),
            simpleResources: simpleResources ?? []
          });
        })
        .catch(errorCatcher)
        .finally(() => setIsLoading(false));
    },
    [buildingId, intervalId, activeModule]
  );

  const clearIntervalDataHandler = () => {
    setData(null);
    setIntervalId(null);
  };

  const handleChangeIntervalId = useCallback((id: number | null) => {
    setIntervalId(id);
  }, []);

  const handleChangeIsAddingNew = useCallback((status: boolean) => {
    setIsAddingNew(status);
  }, []);

  const handleOpenEditingPlan = useCallback(() => {
    setIsEditingPlan(true);
  }, []);

  const handleCloseEditingPlan = useCallback(() => {
    setIsEditingPlan(false);
  }, []);

  const closeHandler = () => {
    onClose();
    clearIntervalDataHandler();
    setIsAddingNew(false);
    setIsEditingPlan(false);
  };

  const { deleteIndicator } = useUIIndicator({
    type: "confirm_expenditure_plan",
    module: module as MODULES_ENUM,
    buildingId: +buildingId,
    data: {
      exp_id: data?.group?.id,
      child_section_id: data?.group?.section_id,
    },
  });

  const approveHandler = useCallback(() => {
    setIsLoading(true);
    approveGroupPlan({ buildingId, planGroupId: data.id })
      .then(() => {
        dispatch(getExpendituresGroups({ buildingId, sectionId: data?.group?.section_id! }));
        getGroupIntervalsListRequest(intervalId, isOpenedOnChart && activeModule === "facts");
        deleteIndicator();
      })
      .catch(errorCatcher)
      .finally(() => setIsLoading(false));
  }, [buildingId, intervalId, data]);

  useEffect(() => {
    if (expendituresOnCurrentWeek?.length && defaultOpenedIntervalId) {
      handleChangeIntervalId(+defaultOpenedIntervalId);
    }
  }, [defaultOpenedIntervalId, expendituresOnCurrentWeek])

  const hasEditRelationsPermission = useCommonModulesPermissions({
    objects: VIEW_MANUFACTURING_CHART_EDIT_RELATIONS,
    constructing: VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS,
  });

  const { hasRelations, relationsFromCurrentInterval, relationsToCurrentInterval } = useLoadIntervalRelations({
    projectId: +objectId,
    intervalId: intervalId,
    isGroup: true
  });

  return {
    handleChangeIntervalId,
    intervalId,
    isLoading,
    data,
    modalStatus,
    isAddingNew,
    isEditingPlan,
    closeHandler,
    handleChangeIsAddingNew,
    handleOpenEditingPlan,
    handleCloseEditingPlan,
    expendituresOnCurrentWeek,
    canEditPlan,
    planIsInFuture,
    canApprovePlan,
    approveHandler,
    getGroupIntervalsListRequest,
    tab,
    setTab,
    hasEditRelationsPermission,
    hasRelations,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
    clearIntervalDataHandler
  };
};
