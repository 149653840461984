import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDetail, setDetailNull } from "../../../../redux/modules/common/building/object/nowObject";
import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import {
  chartViewModeSelector,
  manufacturingHashSelector,
} from "redux/modules/common/building/manufacturing/selectors";
import {
  dropLoadedChartMonths,
  loadArrows,
  loadMaterials,
  loadMimes,
  loadPlan,
  loadTree,
  loadWeekMaterials,
  loadWeekMimes,
  loadWeekPlan,
  setIsLoadingChartData,
  setManufacturingExpandedBranches,
} from "redux/modules/common/building/manufacturing/thunks";

import {
  EQUIPMENT_TAB_ID,
  MATERIALS_TAB_ID,
  MIMES_TAB_ID,
  RESOURCES_TAB_ID,
  WORKS_TAB_ID,
} from "../../Manufacturing/constants";
import {
  ExpandedBranchesType,
  IMonthArrayElement,
  ISpittingTreeElement,
  ManufacturingTabsType,
} from "../../Manufacturing/types";

import { getWeeksInYear } from "../../Manufacturing/utils";

export interface IUseProjectTreeProps {
  projectId: number | string;
  year: string;
  month: IMonthArrayElement;
  startWeek: number;
  endWeek: number;
  expandedBranches: ExpandedBranchesType;
  generateSpittingTree: () => void;
  expenditureTypesToLoad: Record<ManufacturingTabsType, boolean>;
}

export const useProjectTree = ({
  projectId,
  year,
  month,
  startWeek,
  endWeek,
  expandedBranches,
  generateSpittingTree,
  expenditureTypesToLoad,
}: IUseProjectTreeProps) => {
  const dispatch = useDispatch();
  const chartViewMode = useSelector(chartViewModeSelector);
  const manufacturingHash = useSelector(manufacturingHashSelector);
  const needSmoothUpdate = useRef(false);

  const loadEstimateTree = useCallback(() => {
    dispatch(loadTree(projectId, expenditureTypesToLoad));
  }, [projectId, expenditureTypesToLoad]);

  const loadWeeksPlan = () => {
    if (chartViewMode === YEAR) {
      dispatch(loadWeekPlan(projectId, 1, getWeeksInYear(+year), year));
    } else {
      dispatch(loadWeekPlan(projectId, startWeek, endWeek, year));
    }
  };

  const loadDaysMimes = () => {
    dispatch(loadMimes(projectId, year, month.id + 1));
  };

  const loadDaysMaterials = () => {
    dispatch(loadMaterials(projectId, year, month.id + 1));
  };

  const loadDaysPlan = () => {
    dispatch(loadPlan(projectId, year, month.id + 1));
  };

  const loadWeeksMaterials = () => {
    if (chartViewMode === YEAR) {
      dispatch(loadWeekMaterials(projectId, 1, getWeeksInYear(+year), year));
    } else {
      dispatch(loadWeekMaterials(projectId, startWeek, endWeek, year));
    }
  };

  const loadWeeksMimes = () => {
    if (chartViewMode === YEAR) {
      dispatch(loadWeekMimes(projectId, 1, getWeeksInYear(+year), year));
    } else {
      dispatch(loadWeekMimes(projectId, startWeek, endWeek, year));
    }
  };

  useEffect(() => {
    dispatch(setIsLoadingChartData(true));
    dispatch(setDetailNull());

    if (!projectId)
      return () => {
        dispatch(setIsLoadingChartData(false));
      };

    dispatch(dropLoadedChartMonths());
    loadEstimateTree();
    dispatch(loadArrows(projectId));

    dispatch(getDetail(projectId, () => dispatch(setIsLoadingChartData(false))));

    needSmoothUpdate.current = true;

    return () => {
      dispatch(setDetailNull());
      needSmoothUpdate.current = false;
    };
  }, [projectId, loadEstimateTree]);

  useEffect(() => {
    if (!needSmoothUpdate.current) return;
    dispatch(getDetail(projectId));
  }, [projectId, manufacturingHash]);

  useEffect(() => {
    if (chartViewMode === MONTH || chartViewMode === HALF_MONTH) {
      expenditureTypesToLoad[WORKS_TAB_ID] && loadDaysPlan();
      (expenditureTypesToLoad[MATERIALS_TAB_ID] || expenditureTypesToLoad[RESOURCES_TAB_ID]) && loadDaysMaterials();
      (expenditureTypesToLoad[MIMES_TAB_ID] ||
        expenditureTypesToLoad[RESOURCES_TAB_ID] ||
        expenditureTypesToLoad[EQUIPMENT_TAB_ID]) &&
        loadDaysMimes();
    }
  }, [chartViewMode, year, month.id, projectId, expenditureTypesToLoad, manufacturingHash]);

  useEffect(() => {
    if (chartViewMode === WEEK || chartViewMode === YEAR) {
      expenditureTypesToLoad[WORKS_TAB_ID] && loadWeeksPlan();
      (expenditureTypesToLoad[MATERIALS_TAB_ID] || expenditureTypesToLoad[RESOURCES_TAB_ID]) && loadWeeksMaterials();
      (expenditureTypesToLoad[MIMES_TAB_ID] ||
        expenditureTypesToLoad[RESOURCES_TAB_ID] ||
        expenditureTypesToLoad[EQUIPMENT_TAB_ID]) &&
        loadWeeksMimes();
    }
  }, [chartViewMode, year, startWeek, endWeek, projectId, expenditureTypesToLoad, manufacturingHash]);

  useEffect(() => {
    dispatch(loadArrows(projectId));
    return () => {
      dispatch(dropLoadedChartMonths());
    };
  }, [manufacturingHash, projectId]);

  const toggleBranch = (branch: ISpittingTreeElement) => {
    if (expandedBranches.has(branch.id)) {
      expandedBranches.delete(branch.id);
      dispatch(setManufacturingExpandedBranches(expandedBranches));
    } else {
      expandedBranches.add(branch.id);
      dispatch(setManufacturingExpandedBranches(expandedBranches));
    }

    generateSpittingTree();
  };

  const checkIsExpandedBranchId = useCallback(
    (id: number) => {
      return expandedBranches.has(id);
    },
    [expandedBranches]
  );

  return {
    toggleBranch,
    checkIsExpandedBranchId,
  };
};
