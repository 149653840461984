import { message } from "antd";
import cn from "classnames";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import DoubleInput, { Measure } from "../../../../../../../../UI/atoms/DoubleInput/DoubleInput";
import Select, { IOption } from "../../../../../../../../UI/atoms/Select";

import { PositionModalContext } from "../../PositionModal";
import { IPositionInModal } from "../../useEditPositions";

import { POSITION_STAKES, RANK_TYPE_PRIORITIES } from "../../constants";

import intToRoman from "../../../../../../../../../utils/formatters/intToRoman";
import {
  Options,
  transformDigitToFinancial,
  useTransformToFinancial,
} from "../../../../../../../../../utils/formatters/transformDigitToFinancial";

import CheckCircle from "../../../../../../../../../images/icons/CheckCircle";
import CrossInCircleRounded from "../../../../../../../../../images/icons/CrossInCircleRounded";

import styles from "./PositionModalRow.module.scss";

const FINANCIAL_OPTIONS: Options = {
  withFloat: true,
  dropZeros: true,
};

export interface IPositionModalRowProps {
  position: IPositionInModal;
  isEdit: boolean;
  canDelete?: boolean;
  onDelete: () => void;
  onApprove: (payload: Partial<IPositionInModal>) => void;
  index?: number;
  hasTopBorder?: boolean;
  availableRanks: IOption[];
  availableRankTypes: Record<number, IOption[]>;
  isDisplay: boolean;
}

const PositionModalRow: React.FC<IPositionModalRowProps> = ({
  position,
  isEdit,
  canDelete,
  onDelete,
  onApprove,
  index,
  hasTopBorder,
  availableRanks,
  availableRankTypes,
  isDisplay,
}) => {
  const [draftPosition, setDraftPosition] = useState<Partial<IPositionInModal>>(position);
  const { setUnsavedChanges } = React.useContext(PositionModalContext);
  const handleChangeDraft = useCallback((fieldName: keyof IPositionInModal, value: string | number) => {
    setDraftPosition((prevState) => ({ ...prevState, [fieldName]: value }));
  }, []);

  const handleApproveClick = () => {
    if (!draftPosition.stake) {
      message.warn("Укажите ставку для должности");
      return;
    }
    if (parseInt(draftPosition.stake).toString().length > 13) {
      message.warn("Укажите значение ставки не более 13 символов до дробной части");
      return;
    }
    if (!draftPosition.rank) {
      message.warn("Укажите разряд для должности");
      return;
    }
    if (!draftPosition.type) {
      message.warn("Укажите тип ставки для должности");
      return;
    }
    const approveCandidate = { ...draftPosition, isNew: false };
    onApprove(approveCandidate);
    setDraftPosition(approveCandidate);
  };

  const { handleChangeValue: handleChangeStake, formattedValue: formattedStake } = useTransformToFinancial(
    draftPosition.stake || "",
    FINANCIAL_OPTIONS,
    (v) => handleChangeDraft("stake", v)
  );

  const isDirty = useMemo(() => !_.isEqual(position, draftPosition), [position, draftPosition]);

  useEffect(() => {
    if (!position.id) return;
    setUnsavedChanges(position.id, isDirty);
  }, [isDirty, position.id]);

  const rankOptions: IOption[] = useMemo(() => {
    const draftOption = { id: draftPosition.rank || 0, name: draftPosition.rank ? intToRoman(draftPosition.rank) : "" };
    if (!draftOption.id) return availableRanks;
    if (!availableRanks?.length) return [draftOption];
    if (availableRanks.findIndex((rank) => rank.id === draftOption.id) === -1)
      //@ts-ignore
      return [...availableRanks, draftOption].sort((a, b) => a.id - b.id);
    return availableRanks;
  }, [availableRanks, draftPosition.rank]);

  const typeOptions: IOption[] = useMemo(() => {
    const draftOption = {
      id: draftPosition.type || "",
      name: !!draftPosition.type ? POSITION_STAKES[draftPosition.type] : "" || "",
    };
    if (!draftOption.id?.length) return availableRankTypes[draftPosition.rank!];
    if (!availableRankTypes[draftPosition.rank!]?.length) return [draftOption];
    if (availableRankTypes[draftPosition.rank!].findIndex((type) => type.id === draftOption.id) === -1)
      return [...availableRankTypes[draftPosition.rank!], draftOption].sort(
        //@ts-ignore
        (a, b) => RANK_TYPE_PRIORITIES[a.id] - RANK_TYPE_PRIORITIES[b.id]
      );
    return availableRankTypes[draftPosition.rank!];
  }, [availableRankTypes, draftPosition.rank]);

  return (
    <div
      className={cn(styles.row, {
        [styles.display]: isDisplay,
        [styles.lightblueBg]: index !== undefined ? index % 2 !== 0 : false,
        [styles.withTopBorder]: hasTopBorder,
      })}
    >
      <div className={styles.rank}>
        {!isEdit && <span>Разряд {!isEdit && (position.rank_display || intToRoman(position.rank!))}</span>}
        {isEdit && (
          <Select
            value={draftPosition.rank}
            options={rankOptions}
            onChange={(value) => handleChangeDraft("rank", value)}
            className={styles.rankSelect}
            selectClassName={styles.selectInput}
            classNameOptions={styles.selectOptions}
          />
        )}
      </div>
      <div className={styles.stake}>
        {!isEdit && (
          <span>
            {position.type_display || `Ставка за ${(!!position.type && POSITION_STAKES[position.type]) || ""}`}
          </span>
        )}
        {isEdit && (
          <Select
            disabled={!draftPosition.rank}
            value={draftPosition.type}
            options={typeOptions}
            onChange={(value) => handleChangeDraft("type", value)}
            className={styles.typeSelect}
            selectClassName={styles.selectInput}
            classNameOptions={styles.selectOptions}
          />
        )}
      </div>
      <div className={styles.stakeDisplay}>
        {isEdit && (
          <DoubleInput
            oneInputMode
            firstValue={formattedStake as string}
            firstValueMeasure={Measure.currency}
            onChangeFirstValue={handleChangeStake}
            className={styles.stakeInput}
          />
        )}
        {!isEdit &&
          transformDigitToFinancial(draftPosition.stake || "", { ...FINANCIAL_OPTIONS, withCurrencySign: true })}
      </div>
      <div className={styles.icon}>
        {(position.isNew || isDirty) && <CheckCircle onClick={handleApproveClick} color={"#000"} />}
        {(isEdit || canDelete) && <CrossInCircleRounded onClick={onDelete} fill={"#000"} />}
      </div>
    </div>
  );
};

export default React.memo(PositionModalRow);
