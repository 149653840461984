import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import AddExpendituresInModal from "../../../../../../UI/organism/AddingListInModal/AddExpendituresInModal";
import { useExpendituresInGroups } from "../../../../../../UI/organism/AddingListInModal/hooks/useExpendituresInGroups";
import Button from "components/UI/atoms/ButtonBase";
import InputNumber from "components/UI/atoms/InputNumber";
import Select from "components/UI/atoms/Select";
import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import AddMaterialsInModal from "components/UI/organism/AddingListInModal/AddMaterialsInModal";
import AddMimsInModal from "components/UI/organism/AddingListInModal/AddMimsInModal";
import AddWorkersInModal from "components/UI/organism/AddingListInModal/AddWorkersInModal";
import { useSelectingMaterials } from "components/UI/organism/AddingListInModal/hooks/useSelectingMaterials";
import { useSelectingMims } from "components/UI/organism/AddingListInModal/hooks/useSelectingMims";
import { useSelectingWorkers } from "components/UI/organism/AddingListInModal/hooks/useSelectingWorkers";

import { useProductIntervalForm } from "./useProductIntervalForm";

import { IMaterialInAddingInFact } from "types/interfaces/Materials";
import { IMimsInAdding } from "types/interfaces/Mims";

import styles from "./ProductIntervalCard.module.scss";
import { useSimpleResources } from "../../../../../../UI/organism/AddingListInModal/hooks/useSimpleResources";

type propsType = {
  handleClose: () => void;
  objectId: string | number;
  directlySection?: number;
  directlyWork?: number;
  isOpen?: boolean;
  onAddToJournalCallback?: () => void;
  planId?: number | null;
  defaultWorkGroupId?: number;
};

function ProductIntervalCardContent({
  handleClose,
  objectId,
  directlySection,
  directlyWork,
  isOpen,
  onAddToJournalCallback,
  planId,
  defaultWorkGroupId,
}: propsType) {
  const [count, setCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [selectedWorkGroupId, setSelectedWorkGroupId] = useState<number | null>(defaultWorkGroupId || null);
  const onSelectWorkGroup = useCallback((id: number | null) => setSelectedWorkGroupId(id), []);

  useEffect(() => {
    if (!defaultWorkGroupId) return;
    onSelectWorkGroup(defaultWorkGroupId);
  }, [defaultWorkGroupId]);

  const handleCloseModal = () => {
    handleClose();
    clearWorkers();
    clearMaterials();
    clearMims();
    clearForm();
  };

  useEffect(() => {
    if (!isOpen) {
      clearWorkers();
      clearMaterials();
      clearForm();
      setFiles([]);
      clearExpendituresInGroups();
    }
  }, [isOpen]);

  const {
    addedExpenditures,
    changeExpendituresCountHandler,
    validateSubmittingExpenditures,
    createGroupExpendituresFact,
    workGroupMaterials,
    workGroupMims,
    clearExpendituresInGroups,
  } = useExpendituresInGroups({
    selectedWorkGroupId,
    count,
    setCount,
    isOpen,
  });

  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers,
  } = useSelectingWorkers();

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    deletedMaterials,
    clearMaterials,
  } = useSelectingMaterials(workGroupMaterials);

  const {
    confirmMimsHandler,
    cancelMimsHandler,
    changeCountMimsHandler,
    validateSubmittingMims,
    deleteAddedHandler: deleteAddedMims,
    selectMimsHandler,
    addedMims,
    selectedMims,
    selectedMimsIds,
    addedMimsIds,
    deletedMims,
    clearMims,
  } = useSelectingMims(workGroupMims);

  const {
    handleChangeWorks,
    handleChangeSection,
    getWorksList,
    getObjectList,
    clearForm,
    handleSubmit,
    dateEnd,
    dateStart,
    setDateEnd,
    setDateStart,
    user,
    activeSection,
    activeWork,
    isChooseWork,
    setIsChooseWork,
    title,
    setTitle,
    setSearchWork,
    isPending,
    searchWork,
  } = useProductIntervalForm({
    addedMaterials,
    addedUsers,
    objectId,
    clearMaterials,
    clearWorkers,
    handleClose,
    files,
    directlySection,
    directlyWork,
    addedMims,
    onSelectWorkGroup,
    count,
    setCount,
  });

  const {
    resources: simpleResources,
    deleteResource: deleteSimpleResource,
    updateResource: updateSimpleResource,
    createNewResource: createNewSimpleResource,
    isAdding: isSimpleResourceAdding,
    toggleIsEditing: toggleIsSimpleResourceEditing,
    onSubmit: onSimpleResourcesSubmit,
    isValidForSubmit: validateSimpleResources
  } = useSimpleResources();

  const handleAddToJournal = useCallback(async () => {
    const hasValidWorkers = validateSubmitting();
    const hasValidMaterials = validateSubmittingMaterials();
    const hasValidMims = validateSubmittingMims();
    const hasValidExpenditures = validateSubmittingExpenditures();
    const hasValidSimpleResources = validateSimpleResources();
    if (selectedWorkGroupId && hasValidWorkers && hasValidMaterials && hasValidMims && hasValidExpenditures && hasValidSimpleResources) {
      await createGroupExpendituresFact({
        startDate: moment(dateStart),
        endDate: moment(dateEnd),
        addedMims,
        addedMaterials,
        addedWorkers: addedUsers,
        callback: (data) => {
          onSimpleResourcesSubmit({fact_group: data?.id});
          handleCloseModal();
          onAddToJournalCallback?.();
        },
        planId: planId,
      });
    } else if (hasValidWorkers && hasValidMaterials && hasValidMims && hasValidSimpleResources) {
      await handleSubmit((data: any) => {
        onSimpleResourcesSubmit({fact_work: data?.id});
      });
      onAddToJournalCallback?.();
    }
  }, [
    validateSubmittingMaterials,
    validateSubmitting,
    validateSubmittingExpenditures,
    handleSubmit,
    onAddToJournalCallback,
    selectedWorkGroupId,
  ]);

  const [isSearchOpen, setIsSearchOpen] = useState(true);

  return (
    <>
      <div className={styles.titleBlock}>
        <div className={styles.title}>Новая запись</div>
        <div className={styles.dateTitle}>{moment().format("DD.MM.YYYY")}</div>
        <div className={styles.userTitle}>
          от {user?.last_name} {user?.first_name?.[0]}.
        </div>
      </div>
      <div className={styles.content}>
        {!directlySection && (
          <>
            <div className={styles.fieldTitle}>Выберите раздел</div>
            <div className={styles.fieldValue}>
              {isOpen && (
                <Select
                  options={getObjectList()}
                  onChange={(id: number | string) => handleChangeSection(id)}
                  className={styles.classNameSelect}
                  value={0}
                  isScrolledToDefaultOption
                />
              )}
            </div>
          </>
        )}
        {!directlyWork && (
          <>
            <div className={styles.fieldTitle}>
              Выберите работу
              <button
                className={styles.subTitleButton}
                onClick={() => setIsChooseWork(!isChooseWork)}
                disabled={!activeSection}
              >
                {isChooseWork ? "+ введите название" : "+ выбрать из сметы"}
              </button>
            </div>
            <div className={cn(styles.fieldValue, styles.fixedHeightBox)}>
              {isChooseWork && isOpen ? (
                <Select
                  options={getWorksList()}
                  onChange={(id: number | string, name) => {
                    handleChangeWorks(id);
                    setSearchWork(name as string);
                    setIsSearchOpen(false);
                    setTimeout(() => {
                      setIsSearchOpen(true);
                    }, 1);
                  }}
                  disabled={!activeSection}
                  className={styles.classNameSelect}
                  selectClassName={styles.select}
                  isScrolledToDefaultOption
                  isSearchBar
                  searchBarValue={searchWork}
                  onSearchBarChange={(v) => {
                    setSearchWork(v as any);
                  }}
                  externalIsOpen={isSearchOpen}
                  onClear={() => {
                    setSearchWork("");
                  }}
                  renderOption={(option: any, onChange) => (
                    <div
                      key={option.id}
                      className={styles.option}
                      title={option.name}
                      onClick={() => onChange?.(option.id, option.name)}
                    >
                      {!!option.number && <div className={styles.number}>{option.number}</div>}
                      <div className={styles.text}>{option.name}</div>
                    </div>
                  )}
                />
              ) : (
                <TextareaAutosize
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Введите название"
                  className={styles.textarea}
                />
              )}
            </div>
          </>
        )}
        <div className={styles.fieldRow}>
          <div className={styles.fieldBlock}>
            <div className={styles.fieldTitle}>Введите объем (кол-во)</div>
            <div className={styles.fieldValue}>
              {" "}
              {/* @ts-ignore */}
              <InputNumber value={count} setValue={setCount} decimalPlace="4" />
            </div>
          </div>
          <div className={styles.fieldBlock}>
            <div className={styles.fieldTitle}>Укажите период</div>
            <div className={styles.fieldRow}>
              <div className={styles.fieldCalendar}>
                <CalendarRange
                  defaultDateStart={dateStart}
                  setDefaultDateStart={setDateStart}
                  defaultDateEnd={dateEnd}
                  setDefaultDateEnd={setDateEnd}
                  isTooRight
                />
              </div>
            </div>
          </div>
        </div>

        <AddWorkersInModal
          addedUsers={addedUsers}
          selectedUsers={selectedUsers}
          onAccept={confirmHandler}
          onDecline={cancelHandler}
          onChangeCount={changeCountHandler}
          onSelect={selectHandler}
          selectedIds={selectedUsersIds}
          onDelete={deleteAddedHandler}
          addedIds={addedUsersIds}
        />

        {!!selectedWorkGroupId && (
          <AddExpendituresInModal
            addedExpenditures={addedExpenditures}
            onChangeCount={changeExpendituresCountHandler}
          />
        )}
        <AddMaterialsInModal
          addedMaterials={addedMaterials}
          selectedUsers={selectedMaterials as IMaterialInAddingInFact[]}
          onAccept={confirmMaterialsHandler}
          onDecline={cancelMaterialsHandler}
          onChangeCount={changeCountMaterialsHandler}
          onSelect={selectMaterialsHandler}
          selectedIds={selectedMaterialsIds}
          onDelete={deleteAddedMaterial}
          addedIds={addedMaterialsIds}
          isDisabled={!activeSection || (!activeWork && !title)}
          activeSection={activeSection}
          activeWork={activeWork}
          defaultMaterialsList={!!selectedWorkGroupId ? [...addedMaterials, ...deletedMaterials] : undefined}
          isWorkGroup={!!selectedWorkGroupId}
          simpleResources={simpleResources}
          createNewSimpleResource={createNewSimpleResource}
          deleteSimpleResource={deleteSimpleResource}
          isSimpleResourceAdding={isSimpleResourceAdding}
          toggleIsSimpleResourceEditing={toggleIsSimpleResourceEditing}
          updateSimpleResource={updateSimpleResource}
        />
        <AddMimsInModal
          addedMims={addedMims}
          selectedMims={selectedMims as IMimsInAdding[]}
          onAccept={confirmMimsHandler}
          onDecline={cancelMimsHandler}
          onChangeCount={changeCountMimsHandler}
          onSelect={selectMimsHandler}
          selectedIds={selectedMimsIds}
          onDelete={deleteAddedMims}
          addedIds={addedMimsIds}
          isDisabled={!activeSection || (!activeWork && !title)}
          activeSection={activeSection}
          activeWork={activeWork}
          defaultMimsList={!!selectedWorkGroupId ? [...addedMims, ...deletedMims] : undefined}
          isWorkGroup={!!selectedWorkGroupId}
        />
        {activeWork && !selectedWorkGroupId && (
          <ExpandableFileInput files={files || []} setFiles={setFiles} canExtractFiles={false} />
        )}
      </div>
      <div className={styles.actionBlock}>
        <Button type="button" medium secondary disabled={false} onClick={handleCloseModal}>
          Отменить
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          medium
          primary
          onClick={handleAddToJournal}
          disabled={!(title || activeWork || directlyWork)}
          isLoading={isPending}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
}

export default React.memo(ProductIntervalCardContent);
