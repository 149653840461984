import React, { MouseEventHandler } from "react";
import ButtonBase from "../../../../../../UI/atoms/ButtonBase";
import styles from "./Approval.module.scss";

export interface IApprovalFooterProps {
  isApproveButtonDisplayed: boolean;
  approve: MouseEventHandler;
}

const ApprovalFooter: React.FC<IApprovalFooterProps> = ({ isApproveButtonDisplayed, approve }) => {
  return (
    <footer className={styles.footer}>
      {isApproveButtonDisplayed && (
        <ButtonBase primary onClick={approve}>
          Согласовать табель
        </ButtonBase>
      )}
    </footer>
  );
};

export default React.memo(ApprovalFooter);
