import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  allProjectsAuditsListSelector,
  auditLoadingByObjectSelector,
  auditTreeSelector,
  auditTypeSelector,
  detailedProjectAuditsSelector,
  isAuditsLoadingSelector,
  objectIdSelector,
} from "../../../../../redux/modules/common/constructing/finance/audit/selectors";
import {
  getAuditDetailedProjectV2,
  getAuditProjectsV2,
  loadAllProjectsAudits,
  loadDetailedProjectAudit,
  toggleOpenAuditTree,
} from "../../../../../redux/modules/common/constructing/finance/audit/thunks";
import { formatFinanceTreeViaObjectId } from "../../../../../redux/modules/common/constructing/finance/financeCommonUtils";

export const useAuditData = () => {
  const dispatch = useDispatch();
  const objectId = useSelector(objectIdSelector);
  const isLoading = useSelector(isAuditsLoadingSelector);
  const { projectId } = useParams<Record<string, string>>();

  const allProjectsAudits = useSelector(allProjectsAuditsListSelector);
  const tree = useSelector(auditTreeSelector);
  const auditType = useSelector(auditTypeSelector);

  const displayedTree = useMemo(() => {
    return formatFinanceTreeViaObjectId(tree, +objectId, projectId);
  }, [tree, objectId, projectId]);

  const isFirstLoad = useRef<boolean>(!allProjectsAudits?.length);

  const isLoadingByObjects = useSelector(auditLoadingByObjectSelector) as Record<string, boolean>;

  const isLoadingByObject = isLoadingByObjects[objectId as string];

  useEffect(() => {
    if (isLoading || !isFirstLoad.current) return;
    dispatch(getAuditProjectsV2(!isFirstLoad.current));
    isFirstLoad.current = false;
  }, [auditType, isLoading]);

  useEffect(() => {
    if (!+objectId || isLoading) return;
    dispatch(loadDetailedProjectAudit(objectId));
  }, [objectId, isLoading, auditType]);

  useEffect(() => {
    if (projectId && +projectId !== 0) {
      dispatch(getAuditDetailedProjectV2(projectId));
    }
  }, [projectId]);

  return {
    displayedTree,
    isLoading,
    isFirstLoad: isFirstLoad.current,
    isLoadingByObject,
  };
};
