import React from "react";

import { IEmployee } from "types/personsTypes";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./../Chat.module.scss";

interface Iprops {
  user: IEmployee;
  onSelectUser: (user: IEmployee) => void;
}

const SuggestedUser: React.FC<Iprops> = ({ user, onSelectUser }) => {
  const selectUserHandler = React.useCallback(() => {
    onSelectUser(user);
  }, [user, onSelectUser]);

  return (
    <div onClick={selectUserHandler} className={styles.usersItems}>
      <div className={styles.userName}>{getShortFullName(user)}</div>
      <div className={styles.userRole}>{user.roles.map((role) => role.name).join(", ")}</div>
    </div>
  );
};

export default SuggestedUser;
