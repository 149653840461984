export enum DOC_TYPES_ENUM {
  ORDER = "order",
  ORDER_PCR = "order_pcr",
  REQUISITION = "requisition",
  INVOICE = "invoice",
  PACKINGLIST = "packinglist",
}

export enum CHAT_TYPES_ENUM {
  ORDER = "заказа",
  REQUISITION = "заявки",
}

export enum TEMPLATES_ENUM {
  TASK = "task",
  REQUISITION = "requisition",
  ORDER = "order",
  DOC = "doc",
  PACKINGLIST = "packinglist",
  INVOICE = "invoice",
  BUILDING = "building",
  REMARKS = "remarks",
}

export enum TITLES_ENUM {
  DEADLINE = "deadline",
  BUILDING_NAME = "building_name",
  PURCHASER_NAME = "purchaser_name",
  DOC_TYPE = "doc_type",
  PURCHASER_SAFE = "purchaser_safe",
  PROVIDER_NAME = "provider_name",
  EXECUTOR_NAME = "executor_name",
  LIST_NAME = "list_name",
  SENDER_FULL_NAME = "sender_full_name",
}

export enum IDS_ENUM {
  BUILDING_ID = "building_id",
}

export enum STATES_ENUM {
  LOCALE = "locale",
  OBJECT = "object",
  CONSOLIDATE = "consolidate",
}

export enum STATES_TYPES_ENUM {
  LOCALE = "локального",
  OBJECT = "объектного",
  CONSOLIDATE = "сводного",
}

export enum TEMPLATE_VARIANTS_ENUM {
  NEW_ORDER = "order/new_order",
  ORDER_MATCHING = "order/order_matching",
  ORDER_MATCHING_COMPLETED = "order/order_matching_completed",
  ORDER_MATCHING_PAYMENT = "order/order_matching_payment",
  ORDER_MATCHING_PAYMENT_COMPLETED = "order/order_matching_payment_completed",
  ORDER_PAYMENT_DECISION = "order/order_payment_decision",
  ORDER_PAYMENT_DECISION_MADE = "order/order_payment_decision_made",
  ORDER_WAITING_SHIPMENT = "order/order_waiting_shipment",
  PCR_CHANGE_ORDER_EXECUTOR = "order/pcr_change_order_executor",
  PCR_CHANGED_ORDER = "order/pcr_changed_order",
  PCR_REJECTED_ORDER = "order/pcr_rejected_order",
  PVR_CHANGE_ORDER_EXECUTOR = "order/pvr_change_order_executor",
  PVR_CONFIRMED_ORDER = "order/pvr_confirmed_order",
  PVR_REJECTED_ORDER = "order/pvr_rejected_order",
  
  T13_APPROVE = "other/t13_approve",
  CHAT_MENTION = "other/chat_mention",
  CHAT_UNREAD = "other/chat_unread",
  CHAT_V2_MENTION = "other/chat_v2_mention",
  EXPORT_COMPLETE = "other/export_complete",
  NEW_INVITATION = "other/new_invitation",
  SPECIFICATION_COMPLETE = "other/specification_complete",

  PACKING_NEED_CONFIRMED = "packinglist/packing_need_confirmed",
  PCR_PACKING_CHANGED_DATE = "packinglist/pcr_packing_changed_date",
  PVR_PACKING_CHANGED_DATE = "packinglist/pvr_packing_changed_date",

  PAYMENT_CHANGED_DATE = "payment/payment_changed_date",
  PAYMENT_PAID = "payment/payment_paid",
  PAYMENT_PAID_PCR = "payment/payment_paid_pcr",

  REQUISITION_CHANGE_EXECUTOR = "requisition/requisition_change_executor",
  REQUISITION_MATCHING = "requisition/requisition_matching",
  REQUISITION_PROCESSING = "requisition/requisition_processing",

  APPOINTED_EXECUTOR = "tasks_track/appointed_executor",
  APPOINTED_VIEWER = "tasks_track/appointed_viewer",
  EXECUTOR_CHANGED = "tasks_track/executor_changed",
  REMEMBER_TASK = "tasks_track/remember_task",
  STATUS_CHANGED_TO_COMPLETE = "tasks_track/status_changed_to_complete",
  STATUS_CHANGED_TO_COMPLETE_TO_VIEWER = "tasks_track/status_changed_to_complete_to_viewer",
  STATUS_CHANGED_TO_IN_WORK = "tasks_track/status_changed_to_in_work",
  TASK_ADDED_TO_LIST = "tasks_track/task_added_to_list",
  TASK_CHANGED_DEADLINE = "tasks_track/task_changed_deadline",
  TASK_CHANGED_PRIORITY = "tasks_track/task_changed_priority",
  TASK_DELETED = "tasks_track/task_deleted",
  TASK_DELETED_FOR_CREATOR = "tasks_track/task_deleted_for_creator",
  TASK_DELETED_FOR_EXECUTOR = "tasks_track/task_deleted_for_executor",
  TASK_EDITED_FOR_CREATOR = "tasks_track/task_edited_for_creator",
  TASK_EDITED_FOR_EXECUTOR = "tasks_track/task_edited_for_executor",
  TIME_EXPIRED = "tasks_track/time_expired",

  REMARKS_CREATED = "remarks/created",
  REMARKS_RESOLVED = "remarks/resolved",
  REMARKS_ACCEPTED = "remarks/accepted",
  REMARKS_NEED_WORK = "remarks/need_work",

  ESTIMATE_CHANGE_STATE = "estimate/change_state",
  PACKING_ACCEPTED_NEED_CERTIFICATES = "packinglist/packing_accepted_need_certificates",

  SHIFT_CREATED = "shift/created",

  PLAN_APPROVE = "plan/approve",
  FACT_ACCEPTANCE_APPROVE = "fact/acceptance_approve",
}
