import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "redux/typedUseSelector";

import MenuItem from "components/UI/molecules/NavigationBar/MenuItem";
import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";

import Instructions from "./Instructions/Instructions";
import Learning from "./Learning/Learning";
import { getLearning } from "./Learning/model/thunks";
import instructionsIcon from "./assets/instructionsIcon.svg";

import styles from "./Help.module.scss";

const Help = () => {
  const learning = useTypedSelector((state) => state.learning.data);
  const dispatch = useDispatch();
  const [whichModalOpen, setWhichModalOpen] = useState<null | "instructions" | "learning">(null);

  useEffect(() => {
    dispatch(getLearning());
  }, []);

  const showLearning = !!learning?.results?.length;

  return (
    <div className={styles.container}>
      <PopoverOverlay
        popoverClassName={styles.popoverOverlay}
        isMinimal
        content={
          <div className={styles.popover}>
            {showLearning && (
              <div className={styles.menuitem} onClick={() => setWhichModalOpen("learning")}>
                Обучение
              </div>
            )}
            <div className={styles.menuitem} onClick={() => setWhichModalOpen("instructions")}>
              Инструкции пользователя
            </div>
          </div>
        }
        placement="bottom-end"
        openType="hover"
        modifiers={{ popperOffsets: { options: { x: 40, top: 40 } } }}
      >
        <img src={instructionsIcon} className={styles.icon} />
      </PopoverOverlay>
      <Instructions onClose={() => setWhichModalOpen(null)} isOpen={whichModalOpen === "instructions"} />
      <Learning onClose={() => setWhichModalOpen(null)} isOpen={whichModalOpen === "learning"} />
    </div>
  );
};

export default Help;
