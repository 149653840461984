import cn from "classnames";
import React from "react";

import EstimateItem from "./components/EstimateItem/EstimateItem";
import { ChaptersEnum } from "components/pages/Handler/enums";
import { EstimateStateId } from "components/pages/Handler/types";

import TransferApprove from "../../../TransferApprove/TransferApprove";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./ApproveModal.module.scss";

const ESTIMATE_ITEMS_CONSTRAINT = 5;

interface IApproveModalProps {
  isOpen: boolean;
  items: any;
  onApprove: (isApprove: boolean, targetEstimateStateId?: EstimateStateId, chapter?: ChaptersEnum) => void;
  activeEstimateStateId: EstimateStateId;
  targetEstimateStateId: EstimateStateId;
}

const ApproveModal = ({
  isOpen,
  items,
  onApprove,
  activeEstimateStateId,
  targetEstimateStateId,
}: IApproveModalProps) => {
  if (!items) return null;

  return (
    <div className={cn(styles.block, { [styles.isOpen]: isOpen })} onClick={stopEventPropagation}>
      {items.length < ESTIMATE_ITEMS_CONSTRAINT &&
        items.map((estimateItem: any) => (
          <EstimateItem estimateItem={estimateItem} activeEstimateStateId={activeEstimateStateId} />
        ))}
      <TransferApprove onApprove={onApprove} targetEstimateStateId={targetEstimateStateId} itemsCount={items.length} />
    </div>
  );
};

export default React.memo(ApproveModal);
export type { IApproveModalProps };
