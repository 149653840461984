import classNames from "classnames";
import React, { PropsWithChildren, ReactNode, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { toggleOpenForecastTree } from "../../../../../../../redux/modules/common/constructing/finance/forecast/thunks";
import { IForecastTree } from "../../../../../../../redux/modules/common/constructing/finance/forecast/types";
import { objectIdSelector } from "redux/modules/common/constructing/finance/finance";
import { forecastActions } from "redux/modules/common/constructing/finance/forecast/actions";

import Cross from "components/UI/atoms/ButtonPill/icons/Cross";
import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import List from "../../../_internal/List";
import ListRow from "../../../_internal/List/_internal/ListRow";
import { extraForecastActions } from "../../../widgets/AddExtraExpenseModal/model/extraForecastSlice";
import { extraForecastDataSelector } from "../../../widgets/AddExtraExpenseModal/model/selectors";
import { useForecastData } from "../../useForecastData";

import { IExtraForecastTreeItem } from "../../types";
import {
  VIEW_FINANCE_DELETE_EXTRA_FORECAST,
  VIEW_FINANCE_EDIT_EXTRA_FORECAST,
} from "constants/permissions/constructingPermissions";

import usePermission from "hooks/usePermission";

import { isFinanceFooter } from "../../../_internal/utils";

import EditIcon from "images/icons/EditIcon";

import styles from "../../../Summary/ListSide/ListSide.module.scss";
import localStyles from "./ForecastListSide.module.scss";

export interface IListSideProps extends PropsWithChildren {
  additionalHeader?: ReactNode;
  onClickExtraItem?: (id: number) => void;
  onDeleteExtraItem?: (id: number) => void;
}

const ListSide: React.FC<IListSideProps> = ({ children, additionalHeader, onClickExtraItem, onDeleteExtraItem }) => {
  const dispatch = useDispatch();
  const objectId = useSelector(objectIdSelector);
  const { projectId } = useParams() as any;

  const handleListItemClick = (subTree: IForecastTree) => {
    if ((subTree as any).isExtra) {
      dispatch(extraForecastActions.toggleOpen({ key: objectId, id: (subTree as any).localId }));
    } else {
      if (+objectId === subTree.parentId && subTree.forecast_data) {
        dispatch(forecastActions.toggleLsr(subTree.id));
      }
      dispatch(toggleOpenForecastTree(subTree));
    }
  };

  const { displayedTree }: { displayedTree: IExtraForecastTreeItem[] } = useForecastData();
  const extraData = useSelector(extraForecastDataSelector)[objectId];

  const mergedData = useMemo(() => {
    if (!displayedTree) return [];
    if (!extraData && displayedTree) return displayedTree;
    return [...displayedTree, ...extraData.sections];
  }, [displayedTree, extraData]);

  const canEditExtraExpense = usePermission(VIEW_FINANCE_EDIT_EXTRA_FORECAST);
  const canDeleteExtraExpense = usePermission(VIEW_FINANCE_DELETE_EXTRA_FORECAST);

  const renderExtraControl = (id: number) => {
    return (
      <div className={classNames(localStyles.extraControl, "extraControl")}>
        {canEditExtraExpense && (
          <PopoverOverlay
            placement="top"
            openType="hover"
            content={
              <div className={localStyles.popover} onClick={() => onClickExtraItem?.(id)}>
                Редактировать
              </div>
            }
            popoverBorderColor="primary"
          >
            <EditIcon onClick={() => onClickExtraItem?.(id)} />
          </PopoverOverlay>
        )}
        {canDeleteExtraExpense && (
          <PopoverOverlay
            placement="top"
            openType="hover"
            content={
              <div className={localStyles.popover} onClick={() => onDeleteExtraItem?.(id)}>
                Удалить
              </div>
            }
            popoverBorderColor="primary"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => onDeleteExtraItem?.(id)}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.05025 1.63664C2.65972 1.24612 2.02656 1.24612 1.63603 1.63664C1.24551 2.02717 1.24551 2.66033 1.63603 3.05086L6.58579 8.00061L1.63605 12.9504C1.24552 13.3409 1.24552 13.974 1.63605 14.3646C2.02657 14.7551 2.65973 14.7551 3.05026 14.3646L8 9.41482L12.9497 14.3646C13.3403 14.7551 13.9734 14.7551 14.364 14.3646C14.7545 13.974 14.7545 13.3409 14.364 12.9504L9.41421 8.00061L14.364 3.05086C14.7545 2.66033 14.7545 2.02717 14.364 1.63664C13.9734 1.24612 13.3403 1.24612 12.9498 1.63664L8 6.5864L3.05025 1.63664Z"
                fill="#606060"
              />
            </svg>
          </PopoverOverlay>
        )}
      </div>
    );
  };

  const isObject = +(objectId ?? 0) > 0;

  const total = {
    id: "total",
    name: `Итого по ${isObject ? "объекту" : "проекту"}`,
    isOpen: false,
  };

  return (
    <div className={styles.listSide}>
      <div className={classNames(styles.sticky, localStyles.sticky)}>
        {children}
        <div className={styles.listSide__header}>
          <span className={styles.listSide__title}>Прогнозируемые показатели</span>
          {additionalHeader}
        </div>
      </div>
      <div className={styles.listSide__list}>
        <List
          data={mergedData ?? []}
          handleListItemClick={handleListItemClick}
          renderExtraControl={renderExtraControl}
        />
        {isFinanceFooter(objectId, projectId) && (
          <ListRow data={total} depth={0} handleListItemClick={handleListItemClick} className={styles.total} />
        )}
      </div>
    </div>
  );
};

export default React.memo(ListSide);
