import { FC, useState } from "react";
import cn from "classnames";
import Select from "../../../../components/UI/atoms/Select";
import InputBase, { VALUE_TYPES } from "../../../../components/UI/atoms/InputBase";
import { ResourceTypeEnum } from "../../types/simpleResources";
import MeasureSelect from "../../../../components/UI/atoms/MeasureSelect/MeasureSelect";

import styles from "./SimpleResourceEditBlock.module.scss";

import SelectIcon from "../icons/SelectIcon.svg";
import { ReactComponent as SaveIcon } from "../icons/SaveIcon.svg";
import { ReactComponent as CancelIcon } from "../icons/CancelIcon.svg";
import { message } from "antd";


interface IOnSaveArgs {
  name: string;
  count: string;
  measure: string;
  type: ResourceTypeEnum;
}


interface IProps {
  name?: string | null;
  count?: string | null;
  measure?: string | null;
  type?: ResourceTypeEnum | null;
  isAdding?: boolean;
  onSave: (args: IOnSaveArgs) => void;
  onCancel: () => void;
  id?: string;
  isMim?: boolean;
}


export const SimpleResourceEditBlock: FC<IProps> = ({
  name: initialName,
  count: initialCount,
  measure: initialMeasure,
  type: initialType,
  isAdding: initialIsAdding,
  onSave,
  onCancel,
  id,
  isMim = false,
}) => {
  const [name, setName] = useState(() => initialName ?? "");
  const [count, setCount] = useState(() => initialCount ?? "");
  const [measure, setMeasure] = useState(() => initialMeasure ?? "");
  const [type, setType] = useState<ResourceTypeEnum | "">(() => initialType ?? "");

  const isValid = () => {
    if (!type) {
      message.error("Выберите тип ресурса");
      return false;
    }
    if (!count) {
      message.error("Укажите количество ресурса");
      return false;
    }
    if (!measure) {
      message.error("Выберите единицу измерения ресурса");
      return false;
    }
    if (!name) {
      message.error("Укажите название ресурса");
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (isValid()) {
      onSave?.({name, count, measure, type: type as ResourceTypeEnum});
    }
  };

  return (
    <div className={styles.wrapper} key={id}>

      <div className={styles.longItem}>
        <span className={styles.itemTitle}>Название</span>
        <InputBase
          className={styles.longInput}
          classNameInput={styles.input}
          placeholder={"Укажите"}
          variant={"custom"}
          value={name}
          onChange={(v) => setName(v.target.value)}
        />
      </div>

      <div className={styles.itemsGroup}>
        <div className={styles.topItem} key={"type"}>
          <span className={styles.itemTitle}>Тип ресурса</span>
          <Select
            className={cn(styles.selectWrapper, styles.selectType)}
            selectClassName={styles.select}
            classNameOption={styles.selectOption}
            placeholder={"Выберите"}
            options={isMim ? mimsOptions : materialOptions}
            icon={SelectIcon}
            onChange={(v) => setType(v as ResourceTypeEnum)}
            value={type}
          />
        </div>

        <div className={styles.topItem} key={"count"}>
          <span className={styles.itemTitle}>Кол-во</span>
          <InputBase
            valueType={VALUE_TYPES.NUMBER}
            classNameInput={styles.input}
            className={styles.inputWrapper}
            placeholder={"0"}
            variant={"custom"}
            floatConstraint={8}
            value={count}
            onChange={(v) => setCount(v.target.value)}
          />
        </div>

        <div className={styles.topItem} key={"measure"}>
          <span className={styles.itemTitle}>Ед. изм.</span>
          <MeasureSelect
            className={cn(styles.selectWrapper, styles.measureSelect)}
            selectClassName={styles.select}
            classNameOption={styles.selectOption}
            placeholder={"Выберите"}
            icon={SelectIcon}
            value={measure}
            onChange={(v: string) => setMeasure(v)}
            useAllMeasures
          />
        </div>
      </div>

      <button className={styles.save} onClick={handleSave}>
        <SaveIcon />Сохранить
      </button>

        <button className={styles.cancel} onClick={onCancel}>
          <CancelIcon />
        </button>

      {/* TODO: использовать, если вдруг нужен будет второй варинат дизайна кнопок */}
      {/*<div className={styles.buttonsBlock}>*/}
      {/*  <button className={styles.save} onClick={handleSave}>*/}
      {/*    <SaveIcon />Сохранить*/}
      {/*  </button>*/}
      {/*  <button className={styles.cancel} onClick={onCancel}>*/}
      {/*    <CancelIcon />Отмена*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
};

const materialOptions = [
  {
    id: ResourceTypeEnum.material, name: "Материал"
  }, {
    id: ResourceTypeEnum.equipment, name: "Оборудование"
  }
]

const mimsOptions = [
  {
    id: ResourceTypeEnum.transport, name: "Транспорт"
  }
];