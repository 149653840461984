import Validator from "../../../../domain/Validator";

class RequisitionValidator extends Validator {
  validateRequisitionProducts = (requisitionProducts) => {
    if (requisitionProducts.length === 0) this.errors.add("Необходимо добавить товары");

    const someProductHaveNotProviders = requisitionProducts.some((product) => product.requested_providers.length === 0);

    if (someProductHaveNotProviders) this.errors.add("Необходимо выбрать поставщика");
  };

  validateApproval = (approvalAndView) => {
    if (approvalAndView.approvers.length === 0) return;
    if (!approvalAndView.checkAllApproversApprove()) this.errors.add("Необходимо получить все согласования");
  };

  validateExecutor = (executor) => {
    if (!executor) this.errors.add("Необходим исполнитель");
  }

  validate = (requisition, requisitionProducts, approvalAndView) => {
    this.validateExecutor(requisition.executor);
    this.validateApproval(approvalAndView);
    this.validateRequisitionProducts(requisitionProducts);

    if (this.errors.size !== 0) {
      this.onError(this.errors);
      return false;
    }

    return true;
  };
}

export default RequisitionValidator;