import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  VIEW_ADD_CONTACT,
  VIEW_ANALYTICS,
  VIEW_BILLS_LIST,
  VIEW_BUILDING,
  VIEW_BUILDINGS_SECTION,
  VIEW_CONSTRUCTING_CHART,
  VIEW_CONTACTS_LIST,
  VIEW_CONTRACTORS_LIST,
  VIEW_CONTRACTS_LIST,
  VIEW_COUNTERPARTIES_SECTION as VIEW_COUNTERPARTIES_SECTION_CONSTRUCTING,
  VIEW_CUSTOMERS_LIST,
  VIEW_DOCUMENTS_SECTION,
  VIEW_EXPORT_LIST,
  VIEW_FINANCE_AUDIT,
  VIEW_FINANCE_FORECAST,
  VIEW_FINANCE_SECTION,
  VIEW_FINANCE_SUMMARY,
  VIEW_INVITES_LIST,
  VIEW_PACKING_LISTS,
  VIEW_SUPPLIERS_LIST,
} from "constants/permissions/constructingPermissions";
import { VIEW_FILE_STORAGE, VIEW_SETTINGS_SECTION } from "constants/permissions/generalPermissions";
import {
  VIEW_MANUFACTURING_BETON,
  VIEW_MANUFACTURING_BETON_INCOMES,
  VIEW_MANUFACTURING_BETON_OUTCOMES,
  VIEW_MANUFACTURING_BILL_SECTION,
  VIEW_MANUFACTURING_CHART,
  VIEW_MANUFACTURING_JOURNAL,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
  VIEW_MANUFACTURING_JOURNAL_ACTS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_REMARKS,
  VIEW_MANUFACTURING_KS_LISTS,
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE,
  VIEW_MANUFACTURING_MATERIALS_SECTION,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION,
  VIEW_MANUFACTURING_ORDER_SECTION,
  VIEW_MANUFACTURING_PACKING_LIST_SECTION,
  VIEW_MANUFACTURING_PLAN_FACT_SECTION,
  VIEW_MANUFACTURING_PROJECT,
  VIEW_MANUFACTURING_PROJECT_PLAN,
  VIEW_MANUFACTURING_PROJECT_PROGRESS,
  VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION,
  VIEW_MANUFACTURING_REQUISITION_PAGE,
  VIEW_MANUFACTURING_REQUISITION_SECTION,
  VIEW_MANUFACTURING_STOCKS_INCOMES,
  VIEW_MANUFACTURING_STOCKS_MOVINGS,
  VIEW_MANUFACTURING_STOCKS_OUTCOMES,
  VIEW_MANUFACTURING_STOCKS_SECTION,
  VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION,
  VIEW_MANUFACTURING_WORKERS,
  VIEW_MANUFACTURING_WORKERS_TIMESHEET,
  VIEW_MANUFACTURING_WORKERS_TURNOUT,
  VIEW_PLAN_FACT_VOLUME,
} from "constants/permissions/manufacturingPermissions";
import {
  ORDERS_SECTION_VIEW,
  VIEW_COUNTERPARTIES_SECTION,
  VIEW_CREATE_REQUISITION,
  VIEW_PURCHASES_CHART,
  VIEW_PURCHASES_SECTION,
  VIEW_REQUISITION,
  VIEW_REQUISITIONS_LIST,
} from "constants/permissions/purchasesPermissions";

import { useLocationArray } from "utils/hooks/useLocationArray";
import { urlModulesType, useUrlModule } from "utils/hooks/useUrlModule";

export const useInstructionByUrl = (settedValue: string) => {
  const { pathname } = useLocation();
  const rootPath = useUrlModule();
  const loactionArray = useLocationArray();

  const presettedPermission = useMemo(() => {
    if (settedValue) return "";
    return switchDefaultPermission(rootPath, pathname, loactionArray);
  }, [pathname, rootPath, loactionArray, settedValue]);

  return presettedPermission;
};

const switchDefaultPermission = (rootPath: urlModulesType, pathname: string, locationArr: string[]) => {
  if (pathname.includes("settings")) {
    return VIEW_SETTINGS_SECTION;
  }

  if (pathname.includes("files")) {
    return VIEW_FILE_STORAGE;
  }

  if (rootPath === "constructing") {
    if (pathname.includes("finance")) {
      return VIEW_FINANCE_SECTION;
    }

    if (pathname.includes("projects") && locationArr.some((el) => !isNaN(+el))) {
      return VIEW_BUILDING;
    }

    if (pathname.includes("projects") || pathname.includes("handler")) {
      return VIEW_BUILDINGS_SECTION;
    }

    if (pathname.includes("manufacturing")) {
      return VIEW_CONSTRUCTING_CHART;
    }

    if (pathname.includes("counterparties")) {
      return VIEW_COUNTERPARTIES_SECTION_CONSTRUCTING;
    }

    if (pathname.includes("analytics")) {
      return VIEW_ANALYTICS;
    }

    if (pathname.includes("documents")) {
      return VIEW_DOCUMENTS_SECTION;
    }
  } else if (rootPath === "objects") {
    if (pathname.includes("manufacturing")) {
      return VIEW_MANUFACTURING_CHART;
    }

    if (pathname.includes("estimate")) {
      return VIEW_MANUFACTURING_PROJECT;
    }

    if (pathname.includes("journal")) {
      return VIEW_MANUFACTURING_JOURNAL;
    }

    if (pathname.includes("workers")) {
      return VIEW_MANUFACTURING_WORKERS;
    }

    if (pathname.includes("requisitions")) {
      return VIEW_MANUFACTURING_REQUISITION_SECTION;
    }

    if (pathname.includes("beton")) {
      return VIEW_MANUFACTURING_BETON;
    }

    if (pathname.includes("stocks")) {
      return VIEW_MANUFACTURING_STOCKS_SECTION;
    }

    if (pathname.includes("materials")) {
      return VIEW_MANUFACTURING_MATERIALS_SECTION;
    }

    if (pathname.includes("plan-fact")) {
      return VIEW_MANUFACTURING_PLAN_FACT_SECTION;
    }

    if (pathname.includes("packing-list")) {
      return VIEW_MANUFACTURING_PACKING_LIST_SECTION;
    }

    if (pathname.includes("kses")) {
      return VIEW_MANUFACTURING_KS_LISTS;
    }

    if (pathname.includes("documents")) {
      return VIEW_MANUFACTURING_BILL_SECTION;
    }

    if (pathname.includes("order")) {
      return VIEW_MANUFACTURING_ORDER_SECTION;
    }
  } else if (rootPath === "purchases") {
    if (pathname.includes("manufacturing")) {
      return VIEW_PURCHASES_CHART;
    }

    if (pathname.includes("requisitions")) {
      return VIEW_REQUISITIONS_LIST;
    }

    if (pathname.includes("order")) {
      return ORDERS_SECTION_VIEW;
    }

    if (pathname.includes("documents")) {
      return VIEW_DOCUMENTS_SECTION;
    }
    if (pathname.includes("counterparties")) {
      return VIEW_COUNTERPARTIES_SECTION;
    }

    return VIEW_PURCHASES_SECTION;
  } else if (rootPath === "tasks") {
  }

  return "";
};
