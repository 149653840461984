import { message } from "antd";
import axios from "axios";

import downloadFileByUrl from "./downloadFileByUrl";

const downloadFile = async (url: string, name = "doc.xlsx", method = "GET", requestBody?: Record<string, any>) => {
  message.info("Загрузка файла...");

  if (new URL(url).origin === new URL(axios.defaults.baseURL!).origin) {
    let fileBlobUrl;
    if (method === "GET") {
      fileBlobUrl = await axios
        .get(url, { responseType: "blob" })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
    }
    if (method === "POST") {
      fileBlobUrl = await axios
        .post(url, requestBody ?? {}, { responseType: "blob" })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
    }
    const link = document.createElement("a");

    link.href = fileBlobUrl as string;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    return true;
  }

  return downloadFileByUrl(url, name);
};

export default downloadFile;
