import { IActions } from "../../../../../store";

import { IAuditInitialState, IAuditTree, IDetailedProjectAudit, IProjectViewAudit, IProjectViewAuditV2 } from "./types";

export const auditActions = {
  clearData: () =>
    ({
      type: "constructing/finance/audit_CLEAR_DATA",
      payload: {},
    } as const),
  setLoading: (isLoading: boolean) =>
    ({
      type: "constructing/finance/audit_SET_LOADING",
      payload: isLoading,
    } as const),
  setAllProjectsAuditList: (auditList: IProjectViewAudit[]) =>
    ({
      type: "constructing/finance/audit_SET_ALL_PROJECTS_AUDITS",
      payload: auditList,
    } as const),
  setDetailedProjectAudit: (objectId: number, audit: IDetailedProjectAudit) =>
    ({
      type: "constructing/finance/audit_SET_DETAILED_PROJECT_AUDIT",
      payload: { objectId, audit },
    } as const),
  setAuditTree: (tree: IAuditTree[]) =>
    ({
      type: "constructing/finance/audit_SET_AUDIT_TREE",
      payload: tree,
    } as const),
  setAuditDates: (dates: IAuditInitialState["dates"]) =>
    ({
      type: "constructing/finance/audit_SET_DATES",
      payload: dates,
    } as const),
  setAuditType: (type: IAuditInitialState["auditType"]) =>
    ({
      type: "constructing/finance/audit_SET_AUTIT_TYPE",
      payload: type,
    } as const),
  setIsLoadingByObject: (status: boolean, objectId: number) =>
    ({
      type: "constructing/finance/audit_SET_IS_LOADING_BY_OBJECT",
      payload: {
        status,
        objectId,
      },
    } as const),
  //////////////////////////
  setDetailedProjectAuditV2: (projectId: number, data: IProjectViewAuditV2) =>
    ({
      type: "constructing/finance/audit_SET_DETAILED_BY_PROJECT_V2",
      payload: {
        projectId,
        data,
      },
    } as const),
};

export type IAuditAction = IActions<typeof auditActions>;
