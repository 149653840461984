import React from "react";

import { IDiagramIntervalProps } from "../../DiagramInterval";
import { useIntervalEditing } from "./useIntervalEditing";

import styles from "./withEditing.module.scss";

export interface IWithEditingProps {
  intervalLeftRem: number;
  intervalWidthRem: number;
}

export const withEditing =
  (Component: React.ComponentType<IDiagramIntervalProps>) => (props: IDiagramIntervalProps) => {
    const {
      intervalWrapperRef,
      unitMultiplier,
      isGroup,
      isSectionPlan,
      expenditureId,
      intervalId,
      projectId,
      startDate,
      endDate,
      day,
      daysLength,
    } = props;

    const intervalInitialsRem = { left: (day - 1) * unitMultiplier, width: Math.max(daysLength, 1) * unitMultiplier };

    const {
      isIntervalEditingEnabled,
      intervalLeftRem,
      intervalWidthRem,
      handleDragStartHead,
      handleDragStartMid,
      handleDragStartTail,
      handleDragEnd,
      locked,
    } = useIntervalEditing({
      intervalInitialsRem,
      intervalWrapperRef,
      unitMultiplier,
      isGroup,
      isSectionPlan,
      expenditureId: Number(expenditureId),
      intervalId: Number(intervalId),
      projectId: Number(projectId),
      initialDateStart: String(startDate),
      initialDateEnd: String(endDate),
    });

    const innerBeforeContent = (
      <>
        {props.innerBeforeContent}
        {isIntervalEditingEnabled && !locked && (
          <div className={styles.editAnchorContainer}>
            <div className={styles.editAnchorTail} onDragEnd={handleDragEnd} onMouseDown={handleDragStartTail} />
            <div className={styles.editAnchorMid} onDragEnd={handleDragEnd} onMouseDown={handleDragStartMid} />
            <div className={styles.editAnchorHead} onDragEnd={handleDragEnd} onMouseDown={handleDragStartHead} />
          </div>
        )}
      </>
    );

    return (
      <Component
        {...props}
        children={props.children}
        innerBeforeContent={innerBeforeContent}
        intervalLeftRem={intervalLeftRem}
        intervalWidthRem={intervalWidthRem}
      />
    );
  };
