import React from "react"
import { useDispatch } from "react-redux"
import { tasksIndicatorsByBuildingSelector } from "redux/modules/purchaser/Tasks/selectors"
import { getTasksIndicatorsByBuilding } from "redux/modules/purchaser/Tasks/thunks"
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types"
import { useTypedSelector } from "redux/typedUseSelector"

export const useTaskIndicatorsByBuilding = (buildingId: number | undefined, filters: tasksListFilterType) => {
    const dispatch = useDispatch()
    const indicators = useTypedSelector(tasksIndicatorsByBuildingSelector)
    

    React.useEffect(() => {
        buildingId && dispatch(getTasksIndicatorsByBuilding(buildingId, filters))
    }, [buildingId, filters])

    return buildingId ? indicators[buildingId] : null
}