import { useCallback, useEffect, useMemo, useRef } from "react";
import { runningLineMover} from '../utils'

export interface IUseRunningLineProps {
  containerElement: HTMLElement | null;
  calendarElement: HTMLElement | null;
}

export const useRunningLine = ({ containerElement, calendarElement }: IUseRunningLineProps) => {
  const runningLineRef = useRef<HTMLElement>(null);

  const lineMover = useMemo(
    () => runningLineMover(runningLineRef.current, containerElement, calendarElement),
    [runningLineRef.current, containerElement, calendarElement]
  );

  const handleRunningLine = useCallback(
    (e: MouseEvent) => {
      lineMover(e.clientY);
    },
    [lineMover]
  );

  useEffect(() => {
    if (!calendarElement) return;
    calendarElement.addEventListener("mousemove", handleRunningLine);
    return () => calendarElement?.removeEventListener("mousemove", handleRunningLine);
  }, [calendarElement]);

  return {
    runningLineRef,
    lineMover,
  };
};
