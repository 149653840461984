import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import EditProject from "../../../pages/Settings/EditProject/EditProject";
import Settings from "../../../pages/Settings/Settings";
import EditOrganization from "../../../pages/Settings/components/EditOrganization/EditOrganization";
import { UserPage } from "../../../pages/Settings/components/Users/UserPage/UserPage";
import Project from "pages/Settings/Project/Project";

const SettingsRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/users/:id`} component={UserPage} />
      <Route exact path={`${match.path}/organization/:id`} component={EditOrganization} />
      <Route path={`${match.path}/project/:projectId`} component={Project} />
      <Route path={`${match.path}/object/:projectId/:id`} component={EditProject} />
      <Route path={match.path} component={Settings} />
    </Switch>
  );
};

export default React.memo(SettingsRoutes);
