import cn from "classnames";
import React from "react";

import { getCurrencyShortName } from "utils/helpers/getCurrencyShortName";

import styles from "./MeasureBtn.module.scss";
import type { MeasureType } from "../../../../features/financeMeasure";
import { useFinanceMeasure } from "../../../../features/financeMeasure";


export const measureContentSwitcher = (measure: MeasureType) => {
  const activeCurrency = getCurrencyShortName();

  if (measure === 1_000_000) {
    return "млн.";
  } else if (measure === 1_000) {
    return "тыс.";
  } else {
    return `${activeCurrency}.`;
  }
};

export interface IMeasureBtnProps {
  className?: string;
}

const MeasureBtn: React.FC<IMeasureBtnProps> = ({
  className
}) => {
  const {
    measureChangeHandler,
    measureInTitle
  } = useFinanceMeasure();

  return (
    <button className={cn(styles.measureBtn, className)} onClick={measureChangeHandler}>
      Ед. изм. | {measureInTitle}
    </button>
  );
};

export default React.memo(MeasureBtn);
