import cn from "classnames";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "react-textarea-autosize";

import { aookActions } from "redux/modules/common/building/journal/aook/actions";
import { aookCretingPendingSelector, aookWorksSelector } from "redux/modules/common/building/journal/aook/selectors";
import { aosrWorksSelector } from "redux/modules/common/building/journal/aosr/selectors";
import { getWorksBySectionInAosr } from "redux/modules/common/building/journal/aosr/thunks";
import { AosrTypes } from "redux/modules/common/building/journal/aosr/types";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import AddButton from "components/UI/atoms/AddButton/AddButton";
import ButtonBase from "components/UI/atoms/ButtonBase";
import InputBase from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select";
import Calendar from "components/UI/molecules/Calendar/Calendar";
import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import AosrDocumentAdd from "../Aosr/AosrDocumentAdd/AosrDocumentAdd";
import AosrMaterials from "../Aosr/AosrMaterials/AosrMaterials";
import AookPermits from "./AookPermits/AookPermits";
import AookSelectAosrs from "./AookSelectAosrs/AookSelectAosrs";
import { useAookCreate } from "./useAookCreate";

import { aookDocsTypesOptions, initialAosrForm, MATERIAL_SOURCES } from "../Aosr/constants";
import { actTypesEnum, actTypesOptions, getActTypesOptions } from "./constants";
import { IExpenditure } from "types/interfaces/Expenditure";

import { composeFieldValidators, maxLength, minLength, required } from "utils/formHelpers/validations";

import styles from "./Aook.module.scss";
import { getWorksBySectionInAook } from "redux/modules/common/building/journal/aook/thunks";

export type actTypes = "aosr" | "aook";

enum NextType {
  EXPENDITURE = 'expenditure',
  GROUP = 'group'
}

interface IProps {
  onSelectActType: (type: string) => void;
  type: AosrTypes;
  ticket: IExpendituresInJournalFulfillment["items"][0];
  groupId?: number;
  sectionId: number;
  isOpen: boolean;
  onClose: () => void;
  isAosrExists?: boolean;
}

const AookCreateForm: React.FC<IProps> = ({
  onSelectActType,
  type,
  ticket,
  groupId,
  sectionId,
  isOpen,
  onClose,
  isAosrExists,
}) => {
  const objectId = useObjectId();
  const isCreatingPending = useSelector(aookCretingPendingSelector)[objectId];

  const {
    changeAookDateField,
    aook,
    changeFormWatcher,
    addMaterial,
    allMaterials,
    deleteMaterial,
    aosrDocs,
    addAosrDoc,
    removeAosrDoc,
    submitFormHandler,
    currentAook,
  } = useAookCreate({
    type,
    ticket,
    groupId,
    onClose,
  });

  const [nextType, setNextType] = useState<NextType>(NextType.EXPENDITURE);
  const [nextId, setNextId] = useState<number | undefined>(undefined);

  const works = useSelector(aookWorksSelector)[sectionId];
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isOpen) return;
    dispatch(getWorksBySectionInAook(objectId, sectionId));
    return () => {
      dispatch(aookActions.setCurrentAook(null));
    };
  }, [isOpen, sectionId, objectId]);

  return (
    <Form
      initialValues={{ ...initialAosrForm }}
      onSubmit={(values) => {
        const preparedValues = {
          ...values,
        }

        if (nextType === NextType.GROUP) {
          preparedValues.next_group_id = nextId;
          preparedValues.next_expenditure_id = null;
          preparedValues.next_expenditure = null;
        }

        if (nextType === NextType.EXPENDITURE) {
          preparedValues.next_expenditure_id = nextId;
          preparedValues.next_group_id = null;
          preparedValues.next_group = null;
        }

        delete preparedValues.next_work_id;

        submitFormHandler(preparedValues);
      }}
      mutators={{
        setNext: (args, state) => {
          const id = args[0];
          const name = args[1];

          const field = state.fields["next"];
          field.change(name);

          const selected = works.find(w => w.id === args[0]);
          if (selected) {
            const newNextType = selected.expenditure_type === 'work' ? NextType.EXPENDITURE : NextType.GROUP;
            setNextType(newNextType);
            setNextId(id);
          }
        },
      }}
      render={({ handleSubmit, form }) => {
        return (
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            onChange={(_) => changeFormWatcher(form.getState() as any)}
          >
            <div className={styles.content}>
              <div className={cn(styles.row, styles.row1)}>
                <div>
                  <Select
                    options={getActTypesOptions(!!isAosrExists)}
                    value={actTypesEnum.aook}
                    label="Тип акта"
                    //@ts-ignore
                    onChange={onSelectActType}
                  />
                </div>
                <Field
                  selfControlled
                  name="number" /* @ts-ignore */
                  component={InputBase}
                  label="№ акта"
                  validate={composeFieldValidators(
                    required(),
                    maxLength(255, "Не более 255 символов"),
                    minLength(1, "Введите № акта")
                  )}
                />
              </div>
              <div className={cn(styles.row, styles.row2)}>
                <Field
                  selfControlled
                  name="act_date"
                  render={({ input, meta }) => (
                    <Calendar
                      classNameOptions=""
                      label="Дата акта"
                      value={input.value}
                      setValue={input.onChange}
                      //@ts-ignore
                      isTooLeft
                    />
                  )}
                />
                <CalendarRange
                  label="Срок выполнения работ"
                  defaultDateEnd={aook.end_date}
                  defaultDateStart={aook.start_date} /* @ts-ignore */
                  setDefaultDateEnd={changeAookDateField("end_date")} /* @ts-ignore */
                  setDefaultDateStart={changeAookDateField("start_date")}
                />
                {/* @ts-ignore */}
                <Field name="copy_count" component={InputBase} label="Кол-во экз." selfControlled />
              </div>
              <div className={cn(styles.row)}>
                <div>
                  <label>Наименование конструкции</label>
                  <Field
                    selfControlled
                    name="structure_name"
                    render={({ input, meta }) => (
                      <>
                        <TextArea
                          onChange={input.onChange}
                          minRows={3}
                          placeholder="Введите наименование конструкции"
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                        />
                        {meta.touched && meta.error && <span className={styles.error}>{meta.error}</span>}
                      </>
                    )}
                    validate={composeFieldValidators(required())}
                  />
                </div>
              </div>
              <div className={cn(styles.row)}>
                <div>
                  <label>Проектная документация</label>
                  <Field
                    selfControlled
                    name="project_doc"
                    render={({ input, meta }) => (
                      <TextArea
                        onChange={input.onChange}
                        minRows={3}
                        placeholder="Введите номер, реквизиты чертежа, наименование проектной и/или рабочей документации"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={styles.docsContainer}>
              {!!allMaterials?.length && (
                <AosrMaterials materials={allMaterials} onAddMaterial={addMaterial} onRemoveMaterial={deleteMaterial} source={MATERIAL_SOURCES.AOOK} />
              )}
              <h3>Документы, подтверждающие соответствие работ требованиям</h3>
              {aosrDocs.map((el, i) => (
                <AosrDocumentAdd
                  index={i}
                  key={i}
                  remove={removeAosrDoc}
                  isHidden={!!el.isHidden}
                  typeOptions={aookDocsTypesOptions}
                />
              ))}
              <AddButton onClick={addAosrDoc} text="Добавить" textPosition="left" className={styles.addButton} />
              <AookSelectAosrs
                allHiddenworks={currentAook?.all_hiddenworks!}
                selectedHiddenworksIds={form.getState().values?.hiddenworks as number[]}
              />
            </div>
            <div className={styles.content2}>
              <div className={cn(styles.row)}>
                <div>
                  <label>Конструкции выполнены в соответствии с</label>
                  <Field
                    selfControlled
                    name="accordance"
                    render={({ input, meta }) => (
                      <TextArea
                        onChange={input.onChange}
                        minRows={3}
                        placeholder="Введите наименование и структурные единицы технических регламентов, разделы проектной и/или рабочей документации"
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.hr} />
              <AookPermits
                isDisabled={false}
              />
              <h4>Разрешается производство последующих работ:</h4>
              <Field
                name="next"
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <label>Наименование работы</label>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Укажите наименование"
                    />
                  </div>
                )}
              />
              <Field
                name={`next_work_id`}
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <label>Наименование работы по смете</label>
                    <Select
                      containerClassName={styles.select}
                      value={nextId}
                      onChange={(id, name) => {
                        form.mutators.setNext(id, name);
                        input.onChange(id);
                      }}
                      meta={meta}
                      options={works}
                    />
                  </div>
                )}
              />
              <Field
                name="additional"
                render={({ input, meta }) => (
                  <div className={styles.row}>
                    <label>Дополнительные сведения</label>
                    <TextArea
                      value={input.value}
                      key={String(meta.submitting)}
                      onChange={input.onChange}
                      minRows={3}
                      placeholder="Введите дополнительную информацию"
                    />
                  </div>
                )}
              />
            </div>
            <div className={styles.heightHolder} />
            <BottomControls isExists isDoubleBtns>
              <ButtonBase type="submit" primary isLoading={isCreatingPending}>
                Сформировать
              </ButtonBase>
            </BottomControls>
          </form>
        );
      }}
    />
  );
};

export default AookCreateForm;
