import React, { useMemo } from "react";

import useEstimatedPriceDoubleInput from "../../../../../pages/Handler/hooks/useEstimatedPriceDoubleInput";

import DoubleInput, { Measure } from "../../../../atoms/DoubleInput/DoubleInput";

import { beautifyNumberValue } from "../../../../../../utils/formatters/beautifyNumberValue";
import {
  parseTransformedNumber,
  transformDigitToFinancial,
} from "../../../../../../utils/formatters/transformDigitToFinancial";
import { percentsFormatter } from "../../../../../../utils/percentsFormatter";

import blackCheckIcon from "../../../../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../../../../images/icons/blueCheckIcon";

import styles from "../../Expenditure.module.scss";

const EstimatedPriceColumns = ({ estimatedCost, budget, changeEstimatedCost, count, amountSource, quantity }) => {
  const { editableCost, costByUnit, onChangeCost, onChangeCostByUnit } = useEstimatedPriceDoubleInput({
    cost: estimatedCost,
    changeCost: changeEstimatedCost,
    count,
  });

  const difference = +budget - +beautifyNumberValue(editableCost);
  const differencePercent = (difference * 100) / +budget;

  const displayedPercent = +editableCost === 0 ? "0%" : percentsFormatter(differencePercent);
  const displayedDifference =
    +editableCost === 0 ? 0 : transformDigitToFinancial(difference, { withFloat: true, dropZeros: true });

  return (
    <>
      <div className={styles.budgetCol}>
        {transformDigitToFinancial(budget)}
        <br/>
        ({transformDigitToFinancial(budget / quantity)} за ед.)
      </div>
      <div className={styles.estimatePriceCol}>
        <DoubleInput
          firstValue={costByUnit || 0}
          secondValue={editableCost || 0}
          firstInputSize={1}
          secondInputSize={1}
          onChangeFirstValue={onChangeCostByUnit}
          onChangeSecondValue={onChangeCost}
          firstValueMeasure={Measure.currency}
          secondValueMeasure={Measure.currency}
          firstValueLabel="за единицу"
          secondValueLabel="итого"
          inputFontSize={parseTransformedNumber(editableCost) < 10_000_000_000 ? 1 : 0.875}
          className={styles.estimatePriceDoubleInput}
        />
        {+differencePercent !== 0 && +editableCost !== 0 ? (
          {
            ...blueCheckIcon,
            props: { ...blueCheckIcon.props, width: 18, height: 18 },
          }
        ) : (
          <span className={styles.withoutDiscountIcon} title="Разница отсутствует">
            {{
              ...blackCheckIcon,
              props: { ...blackCheckIcon.props, width: 18, height: 18 },
            }}
          </span>
        )}
      </div>
      <div className={styles.diffCol}>
        <div className={styles.diffPercent} title={displayedPercent}>
          {displayedPercent === "-Infinity%" ? "-" : displayedPercent}
        </div>
        <div className={styles.diffPrice} title={displayedDifference}>
          {displayedDifference}
        </div>
      </div>
    </>
  );
};

export default React.memo(EstimatedPriceColumns);
