import Actions from "components/UI/atoms/Actions/Actions";
import ButtonBase from "components/UI/atoms/ButtonBase";
import React from "react";
import styles from "./EditTasksLists.module.scss";
import { useTasksModal } from "../../hooks/useTasksModal";

type propsType = {
  listId: number;
  name: string;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tasksCount?: number;
  onSave: () => void;
  onClose: () => void;
  isNew?: boolean;
};

function EditTasksLists({ listId, tasksCount, onNameChange, name, onSave, onClose, isNew }: propsType) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { openAddModal } = useTasksModal();

  const handleAddTask = React.useCallback(() => {
    openAddModal({ placementType: "inLists", list_id: listId });
  }, [listId]);

  React.useEffect(() => {
    if (!inputRef.current) return;
    const enterHandler = (e: KeyboardEvent) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        inputRef.current?.value && onSave();
      }
    };
    inputRef.current?.addEventListener("keydown", enterHandler);
    return () => {
      inputRef.current?.removeEventListener("keydown", enterHandler);
    };
  }, [onSave]);

  return (
    <div className={styles.row}>
      <input className={styles.input} defaultValue={name} onChange={onNameChange} ref={inputRef} />
      <div className={styles.cell}>Задач: {tasksCount || "-"}</div>
      <div className={styles.cell}>
        {!isNew && (
          <ButtonBase primary onClick={handleAddTask}>
            Новая задача
          </ButtonBase>
        )}
      </div>
      <Actions className={styles.actions} canSave onSave={onSave} canRemove onRemoveDirectly={onClose} />
    </div>
  );
}

export default React.memo(EditTasksLists);
