import React from "react";

import SliderModal from "../../../../../../../UI/atoms/SliderModal";

import ContractorInfo from "../../../ContractorInfo/ContractorInfo";

import styles from "./ContractorInfoModal.module.scss";

const ContractorInfoModal = ({ isOpen, onClose, contractorId, contractorName }) => {
  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose}>
      <div className={styles.container}>
        <header className={styles.header}>{contractorName}</header>
        <ContractorInfo contractorId={contractorId}/>
      </div>
    </SliderModal>
  );
};

export default React.memo(ContractorInfoModal);