import cn from "classnames";
import React from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Modal from "components/UI/atoms/Modal";

import DangerIcon from "../../../../images/icons/DangerIcon";

import styles from "./ConfirmModal.module.scss";

export type modalVariantType = "secondary" | "primary";

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  action: () => void;
  children?: React.ReactNode;
  acceptButtonText?: string;
  cancelButtonText?: string;
  title?: string;
  variant?: modalVariantType;
}

const ConfirmModal: React.FC<IProps> = ({
  isOpen,
  children,
  onClose,
  action,
  acceptButtonText = "Подтвердить ",
  cancelButtonText = "Отменить",
  title = "Подтвердите действие",
  variant = "primary",
}) => {
  return (
    <Modal className={cn(styles.confirmationModal, styles[variant])} isOpen={isOpen} title={title} onClose={onClose}>
      <div className={styles.content}>
        {variant === "secondary" && <DangerIcon />}
        <p className={styles.text}>{children}</p>
      </div>
      <div className={styles.actionBlock}>
        {variant === "primary" && (
          <>
            <ButtonBase medium secondary onClick={onClose}>
              {cancelButtonText}
            </ButtonBase>
            <ButtonBase medium primary onClick={action}>
              {acceptButtonText}
            </ButtonBase>
          </>
        )}
        {variant === "secondary" && (
          <>
            <ButtonBase error onClick={action}>
              {acceptButtonText}
            </ButtonBase>
            <ButtonBase tertiary onClick={onClose}>
              {cancelButtonText}
            </ButtonBase>
          </>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(ConfirmModal);
