import { formatFinanceTreeViaObjectId } from "../financeCommonUtils";

import { EXPENDITURE_TYPES, EXPENDITURE_TYPES_NAMES } from "../../../../../../constants/constant";
import {
  IAuditInitialState,
  IAuditTree,
  IFinancePivotSection,
  IFinancePivotSubsection,
  IProjectViewAudit,
  IProjectViewAuditV2,
  ITreeFinancePivotExpenditure,
  ITreeFinancePivotGroup,
  ITreeFinancePivotItem,
} from "./types";

export const produceMapAuditParentProjectFn =
  (
    detailedProjectAudits: IAuditInitialState["detailedProjectAudits"],
    objectId: number,
    projectId: string = "-1",
    detailedParentProjectAudits?: IAuditInitialState["detailedProjectsV2"]
  ) =>
  (project: IProjectViewAuditV2, _: number, arr: any) => {
    const isProjectAutoExpanded = +projectId === project.id;
    const noObject = +objectId === -1 || isNaN(+objectId) || +objectId === 0;
    if (noObject && +projectId && detailedParentProjectAudits?.[projectId] && +projectId === project.id) {
      console.log(7777, {
        id: project.id,
        isParentProject: true,
        name: project.name,
        isOpen: isProjectAutoExpanded,
        audit_data: project.audit_data,
        children: detailedParentProjectAudits?.[projectId].buildings.map(
          produceMapAuditProjectFn(detailedProjectAudits, objectId)
        ),
      });
      return {
        id: project.id,
        isParentProject: true,
        name: project.name,
        isOpen: isProjectAutoExpanded,
        audit_data: project.audit_data,
        children: detailedParentProjectAudits?.[projectId].buildings.map(
          produceMapAuditProjectFn(detailedProjectAudits, objectId)
        ),
      };
    }

    return {
      id: project.id,
      isParentProject: true,
      name: project.name,
      isOpen: isProjectAutoExpanded,
      audit_data: project.audit_data,
      children: project.buildings.map(produceMapAuditProjectFn(detailedProjectAudits, objectId)),
    };
  };

export const produceMapAuditProjectFn =
  (detailedProjectAudits: IAuditInitialState["detailedProjectAudits"], objectId: number) =>
  (project: IProjectViewAudit, i: number, arr: Array<IProjectViewAudit>) => {
    const isProjectAutoExpanded = objectId === project.id;
    const allSubSectionsNumber = detailedProjectAudits[project.id]?.sections
      ?.flatMap((s) => s.subsections.length)
      .reduce((acc, v) => acc + v, 0);
    const allExpendituresNumber = detailedProjectAudits[project.id]?.sections
      ?.flatMap((s) => s.subsections)
      .reduce((acc, ss) => acc + Number(!!ss.works) + Number(!!ss.materials) + Number(!!ss.services), 0);
    return {
      id: project.id,
      name: project.name,
      audit_data: project.audit_data,
      isOpen: isProjectAutoExpanded,
      isRoot: true,
      parentId: null,
      children:
        detailedProjectAudits?.[project.id]?.sections?.map((section: IFinancePivotSection) => {
          const isSectionAutoExpanded = isProjectAutoExpanded && allSubSectionsNumber < 10;
          return {
            id: section.id,
            name: section.name,
            audit_data: section.audit_data,
            isOpen: isSectionAutoExpanded,
            parentId: project.id,
            children:
              section.subsections?.map((subsection: IFinancePivotSubsection) => {
                const isSubsectionAutoExpanded = isSectionAutoExpanded && allExpendituresNumber < 10;
                return {
                  id: subsection.id,
                  name: subsection.name,
                  audit_data: subsection.audit_data,
                  isOpen: isSubsectionAutoExpanded,
                  parentId: section.id,
                  children: [subsection.works, subsection.materials, subsection.services]
                    .map((typeExpenditures: ITreeFinancePivotItem, index: number) => {
                      const expenditureTypeName =
                        index === 0
                          ? EXPENDITURE_TYPES_NAMES[EXPENDITURE_TYPES.WORK]
                          : index === 1
                          ? EXPENDITURE_TYPES_NAMES[EXPENDITURE_TYPES.MATERIAL]
                          : index === 2
                          ? "Машины и механизмы"
                          : "Расценки";
                      const groupedExpenditures = typeExpenditures?.groups?.flatMap(
                        (group: ITreeFinancePivotGroup) => group.expenditures
                      );
                      const typeExpendituresChildren = (
                        typeExpenditures?.expenditures
                          ?.filter(
                            (expenditure) => groupedExpenditures?.findIndex((x) => x?.id === expenditure?.id) === -1
                          )
                          ?.map((expenditure: ITreeFinancePivotExpenditure) => ({
                            ...expenditure,
                            id: expenditure.id,
                            name: expenditure.name,
                            parentId: `${subsection.id}_${index}`,
                            audit_data: expenditure.audit_data,
                          })) || []
                      ).concat(
                        //@ts-ignore
                        typeExpenditures?.groups?.map((group: ITreeFinancePivotGroup) => ({
                          id: group.id,
                          name: group.name,
                          audit_data: group.audit_data,
                          isOpen: false,
                          parentId: `${subsection.id}_${index}`,
                          children: group.expenditures?.map((expenditure: ITreeFinancePivotExpenditure) => ({
                            ...expenditure,
                            id: expenditure.id,
                            name: expenditure.name,
                            parentId: subsection.id,
                            audit_data: expenditure.audit_data,
                          })),
                        })) || []
                      );
                      if (!typeExpendituresChildren.length) return undefined;
                      return {
                        id: `${section.id}_${subsection.id}_${index}`,
                        name: expenditureTypeName,
                        isOpen: false,
                        parentId: subsection.id,
                        audit_data: typeExpenditures.audit_data,
                        children: typeExpendituresChildren,
                      };
                    })
                    .filter((x) => x),
                };
              }) || [],
          };
        }) || [],
    };
  };

const formatAuditTableSection = (section: IAuditTree) => {
  let tableSection = { ...section, children: undefined, key: `${section.id}_${section.parentId}_row` };
  Object.entries(section.audit_data).forEach(([key, values]: [string, Record<"plan" | "fact" | "diff", string>]) =>
    Object.keys(values).forEach(
      (valueKey: "plan" | "fact" | "diff") => (tableSection[`${key}_${valueKey}`] = Number(values[valueKey]))
    )
  );
  return tableSection;
};

const prepareAuditSections = (sections: IAuditTree[] | undefined) => {
  const preparedSections = [];

  sections?.forEach((section) => {
    preparedSections.push(formatAuditTableSection(section));
    if (section.isOpen) {
      const children = prepareAuditSections(section.children);
      preparedSections.push(...children);
    }
  });
  return preparedSections;
};

export const prepareAuditTableData = (sections: IAuditTree[], objectId: number | string | undefined) => {
  let sectionsToPrepare = formatFinanceTreeViaObjectId(sections, +objectId);
  return prepareAuditSections(sectionsToPrepare);
};

export const auditTypeToggler = (currentType: IAuditInitialState["auditType"]) => {
  switch (currentType) {
    case "forecast":
      return "summary";
    case "summary":
      return "forecast";
    default:
      return "summary";
  }
};

export const prepareAuditTableDataByProjects = (sections: IAuditTree[], projectId?: string, objectId?: string) => {
  let sectionsToPrepare = formatFinanceTreeViaObjectId(sections, +objectId, projectId);
  console.log("audit sectionsToPrepare", sectionsToPrepare);
  return prepareAuditSections(sectionsToPrepare);
};
