import { IExpenditureTypesFiltersInBuilding } from "../../../types";
import { EXPENDITURE_TYPES } from "../../../../../../constants/constant";
import { sortExpendituresAndGroups } from "../../../../../../utils/sortExpendituresAndGroups";

export interface ISortBuildingGroup {
  expenditures: { number: number | string }[];
}
export interface ISortBuildingExpenditure {
  number: number | string;
}

const getBuildingGroupAnchorIndex = (group: ISortBuildingGroup) =>
  Math.min(...(group.expenditures?.map((exp) => Number(exp.number)) || []));

export const sortBuildingExpendituresAndGroups = (
  expenditures: ISortBuildingExpenditure[],
  groups: ISortBuildingGroup[],
  filters: IExpenditureTypesFiltersInBuilding,
) => {
  const groupedExpendituresNumbers = groups
    .flatMap((group) => group.expenditures)
    .map((expenditure) => Number(expenditure.number));

  const typeFiltersArr = [
    EXPENDITURE_TYPES.WORK,
    ...Object.keys(filters).filter((key) => filters[key as keyof IExpenditureTypesFiltersInBuilding])
  ];

  const sortedExpendituresAndGroups = sortExpendituresAndGroups(
    expenditures,
    groups,
    getBuildingGroupAnchorIndex,
    (expenditures, groupAnchorNumber) => expenditures.findIndex((exp) => Number(exp.number) > groupAnchorNumber)
  )
    .filter((x) => (!!x.number ? !groupedExpendituresNumbers.includes(Number(x.number)) : true));

  return sortedExpendituresAndGroups.filter((x) => x.expenditure_type ? typeFiltersArr.includes(x.expenditure_type) : true);
};