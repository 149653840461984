import cn from "classnames";
import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";

import { exportFilterStatusesArray, exportFilterTypesArray } from "../constants";
import { IdNameLabelType } from "types/IdNameLabelType";

import { useCheckIsProductionModule } from "../../hooks/useCheckIsProductionModule";

import styles from "./ExportTableHead.module.scss";

interface Iprops {
  onChangeFilter: (name: string, value: string) => void;
  onReload: () => void;
  formattedObjects: IdNameLabelType[];
}

const ExportTableHead: React.FC<Iprops> = ({ onChangeFilter, onReload, formattedObjects }) => {
  const isInProduction = useCheckIsProductionModule();

  return (
    <TableReusableHead
      className={cn(styles.row, { [styles.inProduction]: isInProduction })}
      isSticky
      dataTestId="page_docs_export"
    >
      <TableReusableHeaderCell>№</TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="doc_type"
        filterOptions={exportFilterTypesArray}
        filterTitle="Тип"
        filterType="select"
        onChangeFilter={onChangeFilter}
      >
        Тип
      </TableReusableHeaderCell>
      {!isInProduction && (
        <TableReusableHeaderCell
          filterField="building_id"
          filterOptions={formattedObjects}
          filterTitle="Проект"
          filterType="select"
          onChangeFilter={onChangeFilter}
        >
          Проект
        </TableReusableHeaderCell>
      )}
      <TableReusableHeaderCell isCentered>Период</TableReusableHeaderCell>
      <TableReusableHeaderCell>Инициатор</TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle="Дата выгрузки"
        filterType="datesRange"
        onChangeFilter={onChangeFilter}
        rangeEndField="created_at_before"
        rangeStartField="created_at_after"
        isCentered
      >
        Дата выгрузки
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="status"
        filterOptions={exportFilterStatusesArray}
        filterTitle="Статус"
        filterType="select"
        onChangeFilter={onChangeFilter}
        isLast
      >
        Статус&nbsp; (
        <span className={styles.reload} onClick={onReload}>
          Обновить
        </span>
        )
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default ExportTableHead;
