import axios from "axios";

import { IGroupInfo } from "components/UI/organism/ExpenditureGroupModal/types";

import {
  IChangeJournalPriceTypeRequest,
  IConfirmGroupData,
  IGroupBySection,
  IJournalSectionForTickets,
  IStockUsingMaterialsResponse,
} from "./types";
import { IList } from "types/ListResponse";
import { ITicketJournalDelivery } from "types/interfaces/Tickets";

export const journalApi = {
  changeMaterialPrice: (buildingId: number, data: IChangeJournalPriceTypeRequest) => {
    return axios.post(`/journal/${buildingId}/fulfillment/materials/change_amount/`, data);
  },

  patchStockUsingMaterialsConfirmPrice: (
    buildingId: number,
    expenditureId: number,
    stockId: number,
    amount: number
  ) => {
    return axios.patch(
      `/building/${buildingId}/estimate/items/${expenditureId}/stockusingmaterials/${stockId}/confirm_price/`,
      {
        amount,
      }
    );
  },

  getSectionsForTickets: (objectId: string, year: number, month: number) => {
    return axios.get<IList<IJournalSectionForTickets>>(`/building/${objectId}/section/tickets/${year}/${month}/`, {
      params: { limit: 500 },
    });
  },

  getTickets: (objectId: string, params: Record<string, string | number>) => {
    return axios.get(`/building/${objectId}/tickets/`, { params });
  },

  patchTicket: (objectId: string, ticketId: number, data: Partial<ITicketJournalDelivery>) => {
    return axios.patch(`/building/${objectId}/tickets/${ticketId}/`, data);
  },

  createTicket: (objectId: string, ticketData: Partial<ITicketJournalDelivery>) => {
    return axios.post(`/building/${objectId}/tickets/`, ticketData);
  },

  getGroups: (objectId: string, params: Record<string, string | number>) => {
    return axios.get<IList<IGroupBySection>>(`/building/${objectId}/groups/journal/`, { params });
  },

  getGroupDetailInfo: (objectId: string, groupId: number) => {
    return axios.get<IGroupInfo>(`/building/${objectId}/groups/${groupId}/`);
  },

  postGroup: (objectId: string, groupId: number, data: IConfirmGroupData) => {
    return axios.post(`/building/${objectId}/groups/facts/${groupId}/confirm/`, data);
  },
  approveTicket: (buildingId: string, expenditureId: number) => {
    return axios.post(`/building/${buildingId}/estimate/fact/expenditure/approve/${expenditureId}/`);
  },
  approveGroupTicket: (buildingId: string, groupId: number) => {
    return axios.post(`/building/${buildingId}/estimate/fact/group/approve/${groupId}/`);
  },
};
