import axios from "axios";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { addFile, deleteFile } from "redux/modules/common/building/materials/specification";

import Icon from "components/UI/Icon/Icon";
import ButtonBase from "components/UI/atoms/ButtonBase";
import SliderModal from "components/UI/atoms/SliderModal";

import downloadFile from "utils/helpers/download";

import crossInCircle from "images/icons/crossInCircle.svg";
import paperClipIcon from "images/icons/paperClipIcon.svg";

import styles from "./AddFile.module.scss";

const AddFile = ({ isOpen, objectId, obj, closeFunModal }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState([]);
  const allFile = [...newFile.filter((item) => !item.delete), ...files];

  const removeFile = (file) => {
    if (file.id) {
      dispatch(deleteFile(objectId, obj.id, file.id));
      setFiles(files.filter((el) => el.id !== file.id));
    } else {
      setNewFile(newFile.filter((item) => item.name !== file.name));
    }
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setNewFile([...newFile, file]);
  };
  const handleCloseModal = () => {
    closeFunModal();
    setNewFile([]);
  };
  const typeFile = (el) => el.substring(el.lastIndexOf(".") + 1, el.length) || el;
  const handleDownloadFile = (el) =>
    downloadFile(`${axios.defaults.baseURL}/building/${objectId}/materials/${obj.id}/files/${el.id}/`, el?.name);

  const handleSubmit = () => {
    if (newFile.length > 0) {
      const formData = new FormData();
      newFile.map((file) => formData.append("files", file));
      dispatch(addFile(objectId, obj.id, formData));
    }
    handleCloseModal();
  };

  const dragEnterHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const dragLeaveHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const dropHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = [...event.dataTransfer.files];
    setNewFile([...newFile, ...file]);
  };

  useEffect(() => {
    isOpen && setFiles(obj.files);
  }, [obj]);

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Вложения</div>
            <div className={styles.subTitle}>{obj?.name}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.hiddenWrapper}>
              <div className={styles.fieldTitle}>Список вложений: {!!files.length && files.length}</div>
              <div className={styles.fieldTitleActive}>Диск</div>
              <button
                type="button"
                disabled={newFile.length === 0}
                onClick={handleSubmit}
                className={styles.fieldTitleActive}
              >
                Сохранить изменения
              </button>
            </div>
            <div
              onDragEnter={dragEnterHandler}
              onDragLeave={dragLeaveHandler}
              onDragOver={dragEnterHandler}
              className={cn(styles.block, { [styles.blocks]: allFile.length === 0 })}
            >
              <div className={cn(styles.blockTop, { [styles.blockTops]: allFile.length === 0 })}>
                {allFile.length > 0 &&
                  allFile.map((item, index) => {
                    return (
                      <div key={index} className={styles.item}>
                        <div
                          className={cn(styles.value, {
                            [styles.valueActive]: typeFile(item?.name) === "docx",
                          })}
                        >
                          <div title={`Скачать`}>
                            <span style={{ cursor: "pointer" }} onClick={() => item.id && handleDownloadFile(item)}>
                              {typeFile(item?.name).toUpperCase()}
                            </span>
                          </div>
                          <div className={styles.icons} onClick={() => removeFile(item)}>
                            <Icon icon={crossInCircle} />
                          </div>
                        </div>
                        <p title={item?.name} className={styles.docName}>
                          {item?.name.split(".")[0]}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <div
                className={styles.blockBottom}
                onDrop={dropHandler}
                onDragEnter={dragEnterHandler}
                onDragLeave={dragLeaveHandler}
                onDragOver={dragEnterHandler}
              >
                {!!files.length && <div className={styles.newText}>Добавить еще</div>}
                <div className={styles.clipBlock}>
                  <div className={styles.clip}>
                    <label>
                      <Icon icon={paperClipIcon} />
                    </label>
                  </div>
                  <span>Перенесите сюда документы</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actionBlock}>
            <ButtonBase type="button" medium secondary onClick={handleCloseModal}>
              Отменить
            </ButtonBase>
            &nbsp;&nbsp;&nbsp;
            <ButtonBase medium primary>
              <input type="file" name="file" id="fileInput" accept=".pdf, .xlsx" multiple onChange={handleFileUpload} />
              {files.length > 0 ? "Прикрепить еще" : "Прикрепить файлы"}
            </ButtonBase>
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default React.memo(AddFile);
