import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "utils/formatters/transformDigitToFinancial";
import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";

const formatPercentValue = (value) =>
  transformDigitToFinancial(parseTransformedString(value), { withFloat: true, dropZeros: true, log: true });

const useClosureDatesDoubleInput = ({ amountSource, discountCost, discountPercent, editClosureDate }) => {
  const [changedDiscountCost, setChangedDiscountCost] = useState(
    transformDigitToFinancial(discountCost, { withFloat: true, dropZeros: true }) || "0"
  );
  const [changedDiscountPercent, setChangedDiscountPercent] = useState(() => dropNonSignificantZeros(discountPercent, 8));

  const onChangeDiscountPercent = useCallback(
    (value) => {
      let valueWithoutPercent = parseTransformedNumber(value);
      if (isNaN(+valueWithoutPercent) || +valueWithoutPercent >= 101 || +valueWithoutPercent < 0) return;

      const isBadLastSign = ['.', '0'].includes(value?.at(-1));
      if (isBadLastSign) {
        setChangedDiscountPercent(value);
        editClosureDate({user_percent: value})
      } else {
        const procVal = Number(value)
        if (!isNaN(procVal)) {
          const newFormattedPercent = dropNonSignificantZeros(procVal, 8);
          setChangedDiscountPercent(newFormattedPercent);
          editClosureDate({user_percent: newFormattedPercent.toString()})
        }
      }

      const beatifiedNewDiscountCost = transformDigitToFinancial((valueWithoutPercent * +amountSource) / 100, {
        withFloat: true,
        dropZeros: true,
      });
      setChangedDiscountCost(beatifiedNewDiscountCost);
    },
    [amountSource]
  );

  const onChangeDiscountCost = useCallback(
    (value) => {
      let beautifiedValue = transformDigitToFinancial(parseTransformedString(value), {
        withFloat: true,
        dropZeros: true,
      });
      const parsedNumber = parseTransformedNumber(beautifiedValue);

      if (isNaN(+parsedNumber) || +parsedNumber >= amountSource + 1 || +parsedNumber < 0) {
        return;
      }

      const isPointLastSign = value?.at(-1) === '.';
      if (isPointLastSign) {
        setChangedDiscountCost(beautifiedValue);
      } else {
        const procVal = Number(beautifiedValue?.replaceAll(' ', ''));
        if (!isNaN(procVal)) {
          setChangedDiscountCost(beautifiedValue);
        } else {
          setChangedDiscountCost('0');
        }
      }

      const newPercentProc = (+parsedNumber * 100) / amountSource;
      if (!isNaN(newPercentProc)) {
        const newFormattedPercent = dropNonSignificantZeros(newPercentProc, 8);
        setChangedDiscountPercent(newFormattedPercent);
        editClosureDate({user_percent: newFormattedPercent})
      }
    },
    [amountSource]
  );

  return { changedDiscountCost, changedDiscountPercent, onChangeDiscountPercent, onChangeDiscountCost };
};

export default useClosureDatesDoubleInput;
