import { useMemo } from "react";

import { formatFinanceTreeViaObjectId } from "../financeCommonUtils";

import { EXPENDITURE_TYPES_NAMES } from "../../../../../../constants/constant";
import { ExpenditureType } from "../../../../../../types/enums/ExpenditureTypeEnum";
import { IAuditTree } from "../audit/types";
import {
  IDetailedProjectForecast,
  IForecastInitialState,
  IForecastTree,
  IProjectViewForecast,
  ITreeFinanceForecastGroup,
  ITreeFinanceForecastGroupExpenditure,
  ITreeFinanceForecastSection,
  ITreeFinanceForecastSubSection,
} from "./types";

const formatForecastTableSection = (section: IForecastTree, year: number) => {
  let tableSection = {};
  for (let i = 0; i < 12; i++) {
    const dataAtMoment = section.forecast_data?.find((x) => +x.year === +year && x.month === i + 1) || {
      plan: 0,
      fact: 0,
      diff: 0,
    };

    const fact = Number(dataAtMoment.fact);
    const plan =
      section.name === "Гарантийные обязательства" ? Number(dataAtMoment.plan) * -1 : Number(dataAtMoment.plan);
    const diff = Number(dataAtMoment.diff);

    const planFact = {
      [`plan_${i}`]: plan,
      [`fact_${i}`]: fact,
      [`diff_${i}`]: diff,
    };

    tableSection = {
      key: `${section.id}_${section.parentId}_row`,
      ...tableSection,
      ...planFact,
    };
  }
  const totalKeys = Object.keys(section?.q_data || {});
  totalKeys.forEach((el) => {
    const planFact = {
      [`plan_${el}`]: section?.q_data?.[el]?.plan,
      [`fact_${el}`]: section?.q_data?.[el]?.fact,
      [`diff_${el}`]: section?.q_data?.[el]?.diff,
    };
    tableSection = {
      ...tableSection,
      ...planFact,
    };
  });
  return tableSection;
};

const prepareForecastSections = (sections: IForecastTree[] | undefined, year: number) => {
  const preparedSections = [];
  sections?.forEach((section) => {
    preparedSections.push(formatForecastTableSection(section, year));
    if (section.isOpen) {
      const children = prepareForecastSections(section.children, year);
      preparedSections.push(...children);
    }
  });
  return preparedSections;
};

export const prepareForecastTableData = (
  sections: IForecastTree[],
  details: Record<number | string, IDetailedProjectForecast>,
  year: number,
  objectId: number | string | undefined
) => {
  let sectionsToPrepare = formatFinanceTreeViaObjectId(sections, objectId);

  const warranties = {
    id: Math.random(),
    children: [],
    isOpen: false,
    name: "Гарантийные обязательства",
    forecast_data: details[objectId]?.warranties,
    parentId: objectId,
  };
  const advancePayment = {
    id: Math.random(),
    name: "Авансирование",
    forecast_data: details[objectId]?.advance_payments,
    parentId: objectId,
    children: [],
    isOpen: false,
  };
  let displayedTree: any[];

  if (advancePayment.forecast_data?.length && warranties.forecast_data?.length) {
    displayedTree = [...sectionsToPrepare, advancePayment, warranties];
  } else if (advancePayment.forecast_data?.length && !warranties.forecast_data?.length) {
    displayedTree = [...sectionsToPrepare, advancePayment];
  } else if (!advancePayment.forecast_data?.length && warranties.forecast_data?.length) {
    displayedTree = [...sectionsToPrepare, warranties];
  } else {
    displayedTree = sectionsToPrepare;
  }

  return prepareForecastSections(+objectId ? displayedTree : sectionsToPrepare, year);
};

export const produceMapForecastParentProjectFn =
  (detailedProjectAudits: any, objectId: number, projectId: string = "-1") =>
  (project: any, _: number, arr: any) => {
    const isProjectAutoExpanded = +projectId === project.id;
    return {
      id: project.id,
      isParentProject: true,
      name: project.name,
      isOpen: isProjectAutoExpanded,
      data: project.data,
      q_data: project?.q_data,
      children: project.buildings?.map(produceMapForecastProjectFn(detailedProjectAudits, objectId)),
    };
  };

export const produceMapForecastProjectFn =
  (detailedProjectForecasts: IForecastInitialState["detailedProjectForecasts"], objectId: number) =>
  (project: IProjectViewForecast) => {
    const isProjectAutoExpanded = objectId === project.id;
    const allSubSectionsNumber = detailedProjectForecasts[project.id]?.sections
      ?.flatMap((s) => s.subsections.length)
      .reduce((acc, v) => acc + v, 0);
    return {
      id: project.id,
      name: project.name,
      forecast_data: project.data,
      q_data: project?.q_data,
      isOpen: isProjectAutoExpanded,
      isRoot: true,
      parentId: null,
      children:
        detailedProjectForecasts?.[project.id]?.sections?.map((section: ITreeFinanceForecastSection) => {
          const isSectionAutoExpanded = isProjectAutoExpanded; // && allSubSectionsNumber < 10;
          return {
            id: section.id,
            name: section.name,
            forecast_data: section.data,
            q_data: section?.q_data,
            isOpen: isSectionAutoExpanded,
            parentId: project.id,
            children:
              section.subsections?.map((subsection: ITreeFinanceForecastSubSection) => {
                const groupedExpenditures =
                  Object.values(subsection.type_expenditures)?.flatMap(
                    (data: ITreeFinanceForecastSubSection["type_expenditures"][ExpenditureType]) =>
                      Object.values(data.groups)
                        .flat()
                        .flatMap((group) => group.expenditures)
                  ) || [];
                return {
                  id: subsection.id,
                  name: subsection.name,
                  forecast_data: subsection.data,
                  q_data: subsection?.q_data,
                  isOpen: isSectionAutoExpanded,
                  parentId: section.id,
                  children: Object.entries(subsection.type_expenditures).map(([expenditureType, data]) => ({
                    id: `${section.id}_${subsection.id}_${expenditureType}`,
                    name: EXPENDITURE_TYPES_NAMES[expenditureType] || "Расценки",
                    isOpen: false,
                    parentId: subsection.id,
                    forecast_data: data.data,
                    children: (
                      data.expenditures
                        ?.filter(
                          (expenditure) => groupedExpenditures.findIndex((x) => x?.id === expenditure?.id) === -1
                        )
                        ?.map((expenditure: ITreeFinanceForecastGroupExpenditure) => ({
                          ...expenditure,
                          id: `${section.id}_${subsection.id}_${expenditureType}_${expenditure.id}`,
                          name: expenditure.name,
                          parentId: subsection.id,
                          forecast_data: expenditure.data,
                        })) || []
                    ).concat(
                      Object.values(data.groups)?.map((group: ITreeFinanceForecastGroup) => ({
                        id: `${section.id}_${subsection.id}_${expenditureType}_${group.id}`,
                        name: group.name,
                        forecast_data: group.data,
                        isOpen: false,
                        parentId: subsection.id,
                        children: group.expenditures?.map((expenditure: ITreeFinanceForecastGroupExpenditure) => ({
                          ...expenditure,
                          id: `${section.id}_${subsection.id}_${expenditureType}_${group.id}_${expenditure.id}`,
                          name: expenditure.name,
                          forecast_data: expenditure.data,
                          parentId: subsection.id,
                        })),
                      })) || []
                    ),
                  })),
                };
              }) || [],
          };
        }) || [],
    };
  };

export const prepareForecastTableDataByProjects = (
  sections: IAuditTree[],
  projectId?: string,
  objectId?: string,
  year?: number
) => {
  let sectionsToPrepare = formatFinanceTreeViaObjectId(sections, +(objectId ?? "-1"), projectId);

  return prepareForecastSections(sectionsToPrepare, year!);
};
