import React from "react";

import { IExpenditureInActing } from "redux/modules/common/building/journal/acting/types";

import Actions from "components/UI/atoms/Actions/Actions";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import styles from "./JournalKsModalRow.module.scss";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

interface IProps {
  expenditure: IExpenditureInActing;
  onDeselect: (expenditureId: number) => void;
}

const JournalKs2ModalSubRow: React.FC<IProps> = ({ expenditure, onDeselect }) => {
  return (
    <TableReusableRow isHoverable={false} className={styles.expRow} containerClassName={styles.expRowWrapper}>
      <TableReusableCell>{expenditure.row_number}</TableReusableCell>
      <TableReusableCell isNoBreak>{expenditure.name}</TableReusableCell>
      <TableReusableCell>
        {expenditure.todo_count} {expenditure.measure}
      </TableReusableCell>
      <TableReusableCell isRighted>{transformDigitToFinancial(expenditure.amount)}</TableReusableCell>
      <TableReusableCell>
        <Actions canRemove onRemove={() => onDeselect(expenditure.id || expenditure.expenditure_id)} isThinCross />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(JournalKs2ModalSubRow);
