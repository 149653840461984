import moment from "moment";
import React from "react";

import { IRemarkBySection } from "../../../../../../../../redux/modules/common/building/journal/journalExecution/types";

import { useUIIndicator } from "../../../../../../../../hooks/uiIndicators/useUIIndicator";
import TableReusableRow, { TableReusableCell } from "../../../../../../../UI/atoms/TableReusable/TableReusableRow";

import RemarkStatus from "../../RemarkStatus/RemarkStatus";

import { MODULES_ENUM } from "../../../../../../../../types/enums/ModulesEnum";

import { ReactComponent as CalendarBlue } from "images/icons/calendarBlue.svg";

import styles from "../RemarksBySection.module.scss";

export interface IRemarkRowProps {
  remark: IRemarkBySection;
  onRowClick: () => void;
}

const RemarkRow: React.FC<IRemarkRowProps> = ({ remark, onRowClick }) => {
  const { isIndicatorDisplayed } = useUIIndicator({
    data: {
      remark_id: remark.id,
      child_section_id: remark.expenditure_section?.id,
      parent_section_id: remark.expenditure_section?.parent?.id,
    },
    module: MODULES_ENUM.OBJECTS,
    type: ["remark_review", "remark_created"],
  });
  return (
    <TableReusableRow className={styles.row} onClick={onRowClick} isWithIndicator={isIndicatorDisplayed}>
      <TableReusableCell isNoBreak>{remark.name || `Замечание №${remark.id}`}</TableReusableCell>
      <TableReusableCell isNoBreak>{remark.author_name}</TableReusableCell>
      <TableReusableCell>
        <div className={styles.date}>
          <span>{moment(remark?.resolve_date).format("DD/MM/YYYY")}</span>
          <CalendarBlue />
        </div>
      </TableReusableCell>
      <TableReusableCell isCentered>
        <RemarkStatus status={remark.status} className={styles.remarkStatus} />
      </TableReusableCell>
      <TableReusableCell isNoBreak>{remark.responsible_name}</TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(RemarkRow);
