import Legend from 'components/UI/atoms/Legend/Legend';
import moment from 'moment';

import { EventPlateNew } from '../CalendarBottom/EventPlateNew';
import { CALENDAR_LEGEND_OPTIONS } from '../constants';

import styles from './CalendarMain.module.scss';


const CalendarMain = ({
  targetDay,
  targetYear,
  targetMonth,
  setIsSlideModal,
  rangedEvents,
  setDateDays,
  periodSelect,
}) => {
  const getGroupedEvents = (() => {
    let cachedEvents = [];
    let lastEventsProp = null;

    return () => {
      if (lastEventsProp === rangedEvents) {
        return cachedEvents;
      }

      cachedEvents = rangedEvents.reduce((acc, event) => {
        const dateArr = acc.find((item) => item.date === event.date);

        if (dateArr) {
          const typeArr = dateArr.groups.find((item) => item.type === event.event_type);
          if (typeArr) {
            typeArr.events.push(event);
          } else {
            dateArr.groups.push({
              type: event.event_type,
              events: [event],
            });
          }
        } else {
          acc.push({
            date: event.date,
            groups: [
              {
                type: event.event_type,
                events: [event],
              },
            ],
          });
        }

        return acc;
      }, []);
      lastEventsProp = rangedEvents;
      return cachedEvents;
    };
  })();

  const groupedEvents = getGroupedEvents();

  const openDayEvents = (day) => {
    setIsSlideModal('isSingle');
    setDateDays(day);
  };

  const days = [];

  let dayMod =
    periodSelect === 'all' || document.body.offsetWidth <= 768
      ? 0
      : -1 * (targetDay.isoWeekday() - 1);

  let renderDaysAmount =
    periodSelect === 'all'
      ? 31
      : moment({
        month: targetMonth,
        year: targetYear,
      }).daysInMonth() + Math.abs(dayMod);

  renderDaysAmount =
    renderDaysAmount +
    (document.body.offsetWidth <= 768 ? 3 - (renderDaysAmount % 3) : 7 - (renderDaysAmount % 7));

  while (days.length < renderDaysAmount) {
    const newDate = moment({
      day: targetDay.date(),
      month: targetDay.month(),
      year: targetYear,
    }).add(dayMod, 'days');
    days.push(newDate);
    dayMod += 1;
  }

  return (
    <div className={styles.container}>
      {periodSelect !== 'all' && (
        <div className={styles.namesOfDays}>
          <div>ПН</div>
          <div>ВТ</div>
          <div>СР</div>
          <div>ЧТ</div>
          <div>ПТ</div>
          <div>СБ</div>
          <div>ВС</div>
        </div>
      )}
      <div className={styles.calendar}>
        {days.map((item, index) => {
          const todayEvents = groupedEvents.find((events) => moment(events.date).format('DD.MM.YYYY') === item.format('DD.MM.YYYY'));
          let today = moment(new Date());
          let month = today.format('M');
          let currentMonth = item.format('M') === month;
          const comparedWithToday = item.format('DD/MM/YY') === today.format('DD/MM/YY');

          return (
            <div
              key={index}
              onClick={() => todayEvents && openDayEvents(item)}
              style={{
                cursor: todayEvents ? 'pointer' : 'default',
                border: comparedWithToday ? '1px solid #4FB2ED' : '1px solid #909090',
                boxShadow: comparedWithToday ? '0 0 4px #4FB2ED' : 'none'
              }}
              className={`
                ${styles.day} 
                ${periodSelect !== 'all' && item.month() !== targetMonth
                  ? styles.disabledDay
                  : item.year() !== targetYear
                    ? styles.disabledDay
                    : ''
                }
              `}
            >
              <div >
                {periodSelect === 'all'
                  ? <div className={styles.dayNumber1}>
                    <span>{item.format('D MMMM')}</span>
                    <span> {item.format('dd')}</span>
                  </div>
                  : <div className={styles.dayNumber2}>
                    {item.format('D')}
                  </div>
                }

                {todayEvents?.groups && todayEvents?.groups.length > 0
                  ? <div className={styles.boxValue}>Всего событий:
                    <span> {todayEvents?.groups.length > 1
                      ? todayEvents?.groups[0].events.length + todayEvents?.groups[1].events.length
                      : todayEvents?.groups[0].events.length}</span>
                  </div>
                  : <div>
                    {currentMonth && <div className={styles.boxValue}>Нет событий</div>}
                  </div>}
              </div>
              <div className={`${styles[`eventsGrid_${todayEvents?.groups.length}`]} ${styles.containerPlate}`}>
                {todayEvents?.groups
                  ?.sort((a, b) => b.type.length - a.type.length)
                  .map((group, index) => (
                    <EventPlateNew
                      key={index}
                      type={group.type}
                      activeCount={group.events.filter((event) => !event.is_completed).length}
                      events={group.events.filter((event) => !event.is_completed)}
                      count={group.events.length}
                      isCollapsed={todayEvents?.groups.length > 2}
                    />
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarMain;
