import Axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import ExpandableFileInput from "../../../molecules/ExpandableFileInput/ExpandableFileInput";
import MaterialsInfoItem from "../components/MaterialInfoItem/MaterialsInfoItem";
import MaterialListItem from "../components/MaterialListItem/MaterialListItem";
import NavBar from "../components/NavBar/NavBar";
import WorkerItem from "../components/WorkersList/WorkerItem";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { Spinner } from "../../../Spinner/Spinner";
import EmptyPlaceholder from "../../../atoms/EmptyPlaceholder/EmptyPlaceholder";

import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";
import { splitThousands } from "../../../../../utils/formatters/splitThousands";
import getShortFullName from "utils/formatters/getShortFullName";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import materialsIcon from "images/icons/navigation/materials.svg";

import styles from "./JournalDoneModal.module.scss";

interface IProps {
  data: IExpendituresInJournalFulfillment["items"][0];
  isOpen: boolean;
  onHideTabs: React.Dispatch<React.SetStateAction<boolean>>;
  isExist: boolean;
  stockId: number;
}

function JournalDoneMaterials({ data, isOpen, onHideTabs, isExist, stockId }: IProps) {
  const objectId = useObjectId();
  const ticketItem = data.expenditure;
  const [materials, setMaterials] = useState<any[] | null>([]);
  const [writeOffs, setWriteOffs] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [activeMaterialId, setActiveMaterialId] = useState<any>();

  useEffect(() => {
    if (!isOpen) return;

    setIsLoading(true);

    Axios.get(`/stocks/${stockId}/using/expenditures/${ticketItem?.expenditure_id}/`).then((resp) => {
      setMaterials(resp.data.results);
      setIsLoading(false);
    });

    Axios.get(`/building/${objectId}/estimate/items/${ticketItem?.expenditure_id}/stockusingmaterials/`, {
      params: { for_journal: 1 },
    }).then((resp) => {
      const writeOffs = resp.data.results.filter((el: any) => !!el.confirm_count);
      setWriteOffs(writeOffs);
    });
  }, [objectId, isOpen]);

  useEffect(() => {
    if (!activeMaterialId) return;
    Axios.get(`/building/${objectId}/expenditure_files/${ticketItem?.expenditure_id}/`).then((resp) =>
      setFiles(resp.data.results?.map((el: any) => ({ ...el, name: el.file_name })))
    );
  }, [activeMaterialId]);

  useEffect(() => {
    if (!isOpen) {
      setWriteOffs(null);
      setMaterials(null);
      setActiveMaterialId(null);
      setFiles([]);
    }
  }, [isOpen, objectId]);

  useEffect(() => {
    if (!materials?.length && !isLoading) {
      onHideTabs(false);
    } else {
      onHideTabs(true);
    }
    if (materials?.length === 1) {
      setActiveMaterialId(materials[0]?.id);
    }
  }, [materials, isLoading]);

  const fileDeleteHandler = (fileId: number) => {
    Axios.delete(`/building/${objectId}/expenditure_files/${ticketItem?.expenditure_id}/${fileId}`).then(() => {
      Axios.get(`/building/${objectId}/expenditure_files/${ticketItem?.expenditure_id}/`).then((resp) =>
        setFiles(resp.data.results?.map((el: any) => ({ ...el, name: el.file_name })))
      );
    });
  };

  const fileUploadHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    Axios.post(`/building/${objectId}/expenditure_files/${ticketItem?.expenditure_id}/`, formData).then((resp) => {
      Axios.get(`/building/${objectId}/expenditure_files/${ticketItem?.expenditure_id}/`).then((resp) =>
        setFiles(resp.data.results?.map((el: any) => ({ ...el, name: el.file_name })))
      );
    });
  };

  const combinedMaterialsListItem = useMemo(() => {
    let filteredArray = [];

    filteredArray = Object.values(
      materials?.reduce((value, object) => {
        if (value[object?.stock_product?.product_building.id]) {
          ["count"].forEach(
            (key) =>
              (value[object?.stock_product?.product_building.id][key] =
                Number(value[object?.stock_product?.product_building.id][key]) + Number(object[key]))
          );
        } else {
          value[object?.stock_product?.product_building.id] = { ...object };
        }
        return value;
      }, {})
    );
    return filteredArray;
  }, [materials]);

  const currentMaterialName = useMemo(
    () => materials?.find((item) => item?.id === activeMaterialId)?.stock_product?.product_building.name,
    [activeMaterialId, materials]
  );

  if (!isExist) return null;

  return (
    <>
      {!isLoading && !activeMaterialId && combinedMaterialsListItem?.length
        ? combinedMaterialsListItem?.map((item: any) => (
            <MaterialListItem
              key={item.id}
              name={item.stock_product?.product_building?.name}
              count={dropNonSignificantZeros(item?.combined_count || item.count)}
              measure={item.stock_product?.product_building?.measure}
              item={item}
              onClick={setActiveMaterialId}
            />
          ))
        : null}
      {!isLoading && !activeMaterialId && !materials?.length ? <EmptyPlaceholder img={materialsIcon} /> : null}
      {isLoading && <Spinner />}
      {activeMaterialId && (
        <>
          <NavBar text="Все материалы" isExist={!!materials?.length} onClick={() => setActiveMaterialId(null)} />
          <h2 className={styles.title}>{currentMaterialName}</h2>
          {combinedMaterialsListItem.map((item: any) =>
            item.id === activeMaterialId ? (
              <React.Fragment key={item.id}>
                <div className={styles.row2}>
                  <div className={styles.cell}>
                    <div className={styles.label}>Кол-во:</div>
                    <div
                      className={styles.value}
                      title={
                        transformDigitToFinancial(item?.count, { withFloat: false }) +
                        "  (" +
                        item.stock_product?.product_building?.measure +
                        ")"
                      }
                    >
                      {transformDigitToFinancial(item?.count, { withFloat: false })} (
                      {item.stock_product?.product_building?.measure})
                    </div>
                  </div>
                  <div className={styles.cell}>
                    <div className={styles.label}>Стоимость:</div>
                    <div className={styles.value}>{splitThousands(item.amount)}</div>
                  </div>
                </div>
                <div className={styles.doubleInfo}>
                  <div className={styles.grid}>
                    <div className={styles.moment}>{moment(item?.stock_using?.used_at).format("DD/MM/YYYY")}</div>
                    <div className={styles.accept}>Выдал:</div>
                    <WorkerItem worker={item?.stock_using?.responsible || {}} color="green" />
                  </div>
                  <div className={styles.grid}>
                    <div className={styles.moment}>в {moment(item?.stock_using?.used_at).format("HH:mm")}</div>
                    <div className={styles.accept}>Получил:</div>
                    <WorkerItem worker={item?.stock_using?.user || item?.stock_using?.worker || {}} />
                  </div>
                </div>
                <div className={styles.hr} />
              </React.Fragment>
            ) : null
          )}
          {writeOffs?.map((el) => (
            <React.Fragment key={el.id}>
              <MaterialsInfoItem
                date={el.confirm_date}
                name={getShortFullName(el.confirm_user)}
                count={dropNonSignificantZeros(el.confirm_count)}
                measure={el.stock_using_material?.product_building?.measure}
                amount={el.confirm_estimate_amount}
              />
              <div className={styles.hr} />
            </React.Fragment>
          ))}
          <ExpandableFileInput
            files={files || []}
            onRemoveFileDirectly={fileDeleteHandler}
            onUploadFileDirectly={fileUploadHandler}
          />
        </>
      )}
    </>
  );
}

export default JournalDoneMaterials;
