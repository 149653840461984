import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.aook;
export const currentAookSelector = createSelector(stateSelector, (state) => state.currentAook);
export const aookWorksSelector = createSelector(stateSelector, (state) => state.worksBySections);
export const detailedAookSelector = createSelector(stateSelector, (state) => state.detailedAooks);
export const aookDocsSelector = createSelector(stateSelector, (state) => state.docs);
export const aookClosingPendingSelector = createSelector(stateSelector, (state) => state.isClosingPending);
export const aookInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const aookCretingPendingSelector = createSelector(stateSelector, (state) => state.isCreatingPending);
