import React from "react";
import { useDrag } from "react-dnd";
import cn from "classnames";
import { useSelector } from "react-redux";

import { sectionSelector } from "../../../../../../../../../../../redux/modules/common/building/sections/sections";

import Expenditure from "../../../../../../../../../../UI/molecules/Expenditure/Expenditure";
import DragHandlerIcon from "../../../../../../../../../../../images/icons/DragHandlerIcon";

import styles from "./DraggableExpenditure.module.scss";

const DraggableExpenditure = ({ expenditure, checkedExpenditures, isParentSectionChecked, isChecked, ...props }) => {
  const activeSection = useSelector(sectionSelector);

  const [{ isDragging }, drag, preview] = useDrag({
    type: "box",
    item: () => {
      if (isParentSectionChecked) return [activeSection];
      if (checkedExpenditures[expenditure.id]) {
        return Object.values(checkedExpenditures);
      }
      return [expenditure];
    },
    isDragging: (monitor) => {
      return monitor.getItem().includes(expenditure) || monitor.getItem().includes(activeSection);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }, [checkedExpenditures, expenditure, isParentSectionChecked, activeSection]);

  return (
    <div className={styles.container} ref={preview}>
      <div ref={drag} className={cn(styles.dragHandler, { [styles.active]: isDragging || isChecked })}>
        <DragHandlerIcon className={styles.dragHandlerIcon} />
      </div>
      {isDragging ? (
        <div className={styles.dragging} />
      ) : (
        <Expenditure className={styles.expenditure} expenditure={expenditure} isChecked={isChecked} {...props} />
      )}
    </div>
  );
};

export default React.memo(DraggableExpenditure);
