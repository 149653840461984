import cn from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { sectionsWithRemarksSelector } from "../../../../../../../redux/modules/common/building/journal/journalExecution/selectors";
import { getJournalSectionsWithRemarks } from "../../../../../../../redux/modules/common/building/journal/journalExecution/thunks";
import { ISectionWithRemarks } from "../../../../../../../redux/modules/common/building/journal/journalExecution/types";

import { Spinner } from "../../../../../../UI/Spinner/Spinner";
import EmptyPlaceholder from "../../../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import TableReusableRow, { TableReusableCell } from "../../../../../../UI/atoms/TableReusable/TableReusableRow";
import styles from "../../../JournalDone/components/JournalExecSections/JournalExecSections.module.scss";
import RemarksSubsection from "./components/RemarksSubsection";

import RemarkCounts from "../RemarkCounts/RemarkCounts";

import { IRouterParamsWithObjectId } from "../../../../../../../types/routerTypes";

import jurnalIcon from "../../../../../../../images/icons/navigation/jurnalIcon.svg";

import commonJournalStyles from "../../../../Journal.module.scss";

export interface ISectionsWithRemarksProps {
  onSectionClick: (sectionId: number) => void;
}

const SectionsWithRemarks: React.FC<ISectionsWithRemarksProps> = ({ onSectionClick }) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const sectionsData: {
    isLoading: boolean;
    results: ISectionWithRemarks[];
    filters: Record<string, string | number>;
  } = useSelector(sectionsWithRemarksSelector)[objectId];

  const [filters, setFilters] = React.useState<Record<string, string | number>>({});

  const filterHandler = React.useCallback((name: string, value: string) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  }, []);

  React.useLayoutEffect(() => {
    dispatch(getJournalSectionsWithRemarks(objectId, filters));
  }, [objectId, filters]);

  return (
    <div className={styles.container}>
      {sectionsData?.isLoading && <Spinner />}
      {!sectionsData?.isLoading &&
        sectionsData?.results?.map((lsr, index) => (
          <React.Fragment key={lsr.id}>
            <TableReusableRow
              containerClassName={styles.parent}
              className={cn(styles.name, commonJournalStyles.sectionParentRow)}
              isHoverable={false}
              isFont13={false}
            >
              <TableReusableCell isNoBreak>{lsr.name}</TableReusableCell>
              <TableReusableCell className={styles.countsCell}>
                <RemarkCounts
                  total={lsr.count_remarks}
                  created={lsr.count_remarks_created}
                  accepted={lsr.count_remarks_accepted}
                  review={lsr.count_remarks_review}
                />
              </TableReusableCell>
            </TableReusableRow>
            {lsr?.subsections?.map((subsection) => (
              <RemarksSubsection
                key={subsection.id}
                subsection={subsection}
                parentId={lsr.id}
                onClick={() => onSectionClick(subsection.id)}
              />
            ))}
          </React.Fragment>
        ))}
      {!sectionsData?.isLoading && !sectionsData?.results?.length && <EmptyPlaceholder img={jurnalIcon} />}
    </div>
  );
};

export default React.memo(SectionsWithRemarks);
