import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { SET_USER_LOGOUT } from "../../../../../../redux/modules/common/auth";

import MenuItem from "../../../NavigationBar/MenuItem";

import useOnClickOutside from "hooks/useOnClickOutside";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { localStorageLogoutCleanUp } from "../../../../../../utils/helpers/localStorageLogoutCleanUp";

import placeholderAvatar from "../../../../../../images/icons/navigation/placeholderAvatar.svg";

import styles from "./Profile.module.scss";
import { useTranslation } from "react-i18next";

export interface IProps {
  avatar?: string;
}

const Profile: React.FC<IProps> = ({ avatar }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlAdapter = useUrlModuleWithObjectId();
  const containerRef = useRef(null);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const profileUserHandler = useCallback(() => {
    history.push(`/${urlAdapter}/profile`);
  }, [urlAdapter]);

  const exitProfileHandler = useCallback(() => {
    localStorageLogoutCleanUp();
    dispatch({ type: SET_USER_LOGOUT });
    history.push("/auth");
  }, []);

  const openCloseHandler = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOnClickOutside(containerRef, closeHandler);

  return (
    <MenuItem
      isExists
      title={t("Профиль")}
      link={undefined}
      jsxIcon={
        avatar ? (
          <img src={avatar} className={styles.avatar} alt="avatar" />
        ) : (
          <img className={styles.avatar} src={placeholderAvatar} alt="avatar" />
        )
      }
      isBottom
      isWithoutUnderline
      icon={undefined}
      isDirectlyActive={undefined}
      className={styles.container}
      onClick={openCloseHandler}
      isTooltipHidden={isOpen}
      ref={containerRef}
    >
      {isOpen && (
        <div className={styles.popup}>
          <div onClick={profileUserHandler} className={styles.item} data-testid="profile_btn">
            Профиль пользователя
          </div>
          <div onClick={exitProfileHandler} className={styles.item} data-testid="logout-btn">
            Выйти
          </div>
        </div>
      )}
    </MenuItem>
  );
};

export default React.memo(Profile);
