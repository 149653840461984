import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import ObjectsList from "../pages/ObjectsList/ObjectsList";
import { OslaLoader } from "../pages/OslaLoader/OslaLoader";
import ConstructingRoutes from "./components/ConstructingRoutes/ConstructingRoutes";
import ForbiddenPage from "./components/ForbiddenPage/ForbiddenPage";
import ManufacturingRoutes from "./components/ManufacturingRoutes/ManufacturingRoutes";
import PurchasesRoutes from "./components/PurchasesRoutes/PurchasesRoutes";
import TasksRoutes from "./components/TasksRoutes/TasksRoutes";

import { VIEW_CONSTRUCTING_SECTION } from "../../constants/permissions/constructingPermissions";
import { VIEW_MANUFACTURING_SECTION } from "../../constants/permissions/manufacturingPermissions";
import { VIEW_PURCHASES_SECTION } from "../../constants/permissions/purchasesPermissions";

import usePermission from "../../hooks/usePermission";
import useRedirectToDefaultPage from "../../hooks/useRedirectToDefaultPage";
import { useRedirectForApprovePlan } from "../../hooks/useRedirectForApprovePlan";

const Content = ({ location, history }) => {
  const haveViewPurchasesPermission = usePermission(VIEW_PURCHASES_SECTION);
  const haveViewManufacturingPermission = usePermission(VIEW_MANUFACTURING_SECTION);
  const haveViewConstructingPermission = usePermission(VIEW_CONSTRUCTING_SECTION);

  useRedirectToDefaultPage();
  useRedirectForApprovePlan();

  useEffect(() => {
    const locationPathWithoutBadSlashes = location.pathname.replace(/\/{2,}/g, "/");
    if (locationPathWithoutBadSlashes !== location.pathname) history.replace(locationPathWithoutBadSlashes);
  }, [history, location.pathname]);

  return (
    <Switch>
      <Route exact path="/objects" component={ObjectsList} />
      <Route path="/tasks" component={TasksRoutes} />
      {haveViewConstructingPermission && <Route path="/constructing" component={ConstructingRoutes} />}
      {haveViewManufacturingPermission && <Route path={"/objects/:objectId"} component={ManufacturingRoutes} />}
      {haveViewPurchasesPermission && <Route path="/purchases" component={PurchasesRoutes} />}
      <Route exact path={"/"} component={OslaLoader} />
      <Route component={ForbiddenPage} />
    </Switch>
  );
};

export default withRouter(Content);
