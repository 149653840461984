import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { aookActions } from "redux/modules/common/building/journal/aook/actions";
import { aookInvalidateKeySelector } from "redux/modules/common/building/journal/aook/selectors";
import { aosrActions } from "redux/modules/common/building/journal/aosr/actions";
import { aosrInvalidateKeySelector } from "redux/modules/common/building/journal/aosr/selectors";
import { AosrTypes } from "redux/modules/common/building/journal/aosr/types";
import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import SliderModal from "components/UI/atoms/SliderModal";

import AookCreateForm from "../Aook/AookCreateForm";
import AosrCreateModal from "./AosrCreateModal";

import { actTypesEnum } from "../Aook/constants";

import styles from "./Aosr.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditure: IExpendituresInJournalFulfillment["items"][0];
  sectionId: number;
  type: AosrTypes;
  groupId?: number;
  year?: number;
  month?: number;
}

const ActCreateModal: React.FC<IProps> = ({ isOpen, onClose, expenditure, sectionId, type, groupId, year, month }) => {
  const dispatch = useDispatch();
  const aookInvalidateKey = useSelector(aookInvalidateKeySelector);
  const aosrInvalidateKey = useSelector(aosrInvalidateKeySelector);
  const [actType, setActType] = React.useState(actTypesEnum.aosr);
  const item = expenditure?.expenditure ?? expenditure?.group ?? expenditure?.linked;
  const aosr = item?.aosr;

  React.useEffect(() => {
    if (!!aosr) {
      setActType(actTypesEnum.aook);
    }
  }, [aosr]);

  React.useEffect(() => {
    onClose();
  }, [aookInvalidateKey, aosrInvalidateKey]);

  const closeHandler = () => {
    onClose();
    dispatch(aookActions.invalidateKey());
    dispatch(aosrActions.invalidateKey());
  };

  return (
    <SliderModal isOpen={isOpen} closeHandler={closeHandler} className={styles.modal}>
      <header className={styles.header}>
        <h2>Формирование акта</h2>
        <div className={styles.subtitle}>К работе:</div>
        <div className={styles.work}>{item?.name}</div>
      </header>
      {actType === actTypesEnum.aosr && (
        <AosrCreateModal
          ticket={expenditure}
          isOpen={isOpen}
          onClose={onClose}
          sectionId={sectionId}
          type={type}
          groupId={groupId}
          year={year}
          month={month}
          onTypeSelect={setActType as any}
          isAosrExists={!!aosr}
        />
      )}
      {actType === actTypesEnum.aook && (
        <AookCreateForm
          type={type}
          onSelectActType={setActType as any}
          ticket={expenditure}
          groupId={groupId}
          sectionId={sectionId}
          isOpen={isOpen}
          onClose={onClose}
          isAosrExists={!!aosr}
        />
      )}
    </SliderModal>
  );
};

export default ActCreateModal;
