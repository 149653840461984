import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ksListDetailedKs2Selector } from "redux/modules/common/building/documents/ksList/selectors";
import { getDetailedKs2InDocs } from "redux/modules/common/building/documents/ksList/thunks";
import { IDetailedKs3InDocs } from "redux/modules/common/building/documents/ksList/types";
import { actingInvalidateKeySelector } from "redux/modules/common/building/journal/acting/selectors";
import { passportsInvalidateKeySelector } from "redux/modules/common/passportsAndCertificates/selectors";

import { Spinner } from "components/UI/Spinner/Spinner";
import { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import KS3PageSubRow from "./KS3PageSubRow";

import { LOCALIZATION_CONFIG } from "constants/localization";

import styles from "./KS3PageRowContent.module.scss";

interface IProps {
  ks2Id: number;
}

const KS3PageRowContent: React.FC<IProps> = ({ ks2Id }) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const ks2Data = useSelector(ksListDetailedKs2Selector)[ks2Id];
  const invalidateKey = useSelector(actingInvalidateKeySelector);
  const invalidateDocsKey = useSelector(passportsInvalidateKeySelector);

  React.useLayoutEffect(() => {
    dispatch(getDetailedKs2InDocs(ks2Id));
  }, [ks2Id, invalidateKey, objectId, invalidateDocsKey]);

  return (
    <>
      <div className={styles.thead}>
        <TableReusableHeaderCell>№</TableReusableHeaderCell>
        <TableReusableHeaderCell>Наименование расценки</TableReusableHeaderCell>
        <TableReusableHeaderCell isCentered>Кол-во</TableReusableHeaderCell>
        <TableReusableHeaderCell isRighted>Стоимость, {LOCALIZATION_CONFIG.currency} </TableReusableHeaderCell>
      </div>
      {ks2Data?.isLoading && <Spinner />}
      {ks2Data?.items?.map((item) =>
        item?.expenditures?.map((el, i) => <KS3PageSubRow isFirst={!i} expenditure={el} section={item} ks2Id={ks2Id} />)
      )}
    </>
  );
};

export default React.memo(KS3PageRowContent);
