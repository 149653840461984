export const LOAD_LIMIT = 10

export const TABS_IDS = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected'
}

export const TABS = [
  {
    id: TABS_IDS.ACCEPTED,
    title: 'Принятые'
  },
  {
    id: TABS_IDS.REJECTED,
    title: 'Отклонены'
  }
]
