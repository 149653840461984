import React, { useCallback } from "react";

import { LABELS } from "../../../../pages/Requisition/constants";
import BottomControls from "../../../organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import ButtonBase from "../../../atoms/ButtonBase";
import InputFiles from "../../../atoms/InputFiles";
import { FilesPermissionsType } from "../../../atoms/InputFiles/InputFiles";
import SliderModal from "../../../atoms/SliderModal";
import ConfirmationModal from "../../ConfirmationModal/ConfirmModal";
import moment from "moment/moment";

import { LOCALIZATION_CONFIG } from "../../../../../constants/localization";
import { IContract } from "../../../../../types/interfaces/Contract";

import { useConfirmationModal } from "../../../../../hooks/useConfirmationModal";
import { useProductFilesModal } from "../../../../../hooks/useProductFilesModal";

import { ReactComponent as CalendarBlue } from "../../../../../images/icons/calendarBlue.svg";

import styles from "./ContractsModal.module.scss";

export interface IContractsModalProps {
  contract: IContract | {};
  isOpen: boolean;
  onClose: () => void;
  onDeleteContract: () => void;
  onAddFile: () => void;
  onDeleteFile: () => void;
  filesPermissions: FilesPermissionsType;
  canDeleteContract: boolean;
  files: any;
}

const ContractsModal: React.FC<IContractsModalProps> = ({
  contract,
  isOpen,
  onClose,
  onDeleteContract,
  canDeleteContract,
  files: productFiles,
  onAddFile,
  onDeleteFile,
  filesPermissions,
}) => {
  const handleDeleteContract = useCallback(() => {
    onDeleteContract();
    onClose();
  }, [onDeleteContract, onClose]);

  const { openRemoveConfirmationModal, isOpenRemoveConfirmationModal, confirmRemove, closeRemoveConfirmationModal } =
    useConfirmationModal({ onRemove: handleDeleteContract });

  const { files, preparedFiles, downloadFiles, handleFilesChange, handleSubmit } = useProductFilesModal({
    productFiles,
    isOpen,
    uploadFilesCallback: onAddFile,
    deleteFilesCallback: onDeleteFile,
  });

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={onClose}>
        <div className={styles.headline}>
          <h2 className={styles.title}>
            {contract.title || (!!contract.contract_num && `Контракт №${contract.contract_num}`)}
          </h2>
          {!!contract.title && !!contract.contract_num && (
            <p className={styles.subtitle}>{`контракт №${contract.contract_num}`}</p>
          )}
        </div>
        <div className={styles.content}>
          {!!contract.building_name && (
            <div className={styles.info}>
              <span className={styles.medium}>Проект</span>
              <p className={styles.text}>{contract.building_name}</p>
            </div>
          )}
          {!!contract.entity_name && (
            <div className={styles.info}>
              <span className={styles.medium}>Контрагент</span>
              <p className={styles.text}>{contract.entity_name}</p>
            </div>
          )}
          {!!contract.contract_date && (
            <div className={styles.info}>
              <span className={styles.medium}>Дата</span>
              <p className={styles.text}>
                <span>{moment(contract.contract_date).format("DD/MM/YYYY")}</span>
                <CalendarBlue className={styles.calendar} />
              </p>
            </div>
          )}
          {!!contract.amount && (
            <div className={styles.info}>
              <span className={styles.medium}>Стоимость</span>
              <p className={styles.text}>{`${contract.amount} ${LOCALIZATION_CONFIG.currency}`}</p>
            </div>
          )}
          <div className={styles.files}>
            <div className={styles.filesHeader}>
              <span>
                {LABELS.FILES_INPUT.TITLE} {files?.length}
              </span>
              {!!files?.length && <button onClick={downloadFiles}>{LABELS.FILES_INPUT.DOWNLOAD}</button>}
            </div>
            <InputFiles value={preparedFiles as any} setValue={handleFilesChange} permissions={filesPermissions} />
          </div>
        </div>

        <BottomControls isExists isDoubleBtns>
          {canDeleteContract && (
            <ButtonBase secondary onClick={openRemoveConfirmationModal}>
              Удалить
            </ButtonBase>
          )}
          <ButtonBase primary onClick={handleSubmit}>
            Сохранить
          </ButtonBase>
        </BottomControls>
      </SliderModal>
      <ConfirmationModal
        variant="secondary"
        isOpen={isOpenRemoveConfirmationModal}
        action={confirmRemove}
        onClose={closeRemoveConfirmationModal}
      >
        Подтвердить удаление?
      </ConfirmationModal>
    </>
  );
};

export default React.memo(ContractsModal);
