import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import { auditActions } from "../../../../redux/modules/common/constructing/finance/audit/actions";
import { forecastActions } from "../../../../redux/modules/common/constructing/finance/forecast/actions";
import { summaryActions } from "../../../../redux/modules/common/constructing/finance/summary/actions";
import { setObjectId } from "redux/modules/common/constructing/finance/finance";

import ForbiddenPage from "../../../routes/components/ForbiddenPage/ForbiddenPage";
import TabBar from "components/UI/atoms/TabBar/TabBar";
import TemplateSimple from "components/UI/templates/TemplateSimple/TemplateSimple";

import Audit from "./Audit/Audit";
import Forecast from "./Forecast/Forecast";
import Summary from "./Summary/Summary";

import {
  VIEW_FINANCE_AUDIT,
  VIEW_FINANCE_FORECAST,
  VIEW_FINANCE_SUMMARY,
} from "../../../../constants/permissions/constructingPermissions";
import { ROUTES } from "../../../../constants/routes";

import usePermission from "../../../../hooks/usePermission";

import styles from "./Finance.module.scss";

const Finance = () => {
  const dispatch = useDispatch();
  const { objectId, projectId } = useParams();

  const haveViewFinanceAuditPermission = usePermission(VIEW_FINANCE_AUDIT);
  const haveViewFinanceSummaryPermission = usePermission(VIEW_FINANCE_SUMMARY);
  const haveViewFinanceForecastPermission = usePermission(VIEW_FINANCE_FORECAST);

  useEffect(() => {
    dispatch(setObjectId(objectId));
  }, [objectId]);

  useEffect(() => {
    return () => {
      dispatch(auditActions.clearData());
      dispatch(summaryActions.clearData());
      dispatch(forecastActions.clearData());
    };
  }, []);

  const tabsV2 = useMemo(
    () => [
      {
        text: "Аудит",
        link: `/constructing/finance/${projectId}/${objectId}/audit`,
        isHidden: !haveViewFinanceAuditPermission,
      },
      {
        text: "Сводная",
        link: `/constructing/finance/${projectId}/${objectId}/summary`,
        isHidden: !haveViewFinanceSummaryPermission,
      },
      {
        text: "Прогноз",
        link: `/constructing/finance/${projectId}/${objectId}/forecast`,
        isHidden: !haveViewFinanceForecastPermission,
      },
    ],
    [
      projectId,
      objectId,
      haveViewFinanceAuditPermission,
      haveViewFinanceSummaryPermission,
      haveViewFinanceForecastPermission,
    ]
  );

  const ableTab = tabsV2.filter((tab) => !tab.isHidden)?.[0]?.link;

  const [tabsOpen, setTabsOpen] = React.useState(false);

  const TabsComponent = () => {
    return (
      <div className={styles.tabs}>
        <TabBar
          className={styles.tabsContainer}
          tabs={tabsV2}
          isDirectlyOpen={tabsOpen}
          setIsDirectlyOpen={setTabsOpen}
        />
      </div>
    );
  };

  return (
    <TemplateSimple dataTestId="page_finances" contentClassName={styles.templateContent}>
      <div className={styles.finance}>
        <Switch>
          <Route
            path={ROUTES.CONSTRUCTING_FINANCE_AUDIT}
            render={() => (
              <Audit>
                <TabsComponent />
              </Audit>
            )}
          />
          <Route
            path={ROUTES.CONSTRUCTING_FINANCE_SUMMARY}
            render={() => (
              <Summary>
                <TabsComponent />
              </Summary>
            )}
          />
          {haveViewFinanceForecastPermission && (
            <Route
              path={ROUTES.CONSTRUCTING_FINANCE_FORECAST}
              render={() => (
                <Forecast>
                  <TabsComponent />
                </Forecast>
              )}
            />
          )}
          {!!ableTab && (
            <Route path={ROUTES.CONSTRUCTING_FINANCE}>
              <Redirect replace to={ableTab} />
            </Route>
          )}
          {!ableTab && <ForbiddenPage />}
        </Switch>
      </div>
    </TemplateSimple>
  );
};

export default Finance;
