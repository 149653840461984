import React, { ChangeEvent, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import {
  deleteArrow,
  updateRelationDayDelay,
} from "../../../../../../../../../redux/modules/common/building/manufacturing/thunks";

import InputBase from "../../../../../../../../UI/atoms/InputBase";
import TableReusableRow from "../../../../../../../../UI/atoms/TableReusable/TableReusableRow";
import ConfirmationModal from "../../../../../../../../UI/molecules/ConfirmationModal/ConfirmModal";

import {RELATION_TYPES, RelationType} from '../../../../../../constants'
import { MAX_RELATION_BREAK_VALUE, MIN_RELATION_BREAK_VALUE } from "../../constants";
import { IDisplayedRelation, RelationDirectionType, RelationsDirection } from "../../types";

import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";

import styles from "./DisplayedRelation.module.scss";

export interface IDisplayedRelationProps {
  relation: IDisplayedRelation;
  isEditMode?: boolean;
  projectId: number;
  type: RelationType;
  direction: RelationDirectionType;
}

const DisplayedRelation: React.FC<IDisplayedRelationProps> = ({ relation, isEditMode, projectId, type, direction }) => {
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [dynamicRelationBreak, setDynamicRelationBreak] = useState(relation.delay_day || "");

  const handleBreakInputChange = useCallback((e: ChangeEvent) => {
    setDynamicRelationBreak(e.target?.value);
  }, []);

  const breakSubmitHandler = useCallback(
    (e: Event | undefined) => {
      const currentBreakValue = Number(e?.target?.value || dynamicRelationBreak);
      if (
        !currentBreakValue ||
        currentBreakValue < MIN_RELATION_BREAK_VALUE ||
        currentBreakValue > MAX_RELATION_BREAK_VALUE
      ) {
        setDynamicRelationBreak("");
        return;
      }
      if (relation.delay_day && +relation.delay_day === currentBreakValue) return;
      dispatch(
        updateRelationDayDelay({
          relationId: relation.id,
          delayDay: currentBreakValue,
          direction,
          projectId,
        })
      );
    },
    [relation, direction, dynamicRelationBreak, projectId]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Enter" || e.keyCode === 13) {
        breakSubmitHandler(e);
      }
    },
    [breakSubmitHandler]
  );

  const handleOpenDeleteModal = useCallback(() => setIsOpenDeleteModal(true), []);

  const handleCloseDeleteModal = useCallback(() => setIsOpenDeleteModal(false), []);

  const handleDeleteRelation = useCallback(() => {
    if (!relation.id || !projectId) return;
    dispatch(deleteArrow({ projectId, arrowId: relation.id, direction }));
    handleCloseDeleteModal();
  }, [relation, projectId, direction]);

  const displayedExpenditureName =
    direction === RelationsDirection.to
      ? relation.from_expenditure_name ?? relation.from_group_name
      : relation.to_expenditure_name ?? relation.to_group_name;

  const displayedSectionName =
    direction === RelationsDirection.to ? relation.from_section_name : relation.to_section_name;

  return (
    <TableReusableRow className={styles.displayedRelation} containerClassName={styles.tableRow}>
      <span className={styles.displayedExpenditureName}>{displayedExpenditureName}</span>
      <div className={styles.displayedRelationBreak}>
        {isEditMode && type === RELATION_TYPES.oh ? (
          <InputBase
            value={dynamicRelationBreak}
            onChange={handleBreakInputChange}
            input={undefined}
            className={styles.breakInputContainer}
            classNameInput={styles.breakInput}
            label={undefined}
            onBlur={breakSubmitHandler}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <span className={styles.breakValue}>{relation.delay_day}</span>
        )}
      </div>
      <div className={styles.displayedRelationSection}>
        <span className={styles.relationSectionLabel}>Раздел:</span>
        <span>{displayedSectionName}</span>
      </div>
      {isEditMode && <DeleteIcon onClick={handleOpenDeleteModal} className={styles.deleteIcon} />}
      {isOpenDeleteModal && (
        <ConfirmationModal
          isOpen={isOpenDeleteModal}
          onClose={handleCloseDeleteModal}
          variant={"secondary"}
          acceptButtonText={"Подтвердить"}
          cancelButtonText={"Отменить"}
          action={handleDeleteRelation}
        >
          <span>Вы действительно хотите удалить связь?</span>
        </ConfirmationModal>
      )}
    </TableReusableRow>
  );
};

export default React.memo(DisplayedRelation);
