import React, { FC, useMemo } from "react";

import { IExpenditure } from "../../../../../../types/interfaces/Expenditure";

import ExpandableFileInput from "../../../../../UI/molecules/ExpandableFileInput/ExpandableFileInput";
import BottomControls from "../../../../../UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import NavBar from "../../../../../UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import WorkListItem from "../../../../../UI/organism/WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import WorkersList from "../../../../../UI/organism/WorkOrMaterialsModals/components/WorkersList/WorkersList";
import ProductIntervalCardContent from "../../../../../pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import ManufacturingModalContentFact from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModalContentFact";
import MaterialBlockInWork from "../../../../organism/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import ModalContainer from "../../../../organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import Expandable from "../../../../atoms/Expandable/Expandable";
import { Spinner } from "../../../../Spinner/Spinner";

import { useFactModalData } from "./useFactModalData";

import {
  manufacturingMaterialsStatusSwitcher,
  manufacturingModalStatusSwitcher
} from "../../../../../pages/Manufacturing/components/modals/ManufacturingModal/manufacturingModalStatusSwitcher";
import { splitThousands } from "../../../../../../utils/formatters/splitThousands";

import styles from "./FactModal.module.scss";
import {
  SimpleResourceBlock
} from "../../../../../../features/simpleResources/ui/SimpleResourceBlock/SimpleResourceBlock";
import { isNanChecker } from "../../../../../../utils/formatters/isNanChecker";
import { ResourceTypeEnum } from "../../../../../../features/simpleResources/types/simpleResources";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expidentureId: number;
  expidentureObj: IExpenditure;
  sectionName: string;
  date_start: string;
  date_end: string;
  objectId: number;
  canDeleteFiles: boolean;
  loadProduct: () => void;
  sectionId: number;
  defaultOpenedIntervalId?: string | null;
}

const FactModal: FC<IProps> = ({
  isOpen,
  onClose,
  expidentureId,
  expidentureObj,
  sectionName,
  date_start,
  date_end,
  objectId,
  canDeleteFiles,
  defaultOpenedIntervalId = null
}) => {
  const {
    fileRemoveHandler,
    fileUploadHandler,
    clearIntervalDataHandler,
    intervalClickHandler,
    backToIntervalsListHandler,
    list,
    intervalId,
    isLoading,
    data,
    files,
  } = useFactModalData({
    isOpen,
    buildingId: objectId,
    expenditureId: expidentureId,
    dateEnd: date_end,
    dateStart: date_start,
    defaultOpenedIntervalId
  });

  const closeHandler = () => {
    onClose();
    clearIntervalDataHandler();
    setIsAddingNew(false);
  };

  const [isAddingNew, setIsAddingNew] = React.useState(false);

  const status = useMemo(() => manufacturingModalStatusSwitcher({
    isPlan: false,
    status: data?.work_status,
  }), [data?.work_status])

  const simpleMaterials = useMemo(() => {
    return data?.simpleResources?.filter((r) => ![
      ResourceTypeEnum.transport,
      ResourceTypeEnum.machine
    ].includes(r.type)) ?? [];
  }, [data?.simpleResources]);

  const simpleMims = useMemo(() => {
    return data?.simpleResources?.filter((r) => [
      ResourceTypeEnum.transport,
      ResourceTypeEnum.machine
    ].includes(r.type)) ?? [];
  }, [data?.simpleResources]);

  const materialsCount = useMemo(() => {
    return isNanChecker(+data?.stock_using_materials?.length) + isNanChecker(simpleMaterials.length)
  }, [data?.stock_using_materials, simpleMaterials]);

  const mimsCount = useMemo(() => {
    return isNanChecker(+data?.ticket_services?.length) + isNanChecker(simpleMims.length)
  }, [data?.ticket_services, simpleMaterials]);


  if (isAddingNew) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={expidentureObj?.name}
        justification={data?.justification}
        statusColor={status.color}
        statusText={status.text}
      >
        {isAddingNew && (
          <NavBar isExist text="Вернуться к работе" onClick={() => setIsAddingNew(false)} className={styles.back} />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent
            objectId={objectId}
            handleClose={() => setIsAddingNew(false)}
            directlySection={data?.section_id ? +data.section_id : undefined}
            directlyWork={data?.expenditure_id}
            isOpen={isOpen}
          />
        )}
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={closeHandler}
      name={expidentureObj?.name}
      justification={data?.justification}
      statusColor={status.color}
      statusText={status.text}
    >
      {isLoading && <Spinner />}
      {!intervalId && (
        <div className={styles.list}>
          {Array.isArray(list) &&
            list.map((el) => (
              <WorkListItem
                key={el.id}
                name={el.expenditure_name}
                measure={el.measure}
                passAmount={el.completed_amount}
                passCount={el.count}
                passDate={el.created_at}
                passName={el.author}
                acceptAmount={el.accepted?.[0]?.amount}
                acceptCount={el.accepted?.[0]?.count}
                acceptDate={el.accepted?.[0]?.created_at}
                acceptName={el.accepted?.[0]?.approve_user}
                hideAccepted={!el.accepted?.length}
                onClick={() => intervalClickHandler(el.id)}
              />
            ))}
        </div>
      )}

      {intervalId && !isLoading && (
        <>
          <NavBar
            onClick={backToIntervalsListHandler}
            className={styles.back}
            isExist={list?.length > 1}
          />

          <ManufacturingModalContentFact
            dateStart={data?.start_at}
            dateEnd={data?.end_at}
            measure={data?.measure}
            passingAuthor={data?.author}
            passingDate={data?.created_at}
            passingCount={data?.count}
            investedAmount={data?.work_amount}
            acceptedAmount={data?.accepted?.[0]?.amount}
            acceptedAuthor={data?.accepted?.[0]?.approve_user}
            acceptedCount={data?.accepted?.[0]?.count}
            acceptedDate={data?.accepted?.[0]?.created_at}
            planCreator={data?.from_interval?.author}
            planCreatingDate={data?.from_interval?.created_at}
            planCount={data?.from_interval?.count}
            projectAmount={data?.from_interval?.work_amount}
            planApprover={data?.from_interval?.approved_user}
            planApprovingDate={data?.from_interval?.approved_at}
          />

          <div className={styles.info}>
            <p className={styles.subtitle}>Раздел:</p>
            <p className={styles.value}>{sectionName}</p>
          </div>

          <WorkersList workers={data?.workers} />

          {materialsCount ? (
            <Expandable
              title={`Материалы: ${materialsCount}`}
              className={styles.subtitle}
            >
              {simpleMaterials.map((el) => (
                <SimpleResourceBlock
                  canInteract={false}
                  name={el.name}
                  count={el.count}
                  measure={el.measure}
                />
              ))}
              {data?.stock_using_materials?.map((el, idx) => (
                <MaterialBlockInWork
                  key={el.id}
                  name={el.stock_using_material?.product_building?.name}
                  measure={el.stock_using_material?.product_building?.measure}
                  count={el.count}
                  amount={el.amount}
                  statusColor={manufacturingMaterialsStatusSwitcher(data, idx).color}
                  statusText={manufacturingMaterialsStatusSwitcher(data, idx).text}
                />
              ))}
            </Expandable>
          ) : null}

          {mimsCount ? (
            <Expandable
              title={`Машины и Механизмы: ${mimsCount}`}
              className={styles.subtitle}
            >
              {simpleMims.map((el) => (
                <SimpleResourceBlock
                  canInteract={false}
                  name={el.name}
                  count={el.count}
                  measure={el.measure}
                />
              ))}
              {data?.ticket_services?.map((el) => (
                <MaterialBlockInWork
                  key={el.id}
                  name={el.service?.name}
                  measure={el.service?.measure}
                  count={el.service?.estimate_expenditure?.estimate_count}
                  amount={el.service?.estimate_expenditure?.estimate_amount}
                />
              ))}
            </Expandable>
          ) : null}

          <ExpandableFileInput
            files={files || []}
            onRemoveFileDirectly={fileRemoveHandler}
            onUploadFileDirectly={fileUploadHandler}
            canUploadFiles={false}
            canDeleteFiles={canDeleteFiles}
          />

          <br />

          <BottomControls isExists={data?.work_status === "to_payed"}>
            <div className={styles.sum}>
              Итого к оплате:
              <strong>{splitThousands(data?.total_sum_amount)}</strong>
            </div>
          </BottomControls>

        </>
      )}
    </ModalContainer>
  );
}

export default React.memo(FactModal);
