import cn from "classnames";
import React, { useState } from "react";

import { IJournalFulFillmentGroup } from "redux/modules/common/building/journal/journalExecution/types";

import JournalExecFiles from "../../../JournalExecTicketV2/components/JournalExecFiles/JournalExecFiles";
import PopoverContent, { COUNTS_ENUM } from "./components/PopoverContent/PopoverContent";
import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";

import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

import { useApproveTicketMaterial } from "../../../../../hooks/useApproveTicketMaterial";

import { splitThousands } from "utils/formatters/splitThousands";

import Check from "images/icons/Check";
import CheckFillRound from "images/icons/CheckFillRound";

import styles from "./RelatedMaterial.module.scss";
import InputBase, { VALUE_TYPES } from "../../../../../../../../UI/atoms/InputBase";

interface IProps {
  materialData: IJournalFulFillmentGroup["materials"][0];
  objectId: string;
  expenditureId: number;
  isGroup?: boolean;
}

const RelatedMaterial: React.FC<IProps> = ({ materialData, objectId, expenditureId, isGroup }) => {
  const displayingPrice =
    materialData.confirmed_amount_type === "estimate"
      ? materialData.confirmed_amount_estimate
      : materialData.confirmed_amount_fact;

  const { toApproveCount, toApproveCountHandler, canApproveMaterial, handleApprove, handleGroupApprove } =
    useApproveTicketMaterial({
      expenditureId,
      materialData,
    });
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const isShowGrayCheckRound =
    (!displayingPrice && !materialData.confirmed_count && !isGroup) || (!materialData.is_confirmed && isGroup);

  const isShowBlueCheckRound =
    (!displayingPrice && materialData.confirmed_count && !isGroup) || (materialData.is_confirmed && isGroup);

  if (!materialData) return null;

  return (
    <div className={styles.container}>
      <div className={cn(styles.rowItem, styles.nameBlock)}>
        <div className={styles.name} title={materialData.name}>
          {materialData?.name}
        </div>
        <JournalExecFiles
          name={materialData?.name!}
          expenditureId={materialData?.expenditure_id}
          expenditureType={materialData?.expenditure_type}
          isExpired={materialData?.attachments?.has_expired!}
          count={materialData?.attachments?.count!}
        />
      </div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>{materialData.measure}</div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>{materialData.count}</div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>
        {Number(materialData.confirmed_count) ? (
          materialData.confirmed_count
        ) : canApproveMaterial ? (
          <InputBase
            name={``}
            classNameInput={cn(styles.input, {
              [styles.inputActive]: Number(toApproveCount)
            })}
            value={toApproveCount.toString()}
            onChange={toApproveCountHandler}
            valueType={VALUE_TYPES.NUMBER}
          />
        ) : (
          "0"
        )}
      </div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>
        {Number(materialData.confirmed_count) ? <>{materialData.confirmed_user?.name}</> : null}
        {!Number(materialData.confirmed_count) && Number(toApproveCount) ? (
          <div className={styles.approveButton} onClick={isGroup ? () => handleGroupApprove() : handleApprove}>
            <Check />
            Принять
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={cn(styles.rowItem, styles.price)}>
        {Number(displayingPrice) && !canApproveMaterial ? (
          <span className={styles.priceCentered}>{splitThousands(displayingPrice, false, false)}</span>
        ) : Number(materialData.confirmed_count) && canApproveMaterial ? (
          <PopoverOverlay
            isOpen={isOpenPopover}
            onInteraction={(event) => setIsOpenPopover(event)}
            content={
              <PopoverContent
                setIsOpenPopover={setIsOpenPopover}
                buildingId={+objectId}
                stockId={materialData.interval_id}
                isGroup={!!isGroup}
                amountType={materialData?.confirmed_amount_type as COUNTS_ENUM}
                factAmount={materialData?.confirmed_amount_fact}
                estimateAmount={materialData?.confirmed_amount_estimate}
              />
            }
            placement={"top"}
            popoverBorderColor={"primary"}
          >
            <span className={styles.priceCentered}>{splitThousands(displayingPrice, false, false)}</span>
          </PopoverOverlay>
        ) : (
          "-"
        )}
        {isShowGrayCheckRound && <CheckFillRound width="22" color={"#888888"} colorFill={"none"} />}
        {isShowBlueCheckRound && <CheckFillRound width="22" color={"#00C4FF"} colorFill={"none"} />}
      </div>
    </div>
  );
};

export default RelatedMaterial;
