import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { planFactVolumeActions } from "./actions";
import { planFactVolumeApi } from "./planFactVolumeApi";

import { PLAN_FACT_VOLUME_ALL_EXPANDED, PLAN_FACT_VOLUME_ITEMS_CONSTRAINT } from "../constants";
import { IPlanFactVolumeSection } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getPlanFactVolumeSections =
  (objectId: string, startAt: string, endAt: string, daysCount: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const sectionsKey = generateStorageKey({ buildingId: objectId, startAt, endAt });

    if (!getState().planFactVolume.sections[sectionsKey]?.length) {
      dispatch(planFactVolumeActions.setSectionsLoading(sectionsKey, true));
    }

    planFactVolumeApi.getSections(objectId, startAt, endAt).then(({ data }) => {
      const subSections = data.reduce<IPlanFactVolumeSection["subsections"]>((acc, cur) => {
        return [...acc, ...cur.subsections];
      }, []);

      dispatch(planFactVolumeActions.setSections(sectionsKey, data));

      const expReqs = subSections.map((el) =>
        planFactVolumeApi.getExpenditures(objectId, startAt, endAt, String(el.id), daysCount)
      );
      Promise.all(expReqs ?? [])
        .then((resp) => {
          resp.forEach(({ data }, i) => {
            const subSectionId = String(subSections[i].id);
            const key = generateStorageKey({ sectionId: subSectionId, startAt, endAt, daysCount });
            dispatch(planFactVolumeActions.setExpenditures(key, data));
          });
        })
        .finally(() => {
          dispatch(planFactVolumeActions.setSectionsLoading(sectionsKey, false));
        });
    });
  };

export const getPlanFactVolumesExpenditures =
  (objectId: string, startAt: string, endAt: string, sectionId: string, daysCount: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ sectionId, startAt, endAt, daysCount });
    if (!getState().planFactVolume.expenditures[sectionId]) {
      dispatch(planFactVolumeActions.setExpendituresLoading(key, true));
    }
    planFactVolumeApi
      .getExpenditures(objectId, startAt, endAt, sectionId, daysCount)
      .then(({ data }) => {
        dispatch(planFactVolumeActions.setExpenditures(key, data));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(planFactVolumeActions.setExpendituresLoading(key, false));
      });
  };

export const planFactVolumeAllExpand = (status: boolean) => (dispatch: Dispatch) => {
  localStorage.setItem(PLAN_FACT_VOLUME_ALL_EXPANDED, JSON.stringify(status));
  dispatch(planFactVolumeActions.setAllExpanded(status));
};
