import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";

import Icon from "components/UI/Icon/Icon";
import InputBase from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select";

import { tariffTypeArray } from "constants/constant";
import { NUMBER_PLACEHOLDER } from "constants/placeholders";

import useDebounce from "utils/hooks/useDebouncedValue";

import arrowCloseIcon from "images/icons/arrow-gray-close-icon.svg";
import arrowOpenIcon from "images/icons/arrow-gray-open-icon.svg";

import styles from "./SettingsHeadline.module.scss";

function SettingsHeadline({ data, onFormChange, onSetDirty }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [form, setForm] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  const debouncedForm = useDebounce(form, 200);

  useEffect(() => {
    if (isDirty) return;
    setForm(data);
  }, [data, isDirty]);

  useEffect(() => {
    if (!isDirty) return;
    onFormChange && onFormChange(debouncedForm);
  }, [debouncedForm, isDirty]);

  const changeHandler = (fieldName) => {
    return (evt) => {
      onSetDirty(true);
      setIsDirty(true);

      const val = evt.target.value;
      setForm((prev) => {
        return {
          ...prev,
          [fieldName]: val,
        };
      });
    };
  };

  return (
    <div className={styles.container}>
      {!isExpanded && (
        <h1 className={styles.title} onClick={() => setIsExpanded(true)}>
          {form?.brand_name}
          <Icon icon={arrowOpenIcon} />
        </h1>
      )}
      {isExpanded && (
        <div>
          <div className={styles.firstline}>
            <input className={styles.nameInput} value={form.brand_name} onChange={changeHandler("brand_name")} />
            <Icon icon={arrowCloseIcon} onClick={() => setIsExpanded(false)} />
          </div>
          <div className={styles.form}>
            <InputBase label="Контактное лицо" meta={{}} onChange={changeHandler("full_name")} value={form.full_name} />
            <InputBase label="Почта" meta={{}} onChange={changeHandler("email")} value={form.email} />
            <div>
              <label>Телефон</label>
              <InputMask
                mask="+7 (999) 999-99-99"
                placeholder={NUMBER_PLACEHOLDER}
                onChange={changeHandler("phone_number")}
                value={form.phone_number}
              />
            </div>
            <div>
              <Select
                label="Тип аккаунта"
                value={"pcr"}
                options={[{ name: "Закупщик", id: "pcr" }]}
                className={styles.select}
                disabled
              />
            </div>
            <div>
              <Select
                label="Тариф"
                value={data?.tariff ?? "STANDARD"}
                options={tariffTypeArray()}
                className={styles.select}
                disabled
              />
            </div>
            <InputBase label="Фактический адрес" meta={{}} onChange={changeHandler("address")} value={form.address} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingsHeadline;
