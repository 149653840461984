import cn from "classnames";
import moment from "moment";
import React from "react";

import InputFiles from "components/UI/atoms/InputFiles";
import { MAX_DISPLAYING_FILES_COUNT } from "components/UI/atoms/InputFiles/InputFiles";
import ModalDivider from "components/UI/atoms/ModalDivider/ModalDivider";

import { detailRemarkStatusSwitcher } from "../../constants/constants";
import { detailRemarkStatuses } from "../../types/types";
import { IFile } from "types/interfaces/Files";

import { ReactComponent as CalendarBlue } from "images/icons/calendarBlue.svg";

import styles from "./CurrentRemark.module.scss";

export interface IProps {
  dividerType: detailRemarkStatuses;
  createdAt: string;
  isShowResolveInfo?: boolean;
  resolveDate?: string;
  isWithResolveHours?: boolean;
  creatorLabel: string;
  creatorName: string;
  resolveLabel?: string;
  resolveName?: string;
  description?: string;
  regulations?: string;
  files?: IFile[];
}

const CurrentRemark: React.FC<IProps> = ({
  dividerType,
  createdAt,
  isShowResolveInfo,
  resolveDate,
  isWithResolveHours,
  creatorLabel,
  creatorName,
  resolveLabel,
  resolveName,
  description,
  regulations,
  files,
}) => {
  return (
    <>
      <ModalDivider typeSwitcher={detailRemarkStatusSwitcher} type={dividerType} />
      <div className={styles.wrapper}>
        <div className={styles.flexBetween}>
          <div className={styles.flexColumn}>
            <div className={cn(styles.flexBetween, styles.mb)}>
              <span className={styles.medium}>{moment(createdAt).format("DD/MM/YYYY")}</span>
              <CalendarBlue className={styles.calendar} />
              <span className={cn(styles.text, styles.minWidth)}>в {moment(createdAt).format("LT")}</span>
            </div>
            {isShowResolveInfo && (
              <div className={styles.flexBetween}>
                <span className={styles.medium}>{moment(resolveDate).format("DD/MM/YYYY")}</span>
                <CalendarBlue className={styles.calendar} />
                <span className={cn(styles.text, styles.minWidth)}>
                  {isWithResolveHours && `в ${moment(resolveDate).format("LT")}`}
                </span>
              </div>
            )}
          </div>
          <div className={cn(styles.flexColumn, styles.rightBlock)}>
            <div className={cn(styles.flexBetween, styles.mb)}>
              <span className={styles.medium}>{creatorLabel}</span>
              <span className={cn(styles.text, styles.name)} title={creatorName}>
                {creatorName}
              </span>
            </div>
            {isShowResolveInfo && (
              <div className={styles.flexBetween}>
                <span className={styles.medium}>{resolveLabel}</span>
                <span className={cn(styles.text, styles.name)} title={resolveName}>
                  {resolveName}
                </span>
              </div>
            )}
          </div>
        </div>
        {description && (
          <div className={styles.description}>
            <span className={styles.medium}>Описание</span>
            <p className={styles.text}>{description}</p>
          </div>
        )}
        {regulations && (
          <div className={styles.regulations}>
            <span className={styles.medium}>Нормативные документы</span>
            <p className={styles.text}>{regulations}</p>
          </div>
         )}
      </div>
      {files && files.length > 0 ? (
        <div className={cn(styles.wrapper, styles.files)}>
          <span className={styles.medium}>
            Список вложений: <span className={styles.bold}>{files.length}</span>
          </span>
          <InputFiles
            value={files}
            canExpand={files.length > MAX_DISPLAYING_FILES_COUNT}
            canExport
            permissions={{
              addFiles: false,
              deleteFiles: false,
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default CurrentRemark;
