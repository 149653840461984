import React from "react";

import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";

import { ReactComponent as VisibilityIcon } from "shared/assets/icons/VisibilityIcon.svg";

import styles from "./VisibilityMark.module.scss";

interface IProps {
  variant: "allHidden" | "allAvailable" | "partHidden";
  isExpenditure?: boolean;
}

const generateTooltipContent = (variant: string, isExpenditure: boolean) => {
  return (
    <span>
      {isExpenditure ? "Расценка" : variant === "partHidden" ? "Часть расценок" : "Все расценки"}
      <br />
      {`${
        isExpenditure
          ? variant === "allAvailable"
            ? "доступна"
            : "скрыта"
          : variant === "allAvailable"
          ? "доступны"
          : variant === "allHidden"
          ? "скрыты"
          : "доступна"
      } в производстве`}
    </span>
  );
};

const VisibilityMark = ({ variant = "allAvailable", isExpenditure = false }: IProps) => {
  return (
    <PopoverOverlay
      popoverBorderColor="primary"
      placement="top"
      openType="hover"
      popoverClassName={styles.tooltipText}
      className={styles.visibilityMark}
      content={generateTooltipContent(variant, isExpenditure)}
    >
      <div className={styles.circle} style={isExpenditure ? { marginLeft: "-1rem" } : {}}>
        <VisibilityIcon className={styles[variant]} />
      </div>
    </PopoverOverlay>
  );
};

export default React.memo(VisibilityMark);
