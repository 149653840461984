import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.aosr;
export const aosrActsSelector = createSelector(stateSelector, (state) => state.acts);
export const aosrWorksSelector = createSelector(stateSelector, (state) => state.worksBySections);
export const aosrInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const aosrCurrentDetailSelector = createSelector(stateSelector, (state) => state.currentAosrDetail);
export const aosrClosingPendingSelector = createSelector(stateSelector, (state) => state.isClosingPending);
export const aosrCreatingPendingSelector = createSelector(stateSelector, (state) => state.isCreatingPending);
