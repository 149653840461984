import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

interface Iprops {
  color: string;
  progress: number | string;
  percent?: string;
  plus?: string;
  displayingValue?: number | string;
  description?: string;
  isProgressStringHidden?: boolean;
  titleClassName?: string;
  descriptionClassName?: string;
  subtitle?: string;
  subtitleClassName?: string;
}

const CircleComponents = ({
  color,
  progress,
  percent,
  plus,
  displayingValue,
  description,
  isProgressStringHidden,
  titleClassName,
  descriptionClassName,
  subtitle,
  subtitleClassName,
}: Iprops) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef<SVGCircleElement>(null);

  const center = 50 / 2;
  const radius = 50 / 2 - 5 / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    if (progress === "-" || isNaN(Number(progress))) {
      setOffset(circumference);
    } else {
      const progressOffset = ((100 - Number(progress)) / 100) * circumference;
      setOffset(progressOffset);
    }
  }, [setOffset, progress, circumference, offset]);

  return (
    <div className={styles.container}>
      <div className={styles.container_svg}>
        <svg className={styles.svg} width={50} height={50}>
          <circle
            className={styles.svg_circle_bg}
            stroke={"#F5F5F5"}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={5}
          />
          <circle
            className={styles.svg_circle}
            ref={circleRef}
            stroke={color}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={5}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
          />
        </svg>
      </div>
      <div className={styles.container_title}>
        {!isProgressStringHidden && (
          <div className={cn(styles.progress, titleClassName)}>
            {`${progress}${percent || ""}`}
            <span className={styles.title}>{displayingValue}</span>
          </div>
        )}
        {isProgressStringHidden && (
          <div className={cn(styles.progress, titleClassName)}>
            {displayingValue}
          </div>
        )}
        {plus && <div className={styles.plus}>+ {plus}</div>}
        <div className={cn(styles.text, descriptionClassName)}>
          {description}
          {subtitle && <div className={subtitleClassName}>{subtitle}</div>}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CircleComponents);
