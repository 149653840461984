import cn from "classnames";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";

import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import { IBillInList } from "types/interfaces/Bill";

import { useCheckIsProductionModule } from "../../hooks/useCheckIsProductionModule";
import { useUrlModule } from "utils/hooks/useUrlModule";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./BillRow.module.scss";

interface Iprops {
  bill: IBillInList;
}

const BillRow: React.FC<Iprops> = ({ bill }) => {
  const history = useHistory();
  const urlAdapter = useUrlModuleWithObjectId();

  const rowClickHandler = () => {
    history.push(`/${urlAdapter}/documents/bills/${bill.id}`);
  };

  const isInProduction = useCheckIsProductionModule();

  return (
    <TableReusableRow className={cn(styles.row, { [styles.inProduction]: isInProduction })} onClick={rowClickHandler}>
      <TableReusableCell>
        {bill.number ? sliceTextByConstraint(String(bill.number), 8) : "Формируется"}
      </TableReusableCell>
      <TableReusableCell>
        <NavLinkIcon
          to={`/${urlAdapter}/orders/${bill.order.id}`}
          title={sliceTextByConstraint(String(bill.order.number), 8)}
          className={styles.orderLink}
        />
      </TableReusableCell>
      {!isInProduction && <TableReusableCell isNoBreak>{bill.building.name}</TableReusableCell>}
      <TableReusableCell isNoBreak>{bill.provider.name}</TableReusableCell>
      <TableReusableCell isCentered>
        {bill.created_at ? moment(bill.created_at).format("DD.MM.YYYY") : "---"}
      </TableReusableCell>
      <TableReusableCell isRighted>{transformDigitToFinancial(bill.amount_total)}</TableReusableCell>
      <TableReusableCell>{bill.status_name}</TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(BillRow);
