import cn from "classnames";
import { memoize } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { detailDataSelector } from "redux/modules/common/building/object/nowObject";

import ApproveModal from "./components/ApproveModal/ApproveModal";
import HandlerIndicatorItem from "./components/HandlerIndicatorItem/HandlerIndicatorItem";
import HandlerStateCount from "./components/HandlerStateCount/HandlerStateCount";
import LoadDraftIndicator from "./components/LoadDraftIndicator";
import ProductionIndicator from "./components/ProductionIndicator/ProductionIndicator";

import { useDrop } from "react-dnd";

import { AGGERATIONS_TRANSFORM_OPTIONS, ESTIMATE_STATES_IDS } from "../../constants";

import { useAggregations } from "../../hooks/useAggregations";

import { getExpenditureAmount } from "../../utils/getExpenditureAmount";
import { getSectionAmount } from "../../utils/getSectionAmount";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { stringifyArgs } from "utils/helpers/stringifyArgs";

import styles from "./EstimateStatesIndicators.module.scss";

const EstimateStatesIndicators = ({
  activeEstimateStateId,
  setActiveEstimateStateId,
  changeEstimateItemsState,
  isShared,
  permissions,
  isHideProductionIndicatorInfo,
  isOpen,
  handleOpening,
}) => {
  const building = useSelector(detailDataSelector);
  const isBuildingShared = building && building.is_shared;

  const { aggregations, locale, object, consolidate } = useAggregations({
    transformOptions: AGGERATIONS_TRANSFORM_OPTIONS,
  });

  const [dropTarget, setDropTarget] = useState(false);

  const [{ canDropLocale, didDrop, droppedItem }, dropLocale] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: ESTIMATE_STATES_IDS.LOCALE }),
    collect: (monitor) => {
      const itemState = monitor.getItem()?.current_state;
      const canDrop = itemState === "";

      return {
        didDrop: monitor.getDropResult(),
        canDropLocale: canDrop,
        droppedItem: monitor.getItem(),
      };
    },
  }));

  const [{ canDropObject, didDropObject, droppedItemObject }, dropObject] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: ESTIMATE_STATES_IDS.OBJECT }),
    collect: (monitor) => {
      const itemState = monitor.getItem()?.current_state;
      const canDrop = ["", ESTIMATE_STATES_IDS.LOCALE].indexOf(itemState) !== -1;

      return {
        canDropObject: canDrop,
        didDropObject: monitor.getDropResult(),
        droppedItemObject: monitor.getItem(),
      };
    },
  }));

  const [{ canDropConsolidate, didDropConsolidate, droppedItemConsolidate }, dropConsolidate] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: ESTIMATE_STATES_IDS.CONSOLIDATE }),
    collect: (monitor) => {
      const itemState = monitor.getItem()?.current_state;
      const canDrop = ["", ESTIMATE_STATES_IDS.LOCALE, ESTIMATE_STATES_IDS.OBJECT].indexOf(itemState) !== -1;

      return {
        canDropConsolidate: canDrop,
        didDropConsolidate: monitor.getDropResult(),
        droppedItemConsolidate: monitor.getItem(),
      };
    },
  }));

  const onApproveTransfer = useCallback(
    (isApprove, targetStateId, chapter) => {
      setDropTarget(null);

      if (isApprove && dropTarget && dropTarget.item) {
        changeEstimateItemsState(
          targetStateId,
          dropTarget.item.map((item) => item.id),
          chapter
        );
      }
    },
    [changeEstimateItemsState, dropTarget]
  );

  const isObjectIndicatorDropTarget = dropTarget?.id === ESTIMATE_STATES_IDS.OBJECT;
  const isLocaleIndicatorIsDropTarget = dropTarget?.id === ESTIMATE_STATES_IDS.LOCALE;
  const isConsolidateIndicatorDropTarget = dropTarget?.id === ESTIMATE_STATES_IDS.CONSOLIDATE;

  const totalDropItemsAmount = useMemo(() => {
    if (!dropTarget) return 0;
    const totalDropItemsAmount = dropTarget.item.reduce((acc, estimateItem) => {
      if (estimateItem.expenditure_type) return acc + +getExpenditureAmount(estimateItem);
      return acc + +getSectionAmount(estimateItem, activeEstimateStateId);
    }, 0);
    return transformDigitToFinancial(totalDropItemsAmount, AGGERATIONS_TRANSFORM_OPTIONS);
  }, [dropTarget?.item]);

  const handleOnClickByIndicator = useMemo(
    () => memoize((indicatorStateId) => () => setActiveEstimateStateId(indicatorStateId), stringifyArgs),
    [setActiveEstimateStateId]
  );

  useEffect(() => {
    if (dropTarget) return;

    if (didDrop?.name === ESTIMATE_STATES_IDS.LOCALE && droppedItem) {
      setDropTarget({ id: ESTIMATE_STATES_IDS.LOCALE, item: droppedItem });
    }

    if (didDropObject?.name === ESTIMATE_STATES_IDS.OBJECT && droppedItemObject) {
      setDropTarget({ id: ESTIMATE_STATES_IDS.OBJECT, item: droppedItemObject });
    }

    if (didDropConsolidate?.name === ESTIMATE_STATES_IDS.CONSOLIDATE && droppedItemConsolidate) {
      setDropTarget({ id: ESTIMATE_STATES_IDS.CONSOLIDATE, item: droppedItemConsolidate });
    }
  }, [
    dropTarget,
    droppedItem,
    droppedItemObject,
    droppedItemConsolidate,
    didDropConsolidate?.name,
    didDropObject?.name,
    didDrop?.name,
  ]);

  const handleProductionClick = (id) => {
    if (isHideProductionIndicatorInfo) return;

    setActiveEstimateStateId(id);
  };

  return (
    <div className={cn(styles.outer, { [styles.outerOpened]: isOpen })}>
      <div className={cn(styles.trigger, { [styles.triggerOpened]: isOpen })} onClick={handleOpening}>
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.13278 0L0.591797 4.5L6.13278 9" fill="white" />
        </svg>
      </div>
      {isOpen && (
        <div className={styles.container}>
          {!isShared && permissions.viewDraftHandlerState && (
            <LoadDraftIndicator
              onClick={setActiveEstimateStateId}
              isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT}
              drafterCount={aggregations.drafter_count}
            />
          )}
          {permissions.viewLocaleHandlerState && (
            <HandlerIndicatorItem
              isDropTarget={isLocaleIndicatorIsDropTarget}
              isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE}
            >
              <div
                ref={!isBuildingShared ? dropLocale : null}
                className={cn(styles.item, { [styles.canDrop1]: canDropLocale })}
                onClick={handleOnClickByIndicator(ESTIMATE_STATES_IDS.LOCALE)}
              >
                <div
                  className={cn(styles.circle, {
                    [styles.active]: activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE,
                  })}
                >
                  <HandlerStateCount
                    count={aggregations.locale_count}
                    amount={locale}
                    plus={isLocaleIndicatorIsDropTarget && totalDropItemsAmount}
                    title={"Локальный сметный расчет"}
                  />
                </div>
                <ApproveModal
                  activeEstimateStateId={activeEstimateStateId}
                  targetEstimateStateId={ESTIMATE_STATES_IDS.LOCALE}
                  isOpen={isLocaleIndicatorIsDropTarget}
                  onApprove={onApproveTransfer}
                  items={dropTarget?.item}
                />
                {activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE && <span className={styles.border} />}
              </div>
            </HandlerIndicatorItem>
          )}
          {permissions.viewObjectHandlerState && (
            <HandlerIndicatorItem
              isDropTarget={isObjectIndicatorDropTarget}
              isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.OBJECT}
            >
              <div
                ref={!isBuildingShared ? dropObject : null}
                className={cn(styles.item, { [styles.canDrop2]: canDropObject })}
                onClick={handleOnClickByIndicator(ESTIMATE_STATES_IDS.OBJECT)}
              >
                <div
                  className={cn(styles.circle, {
                    [styles.active]: activeEstimateStateId === ESTIMATE_STATES_IDS.OBJECT,
                  })}
                >
                  <HandlerStateCount
                    count={aggregations.object_count}
                    amount={object}
                    plus={isObjectIndicatorDropTarget && totalDropItemsAmount}
                    title={"Объектный сметный расчет"}
                  />
                </div>
                <ApproveModal
                  activeEstimateStateId={activeEstimateStateId}
                  targetEstimateStateId={ESTIMATE_STATES_IDS.OBJECT}
                  isOpen={isObjectIndicatorDropTarget}
                  onApprove={onApproveTransfer}
                  items={dropTarget?.item}
                />
                {activeEstimateStateId === ESTIMATE_STATES_IDS.OBJECT && <span className={styles.border} />}
              </div>
            </HandlerIndicatorItem>
          )}
          {permissions.viewConsolidateHandlerState && (
            <HandlerIndicatorItem
              color="#8FE52B"
              isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE}
              isDropTarget={isConsolidateIndicatorDropTarget}
            >
              <div
                ref={!isBuildingShared ? dropConsolidate : null}
                className={cn(styles.item, { [styles.canDrop3]: canDropConsolidate })}
                onClick={handleOnClickByIndicator(ESTIMATE_STATES_IDS.CONSOLIDATE)}
              >
                <div
                  className={cn(styles.circle, {
                    [styles.active]: activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE,
                  })}
                >
                  <HandlerStateCount
                    count={aggregations.consolidate_count}
                    amount={consolidate}
                    plus={isConsolidateIndicatorDropTarget && totalDropItemsAmount}
                    title={"Сводный сметный расчет"}
                  />
                </div>
                <ApproveModal
                  targetEstimateStateId={ESTIMATE_STATES_IDS.CONSOLIDATE}
                  activeEstimateStateId={activeEstimateStateId}
                  isOpen={isConsolidateIndicatorDropTarget}
                  items={dropTarget?.item}
                  onApprove={onApproveTransfer}
                />
                {activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE && <span className={styles.border} />}
              </div>
            </HandlerIndicatorItem>
          )}
          {permissions.viewProductionHandlerState && (
            <ProductionIndicator
              sumEstimateAmount={aggregations.sum_estimate_amount}
              activeEstimateStateId={activeEstimateStateId}
              changeEstimateItemsState={changeEstimateItemsState}
              onClick={handleProductionClick}
              count={aggregations.confirmed_count}
              isHideInfo={isHideProductionIndicatorInfo}
              isShared={isBuildingShared}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(EstimateStatesIndicators);
