import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getNotificationsToModify,
  notificationsToModifySelector,
  unsubscribeNotification,
} from "../../../../../../../redux/modules/common/notifications";

import { ChangeNotificationConfigFnType, INotificationsToModify } from "../types/types";

export const useManageNotifications = () => {
  const dispatch = useDispatch();

  const canEditNotifications = true;
  const notificationsToModify: INotificationsToModify = useSelector(notificationsToModifySelector);

  const [isManageNotificationsModalOpen, setIsManageNotificationsModalOpen] = useState(false);
  const openManageNotificationsModal = useCallback(() => setIsManageNotificationsModalOpen(true), []);
  const closeManageNotificationsModal = useCallback(() => setIsManageNotificationsModalOpen(false), []);

  useEffect(() => {
    if (!isManageNotificationsModalOpen) return;
    dispatch(getNotificationsToModify());
  }, [isManageNotificationsModalOpen]);

  const onChangeCheckedNotifications = useCallback<ChangeNotificationConfigFnType>(
    async (option, type, isChecked) => {
      if (option?.class_name === "NotificationChatMention") {
        const chatV2MentionOption = Object.assign({}, option);

        chatV2MentionOption.class_name = "NotificationChatMentionV2";

        await dispatch(unsubscribeNotification(notificationsToModify, chatV2MentionOption, type, isChecked));
      }
      dispatch(unsubscribeNotification(notificationsToModify, option, type, isChecked));
    },
    [notificationsToModify]
  );

  return {
    canEditNotifications,
    notificationsToModify,
    isManageNotificationsModalOpen,
    openManageNotificationsModal,
    closeManageNotificationsModal,
    onChangeCheckedNotifications,
  };
};
