import React from "react";

import { ReactComponent as CloseIcon } from "images/icons/close-icon-gray.svg";

export interface IProps {
  onClick: any;
  isWithHeader?: boolean;
}

export const DeleteButton: React.FC<IProps> = React.memo(({ onClick, isWithHeader }) => {
  return (
    <button
      type="button"
      onClick={(onClick || null)}
      className={`btn-delete ${isWithHeader ? "table-button_header" : ""}`}
    >
      <CloseIcon />
    </button>
  );
});
