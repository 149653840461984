import React, { useRef } from "react";

import CreateOrEditRemarkForm from "../components/CreateOrEditRemark/CreateOrEditRemarkForm";
import Header from "../components/Header/Header";
import SliderModal from "components/UI/atoms/SliderModal";

import { CREATE_REMARK_INITIALS } from "../constants/constants";

import { useCreateRemarkModal } from "../hooks/useCreateRemarkModal";

import styles from "./CreateRemarkModal.module.scss";

export interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  workName: string;
  expenditureId: number;
  isGroup?: boolean;
  isDelivery?: boolean;
  isShared?: boolean;
  intervalId: number;
}

const CreateRemarkModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  workName,
  expenditureId,
  isGroup,
  isDelivery,
  isShared,
  intervalId,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const {
    files,
    setFiles,
    isResetFiles,
    onSubmit,
    selectedWorker,
    closeModal,
    handleOpenTable,
    handleCloseTable,
    handleRemoveSelected,
    handleSelectWorker,
    isLoading,
    isTableOpen,
  } = useCreateRemarkModal({ formRef, isOpen, setIsOpen, expenditureId, isGroup, isDelivery: !!isDelivery, isShared });

  return (
    <SliderModal className={styles.modal} isOpen={isOpen} closeHandler={closeModal}>
      <Header title="Новое замечание" workName={workName} />
      <CreateOrEditRemarkForm
        ref={formRef}
        onSubmit={onSubmit}
        initialValues={CREATE_REMARK_INITIALS}
        handleOpenTable={handleOpenTable}
        handleCloseTable={handleCloseTable}
        selectedWorker={selectedWorker}
        handleRemoveSelected={handleRemoveSelected}
        handleSelectWorker={handleSelectWorker}
        files={files}
        setFiles={setFiles}
        isResetFiles={isResetFiles}
        isLoading={isLoading}
        isTableOpen={isTableOpen}
        isShared={isShared}
        intervalId={intervalId}
      />
    </SliderModal>
  );
};

export default React.memo(CreateRemarkModal);
