import { IActions } from "../../../store";

import { MODULES_ENUM } from "../../../../types/enums/ModulesEnum";
import { IUIIndicatorResponse } from "./types";

export const uiIndicatorsActions = {
  setIndicators: (indicatorsList: IUIIndicatorResponse[], module: MODULES_ENUM, count: number, buildingId?: number) =>
    ({
      type: "uiIndicators/SET_INDICATORS",
      payload: { indicators: indicatorsList, module, buildingId, count },
    } as const),
  setIndicatorsLoading: (isLoading: boolean) =>
    ({
      type: "uiIndicators/SET_INDICATORS_LOADING",
      payload: isLoading,
    } as const),
  deleteIndicator: (indicatorId: number, modules: MODULES_ENUM[], buildingId?: number) =>
    ({
      type: "uiIndicators/DELETE_INDICATOR",
      payload: { indicatorId, modules, buildingId },
    } as const),
  pushIndicator: (indicator: IUIIndicatorResponse) =>
    ({
      type: "uiIndicators/PUSH_INDICATOR",
      payload: indicator,
    } as const),
  invalidateKey: () =>
    ({
      type: "uiIndicators/INVALIDATE_KEY",
      payload: Math.random(),
    } as const),
};

export type IUiIndicatorsAction = IActions<typeof uiIndicatorsActions>;
