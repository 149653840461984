import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DEFAULT_LIMIT_EXPORT,
  deleteExport,
  exportsIsLoadingSelector,
  exportsSelector,
  loadExports,
  loadMoreExport,
} from "redux/modules/common/building/documents/exports";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import ButtonBase from "../../../UI/atoms/ButtonBase";
import EmptyPlaceholder from "../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ConfirmationModal from "../../../UI/molecules/ConfirmationModal/ConfirmModal";
import { Spinner } from "components/UI/Spinner/Spinner";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import { exportSvg } from "../MakeExport/exportSvg";
import ExportRow from "./ExportRow/ExportRow";
import ExportTableHead from "./ExportTableHead/ExportTableHead";

import { IIdAndName } from "types/idAndName";
import { IExport } from "types/interfaces/Export";

import { useCheckIsProductionModule } from "../hooks/useCheckIsProductionModule";
import { useObjectId } from "../hooks/useObjectId";

import { hasOnlyPaginationFilters } from "../../../../utils/hasOnlyPaginationFilters";

interface Iprops {
  onClickExportBtn: () => void;
  canDeleteExport: boolean;
}

const Export: React.FC<Iprops> = ({ onClickExportBtn, canDeleteExport }) => {
  const dispatch = useDispatch();
  const exports = useSelector(exportsSelector);
  const isLoading = useSelector(exportsIsLoadingSelector);

  const isInProduction = useCheckIsProductionModule();
  const objectId = useObjectId();
  const [filters, setFilters] = useState({
    limit: DEFAULT_LIMIT_EXPORT,
    offset: 0,
    building_id: isInProduction ? objectId : null,
  });

  const isWithFilters = useMemo(
    () => !hasOnlyPaginationFilters(filters, isInProduction ? ["building_id"] : []),
    [filters, isInProduction]
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [exportsFileId, setExportsFileId] = useState<null | number>(null);

  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  const formattedObjects = React.useMemo(() => {
    return breadcrumbsProjects.results.map((el: IIdAndName) => ({ id: el.id, name: el.id, label: el.name }));
  }, [breadcrumbsProjects]);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteHandler = (id: number) => {
    dispatch(deleteExport(id));
    closeModal();
  };
  const openDeleteModalHandler = (id: number) => {
    setExportsFileId(id);
    setIsDeleteModalOpen(true);
  };
  const hasNextPage = useMemo(() => exports?.count > exports?.results?.length, [exports]);
  const loadMore = () => {
    dispatch(
      loadMoreExport({
        ...filters,
        offset: exports?.results?.length,
      })
    );
  };

  useEffect(() => {
    dispatch(loadExports(false, filters));
  }, [filters]);

  const isEmpty = !isLoading && !exports?.results.length;

  return (
    <>
      <ConfirmationModal
        variant="secondary"
        action={() => deleteHandler(exportsFileId as number)}
        isOpen={isDeleteModalOpen}
        onClose={closeModal}
        title="Подтвердите действие"
        acceptButtonText="Удалить"
      >
        Вы действительно хотите удалить выгрузку?
      </ConfirmationModal>
      {((isEmpty && isWithFilters) || !isEmpty) && (
        <ExportTableHead
          onChangeFilter={changeFilters}
          formattedObjects={formattedObjects}
          onReload={() => dispatch(loadExports(false, filters))}
        />
      )}
      {exports?.results.map((el: IExport, i: number) => (
        <ExportRow
          key={el.id}
          exportDoc={el}
          idx={i}
          canDeleteExport={canDeleteExport}
          onDelete={openDeleteModalHandler}
        />
      ))}
      {isEmpty && (
        <EmptyPlaceholder text="Нет выгрузок" svgNative={exportSvg}>
          <ButtonBase primary onClick={onClickExportBtn}>
            Создать выгрузку
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {isLoading && !exports?.results?.length && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage}
        handleAdd={loadMore}
        showedCount={exports?.results?.length}
        allCount={exports?.count}
      />
    </>
  );
};

export default React.memo(Export);
