import axios from "axios";

import { IProjectTreeResponse } from "../../building/manufacturing/types";
import { IAnalyticsProjectDatesData } from "./types";
import { IAnalyticsBudgetData } from "./types/budget";
import {
  AnalyticsBudgetResourceType,
  IAnalyticsBudgetResource,
  IAnalyticsBudgetResourceData,
} from "./types/budgetResource";

export interface IAnalyticsParams {
  building_id?: number;
  section_id?: number;
  all?: 1;
  start_at?: string;
  end_at?: string;
}

export interface IAnalyticsEventsParams extends IAnalyticsParams {
  overdue?: 1;
  event_types?: ("payment" | "supply" | "task" | "order")[];
}

export interface IAnalyticsBudgetResourceParams extends IAnalyticsParams {
  resource_type?: AnalyticsBudgetResourceType;
}

export const analyticsApi = {
  loadTree: (objectId: number | string) => axios.get<IProjectTreeResponse>(`/tree/buildings/${objectId}/lite/`),
  loadBudgetResource: (
    resourceType: AnalyticsBudgetResourceType,
    params: IAnalyticsBudgetResourceParams,
    signal: AbortSignal
  ) => axios.get<IAnalyticsBudgetResourceData>(`/analytics/budget_resource/`, { params, signal }),
  loadBudget: (params: any) => axios.get<IAnalyticsBudgetData[]>(`/analytics/budget/`, { params }),
  loadProjectDates: (params: { building_id: number; section_id?: number }) =>
    axios.get<IAnalyticsProjectDatesData>(`/analytics/all_project/`, { params: { ...params, all: 1 } }),
  loadProgress: (params: IAnalyticsParams, signal: AbortSignal) =>
    axios.get(`/analytics/fulfillment_progress/`, { params, signal }),
  loadFulfillment: (params: IAnalyticsParams, signal: AbortSignal) =>
    axios.get(`/analytics/fulfillment/`, { params, signal }),
  loadEvents: (params: IAnalyticsEventsParams, signal: AbortSignal) =>
    axios.get(`/analytics/events/`, { params, signal }),
};
