import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { compose } from "redux";

import {
  clearObjectsLegacy,
  loadObjects,
  loadSettingsProjects,
  objectsSelector,
  settingsProjectsLoadingSelector,
  settingsProjectsSelector,
} from "redux/modules/common/building/projects";

import Person from "../../../../UI/molecules/Person/Person";
import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import ContactsModal from "components/UI/atoms/ContactsModal/ContactsModal";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import SearchFilterTable from "components/UI/atoms/SearchFilterTable";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import settingGear from "images/icons/navigation/settingGear.svg";

import styles from "./ProjectsTable.module.scss";

let offset = 0;

function ProjectsTable({ path }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const objectsLoading = useSelector(settingsProjectsLoadingSelector);
  const { projectId } = useParams();
  const objects = useSelector(settingsProjectsSelector)[projectId];
  const rootPath = useUrlModuleWithObjectId();
  const [personal, setPersonal] = useState(null);
  const [filter, setFilter] = useState({});

  const filterHandler = (val, name) => setFilter((prev) => ({ ...prev, [name]: val }));

  const resetPersonal = useCallback(() => setPersonal(null), []);

  useEffect(() => {
    offset = 0;
    dispatch(loadSettingsProjects({ offset, ...filter, project: projectId ?? "", limit: 500 }));
  }, [filter, projectId]);

  return (
    <>
      <div className={styles.wrapper}>
        <TableOslaLocal
          data={objects?.results ?? []}
          pagination={false}
          tableClass={styles.table}
          headerClass={styles.table__header}
          rowClass={styles.table__row}
          isLoading={objectsLoading && !objects?.results.length}
          isFixedLoader
          onRowClick={(row) => history.push(`/${rootPath}/settings/object/${projectId}/${row.id}/passport`)}
          tableStyle={{ tableLayout: "fixed" }}
          emptyPlaceholder={<EmptyPlaceholder text="Нет проектов" img={settingGear} />}
          paginationHide
        >
          <TableHeader data="name" cellStyle={{ width: "35%" }}>
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Наименование проекта</div>
              <SearchFilterTable
                title="Наименование компании"
                setFilter={(value) => {
                  filterHandler(value, "name");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="entity_name"
            cellStyle={{
              width: "20%",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Организация</div>
              <SearchFilterTable
                title="Организация"
                setFilter={(value) => {
                  filterHandler(value, "entity_name");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="address"
            cellStyle={{
              width: "24%",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Адрес</div>
            </div>
          </TableHeader>
          <TableHeader
            data="responsible_employee"
            cellStyle={{
              width: "18%",
            }}
            formatter={(cell) => (
              <div onClick={compose(() => setPersonal(cell), stopEventPropagation)}>
                <Person cell={cell} />
              </div>
            )}
          >
            <div className={styles.table__titleBlock}>
              <div>Ответственный</div>
            </div>
          </TableHeader>
        </TableOslaLocal>
      </div>
      {false && personal && (
        <ContactsModal isOpen title="Контакты ответственного" contacts={personal} onClose={resetPersonal} />
      )}
    </>
  );
}

export default React.memo(ProjectsTable);
