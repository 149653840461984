import React from "react";

import { chatRoomTypes } from "redux/modules/common/chatV2/types";

import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";

import { CHAT_TYPES_ENUM, TEMPLATE_VARIANTS_ENUM } from "../../types/enums";
import { INotification } from "../../types/types";

import {
  useChatLink,
  useChatV2Link,
  useExportLink,
  useOpenFactApproveLink,
  useOrderLink,
  usePackingListLink,
  usePartnerInviteLink,
  usePaymentLink,
  usePlanApproveLink,
  useRemarkLink,
  useRequisitionLink,
  useShiftLink,
  useStateLink,
  useTasksTrackLink,
  useTimeSheetLink
} from "./hooks/useLinkForNotificationMessages";

import { findNotificationChatType } from "./utils/findNotificationChatType";
import { findNotificationStateType } from "./utils/findNotificationStateType";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./FormatNotificationMessage.module.scss";
import { useSelector } from "react-redux";
import { objectsBreadcrumbsSelector } from "../../../../../../../../redux/modules/common/building/objects";
import { IIdAndName } from "../../../../../../../../types/idAndName";

const FormatNotificationMessage = (notification: INotification) => {
  const objects = useSelector(objectsBreadcrumbsSelector);
  const chatType = findNotificationChatType(notification.data?.doc_type);
  const estimateType = findNotificationStateType(notification.data?.state);

  const orderLink = useOrderLink(
    notification.data?.order_id || notification.data?.order?.id || notification.data?.doc?.id,
    notification.data?.building?.id || notification.data?.building_id
  );
  const planApproveLink = usePlanApproveLink({
    building_id: notification?.data?.building_id,
    plan_type: notification?.data?.plan_type,
    plan_id: notification?.data?.plan_id,
    parent_section_id: notification?.data?.parent_section_id,
    child_section_id: notification?.data?.section_id,
    expenditure_id: notification?.data?.expenditure_id,
    group_id: notification?.data?.group_id,
  });

  const timeSheetLink = useTimeSheetLink(notification.data?.building?.id, notification.data?.month);
  const exportLink = useExportLink();
  const partnerInviteLink = usePartnerInviteLink();
  const packingListLink = usePackingListLink(
    notification.data?.packinglist?.id,
    notification.data?.building?.id || notification.data?.building_id
  );
  const paymentLink = usePaymentLink(
    notification.data?.invoice?.id,
    notification.data?.building?.id || notification.data?.building_id
  );
  const requisitionLink = useRequisitionLink(
    notification.data?.requisition_id || notification.data?.requisition?.id || notification.data?.doc?.id,
    notification.data?.building?.id || notification.data?.building_id
  );
  const taskLink = useTasksTrackLink(notification.data?.task?.id);

  const chatLink = useChatLink(
    chatType === CHAT_TYPES_ENUM.ORDER || notification.data.chat_type === chatRoomTypes.ORDER
      ? orderLink
      : requisitionLink
  );
  const chatV2Link = useChatV2Link(notification.data?.chat_type, notification.data?.building_id);

  const remarkLink = useRemarkLink(
    notification.data?.building_id,
    notification.data?.remark_id,
    notification.data?.section_id
  );

  const stateLink = useStateLink(notification.data?.building_id, notification.data?.state);

  const shiftLink = useShiftLink(notification?.data?.building_id, notification?.created_at);

  const openFactApproveLink = useOpenFactApproveLink({
    buildingId: notification?.data?.building_id,
    sectionId: notification?.data?.child_section_id,
    factType: notification?.data?.fact_type,
    factId: notification?.data?.fact_id,
    year: notification?.data?.year,
    month: notification?.data?.month
  });


  switch (notification.data?.template) {
    case TEMPLATE_VARIANTS_ENUM.PLAN_APPROVE:
      return (
        <span>
          Необходимо согласование <NavLinkIcon title={"плана"} to={planApproveLink} />, {notification?.data?.building_name}.
        </span>
      )
    case TEMPLATE_VARIANTS_ENUM.FACT_ACCEPTANCE_APPROVE:
      return (
        <span>
          Необходимо согласовать приемку работы {' '}
          <NavLinkIcon
            titleClassName={styles.wrap}
            isReplaceRoute
            title={sliceTextByConstraint(notification.data?.name, 75)}
            to={openFactApproveLink}
          />
        </span>
      )
    case TEMPLATE_VARIANTS_ENUM.NEW_ORDER:
      return (
        <span>
          Поступление нового заказа:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 80)}
          </span>
          заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ORDER_MATCHING:
      return (
        <span>
          Необходимо согласование заказа:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ORDER_MATCHING_COMPLETED:
      return (
        <span>
          Согласование заказа завершено:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ORDER_MATCHING_PAYMENT:
      return (
        <span>
          Необходимо согласование заказа:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ORDER_MATCHING_PAYMENT_COMPLETED:
      return (
        <span>
          Согласование оплаты завершено:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ORDER_PAYMENT_DECISION:
      return (
        <span>
          Необходимо решение по оплате заказа:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ORDER_PAYMENT_DECISION_MADE:
      return (
        <span>
          Заказ передан в оплату:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ORDER_WAITING_SHIPMENT:
      return (
        <span>
          Подтверждение заказа:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 80)},
          </span>
          заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PCR_CHANGE_ORDER_EXECUTOR:
      return (
        <span>
          Назначение на обработку заказа:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PCR_CHANGED_ORDER:
      return (
        <span>
          Изменение заказа:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 80)},
          </span>
          заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PCR_REJECTED_ORDER:
      return (
        <span>
          Отмена заказа:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 80)},
          </span>
          заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PVR_CHANGE_ORDER_EXECUTOR:
      return (
        <span>
          Назначение на обработку заказа:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_safe}>
            {sliceTextByConstraint(notification.data?.purchaser_safe, 80)},
          </span>
          заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PVR_CONFIRMED_ORDER:
      return (
        <span>
          Заказ подтвержден поставщиком:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PVR_REJECTED_ORDER:
      return (
        <span>
          Заказ отклонен поставщиком:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
          , заказ №
          <NavLinkIcon isReplaceRoute title={notification.data?.order.number} to={orderLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.T13_APPROVE:
      return (
        <span>
          {`Требуется согласование табеля Т13 за `}
          <NavLinkIcon isReplaceRoute title={notification.data?.month} to={timeSheetLink} />
          на
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {notification.data?.building?.name}
          </span>
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.CHAT_MENTION:
      return (
        <span>
          Вас упомянули в чате
          <span className={styles.slicedTitle} title={chatType}>
            {sliceTextByConstraint(chatType, 20)}
          </span>
          №
          <NavLinkIcon isReplaceRoute title={notification.data?.doc.number} to={chatLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.CHAT_UNREAD:
      return (
        <span>
          Новое сообщение в чате
          <span className={styles.slicedTitle} title={chatType}>
            {sliceTextByConstraint(chatType, 20)}
          </span>
          №
          <NavLinkIcon isReplaceRoute title={notification.data?.doc.number} to={chatLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.CHAT_V2_MENTION:
      if (
        notification.data.chat_type === chatRoomTypes.REQUISITION ||
        notification.data.chat_type === chatRoomTypes.ORDER
      ) {
        return (
          <span>
            Вас упомянули в <NavLinkIcon isReplaceRoute title={"чате"} to={chatLink} />
          </span>
        );
      }
      return (
        <span>
          Вас упомянули в <NavLinkIcon isReplaceRoute title={"чате"} to={chatV2Link} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.EXPORT_COMPLETE:
      return (
        <span>
          <NavLinkIcon isReplaceRoute title="Экспорт" to={exportLink} />
          документов завершен
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.NEW_INVITATION:
      return (
        <span>
          Новое <NavLinkIcon isReplaceRoute title="приглашение" to={partnerInviteLink} />
          стать партнером
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.SPECIFICATION_COMPLETE:
      return (
        <span>
          Спецификация на объект
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)}
          </span>
          загружена
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PACKING_NEED_CONFIRMED:
      return (
        <span>
          УПД принята с изменениями:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 80)},
          </span>
          УПД №
          {notification.data?.building_id ? (
            <NavLinkIcon isReplaceRoute title={notification.data?.packinglist.number} to={packingListLink} />
          ) : (
            notification.data?.packinglist.number
          )}
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PCR_PACKING_CHANGED_DATE:
      return (
        <span>
          Изменение даты УПД:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 80)},
          </span>
          УПД №
          {notification.data?.building_id ? (
            <NavLinkIcon isReplaceRoute title={notification.data?.packinglist.number} to={packingListLink} />
          ) : (
            notification.data?.packinglist.number
          )}
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PVR_PACKING_CHANGED_DATE:
      return (
        <span>
          Изменение даты УПД:
          <span className={styles.slicedTitle} title={notification.data?.provider_name}>
            {sliceTextByConstraint(notification.data?.provider_name, 80)},
          </span>
          УПД №
          {notification.data?.building_id ? (
            <NavLinkIcon isReplaceRoute title={notification.data?.packinglist.number} to={packingListLink} />
          ) : (
            notification.data?.packinglist.number
          )}
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PAYMENT_CHANGED_DATE:
      return (
        <span>
          Изменение даты платежа:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 20)},
          </span>
          счет №
          <NavLinkIcon isReplaceRoute title={notification.data?.invoice.number} to={paymentLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PAYMENT_PAID:
      return (
        <span>
          Оплата счета:
          <span className={styles.slicedTitle} title={notification.data?.purchaser_name}>
            {sliceTextByConstraint(notification.data?.purchaser_name, 20)},
          </span>
          счет №
          <NavLinkIcon isReplaceRoute title={notification.data?.invoice.number} to={paymentLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PAYMENT_PAID_PCR:
      return (
        <span>
          Счет №
          <NavLinkIcon isReplaceRoute title={notification.data?.invoice.number} to={paymentLink} />
          оплачен
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REQUISITION_CHANGE_EXECUTOR:
      return (
        <span>
          Назначение на обработку заявки:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 20)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REQUISITION_MATCHING:
      return (
        <span>
          Необходимо согласование заявки:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon isReplaceRoute title={notification.data?.requisition.number} to={requisitionLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REQUISITION_PROCESSING:
      return (
        <span>
          Поступление новой заявки:
          <span className={styles.slicedTitle} title={notification.data?.building?.name}>
            {sliceTextByConstraint(notification.data?.building?.name, 80)},
          </span>
          заявка №
          <NavLinkIcon title={notification.data?.requisition.number} to={requisitionLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.APPOINTED_EXECUTOR:
      return (
        <span>
          Вас назначили исполнителем по задаче:
          <span className={styles.slicedTitle} title={notification.data?.building_name}>
            {sliceTextByConstraint(notification.data?.building_name, 80)},
          </span>
          задача №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.APPOINTED_VIEWER:
      return (
        <span>
          Вас назначили контролером по задаче:
          <span className={styles.slicedTitle} title={notification.data?.building_name}>
            {sliceTextByConstraint(notification.data?.building_name, 80)},
          </span>
          задача №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.EXECUTOR_CHANGED:
      return (
        <span>
          По задаче №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          новый исполнитель
          <span className={styles.slicedTitle} title={notification.data?.executor_name}>
            {sliceTextByConstraint(notification.data?.executor_name, 80)}
          </span>
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REMEMBER_TASK:
      return (
        <span>
          Вы являетесь исполнителем по задаче №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          Срок выполнения по задаче истекает {notification.data?.deadline}
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.STATUS_CHANGED_TO_COMPLETE:
      return (
        <span>
          Задача №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          была выполнена.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.STATUS_CHANGED_TO_COMPLETE_TO_VIEWER:
      return (
        <span>
          Задача №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          была отправлена на проверку.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.STATUS_CHANGED_TO_IN_WORK:
      return (
        <span>
          Задача №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          была отправлена на доработку.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_ADDED_TO_LIST:
      return (
        <span>
          Задачу №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          добавили в список задач
          <span className={styles.slicedTitle} title={notification.data?.list_name}>
            {sliceTextByConstraint(notification.data?.list_name, 20)}
          </span>
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_CHANGED_DEADLINE:
      return (
        <span>
          По задаче №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          изменили срок выполнения.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_CHANGED_PRIORITY:
      return (
        <span>
          По задаче №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          изменили приоритет.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_DELETED:
      return (
        <span>
          Сотрудник
          <span className={styles.slicedTitle} title={notification.data?.sender_full_name}>
            {sliceTextByConstraint(notification.data?.sender_full_name, 80)}
          </span>
          удалил задачу № {notification.data?.task.number}
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_DELETED_FOR_CREATOR:
      return (
        <span>
          Сотрудник
          <span className={styles.slicedTitle} title={notification.data?.sender_full_name}>
            {sliceTextByConstraint(notification.data?.sender_full_name, 80)}
          </span>
          удалил задачу № {notification.data?.task.number}
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_DELETED_FOR_EXECUTOR:
      return (
        <span>
          Сотрудник
          <span className={styles.slicedTitle} title={notification.data?.sender_full_name}>
            {sliceTextByConstraint(notification.data?.sender_full_name, 80)}
          </span>
          удалил задачу № {notification.data?.task.number}
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_EDITED_FOR_CREATOR:
      return (
        <span>
          В задачу №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          по которой Вы являетесь постановщиком, внесли изменения.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TASK_EDITED_FOR_EXECUTOR:
      return (
        <span>
          Задача №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />
          по которой Вы являетесь исполнителем была отредактирована.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.TIME_EXPIRED:
      return (
        <span>
          По задаче №
          <NavLinkIcon title={notification.data?.task.number} to={taskLink} />в которой Вы являетесь постановщиком
          закончился срок.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REMARKS_CREATED:
      return (
        <span>
          Поступило замечание №
          <NavLinkIcon title={notification.data?.remark_id} to={remarkLink} />к работе{" "}
          <span title={notification.data?.remark_title}>
            "{sliceTextByConstraint(notification.data?.remark_title, 20)}"
          </span>
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REMARKS_RESOLVED:
      return (
        <span>
          Добавлена информация об устранении замечания №
          <NavLinkIcon title={notification.data?.remark_id} to={remarkLink} />к работе{" "}
          <span title={notification.data?.remark_title}>
            "{sliceTextByConstraint(notification.data?.remark_title, 20)}"
          </span>
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REMARKS_ACCEPTED:
      return (
        <span>
          Замечание №
          <NavLinkIcon title={notification.data?.remark_id} to={remarkLink} />к работе{" "}
          <span title={notification.data?.remark_title}>
            "{sliceTextByConstraint(notification.data?.remark_title, 20)}"
          </span>{" "}
          устранено.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.REMARKS_NEED_WORK:
      return (
        <span>
          Замечание №
          <NavLinkIcon title={notification.data?.remark_id} to={remarkLink} />к работе{" "}
          <span title={notification.data?.remark_title}>
            "{sliceTextByConstraint(notification.data?.remark_title, 20)}"
          </span>{" "}
          отправлено на доработку.
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.ESTIMATE_CHANGE_STATE:
      return (
        <span>
          Необходимо утверждение <NavLinkIcon title={estimateType} to={stateLink} /> сметного расчета:
          <span title={notification.data?.building_name}>
            {sliceTextByConstraint(notification.data?.building_name, 80)}
          </span>
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.PACKING_ACCEPTED_NEED_CERTIFICATES:
      return (
        <span>
          Поставка принята: {sliceTextByConstraint(notification.data.building_name, 80)},{" "}
          <NavLinkIcon title={`УПД №${notification.data.packinglist.number}`} to={packingListLink} />
        </span>
      );
    case TEMPLATE_VARIANTS_ENUM.SHIFT_CREATED:
      const projectName = objects?.results?.find((o: IIdAndName) => +o.id == +notification?.data?.building_id)?.name ?? '';

      return (
        <span>
          Недостаточно материала, требуется корректировка плана работ&nbsp;
          <NavLinkIcon title={projectName} to={shiftLink} />
        </span>
      );
    default:
      return "";
  }
};

export default FormatNotificationMessage;
