import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { postsActions } from "../../../../../redux/modules/common/building/posts/actions";
import {
  allPostsIsLoadingSelector,
  allPostsSelector,
} from "../../../../../redux/modules/common/building/posts/selectors";
import {
  createNewPost,
  deletePost,
  editPost,
  loadAllPostsList,
} from "../../../../../redux/modules/common/building/posts/thunks";
import { useTypedSelector } from "../../../../../redux/typedUseSelector";

import PositionModal, { IOnSavePostProps } from "./components/PositionModal/PositionModal";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import TableReusableRow from "components/UI/atoms/TableReusable/TableReusableRow";
import ConfirmationModal from "components/UI/molecules/ConfirmationModal/ConfirmModal";

import { wrapperTestId } from "./tests/positionMocks";

import { ReactComponent as CrossIcon } from "images/icons/cross-bold-small-icon.svg";
import settingGear from "images/icons/navigation/settingGear.svg";

import styles from "./Positions.module.scss";

const Positions = () => {
  const dispatch = useDispatch();

  const [isShowNewPostModal, setIsShowNewPostModal] = useState<boolean>(false);
  const [detailedPostId, setDetailedPostId] = useState<number | null>(null);
  const [activePostInputId, setActivePostInputId] = useState<number | null>(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const posts = useTypedSelector(allPostsSelector);
  const isPostsLoading = useTypedSelector(allPostsIsLoadingSelector);

  const isShowEmptyPlaceholder = (!Array.isArray(posts) || posts?.length === 0) && !isPostsLoading;

  useEffect(() => {
    dispatch(loadAllPostsList());

    return () => {
      dispatch(postsActions.clearData());
    };
  }, []);

  const handleOpenNewPostModal = () => {
    setIsShowNewPostModal(true);
  };

  const handleClosePostModal = () => {
    setIsShowNewPostModal(false);
    setDetailedPostId(null);
  };

  const handleOpenConfirmModal = (id: number) => {
    setActivePostInputId(id);
    setIsOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setActivePostInputId(null);
    setIsOpenConfirmModal(false);
  };

  const handleSubmitDeletePost = useCallback(() => {
    if (!activePostInputId) return;

    setActivePostInputId(null);
    setIsOpenConfirmModal(false);

    dispatch(deletePost(activePostInputId));
  }, [activePostInputId]);

  const handleCreatePosition = useCallback(({ title, addedRanks }: IOnSavePostProps) => {
    dispatch(createNewPost(title, addedRanks));
  }, []);

  const handleEditPosition = useCallback(
    ({ title, removedRanksIds, addedRanks, editedRanks }: IOnSavePostProps) => {
      if (!detailedPostId) return;
      dispatch(editPost({ title, addedRanks, removedRanksIds, editedRanks, postId: detailedPostId }));
    },
    [detailedPostId]
  );

  return (
    <div data-testid={wrapperTestId}>
      <div className={styles.headline}>
        <ButtonBase buttonPrimaryEmpty onClick={handleOpenNewPostModal}>
          Добавить должность
        </ButtonBase>
      </div>
      <div className={styles.table}>
        {isShowEmptyPlaceholder && <EmptyPlaceholder img={settingGear} text={"Должности не добавлены"} />}
        {!isPostsLoading ? (
          posts?.map((item) => (
            <React.Fragment key={item.id}>
              <TableReusableRow
                containerClassName={styles.rowContainer}
                className={styles.rowHeader}
                onClick={() => setDetailedPostId(item.id)}
              >
                <span className={styles.title}>{item.title}</span>
                <div className={styles.changeItems}>
                  <CrossIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenConfirmModal(item.id);
                    }}
                  />
                </div>
              </TableReusableRow>
            </React.Fragment>
          ))
        ) : (
          <Spinner isFixed />
        )}
      </div>
      <ConfirmationModal
        variant="secondary"
        acceptButtonText="Подтвердить удаление"
        onClose={handleCloseConfirmModal}
        isOpen={isOpenConfirmModal}
        action={handleSubmitDeletePost}
      >
        Вы действительно хотите удалить должность?
      </ConfirmationModal>
      <PositionModal
        isOpen={!!detailedPostId || isShowNewPostModal}
        postId={detailedPostId}
        onClose={handleClosePostModal}
        isNew={isShowNewPostModal}
        onSave={handleCreatePosition}
        onEdit={handleEditPosition}
      />
    </div>
  );
};

export default React.memo(Positions);
