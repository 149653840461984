import cn from "classnames";
import moment from "moment";
import React, { useMemo } from "react";

import { IJournalAcceptanceTicketItem } from "redux/modules/common/building/journal/acceptance/types";

import Approval from "../../../../../UI/organism/WorkOrMaterialsModals/components/Approval/Approval";
import Expandable from "components/UI/atoms/Expandable/Expandable";
import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";
import JournalDay from "components/UI/organism/WorkOrMaterialsModals/JournalTicketModal/JournalDay";
import MaterialBlockInWork from "components/UI/organism/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import WorkersList from "components/UI/organism/WorkOrMaterialsModals/components/WorkersList/WorkersList";

import FactEditingHistory from "widgets/EditingHistory/FactEditingHistory";

import { ITicketDetailed } from "types/interfaces/Tickets";

import getShortFullName from "utils/formatters/getShortFullName";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalTicketModal.module.scss";
import { isNanChecker } from "../../../../../../utils/formatters/isNanChecker";
import {
  SimpleResourceBlock
} from "../../../../../../features/simpleResources/ui/SimpleResourceBlock/SimpleResourceBlock";
import { ResourceTypeEnum } from "../../../../../../features/simpleResources/types/simpleResources";

interface IProps {
  ticketData: IJournalAcceptanceTicketItem;
  detailedData: ITicketDetailed | null;
  parentName?: string;
  isOpen: boolean;
  fileInput: React.ReactNode;
}

const JournalTicketModalDisplay: React.FC<IProps> = ({ ticketData, detailedData, parentName, isOpen, fileInput }) => {
  const ticketItem = ticketData?.expenditure ?? ticketData?.group;

  const simpleMaterials = useMemo(() => {
    if (detailedData?.simple_resources) {
      return detailedData.simple_resources.filter((r) => ![
        ResourceTypeEnum.transport,
        ResourceTypeEnum.machine
      ].includes(r.type));
    }

    return [];
  }, [detailedData?.simple_resources]);

  const simpleMims = useMemo(() => {
    if (detailedData?.simple_resources) {
      return detailedData.simple_resources.filter((r) => [
        ResourceTypeEnum.transport,
        ResourceTypeEnum.machine
      ].includes(r.type));
    }

    return [];
  }, [detailedData?.simple_resources]);

  const materialsCount = useMemo(() => {
    return isNanChecker(+detailedData?.materials?.length) + isNanChecker(simpleMaterials.length);
  }, [detailedData])

  const mimsCount = useMemo(() => {
    return isNanChecker(+detailedData?.services?.length) + isNanChecker(simpleMims.length);
  }, [detailedData])

  return (
    <>
      <div className={styles.countsContainer}>
        <div className={styles.countsHeader}>Количество {ticketItem?.measure && `(${ticketItem?.measure})`}:</div>
        <div className={styles.counts}>
          <div className={styles.info}>
            <b>По смете:</b>
            <span>{detailedData?.max_count}</span>
          </div>
          <div className={styles.info}>
            <b>Сдано:</b>
            <span>{detailedData?.todo_count}</span>
          </div>
          <div className={styles.info}>
            <b>По плану:</b>
            <span>{detailedData?.plan_count}</span>
          </div>
        </div>
      </div>
      <div className={styles.pair}>
        <JournalDay
          day={moment(ticketItem?.created_at).format("DD/MM/YYYY")}
          time={moment(ticketItem?.created_at).format("HH:mm")}
        />
        <div className={styles.pass} title={getShortFullName(ticketItem?.author)}>
          <b>Сдал:</b>
          <span>{sliceTextByConstraint(getShortFullName(ticketItem?.author), 18)}</span>
        </div>
      </div>
      <div className={styles.pair}>
        <div className={styles.info}>
          <b>Количество (к сдаче):</b>
          <span>
            {ticketItem?.count} {ticketItem?.measure && `(${ticketItem?.measure})`}
          </span>
        </div>
        <div className={styles.info}>
          <b>Стоимость:</b>
          <span>{splitThousands(detailedData?.amount)}</span>
        </div>
      </div>

      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          defaultDateStart={detailedData?.start_at}
          defaultDateEnd={detailedData?.end_at}
          disabled
          classNameSelect={styles.calendar}
          skipValidation
        />
      </div>
      {!!detailedData?.approvals?.length && (
        <Approval approvals={detailedData?.approvals ?? []} planCreatingDate={ticketItem?.created_at} />
      )}
      {!!parentName && (
        <div className={styles.info}>
          <b>Раздел:</b>
          <span>{parentName}</span>
        </div>
      )}
      {<WorkersList workers={detailedData?.workers!} />}
      {!!detailedData?.expenditures?.length && (
        <Expandable title={`Расценки в группе: ${detailedData.expenditures?.length}`} className={styles.subtitle}>
          {detailedData.expenditures?.map((el) => (
            <MaterialBlockInWork name={el.name} measure={el.measure} count={el.count} amount={+el.amount} key={el.id} />
          ))}
        </Expandable>
      )}
      {!!materialsCount && (
        <Expandable title={`Материалы: ${materialsCount}`} className={styles.subtitle}>
          {simpleMaterials.map((el) => (
            <SimpleResourceBlock canInteract={false} name={el.name} count={el.count} measure={el.measure} />
          ))}
          {detailedData?.materials?.map((el) => (
            <MaterialBlockInWork name={el.name} measure={el.measure} count={el.count} amount={+el.amount} key={el.id} />
          ))}
        </Expandable>
      )}
      {!!mimsCount && (
        <Expandable title={`Машины и механизмы: ${mimsCount}`} className={styles.subtitle}>
          {simpleMims.map((el) => (
            <SimpleResourceBlock canInteract={false} name={el.name} count={el.count} measure={el.measure} />
          ))}
          {detailedData.services?.map((el) => (
            <MaterialBlockInWork name={el.name} measure={el.measure} count={el.count} amount={+el.amount} key={el.id} />
          ))}
        </Expandable>
      )}
      {fileInput}
      <FactEditingHistory isGroup={!!ticketData.group} isOpen={isOpen} ticketId={ticketItem.id} />
    </>
  );
};

export default JournalTicketModalDisplay;
