import React, { useCallback, useState } from "react";

import { Switch } from "../../../../../../../../../../../../../UI/atoms/Switch/Switch";

import { IPermissionsTree } from "features/permissions/model/types";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import styles from "./SwitchedPermission.module.scss";

interface IProps {
  group: IPermissionsTree;
  onChangeChecked: (alias: string, status: boolean) => void;
  checkedPermissions: Record<string, boolean>;
  canEdit: boolean;
}

const SwitchedPermission: React.FC<IProps> = ({ group, onChangeChecked, checkedPermissions, canEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = useCallback(() => setIsExpanded((prevState) => !prevState), []);

  const isNeedToExtractPermissions = !group.name && !group.groups.length && group.permissions.length;

  if (isNeedToExtractPermissions) {
    return (
      <>
        {group.permissions.map((el) => (
          <div className={styles.switch}>
            <span>{el.title}</span>
            <Switch
              checked={checkedPermissions[el.alias]}
              onChange={(e) => onChangeChecked(el.alias, e.target.checked)}
              //@ts-ignore
              onClick={stopEventPropagation}
              // disabled={!canEdit || el.optional === false}
            />
          </div>
        ))}
      </>
    );
  }

  const mainGroupPermission = group.permissions.at(-1);

  if (!mainGroupPermission) return null;

  const showChildren = group.permissions?.length > 1 || !!group.groups.length;

  return (
    <>
      <div className={styles.container} onClick={handleExpand}>
        {showChildren && (
          <ArrowSmallUpGrey className={styles.arrow} color="#C9C9C9" rotate={isExpanded ? "180" : "90"} />
        )}
        <div className={styles.switch}>
          <span>{group.name}</span>
          <Switch
            checked={checkedPermissions[mainGroupPermission.alias]}
            onChange={(e) => onChangeChecked(mainGroupPermission.alias, e.target.checked)}
            //@ts-ignore
            onClick={stopEventPropagation}
            //disabled={!canEdit || mainGroupPermission.optional === false}
          />
        </div>
      </div>
      {showChildren && isExpanded && (
        <div className={styles.children}>
          {group.groups?.map((el) => (
            <SwitchedPermission
              key={el.name}
              group={el}
              checkedPermissions={checkedPermissions}
              onChangeChecked={onChangeChecked}
              canEdit={canEdit}
            />
          ))}
          {group.permissions.map((el, i, arr) => {
            if (i === arr.length - 1) {
              return null;
            }
            return (
              <div className={styles.switch}>
                <span>{el.title}</span>
                <Switch
                  checked={checkedPermissions[el.alias]}
                  onChange={(e) => onChangeChecked(el.alias, e.target.checked)}
                  //@ts-ignore
                  onClick={stopEventPropagation}
                  // disabled={!canEdit || !el.optional}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default React.memo(SwitchedPermission);
