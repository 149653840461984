import cn from "classnames";
import React from "react";

import styles from "./index.module.scss";

const BlueLabel = ({ children, className, blueFont, ...props }) => {
  if (!children) return null;

  return (
    <div className={cn(styles.container, className, { [styles.blueFont]: blueFont })} {...props}>
      {children}
    </div>
  );
};

export default React.memo(BlueLabel);
