import { debounce, memoize, partial } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  loadMixedProviders,
  mixedProvidersLoadingSelector,
  mixedProvidersSelector,
  resetVirtualProviders,
} from "../../../../../redux/modules/common/building/virtualProviders";

import Checkbox from "../../../../UI/Checkbox";
import { InputSearchRound } from "../../../../UI/InputSearchRound";
import { Spinner } from "../../../../UI/Spinner/Spinner";
import ButtonBase from "../../../../UI/atoms/ButtonBase";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "../../../../UI/atoms/ShowMoreButton/index";
import SliderModal from "../../../../UI/atoms/SliderModal";

import CreateVirtualProvider from "../CreateVirtualProvider";

import { LOCALIZATION_CONFIG } from "../../../../../constants/localization";

import useArrayItemsChecker from "../../../../../hooks/useArrayItemsChecker";

import { stringifyArgs } from "../../../../../utils/helpers/stringifyArgs";

import counterpartiesIcon from "../../../../../images/icons/navigation/contractors.svg";

import styles from "./index.module.scss";

const INITIAL_FILTERS = { limit: 10, offset: 0 };
const EMPTY_MIXED_PROVIDERS = [];

const VirtualProviders = ({ onClose, addVirtualProviders, canCreateVirtualProviders }) => {
  const dispatch = useDispatch();
  const mixedProviders = useSelector(mixedProvidersSelector);
  const mixedProvidersAreLoading = useSelector(mixedProvidersLoadingSelector);

  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const {
    items: checkerItems,
    checkedCount: checkedProvidersCount,
    checkOnce: checkProvider,
    checkAll: checkAllProviders,
    reset: resetCheckerItems,
  } = useArrayItemsChecker(mixedProviders.results || EMPTY_MIXED_PROVIDERS, "id");

  const isCheckedAll = mixedProviders.results?.length > 0 && checkedProvidersCount === mixedProviders.results?.length;

  const handleLoadMixedProviders = useCallback(
    (filtersToRequest) => compose(dispatch, loadMixedProviders)(filtersToRequest),
    []
  );

  const debouncedHandleLoadMixedProviders = useMemo(
    () => debounce(handleLoadMixedProviders, 500),
    [handleLoadMixedProviders]
  );

  const onCheckProvider = useCallback((id, e) => checkProvider(id, e.target.checked), [checkProvider]);

  const partialOnCheckProvider = useCallback((id) => partial(onCheckProvider, [id]), [onCheckProvider]);

  const memoizedPartialOnCheckProvider = useMemo(() => memoize(partialOnCheckProvider), [partialOnCheckProvider]);

  const onCheckAllProvider = useCallback((e) => checkAllProviders(e.target.checked), [checkAllProviders]);

  const handleAddProviders = useCallback(() => {
    setFilters((prevState) => ({ ...prevState, offset: prevState.offset + 10 }));
  }, []);

  const onChangeSearch = useCallback(
    (e) =>
      setFilters((prevState) => {
        const changedSearchValue = e.target.value;

        if (changedSearchValue.length !== 0) return { ...INITIAL_FILTERS, search: changedSearchValue };
        return { ...prevState, search: changedSearchValue };
      }),
    []
  );

  const submitHandler = useCallback(() => {
    const virtualProvidersToAdd = Object.entries(checkerItems)
      .map(([id, isChecked]) => {
        if (!isChecked) return null;
        return id;
      })
      .filter((item) => item);

    addVirtualProviders(virtualProvidersToAdd);
    onClose();
    resetCheckerItems();
  }, [resetCheckerItems, onClose, addVirtualProviders, checkerItems]);

  const getHandleLoadMixedProviders = useMemo(
    () => memoize((filters) => () => debouncedHandleLoadMixedProviders(filters), stringifyArgs),
    [debouncedHandleLoadMixedProviders]
  );

  useEffect(() => {
    debouncedHandleLoadMixedProviders(filters);
  }, [debouncedHandleLoadMixedProviders, filters]);

  useEffect(() => () => compose(dispatch, resetVirtualProviders)(), []);

  return (
    <SliderModal closeHandler={onClose} className={styles.sliderModal} isOpen>
      <section className={styles.container}>
        {canCreateVirtualProviders && <CreateVirtualProvider reload={getHandleLoadMixedProviders(filters)} />}
        <div className={styles.content}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Список контактов</div>
            <InputSearchRound onChange={onChangeSearch} className={styles.inputSearchClassName} />
            <ButtonBase secondary medium onClick={submitHandler}>
              Выбрать
            </ButtonBase>
          </div>
          <div className={styles.headerTable}>
            <Checkbox
              className={styles.checkboxField}
              checkboxClassName={styles.checkbox}
              checked={isCheckedAll}
              onChange={onCheckAllProvider}
            />
            <div>Наименование</div>
            <div>Юр. наименование</div>
            <div>Эл. почта</div>
            <div>{LOCALIZATION_CONFIG.taxesNumberLabel}</div>
          </div>
          {!mixedProvidersAreLoading && mixedProviders.results ? (
            mixedProviders.results.map((mixedProvider) => (
              <div
                className={styles.tableRow}
                onClick={() => checkProvider(mixedProvider.id, !checkerItems[mixedProvider.id])}
                key={mixedProvider.id}
              >
                <Checkbox
                  className={styles.checkboxField}
                  checkboxClassName={styles.checkbox}
                  checked={checkerItems[mixedProvider.id]}
                  onChange={memoizedPartialOnCheckProvider(mixedProvider.id)}
                />
                <div title={mixedProvider.nickname} className={styles.nowrapText}>
                  {mixedProvider.nickname}
                </div>
                <div title={mixedProvider.name} className={styles.nowrapText}>
                  {mixedProvider.name}
                </div>
                <div title={mixedProvider.email} className={styles.nowrapText}>
                  {mixedProvider.email}
                </div>
                <div>{mixedProvider.itn}</div>
              </div>
            ))
          ) : (
            <Spinner />
          )}
          {mixedProviders.count === 0 && <EmptyPlaceholder img={counterpartiesIcon} text="Нет поставщиков" />}
          <ShowMoreButton
            showedCount={mixedProviders.results?.length}
            allCount={mixedProviders.count}
            handleAdd={handleAddProviders}
          />
        </div>
      </section>
    </SliderModal>
  );
};

export default React.memo(VirtualProviders);
