import styles from "../Footer.module.scss";
import ButtonBase from "../../../../../atoms/ButtonBase";
import BottomControls from "../../../../WorkOrMaterialsModals/components/BottomControls/BottomControls";
import React, { FC } from "react";
import Check from "../../../../../../../images/icons/Check";


interface IProps {
  isShowButtons?: boolean;
  haveUngroupGroupsPermission: boolean;
  haveEditGroupsPermission: boolean;
  isEditing: boolean;
  hasChanges: boolean;

  toggleEdit: () => void;
  handleCompleteEdit: () => void;
  handleUngroup?: () => void;
  onClose: () => void;
  onCreate: () => void;
}

export const CreationControls: FC<IProps> = ({
  isShowButtons,
  haveUngroupGroupsPermission,
  haveEditGroupsPermission,
  isEditing,
  hasChanges,
  toggleEdit,
  handleCompleteEdit,
  handleUngroup,
  onClose,
  onCreate
}) => {

  return (
    <BottomControls
      isExists={isShowButtons && haveUngroupGroupsPermission}
      wrapperClassName={styles.bottomControlsWrapper}
      className={styles.bottomControls}
    >
      <ButtonBase
        secondary
        onClick={onClose}
      >
        Отмена
      </ButtonBase>
      <ButtonBase
        type="submit"
        primary
        className={styles.create}
        onClick={onCreate}
      >
        <Check />
        Сгруппировать
      </ButtonBase>
    </BottomControls>
  )
}