import React from "react";

import ButtonBase from "../../../../../../../../UI/atoms/ButtonBase";
import ImportDraftIcon from "../../../../../../../../../images/icons/ImportDraftIconLazy";

import styles from "./Draft.module.scss";

const Draft = ({ onCreateSection }) => {
  return (
    <div className={styles.draft}>
      <div className={styles.title}>
        Можно сформировать смету из данных через загрузку xml файла или создать позиции вручную
      </div>
      <ImportDraftIcon />
      <ButtonBase className={styles.button} primary onClick={onCreateSection}>Создать вручную</ButtonBase>
    </div>
  );
};

export default React.memo(Draft);
