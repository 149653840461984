import cn from "classnames";
import React, { Fragment } from "react";

import { ICalendarDateLineProps } from "../../CalendarDateLine";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import { generateDaysForLine } from "../../../../utils";

import styles from "./DaysLine.module.scss";

const DaysLine = React.forwardRef<Record<number | string, HTMLDivElement>, ICalendarDateLineProps>(
  ({ year, unitOffset, criticalDates }, ref) => {
    const unitMultiplier = useUnitMultiplier();
    const days = generateDaysForLine(+year, undefined, undefined, criticalDates);
    return (
      <div
        className={styles.daysWrapper}
        ref={(r) => {
          if (!ref.current) return;
          ref.current[year] = r;
        }}
        style={{ left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
      >
        {days.map((day, index) => {
          return (
            <Fragment key={index}>
              <div
                className={cn(
                  {
                    startMonth: day.day === 1,
                    [styles.weekend]: day.weekend,
                    [styles.today]: day.today,
                    [styles.critical]: day.isCritical
                  },
                  styles.dayWrapper
                )}
                style={{
                  left: `${index * unitMultiplier}rem`,
                  width: `${unitMultiplier}rem`,
                }}
                data-year={year}
                data-month={day.month - 1}
              >
                <div className={styles.day}>{day.day}</div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }
);

export default React.memo(DaysLine);
