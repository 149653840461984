import { message } from "antd";
import axios from "axios";
import { createSelector } from "reselect";

import { mapApprovalsToObjects } from "./auth-utils.";
import { errorCatcher } from "utils/helpers/errorCatcher";

const SET_CURRENT_USER = "auth/SET_CURRENT_USER";
const SET_AUTH_TOKEN = "auth/SET_AUTH_TOKEN";
const SET_IS_ADMIN = "auth/SET_IS_ADMIN";
const SET_APPROVAL = "auth/SET_APPROVAL";
const SET_SIMPLE_AUTH_TOKEN = "auth/SET_SIMPLE_AUTH_TOKEN";
const UPDATE_USER_PROFILE = "auth/UPDATE_USER_PROFILE";
const DISABLE_WELCOME_TOUR = "auth/DISABLE_WELCOME_TOUR";
const UPDATE_USER_ENTITIES = "auth/UPDATE_USER_ENTITIES";
const SET_WORKER_ENTITY = "auth/SET_WORKER_ENTITY";
const USER_REGISTERED = "auth/USER_REGISTERED";
const SET_ACCOUNT_TYPE = "auth/SET_ACCOUNT_TYPE";
const REGISTRATION_CONFIRM = "auth/REGISTRATION_CONFIRM";
export const SET_ORGANIZATION = "auth/SET_ORGANIZATION";
export const USER_LOGOUT = "auth/USER_LOGOUT";
export const SET_USER_LOGOUT = "auth/SET_USER_LOGOUT";
export const USER_AUTO_LOGOUT = "auth/USER_AUTO_LOGOUT";
const SET_WORKERS = `auth/SET_WORKERS`;
const SET_PAGE_LOADING = "auth/SET_PAGE_LOADING";

export const initialState = {
  isAuthenticated: !!localStorage.getItem("authToken"),
  isPageLoading: false,
  user: {
    key: localStorage.getItem("authToken"),
    welcome_tour: false,
    roles: [],
    general_entity: {},
    public_modules: [],
    my_entities: [],
    approval: {},
  },
  entity: {},
  organization: {
    entities: [],
    members: [],
  },
  isAdministrator: false,
};

/*
  Reducer
*/

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: {
          ...payload,
          ...state.user,
          ...action.user,
        },
      };

    case SET_ACCOUNT_TYPE:
      return {
        ...state,
        entity: {
          ...state.entity,
          type: payload,
        },
      };
    case SET_PAGE_LOADING:
      return { ...state, isPageLoading: payload };
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        isAuthenticated: true,
        isPageLoading: true,
        user: {
          ...state.user,
          key: payload,
        },
      };
    }

    case SET_IS_ADMIN: {
      return {
        ...state,
        user: {
          ...state.user,
          is_admin: payload,
        },
      };
    }

    case SET_APPROVAL: {
      return {
        ...state,
        user: {
          ...state.user,
          approval: payload,
        },
      };
    }

    case SET_USER_LOGOUT: {
      return {
        ...initialState,
      };
    }

    case SET_SIMPLE_AUTH_TOKEN: {
      return {
        ...state,
        user: {
          ...state.user,
          key: payload.key,
          id: payload.id,
        },
        entity: {
          ...state.entity,
          id: payload.id,
        },
      };
    }

    case SET_WORKERS:
      return {
        ...state,
        entity: { ...state.entity, workers: payload },
      };
    case DISABLE_WELCOME_TOUR: {
      return {
        ...state,
        user: {
          ...state.user,
          welcome_tour: false,
        },
      };
    }

    case UPDATE_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };

    case UPDATE_USER_ENTITIES:
      return {
        ...state,
        user: {
          ...state.user,
          my_entities: action.my_entities,
        },
      };

    case SET_WORKER_ENTITY: {
      return {
        ...state,
        isPageLoading: false,
        entity: action.entity,
      };
    }

    case SET_ORGANIZATION:
      return {
        ...state,
        organization: {
          entities: action.entities,
          members: action.members,
        },
      };

    case USER_REGISTERED: {
      return {
        ...state,
        userRegistered: action.payload,
      };
    }
    case "UPDATE_1C_INTEGRATION": {
      return {
        ...state,
        entity: {
          ...state.entity,
          use_1c: true,
        },
      };
    }
    case REGISTRATION_CONFIRM: {
      return {
        ...state,
        registrationConfirm: action.payload,
      };
    }

    default:
      return state;
  }
};

/*
  Selectors
*/

export const stateSelector = (state) => state.auth;
export const userSelector = createSelector(stateSelector, (state) => state.user);
export const guidesSelector = createSelector(stateSelector, (state) => state.user.guides);
export const entityIdSelector = createSelector(stateSelector, (state) => state.entity.id);
export const isPurchaserSelector = createSelector(stateSelector, () => true); //? When the marketplace appears, replace it with: === "pcr"
export const entitysListSelector = createSelector(stateSelector, (state) => state.organization.entities);
export const workersSelector = createSelector(stateSelector, (state) => state.entity.workers);
export const isAdministratorSelector = createSelector(stateSelector, (state) => state.isAdministrator);
export const isAdminSelector = createSelector(stateSelector, (state) => state.user.is_admin);
export const pageLoadingSelector = createSelector(stateSelector, (state) => state.isPageLoading);
export const isAbleToCreateProjectSelector = createSelector(stateSelector, (state) => {
  const { max_buildings_count, active_buildings_count } = state.user || {};
  return max_buildings_count > active_buildings_count;
});
/*
  Action creators
*/

export const setWorkerEntity = (entity) => {
  return {
    type: SET_WORKER_ENTITY,
    entity,
  };
};

export const setWorkers = (workers) => ({
  type: SET_WORKERS,
  payload: workers,
});

/*
  Thunks
*/

export const authorizeUser = (userData) => {
  return (dispatch) =>
    axios.post(`/auth/login/`, userData).then((res) => {
      localStorage.setItem("authToken", res.data.key);
      dispatch({ type: SET_AUTH_TOKEN, payload: res.data.key });

      if (res.data.is_admin) {
        dispatch({
          type: SET_IS_ADMIN,
          payload: true,
        });
        dispatch({
          type: SET_WORKER_ENTITY,
          entity: {
            id: null,
            type: "pvr",
          },
        });
      }
    });
};

export const setSimpleToken = (data) => {
  return (dispatch) =>
    dispatch({
      type: SET_SIMPLE_AUTH_TOKEN,
      payload: data,
    });
};

export const registerUser = (userData) => {
  let newUser = {
    email: userData.email,
    acc_type: userData.acc_type,
  };
  return (dispatch) =>
    axios.post(`/auth/registration/`, newUser).then((res) => {
      dispatch({
        type: USER_REGISTERED,
        payload: res,
      });
    });
};

export const confirmRegistration = (token) => {
  const body = {
    key: token,
  };

  return (dispatch) =>
    axios.post(`/auth/signup/verify-email/`, body).then((res) => {
      dispatch({
        type: REGISTRATION_CONFIRM,
        payload: res,
      });
    });
};

export const setCurrentUser = () => async (dispatch) => {
  dispatch({ type: SET_PAGE_LOADING, payload: true });
  await axios
    .get(`/profile/`)
    .then(
      (profileRes) => {
        axios
          .get(`/users/current/`)
          .then((currentUser) => {
            dispatch({
              type: SET_CURRENT_USER,
              user: { ...profileRes.data, ...currentUser.data },
            });
          })
          .catch(errorCatcher);
        axios
          .get(`/users/current/projects/`, { params: { limit: 1000 } })
          .then(({ data }) => {
            dispatch({ type: SET_APPROVAL, payload: mapApprovalsToObjects(data.results) });
          })
          .catch(errorCatcher);
      },
      (err) => {
        console.error(err);
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: null,
        });
      }
    )
    .catch(errorCatcher);
  dispatch({ type: SET_PAGE_LOADING, payload: false });
};

export const setAccountType = () => {
  return (dispatch) =>
    axios
      .get(`/users/current/`)
      .then((response) => {
        dispatch({
          type: SET_ACCOUNT_TYPE,
          payload: response.data.account_type,
        });
      })
      .catch((e) => console.error(e));
};

export const logout = () => {
  return (dispatch) => {
    if (process.env.REACT_APP_MODE === "test") {
      axios.post(`/auth/logout/`).then(() => {
        dispatch({
          type: USER_LOGOUT,
        });
      });
    }
  };
};

export const updateUserEntities = () => {
  return (dispatch, getState) =>
    !getState().auth.user.is_admin &&
    axios.get(`/entities/`).then(
      (res) => {
        dispatch({
          type: UPDATE_USER_ENTITIES,
          my_entities: res.data.results,
        });
        dispatch({
          type: SET_WORKER_ENTITY,
          entity: res.data.results[0],
        });
      },
      (error) => {
        console.error(error);
      }
    );
};

export const updateUserProfileInfo = (userData) => {
  return (dispatch) =>
    axios.put(`/profile/`, userData).then(() =>
      dispatch({
        type: UPDATE_USER_PROFILE,
        payload: userData,
      })
    );
};

export const updateUserCurrentProfileInfo = (userData) => {
  return (dispatch) =>
    axios.patch(`/users/current/ `, userData).then(
      (res) => {
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: userData,
        });
        message.success("Профиль успешно обновлен");
      },
      (error) => errorCatcher(error)
    );
};

export const updateWelcomeTour = () => {
  return (dispatch) =>
    axios.patch(`/profile/`, { welcome_tour: false }).then(() =>
      dispatch({
        type: DISABLE_WELCOME_TOUR,
      })
    );
};

export const setCurrentOrganization = (data) => {
  return {
    type: SET_ORGANIZATION,
    entities: data.entities,
    members: data.members,
  };
};

export const loadWorkers = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };
    return (dispatch) => {
      axios.get(`/entities/${id}/workers/`, config).then(
        (res) => {
          dispatch(setWorkers(res.data.results));
        },
        (error) => {
          console.error(error);
        }
      );
    };
  };
})();

export const isSessionValid = (callback) => {
  return (dispatch) =>
    axios
      .head(`/profile/`)
      .then(() => callback && callback())
      .catch(errorCatcher);
};

export const setOrganization = () => {
  const data = {};

  return (dispatch) =>
    axios.get(`/entities/`).then(
      (res) => {
        data.entities = res.data.results;
        return axios.get(`/users/`).then(
          (res) => {
            data.members = res.data.results;
            dispatch(setCurrentOrganization(data));
          },
          (err) => {
            console.error(err);
          }
        );
      },
      (err) => {
        console.error(err);
      }
    );
};

export const get1cToken = (id) => async () =>
  await axios.get(`/entities/${id}/orders/sync/token/`).catch((e) => console.error(e));
export const create1cToken = (id) => async () =>
  await axios.post(`/entities/${id}/orders/sync/token/`).catch((e) => console.error(e));

export function uodate1CIntegration() {
  return (dispatch) => dispatch({ type: "UPDATE_1C_INTEGRATION" });
}
