import { createSelector } from "reselect";

import { financeStateSelector } from "../finance";
import { objectIdSelector, summaryYearSelector } from "../summary/selectors";

import { IForecastInitialState } from "./types";

import { prepareForecastTableData } from "./utils";

export const stateSelector = createSelector(financeStateSelector, (state): IForecastInitialState => state.forecast);

export const isForecastsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const allProjectsForecastsListSelector = createSelector(
  stateSelector,
  (state) => state.allProjectsForecastsList
);
export const detailedProjectForecastsSelector = createSelector(
  stateSelector,
  (state) => state.detailedProjectForecasts
);
export const forecastTreeSelector = createSelector(stateSelector, (state) => state.tree);
export const forecastTableDataSelector = createSelector(
  forecastTreeSelector,
  detailedProjectForecastsSelector,
  summaryYearSelector,
  objectIdSelector,
  prepareForecastTableData
);

export const forecastIsLoadingByObjectsSelector = createSelector(stateSelector, (state) => state.isLoadingByObjects);
export const summaryByProjectV2Selector = createSelector(stateSelector, (state) => state.detailedProjectSummaryV2);
