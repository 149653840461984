import React from "react";

import usePermission from "./usePermission";

import { urlModulesType, useUrlModule } from "../utils/hooks/useUrlModule";

export const useCommonModulesPermissions = (
  permissionsByModule: Partial<Record<urlModulesType, string | string[]>>
) => {
  const urlModule = useUrlModule();
  const haveConstructingPermission = usePermission(permissionsByModule["constructing"] || "");
  const haveManufacturingPermission = usePermission(permissionsByModule["objects"] || "");
  const havePurchasesPermission = usePermission(permissionsByModule["purchases"] || "");
  const haveTasksPermission = usePermission(permissionsByModule["tasks"] || "");
  return (
    (urlModule === "constructing" && haveConstructingPermission) ||
    (urlModule === "objects" && haveManufacturingPermission) ||
    (urlModule === "purchases" && havePurchasesPermission) ||
    (urlModule === "tasks" && haveTasksPermission)
  );
};
