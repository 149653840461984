import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { concatStateMaterialField } from "../../../../redux/modules/common/building/manufacturing/utils";
import {
  HALF_MONTH,
  MONTH,
  WEEK,
  YEAR,
  getInitialMaterialData,
  getInitialMimesData,
  getInitialWorksData,
} from "redux/modules/common/building/manufacturing/manufacturing";
import {
  chartViewModeSelector,
  intervalSelector,
  manufacturingMonthMarkersSelector,
  materialDataSelector,
  materialIntervalSelector,
  materialWeekDataSelector,
  materialsSelector,
  mimesDataSelector,
  mimesSelector,
  mimesWeekDataSelector,
  planSelector,
  projectDataSelector,
  projectWeekDataSelector,
  weekMaterialsSelector,
  weekMimesSelector,
  weekPlanSelector,
} from "redux/modules/common/building/manufacturing/selectors";
import { dropLoadedChartMonths } from "redux/modules/common/building/manufacturing/thunks";

import { useRem } from "./useRem";

import {
  EQUIPMENT_TAB_ID,
  MATERIALS_TAB_ID,
  MIMES_TAB_ID,
  RESOURCES_TAB_ID,
  WORKS_TAB_ID,
  getLocalMaterialsTree,
  getLocalTree,
  getMaterialsMaps,
  getMaterialsWeekMaps,
  getMaterialsWeekTree,
  getMimesMaps,
  getMimesWeekMaps,
  getMonthsWorksTree,
  getWeekWorksMaps,
  getWeekWorksTree,
  getWorksMaps,
} from "../constants";
import {
  IMonthArrayElement,
  IProcessedBranch,
  IProject,
  IProjectMaterialsDataInterval,
  IProjectWorksDataInterval,
  ISpittingTreeElement,
  ManufacturingTabsType,
} from "../types";

import { getMonthEnumerateDataForChart } from "../utils";

export interface IUseChartTreesProps {
  tree: ISpittingTreeElement[];
  // year: number;
  // month: IMonthArrayElement;
  projects?: IProject[];
  startWeek: number;
  endWeek: number;
  projectInterval?: IProjectWorksDataInterval | IProjectMaterialsDataInterval;
  monthMarkers: number[];
  type: ManufacturingTabsType;
  isGeneratingSpittingTree: boolean;
  touchedYears: string[];
}

const calcTreesInitialState = () => ({});

export const useChartTrees = ({
  tree,
  projects,
  startWeek,
  endWeek,
  type,
  isGeneratingSpittingTree,
  touchedYears,
}: IUseChartTreesProps) => {
  const [calcTrees, setCalcTrees] = useState<Record<string, IProcessedBranch[][]>>(() => calcTreesInitialState());
  const chartViewMode = useSelector(chartViewModeSelector);
  const dispatch = useDispatch();
  const multipleProjectsWorksData = useSelector(projectDataSelector);
  const multipleProjectsMaterialsData = useSelector(materialDataSelector);
  const multipleProjectsMimesData = useSelector(mimesDataSelector);
  const multipleProjectsWeekWorksData = useSelector(projectWeekDataSelector);
  const multipleProjectsWeekMaterialsData = useSelector(materialWeekDataSelector);
  const multipleProjectsWeekMimesData = useSelector(mimesWeekDataSelector);

  const interval = useSelector(intervalSelector);
  const materialInterval = useSelector(materialIntervalSelector);

  const singleProjectWorksData = useSelector(planSelector);
  const singleProjectWeekWorksData = useSelector(weekPlanSelector);
  const singleProjectMaterialsData = useSelector(materialsSelector);
  const singleProjectWeekMaterialsData = useSelector(weekMaterialsSelector);
  const singleProjectMimesData = useSelector(mimesSelector);
  const singleProjectWeekMimesData = useSelector(weekMimesSelector);

  const monthMarkersByYears = useSelector(manufacturingMonthMarkersSelector);
  const { REM } = useRem();

  const monthEnumerateData = useMemo(
    () =>
      touchedYears.flatMap((y) =>
        getMonthEnumerateDataForChart({
          year: +y,
          monthMarkers: monthMarkersByYears[y]?.map((x: number) => x * REM) || [],
        })
      ),
    [touchedYears, monthMarkersByYears, REM]
  );

  const pushCalcTree = useCallback(
    (t: IProcessedBranch[][]) => {
      setCalcTrees((prevState) => ({
        ...prevState,
        [`${chartViewMode}_${type}`]: t,
      }));
    },
    [tree, chartViewMode, type]
  );

  const generateDaysWorkTree = useCallback(() => {
    const trees: IProcessedBranch[][] = [];
    const data = projects ? multipleProjectsWorksData : singleProjectWorksData;
    if (!data) return;
    const workPlanMap = getWorksMaps(data, projects, interval);
    monthEnumerateData.forEach((x) =>
      trees.push(getLocalTree(tree, x.maxDay, x.year, x.monthNumber, x.offsetLeft, workPlanMap, projects ? 4 : 3))
    );
    return trees;
  }, [projects, multipleProjectsWorksData, singleProjectWorksData, interval, monthEnumerateData, tree]);

  const generateDaysMaterialTree = useCallback(() => {
    const trees: IProcessedBranch[][] = [];
    const data = projects ? multipleProjectsMaterialsData : singleProjectMaterialsData;
    if (!data) return;
    const { on_stock, plans, purchases, stockless, accepted, to_paid, payed } = getMaterialsMaps(
      data,
      projects,
      materialInterval
    );
    monthEnumerateData.forEach((x) =>
      trees.push(
        getLocalMaterialsTree(
          tree,
          x.maxDay,
          x.year,
          x.monthNumber,
          x.offsetLeft,
          on_stock,
          plans,
          purchases,
          stockless,
          accepted,
          to_paid,
          payed
        )
      )
    );
    return trees;
  }, [projects, multipleProjectsMaterialsData, singleProjectMaterialsData, materialInterval, monthEnumerateData, tree]);

  const generateDaysMimesTree = useCallback(
    (isWithMaterials?: boolean) => {
      const trees: IProcessedBranch[][] = [];
      const mimesData = projects ? multipleProjectsMimesData : singleProjectMimesData;
      const materialsData = projects ? multipleProjectsMaterialsData : singleProjectMaterialsData;
      if (!mimesData && !materialsData) return;
      const { on_stock, plans, purchases, stockless, accepted, payed, to_paid } = getMimesMaps(
        isWithMaterials
          ? concatStateMaterialField(
            "materialsAndMimes",
            { ["materialsAndMimes"]: materialsData },
            mimesData
          )["materialsAndMimes"]
          : mimesData,
        projects
      );
      monthEnumerateData.forEach((x) =>
        trees.push(
          getLocalMaterialsTree(
            tree,
            x.maxDay,
            x.year,
            x.monthNumber,
            x.offsetLeft,
            on_stock,
            plans,
            purchases,
            stockless,
            accepted,
            payed,
            to_paid
          )
        )
      );
      return trees;
    },
    [
      projects,
      multipleProjectsMimesData,
      singleProjectMimesData,
      monthEnumerateData,
      tree,
      multipleProjectsMaterialsData,
      singleProjectMaterialsData,
    ]
  );

  const generateWeeksWorkTree = useCallback(() => {
    const data = projects ? multipleProjectsWeekWorksData : singleProjectWeekWorksData;
    if (!data) return;
    let trees: IProcessedBranch[][] = [];
    touchedYears?.map((year) => {
      const workPlanMap = getWeekWorksMaps({
        data: data[year] || getInitialWorksData(),
        projectInterval: interval,
        projects,
        year: +year,
      });

      const localTree = getWeekWorksTree({
        tree,
        startWeek,
        endWeek,
        year,
        workPlanMap,
        expenditureDepth: projects ? 4 : 3,
      });

      trees.push(localTree);
    });

    return trees;
  }, [
    projects,
    multipleProjectsWeekWorksData,
    singleProjectWeekWorksData,
    interval,
    touchedYears,
    tree,
    startWeek,
    endWeek,
  ]);

  const generateWeeksMaterialTree = useCallback(() => {
    const data = projects ? multipleProjectsWeekMaterialsData : singleProjectWeekMaterialsData;
    if (!data) return;
    let trees: IProcessedBranch[][] = [];
    touchedYears?.map((year) => {
      const { on_stock, plans, purchases, stockless, accepted, to_paid, payed } = getMaterialsWeekMaps({
        projects,
        projectInterval: materialInterval,
        year,
        data: data[year] || getInitialMaterialData(),
      });
      const localTree = getMaterialsWeekTree({
        tree,
        startWeek,
        endWeek,
        year,
        on_stock,
        plans,
        purchases,
        stockless,
        accepted,
        to_paid,
        payed,
      });
      trees.push(localTree);
    });

    return trees;
  }, [
    projects,
    multipleProjectsWeekMaterialsData,
    singleProjectWeekMaterialsData,
    materialInterval,
    touchedYears,
    tree,
    startWeek,
    endWeek,
  ]);

  const generateWeeksMimesTree = useCallback(
    (isWithMaterials?: boolean) => {
      const mimesWeekData = projects ? multipleProjectsWeekMimesData : singleProjectWeekMimesData;
      const materialsWeekData = projects ? multipleProjectsWeekMaterialsData : singleProjectWeekMaterialsData;
      if (!mimesWeekData && !materialsWeekData) return;
      let trees: IProcessedBranch[][] = [];
      touchedYears?.map((year) => {
        const { on_stock, plans, purchases, stockless, accepted, payed, to_paid } = getMimesWeekMaps({
          projects,
          year,
          data: isWithMaterials
            ? concatStateMaterialField(
                "materialsAndMimes",
                { ["materialsAndMimes"]: materialsWeekData[year] || getInitialMaterialData() },
                mimesWeekData[year] || getInitialMimesData()
              )["materialsAndMimes"]
            : mimesWeekData[year] || getInitialMimesData(),
        });
        const localTree = getMaterialsWeekTree({
          tree,
          startWeek,
          endWeek,
          year,
          on_stock,
          plans,
          purchases,
          stockless,
          accepted,
          payed,
          to_paid,
        });
        trees.push(localTree);
      });

      return trees;
    },
    [
      projects,
      multipleProjectsWeekMimesData,
      singleProjectWeekMimesData,
      touchedYears,
      tree,
      startWeek,
      endWeek,
      multipleProjectsWeekMaterialsData,
      singleProjectWeekMaterialsData,
    ]
  );

  const generateMonthsWorkTree = useCallback(() => {
    const data = projects ? multipleProjectsWeekWorksData : singleProjectWeekWorksData;
    if (!data) return;
    let trees: IProcessedBranch[][] = [];

    touchedYears?.map((year) => {
      const workPlanMap = getWeekWorksMaps({
        data: data[year] || getInitialWorksData(),
        projectInterval: interval,
        projects,
        year: +year,
      });

      const localTree = getMonthsWorksTree({
        tree,
        workPlanMap,
        year,
      });
      trees.push(localTree);
    });

    return trees;
  }, [projects, multipleProjectsWeekWorksData, singleProjectWeekWorksData, interval, touchedYears, tree]);

  const generateMonthsMaterialsTree = useCallback(() => [], []);

  const generateDaysTree = useCallback(
    (tabType: ManufacturingTabsType) => {
      let tree: IProcessedBranch[][] = [];
      if (tabType === WORKS_TAB_ID) tree = generateDaysWorkTree() || [];
      if (tabType === MATERIALS_TAB_ID) tree = generateDaysMaterialTree() || [];
      if ([MIMES_TAB_ID, RESOURCES_TAB_ID, EQUIPMENT_TAB_ID].includes(tabType))
        tree = generateDaysMimesTree(tabType === RESOURCES_TAB_ID) || [];
      pushCalcTree(tree);
    },
    [generateDaysWorkTree, generateDaysMaterialTree, generateDaysMimesTree, pushCalcTree]
  );
  const generateWeeksTree = useCallback(
    (tabType: ManufacturingTabsType) => {
      let tree: IProcessedBranch[][] = [];
      if (tabType === WORKS_TAB_ID) tree = generateWeeksWorkTree() || [];
      if (tabType === MATERIALS_TAB_ID) tree = generateWeeksMaterialTree() || [];
      if ([MIMES_TAB_ID, RESOURCES_TAB_ID, EQUIPMENT_TAB_ID].includes(tabType))
        tree = generateWeeksMimesTree(tabType === RESOURCES_TAB_ID) || [];
      pushCalcTree(tree);
    },
    [generateWeeksWorkTree, generateWeeksMaterialTree, generateWeeksMimesTree, pushCalcTree]
  );
  const generateYearsTree = useCallback(
    (tabType: ManufacturingTabsType) => {
      let tree: IProcessedBranch[][] = [];
      if (tabType === WORKS_TAB_ID) tree = generateMonthsWorkTree() || [];
      if (tabType === MATERIALS_TAB_ID) tree = generateMonthsMaterialsTree() || [];
      pushCalcTree(tree);
    },
    [generateMonthsWorkTree, generateMonthsMaterialsTree, pushCalcTree]
  );

  useEffect(() => {
    if (!tree?.length || isGeneratingSpittingTree) return;
    if ([MONTH, HALF_MONTH].includes(chartViewMode)) {
      generateDaysTree(type);
    }
    if (chartViewMode === WEEK) {
      generateWeeksTree(type);
    }
    if (chartViewMode === YEAR) {
      generateYearsTree(type);
    }
  }, [tree, isGeneratingSpittingTree, type, chartViewMode, generateDaysTree, generateWeeksTree, generateYearsTree]);

  useEffect(() => {
    return () => {
      dispatch(dropLoadedChartMonths());
      setCalcTrees(calcTreesInitialState());
    };
  }, []);

  return {
    calcTrees: calcTrees[`${chartViewMode}_${type}`] || [],
  };
};
