import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  invalidateKeyInJournalExecSelector,
  remarksBySectionsSelector,
  sectionsWithRemarksSelector,
} from "../../../../../../../redux/modules/common/building/journal/journalExecution/selectors";
import {
  getJournalRemarksBySection,
  getJournalSectionsWithRemarks,
} from "../../../../../../../redux/modules/common/building/journal/journalExecution/thunks";
import {
  IRemarkBySection,
  ISectionWithRemarks,
  RemarkBySectionFilters,
} from "../../../../../../../redux/modules/common/building/journal/journalExecution/types";
import { generateJornalRemarkSectionKey } from "../../../../../../../redux/modules/common/building/journal/utils";

import { Spinner } from "../../../../../../UI/Spinner/Spinner";
import BackNavigationBar from "../../../../../../UI/atoms/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "../../../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import RemarkRow from "./components/RemarkRow";

import RemarkModal from "../../../../../../modals/Journal/Remarks/RemarkModal/RemarkModal";
import RemarkCounts from "../RemarkCounts/RemarkCounts";
import RemarksHeader from "../RemarksHeader/RemarksHeader";

import { IRouterParamsWithObjectId } from "../../../../../../../types/routerTypes";

import { useQueryParams } from "utils/hooks/useQueryParams";

import jurnalIcon from "../../../../../../../images/icons/navigation/jurnalIcon.svg";

import styles from "./RemarksBySection.module.scss";

export interface IRemarksBySectionProps {
  onBack: () => void;
  sectionId: number;
}

const RemarksBySection: React.FC<IRemarksBySectionProps> = ({ onBack, sectionId }) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const remarkId = useQueryParams("remarkId")?.split("?")[0];
  const key = generateJornalRemarkSectionKey(objectId, sectionId);

  const remarksData:
    | {
        results: IRemarkBySection[];
        isLoading: boolean;
      }
    | undefined = useSelector(remarksBySectionsSelector)[key];

  const sectionsData:
    | {
        isLoading: boolean;
        results: ISectionWithRemarks[];
        filters: Record<string, string | number>;
      }
    | undefined = useSelector(sectionsWithRemarksSelector)[objectId];

  const activeSubsection = useMemo(
    () =>
      sectionsData?.results
        ?.map((section) => {
          const subsectionCandidate = section.subsections.find((x) => x.id === sectionId);
          if (subsectionCandidate) return subsectionCandidate;
        })
        .filter((x) => x)[0],
    [sectionsData, sectionId]
  );

  const invalidateKey = useSelector(invalidateKeyInJournalExecSelector);
  const [isOpenRemarkModal, setIsOpenRemarkModal] = useState(false);

  const parentName = useRef<string>();
  const sectionName = useRef<string>();

  const [activeRemark, setActiveRemark] = useState<IRemarkBySection | null>(null);

  const [filters, setFilters] = useState<RemarkBySectionFilters>({});

  const filterHandler = useCallback((name: keyof RemarkBySectionFilters, value: string) => {
    setFilters((prev) => {
      const filtersCandidate = { ...prev, [name]: value };
      if (value === "") delete filtersCandidate[name];
      return filtersCandidate;
    });
  }, []);

  useLayoutEffect(() => {
    if (!sectionsData?.results?.length && !sectionsData?.isLoading && !activeSubsection)
      dispatch(getJournalSectionsWithRemarks(objectId, {}));
  }, [objectId, sectionsData]);

  useLayoutEffect(() => {
    dispatch(getJournalRemarksBySection(objectId, sectionId, filters));
  }, [objectId, filters, sectionId, invalidateKey]);

  useEffect(() => {
    const sectionCandidate = remarksData?.results?.[0]?.expenditure_section;
    if (!sectionCandidate) return;
    parentName.current = sectionCandidate.parent?.name;
    sectionName.current = sectionCandidate.name;
  }, [remarksData]);

  useEffect(() => {
    if (!remarkId || !remarksData?.results?.length) return;

    const activeRemarkFromNotification = remarksData?.results?.filter((item) => item.id === Number(remarkId))[0];

    handleRowClick(activeRemarkFromNotification);
  }, [remarkId, remarksData]);

  const handleRowClick = useCallback((remark: IRemarkBySection) => {
    setActiveRemark(remark);
    setIsOpenRemarkModal(true);
  }, []);

  return (
    <>
      <div className={styles.sticky}>
        <div className={styles.headline}>
          <BackNavigationBar
            onBack={onBack}
            title={parentName.current || sectionName.current || ""}
            slashTitle={(parentName.current && sectionName.current) || undefined}
            className={styles.nav}
          />
          {!!activeSubsection?.count_remarks && (
            <RemarkCounts
              total={activeSubsection.count_remarks}
              created={activeSubsection.count_remarks_created}
              accepted={activeSubsection.count_remarks_accepted}
              review={activeSubsection.count_remarks_review}
            />
          )}
        </div>
        <RemarksHeader onChangeFilter={filterHandler as (n: string, v: string) => void} />
      </div>
      {remarksData?.isLoading && <Spinner />}
      {!remarksData?.isLoading &&
        remarksData?.results?.map((remark) => (
          <RemarkRow key={remark.id} remark={remark} onRowClick={() => handleRowClick(remark)} />
        ))}
      <RemarkModal
        isOpen={isOpenRemarkModal}
        setIsOpen={setIsOpenRemarkModal}
        workName={activeRemark?.expenditure?.name || ""}
        remarksCount={1}
        expenditureId={activeRemark?.expenditure?.id || -1}
        isShowOnlyAccepted={false}
        defaultRemarkId={activeRemark?.id}
      />
      {!remarksData?.isLoading && !remarksData?.results?.length && <EmptyPlaceholder img={jurnalIcon} />}
    </>
  );
};

export default React.memo(RemarksBySection);
