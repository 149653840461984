import cn from "classnames";
import React, { ChangeEventHandler, FocusEventHandler, MouseEventHandler, useCallback } from "react";

import { LOCALIZATION_CONFIG } from "../../../../constants/localization";
import { CURRENCY_ENUM } from "../../../../types/enums/CurrencyEnum";

import styles from "./DoubleInput.module.scss";

export type DoubleInputMeasure = CURRENCY_ENUM | "%";

export const Measure = {
  currency: LOCALIZATION_CONFIG.currency,
  percent: "%",
} as const;

const makeDisabledColor = (variant: IDoubleInputProps["variant"]) => {
  if (variant === "outline") return "white";
  return "#D9D9D9";
};

const makeEnabledColor = (variant: IDoubleInputProps["variant"]) => {
  if (variant === "outline") return "white";
  return "#DAEBFD";
};

export interface IDoubleInputProps {
  firstValue: string;
  secondValue?: string;
  firstValueMeasure?: DoubleInputMeasure;
  secondValueMeasure?: DoubleInputMeasure;
  firstValueLabel?: string;
  secondValueLabel?: string;
  firstInputSize?: number;
  secondInputSize?: number;
  onChangeFirstValue: (v: string) => void;
  onChangeSecondValue?: (v: string) => void;
  onBlurFirstInput?: FocusEventHandler;
  onBlurSecondInput?: FocusEventHandler;
  fontWeight?: number | string;
  generalLabel?: string;
  inputFontSize?: string | number;
  oneInputMode?: boolean;
  isFirstDisabled?: boolean;
  isSecondDisabled?: boolean;
  isFullHeight?: boolean;
  className?: string;
  firstInputType?: string;
  secondInputType?: string;
  variant?: "default" | "outline";
}

const DoubleInput = ({
  firstValue,
  secondValue,
  firstValueMeasure,
  secondValueMeasure,
  firstValueLabel,
  secondValueLabel,
  firstInputSize = 1,
  secondInputSize = 1,
  onChangeFirstValue,
  onChangeSecondValue,
  onBlurFirstInput,
  onBlurSecondInput,
  generalLabel,
  fontWeight = 400,
  inputFontSize = 1,
  oneInputMode = false,
  isFirstDisabled,
  isSecondDisabled,
  isFullHeight,
  className,
  firstInputType = "text",
  secondInputType = "text",
  variant = "default",
}: IDoubleInputProps) => {
  const handleChangeFirstValueEvent: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChangeFirstValue(e.target.value?.replaceAll(",", ".") ?? e.target.value);
    },
    [onChangeFirstValue]
  );

  const handleChangeSecondValueEvent: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChangeSecondValue?.(e.target.value?.replaceAll(",", ".") ?? e.target.value);
    },
    [onChangeSecondValue]
  );

  return (
    <div
      className={cn(
        styles.doubleInput,
        { [styles.oneInputMode]: oneInputMode, [styles.outline]: variant === "outline" },
        className
      )}
      aria-disabled={isFirstDisabled && isSecondDisabled}
      style={{
        height: firstValueLabel || secondValueLabel || generalLabel || isFullHeight ? "2.75rem" : "2.188rem",
        backgroundColor: oneInputMode
          ? isFirstDisabled
            ? makeDisabledColor(variant)
            : makeEnabledColor(variant)
          : undefined,
        backgroundImage: !oneInputMode
          ? `linear-gradient(to right, ${isFirstDisabled ? makeDisabledColor(variant) : makeEnabledColor(variant)} ${
              (100 * firstInputSize) / (firstInputSize + secondInputSize)
            }%, transparent ${
              (100 * firstInputSize) / (firstInputSize + secondInputSize)
            }%, transparent), linear-gradient(to left, ${
              isSecondDisabled ? makeDisabledColor(variant) : makeEnabledColor(variant)
            } ${(100 * secondInputSize) / (firstInputSize + secondInputSize)}%, transparent ${
              (100 * secondInputSize) / (firstInputSize + secondInputSize)
            }%, transparent)`
          : undefined,
      }}
    >
      <div className={styles.inputs}>
        <label
          className={cn(styles.inputContainer, {
            [styles.containerWithDivider]: !oneInputMode,
            [styles.withSmallDivider]: !!generalLabel,
          })}
          style={{ flex: firstInputSize, left: `${(100 * firstInputSize) / (firstInputSize + secondInputSize)}%` }}
        >
          <input
            style={{ fontWeight, fontSize: `${inputFontSize}rem`, height: `${inputFontSize}rem` }}
            className={styles.input}
            type={firstInputType}
            value={firstValue}
            title={firstValue}
            onChange={handleChangeFirstValueEvent}
            onBlur={onBlurFirstInput}
            disabled={isFirstDisabled}
          />
          {firstValueMeasure && (
            <span style={{ fontWeight }} className={cn(styles.measure, styles.firstValueMeasure)}>
              {firstValueMeasure}
            </span>
          )}
        </label>
        {!oneInputMode && (
          <label className={styles.inputContainer} style={{ flex: secondInputSize }}>
            <input
              style={{ fontWeight, fontSize: `${inputFontSize}rem`, height: `${inputFontSize}rem` }}
              className={styles.input}
              type={secondInputType}
              value={secondValue}
              title={secondValue}
              onChange={handleChangeSecondValueEvent}
              onBlur={onBlurSecondInput}
              disabled={isSecondDisabled}
            />
            {secondValueMeasure && (
              <span style={{ fontWeight }} className={styles.measure}>
                {secondValueMeasure}
              </span>
            )}
          </label>
        )}
      </div>
      {(!!firstValueLabel || !!secondValueLabel) && (
        <div className={styles.inputLabels}>
          {firstValueLabel && (
            <span style={{ fontWeight, flex: firstInputSize }} className={styles.label}>
              {firstValueLabel}
            </span>
          )}
          {secondValueLabel && (
            <span style={{ fontWeight, flex: secondInputSize }} className={styles.label}>
              {secondValueLabel}
            </span>
          )}
        </div>
      )}
      {!!generalLabel && <span className={styles.label}>{generalLabel}</span>}
    </div>
  );
};

export default React.memo(DoubleInput);
