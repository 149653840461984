import React from "react";
import { useParams } from "react-router-dom";

import MenuItem from "../../../NavigationBar/MenuItem";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useLocationArray } from "utils/hooks/useLocationArray";
import { useUrlModule } from "utils/hooks/useUrlModule";

import diskIcon from "images/icons/navigation/diskIcon.svg";

import styles from "./FileStorageButton.module.scss";
import { useTranslation } from "react-i18next";

const FileStorageButton = () => {
  const { t } = useTranslation();
  const routeModule = useUrlModule();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const locationArray = useLocationArray();
  const moduleName = useUrlModule();
  const isActive = locationArray.includes("files");

  const computedBuildingId = objectId || 0;

  const link =
    routeModule !== "objects"
      ? `/${routeModule}/files/${computedBuildingId}/`
      : `/${routeModule}/${computedBuildingId}/files/`;

  const isAddTaskIconShown = ["objects", "purchases", "constructing"].includes(moduleName);

  return (
    <MenuItem
      isExists
      title={t("Диск")}
      link={link}
      icon={diskIcon}
      isDirectlyActive={isActive}
      className={styles.link}
      isBottom
    />
  );
};

export default React.memo(FileStorageButton);
