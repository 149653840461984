import { memoize } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { compose } from "redux";

import { userSelector } from "../../../../../../../redux/modules/common/auth";
import {
  INVALIDATE_HANDLER_AGGREGATION,
  loadContracts,
} from "../../../../../../../redux/modules/common/building/aggregations";
import { detailDataSelector } from "../../../../../../../redux/modules/common/building/object/nowObject";
import {
  cancelSection,
  changeEstimateItemCost,
  changeEstimateItemDiscount,
  changeSection,
  confirmSection,
  deleteDefaultItemInHandler,
  deleteSection,
  getSectionKey,
  loadSection,
  resetSectionAction,
  resetSectionsAction,
  sectionsLoadingByKeySelector,
  sectionsSelector,
  sectionsWithConfirmedChildStatusSelector,
  sectionsWithNewChildStatusSelector,
} from "../../../../../../../redux/modules/common/building/sections/sections";

import { useUIIndicator } from "../../../../../../../hooks/uiIndicators/useUIIndicator";
import Section from "./components/Section/Section";
import SeparatedByChildStatusSections from "./components/SeparatedByChildStatusSections/SeparatedByChildStatusSections";
import { handlerIndexesInvalidateKeySelector } from "components/pages/Handler/widgets/HandlerIndexes/model/selectors";

import EmptyMessage from "../../../EmptyMessage/EmptyMessage";

import { MODULES_ENUM } from "../../../../../../../types/enums/ModulesEnum";
import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../constants";

import { stringifyArgs } from "../../../../../../../utils/helpers/stringifyArgs";
import { filterNotConfirmedSections } from "./utils/filterNotConfirmedSections";

const Sections = ({
  activeEstimateStateId,
  sectionId,
  checkerItems,
  checkOnce,
  activePricingMode,
  visibilityChecked,
  handleVisibilityCheck,
}) => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const sectionsWithNewChildStatus = useSelector(sectionsWithNewChildStatusSelector);
  const sectionsWithConfirmedChildStatus = useSelector(sectionsWithConfirmedChildStatusSelector);
  const sections = useSelector(sectionsSelector);
  const disabledSectionsByKey = useSelector(sectionsLoadingByKeySelector);
  const indexesInvalidateKey = useSelector(handlerIndexesInvalidateKeySelector);

  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");
  const statusFromQueryParamsIsNew = statusFromQueryParams === ESTIMATE_ITEM_STATUSES.NEW;

  const user = useSelector(userSelector);
  const building = useSelector(detailDataSelector);

  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;
  const canConfirmSections = userIsResponsibleEmployee;

  const isSubsections = sectionId;
  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;
  const isBuildingShared = building && building.is_shared;

  const handleCheckOnce = useMemo(
    () => memoize((estimateItemId) => (isChecked) => checkOnce(estimateItemId, isChecked), stringifyArgs),
    [checkOnce]
  );

  const displayingSections = useMemo(() => {
    if (isDraft || isProduction) return sections;
    if (!userIsResponsibleEmployee) return filterNotConfirmedSections(sections || []);
    if (statusFromQueryParams === ESTIMATE_ITEM_STATUSES.NEW) return sectionsWithNewChildStatus;
    if (statusFromQueryParams === ESTIMATE_ITEM_STATUSES.CONFIRMED) return sectionsWithConfirmedChildStatus;
    return sectionsWithConfirmedChildStatus;
  }, [
    statusFromQueryParams,
    isDraft,
    isProduction,
    sectionsWithNewChildStatus,
    sections,
    sectionsWithConfirmedChildStatus,
    userIsResponsibleEmployee,
  ]);

  const handleDeleteSection = useCallback(
    async (sectionId, section) => {
      if (section.is_default) {
        dispatch(
          deleteDefaultItemInHandler(building.id, section.id, () => {
            dispatch({ type: INVALIDATE_HANDLER_AGGREGATION });
            dispatch(loadContracts(building.id));
          })
        );
      } else {
        await compose(dispatch, deleteSection)(building.id, sectionId, activeEstimateStateId);
        compose(dispatch, loadContracts)(building.id);
      }
    },
    [building.id, activeEstimateStateId]
  );

  const onClickBySection = useCallback(
    (sectionId, sectionStatus) => {
      if (sectionStatus) {
        history.push(`${location.pathname}/${sectionId}?status=${sectionStatus}`);
      } else {
        history.push(`${location.pathname}/${sectionId}`);
      }
      compose(dispatch, resetSectionsAction)();
    },
    [history, location.pathname]
  );

  const { deleteIndicator } = useUIIndicator({
    data: { state: activeEstimateStateId },
    module: MODULES_ENUM.CONSTRUCTING,
    type: "change_estimate_state",
    buildingId: building.id,
  });

  const handleConfirmSection = useCallback(
    async (section) => {
      await compose(dispatch, confirmSection)(
        {
          buildingId: building.id,
          section,
          fromState: activeEstimateStateId,
        },
        deleteIndicator
      );
      compose(dispatch, loadContracts)(building.id);
    },
    [activeEstimateStateId, building.id, deleteIndicator]
  );

  const handleCancelSection = useCallback(
    async (section) => {
      await compose(dispatch, cancelSection)(
        {
          buildingId: building.id,
          section,
          fromState: activeEstimateStateId,
        },
        deleteIndicator
      );
    },
    [activeEstimateStateId, building.id, deleteIndicator]
  );

  const submitEditSectionName = useCallback(
    (changedName, sectionId, sectionEstimateState) => {
      compose(dispatch, changeSection)(building.id, sectionEstimateState, { id: sectionId, name: changedName });
    },
    [building.id]
  );

  const handleChangeDiscountCost = useCallback(
    (sectionId, cost) => {
      compose(dispatch, changeEstimateItemDiscount)(building.id, sectionId, cost);
    },
    [building.id]
  );

  const handleChangeCost = useCallback(
    (sectionId, cost) => {
      compose(dispatch, changeEstimateItemCost)(building.id, sectionId, cost);
    },
    [building.id]
  );

  const getSectionActions = useCallback(
    (section, isDirectlyCanConfirm) => {
      if (!isDraft && !userIsResponsibleEmployee) return;
      if (section?.is_default) {
        return {
          canEdit: true,
          onSaveEditName: (changedName) => submitEditSectionName(changedName, section.id, section.current_state),
          canRemove: isProduction,
          onRemove: () => handleDeleteSection(section.id, section),
        };
      }
      const isLoading = disabledSectionsByKey[getSectionKey(section.id, building.id)];
      const sectionIsInDraft = isDraft && section.current_state === "";
      const sectionIsEmpty = section.count_subsections === 0 && section.count_expenditures === 0;
      const canConfirm =
        typeof isDirectlyCanConfirm === "boolean"
          ? isDirectlyCanConfirm
          : canConfirmSections && section.status === "new" && !isDraft && statusFromQueryParams === "new";
      return {
        onConfirm: () => handleConfirmSection(section),
        onCancel: () => handleCancelSection(section),
        onSaveEditName: (changedName) => submitEditSectionName(changedName, section.id, section.current_state),
        canEdit: isProduction ? section.current_state !== ESTIMATE_STATES_IDS.LOCALE : true,
        onRemove: () => handleDeleteSection(section.id, section),
        isLoading,
        isDisabled: !isLoading && Object.values(disabledSectionsByKey).some((x) => x),
        canRemove:
          sectionIsInDraft && userIsResponsibleEmployee && (section.status === "confirmed" || section.added_manually),
        canConfirm,
      };
    },
    [
      isDraft,
      isProduction,
      userIsResponsibleEmployee,
      disabledSectionsByKey,
      building.id,
      canConfirmSections,
      handleConfirmSection,
      handleCancelSection,
      submitEditSectionName,
      handleDeleteSection,
      statusFromQueryParams,
    ]
  );

  useEffect(
    () => () => {
      compose(dispatch, resetSectionAction)();
    },
    []
  );

  const sectionsAreEmpty = sections?.length === 0;
  const sectionsByChildStatusAreEmpty =
    sectionsWithConfirmedChildStatus?.length === 0 && sectionsWithNewChildStatus?.length === 0;

  const isDisplaySeparatedSectionsByChildStatus =
    !isDraft &&
    !isProduction &&
    userIsResponsibleEmployee &&
    !isSubsections &&
    sectionsWithNewChildStatus?.length !== 0;
  const isDisplayEmptyMessage =
    !isDraft &&
    (isDisplaySeparatedSectionsByChildStatus ? sectionsByChildStatusAreEmpty : displayingSections?.length === 0);

  const isDisplayDraftEmptyMessage = isDraft && sectionsAreEmpty && isSubsections;
  const isShowSectionCheckbox = !isProduction && !isBuildingShared;

  useEffect(() => {
    if (!isProduction) return;
    if (sectionId) {
      compose(dispatch, loadSection)(building.id, sectionId, activeEstimateStateId);
    }
  }, [sectionId, building.id, isProduction, activeEstimateStateId, indexesInvalidateKey]);

  return (
    <>
      {isDisplayEmptyMessage && <EmptyMessage message="Чтобы продолжить, перенесите разделы и/или позиции" />}
      {isDisplayDraftEmptyMessage && (
        <EmptyMessage message={`Чтобы продолжить, добавьте ${isSubsections ? "подраздел" : "раздел"} сметы`} />
      )}
      {isDisplaySeparatedSectionsByChildStatus ? (
        <SeparatedByChildStatusSections
          checkerItems={checkerItems}
          handleCheckOnce={handleCheckOnce}
          activeEstimateStateId={activeEstimateStateId}
          onClickBySection={onClickBySection}
          getSectionActions={getSectionActions}
        />
      ) : (
        displayingSections?.map((section) => (
          <div key={section.id} onClick={() => onClickBySection(section.id, statusFromQueryParams)}>
            <Section
              isProduction={isProduction}
              section={section}
              isChecked={checkerItems[section.id]}
              check={handleCheckOnce(section.id)}
              activeEstimateStateId={activeEstimateStateId}
              activePricingMode={activePricingMode}
              actions={getSectionActions(section)}
              canChangeEstimateState={isShowSectionCheckbox}
              changeDiscountCost={handleChangeDiscountCost}
              changeCost={handleChangeCost}
              isSubsection={isSubsections}
              handleVisibilityCheck={handleVisibilityCheck}
              visibilityChecked={visibilityChecked}
            />
          </div>
        ))
      )}
    </>
  );
};

export default React.memo(Sections);
