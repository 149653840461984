import React from "react";

import TableReusableHead, { TableReusableHeaderCell } from "components/UI/atoms/TableReusable/TableReusableHead";

import styles from "./ProjectsTable.module.scss";

interface IProps {
  onChangeFilter: (name: string, value: string) => void;
  isSticky?: boolean;
}

const ProjectsTableHeader: React.FC<IProps> = ({ onChangeFilter, isSticky = true }) => {
  return (
    <TableReusableHead isSticky={isSticky} className={styles.row}>
      <TableReusableHeaderCell filterType="text" onChangeFilter={onChangeFilter} filterField="name">
        Наименование проекта
      </TableReusableHeaderCell>
      <TableReusableHeaderCell filterType="text" onChangeFilter={onChangeFilter} filterField="entity">
        Организация
      </TableReusableHeaderCell>
      <TableReusableHeaderCell filterType="text" onChangeFilter={onChangeFilter} filterField="customer">
        Заказчик
      </TableReusableHeaderCell>
    </TableReusableHead>
  );
};

export default ProjectsTableHeader;
