import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { MONTH } from "../../../redux/modules/common/building/manufacturing/manufacturing";
import {
  diagramFiltersSelector,
  equipmentTreeSelector,
  isLoadingChartDataSelector,
  isLoadingChartTreeSelector,
  manufacturingExpandedBranchesSelector,
  materialsTreeSelector,
  mimesTreeSelector,
  resourcesTreeSelector,
  treeSelector,
} from "../../../redux/modules/common/building/manufacturing/selectors";

import { Spinner } from "../../UI/Spinner/Spinner";
import TemplateSimple from "../../UI/templates/TemplateSimple/TemplateSimple";
import CalendarBackground from "../Manufacturing/components/CalendarBackground/CalendarBackground";
import CalendarDateLine from "../Manufacturing/components/CalendarDateLine/CalendarDateLine";
import DiagramActions from "../Manufacturing/components/DiagramActions/DiagramActions";
import Legend from "../Manufacturing/components/Legend/Legend";
import ManufacturingControls from "../Manufacturing/components/ManufacturingControls/ManufacturingControls";
import Month from "../Manufacturing/components/Month/Month";
import ProjectsTree from "../Manufacturing/components/ProjectsTree/ProjectsTree";
import ShiftsModal from "../Manufacturing/components/modals/ShiftsModal/ShiftsModal";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";

import ManufacturingProductEmptyPlaceholder from "./ManufacturingProductEmptyPlaceholder/ManufacturingProductEmptyPlaceholder";
import { Xwrapper } from "react-xarrows";

import { IRouterParamsWithObjectId } from "../../../types/routerTypes";
import {
  DiagramFilters,
  EQUIPMENT_TAB_ID,
  MATERIALS_TAB_ID,
  MIMES_TAB_ID,
  RESOURCES_TAB_ID,
  WORKS_TAB_ID,
} from "../Manufacturing/constants";
import { ISpittingTreeElement } from "../Manufacturing/types";

import { useQueryParams } from "../../../utils/hooks/useQueryParams";
import { useCalendarScroll } from "../Manufacturing/hooks/useCalendarScroll";
import { useChartViewModeTabs } from "../Manufacturing/hooks/useChartViewModeTabs";
import { useSpittingTree } from "../Manufacturing/hooks/useSpittingTree";
import { useTabsWithPermissions } from "../Manufacturing/hooks/useTabsWithPermissions";
import { useProjectTree } from "./hooks/useProjectTree";

import { getMonthInfo, getWeeksInYear } from "../Manufacturing/utils";
import {
  checkIsMonthBranchShownAsExpenditure,
  checkIsMonthBranchShownAsSection,
  checkIsShownSectionPlan,
  checkIsShownSuperSectionPlan,
  spittingTreeGenerationFn,
} from "./utils";

import scheduleIcon from "../../../images/icons/navigation/scheduleIcon.svg";

import styles from "./ManufacturingProduct.module.scss";

const ManufacturingProduct = () => {
  const isLoadingChartTree = useSelector(isLoadingChartTreeSelector);
  const isLoadingChartData = useSelector(isLoadingChartDataSelector);

  const currentDay = moment().date();
  const currentMonth = moment().month();

  const { objectId: projectId } = useParams<IRouterParamsWithObjectId>();

  const worksTree = useSelector(treeSelector);
  const materialsTree = useSelector(materialsTreeSelector);
  const mimesTree = useSelector(mimesTreeSelector);
  const resourcesTree = useSelector(resourcesTreeSelector);
  const equipmentTree = useSelector(equipmentTreeSelector);
  const { chartViewMode } = useChartViewModeTabs();

  const { tab, setTab, expenditureTypesToLoad, manufacturingTabs } = useTabsWithPermissions();

  const diagramFilters = useSelector(diagramFiltersSelector);
  const isPlanEditing = diagramFilters[DiagramFilters.plans_editing_enabled];
  // const isShowOutOfEstimate = useSelector(diagramFiltersSelector)[DiagramFilters.out_of_estimate_enabled];
  // const showOnlyEstimate = tab === WORKS_TAB_ID && !isShowOutOfEstimate;

  const projectTree = useMemo(() => {
    switch (tab) {
      case WORKS_TAB_ID:
        return worksTree;
      case MATERIALS_TAB_ID:
        return mimesTree;
      case MIMES_TAB_ID:
        return mimesTree;
      case RESOURCES_TAB_ID:
        return resourcesTree;
      case EQUIPMENT_TAB_ID:
        return equipmentTree;
    }
  }, [tab, worksTree, materialsTree, mimesTree, resourcesTree, equipmentTree]);

  const expandedBranches = useSelector(manufacturingExpandedBranchesSelector);
  const [month, setMonth] = useState(() => getMonthInfo(currentMonth));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [filterValue, setFilterValue] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState({
    dateEnd: null,
    dateStart: null,
  });

  const [isFiltersUsed, setIsFiltersUsed] = useState(false);

  useEffect(() => {
    if (!isFiltersUsed && filterValue || selectedPeriod.dateStart || selectedPeriod.dateEnd) {
      setIsFiltersUsed(true);
    }
  }, [filterValue, selectedPeriod]);

  const startWeek = 1;
  const endWeek = getWeeksInYear(year);

  const containerRef = useRef(null);
  const calendarRef = useRef(null);

  const isShiftsModalOpenedQuery = useQueryParams("openShiftModal");
  const [isShiftsModalOpened, setIsShiftsModalOpened] = useState<boolean>(!!isShiftsModalOpenedQuery);
  const onShiftsModalClose = useCallback(() => {
    setIsShiftsModalOpened(false);
  }, []);

  const { handleDateChange, scrollCalendar, touchedYears } = useCalendarScroll({
    calendarRef,
    month,
    year,
    setMonth,
    setYear,
    projectId,
  });

  const { generateSpittingTree, isGeneratingTree, spittingTree } = useSpittingTree({
    spittingTreeGenerationFn,
    spittingTreeFnArgs: {
      projectTree,
      tab,
      expandedBranches,
      // showOnlyEstimate
    },
    projectId,
    filterParams: filterValue,
    isProduction: true,
    selectedPeriod,
  });

  const { toggleBranch, checkIsExpandedBranchId } = useProjectTree({
    expenditureTypesToLoad,
    projectId,
    year,
    month,
    startWeek,
    endWeek,
    expandedBranches,
    generateSpittingTree,
  });

  useEffect(generateSpittingTree, [projectTree, touchedYears?.length]);

  const checkIsCheckboxTrue = useCallback(
    (branch: ISpittingTreeElement) => {
      const expandedBranchesHasBranch = checkIsExpandedBranchId(branch.id);
      return branch.lvl === 2 || branch.lvl === 1 ? !expandedBranchesHasBranch : expandedBranchesHasBranch;
    },
    [checkIsExpandedBranchId]
  );

  const checkIsCheckboxVisible = useCallback(
    (branch: ISpittingTreeElement) => branch.lvl < 3 && branch.childCount > 0,
    []
  );

  const isShownSpinner = isGeneratingTree || isLoadingChartTree || isLoadingChartData;
  const isFilterActive = useMemo(() => {
    return !!filterValue || (!!selectedPeriod.dateEnd && !!selectedPeriod.dateStart);
  }, [filterValue, selectedPeriod]);
  const isShownPlaceholder = !spittingTree?.length && !isFilterActive && !isShownSpinner && !isFiltersUsed;

  useEffect(() => {
    setSelectedPeriod({
      dateEnd: null,
      dateStart: null,
    });
    setFilterValue("");
  }, [projectId, tab]);

  if (!manufacturingTabs?.length && !isShownSpinner) {
    return (
      <TemplateSimple>
        <ForbiddenPage />
      </TemplateSimple>
    );
  }

  return (
    <TemplateSimple contentClassName={styles.template}>
      {isShownSpinner ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          <ManufacturingControls
            year={year}
            month={month}
            handleDateChange={handleDateChange}
            scrollCalendar={scrollCalendar}
            manufacturingTabs={manufacturingTabs}
            tab={tab}
            setTab={setTab}
            isEmpty={isShownPlaceholder}
          />
          {isShownPlaceholder ? (
            <ManufacturingProductEmptyPlaceholder img={scheduleIcon} isSwitcherVisible={tab === WORKS_TAB_ID} />
          ) : (
            <div className={styles.content} ref={containerRef}>
              <div
                ref={calendarRef}
                className={cn(styles.calendar, "diagram_calendar")}
                style={{ height: `${spittingTree.length * 3}rem` }}
              >
                <CalendarDateLine year={year} touchedYears={touchedYears} />
                <ProjectsTree
                  isHideFilters={tab === "work"}
                  spittingTree={spittingTree}
                  checkIsExpandedBranchId={checkIsExpandedBranchId}
                  toggleBranch={toggleBranch}
                  type={tab}
                  checkIsCheckboxTrue={checkIsCheckboxTrue}
                  checkIsCheckboxVisible={checkIsCheckboxVisible}
                  isProduction
                  isPlanEditing={isPlanEditing}
                  setFilterValue={setFilterValue}
                  setSelectedPeriod={setSelectedPeriod}
                  isFilterVisible={tab === WORKS_TAB_ID}
                  isPeriodVisible={tab === WORKS_TAB_ID}
                />
                <CalendarBackground year={year} unitHeight={spittingTree.length} touchedYears={touchedYears} />
                <Xwrapper>
                  <Month
                    tree={spittingTree}
                    year={year}
                    touchedYears={touchedYears}
                    today={currentMonth === month?.id ? currentDay : -1}
                    startWeek={startWeek}
                    endWeek={endWeek}
                    type={tab}
                    checkIsMonthBranchShownAsSection={checkIsMonthBranchShownAsSection}
                    checkIsMonthBranchShownAsExpenditure={checkIsMonthBranchShownAsExpenditure}
                    checkIsShownSectionPlan={checkIsShownSectionPlan}
                    checkIsShownSuperSectionPlan={checkIsShownSuperSectionPlan}
                    isGeneratingSpittingTree={isGeneratingTree}
                    isInConstructing={false}
                  />
                </Xwrapper>
                <div className={styles.legendaWrapper}>
                  <div className={styles.legenda}>
                    <Legend type={tab} />
                  </div>
                </div>
                {tab === WORKS_TAB_ID && (
                  <div
                    className={cn(styles.diagramActionsWrapper, {
                      [styles.diagramActionsWrapperMoreOffsetTop]: chartViewMode !== MONTH,
                    })}
                  >
                    <DiagramActions year={year} />
                  </div>
                )}
                {isShiftsModalOpened && (
                  <ShiftsModal isOpened={true} onClose={onShiftsModalClose} objectId={projectId} />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </TemplateSimple>
  );
};

export default ManufacturingProduct;
