import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { userSelector } from "redux/modules/common/auth";
import { createTicket } from "redux/modules/common/building/journal/thunks";
import { loadExpenditure, loadShedules, shedulesSelector } from "redux/modules/common/building/shedules";
import { resetWorkersToInitialAction } from "redux/modules/common/building/workers";

import { ExpenditureTypeEnum } from "../../../../../../../types/enums/ExpenditureTypeEnum";
import { EXPENDITURE_TYPES } from "constants/constant";
import { IFile } from "types/interfaces/Files";

import { useLocationArray } from "utils/hooks/useLocationArray";

import { mapAddedToTicketMaterials, mapAddedToTicketMims } from "./utils";

export type materialType = {
  id: number;
  local_count?: number;
  using_ids?: number[];
};

export type mimsType = {
  id: number;
  local_count?: number;
  service_id?: number;
};

type userType = {
  id: number;
  count?: number;
};

type submitDataType = {
  section_id: number;
  count: number;
  start_at: string;
  end_at: string;
  count_workers?: any[]; //ANY
  stock_using_materials?: any[]; //ANY
  ticket_services?: any[]; //ANY
  expenditure_id?: number;
  title?: string;
};

type propsType = {
  addedMaterials?: materialType[] | any[];
  addedMims?: mimsType[] | any[];
  addedUsers?: userType[];
  objectId: string;
  clearMaterials: () => void;
  clearWorkers: () => void;
  handleClose: () => void;
  files?: IFile[];
  directlySection?: number;
  directlyWork?: number;
  onSelectWorkGroup?: (workGroupId: number | null) => void;
  count: number;
  setCount: (_: number) => void;
};

export const useProductIntervalForm = ({
  addedMaterials,
  addedUsers,
  objectId,
  clearMaterials,
  clearWorkers,
  handleClose,
  files,
  directlySection,
  directlyWork,
  addedMims,
  onSelectWorkGroup,
  count,
  setCount,
}: propsType) => {
  const [searchWork, setSearchWork] = useState("");
  const [isPending, setIsPending] = React.useState(false);
  const dispatch = useDispatch();
  const locationArray = useLocationArray();

  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const sections = useSelector(shedulesSelector)?.sections;
  const user = useSelector(userSelector);
  const [activeSection, setActiveSection] = useState<number | null>(directlySection ? +directlySection : null);
  const [works, setWorks] = useState([]);
  const [activeWork, setActiveWork] = useState<number | null>(directlyWork ? +directlyWork : null);
  const [isChooseWork, setIsChooseWork] = useState(true);
  const [title, setTitle] = useState("");

  const handleSubmit = async (successCallback?: (data: any) => void) => {
    const data: submitDataType = {
      section_id: directlySection || (activeSection as number),
      count: count,
      start_at: moment(dateStart).format("YYYY-MM-DD"),
      end_at: moment(dateEnd).format("YYYY-MM-DD"),
      count_workers: [],
      stock_using_materials: mapAddedToTicketMaterials(addedMaterials),
      ticket_services: mapAddedToTicketMims(addedMims),
    };
    if (activeWork) data.expenditure_id = activeWork;
    if (title) data.title = title;
    if (directlyWork) data.expenditure_id = directlyWork;

    if (addedUsers) {
      data.count_workers = addedUsers.map((item) => ({ worker_id: item.id, count: item.count }));
    }
    setIsPending(true);
    await dispatch(
      createTicket(objectId, data, files, locationArray, {
        finallyCallback: () => {
          setIsPending(false);
          handleClose();
          clearForm();
        },
        successCallback
      })
    );
  };

  const clearForm = () => {
    setActiveSection(null);
    setActiveWork(null);
    setIsChooseWork(true);
    setTitle("");
    setCount?.(0);
    setDateStart(moment());
    setDateEnd(moment());
    clearWorkers && clearWorkers();
    clearMaterials && clearMaterials();
  };

  const subSections = sections && [].concat(...sections?.map((section: any) => section.subsections)).filter((x) => x);

  const getObjectList = () => {
    return subSections && subSections.length > 0
      ? subSections
          .filter((subsection) => subsection.count_expenditures.work > 0 || subsection.count_expenditures.groups > 0)
          // .filter((el: any) => !isExpenditureSharedToMyContractor(el) && !isExpenditureSharedToMeButCantAddInterval(el)) TODO: Проверить, что расшаренных секций нет в списке
          .map((item: any) => ({ id: item?.id, name: item?.name, label: item?.name })) //ANY
      : [];
  };

  const getWorksList = () => {
    return works && works.length > 0
      ? works
          // .filter((el: any) => !isExpenditureSharedToMyContractor(el) && !isExpenditureSharedToMeButCantAddInterval(el)) TODO: Проверить, что расшаренных расценок нет в списке
          .map((item: any) => ({ id: item?.id, name: item?.name, label: item?.name, number: item?.number }))
          .filter(
            (el) =>
              el.name.trim().toLowerCase().includes(searchWork.trim().toLowerCase()) ||
              String(el.number).includes(searchWork)
          )
      : [];
  };

  const handleChangeSection = useCallback(
    (newActiveSubSectionId: number | string) => {
      setActiveSection(+newActiveSubSectionId);
      const activeSubSection = subSections.find((section: any) => +section.id === +newActiveSubSectionId);
      const groupedIds = activeSubSection?.groups?.flatMap((group) => group?.expenditure_ids);
      const worksAndGroups = (activeSubSection?.groups?.map((group) => ({ ...group, isGroup: true })) || []).concat(
        activeSubSection?.expenditures?.filter(
          (expenditure) =>
            !groupedIds?.includes(expenditure.id) && expenditure.expenditure_type === ExpenditureTypeEnum.work
        ) || []
      );
      setWorks(worksAndGroups);
      onSelectWorkGroup?.(null);
      setActiveWork(null);
      setSearchWork("");
    },
    [subSections, onSelectWorkGroup]
  );

  const handleChangeWorks = (id: number | string) => {
    if (works?.findIndex((x) => x?.id === id && x.isGroup) === -1) {
      onSelectWorkGroup?.(null);
      dispatch(loadExpenditure(objectId, id));
    } else {
      onSelectWorkGroup?.(+id);
    }
    setActiveWork(+id);
  };

  useEffect(() => {
    dispatch(
      loadShedules(objectId, { expenditure_type: EXPENDITURE_TYPES.WORK, exclude_shared: 1, without_hidden: 1 })
    );
  }, [objectId]);

  useEffect(() => {
    return () => {
      compose(dispatch, resetWorkersToInitialAction)();
    };
  }, []);

  useEffect(() => {
    if (!isChooseWork) {
      setActiveWork(null);
    } else {
      setTitle("");
    }
  }, [isChooseWork]);

  return {
    handleChangeWorks,
    handleChangeSection,
    getWorksList,
    getObjectList,
    clearForm,
    handleSubmit,
    dateEnd,
    dateStart,
    setDateEnd,
    setDateStart,
    user,
    activeSection,
    activeWork,
    isChooseWork,
    setIsChooseWork,
    title,
    setTitle,
    isPending,
    setSearchWork,
    searchWork,
  };
};
