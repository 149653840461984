import { message } from "antd";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { actingActions } from "./actions";
import { actingApi } from "./api";

import { ICreateKs2, ICreateKs3 } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getActingEstimates = (buildingId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().acting.estimates?.[buildingId]?.results?.length) {
    dispatch(actingActions.setEstimatesIsLoading(true, buildingId));
  }
  actingApi
    .getLsrs(buildingId)
    .then(({ data }) => {
      dispatch(actingActions.setEstimates(data.results, buildingId));
    })
    .finally(() => {
      dispatch(actingActions.setEstimatesIsLoading(false, buildingId));
    });
};

export const getActingSections =
  (buildingId: string, lsrId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = String(lsrId);
    if (!getState().acting.sections?.[key]?.results?.length) {
      dispatch(actingActions.setSectionsIsLoading(true, key));
    }
    actingApi
      .getSections(buildingId, lsrId)
      .then(({ data }) => {
        dispatch(actingActions.setSections(data.results, key));
      })
      .finally(() => {
        dispatch(actingActions.setSectionsIsLoading(false, key));
      });
  };

export const getActingExpenditures =
  (buildingId: string, sectionId: number, estimateId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = String(sectionId);
    if (!getState().acting.expenditures?.[key]?.results?.length) {
      dispatch(actingActions.setExpendituresIsLoading(true, key));
    }
    actingApi
      .getExpenditures(buildingId, sectionId)
      .then(({ data }) => {
        dispatch(actingActions.setExpenditures(data.results, key, estimateId));
      })
      .finally(() => {
        dispatch(actingActions.setExpendituresIsLoading(false, key));
      });
  };

export const getKs2ListByBuilding = (buildingId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().acting.ks2?.[buildingId]?.results?.length) {
    dispatch(actingActions.setKsListIsLoading(true, buildingId));
  }
  actingApi
    .getKs2List(buildingId)
    .then(({ data }) => {
      dispatch(actingActions.setKsList(data.results, buildingId));
    })
    .finally(() => {
      dispatch(actingActions.setKsListIsLoading(false, buildingId));
    });
};

export const createKs2 = (buildingId: string, creatingKs: ICreateKs2) => (dispatch: Dispatch) => {
  actingApi
    .checkKs2Intersecting(buildingId, creatingKs)
    .then(({ data }) => {
      if (!!data.errors?.length) {
        dispatch(actingActions.setIntersectingExpenditures(data.errors));
      } else {
        actingApi.createKs2(buildingId, creatingKs).then(() => {
          dispatch(actingActions.invalidateCache());
          dispatch(actingActions.deselectAllForKs2(buildingId));
          message.success("Успешно сформировано");
          dispatch(actingActions.setIntersectingExpenditures([]));
        });
      }
    })
    .catch(errorCatcher);
};

export const checkIntersectingKs2 =
  (buildingId: string, creatingKs: ICreateKs2, successCallback: () => void) => (dispatch: Dispatch) => {
    actingApi
      .checkKs2Intersecting(buildingId, creatingKs)
      .then(({ data }) => {
        successCallback();
        if (!!data.errors?.length) {
          dispatch(actingActions.setIntersectingExpenditures(data.errors));
        }
      })
      .catch(errorCatcher);
  };

export const createKsAfterExcluding = (buildingId: string, creatingKs: ICreateKs2) => (dispatch: Dispatch) => {
  actingApi
    .createKs2(buildingId, creatingKs)
    .then(() => {
      dispatch(actingActions.invalidateCache());
      dispatch(actingActions.deselectAllForKs2(buildingId));
      message.success("Успешно сформировано");
      dispatch(actingActions.setIntersectingExpenditures([]));
    })
    .catch((e) => {
      errorCatcher(e);
    });
};

export const getDetailedKs2 =
  (buildingId: string, ks2Id: number) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().acting?.detailedKs2?.[ks2Id]?.id) {
      dispatch(actingActions.setDetailedKs2IsLoading(ks2Id, true));
    }
    actingApi
      .getdetailedKs2(buildingId, ks2Id)
      .then(({ data }) => {
        dispatch(actingActions.setDetailedKs2(data));
      })
      .finally(() => {
        dispatch(actingActions.setDetailedKs2IsLoading(ks2Id, false));
      });
  };

export const createKs3 = (buildingId: string, data: ICreateKs3) => (dispatch: Dispatch) => {
  actingApi
    .createKs3(buildingId, data)
    .then(() => {
      dispatch(actingActions.deSelectAllKs2Ids(buildingId));
      message.success("Успешно сформировано");
      dispatch(actingActions.invalidateCache());
    })
    .catch(errorCatcher);
};

export const editKs2 =
  (
    buildingId: string,
    ks2Id: number,
    deletingExpenditures: number[],
    data: Omit<ICreateKs2, "section_ids" | "expenditure_ids">
  ) =>
  (dispatch: Dispatch) => {
    Promise.all([
      actingApi.editKs2(buildingId, ks2Id, data),
      deletingExpenditures.map((expId) => actingApi.deleteExpenditureFromKs2(buildingId, ks2Id, expId)),
    ])
      .then(() => {
        message.success("Успешно отредактировано");
        dispatch(actingActions.invalidateCache());
      })
      .catch(errorCatcher);
  };

export const editKs3 =
  (
    buildingId: string,
    ks3Id: number,
    deletingExpenditures: number[],
    data: Omit<ICreateKs2, "section_ids" | "expenditure_ids">
  ) =>
  (dispatch: Dispatch) => {
    Promise.all([
      actingApi.editKs3(buildingId, ks3Id, data),
      deletingExpenditures.map((expId) => actingApi.deleteExpenditureFromKs3(buildingId, ks3Id, expId)),
    ])
      .then(() => {
        message.success("Успешно отредактировано");
        dispatch(actingActions.invalidateCache());
      })
      .catch(errorCatcher);
  };

export const getDetailedKs3 = (buildingId: string, ks3Id: number) => (dispatch: Dispatch) => {
  actingApi.getDetailedKs3(buildingId, ks3Id).then(({ data }) => {
    dispatch(actingActions.setDetailedKs3(data));
  });
};

export const getKs2ListByEstimate =
  (buildingId: string, estimateId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().acting.ks2byEstimate?.[estimateId]?.results?.length) {
      dispatch(actingActions.setKs2ListByEstimateLoading(true, estimateId));
    }
    actingApi
      .getKs2List(buildingId, estimateId)
      .then(({ data }) => {
        dispatch(actingActions.setKs2ListByEstimate(data.results, estimateId));
      })
      .finally(() => {
        dispatch(actingActions.setKs2ListByEstimateLoading(false, estimateId));
      });
  };
