import cn from "classnames";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  addPaymentFile,
  changePaymentDate,
  payPayment,
} from "../../../../../../../redux/modules/common/building/documents/bills";

import Checkbox from "../../../../../../UI/Checkbox";
import { NavLinkIcon } from "../../../../../../UI/NavLinkIcon/NavLinkIcon";
import FileRow from "../../../../../../UI/atoms/FileRow/FileRow";
import FileUploader from "../../../../../../UI/atoms/FileUploader/FileUploader";
import Calendar from "../../../../../../UI/molecules/Calendar/Calendar";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { splitThousands } from "../../../../../../../utils/formatters/splitThousands";
import downloadFile from "../../../../../../../utils/helpers/download";

import calendarBlue from "images/icons/calendarBlue.svg";

import styles from "./BillPayment.module.scss";

const handleDownloadFile = (file) => downloadFile(file.link, file.name);

function BillPayment({ parentId, payment, permissions, isSimplified }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const [localPaid, setLocalPaid] = React.useState(payment.paid);
  const [isPaying, setIsPaying] = React.useState(false);
  const urlAdapter = useUrlModuleWithObjectId();

  const dateHandler = (date) => {
    dispatch(changePaymentDate(parentId, payment.id, moment(date).format("YYYY-MM-DD")));
  };

  const checkHandler = _.debounce(() => {
    setChecked((prev) => !prev);
    if (!payment.file || isPaying || localPaid) return;
    setIsPaying(true);
    dispatch(
      payPayment(
        parentId,
        payment.id,
        () => setLocalPaid(true),
        () => setIsPaying(false)
      )
    );
  }, 5);

  const fileHandler = (e) => {
    dispatch(addPaymentFile(parentId, payment.id, e.target.files[0]));
  };

  const serializedFile = useMemo(
    () => ({
      name: payment.file_name,
      link: payment.file,
    }),
    [payment.file, payment.file_name]
  );

  const packingListNumber = payment.packinglist?.number ? payment.packinglist.number : "Ожидается";

  useEffect(() => {
    setLocalPaid(payment.paid);
  }, [payment.paid]);

  return (
    <div className={styles.container}>
      <div className={cn(styles.headline, { [styles.disabled]: payment.paid })}>
        <div className={styles.text}>Платеж</div>
        <div>

          <Calendar
            icon={calendarBlue}
            classNameSelect={cn(styles.calendar, { [styles.disabled]: payment.paid })}
            value={moment(payment.date)}
            format={'DD.MM.YY'}
            setValue={(val) => dateHandler(val)}
            disabled={payment.paid}
            variant="brandColorTransparent"
          />
        </div>
      </div>
      {localPaid ? (
        <div className={styles.isPaid}>Оплачен</div>
      ) : (
        <div className={styles.isWaiting}>Ожидает оплаты</div>
      )}
      <div className={styles.terms}>
        {payment.payment_term?.payment_type_name} {payment.payment_term?.percent}%
      </div>
      {payment.packinglist && !isSimplified ? (
        <NavLinkIcon
          className={styles.upd}
          title={`УПД №${packingListNumber}`}
          to={`/${urlAdapter}/documents/packing-lists/${payment.packinglist.id}`}
        />
      ) : (
        <span className={styles.textUpd} title={packingListNumber}>{`УПД: №${packingListNumber}`}</span>
      )}
      <div className={styles.amount}>Сумма: {splitThousands(payment.amount)}</div>
      {!payment.file ? (
        permissions.viewAddBillPaymentsFile && <FileUploader changeHandler={fileHandler} />
      ) : (
        <FileRow file={serializedFile} download={handleDownloadFile} />
      )}
      {!localPaid && permissions.viewBillPaymentsMakePaid && (
        <div className={`${!payment.file ? styles.disabledPay : ""} ${styles.pay}`} onClick={checkHandler}>
          <div style={{ display: "flex" }}>
            <Checkbox checked={checked} disabled={!payment.file} />
            <div style={{ alignSelf: "center", marginLeft: 7 }}>Пометить оплаченным</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(BillPayment);
