import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

export const stateSelector = (state: RootState) => state.acting;

export const actingInvalidateKeySelector = createSelector(stateSelector, (state) => state.invalidateKey);
export const actingEstimatesSelector = createSelector(stateSelector, (state) => state.estimates);
export const actingSectionsSelector = createSelector(stateSelector, (state) => state.sections);
export const actingExpendituresSelector = createSelector(stateSelector, (state) => state.expenditures);
export const actingKs2ListSelector = createSelector(stateSelector, (state) => state.ks2);
export const actingIntersectingExpendituresSelector = createSelector(
  stateSelector,
  (state) => state.intersectingExpenditures
);
export const actingDetailedKs2Selector = createSelector(stateSelector, (state) => state.detailedKs2);
export const actingSelectedKs2IdsSelector = createSelector(stateSelector, (state) => state.selectedKs2Ids);
export const actingDetailedKs3Selector = createSelector(stateSelector, (state) => state.detailedKs3);
export const actingKs2ListByEstimateSelector = createSelector(stateSelector, (state) => state.ks2byEstimate);
