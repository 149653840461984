import { ManufacturingModalType } from "../../../../../components/pages/Manufacturing/components/modals/ManufacturingModal/useManufacturingModal";

import {
  ADD_INTERVAL_LINK,
  DELETE_ARROW,
  DROP_HIGHLIGHTED_INTERVAL,
  DROP_LOADED_CHART_MONTHS,
  GET_ESTIMATE_EQUIPMENT_TREE,
  GET_ESTIMATE_MATERIALS_TREE,
  GET_ESTIMATE_MIMES_TREE,
  GET_ESTIMATE_RESOURCES_TREE,
  GET_ESTIMATE_TREE,
  GET_INTERVAL,
  GET_MATERIALS,
  GET_MATERIAL_INTERVAL,
  GET_MIMES,
  GET_MODAL_FILES,
  GET_MODAL_INTERVAL,
  GET_MODAL_INTERVAL_LIST,
  GET_MODAL_MATERIALS,
  GET_PLAN,
  GET_PROJECTS,
  GET_WEEK_MATERIALS,
  GET_WEEK_MIMES,
  GET_WEEK_PLAN,
  HIGHLIGHT_INTERVAL,
  MARK_CONSTRUCTING_PROJECT_DATA_TYPE_MONTH_YEAR,
  MARK_MANUFACTURING_PROJECT_DATA_TYPE_MONTH_YEAR,
  PROJECT_ESTIMATE_SET,
  PUSH_FROM_RELATIONS,
  PUSH_TO_RELATIONS,
  REMOVE_FROM_RELATION,
  REMOVE_TO_RELATION,
  SET_ACTIVE_BRANCH,
  SET_ARROWS,
  SET_ARROW_HASH,
  SET_AVAILABLE_INTERVALS,
  SET_AVAILABLE_SECTIONS,
  SET_CHART_VIEW_MODE,
  SET_CONSTRUCTING_EXPANDED_BRANCHES,
  SET_CRITICAL_DATES,
  SET_IS_BEING_DRAGGED,
  SET_IS_ENABLED_LINKING,
  SET_IS_LOADING_CHART_DATA,
  SET_IS_LOADING_CHART_TREE,
  SET_IS_LOADING_MODAL,
  SET_IS_LOADING_PROJECTS,
  SET_IS_LOCKED_INTERVAL_EDITING,
  SET_MANUFACTURING_EXPANDED_BRANCHES,
  SET_MANUFACTURING_HASH,
  SET_MATERIAL_DATA,
  SET_MATERIAL_WEEK_DATA,
  SET_MIMES_DATA,
  SET_MIMES_WEEK_DATA,
  SET_MODAL_DATA,
  SET_MONTH_MARKERS,
  SET_PROJECT_DATA,
  SET_PROJECT_DETAILED_COUNT_EXPENDITURES,
  SET_PROJECT_WEEK_DATA,
  SET_RELATIONS_FROM_CURRENT_INTERVAL,
  SET_RELATIONS_TO_CURRENT_INTERVAL,
  SET_TAB,
  UPDATE_ARROW,
  UPDATE_DIAGRAM_FILTERS,
  UPDATE_FROM_RELATION,
  UPDATE_TO_RELATION,
  getInitialModalData,
} from "./manufacturing";

import { IBuildingRootResponse, IProjectTreeResponse, ISetProjectDetailedCountExpendituresPayload } from "./types";

export const getEstimateTreeAction = (tree: IProjectTreeResponse) => ({
  type: GET_ESTIMATE_TREE,
  payload: tree,
});

export const getEstimateMaterialsTreeAction = (materialsTree: IProjectTreeResponse) => ({
  type: GET_ESTIMATE_MATERIALS_TREE,
  payload: materialsTree,
});

export const getEstimateMimesTreeAction = (mimesTree: IProjectTreeResponse) => ({
  type: GET_ESTIMATE_MIMES_TREE,
  payload: mimesTree,
});

export const getEstimateResourcesTreeAction = (resourcesTree: IProjectTreeResponse) => ({
  type: GET_ESTIMATE_RESOURCES_TREE,
  payload: resourcesTree,
});

export const getEstimateEquipmentTreeAction = (equipmentTree: IProjectTreeResponse) => ({
  type: GET_ESTIMATE_EQUIPMENT_TREE,
  payload: equipmentTree,
});

export const getPlanAction = (plan) => ({
  type: GET_PLAN,
  payload: plan,
});

export const markManufacturingYearMonthAction = ({ projectId, fetchedDates, dataType }) => ({
  type: MARK_MANUFACTURING_PROJECT_DATA_TYPE_MONTH_YEAR,
  payload: {
    projectId,
    dataType,
    fetchedDates,
  },
});

export const markConstructingYearMonthAction = ({ projectId, fetchedDates, dataType }) => ({
  type: MARK_CONSTRUCTING_PROJECT_DATA_TYPE_MONTH_YEAR,
  payload: {
    projectId,
    dataType,
    fetchedDates,
  },
});

export const getMaterialsAction = (materials) => ({
  type: GET_MATERIALS,
  payload: materials,
});

export const getMimesAction = (mimes) => ({
  type: GET_MIMES,
  payload: mimes,
});

export const getWeekMimesAction = (weekMimes, year?: string) => ({
  type: GET_WEEK_MIMES,
  payload: { ...weekMimes, year },
});

export const getWeekPlanAction = (weekPlan, year?: string) => ({
  type: GET_WEEK_PLAN,
  payload: { ...weekPlan, year },
});

export const getWeekMaterialsAction = (weekMaterials, year?: string) => ({
  type: GET_WEEK_MATERIALS,
  payload: { ...weekMaterials, year },
});

export const getIntervalAction = (interval) => ({
  type: GET_INTERVAL,
  payload: interval,
});

export const getMaterialIntervalAction = (materialInterval) => ({
  type: GET_MATERIAL_INTERVAL,
  payload: materialInterval,
});

export const setChartViewModeAction = (chartViewMode) => ({
  type: SET_CHART_VIEW_MODE,
  payload: chartViewMode,
});

export const setManufacturingTabAction = (manufacturingTab) => ({
  type: SET_TAB,
  payload: manufacturingTab,
});

export const startLoadingModalAction = (modalType: ManufacturingModalType) => ({
  type: SET_IS_LOADING_MODAL,
  payload: { modalType, isLoading: true },
});

export const stopLoadingModalAction = (modalType: ManufacturingModalType) => ({
  type: SET_IS_LOADING_MODAL,
  payload: { modalType, isLoading: false },
});

export const getModalIntervalListAction = (modalType: ManufacturingModalType, intervals: any[]) => ({
  type: GET_MODAL_INTERVAL_LIST,
  payload: { modalType, intervals },
});

export const getModalIntervalAction = (modalType: ManufacturingModalType, data: any) => ({
  type: GET_MODAL_INTERVAL,
  payload: { modalType, data },
});

export const getModalIntervalFilesAction = (modalIntervalFiles) => ({
  type: GET_MODAL_FILES,
  payload: modalIntervalFiles,
});

export const getModalIntervalMaterialsAction = (modalIntervalFiles) => ({
  type: GET_MODAL_MATERIALS,
  payload: modalIntervalFiles,
});

export const setModalIntervalDataAction = (modalIntervalData: ReturnType<typeof getInitialModalData>) => ({
  type: SET_MODAL_DATA,
  payload: modalIntervalData,
});

export const setIsLoadingChartDataAction = (isLoading: boolean) => ({
  type: SET_IS_LOADING_CHART_DATA,
  payload: isLoading,
});

export const setIsLockedIntervalEditing = (isLoading: boolean) => ({
  type: SET_IS_LOCKED_INTERVAL_EDITING,
  payload: isLoading,
});

export const setProjectDataAction = (projectData) => ({
  type: SET_PROJECT_DATA,
  payload: projectData,
});

export const setMaterialDataAction = (materialData) => ({
  type: SET_MATERIAL_DATA,
  payload: materialData,
});

export const setMimesDataAction = (mimesData) => ({
  type: SET_MIMES_DATA,
  payload: mimesData,
});

export const setProjectWeekDataAction = (projectWeekData, year?: string) => ({
  type: SET_PROJECT_WEEK_DATA,
  payload: { ...projectWeekData, year },
});

export const setMaterialWeekDataAction = (materialWeekData, year?: string) => ({
  type: SET_MATERIAL_WEEK_DATA,
  payload: { ...materialWeekData, year },
});

export const setMimesWeekDataAction = (mimesWeekData, year?: string) => ({
  type: SET_MIMES_WEEK_DATA,
  payload: { ...mimesWeekData, year },
});

export const projectEstimateSetAction = ({ key, value }) => ({
  type: PROJECT_ESTIMATE_SET,
  payload: {
    key,
    value,
  },
});

export const dropLoadedChartMonthsAction = () => ({
  type: DROP_LOADED_CHART_MONTHS,
});

export const setManufacturingMonthMarkersAction = (monthMarkers: number[], year: number | string) => ({
  type: SET_MONTH_MARKERS,
  payload: { year, markers: monthMarkers },
});

export const startLoadingChartTreeAction = () => ({
  type: SET_IS_LOADING_CHART_TREE,
  payload: true,
});

export const stopLoadingChartTreeAction = () => ({
  type: SET_IS_LOADING_CHART_TREE,
  payload: false,
});

export const setActiveBranchAction = ({ index, eventFrom }) => ({
  type: SET_ACTIVE_BRANCH,
  payload: { index, eventFrom },
});

export const updateDiagramFiltersAction = ({ name, value }) => ({
  type: UPDATE_DIAGRAM_FILTERS,
  payload: { name, value },
});

export const startDragIntervalAction = ({ projectId, intervalId, startDate, endDate, origin, isGroup }) => ({
  type: SET_IS_BEING_DRAGGED,
  payload: { projectId, intervalId, isBeingDragged: true, startDate, endDate, origin, isGroup },
});

export const stopDragIntervalAction = ({ projectId, intervalId }) => ({
  type: SET_IS_BEING_DRAGGED,
  payload: { projectId, intervalId, isBeingDragged: false },
});

export const enableIntervalLinkingAction = () => ({
  type: SET_IS_ENABLED_LINKING,
  payload: true,
});

export const disableIntervalLinkingAction = () => ({
  type: SET_IS_ENABLED_LINKING,
  payload: false,
});

export const addIntervalLinkAction = ({ projectId, arrows }) => ({
  type: ADD_INTERVAL_LINK,
  payload: { projectId, arrows },
});

export const setArrowsAction = ({ projectId, arrows }) => ({
  type: SET_ARROWS,
  payload: { projectId, arrows },
});

export const deleteArrowAction = ({ projectId, arrowId }) => ({
  type: DELETE_ARROW,
  payload: { projectId, arrowId },
});

export const updateArrowAction = ({ projectId, arrowId, data }) => ({
  type: UPDATE_ARROW,
  payload: { projectId, arrowId, data },
});

export const setRelationsFromCurrentIntervalAction = (relations) => ({
  type: SET_RELATIONS_FROM_CURRENT_INTERVAL,
  payload: relations,
});

export const setRelationsToCurrentIntervalAction = (relations) => ({
  type: SET_RELATIONS_TO_CURRENT_INTERVAL,
  payload: relations,
});

export const loadAvailableSectionsAction = (sections) => ({
  type: SET_AVAILABLE_SECTIONS,
  payload: sections,
});

export const loadAvailableIntervalsAction = (intervals) => ({
  type: SET_AVAILABLE_INTERVALS,
  payload: intervals,
});

export const pushToRelationsAction = (intervals) => ({
  type: PUSH_TO_RELATIONS,
  payload: intervals,
});

export const pushFromRelationsAction = (intervals) => ({
  type: PUSH_FROM_RELATIONS,
  payload: intervals,
});

export const removeToRelationAction = (toIntervalId) => ({
  type: REMOVE_TO_RELATION,
  payload: toIntervalId,
});

export const removeFromRelationAction = (fromIntervalId) => ({
  type: REMOVE_FROM_RELATION,
  payload: fromIntervalId,
});

export const updateToRelationAction = ({ relationId, data }) => ({
  type: UPDATE_TO_RELATION,
  payload: { relationId, data },
});

export const updateFromRelationAction = ({ relationId, data }) => ({
  type: UPDATE_FROM_RELATION,
  payload: { relationId, data },
});

export const highlightRelatedIntervalsAction = ({ intervalId, projectId }) => ({
  type: HIGHLIGHT_INTERVAL,
  payload: { intervalId, projectId },
});

export const dropHighlightRelatedIntervalsAction = () => ({
  type: DROP_HIGHLIGHTED_INTERVAL,
});

export const setArrowHashAction = (hash) => ({
  type: SET_ARROW_HASH,
  payload: hash,
});

export const setManufacturingHashAction = (hash) => ({
  type: SET_MANUFACTURING_HASH,
  payload: hash,
});

export const setManufacturingExpandedBranchesAction = (branchesSet) => ({
  type: SET_MANUFACTURING_EXPANDED_BRANCHES,
  payload: branchesSet,
});

export const setConstructingExpandedBranchesAction = (branchesSet) => ({
  type: SET_CONSTRUCTING_EXPANDED_BRANCHES,
  payload: branchesSet,
});

export const getProjectsAction = (projects: IBuildingRootResponse) => ({
  type: GET_PROJECTS,
  payload: projects,
});

export const setIsLoadingProjectsAction = (isLoading: boolean) => ({
  type: SET_IS_LOADING_PROJECTS,
  payload: isLoading,
});

export const setModalMaterialsLoading = (status: boolean) => ({
  type: "manufacturing/SET_MATERIALS_LOADING",
  payload: status,
});

export const setCriticalDates = (payload: string[]) => ({
  type: SET_CRITICAL_DATES,
  payload,
});
