import cn from "classnames";
import moment from "moment";
import React, { useCallback } from "react";

import Calendar from "../../../../../../UI/molecules/Calendar/Calendar";
import Select from "components/UI/atoms/Select";
import LabelTemplate from "components/UI/templates/LabelTemplate/LabelTemplate";
import TaskCommentsContainer from "components/pages/Tasks/components/TaskComments/TaskCommentsContainer";

import PriorityRange from "../../../PriorityRange/PriorityRange";
import TaskFiles from "../../../TaskFiles/TaskFiles";

import {
  EXTRA_MODAL_TAB,
  MAIN_MODAL_TAB,
  TASKS_STATUS_OPTIONS,
  TASK_MODAL_TYPES,
  TASK_REMEMBER,
  TASK_REPEAT,
  TASK_STATUSES,
} from "../../../../constants";

import { useTasksModal } from "../../../../hooks/useTasksModal";
import { useUpdateTask } from "../../../../hooks/useUpdateTask";

import getShortFullName from "../../../../../../../utils/formatters/getShortFullName";

import selectStyles from "../../../TaskPersonsSelect/TasksPersonsSelect.module.scss";
import styles from "./ModalViewTask.module.scss";

export interface IModalViewTaskProps {
  activeTab: string;
}

const ModalViewTask: React.FC<IModalViewTaskProps> = ({ activeTab }) => {
  const { taskId, placementType, listType, list_id } = useTasksModal();
  const { updateTaskStatusDirectly, isTaskStatusEditable, certainTask } = useUpdateTask({
    taskId,
    placementType,
    listType,
    list_id,
  });

  const onChangeTaskStatus = useCallback(
    (newStatus: string | number) => {
      updateTaskStatusDirectly(newStatus as keyof typeof TASK_STATUSES);
    },
    [updateTaskStatusDirectly]
  );

  if (!certainTask) return null;

  const {
    id,
    description,
    deadline,
    priority,
    building_obj,
    section_obj,
    executor_user,
    viewers,
    creator_user,
    repeat_task,
    remember_task,
    task_files,
    task_comments,
    viewers_data,
    status,
  } = certainTask;

  return (
    <div className={styles.viewContainer}>
      {activeTab === MAIN_MODAL_TAB && (
        <>
          {description && <LabelTemplate label={"Описание задачи"}>{description}</LabelTemplate>}
          <div className={styles.pair}>
            {deadline && (
              <LabelTemplate label={"Контрольная дата"}>
                <Calendar
                  value={moment(deadline)}
                  classNameOptions={styles.calendarOptions}
                  classNameSelect={cn(styles.calendarSelect, styles.disabled)}
                  format={"DD/MM/YY"}
                  setValue={undefined}
                  label={undefined}
                  disabled
                  testId={undefined}
                />
              </LabelTemplate>
            )}
            {priority && <PriorityRange disabled defaultValue={priority} value={priority} className={styles.range} />}
          </div>
          {building_obj && <LabelTemplate label={"Проект"}>{building_obj.name}</LabelTemplate>}
          {section_obj && <LabelTemplate label={"Раздел"}>{section_obj.name}</LabelTemplate>}
          {executor_user && (
            <LabelTemplate label={"Исполнители"}>
              <div className={styles.bubbles}>
                <div className={styles.bubble}>
                  <span>
                    {getShortFullName(executor_user.last_name, executor_user.first_name, executor_user.middle_name)}
                  </span>
                </div>
              </div>
            </LabelTemplate>
          )}
          {viewers?.length > 0 && (
            <LabelTemplate label={`Контролеры: ${viewers.length}`}>
              <div className={styles.bubbles}>
                {viewers_data.map((viewer) => (
                  <div className={styles.bubble} key={viewer.id}>
                    <span>{getShortFullName(viewer.last_name, viewer.first_name, viewer.middle_name)}</span>
                  </div>
                ))}
              </div>
            </LabelTemplate>
          )}
          {status && (
            <LabelTemplate label={"Статус задачи"}>
              <Select
                options={TASKS_STATUS_OPTIONS}
                value={status}
                color={undefined}
                size={undefined}
                labelColor={undefined}
                onChange={onChangeTaskStatus}
                disabled={!isTaskStatusEditable}
                className={undefined}
                selectClassName={selectStyles.select}
                label={undefined}
                meta={undefined}
                onOpen={undefined}
                onClose={undefined}
              />
            </LabelTemplate>
          )}
          {creator_user && (
            <LabelTemplate label={"Постановщик"}>
              {`${creator_user.last_name} ${creator_user.first_name} ${creator_user.middle_name}`}
            </LabelTemplate>
          )}
        </>
      )}
      {activeTab === EXTRA_MODAL_TAB && (
        <>
          <LabelTemplate label={"Напоминания и повторы"}>
            <div className={styles.rememberRepeat}>
              <span>Напомнить: {remember_task ? TASK_REMEMBER[remember_task[0]] : "не напоминать"}</span>
              <span>Повтор: {repeat_task ? TASK_REPEAT[repeat_task] : "без повтора"}</span>
            </div>
          </LabelTemplate>
          <TaskFiles
            files={task_files.map((file) => ({ ...file, name: file.file_name })) || []}
            taskId={id}
            variant={TASK_MODAL_TYPES.VIEW}
          />
          <TaskCommentsContainer comments={task_comments || []} taskId={id} />
        </>
      )}
    </div>
  );
};

export default React.memo(ModalViewTask);
