import React from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import styles from "./JournalExecWorkRow.module.scss";

const getReplacementIcon = (expenditure: IExpendituresInJournalFulfillment["items"][0]) => {
  const replacementsFilesCount = React.useMemo(() => {
    const item = expenditure.expenditure ?? expenditure.group ?? expenditure.linked;
    if (!item) return 0;
    return [item.replacement?.has_drawing, item.replacement?.has_covering_letter].filter((el) => !!el).length;
  }, [expenditure]);

  if (replacementsFilesCount === 1) {
    return (
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.49219 7.93378L6.50919 12.9508L14.5092 1.05078" stroke="#4FB1EB" stroke-width="1.6" />
      </svg>
    );
  }

  if (replacementsFilesCount === 2) {
    return (
      <div>
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.49219 7.93378L6.50919 12.9508L14.5092 1.05078" stroke="#4FB1EB" stroke-width="1.6" />
        </svg>
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.secondCheck}
        >
          <path d="M1.49219 7.93378L6.50919 12.9508L14.5092 1.05078" stroke="#4FB1EB" stroke-width="1.6" />
        </svg>
      </div>
    );
  }

  return null;
};

export default getReplacementIcon;
