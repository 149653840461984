import cn from "classnames";
import Moment from "moment";
import React, { useCallback, useMemo } from "react";

import { MONTH_IDS } from "components/pages/Manufacturing/constants";
import { generateWeeksNumbersInMonth } from "components/pages/Manufacturing/utils";

import { ICalendarBackgroundProps } from "../../CalendarBackground";
import { extendMoment } from "moment-range";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import styles from "../../../../Manufacturing.module.scss";
import localStyles from "./YearBackground.module.scss";

//@ts-ignore
const moment = extendMoment(Moment);

const YearBackground: React.FC<ICalendarBackgroundProps> = ({
  year,
  unitHeight,
  constructionDateStart,
  constructionDateEnd,
  unitOffset,
  criticalDates = []
}) => {
  const unitMultiplier = useUnitMultiplier();
  const weekWidth = unitMultiplier;

  const criticalMoments = useMemo(() => {
    return criticalDates.map(d => moment(d, "YYYY-MM-DD"));
  }, [criticalDates]);

  const checkIsCurrentWeek = useCallback(
    (week: number) =>
      moment().isSame(
        moment()
          .year(+year)
          .week(week),
        "week"
      ),
    [year]
  );

  const checkIsWeekOutOfDates = useCallback(
    (week: number, year: number) => {
      if (!constructionDateEnd || !constructionDateStart) return false;
      const weekMoment = moment()
        .year(+year)
        .week(week);

      const constructionStartMoment = constructionDateStart ? moment(constructionDateStart, "YYYY-MM-DD") : null;
      const constructionEndMoment = constructionDateEnd ? moment(constructionDateEnd, "YYYY-MM-DD") : null;

      const isOutOfDates =
        constructionStartMoment && constructionEndMoment
          ? weekMoment.day(0).isAfter(constructionEndMoment) || weekMoment.day(6).isBefore(constructionStartMoment)
          : false;

      return isOutOfDates;
    },
    [constructionDateStart, constructionDateEnd]
  );

  return (
    <div
      className={styles.weeksLine}
      style={{ height: `${unitHeight * 3}rem`, left: `${!!unitOffset ? unitOffset * unitMultiplier : 0}rem` }}
    >
      {MONTH_IDS.map((monthId, index) => {
        const monthMoment = moment()
          .year(+year)
          .month(monthId);

        const today = moment().isSame(monthMoment, "month") ? moment().date() : undefined;

        const weeks = generateWeeksNumbersInMonth(+year, monthId);

        return (
          <React.Fragment key={index}>
            <div
              className={cn(styles.weekBackground, localStyles.month)}
              style={{
                left: `${weekWidth * (weeks[0] - 1)}rem`,
                width: `${weekWidth * weeks.length}rem`,
              }}
            >
              {weeks.map((el, index) => {
                const criticalWeek = criticalMoments.some(m => m.week() === el);

                return (
                  <div
                    key={el}
                    className={cn(localStyles.week, {
                      [styles.outdatedBg]: checkIsWeekOutOfDates(el, +year),
                      [localStyles.lastWeekInMonth]: index === weeks.length - 1 && !criticalWeek,
                      [localStyles.critical]: criticalWeek
                    })}
                    style={{ width: `${weekWidth}rem` }}
                  >
                    {!checkIsCurrentWeek(el) && el}
                  </div>
                )
              })}
            </div>
            {today !== undefined && (
              <div
                className={cn(styles.currentLine, styles.yearCurrentLine)}
                style={{
                  left: `${(moment().week() - 0.5) * unitMultiplier}rem`,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(YearBackground);
