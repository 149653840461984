import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";

import { BetonIncomeStatusEnum, IBetonIncomeItem } from "redux/modules/common/building/beton/types";

import ButtonBase from "components/UI/atoms/ButtonBase";
import FilesClipButton from "components/UI/atoms/FilesClipButton/FilesClipButton";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import BetonIncomeModal from "../BetonIncomeModal/BetonIncomeModal";
import BetonIncomeWriteOff from "../BetonIncomeWriteOff/BetonIncomeWriteOff";
import BetonProgress from "../BetonProgress/BetonProgress";
import BetonVolume from "../BetonVolume/BetonVolume";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./../../BetonIncome.module.scss";

interface IProps {
  item: IBetonIncomeItem;
  stockId: number;
  haveViewSendToProduction: boolean;
}

const BetonIncomeRow: React.FC<IProps> = ({ item, stockId, haveViewSendToProduction }) => {
  const isFulfilled = item.status === BetonIncomeStatusEnum.full;
  const [isWriteOffModal, setIsWriteOffModal] = useState(false);
  const [isDetailModal, setIsDetailModal] = useState(false);

  return (
    <>
      <TableReusableRow className={styles.row} isHoverable={!false} onClick={() => setIsDetailModal(true)}>
        <TableReusableCell isNoBreak>{item.name}</TableReusableCell>
        <TableReusableCell isNoBreak>{item.provider}</TableReusableCell>
        <TableReusableCell>{moment(item.delivery_date).format("DD.MM.YYYY")}</TableReusableCell>
        <TableReusableCell>
          <BetonVolume volume={item.count} leftVolume={item.left_count} measure={item.measure} />
        </TableReusableCell>
        <TableReusableCell>
          <BetonProgress status={item.status} progress={item.part_percent} />
        </TableReusableCell>
        <TableReusableCell>
          <FilesClipButton count={item.files} className={cn({ [styles.disabledFiles]: !item.files })} />
        </TableReusableCell>
        <TableReusableCell isRighted onClick={stopEventPropagation}>
          <ButtonBase
            disabled={isFulfilled}
            primary
            className={cn({ [styles.disabledBtn]: isFulfilled })}
            onClick={() => setIsWriteOffModal(true)}
          >
            В производство
          </ButtonBase>
        </TableReusableCell>
      </TableReusableRow>
      <BetonIncomeModal
        isOpen={isDetailModal}
        onClose={() => setIsDetailModal(false)}
        item={item}
        stockId={stockId}
        onSendToProduction={() => setIsWriteOffModal(true)}
      />
      {haveViewSendToProduction && (
          <BetonIncomeWriteOff
              stockId={stockId}
              betonId={item.id}
              isOpen={isWriteOffModal}
              onClose={() => setIsWriteOffModal(false)}
              item={item}
              haveViewSendToProduction={haveViewSendToProduction}
          />
      )}
    </>
  );
};

export default BetonIncomeRow;
