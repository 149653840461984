import React from "react";

import SliderModal from "components/UI/atoms/SliderModal";

import ProductIntervalCardContent from "./ProductIntervalCardContent";

import styles from "./ProductIntervalCard.module.scss";

const ProductIntervalCard = ({ isOpen, handleClose, objectId }) => {
  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.contentWrapper}>
            <ProductIntervalCardContent handleClose={handleClose} objectId={objectId} isOpen={isOpen} />
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default ProductIntervalCard;
