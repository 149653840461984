import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { diagramFiltersSelector } from "../../../../../../../redux/modules/common/building/manufacturing/selectors";
import {
  dropHighlightRelatedIntervals,
  setManufacturingHash,
} from "../../../../../../../redux/modules/common/building/manufacturing/thunks";

import BraceTooltip from "./components/BraceTooltip/BraceTooltip";
import ManufacturingPlanSectionModal from "components/modals/ManufacturingPlanSectionModal/ManufacturingPlanSectionModal";

import { ManufacturingPlanModal } from "../../../modals/ManufacturingPlanModal/ManufacturingPlanModal";
import ShiftModal from "../../../modals/ShiftsModal/ShiftsModal";
import DiagramIntervalBase from "../DiagramInterval/DiagramInterval";
import InfoPopup from "../InfoPopup/InfoPopup";

import {
  INTERVAL_MAX_Z_INDEX,
  INTERVAL_TYPES,
  TAILS_OFFSET_COMPENSATION_REM,
  MONTH_COLOR_MAP as colorMap,
} from "../../../../constants";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import { getIntervalDatesLabel } from "../../../../utils";
import { shouldIntervalBeHiddeByFilters } from "../../utils";
import { withEditing } from "../DiagramInterval/utils/withEditing";
import { withLinking } from "../DiagramInterval/utils/withLinking";
import { useIntervalLinks } from "../DiagramInterval/utils/withLinking/useIntervalLinks";

import TicketTail from "../../../../../../../images/TicketTail";
import ticketBackground from "../../../../../../../images/ticket-background.svg";

import styles from "../../Month.module.scss";

const DiagramInterval = withLinking(withEditing(DiagramIntervalBase));

const Plan = ({
  branch,
  offset,
  isSection = false,
  offsetLeft,
  objectId = null,
  isSectionPlan = branch.plan?.isSectionPlan,
}) => {
  const dispatch = useDispatch();
  const [isOpenWorkCard, setIsOpenWorkCard] = useState(false);
  const [isShiftModalOpened, setIsShiftModalOpened] = useState(false);
  const [hover, setHover] = useState(false);
  const [isEditRelationsByDefault, setIsEditRelationsByDefault] = useState(false);
  const planWrapperRef = useRef(null);
  const braceTooltipRef = useRef(null);
  const popupRef = useRef(null);
  const diagramFilters = useSelector(diagramFiltersSelector);
  const isPlansEditingEnabled = diagramFilters.plans_editing_enabled;

  const unitMultiplier = useUnitMultiplier();

  const isGroup = !!branch.plan?.data?.group;

  const { isLinkingEnabled, beingDraggedArrows, draggedIntervalEntry, isSomeArrowBeingDragged, isIntervalHighlighted } =
    useIntervalLinks({
      toIntervalId: branch.plan?.data?.id,
      needLinking: false,
      isToIntervalGroup: isGroup,
    });

  const onAddArrowCallback = useCallback(() => {
    setIsEditRelationsByDefault(true);
    setIsOpenWorkCard(true);
  }, []);

  const onBraceHover = useCallback(() => setHover(true), []);
  const onBraceLeave = useCallback(() => setHover(false), []);

  const handleCloseModal = useCallback(() => {
    setIsOpenWorkCard(false);
    setHover(false);
    setIsEditRelationsByDefault(false);
    dispatch(dropHighlightRelatedIntervals());
  }, []);

  const currentPlanDraggedInfo = branch.plan?.data?.id && beingDraggedArrows[branch.plan?.data?.id];

  const resetManufacturingHash = useCallback(() => {
    dispatch(setManufacturingHash(Math.random()));
  }, []);

  const isPlanHighlighted =
    isPlansEditingEnabled ||
    (isLinkingEnabled &&
      (isIntervalHighlighted ||
        (hover && !isSomeArrowBeingDragged) ||
        (draggedIntervalEntry && hover && moment(branch.plan.start).isAfter(draggedIntervalEntry[1]?.startDate)) ||
        currentPlanDraggedInfo?.isBeingDragged));

  const hasShift = branch?.plan?.data?.has_shifts;

  const onShiftModalClose = useCallback(() => {
    setIsShiftModalOpened(false);
  }, []);

  const handleOpenShiftModal = useCallback(() => {
    setIsShiftModalOpened(true);
  }, []);

  const borderColor = hasShift ? "red" : isPlanHighlighted ? "#4FB1EB" : colorMap["defaultBorder"];

  const planWidthRem = Math.max(branch.plan.days, 1) * unitMultiplier;

  const getPlanWrapperZIndex = () => {
    if (currentPlanDraggedInfo?.isBeingDragged) return INTERVAL_MAX_Z_INDEX + 1;
    else if (isPlanHighlighted) return INTERVAL_MAX_Z_INDEX;
    else if (isSectionPlan) {
      if (!branch.plan.data?.parent_id) return 2;
      return 1;
    }
    return null;
  };

  const planWrapperStyles = {
    zIndex: getPlanWrapperZIndex(),
  };

  const planFullStyles = {
    backgroundImage: `url(${ticketBackground})`,
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`,
  };

  const planBraceStyles = {
    maxWidth: `${planWidthRem}rem`,
    transform: `translateX(calc(${
      (branch.day - 1) * unitMultiplier - TAILS_OFFSET_COMPENSATION_REM + 0.44
    }rem + ${offsetLeft}px))`,
    width: `${planWidthRem - 0.44}rem`,
    zIndex: hover || isOpenWorkCard ? INTERVAL_MAX_Z_INDEX : 1,
  };

  const modalType =
    +branch.parentId === +objectId
      ? "building"
      : isSection &&
        !branch.plan.data?.parent_id &&
        (!branch.parentId || branch.parentId === branch.plan.data?.section_id)
      ? "lsr"
      : isSection
      ? "section"
      : "expenditure";

  const dateStart = moment(branch.plan.interval_start_date || branch.plan.start)?.format("YYYY-MM-DD");
  const dateEnd = moment(branch.plan.interval_end_date || branch.plan.end)?.format("YYYY-MM-DD");

  const intervalDates = getIntervalDatesLabel(branch.plan.start, branch.plan.end);

  const isInfoPopupShown =
    !isPlansEditingEnabled && (hover || isOpenWorkCard) && (branch.plan.type === "full" || isLinkingEnabled);

  const isPlanBraceShown = !isPlansEditingEnabled && !isLinkingEnabled && branch.plan.type === "brace";

  const updatePlanStatus = (newStatus, intervalId) => {
    if (intervalId !== branch.plan.data?.id) return;
    branch.plan.data.status = newStatus;
  };

  const moveBraceTooltip = (e) => {
    requestAnimationFrame(() => {
      if (!braceTooltipRef?.current || branch.plan.days < 2 || isOpenWorkCard || !planWrapperRef.current) return;
      const bounds = planWrapperRef.current.getBoundingClientRect();
      const x = Math.max(bounds.left + 8, Math.min(e.clientX, bounds.right - 8));
      braceTooltipRef.current.style.left = `${x}px`;
      braceTooltipRef.current.style.transform = `translateX(-50%)`;
    });
  };

  const sharedStatus = branch.plan?.data?.status;

  if ((isLinkingEnabled && !branch.plan?.data?.id) || shouldIntervalBeHiddeByFilters(sharedStatus, diagramFilters)) {
    return null;
  }

  return (
    <>
      {isPlanBraceShown && (
        <div
          className={styles.planBrace}
          onClick={() => setIsOpenWorkCard(true)}
          style={planBraceStyles}
          onMouseEnter={onBraceHover}
          onMouseLeave={onBraceLeave}
          onMouseMove={moveBraceTooltip}
        >
          <BraceTooltip
            isOpen={hover || isOpenWorkCard}
            setIsOpen={setHover}
            label={`Плановый диапазон: ${intervalDates}`}
            ref={braceTooltipRef}
          >
            <div />
          </BraceTooltip>
        </div>
      )}
      <DiagramInterval
        day={branch.day}
        daysLength={branch.plan.days}
        unitMultiplier={unitMultiplier}
        offsetLeft={offsetLeft}
        hover={hover}
        setHover={setHover}
        intervalWrapperRef={planWrapperRef}
        isCardOpen={isOpenWorkCard}
        type={INTERVAL_TYPES.plan}
        intervalId={branch.plan.data?.id}
        wrapperStyles={planWrapperStyles}
        projectId={objectId && +objectId}
        startDate={branch.plan.start}
        endDate={branch.plan.end}
        intervalSharedStatus={sharedStatus}
        onAddArrowCallback={onAddArrowCallback}
        popupRef={popupRef}
        onMouseMove={moveBraceTooltip}
        expenditureId={branch.plan.data?.expenditure_id}
        isGroup={isGroup}
        isSectionPlan={isSectionPlan}
      >
        <TicketTail fillColor={"none"} borderColor={borderColor} />
        <div className={styles.planFull} style={planFullStyles} />
        <TicketTail isRight fillColor={"none"} borderColor={borderColor} />
        {isInfoPopupShown && (
          <InfoPopup
            isOpen
            ref={popupRef}
            setOpenPopup={setHover}
            intervalDates={intervalDates}
            data={branch.plan.data}
            type={INTERVAL_TYPES.plan}
            objectId={objectId}
            setOpenModal={setIsOpenWorkCard}
            openShiftModal={handleOpenShiftModal}
            hasShift={hasShift}
          >
            <div className={styles.popupAnchor} />
          </InfoPopup>
        )}
        {isOpenWorkCard && !isSectionPlan && (
          <ManufacturingPlanModal
            isOpen
            onClose={handleCloseModal}
            dateStart={dateStart}
            dateEnd={dateEnd}
            data={branch.plan.data}
            expenditureId={branch.plan.data?.expenditure_id || branch.parentId}
            modalType={modalType}
            updatePlanStatus={updatePlanStatus}
            isEditRelationsByDefault={isEditRelationsByDefault}
            onEditCallback={resetManufacturingHash}
            onAddToJournalCallback={resetManufacturingHash}
            isGroupPlan={isGroup}
          />
        )}
        {isOpenWorkCard && isSectionPlan && (
          <ManufacturingPlanSectionModal
            isOpen
            onClose={handleCloseModal}
            type={INTERVAL_TYPES.plan}
            date_start={dateStart}
            date_end={dateEnd}
            objectId={objectId}
            intervaldata={branch.plan.data}
            sectionId={
              branch.plan.data?.expenditure_id ||
              branch.plan.data?.section_id ||
              branch.parentId ||
              branch.plan.data?.id
            }
            modalType={modalType}
            onEditCallback={resetManufacturingHash}
            onAddToJournalCallback={resetManufacturingHash}
          />
        )}
      </DiagramInterval>
      {isShiftModalOpened && (
        <ShiftModal
          isOpened={isShiftModalOpened}
          onClose={onShiftModalClose}
          objectId={objectId}
          planId={branch?.plan?.data?.id}
          openedFromPlanClick
          isGroup={isGroup}
        />
      )}
    </>
  );
};

export default React.memo(Plan);
