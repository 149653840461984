import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import { chartViewModeSelector, criticalDatesSelector } from "redux/modules/common/building/manufacturing/selectors";
import { setManufacturingMonthMarkers } from "redux/modules/common/building/manufacturing/thunks";

import DaysLine from "./components/DaysLine/DaysLine";
import MonthsLine from "./components/MonthsLine/MonthsLine";
import WeeksLine from "./components/WeeksLine/WeeksLine";

import { getUnitOffset, monthMarkers } from "../../utils";

import styles from "./CalendarDateLine.module.scss";

export interface ICalendarDateLineProps {
  year: number | string;
  touchedYears?: string[];
  unitOffset?: number;
  criticalDates?: string[]
}

const CalendarDateLine: React.FC<ICalendarDateLineProps> = (props) => {
  const {
    year,
    touchedYears,
  } = props;

  const dispatch = useDispatch();
  const chartViewMode = useSelector(chartViewModeSelector);
  const criticalDates = useSelector(criticalDatesSelector);
  const lineRef = useRef<Record<number | string, HTMLDivElement>>({});

  useEffect(() => {
    touchedYears?.forEach((y) => {
      if (!lineRef.current[y]) return;
      const startMonthElements = Array.from(lineRef.current[y].querySelectorAll(`.startMonth`));
      if (startMonthElements.length > 0) {
        dispatch(setManufacturingMonthMarkers(monthMarkers(startMonthElements), y));
      }
    });
  }, [lineRef.current, touchedYears, chartViewMode]);

  if (chartViewMode === WEEK)
    return (
      <div className={styles.lineContainer}>
        {touchedYears ? (
          touchedYears.map((y, index) => (
            <WeeksLine
              year={y}
              key={y}
              ref={lineRef}
              unitOffset={getUnitOffset(touchedYears, index, chartViewMode)}
              criticalDates={criticalDates}
            />
          ))
        ) : (
          <WeeksLine
            year={year}
            ref={lineRef}
            criticalDates={criticalDates}
          />
        )}
      </div>
    );
  if (chartViewMode === MONTH || chartViewMode === HALF_MONTH)
    return (
      <div className={styles.lineContainer}>
        {touchedYears ? (
          touchedYears.map((y, index) => {
            return (
              <DaysLine
                key={y}
                year={y}
                ref={lineRef}
                unitOffset={getUnitOffset(touchedYears, index, chartViewMode)}
                criticalDates={criticalDates}
              />
            );
          })
        ) : (
          <DaysLine
            year={year}
            ref={lineRef}
            criticalDates={criticalDates}
          />
        )}
      </div>
    );
  if (chartViewMode === YEAR)
    return (
      <div className={styles.lineContainer}>
        {touchedYears ? (
          touchedYears.map((y, index) => (
            <MonthsLine
              key={y}
              year={y}
              ref={lineRef}
              unitOffset={getUnitOffset(touchedYears, index, chartViewMode)}
            />
          ))
        ) : (
          <MonthsLine
            year={year}
            ref={lineRef}
          />
        )}
      </div>
    );
  return null;
};

export default React.memo(CalendarDateLine);
