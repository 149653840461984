import axios from 'axios'
import {createSelector} from 'reselect'

import {suppliersStateSelector} from '../suppliers'
import {moduleName as parentModuleName} from './index'
import {LOAD_LIMIT} from './constants'
import {prepareTableData} from './utils'

const initialState = {
  isLoading: true,
  suppliers: {
    count: 0,
    list: []
  },
  tableFilters: {}
}

const moduleName = 'index'
const actionsPrefix = 'constructing/counterparties/suppliers/list/'

const SET_LOADING = `${actionsPrefix}_SET_LOADING`
const SET_SUPPLIERS = `${actionsPrefix}_SET_SUPPLIERS`
const SET_MORE_SUPPLIERS = `${actionsPrefix}_SET_MORE_SUPPLIERS`
const SET_TABLE_FILTER = `${actionsPrefix}_SET_TABLE_FILTER`
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`

export const listStateSelector = createSelector(
  suppliersStateSelector,
  (state) => state[parentModuleName]
)
export const stateSelector = createSelector(listStateSelector, (state) => state[moduleName])
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading)
export const suppliersSelector = createSelector(stateSelector, (state) => state.suppliers)
export const tableFiltersSelector = createSelector(stateSelector, (state) => state.tableFilters)

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: {
          count: payload.count,
          list: payload.list
        }
      }
    case SET_MORE_SUPPLIERS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          list: [...state.suppliers.list, ...payload.list]
        }
      }
    case SET_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...payload
        }
      }
    case CLEAR_DATA:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
})

export const setSuppliers = (data) => ({
  type: SET_SUPPLIERS,
  payload: data
})

export const setMoreSuppliers = (data) => ({
  type: SET_MORE_SUPPLIERS,
  payload: data
})

export const loadSuppliers = () => {
  return async (dispatch, getState) => {
    const tableFilters = tableFiltersSelector(getState())
    try {
      dispatch(setLoading(true))
      const response = await axios.get('/providers/', {
        params: {
          offset: 0,
          limit: LOAD_LIMIT,
          ...tableFilters
        }
      })

      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results)
      }
      dispatch(setSuppliers(preparedData))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const loadMoreSuppliers = () => {
  return async (dispatch, getState) => {
    const suppliers = suppliersSelector(getState())
    const tableFilters = tableFiltersSelector(getState())
    try {
      const response = await axios.get('/providers/', {
        params: {
          offset: suppliers.list.length,
          limit: LOAD_LIMIT,
          ...tableFilters
        }
      })
      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results)
      }
      dispatch(setMoreSuppliers(preparedData))
    } catch (e) {
      console.error(e)
    }
  }
}

export const deleteSupplier = (id) => {
  return async (dispatch, getState) => {
    const suppliers = suppliersSelector(getState())
    try {
      await axios.delete(`/providers/${id}/`)
      const newSuppliers = {
        count: suppliers.count - 1,
        list: suppliers.list.filter((supplier) => supplier.id !== id)
      }
      dispatch(setSuppliers(newSuppliers))
    } catch (e) {
      console.error(e)
    }
  }
}

export const setTableFilter = (data) => ({
  type: SET_TABLE_FILTER,
  payload: data
})

export const clearData = () => ({
  type: CLEAR_DATA
})
