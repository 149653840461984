import axios from "axios";
import { IGetShiftsResponse, ShiftStatusesEnum } from "./types";

interface IGetShiftsListOptions {
  building?: string | null;
  plan_work?: string;
  plan_group?: string;
  created_at_after?: string;
  created_at_before?: string;
}

export const getShiftsList = (options: IGetShiftsListOptions = {}) => {
  const url = '/shift/';

  const params = new URLSearchParams({
    status: ShiftStatusesEnum.new
  })

  Object.keys(options).forEach((key) => {
    if (options[key as keyof IGetShiftsListOptions]) {
      params.append(key, options[key]);
    }
  })

  return axios.get<IGetShiftsResponse>(`${url}?${params.toString()}`);
}