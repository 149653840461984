import styles from "./Header.module.scss";
import InputBase from "../../../../atoms/InputBase";
import React, { ChangeEvent, FC } from "react";
import { IGroupInfo } from "../../types";


interface IProps {
  isEdit: boolean;
  name: string;
  onNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  expendituresCount: number;
}

export const Header: FC<IProps> = ({
  isEdit,
  name,
  onNameChange,
  expendituresCount
}) => {
  return (
    <div className={styles.title}>
      {isEdit ? (
        <div className={styles.titleInput}>
          <InputBase
            variant={"tertiary"}
            value={name}
            onChange={onNameChange}
            placeholder="Введите название группы"
          />
        </div>
      ) : (
        <span>
          {name}
        </span>
      )}
      <span className={styles.selectedCount}>(Расценок:&nbsp;{expendituresCount})</span>
    </div>
  )
}