import cn from "classnames";
import React, { useMemo, useState } from "react";

import { IExpendituresInJournalFulfillment } from "redux/modules/common/building/journal/journalExecution/types";

import HistoryModal from "./components/HistoryModal/index";
import ButtonBase from "components/UI/atoms/ButtonBase";
import RoundChart from "components/UI/atoms/RoundChart";

import { IGroupInJournalDone, ITicketInJournalDone } from "types/interfaces/Tickets";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import InfoIcon from "images/icons/InfoIcon";

import styles from "./InfoPopup.module.scss";

export interface IProps {
  data: IExpendituresInJournalFulfillment["items"][0];
  objectId: string;
  isHideHistoryButton?: boolean;
}

export interface IChart {
  width: number;
  color: string;
}

const InfoPopup: React.FC<IProps> = ({ data, objectId, isHideHistoryButton }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ticketItem = data.expenditure ?? data.group ?? data.linked;
  const estimateCount = ticketItem?.indicators?.estimate_count ?? 0;
  const acceptedCount = ticketItem?.indicators?.accepted_count;
  const completedCount = ticketItem?.indicators?.completed_count;

  const onePercent = Math.round(+estimateCount / 100);

  const chartData = useMemo(() => {
    const res: IChart[] = [];
    if (!ticketItem?.indicators) return res;

    res.push({ width: +ticketItem?.indicators?.accepted_count! / onePercent, color: "#8FE532" });
    res.push({ width: +ticketItem?.indicators?.completed_count! / onePercent, color: "#FFE86A" });

    return res;
  }, [data]);

  return (
    <div className={styles.container}>
      <HistoryModal expenditure={data} isOpen={isOpen} handleCloseModal={() => setIsOpen(false)} />
      <InfoIcon />
      <div className={styles.popupContainer}>
        <div className={styles.arrow}></div>
        <div className={styles.title}>Сводные показатели</div>
        <div className={styles.infoCircleBlock}>
          <div className={styles.circle}>
            <div className={styles.circleInner}>
              <RoundChart data={chartData} />
            </div>
          </div>

          <div className={styles.legendBlock}>
            <div className={styles.legend}>
              <>
                <div className={styles.projectBlock}></div>
                По проекту: {estimateCount ? dropNonSignificantZeros(estimateCount) : "0.00"}
              </>
            </div>
            <div className={styles.legend}>
              <>
                <div className={cn(styles.projectBlock, styles.doneStatus)}></div>
                Выполнено: {acceptedCount ? dropNonSignificantZeros(acceptedCount) : "0.00"} ({ticketItem?.measure})
              </>
            </div>
            <div className={styles.legend}>
              <>
                <div className={cn(styles.projectBlock, styles.acceptedStatus)}></div>
                Принято: {completedCount ? dropNonSignificantZeros(completedCount) : "0.00"} ({ticketItem?.measure})
              </>
            </div>
          </div>
        </div>
        {!isHideHistoryButton && (
          <div className={styles.button}>
            <ButtonBase primary onClick={() => setIsOpen(true)}>
              История выполнения
            </ButtonBase>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(InfoPopup);
