import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Route, Switch } from "react-router-dom";

import { userSelector } from "redux/modules/common/auth";
import { useTypedSelector } from "redux/typedUseSelector";

import TabBar from "../../../UI/atoms/TabBar/TabBar";
import EditProjectHeadline from "./components/EditProjectHeadline/EditProjectHeadline";
import { Spinner } from "components/UI/Spinner/Spinner";
import InputBase from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";

import EditProjectApprovals from "./EditProjectApprovals";
import EditProjectContractors from "./EditProjectContractors";
import EditProjectPassport from "./EditProjectPassport";
import EditProjectUsers from "./EditProjectUsers";

import { useEditProject } from "./hooks/useEditProject";
import { useProjectApprovals } from "./hooks/useProjectApprovals";
import { useProjectEmployees } from "./hooks/useProjectEmployees";
import { useLocationArray } from "utils/hooks/useLocationArray";

import { dirtySubmitBtnSwitcher, dirtySubmitBtnSwitcherForNewProject } from "./utils";

import styles from "./EditProject.module.scss";

const EditProject = ({ match }) => {
  const userInfo = useTypedSelector(userSelector);
  const locationArray = useLocationArray();

  const [dateStart, setDefaultDateStart] = useState(moment());
  const [dateEnd, setDefaultDateEnd] = useState(moment());

  const baseUrl = useMemo(() => {
    const splitUrl = match.url.split("/");
    splitUrl.pop();
    return splitUrl.join("/");
  }, [match.url]);

  const {
    entitiesList,
    object,
    urlId,
    isNew,
    editPassportHandler,
    localForm,
    localFormHandler,
    isLoading,
    resetForm,
    isPending,
  } = useEditProject(baseUrl);

  const {
    payment_decision_employee,
    pto_employee,
    employees,
    responsible_employee,
    deleteEmployeeHandler,
    addEmployeeHandler,
    getValues: getEmployeesValues,
    timesheetResponsibleEmployee,
    stockResponsibleEmployee,
    stocksEmployeeHandler,
    stockData,
    estimateResponsibleEmployee,
  } = useProjectEmployees(urlId, isNew, object, !isLoading);

  const {
    approvalsState,
    handleAddChainEmployee,
    deleteAgreementsEmployee,
    handleChangeAgreementsEmployees,
    getValues: getApprovalsValues,
    handleChangeAllowShift,
    handleChangenew_close_dates,
  } = useProjectApprovals(urlId, isNew, object, employees);

  const isObjectSharedAndAccepted = useMemo(
    () => object?.is_shared && object?.shared_status === "accepted",
    [object?.is_shared, object?.shared_status]
  );
  const isObjectSharedAndTransferred = useMemo(
    () => object?.is_shared && object?.shared_status === "transferred",
    [object?.is_shared, object?.shared_status]
  );

  const handleSubmit = useCallback(
    (values) => {
      editPassportHandler({
        vals: { ...values, ...getApprovalsValues(), ...getEmployeesValues() },
        urlId,
        dateStart,
        dateEnd,
      });
    },
    [urlId, dateEnd, dateStart, getApprovalsValues, editPassportHandler, getEmployeesValues]
  );

  const isNewProject = useMemo(() => locationArray.includes("new"), [locationArray]);

  const tabs = useMemo(() => {
    const tabsContent = [
      {
        link: `${match.url}/passport`,
        text: "Паспорт объекта",
      },
      {
        link: `${match.url}/users`,
        text: "Сотрудники",
      },
      {
        link: `${match.url}/approvals`,
        text: "Согласования",
      },
      {
        link: `${match.url}/contractors`,
        text: "Подрядчики",
      },
    ];
    if (userInfo.tariff === "BASIC") {
      return tabsContent.filter((el) => el.text !== "Подрядчики");
    }
    return tabsContent;
  }, [match.url, userInfo]);

  useEffect(() => {
    if (object?.construction_date_start) {
      setDefaultDateStart(object?.construction_date_start);
    }
    if (object?.construction_date_end) {
      setDefaultDateEnd(object?.construction_date_end);
    }
  }, [object]);

  const editProjectTestId = `page_settings_edit_project_${isNew ? "new" : urlId}`;

  return (
    <TemplateBase dataTestId={editProjectTestId}>
      <div className={cn({ [styles.container]: !isLoading })}>
        {object ? (
          <Form
            onSubmit={handleSubmit}
            initialValues={object}
            render={({ handleSubmit, dirty, form }) => (
              <>
                <EditProjectHeadline
                  isPending={isPending}
                  isDirty={
                    isNewProject
                      ? dirtySubmitBtnSwitcherForNewProject({ localForm, dirty })
                      : dirtySubmitBtnSwitcher({
                          localForm,
                          dirty,
                          object,
                          stockData,
                          employees,
                          responsible_employee,
                          stockResponsibleEmployee,
                          timesheetResponsibleEmployee,
                          payment_decision_employee,
                          pto_employee,
                          approvals: approvalsState,
                          dateStart,
                          dateEnd,
                          estimateResponsible: estimateResponsibleEmployee,
                        })
                  }
                  name={object?.name}
                  submit={handleSubmit}
                  isNew={isNew}
                  resetForm={() => {
                    form.restart();
                    resetForm();
                  }}
                  type="objects"
                />
                <div className={styles.wrapper}>
                  <div className={styles.row}>
                    <InputBase
                      label="*Наименование проекта"
                      meta={{ error: !localForm.name && "Заполните поле", touched: localForm.nameTouched }}
                      onChange={(e) => localFormHandler(e.target.value, "name")}
                      value={localForm.name}
                      onFocus={() => localFormHandler(true, "nameTouched")}
                      disabled={!isNewProject && isObjectSharedAndTransferred}
                    />
                    <div>
                      <Select
                        label="*Организация"
                        options={entitiesList?.map((el) => ({ name: el.name, id: el.id })) || []}
                        input={{
                          onFocus: () => localFormHandler(true, "entityTouched"),
                          onChange: (id) => localFormHandler(id, "entity"),
                          value: localForm.entity,
                        }}
                        meta={{ error: !localForm.entity && "Заполните поле", touched: localForm.entityTouched }}
                        disabled={!isNewProject && isObjectSharedAndTransferred}
                      />
                    </div>
                  </div>
                  <TabBar tabs={tabs} className={styles.mb32} disabled={isNew} isOnlyTabsView />
                  <Switch>
                    <Route exact path={`${match.url}/passport`}>
                      <EditProjectPassport
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        setDefaultDateEnd={setDefaultDateEnd}
                        setDefaultDateStart={setDefaultDateStart}
                        isDisabled={!isNewProject && (isObjectSharedAndAccepted || isObjectSharedAndTransferred)}
                      />
                    </Route>
                    <Route exact path={`${match.url}/users`}>
                      <EditProjectUsers
                        responsible_employee={responsible_employee}
                        pto_employee={pto_employee}
                        payment_decision_employee={payment_decision_employee}
                        employees={employees}
                        timesheetResponsibleEmployee={timesheetResponsibleEmployee}
                        deleteEmployeeHandler={deleteEmployeeHandler}
                        addEmployeeHandler={addEmployeeHandler}
                        object={object}
                        stockResponsibleEmployee={stockResponsibleEmployee}
                        stockResponsibleEmployeeHandler={stocksEmployeeHandler}
                        estimateResponsibleEmployee={estimateResponsibleEmployee}
                        isDisabled={!isNewProject && isObjectSharedAndTransferred}
                      />
                    </Route>
                    <Route exact path={`${match.url}/approvals`}>
                      <EditProjectApprovals
                        approvalsState={approvalsState}
                        deleteAgreementsEmployee={deleteAgreementsEmployee}
                        handleAddChainEmployee={handleAddChainEmployee}
                        urlId={urlId}
                        handleChangeAgreementsEmployees={handleChangeAgreementsEmployees}
                        isDisabled={!isNewProject && isObjectSharedAndTransferred}
                        handleChangeAllowShift={handleChangeAllowShift}
                        handleChangenew_close_dates={handleChangenew_close_dates}
                      />
                    </Route>
                    <Route exact path={`${match.url}/contractors`}>
                      <EditProjectContractors
                        buildingId={urlId}
                        isDisabled={!isNewProject && isObjectSharedAndTransferred}
                      />
                    </Route>
                  </Switch>
                </div>
              </>
            )}
          />
        ) : (
          <Spinner isFixed />
        )}
      </div>
    </TemplateBase>
  );
};

export default React.memo(EditProject);
