import { message } from "antd";
import React, { useCallback, useState } from "react";

import ButtonPill from "../../../../../../atoms/ButtonPill/ButtonPill";
import DoubleInput, { Measure } from "../../../../../../atoms/DoubleInput/DoubleInput";
import InputBase from "../../../../../../atoms/InputBase";
import TableReusableRow, { TableReusableCell } from "../../../../../../atoms/TableReusable/TableReusableRow";

import { PERCENT_DECIMAL } from "../../constants";
import { ISubExpenditure } from "../../types";

import { dropNonSignificantZeros } from "../../../../../../../../utils/formatters/dropNonSignificantZeros";
import {
  parseTransformedNumber,
  parseTransformedString,
  transformDigitToFinancial,
} from "../../../../../../../../utils/formatters/transformDigitToFinancial";
import { checkIsValidCostItem } from "../../utils";

import styles from "./UngroupExpenditureCostItem.module.scss";

export interface IUngroupExpenditureStructureItemProps {
  subExpenditure: ISubExpenditure;
  isEdit: boolean;
  onEdit: () => void;
  onEditSuccess: (exp: Partial<ISubExpenditure>) => void;
  updateSubExpenditure: (exp: Partial<ISubExpenditure>) => void;
  budget: number | string;
  estimatedCost: number | string;
}

const UngroupExpenditureCostItem: React.FC<IUngroupExpenditureStructureItemProps> = ({
  subExpenditure,
  isEdit,
  onEdit,
  onEditSuccess,
  updateSubExpenditure,
  budget,
  estimatedCost,
}) => {
  const [editedInfo, setEditedInfo] = useState<
    Omit<ISubExpenditure, "_unitCost" | "estimated_cost"> & {
      _unitCost: string | number;
      estimated_cost: string | number;
    }
  >({
    ...subExpenditure,
    _unitCost: transformDigitToFinancial(subExpenditure?._unitCost || 0, { withFloat: true, dropZeros: true }),
    estimated_cost: transformDigitToFinancial(subExpenditure?.estimated_cost, { withFloat: true, dropZeros: true }),
  });

  const handleEditButtonClick = () => {
    if (!isEdit && !parseFloat(editedInfo.percent)) onChangeInfo("percent")("");
    if (!isEdit && parseFloat(estimatedCost?.toString()) > 0) {
      if (!parseFloat(editedInfo._unitCost || "")) onChangeInfo("_unitCost")("");
      if (!parseFloat(editedInfo.estimated_cost)) onChangeInfo("estimated_cost")("");
    }
    onEdit();
  };

  const onChangeInfo = useCallback(
    (field: string) => (value: any) => {
      setEditedInfo((prevState) => ({ ...prevState, [field]: value }));
      updateSubExpenditure({
        [field]: ["_unitCost", "estimated_cost"].includes(field)
          ? parseTransformedNumber(value?.toString())?.toString() || ""
          : value,
      });
    },
    [updateSubExpenditure]
  );

  const onChangeUnitCost = (unitCost: string) => {
    const unitCostBeautified = transformDigitToFinancial(parseTransformedString(unitCost) || "", {
      withFloat: true,
      dropZeros: true,
    });
    const unitCostNumber = parseTransformedNumber(unitCostBeautified.toString());
    onChangeInfo("_unitCost")(unitCostBeautified);
    if (!unitCostBeautified || isNaN(unitCostNumber)) return;
    try {
      const newTotal = (unitCostNumber * parseFloat(editedInfo.count)).toFixed(2) || 0;
      onChangeInfo("estimated_cost")(transformDigitToFinancial(newTotal, { withFloat: true, dropZeros: true }));
    } catch (e) {
      console.warn(e);
    }
  };

  const onChangeEstimateCost = (estimated_cost: string) => {
    const estimatedCostBeautified = transformDigitToFinancial(parseTransformedString(estimated_cost) || "", {
      withFloat: true,
      dropZeros: true,
    });
    const estimatedCostNumber = parseTransformedNumber(estimatedCostBeautified.toString());
    onChangeInfo("estimated_cost")(estimatedCostBeautified);
    if (!estimatedCostBeautified || isNaN(estimatedCostNumber)) return;
    try {
      const newUnitCost = (estimatedCostNumber / parseFloat(editedInfo.count)).toFixed(2) || 0;
      onChangeInfo("_unitCost")(transformDigitToFinancial(newUnitCost, { withFloat: true, dropZeros: true }));
    } catch (e) {
      console.warn(e);
    }
  };

  const onChangePercent = (percent: string) => {
    onChangeInfo("percent")(percent);
    if (!percent || isNaN(parseFloat(percent)) || !+estimatedCost) return;
    const newTotal = ((parseFloat(percent) / 100) * parseFloat(estimatedCost.toString())).toFixed(2) || "";
    onChangeEstimateCost(transformDigitToFinancial(newTotal, { withFloat: true, dropZeros: true }).toString());
    const newUnitCost =
      (((parseFloat(percent) / 100) * parseFloat(estimatedCost.toString())) / parseFloat(editedInfo.count)).toFixed(
        2
      ) || "";
    onChangeUnitCost(transformDigitToFinancial(newUnitCost, { withFloat: true, dropZeros: true }).toString());
  };

  const onBlurPercent = (e) => {
    const percentCandidate = e.target?.value;
    if (isNaN(parseFloat(percentCandidate))) onChangePercent("");
    else
      onChangePercent(dropNonSignificantZeros(parseFloat(percentCandidate).toFixed(PERCENT_DECIMAL))?.toString() || "");
  };

  const onBlurEstimateCost = (e) => {
    const totalCandidate = parseTransformedNumber(e.target?.value);
    if (totalCandidate === null || isNaN(totalCandidate)) onChangeEstimateCost("");
    else
      onChangeEstimateCost(transformDigitToFinancial(totalCandidate, { withFloat: true, dropZeros: true }).toString());
  };

  const onBlurUnitCost = (e) => {
    const unitCandidate = parseTransformedNumber(e.target?.value);
    if (unitCandidate === null || isNaN(unitCandidate)) onChangeUnitCost("");
    else onChangeUnitCost(transformDigitToFinancial(unitCandidate, { withFloat: true, dropZeros: true }).toString());
  };

  const validateAndSave = () => {
    const submitCandidate: ISubExpenditure = {
      ...editedInfo,
      _unitCost: parseTransformedNumber(editedInfo._unitCost?.toString())?.toString() || undefined,
      estimated_cost: parseTransformedNumber(editedInfo.estimated_cost?.toString())?.toString() || "",
    };
    if (checkIsValidCostItem(submitCandidate)) onEditSuccess(submitCandidate);
    else message.warn("Заполните все поля");
  };

  if (isEdit) {
    return (
      <TableReusableRow className={styles.row} isHoverable={false}>
        <TableReusableCell isNoBreak>{subExpenditure.number}</TableReusableCell>
        <TableReusableCell isNoBreak>
          <InputBase
            value={editedInfo.name}
            onChange={(e) => onChangeInfo("name")(e.target.value)}
            className={styles.input}
          />
        </TableReusableCell>
        <TableReusableCell isNoBreak>
          <DoubleInput
            firstValueLabel={"за единицу"}
            firstValue={editedInfo?._unitCost || ""}
            onChangeFirstValue={onChangeUnitCost}
            onBlurFirstInput={onBlurUnitCost}
            secondValueLabel={"итого"}
            secondValue={editedInfo.estimated_cost || ""}
            onChangeSecondValue={onChangeEstimateCost}
            onBlurSecondInput={onBlurEstimateCost}
            inputFontSize={1}
            className={styles.doubleInput}
          />
        </TableReusableCell>
        <TableReusableCell isNoBreak>
          <DoubleInput
            firstValueMeasure={Measure.percent}
            secondValueMeasure={Measure.currency}
            inputFontSize={1}
            isSecondDisabled
            onChangeFirstValue={onChangePercent}
            onBlurFirstInput={onBlurPercent}
            firstValue={editedInfo.percent}
            secondValue={transformDigitToFinancial(
              (Number(budget) * (parseFloat(editedInfo.percent) / 100 || 0)).toFixed(2),
              { withFloat: true }
            )}
            isFullHeight
            className={styles.doubleInput}
            firstInputSize={1}
            secondInputSize={2}
          />
        </TableReusableCell>
        <TableReusableCell isNoBreak>
          <ButtonPill onClick={validateAndSave} icon={"check"} variant={"primary"} />
        </TableReusableCell>
      </TableReusableRow>
    );
  }
  return (
    <TableReusableRow className={styles.row} isHoverable={false}>
      <TableReusableCell isNoBreak>{subExpenditure.number}</TableReusableCell>
      <TableReusableCell isNoBreak>{subExpenditure.name}</TableReusableCell>
      <TableReusableCell isNoBreak>
        <DoubleInput
          firstValueLabel={"за единицу"}
          secondValueLabel={"итого"}
          inputFontSize={1}
          firstValue={editedInfo._unitCost}
          secondValue={editedInfo.estimated_cost}
          isFirstDisabled
          isSecondDisabled
          className={styles.doubleInput}
        />
      </TableReusableCell>
      <TableReusableCell isNoBreak>
        <DoubleInput
          firstValueMeasure={Measure.percent}
          secondValueMeasure={Measure.currency}
          inputFontSize={1}
          firstValue={subExpenditure.percent}
          secondValue={transformDigitToFinancial((Number(budget) * (parseFloat(editedInfo.percent) / 100)).toFixed(2), {
            withFloat: true,
            dropZeros: true,
          })}
          isFullHeight
          isFirstDisabled
          isSecondDisabled
          className={styles.doubleInput}
          firstInputSize={1}
          secondInputSize={2}
        />
      </TableReusableCell>
      <TableReusableCell isNoBreak>
        <ButtonPill onClick={handleEditButtonClick} icon={"edit"} variant={"default"} />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(UngroupExpenditureCostItem);
