import cn from "classnames";
import React from "react";

import styles from "./DropdownArrow.module.scss";

//todo delete legacy
const DropdownArrow = ({ isOpen, withBackground }: { isOpen: boolean; withBackground?: boolean }) => {
  return (
    <span
      className={cn(styles.arrowContainer, {
        [styles.withBackground]: withBackground,
        [styles.background]: isOpen && withBackground,
      })}
    >
      <svg
        className={cn(styles.arrow, { [styles.openArrow]: isOpen })}
        xmlns="http://www.w3.org/2000/svg"
        width="14.817"
        height="7.408"
        viewBox="0 0 14.817 7.408"
      >
        <path
          id="Path_7005"
          data-name="Path 7005"
          d="M424.907,20l7.408,7.409-7.408,7.409"
          transform="translate(-20 432.315) rotate(-90)"
          fill="#535353"
        />
      </svg>
    </span>
  );
};

export default React.memo(DropdownArrow);
