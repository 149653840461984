const isSearchParams = (params: paramsType | Array<string>) => params instanceof URLSearchParams;

type paramsType = Record<string, string | null | undefined> | URLSearchParams;

export const queryParamsFormatter = (
  params: paramsType,
  appendingParams: paramsType | Array<string>,
  appendingMultipleParamName?: string
): paramsType => {
  const cleanAppendingParams = (entity: paramsType) => {
    if (isSearchParams(entity)) {
      (entity as URLSearchParams).forEach((value, key) => {
        if (!value) (entity as URLSearchParams).delete(key);
      });
    } else {
      Object.entries(entity).forEach(([key, value]) => {
        if (!value) {
          delete (entity as Record<string, string | null | undefined>)[key];
        }
      });
    }
  };
  cleanAppendingParams(params);
  if (!Array.isArray(appendingParams)) cleanAppendingParams(appendingParams);

  if (isSearchParams(params)) {
    if (isSearchParams(appendingParams)) {
      return new URLSearchParams(params.toString() + "&" + appendingParams.toString());
    }
    if (Array.isArray(appendingParams) && appendingMultipleParamName) {
      appendingParams.forEach((el) => {
        (params as URLSearchParams).append(appendingMultipleParamName, el);
      });
      return params;
    }
    if (typeof appendingParams === "object") {
      for (let key in appendingParams) {
        (params as URLSearchParams).append(key, (appendingParams as Record<string, string>)[key]);
      }
      return params;
    }
  } else {
    if (isSearchParams(appendingParams)) {
      for (let key in params) {
        (appendingParams as URLSearchParams).append(key, (params as Record<string, string>)[key]);
      }
      return appendingParams as URLSearchParams;
    }
    if (Array.isArray(appendingParams) && appendingMultipleParamName) {
      const urlParams = new URLSearchParams(params);
      appendingParams.forEach((el) => {
        urlParams.append(appendingMultipleParamName, el);
      });
      return urlParams;
    }
    if (typeof appendingParams === "object") {
      const urlParams = new URLSearchParams(params);
      for (let key in appendingParams) {
        (urlParams as URLSearchParams).append(key, (appendingParams as Record<string, string>)[key]);
      }
      return urlParams;
    }
  }

  return new URLSearchParams();
};
