import { createSelector } from "reselect";

import { financeStateSelector, stateSelector as indexStateSelector } from "../finance";

import { IAuditInitialState } from "./types";

import { prepareAuditTableData } from "./utils";

export const objectIdSelector = createSelector(indexStateSelector, (state) => state.objectId);

export const stateSelector = createSelector(financeStateSelector, (state): IAuditInitialState => state.audit);

export const isAuditsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const allProjectsAuditsListSelector = createSelector(stateSelector, (state) => state.allProjectsAuditList);
export const detailedProjectAuditsSelector = createSelector(stateSelector, (state) => state.detailedProjectAudits);
export const auditTreeSelector = createSelector(stateSelector, (state) => state.tree);
export const auditDatesSelector = createSelector(stateSelector, (state) => state.dates);
export const auditTypeSelector = createSelector(stateSelector, (state) => state.auditType);

export const auditTableHighlightingSelector = createSelector(stateSelector, (state) => state.tableHighlighting);
export const auditTableDataSelector = createSelector(auditTreeSelector, objectIdSelector, prepareAuditTableData);
export const auditLoadingByObjectSelector = createSelector(stateSelector, (state) => state.isLoadingByObjects);

export const auditDetailedProjectsV2Selector = createSelector(stateSelector, (state) => state.detailedProjectsV2);
