import { MeasureType } from "../../../../../../components/UI/atoms/MeasureBtn/MeasureBtn";

import { IActions } from "../../../../../store";

import { IPlanFactInitialState, IPlanFactResponse } from "./types";

export const planFactActions = {
  clearData: () =>
    ({
      type: "constructing/finance/planFact_CLEAR_DATA",
      payload: {},
    } as const),
  setLoading: (isLoading: boolean) =>
    ({
      type: "constructing/finance/planFact_SET_LOADING",
      payload: isLoading,
    } as const),
  setTree: (tree: IPlanFactResponse) =>
    ({
      type: "constructing/finance/planFact_SET_TREE",
      payload: tree,
    } as const),
  setMeasure: (measure: MeasureType) =>
    ({
      type: "constructing/finance/planFact_SET_MEASURE",
      payload: measure,
    } as const),
  setSelectedDates: ({ start_at, end_at, monthsCount }: IPlanFactInitialState["selectedDates"]) =>
    ({
      type: "constructing/finance/planFact_SET_SELECTED_DATES",
      payload: { start_at, end_at, monthsCount },
    } as const),
};

export type IPlanFactAction = IActions<typeof planFactActions>;
