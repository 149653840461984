import axios from 'axios';
import { message } from 'antd';
import { createSelector } from 'reselect';

const INVITES = 'invites';
const LOAD_INVITES = `${INVITES}/LOAD_INVITES`;
const SET_INVITES = `${INVITES}/SET_INVITES`;

const initialState = {
  invites: null,
  isLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case LOAD_INVITES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_INVITES: {
      return {
        ...state,
        isLoading: false,
        invites: payload
      };
    }

    default:
      return state;
  }
};

export const stateSelector = state => state.invites;
export const invitesSelector = createSelector(stateSelector, state => state.invites);
export const loadingSelector = createSelector(stateSelector, state => state.isLoading);

export const loadInvitesAction = () => {
  return {
    type: LOAD_INVITES,
  };
};

export const setInvites = data => {
  return {
    type: SET_INVITES,
    payload: data,
  };
};

export const loadInvites = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  let cachedPaginationData = null;
  return (paginationParams, filterParams, sorting) => {

    if (ge_cancel) ge_cancel();

    let pagination = cachedPaginationData;

    if (paginationParams) {
      pagination = paginationParams;
      cachedPaginationData = paginationParams;
    }

    const config = {
      params: {
        ...pagination,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      })
    };

    return dispatch => {

      dispatch(loadInvitesAction());

      axios.get(`partnership/invitations/`, config)
        .then(res => {
          dispatch(setInvites(res.data));
        }, error => {
          dispatch(setInvites(null));
        });
    }
  };
})();

export const cancelInvite = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, inviteId, callback = null) => {

    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      })
    };

    return dispatch => {
      axios.delete(`entities/${entityId}/invite/${inviteId}/`, config)
        .then(res => {
          message.success('Приглашение отменено');
          callback && callback();
          //dispatch(setInvites(res.data));
        }, error => {
          message.error('Ошибка сервера');
          //dispatch(setInvites(null));
        });
    }
  };
})();

export const processInvite = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (inviteId, action, callback = null) => {

    if (ge_cancel) ge_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      })
    };

    return dispatch => {
      if (action) {
        axios.post(`/partnership/invitations/${inviteId}/accept/`, config)
          .then(res => {
            message.success(`Заявка одобрена`);
            callback && callback();
            //dispatch(setInvites(res.data));
          }, error => {
            //dispatch(setInvites(null));
          });
      } else {
        axios.post(`/partnership/invitations/${inviteId}/reject/`, config)
          .then(res => {
            message.success(`Заявка отклонена`);
            callback && callback();
          }, error => {
          });
      }
    }
  };
})();
