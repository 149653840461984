import axios from "axios";
import cn from "classnames";
import moment from "moment";
import React, { useCallback } from "react";

import { NavLinkIcon } from "../../../../UI/NavLinkIcon/NavLinkIcon";
import DownloadButton from "../../../../UI/atoms/DownloadButton/DownloadButton";
import DynamicTitleInput, { SIZES } from "../../../../UI/atoms/DynamicTitleInput/DynamicTitleInput";
import Calendar from "../../../../UI/molecules/Calendar/Calendar";
import FileBlock from "../../../../UI/molecules/FileBlock/FileBlock";
import OpeningInfo from "../../../../UI/molecules/OpeningInfo";
import Icon from "components/UI/Icon/Icon";
import { ACT_TYPES_ENUM } from "components/pages/ServiceAct/types/enums";

import { PACKING_LIST_STATUSES } from "../../constants";
import { IFile } from "types/interfaces/Files";
import { IPackingList, IPackingListSubpackinglist } from "types/interfaces/PackingList";
import { IServiceAct } from "types/interfaces/ServiceAct";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import downloadFile from "../../../../../utils/helpers/download";

import calendarBlue from "images/icons/calendarBlue.svg";
import statusIn from "images/statuses/status-in.svg";
import statusOut from "images/statuses/status-out.svg";

import styles from "./Header.module.scss";

export interface IProps {
  data: IServiceAct | IPackingList;
  onChangeNumber: (number: string) => void;
  addFiles: (files: IFile[]) => void;
  deleteFile: (removedFileId: number) => void;
  canEdit: boolean;
  changeExecutionDate: (date: moment.Moment) => void;
  isSimplified: boolean;
  type?: ACT_TYPES_ENUM;
  propsFiles?: IFile[];
  subPackingList?: IPackingListSubpackinglist;
  filesPermissions?: Record<string, boolean>;
  fallbackObjectId?: string;
}

const Header: React.FC<IProps> = ({
  data,
  onChangeNumber,
  addFiles,
  deleteFile,
  canEdit,
  changeExecutionDate,
  subPackingList,
  filesPermissions,
  isSimplified,
  type = ACT_TYPES_ENUM.PACKING_LISTS,
  propsFiles,
  fallbackObjectId,
}) => {
  const fullUrlModule = useUrlModuleWithObjectId(fallbackObjectId);

  const handleDownload = useCallback(() => {
    const purchaserPrefix = type === ACT_TYPES_ENUM.PACKING_LISTS ? "/purchaser" : "";
    downloadFile(`${axios.defaults.baseURL}${purchaserPrefix}/${type}/${data.id}/reports/excel/`);
  }, [data.id, type]);

  const getUrlPrefix = useCallback(() => {
    if (fullUrlModule !== "objects") return fullUrlModule;
    return `${fullUrlModule}/${data.building.id}`;
  }, [fullUrlModule, data.building.id]);

  const address =
    type === ACT_TYPES_ENUM.SERVICE_ACTS ? (data as IServiceAct).address : (data as IPackingList).delivery_address;

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{type === ACT_TYPES_ENUM.PACKING_LISTS ? "УПД №" : "Акт №"}</h2>
          {canEdit ? (
            <DynamicTitleInput
              title={data.number}
              onChange={onChangeNumber}
              placeholder="Введите номер"
              size={SIZES.NORMAL}
            />
          ) : (
            <div className={styles.number}>{data.number}</div>
          )}
        </div>
        <DownloadButton className={styles.downloadButton} onDownload={handleDownload} />
        <div className={styles.mainInfo}>
          <div className={styles.row}>
            <div>Дата поставки:</div>
            <Calendar
              classNameSelect={cn(styles.calendar, { [styles.calendarDisabled]: !canEdit })}
              icon={calendarBlue}
              value={moment((data as IPackingList).delivery_date || (data as IServiceAct).date)}
              setValue={changeExecutionDate}
              disabled={!canEdit}
              variant={"display"}
            />
          </div>
          <div className={styles.row}>
            <div>заказ:</div>
            <div className={styles.rowValue}>
              {!isSimplified ? (
                <NavLinkIcon to={`/${getUrlPrefix()}/orders/${data.order.id}`} title={`№${data.order.number}`} />
              ) : (
                <div className={styles.hiddenText}>{`№${data.order.number}`}</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.topBlock}>
          <div className={styles.col}>
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Статус:</div>
              <div className={styles.myOrdersStatus}>
                <span>
                  {data.status === PACKING_LIST_STATUSES.accepted ? (
                    <Icon icon={statusIn} className={styles.arrowLeft} />
                  ) : (
                    <Icon icon={statusOut} className={styles.arrowLeft} />
                  )}
                  {data.status_name}
                </span>
              </div>
            </div>
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Заказчик:</div>
              <OpeningInfo info={data.executor_purchaser} title="Контакты заказчика" />
            </div>
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Поставщик:</div>
              <OpeningInfo info={data.executor_provider} title="Контакты поставщика" />
            </div>
          </div>
          <div className={cn(styles.col, styles.middleCol)}>
            {data.status === PACKING_LIST_STATUSES.accepted && (
              <div className={styles.topBox}>
                <div className={styles.topBoxName}>Принял:</div>
                <OpeningInfo info={data.accepted_user} title="Поставку принял" />
              </div>
            )}
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Адрес доставки:</div>
              <div>{address ? address : "отсутствует"}</div>
            </div>
            {subPackingList && (
              <div className={styles.topBox}>
                <div className={styles.topBoxName}>УПД на остаток:&nbsp;</div>
                {!isSimplified ? (
                  <NavLinkIcon
                    to={`/${fullUrlModule}/documents/packing-lists/${subPackingList.id}`}
                    title={`${subPackingList.number ? `УПД № ${subPackingList.number}` : "УПД"}`}
                  />
                ) : (
                  <div className={styles.hiddenText}>
                    {`${subPackingList.number ? `УПД № ${subPackingList.number}` : "УПД"}`}
                  </div>
                )}
              </div>
            )}
          </div>
          <FileBlock
            className={styles.fileBlock}
            files={propsFiles || (data as IPackingList).files}
            addFiles={addFiles}
            deleteFile={deleteFile}
            permissions={filesPermissions}
          />
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
