import React, { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  detailedProjectForecastsSelector,
  forecastTableDataSelector,
  forecastTreeSelector,
  isForecastsLoadingSelector,
} from "../../../../../../../redux/modules/common/constructing/finance/forecast/selectors";
import { prepareForecastTableDataByProjects } from "redux/modules/common/constructing/finance/forecast/utils";
import { summaryYearSelector } from "redux/modules/common/constructing/finance/summary/selectors";
import { useTypedSelector } from "redux/typedUseSelector";

import { Spinner } from "../../../../../../UI/Spinner/Spinner";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import Table from "../../../Summary/TableSide/Table/Table";
import FinanceFooter from "../../../_internal/FinanceFooter/FinanceFooter";
import { extraForecastDataSelector } from "../../../widgets/AddExtraExpenseModal/model/selectors";

import { ITotalsInFinance } from "../../../types";

import { isFinanceFooter } from "../../../_internal/utils";
import { prepareDataForTableInExtraForecast } from "../../../widgets/AddExtraExpenseModal/model/utils";

import styles from "../../../Summary/TableSide/TableSide.module.scss";

const TableSide: FC = () => {
  const objectId = useObjectId(2);
  const { projectId } = useParams<Record<string, string>>();
  const isLoading = useSelector(isForecastsLoadingSelector);
  const tableData = useSelector(forecastTableDataSelector);
  const totalsData = (useSelector(detailedProjectForecastsSelector as any) as any)[objectId] as ITotalsInFinance | null;
  const extraData = useSelector(extraForecastDataSelector)[objectId];
  const rawData = useSelector(forecastTreeSelector);
  const year = useSelector(summaryYearSelector) as number;
  const projectDetail = useTypedSelector((state) => state.constructing.finance.forecast.detailedProjectSummaryV2)[
    projectId as any
  ];

  const mergedData = useMemo(() => {
    if (!tableData) return [];
    if (projectId && projectId !== "0" && (!objectId || objectId === "0")) {
      return prepareForecastTableDataByProjects(rawData as any, projectId, objectId, year);
    }
    if (!extraData && tableData) return tableData;

    return [...tableData, ...prepareDataForTableInExtraForecast(extraData)];
  }, [tableData, extraData, rawData, objectId, projectId, year]);

  const footerData = useMemo(() => {
    const objId = +(objectId ?? "0");
    if (objId > 0) {
      return totalsData;
    } else {
      return projectDetail;
    }
  }, [projectId, objectId, totalsData, projectDetail]);

  if (isLoading && !tableData?.length) return <Spinner isStatic />;

  return (
    <>
      <div className={styles.tableSide}>
        <Table data={mergedData} totalsData={totalsData} />
      </div>
      {isFinanceFooter(objectId, projectId) && <FinanceFooter totalsData={footerData as any} />}
    </>
  );
};

export default React.memo(TableSide);
