import cn from "classnames";
import React, { useMemo } from "react";

import { ReactComponent as ClipIcon } from "images/icons/clip.svg";

import styles from "./FileUploader.module.scss";

interface IProps {
  className: string;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  children?: string;
  isDisabled?: boolean;
}

const FileUploader: React.FC<IProps> = ({
  className,
  changeHandler,
  text = "Прикрепить файл",
  children,
  isDisabled,
}) => {
  const temporalId = useMemo(() => String(Math.random()), []);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(e);
    setTimeout(() => {
      if (inputRef.current?.value) {
        inputRef.current.value = "";
      }
    }, 1);
  };

  return (
    <label
      className={cn({ [styles.container]: !children, [styles.disabled]: isDisabled }, className)}
      htmlFor={temporalId}
    >
      {!children && <ClipIcon />}
      {!children && text}
      {children}
      <input
        ref={inputRef}
        type="file"
        id={temporalId}
        onChange={change}
        className={styles.hideInput}
        disabled={isDisabled}
      />
    </label>
  );
};

export default FileUploader;
