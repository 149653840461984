import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  isLoadingSelector,
  planFactBudgetsSelector,
  planFactSectionsSelector,
} from "../../../../redux/modules/common/constructing/finance/planFact/selectors";

import EmptyPlaceholder from "../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ForbiddenPage from "../../../routes/components/ForbiddenPage/ForbiddenPage";
import PlanFactIndicators from "./components/PlanFactIndicators/PlanFactIndicators";
import PlanFactSectionRow from "./components/PlanFactSectionRow/PlanFactSectionRow";
import { Spinner } from "components/UI/Spinner/Spinner";

import {
  VIEW_PLAN_FACT_FINANCE,
  VIEW_PLAN_FACT_VOLUME,
} from "../../../../constants/permissions/manufacturingPermissions";
import { ROUTES } from "../../../../constants/routes";
import { IRouterParamsWithObjectId } from "../../../../types/routerTypes";

import usePermission from "../../../../hooks/usePermission";

import replaceAll from "utils/helpers/replaceAll";

import planFact from "images/icons/navigation/plan-fact.svg";

import styles from "./PlanFactList.module.scss";

const PlanFactList: React.FC<PropsWithChildren> = ({ children }) => {
  const isLoading = useSelector(isLoadingSelector);

  const sections = useSelector(planFactSectionsSelector);
  const budgets = useSelector(planFactBudgetsSelector);

  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const viewVolumePermission = usePermission(VIEW_PLAN_FACT_VOLUME);
  const viewFinancePermission = usePermission(VIEW_PLAN_FACT_FINANCE);

  if (!viewFinancePermission && viewVolumePermission) {
    return <Redirect to={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_VOLUME} />;
  } else if (!viewFinancePermission) {
    return <ForbiddenPage />;
  }

  return (
    <div className={styles.listContainer}>
      {children}
      <PlanFactIndicators planFactBudgets={budgets} />
      {isLoading && <Spinner />}
      {!isLoading && sections?.length
        ? sections?.map((section) => {
            return (
              <Link
                key={section?.id}
                to={replaceAll(ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_FINANCE_ITEM, {
                  ":objectId": objectId,
                  ":sectionId": section.id,
                })}
              >
                <PlanFactSectionRow section={section} />
              </Link>
            );
          })
        : null}
      {!isLoading && !sections?.length ? <EmptyPlaceholder img={planFact} /> : null}
    </div>
  );
};

export default React.memo(PlanFactList);
