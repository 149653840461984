import React from "react";
import ReactTextareaAutosize from "react-textarea-autosize";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Amounts from "components/UI/molecules/Amounts/Amounts";

import { IPackingList } from "types/interfaces/PackingList";

import styles from "./Footer.module.scss";

interface IProps {
  canEditPackingList: boolean;
  productsHaveChanges?: boolean;
  onAccept: () => void;
  data?: IPackingList;
  commentChangeHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  comment: string;
  isAccepting: boolean;
}

const Footer: React.FC<IProps> = ({
  canEditPackingList,
  productsHaveChanges = false,
  onAccept,
  data,
  commentChangeHandler,
  comment,
  isAccepting,
}) => {
  return (
    <footer className={styles.footer}>
      {data && data.calculation && (
        <Amounts
          productsAmount={data.calculation?.amount}
          taxAmount={data.calculation?.amount_vat}
          totalAmount={data.calculation?.amount_total}
          deliveryAmount={data.calculation?.amount_delivery}
          taxIsInclude={!!data.calculation?.amount_vat}
        />
      )}
      <ReactTextareaAutosize
        onChange={commentChangeHandler}
        value={comment}
        className={styles.commentTextarea}
        disabled={!canEditPackingList}
        maxLength={510}
        minRows={3}
        maxRows={10}
        placeholder={"Комментарий закупщика"}
      />
      {canEditPackingList && (
        <div className={styles.buttons}>
          {" "}
          {/* @ts-ignore */}
          <ButtonBase medium onClick={onAccept} isLoading={isAccepting}>
            Принять {productsHaveChanges && "с изменениями"}
          </ButtonBase>
        </div>
      )}
    </footer>
  );
};

export default React.memo(Footer);
