import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";

import MonthsSlider from "../../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import ButtonBase from "components/UI/atoms/ButtonBase";
import Modal from "components/UI/atoms/Modal";
import Select from "components/UI/atoms/Select";

import { Events } from "../Events/Events";
import { eventsBtnSvg } from "./eventsBtnSvg";

import { eventSelectArr } from "constants/constant";
import { IEventProjects } from "types/interfaces/Event";

import styles from "./CalendarHeader.module.scss";

export interface IProps {
  date: Moment;
  setDate: React.Dispatch<React.SetStateAction<Moment>>;
  setIsSlideModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDateBefore: React.Dispatch<React.SetStateAction<Moment>>;
  setDateAfter: React.Dispatch<React.SetStateAction<Moment>>;
  eventSelect: string | null;
  setEventSelect: React.Dispatch<React.SetStateAction<IEventProjects[] | string | null>>;
  objSelect: string | null;
  setObjSelect: React.Dispatch<React.SetStateAction<IEventProjects[] | string | null>>;
  objSelectArr: IEventProjects[];
}

const CalendarHeader: React.FC<IProps> = ({
  date,
  setDate,
  setIsSlideModal,
  setDateBefore,
  setDateAfter,
  setEventSelect,
  eventSelect,
  setObjSelect,
  objSelectArr,
  objSelect,
}) => {
  const [isModal, setIsModal] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [localObj, setLocalObj] = useState<IEventProjects[] | string | null>(objSelectArr);
  const [localEvents, setLocalEvents] = useState<IEventProjects[] | string | null>(eventSelectArr);

  const setMonthAll = (month: Moment) => {
    setDate(month);
    setDateAfter(moment(month).day(0).add(-21, "days"));
    setDateBefore(moment(month).day(28).add(25, "days"));
  };

  const clearCalendarFilters = () => {
    setEventSelect(null);
    setObjSelect(null);
    setIsModal(false);
  };

  const submitHandler = () => {
    setObjSelect(localObj);
    setEventSelect(localEvents);
    setIsModal(false);
  };

  useEffect(() => {
    setLocalObj(objSelect);
    setLocalEvents(eventSelect);
    if ((!objSelect || objSelect === "Все проекты") && (!eventSelect || eventSelect === "events")) {
      setIsFilterActive(false);
    } else {
      setIsFilterActive(true);
    }
  }, [objSelect, eventSelect]);

  return (
    <div className={styles.top}>
      <Events />
      <div className={styles.topBox2}>
        <MonthsSlider date={date} onChange={setMonthAll} />
      </div>
      <div className={styles.topBox1} data-testid="calendar_events_btn">
        <button className={styles.topBox1Btn} onClick={() => setIsSlideModal(true)}>
          {eventsBtnSvg}
        </button>
      </div>
      <button
        className={`${isFilterActive ? styles.activeFilter : ""} ${styles.filterBtn}`}
        onClick={() => setIsModal(true)}
      >
        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.82031 1.3457H23.1826L14.5908 9.93555V20.6519L11.1904 18.7427V9.93457L2.82031 1.3457Z"
            stroke="#707070"
            strokeWidth="2"
          />
        </svg>
      </button>
      <Modal isOpen={isModal} onClose={() => setIsModal(false)} title="Параметры отображения" className={styles.modal}>
        <div className={styles.selects}>
          <Select
            className={styles.select}
            options={objSelectArr}
            onChange={setLocalObj}
            placeholder={"Все проекты"}
            value={localObj}
          />
          <Select
            className={styles.select}
            options={eventSelectArr}
            onChange={setLocalEvents}
            placeholder={"Все события"}
            value={localEvents}
          />
        </div>
        <div className={styles.btns}>
          <ButtonBase onClick={clearCalendarFilters} buttonPrimaryEmpty>
            Очистить
          </ButtonBase>
          <ButtonBase onClick={submitHandler}>Применить</ButtonBase>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(CalendarHeader);
