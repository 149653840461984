import { IActions } from "redux/store";

import { IDetailedKs2InDocs, IDetailedKs3InDocs, IKs3InList } from "./types";
import { IList } from "types/ListResponse";

export const ksListActions = {
  setKsList: (buildingId: string, results: IList<IKs3InList>) =>
    ({
      type: "ksList/SET_LIST",
      payload: {
        buildingId,
        results,
      },
    } as const),
  addMoreKsList: (buildingId: string, results: IList<IKs3InList>) =>
    ({
      type: "ksList/ADD_MORE_LIST",
      payload: {
        buildingId,
        results,
      },
    } as const),
  setKsListLoading: (buildingId: string, status: boolean) =>
    ({
      type: "ksList/SET_LIST_LOADING",
      payload: {
        buildingId,
        status,
      },
    } as const),
  setKsSublist: (ks3Id: number, results: IDetailedKs3InDocs) =>
    ({
      type: "ksList/SET_SUBLIST",
      payload: {
        ks3Id,
        results,
      },
    } as const),
  setKsSublistLoading: (ks3Id: number, status: boolean) =>
    ({
      type: "ksList/SET_SUBLIST_LOADING",
      payload: {
        ks3Id,
        status,
      },
    } as const),
  setDetailedKs2: (ks2Id: number, data: IDetailedKs2InDocs) =>
    ({
      type: "ksList/SET_DETAILED_KS2",
      payload: { ks2Id, data },
    } as const),
  setDetailedKs2Loading: (ks2Id: number, status: boolean) =>
    ({
      type: "ksList/SET_DETAILED_KS2_LOADING",
      payload: { ks2Id, status },
    } as const),
  invalidateKey: () =>
    ({
      type: "ksList/INVALIDATE_KEY",
      payload: true,
    } as const),
};

export type IKsListActions = IActions<typeof ksListActions>;
