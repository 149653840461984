import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  auditTreeSelector,
  auditTypeSelector,
  objectIdSelector,
} from "../../../../../../redux/modules/common/constructing/finance/audit/selectors";
import { toggleOpenAuditTree } from "../../../../../../redux/modules/common/constructing/finance/audit/thunks";
import { IAuditTree } from "../../../../../../redux/modules/common/constructing/finance/audit/types";
import { formatFinanceTreeViaObjectId } from "../../../../../../redux/modules/common/constructing/finance/financeCommonUtils";

import List from "../../_internal/List";
import { useAuditData } from "../useAuditData";

import { LABELS } from "./constants";

import styles from "./ListSide.module.scss";

export interface IListSideProps extends PropsWithChildren {}

const ListSide: React.FC<IListSideProps> = ({ children }) => {
  const dispatch = useDispatch();
  const objectId = useSelector(objectIdSelector);
  const tree = useSelector(auditTreeSelector);
  const type = useSelector(auditTypeSelector);
  const { projectId } = useParams<Record<string, string>>();

  const handleListItemClick = (subTree: IAuditTree) => {
    dispatch(toggleOpenAuditTree(subTree));
  };

  const displayedTree = useMemo(
    () => formatFinanceTreeViaObjectId(tree, +objectId, projectId),
    [tree, objectId, projectId]
  );

  return (
    <div className={styles.listSide}>
      <div className={styles.sticky}>
        {children}
        <div className={styles.listSide__header}>
          <span className={styles.listSide__title}>{type === "summary" ? LABELS.SUMMARY : LABELS.FORECAST}</span>
        </div>
      </div>

      <div className={styles.listSide__list}>
        <List data={displayedTree} handleListItemClick={handleListItemClick} />
      </div>
    </div>
  );
};

export default React.memo(ListSide);
