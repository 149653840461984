import { message } from "antd";
import moment from "moment";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { getNormalizedAookPermit } from "components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/Aook/utils";

import { aookActions } from "./actions";
import { aookApi } from "./api";

import { IAosrDoc } from "../aosr/types";
import { ICreateAook, ICreateAookDoc } from "./types";

import { separateDocsWhileEditingAct } from "../aosr/utils";
import { prepareAookForEdit } from "./utils";
import { getArrayFilesInBase64 } from "utils/formatters/getArrayFilesInBase64";
import { sanitizeObject } from "utils/formatters/sanitizers";
import { errorCatcher } from "utils/helpers/errorCatcher";
import axios from "axios";
import { IExpenditure } from "../../../../../../types/interfaces/Expenditure";
import { aosrActions } from "../aosr/actions";

export const publishAook =
  (buildingId: string, values: ICreateAook) => (dispatch: Dispatch, getState: () => RootState) => {
    const aookId = getState().aook.currentAook?.id;
    if (!aookId) return;
    dispatch(aookActions.setCreatingPending(true, +buildingId));
    aookApi
      .editAook(values, buildingId, aookId)
      .then(() => {
        if (values.docs?.length) {
          const docRequests = values.docs?.map(async (el: any) => {
            if (el.files?.length) {
              /* @ts-ignore */
              const mappedInB64Files = await getArrayFilesInBase64(el.files || []);
              return aookApi.createDoc(buildingId, aookId, {
                ...el,
                aook: aookId,
                files: mappedInB64Files || [],
              });
            } else {
              return aookApi.createDoc(buildingId, aookId, {
                ...el,
                aook: aookId,
              });
            }
          });
          Promise.all(docRequests).then(() => {
            message.success("Успешно сформировано");
            dispatch(aookActions.invalidateKey());
          });
        } else {
          dispatch(aookActions.invalidateKey());
          message.success("Успешно сформировано");
        }
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(aookActions.setCreatingPending(false, +buildingId));
      });
  };

export const createAook = (buildingId: string, values: ICreateAook) => (dispatch: Dispatch) => {
  dispatch(aookActions.setCreatingPending(true, +buildingId));
  aookApi
    .createDraft(values, buildingId)
    .then(({ data }) => {
      dispatch(aookActions.setCurrentAook(data));
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(aookActions.setCreatingPending(false, +buildingId));
    });
};

export const getDetailedAook = (objectId: string, aookId: number) => (dispatch: Dispatch) => {
  aookApi
    .getDetailedAook(objectId, aookId)
    .then(({ data }) => {
      dispatch(aookActions.setDetailedAook(data));
    })
    .catch(errorCatcher);
};

export const getAookDocs = (objectId: string, aookId: number) => (dispatch: Dispatch) => {
  aookApi.getDocs(objectId, aookId).then(({ data }) => {
    dispatch(aookActions.setDocs(data, aookId));
  });
};

export const getWorksBySectionInAook = (buildingId: string, sectionId: number) => async (dispatch: Dispatch) => {
  await axios
    .get(`/tree/buildings/${buildingId}/lite/`, { params: { limit: 500, expenditure_type: "work" } })
    .then((response) => {
      const works: IExpenditure[] = [];
      response.data.sections.forEach(
        (section: {
          id: number;
          subsections: { id: number; expenditures: IExpenditure[]; groups: IExpenditure[] }[];
        }) =>
          section.subsections.forEach((subsection) => {
            if (subsection.id === sectionId)
              works.push(
                ...subsection.expenditures?.filter((exp) => exp.expenditure_type === "work"),
                ...subsection.groups
              );
          })
      );
      dispatch(aookActions.setWorksBySection(works, sectionId));
    });
};

export const closeAook = (objectId: string, aookId: number, file: File) => async (dispatch: Dispatch) => {
  const [serializedFile] = await getArrayFilesInBase64([file]);
  dispatch(aookActions.setClosingPending(true, aookId));
  aookApi
    .closeAook(objectId, aookId, serializedFile.file as string)
    .then(() => {
      dispatch(aookActions.invalidateKey());
      message.success("Успешно");
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(aookActions.setClosingPending(false, aookId));
      dispatch(aookActions.invalidateKey());
    });
};

export const editAook =
  (
    data: Partial<ICreateAook>,
    buildingId: string,
    aookId: number,
    config: { isSilent?: boolean; initialDocs: IAosrDoc[] },
    callback?: () => void
  ) =>
  (dispatch: Dispatch) => {
    const { newDocs } = separateDocsWhileEditingAct(data.docs!, config.initialDocs);
    const preparedData = prepareAookForEdit(data);

    preparedData.next_expenditure = data.next_expenditure;
    preparedData.next_expenditure_id = data.next_expenditure_id;
    preparedData.next_group = data.next_group;
    preparedData.next_group_id = data.next_group_id;

    aookApi
      .editAook(preparedData, buildingId, aookId)
      .then(() => {
        if (newDocs?.length) {
          const docRequests = newDocs?.map(async (el: any) => {
            if (el.listfiles?.length) {
              const mappedInB64Files = await getArrayFilesInBase64(el.listfiles || []);
              return aookApi.createDoc(buildingId, aookId, {
                ...el,
                aook: aookId,
                files: mappedInB64Files || [],
                doc_date: moment(el.doc_date).format("YYYY-MM-DD"),
              });
            } else {
              return aookApi.createDoc(buildingId, aookId, {
                ...el,
                aook: aookId,
                doc_date: moment(el.doc_date).format("YYYY-MM-DD"),
              });
            }
          });
          Promise.all(docRequests).then(() => {
            message.success("Успешно сохранено");
            dispatch(aookActions.invalidateKey());
          });
        } else {
          dispatch(aookActions.invalidateKey());
          message.success("Успешно сохранено");
        }
        callback?.();
      })
      .catch(errorCatcher);
  };

export const deleteDocInAook = (buildingId: string, aookId: number, docId: number) => (dispatch: Dispatch) => {
  aookApi
    .deleteDoc(buildingId, aookId, docId)
    .then(() => {
      dispatch(aookActions.invalidateKey());
    })
    .catch(errorCatcher);
};
