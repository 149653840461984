import React from 'react';
import cn from 'classnames';
import styles from './BottomSliderMaterials.module.scss';

import Portal from 'components/UI/atoms/Portal';
import useEscapeHandler from '../../../../../utils/hooks/useEscapeHandler'

const BottomSliderMaterials = ({
  isOpen,
  closeFunc,
  title,
  children,
  className,
  parentSelector,
}) => {
  useEscapeHandler(closeFunc)
  return (
    <>
      <Portal>
        {isOpen && <div className={styles.shadow} onClick={closeFunc}></div>}
        <div
          className={cn(styles.container, className, {
            [styles.active]: isOpen,
          })}
        >
          <div className={cn(styles.content)}>
            <button onClick={closeFunc} type="button" className="close" />
            {children}
          </div>
        </div>
      </Portal>
    </>
  );
};

export default BottomSliderMaterials;
