import { IJournalAction } from "./actions";

import { IJournalInitialState } from "./types";

const moduleName = "journal";

export const SET_GROUP_DETAIL_INFO = `${moduleName}/SET_GROUP_DETAIL_INFO`;

const initialState: IJournalInitialState = {
  groupDetailInfo: null,
  invalidateKey: 1,
};

export default (state = initialState, action: IJournalAction): IJournalInitialState => {
  const { payload, type } = action;

  switch (type) {
    case SET_GROUP_DETAIL_INFO:
      return {
        ...state, //@ts-ignore
        groupDetailInfo: payload,
      };
    case "journal/CHANGE_INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    default:
      return state;
  }
};
