import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { permissionsApi } from "./permissionsApi";
import { permissionsActions } from "./permissionsSlice";

import { IPermissionSimplified } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getPermissionsTree = (userId: number, withDescription?: boolean) => (dispatch: Dispatch) => {
  permissionsApi.getPermissions(userId, withDescription ? 1 : null).then(({ data }) => {
    dispatch(permissionsActions.setPermissionsTree(data));
  });
};

export const getCurrentUserPermissions = (userId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const myId = getState().auth?.user?.id;
  const isMyPermissions = +myId === +userId;
  if (!getState().permissionsV2.userPermissionsList[userId]?.length && isMyPermissions) {
    dispatch(permissionsActions.setIsLoading(true));
  }
  permissionsApi
    .getPermissionsList(userId)
    .then(({ data }) => {
      dispatch(permissionsActions.setUserPermissions({ list: data, userId }));
    })
    .finally(() => {
      dispatch(permissionsActions.setIsLoading(false));
    });
};

export const updatePermissions = (userId: number, permissionsDict: Record<string, boolean>) => (dispatch: Dispatch) => {
  const permissions: IPermissionSimplified[] = [];
  Object.entries(permissionsDict).forEach(([alias, value]) => {
    permissions.push({ alias, state: value ? "on" : "off" });
  });
  dispatch(permissionsActions.setIsPending({ status: true, userId }));
  permissionsApi
    .updatePermissions(userId, permissions)
    .then(() => {
      dispatch(permissionsActions.invalidateKey());
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(permissionsActions.setIsPending({ status: false, userId }));
    });
};
