import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";

import DatePicker from "../CalendarRange/components/DatePicker/DatePicker";
import MonthPicker from "../CalendarRange/components/MonthPicker/MonthPicker";
import Icon from "components/UI/Icon/Icon";

import PopoverOverlay from "../PopoverOverlay/PopoverOverlay";
import { Moment } from "moment/moment";

import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import useEscapeHandler from "../../../../utils/hooks/useEscapeHandler";

import activeCalendarBlue from "../../../../images/icons/activeCalendarBlue.png";

import styles from "./Calendar.module.scss";

export interface IProps {
  value: string | moment.Moment | null;
  setValue: (date: moment.Moment) => void;
  icon?: string;
  disabled?: boolean;
  classNameSelect?: string;
  classNameOptions?: string;
  label?: string;
  placeholder?: string;
  format?: string;
  testId?: number;
  error?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: "default" | "display";
  customIcon?: React.ReactNode;
  isDefaultOpen?: boolean;
  displayValue?: string;
  containerClassName?: string;
  parsingFormat?: string;
  touched?: boolean;
}

const Calendar: React.FC<IProps> = ({
  value,
  setValue,
  classNameSelect,
  classNameOptions,
  disabled = false,
  icon = activeCalendarBlue,
  label,
  placeholder = "",
  format = "DD.MM.YYYY",
  testId,
  error,
  onFocus,
  onBlur,
  variant,
  customIcon,
  isDefaultOpen,
  displayValue,
  containerClassName,
  parsingFormat,
  touched,
}) => {
  const [date, setDate] = useState<string | moment.Moment>(value || "" /* ?? moment() */);
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  useEffect(() => {
    if (isDefaultOpen !== undefined) {
      setIsOpen(isDefaultOpen);
    }
  }, [isDefaultOpen]);

  useEscapeHandler(() => setIsOpen(false));

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false);
    onFocus?.();
    onBlur?.();
  });

  const currentMoment = useMemo(() => (!!date ? moment(date, parsingFormat) : undefined), [date, parsingFormat]);

  const [navigationDate, setNavigationDate] = useState<Moment>(moment(value || moment()));

  const setToggle = () => {
    setIsOpen(!isOpen);
    isOpen ? onFocus?.() : onBlur?.();
  };

  const handleSetDate = (date: moment.Moment) => {
    setValue(date);
    setDate(date);
    setToggle();
  };

  const submitHandler = (date: moment.Moment) => {
    setTimeout(() => {
      handleSetDate(date);
    }, 100);
  };

  return (
    <div className={containerClassName}>
      {label && <label className={styles.label}>{label}</label>}
      <PopoverOverlay
        isOpen={isOpen}
        isDisabled={disabled}
        portalClassName={styles.optionsPortal}
        placement={"bottom"}
        popoverBorderColor={"primary"}
        className={styles.popoverWrapper}
        content={
          <div
            className={cn(styles.optionsBlock, classNameOptions, styles.isOpen)}
            onClick={(e) => e.stopPropagation()}
            ref={wrapperRef}
          >
            <div className={styles.wrapper}>
              <div className={styles.pickers}>
                <MonthPicker date={navigationDate} setDate={setNavigationDate} />
                <DatePicker
                  date={currentMoment!}
                  dateEnd={currentMoment!}
                  dateStart={currentMoment!}
                  setDate={submitHandler}
                  navigationDate={navigationDate}
                  isDirty={false}
                />
              </div>
            </div>
          </div>
        }
      >
        <div className={styles.container}>
          <div
            className={cn(styles.select, classNameSelect, {
              [styles.isOpen]: isOpen && !disabled,
              [styles.display]: variant === "display",
            })}
            onClick={setToggle}
            aria-disabled={disabled}
          >
            <span className={cn(styles.title, { [styles.placeholder]: !value })} data-testid={testId}>
              {displayValue ? displayValue : value ? moment(value).format(format) : placeholder}
            </span>
            {!!customIcon ? customIcon : <Icon icon={icon} className={styles.calendarIcon} />}
          </div>
          {touched && error && <span className={styles.error}>{error}</span>}
        </div>
      </PopoverOverlay>
    </div>
  );
};

export default React.memo(Calendar);
