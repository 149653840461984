import moment from "moment";
import React, { FC } from "react";

import { ICertainTaskResponse } from "redux/modules/purchaser/Tasks/types";

import Select from "components/UI/atoms/Select";
import Calendar from "components/UI/molecules/Calendar/Calendar";
import TaskModalViewPartial from "components/UI/templates/LabelTemplate/LabelTemplate";
import LabelTemplate from "components/UI/templates/LabelTemplate/LabelTemplate";
import PriorityRange from "components/pages/Tasks/components/PriorityRange/PriorityRange";
import { TASKS_STATUS_OPTIONS } from "components/pages/Tasks/constants";

import { MAIN_TAB_ID } from "../../SimplifiedTask";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./MainTabArea.module.scss";

interface IMainTabArea extends Pick<ICertainTaskResponse, "deadline" | "priority" | "status" | "description"> {
  buildingName: string;
  sectionName: string | null;
  executor: ICertainTaskResponse["executor_user"];
  viewers: ICertainTaskResponse["viewers_data"];
  creator: ICertainTaskResponse["creator_user"];
  rangeClassName?: string;
}

const MainTabArea: FC<IMainTabArea> = ({
  deadline,
  priority,
  sectionName,
  buildingName,
  executor,
  viewers,
  status,
  creator,
  description,
  rangeClassName,
}) => {
  return (
    <div className={styles.container} data-testid={`area-${MAIN_TAB_ID}`}>
      {description && (
        <LabelTemplate label="Описание задачи" testId="description">
          {description}
        </LabelTemplate>
      )}
      <TaskModalViewPartial label="Контрольная дата">
        <Calendar
          classNameSelect={styles.input}
          value={moment(deadline)}
          format="DD/MM/YY"
          setValue={undefined}
          label={undefined}
          disabled={true}
          testId="deadlineCalendar"
          classNameOptions={undefined}
        />
      </TaskModalViewPartial>
      <PriorityRange disabled value={priority} testId="priorityRange" className={rangeClassName} />
      <TaskModalViewPartial label="Проект" testId="buildingName">
        {buildingName}
      </TaskModalViewPartial>
      {sectionName && (
        <TaskModalViewPartial label="Раздел" testId="sectionName">
          {sectionName}
        </TaskModalViewPartial>
      )}
      <LabelTemplate label="Исполнители">
        <div className={styles.bubbles}>
          <div className={styles.bubble}>
            <span data-testid="taskExecutor">{getShortFullName(executor)}</span>
          </div>
        </div>
      </LabelTemplate>
      {viewers.length > 0 && (
        <LabelTemplate label={`Контролеры: ${viewers.length}`} testId="taskViewers">
          <div className={styles.bubbles}>
            {viewers.map((viewer) => (
              <div className={styles.bubble} key={viewer.id}>
                <span data-testid={`taskViewers-${viewer.id}`}>{getShortFullName(viewer)}</span>
              </div>
            ))}
          </div>
        </LabelTemplate>
      )}
      <LabelTemplate label="Статус задачи">
        <Select
          selectClassName={styles.input}
          options={TASKS_STATUS_OPTIONS}
          value={status}
          testId="taskStatus"
          disabled
        />
      </LabelTemplate>
      <LabelTemplate label="Постановщик">
        <div className={styles.bubbles}>
          <div className={styles.bubble}>
            <span data-testid="taskCreator">{getShortFullName(creator)}</span>
          </div>
        </div>
      </LabelTemplate>
    </div>
  );
};

export default React.memo(MainTabArea);
