import { ISummaryAction } from "./actions";
import moment from "moment/moment";

import { SCROLL_EVENT_TARGETS } from "./constants";
import { ISummaryInitialState } from "./types";

const initialState: ISummaryInitialState = {
  year: +moment().year(),
  tree: [],
  isLoading: false,
  tableScrollPosition: {
    percent: 0,
    eventFrom: SCROLL_EVENT_TARGETS.TABLE,
  },
  tableViewWidthPercent: 0,
  tableHighlighting: {
    positive: false,
    negative: true,
  },
  allProjectsSummaryList: [],
  detailedProjectSummary: {},
  isLoadingByObjects: {},
  detailedProjectSummaryV2: {},
  totalsByProjectsV2: {},
};

export default (state = initialState, action: ISummaryAction): ISummaryInitialState => {
  const { type, payload } = action;
  switch (type) {
    case "constructing/finance/summary_SET_LOADING":
      return {
        ...state,
        isLoading: payload,
      };
    case "constructing/finance/summary_CLEAR_DATA":
      return {
        ...initialState,
      };

    case "constructing/finance/summary_SET_ALL_PROJECTS_SUMMARY":
      return {
        ...state,
        allProjectsSummaryList: payload,
      };
    case "constructing/finance/summary_SET_DETAILED_PROJECT_SUMMARY":
      return {
        ...state,
        detailedProjectSummary: { ...state.detailedProjectSummary, [payload.objectId]: payload.summary },
      };
    case "constructing/finance/summary_SET_SUMMARY_TREE":
      return {
        ...state,
        tree: payload,
      };
    case "constructing/finance/summary_SET_TABLE_VIEW_WIDTH_PERCENT":
      return {
        ...state,
        tableViewWidthPercent: payload,
      };
    case "constructing/finance/summary_SET_TABLE_HIGHLIGHTING": {
      return { ...state, tableHighlighting: { ...state.tableHighlighting, ...payload } };
    }
    case "constructing/finance/summary_SET_TABLE_SCROLL_POSITION": {
      return { ...state, tableScrollPosition: { ...state.tableScrollPosition, ...payload } };
    }
    case "constructing/finance/summary_SET_YEAR": {
      return { ...state, year: payload };
    }
    case "constructing/finance/summary_SET_IS_LOADING_BY_OBJECT":
      return {
        ...state,
        isLoadingByObjects: {
          ...state.isLoadingByObjects,
          [payload.objectId]: payload.status,
        },
      };
    case "constructing/finance/summary_SET_DEATILED_BY_PROJECT_V2":
      return {
        ...state,
        detailedProjectSummaryV2: {
          ...state.detailedProjectSummaryV2,
          [payload.projectId]: payload.data,
        },
      };
    case "constructing/finance/summary_setProjectsV2Totals":
      return {
        ...state,
        totalsByProjectsV2: {
          ...state.totalsByProjectsV2,
          [payload.projectId]: payload.data,
        },
      };
    default:
      return state;
  }
};
