import React, { FC, PropsWithChildren } from "react";

import DateCell from "../../../../../UI/atoms/DateCell/DateCell";
import StatusFormatter from "../../../../../UI/atoms/StatusFormatter";
import ConfirmationModal from "../../../../../UI/molecules/ConfirmationModal/ConfirmModal";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import { uploadSvg } from "../../../../Documents/MakeExport/exportSvg";

import CheckFillRound from "../../../../../../images/icons/CheckFillRound";

import styles from "./TableRow.module.scss";

enum StockEventTypeEnum {
  outgoing = "Расход",
  incoming = "Приход",
}

enum StockEventStatusEnum {
  confirmed = "Подтвержден",
  waiting_confirm = "Ожидает подтверждения",
}

const flowFormatter = (status: StockEventStatusEnum, type: StockEventTypeEnum) => {
  if (status === StockEventStatusEnum.confirmed) return { flow: "end" };
  if (type === StockEventTypeEnum.outgoing) return { flow: "out" };
  if (type === StockEventTypeEnum.incoming) return { flow: "in" };
};

const StatusCell = ({
  isResponsible,
  status,
  type,
  onApprove,
}: {
  isResponsible?: boolean;
  status: StockEventStatusEnum;
  type: StockEventTypeEnum;
  onApprove: () => void;
}) => {
  if (!isResponsible || status === "Подтвержден") {
    return StatusFormatter(status, flowFormatter(status, type), styles.status); //ts-ignore
  }

  return (
    <button onClick={onApprove} className={styles.approve}>
      <CheckFillRound width="18" colorFill="#00DEC5" />
      Подтвердить
    </button>
  );
};

interface IIncomeRowProps extends PropsWithChildren {
  countChildren: number | string;
  onFileClick: () => void;
  rowNumber: number | string;
  date: string;
  status: StockEventStatusEnum;
  type: StockEventTypeEnum;
  confirmedUser: string;
  sendUser: string;
  onApprove: () => void;
  isResponsible?: boolean;
  isExpanded: boolean;
}

const IncomeRow: FC<IIncomeRowProps> = ({
  countChildren,
  onFileClick,
  children,
  rowNumber,
  date,
  status,
  type,
  confirmedUser,
  sendUser,
  onApprove,
  isResponsible,
  isExpanded = true,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const openHandler = React.useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

  const [isConfirmationModal, setIsConfirmationModal] = React.useState(false);
  const confirmModalHandler = React.useCallback(() => setIsConfirmationModal((prev) => !prev), []);

  const fileHandler = React.useCallback(() => {
    confirmModalHandler();
    onFileClick();
  }, [confirmModalHandler, onFileClick]);

  const expandHandler = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const collapseHandler = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <TableReusableRow
        isExpandable={!!countChildren}
        onExpand={expandHandler}
        onCollapse={collapseHandler}
        isEarBlue={isOpen}
        earCount={Number(countChildren)}
        isEarCounter
        className={styles.incomeHeader}
        innerContent={children}
        isHoverable={false}
      >
        <TableReusableCell>{rowNumber}</TableReusableCell>
        <TableReusableCell isNoBreak>{sendUser}</TableReusableCell>
        <TableReusableCell isNoBreak>{confirmedUser}</TableReusableCell>
        <TableReusableCell>{type}</TableReusableCell>
        <TableReusableCell>
          <StatusCell onApprove={onApprove} status={status} type={type} isResponsible={isResponsible} />
        </TableReusableCell>
        <TableReusableCell isCentered>
          <DateCell date={date} className={undefined} />
        </TableReusableCell>
        <TableReusableCell isCentered>
          <div className={styles.action} onClick={confirmModalHandler}>
            {uploadSvg}
          </div>
        </TableReusableCell>
      </TableReusableRow>
      <ConfirmationModal isOpen={isConfirmationModal} onClose={confirmModalHandler} action={fileHandler}>
        Вы действительно хотите выгрузить файл?
      </ConfirmationModal>
    </>
  );
};

export default React.memo(IncomeRow);
