import cn from "classnames";
import React, { useState, useRef } from "react";

import Avatar from "../../../../atoms/Avatar/Avatar";
import Checkbox from "../../../../atoms/Checkbox/Checkbox";
import Actions from "./../../../../atoms/Actions/Actions";

import getShortFullName from "../../../../../../utils/formatters/getShortFullName";
import { stopEventPropagation } from "../../../../../../utils/helpers/stopEventPropagation";

import styles from "./TableRow.module.scss";
import InputBase, { VALUE_TYPES } from "../../../../atoms/InputBase";

function WorkerTableRow({
  number,
  worker,
  onCheck,
  onChangeCount,
  canBeChecked = true,
  onDelete,
  canDelete,
  isSelected,
}) {
  const ref = useRef(null);

  const [localValue, setLocalValue] = useState(worker.count);
  
  const changeHandler = (e) => {
    onChangeCount(e);
    setLocalValue(e.target.value);
  };

  const clickDrowningHandler = () => {
    ref.current?.click();
  };

  return (
    <div className={cn(styles.row, { [styles.selected]: isSelected })} onClick={clickDrowningHandler}>
      <div className={styles.checkbox}>
        {canBeChecked && (
          <Checkbox onCheck={(e) => onCheck(e, { ...worker, count: localValue })} checked={isSelected}>
            <div ref={ref} />
          </Checkbox>
        )}
      </div>
      <div className={styles.number}>{number}</div>
      <div className={styles.avatar}>
        <Avatar person={worker} img={worker?.avatar} />
      </div>
      <div className={styles.name} title={getShortFullName(worker)}>
        {worker?.name || getShortFullName(worker)}
      </div>
      <div onClick={stopEventPropagation}>
        <InputBase
          classNameInput={styles.input}
          onChange={changeHandler}
          placeholder="Кол-во"
          value={localValue}
          valueType={VALUE_TYPES.NUMBER}
        />
      </div>
      <div>{canDelete && <Actions canRemove={canDelete} onRemove={onDelete} className={styles.delete} />}</div>
    </div>
  );
}

export default WorkerTableRow;
