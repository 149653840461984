import React from "react";
import { useDispatch } from "react-redux";
import { deleteOneTask } from "redux/modules/purchaser/Tasks/thunks";
import { deleteOneTaskParams } from "redux/modules/purchaser/Tasks/types";
import { TASK_LISTS_TYPES } from "../constants";

interface deleteTaskInListProps {
    variant: "inLists",
    list_id: number
}

interface deleteTaskInAllBuildingsProps {
    variant: "byAllBuildings",
    type: TASK_LISTS_TYPES,
}

interface deleteTaskInCertainBuildingProps {
    variant: "byCertainBuilding",
    type: TASK_LISTS_TYPES,
    building_id: number
}

type unitedProps = | deleteTaskInListProps | deleteTaskInAllBuildingsProps | deleteTaskInCertainBuildingProps

export const useDeleteTask = (props: unitedProps) => {
    const dispatch = useDispatch()

    const deleteTaskHandler = React.useCallback((id: number) => {
        dispatch(deleteOneTask({ id, ...props }))
    }, [props])

    return { deleteTaskHandler }
}