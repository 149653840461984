import { memoize } from "lodash";
import React, { FC, useMemo } from "react";

import HiddenSubsection from "./components/HiddenSubsection/HiddenSubsection";
import HideBlock from "components/UI/atoms/HideBlock";

import ISection, { IExpenditureTree, ISectionTree, ISubsectionTree } from "types/interfaces/Section";

import { stringifyArgs } from "utils/helpers/stringifyArgs";

import styles from "./SectionsWithCheckedExpenditures.module.scss";

export interface IEstimateTarget {
  sectionId: ISectionTree["id"] | undefined;
  subsectionId: ISubsectionTree["id"] | undefined;
  expenditureId: IExpenditureTree["id"] | undefined;
}

export interface ISectionsWithCheckedExpendituresProps {
  sections: ISectionTree[];
  opened: boolean;
  selectedEstimateTargets: IEstimateTarget[];
  onCheckExpenditure: (target: IEstimateTarget, isChecked: boolean) => void;
  isDisabled?: boolean;
}

const AddRelationModalSections: FC<ISectionsWithCheckedExpendituresProps> = ({
  sections,
  opened,
  selectedEstimateTargets,
  onCheckExpenditure,
}) => {
  const checkedExpenditures = useMemo(() => (
    selectedEstimateTargets.length
      ? Object.fromEntries(selectedEstimateTargets.map(t => [t.expenditureId, true]))
      : {}
    ),
    [selectedEstimateTargets]
  );

  const partialCheckExpenditure =
    (sectionId: ISection["id"]) => (estimateTarget: Omit<IEstimateTarget, "sectionId">, isChecked: boolean) => {
      onCheckExpenditure(
        {
          ...estimateTarget,
          sectionId,
        },
        isChecked
      );
    };

  const memoizedPartialCheckExpenditure = useMemo(
    () => memoize(partialCheckExpenditure, stringifyArgs),
    [onCheckExpenditure]
  );

  const selectedSectionIds = selectedEstimateTargets.map(t => t.sectionId);

  return (
    <div>
      {sections.map((section) => (
        <HideBlock
          titleClassName={styles.sectionTitleClassName}
          title={section.name}
          key={section.id}
          isHiddenDefault={!((selectedSectionIds.includes(section?.id)) || opened)}
        >
          <div className={styles.subSection}>
            {Array.isArray(section.subsections) &&
              section.subsections.map((subsection) => (
                <HiddenSubsection
                  subsection={subsection}
                  opened={opened}
                  expenditures={subsection.expenditures}
                  expendituresAreLoading={!subsection.expenditures}
                  selectedEstimateTargets={selectedEstimateTargets}
                  checkedExpenditures={checkedExpenditures}
                  onCheckExpenditure={memoizedPartialCheckExpenditure(section.id)}
                  key={subsection.id}
                />
              ))}
          </div>
        </HideBlock>
      ))}
    </div>
  );
};

export default React.memo(AddRelationModalSections);
