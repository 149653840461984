import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setCurrentUser } from "redux/modules/common/auth";

import { Spinner } from "components/UI/Spinner/Spinner";
import TabBarNotLinks, { ITab } from "components/UI/atoms/TabBar/TabBarNotLinks";
import Paper from "components/UI/templates/Paper/Paper";
import MainTabArea from "components/pages/SimplifiedTask/components/MainTabArea/MainTabArea";
import { useGetTask } from "components/pages/Tasks/hooks/useGetTask";

import styles from "./SimplifiedTask.module.scss";

export interface ISimplifiedTaskProps {
  taskId: number | string;
}

export const MAIN_TAB_ID = "main";
export const ADDITIONAL_TAB_ID = "add";

export const TABS = [{ text: "Основные сведения", className: styles.tab, id: MAIN_TAB_ID }];

const SimplifiedTask: FC<ISimplifiedTaskProps> = ({ taskId }) => {
  const dispatch = useDispatch();

  const { certainTask, isCertainTaskLoading } = useGetTask(+taskId, true);

  const [activeTabId, setActiveTabId] = useState<ITab["id"]>(MAIN_TAB_ID);

  const onClickByTab = useCallback((tabId: ITab["id"]) => setActiveTabId(tabId), []);

  useEffect(() => {
    dispatch(setCurrentUser());
  }, []);

  if (isCertainTaskLoading) {
    return (
      <div data-testid="spinner">
        <Spinner />
      </div>
    );
  }

  if (!certainTask) return null;

  return (
    <div className={styles.container}>
      <Paper>
        <div className={styles.content} data-testid="content">
          <header className={styles.header}>
            <h1 className={styles.title} data-testid="taskTitle">
              {certainTask.name}
            </h1>
            <TabBarNotLinks
              className={styles.tabBar}
              tabs={TABS}
              activeId={activeTabId}
              onClick={onClickByTab}
              isTested
            />
          </header>
          {activeTabId === MAIN_TAB_ID && (
            <MainTabArea
              deadline={certainTask.deadline}
              priority={certainTask.priority}
              buildingName={certainTask.building_obj.name}
              sectionName={certainTask.section_obj ? certainTask.section_obj.name : null}
              executor={certainTask.executor_user}
              viewers={certainTask.viewers_data}
              status={certainTask.status}
              creator={certainTask.creator_user}
              description={certainTask.description}
              rangeClassName={styles.rangeContainer}
            />
          )}
        </div>
      </Paper>
    </div>
  );
};

export default React.memo(SimplifiedTask);
