import React, { useCallback, useState } from "react";
import cn from "classnames";

import AddEmployeeSlide from "../../atoms/AddEmployeeSlide";
import { TYPES } from "../Approvers";

import EmployeesElem from "components/UI/molecules/EmployeesElem";

import CircleAddIcon from "images/icons/CircleAddIcon";

import styles from "./index.module.scss";

const Viewers = ({
  type,
  viewers,
  handleAddViewers,
  handleDeleteViewer,
  employees,
  canAddViewers,
  className,
  canDeleteViewers,
}) => {
  const [isEmployeesModalShown, setIsEmployeesModalShown] = useState(false);
  const hideEmployeesModal = useCallback(() => setIsEmployeesModalShown(false), []);
  const openEmployeesModal = useCallback(() => setIsEmployeesModalShown(true), []);

  const hideModalAndHandleAddViewers = useCallback(
    (...args) => {
      hideEmployeesModal();
      handleAddViewers(...args);
    },
    [hideEmployeesModal, handleAddViewers]
  );

  return (
    <div className={cn(styles.viewers, className)}>
      <header className={styles.header}>
        <h2 className={styles.headerText}>
          {type === TYPES.order && "Просмотр заказа:"}
          {type === TYPES.payment && "Просмотр оплаты:"}
          {type === TYPES.requisition && "Просмотр заявки:"}
        </h2>
        {canAddViewers && (
          <button onClick={openEmployeesModal}>
            <CircleAddIcon />
          </button>
        )}
      </header>
      <div className={styles.elements}>
        {viewers.map((element, index) => (
          <EmployeesElem
            employees={employees}
            employee={element.employee}
            isApproved={element.is_approved}
            approvedAt={element.approved_at}
            deleteEmployee={handleDeleteViewer(element.id)}
            index={index}
            isShownRole={false}
            isShownApproveDate={element.is_approved}
            editPermission={canDeleteViewers}
            key={element.id}
          />
        ))}
      </div>
      {canAddViewers && (
        <AddEmployeeSlide
          employees={employees}
          returnOneData={false}
          isOpen={isEmployeesModalShown}
          addEmployees={hideModalAndHandleAddViewers}
          closeAddPEmployeeBlock={hideEmployeesModal}
        />
      )}
    </div>
  );
};

export default React.memo(Viewers);
