import {combineReducers} from 'redux'

import contractors from './contractors'
import list from './list'
import item from './item'

export const moduleName = 'contractors'

export default combineReducers({
  index: contractors,
  list,
  item
})
