import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { productsDataSelector } from "../../../../../../../redux/modules/common/building/requisition/reducer";

import ButtonBase from "../../../../../../UI/atoms/ButtonBase";
import SliderModal from "../../../../../../UI/atoms/SliderModal";
import TableReusableRow from "../../../../../../UI/atoms/TableReusable/TableReusableRow";
import ProductsTable from "../../../../../../UI/molecules/ProductsTable/ProductsTable";
import OrdersComparison from "../../../../../../UI/organism/OrdersComparison/OrdersComparison";

import useArrayItemsChecker from "../../../../../../../hooks/useArrayItemsChecker";
import { useUrlModule } from "../../../../../../../utils/hooks/useUrlModule";
import { useUrlModuleWithObjectId } from "../../../../../../../utils/hooks/useUrlModuleWithObjectId";

import styles from "../../index.module.scss";

const Comparison = ({ orders }) => {
  const urlAdapter = useUrlModule();
  const { objectId } = useParams();

  const baseModuleRoute = useUrlModuleWithObjectId(objectId);

  const products = useSelector(productsDataSelector);

  const [isOrdersComparisonOpen, setIsOrdersComparisonOpen] = useState(false);
  const closeOrdersComparison = useCallback(() => setIsOrdersComparisonOpen(false), []);
  const openOrdersComparison = useCallback(() => setIsOrdersComparisonOpen(true), []);

  const requestsByExpenditures = useMemo(() => {
    if (!orders) return {};
    const result = {};

    orders.forEach((order) => {
      order.body.requests.forEach((request) => {
        const expenditureId = request.product_building.estimate_expenditure?.id;
        if (!result[expenditureId]) result[expenditureId] = [];
        result[expenditureId].push(request);
      });
    });
    return result;
  }, [orders]);

  const urlPrefix = useMemo(() => {
    if (urlAdapter !== "objects") return urlAdapter;
    return `${urlAdapter}/${objectId}`;
  }, [objectId, urlAdapter]);

  const productsFromOrders = useMemo(
    () =>
      Array.from(
        new Set(
          products
            .map((product) => ({ ...product, checkerKey: product.product_building.estimate_expenditure?.id }))
            .filter((product) => requestsByExpenditures[product.checkerKey])
        )
      ),
    [products, requestsByExpenditures]
  );

  const toComparisonProductsChecker = useArrayItemsChecker(productsFromOrders, "id");

  const toComparisonOrders = useMemo(() => {
    return orders
      .map((order) => ({
        ...order,
        body: {
          ...order.body,
          requests: order.body.requests.filter((request) => {
            const requestExpenditureId = request.product_building.estimate_expenditure?.id;
            return toComparisonProductsChecker.items[
              productsFromOrders.find((x) => x.checkerKey === requestExpenditureId)?.id
            ];
          }),
        },
      }))
      .filter((order) => order.body.requests.length !== 0);
  }, [toComparisonProductsChecker.items, orders]);

  const canCompare = toComparisonOrders.length !== 0;
  const hasProductsInTable = productsFromOrders.length !== 0;

  return (
    <div className={styles.comparison}>
      <TableReusableRow
        isHoverable={false}
        isExpandable={hasProductsInTable}
        isEarBlue
        isDefaultExpanded
        innerContent={
          hasProductsInTable && (
            <ProductsTable
              products={productsFromOrders}
              productsChecker={toComparisonProductsChecker}
              baseModuleRoute={baseModuleRoute}
              className={styles.productTable}
            />
          )
        }
      >
        <div className={styles.comparisonRow}>
          <span>Товаров: {productsFromOrders.length}</span>
          <span>Выбрано: {toComparisonProductsChecker.checkedCount}</span>
          <ButtonBase primary onClick={openOrdersComparison} disabled={!canCompare}>
            Сравнение
          </ButtonBase>
        </div>
      </TableReusableRow>

      {isOrdersComparisonOpen && (
        <SliderModal
          className={styles.ordersComparisonModal}
          childrenContainerClassName={styles.scrollX}
          containerClassName={styles.noScrollY}
          isOpen
          closeHandler={closeOrdersComparison}
        >
          <OrdersComparison orders={toComparisonOrders} baseModuleRoute={`/${urlPrefix}`} />
        </SliderModal>
      )}
    </div>
  );
};

export default React.memo(Comparison);
