import { IOrdersListActions } from "./actions";

import { IOrdersListState } from "./types";

export const initialOrdersListState: IOrdersListState = {
  orders: {},
};

export default (state: IOrdersListState = initialOrdersListState, action: IOrdersListActions): IOrdersListState => {
  const { type, payload } = action;
  switch (type) {
    case "ordersList/SET_IS_LOADING":
      return {
        ...state,
        orders: {
          ...state.orders,
          [payload.key]: {
            ...(state.orders[payload.key] || {}),
            isLoading: payload.status,
          },
        },
      };
    case "ordersList/SET_ORDERS":
      return {
        ...state,
        orders: {
          ...state.orders,
          [payload.key]: {
            ...(state.orders[payload.key] || {}),
            isLoading: false,
            orders: payload.orders,
            filters: payload.filters,
            count: payload.count,
          },
        },
      };
    case "ordersList/SET_MORE_ORDERS":
      return {
        ...state,
        orders: {
          ...state.orders,
          [payload.key]: {
            ...(state.orders[payload.key] || {}),
            isLoading: false,
            orders: [...(state.orders[payload.key].orders || []), ...payload.orders],
            filters: payload.filters,
            count: payload.count,
          },
        },
      };
    default:
      return state;
  }
};
