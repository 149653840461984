import { IActions } from "redux/store";

import {
  IDetailRemarkInfo,
  IExpendituresInJournalFulfillment, //@ts-ignore
  IGroupTicketActingInfoData,
  IJournalFulFillmentGroup,
  IRemarkBySection,
  IRemarksList,
  ISectionInJournalExec,
  ISectionInJournalFulfillment,
  ISectionWithRemarks,
  ITicketActingExpenditure,
  ITicketActingInfo,
} from "./types";

export const journalExecutionActions = {
  setSections: (sections: ISectionInJournalExec[], key: string) =>
    ({
      type: "journalExecution/SET_SECTIONS",
      payload: { sections, key },
    } as const),
  setSectionsWithRemarks: (sections: ISectionWithRemarks[], key: string) =>
    ({
      type: "journalExecution/SET_SECTIONS_WITH_REMARKS",
      payload: { sections, key },
    } as const),
  setSectionsLoading: (status: boolean, key: string) =>
    ({
      type: "journalExecution/SET_SECTIONS_LOADING",
      payload: { status, key },
    } as const),
  setSectionsWithRemarksLoading: (status: boolean, key: string) =>
    ({
      type: "journalExecution/SET_SECTIONS_WITH_REMARKS_LOADING",
      payload: { status, key },
    } as const),
  setTickets: (tickets: any[], key: string) =>
    ({
      type: "journalExecution/SET_TICKETS",
      payload: { tickets, key },
    } as const),
  setGroupTickets: (tickets: any[], key: string) =>
    ({
      type: "journalExecution/SET_GROUP_TICKETS",
      payload: { tickets, key },
    } as const),
  setRemarksBySection: (remarks: IRemarkBySection[], key: string) =>
    ({
      type: "journalExecution/SET_REMARKS_BY_SECTION",
      payload: { remarks, key },
    } as const),
  setTicketInSection: (ticket: any, key: string) =>
    ({
      type: "journalExecution/SET_TICKET_IN_SECTION",
      payload: { ticket, key },
    } as const),
  setTicketsLoading: (status: boolean, key: string) =>
    ({
      type: "journalExecution/SET_TICKETS_LOADING",
      payload: { status, key },
    } as const),
  setRemarksBySectionLoading: (status: boolean, key: string) =>
    ({
      type: "journalExecution/SET_REMARKS_BY_SECTION_LOADING",
      payload: { status, key },
    } as const),
  setIsCreateRemarkSuccess: (isSuccess: boolean) =>
    ({
      type: "journalExecution/SET_IS_CREATE_REMARK_SUCCESS",
      payload: isSuccess,
    } as const),
  setIsCreateRemarkLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_CREATE_REMARK_LOADING",
      payload: isLoading,
    } as const),
  setIsEditRemarkSuccess: (isSuccess: boolean) =>
    ({
      type: "journalExecution/SET_IS_EDIT_REMARK_SUCCESS",
      payload: isSuccess,
    } as const),
  setIsEditRemarkLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_EDIT_REMARK_LOADING",
      payload: isLoading,
    } as const),
  setRemarksListData: (data: IRemarksList[]) =>
    ({
      type: "journalExecution/SET_REMARKS_LIST_DATA",
      payload: data,
    } as const),
  setIsRemarksListLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_REMARKS_LIST_LOADING",
      payload: isLoading,
    } as const),
  setRemarkDetailInfoData: (data: IDetailRemarkInfo | null) =>
    ({
      type: "journalExecution/SET_DETAIL_REMARK_INFO_DATA",
      payload: data,
    } as const),
  setIsRemarkDetailInfoLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_DETAIL_REMARK_INFO_LOADING",
      payload: isLoading,
    } as const),
  setIsDeleteRemarkLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_DELETE_REMARK_LOADING",
      payload: isLoading,
    } as const),
  setIsDeleteRemarkSuccess: (isSuccess: boolean) =>
    ({
      type: "journalExecution/SET_IS_DELETE_REMARK_SUCCESS",
      payload: isSuccess,
    } as const),
  setIsResolveOrReturnRemarkSuccess: (isSuccess: boolean) =>
    ({
      type: "journalExecution/SET_IS_RESOLVE_OR_RETURN_REMARK_SUCCESS",
      payload: isSuccess,
    } as const),
  setIsResolveOrReturnRemarkLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_RESOLVE_OR_RETURN_REMARK_LOADING",
      payload: isLoading,
    } as const),
  setIsAcceptRemarkLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_ACCEPT_REMARK_LOADING",
      payload: isLoading,
    } as const),
  setGroupTicketActingInfoData: (data: IGroupTicketActingInfoData) =>
    ({
      type: "journalExecution/SET_GROUP_TICKET_ACTING_INFO_DATA",
      payload: data,
    } as const),
  setTicketActingInfoData: (data: ITicketActingInfo | null) =>
    ({
      type: "journalExecution/SET_TICKET_ACTING_INFO_DATA",
      payload: data,
    } as const),
  setTicketActingIsApproving: (isApproving: boolean) =>
    ({
      type: "journalExecution/SET_TICKET_ACTING_IS_APPROVING",
      payload: isApproving,
    } as const),
  updateOneTicketActingInfoData: (id: number, count: string, expenditures?: ITicketActingExpenditure[]) =>
    ({
      type: "journalExecution/UPDATE_ONE_TICKET_ACTING_INFO_DATA",
      payload: { id, count, expenditures },
    } as const),
  updateGroupTicketActingInfoData: (id: number, count: string, expenditures?: ITicketActingExpenditure[]) =>
    ({
      type: "journalExecution/UPDATE_GROUP_TICKET_ACTING_INFO_DATA",
      payload: { id, count, expenditures },
    } as const),
  removeOneTicketActingInfoData: (id: number) =>
    ({
      type: "journalExecution/REMOVE_ONE_TICKET_ACTING_INFO_DATA",
      payload: id,
    } as const),
  setIsTicketActingInfoLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_IS_TICKET_ACTING_INFO_LOADING",
      payload: isLoading,
    } as const),
  setGroupTicketActingInfoLoading: (isLoading: boolean) =>
    ({
      type: "journalExecution/SET_GROUP_TICKET_ACTING_INFO_LOADING",
      payload: isLoading,
    } as const),
  changeReportInvalidateKey: () =>
    ({
      type: "journalExecution/CHANGE_REPORT_INVALIDATE_KEY",
      payload: true,
    } as const),
  changeInvalidateKey: () =>
    ({
      type: "journalExecution/CHANGE_INVALIDATE_KEY",
      payload: true,
    } as const),
  setSectionsV2: (sections: ISectionInJournalFulfillment[], buildingId: string) =>
    ({
      type: "journalFulfillment/SET_SECTIONS",
      payload: { sections, buildingId },
    } as const),
  setExpendituresV2: (expenditures: IExpendituresInJournalFulfillment, sectionId: string) =>
    ({
      type: "journalFulfillment/SET_EXPENDITURES",
      payload: { expenditures, sectionId },
    } as const),
  setGroup: (group: IJournalFulFillmentGroup, key: string) =>
    ({
      type: "journalFulfillment/SET_GROUP",
      payload: {
        group,
        key,
      },
    } as const),
  setContentOfExpenditure: (content: Omit<IJournalFulFillmentGroup, "expenditure">, key: string) =>
    ({
      type: "journalFulfillment/SET_CONTENT_OF_EXPENDITURE",
      payload: {
        content,
        key,
      },
    } as const),
  changeMaterialsInvalidateKey: () =>
    ({
      type: "journalExecution/CHANGE_MATERIALS_INVALIDATE_KEY",
      payload: true,
    } as const),
  setSectionsV2Loading: (key: string, status: boolean) =>
    ({
      type: "journalExecution/SET_SECTIONS_V2_LOADING",
      payload: { key, status },
    } as const),
  setExpendituresV2Loading: (key: string, status: boolean) =>
    ({
      type: "journalExecution/SET_EXPENDITURES_V2_LOADING",
      payload: { key, status },
    } as const),
};

export type IJournalExecutionAction = IActions<typeof journalExecutionActions>;
