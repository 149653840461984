import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { summaryActions } from "../../../../../redux/modules/common/constructing/finance/summary/actions";
import { SCROLL_EVENT_TARGETS } from "../../../../../redux/modules/common/constructing/finance/summary/constants";
import {
  summaryYearSelector,
  tableScrollPositionSelector,
} from "../../../../../redux/modules/common/constructing/finance/summary/selectors";

import { scrollTable } from "./TableSide/Table/utils";

export const useSummaryScroll = (isNotTable?: boolean) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const year = useSelector(summaryYearSelector);
  const scrollPosition = useSelector(tableScrollPositionSelector);

  useEffect(() => {
    if (scrollPosition.eventFrom !== SCROLL_EVENT_TARGETS.TABLE) {
      scrollTable(tableRef.current, scrollPosition.percent, isNotTable);
    }
    if (isNotTable) {
      scrollTable(tableRef.current, scrollPosition.percent, isNotTable);
    }
  }, [scrollPosition, isNotTable]);

  useEffect(() => {
    dispatch(summaryActions.setTableScrollPosition({ scrollPosition: 0 }));
  }, []);

  return {
    tableRef,
    year,
  };
};
