import axios from "axios";
import { Dispatch } from "redux";

import { RootState } from "redux/rootReducer";

import { requisitionsListActions } from "./actions";

import { checkIsNeedSetLoading, parseStatusParam } from "./utils";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const getRequisitionsList =
  (statuses: string[], filters: any) => (dispatch: Dispatch, getState: () => RootState) => {
    let url = "/requisition/";
    if (statuses) url += parseStatusParam(statuses);
    const key = parseStatusParam(statuses);
    const keyWithBuildingId = key + "_" + filters.building_id;
    if (checkIsNeedSetLoading(getState(), filters, keyWithBuildingId)) {
      dispatch(requisitionsListActions.setIsLoading(true, keyWithBuildingId));
    }
    return axios
      .get(url, { params: filters })
      .then((response) =>
        dispatch(
          requisitionsListActions.setRequisitions(
            response.data.results,
            keyWithBuildingId,
            filters,
            response.data.count
          )
        )
      )
      .catch(errorCatcher)
      .finally(() => {
        dispatch(requisitionsListActions.setIsLoading(false, keyWithBuildingId));
      });
  };

export const getMoreRequisitionsInList =
  (statuses: string[], filters: any) => (dispatch: Dispatch, getState: () => RootState) => {
    let url = "/requisition/";
    if (statuses) url += parseStatusParam(statuses);
    const key = parseStatusParam(statuses);
    const keyWithBuildingId = key + "_" + filters.building_id;
    axios
      .get(url, { params: filters })
      .then((response) =>
        dispatch(
          requisitionsListActions.setMoreRequisitions(
            response.data.results,
            keyWithBuildingId,
            filters,
            response.data.count
          )
        )
      )
      .catch(errorCatcher);
  };
