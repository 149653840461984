import React from 'react'
import styles from "./TableRow.module.scss"
import AmountAndCount from '../AmountAndCount/AmountAndCount'
import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow'

function TableRow({ 
    countChildren, 
    children, 
    rowNumber, 
    name, 
    sectionString, 
    measureProduct, 
    measureMoney, 
    count1, 
    amount1, 
    count2, 
    amount2, 
    count3, 
    amount3, 
    onOpen 
}) {
    const [isOpen, setIsOpen] = React.useState(false)

    const expandHandler = React.useCallback(() => {
        setIsOpen(true)
        onOpen?.()
    }, [])

    const collapseHandler = React.useCallback(() => {
        setIsOpen(false)
    }, [])
    
    return (
        <TableReusableRow
            isExpandable={!!countChildren}
            onExpand={expandHandler}
            onCollapse={collapseHandler}
            isEarBlue={isOpen}
            earCount={countChildren}
            isEarCounter
            className={styles.header}
            innerContent={children}
            isHoverable={false}
        >
            <TableReusableCell>
                {rowNumber}
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {name}
            </TableReusableCell>
            <TableReusableCell isNoBreak className={styles.doubleLines}>
                {sectionString}
            </TableReusableCell>
            <TableReusableCell isCentered>
                {measureProduct}
            </TableReusableCell>
            <TableReusableCell isCentered className={styles.amountAndCount}>
                <AmountAndCount amount={amount1} count={count1} measure={measureMoney} />
            </TableReusableCell>
            <TableReusableCell isCentered className={styles.amountAndCount}>
                <AmountAndCount amount={amount2} count={count2} measure={measureMoney} />
            </TableReusableCell>
            <TableReusableCell isCentered className={styles.amountAndCount}>
                <AmountAndCount amount={amount3} count={count3} measure={measureMoney} />
            </TableReusableCell>
        </TableReusableRow>
    )
}

export default React.memo(TableRow)