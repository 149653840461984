import React from "react";

import { IEstimateInActing } from "redux/modules/common/building/journal/acting/types";

import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import JournalRowIndicator from "../common/JournalRowIndicator/JournalRowIndicator";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalActingLsrLevel.module.scss";

interface IProps {
  onClick: (lsrId: number) => void;
  estimate: IEstimateInActing;
}

const JournalActingLsrRow: React.FC<IProps> = ({ onClick, estimate }) => {
  const clickHandler = React.useCallback(() => {
    onClick(estimate.id);
  }, [estimate.id, onClick]);

  return (
    <TableReusableRow onClick={clickHandler} className={styles.lsrRow}>
      <TableReusableCell>
        <span className={styles.lsrName}>{estimate.name}</span>
      </TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator title="Бюджет" value={splitThousands(estimate.budget_amount)} />
      </TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator title="Сдано" value={splitThousands(estimate.completed_amount)} />
      </TableReusableCell>
      <TableReusableCell>
        <JournalRowIndicator title="К сдаче" value={splitThousands(estimate.todo_amount)} />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(JournalActingLsrRow);
