import React, { useCallback, useState } from "react";

import Modal from "../../../../UI/atoms/Modal";
import ButtonBase from "components/UI/atoms/ButtonBase";
import Textarea from "components/UI/atoms/Textarea";

import styles from "./index.module.scss";

const SendToPaymentModal = ({ onClose, isOpen, sendToPayment, paymentTerms }) => {
  const [message, setMessage] = useState("");

  const onChangeMessage = useCallback((e) => setMessage(e.target.value), []);
  const handleSendToPayment = useCallback(() => sendToPayment(message), [sendToPayment, message]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Оплатить" className={styles.sendToPaymentModal}>
      <div className={styles.body}>
        <div className={styles.paymentTermsTable}>
          <header className={styles.header}>
            <div className={styles.nameCell}>Условия оплаты</div>
            <div className={styles.percentCell}>%</div>
            <div className={styles.daysCell}>дни</div>
          </header>
          <div>
            {paymentTerms.map((term) => (
              <div className={styles.row} key={term.id}>
                <div className={styles.nameCell}>{term?.payment_type_name}</div>
                <div className={styles.percentCell}>{term?.percent}</div>
                <div className={styles.daysCell}>{term?.delay_days}</div>
              </div>
            ))}
          </div>
        </div>
        <p>Комментарий</p>
        <Textarea value={message} onChange={onChangeMessage} className={styles.textarea} />
      </div>
      <footer className={styles.footer}>
        <ButtonBase className={styles.cancelButton} onClick={onClose} secondary medium>
          Отменить
        </ButtonBase>
        <ButtonBase medium onClick={handleSendToPayment}>
          Отправить
        </ButtonBase>
      </footer>
    </Modal>
  );
};

export default React.memo(SendToPaymentModal);
