import { useSelector } from "react-redux";

import { replacementsSelector } from "redux/modules/common/building/sections/sections";

import AddButton from "components/UI/atoms/AddButton/AddButton";
import ModalContainer from "components/UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import ReplacementsFileForm from "./ReplacementsFileForm";

import styles from "./ReplacedExpenditureFilesModal.module.scss";
import { ReplacementFileTypesEnum } from "../../types/ReplacementFileTypesEnum";
import { FC, useEffect, useState } from "react";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  replacementId: number;
  buildingId: number;
}

const ReplacedExpenditureFilesModal: FC<IProps> = (props) => {
  const { isOpen, onClose, name, replacementId, buildingId } = props;

  const [addingFilesCount, setAddingFilesCount] = useState(0);
  const savedReplacementData = useSelector(replacementsSelector)[replacementId];
  const attachedFilesTypes: ReplacementFileTypesEnum[] = [];

  if (savedReplacementData?.drawing_file) {
    attachedFilesTypes.push(ReplacementFileTypesEnum.DRAWING);
  }

  if (savedReplacementData?.covering_letter_file) {
    attachedFilesTypes.push(ReplacementFileTypesEnum.COVER_LETTER);
  }

  useEffect(() => {
    setAddingFilesCount(0);
  }, [savedReplacementData]);

  const isAddingDisabled =
    addingFilesCount > 1 || !!(savedReplacementData?.drawing_file && savedReplacementData?.covering_letter_file);

  return (
    <ModalContainer
      name="Обязательные документы"
      isOpen={isOpen}
      onClose={onClose}
      customHeadline={
        <>
          <div className={styles.subtext}>к позиции:</div>
          <div className={styles.subtitle}>{name}</div>
        </>
      }
    >
      <AddButton
        onClick={() => setAddingFilesCount((prev) => prev + 1)}
        text="Добавить"
        textPosition="left"
        isDisabled={isAddingDisabled}
      />
      {!!savedReplacementData?.drawing_file && (
        <ReplacementsFileForm
          replacementId={replacementId}
          buildingId={buildingId}
          fileType={ReplacementFileTypesEnum.DRAWING}
          fileName={savedReplacementData?.drawing_file_name!}
          filePath={savedReplacementData?.drawing_file}
        />
      )}
      {!!savedReplacementData?.covering_letter_file && (
        <ReplacementsFileForm
          replacementId={replacementId}
          buildingId={buildingId}
          fileType={ReplacementFileTypesEnum.COVER_LETTER}
          fileName={savedReplacementData?.covering_letter_file_name!}
          filePath={savedReplacementData?.covering_letter_file}
        />
      )}
      {((!!addingFilesCount) || (addingFilesCount === 2)) && (
        <ReplacementsFileForm
          attachedFilesTypes={attachedFilesTypes}
          replacementId={replacementId}
          buildingId={buildingId}
        />
      )}
    </ModalContainer>
  );
};

export default ReplacedExpenditureFilesModal;
