import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { transferredSelector } from "redux/modules/common/building/projects";
import { loadObjects } from "redux/modules/common/building/projects";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import TabBarNotLinks, { ITab } from "components/UI/atoms/TabBar/TabBarNotLinks";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";
import TemplateBase from "components/UI/templates/TemplateBase/TemplateBase";
import ObjectsRow from "components/pages/Constructing/Objects/components/ObjectsRow/ObjectsRow";
import { DEFAULT_TRANSFERRED_FILTERS } from "components/pages/Constructing/Objects/constants";

import { projectsV2CountsSelector, projectsV2IsLoadingSelector, projectsV2ListSelector } from "./model/selectors";
import { createProject, getProjectsList } from "./model/thunks";
import ProjectsTableHeader from "./ui/ProjectsTable/ProjectsTableHeader";
import ProjectsTableRow from "./ui/ProjectsTable/ProjectsTableRow";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import projectsIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./Projects.module.scss";

const tabs: ITab[] = [
  { id: "all", text: "Все проекты" },
  //{ id: "sent", text: "Выполненные" },
  { text: "Выполняются", id: "accepted" },
];

const Projects = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<Record<string, string>>({});
  const [tab, setTab] = useState("all");

  const key = generateStorageKey({ projectId: 0, ...filter } as any);

  const isLoading = useSelector(projectsV2IsLoadingSelector)[key];
  const projects = useSelector(projectsV2ListSelector)[key];
  const total = useSelector(projectsV2CountsSelector)[key];

  const onCreate = () => {
    history.push("/constructing/projects/create-project/new");
  };

  const onClickProject = (id: number) => {
    history.push(`/constructing/projects/objects-list/${id}`);
  };

  const onClickSettings = (id: number) => {
    history.push(`/constructing/projects/edit-project/${id}`);
  };

  useEffect(() => {
    dispatch(getProjectsList(filter));
  }, [filter]);

  // transferred
  const transferred = useSelector(transferredSelector);

  useEffect(() => {
    dispatch(loadObjects(null, DEFAULT_TRANSFERRED_FILTERS));
  }, []);
  // transferred

  return (
    <TemplateBase>
      <ListPageHeaderTemplate>
        <TabBarNotLinks tabs={tabs} activeId={tab} onClick={setTab} />
        <ButtonBase secondary onClick={onCreate}>
          Создать проект
        </ButtonBase>
      </ListPageHeaderTemplate>
      {tab === "all" && transferred?.results?.length > 0 && (
        <div className={styles.sharedBlock}>
          <p>
            Предложения от заказчика: <strong>{transferred.results.length}</strong>
          </p>
          {transferred.results.map((el: any) => (
            /* @ts-ignore */
            <ObjectsRow
              key={el.id}
              object={el}
              //onStatusChange={handleStatusChange}
              isTransferred
              /* viewBuildingPermission={permissions.viewBuilding}
              viewEditBuildingPermission={permissions.viewEditBuildings}
              viewHandlerPermission={permissions.viewHandler} */
              //isSharingPending={pendingSharedObjectsIds.includes(el.id)}
            />
          ))}
          {/* <ShowMoreButton
            handleAdd={handleTransferredAdd}
            showedCount={transferred?.results.length}
            allCount={transferred?.count}
            isExists={transferred?.count > transferred?.results.length}
            isLoading={isLoadingMoreObjects}
          /> */}
          {/* {confirmModal.open && (
            <AlertPopup
              onClose={clearConfirm}
              title={`${confirmModal.status === "accepted" ? "Принять" : "Отклонить"} проект "${confirmModal.name}"`}
            >
              <div className={styles.alert}>
                <p>
                  Вы действительно хотите{" "}
                  <strong>{confirmModal.status === "accepted" ? "принять" : "отклонить"}</strong> проект{" "}
                  <strong>{confirmModal.name}</strong>?
                </p>
              </div>
              <div className={styles.btns}>
                <button onClick={() => cancelChange(confirmModal.id)}>Отменить</button>
                <button
                  onClick={() => changeStatus(confirmModal.id, confirmModal.status)}
                  className={confirmModal.status === "accepted" ? styles.accept : styles.decline}
                >
                  {confirmModal.status === "accepted" ? "Принять" : "Отклонить"}
                </button>
              </div>
            </AlertPopup>
          )} */}
        </div>
      )}
      <ProjectsTableHeader onChangeFilter={(name, value) => setFilter((prev) => ({ ...prev, [name]: value }))} />
      {!isLoading && !projects?.length && (
        <EmptyPlaceholder text="Нет проектов" img={projectsIcon}>
          <ButtonBase primary onClick={onCreate}>
            Создать проект
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {isLoading && <Spinner />}
      {projects?.map((el) => (
        <ProjectsTableRow project={el} key={el.id} onClick={onClickProject} onSettingsClick={onClickSettings} />
      ))}
    </TemplateBase>
  );
};

export default Projects;
