import { IActions } from "redux/store";

import { IAosr, IAosrDetail, IAosrDoc } from "./types";
import { IExpenditure } from "types/interfaces/Expenditure";

export const aosrActions = {
  setAosr: (aosr: IAosr, expenditureId: number) =>
    ({
      type: "aosr/SET",
      payload: { aosr, expenditureId },
    } as const),
  setAosrDetail: (aosrDetail: IAosrDetail | null) =>
    ({
      type: "aosr/SET_DETAIL",
      payload: aosrDetail,
    } as const),
  setWorksBySection: (works: IExpenditure[], sectionId: number) =>
    ({
      type: "aosr/SET_WORKS",
      payload: { works, sectionId },
    } as const),
  invalidateKey: () =>
    ({
      type: "aosr/INVALIDATE_KEY",
      payload: true,
    } as const),
  setClosingPending: (status: boolean, aookId: number) =>
    ({
      type: "aosr/SET_CLOSING_PENDING",
      payload: { status, aookId },
    } as const),
  setCreatingPending: (status: boolean, aosrId: number) =>
    ({
      type: "aosr/SET_CREATING_PENDING",
      payload: { status, aosrId },
    } as const),
};

export type IAosrAction = IActions<typeof aosrActions>;
