import { Table as AntTable } from "antd";
import cn from "classnames";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { summaryActions } from "../../../../../../../redux/modules/common/constructing/finance/summary/actions";
import { SCROLL_EVENT_TARGETS } from "redux/modules/common/constructing/finance/summary/constants";
import {
  summaryYearSelector,
  tableScrollPositionSelector,
} from "redux/modules/common/constructing/finance/summary/selectors";
import { useTypedSelector } from "redux/typedUseSelector";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useFinanceMeasure } from "../../../../../../../features/financeMeasure";
import { useTableHover } from "../../../Audit/TableSide/Table/_internal/useTableHover";
import renderMoneyCell from "./_internal/MoneyCell";

import { MonthAndQuarterArray, MonthArray } from "constants/constant";
import { LOCALIZATION_CONFIG } from "constants/localization";

import { isFinanceFooter, isQuartalColumns } from "../../../_internal/utils";
import { getTableViewWidthPercent, scrollTable } from "./utils";

import styles from "./Table.module.scss";

const { Column, ColumnGroup } = AntTable;

const Table = (props) => {
  const objectId = useObjectId(3);
  const { projectId } = useParams();
  const { data, totalsData } = props;
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const scrollPosition = useSelector(tableScrollPositionSelector);
  const year = useSelector(summaryYearSelector);
  const extraData = props.extraData;
  const projectSummary = useTypedSelector((state) => state.constructing.finance.summary.totalsByProjectsV2);

  const { measure, measureInTitle } = useFinanceMeasure();

  useTableHover();

  useEffect(() => {
    const handleResize = () => {
      const tableViewWidthPercent = getTableViewWidthPercent(tableRef.current);
      dispatch(summaryActions.setTableViewWidthPercent(tableViewWidthPercent));
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition.eventFrom !== SCROLL_EVENT_TARGETS.TABLE) {
      scrollTable(tableRef.current, scrollPosition.percent);
    }
  }, [scrollPosition]);

  useEffect(() => {
    const tableScrollableElement = tableRef.current.querySelectorAll(".ant-table-content")[0];
    const tableScrollableElementTotal = tableRef.current.querySelectorAll(".ant-table-content")[1];
    const header = document.querySelector(".ant-table-content");
    const footer = document.querySelector(".tableFooter");
    header.style.scrollBehavior = "auto";
    tableScrollableElement.style.scrollBehavior = "auto";
    tableScrollableElement.scrollLeft = 0;
    if (!!footer) {
      footer.style.scrollBehavior = "auto";
      footer.scrollLeft = 0;
    }
    if (!!tableScrollableElementTotal) {
      tableScrollableElementTotal.style.scrollBehavior = "auto";
      tableScrollableElementTotal.scrollLeft = 0;
    }

    const debouncedHandler = debounce((e) => {
      dispatch(
        summaryActions.setTableScrollPosition({
          scrollPosition: tableScrollableElement.scrollLeft || tableScrollableElementTotal?.scrollLeft,
          containerWidth: tableScrollableElement.scrollWidth || tableScrollableElementTotal?.scrollWidth,
          eventFrom: SCROLL_EVENT_TARGETS.TABLE,
        })
      );
    }, 50);

    const handleTableScroll = (e) => {
      debouncedHandler();
      requestAnimationFrame(() => {
        header.scroll(e.target.scrollLeft, 0);
        tableScrollableElementTotal?.scroll(e.target.scrollLeft, 0);
        footer?.scroll(e.target.scrollLeft, 0);
      });
    };
    const handleTableTotalScroll = (e) => {
      debouncedHandler();
      requestAnimationFrame(() => {
        header.scroll(e.target.scrollLeft, 0);
        tableScrollableElement?.scroll(e.target.scrollLeft, 0);
        footer?.scroll(e.target.scrollLeft, 0);
      });
    };

    tableScrollableElement?.addEventListener("scroll", handleTableScroll);
    tableScrollableElementTotal?.addEventListener("scroll", handleTableTotalScroll);

    return () => {
      tableScrollableElement?.removeEventListener("scroll", handleTableScroll);
      tableScrollableElementTotal?.removeEventListener("scroll", handleTableTotalScroll);
    };
  }, [objectId, projectId]);

  const totalsFirstRow = useMemo(() => {
    if (+projectId && !+objectId) {
      return [{ ...(projectSummary[projectId] ?? {}), key: "total" }];
    }

    if (!totalsData?.data) return [{}];
    const totalKeys = Object.keys(totalsData?.q_data || {});

    const initialData = totalKeys.reduce(
      (acc, cur) => {
        return {
          ...acc,
          [`diff_${cur}`]: totalsData?.q_data?.[cur]?.diff,
          [`plan_${cur}`]: totalsData?.q_data?.[cur]?.plan,
          [`fact_${cur}`]: totalsData?.q_data?.[cur]?.fact,
        };
      },
      { key: "total" }
    );

    return [
      totalsData?.data?.reduce((acc, cur) => {
        return {
          ...acc,
          [`diff_${cur.month - 1}`]: cur.diff,
          [`plan_${cur.month - 1}`]: cur.plan,
          [`fact_${cur.month - 1}`]: cur.fact,
        };
      }, initialData),
    ];
  }, [totalsData, projectId, objectId, projectSummary]);

  return (
    <div ref={tableRef} className={cn(styles.tableWrapper, { [styles.hideScroll]: isFinanceFooter(objectId) })}>
      <AntTable dataSource={data} size="small" scroll={{ x: 1300 }} pagination={false}>
        {isQuartalColumns(objectId, projectId)
          ? MonthAndQuarterArray.map((month) => (
              <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                <Column
                  title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`plan_${month.id}`}
                  key={`plan_${month.id}`}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure, false, data < 0 ? styles.negative : null)}
                  ellipsis
                />
                <Column
                  title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`fact_${month.id}`}
                  key={`fact_${month.id}`}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure)}
                  ellipsis
                />
                <Column
                  title={`Разница ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`diff_${month.id}`}
                  key={`diff_${month.id}`}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure, true)}
                  ellipsis
                />
              </ColumnGroup>
            ))
          : MonthArray.map((month, idx) => (
              <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                <Column
                  title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`plan_${idx}`}
                  key={`plan_${idx}`}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure, false, data < 0 ? styles.negative : null)}
                  ellipsis
                />
                <Column
                  title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`fact_${idx}`}
                  key={`fact_${idx}`}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure)}
                  ellipsis
                />
                <Column
                  title={`Разница ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                  dataIndex={`diff_${idx}`}
                  key={`diff_${idx}`}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure, true)}
                  ellipsis
                />
              </ColumnGroup>
            ))}
      </AntTable>
      {isFinanceFooter(objectId, projectId) && (
        <AntTable
          dataSource={totalsFirstRow}
          size="small"
          scroll={{ x: 1300 }}
          pagination={false}
          className={styles.totalString}
        >
          {MonthAndQuarterArray.map((month) => (
            <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
              <Column
                title={`План ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`plan_${month.id}`}
                key={`plan_${month.id}`}
                width={116}
                render={(data) => renderMoneyCell(data, measure, false, data < 0 ? styles.negative : null)}
                ellipsis
              />
              <Column
                title={`Факт ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`fact_${month.id}`}
                key={`fact_${month.id}`}
                width={116}
                render={(data) => renderMoneyCell(data, measure)}
                ellipsis
              />
              <Column
                title={`Разница ${measureInTitle}${LOCALIZATION_CONFIG.currency}`}
                dataIndex={`diff_${month.id}`}
                key={`diff_${month.id}`}
                width={116}
                render={(data) => renderMoneyCell(data, measure, true)}
                ellipsis
              />
            </ColumnGroup>
          ))}
        </AntTable>
      )}
    </div>
  );
};

export default React.memo(Table);
