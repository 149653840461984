import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow';
import React from 'react'
import { IIdAndName } from 'types/idAndName'
import styles from "./TaskSubheaderByObject.module.scss"
import cn from "classnames"
import ExpandIcon from '../../ExpandIcon/ExpandIcon';
import Select from 'components/UI/atoms/Select';
import { useFilterTasksBySection } from 'components/pages/Tasks/hooks/useFilterTaskBySection';
import { tasksListFilterType } from 'redux/modules/purchaser/Tasks/types';
import { useLocationArray } from 'utils/hooks/useLocationArray';
import { TASK_LISTS_TYPES } from 'components/pages/Tasks/constants';
import { useTaskIndicatorsByBuilding } from 'components/pages/Tasks/hooks/useTaskIndicatorsByBuilding';
import { useTypedSelector } from 'redux/typedUseSelector';
import { userSelector } from 'redux/modules/common/auth';

export interface ITaskSubheaderByObjectProps {
    building: IIdAndName;
    onExpand: (id: number) => void;
    isExpanded: boolean;
    onChangeSection: (id: number) => void;
    filters: tasksListFilterType
}

const TaskSubheaderByObject: React.FC<ITaskSubheaderByObjectProps> = ({
    building,
    onExpand,
    isExpanded,
    filters
}) => {
    const userInfo = useTypedSelector(userSelector)
    const type = useLocationArray()[1]

    const expandHandler = React.useCallback(() => {
        building?.id && onExpand(building.id)
    }, [building?.id, onExpand])

    const { loadSectionsHandler, fields, submitFilterBySectionHandler, sectionTaskFilter, isSectionsLoading } = useFilterTasksBySection(building?.id, filters, type as TASK_LISTS_TYPES)

    const [isSelectOpened, setIsSelectOpened] = React.useState(false)
    const openSelectHandler = React.useCallback(() => {
        setIsSelectOpened(true)
        loadSectionsHandler()
    }, [])
    const closeSelectHandler = React.useCallback(() => {
        setIsSelectOpened(false)
    }, [])

    const filterBySectionHandler = React.useCallback((sectionId: number) => {
        submitFilterBySectionHandler(sectionId)
    }, [])

    const filtersForBuildingIndicators = React.useMemo(() => {
        return {
            ...filters,
            section_id: sectionTaskFilter,
            executor: type === TASK_LISTS_TYPES.MY ? userInfo.id : undefined,
            viewer: type === TASK_LISTS_TYPES.WATCHING ? userInfo.id : undefined,
            creator: type === TASK_LISTS_TYPES.ASSIGNED ? userInfo.id : undefined,
        }
    }, [filters, sectionTaskFilter])

    const indicators = useTaskIndicatorsByBuilding(building?.id, filtersForBuildingIndicators)

    return (
        <TableReusableRow
            containerClassName={cn(styles.container, { [styles.zIndexIncreased]: isSelectOpened })}
            onClick={expandHandler}
            isHoverable={false}
            className={styles.grid}
        >
            <TableReusableCell>
                <ExpandIcon isExpanded={isExpanded} />
            </TableReusableCell>
            <TableReusableCell className={styles.name}>{building?.name}</TableReusableCell>
            <TableReusableCell isCentered>
                <div className={cn(styles.mark, styles.expired)} />
                <span className={styles.hidden}>Просрочено&nbsp;</span>
                {indicators?.expired_count || 0} из {indicators?.tasks_count || 0}
            </TableReusableCell>
            <TableReusableCell isCentered>
                <div className={cn(styles.mark, styles.expiring)} />
                <span className={styles.hidden}>Срок истекает&nbsp;</span>
                {indicators?.expires_count || 0} из {indicators?.tasks_count || 0}
            </TableReusableCell>
            <TableReusableCell>{/* @ts-ignore */}
                <Select
                    value={sectionTaskFilter}
                    onOpen={openSelectHandler}
                    onClose={closeSelectHandler}
                    options={fields}//@ts-ignore
                    onChange={filterBySectionHandler}
                    className={styles.sections}
                    placeholder="Раздел"
                    isLoading={isSectionsLoading}
                />
            </TableReusableCell>
            <TableReusableCell isCentered>
                Задач: {indicators?.tasks_count || 0}
            </TableReusableCell>
        </TableReusableRow>
    )
}

export default TaskSubheaderByObject