import cn from "classnames";
import React from "react";

import DoubleInput, { Measure } from "../../../../../UI/atoms/DoubleInput/DoubleInput";
import Expandable from "../../../../../UI/atoms/Expandable/Expandable";
import ClosureDates from "../../../../../UI/molecules/ClosureDates/ClosureDates";
import { IClosureDate } from "../../../../../UI/molecules/ClosureDates/useClosureDates";
import Approval from "../../../../../UI/organism/WorkOrMaterialsModals/components/Approval/Approval";
import { IApprovalInModal } from "../../../../../UI/organism/WorkOrMaterialsModals/components/Approval/types";
import ClosureDateDisplay from "../../../../../UI/organism/WorkOrMaterialsModals/components/ClosureDateDisplay/ClosureDateDisplay";
import ManufacturingModalDivider from "../../../../../UI/organism/WorkOrMaterialsModals/components/ManufacturingModalDivider/ManufacturingModalDivider";
import DayWorkerAndEvent from "components/UI/organism/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent";
import PairOfCountAndAmount from "components/UI/organism/WorkOrMaterialsModals/components/PairOfInfoItems/PairOfCountAndAmount";
import PeriodOfExecution from "components/UI/organism/WorkOrMaterialsModals/components/PeriodOfExecution/PeriodOfExecution";

import PlanEditingHistory from "widgets/EditingHistory/PlanEditingHistory";

import { personFullNameWithId } from "types/personsTypes";

import styles from "./ManufacturingModal.module.scss";

type propsType = {
  planCreatingDate?: string;
  planCreator?: personFullNameWithId | string;
  planIsInWork?: boolean;
  planCount?: string;

  projectAmount?: string;
  projectFullAmount?: string;
  projectFullCount?: string;
  measure: string;
  dateStart: string;
  dateEnd: string;

  approvals?: IApprovalInModal[];
  closureDate: string;
};

function ManufacturingModalContentPlan({
  planCreatingDate,
  planCreator,
  planIsInWork,
  planCount,
  measure,
  dateEnd,
  dateStart,
  projectAmount,
  projectFullAmount,
  projectFullCount,
  approvals,
  closureDate,
}: propsType) {
  return (
    <>
      {/*{planIsInWork && <ManufacturingModalDivider type="plan" className={styles.mt0} />}*/}
      <DayWorkerAndEvent date={planCreatingDate!} author={planCreator!} label="Создал:" />
      <PairOfCountAndAmount
        count={planCount!}
        amount={projectAmount!}
        labelAmount="Стоимость (по проекту):"
        labelCount="Кол-во (план):"
        measure={measure}
      />
      <div className={styles.pair}>
        <PeriodOfExecution dateEnd={dateEnd} dateStart={dateStart} isVertical className={styles.pr1} />
        <ClosureDateDisplay closureDate={closureDate} isVertical />
      </div>
      <PairOfCountAndAmount
        count={projectFullCount!}
        amount={projectFullAmount!}
        labelAmount="Бюджет (по проекту):"
        labelCount="Всего (по проекту):"
        measure={measure}
      />
      {!!approvals?.length && !!planCreatingDate && (
        <Approval approvals={approvals} planCreatingDate={planCreatingDate} />
      )}
    </>
  );
}

export default ManufacturingModalContentPlan;
