import React, { useCallback, useDebugValue, useEffect, useMemo, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";

import { rolesSelector } from "redux/modules/common/userRedux";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Select from "components/UI/atoms/Select";

import { MiniModal } from "../MiniModal/MiniModal";

import { NUMBER_PLACEHOLDER } from "constants/placeholders";

import useFormFocus from "utils/hooks/useFormFocus";

import { parseInputMask } from "utils/formatters/parseInputMask";

import { RiCloseFill } from "react-icons/ri";

import styles from "./InputData.module.scss";

export const InputData = ({ isEditable = true, innerInput, setInnerInput, setFormValid }) => {
  const roles = useSelector(rolesSelector);

  const [modal, setModal] = useState(false);

  const onChangeRole = (selectedRoleId) => {
    const foundRole = roles.find((role) => role.id === selectedRoleId);
    setInnerInput({ ...innerInput, roles: [{ id: foundRole?.id, name: foundRole?.name, alias: foundRole?.alias }] });
    setModal(false);
  };

  const [dirtyFields, setDirtyFields] = useState({
    first_name: false,
    last_name: false,
    middle_name: false,
    number: false,
    email: false,
    position: false,
  });

  const blurHandler = useCallback((e) => {
    setDirtyFields((prevState) => ({ ...prevState, [e.target.name]: true }));
  }, []);

  const [validFields, setValidFields] = useState({
    first_name: false,
    last_name: false,
    middle_name: false,
    number: false,
    email: false,
    position: false,
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    number: "",
    email: "",
    position: "",
  });

  useEffect(() => {
    setFormValid(Object.values(errors).every((x) => !x));
  }, [errors, setFormValid]);

  const setIsValid = useCallback(
    (fieldName, isValid) => setValidFields((prevState) => ({ ...prevState, [fieldName]: isValid })),
    []
  );

  const setError = useCallback(
    (fieldName, error) => setErrors((prevState) => ({ ...prevState, [fieldName]: error })),
    []
  );

  const firstNameHandler = (event) => {
    setInnerInput({ ...innerInput, first_name: event?.target?.value });
    const regex = /[\wа-я]+/gi;
    if (!event?.target?.value.match(regex)) {
      setError(event.target.name, "Фамилия не может быть пустой либо содержать некорректные символы");
      setIsValid(event.target.name, false);
    } else {
      setError(event.target.name, "");
      setIsValid(event.target.name, true);
    }
  };

  const lastNameHandler = (event) => {
    setInnerInput({ ...innerInput, last_name: event?.target?.value });
    const regex = /[\wа-я]+/gi;
    if (!event?.target?.value.match(regex)) {
      setError(event.target.name, "Имя не может быть пустым либо содержать некорректные символы");
      setIsValid(event.target.name, false);
    } else {
      setError(event.target.name, "");
      setIsValid(event.target.name, true);
    }
  };

  const middleNameHandler = (event) => {
    setInnerInput({ ...innerInput, middle_name: event?.target?.value });
    const regex = /[\wа-я]+/gi;
    if (!event?.target?.value.match(regex) && event?.target?.value !== "") {
      setError(event.target.name, "Отчество не может быть пустым либо содержать некорректные символы");
      setIsValid(event.target.name, false);
    } else {
      setError(event.target.name, "");
      setIsValid(event.target.name, true);
    }
  };

  const numberHandler = (event) => {
    setInnerInput({ ...innerInput, phone_number: parseInputMask(event?.target?.value) });
    const regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    if (
      !event?.target?.value.match(regex) &&
      String(event?.target?.value) !== NUMBER_PLACEHOLDER &&
      String(event?.target?.value) !== ""
    ) {
      setError(event.target.name, "Номер введен некорректно");
      setIsValid(event.target.name, false);
    } else {
      setError(event.target.name, "");
      setIsValid(event.target.name, true);
    }
  };

  const emailHandler = (event) => {
    setInnerInput({ ...innerInput, email: event?.target?.value });
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!String(event?.target?.value).toLowerCase().match(regex) && String(event?.target?.value) !== "") {
      setError(event.target.name, "Email введен некорректно");
      setIsValid(event.target.name, false);
    } else if (!event?.target?.value) {
      setError(event.target.name, "Email обязателен");
      setIsValid(event.target.name, false);
    } else {
      setError(event.target.name, "");
      setIsValid(event.target.name, true);
    }
  };

  const positionHandler = (event) => {
    setInnerInput({ ...innerInput, position: event?.target?.value });
    if (event?.target?.value?.length > 255) {
      setError(event.target.name, "Максимальное число символов - 255");
      setIsValid(event.target.name, false);
    } else {
      setError(event.target.name, "");
      setIsValid(event.target.name, true);
    }
  };

  const rolesOptions = useMemo(() => (roles ? roles.map(({ id, name }) => ({ id, name })) : []), [roles]);

  return (
    <>
      <MiniModal visible={modal} setVisible={setModal}>
        <div className={styles.flexAction}>
          <div style={{ fontSize: "22px", fontWeight: "500", color: "#000" }}>Подтвердите действие</div>
          <RiCloseFill size="25" style={{ cursor: "pointer", color: "#707070" }} onClick={() => setModal(!modal)} />
        </div>
        <div>
          <div style={{ padding: "0 20px", marginBottom: "17px" }}>
            <div className={styles.text}>Назначить пользователю стандартные доступы выбранной должности?</div>
          </div>
          <div
            style={{
              borderTop: "1px solid #d3d3d3",
              padding: "10px 19px 11px 0px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonBase style={{ marginRight: "12.5px" }} onClick={() => setModal(!modal)} medium secondary>
              Отменить
            </ButtonBase>
            <ButtonBase onClick={() => setModal(!modal)} medium primary>
              Да
            </ButtonBase>
          </div>
        </div>
      </MiniModal>
      <div className={styles.flexInput}>
        <label style={{ marginBottom: "7px" }}>*Фамилия</label>
        {dirtyFields.last_name && errors.last_name && <MdOutlineCancel className={styles.badIcon} />}
        {validFields.last_name && <AiOutlineCheckCircle className={styles.goodIcon} />}
        <input
          readOnly={!isEditable}
          onBlur={blurHandler}
          name="last_name"
          type="text"
          placeholder="Введите фамилию"
          value={innerInput?.last_name}
          onChange={lastNameHandler}
          className={styles.input}
        />
        {dirtyFields.last_name && errors.last_name && <span className={styles.error}>{errors.last_name}</span>}
      </div>
      <div className={styles.flexInput}>
        <label style={{ marginBottom: "7px" }}>*Имя</label>
        {dirtyFields.first_name && errors.first_name && <MdOutlineCancel className={styles.badIcon} />}
        {validFields.first_name && <AiOutlineCheckCircle className={styles.goodIcon} />}
        <input
          readOnly={!isEditable}
          onBlur={blurHandler}
          name="first_name"
          type="text"
          placeholder="Введите имя"
          value={innerInput?.first_name}
          onChange={firstNameHandler}
          className={styles.input}
        />
        {dirtyFields.first_name && errors.first_name && <span className={styles.error}>{errors.first_name}</span>}
      </div>
      <div className={styles.flexInput}>
        <label style={{ marginBottom: "7px" }}>Отчество</label>
        {dirtyFields.middle_name && errors.middle_name && <MdOutlineCancel className={styles.badIcon} />}
        {validFields.middle_name && <AiOutlineCheckCircle className={styles.goodIcon} />}
        <input
          readOnly={!isEditable}
          onBlur={blurHandler}
          name="middle_name"
          type="text"
          placeholder="Введите отчество"
          value={innerInput?.middle_name}
          onChange={middleNameHandler}
          className={styles.input}
        />
        {dirtyFields.middle_name && errors.middle_name && <span className={styles.error}>{errors.middle_name}</span>}
      </div>
      <div className={styles.flexInput}>
        <label style={{ marginBottom: "7px" }}>Номер телефона</label>
        {dirtyFields.number && errors.number && <MdOutlineCancel className={styles.badIcon} />}
        {validFields.number && <AiOutlineCheckCircle className={styles.goodIcon} />}
        <InputMask
          className={styles.cleaveInput}
          mask="+7 (999) 999-99-99"
          placeholder={NUMBER_PLACEHOLDER}
          readOnly={!isEditable}
          name="number"
          onBlur={blurHandler}
          value={innerInput?.phone_number}
          onChange={numberHandler}
          style={{ fontSize: "1rem" }}
        />
        {dirtyFields.number && errors.number && <span className={styles.error}>{errors.number}</span>}
      </div>
      <div className={styles.flexInput}>
        <label style={{ marginBottom: "7px" }}>E-mail</label>
        {dirtyFields.email && errors.email && <MdOutlineCancel className={styles.badIcon} />}
        {validFields.email && <AiOutlineCheckCircle className={styles.goodIcon} />}
        <input
          name="email"
          onBlur={blurHandler}
          readOnly={!isEditable}
          placeholder="Введите e-mail"
          value={innerInput?.email}
          onChange={emailHandler}
          className={styles.input}
        />
        {dirtyFields.email && errors.email && <span className={styles.error}>{errors.email}</span>}
      </div>
      <div className={styles.flexInput}>
        <label style={{ marginBottom: "7px" }}>Должность</label>
        {dirtyFields.position && errors.position && <MdOutlineCancel className={styles.badIcon} />}
        {validFields.position && <AiOutlineCheckCircle className={styles.goodIcon} />}
        <input
          name="position"
          onBlur={blurHandler}
          readOnly={!isEditable}
          placeholder="Введите должность"
          value={innerInput?.position}
          onChange={positionHandler}
          className={styles.input}
        />
        {dirtyFields.position && errors.position && <span className={styles.error}>{errors.position}</span>}
      </div>
      <div className={styles.flexInput}>
        <Select
          name="roles"
          selectClassName={styles.border}
          onChange={onChangeRole}
          label="Роль"
          options={rolesOptions}
          value={innerInput?.roles[0]?.id}
          labelColor="baseColor"
          disabled={!isEditable}
        />
      </div>
    </>
  );
};
