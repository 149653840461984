import axios from 'axios'
import {createSelector} from 'reselect'

import {commonStateSelector} from '../common'
import {LOAD_LIMIT} from './constants'
import {prepareTableData, prepareTableFilters} from './utils'

const initialState = {
  partnerId: null,
  managers: {
    count: 0,
    list: []
  },
  isOpenManagersModal: false,
  tableFilters: {},
  selectedManagerId: null
}

const moduleName = 'manager'
const actionsPrefix = 'constructing/counterparties/common/manager'

const SET_PARTNER_ID = `${actionsPrefix}_SET_PARTNER_ID`
const SET_MANAGERS = `${actionsPrefix}_SET_MANAGERS`
const SET_MORE_MANAGERS = `${actionsPrefix}_SET_MORE_MANAGERS`
const SET_MANAGERS_MODAL_STATE = `${actionsPrefix}_SET_MANAGERS_MODAL_STATE`
const SET_TABLE_FILTER = `${actionsPrefix}_SET_TABLE_FILTER`
const SET_SELECTED_MANAGER_ID = `${actionsPrefix}_SET_SELECTED_MANAGER_ID`
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`

export const stateSelector = createSelector(commonStateSelector, (state) => state[moduleName])
export const partnerIdSelector = createSelector(stateSelector, (state) => state.partnerId)
export const managersSelector = createSelector(stateSelector, (state) => state.managers)
export const isOpenManagersModalSelector = createSelector(
  stateSelector,
  (state) => state.isOpenManagersModal
)
export const tableFiltersSelector = createSelector(stateSelector, (state) => state.tableFilters)
export const selectedManagerIdSelector = createSelector(
  stateSelector,
  (state) => state.selectedManagerId
)

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case SET_PARTNER_ID:
      return {
        ...state,
        partnerId: payload
      }
    case SET_MANAGERS:
      return {
        ...state,
        managers: {
          count: payload.count,
          list: payload.list
        }
      }
    case SET_MORE_MANAGERS:
      return {
        ...state,
        managers: {
          ...state.managers,
          list: [...state.managers.list, ...payload.list]
        }
      }
    case SET_MANAGERS_MODAL_STATE:
      return {
        ...state,
        isOpenManagersModal: payload
      }
    case SET_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...payload
        }
      }
    case SET_SELECTED_MANAGER_ID:
      return {
        ...state,
        selectedManagerId: payload
      }
    case CLEAR_DATA: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}

export const setPartnerId = (data) => ({
  type: SET_PARTNER_ID,
  payload: data
})

export const setManagers = (data) => ({
  type: SET_MANAGERS,
  payload: data
})

export const setMoreManagers = (data) => ({
  type: SET_MORE_MANAGERS,
  payload: data
})

export const loadManagers = () => {
  return async (dispatch, getState) => {
    const partnerId = partnerIdSelector(getState())
    const tableFilters = tableFiltersSelector(getState())
    try {
      const response = await axios.get(`/entities/${partnerId}/users/`, {
        params: {
          offset: 0,
          limit: LOAD_LIMIT,
          ...prepareTableFilters(tableFilters)
        }
      })
      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results)
      }
      dispatch(setManagers(preparedData))
    } catch (e) {
      console.error(e)
    }
  }
}

export const loadMoreManagers = () => {
  return async (dispatch, getState) => {
    const partnerId = partnerIdSelector(getState())
    const managers = managersSelector(getState())
    const tableFilters = tableFiltersSelector(getState())
    try {
      const response = await axios.get(`/entities/${partnerId}/users/`, {
        params: {
          offset: managers.list.length,
          limit: LOAD_LIMIT,
          ...prepareTableFilters(tableFilters)
        }
      })

      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results)
      }
      dispatch(setMoreManagers(preparedData))
    } catch (e) {
      console.error(e)
    }
  }
}

export const setManagersModalState = (data) => ({
  type: SET_MANAGERS_MODAL_STATE,
  payload: data
})

export const setTableFilter = (data) => ({
  type: SET_TABLE_FILTER,
  payload: data
})

export const setSelectedManagerId = (data) => ({
  type: SET_SELECTED_MANAGER_ID,
  payload: data
})

export const clearData = () => ({
  type: CLEAR_DATA
})
