import { message } from "antd";
import axios from "axios";
import { compose } from "redux";
import { createSelector } from "reselect";

import { DEFAULT_LIMIT_DOCUMENT } from "../../documents/constants";

import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";
import { serializeFiles } from "../../../../../utils/serializeFiles";
import { serializeContractFile } from "./utils/serializeContractFile";

const moduleName = "contracts";
const SET_LOADING = `${moduleName}/SET_LOADING`;
const SET_CONTRACTS = `${moduleName}/SET_CONTRACTS`;
const SET_MORE_CONTRACTS = `${moduleName}/SET_MORE_CONTRACTS`;
const SET_FILES = `${moduleName}/SET_FILES`;
const ADD_FILE = `${moduleName}/ADD_FILE`;
const DELETE_FILE = `${moduleName}/DELETE_FILE`;
const DELETE_CONTRACT = `${moduleName}/DELETE_CONTRACT`;
const RESET_CONTRACTS = `${moduleName}/RESET_CONTRACTS`;
const CREATE_CONTRACT = `${moduleName}/CREATE_CONTRACT`;

const initialState = {
  contracts: null,
  files: {},
  isLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_CONTRACTS:
      return {
        ...state,
        contracts: payload,
      };
    case DELETE_CONTRACT:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          results: state.contracts.results.filter((contract) => contract.id !== payload),
          count: state.contracts.count - 1,
        },
      };
    case CREATE_CONTRACT:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          results: [...state.contracts.results, payload],
          count: state.contracts.count + 1,
        },
      };
    case SET_MORE_CONTRACTS:
      return {
        ...state,
        contracts: {
          ...state?.contracts,
          results: [...state?.contracts?.results, ...payload?.results],
        },
      };
    case SET_FILES:
      return { ...state, files: { ...state.files, [payload.contractId]: payload.files } };
    case ADD_FILE:
      return {
        ...state,
        files: { ...state.files, [payload.contractId]: [...state.files[payload.contractId], payload.file] },
      };
    case DELETE_FILE:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.contractId]: state.files[payload.contractId].filter((file) => file.id !== payload.fileId),
        },
      };
    case RESET_CONTRACTS:
      return { ...state, contracts: initialState.contracts };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const contractsSelector = createSelector(stateSelector, (state) => state.contracts);
export const contractsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const filesSelector = createSelector(stateSelector, (state) => state.files);

const addFileAction = (payload) => ({ type: ADD_FILE, payload });
const setFilesAction = (payload) => ({ type: SET_FILES, payload });
const setLoadingAction = (payload) => ({ type: SET_LOADING, payload });
const deleteFileAction = (payload) => ({ type: DELETE_FILE, payload });
const deleteContractAction = (payload) => ({ type: DELETE_CONTRACT, payload });
export const resetContractsAction = () => ({ type: RESET_CONTRACTS });
const createContractAction = (payload) => ({ type: CREATE_CONTRACT, payload });

export const getFiles = (contractId) => (dispatch) => {
  return axios
    .get(`/entities/contracts/${contractId}/files/`)
    .then((response) =>
      compose(
        dispatch,
        setFilesAction
      )({
        contractId,
        files: serializeFiles(response.data.results),
      })
    )
    .catch(errorCatcher);
};

export const getContractsFiles = (contracts) => (dispatch) => {
  return Promise.all(contracts.map((contract) => compose(dispatch, getFiles)(contract.id))).then(() => {
    compose(dispatch, setLoadingAction)(false);
  });
};

export const loadContracts =
  (requestParams, options = { isMore: false }) =>
  async (dispatch) => {
    if (!options.isMore) compose(dispatch, setLoadingAction)(true);

    let url = "/entities/contracts/";
    if (options.entityId) url = `/entities/${options.entityId}/contracts/`;

    const response = await axios
      .get(url, { params: { ...requestParams, limit: DEFAULT_LIMIT_DOCUMENT } })
      .catch(errorCatcher);
    await compose(dispatch, getContractsFiles)(response.data.results);

    if (options.isMore) {
      dispatch({ type: SET_MORE_CONTRACTS, payload: response.data });
    } else {
      dispatch({ type: SET_CONTRACTS, payload: response.data });
    }
    compose(dispatch, setLoadingAction)(false);
  };

export const addFile = (contractId, file) => (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);

  axios
    .post(`/entities/contracts/${contractId}/files/`, formData)
    .then((response) =>
      compose(
        dispatch,
        addFileAction
      )({
        contractId,
        file: serializeContractFile(response.data),
      })
    )
    .catch(errorCatcher);
};

export const deleteFile = (contractId, fileId) => (dispatch) => {
  axios
    .delete(`/entities/contracts/${contractId}/files/${fileId}/`)
    .then(() => compose(dispatch, deleteFileAction)({ contractId, fileId }))
    .catch(errorCatcher);
};

export const deleteContract = (contractId) => async (dispatch) => {
  await axios.delete(`/entities/contracts/${contractId}/`).catch(errorCatcher);
  compose(dispatch, deleteContractAction)(contractId);
  message.success("Контракт успешно удален");
};

export const createContract = (contract, entityId) => (dispatch) => {
  let url = "/entities/contracts/";
  if (entityId) url = `/entities/${entityId}/contracts/`;

  const formData = new FormData();
  for (const fieldName in contract) {
    if (fieldName === "contract_files") {
      contract[fieldName].forEach((file) => formData.append("upload_files", file.file));
    } else {
      formData.append(fieldName, contract[fieldName]);
    }
  }

  axios
    .post(url, formData)
    .then((response) => {
      compose(dispatch, setFilesAction)({ contractId: response.data.id, files: response.data.files });
      compose(dispatch, createContractAction)(response.data);
      message.success("Контракт успешно создан");
    })
    .catch(errorCatcher);
};
