import React from "react";

import { ISubsectionWithRemarks } from "../../../../../../../../redux/modules/common/building/journal/journalExecution/types";

import { useUIIndicator } from "../../../../../../../../hooks/uiIndicators/useUIIndicator";
import TableReusableRow, { TableReusableCell } from "../../../../../../../UI/atoms/TableReusable/TableReusableRow";
import styles from "../../../../JournalDone/components/JournalExecSections/JournalExecSections.module.scss";

import RemarkCounts from "../../RemarkCounts/RemarkCounts";

import { MODULES_ENUM } from "../../../../../../../../types/enums/ModulesEnum";

export interface IRemarksSubsectionProps {
  subsection: ISubsectionWithRemarks;
  parentId?: number;
  onClick: () => void;
}

const RemarksSubsection: React.FC<IRemarksSubsectionProps> = ({ subsection, parentId, onClick }) => {
  const { isIndicatorDisplayed } = useUIIndicator({
    data: { child_section_id: subsection.id, parent_section_id: parentId },
    module: MODULES_ENUM.OBJECTS,
    type: ["remark_review", "remark_created"],
  });
  return (
    <TableReusableRow className={styles.row} onClick={onClick} isWithIndicator={isIndicatorDisplayed}>
      <TableReusableCell isNoBreak>{subsection.name}</TableReusableCell>
      <TableReusableCell className={styles.countsCell}>
        <RemarkCounts total={subsection.count_remarks} created={subsection.count_remarks_created} />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(RemarksSubsection);
