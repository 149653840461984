import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { analyticsActions } from "../../../../../redux/modules/common/constructing/analytics/actions";
import {
  analyticsBudgetResourceSelector,
  analyticsInvalidateKeySelector,
  analyticsLoadingsSelector,
} from "../../../../../redux/modules/common/constructing/analytics/selectors";
import { loadAnalyticsBudgetResource } from "../../../../../redux/modules/common/constructing/analytics/thunks";
import { AnalyticsBudgetResourceType } from "../../../../../redux/modules/common/constructing/analytics/types/budgetResource";
import { useTypedSelector } from "../../../../../redux/typedUseSelector";

import Select from "../../../../UI/atoms/Select";

import Island, { IIslandElement } from "../Island/Island";
import AnalyticsChartLegend from "../charts/AnalyticsChartLegend/AnalyticsChartLegend";
import AnalyticsChartTick from "../charts/AnalyticsChartTick/AnalyticsChartTick";
import AnalyticsChartTooltip from "../charts/AnalyticsChartTooltip/AnalyticsChartTooltip";
import FullScreenBtn from "../charts/FullScreenBtn/FullScreenBtn";
import ZoomOutBtn from "../charts/ZoomOutBtn/ZoomOutBtn";
import useAnalyticsFullScreen from "../charts/useAnalyticsFullScreen";
import { useChartSlice } from "../charts/useChartSlice";

import {
  CHART_BASE_PROPS,
  CHART_MAIN_ELEMENT_BASE_PROPS,
  GRID_BASE_PROPS,
  XAXIS_BASE_PROPS,
  YAXIS_BASE_PROPS,
} from "../charts/constants";
import { BUDGET_RESOURCE_TYPE_OPTIONS, CHART_COLORS, matchChartLabels } from "./constants";

import { formatDatesForChart } from "../charts/utils";

import commonStyles from "../../Analytics.module.scss";
import styles from "./AnalyticsBudgetResource.module.scss";

const AnalyticsBudgetResource: React.FC<IIslandElement> = ({ islandParentRef }) => {
  const dispatch = useDispatch();
  const budgetResource = useTypedSelector(analyticsBudgetResourceSelector);
  const invalidateKey = useTypedSelector(analyticsInvalidateKeySelector);
  const isLoading = useTypedSelector(analyticsLoadingsSelector)["budgetResource"];

  const { isFullScreen, toggleFullScreen } = useAnalyticsFullScreen();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(loadAnalyticsBudgetResource(abortController.signal));
    return () => {
      abortController.abort();
    };
  }, [invalidateKey, budgetResource?.resourceType]);

  const handleResourceTypeChange = useCallback((newType: AnalyticsBudgetResourceType) => {
    dispatch(analyticsActions.setBudgetResourceType(newType));
  }, []);

  const formattedData = useMemo(
    () => formatDatesForChart(budgetResource?.data?.[budgetResource.resourceType] || []),
    [budgetResource?.data?.[budgetResource?.resourceType]]
  );

  const {
    zoom,
    zoomOut,
    onMouseDown,
    onMouseMove,
    left,
    right,
    bottom,
    top,
    refAreaLeft,
    refAreaRight,
    data,
    isZoomed,
  } = useChartSlice({
    initialData: formattedData,
    xAxisKey: "date",
  });

  return (
    <Island
      islandParentRef={islandParentRef}
      heading={
        <div className={styles.heading}>
          <h3 className={commonStyles.islandHeading}>Бюджет по ресурсам</h3>
          {isZoomed && <ZoomOutBtn zoomOut={zoomOut} className={styles.zoomOutBtn} />}
          <Select
            options={BUDGET_RESOURCE_TYPE_OPTIONS}
            value={budgetResource.resourceType}
            onChange={handleResourceTypeChange}
            className={styles.typeSelect}
          />
          <FullScreenBtn isFullScreen={isFullScreen} toggleFullScreen={toggleFullScreen} />
        </div>
      }
      className={styles.budgetResourceIsland}
      isLoading={isLoading}
      isEmpty={!formattedData?.length}
      isFullScreen={isFullScreen}
    >
      <ResponsiveContainer width="99%" height={"99%"}>
        <AreaChart
          data={formattedData}
          {...CHART_BASE_PROPS}
          onMouseUp={zoom}
          onMouseMove={onMouseMove}
          onMouseDown={onMouseDown}
        >
          <CartesianGrid {...GRID_BASE_PROPS} />
          <XAxis
            dataKey="date"
            {...XAXIS_BASE_PROPS}
            tick={<AnalyticsChartTick />}
            domain={[left, right]}
            allowDataOverflow
          />
          <YAxis {...YAXIS_BASE_PROPS} domain={[bottom, top]} allowDataOverflow />
          <Tooltip
            cursor={{ stroke: CHART_COLORS.CURSOR, strokeWidth: 2 }}
            content={(props) => <AnalyticsChartTooltip {...props} matchChartLabels={matchChartLabels} />}
          />
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
          ) : null}
          <Area
            {...CHART_MAIN_ELEMENT_BASE_PROPS}
            type="linear"
            dataKey="plan"
            stackId="1"
            stroke={CHART_COLORS.PLAN}
            fill={CHART_COLORS.PLAN}
            isAnimationActive={!isFullScreen}
          />
          <Area
            {...CHART_MAIN_ELEMENT_BASE_PROPS}
            type="linear"
            dataKey="fact"
            stackId="2"
            stroke={CHART_COLORS.FACT}
            fill={CHART_COLORS.FACT}
            isAnimationActive={!isFullScreen}
          />
          <Legend
            content={(props) => <AnalyticsChartLegend {...props} centered />}
            payload={[
              { value: "План", type: "circle", color: CHART_COLORS.PLAN },
              { value: "Факт", type: "circle", color: CHART_COLORS.FACT },
            ]}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Island>
  );
};

export default React.memo(AnalyticsBudgetResource);
