import { message } from "antd";
import Axios from "axios";
import { debounce } from "lodash";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { journalActions } from "../../../../../redux/modules/common/building/journal/actions";

import {
  IExpenditureInWorkGroup,
  IProductInWorkGroup,
  IWorkGroup,
  IWorkersInWorkGroup,
} from "../../../../../types/interfaces/Works";
import { IRouterParamsWithObjectId } from "../../../../../types/routerTypes";

import { dropNonSignificantZeros } from "../../../../../utils/formatters/dropNonSignificantZeros";
import { errorCatcher } from "../../../../../utils/helpers/errorCatcher";
import axios from "axios";

const expenditureCountValidation = (expenditure: IExpenditureInWorkGroup) =>
  expenditure?.local_count && expenditure.count && +expenditure.local_count > +expenditure.count;

const calculateExpenditureLocalCount = (
  count: string | number,
  groupCount: string | number,
  expenditurePercent: string | number,
  expenditureCount: string | number,
  totalEstimateExpenditureCount: string | number | undefined
) => {
  try {
    const fillPercent = parseFloat(count) / parseFloat(groupCount);
    let result = 0;
    if (fillPercent.toString().split(".")[1]?.length > 4 && totalEstimateExpenditureCount) {
      result = fillPercent * parseFloat(totalEstimateExpenditureCount) * parseFloat(expenditurePercent);
    } else {
      result = fillPercent * parseFloat(expenditureCount);
    }
    return dropNonSignificantZeros(result, 4);
  } catch (e) {
    console.error(e);
  }
};

const calculateGroupCount = (
  expenditureCount: string | number,
  newExpenditureCount: string | number,
  groupCount: string | number
) => {
  try {
    return dropNonSignificantZeros(
      (parseFloat(groupCount) * parseFloat(newExpenditureCount)) / parseFloat(expenditureCount),
      4
    );
  } catch (e) {
    console.error(e);
  }
};

export interface IUseExpendituresInGroupsProps {
  isOpen: boolean | undefined;
  existingPlanId?: number | null;
  selectedWorkGroupId: number | null;
  count: string | number;
  setCount: (newCount: string | number) => void;
  isPlan?: boolean;
}

export const useExpendituresInGroups = ({
  isOpen,
  existingPlanId = null,
  selectedWorkGroupId,
  count,
  setCount,
  isPlan,
}: IUseExpendituresInGroupsProps) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<IWorkGroup | null>(null);
  const [addedExpenditures, setAddedExpenditures] = useState<IExpenditureInWorkGroup[]>([]);
  const [workGroupMaterials, setWorkGroupMaterials] = useState<IProductInWorkGroup[]>([]);
  const [workGroupMims, setWorkGroupMims] = useState<IProductInWorkGroup[]>([]);

  const totalEstimateExpenditureCount = useMemo(
    () => selectedWorkGroup?.expenditures?.reduce((acc, expenditure) => acc + parseFloat(expenditure.count), 0),
    [selectedWorkGroup]
  );

  const clearExpendituresInGroups = useCallback(() => {
    setAddedExpenditures([]);
    setSelectedWorkGroup(null);
    setWorkGroupMaterials([]);
    setWorkGroupMims([]);
  }, []);

  useEffect(() => {
    if (!selectedWorkGroupId || !objectId || !isOpen) return;
    Axios.get(`/building/${objectId}/groups/${selectedWorkGroupId}/`)
      .then(({ data }) => {
        setSelectedWorkGroup(data);
        setAddedExpenditures(
          data?.expenditures?.filter(e => e.expenditure_type === 'work')?.map((expenditure: IExpenditureInWorkGroup) => ({
            ...expenditure,
            local_count: calculateExpenditureLocalCount(
              count,
              data?.count,
              expenditure.percent,
              expenditure.count,
              totalEstimateExpenditureCount
            ),
          }))
        );
        isPlan
          ? Axios.get(`/building/${objectId}/groups/${selectedWorkGroupId}/plans/products/`).then(({ data }) => {
              setWorkGroupMaterials(data?.materials || []);
              setWorkGroupMims(data?.services || []);
            })
          : Axios.get(`/building/${objectId}/groups/${selectedWorkGroupId}/facts/products/`).then(({ data }) => {
              setWorkGroupMaterials(data?.materials || []);
              setWorkGroupMims(data?.services || []);
            });
      })
      .catch(errorCatcher);
    return () => {
      clearExpendituresInGroups();
    };
  }, [selectedWorkGroupId, objectId, clearExpendituresInGroups, isOpen]);

  useEffect(() => {
    setAddedExpenditures((prevState) =>
      prevState.map(
        (expenditure) =>
          ({
            ...expenditure,
            local_count: selectedWorkGroup
              ? calculateExpenditureLocalCount(
                  count,
                  selectedWorkGroup.count,
                  expenditure.percent,
                  expenditure.count,
                  undefined
                )
              : 0,
          } as IExpenditureInWorkGroup)
      )
    );
    if (isPlan) {
      setWorkGroupMaterials((prevState) =>
        prevState.map(
          (material) =>
            ({
              ...material,
              local_count: selectedWorkGroup
                ? calculateExpenditureLocalCount(
                    count,
                    selectedWorkGroup.count,
                    selectedWorkGroup.expenditures.find((x) => +x.id === +material.work_expenditure_id)?.percent || 1,
                    material.count,
                    undefined
                  )
                : 0,
            } as IProductInWorkGroup)
        )
      );
      setWorkGroupMims((prevState) =>
        prevState.map(
          (mim) =>
            ({
              ...mim,
              local_count: selectedWorkGroup
                ? calculateExpenditureLocalCount(
                    count,
                    selectedWorkGroup.count,
                    selectedWorkGroup.expenditures.find((x) => +x.id === +mim.work_expenditure_id)?.percent || 1,
                    mim.count,
                    undefined
                  )
                : 0,
            } as IProductInWorkGroup)
        )
      );
    }
  }, [count, selectedWorkGroup, isPlan, totalEstimateExpenditureCount]);

  const changeExpendituresCountHandler = debounce((id: number, count: string | number) => {
    const candidate = addedExpenditures.find((expenditure) => expenditure.id === id);
    if (!candidate) return;
    const newCount = selectedWorkGroup ? calculateGroupCount(candidate.count, count, selectedWorkGroup.count) : 0;
    if (newCount === undefined || isNaN(newCount)) return;
    setCount(newCount);
  }, 300);

  const validateSubmittingExpenditures = () => {
    if (addedExpenditures.some((el) => !el.local_count)) {
      message.error("Укажите кол-во каждой расценке");
      return false;
    }
    if (addedExpenditures.some(expenditureCountValidation)) {
      message.error("Кол-во для расценки не должно превышать значение по проекту");
      return false;
    }
    return true;
  };

  const createGroupExpendituresFact = useCallback(
    ({
      startDate,
      endDate,
      addedMims,
      addedMaterials,
      addedWorkers,
      callback,
      planId,
    }: {
      startDate: Moment;
      endDate: Moment;
      addedMims: IProductInWorkGroup[];
      addedMaterials: IProductInWorkGroup[];
      addedWorkers: IWorkersInWorkGroup[];
      callback?: () => void | undefined;
      planId?: number | null;
    }) => {
      if (!selectedWorkGroupId || !objectId) return;
      return axios.post(`/building/${objectId}/groups/facts/`, {
        count,
        start_at: moment(startDate).format("YYYY-MM-DD"),
        end_at: moment(endDate).format("YYYY-MM-DD"),
        group_id: selectedWorkGroupId,
        plan_id: planId || existingPlanId,
        expenditures_write: addedExpenditures.map((x) => ({ id: x.id, count: x.local_count })),
        materials_write: addedMaterials
          .map((x) => ({
            id: x.id,
            count: x.local_count,
          }))
          .filter((x) => !!x.count),
        services_write: addedMims.map((x) => ({ id: x.id, count: x.local_count })).filter((x) => !!x.count),
        workers_write: addedWorkers?.map((item) => ({ id: item.id, stake_count: item.count })),
      })
        .then((response) => {
          callback?.(response?.data);
          dispatch(journalActions.changeInvalidateKey());
          message.success("Запись успешно добавлена");
        })
        .catch(errorCatcher);
    },
    [addedExpenditures, objectId, selectedWorkGroupId, count]
  );

  const createGroupExpendituresPlan = useCallback(
    ({
      startDate,
      endDate,
      addedMims,
      addedMaterials,
      addedWorkers,
      callback,
      paymentDate,
      closureDateId,
    }: {
      startDate: Moment;
      endDate: Moment;
      addedMims: IProductInWorkGroup[];
      paymentDate: Moment | undefined;
      closureDateId: number | null;
      addedMaterials: IProductInWorkGroup[];
      addedWorkers: IWorkersInWorkGroup[];
      callback?: () => void | undefined;
    }) => {
      if (!selectedWorkGroupId || !objectId) return;
      return Axios.post(`/building/${objectId}/groups/plans/`, {
        count,
        start_at: moment(startDate).format("YYYY-MM-DD"),
        end_at: moment(endDate).format("YYYY-MM-DD"),
        payment_date: paymentDate ? moment(paymentDate).format("YYYY-MM-DD") : undefined,
        close_date_id: !!closureDateId ? closureDateId : undefined,
        group_id: selectedWorkGroupId,
        expenditures_write: addedExpenditures.map((x) => ({ id: x.id, count: x.local_count })),
        materials_write: addedMaterials
          .map((x) => ({
            id: x.id,
            count: x.local_count,
          }))
          .filter((x) => !!x.count),
        services_write: addedMims
          .map((x) => ({
            id: x.id,
            count: x.local_count
          }))
          .filter((x) => !!x.count),
        workers_write: addedWorkers?.map((item) => ({
          id: item.id,
          stake_count: item.count
        })),
      })
        .then(() => {
          callback?.();
          message.success("План создан");
        })
        .catch(errorCatcher);
    },
    [addedExpenditures, objectId, selectedWorkGroupId, count]
  );

  return {
    addedExpenditures,
    changeExpendituresCountHandler,
    validateSubmittingExpenditures,
    createGroupExpendituresFact,
    createGroupExpendituresPlan,
    workGroupMaterials,
    workGroupMims,
    clearExpendituresInGroups,
  };
};
