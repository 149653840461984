import React, {useEffect, useState} from 'react'
import { ADD_TASK_INITIALS, EXTRA_MODAL_TAB, MAIN_MODAL_TAB } from "../../../../constants";
import { message } from "antd";
import { Form } from "react-final-form";
import { useTasksModal } from "../../../../hooks/useTasksModal";

import styles from "../ModalAddTask/ModalAddTask.module.scss";
import ModalEditTaskMainFields from "./ModalEditTaskMainFields";
import ModalEditTaskExtraFields from "./ModalEditTaskExtraFields";
import { useUpdateTask } from "../../../../hooks/useUpdateTask";

export interface IModalEditTaskProps {
  formRef: React.RefObject<HTMLFormElement>;
  activeTab: string;
  handleCloseModal: () => void;
}

const ModalEditTask: React.FC<IModalEditTaskProps> = ({ formRef, activeTab, handleCloseModal }) => {
  const { placementType, listType, list_id, taskId, submission } = useTasksModal();
  const {
    onSubmit,
    building,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
    certainTask,
    isTaskStatusEditable
  } = useUpdateTask({ taskId, placementType, listType, list_id });

  const [requestClose, setRequestClose] = useState<boolean>(false);

  useEffect(() => {
    if (!submission?.isSubmitted && submission?.taskId !== -1 && requestClose) handleCloseModal();
  }, [submission, requestClose]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={certainTask || ADD_TASK_INITIALS}
      render={({ form, handleSubmit }) => (
        <form
          className={styles.form}
          ref={formRef}
          onSubmit={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (form.getState().valid) {
              await handleSubmit();
              form.restart();
              setRequestClose(true);
            } else {
              message.warn("Заполните обязательные поля формы");
            }
          }}
        >
          <ModalEditTaskMainFields
            building={building}
            section_id={section_id}
            setSectionId={setSectionId}
            isVisible={activeTab === MAIN_MODAL_TAB}
            executor={executor}
            setExecutor={setExecutor}
            viewers={viewers}
            setViewers={setViewers}
            isTaskStatusEditable={isTaskStatusEditable}
          />
          <ModalEditTaskExtraFields certainTask={certainTask} isVisible={activeTab === EXTRA_MODAL_TAB} />
        </form>
      )}
    />
  );
};

export default ModalEditTask;
