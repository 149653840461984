import React, { FC } from "react";
import ModalContainer from "../../../../../UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import { useManufacturingPlanModal } from "./useManufacturingPlanModal";
import ButtonBack from "../../../../../UI/atoms/ButtonBack/ButtonBack";
import styles from "../ManufacturingModal/ManufacturingModal.module.scss";
import PlanEditing from "../ManufacturingModal/PlanEditing/PlanEditing";
import NavBar from "../../../../../UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import ProductIntervalCardContent
  from "../../../../Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import TabBarNotLinks from "../../../../../UI/atoms/TabBar/TabBarNotLinks";
import { MAIN_PLAN_TAB, PLAN_TABS } from "../ManufacturingModal/constants";
import IntervalRelationsContent from "../ManufacturingModal/IntervalRelationsContent/IntervalRelationsContent";
import ManufacturingModalContentPlan from "../ManufacturingModal/ManufacturingModalContentPlan";
import WorkersList from "../../../../../UI/organism/WorkOrMaterialsModals/components/WorkersList/WorkersList";
import Expandable from "../../../../../UI/atoms/Expandable/Expandable";
import MaterialBlockInWork
  from "../../../../../UI/organism/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import ExpandableFileInput from "../../../../../UI/molecules/ExpandableFileInput/ExpandableFileInput";
import PlanEditingHistory from "../../../../../../widgets/EditingHistory/PlanEditingHistory";
import ManufacturingModalBottom from "../ManufacturingModal/ManufacturingModalBottom/ManufacturingModalBottom";
import { ManufacturingModalsTypesEnum, PlanTabsEnum } from "../const";
import { Spinner } from "../../../../../UI/Spinner/Spinner";
import SliderModal from "../../../../../UI/atoms/SliderModal";
import ModalContentSplitter from "../../../../../UI/atoms/ModalContentSplitter/ModalContentSplitter";
import moment from "moment/moment";
import WorkListItem from "../../../../../UI/organism/WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import EmptyPlaceholder from "../../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import scheduleIcon from "../../../../../../images/icons/navigation/scheduleIcon.svg";
import { INTERVAL_TYPES } from "../../../constants";
import IntervalGroupModal
  from "../../../../../UI/molecules/Expenditure/components/IntervalGroupModal/IntervalGroupModal";
import { useHasEditPlanPermission } from "../../../../../../shared/lib/hooks/useHasEditPlanPermission";


interface IProps {
  isOpen: boolean;
  dateStart: string;
  dateEnd: string;
  expenditureId: number;
  onClose: () => void;
  onEditCallback?: () => void;
  onAddToJournalCallback?: () => void;
  data?: any; // TODO: refactor
  isEditRelationsByDefault?: boolean;
  updatePlanStatus?: (status: string, id: number) => void;
  modalType: ManufacturingModalsTypesEnum;
  isGroupPlan: boolean;
  isPlanSection?: boolean;
}

export const ManufacturingPlanModal: FC<IProps> = ({
  dateStart,
  dateEnd,
  expenditureId,
  onClose,
  onEditCallback,
  onAddToJournalCallback,
  data,
  isEditRelationsByDefault,
  updatePlanStatus,
  modalType,
  isGroupPlan,
  isPlanSection
}) => {
  const {
    closeHandler,
    interval,
    intervalDataFetcher,
    files,
    fileUploadHandler,
    fileRemoveHandler,
    isEditingPlan,
    setIsEditingPlan,
    closePlanEditingHandler,
    isLoading,
    hasRelations,
    isAddingNew,
    setIsAddingNew,
    list,
    handleListBackButton,
    hasEditRelationsPermission,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
    canEditPlan,
    canApprovePlan,
    approveHandler,
    status,
    tab,
    setTab,
    objectId,
    intervalClickHandler,
    isGroup,
    planIsInFuture,
  } = useManufacturingPlanModal({
    dateStart,
    dateEnd,
    expenditureId,
    onClose,
    updatePlanStatus,
    modalType,
    data,
    isGroupPlan,
    isPlanSection
  });

  const hasEditPlanPermission = useHasEditPlanPermission();

  if (isEditingPlan) {
    return (
      <ModalContainer
        isOpen
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification}
        statusColor={status.color}
        statusText={status.text}
        statusIcon={status.icon}
      >
        <ButtonBack onClick={() => setIsEditingPlan(false)} className={styles.back} />
        <PlanEditing
          planCreator={interval?.author}
          planCreatingDate={interval?.created_at}
          productId={interval?.expenditure_id}
          buildingId={+objectId}
          intervalId={interval?.id}
          planCount={interval?.count}
          planDateStart={interval?.start_at}
          planDateEnd={interval?.end_at}
          onClose={closePlanEditingHandler}
          files={files}
          onDeleteFiles={fileRemoveHandler}
          onUploadFiles={fileUploadHandler}
          defaultMaterials={interval?.planned_materials_list}
          defaultMims={interval?.count_planned_services}
          defaultWorkers={interval?.workers}
          defaultWorkersCounts={interval?.count_workers_data}
          onEditCallback={onEditCallback}
          sectionId={interval.parent_section_id || interval.parent_id || interval.section_id}
          paymentDate={interval?.payment_date}
          closeWholeModal={onClose}
          isExpendituresGroup={false}
          measure={interval?.measure}
        />
      </ModalContainer>
    );
  }

  const isWorkListShown = !interval?.id && list?.length > 1;

  if (isWorkListShown) {
    return (
      <SliderModal closeHandler={closeHandler} isOpen className={styles.modalContainer}>
        {isLoading && <Spinner />}
        {!isLoading && isWorkListShown && (
          <div className={styles.list}>
            {list.map((el: any, index: number) => {
                const isGroup = !!el.group;
                return (
                  <React.Fragment key={el.id}>
                    {(index === 0 || el.start_at !== list[index - 1]?.start_at) && (
                      <ModalContentSplitter label={moment(el.start_at).format("DD.MM.YY")} />
                    )}
                    <WorkListItem
                      type={INTERVAL_TYPES.plan}
                      onClick={() => intervalClickHandler(el.id, el.expenditure_id || el.group?.id, isGroup)}
                      name={el.expenditure_name || el.group?.name}
                      passAmount={el.completed_amount || el.work_amount_with_materials || el.group?.amount}
                      passCount={el.count || el.group?.count}
                      passDate={el.created_at}
                      passName={isGroup ? el.author?.name : el.author}
                      acceptAmount={el.accepted?.length ? el.accepted[0].amount : 0}
                      acceptCount={el.accepted?.length ? el.accepted[0].count : 0}
                      acceptDate={el.accepted?.length ? el.accepted[0].created_at : ""}
                      acceptName={el.accepted?.length ? el.accepted[0].approve_user : ""}
                      measure={el.measure || el.group?.measure}
                      hideAccepted={!el.accepted?.length}
                      modalType={"expenditure"}
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}
        {!isLoading && !isWorkListShown && (
          <EmptyPlaceholder
            img={scheduleIcon}
            text={"В данном интервале пока что нет записей"}
            className={styles.emptyPlaceholder}
          />
        )}
      </SliderModal>
    );
  }

  if (isGroup && !!interval?.id && !isLoading) {
    return (
      <IntervalGroupModal
        isOpen
        onClose={closeHandler}
        onBackButtonClick={list.length > 1 ? handleListBackButton : undefined}
        expenditureId={interval.id}
        expenditure={interval}
        sectionName={interval.group?.section_name}
        activeModule={"plans"}
        date_start={interval.start_at}
        date_end={interval.end_at}
        objectId={objectId}
        sectionId={interval.parent_section_id || interval.parent_id || interval.section_id}
        remarks={interval?.remarks ?? []}
        ticketRemarks={interval?.ticketRemarks ?? []}
        onEditCallback={onEditCallback}
        showRelationsTab={true}
        hasRelations={hasRelations}
      />
    );
  }

  return (
    <ModalContainer
      isOpen
      onClose={closeHandler}
      name={interval?.expenditure_name}
      justification={interval?.justification}
      statusColor={status.color}
      statusText={status.text}
      statusIcon={status.icon}
    >
      {isAddingNew && (
        <>
          <NavBar isExist text="Вернуться к работе" onClick={() => setIsAddingNew(false)} className={styles.back} />
          <ProductIntervalCardContent //@ts-ignore
            isOpen
            objectId={data.building_id ?? objectId}
            handleClose={() => setIsAddingNew(false)}
            directlySection={interval?.section_id}
            directlyWork={interval?.expenditure_id}
            onAddToJournalCallback={onAddToJournalCallback}
          />
        </>
      )}
      {isLoading && (
        <Spinner />
      )}
      {!isAddingNew && !isLoading && (
        <>
          <NavBar isExist={list?.length > 1} onClick={handleListBackButton} className={styles.back} />
          {hasRelations && (
            <TabBarNotLinks
              tabs={PLAN_TABS}
              activeId={tab}
              onClick={setTab}
              className={styles.relationsTabs}
            />
          )}
          {tab === PlanTabsEnum.RELATIONS && (
            <IntervalRelationsContent
              hasEditPermission={hasEditRelationsPermission}
              intervalId={interval.id}
              projectId={+objectId}
              relationsFromCurrentInterval={relationsFromCurrentInterval}
              relationsToCurrentInterval={relationsToCurrentInterval}
              intervalStartAt={interval.start_at}
              intervalEndAt={interval.end_at}
              isEditRelationsByDefault={isEditRelationsByDefault}
            />
          )}
          {tab === MAIN_PLAN_TAB && (
            <>
              <ManufacturingModalContentPlan
                planCreator={interval?.author}
                planCreatingDate={interval?.created_at}
                measure={interval?.measure}
                dateStart={interval?.start_at}
                dateEnd={interval?.end_at}
                projectAmount={interval?.work_amount}
                projectFullAmount={interval?.expenditure_amount}
                projectFullCount={interval?.expenditure_count}
                planCount={interval?.count}
                planIsInWork={interval?.work_status === "actived"}
                approvals={interval?.approvals}
                closureDate={interval?.payment_date}
              />
              <div className={styles.pair}>
                <div className={styles.info}>
                  <b>Раздел:</b>
                  <span>{interval?.section_name}</span>
                </div>
              </div>
              <WorkersList workers={interval?.workers} />
              {!!interval?.planned_materials_list?.length && (
                <Expandable title={`Материалы: ${interval?.planned_materials_list.length}`}>
                  {interval?.planned_materials_list.map((el: any) => (
                    <MaterialBlockInWork
                      name={el.material?.name}
                      measure={el.material?.measure}
                      count={el.count}
                      amount={
                        (parseFloat(el.count) * parseFloat(el.material?.estimate_expenditure?.estimate_amount)) /
                        parseFloat(el.material?.estimate_expenditure?.estimate_count) ||
                        el.material?.estimate_expenditure?.estimate_amount
                      }
                      key={el.id}
                      statusColor="blue"
                      statusText="План"
                    />
                  ))}
                </Expandable>
              )}
              {!!interval?.count_planned_services?.length && (
                <Expandable title={`Машины и Механизмы: ${interval?.count_planned_services.length}`}>
                  {interval?.count_planned_services.map((el: any) => (
                    <MaterialBlockInWork
                      key={el.id}
                      name={el.service?.name}
                      measure={el.service?.measure}
                      count={el.count}
                      amount={el.amount}
                      statusColor="blue"
                      statusText="План"
                    />
                  ))}
                </Expandable>
              )}
              {!!files?.length && (
                <ExpandableFileInput
                  files={files || []}
                  canExtractFiles
                  canDeleteFiles={false}
                  canUploadFiles={false}
                />
              )}
              <PlanEditingHistory
                isOpen
                isGroup={isGroup}
                objectId={objectId}
                ticketId={interval?.id}
              />
              <ManufacturingModalBottom
                interval={interval}
                isPlan={true}
                canEditPlan={canEditPlan && hasEditPlanPermission}
                canApprovePlan={!!canApprovePlan}
                planIsInFuture={planIsInFuture}
                onAddToJournal={() => setIsAddingNew(true)}
                onApprove={approveHandler}
                onEdit={() => setIsEditingPlan(true)}
              />
            </>
          )}
        </>
      )}
    </ModalContainer>
  );
};