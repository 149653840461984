import { message } from "antd";
import axios from "axios";
import cn from "classnames";
import React, { Component } from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

import RegistrImg from "images/authorization/registration-img.svg";

import styles from "./AuthTabs.module.scss";

const INITIAL_REGISTER_DATA = { email: "", fullName: "", phoneNumber: "" };

export class SignUpCall extends Component {
  state = {
    registerData: INITIAL_REGISTER_DATA,
    success: false,
  };

  submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(`/user_logging/registration/`, {
        ...this.state.registerData,
        full_name: this.state.registerData.fullName,
        phone_number: this.state.registerData.phoneNumber,
      })
      .then(() => {
        message.success("Заявка успешно отправлена");
        this.setState({ registerData: INITIAL_REGISTER_DATA });
      })
      .catch(errorCatcher);
  };

  onChangeRegisterData = (field) => (e) => {
    this.setState((state) => ({ ...state, registerData: { ...state.registerData, [field]: e.target.value } }));
  };

  render() {
    return (
      <div>
        <div className="authorization__form-container">
          {this.state.success ? (
            <div className="authorization__confirm-panel">
              <div className="authorization__img">
                <img src={RegistrImg} alt="Графическая иллюстрация" />
              </div>
              <div className="authorization__text">Мы отправили письмо на Вашу почту!</div>
              <div className="authorization__buttons">
                <button onClick={this.props.toLogin} className="authorization__btn">
                  Ок!
                </button>
              </div>
            </div>
          ) : (
            <div className="authorization__form">
              <div className="form__title">Оставьте заявку и наш специалист свяжется с вами в ближайшее время</div>
              <form className="form gform" onSubmit={this.submitHandler}>
                <div className="authorization__form-item">
                  <label className="authorization__label" htmlFor="fio">
                    <span className="authorization__input-icon authorization__input-icon_user"></span>
                    <input
                      className={cn("authorization__input form__input", styles.input)}
                      value={this.state.registerData.fullName}
                      onChange={this.onChangeRegisterData("fullName")}
                      placeholder="ФИО"
                      required
                    />
                  </label>
                  <div className="form__error"></div>
                </div>
                <div className="authorization__form-item">
                  <label className="authorization__label" htmlFor="tel">
                    <span className="authorization__input-icon authorization__input-icon_phone"></span>
                    <input
                      type="tel"
                      value={this.state.registerData.phoneNumber}
                      onChange={this.onChangeRegisterData("phoneNumber")}
                      className={cn("authorization__input form__input", styles.input)}
                      placeholder="+71234567890"
                    />
                  </label>
                  <div className="form__error"></div>
                </div>
                <div className="authorization__form-item">
                  <label className="authorization__label" htmlFor="mail">
                    <span className="authorization__input-icon authorization__input-icon_mail"></span>
                    <input
                      className={cn("authorization__input form__input", styles.input)}
                      type="email"
                      value={this.state.registerData.email}
                      onChange={this.onChangeRegisterData("email")}
                      placeholder="Email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
                      required
                    />
                  </label>
                  <div className="form__error"></div>
                </div>
                <div className="authorization__form-item">
                  <div className="authorization__confirm">
                    Нажимая "Отправить", я соглашаюсь с условиями пользовательского соглашения
                  </div>
                </div>
                <div className="authorization__buttons">
                  <ButtonBase secondary className={cn("btn btn-main", styles.submit)}>
                    Отправить
                  </ButtonBase>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SignUpCall;
