import cn from "classnames";
import React from "react";

import { handlerCountSvg } from "./handlerCountSvg";

import styles from "./HandlerStateCount.module.scss";

interface IProps {
  color?: "blue" | "green";
  count: string;
  title: string;
  amount: number;
  plus?: string;
}

const HandlerStateCount: React.FC<IProps> = ({ color = "blue", count, title, amount, plus }) => {
  return (
    <div className={cn(styles.container, styles[color])}>
      <div className={styles.row}>
        <div className={styles.count}>
          <div className={styles.bg}>{handlerCountSvg}</div>
          <span>{count}</span>
        </div>
        <div className={styles.amount}>
          {amount}
          {plus && <div className={styles.plus}>+ {plus}</div>}
        </div>
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default HandlerStateCount;
