import React from "react";
import { useHistory } from "react-router-dom";

import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";
import { NavLinkVariantsEnum } from "components/UI/NavLinkIcon/constants";
import SelectMenu from "components/UI/atoms/SelectMenu";
import StatusFormatter from "components/UI/atoms/StatusFormatter";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";

import { PCR_REJECTED, PVR_REJECTED } from "constants/constant";
import { IOrderInList } from "types/interfaces/Order";

import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";

import styles from "./OrdersRow.module.scss";

interface Iprops {
  order: IOrderInList;
}

const OrdersRow: React.FC<Iprops> = ({ order }) => {
  const history = useHistory();

  const rowClickHandler = () => history.push(`/purchases/orders/${order.id}`);

  return (
    <TableReusableRow className={styles.row} onClick={rowClickHandler}>
      <TableReusableCell>{sliceTextByConstraint(String(order.number), 8)}</TableReusableCell>
      <TableReusableCell>
        <NavLinkIcon
          to={`/purchases/requisitions/in-work/${order.requisition.id}`}
          title={sliceTextByConstraint(String(order.requisition.number), 8)}
          className={styles.link}
        />
      </TableReusableCell>
      <TableReusableCell isNoBreak>{order.building.name || "-"}</TableReusableCell>
      <TableReusableCell isNoBreak>{order.provider.name || "-"}</TableReusableCell>
      <TableReusableCell>
        {order.packinglists.length > 0 ? (
          <div className={styles.block}>
            <NavLinkIcon
              className={styles.link}
              variant={NavLinkVariantsEnum.TERTIARY}
              to={`/purchases/documents/packing-lists/${order.packinglists[0]!.id}`}
              title={
                order.packinglists[0].number
                  ? `№ ${sliceTextByConstraint(String(order.packinglists[0].number), 6)}`
                  : "Сформирован"
              }
              isWithoutIcon={order.packinglists.length > 1}
            />
            {order.packinglists.length > 1 && (
              <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <SelectMenu
                  selectedOption={order.packinglists.length}
                  titleClassName={styles.breadcrumbsTitle}
                  selectClassName={styles.selectClassName}
                  optionsBlockClassName={styles.optionsBlockClassName}
                  rotate={45}
                  lastEl
                >
                  {order.packinglists.map((item) => (
                    <NavLinkIcon
                      className={styles.breadcrumbsItem}
                      variant={NavLinkVariantsEnum.TERTIARY}
                      to={`/purchases/documents/packing-lists/${item.id}`}
                      title={`№ ${!item.number ? "Ожидается" : item.number}`}
                      key={item.id}
                    />
                  ))}
                </SelectMenu>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.block}>
            <div className={styles.icon}>Ожидается</div>
          </div>
        )}
      </TableReusableCell>
      <TableReusableCell>
        {order.invoice ? (
          <NavLinkIcon
            className={styles.link}
            variant={NavLinkVariantsEnum.TERTIARY}
            to={`/purchases/documents/bills/${order.invoice.id}`}
            title={order.invoice.number ? `№ ${order.invoice.number}` : "Сформирован"}
          />
        ) : order.status === PVR_REJECTED || order.status === PCR_REJECTED ? (
          "Отсутствует"
        ) : (
          "Ожидается"
        )}
      </TableReusableCell>
      <TableReusableCell>{StatusFormatter(order.status_name, order)}</TableReusableCell>
    </TableReusableRow>
  );
};

export default OrdersRow;
