import moment from "moment";
import React from "react";

import Actions from "components/UI/atoms/Actions/Actions";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";
import ProductFilesModal from "components/pages/Requisition/components/ProductFilesModal/ProductFilesModal";

import { IContract } from "types/interfaces/Contract";

import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./ContractsRow.module.scss";

interface IProps {
  contract: IContract;
  files: unknown[];
  canDeleteContract?: boolean;
  onClick: () => void;
  onDeleteContract: () => void;
  isContacts?: boolean;
}

const ContractsRow: React.FC<IProps> = ({
  contract,
  files,
  canDeleteContract,
  onDeleteContract,
  onClick,
  isContacts,
}: IProps) => {
  return (
    <TableReusableRow className={styles.row} onClick={onClick}>
      <TableReusableCell>{contract.contract_num}</TableReusableCell>
      <TableReusableCell isNoBreak>{contract.building_name || "-"}</TableReusableCell>
      <TableReusableCell isNoBreak>{isContacts ? contract.contractor?.name : contract.entity_name}</TableReusableCell>
      <TableReusableCell isCentered>{moment(contract.contract_date).format("DD.MM.YYYY")}</TableReusableCell>
      <TableReusableCell isRighted>{splitThousands(contract.amount, false, false)}</TableReusableCell>
      {!canDeleteContract && <div />}
      <TableReusableCell>
        <ProductFilesModal files={files} variant="secondary" isDontOpenModal expenditureId={-1} />
      </TableReusableCell>
      {canDeleteContract && (
        <TableReusableCell>
          <Actions className={styles.actions} canRemove onRemove={onDeleteContract} />
        </TableReusableCell>
      )}
    </TableReusableRow>
  );
};

export default ContractsRow;
