import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { manufacturingHashSelector } from "../../../../redux/modules/common/building/manufacturing/selectors";
import { loadInterval, loadMaterialInterval } from "redux/modules/common/building/manufacturing/thunks";

import { MATERIALS_TAB_ID, RESOURCES_TAB_ID, WORKS_TAB_ID } from "../constants";
import { ManufacturingTabsType } from "../types";

export interface IUseIntervalsProps {
  touchedYears: string[];
  expenditureTypesToLoad: Record<ManufacturingTabsType, boolean>;
}

export const useIntervals = ({ touchedYears, expenditureTypesToLoad }: IUseIntervalsProps) => {
  const dispatch = useDispatch();
  const manufacturingHash = useSelector(manufacturingHashSelector);

  useEffect(() => {
    const startYear = +touchedYears?.[0] || moment().year();
    const endYear = +(touchedYears?.at(-1) || moment().year());
    expenditureTypesToLoad[WORKS_TAB_ID] &&
      dispatch(loadInterval(moment().year(startYear).startOf("year"), moment().year(endYear).endOf("year")));
    (expenditureTypesToLoad[MATERIALS_TAB_ID] || expenditureTypesToLoad[RESOURCES_TAB_ID]) &&
      dispatch(loadMaterialInterval(moment().year(startYear).startOf("year"), moment().year(endYear).endOf("year")));
  }, [touchedYears, expenditureTypesToLoad, manufacturingHash]);
};
