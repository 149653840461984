import cn from "classnames";
import React from "react";

import { personFullNameWithId } from "types/personsTypes";

import styles from "./Avatar.module.scss";

// const getFirstLettersInName = (person?: personFullNameWithId | string) => {
//   if (!person) return "-";
//   if (typeof person === "string") {
//     const splitByDots = person.split(".");
//     return splitByDots[0][0] + splitByDots[0][splitByDots[0].length - 1];
//   }
//   if (!person?.first_name || !person?.last_name) return "-";
//   return person?.last_name[0] + "" + person?.first_name[0];
// };

const getFirstLettersInName = (person?: personFullNameWithId | string) => {
  if (!person) return "-";
  if (typeof person === "string") {
    const splitBySpace = person.split(" ");
    return splitBySpace[0][0] + splitBySpace[1][0];
  }
  if (!person?.first_name || !person?.last_name) return "-";
  return person?.last_name[0] + "" + person?.first_name[0];
};

export interface IAvatarProps {
  person: personFullNameWithId | string;
  img?: string;
  title?: string;
  className?: string;
}

function Avatar({ person, img, className, title }: IAvatarProps) {
  return (
    <div className={cn(styles.avatar, className)} title={title}>
      {img ? <img src={img} alt="аватар" /> : <div>{getFirstLettersInName(person)}</div>}
    </div>
  );
}

export default React.memo(Avatar);
