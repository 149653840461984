import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { Spinner } from "components/UI/Spinner/Spinner";

import Dropdown from "shared/ui/atoms/Dropdown/Dropdown";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";

import { IIdAndName } from "types/idAndName";

import styles from "./InputAutocomplete.module.scss";

interface IProps {
  displayingValue: string;
  onChangeSearchBar: (value: string) => void;
  isLoading?: boolean;
  options?: IIdAndName[];
  optionClassName?: string;
  onSelectOption: (name: string, id: number) => void;
  label?: string;
  error?: string;
  hideOptions?: boolean;
}

const InputAutocomplete: React.FC<IProps> = ({
  onChangeSearchBar,
  isLoading,
  options,
  optionClassName,
  onSelectOption,
  displayingValue,
  label,
  error,
  hideOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wasSelected = useRef(false);

  const toggleHandler = () => {
    if (!displayingValue || hideOptions) return;
    setIsOpen((p) => !p);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    wasSelected.current = false;
    onChangeSearchBar(value);
    if (value) {
      !hideOptions && setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (hideOptions) {
      setIsOpen(false);
    }
  }, [hideOptions]);

  return (
    <LabeledItem error={error} label={label}>
      <Dropdown
        placeholder={<input className={styles.input} value={displayingValue} onChange={changeHandler} />}
        externalIsOpen={isOpen}
        onClick={toggleHandler}
        onClose={() => setIsOpen(false)}
      >
        {isLoading && (
          <div className={styles.loading}>
            <Spinner isSmall isStatic />
          </div>
        )}
        {!isLoading && !options?.length && <div className={styles.empty}>По вашему запросу ничего не найдено</div>}
        {!isLoading &&
          options?.map((el) => (
            <div
              key={el.id}
              className={classNames(styles.option, optionClassName)}
              onClick={(e) => {
                e.stopPropagation();
                onSelectOption(el.name, el.id);
                setIsOpen(false);
                wasSelected.current = true;
              }}
            >
              {el.name}
            </div>
          ))}
      </Dropdown>
    </LabeledItem>
  );
};

export default InputAutocomplete;
