import cn from "classnames";
import React, { MouseEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import ProjectsBranch from "./components/ProjectsBranch/ProjectsBranch";
import TreeFilterHeader from "./components/TreeFilterHeader/TreeFilterHeader";

import { ISpittingTreeElement, ManufacturingTabsType } from "../../types";

import { ReactComponent as LeftIcon } from "shared/assets/icons/ArrowLeft-small.svg";
import { ReactComponent as RigthIcon } from "shared/assets/icons/ArrowRigth.svg";

import styles from "./ProjectsTree.module.scss";

export interface IProjectsTreeProps {
  isHideFilters: boolean;
  spittingTree: ISpittingTreeElement[];
  type: ManufacturingTabsType;
  checkIsExpandedBranchId: (id: number) => boolean;
  toggleBranch: (branch: ISpittingTreeElement) => void;
  checkIsCheckboxVisible: (branch: ISpittingTreeElement) => boolean;
  checkIsCheckboxTrue: (branch: ISpittingTreeElement) => boolean;
  blockAndMoveRunningLine: MouseEventHandler;
  releaseRunningLine: MouseEventHandler;
  isProduction?: boolean;
  isPlanEditing?: boolean;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPeriod: React.Dispatch<SetStateAction<{ dateEnd: null; dateStart: null }>>;
  isPeriodVisible?: boolean;
}

const ProjectsTree: React.FC<IProjectsTreeProps> = ({
  isHideFilters,
  spittingTree,
  checkIsExpandedBranchId,
  toggleBranch,
  type,
  checkIsCheckboxVisible,
  checkIsCheckboxTrue,
  blockAndMoveRunningLine,
  releaseRunningLine,
  isProduction = false,
  isPlanEditing = false,
  setFilterValue,
  setSelectedPeriod,
  isPeriodVisible = false
}) => {
  const [variant, setVariant] = useState("default");

  useEffect(() => {
    setVariant("default");
  }, [type]);

  const treeStyles = useMemo(() => {
    return {
      height: `${(spittingTree.length + 1) * 3}rem`,
      width: variant === "days" ? "calc(35% + 7rem)" : variant === "period" ? "calc(35% + 22rem)" : "35%",
    };
  }, [variant, spittingTree.length]);

  return (
    <div className={styles.tree} style={treeStyles}>
      <TreeFilterHeader
        setFilterValue={setFilterValue}
        setSelectedPeriod={setSelectedPeriod}
        isHideFilters={isHideFilters}
        variant={variant}
      />
      <div className={cn(styles.branches, {[styles.withPeriod]: isPeriodVisible})}>
        {spittingTree.map((item, index) => (
          <ProjectsBranch
            key={index}
            index={index}
            branch={item}
            checkIsExpandedBranchId={checkIsExpandedBranchId}
            toggleBranch={toggleBranch}
            type={type}
            checkIsCheckboxVisible={checkIsCheckboxVisible}
            checkIsCheckboxTrue={checkIsCheckboxTrue}
            onHover={blockAndMoveRunningLine}
            onLeave={releaseRunningLine}
            isProduction={isProduction}
            isPlanEditing={isPlanEditing}
            variant={variant}
          />
        ))}
        {isPeriodVisible && (
          <>
            {variant === "period" && (
              <>
                <div className={cn(styles.barier, styles.rigthFirst)} />
                <div className={cn(styles.barier, styles.rigthSecond)} />
              </>
            )}
            {variant === "days" ? <div className={cn(styles.barier, styles.rigthDays)} /> : null}
            {variant === "default" || variant === "period" ? (
              <div
                className={cn(styles.arrowButton, styles.arrows)}
                onClick={() => {
                  setVariant("days");
                }}
              >
                {variant === "period" ? <LeftIcon /> : <RigthIcon />}
              </div>
            ) : null}
            {variant === "days" && (
              <>
                <div
                  className={cn(styles.arrowButton, styles.arrowLeftDouble)}
                  onClick={() => {
                    setVariant("default");
                  }}
                >
                  <LeftIcon className={styles.icon} />
                </div>
                <div
                  className={cn(styles.arrowButton, styles.arrowRigthDouble)}
                  onClick={() => {
                    setVariant("period");
                  }}
                >
                  <RigthIcon className={styles.icon} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ProjectsTree);
