import cn from "classnames";
import moment from "moment";
import React, { Fragment, MouseEventHandler, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  setActiveBranch,
  setManufacturingHash,
} from "../../../../../../../redux/modules/common/building/manufacturing/thunks";

import CreatePlanModal from "../../../../../../UI/molecules/Expenditure/components/ProductIntervalCard/CreatePlanModal";
import PopoverOverlay from "../../../../../../UI/molecules/PopoverOverlay/PopoverOverlay";
import SectionPlanModal from "../../../../../Building/components/SectionPlanModal/SectionPlanModal";

import CurrencyPopover from "../Popovers/CurrencyPopover/CurrencyPopover";
import InfoPopover from "../Popovers/InfoPopover/InfoPopover";

import {
  EQUIPMENT_TAB_ID,
  MATERIALS_TAB_ID,
  MIMES_TAB_ID,
  MT_REM,
  RESOURCES_TAB_ID,
  WORKS_TAB_ID,
} from "../../../../constants";
import { ISpittingTreeElement, ManufacturingTabsType } from "../../../../types";

import { parseBranchIndicators } from "../../../../utils";

import { ReactComponent as IconPlus } from "../../../../../../../shared/assets/icons/IconPlus.svg";

import styles from "./ProjectsBranch.module.scss";

export interface IProjectsBranchProps {
  branch: ISpittingTreeElement;
  type: ManufacturingTabsType;
  checkIsExpandedBranchId: (id: number) => boolean;
  toggleBranch: (branch: ISpittingTreeElement) => void;
  index: number;
  checkIsCheckboxVisible: (branch: ISpittingTreeElement) => boolean;
  checkIsCheckboxTrue: (branch: ISpittingTreeElement) => boolean;
  onHover: MouseEventHandler;
  onLeave: MouseEventHandler;
  isProduction?: boolean;
  isPlanEditing?: boolean;
  variant?: string;
}

const ProjectsBranch: React.FC<IProjectsBranchProps> = ({
  branch,
  toggleBranch,
  index,
  type,
  checkIsCheckboxTrue,
  checkIsCheckboxVisible,
  onHover,
  onLeave,
  isProduction,
  isPlanEditing = false,
  variant = "default",
}) => {
  const dispatch = useDispatch();
  const [isCreatePlanModalOpened, setIsCreatePlanModalOpened] = useState(false);

  const isLsrLevel = isProduction ? branch.lvl === 1 : branch.lvl === 2;
  const isSectionLevel = isProduction ? branch.lvl === 2 : branch.lvl === 3;
  const isExpenditureLevel = isProduction ? branch.lvl === 3 : branch.lvl === 4;

  const branchHasPlan = !!(branch?.data?.plan_start && branch?.data?.plan_end);
  const isPlanEditingIconVisible = !(!isProduction && branch.lvl === 1)
    && (!branchHasPlan || isExpenditureLevel);

  const isGroup = !!branch.data?.expenditure_numbers;

  const hide = checkIsCheckboxTrue(branch);

  const handlePlanCreateSuccess = useCallback(() => {
    dispatch(setManufacturingHash(Math.random()));
  }, []);

  const { infoIndicators, currencyIndicators } = useMemo(
    () => parseBranchIndicators(branch.data, type),
    [branch, type]
  );

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onHover?.(e);
      dispatch(setActiveBranch({ index: index, eventFrom: "tree" }));
    },
    [onHover, index]
  );

  const isCheckboxVisible = checkIsCheckboxVisible(branch);
  const isNumberVisible = !isCheckboxVisible && branch.lvl !== 1 && !!branch.data?.number;

  const handleBranchClick = useCallback(
    (branch: ISpittingTreeElement) => () => {
      if (!isCheckboxVisible) return;
      toggleBranch(branch);
    },
    [isCheckboxVisible, toggleBranch]
  );

  const hasIndicators = useMemo(() => {
    if (isPlanEditing) {
      return false;
    }

    return Object.keys(infoIndicators)?.length > 0 || Object.keys(currencyIndicators)?.length > 0;
  }, [infoIndicators, currencyIndicators, isPlanEditing]);


  const branchWrapperStyles = useMemo(() => {
    return {
      width:
        variant === "days"
          ? "calc(100% - 11.5rem)"
          : variant === "period"
          ? "calc(100% - 26.5rem)"
          : "calc(100% - 4.5rem)",
    };
  }, [variant]);


  return (
    <Fragment>
      <div
        className={cn(styles.branch, {
          [styles.branchChild]: branch.lvl === 2,
          [styles.branchChildActive]: branch.lvl === 2 && branch.childCount > 0,
          [styles.branchExpenditure]: branch.lvl === 3,
          [styles.branchExpenditureChild]: branch.lvl === 4,
          [styles.productionBranch]: isProduction,
        })}
        style={{ top: `${index * 3 + MT_REM}rem` }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onLeave}
        onClick={handleBranchClick(branch)}
      >
        <div className={styles.branchNameWrapper} style={branchWrapperStyles}>
          {isCheckboxVisible && (
            <div
              className={cn(
                styles.branchCheckbox,
                {
                  [styles.branchCheckboxTrue]: hide,
                },
                styles.branchCheckboxFalse
              )}
            />
          )}
          {isNumberVisible && (
            <span className={styles.branchNumber} title={branch.data?.number}>
              {branch.data.number}
            </span>
          )}
          <div className={cn(styles.branchName, { [styles.nameWithNumber]: isNumberVisible })}>{branch.name}</div>
        </div>
        <div className={styles.branchBubbles}>
          {isPlanEditing && isPlanEditingIconVisible && (
            <>
              <div className={styles.bubbleAddPlan}>
                <PopoverOverlay
                  openType="hover"
                  placement="top"
                  popoverBorderColor="primary"
                  content={<div className={styles.bubbleAddPlanPopover}>Добавить в план</div>}
                >
                  <IconPlus
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCreatePlanModalOpened(true);
                    }}
                  />
                </PopoverOverlay>
              </div>
            </>
          )}
          {hasIndicators && (
            <>
              <div className={styles.bubbleCurrency}>
                <CurrencyPopover indicators={currencyIndicators} type={type} />
              </div>
              {!branch.childCount && (
                <div className={styles.bubbleInfo}>
                  <InfoPopover indicators={infoIndicators} type={type} />
                </div>
              )}
            </>
          )}
          {!isPlanEditing && branch.count_work !== undefined && branch.count_work > 0 && type === WORKS_TAB_ID && (
            <div className={styles.bubbleCount}>
              <div className={styles.count}>{branch.count_work}</div>
            </div>
          )}
          {branch.count_material !== undefined && branch.count_material > 0 && type === MATERIALS_TAB_ID && (
            <div className={styles.bubbleCount}>
              <div className={styles.count}>{branch.count_material}</div>
            </div>
          )}
          {branch.count_mims !== undefined && branch.count_mims > 0 && type === MIMES_TAB_ID && (
            <div className={styles.bubbleCount}>
              <div className={styles.count}>{branch.count_mims}</div>
            </div>
          )}
          {branch.count_equipment !== undefined && branch.count_equipment > 0 && type === EQUIPMENT_TAB_ID && (
            <div className={styles.bubbleCount}>
              <div className={styles.count}>{branch.count_equipment}</div>
            </div>
          )}
          {branch.count_resources !== undefined && branch.count_resources > 0 && type === RESOURCES_TAB_ID && (
            <div className={styles.bubbleCount}>
              <div className={styles.count}>{branch.count_resources}</div>
            </div>
          )}
        </div>
        <div className={styles.flexDaysPeriods}>
          {variant === "days" || variant === "period" ? (
            <div className={styles.days}>
              <span>
                {!!branch.data.plan_duration ? branch.data.plan_duration : "-"}
              </span>
            </div>
          ) : null}
          {variant === "period" ? (
            <div className={styles.period}>
              <span>
                {!!branch.data.plan_start && !!branch.data.plan_end
                  ? `${moment(branch.data.plan_start).format("DD.MM.YYYY")} - ${moment(branch.data.plan_end).format(
                      "DD.MM.YYYY"
                    )}`
                  : "-"}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {isCreatePlanModalOpened && isLsrLevel && (
        <SectionPlanModal
          isOpen
          isLsr
          sectionName={branch.name}
          sectionId={branch.id}
          onPlanCreateSuccess={handlePlanCreateSuccess}
          onClose={() => {
            setIsCreatePlanModalOpened(false);
          }}
        />
      )}
      {isCreatePlanModalOpened && isSectionLevel && (
        <SectionPlanModal
          isOpen
          isLsr={false}
          sectionName={branch.name}
          sectionId={branch.id}
          onPlanCreateSuccess={handlePlanCreateSuccess}
          sectionBudget={branch?.data?.estimate_amount}
          onClose={() => {
            setIsCreatePlanModalOpened(false);
          }}
        />
      )}
      {isCreatePlanModalOpened && isExpenditureLevel && (
        <CreatePlanModal
          isOpen
          selectedWorkGroupId={isGroup ? branch.id : null}
          loadSectionGroups={() => {}}
          onPlanCreateSuccess={handlePlanCreateSuccess}
          handleClose={() => {
            setIsCreatePlanModalOpened(false);
          }}
          product={{
            name: branch.name,
            id: branch.id,
            count: branch.data?.count,
            measure: branch.data?.measure
          }}
        />
      )}
    </Fragment>
  );
};

export default ProjectsBranch;
