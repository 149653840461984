import cn from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { editAcceptanceTicket } from "redux/modules/common/building/journal/acceptance/thunks";
import {
  IEditAcceptanceTicket,
  IJournalAcceptanceTicketItem,
} from "redux/modules/common/building/journal/acceptance/types";

import ButtonBase from "components/UI/atoms/ButtonBase";
import InputBase, { VALUE_TYPES } from "components/UI/atoms/InputBase";
import CalendarRange from "components/UI/molecules/CalendarRange/CalendarRange";
import AddMaterialsInModal from "components/UI/organism/AddingListInModal/AddMaterialsInModal";
import AddMimsInModal from "components/UI/organism/AddingListInModal/AddMimsInModal";
import AddWorkersInModal from "components/UI/organism/AddingListInModal/AddWorkersInModal";
import { useExpendituresInGroups } from "components/UI/organism/AddingListInModal/hooks/useExpendituresInGroups";
import { useSelectingMaterials } from "components/UI/organism/AddingListInModal/hooks/useSelectingMaterials";
import { useSelectingMims } from "components/UI/organism/AddingListInModal/hooks/useSelectingMims";
import { useSelectingWorkers } from "components/UI/organism/AddingListInModal/hooks/useSelectingWorkers";
import JournalDay from "components/UI/organism/WorkOrMaterialsModals/JournalTicketModal/JournalDay";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { IMaterialInAddingInFact } from "types/interfaces/Materials";
import { IMimsInAdding } from "types/interfaces/Mims";
import { ITicketDetailed } from "types/interfaces/Tickets";

import { useMaterialsList } from "hooks/useMaterialsList";
import { useMimsList } from "hooks/useMimsList";

import { checkResorcesCountIsValid, prepareAcceptanceTicketForEdit } from "./utils";
import getShortFullName from "utils/formatters/getShortFullName";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./JournalTicketModal.module.scss";
import { useSimpleResources } from "../../../../../UI/organism/AddingListInModal/hooks/useSimpleResources";
import {
  journalAcceptanceActions
} from "../../../../../../redux/modules/common/building/journal/acceptance/actions";
import { generateStorageKey } from "../../../../../../utils/helpers/generateStorageKey";

interface IProps {
  ticketData: IJournalAcceptanceTicketItem;
  detailedData: ITicketDetailed | null;
  parentName?: string;
  sectionId: number;
  onClose: () => void;
  fileInput: React.ReactNode;
  onCancel: () => void;
}

const JournalTicketModalEdit: React.FC<IProps> = ({
  ticketData,
  detailedData,
  parentName,
  sectionId,
  onClose,
  fileInput,
  onCancel
}) => {
  const dispatch = useDispatch();
  const buildingId = useObjectId();
  const ticketItem = ticketData?.expenditure ?? ticketData?.group;
  const itemId = ticketData?.expenditure?.expenditure_id ?? ticketData?.group?.id;
  const isGroup = !!ticketData?.group;
  const [count, setCount] = useState(detailedData?.count);
  const [countTouched, setCountTouched] = useState(false);
  const countError = useMemo(() => {
    if (count?.length && count?.length > 24) return "Слишком большое значение";
    if (!!count && detailedData?.max_count && +count > +detailedData?.max_count) {
      return "Не может быть больше сметного";
    }
  }, [count, detailedData?.max_count]);

  const editCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountTouched(true);
    setCount(e.target.value);
  };

  const [dateStart, setDateStart] = useState(detailedData?.start_at);
  const [dateEnd, setDateEnd] = useState(detailedData?.end_at);

  useEffect(() => {
    if (!detailedData) return;
    setCount(detailedData.count);
    setDateStart(detailedData.start_at);
    setDateEnd(detailedData.end_at);
  }, [detailedData]);

  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers,
  } = useSelectingWorkers(detailedData?.workers);

  const {
    allMaterials,
    isLoading: allMaterialsLoading,
    loadMoreHandler: allMaterialsLoadMoreHandler,
    count: allMaterialsCount,
    filterHandler: allMaterialsFilterHandler,
  } = useMaterialsList({
    objectId: buildingId,
    new_section_id: sectionId,
    exp_work_id: ticketData?.expenditure?.expenditure_id,
    defaultMaterialsList: undefined,
    isWorkGroup: isGroup,
    groupId: ticketData?.group?.group_id,
    isSkippingRequests: false,
  });

  const filteredAllMaterials = useMemo(() => {
    return allMaterials?.filter((el: any) => typeof el.available_count === "undefined" || +el.available_count > 0);
  }, [allMaterials]);

  const allMaterialsCountsMap = useMemo(() => {
    return allMaterials.reduce((acc: Record<string, number>, cur: { id: number; count: number }) => {
      return {
        ...acc,
        [cur.id]: (cur as any).available_count ?? cur.count,
      };
    }, {}) as Record<string, number>;
  }, [allMaterials]);

  const serializedMaterials = useMemo(() => {
    return detailedData?.materials?.map((el) => ({
      ...el,
      local_count: el.count,
      id: el.expenditure_id ?? el.id,
      count: allMaterialsCountsMap?.[el.expenditure_id] ?? el.count,
    }));
  }, [detailedData, allMaterialsCountsMap]);

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    deletedMaterials,
    clearMaterials,
  } = useSelectingMaterials(serializedMaterials as any, true, isGroup);

  const {
    allMims,
    isLoading: allMimsLoading,
    loadMoreHandler: allMimsLoadMore,
    count: allMimsCount,
    filterHandler: allMimsFilter,
  } = useMimsList({
    objectId: buildingId,
    newSectionId: sectionId,
    expWorkId: ticketData?.expenditure?.expenditure_id,
    isWorkGroup: isGroup,
    groupId: ticketData?.group?.group_id,
  });

  const allMimsCountsMap = useMemo(() => {
    return allMims.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur.available_count ?? cur.sum_ordered_count,
      };
    }, {}) as Record<string, number>;
  }, [allMims]);

  const serializedMims = useMemo(() => {
    return detailedData?.services?.map((el) => ({
      ...el,
      local_count: el.count,
      id: el.expenditure_id ?? el.id,
      count: allMimsCountsMap?.[el.expenditure_id] ?? el.count,
    }));
  }, [detailedData, allMimsCountsMap]);

  const {
    confirmMimsHandler,
    cancelMimsHandler,
    changeCountMimsHandler,
    validateSubmittingMims,
    deleteAddedHandler: deleteAddedMims,
    selectMimsHandler,
    addedMims,
    selectedMims,
    selectedMimsIds,
    addedMimsIds,
    deletedMims,
    clearMims,
  } = useSelectingMims(serializedMims as any, true, isGroup);

  const {
    resources: simpleResources,
    materials: simpleMaterials,
    mims: simpleMims,
    deleteResource: deleteSimpleResource,
    updateResource: updateSimpleResource,
    createNewResource: createNewSimpleResource,
    isAdding: isSimpleResourceAdding,
    isMaterialAdding: isSimpleMaterialAdding,
    isMimAdding: isSimpleMimAdding,
    toggleIsEditing: toggleIsSimpleResourceEditing,
    onSubmit: onSimpleResourcesSubmit,
    isValidForSubmit: validateSimpleResources
  } = useSimpleResources(detailedData?.simple_resources ?? []);

  const deleteMim = (id: number) => {
    deleteAddedMims(id);
  };

  const filteredAllMims = useMemo(() => {
    const idsOfFiltered: Record<string, boolean> = {};
    const filtered =
      allMims?.filter((el: any) => {
        if (typeof el.available_count === "undefined" || +el.available_count > 0) {
          idsOfFiltered[el.id] = true;
          return true;
        }
      }) ?? [];
    const deleted =
      deletedMims
        ?.filter((el) => !idsOfFiltered[el.id])
        ?.map((el) => {
          return { ...el, available_count: el.local_count };
        }) ?? [];

    return [...filtered, ...deleted];
  }, [allMims, deletedMims]);

  const submitHandler = async () => {
    const [data, wasChanged] = prepareAcceptanceTicketForEdit({
      dateEnd,
      dateStart,
      count,
      initialTicket: detailedData,
      workers: addedUsers,
      materials: addedMaterials as any,
      services: addedMims as any,
    });
    if (
      checkResorcesCountIsValid(data.materials!, allMaterialsCountsMap, "материалов") &&
      checkResorcesCountIsValid(data.services!, allMimsCountsMap, "машин и механизмов")
      && validateSimpleResources()
    ) {
      dispatch(editAcceptanceTicket({
        isGroup: !!ticketData?.group,
        data,
        buildingId,
        itemId: detailedData?.id!,
        callback: async () => {
          const updatedResources = await onSimpleResourcesSubmit({
            fact_work: !isGroup ? detailedData?.id : undefined,
            fact_group: isGroup ? detailedData?.id : undefined
          });
          const key = generateStorageKey({
            type: !!ticketData.group ? "group" : "expenditure",
            ticketId: String(detailedData.id)
          });

          dispatch(journalAcceptanceActions.updateTicketSimpleResources(key, updatedResources));
        }
      }))
      onClose();
    }
  };

  return (
    <>
      <div className={styles.pair}>
        <JournalDay
          day={moment(ticketItem?.created_at).format("DD/MM/YYYY")}
          time={moment(ticketItem?.created_at).format("HH:mm")}
        />
        <div className={styles.pass} title={getShortFullName(ticketItem?.author)}>
          <b>Сдал:</b>
          <span>{sliceTextByConstraint(getShortFullName(ticketItem?.author), 18)}</span>
        </div>
      </div>
      <div className={styles.pair}>
        <div className={styles.info}>
          <b>Количество (к сдаче):</b>
          <InputBase
            valueType={VALUE_TYPES.NUMBER}
            value={count}
            onChange={editCount}
            meta={{ error: countError, touched: countTouched }}
            className={styles.formInput}
            numbersConstraint={8}
          />
        </div>
        <div className={styles.info}>
          <b>Стоимость:</b>
          <span>{splitThousands(detailedData?.amount)}</span>
        </div>
      </div>
      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          key={detailedData?.start_at}
          defaultDateStart={moment(dateStart)}
          defaultDateEnd={moment(dateEnd)}
          classNameSelect={styles.calendar}
          setDefaultDateEnd={setDateEnd}
          setDefaultDateStart={setDateStart}
          skipValidation
          isTooRight
        />
      </div>
      {!!parentName && (
        <div className={styles.info}>
          <b>Раздел:</b>
          <span>{parentName}</span>
        </div>
      )}
      <AddWorkersInModal
        addedUsers={addedUsers}
        selectedUsers={selectedUsers}
        onAccept={confirmHandler}
        onDecline={cancelHandler}
        onChangeCount={changeCountHandler}
        onSelect={selectHandler}
        selectedIds={selectedUsersIds}
        onDelete={deleteAddedHandler}
        addedIds={addedUsersIds}
      />
      <AddMaterialsInModal
        addedMaterials={addedMaterials}
        selectedUsers={selectedMaterials as IMaterialInAddingInFact[]}
        onAccept={confirmMaterialsHandler}
        onDecline={cancelMaterialsHandler}
        onChangeCount={changeCountMaterialsHandler}
        onSelect={selectMaterialsHandler}
        selectedIds={selectedMaterialsIds}
        onDelete={deleteAddedMaterial}
        addedIds={addedMaterialsIds}
        isDisabled={false}
        activeSection={sectionId}
        activeWork={itemId!}
        isWorkGroup={isGroup}
        groupId={(ticketData?.group as any)?.group_id}
        externalMaterialsData={{
          count: allMaterialsCount,
          allMaterials: filteredAllMaterials,
          isLoading: allMaterialsLoading,
          filterHandler: allMaterialsFilterHandler,
          loadMoreHandler: allMaterialsLoadMoreHandler,
        }}
        isValidatingCount
        simpleResources={simpleMaterials}
        createNewSimpleResource={createNewSimpleResource}
        deleteSimpleResource={deleteSimpleResource}
        isSimpleResourceAdding={isSimpleMaterialAdding}
        toggleIsSimpleResourceEditing={toggleIsSimpleResourceEditing}
        updateSimpleResource={updateSimpleResource}
      />
      <AddMimsInModal
        addedMims={addedMims}
        selectedMims={selectedMims as IMimsInAdding[]}
        onAccept={confirmMimsHandler}
        onDecline={cancelMimsHandler}
        onChangeCount={changeCountMimsHandler}
        onSelect={selectMimsHandler}
        selectedIds={selectedMimsIds}
        onDelete={deleteMim}
        addedIds={addedMimsIds}
        activeSection={sectionId}
        activeWork={itemId!}
        isDisabled={false}
        externalMimsData={{
          count: filteredAllMims?.length,
          allMims: filteredAllMims,
          isLoading: allMimsLoading,
          filterHandler: allMimsFilter as any,
          loadMoreHandler: allMimsLoadMore,
        }}
        isWorkGroup={isGroup}
        groupId={(ticketData?.group as any)?.group_id}
        isValidatingCount
        simpleResources={simpleMims}
        createNewSimpleResource={createNewSimpleResource}
        deleteSimpleResource={deleteSimpleResource}
        isSimpleResourceAdding={isSimpleMimAdding}
        toggleIsSimpleResourceEditing={toggleIsSimpleResourceEditing}
        updateSimpleResource={updateSimpleResource}
      />
      {fileInput}
      <BottomControls isExists isDoubleBtns>
        <ButtonBase secondary type="reset" onClick={onCancel}>
          Отмена
        </ButtonBase>
        <ButtonBase primary type="submit" onClick={submitHandler} disabled={!!countError}>
          Сохранить
        </ButtonBase>
      </BottomControls>
    </>
  );
};

export default JournalTicketModalEdit;
