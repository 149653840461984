import { Ks6aListItemType, Ks6aMonthTotalType } from "../../../../../../../redux/modules/common/ks6a/types";

export const getTableViewWidthPercent = (tableElement: Element) => {
  return (tableElement.clientWidth / (tableElement.scrollWidth - tableElement.clientWidth)) * 100 * 0.39;
};

export const scrollTable = (tableElement: Element, scrollPercent: number) => {
  tableElement.scrollLeft = tableElement.scrollWidth * (scrollPercent / 100);
};

const monthTotalInitial: Ks6aMonthTotalType = {
  count: 0,
  amount: 0,
  fact_amount: 0,
};

export const calculateTotals = (list: Ks6aListItemType[]) => {
  let newMonthTotal = {};
  let newTotalPrice = 0;
  let newTotalCount = 0;
  list.forEach((item: Ks6aListItemType) => {
    Object.entries(item.month_data.months).forEach(([key, value]) => {
      const { count, amount, fact_amount } = value;
      if (!newMonthTotal[key]) {
        newMonthTotal[key] = { ...monthTotalInitial };
      }
      if (count) {
        newMonthTotal[key].count += +count;
        newTotalCount += +count;
      }
      if (amount) newMonthTotal[key].amount += +amount;
      if (fact_amount) {
        newMonthTotal[key].fact_amount += +fact_amount;
        newTotalPrice += +fact_amount;
      }
    });
  });
  return { newTotalPrice, newMonthTotal, newTotalCount };
};

export const countFormatter = (count: number | string | undefined) => Number((+count || 0).toFixed(2));
