import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { actingApi } from "redux/modules/common/building/journal/acting/api";
import { actingInvalidateKeySelector } from "redux/modules/common/building/journal/acting/selectors";

import JournalActingModalContainer from "./JournalActingModalContainer";
import JournalEditingKs2ModalRow from "./Rows/JournalKs3ModalRow/JournalKs3EditingModalRow";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useEditKs3 } from "../hooks/useEditKs3";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  ks3Id: number;
}

const JournalKs3EditModal: React.FC<IProps> = ({ isOpen, onClose, ks3Id }) => {
  const {
    submitHandler,
    actData,
    actNumberHandler,
    dateStartHandler,
    dateEndHandler,
    deleteExpenditure,
    expendituresInKs,
    isShowSubmit,
    detailedKs2,
  } = useEditKs3(ks3Id, isOpen);
  const cacheKey = useSelector(actingInvalidateKeySelector);
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  useEffect(() => {
    onClose();
  }, [cacheKey]);

  const downloadReport = useCallback(() => {
    actingApi.getKs3Report(
      objectId,
      ks3Id,
      `КС-3 №${actData.number} от ${moment(actData.dateStart).format("DD.MM.YYYY")}.xlsx`
    );
  }, [objectId, ks3Id, actData.number]);

  return (
    <>
      <JournalActingModalContainer
        title="Редактирование акта КС-3"
        isOpen={isOpen}
        onClose={onClose}
        count={detailedKs2?.expenditures_count}
        actNumber={actData.number}
        onChangeAct={actNumberHandler}
        dateEnd={actData.dateEnd}
        dateStart={actData.dateStart}
        onChangeDateEnd={dateEndHandler}
        onChangeDateStart={dateStartHandler}
        onSubmit={submitHandler}
        isEdit
        isShowSubmit={isShowSubmit}
        onDownload={downloadReport}
      >
        {expendituresInKs?.map((sec) =>
          sec.expenditures.map((el) => (
            <JournalEditingKs2ModalRow key={el.id} ks2={el} onDeselectKs2={deleteExpenditure} />
          ))
        )}
      </JournalActingModalContainer>
    </>
  );
};

export default React.memo(JournalKs3EditModal);
