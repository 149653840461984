import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";

import { userSelector } from "../../../../../redux/modules/common/auth";
import { approveStockMovement, stockDetailSelector } from "../../../../../redux/modules/common/building/stocks";
import {
  addReplenishments,
  loadReplenishments,
  replenishmentsLoadingSelector,
  replenishmentsSelector,
} from "redux/modules/common/building/stocks";

import { Spinner } from "../../../../UI/Spinner/Spinner";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "../../../../UI/atoms/ShowMoreButton/index";

import IncomeHeader from "../common/TableHeader/IncomeHeader";
import IncomeRow from "../common/TableRow/IncomeRow";
import SubTablePacking from "./SubTable/SubTablePacking";
import SubTableUsing from "./SubTable/SubTableUsing";
import { useDownloadStockReport } from "./useDownloadStockReport";

import getShortFullName from "../../../../../utils/formatters/getShortFullName";
import { hasOnlyPaginationFilters } from "../../../../../utils/hasOnlyPaginationFilters";

import warehouses from "images/icons/navigation/warehouses.svg";

const Income = ({ stockId }) => {
  const dispatch = useDispatch();
  const replenishments = useSelector(replenishmentsSelector);
  const isLoading = useSelector(replenishmentsLoadingSelector);

  const stockDetail = useSelector(stockDetailSelector);
  const user = useSelector(userSelector);

  const [filters, setFilters] = useState({ limit: 10, offset: 0 });
  const { measure } = useFinanceMeasure();

  const isWithFilters = useMemo(() => !hasOnlyPaginationFilters(filters), [filters]);

  const changeFilters = (name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAdd = () => {
    dispatch(addReplenishments(stockId, { offset: replenishments?.results?.length }));
  };

  useEffect(() => {
    stockId && dispatch(loadReplenishments(stockId, filters));
  }, [filters, stockId]);

  const downloadReportHandler = useDownloadStockReport(stockId);

  const handleApproveStockMovement = React.useCallback(
    (itemId, stockUsing, packingList) => {
      dispatch(approveStockMovement({ stockId, stockUsing, packingList, itemId, payload: true }));
    },
    [stockId]
  );

  const isEmpty = !isLoading && !replenishments?.results?.length;

  return (
    <>
      {((isEmpty && isWithFilters) || !isEmpty) && <IncomeHeader onFiltersChange={changeFilters} withFilters />}
      {isLoading && <Spinner />}
      {!isLoading &&
        !!replenishments?.results?.length &&
        replenishments?.results?.map((event) => (
          <IncomeRow
            rowNumber={event.id}
            countChildren={event.count_packingitems || event.count_stockusingitems}
            date={moment(event.created_at).format("DD.MM.YYYY")}
            sendUser={getShortFullName(event.sendeduser)}
            confirmedUser={getShortFullName(event.confirmeduser)}
            type={event.type_label}
            status={event.status}
            onFileClick={downloadReportHandler(event)}
            isResponsible={stockDetail.responsible && stockDetail.responsible.id === user.id}
            onApprove={() => handleApproveStockMovement(event.id, event.stockusing, event.packinglist)}
            key={event.id}
          >
            <SubTableUsing limit={event.count_stockusingitems} stockusing={event.stockusing} measure={measure} />
            <SubTablePacking packinglist={event.packinglist} measure={measure} />
          </IncomeRow>
        ))}
      {isEmpty && <EmptyPlaceholder img={warehouses} />}
      <ShowMoreButton
        isExists={!isLoading && replenishments?.count > replenishments?.results.length}
        allCount={replenishments?.count}
        showedCount={replenishments?.results?.length}
        handleAdd={handleAdd}
      />
    </>
  );
};

export default React.memo(Income);
