import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import {
  chartViewModeSelector,
  diagramFiltersSelector,
  manufacturingTabSelector,
} from "redux/modules/common/building/manufacturing/selectors";
import { setChartViewMode } from "redux/modules/common/building/manufacturing/thunks";

import { WORKS_TAB_ID } from "../constants";

import { useUrlModule } from "utils/hooks/useUrlModule";

import { getDiagramMoment } from "../utils";

export const useChartViewModeTabs = (
  year?: number | string,
  month?: number,
  handleDateChange?: (_: Moment) => void
) => {
  const chartViewMode = useSelector(chartViewModeSelector);
  const tab = useSelector(manufacturingTabSelector);
  const diagramFilters = useSelector(diagramFiltersSelector);
  const isMonthsDisabled = tab === WORKS_TAB_ID && diagramFilters.plans_editing_enabled;
  const isWeeksDisabled = tab === WORKS_TAB_ID && diagramFilters.plans_editing_enabled;
  const dispatch = useDispatch();
  const moduleRoute = useUrlModule();
  const [cachedDate, setCachedDate] = useState({ year, month });

  const setViewTab = useCallback(
    (newView: string) => {
      newView =
        chartViewMode === MONTH && newView === MONTH
          ? HALF_MONTH
          : chartViewMode === HALF_MONTH && newView === HALF_MONTH
          ? MONTH
          : newView;

      if (handleDateChange && !!cachedDate.month && !!cachedDate.year) {
        if (newView !== YEAR) {
          handleDateChange(getDiagramMoment(cachedDate.year, cachedDate.month));
        } else if (!!year && !!month) {
          setCachedDate({ year, month });
        }
      }
      dispatch(setChartViewMode(newView));
    },
    [chartViewMode, handleDateChange, cachedDate, year, month]
  );

  const chartViewModeTabs = useMemo(
    () => [
      {
        id: chartViewMode === HALF_MONTH ? HALF_MONTH : MONTH,
        text: `Дни | ${chartViewMode === HALF_MONTH ? 15 : 30}`,
      },
      {
        id: WEEK,
        text: "Недели",
        disabled: isWeeksDisabled,
      },
      {
        id: YEAR,
        text: "Месяцы",
        isHidden: moduleRoute !== "constructing",
        disabled: isMonthsDisabled,
      },
    ],
    [chartViewMode, moduleRoute, isMonthsDisabled, isWeeksDisabled]
  );

  return {
    chartViewMode,
    setViewTab,
    chartViewModeTabs,
  };
};
